import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import deleteIcon from '../../../../assets/img/icons/cleanCloseIcon.svg';
import iconProvisions from '../../../../assets/img/icons/cloneIcon.svg';
import Search from '../../../../assets/img/icons/lupa.svg';
import detail from '../../../../assets/img/icons/lupaTransparent.svg';
import send from '../../../../assets/img/icons/paperplane.svg';
import threeDots from '../../../../assets/img/icons/threeDots.svg';
import tableStyles2 from '../../../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../../../components/Popup/customPopup.module.scss';
import { customSwaltAlert } from '../../../../helpers/customSwaltAlert';
import {
  convertMoneyFormat,
  formatToRcSelect,
  loader,
  message,
  validateEmptyString,
} from '../../../../helpers/helpers';
import Styles2 from '../../../GeneratePayroll/generatePayroll.module.scss';
import GenericTableNew from '../../../Layouts/GenericTableNew';
import ModalNew from '../../../Layouts/ModalNew';
import paginationStyles from '../../../Layouts/pagination.module.scss';
import { customSelectNew } from '../../../Layouts/react-select-custom';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../../Popup/customPopUpExtends';
import SelectComponent from '../../../SelectComponent/SelectComponent';
import '../../../TableUsers/tableUserStyle.scss';
import { RoyaltyPaymentDetail } from './RoyaltyPaymentDetail';

export const RoyaltyPayment = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandenom',
  );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [data, setData] = useState({
    trigger: 0,
    generatePremiumSchedule: null,
    idTemplateDetail: '',
    statusTemplate: '',
    templateDetailActive: false,
    dataPayroll: '',
    filters: {
      year: '',
      search: '',
      page: 1,
      perpage: 10,
      entity_account: idAccount,
      status: undefined,
    },
    modal: {
      show: false,
      isEditing: false,
      datePayment: '',
      payPeriod: 2020,
    },
  });
  const { filters, trigger, modal, generatePremiumSchedule } = data;
  const {
    results: royaltyPaymentResults,
    load: royaltyPaymentLoader,
    trigger: getRoyaltyPayment,
  } = useGetMethod();
  const { load: loaderGeneratePayrollBonus, trigger: generatePayrollBonus } = usePostMethod();
  const { load: deletePayrollLoader, trigger: deletePayrollMethod } = usePostMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();
  const {
    results: statusMethodResults,
    load: statusLoader,
    trigger: getStatusMethod,
  } = useGetMethod();
  const statusOptions = formatToRcSelect(statusMethodResults?.results, 'name', 'label', '', '', '');

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    getRoyaltyPayment({
      url: '/payroll/perks/',
      objFilters: filters,
      token: token,
    });
    getStatusMethod({
      url: '/payroll/status-rd/perks/',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, generatePremiumSchedule]);

  const renderElement = elem => {
    const optionElem = {
      detail: {
        text: <span>Ver detalle</span>,
        class: CustomPopupStyles.popUpGeneric,
        event: myPermission?.read
          ? () => {
              setData({
                ...data,
                idTemplateDetail: elem?.id,
                statusTemplate: elem?.status?.name,
                generatePremiumSchedule: true,
                dataPayroll: elem,
              });
            }
          : null,
        icon: detail,
      },
      delete: {
        text: <span>Eliminar</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: deleteIcon,
        event: myPermission.edit
          ? () => {
              deletePayrollFunction(elem?.id, elem.payment_year);
            }
          : null,
      },
      sendToAccounting: {
        text: <span>Enviar a contabilidad</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: send,
        event: myPermission.edit
          ? () => {
              sendToAccountingFunction(elem.id, elem.payment_year, elem.period);
            }
          : null,
      },
      failed: {
        text: 'Ver fallo',
        icon: iconProvisions,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          message('warning', 'Intenta de nuevo', elem?.cron_log_message, undefined, true);
        },
      },
      showComment: {
        text: 'Ver comentario',
        icon: iconProvisions,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          setCommentModal({ ...commentModal, show: true, comment: elem?.comment ?? '' });
        },
      },
    };
    const options = {
      loaded: [optionElem.detail, optionElem.delete, optionElem.sendToAccounting],
      review: [optionElem.detail],
      rejected: [optionElem.detail, optionElem.showComment],
      approved: [optionElem.detail],
      failed: [optionElem.failed, optionElem.delete],
    };
    return (
      <tr key={elem.id}>
        <td className={`col-3 px-3 text-start fw-bold ${tableStyles2.darkBlueText}`}>
          {elem?.period ?? '-'}
        </td>
        <td className='col-2 text-center'>{elem?.payment_year ?? '-'}</td>
        <td className='col-2 text-center'>{elem?.total_employees ?? '-'}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.total ?? '-')}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.total_amount_perks ?? '-')}</td>
        <td className='col-2 text-center px-2'>
          <div
            className='rounded-pill p-1'
            style={{
              backgroundColor: elem?.status?.bgColor,
            }}
          >
            <b style={{ color: elem?.status?.fontColor }}>{elem?.status?.value ?? '-'}</b>
          </div>
        </td>
        <td className='col-1 '>
          {elem.status.name !== 'in_process' ? (
            <CustomPopupExtend
              triggerSrc={threeDots}
              center
              noHover
              extraButtons={options?.[elem?.status?.name]}
            ></CustomPopupExtend>
          ) : (
            <div style={{ width: '24px' }}>&nbsp;</div>
          )}
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th key={1} className='text-start col-3 px-3'>
      Periodo
    </th>,

    <th key={2} className='text-center col-2'>
      Año
    </th>,

    <th key={3} className='text-center col-2'>
      No. de empleados
    </th>,

    <th key={4} className='text-end col-2'>
      Total
    </th>,

    <th key={5} className='text-end col-2'>
      Total regalía pascual
    </th>,

    <th key={6} className='text-center col-1'>
      Estado
    </th>,
    <th key={6} className='text-start col-1'>
      &nbsp;
    </th>,
  ];

  const sendToAccountingFunction = (id, paymentYear, range) => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se enviará a contabilidad la planilla de regalía pascual`,
    }).then(result => {
      if (result.isConfirmed) {
        updatePayroll({
          url: '/payroll/perks/',
          token: token,
          method: 'PUT',
          body: {
            persk_id: id,
            entity_account: idAccount,
            status: 'review',
          },
          succesAction: () => {
            setData(state => ({ ...state, trigger: state.trigger + 1 }));
            customSwaltAlert({
              title: `Enviada exitosamente`,
              text: `Se ha enviado a contabilidad planilla del año ${paymentYear} - Periodo ${range}. `,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const deletePayrollFunction = (id, year) => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se eliminará la planilla de regalía pascual del año ${year}`,
      confirmButtonText: 'Sí, continuar',
    }).then(result => {
      if (result.isConfirmed) {
        deletePayrollMethod({
          url: '/payroll/perks/',
          method: 'DELETE',
          body: {
            id: id,
            eaccount: idAccount,
          },
          token: token,
          succesAction: () => {
            setData(state => ({
              ...state,
              trigger: state.trigger + 1,
            }));
            customSwaltAlert({
              icon: 'success',
              title: 'Proceso completado',
              text: `Se ha eliminado la planilla de regalía pascual`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        show: false,
        isEditing: false,
        datePayment: '',
        payPeriod: 2023,
      },
    }));
  };

  const validationRequest = () => {
    let validations = validateEmptyString([
      {
        field: data?.modal?.payPeriod,
        message: 'El periodo de regalía pascual es obligatorio para generar la planilla',
      },
      {
        field: data?.modal?.datePayment,
        message: 'La fecha de pago es obligatorio para generar la planilla',
      },
    ]);
    if (validations.valid) {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: 'Se generará una planilla de regalía pascual.',
        confirmButtonText: 'Sí, continuar',
      }).then(result => {
        if (result.isConfirmed) {
          generatePayrollBonus({
            url: '/payroll/perks/',
            token: token,
            noAlert: true,
            method: 'POST',
            body: {
              entity_account: idAccount,
              year: data.modal.payPeriod,
              date: data.modal.datePayment,
            },
            doAfterSuccess: () => {
              closeModal();
              setData(state => ({ ...state, trigger: state.trigger + 1 }));
            },
            doAfterException: res => {
              message('warning', `Intente de nuevo`, res?.message, undefined, true);
            },
          });
        }
      });
    }
  };
  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
  });
  const closeCommentModal = () => {
    setCommentModal({
      ...commentModal,
      show: false,
      cancelledReason: '',
    });
  };
  return (
    <>
      {(statusLoader ||
        deletePayrollLoader ||
        updatePayrollLoader ||
        royaltyPaymentLoader ||
        loaderGeneratePayrollBonus) &&
        loader}
      {!generatePremiumSchedule ? (
        <>
          <ModalNew
            title='Comentarios'
            show={commentModal.show}
            // btnYesName={showModalRejected.justify !== "" && "Guardar"}
            btnNoName={'Cerrar'}
            size='400'
            btnYesEvent={null}
            btnYesDisabled={true}
            onHide={() => closeCommentModal()}
            btnNoEvent={() => closeCommentModal()}
            btnNoDisabled={false}
          >
            <p className={tableStyles.crudModalLabel}>Comentario</p>
            <textarea
              placeholder={'Escribir...'}
              id='txtArea'
              rows='10'
              cols='10'
              disabled={true}
              defaultValue={commentModal.comment}
              style={{
                height: '4rem',
                borderRadius: '5px',
                border: '1.5px solid #01A0F6',
                padding: '0 0.05rem',
                width: '100%',
              }}
              className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
            ></textarea>
          </ModalNew>
          <OrdModal
            title={modal.isEditing ? '' : 'Generar planilla de regalía pascual'}
            show={modal.show}
            btnYesName={'Guardar'}
            btnNoName={'Cancelar'}
            size={550}
            onHide={() => closeModal()}
            btnYesEvent={myPermission?.create ? () => validationRequest() : null}
            btnNoEvent={() => closeModal()}
            sizeBody={`${tableStyles2.modalHeight}`}
            titleClassName={tableStyles2.darkBlueText}
            btnYesStyle={tableStyles2.btnYesStyle}
            btnCancelStyle={tableStyles2.btnCancelStyle}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <label className={tableStyles.crudModalLabel}>Periodo de regalía pascual</label>
                <SelectComponent
                  key={`select}`}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew}
                  // options={bonusSalaryOptions}
                  // value={bonusSalaryOptions.find((e) => e.extra2 === "" ? "" : e.extra2 === "0" ? "0" : e.extra + "-" + e.extra2 === modal?.payPeriod)}
                  onChange={e => {
                    let value = e.extra2 === '0' ? '0' : e.extra + '-' + e.extra2;
                    setData(state => ({ ...state, modal: { ...state.modal, payPeriod: value } }));
                  }}
                />
              </Grid>
            </Grid>

            <Grid container className='my-3'>
              <Grid item xs={12}>
                <label className={tableStyles.crudModalLabel}>Fecha de pago</label>
                <input
                  className={`register-inputs `}
                  type='date'
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      modal: { ...state.modal, datePayment: e.target.value },
                    }))
                  }
                />
              </Grid>
            </Grid>
          </OrdModal>
          <div className='w-80 mx-auto'>
            <h1 className={tableStyles.title}>Planilla regalía pascual</h1>

            {!!myPermission?.read && (
              <>
                <Row className='d-flex'>
                  <Col xs={2}>
                    <label className={tableStyles.crudModalLabel}>Año</label>
                    <input
                      onChange={e =>
                        setData(state => ({
                          ...state,
                          filters: { ...state.filters, year: e.target.value },
                        }))
                      }
                      type='number'
                      placeholder='Escribir...'
                      className={`register-inputs `}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setData(state => ({ ...state, trigger: state.trigger + 1 }));
                        }
                      }}
                    />
                  </Col>
                  <Col xs={2} className='align-self-end'>
                    <label className={tableStyles.crudModalLabel}>Estado</label>
                    <SelectComponent
                      key={`select`}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNew}
                      options={statusOptions}
                      value={statusOptions.find(e => e.value === filters?.status)}
                      onChange={e => {
                        setData(state => ({ ...state }));
                        setData(state => ({
                          ...state,
                          trigger: state.trigger + 1,
                          filters: { ...state.filters, status: e.value },
                        }));
                      }}
                    />
                  </Col>
                  <Col xs={5} className='d-flex align-items-end'>
                    <input
                      className={` ${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch}
                      name='search'
                      type='text'
                      placeholder='Buscar...'
                      value={filters.search}
                      onChange={e =>
                        setData(state => ({
                          ...state,
                          filters: { ...state.filters, search: e.target.value },
                        }))
                      }
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setData(state => ({ ...state, trigger: state.trigger + 1 }));
                        }
                      }}
                    />

                    <img
                      src={Search}
                      style={{ cursor: 'pointer' }}
                      alt='Search icon'
                      height='24px'
                      onClick={() => {
                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                      }}
                    />
                  </Col>

                  {!!myPermission?.create && (
                    <>
                      <Col xs={3} className='d-flex align-items-end justify-content-end'>
                        <label
                          className='darkGray fw-bold'
                          style={{ color: '#58595B', margin: '4px' }}
                        >
                          Generar planilla
                        </label>
                        <div
                          className={Styles2.btnpreviw}
                          style={{ width: '33px', height: '31px' }}
                          onClick={() =>
                            setData(state => ({
                              ...state,
                              modal: { ...state.modal, isEditing: false, show: true },
                            }))
                          }
                        ></div>
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <div>
                    <GenericTableNew headers={renderHeaders} dark={true}>
                      {royaltyPaymentResults?.results?.length > 0 &&
                        royaltyPaymentResults?.results?.map(el => {
                          return renderElement(el);
                        })}
                    </GenericTableNew>
                  </div>

                  <div>
                    <div className={paginationStyles.wrapper}>
                      <p className={`${paginationStyles.paginationText} text-secondary`}>
                        Pag. {royaltyPaymentResults?.results ? filters.page : ''}
                        {' de '}
                        {Math.ceil(royaltyPaymentResults?.rowTotal / filters.perpage)
                          ? Math.ceil(royaltyPaymentResults?.rowTotal / filters.perpage)
                          : ''}{' '}
                        ({royaltyPaymentResults?.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={royaltyPaymentResults?.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e =>
                          setData(state => ({
                            ...state,
                            filters: { ...state.filters, page: e },
                            trigger: state.trigger + 1,
                          }))
                        }
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                      />
                    </div>
                  </div>
                </Row>
              </>
            )}
          </div>
        </>
      ) : (
        <RoyaltyPaymentDetail
          backPage={{ setData }}
          idTemplate={data.idTemplateDetail}
          status={data.statusTemplate}
          dataPayroll={data.dataPayroll}
        />
      )}
    </>
  );
};
