import { useEffect, useState, useMemo } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Descarga from '../../assets/img/icons/AgregarTable.svg';
import ModalNew from '../../components/Layouts/ModalNew';
import { getNiifAccounts } from '../../actions/AccountsActions';
import Select from 'react-select';
import boxReceiptStyles from './boxReceiptStyles.module.scss';
import { getCorporateClients } from '../../actions/corporateClientsActions';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { getDepositCheck, getOneDeposit } from '../../actions/depositbankAction';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { SlideDownButton } from '../../components/Layouts/SlideDownButton';
import NumberFormat from 'react-number-format';
import GearGray from '../../assets/img/icons/engraneGris.svg';
import { getInvoices, update_Invoices } from '../../actions/cashReceiptAction';
import {
  GET_CHECK_DEPOSIT_BANK,
  GET_INVOICES,
  GET_ONE_DEPOSIT_BANK,
} from '../../actions/actionTypes';
import { useHistory } from 'react-router';
import { Tooltip, withStyles } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import { formatter } from '../../helpers/helpers.js';
import { customSwaltAlert } from '../../helpers';
import { Title } from '../../shared';

function CashReceipt() {
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const [trigger3, setTrigger3] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedList, setselectedList] = useState([]);
  const [searchInvoices, setSearchInvoices] = useState([]);
  const [sumTotalInvoice, setSumTotalInvoice] = useState([]);
  const [showModalAjust, setShowModalAjust] = useState(false);
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'clientbox',
    );
  let history = useHistory();
  if (!myPermission()?.create || !myPermission()?.read) {
    history.push('/tesoreria/inicio');
  }
  useEffect(() => {
    dispatch(getCorporateClients({ id_account: counter.loginReducer.currentAccount.id }));
    dispatch(getNiifAccounts({ eaccount: counter.loginReducer.currentAccount.id }));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
  useEffect(() => {
    dispatch({
      type: GET_CHECK_DEPOSIT_BANK,
      payload: [],
    });
    dispatch({
      type: GET_ONE_DEPOSIT_BANK,
      payload: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // llamado de select de cuenta contable
  var optionClients = [{ label: 'Seleccionar', value: '' }];
  if (Array.isArray(counter.corporateclientsReducer.corporateClients)) {
    counter.corporateclientsReducer.corporateClients.forEach(item => {
      optionClients.push({
        value: item.id,
        label: item.Nit + ' - ' + item.copyName,
        copyName: item.copyName,
        key: item.id + 'wweh',
      });
    });
  }
  // llamado de select de cuenta cliente
  var optionNiifAccounts = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.forEach(item => {
      if (item.check_info_bank === true && item.active === true) {
        optionNiifAccounts.push({
          value: item.id,
          label: item.complete_account + ' - ' + item.description,
          key: item.id + 'qg',
        });
      }
    });
  }

  var NiifAccountsModal = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.forEach(item => {
      if (item.active === true) {
        NiifAccountsModal.push({
          value: item.id,
          label: item.complete_account + ' - ' + item.description,
          key: item.id + 'qc',
        });
      }
    });
  }

  // funcion para formatear la fecha
  var date = new Date();
  const formatDate = date => {
    let formatted_date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    return formatted_date;
  };
  const formatDateTwo = date => {
    let formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return formatted_date;
  };

  const isBoxSelected = id_req => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id_req);
      return !!target;
    }
  };
  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    let values = sumTotalInvoice;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      let target2 = values.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }

      if (target2) {
        values = values.filter(x => x.id !== item.id);
      }

      setselectedList(tempSelected);
      setSumTotalInvoice(values);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(counter.cashReceipttReducer?.results) && Array.isArray(selectedList)) {
      counter.cashReceipttReducer?.results.forEach(item => {
        if (selectedList.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(counter.cashReceipttReducer.results) && Array.isArray(tempSelected)) {
        counter.cashReceipttReducer.results.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const [showModalInvoice, setShowModalInvoice] = useState(false);

  const header = [
    <th key={1}></th>,
    <th key={2}>Consec. / cheque</th>,
    <th key={3}>Fecha</th>,
    <th key={4}>Valor pendiente</th>,
    <th key={5}>Valor inicial</th>,
  ];

  const headerRecaudo = [
    <th key={1} className='text-center col-2'>
      Tipo
    </th>,
    <th key={2} className='text-center col-2'>
      Consecutivo / No. Cheque
    </th>,
    <th key={3} className='text-center col-2'>
      Banco
    </th>,
    <th key={4} className='text-center col-2'>
      Fecha documento
    </th>,
    <th key={5} className='text-center col-2'>
      Valor recibido
    </th>,
  ];

  const [getSearchRecaudo, setSearchRecaudo] = useState({
    transaction_date: '',
    deposit_type: 0,
    identifier: '',
  });

  const [getRecaudo, setRecaudo] = useState({
    recaudo: '',
    value: '',
  });
  const [getCreate, setCreate] = useState({
    niff_account: '',
  });
  const getAmountNiffAccount = e => {
    resetChangesSelect();
    dispatch(
      getDepositCheck({
        niff_account: e.value ? e.value : '',
        entity_account: counter.loginReducer.currentAccount.id,
      }),
    );

    setCreate({
      ...getCreate,
      niff_account: e.value,
    });
  };
  const bodyConsig = [];
  const getAmountClient = e => {
    resetChangesSelect2();
    setLoading(true);
    dispatch(
      getOneDeposit(
        {
          corporate_client: e?.value ? e?.value : getCreate?.corporate_client,
          niff_account: getCreate?.niff_account,
          entity_account: counter?.loginReducer?.currentAccount.id,
          transaction_date: getSearchRecaudo?.transaction_date
            ? getSearchRecaudo?.transaction_date
            : '',
          identifier: getSearchRecaudo?.identifier ? getSearchRecaudo?.identifier : '',
          deposit_type: getSearchRecaudo?.deposit_type ? getSearchRecaudo?.deposit_type : '',
          status: '2,3',
        },
        () => setLoading(false),
      ),
    );
    if (e) {
      setCreate({
        ...getCreate,
        corporate_client: e?.value,
        nameClient: e?.label,
        copyName: e?.copyName,
      });
    }
  };

  if (Array.isArray(counter.depositBankReducer.resultsOne)) {
    counter.depositBankReducer.resultsOne.forEach(item => {
      bodyConsig.push(
        <tr key={1} htmlFor='RecaudoSelect'>
          <input
            className=''
            type='radio'
            name='property_'
            id={item.id}
            value='property_'
            onChange={() =>
              setRecaudo({
                ...getRecaudo,
                recaudo: item,
                value: 1,
              })
            }
          />
          <td>{item.identifier}</td>
          <td>{convertDateToLatinFormat(item.transaction_date)}</td>
          <td>{formatter.format(item?.pending_amount)}</td>
          <td>{formatter.format(item.amount)}</td>
        </tr>,
      );
    });
  }

  const [TableRecaudo, setTableRecaudo] = useState([]);

  const addItemTable = item => {
    let tempSelected = TableRecaudo;
    let target = tempSelected?.find(x => x.id === item.id);
    if (target) {
      tempSelected = tempSelected?.filter(x => x.id !== item.id);
    } else {
      let newChange = {
        ...item,
      };
      tempSelected.push(newChange);
    }
    setTableRecaudo(tempSelected);
    setRecaudo({
      recaudo: '',
      value: '',
    });
  };
  const [renderTableDeposit, valorTotalDeposit] = useMemo(() => {
    let valueTotal = 0;
    let table = [];
    if (Array.isArray(selectedList)) {
      table = TableRecaudo?.map(item => {
        if (item?.pending_amount) {
          valueTotal += parseInt(item?.pending_amount);
        }
        return (
          <tr key={1}>
            <td className='text-center'>
              {item?.deposit_type === 1
                ? 'Transferencia'
                : item?.deposit_type === 2
                  ? 'Cheque'
                  : item?.deposit_type === 3
                    ? 'Consignación'
                    : item?.deposit_type === 4 && 'Tarjeta de Crédito'}
            </td>
            <td className='text-center'>{item.identifier}</td>
            <td className='text-center'>{item.niff_account?.name_bank}</td>
            <td className='text-center'>{convertDateToLatinFormat(item.transaction_date)}</td>
            <td className='text-end col-1' style={{ paddingRight: '1rem' }}>
              {' '}
              {item?.pending_amount ? formatter.format(item?.pending_amount) : ''}{' '}
            </td>
          </tr>
        );
      });
    }
    return [table, valueTotal];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TableRecaudo.length]);

  const footerRecaudo = [
    <td key={1} style={{ color: '#58595B' }}>
      TOTAL
    </td>,
    <td key={2} colSpan='2'></td>,
    <td key={3} className='text-end' colSpan='2' style={{ color: '#005DBF' }}>
      <b>{formatter.format(valorTotalDeposit)}</b>
    </td>,
  ];
  const [showModal, setShowModal] = useState(false);
  const [changeRadius] = useState('20px');

  const closeModalRecaudo = () => {
    setShowModal(false);
    setRecaudo({
      recaudo: '',
      value: '',
    });
  };

  const headerModalAdd = [
    <th key={1}>
      <input
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2}>No. factura</th>,
    <th key={3}>Fecha</th>,
    <th key={4}>Valor pendiente</th>,
    <th key={5}>Valor base</th>,
  ];

  const renderList = e => {
    let tempList = [];
    if (!!e === true) {
      tempList = [];
    } else {
      if (Array.isArray(counter.cashReceipttReducer.results)) {
        counter.cashReceipttReducer.results.forEach(item => {
          if (item.status !== 'closed') {
            tempList.push(
              <tr key={1}>
                <td>
                  <input
                    type='checkbox'
                    checked={isBoxSelected(item.id)}
                    onChange={() => changeBoxSelected(item)}
                  />
                </td>
                <td>{item.sequence}</td>
                <td>{convertDateToLatinFormat(item?.exp_date)}</td>
                <td>{formatter.format(item?.pending_amount)}</td>
                <td>{formatter.format(item?.total_amount)}</td>
              </tr>,
            );
          }
        });
      }
    }
    return tempList;
  };

  const getInvoice = () => {
    if (getCreate.corporate_client) {
      dispatch(
        getInvoices({
          corporate_client: getCreate.corporate_client,
          status: 'partialPaid,open',
          sequence: searchInvoices.search ? searchInvoices.search : '',
          exp_date: searchInvoices.searchDate ? searchInvoices.searchDate : '',
        }),
      );
    }
    setShowModalInvoice(true);
  };

  const headerModal = [
    <th key={1} className='text-center col-1'>
      Factura No.
    </th>,
    <th key={2} className='text-center col-1'>
      Saldo
    </th>,
    <th key={3} className='text-center col-1'>
      Retefuente
    </th>,
    <th key={4} className='text-center col-1'>
      ReteIVA
    </th>,
    <th key={5} className='text-center col-1'>
      ReteICA
    </th>,
    <th key={6} className='text-center col-1'>
      Descuento
    </th>,
    <th key={7} className='text-center col-1'>
      Abono
    </th>,
    <th key={8} className='text-center col-1'>
      Nuevo saldo
    </th>,
    <th key={9} className='text-center col-1'>
      &nbsp;
    </th>,
  ];

  let timeout;
  const timeoutFunction = (time, subfunction) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (subfunction) {
        subfunction();
      }
      clearTimeout(timeout);
    }, time);
  };

  const findValueTotalAbono = () => {
    if (Array.isArray(sumTotalInvoice)) {
      let value = 0;
      sumTotalInvoice.forEach(curr => {
        if (curr.advance_payment) {
          value += curr.advance_payment;
        }
      });
      return value;
    } else {
      return 0;
    }
  };

  const findValueTotal = id => {
    const target = sumTotalInvoice.find(elem => elem.id === id);
    if (target) {
      return (
        target.balance_due -
        (target.tax_rtefte +
          target.tax_rteiva +
          target.tax_rteica +
          target.discount +
          target.advance_payment)
      );
    } else {
      return '0';
    }
  };

  const findValueArray = (id, field) => {
    const target = sumTotalInvoice.findIndex(elem => elem.id === id);
    if (target >= 0) {
      return sumTotalInvoice[field];
    } else {
      return 0;
    }
  };

  const editValueArray = (
    id,
    field,
    value,
    status,
    pending_amount,
    doc_type,
    doc_number,
    doc_date,
  ) => {
    let temp = [...sumTotalInvoice];
    const target = temp.findIndex(elem => elem.id === id);
    if (target >= 0) {
      temp[target][field] = value;
    } else {
      let upcoming = {
        status: status,
        balance_due: pending_amount,
        id: id,
        tax_rtefte: 0,
        tax_rteiva: 0,
        tax_rteica: 0,
        discount: 0,
        advance_payment: 0,

        doc_type: doc_type,
        doc_number: doc_number,
        doc_account: '',
        doc_date: doc_date,
      };
      upcoming[field] = value;
      temp.push(upcoming);
    }
    setSumTotalInvoice(temp);
  };

  let valorTotalSelect = 0;
  if (Array.isArray(selectedList)) {
    selectedList.forEach(x => {
      if (x.total_amount) {
        valorTotalSelect += x.total_amount;
      }
    });
  }

  const renderTable = () => {
    let valor = 0;
    let table = [];
    if (Array.isArray(selectedList)) {
      table = selectedList.map(x => {
        if (x.pending_amount) {
          valor = x.pending_amount + valor;
        }
        return (
          <tr key={x.id + 'row744'}>
            <td className='text-center'>{x.sequence}</td>
            <td className='text-end' style={{ paddingRight: '1rem' }}>
              {x.pending_amount != null ? formatter.format(x.pending_amount) : 0}
            </td>
            <td className='text-center'>
              <NumberFormat
                key={x.id + 'qs4a3'}
                allowNegative={false}
                id='cost_price'
                maxLength='12'
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: `${valuedifer ? '#CECECE' : '#58595b'}`,
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={findValueArray(x.id, 'tax_rtefte')}
                onValueChange={e =>
                  editValueArray(
                    x.id,
                    'tax_rtefte',
                    e.floatValue,
                    x.status,
                    x.pending_amount,
                    1,
                    x.sequence,
                    x.exp_date,
                  )
                }
                disabled={valuedifer}
              />
            </td>
            <td className='text-center'>
              <NumberFormat
                key={x.id + 'qs5545a'}
                allowNegative={false}
                id='cost_price'
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: `${valuedifer ? '#CECECE' : '#58595b'}`,
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={findValueArray(x.id, 'tax_rteiva')}
                onValueChange={e =>
                  editValueArray(
                    x.id,
                    'tax_rteiva',
                    e.floatValue,
                    x.status,
                    x.pending_amount,
                    1,
                    x.sequence,
                    x.exp_date,
                  )
                }
                disabled={valuedifer}
              />
            </td>
            <td className='text-center'>
              <NumberFormat
                key={x.id + 'u4sa'}
                allowNegative={false}
                id='cost_price'
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: `${valuedifer ? '#CECECE' : '#58595b'}`,
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={findValueArray(x.id, 'tax_rteica')}
                onValueChange={e =>
                  editValueArray(
                    x.id,
                    'tax_rteica',
                    e.floatValue,
                    x.status,
                    x.pending_amount,
                    1,
                    x.sequence,
                    x.exp_date,
                  )
                }
                disabled={valuedifer}
              />
            </td>
            <td className='text-center'>
              <NumberFormat
                key={x.id + 'q56a'}
                allowNegative={false}
                id='cost_price'
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: `${valuedifer ? '#CECECE' : '#58595b'}`,
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={findValueArray(x.id, 'discount')}
                onValueChange={e =>
                  editValueArray(
                    x.id,
                    'discount',
                    e.floatValue,
                    x.status,
                    x.pending_amount,
                    1,
                    x.sequence,
                    x.exp_date,
                  )
                }
                disabled={valuedifer}
              />
            </td>
            <td className='text-center'>
              <NumberFormat
                key={x.id + 'qsx'}
                allowNegative={false}
                id='cost_price'
                style={{
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  color: `${valuedifer ? '#CECECE' : '#58595b'}`,
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={findValueArray(x.id, 'advance_payment')}
                onValueChange={e =>
                  timeoutFunction(700, () =>
                    editValueArray(
                      x.id,
                      'advance_payment',
                      e.floatValue,
                      findValueTotal(x.id) - x.pending_amount === 0 ? 1 : 2,
                      x.pending_amount,
                      1,
                      x.sequence,
                      x.exp_date,
                    ),
                  )
                }
                disabled={valuedifer}
              />
            </td>
            <td className='text-end' style={{ paddingRight: '1rem' }}>
              {findValueTotal(x.id) === '0' || isNaN(findValueTotal(x.id))
                ? formatter.format(x.pending_amount != null ? x.pending_amount : 0)
                : formatter.format(findValueTotal(x.id))}
            </td>
            <td className='text-center' style={{ paddingRight: '1rem' }}>
              <div onClick={() => changeBoxSelected(x)}>
                <b style={{ color: '#005DBF' }}>X</b>
              </div>
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const footerModal = [
    <td key={1} style={{ color: '#58595B' }}>
      TOTAL
    </td>,
    <td key={2} colSpan='5'></td>,
    <td key={3} className='text-center' style={{ color: '#01A0F6' }}>
      <b>{formatter.format(findValueTotalAbono())}</b>
    </td>,
  ];
  let valueTotalInvoice = findValueTotalAbono();
  const [valuedifer, setValuedifer] = useState(false);

  let difere = null;
  if (!isNaN(valorTotalDeposit) && !isNaN(valueTotalInvoice)) {
    difere = valorTotalDeposit - valueTotalInvoice;
  }

  const btnAjustModal = () => {
    setShowModalAjust(true);
  };

  const applyAdjustment = () => {
    setValuedifer(true);
    setShowModalAjust(false);
  };

  const postUpdateInvoices = () => {
    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: 'Verifique la información',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        setLoading(true);
        const listWithStatus = sumTotalInvoice.map(x => {
          const newstatus = x.advance_payment - x.balance_due === 0 ? 'closed' : 'open';
          return {
            ...x,
            status: newstatus,
          };
        });

        dispatch(
          update_Invoices(
            {
              eaccount: counter.loginReducer.currentAccount.id,
              id_sup_cli: getCreate.corporate_client, //getRecaudo?.recaudo?.corporate_client?.id,
              sup_cli_name: getCreate.copyName, //getRecaudo?.recaudo?.corporate_client?.copyName,
              collections_ack: {
                coporate_client: getCreate.corporate_client,
                details: getCreate.deteli,
                adjustment_destination: valorTotalDeposit - valueTotalInvoice,
                created_by: counter.loginReducer.user_data.id,
                receipt_date: formatDateTwo(date),
                niffAccount: getCreate.niff_account,
                adjustment_account: getCreate.niff_accountAjusment,
                total_amount: findValueTotalAbono(),
              },
              corp_deposit: TableRecaudo,
              invoices: listWithStatus,
            },
            () => resetStatus(),
            () => setLoading(false),
          ),
        );
      }
    });
  };

  const resetStatus = () => {
    setCreate({ deteli: '', niff_account: 0 });
    setRecaudo({ recaudo: '', value: '' });
    renderList(1);
    setTrigger(trigger + 1);
    setTrigger2(trigger2 + 1);
    setTrigger3(trigger3 + 1);
    setTableRecaudo([]);
    setselectedList([]);
    setSumTotalInvoice([]);
    setShowModalAjust(false);
    setValuedifer(false);

    dispatch({
      type: GET_CHECK_DEPOSIT_BANK,
      payload: [],
    });
    dispatch({
      type: GET_ONE_DEPOSIT_BANK,
      payload: [],
    });
    dispatch({
      type: GET_INVOICES,
      payload: [],
    });
  };
  const resetChangesSelect = () => {
    setCreate({ wwe: 0 });
    setRecaudo({ recaudo: '', value: '' });
    setTrigger2(trigger2 + 1);
    renderList(1);
    setTableRecaudo([]);
    setselectedList([]);
    setSumTotalInvoice([]);
    dispatch({
      type: GET_ONE_DEPOSIT_BANK,
      payload: [],
    });
    dispatch({
      type: GET_INVOICES,
      payload: [],
    });
  };
  const resetChangesSelect2 = () => {
    setRecaudo({ recaudo: '', value: '' });
    renderList(1);
    setTableRecaudo([]);
    setselectedList([]);
    setSumTotalInvoice([]);
    dispatch({
      type: GET_INVOICES,
      payload: [],
    });
  };
  const WhiteTooltip = withStyles({
    tooltip: {
      color: 'rgba(88, 89, 91, 1)',
      backgroundColor: '#FFF',
      boxShadow: '0px 1px 4px rgba(0, 63, 128, 0.35);',
      fontSize: '12px',
      position: 'relative',
    },
    arrow: {
      color: '#FFF',
      '&::before': {
        backgroundColor: '#FFF',
        border: '2px solid #FFF',
        boxShadow: '0px 0px 2px rgba(0, 63, 128, 0.35);',
        position: 'absolute',
      },
    },
  })(Tooltip);
  return (
    <>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={boxReceiptStyles.centeredContainer}>
        <div className={tableStyles.ItemSmall} style={{ marginTop: '30px' }}>
          <Title
            title='Recibo de caja de clientes'
            className={'mb-2'}
            onClickIcon={() => {
              history.push('/tesoreria/inicio');
            }}
          />
        </div>
        <div className={`${tableStyles.container1} mb-4`}>
          <div>
            {/* ---------------------------- ROW 1 DE INPUT ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%', marginLeft: '0px' }}>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Cuenta contable*</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'Cuenta contable' + trigger}
                  styles={customSelectNewDark}
                  options={optionNiifAccounts}
                  placeholder='Seleccionar...'
                  onChange={e => getAmountNiffAccount(e)}
                />
              </Col>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Saldo a la fecha</p>
                <input
                  key={'Saldo a la fecha'}
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  type='text'
                  defaultValue={''}
                  value={
                    counter.depositBankReducer.deposits_total_peding > 0
                      ? formatter.format(counter.depositBankReducer.deposits_total_peding)
                      : ''
                  }
                  disabled
                />
              </Col>
              <Col xs={3} style={{ paddingRight: '0px' }}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  name='requisition_date'
                  type='text'
                  value={formatDate(date)}
                  disabled
                />
              </Col>
            </Row>
            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%', marginLeft: '0px' }}>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Cuenta Cliente*</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'cuenta cliente' + trigger2}
                  styles={customSelectNewDark}
                  placeholder='Seleccionar...'
                  options={optionClients}
                  isDisabled={
                    getCreate.niff_account === 0 || !!getCreate.niff_account === false
                      ? true
                      : false
                  }
                  onChange={e => getAmountClient(e)}
                />
              </Col>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Pendiente por legalizar</p>
                <input
                  key={'pendding' + trigger2}
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  type='text'
                  value={
                    !isNaN(counter.depositBankReducer.deposits_total_peding_one)
                      ? formatter.format(counter.depositBankReducer.deposits_total_peding_one)
                      : ''
                  }
                  disabled
                />
              </Col>

              <Col xs={4} style={{ paddingRight: '0px' }}>
                <p className={tableStyles.crudModalLabel}>Detalle*</p>
                <input
                  placeholder='Escribir...'
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  type='text'
                  value={getCreate.deteli}
                  onChange={e =>
                    setCreate({
                      ...getCreate,
                      deteli: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </div>
        </div>
        <SlideDownButton
          bgBody={'#005DBF10'}
          width={'100%'}
          body={
            <>
              <ModalNew
                show={showModal}
                onHide={() => closeModalRecaudo()}
                title={'Consignaciones pendientes '}
                btnYesEvent={() => setShowModal(false)}
                btnYesDisabled={getRecaudo.recaudo === '' ? true : false}
                btnYesName={'Seleccionar'}
                size={'750'}
              >
                <Row className='d-flex'>
                  <Col xs={4}>
                    <label className={tableStyles.crudModalLabel}>Tipo de consignación</label>
                    <Select
                      placeholder='Seleccionar...'
                      styles={customSelectNewDark}
                      value={
                        getSearchRecaudo.deposit_type
                          ? {
                              label: getSearchRecaudo?.deposit_typeLabel,
                              value: getSearchRecaudo?.deposit_type,
                            }
                          : ''
                      }
                      options={[
                        { label: 'Seleccionar...', value: '' },
                        { label: 'Transferencia', value: '1' },
                        { label: 'Cheque', value: '2' },
                        { label: 'Consignación', value: '3' },
                        { label: 'Tarjetas', value: '4' },
                      ]}
                      onChange={({ value, label }) =>
                        setSearchRecaudo({
                          ...getSearchRecaudo,
                          deposit_type: Number(value),
                          deposit_typeLabel: label,
                        })
                      }
                    />
                  </Col>
                  <Col xs={3} className='p-0'>
                    <label className={tableStyles.crudModalLabel}>Consec. / cheque</label>
                    <input
                      placeholder='Escribir...'
                      className={IndividualStyles.registerInputsGris}
                      type='text'
                      value={getSearchRecaudo.identifier}
                      onChange={e =>
                        setSearchRecaudo({
                          ...getSearchRecaudo,
                          identifier: e.target.value,
                        })
                      }
                    ></input>
                  </Col>
                  <Col xs={4}>
                    <label className={tableStyles.crudModalLabel}>Fecha</label>
                    <input
                      className={IndividualStyles.registerInputsBlue}
                      type='date'
                      value={getSearchRecaudo.transaction_date}
                      onChange={e =>
                        setSearchRecaudo({
                          ...getSearchRecaudo,
                          transaction_date: e.target.value,
                        })
                      }
                    ></input>
                  </Col>
                  <Col xs={1}>
                    <i>
                      <img
                        alt=''
                        style={{ width: '25px', marginTop: '2rem' }}
                        src={Lupa}
                        onClick={() =>
                          getCreate?.niff_account !== 0 && !!getCreate.corporate_client
                            ? getAmountClient()
                            : ''
                        }
                      />
                    </i>
                  </Col>
                </Row>
                <GenericTable headers={header}>{bodyConsig}</GenericTable>
              </ModalNew>
              <Row>
                {/* ---------------------------- ROW 1 ---------------------------------- */}
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Consignación</p>
                  <div
                    key={'consig' + trigger}
                    style={{ cursor: 'pointer' }}
                    className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.backgroundImage}`}
                    onClick={() => {
                      setShowModal(true);
                      getAmountClient();
                    }}
                  >
                    {getRecaudo?.recaudo?.identifier}
                  </div>
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Banco</p>
                  <input
                    key={'BANCO' + trigger}
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    value={
                      getRecaudo.value === '' ? '' : getRecaudo.recaudo?.niff_account?.name_bank
                    }
                    disabled
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Fecha</p>
                  <input
                    className={IndividualStyles.registerInputsBlue}
                    name='requisition_date'
                    key={'date' + trigger}
                    type='date'
                    value={
                      getRecaudo.value === ''
                        ? getRecaudo.value
                        : getRecaudo.recaudo?.transaction_date
                    }
                    disabled
                  />
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Valor recibido</p>
                  <NumberFormat
                    placeholder='Escribir...'
                    allowNegative={false}
                    className={IndividualStyles.registerInputsGris}
                    name='valueRecieved'
                    thousandSeparator={','}
                    decimalSeparator={'.'}
                    isNumericString={true}
                    prefix={'$'}
                    value={
                      getRecaudo.value === ''
                        ? getRecaudo.value
                        : getRecaudo.recaudo?.pending_amount
                    }
                    onValueChange={({ value }) =>
                      setRecaudo({
                        ...getRecaudo,
                        recaudo: {
                          ...getRecaudo.recaudo,
                          pending_amount: value,
                        },
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                {/* ---------------------------- ROW 2 ---------------------------------- */}
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Moneda</p>
                  <input
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    disabled
                  />
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>TRM</p>
                  <input
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    disabled
                  />
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Saldo en moneda</p>
                  <input
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    disabled
                  />
                </Col>
                <Col xs={3}>
                  <p className={tableStyles.crudModalLabel}>Saldo a FAVOR</p>
                  <input
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    disabled
                  />
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Saldo en CONTRA</p>
                  <input
                    className={IndividualStyles.registerInputsGris}
                    name='requisition_date'
                    type='text'
                    disabled
                  />
                </Col>
                <Col xs={1}>
                  <div style={{ marginTop: '25px' }}>
                    <WhiteTooltip title={'Agregar recaudo'} placement='top' arrow>
                      <img
                        src={Descarga}
                        alt=''
                        srcSet=''
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          getRecaudo?.recaudo ? addItemTable(getRecaudo?.recaudo) : ''
                        }
                      />
                    </WhiteTooltip>
                  </div>
                </Col>
              </Row>
            </>
          }
          title='ㅤ+ Agregar recaudo'
        />

        <GenericTable headers={headerRecaudo} footer={footerRecaudo} dark={true}>
          {renderTableDeposit}
        </GenericTable>

        <ModalNew
          show={showModalInvoice}
          onHide={() => setShowModalInvoice(false)}
          title={'Facturas pendientes'}
          subtitle={' '}
          btnYesEvent={() => setShowModalInvoice(false)}
          btnYesName={'Seleccionar'}
          size={'620'}
        >
          <Row className='d-flex'>
            <Col>
              <label className={tableStyles.crudModalLabel}>Consec. / cheque</label>
              <input
                placeholder='Escribir...'
                className={IndividualStyles.registerInputsGris}
                type='text'
                onChange={e =>
                  setSearchInvoices({
                    ...searchInvoices,
                    search: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col>
              <label className={tableStyles.crudModalLabel}>Fecha</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                onChange={e =>
                  setSearchInvoices({
                    ...searchInvoices,
                    searchDate: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col>
              <label className={tableStyles.crudModalLabel}>&nbsp;</label>
              <div>
                <img alt='' style={{ width: '25px' }} src={Lupa} onClick={() => getInvoice()} />
              </div>
            </Col>
          </Row>
          <GenericTable headers={headerModalAdd}>{renderList()}</GenericTable>
          <Row className='d-flex'>
            <Col xs={4}>
              <label className={tableStyles.crudModalLabel}>Total seleccionado</label>
              <NumberFormat
                allowNegative={false}
                id='cost_price'
                className={IndividualStyles.registerInputsBlue}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={valorTotalSelect}
                disabled={true}
              />
            </Col>
          </Row>
        </ModalNew>
        <ModalNew
          show={showModalAjust}
          onHide={() => {
            setShowModalAjust(false);
            setValuedifer(false);
            setCreate({
              ...getCreate,
              niff_accountAjusment: null,
            });
          }}
          title={'Ajustes'}
          subtitle={' '}
          btnYesEvent={() => applyAdjustment()}
          btnYesName={'Aplicar'}
          size={'450'}
        >
          <Row className='d-flex'>
            <Col>
              <label className={tableStyles.crudModalLabel}>Valor total de recaudo</label>
              <NumberFormat
                allowNegative={false}
                id='cost_price'
                className={IndividualStyles.registerInputsBlue}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={valorTotalDeposit}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className='d-flex'>
            <Col>
              <label className={tableStyles.crudModalLabel}>Valor total de facturas</label>
              <NumberFormat
                allowNegative={false}
                id='cost_price'
                className={IndividualStyles.registerInputsBlue}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={valueTotalInvoice}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className='d-flex'>
            <Col>
              <label className={tableStyles.crudModalLabel}>Diferencia</label>
              <NumberFormat
                allowNegative={false}
                style={{ fontWeight: 'bold' }}
                id='cost_price'
                className={IndividualStyles.registerInputsGris}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={difere}
                disabled={true}
              />
            </Col>
          </Row>
          <label className={`${tableStyles.crudModalLabel}`} style={{ marginTop: '1rem' }}>
            <b style={{ fontSize: '14px', marginTop: '10px' }}>Enviar direfencia a:</b>
          </label>
          <Row className='d-flex'>
            <Col>
              <p className={tableStyles.crudModalLabel}>Cuenta contable*</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder=''
                styles={customSelectNewDark}
                options={NiifAccountsModal}
                value={NiifAccountsModal.find(x => x.value === getCreate.niff_accountAjusment)}
                onChange={e =>
                  setCreate({
                    ...getCreate,
                    niff_accountAjusment: e.value,
                  })
                }
              />
            </Col>
          </Row>
        </ModalNew>
        <div>
          <button
            onClick={() => getInvoice()}
            className='text-start'
            style={{
              color: '#01A0F6',
              backgroundColor: '#F2F7FC',
              borderRadius: `20px 20px ${changeRadius} ${changeRadius}`,
              padding: '10px',
              width: '100%',
            }}
          >
            <b>+ Agregar factura</b>
          </button>
        </div>

        <div>
          <GenericTable
            headers={headerModal}
            // children={bodyModal}
            footer={footerModal}
          >
            {renderTable()}
          </GenericTable>
          <Row className='mb-5 mt-5'>
            <Col>
              <label className={tableStyles.crudModalLabelClear}>Diferencia</label>
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <input
                  className='register-inputs '
                  style={{ width: 'max-content', fontWeight: 'bold' }}
                  type='text'
                  placeholder=''
                  value={valuedifer ? formatter.format(0) : formatter.format(difere)}
                  disabled
                />

                {!isNaN(difere) && difere !== 0 && (
                  <img
                    alt=''
                    src={GearGray}
                    onClick={() => btnAjustModal()}
                    style={{ width: '25px', marginLeft: '10px' }}
                  />
                )}
              </div>

              {valuedifer && (
                <p style={{ fontSize: 10, color: '#CECECE' }}>
                  * la diferencia de {difere} se enviara a una cuenta contable
                </p>
              )}
            </Col>

            <Col className='d-block text-end mt-4'>
              <button
                className={`${boxReceiptStyles.btnSecondary} mr-3`}
                onClick={() => resetStatus()}
              >
                Cancelar
              </button>
              <button
                className={boxReceiptStyles.btnPrimary}
                disabled={
                  !isNaN(difere) && difere === 0
                    ? selectedList.length <= 0 || TableRecaudo.length <= 0
                    : !valuedifer
                }
                onClick={() => postUpdateInvoices()}
              >
                Guardar
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default CashReceipt;
