import { useEffect, useMemo, useState } from 'react';
import AddBlue from '../../assets/img/icons/AddBlue.svg';
import alertDarkBlue from '../../assets/img/icons/alertDarkBlue.svg';
import blueCheck from '../../assets/img/icons/blueCheck.svg';
import Menos from '../../assets/img/icons/Menos.svg';
import Select from 'react-select';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Accordion, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllconfigParams } from '../../actions/accountingActions';
import Loader from 'react-loader-spinner';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { getAllConCenter } from '../../actions/consumptionCenterActions';
import {
  formatToRcSelect,
  getPermission,
  isEmptyOrUndefined,
  message,
} from '../../helpers/helpers';
import { costcenter_get } from '../../actions/costcenterActions';
import { getNiifAccounts } from '../../actions/pucActions';
import { getIF } from '../../actions/inventoryFamilyActions';
import { removeParam, sendEachParam } from '../../actions/movementsActions';
import { customSwaltAlert } from '../../helpers';

export const ConfigMovesNew = () => {
  const store = useSelector(state => state);
  const paramsList = store?.accountingReducer?.paramConfigList?.results;
  const [trigger, setTrigger] = useState(0);
  const [updateSelect, setUpdateSelect] = useState(0);
  const CCList = store?.consumptionCenterReducer?.consumption_center;
  const CCost = store?.costcenterReducer?.cc;
  const invFamList = store?.inventoryFamilyReducer?.inventoryFamily;
  const dispatch = useDispatch();
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isLoading, setIsLoading] = useState([]);
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'DefiMov', listPermission });
  useEffect(() => {
    dispatch(getAllconfigParams({ eaccount: idEnterprise }));
    dispatch(getAllConCenter({ eaccount: idEnterprise }));
    dispatch(costcenter_get({ eaccount: idEnterprise, active: 1 }));
    dispatch(
      getNiifAccounts({
        eaccount: idEnterprise,
      }),
    );
    dispatch(getIF({ eaccount: idEnterprise }));
  }, [dispatch, idEnterprise]);

  let formattedNiifAcc = [{ value: '', label: 'Seleccionar...' }];
  if (Array.isArray(store.pucReducer.niifAccounts)) {
    store.pucReducer.niifAccounts.forEach(item => {
      formattedNiifAcc.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }
  const formattedCCList = formatToRcSelect(CCList, 'id', 'description');
  const formattedInvFam = formatToRcSelect(invFamList, 'id', 'description');
  const [inputs, setInputs] = useState([]);
  const token = store.loginReducer.Authorization;

  const listCCost = useMemo(()=>(
    [
      { value: '', label: 'Seleccionar...' },
      ...(CCost?.map(cost => ({
        ...cost,
        value: cost.id,
        label: `${cost.code} - ${cost.description}`
      })) ?? [])
    ]
  ),[CCost])

  /* --------------------------- DELETE ROW ON EACH -------------------------- */
  const removeRow = async e => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Este parametro será eliminado de forma permanente',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        const send = async () => {
          try {
            const res = await removeParam(
              {
                id: e.id,
                eaccount: idEnterprise,
              },
              token,
            );
            if (res?.success) {
              dispatch(getAllconfigParams({ eaccount: idEnterprise }, false));
              customSwaltAlert({
                icon: 'success',
                title: 'El parametro ha sido eliminado correctamente',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            } else {
              return message('error', 'Parametro no eliminado', res.message);
            }
          } catch (error) {
            return message('error', 'error', error.message);
          }
        };
        send();
      }
    });
  };
  /* --------------------------- ADD NEW ROW ON EACH -------------------------- */
  const addRow = async (item, i, pIndex, ePrincipal) => {
    const changeLoading = bool => {
      let tempIsLoading = [...isLoading];
      let rowSelected = isLoading.findIndex(x => x.id === pIndex + '_' + i);
      if (rowSelected >= 0) {
        tempIsLoading[rowSelected] = {
          ...tempIsLoading[rowSelected],
          isLoading: bool,
        };
      } else {
        tempIsLoading.push({
          id: pIndex + '_' + i,
          isLoading: bool,
        });
      }

      setIsLoading(tempIsLoading);
    };
    setTrigger(trigger + 1);
    let found = inputs.find(x => x.id === pIndex + '_' + i);
    if (item.type === 'account') {
      if (isEmptyOrUndefined(found?.niif?.value)) {
        return message('info', 'Campos obligatorios', 'El campo Cuenta NIIF es obligatorio');
      }
      try {
        changeLoading(true);
        const res = await sendEachParam(
          {
            concept: item.concept,
            transacName: ePrincipal.transacName,
            eaccount: idEnterprise,
            niifId: found?.niif?.value,
          },
          token,
        );
        if (res?.success) {
          dispatch(getAllconfigParams({ eaccount: idEnterprise }, false));
          changeLoading(false);
        } else {
          changeLoading(false);
          return message('warning', 'Parametro no configurado', res.message);
        }
      } catch (error) {
        changeLoading(false);
        return message('error', 'error', error.message);
      }
    }
    if (item.type === 'cc') {
      if (isEmptyOrUndefined(found?.niif?.value)) {
        return message('info', 'Campos obligatorios', 'El campo Cuenta NIIF es obligatorio');
      }
      if (isEmptyOrUndefined(found?.consumCenter?.value)) {
        return message('info', 'Campos obligatorios', 'El campo Centro de consumo es obligatorio');
      }

      try {
        changeLoading(true);
        const res = await sendEachParam(
          {
            concept: item.concept,
            transacName: ePrincipal.transacName,
            eaccount: idEnterprise,
            niifId: found?.niif?.value,
            costCenterId: found?.costCenter?.value,
            consumCenterId: found?.consumCenter?.value,
          },
          token,
        );

        if (res?.success) {
          dispatch(getAllconfigParams({ eaccount: idEnterprise }, false));
          changeLoading(false);
        } else {
          changeLoading(false);
          return message('warning', 'Parametro no configurado', res.message);
        }
      } catch (error) {
        changeLoading(false);
        return message('error', 'error', error.message);
      }
    }
    if (item.type === 'invFamily') {
      if (isEmptyOrUndefined(found?.niif?.value)) {
        return message('info', 'Campos obligatorios', 'El campo Cuenta NIIF es obligatorio');
      }
      if (isEmptyOrUndefined(found?.invFamily?.value)) {
        return message(
          'info',
          'Campos obligatorios',
          'El campo familia de inventario es obligatorio',
        );
      }

      try {
        changeLoading(true);
        const res = await sendEachParam(
          {
            concept: item.concept,
            transacName: ePrincipal.transacName,
            eaccount: idEnterprise,
            niifId: found?.niif?.value,
            invFamilyId: found?.invFamily?.value,
          },
          token,
        );
        if (res?.success) {
          dispatch(getAllconfigParams({ eaccount: idEnterprise }, false));
          changeLoading(false);
        } else {
          changeLoading(false);
          return message('warning', 'Parametro no configurado', res.message);
        }
      } catch (error) {
        changeLoading(false);
        return message('error', 'error', error.message);
      }
    }
    if (item.type === 'invFam&cc') {
      if (isEmptyOrUndefined(found?.niif?.value)) {
        return message('info', 'Campos obligatorios', 'El campo Cuenta NIIF es obligatorio');
      }
      if (isEmptyOrUndefined(found?.invFamily?.value)) {
        return message(
          'info',
          'Campos obligatorios',
          'El campo familia de inventario es obligatorio',
        );
      }

      if (isEmptyOrUndefined(found?.consumCenter?.value)) {
        return message('info', 'Campos obligatorios', 'El campo centro de consumo es obligatorio');
      }

      try {
        changeLoading(true);
        const res = await sendEachParam(
          {
            concept: item.concept,
            transacName: ePrincipal.transacName,
            eaccount: idEnterprise,
            niifId: found?.niif?.value,
            invFamilyId: found?.invFamily?.value,
            costCenterId: found?.costCenter?.value,
            consumCenterId: found?.consumCenter?.value,
          },
          token,
        );
        if (res?.success) {
          dispatch(getAllconfigParams({ eaccount: idEnterprise }, false));
          changeLoading(false);
        } else {
          changeLoading(false);
          return message('warning', 'Parametro no configurado', res.message);
        }
      } catch (error) {
        changeLoading(false);
        return message('error', 'error', error.message);
      }
    }
  };

  return (
    <>
      {store?.accountingReducer?.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={`${tableStyles.bgColorWhite} w-100 p-2 container `}>
        <Row>
          <Col xs={10}>
            <h1 className={`px-3 ${tableStyles.title}`}>Definición de movimientos</h1>
          </Col>
        </Row>

        {paramsList?.length > 0 &&
          paramsList.map((ePrincipal, pIndex) => {
            return (
              <Accordion key={pIndex} className='w-100 m-3'>
                <div className='w-100 ml-1'>
                  <Accordion.Toggle eventKey={2} className={`bg-light w-100 accordionToggleButton`}>
                    <div
                      style={{
                        backgroundColor: '#ffffff00',
                        borderRadius: '12px',
                      }}
                      className={`d-flex`}
                    >
                      <div
                        style={{
                          width: '100%',
                          marginTop: '-8px',
                          'margin-left': '',
                          'border-radius': '10px ',
                        }}
                        className={`${tableStyles.bgColorClearBlue}`}
                      >
                        <p
                          className={`${tableStyles.darkBlueText} m-1`}
                          style={{
                            'text-align': 'left',
                            padding: '0 0 0 5px',
                          }}
                        >
                          <h5>
                            <b>
                              <code className={`${tableStyles.darkBlueText}`}>{`>`}</code>
                              &nbsp; {ePrincipal.name}
                            </b>
                          </h5>
                        </p>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={2}>
                    <Card.Body
                      className={`${tableStyles.bgColorClearBlue} mb-3`}
                      style={{
                        width: '100%',
                        borderRadius: '0 0 10px 10px',
                      }}
                    >
                      <Col className={`${tableStyles.darkGrayText} bg-light rounded`} xs={11}>
                        <h6>
                          <img alt='' src={alertDarkBlue}></img>
                          &nbsp;
                          <b>{ePrincipal.comment}</b>
                        </h6>
                      </Col>
                      {ePrincipal?.params?.length > 0 &&
                        ePrincipal.params.map((e, i) => {
                          return (
                            <>
                              {/* /* ----------------------------- TITLES SECTION ----------------------------- */}
                              <Row className='mt-2'>
                                <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                                  <h6>
                                    <b>Concepto: &nbsp;&nbsp;{e.label}</b>
                                  </h6>
                                </Col>
                                <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                                  <h6>
                                    <b>Naturaleza: &nbsp;&nbsp;{e.nature}</b>
                                  </h6>
                                </Col>
                              </Row>

                              {/* /* -----------------------------CONDITIONAL SELECT SECTION ----------------------------- */}
                              <Row>
                                {e.type === 'account' ? (
                                  <>
                                    {/* <h5 className={tableStyles.darkBlueText}>
                                      <b>account</b>
                                    </h5> */}
                                    {/* /* ---------------------------- SOLO CUENTA
                                    NIIF ---------------------------- */}
                                    <Col xs={11}>
                                      <p className={`${tableStyles.crudModalLabel} `}>
                                        Cuenta NIIF
                                        <span className={'text-danger'}>*</span>
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        options={formattedNiifAcc}
                                        key={'Account' + trigger + '_' + updateSelect}
                                        className='text-secondary'
                                        value={
                                          inputs.find(x => x.id === pIndex + '_' + i) === undefined
                                            ? e.detail?.length > 0 &&
                                              formattedNiifAcc.find(
                                                x => x.value === e.detail[0].niifId,
                                              )
                                            : inputs.find(x => x.id === pIndex + '_' + i)?.niif
                                        }
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              niif: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              niif: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                          setUpdateSelect(updateSelect + 1);
                                        }}
                                        placeholder={'Seleccionar...'}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={1} className='text-end'>
                                      {isLoading.find(x => x.id === pIndex + '_' + i)?.isLoading ? (
                                        <Spinner
                                          className='mt-4'
                                          animation='border'
                                          variant='info'
                                        />
                                      ) : (
                                        <>
                                          {myPermission?.edit && (
                                            <img
                                              alt='edit'
                                              className='mt-4 cursorPointer'
                                              key={'invFamily' + trigger}
                                              onClick={() => addRow(e, i, pIndex, ePrincipal)}
                                              width='30px'
                                              src={blueCheck}
                                            ></img>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </>
                                ) : e.type === 'invFamily' ? (
                                  /* -------------------- NIIF, invFamily, botón + y Tabla -------------------- */
                                  <>
                                    <Col xs={5}>
                                      <p className={`${tableStyles.crudModalLabel}`}>
                                        Cuenta NIIF
                                        <span className={'text-danger'}>*</span>
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        options={formattedNiifAcc}
                                        className='text-secondary'
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              niif: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              niif: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                        }}
                                        placeholder={'Seleccionar...'}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={6}>
                                      <p className={`${tableStyles.crudModalLabel} `}>
                                        Familia de inventario
                                        <span className={'text-danger'}>*</span>
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        options={formattedInvFam}
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              invFamily: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              invFamily: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                        }}
                                        className='text-secondary'
                                        placeholder={'Seleccionar...'}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={1} className='text-end'>
                                      {isLoading.find(x => x.id === pIndex + '_' + i)?.isLoading ? (
                                        <Spinner
                                          className='mt-4'
                                          animation='border'
                                          variant='info'
                                        />
                                      ) : (
                                        <>
                                          {myPermission?.create && (
                                            <img
                                              alt='agregar'
                                              className='mt-4 cursorPointer'
                                              key={'invFamily' + trigger}
                                              onClick={() => addRow(e, i, pIndex, ePrincipal)}
                                              width='30px'
                                              src={AddBlue}
                                            ></img>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                    {/* <h5 className={tableStyles.darkBlueText}>
                                      <b>invFamily</b>
                                    </h5> */}
                                    <Col
                                      xs={12}
                                      className={`mt-2 ${tableStyles.scrollThis}`}
                                      style={{ height: '27vh' }}
                                    >
                                      <Row className=''>
                                        <Col xs={12}>
                                          <table
                                            className={tableStyles.clearGrayBorder}
                                            style={{
                                              fontFamily: ' PT Sans ',
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                >
                                                  Cuenta NIIF
                                                </th>
                                                <th
                                                  className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                >
                                                  Familia de inventario
                                                </th>

                                                <th
                                                  className={`col-md-1 ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                  style={{ width: '1%' }}
                                                >
                                                  &nbsp;
                                                </th>
                                              </tr>
                                            </thead>
                                            {myPermission?.read && (
                                              <tbody>
                                                {e.detail?.length > 0 &&
                                                  e.detail?.map((e, i) => {
                                                    return (
                                                      <tr key={i}>
                                                        <th
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                        >
                                                          {e.niif}
                                                        </th>
                                                        <td
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                        >
                                                          {e.invFamily}
                                                        </td>
                                                        <td
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder} cursorPointer col-1`}
                                                          onClick={() => removeRow(e)}
                                                          style={{
                                                            width: '1%',
                                                          }}
                                                        >
                                                          <img
                                                            alt=''
                                                            className='mt-4 cursorPointer'
                                                            key={'invFamily' + trigger}
                                                            onClick={() => removeRow(e)}
                                                            width='20px'
                                                            src={Menos}
                                                          ></img>
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </tbody>
                                            )}
                                          </table>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                ) : e.type === 'cc' ? (
                                  /* -------------------- "NIIf, c Cost, c cons,  +, Tabla" ------------------- */
                                  <>
                                    <Col xs={4}>
                                      <p className={`${tableStyles.crudModalLabel} `}>
                                        Cuenta NIIF
                                        <span className={'text-danger'}>*</span>
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              niif: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              niif: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                        }}
                                        options={formattedNiifAcc}
                                        className='text-secondary'
                                        placeholder={'Seleccionar...'}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={4}>
                                      <p className={`${tableStyles.crudModalLabel} `}>
                                        Centro de consumo
                                        <span className={'text-danger'}>*</span>
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              consumCenter: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              consumCenter: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                        }}
                                        className='text-secondary'
                                        placeholder={'Seleccionar...'}
                                        options={formattedCCList}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={3}>
                                      <p className={`${tableStyles.crudModalLabel} `}>
                                        Centro de costo
                                      </p>
                                      <Select
                                        noOptionsMessage={() => 'No hay datos'}
                                        onChange={event => {
                                          let tempInputs = [...inputs];
                                          let rowSelected = inputs.findIndex(
                                            x => x.id === pIndex + '_' + i,
                                          );
                                          if (rowSelected >= 0) {
                                            tempInputs[rowSelected] = {
                                              ...tempInputs[rowSelected],
                                              costCenter: event,
                                            };
                                          } else {
                                            tempInputs.push({
                                              id: pIndex + '_' + i,
                                              costCenter: event,
                                            });
                                          }

                                          setInputs(tempInputs);
                                        }}
                                        options={listCCost}
                                        className='text-secondary'
                                        placeholder={'Seleccionar...'}
                                        styles={customSelectNewDark}
                                      ></Select>
                                    </Col>
                                    <Col xs={1} className='text-end'>
                                      {isLoading.find(x => x.id === pIndex + '_' + i)?.isLoading ? (
                                        <Spinner
                                          className='mt-4'
                                          animation='border'
                                          variant='info'
                                        />
                                      ) : (
                                        <>
                                          {myPermission?.create && (
                                            <img
                                              alt='add'
                                              className='mt-4 cursorPointer'
                                              key={'invFamily' + trigger}
                                              onClick={() => addRow(e, i, pIndex, ePrincipal)}
                                              width='30px'
                                              src={AddBlue}
                                            ></img>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                    {/* <h5 className={tableStyles.darkBlueText}>
                                      <b>CC</b>
                                    </h5> */}
                                    <Col
                                      xs={12}
                                      className={`mt-2 ${tableStyles.scrollThis}`}
                                      style={{ height: '27vh' }}
                                    >
                                      <Row className=''>
                                        <Col xs={12}>
                                          <table
                                            className={tableStyles.clearGrayBorder}
                                            style={{
                                              fontFamily: ' PT Sans ',
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                >
                                                  Cuenta NIIF
                                                </th>
                                                <th
                                                  className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                >
                                                  Centro de consumo
                                                </th>
                                                <th
                                                  className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  scope='col'
                                                >
                                                  Centro de costo
                                                </th>
                                                <th
                                                  className={`col-md-1 ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                  style={{ width: '1%' }}
                                                  scope='col'
                                                >
                                                  &nbsp;
                                                </th>
                                              </tr>
                                            </thead>
                                            {myPermission?.read && (
                                              <tbody>
                                                {e.detail?.length > 0 &&
                                                  e.detail?.map((e, i) => {
                                                    return (
                                                      <tr key={i}>
                                                        <th
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                        >
                                                          {e.niif}
                                                        </th>
                                                        <td
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                        >
                                                          {e.consumCenter || '-'}
                                                        </td>
                                                        <td
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                        >
                                                          {e.costCenter || '-'}
                                                        </td>
                                                        <td
                                                          className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder} cursorPointer col-1`}
                                                          onClick={() => removeRow(e)}
                                                          style={{
                                                            width: '1%',
                                                          }}
                                                        >
                                                          <img
                                                            alt=''
                                                            className='mt-4 cursorPointer'
                                                            key={'cc' + trigger}
                                                            onClick={() => removeRow(e)}
                                                            width='20px'
                                                            src={Menos}
                                                          ></img>
                                                        </td>
                                                      </tr>
                                                    );
                                                  })}
                                              </tbody>
                                            )}
                                          </table>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </>
                                ) : (
                                  e.type === 'invFam&cc' && (
                                    /* ---------------- "consum, c cost, inv fam, niif, tabla, +" --------------- */
                                    <>
                                      {/* invFamilyAndCC */}
                                      <Col xs={3}>
                                        <p className={`${tableStyles.crudModalLabel} `}>
                                          Cuenta NIIF
                                          <span className={'text-danger'}>*</span>
                                        </p>
                                        <Select
                                          noOptionsMessage={() => 'No hay datos'}
                                          onChange={event => {
                                            let tempInputs = [...inputs];
                                            let rowSelected = inputs.findIndex(
                                              x => x.id === pIndex + '_' + i,
                                            );
                                            if (rowSelected >= 0) {
                                              tempInputs[rowSelected] = {
                                                ...tempInputs[rowSelected],
                                                niif: event,
                                              };
                                            } else {
                                              tempInputs.push({
                                                id: pIndex + '_' + i,
                                                niif: event,
                                              });
                                            }

                                            setInputs(tempInputs);
                                          }}
                                          options={formattedNiifAcc}
                                          className='text-secondary'
                                          placeholder={'Seleccionar...'}
                                          styles={customSelectNewDark}
                                        ></Select>
                                      </Col>
                                      <Col xs={3}>
                                        <p className={`${tableStyles.crudModalLabel} `}>
                                          Familia de inventario
                                          <span className={'text-danger'}>*</span>
                                        </p>
                                        <Select
                                          noOptionsMessage={() => 'No hay datos'}
                                          onChange={event => {
                                            let tempInputs = [...inputs];
                                            let rowSelected = inputs.findIndex(
                                              x => x.id === pIndex + '_' + i,
                                            );
                                            if (rowSelected >= 0) {
                                              tempInputs[rowSelected] = {
                                                ...tempInputs[rowSelected],
                                                invFamily: event,
                                              };
                                            } else {
                                              tempInputs.push({
                                                id: pIndex + '_' + i,
                                                invFamily: event,
                                              });
                                            }

                                            setInputs(tempInputs);
                                          }}
                                          options={formattedInvFam}
                                          className='text-secondary'
                                          placeholder={'Seleccionar...'}
                                          styles={customSelectNewDark}
                                        ></Select>
                                      </Col>
                                      <Col xs={3}>
                                        <p className={`${tableStyles.crudModalLabel} `}>
                                          Centro de consumo
                                          <span className={'text-danger'}>*</span>
                                        </p>
                                        <Select
                                          noOptionsMessage={() => 'No hay datos'}
                                          onChange={event => {
                                            let tempInputs = [...inputs];
                                            let rowSelected = inputs.findIndex(
                                              x => x.id === pIndex + '_' + i,
                                            );
                                            if (rowSelected >= 0) {
                                              tempInputs[rowSelected] = {
                                                ...tempInputs[rowSelected],
                                                consumCenter: event,
                                              };
                                            } else {
                                              tempInputs.push({
                                                id: pIndex + '_' + i,
                                                consumCenter: event,
                                              });
                                            }

                                            setInputs(tempInputs);
                                          }}
                                          className='text-secondary'
                                          placeholder={'Seleccionar...'}
                                          options={formattedCCList}
                                          styles={customSelectNewDark}
                                        ></Select>
                                      </Col>
                                      <Col xs={2}>
                                        <p className={`${tableStyles.crudModalLabel} `}>
                                          Centro de costo
                                        </p>
                                        <Select
                                          noOptionsMessage={() => 'No hay datos'}
                                          onChange={event => {
                                            let tempInputs = [...inputs];
                                            let rowSelected = inputs.findIndex(
                                              x => x.id === pIndex + '_' + i,
                                            );
                                            if (rowSelected >= 0) {
                                              tempInputs[rowSelected] = {
                                                ...tempInputs[rowSelected],
                                                costCenter: event,
                                              };
                                            } else {
                                              tempInputs.push({
                                                id: pIndex + '_' + i,
                                                costCenter: event,
                                              });
                                            }

                                            setInputs(tempInputs);
                                          }}
                                          options={listCCost}
                                          className='text-secondary'
                                          placeholder={'Seleccionar...'}
                                          styles={customSelectNewDark}
                                        ></Select>
                                      </Col>
                                      <Col xs={1} className='text-end'>
                                        {isLoading.find(x => x.id === pIndex + '_' + i)
                                          ?.isLoading ? (
                                          <Spinner
                                            className='mt-4'
                                            animation='border'
                                            variant='info'
                                          />
                                        ) : (
                                          <>
                                            {myPermission?.create && (
                                              <img
                                                alt='add'
                                                className='mt-4 cursorPointer'
                                                key={'invFamily' + trigger}
                                                onClick={() => addRow(e, i, pIndex, ePrincipal)}
                                                width='30px'
                                                src={AddBlue}
                                              ></img>
                                            )}
                                          </>
                                        )}
                                      </Col>

                                      {/* <h5 className={tableStyles.darkBlueText}>
                                        <b>inventoryFamilyAndCC</b>
                                      </h5> */}
                                      <Col
                                        xs={12}
                                        className={`mt-2 ${tableStyles.scrollThis}`}
                                        style={{ height: '27vh' }}
                                      >
                                        <Row className=''>
                                          <Col xs={12}>
                                            <table
                                              className={tableStyles.clearGrayBorder}
                                              style={{
                                                fontFamily: ' PT Sans ',
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                    scope='col'
                                                  >
                                                    Cuenta NIIF
                                                  </th>
                                                  <th
                                                    className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                    scope='col'
                                                  >
                                                    Familia de inventario
                                                  </th>
                                                  <th
                                                    className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                    scope='col'
                                                  >
                                                    Centro de consumo
                                                  </th>
                                                  <th
                                                    className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                    scope='col'
                                                  >
                                                    Centro de costos
                                                  </th>
                                                  <th
                                                    className={`col-md-2 text-center ${tableStyles.blackGrayBoldFont} ${tableStyles.clearGrayBorder}`}
                                                    scope='col'
                                                    style={{ width: '1%' }}
                                                  >
                                                    &nbsp;
                                                  </th>
                                                </tr>
                                              </thead>
                                              {myPermission?.read && (
                                                <tbody>
                                                  {e.detail?.length > 0 &&
                                                    e.detail?.map((e, i) => {
                                                      return (
                                                        <tr key={i}>
                                                          <th
                                                            className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                          >
                                                            {e.niif || '-'}
                                                          </th>
                                                          <td
                                                            className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                          >
                                                            {e.invFamily || '-'}
                                                          </td>
                                                          <td
                                                            className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                          >
                                                            {e.consumCenter || '-'}
                                                          </td>
                                                          <td
                                                            className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder}`}
                                                          >
                                                            {e.costCenter || '-'}
                                                          </td>
                                                          <td
                                                            className={`text-secondary text-center ${tableStyles.fw700End} ${tableStyles.clearGrayBorder} cursorPointer col-1`}
                                                            onClick={() => removeRow(e)}
                                                            style={{
                                                              width: '1%',
                                                            }}
                                                          >
                                                            <img
                                                              alt=''
                                                              className='mt-4 cursorPointer'
                                                              key={'invFamily' + trigger}
                                                              onClick={() => removeRow(e)}
                                                              width='20px'
                                                              src={Menos}
                                                            ></img>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                </tbody>
                                              )}
                                            </table>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </>
                                  )
                                )}
                              </Row>
                            </>
                          );
                        })}
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            );
          })}
      </div>
    </>
  );
};
