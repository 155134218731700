//User's reducer

import {
  GET_INVENTORY_FAMILY,
  SET_INDEX_INVENTORY_FAMILY,
  GET_NIIF_AUX,
  GET_ADD_INFO,
  INFO_ID,
  GET_INVENTORY_ACTIVE,
  LOADING
} from "../actions/actionTypes";

const initialState = {
  inventoryFamily: [],
  activeInventory: [],
  totalIFamilies: 0,
  niifAux: [],
  addInfo: [],
  info_id: {},
  displayError: false,
  error: null,
  token: "",
  family_loading: false,
  isLoading: false,
};
export const inventoryFamilyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVENTORY_FAMILY:
      return {
        ...state,
        inventoryFamily: action.payload,
        token: action.token,
        family_loading: action.loading,
        isLoading: action.loading,
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case GET_INVENTORY_ACTIVE:
      return {
        ...state,
        activeInventory: action.payload,
        token: action.token,
      };
    case SET_INDEX_INVENTORY_FAMILY:
      return {
        ...state,
        totalIFamilies: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };
    case GET_NIIF_AUX:
      return {
        ...state,
        niifAux: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };
    case GET_ADD_INFO:
      return {
        ...state,
        addInfo: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };
    case INFO_ID:
      return {
        ...state,
        info_id: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };

    default:
      return state;
  }
};
