//components
import { customSwaltAlert, customSwaltAlertAsistencial, loader } from '../../helpers';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

//styles
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';

//icons
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

//hooks
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Title } from '../../shared';
import ModalNew from '../../components/Layouts/ModalNew';

const initialInfoState = {
    noteType: '',
    noteValue: '',
    comments: '',
    table: undefined
}

const noteOptions = [
    {
        value: '',
        label: 'Seleccionar...'
    },
    {
        value: 'credit',
        label: 'Crédito'
    },
    {
        value: 'debit',
        label: 'Débito'
    }
]

export const CreateNote = ({ version }) => {
    const isAdminVersion = useMemo(() => {
        return version === 'administration'
    }, [version])

    const history = useHistory();
    const location = useLocation();
    const store = useSelector(store => store);
    const [trigger, setTrigger] = useState(0)
    const [info, setInfo] = useState(initialInfoState);
    const sequenceRef = useRef(null);
    const [invoiceResults, setInvoiceResults] = useState(null);
    const [showPdf, setShowPdf] = useState(false);
    const [file, setFile] = useState('');

    const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === (isAdminVersion ? 'invoicing_notes' : 'admision_notes'),
    );

    const {
        trigger: getInvoice,
        results: invoiceInfo,
        load: invoiceLoader
    } = useGetMethod();

    const {
        trigger: getNotePDF,
        load: notePDFLoader,
    } = useGetMethod();

    const {
        trigger: createInvoiceNote,
        load: loaderCreate
    } = usePostMethod()

    useEffect(() => {
        if (invoiceInfo && invoiceInfo.results && invoiceInfo.results.length > 0) {
            setInvoiceResults(invoiceInfo.results[0]);
        } else {
            setInvoiceResults(null);
        }
    }, [invoiceInfo])

    const onResetData = () => {
        setInvoiceResults(null);
        setInfo(initialInfoState);
        sequenceRef.current.value = null
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search).get('query');
        setInfo(state => ({
            ...state,
            table: isAdminVersion ? 'inv' : 'app'
        }))
        if (query) {
            sequenceRef.current.value = query;
            const url = isAdminVersion ? '/invoicing/invoice/ListToSend/' : '/medical/invoicing/appointment-payment/'
            getInvoice({
                url: url,
                objFilters: {
                    eaccount: store.loginReducer.currentAccount.id,
                    sequence: query.trim()
                },
                token: store.loginReducer.Authorization,
                doAfterException: result => {
                    if (!result?.success) {
                        const warningModalObject = {
                            showCancelButton: false,
                            icon: 'warning',
                            title: 'Intenta de nuevo',
                            text: result.message,
                            confirmButtonText: 'Aceptar'

                        }
                        if (isAdminVersion) {
                            return customSwaltAlert(warningModalObject)
                        } else {
                            return customSwaltAlertAsistencial(warningModalObject)
                        }
                    }
                },
                doAfterSuccess: result => {
                    if (result.success) {
                        if (result.results.length === 0) {
                            const warningModalObject = {
                                showCancelButton: false,
                                icon: 'warning',
                                title: 'Intenta de nuevo',
                                text: result.message,
                                confirmButtonText: 'Aceptar'

                            }
                            if (isAdminVersion) {
                                return customSwaltAlert(warningModalObject)
                            } else {
                                return customSwaltAlertAsistencial(warningModalObject)
                            }
                        }
                    }
                }
            });
        }
    }, [location.search,
        getInvoice,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
        isAdminVersion,
        trigger]);

    const handleSubmit = e => {
        e.preventDefault();
        const queryValue = e.target.sequence.value;
        const baseUrl = isAdminVersion ? '/facturacion/NotasContables/CrearNota' : '/admision/NotasContables/CrearNota';
        history.push(`${baseUrl}?query=${queryValue}`);
        setTrigger(trigger + 1)
    }

    const isValidGeneration = () => {
        if (!info.noteType) {
            customSwaltAlert({
                icon: 'warning',
                title: `Intenta de nuevo`,
                text: 'Debe escoger un tipo válido',
                showCancelButton: false,
            })
            return false
        }

        if (info.noteValue <= 0) {
            customSwaltAlert({
                icon: 'warning',
                title: `Intenta de nuevo`,
                text: 'Valor ingresado inválido',
                showCancelButton: false,
            })
            return false
        }

        return true
    }

    const buttonGenerate = () => {
        if (!isValidGeneration()) {
            return;
        }
        createInvoiceNote({
            url: '/medical/invoicing/invoicing_notes/',
            token: store.loginReducer.Authorization,
            body: {
                ...info,
                table: info.table,
                eaccount: store.loginReducer.currentAccount.id,
                user: store.loginReducer.user_data.id,
                invoiceId: invoiceResults.id,
                invoiceValue: isAdminVersion ? invoiceResults.total_amount : invoiceResults.pending_amount,
                resolution: invoiceResults.resolution_id
            },
            method: 'POST',
            succesAction: res => {
                customSwaltAlert({
                    icon: 'success',
                    title: res.title ? res.title : 'Creado exitosamente',
                    text: res.message,
                    showCancelButton: true,
                    confirmButtonText: 'Previsualizar',
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Llamar a getNotePDF para obtener el archivo PDF en base64
                        getNotePDF({
                            url: '/medical/invoicing/download-note/',
                            token: store.loginReducer.Authorization,
                            objFilters: {
                                eaccount: store.loginReducer.currentAccount.id,
                                noteId: res.results,
                                table: 'app'
                            },
                            succesAction: pdfRes => {
                                // Limpiar la cadena pdfRes.results.key
                                const cleanKey = (key) => {
                                    return key.replace(/^b'|'$/g, '');
                                };

                                const cleanedKey = cleanKey(pdfRes.results.key);
                                setFile(cleanedKey);
                                setShowPdf(true);
                            }
                        });
                    }
                });

                onResetData();  // Limpiar el formulario
            },
            doAfterException: res => {
                if (!res.success) {
                    const warningModalObject = {
                        showCancelButton: false,
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: res.message,
                        confirmButtonText: 'Aceptar'
                    };
                    if (isAdminVersion) {
                        return customSwaltAlert(warningModalObject);
                    } else {
                        return customSwaltAlertAsistencial(warningModalObject);
                    }
                }
            }
        });
    }

    const newBalance = useMemo(() => {
        const amount = parseFloat(invoiceResults?.pending_amount ?? invoiceResults?.total_amount ?? 0);
        const noteValue = parseFloat(info?.noteValue ?? 0);
        return info.noteType === "credit"
            ? amount - noteValue
            : amount + noteValue;
    }, [info, invoiceResults]);

    return (
        <>
            {(invoiceLoader || loaderCreate || notePDFLoader) && loader}
            <div
                className={`ml-5 ${tableStyles.container}`}
                style={{ marginRight: '3rem', paddingTop: '30px', width: '54.5%' }}
            >
                {
                    isAdminVersion ? (
                        <Title
                            title='Crear nota'
                            backIconNone
                            className={'mb-2'}
                            onClickIcon={() => {
                                history.push('/facturacion/inicio');
                            }}
                        />
                    ) : (
                        <OrdGenericTemplate
                            title={'Crear nota'}
                            style={{ padding: 0 }}
                            backArrowAction={() => { history.push('/admision/inicio') }}>
                        </OrdGenericTemplate>
                    )
                }

                <div className={'mt-4'}>
                    <form onSubmit={handleSubmit} className='d-flex gap-3'>
                        <div className={'w-20'}>
                            <input
                                className={IndividualStyles.Search}
                                type='text'
                                placeholder='Número de factura…   '
                                name='sequence'
                                ref={sequenceRef}
                            />
                        </div>

                        <button type='submit'>
                            <img
                                className='hoverPointer'
                                alt='iconSearch'
                                src={isAdminVersion ? Lupa : ordBlueSearch}
                                width={isAdminVersion ? 24 : 16}
                            />
                        </button>
                    </form>
                </div>
                <div className='d-flex gap-3 mb-3 mt-4'>
                    <div className={tableStyles.containerSelect}>
                        {isAdminVersion ? (
                            <label className={tableStyles.stylesLabel}>Valor factura</label>
                        ) : (
                            <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Valor factura</div>
                        )}
                        <NumberFormat
                            allowNegative={false}
                            className={isAdminVersion ? IndividualStyles.registerInputs : 'w-100 ord-roundInput'}
                            name='pending_amount'
                            thousandsGroupStyle='thousand'
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            style={isAdminVersion ? { padding: 0, color: '#58595B' } : {}}
                            prefix={'$'}
                            value={(invoiceResults?.pending_amount ?? invoiceResults?.total_amount ?? '')}
                            placeholder='$0'
                            min={1}
                            readOnly
                        />
                    </div>
                    <div className={tableStyles.containerSelect}>
                        {isAdminVersion ? (
                            <label className={tableStyles.stylesLabel}>Empresa</label>
                        ) : (
                            <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Paciente</div>
                        )}
                        <input
                            className={isAdminVersion ? IndividualStyles.registerInputs : 'w-100 ord-roundInput'}
                            type="text"
                            readOnly
                            style={isAdminVersion ? { background: '#F5F7FA', color: '#58595B' } : {}}
                            value={invoiceResults?.clientName ?? ''}
                        />

                    </div>
                    {
                        isAdminVersion &&
                        <div className={tableStyles.containerSelect}>
                            {isAdminVersion ? (
                                <label className={tableStyles.stylesLabel}>Contrato</label>
                            ) : (
                                <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Contrato</div>
                            )}
                            <input
                                className={isAdminVersion ? IndividualStyles.registerInputs : 'w-100 ord-roundInput'}
                                type='text'
                                readOnly
                                style={isAdminVersion ? { background: '#F5F7FA', color: '#58595B' } : {}}
                                value={invoiceResults?.contract_name ?? ''}
                            />
                        </div>
                    }
                </div>
                <div className='d-flex gap-3'>
                    <div className={tableStyles.containerSelect}>
                        {isAdminVersion ? (
                            <label className={tableStyles.stylesLabel}>Tipo de nota<b style={{ color: '#FF8B00' }}>*</b></label>
                        ) : (
                            <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Tipo de nota<b style={{ color: '#FF8B00' }}>*</b></div>
                        )}
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            key={'client' + 1}
                            placeholder='Seleccionar...'
                            options={noteOptions}
                            onChange={e =>
                                setInfo(state => ({
                                    ...state,
                                    noteType: e.value,
                                    noteValue: ''
                                }))
                            }
                            styles={isAdminVersion ? customSelectNewDark : ordCustomSelect}
                            value={noteOptions.find(option => option.value === info.noteType)}
                        />
                    </div>
                    <div className={tableStyles.containerSelect}>
                        {isAdminVersion ? (
                            <label className={tableStyles.stylesLabel}>Valor<b style={{ color: '#FF8B00' }}>*</b></label>
                        ) : (
                            <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Valor<b style={{ color: '#FF8B00' }}>*</b></div>
                        )}
                        <NumberFormat
                            allowNegative={false}
                            className={isAdminVersion ? IndividualStyles.registerInputs : 'w-100 ord-roundInput'}
                            name='valueNote'
                            thousandsGroupStyle='thousand'
                            allowLeadingZeros={false}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            style={isAdminVersion ? { padding: 0, color: '#58595B' } : {}}
                            prefix={'$'}
                            value={parseInt(info.noteValue)}
                            placeholder='$0'
                            onValueChange={e => {
                                if ((info.noteType === 'credit' && e.value <= (invoiceResults?.total_amount ?? invoiceResults?.pending_amount)) || info.noteType === 'debit') {
                                    setInfo((state) => ({
                                        ...state,
                                        noteValue: parseInt(e.value),
                                    }));
                                }
                                setInfo((state) => ({...state, noteValue: parseInt(e.value)}))
                            }}
                            min={1}
                            readOnly={(invoiceResults?.pending_amount ?? invoiceResults?.total_amount) <= 0 || !info.noteType}
                        />
                    </div>

                    <div className={tableStyles.containerSelect}>
                        {isAdminVersion ? (
                            <label className={tableStyles.stylesLabel}>Nuevo saldo</label>
                        ) : (
                            <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Nuevo saldo</div>
                        )}
                        <NumberFormat
                            allowNegative={false}
                            className={isAdminVersion ? IndividualStyles.registerInputs : 'w-100 ord-roundInput'}
                            name='newBalance'
                            thousandsGroupStyle='thousand'
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            style={isAdminVersion ? { padding: 0, color: '#58595B' } : {}}
                            prefix={'$'}
                            value={newBalance}
                            placeholder='$0'
                            min={1}
                            readOnly
                        />
                    </div>
                </div>
                <div className='mt-3'>
                    {isAdminVersion ? (
                        <label className={tableStyles.stylesLabel}>Comentarios</label>
                    ) : (
                        <div className={`${tableStyles.ordDarkBlueText} small ps-1`}>Comentarios</div>
                    )}
                    <textarea
                        className={IndividualStyles.textArea_new3}
                        rows='6'
                        cols=''
                        value={info.comments ?? ''}
                        placeholder='Escribir...'
                        onChange={e =>
                            setInfo({
                                ...info,
                                comments: e.target.value,
                            })
                        }
                    ></textarea>
                </div>
                <div className={`${IndividualStyles.bottomRow} mb-3`}>
                    <Button
                        className={`d-block ${isAdminVersion ? tableStyles.buttonExtra3 : tableStyles.ordBtnPrimary} `}
                        style={{ padding: '8px', border: '0px' }}
                        onClick={() => buttonGenerate()}
                        disabled={!myPermission.create}
                    >
                        Crear nota
                    </Button>
                    <ModalNew
                        title='Crear nota'
                        show={showPdf && file}
                        btnNoName={'Cancelar'}
                        size='700'
                        btnYesDisabled={false}
                        onHide={() => setShowPdf(false)}
                        btnNoEvent={() => setShowPdf(false)}
                        btnNoDisabled={false}
                    >
                        <embed
                            src={`data:application/pdf;base64,${file}`}
                            type="application/pdf"
                            width="100%"
                            height="600px"
                        />
                    </ModalNew>
                </div>
            </div>
        </>
    )
}
