//styles
import tableStyles from "../../../components/Layouts/tableStyle.module.scss";

//utils
import parse from 'html-react-parser';

//components
import { Box } from "@mui/material";
import { Text, TextGrayInBrackets, TextGrayOnBrackets } from "../../../AsistanceModule/AdmissionsMasterTables/componentsConsents";


export const ConsentToPrint = ({
    pdfRef,
    nameAccount,
    logoAccount,
    bussinesNameAccount,
    nitAccount,
    phoneAccount,
    emailAccount,
    addressAccount,
    code,
    version,
    validity,
    introduction,
    meaning,
    risks,
    benefits,
    recomendations,
    additionalComments,
    declarations,
    withdrawal,
    revocation,
    footer,
    
}) => {

    return (
        <Box
            ref={pdfRef}
            style={{ width: '100%', padding: '35px 20px' }}
            className={`${tableStyles.ordDarkGrayText}`}
        >
            <Box className={`${tableStyles.styleCardConsent} h-auto shadow-none mt-0 p-0 mb-4`}>
                <Box className="d-flex flex-column gap-3">
                    <img
                        width={137}
                        src={logoAccount}
                        alt="logo"
                    />

                    <Box>
                        <Box className={`${tableStyles.darkerBlueText} fw-bold`}>
                            {bussinesNameAccount || '-'}
                        </Box>

                        <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                            <Text title={'Nit'} subtitle={nitAccount || '-'} />
                            <Text title={'Teléfono'} subtitle={phoneAccount || '-'} />
                            <Text title={'Email'} subtitle={emailAccount || '-'} />
                            <Text title={'Dirección'} subtitle={addressAccount || '-'} />
                        </Box>

                    </Box>


                </Box>

                <Box className="d-flex flex-column align-items-end justify-content-around gap-3 align-self-end">
                    <Box className="text-end">
                        <Box className={`${tableStyles.f14} ${tableStyles.ordAquaMarineText} text-uppercase fw-bold`}>
                            {nameAccount || '-'}
                        </Box>
                        <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                            <Text title={'Código'} subtitle={code || '-'}></Text>
                            <Text title={'Versión'} subtitle={version || '-'}></Text>
                            <Text title={'Vigencia'} subtitle={validity || '-'}></Text>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.marginBottom15px} row`}>
                    <Box className="col-3 ">
                        <TextGrayOnBrackets active title={'Fecha'} />
                    </Box>
                </Box>

                <Box className={`${tableStyles.marginBottom15px} row`}>
                    <Box className="col-6 ">
                        <TextGrayOnBrackets title={'Paciente'} active />
                    </Box>
                    <Box className="col-3 ">
                        <TextGrayOnBrackets title={'Identificación'} active />
                    </Box>
                    <Box className="col-3 ">
                        <TextGrayOnBrackets title={'Teléfono'} active />
                    </Box>
                </Box>

                <Box className={`${tableStyles.marginBottom15px} row`}>
                    <Box className="col-6 ">
                        <TextGrayOnBrackets title={'Procedimiento'} active />
                    </Box>
                    <Box className="col-3 ">
                        <TextGrayOnBrackets title={'Ojo a operarse'} active />
                    </Box>
                    <Box className="col-3 ">
                        <TextGrayOnBrackets title={'Responsables'} active />
                    </Box>
                </Box>

            </Box>

            <Box className={`${tableStyles.f12} mb-4`} style={{ textAlign: 'justify' }} >
                {parse(introduction || '-')}
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Significado del procedimiento
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(meaning || '-')}
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Riesgos
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(risks || '-')}
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Beneficios
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(benefits || '-')}
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Recomendaciones
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(recomendations || '-')}
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Comentarios adicionales
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(additionalComments || '-')}
                </Box>
            </Box>

            <Box className="mb-4">
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Declaratorias
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(declarations || '-')}
                </Box>
            </Box>

            <Box
                style={{ textAlign: 'justify', marginBottom: '80px' }}
            >
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold mb-3`}>
                    Consentimiento o desistimiento informado
                </Box>
                <Box className={`${tableStyles.f12} mb-4`} >
                    {parse(withdrawal || '-')}
                </Box>
            </Box>

            <Box className="row mb-5" style={{ width: '85%' }}>
                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Firma del Paciente'} typeId={'C.C'} active />
                </Box>

                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Firma del acompañante'} typeId={'C.C'} active />
                </Box>

                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'R.M.'} active />
                </Box>

            </Box>

            <Box className="row align-items-center mb-2" style={{ width: '85%' }}>
                <Box className={`${tableStyles.f13} ${tableStyles.ordAquaMarineText} fw-bold d-flex gap-3 align-items-end`}>
                    <span>REVOCATORIA DE LO CONSENTIDO:</span>
                    <span className="col-6">
                        <TextGrayOnBrackets className='w-100' active title={'Fecha'} classNameTitle='fw-lighter' />
                    </span>
                </Box>
            </Box>

            <Box style={{ marginBottom: '70px' }}>
                <Box className={`${tableStyles.f12}`} >
                    {parse(revocation || '-')}
                </Box>
            </Box>

            <Box className="row mt-5" style={{ width: '85%' }}>
                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Paciente o representante legal'} typeId={'C.C'} active />
                </Box>

                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Acompañaste (s)'} typeId={'C.C'} active />
                </Box>

                <Box className="col-4">
                    <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'R.M.'} active />
                </Box>

            </Box>

            <Box style={{ margin: 'auto', marginTop: '110px', fontSize: '10px', textAlign: 'center', width: '95%' }}>
                {parse(footer || '-')}
            </Box>

        </Box>
    )
}