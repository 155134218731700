/* eslint-disable react-hooks/exhaustive-deps */
// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState, useEffect } from 'react';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupaTransparent.svg';
import LupaAzul from '../../assets/img/icons/lupa.svg';
import AddCheck from '../../assets/img/icons/add-check.svg';
import DateGrey from '../../assets/img/icons/dateGrey.svg';
import Share from '../../assets/img/icons/share.svg';
//-------------- scss styles 😄   --------------
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from './Consultations.module.scss';

//-------------- actions 😄   --------------
import { useHistory } from 'react-router-dom';

import {
  getTask,
  getOneTask,
  getEventType,
  createEvent,
  finishEvent,
} from '../../actions/accountingConsultsActions';
import { Title } from '../../shared';

function TaxCalendar() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
    id: '',
    create: '',
    detail: '',
  });
  const [triggerModal, setTriggerModal] = useState(1);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateUntil: '',
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [event, setEvent] = useState({
    dueDate: '',
    title: '',
    tt: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  //-------------- myPermission  😄   --------------
  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'taxCalenda',
    );

  //-------------- first actions 😄   --------------
  const handlerClick = () => {
    history?.push('/contabilidad/Consultas/VerCalendarioTributario');
  };
  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
  }, []);

  useEffect(() => {
    dispatch(getTask(filters));
  }, [trigger]);

  useEffect(() => {
    dispatch(getEventType({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [trigger]);

  useEffect(() => {
    if (showModal.id !== '') {
      dispatch(
        getOneTask(
          { eaccount: storage.loginReducer.currentAccount.id, id: showModal.id },
          setEvent,
        ),
      );
    }
  }, [triggerModal]);

  // --------------- Table 💥  ----------------//

  const header = [
    <div key={1} className={`text-start col-5`} style={{ paddingLeft: '14px' }}>
      Evento
    </div>,
    <div key={2} className={`col-1`}>
      Fecha límite
    </div>,
    <div key={3} className={`col-3`}>
      Tipo de evento
    </div>,
    <div key={4} className={`col-2`}>
      Estado
    </div>,
    <div key={5} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingConsultsReducer.listTasks)) {
      storage.accountingConsultsReducer.listTasks.forEach((item, index) => {
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-start col-5' style={{ paddingLeft: '14px' }}>
              {item.title}
            </div>
            <div className='text-center col-1'>
              {item.dueDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className='text-start col-3'>{item.ttName}</div>
            <div className='text-center col-2'>
              <div
                className='rounded-pill p-1'
                style={{ backgroundColor: `${item.status_background}` }}
              >
                <b style={{ color: `${item.status_fontcolor}` }}>{item.statusName}</b>
              </div>
            </div>
            <div className='text-center col-1'>
              <img
                alt=''
                className={`w-40 ${IndividualStyles.pointer}`}
                style={{ width: '16px', height: '16px' }}
                onClick={() => {
                  setShowModal({ show: true, create: false, detail: true, id: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                src={Lupa}
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  // --------------- Functions 💥  ----------------//
  const newEvent = () => {
    dispatch(
      createEvent(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          dueDate: event.dueDate,
          title: event.title,
          type: event.tt,
        },
        () => {
          setShowModal({ show: false });
          setEvent({
            eaccount: storage.loginReducer.currentAccount.id,
            dueDate: '',
            title: '',
            type: '',
          });
          dispatch(getTask(filters));
        },
      ),
    );
  };

  const endEvent = () => {
    dispatch(
      finishEvent(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          user: storage.loginReducer.user_data.id,
          id: showModal.id,
        },
        () => {
          setShowModal({ show: false });
          setEvent({
            eaccount: storage.loginReducer.currentAccount.id,
            dueDate: '',
            title: '',
            type: '',
          });
          dispatch(getTask(filters));
        },
      ),
    );
  };

  //-------------- select options 🍕   --------------

  let optionsEvents = [{ key: '', value: '', label: 'Seleccione tipo de evento' }];
  if (Array.isArray(storage.accountingConsultsReducer.listEventTypes)) {
    storage.accountingConsultsReducer.listEventTypes.forEach((item, index) => {
      optionsEvents.push({
        value: item.id,
        label: item.name,
        key: index + 'eventType',
      });
    });
  }

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {storage.accountingConsultsReducer.listTasks_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={`${tableStyles.title} d-flex gap-2 align-items-center mb-0`}>
          <Title
            title='Calendario tributario'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
          <img style={{ width: '18px', marginLeft: '15px' }} src={Share} alt='icon' />
        </div>

        <Row className='d-flex'>
          <Col xs={8}></Col>
          <div className=' display-grid col-4' style={{ alignContent: 'end' }}>
            <div className='col text-end d-flex justify-content-end align-items-end'>
              {myPermission().create ? (
                <div
                  onClick={() => setShowModal({ show: true, create: true, detail: false })}
                  className={tableStyles.createNomModule}
                >
                  <b className={`mr-2`}>Agregar Evento</b>
                  <img src={AddCheck} alt='User' />
                  <div></div>
                </div>
              ) : (
                ''
              )}
              <label
                onClick={() => handlerClick()}
                className={Styles.bgBtn}
                style={{
                  borderRadius: '10px',
                  marginTop: '30px',
                  padding: '4px 7px',
                  width: '140px',
                  fontSize: '12px',
                  fontWeight: '700',
                  fontStyle: 'bold',
                  cursor: 'pointer',
                }}
              >
                {' '}
                Ver calendario
                <img style={{ width: '17px', marginLeft: '7px' }} src={DateGrey} alt='icon' />
              </label>
            </div>
          </div>
        </Row>

        <Row className='d-flex'>
          <Col xs={2}>
            <label className={tableStyles.crudModalLabel}>Fecha desde</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='date'
              value={filters.dateFrom}
              onChange={e =>
                setFilters({
                  ...filters,
                  dateFrom: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearch(e);
                }
              }}
            ></input>
          </Col>

          <Col xs={2}>
            <label className={tableStyles.crudModalLabel}>Hasta</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='date'
              value={filters.dateUntil}
              onChange={e =>
                setFilters({
                  ...filters,
                  dateUntil: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearch(e);
                }
              }}
            ></input>
          </Col>

          <Col xs={7} style={{ alignSelf: 'end' }}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={`${tableStyles.crudModalLabel}`}></label>
              <input
                className={tableStyles.SearchNew}
                style={{ height: '27px' }}
                placeholder='Buscar...'
                type='text'
                value={filters.search}
                onChange={e =>
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              ></input>
            </form>
          </Col>

          <div className=' col display-grid  mr-2' style={{ alignContent: 'end' }}>
            <label className=''>
              <img
                className={`${tableStyles.cursorPointer}`}
                src={LupaAzul}
                onClick={() => setTrigger(trigger + 1)}
                alt='icon'
                height='24px'
              />
            </label>
          </div>
        </Row>

        <div>
          <GenericTableScroll
            headers={header}
            dark={true}
            body={renderList()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>

        <ModalNew
          show={showModal.show}
          onHide={() => {
            setShowModal({ show: false });
            setEvent({
              dueDate: '',
              title: '',
              tt: '',
              eaccount: storage.loginReducer.currentAccount.id,
            });
          }}
          title={showModal.create ? 'Agregar evento' : 'Evento'}
          btnYesEvent={() => (showModal.create ? newEvent() : endEvent())}
          btnYesName={showModal.create ? 'Agregar' : 'Marcar como realizado'}
          btnYesDisabled={
            !!(event.dueDate === '') ||
            !!(event.title === '') ||
            !!(event.tt === '') ||
            myPermission().edit !== 1
          }
          btnNoEvent={() => {
            setShowModal({ show: false });
            setEvent({
              dueDate: '',
              title: '',
              tt: '',
              eaccount: storage.loginReducer.currentAccount.id,
            });
          }}
          btnNoName={'Cancelar'}
          size={'500'}
        >
          <Row className='d-flex'>
            <Col xs={12}>
              <label className={tableStyles.crudModalLabel}>Fecha limite</label>
              <input
                className={IndividualStyles.registerInputsClearBlue}
                style={{ color: '#7FADDE', fontWeight: '700' }}
                type='date'
                value={event.dueDate}
                onChange={e =>
                  setEvent({
                    ...event,
                    dueDate: e.target.value,
                  })
                }
                disabled={showModal.create ? false : true}
              ></input>
            </Col>
          </Row>

          <Row className='d-flex'>
            <Col xs={12}>
              <label className={tableStyles.crudModalLabel}>Título</label>
              <input
                className={IndividualStyles.registerInputsClearBlue}
                type='text'
                value={event.title}
                style={{ color: '#58595B' }}
                onChange={e =>
                  setEvent({
                    ...event,
                    title: e.target.value,
                  })
                }
                disabled={showModal.create ? false : true}
              ></input>
            </Col>
          </Row>

          <Row className='d-flex'>
            <Col xs={12}>
              <label className={tableStyles.crudModalLabel}>Tipo de evento</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionsEvents}
                onChange={e =>
                  setEvent({
                    ...event,
                    tt: e.value,
                  })
                }
                value={optionsEvents.find(item => item.value === event.tt)}
                placeholder=''
                styles={customSelectNew}
                isDisabled={showModal.create ? false : true}
              />
            </Col>
          </Row>
        </ModalNew>
      </div>
    </>
  );
}

export default TaxCalendar;
