import "./TextField.scss";

export const TextField = ({ heightIcon = 18, widthIcon = 18, className, startIcon, endIcon, variant = "default", ...rest }) => {

    return (
        <div
            className={`${rest.disabled ? "textfield-disabled" : ""} textfield ${className} textfield-${variant}`}
        >
            {startIcon ? <span className="px-2" style={{ pointerEvents: "none" }}>
                <img src={startIcon} alt="icon" height={heightIcon} width={widthIcon} />
            </span> : null}
            <input {...rest} />
            {endIcon ? <span className="px-2" style={{ pointerEvents: "none" }}>
                <img src={endIcon} alt="icon" height={heightIcon} width={widthIcon} />
            </span> : null}
        </div>
    )

}