import deepcopy from 'deepcopy';
import { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  activationDeactivation,
  createIF,
  createInfo,
  getIF,
  getInfo,
  getNiif,
  updateIF,
} from '../../actions/inventoryFamilyActions';
import { getAllMu, getMu } from '../../actions/measurementUnitsActions';
import Trash from '../../assets/img/icons/Cerrar.svg';
import Search from '../../assets/img/icons/lupa.svg';
import question from '../../assets/img/icons/question.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import GenericTable from '../Layouts/GenericTableNew';
import ModalCreateRecord from '../Layouts/ModalNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopup';
import SelectComponent from '../SelectComponent/SelectComponent';
import { customSelectMulti, customSelectNewDark } from './../Layouts/react-select-custom';

class TableInventoryFamily extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getIF(this.state.filters);
    this.props.getNiif();
    this.props.getMu(this.state.filters);
    // this.props.getAllMu();
    this.props.getInfo();
  }
  componentDidUpdate(prevState) {
    if (
      prevState.add_info !== this.state.add_info ||
      prevState.add_info_edit !== this.state.add_info_edit ||
      this.state.inventoryFamily.additional_info
    ) {
      if (this.state.add_info.length > 0 && this.state.headerModal.length !== 3) {
        this.setState({ headerModal: [' #', 'Descripción', ''] });
      } else if (prevState.editRecord !== this.state.editRecord) {
        if (!!this.state.editRecord && this.state.headerModal.length !== 3) {
          this.setState({ headerModal: [' #', 'Descripción', ''] });
        }
      }
    }
  }
  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });

    this.props.getIF(this.state.filters);
  }
  handleChange(event) {
    let { filters } = this.state;
    filters.search = event.target.value;
    this.setState({ filters });
  }

  handleClick() {
    let { filters } = this.state;
    if (filters.search === '') {
      filters.page = 1;
    }
    this.setState({ filters });
    this.props.getIF(this.state.filters);
  }

  toggleModal = () => {
    this.setState(this.initialState);
    this.setState({
      modal: !this.state.modal,
    });
  };

  get initialState() {
    return {
      titleModal: 'Crear Familia',
      filters: {
        page: 1,
        perpage: 10,
        search: '',
        id_account: this.props.eaccount,
      },
      info: '',
      niff_label: '',
      infoTable: false,
      add_info: [],
      add_info_edit: [],
      inventoryFamily: {
        description: '',
        niff_id: undefined,
        measurements_list: [],
        additional_info: [],
        active: true,
        provider_classification: false,
      },
      // headerState: ["Descripción", "Cuenta de inventario", "est", "ㅤ"],
      headerState: [
        {
          tittle: 'Descripción',
          className: 'text-start px-2',
        },
        {
          tittle: 'Cuenta de inventario',
          className: 'text-center ',
        },
        {
          tittle: 'Estado',
          className: 'text-center w100',
        },
        {
          tittle: 'ㅤ',
          className: 'text-start ',
        },
      ],
      headerModal: [' #', 'Descripción'],
      editRecord: false,
      modal: false,
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'FInv');

  onChangeForm = e => {
    const name = e.target.name;
    let value = e.target.value;
    let { inventoryFamily } = this.state;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    inventoryFamily[name] = value;
    this.setState({ inventoryFamily });
  };

  onChangeInfo = e => {
    this.setState({
      info: e.target.value,
    });
  };
  addingAdditional = async () => {
    if (!!this.state.info === false) {
      return null;
    }
    let add = {
      info: this.state.info,
    };
    const response = await this.props.createInfo(add);

    if (response && response.status === 'fail') return;

    let { inventoryFamily } = this.state;
    inventoryFamily['additional_info'].push(this.props.info_id);
    this.setState({
      add_info: [...this.state.add_info, add.info],
      info: '',
      inventoryFamily,
    });
  };

  removeCreate = addInfo => {
    let { add_info } = this.state;
    for (let m in add_info) {
      if (addInfo === add_info[m]) {
        add_info.splice(m, 1);
      }
    }
    this.setState({ add_info });
  };

  removeEdit = addInfo => {
    let { inventoryFamily } = this.state;
    for (let m in inventoryFamily.additional_info) {
      if (addInfo === inventoryFamily.additional_info[m].info) {
        inventoryFamily.additional_info.splice(m, 1);
      }
    }
    this.setState({ inventoryFamily });
  };
  changeSelect = e => {
    let { inventoryFamily } = this.state;
    inventoryFamily['niff_id'] = e.value;

    this.setState({ inventoryFamily, niff_label: e.description });
  };

  changeMeasure = e => {
    const newMeasures = e.map(elem => elem.value);
    let { inventoryFamily } = this.state;
    inventoryFamily['measurements_list'] = newMeasures;
    this.setState({ inventoryFamily });
  };

  handleEditRecord = familia => {
    let { modal, inventoryFamily, titleModal } = this.state;

    modal = !this.state.modal;

    inventoryFamily = deepcopy(familia);

    titleModal = 'Editar Familia de Inventario';
    this.setState({
      modal,
      infoTable: true,
      inventoryFamily,
      editRecord: true,
      titleModal,
    });
  };

  handleSubmit() {
    if (this.state.editRecord) {
      this.setState({
        modal: !this.state.modal,
      });

      return this.props.updateIF(this.state.inventoryFamily, this.state.filters);
    }

    this.props.createIF(this.state.inventoryFamily, this.state.filters);
    this.setState({
      modal: !this.state.modal,
    });
  }

  renderFamilies(IFamily) {
    return (
      <tr key={IFamily.id} className='hover-table-row text-center align-middle'>
        <td className='col-md-6 text-start px-2'>{IFamily.description}</td>

        <td className='col-md-5'>{IFamily.niff_id}</td>
        <td className='col-md-1'>
          <div className={tableStyles.groupElemsWithoutMarginL}>
            <div
              className={`${IFamily.active ? tableStyles.greenState : tableStyles.redState} mx-2`}
            >
              {IFamily.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
          </div>
        </td>

        <td>
          {this.myPermission()?.edit && (
            <CustomPopup
              triggerSrc={threeDots}
              showEdit={this.myPermission()?.edit}
              editClickEvent={() => this.handleEditRecord(IFamily)}
              showEnable={this.myPermission()?.edit}
              isEnabled={IFamily.active}
              enableClickEvent={() =>
                this.props.activationDeactivation(
                  IFamily.id,
                  IFamily.active,
                  this.state.filters,
                  IFamily.description,
                )
              }
            />
          )}
        </td>
      </tr>
    );
  }

  render() {
    let listInventoryFamily;

    const optionsNiifs = () => {
      let tempNiifs = [];
      let data = [{ label: 'Seleccionar...', value: '' }];

      if (!this.props.niifAux.length) return data;

      if (this.props.niifAux !== undefined) {
        const elem2 = this.props.niifAux;
        tempNiifs = elem2.map(elem => {
          return {
            value: Number(elem.complete_account),
            label: elem.complete_account + ': ' + elem.description,
            description: elem.description,
          };
        });
      }
      return [...data, ...tempNiifs];
    };

    const optionMeasures = () => {
      let selectedOptions = [];
      let data = [{ label: 'Seleccionar...', value: '' }];

      if (!this?.props?.allMeasurements?.length) return data;

      if (this.props.allMeasurements !== undefined) {
        const elem2 = this.props.allMeasurements;
        selectedOptions = elem2.map(elem => {
          return {
            value: elem.id,
            label: elem.id + ': ' + elem.description,
            description: elem.description,
          };
        });
      }

      return [...data, ...selectedOptions];
    };

    const optionsMeasuresValue = () => {
      let selectedOptions = [];

      this.state.inventoryFamily.measurements_list.forEach(myMeasureId => {
        let foundOption = optionMeasures().find(measure => measure.value === myMeasureId.id);
        if (foundOption) {
          selectedOptions.push(foundOption);
        }
      });
      return selectedOptions;
    };
    let listAdditionalInfo;
    let listAdditionalInfoEdit;

    if (this.state.add_info !== undefined) {
      listAdditionalInfo = this.state.add_info.map((addInfo, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td style={{ textAlign: 'initial' }} className='px-2'>
              {addInfo}
            </td>
            <td style={{ textAlign: 'end' }}>
              {' '}
              <img
                src={Trash}
                alt='agregar'
                className='icons-popUp'
                onClick={() => this.removeCreate(addInfo)}
              />
            </td>
          </tr>
        );
      });
    }
    listAdditionalInfoEdit = this.state.inventoryFamily.additional_info.map((addInfo, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td style={{ textAlign: 'initial' }} className='px-2'>
            {addInfo.info}
          </td>
          <td>
            {' '}
            <img
              src={Trash}
              alt='agregar'
              className='icons-popUp'
              onClick={() => this.removeEdit(addInfo.info)}
            />
          </td>
        </tr>
      );
    });

    if (this.props.inventoryFamily !== undefined) {
      const family = this.props.inventoryFamily;
      listInventoryFamily = family.map(familia => {
        return this.renderFamilies(familia);
      });
    }
    let renderHeaders = this.state.headerState.map((header, index) => {
      return (
        <th key={index} className='text-center'>
          <div
            className={`${header === 'Est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}
          >
            {header.tittle}
          </div>
        </th>
      );
    });
    let renderHeaderModal = this.state.headerModal.map((header, index) => {
      return (
        <th key={index} className={header === ' #' ? 'border-end px-1 text-center' : 'px-2'}>
          {header}
        </th>
      );
    });

    const renderTooltip = props => (
      <Tooltip id='button-tooltip' {...props}>
        Esta familia de inventario será una posible clasificación de proveedor
      </Tooltip>
    );
    const handleSumit = e => {
      e.preventDefault();
      let { filters } = this.state;
      if (filters.search === '') {
        filters.page = 1;
      }
      this.setState({ filters });
      this.props.getIF(this.state.filters);
    };

    return (
      <div className={`${tableStyles.container}`} style={{ padding: '0rem 8rem' }}>
        {(this.props.loading || this.props.isLoading) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <h1 className={tableStyles.title}>Familia de Inventario</h1>
        <div>
          <div className={tableStyles.mainRow}>
            <div className='mt-auto'>
              <form onSubmit={handleSumit}>
                <input
                  className='inputsearch p-1'
                  type='text'
                  placeholder='Buscar...'
                  onChange={e => this.handleChange(e)}
                />
                <button onClick={e => this.handleClick(e)}>
                  <img src={Search} alt='User icon' className={`${tableStyles.iconSvgMargin2}`} />
                </button>
              </form>
            </div>
            {this.myPermission()?.create && (
              <div className={tableStyles.groupElems}>
                <div
                  className='col d-flex justify-content-end groupAddButton align-items-center'
                  onClick={() => this.toggleModal(true)}
                >
                  <label htmlFor='newAccident' className='darkGray fw-bold'>
                    Nueva Familia
                  </label>
                  <button className='addCheckButton mx-2' />
                </div>
              </div>
            )}
          </div>

          <ModalCreateRecord
            hideFooter={
              this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create
            }
            title={this.state.titleModal}
            show={this.state.modal}
            btnYesEvent={() => this.handleSubmit()}
            onHide={() => this.toggleModal(false)}
          >
            <div className='row mb-2'>
              <div className='col-md-12'>
                <p className='label-inputs text-light-blue'>Descripción</p>
                <input
                  className={`${IndividualStyles.registerInputs} input-text-light-blue`}
                  style={{ padding: '7px', color: '#58595b' }}
                  name='description'
                  type='text'
                  placeholder='Escribir...'
                  onChange={this.onChangeForm}
                  value={this.state.inventoryFamily.description}
                />
              </div>
            </div>

            <div className='row mb-2'>
              <p className='label-inputs text-light-blue'>Cuenta Inventario</p>
              <SelectComponent
                value={optionsNiifs().filter(
                  option => option.value === this.state.inventoryFamily.niff_id,
                )}
                onChange={e => this.changeSelect(e)}
                options={optionsNiifs()}
                placeholder='Seleccionar...'
                className='selectInput-react-select'
                styles={customSelectNewDark}
              />
            </div>

            <div className='row mb-2'>
              <p className='label-inputs text-light-blue'>Unidades de Medida</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                defaultValue={optionsMeasuresValue()}
                // defaultValue={optionsNiifs().filter(option => option.value == this.state.inventoryFamily.niff_id)}
                onChange={e => this.changeMeasure(e)}
                isMulti
                options={optionMeasures()}
                placeholder='Seleccionar...'
                className='selectInput-react-select'
                styles={customSelectMulti}
              />
            </div>

            <div className='row col-md-11 mt-3' style={{ marginLeft: '0px' }}>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.inventoryFamily?.provider_classification}
                  id='flexCheckChecked'
                  name='provider_classification'
                  onChange={this.onChangeForm}
                />
                <label
                  className='form-check-label'
                  htmlFor='flexCheckChecked'
                  style={{ color: '#58595b' }}
                >
                  Clasificación de proveedor
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <img
                      src={question}
                      alt='ayuda'
                      width='100'
                      style={{ width: '0.7rem', position: 'absolute' }}
                    />
                  </OverlayTrigger>
                </label>
              </div>
              {/* Validate if this family can be order in orderings module */}
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={this.state.inventoryFamily?.isOrdering}
                  id='isOrdering'
                  name='isOrdering'
                  onChange={this.onChangeForm}
                />
                <label
                  className='form-check-label'
                  htmlFor='isOrdering'
                  style={{ color: '#58595b' }}
                >
                  Permite ordenamiento
                </label>
              </div>
            </div>

            <div className='row mt-3'>
              <p className='label-inputs fw-bold input-text-darker-blue'>Información Adicional</p>
              <label htmlFor='info' className='label-inputs text-light-blue'>
                Descripción de información adicional
              </label>
              <div className='d-flex justify-content-between align-items-center'>
                <input
                  placeholder='Escribir...'
                  className={`${IndividualStyles.registerInputs} input-text-light-blue`}
                  style={{ padding: '7px', color: '#58595b' }}
                  name='info'
                  type='text'
                  onChange={this.onChangeInfo}
                  value={this.state.info}
                  maxLength='40'
                  onKeyUp={({ key }) => {
                    if (key === 'Enter') {
                      this.addingAdditional();
                    }
                  }}
                />

                <button className='addCheckButton mx-2' onClick={() => this.addingAdditional()} />
              </div>
            </div>

            <div className='row mb-4'>
              <div className='col-md-12'>
                <GenericTable headers={renderHeaderModal} fontFamilyTable={'fontFamilyTable'}>
                  {this.state.infoTable ? listAdditionalInfoEdit : listAdditionalInfo}
                </GenericTable>
              </div>
            </div>
          </ModalCreateRecord>
          {this.myPermission()?.read && (
            <GenericTable headers={renderHeaders} dark>
              {listInventoryFamily}
            </GenericTable>
          )}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalIFamilies ? this.state.filters.page : ''}
              {' de '}
              {Math.ceil(this.props.totalIFamilies / this.state.filters.perpage)
                ? Math.ceil(this.props.totalIFamilies / this.state.filters.perpage)
                : ''}{' '}
              ({this.props.totalIFamilies} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalIFamilies}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  isAuth: state.loginReducer.Authenticated,
  inventoryFamily: state.inventoryFamilyReducer.inventoryFamily,
  totalIFamilies: state.inventoryFamilyReducer.totalIFamilies,
  niifAux: state.inventoryFamilyReducer.niifAux,
  addInfo: state.inventoryFamilyReducer.addInfo,
  info_id: state.inventoryFamilyReducer.info_id,
  measurements: state.measurementUnitsReducer.measurements,
  allMeasurements: state.measurementUnitsReducer.measurements,
  eaccount: state.loginReducer.currentAccount.id,
  loading: state.inventoryFamilyReducer.family_loading,
  isLoading: state.inventoryFamilyReducer.isLoading,
});

const mapDispatchToProps = dispatch => ({
  getIF: filters => {
    dispatch(getIF(filters));
  },
  getMu: filters => {
    dispatch(getMu(filters));
  },
  getNiif: () => {
    dispatch(getNiif());
  },
  getAllMu: () => {
    dispatch(getAllMu());
  },
  getInfo: () => {
    dispatch(getInfo());
  },

  activationDeactivation: (id, status, filters, description) => {
    dispatch(activationDeactivation(id, status, filters, description));
  },
  updateIF: (obj, filters) => {
    dispatch(updateIF(obj, filters));
  },
  createIF: (obj, filters) => {
    dispatch(createIF(obj, filters));
  },
  createInfo: obj => {
    return dispatch(createInfo(obj));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableInventoryFamily);
