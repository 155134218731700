import { GET_COMPLAINTS, LOADING } from "../actions/actionTypes";
const initialState = {
    allReportsComplaints: [],
    totalReportsComplaints: 0,
    Authorization: "",
    loading: false,

};
export const complaintsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPLAINTS:
            return {
                ...state,
                allReportsComplaints: action.payload,
                totalReportsComplaints: action.total
            }
        case LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}