import {
    GET_SUBSPECIALITY, SET_INDEX_SUBSPECIALITY
} from "../actions/actionTypes";

const initialState = {
    subspecialities: [],
    allsubspecialities: [],
    totalsubspecialities: 0,
    displayError: false,
    error: null,
    Authorization:""
   
   };
export const subspecialitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSPECIALITY:
            return{
                ...state,
                subspecialities: action.payload,
                Authorization:action.Authorization
            };
        case SET_INDEX_SUBSPECIALITY:
            return{
                ...state,
                totalsubspecialities: action.payload,
                displayError: false,
                error: null,
                authorization: action.Authorization
            };
    default:
        return state;
    }
    
}
