//-------------- components 🍕   --------------------------------------------
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Select from 'react-select';
import { ordCustomSelect } from '../../../src/components/Layouts/react-select-custom';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';

//-------------- styles 🍕   --------------------------------------------
import tableStyles from '../../../src/components/Layouts/tableStyle.module.scss';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getPermission } from '../../helpers/helpers';
const MySwal = withReactContent(Swal);
//-------------- icons 🍕   --------------------------------------------

function TelemedicineSetup() {
  //-------------- const 🍕   --------------------------
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [valueselect, setValueselect] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [reload, setReload] = useState(0);
  const [contract, setContract] = useState(null);
  const [disablet, setDisablet] = useState(true);
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'setgg', listPermission });

  const { results: listConfig, load: ListConfigLoader, trigger: getListConfig } = useGetMethod();
  const {
    results: listContract,
    load: ListContractLoader,
    trigger: getListContract,
  } = useGetMethod();
  const { results: selectTimes, load: SelectTimesLoader, trigger: getSelectTimes } = useGetMethod();
  const { load: uptdateConfigLoader, trigger: uptdateConfig } = usePostMethod();
  //-------------- Actions 🍕   --------------------------
  useEffect(() => {
    getListConfig({
      url: '/medical/generals/configurationTm/',
      token: token,
    });
    getListContract({
      url: '/medical/generals/particularContract/',
      token: token,
    });
    getSelectTimes({
      url: '/medical/generals/times/',
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    let valueTime = 0;
    let respuesta = [];
    if (Array.isArray(listConfig?.results) && selectTimes?.results) {
      listConfig?.results.forEach(x => {
        valueTime = optionTimes?.find(e => e.value === x.time_id)?.valueTime;
        x.valueTime = Number(valueTime);
        respuesta.push(x);
      });
    }
    setValueselect(listConfig?.results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listConfig, selectTimes]);

  const renderListConfig = () => {
    let list = [];
    if (Array.isArray(valueselect)) {
      list = valueselect.map((x, index) => {
        return (
          <Row key={index}>
            <Col xs={3}>
              <b className={` ${tableStyles.tlnTextPrimary}`}>{x.description}</b>
            </Col>
            <Row>
              <Col xs={1}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Cantidad</b>
                  </Form.Label>
                  <Form.Control
                    name={'value_config'}
                    className={`ord-roundInput pleaceholderDate`}
                    type='number'
                    placeholder=''
                    // value={ }
                    key={'value_config' + index}
                    defaultValue={x.value_config}
                    onChange={e => {
                      onChangeInputForm(e, index);
                      setDisablet(false);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Unidad</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={e => {
                      onChangeSelectForm(e, index);
                      setDisablet(false);
                    }}
                    options={optionTimes}
                    value={optionTimes.find(elem => elem.value === x.time_id)}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        );
      });
    }
    return list;
  };

  let isSelected = '';
  let optionContract = [];
  if (Array.isArray(listContract.results)) {
    listContract.results.forEach(x => {
      if (x.selected_contract) {
        isSelected = Number(x.contract_id);
      }

      optionContract.push({ value: x.contract_id, label: x.corporate_client });
    });
  }
  let optionTimes = [];
  if (Array.isArray(selectTimes.results)) {
    selectTimes.results.forEach(x => {
      optionTimes.push({
        value: x.tim_id,
        label: x.tim_name,
        name: 'time_id',
        valueTime: x?.tim_value,
      });
    });
  }
  const validateMinMax = () => {
    let sw = false;
    if (Array.isArray(valueselect)) {
      valueselect.forEach(item => {
        if (item?.config === 'minimum_time_admission') {
          let multi = item?.valueTime * item?.value_config;
          if (multi > 43200) {
            sw = false;
          } else {
            sw = true;
          }
        }
      });
    }
    return sw;
  };
  const onChangeInputForm = (e, i) => {
    setTrigger(trigger + 1);
    e.preventDefault();
    let { name, value } = e.target;
    let tempArray = valueselect;
    tempArray[i][name] = Number(value);
    setValueselect(tempArray);
  };
  const onChangeSelectForm = (e, i) => {
    setTrigger(trigger + 1);
    let { name, value, valueTime } = e;
    let tempArray = valueselect;
    tempArray[i][name] = Number(value);
    tempArray[i]['valueTime'] = Number(valueTime);
    setValueselect(tempArray);
  };

  const uptdate = () => {
    if (!validateMinMax()) {
      return MySwal.fire({
        icon: 'info',
        title: 'Error al guardar las configuraciones',
        text: 'El tiempo mínimo de ingreso previo a consulta no puede ser mayor a 12 horas.',
      });
    }

    uptdateConfig({
      url: '/medical/generals/configurationTm/',
      token: token,
      method: 'PUT',
      body: valueselect,
      doAfterSuccess: () => {
        setReload(reload + 1);
        setDisablet(true);
      },
    });
    if (contract !== null) {
      uptdateConfig({
        url: '/medical/generals/particularContract/',
        token: token,
        method: 'PUT',
        body: { contract: contract },
        doAfterSuccess: () => {
          setReload(reload + 1);
          setContract(null);
          setDisablet(true);
        },
      });
    }
  };
  //-------------- Principal return 🍕   --------------------------
  return (
    <div className={`${tableStyles.tlnContainer} mt-4`}>
      {(ListConfigLoader || uptdateConfigLoader || SelectTimesLoader || ListContractLoader) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <OrdGenericTemplate showBottomBorder={false} title={'Configuración'}>
        <Row>
          <Col xs={12} className='my-4'>
            <b className={`${tableStyles.tlnTextPrimary}`} style={{ fontSize: '16px' }}>
              Parámetros de consulta
            </b>
          </Col>
        </Row>
        {renderListConfig()}
        <Row>
          <Col xs={12} className='mt-3'>
            <b className={`${tableStyles.tlnTextPrimary}`}>Contrato particular para telemedicina</b>
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1' style={{ fontSize: '14px' }}>
                  Unidad
                </b>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionContract}
                value={
                  contract
                    ? optionContract.find(x => x.value === contract)
                    : optionContract.find(x => x.value === isSelected)
                }
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                onChange={e => {
                  setContract(e.value);
                  setDisablet(false);
                }}
              ></Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className='mb-5 mt-4'>
          <Col xs={1}></Col>
          {myPermission?.create && (
            <Col xs={2} className='text-end'>
              <button
                disabled={disablet}
                onClick={() => uptdate()}
                className={`${tableStyles.tlnBtnDark} w-auto text-white fw-bold`}
              >
                Guardar
              </button>
            </Col>
          )}
        </Row>
      </OrdGenericTemplate>
    </div>
  );
}
export default TelemedicineSetup;
