import doc from '../../assets/img/icons/documentos.svg';
import notebank from '../../assets/img/icons/notabancaria.svg';
import egreso from '../../assets/img/icons/egreso.svg';
import recibocaja from '../../assets/img/icons/recibocaja.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import '../Consultation/Consultation.scss';
import { useHistory } from 'react-router-dom';
import { Title } from '../../shared';

function QueryType() {
  let history = useHistory();
  function handleClickDocumento() {
    history.push('/tesoreria/Consulta/Documentos');
  }
  function handleClickNotaBancaria() {
    history.push('/tesoreria/Consulta/NotaBancaria');
  }
  function handleClickEgreso() {
    history.push('/tesoreria/Consulta/Egresos');
  }
  function handleClickReciboCaja() {
    history.push('/tesoreria/Consulta/ReciboCaja');
  }

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <div className='px-5'>
          <Title
            title='Tipos de consulta'
            className={'mb-2'}
            onClickIcon={() => {
              history.push('/tesoreria/inicio');
            }}
          />
          <h4 className='text-secondary'>Selecciona el tipo de consulta a realizar</h4>
        </div>

        <Row className='p-5 d-flex'>
          <Col xs={4} className='pt-5'>
            <button className='botn' onClick={handleClickDocumento}>
              <div className='col-auto p-4 text-center'>
                <img src={doc} alt='' srcSet='' />
                <p className='text-secondary'>Documentos</p>
              </div>
            </button>
          </Col>
          <Col xs={4} className='pt-5'>
            <button className='botn' onClick={handleClickNotaBancaria}>
              <div className='col-auto p-4 text-center'>
                <img src={notebank} alt='' srcSet='' />
                <p className='text-secondary'>Notas Bancarias</p>
              </div>
            </button>
          </Col>
          <Col xs={4} className='pt-5'>
            <button className='botn' onClick={handleClickEgreso}>
              <div className='col-auto p-4 text-center'>
                <img src={egreso} alt='' srcSet='' />
                <p className='text-secondary'>Egresos</p>
              </div>
            </button>
          </Col>
          <Col xs={4} className='pt-5'>
            <button className='botn' onClick={handleClickReciboCaja}>
              <div className='col-auto p-4 text-center'>
                <img src={recibocaja} alt='' srcSet='' />
                <p className='text-secondary'>Recibos de caja</p>
              </div>
            </button>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default QueryType;
