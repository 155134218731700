import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Drawer } from '@mui/material';
import moment from 'moment';
import { Send } from '@material-ui/icons';
//
import IconBack from '../../assets/img/icons/iconBack.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { convertMoneyFormat, customSwaltAlert, loader } from '../../helpers';
//
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';

export default function InvoiceSupports() {
  const params = useParams();
  const history = useHistory();
  const storage = useSelector(state => state);

  const [selectedDocument, setSelectedDocument] = useState(false);
  const [commentsDrawer, setCommentsDrawer] = useState({
    isOpen: false,
    readOnly: false,
    isValid: undefined,
    supportId: undefined,
  });

  const {
    results: invoiceDetails,
    trigger: getInvoiceDetails,
    load: detailsLoader,
  } = useGetMethod();
  const { results: commentsResult, trigger: getComments, load: commentsLoader } = useGetMethod();

  const { trigger: commentAction, load: commentActionLoader } = usePostMethod();

  const invoice = useMemo(() => invoiceDetails?.results?.[0], [invoiceDetails?.results]);
  const supportFiles = useMemo(() => {
    if (invoice?.supports) {
      return invoice.supports.map(el => ({
        id: el.support,
        name: 'Soporte',
        date: '22/12/23',
        status: 'Pendiente',
        status_bg: '#fff3bf',
        status_color: '#fcc419',
        quantity: el.quantity,
      }));
    }
    return [];
  }, [invoice?.supports]);

  useEffect(() => {
    if (params.id) {
      getInvoiceDetails({
        url: '/receivable/receiptRecord/getSupport/',
        objFilters: {
          id: params.id,
          eaccount: storage.loginReducer.currentAccount.id,
        },
        token: storage.loginReducer.Authorization,
      });
    }
  }, [
    params.id,
    getInvoiceDetails,
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
  ]);

  const onOpenCommentsDrawer = async (supportId, readOnly, validate) => {
    setCommentsDrawer({
      isOpen: true,
      readOnly: readOnly,
      isValid: validate,
      supportId,
    });
    await getComments({
      url: '/receivable/receiptRecord/getComments/',
      objFilters: {
        invoice: params.id,
        support: supportId,
      },
      token: storage.loginReducer.Authorization,
    });
  };

  const onCloseCommentsDrawer = () => {
    setCommentsDrawer({
      isOpen: false,
      readOnly: false,
    });
  };

  const onSendComment = event => {
    event.preventDefault();
    const { comment } = event.target;

    if (!comment.value) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Es necesario diligenciar el comentario.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${commentsDrawer?.isValid ? 'validará' : 'invalidará'} el documento.`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        commentAction({
          url: '/receivable/receiptRecord/comments/',
          token: storage.loginReducer.Authorization,
          body: {
            invoice: params.id,
            support: commentsDrawer?.supportId,
            created_by: storage.loginReducer.user_data.id,
            comments: comment.value,
          },
          method: 'POST',
          succesAction: () => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: `Se ha ${commentsDrawer?.isValid ? 'validado' : 'invalidado'} el documento.`,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                onCloseCommentsDrawer();
                getInvoiceDetails({
                  url: '/receivable/receiptRecord/getSupport/',
                  objFilters: {
                    id: params.id,
                    eaccount: storage.loginReducer.currentAccount.id,
                  },
                  token: storage.loginReducer.Authorization,
                });
              }
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const renderDrawer = () => {
    return (
      <Drawer
        open={commentsDrawer.isOpen}
        anchor='right'
        onClose={() => onCloseCommentsDrawer()}
        PaperProps={{ style: { width: 360 } }}
      >
        <div className='d-flex flex-column p-4 h-100 overflow-hidden'>
          <div
            className={`${tableStyles.tlndrawer__close} align-self-end`}
            onClick={() => onCloseCommentsDrawer()}
          ></div>
          <h4 className='text-primary m-0 my-4 fw-bold'>Seguimiento</h4>
          <div className='d-flex flex-column h-100' style={{ overflowY: 'auto' }}>
            {!commentsLoader && (
              <>
                {commentsResult.results?.length ? (
                  commentsResult.results?.map(item => (
                    <div
                      key={item.id}
                      className='small mb-2 border-bottom'
                      style={{
                        backgroundColor:
                          storage.loginReducer.user_data.id === item?.userId
                            ? '#F5F7FA'
                            : undefined,
                      }}
                    >
                      <div className='text-primary mb-2'>{item.name}</div>
                      <p>{item.comments}</p>
                      <p className='text-end text-primary'>
                        {moment(item.created_at).format('DD/MM/YYYY hh:mm')}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className='text-muted text-center'>No hay comentarios disponibles.</p>
                )}
              </>
            )}
          </div>

          {!commentsDrawer.readOnly && (
            <div
              className='card pt-3 px-3 pb-1 mt-3'
              style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
            >
              <small className='text-primary'>
                {storage.loginReducer.user_data.first_name}{' '}
                {storage.loginReducer.user_data.first_surname}
              </small>
              <form onSubmit={event => onSendComment(event, invoice.id)}>
                <textarea
                  className='register-inputs'
                  name='comment'
                  placeholder='Escribe...'
                  style={{ minHeight: 60 }}
                />
                <div className='text-end'>
                  <button type='submit'>
                    <Send className='text-primary pointer mt-2' />
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  const renderDocumentsTable = () => {
    return (
      <>
        <div className='col-lg-6 d-flex flex-column'>
          <div
            className='row g-0 p-2 text-white fw-bold small'
            style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <div className='col'>Documento</div>
            <div className='col'>Fecha adjunto</div>
            <div className='col-3 text-center'>Estado</div>
            <div className='col-2'></div>
          </div>

          <div className={`${tableStyles.odd_rows} text-muted border-0 small`}>
            {supportFiles.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`row g-0 pointer p-2 ${tableStyles.hoverable_row}`}
                  onClick={() => setSelectedDocument(true)}
                >
                  <div className='col'>
                    <span className=''>{item.name}</span>
                  </div>
                  <div className='col'>
                    <span className=''>{item.date}</span>
                  </div>
                  <div className='col-3'>
                    <div
                      style={{
                        backgroundColor: item.status_bg,
                        color: item.status_color,
                      }}
                      className={`${tableStyles.baseStatus} px-2`}
                    >
                      {item.filing_status ?? item.status}
                    </div>
                  </div>
                  <div className='col-2 justify-content-end d-flex align-items-center'>
                    <div
                      className={`${tableStyles.message_icon} me-2`}
                      onClick={() => onOpenCommentsDrawer(item.id, true)}
                    >
                      {item.quantity}
                    </div>
                    <CustomPopupExtend
                      position='right'
                      triggerSrc={ThreeDots}
                      extraButtons={[
                        {
                          text: 'Validar',
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => onOpenCommentsDrawer(item.id, false, true),
                        },
                        {
                          text: 'Invalidar',
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => onOpenCommentsDrawer(item.id, false, false),
                        },
                      ]}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <>
        {(detailsLoader || commentsLoader || commentActionLoader) && loader}

        <div className='container-fluid px-5'>
          <div className='d-flex align-items-center my-5'>
            <img onClick={() => history.goBack()} src={IconBack} className='pointer me-3' alt='' />
            <h1 className='m-0'>Soportes factura</h1>
          </div>

          <div style={{ padding: '0 35px 70px 35px' }}>
            <h4 className={`${tableStyles.text_primary_alt} fw-bold`}>Información del cliente</h4>

            {invoice && (
              <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
                <div className='row small'>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Empresa cliente</div>
                    <div className='text-muted'>{invoice.clientName}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>NIT</div>
                    <div className='text-muted'>{invoice.clientNit}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Modalidad</div>
                    <div className='text-muted'>
                      {invoice.modality === 'group' ? 'Grupo' : 'Individual'}
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Contrato</div>
                    <div className='text-muted'>{invoice.contract}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>No. Factura</div>
                    <div className='text-muted'>{invoice.sequence}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Valor Factura</div>
                    <div className='text-muted'>{convertMoneyFormat(invoice.totalAmount)}</div>
                  </div>
                </div>
              </div>
            )}

            <div className='row pt-3'>
              <div className='col-lg-6'>
                <div className='shadow p-3 text-center' style={{ borderRadius: 10 }}>
                  {!selectedDocument ? (
                    <div className='text-muted'>Seleccione un documento para previsualizar.</div>
                  ) : (
                    <img
                      className='img-fluid'
                      width={500}
                      src='https://www.smartsheet.com/sites/default/files/2022-02/IC-Simple-Fillable-and-Printable-Receipt-Template_WORD.png'
                      alt=''
                    />
                  )}
                </div>
              </div>

              {renderDocumentsTable()}
            </div>
          </div>
        </div>
        {renderDrawer()}
      </>
    );
  };

  return render();
}
