import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Header from '../../components/Header/Header';
import { showAccountInfo } from '../../actions/headerActions';
import layoutStyles from '../../routes/fullLayout.module.css';
import myStyles from './AccountSelect.module.css';
import { useHistory } from 'react-router-dom';
import AccountSelectRender from './AccountSelectRender';

function AccountSelect(props) {
  const history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!counter.loginReducer.Authenticated) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.loginReducer.Authenticated]);

  return (
    <div className={`${layoutStyles.fullLayout} animate__animated animate__fadeIn`}>
      <Header isAuth={props.isAuth}>Cuentas</Header>
      <div className={layoutStyles.fullLayoutBody}>
        <div
          className={layoutStyles.fullLayoutWorkArea}
          onClick={() => dispatch(showAccountInfo(false))}
        >
          <div className={myStyles.container}>
            <div className={myStyles.centralContainer}>
              <h2 className={`${myStyles.subtitle} ${myStyles.titleContainer} mb-4`}>
                Indica la cuenta con la cual deseas trabajar
              </h2>
              {/* <div className={myStyles.accountBoxWrap}> */}
              <AccountSelectRender afterClick={() => history.push('/categoryselect')} />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountSelect;
