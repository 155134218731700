
import {
    GET_PAYROLL_AUTHORIZATION,
    GET_ONE_PAYROLL_AUTHORIZATION, GET_PAYMENTS_AUTHORIZATION, GET_CARD_CREDIT
} from "../actions/actionTypes";

const initialState = {
    listPayrollAuthorization: [],
    onePayrollAuthorization: [],
    paymentslAuthorization: [],
    getCardCredit: [],
    authorization_loading : false,
    card_loading: false,
    paymentA_loading: false
};

export const accountingMovementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYROLL_AUTHORIZATION:
            return {
                listPayrollAuthorization: action.payload,
                authorization_loading: action.loading
            };
        case GET_ONE_PAYROLL_AUTHORIZATION:
            return {
                onePayrollAuthorization: action.payload,
            };
        case GET_PAYMENTS_AUTHORIZATION:
            return {
                paymentslAuthorization: action.payload,
                paymentA_loading: action.loading

            };
        case GET_CARD_CREDIT:
            return {
                getCardCredit: action.payload,
                card_loading : action.loading
            };
        default:
            return state;
    }
};