
import {
  CREATE_SITE_SUCCESS,
  GET_SITES,
  SET_INDEX_SITE,
  GET_RESOLUTIONS,
  SITE_RED_LOADING,
  GET_SITES_SELECT,
  GET_DISTANCES_SELECT
} from "../actions/actionTypes";

const initialState = {
  sites: [],
  totalSites: 0,
  createSucces: false,
  site_loading: false,
  resolutions: [],
  sitesSelect: [],
  distancesSelect: [],
  loading: false
};
export const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SITES:
      return {
        ...state,
        sites: action.payload,
        site_loading: action.loading
      };
    case GET_SITES_SELECT:
      return {
        ...state,
        sitesSelect: action.payload,
        site_loading: action.loading
      };
    case GET_DISTANCES_SELECT:
      return {
        ...state,
        distancesSelect: action.payload,
        site_loading: action.loading
      };
    case SET_INDEX_SITE:
      return {
        ...state,
        totalSites: action.payload,
      };
    case CREATE_SITE_SUCCESS:
      return {
        ...state,
        createSucces: action.payload
      }
    case GET_RESOLUTIONS:
      return {
        ...state,
        resolutions: action.payload
      }
    case SITE_RED_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state;
  }
};
