import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getDetailRecord, receiveRecord } from '../../actions/receiptOfInvoicesActions';
import Excel from '../../assets/img/icons/excel.svg';
import iconBack from '../../assets/img/icons/iconBack.svg';
import Attach from '../../assets/img/icons/insertar.svg';
import PDF from '../../assets/img/icons/pdf.svg';
import Word from '../../assets/img/icons/word.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { MAX_SIZE_FILE } from '../../helpers/constants';

const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;
// eslint-disable-next-line no-multi-str
const type_file =
  'csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf';

function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
}

function DocSignature(props) {
  const MySwal = withReactContent(Swal);
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const [info, setInfo] = useState({
    name: '',
    identification: '',
    file: '',
    file64: '',
    fileUrl: '',
    type: '',
    id: '',
  });

  useEffect(() => {
    if (props.selectedRecord) {
      dispatch(
        getDetailRecord({
          id: props.selectedRecord,
          eaccount: storage.loginReducer.currentAccount.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const handleImagePick = async e => {
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setInfo({
      ...info,
      file: e.target.files[0],
      file64: file64,
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const header = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha factura
    </th>,
    <th key={3} className='text-center'>
      Valor
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.detail.invoices)) {
      table = storage.invoiceReducer.detail.invoices.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-uppercase'>{x.invoice}</td>
            <td>{x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
            <td>{x.amount ? '$' + numberWithCommas(x.amount) : ''}</td>
          </tr>
        );
      });
    }
    return table;
  };

  var today = new Date();

  const buttonReceive = () => {
    dispatch(
      receiveRecord({
        id: props.selectedRecord,
        eaccount: storage.loginReducer.currentAccount.id,
        name: info.name,
        identification: info.identification,
        file64: info.file64,
        type: props.type,
        typeDoc: info.file ? info.file.type.split('/')[1] : filenameState.type_doc,
      }),
    );
  };

  const [filenameState, setfilenameState] = useState({
    name_doc: '',
    type_doc: '',
  });

  const validateFile = async acceptedFile => {
    let size = acceptedFile[0].size;
    if (size > MAX_SIZE_FILE) {
      MySwal.fire({
        icon: 'error',
        title: 'El archivo es demasiado pesado',
        footer: 'El tamaño máximo permitido es de 5MB',
      });
    }
    if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
      MySwal.fire({
        icon: 'error',
        title: 'El formato del archivo debe ser PDF, WORD, EXCEL',
        footer: 'El tamaño máximo permitido es de 5MB',
      });
    }
  };

  const docType = data => {
    let iconDoc;
    if (data) {
      switch (data) {
        case 'application/pdf':
          iconDoc = PDF;
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          'application/msword':
          iconDoc = Word;
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          'csv' ||
          'application/vnd.ms-excel':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  const onChangeDoc = async e => {
    var f = e.target.files[0];
    var name_doc = f.name.split('.')[0];
    var type_doc = f.type;
    setfilenameState({ name_doc: name_doc, type_doc: type_doc });
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setInfo({
      ...info,
      file: e.target.files[0],
      file64: file64,
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
  };

  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem', width: '60%' }}>
      <div className='d-flex' style={{ marginTop: '40px', alignItems: 'end' }}>
        <img
          alt='2'
          style={{ height: '43px', paddingBottom: '10px' }}
          className={`mr-3 cursorPointer`}
          src={iconBack}
          onClick={() => props.setDetails({ show: false })}
        />

        <h1 className={tableStyles.title} style={{ margin: '0px' }}>
          Acta de facturación
        </h1>
      </div>
      <div className='text-end'>
        <p></p>
      </div>
      <Row className='d-flex'>
        <Col>
          <p>
            Por medio del presente documento se da constancia de radicación de las facturas anexadas
            a la empresa {storage.invoiceReducer.detail.clientName} con NIT{' '}
            {storage.invoiceReducer.detail.clientNit}, en la dirección{' '}
            {storage.invoiceReducer.detail.clientAddress} de la ciudad de Barranquilla, el día{' '}
            {String(today.getDate()).padStart(2, '0')}, del mes{' '}
            {String(today.getMonth() + 1).padStart(2, '0')} del {today.getFullYear()}.
          </p>
        </Col>
      </Row>

      <GenericTable className='mb-6' headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      {props.type === 'P' ? (
        <div className='d-flex mt-5'>
          <div className='col-4 mr-3'>
            <div>
              <label className={tableStyles.crudModalLabel}>Nombre</label>
              <input
                placeholder='Escribir...'
                className={IndividualStyles.registerInputs}
                type='text'
                value={info.name}
                onChange={e =>
                  setInfo({
                    ...info,
                    name: e.target.value,
                  })
                }
              ></input>
            </div>
            <div>
              <label className={tableStyles.crudModalLabel}>No. Documento identidad</label>
              <input
                placeholder='Escribir...'
                className={IndividualStyles.registerInputs}
                type='text'
                value={info.identification}
                onChange={e =>
                  setInfo({
                    ...info,
                    identification: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          <div className='col-8'>
            <div className='display-grid'>
              <label
                htmlFor='imageUpload'
                className={` ${/*IndividualStyles.imageInputLabel*/ IndividualStyles.divfir} `}
              >
                {info?.fileUrl ? (
                  <img
                    className={`${IndividualStyles.profilepic} ${IndividualStyles.hoverPointer} `}
                    src={info?.fileUrl}
                    alt='firma'
                  />
                ) : (
                  ''
                )}
                <input
                  accept='image/png, image/jpeg'
                  id='imageUpload'
                  name='imageUpload'
                  type='file'
                  onChange={handleImagePick}
                  className={IndividualStyles.imageInputBtn}
                />
              </label>
              <div className='w-100 mt-2' style={{ height: '1px', border: 'solid 1px' }}></div>
              <label className={`${tableStyles.crudModalLabelGris} text-center m-0`}>
                <b>Firma</b>
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex'>
          <div className='col-5'>
            <div className='display-grid'>
              <label className={`${tableStyles.crudModalLabel} m-0`}>Cargar soporte</label>
            </div>
            <div style={{ height: '90px', width: '100%' }}>
              <Dropzone onDrop={acceptedFile => validateFile(acceptedFile)} accept={type_file}>
                {({ getRootProps, getInputProps }) => (
                  <section
                    className='w-100 h-100 p-1 text-center mx-auto'
                    style={{ border: 'solid 1px #005DBF', borderRadius: '10px' }}
                  >
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        type='file'
                        name='documents'
                        onChange={onChangeDoc}
                        accept={type_file}
                        multiple={false}
                      />
                      {filenameState.name_doc ? (
                        <div
                          id='2'
                          className={`text-muted text-center "px-3 pt-3 pb-5": "p-5" ${IndividualStyles.filesDir}`}
                        >
                          <img
                            src={docType(filenameState.type_doc)}
                            alt='icon'
                            className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.inputMargin}`}
                          />
                          <p className={``}>{filenameState.name_doc}</p>
                        </div>
                      ) : (
                        <p
                          className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}
                        >
                          {'Soltar archivo aquí'}
                        </p>
                      )}

                      {
                        <img
                          src={Attach}
                          alt='añadir'
                          className={`${IndividualStyles.imagePaddingType} ${IndividualStyles.Aling}`}
                        />
                      }

                      {/* {typeof (this.state.addArticle.data_sheet) === 'string' && this.state.addArticle.data_sheet !== ""  ? (
                        <div>
                          <p className={`text-muted text-center $ "px-5 pt-3 pb-5": "p-5"} hoverPointer`}>{this.state.addArticle.data_sheet ? this.state.addArticle.description : ""}</p>
                          <a className="text-center" href={this.state.addArticle.data_sheet} target="_blank">Descargar</a>
                        </div>
                        ): (
                          <p className={`text-muted text-center ${this.state.fileValid && this.state.addArticle.data_sheet !== "" ? "px-5 pt-3 pb-5": "p-5"} hoverPointer`}>{this.state.fileValid && this.state.fileUpload ? this.state.fileUpload[0].name : "Soltar archivo aquí"}</p>
                        )} */}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
          <div className={IndividualStyles.bottomRow} style={{ marginTop: '20px' }}>
            <Button
              style={{ height: 'max-content', alignSelf: 'center', border: '0px' }}
              className={`${tableStyles.buttonExtra3} d-block`}
              onClick={() => buttonReceive()}
              disabled={!!(info.file64 === '')}
            >
              Radicar
            </Button>
            <Button
              style={{ height: 'max-content', alignSelf: 'center' }}
              className={tableStyles.btnSecondary}
              onClick={() => props.setDetails({ show: false })}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}

      {props.type === 'P' ? (
        <div className={IndividualStyles.bottomRow} style={{ marginTop: '20px' }}>
          <Button
            style={{ border: '0px' }}
            className={`${tableStyles.buttonExtra3} d-block`}
            onClick={() => buttonReceive()}
            disabled={
              !!(info.name === '') || !!(info.identification === '') || !!(info.file64 === '')
            }
          >
            Radicar
          </Button>
          <Button
            className={tableStyles.btnSecondary}
            onClick={() => props.setDetails({ show: false })}
          >
            Cancelar
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
export default DocSignature;
