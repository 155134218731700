//react
import { useEffect, useState } from 'react';
import Select from 'react-select';

//helpers
import { loader,formatteDocument } from '../../../../helpers';

//icons
import ordBlueSearch from '../../../../../src/assets/img/icons/ordBlueSearch.svg';

// hooks
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../../Hooks/useFetch';

//css
import styles from '../../../../components/Layouts/tableStyle.module.scss';

//components
import { Box } from '@mui/material';
import moment from 'moment';
import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import { ordCustomSelect } from '../../../Layouts/react-select-custom';

export const QueriesOpportunity = () => {
  //constants-------------------------

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const firstDayDateMonth = moment(new Date()).startOf('month').format('YYYY-MM-DD');
  const lastDayDateMonth = moment(new Date()).format('YYYY-MM-DD');
  //states
  const [data, setData] = useState({
    filters: {
      userId,
      page: 1,
      perpage: 10,
      search: undefined,
      dateFilter: undefined,
      deviationFilter: undefined,
      dateMin: firstDayDateMonth,
      dateMax: lastDayDateMonth,
    },
    trigger: 0,
  });

  // headers
  const headers = [
    {
      title: 'Paciente',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Empresa',
      className: 'px-2 text-start col-3',
    },
    {
      title: 'Contrato',
      className: `px-2 text-start col-3`,
    },
    {
      title: 'Servicio',
      className: 'px-2 text-start col-3',
    },
    {
      title: 'Agendamiento',
      className: 'px-2 text-center',
    },
    {
      title: 'Óptimo',
      className: `px-2 text-center`,
    },
    {
      title: 'Cita',
      className: `px-2 text-center`,
    },
    {
      title: 'Desviación',
      className: 'px-3 text-center',
    },
  ];

  // functions

  const incrementTrigger = () => {
    setData(state => ({
      ...state,
      trigger: state.trigger + 1,
    }));
  };

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              <div>{item?.patientName || '-'}</div>
              <div className='d-flex gap1'>
                <span>{item?.documentType || '-'}&nbsp;</span>
                <span>
                  {formatteDocument(item?.document).format || '-'}
                </span>
              </div>
            </td>

            <td className='text-start px-2'>
              <span>{item?.corporateClient || '-'}</span>
            </td>

            <td className='text-start px-2'>
              <span>{item?.contractName || '-'}</span>
            </td>

            <td className='text-start px-2'>
              <span>{item?.serviceName || '-'}</span>
            </td>

            <td className='text-center px-2 w-100' style={{ minWidth: '3rem' }}>
              <span>{item?.initialDate || '-'}</span>
            </td>

            <td className='text-center px-2 w-100' style={{ minWidth: '6rem' }}>
              <span>{item?.optimalDate || '-'}</span>
            </td>

            <td className='text-center px-2 w-100' style={{ minWidth: '6rem' }}>
              <span>{item?.appointmentDate || '-'}</span>
            </td>

            <td className='text-center px-3 w-100'>
              <span>{typeof item?.deviation === 'number' ? item?.deviation : '-'}</span>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  // hooks

  const {
    load: loaderListOpportunity,
    results: listOpportunity,
    trigger: getListOpportunity,
  } = useGetMethod();

  useEffect(() => {
    getListOpportunity({
      token,
      objFilters: data?.filters,
      url: '/medical/admissions/opportunity/',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.trigger]);

  return (
    <>
      {loaderListOpportunity && loader}

      <OrdGenericTemplate titleSize={12} className={'mx-2'} title={'Consulta de oportunidad'}>
        <Box className={`mb-3 asistencialDateColorIcon ${styles.app_mr_100}`}>
          <Box
            display={'grid'}
            gridTemplateColumns={'190px 170px 170px 170px 1fr'}
            gridAutoRows={'100%'}
            gap={2}
          >
            <Box>
              <span className={`m-0 d-block ${styles.ordDarkBlueText} fw-bold`}>
                &nbsp;Buscar por desviación
              </span>
              <Select
                placeholder='Seleccionar...'
                defaultValue={{ label: 'Seleccionar...', value: '' }}
                styles={ordCustomSelect}
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Positiva', value: 'positive' },
                  { label: 'Negativa', value: 'negative' },
                  { label: 'Cero', value: 'zero' },
                ]}
                onChange={({ value }) => {
                  setData(state => ({
                    ...state,
                    filters: {
                      ...state.filters,
                      deviationFilter: value,
                    },
                  }));
                }}
              />
            </Box>

            <Box>
              <span className={`m-0 d-block ${styles.ordDarkBlueText} fw-bold`}>
                &nbsp;Buscar por fecha
              </span>
              <Select
                styles={ordCustomSelect}
                placeholder='Seleccionar...'
                noOptionsMessage={() => 'No hay datos'}
                defaultValue={{ label: 'Seleccionar...', value: '' }}
                options={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Agendamiento', value: 'initial' },
                  { label: 'Óptimo', value: 'optimal' },
                  { label: 'Cita', value: 'zero' },
                ]}
                onChange={({ value }) => {
                  setData(state => ({
                    ...state,
                    filters: {
                      ...state.filters,
                      dateFilter: value,
                      dateMin: firstDayDateMonth,
                      dateMax: lastDayDateMonth,
                    },
                  }));
                }}
              />
            </Box>

            <Box>
              <span className={`m-0 d-block ${styles.ordDarkBlueText} fw-bold`}>
                &nbsp;Fecha desde
              </span>
              <input
                type='date'
                className={`ord-roundInput2`}
                disabled={!data.filters.dateFilter}
                max={data.filters.dateFilter === 'initial' ? lastDayDateMonth : undefined}
                style={data?.filters?.dateMin ? { color: '#1a538d' } : {}}
                value={!data.filters.dateFilter ? '' : data?.filters?.dateMin}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    filters: {
                      ...state?.filters,
                      dateMin: e.target.value,
                      dateMax: e.target.value ? lastDayDateMonth : e.target.value,
                    },
                  }));
                }}
              />
            </Box>

            <Box>
              <span className={`m-0 d-block ${styles.ordDarkBlueText} fw-bold`}>&nbsp;Hasta</span>
              <input
                className={`ord-roundInput2`}
                style={data?.filters?.dateMax ? { color: '#1a538d' } : {}}
                type='date'
                disabled={!data.filters.dateFilter || !data.filters.dateMin}
                max={data.filters.dateFilter === 'initial' ? lastDayDateMonth : undefined}
                value={!data.filters.dateFilter ? '' : data?.filters?.dateMax}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    filters: { ...state?.filters, dateMax: e.target.value },
                  }));
                }}
              />
            </Box>

            <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
              <input
                className={`ord-roundInput ${styles.ordDarkGrayText} w-100 pe-2`}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={e => {
                  setData(state => ({
                    ...state,
                    filters: { ...state?.filters, search: e.target.value },
                  }));
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') incrementTrigger();
                }}
              />
              <img
                src={ordBlueSearch}
                className='pointer'
                alt='buscar'
                onClick={incrementTrigger}
              />
            </Box>
          </Box>

          <OrdTable
            shadow
            hasChildren
            headers={headers}
            paginate={{
              activePage: data?.filters.page,
              totalPages: listOpportunity?.rowTotal,
              perPage: data?.filters?.perpage,
              pageRangeDisplayed: 3,
              showTextDetails: true,
              onChangePage: async e => {
                setData(state => ({
                  ...state,
                  trigger: state.trigger + 1,
                  filters: {
                    ...state.filters,
                    page: e,
                  },
                }));
              },
            }}
          >
            {formatData(listOpportunity?.results || [])}
          </OrdTable>
        </Box>
      </OrdGenericTemplate>
    </>
  );
};
