/**
 * Redirects to a new route, either internally using React Router or externally with window.location.href.
 *
 * @param {Object} options - Options for the redirection.
 * @param {Object} [options.history=null] - React Router's history object for internal redirections.
 * @param {string} options.path - The path to redirect to. It can contain placeholders like ':id'.
 * @param {Object} [options.params] - An object of additional parameters that can be added to the query string or passed in the React Router state.
 * @param {Object} [options.getParams={}] - An object of parameters used to replace placeholders in the path or to add query parameters.
 * @param {boolean} [options.useQueryParams=false] - If true, adds parameters from getParams that are not in the path to the query string.
 * @param {boolean} [options.isInternal=true] - Defines whether the redirection is internal (using history) or external (using window.location.href).
 */
export const historyRedirect = ({ history = null, path, params, getParams = {}, useQueryParams = false, isInternal = true }) => {
    const queryParams = [];
    Object.keys(getParams).forEach((key) => {
        const value = encodeURIComponent(getParams[key]);
        const paramInPath = `:${key}`;
        if (path.includes(paramInPath)) {
            path = path.replace(paramInPath, value);
        } else if (useQueryParams) {
            queryParams.push(`${key}=${value}`);
        }
    });

    if (queryParams.length > 0) {
        path += `${queryParams.join('&')}`;
    }

    if (params && Object.keys(params).length > 0 && !isInternal) {
        // If there are parameters and the redirection is not internal,
        // concatenate all parameters in a special format (key1=value1-key2=value2)
        // and append them to the path as a single "params" variable.
        // This allows sending multiple parameters and retrieving them together in a single variable.
        const paramsArray = Object.entries(params).map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
        path += `&params=${paramsArray.join('-')}`;
    }

    if (isInternal && history) {
        history.push({
            pathname: path,
            state: params,
        })
    } else if (!isInternal) {
        window.location.href = path;
    }
}
