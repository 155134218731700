/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  getAccountable,
  getClient,
  getReceiptInvoices,
} from '../../actions/receiptOfInvoicesActions';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { Title } from '../../shared';
import DetailReceiptRecord from './DetailReceiptRecord';

function RecordsFiling() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [details, setDetails] = useState({
    show: false,
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    filing_status: 'filed',
    client: '',
    minuteNumber: '',
    accountableId: '',
  });

  useEffect(() => {
    dispatch(getReceiptInvoices(filters));
  }, [trigger]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(
      getAccountable({ type: 'receiptMinute', eaccount: storage.loginReducer.currentAccount.id }),
    );
  }, []);

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsAccountable = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.accountables)) {
    storage.invoiceReducer.accountables.forEach(item => {
      optionsAccountable.push({
        value: item.id,
        label: item.name,
        key: item.id + 'accountables',
      });
    });
  }
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const header = [
    <th key={1} className='text-center'>
      No. Acta
    </th>,
    <th key={2} className='text-center'>
      Fecha radicación
    </th>,
    <th key={3} className='text-center'>
      NIT
    </th>,
    <th key={4} className='text-center'>
      Empresa
    </th>,
    <th key={5} className='text-center'>
      Responsable
    </th>,
    <th key={6} className='text-center'>
      Valor total
    </th>,
    <th key={7} className='text-center'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.invoices)) {
      table = storage.invoiceReducer.invoices.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-uppercase'>{x.sequence}</td>
            <td>{x.filing_date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
            <td>{x.clientNit}</td>
            <td>{x.clientName}</td>
            <td>{x.generatorFullName}</td>
            <td>{x?.total_amount ? '$' + numberWithCommas(Number(x.total_amount)) : ''}</td>
            <td className='text-center px-2'>
              <img
                alt='q'
                className='text-start cursorPointer'
                src={Filtrar}
                onClick={() => setDetails({ show: true, receiptRecord: x.id })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const principalRender = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {storage.invoiceReducer.loading_receipt_invoice && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={tableStyles.title}>
        <Title
          title='Actas de radicación'
          onClickIcon={() => {
            history.push('/cartera/inicio');
          }}
        />
      </div>

      <div className='d-flex gap-3 align-items-end'>
        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>No. Acta</label>
            <input
              className={IndividualStyles.registerInputs}
              placeholder='Escribir...'
              type='text'
              value={filters.minuteNumber}
              onChange={e => setFilters({ ...filters, minuteNumber: e.target.value })}
            ></input>
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsClient}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters({
                  ...filters,
                  client: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_client'
            />
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>Responsable</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsAccountable}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters({
                  ...filters,
                  accountableId: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_accountable'
            />
          </form>
        </div>

        <div>
          <i>
            <form onSubmit={e => handleSearch(e)}>
              <img
                style={{ width: '25px' }}
                className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                src={Lupa}
                alt=''
                onClick={() => setTrigger(trigger + 1)}
              />
            </form>
          </i>
        </div>
      </div>

      <GenericTable headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.invoices ? filters.page : ''}
            {' de '}
            {Math.ceil(storage.invoiceReducer?.invoices_total / filters.perpage)
              ? Math.ceil(storage.invoiceReducer.invoices_total / filters.perpage)
              : ''}{' '}
            ({storage.invoiceReducer.invoices_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.invoices_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );
  return details.show ? (
    <DetailReceiptRecord
      selectedRecord={details.receiptRecord}
      show={details.show}
      setDetails={setDetails}
      id={details.receiptRecord}
    />
  ) : (
    principalRender
  );
}
export default RecordsFiling;
