//react
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

//icons
import previewIcon from '../../assets/img/icons/arrowDerechaCalendario.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';

//utils
import { customSwaltAlertAsistencial, formatteDocument, loader } from '../../helpers';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../helpers/numberFormatting';

import NumberFormat from 'react-number-format';

//styles
import styles from '../../components/Layouts/tableStyle.module.scss';

//hooks
import { useEffect } from 'react';
import { useGetMethod, usePostMethod } from '../../Hooks';

//components
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';

const localeSeparators = getLocaleSeparators();

export const AdmissionGeneralBalance = () => {
  // states
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const initialStateFilters = {
    status: '',
    page: 1,
    perpage: 10,
    eaccount,
  };

  const [search, setSearch] = useState('');
  const [data, setData] = useState({
    trigger: 0,
    filters: { ...initialStateFilters },
    modalAmount: {
      trigger: 0,
      isOpen: false,
      data: { amountToDeliver: '', patientId: '', patient: '' },
    },
  });

  const { trigger, modalAmount } = data;
  const { page, perpage } = data.filters;

  const headers = [
    {
      title: 'Fecha solicitud',
      className: `px-2 text-center`,
    },

    {
      title: 'Fecha entregado',
      className: `px-2 text-center`,
    },

    {
      title: 'Tipo documento',
      className: `px-2 text-center`,
    },

    {
      title: 'Número documento',
      className: `px-2 text-start`,
    },

    {
      title: 'Nombre paciente',
      className: `px-2 text-center`,
    },

    {
      title: 'Valor',
      className: `px-2 text-end`,
    },

    {
      title: 'Generado por',
      className: `px-2 text-center`,
    },

    {
      title: 'Estado',
      className: `px-2 text-center`,
    },

    {
      title: '',
      className: `px-2`,
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row`}>
            <td className={`text-center px-2`}>
              {item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD') : '-'}
            </td>

            <td className={`text-center px-2`}>
              {item?.updatedAt ? moment(item?.updatedAt).format('YYYY-MM-DD') : '-'}
            </td>
            <td className={`text-center px-2`}>{item?.documentType ?? '-'}</td>

            <td className={`text-center px-2`}>{formatteDocument(item?.docNum).format || '-'}</td>

            <td className={`text-center px-2`}>{item?.namePatient ?? '-'}</td>

            <td className={`text-end px-2`}>
              {formatNumberWithoutDecimals(item?.amount ?? 0)}
            </td>

            <td className={`text-center px-2`}>{item?.createdBy || '-'}</td>

            <td className={`text-center px-2`}>
              {item?.status ? (
                <div
                  className={
                    item?.status === 'pending' ? styles.lightRedState : styles.lightGreenState
                  }
                >
                  {item?.status === 'pending' ? 'Pendiente' : 'Entregado'}
                </div>
              ) : (
                '-'
              )}
            </td>

            <td className={`text-center`} width={30}>
              <button
                onClick={() => {
                  history.push({
                    pathname: `/admision/vueltos/${item?.patientId}`,
                    state: { patientId: item?.patientId },
                  });
                }}
              >
                <img src={previewIcon} alt='' />
              </button>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //request
  const {
    load: loadListGeneralBalance,
    results: listGeneralBalance,
    trigger: getListGeneralBalance,
  } = useGetMethod();

  const { load: loadPostModalAmount, trigger: postModalAmount } = usePostMethod();

  const getListGeneralBalanceFunction = useCallback(() => {
    getListGeneralBalance({
      objFilters: { ...data.filters },
      token: token,
      url: '/medical/patient/wallet',
    });
  }, [getListGeneralBalance, data.filters, token]);

  useEffect(() => {
    getListGeneralBalanceFunction();
  }, [trigger, getListGeneralBalanceFunction]);

  const handlePostModalAmount = () => {
    const { amountToDeliver, patientId, patient } = modalAmount.data;

    if (Number(amountToDeliver) <= 0) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ha ocurrido un error al intentar entregar el monto',
        showCancelButton: false,
      });
    }

    const body = {
      eaccount,
      amountToDeliver: Number(amountToDeliver),
    };

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se entregará el monto: ${formatNumberWithoutDecimals(Number(amountToDeliver))} al paciente: ${patient}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        postModalAmount({
          token,
          body: body,
          method: 'POST',
          url: `/medical/patient/${patientId}/wallet/movement`,
          succesAction: () => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Pagado exitosamente',
              text: `Se ha entregado el monto: ${formatNumberWithoutDecimals(amountToDeliver)} al paciente: ${patient}`,
              showCancelButton: false,
            }).finally(() => {
              setData({
                trigger: trigger + 1,
                filters: { ...initialStateFilters },
                modalAmount: {
                  ...modalAmount,
                  isOpen: false,
                  data: { amountToDeliver: '', patientId: '', patient: '' },
                },
              });
            });
          },
          doAfterException: () => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'Ha ocurrido un error al intentar registrar el monto en el sistema',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const onClearStateModal = () => {
    setData(state => ({
      ...state,
      modalAmount: {
        ...state,
        isOpen: false,
      },
    }));
  };

  return (
    <>
      {(loadListGeneralBalance || loadPostModalAmount) && loader}

      <OrdGenericTemplate
        className='w-92 my-4'
        showBottomBorder={false}
        title={'Devueltas'}
        titleSize={12}
      >
        <div className='me-4 my-2 asistencialDateColorIcon'>
          <Row className='d-flex px-1'>
            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className='px-2' controlId='ordType'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Estado</span>
                </Form.Label>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  options={[
                    { label: 'Seleccionar...', value: '' },
                    { label: 'Entregado', value: 'delivered' },
                    { label: 'Pendiente', value: 'pending' },
                  ]}
                  onChange={({ value }) => {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, status: value },
                    }));
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={4} className='align-self-end'>
              <Form.Group className=' text-start' controlId='fName'>
                <div className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                  <input
                    className={`ord-roundInput ${styles.appInputDate} w-100`}
                    type='text'
                    placeholder='Escribe aquí para buscar'
                    autoComplete='off'
                    onChange={e => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setData(state => ({
                          ...state,
                          trigger: trigger + 1,
                          filters: { ...state.filters, page: 1, search: search },
                        }));
                      }
                    }}
                  />
                  <img
                    src={ordBlueSearch}
                    className='pointer'
                    alt='buscar'
                    onClick={() => {
                      setData(state => ({
                        ...state,
                        trigger: trigger + 1,
                        filters: {
                          ...state.filters,
                          page: 1,
                          search: search,
                        },
                      }));
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* SECTION TABLE */}
          <OrdTable
            shadow
            headers={headers}
            hasChildren={true}
            oneHeadDarkBlue={false}
            lowerCase={false}
            paginate={{
              activePage: page,
              totalPages: listGeneralBalance?.rowTotal,
              perPage: perpage,
              pageRangeDisplayed: 3,
              showTextDetails: true,
              onChangePage: async e => {
                setData(state => ({
                  ...state,
                  filters: { ...state?.filters, page: e },
                  trigger: trigger + 1,
                }));
              },
            }}
          >
            {formatData(listGeneralBalance?.results || [])}
          </OrdTable>
        </div>
      </OrdGenericTemplate>

      <OrdModal
        size={400}
        styleBody='p-2'
        title={'Entregar monto'}
        show={modalAmount?.isOpen}
        btnYesName={'Aceptar'}
        onHide={onClearStateModal}
        btnYesDisabled={Number(modalAmount?.data?.amountToDeliver) <= 0}
        btnYesEvent={handlePostModalAmount}
        btnNoEvent={onClearStateModal}
      >
        <Row className='d-flex'>
          <div className='d-flex'>
            <Col xs={6}>
              <Form.Label className={`m-0 ${styles.darkerBlueText}  ${styles.f16}`}>
                <b>&nbsp;Devuelta pendiente</b>
              </Form.Label>
            </Col>
            <Col xs={6}>
              <Form.Group className='mb-3 text-start' controlId='pending'>
                <NumberFormat
                  disabled
                  allowNegative={false}
                  className={'ordInputAdmission w-100'}
                  placeholder='Escribir...'
                  isNumericString={true}
                  maxLength={12}
                  thousandsGroupStyle='thousand'
                  value={Number(modalAmount?.data?.amountToDeliver)}
                  thousandSeparator={localeSeparators.groupSeparator}
                  decimalSeparator={localeSeparators.decimalSeparator}
                  format={valueStr => {
                    if (!valueStr) return '';

                    return formatNumberWithoutDecimals(valueStr);
                  }}
                  allowLeadingZeros={false}
                />
              </Form.Group>
            </Col>
          </div>
        </Row>
      </OrdModal>
    </>
  );
};
