import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import { Box } from '@mui/material';
import camara from "../../assets/img/icons/aaa.svg";
import cancelPng from "../../assets/img/icons/cancelPng.png";
import usePatientAdmissionStore from "../../Hooks/usePatientAdmissionStore";

const videoConstraints = {
    width: '540',
    facingMode: "environment",
};

const Camera = () => {

    const { setImage } = usePatientAdmissionStore()

    const webcamRef = useRef(null);
    const [url, setUrl] = React.useState('');

    const capturePhoto = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setUrl(imageSrc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [webcamRef]);


    useEffect(() => {
        if (url !== '') {
            setImage(url)
        } else {
            setImage(url)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])



    return (
        <Box display='grid' justifyContent={'center'}>
            <Webcam
                ref={webcamRef}
                screenshotFormat="image/png"
                videoConstraints={videoConstraints}
                mirrored={true}
                height='500px'
            />
            <Box 
                display={'flex'} 
                columnGap='10px' 
                justifyContent={'center'} 
                m='10px auto' 
                alignItems={'center'}
            >
                <img 
                    style={{ cursor: 'pointer',transition: 'all 0.3s' }} 
                    src={camara} 
                    height={57} 
                    alt="camera" 
                    onClick={capturePhoto} 
                />

                <img 
                    style={{ cursor: 'pointer' }} 
                    src={cancelPng} 
                    height={40}
                    alt="cancel" 
                    onClick={() => setUrl('')} 
                />

            </Box>

        </Box>
    );
};

export default Camera;