//react
import { useEffect, useState } from 'react';

//helpers
import moment from 'moment';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isNullOrUndefined } from 'util';
import {
  customSwaltAlertAsistencial,
  generateId,
  loader,
  today,
  formatteDocument
} from '../../helpers';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../helpers/numberFormatting'

//styles
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../../components/Layouts/tableStyle.module.scss';

//hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useGetMethod } from '../../Hooks';

//icons
import arrow from '../../assets/img/icons/arrowTable.svg';
import countNotes from '../../assets/img/icons/countNotes.svg';
import iconClose from '../../assets/img/icons/modalClose.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';

//components
import { Drawer } from '@material-ui/core';
import { Box } from '@mui/material';
import NumberFormat from 'react-number-format';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { Button, Text } from '../../components/UI/atoms';
import { MultiSelect } from '../../shared/MultiSelect';

const localeSeparators = getLocaleSeparators()

const DailyActivity = () => {
  //----------States----------------
  const { colors } = useTheme();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const siteId = store.userReducer.site;
  const userId = store.loginReducer.user_data.id;
  const [drawerDetail, setDrawerDetail] = useState(false);
  const [drawerVoucher, setDrawerVoucher] = useState(false);
  const [dailyCashClosingDate, setDailyCashClosingDate] = useState(undefined);
  const [voucherDepositTypeName, setVoucherDepositTypeName] = useState(undefined);
  const [admisionistName, setAdmisionistName] = useState(undefined);

  const [optionSelected, setOptionSelected] = useState();

  const [options, setOptions] = useState({
    showNotes: false,
    showImportance: false,
  });

  const [filtersDailyActivity, setFiltersDailyActivity] = useState({
    siteId,
    userId,
    page: 1,
    perpage: 10,
    isCoordinator: 1,
    dateMin: moment(new Date()).format('YYYY-MM-DD'),
    dateMax: undefined,
    search: undefined,
    sites: undefined,
  });

  const [filtersListCashClosingCurrency, setFiltersListCashClosingCurrency] = useState({
    userId,
    siteId,
    eaccount: idAccount,
    getDetail: 1,
    page: 1,
    perpage: 10,
    depositTypeId: undefined,
  });

  const [triggers, setTriggers] = useState({
    filtersDailyActivity: 0,
  });

  const [tableHeaderListActivity, setTableHeaderListActivity] = useState([]);

  //----------HTTPS METHOD----------------

  const {
    results: listDailyActivity,
    trigger: getListDailyActivity,
    load: loaderListDailyActivity,
  } = useGetMethod();

  const {
    results: listDepositType,
    load: loaderListDepositType,
    trigger: getListDepositType,
  } = useGetMethod();

  const {
    results: listGetDailyCashClosingDate,
    load: loaderListGetDailyCashClosingDate,
    trigger: getDailyCashClosingDate,
  } = useGetMethod();

  const {
    results: listCashClosingCurrency,
    trigger: getListCashClosingCurrency,
    load: loaderListCashClosingCurrency,
  } = useGetMethod();

  const { results: listSites, trigger: getSites, load: loadSites } = useGetMethod();

  //----------FUNCTIONS----------------

  const onChangeTriggerDailyActivity = () => {
    setTriggers(state => ({ ...state, filtersDailyActivity: state.filtersDailyActivity + 1 }));
  };

  const formatDataListActivity = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        const deposits = item?.depositsSummary?.map(el => el?.depositTypeName?.toLocaleLowerCase());

        tempList.push(
          <tr
            key={index}
            className={`pointer ${styles.tableAdmission}`}
            onClick={() => {
              getDailyCashClosingDate({
                url: '/medical/admissions/dailyCashClosing/',
                token: token,
                objFilters: {
                  eaccount: idAccount,
                  userId,
                  siteId,
                  getDetail: 1,
                  dailyCashClosingDate: item?.dailyCashClosingDate,
                  // perpage: 10,
                  // page: filtersDailyCashClosing.page,
                },
              }).then(res => {
                if (res?.success) {
                  setDrawerDetail(true);
                  setDailyCashClosingDate(item?.dailyCashClosingDate);
                  setAdmisionistName(item?.admisionistName);
                } else {
                  customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: res.message,
                    showCancelButton: false,
                  });
                }
              });
            }}
          >
            <td className='text-center px-2'>{item?.sitePrefix || '-'}</td>
            <td className='text-center px-2'>{item?.dailyCashClosingDate || '-'}</td>
            <td className='text-center px-2'>{item?.admisionistName || '-'}</td>

            {listDepositType?.results?.map(el => {
              if (deposits?.includes(el?.depoTypeName?.toLocaleLowerCase())) {
                const depositTypeAmount = item?.depositsSummary?.find(
                  elem => elem?.depositTypeName === el?.depoTypeName
                )?.depositTypeAmount

                return (
                  <td className='text-end px-3' key={generateId()}>
                    {depositTypeAmount ? formatNumberWithoutDecimals(depositTypeAmount) : '-'}
                  </td>
                );
              }

              return (
                <td className='text-end px-3' key={generateId()}>
                  {'-'}
                </td>
              );
            })}
            <td className='text-end px-2'>
            {item?.systemTotal ? formatNumberWithoutDecimals(item?.systemTotal) : '-'}
            </td>
            <td className='text-end px-2'>
            {item?.userTotal ? formatNumberWithoutDecimals(item?.userTotal) : '-'}
            </td>
            <td className='text-end px-2'>
            {item?.difference ? formatNumberWithoutDecimals(item?.difference) : '-'}
            </td>
            <td className='text-center px-3'>
              <img
                className={`cursorPointer ${styles.filterOrdDarkGrayText}`}
                alt={'arrow'}
                src={arrow}
                width={8}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataCashClosingDate = array => {
    const validateArray = array[0] === null ? [] : array;

    let tempList = [];
    if (Array.isArray(validateArray)) {
      const listCollections = [];

      listGetDailyCashClosingDate?.results?.depositsData?.forEach(el => {
        if (el?.depositTypeLabel !== 'cash' && el?.depositType !== 'replacedDeposits') {
          listCollections?.push(el);
        }
      });
      let resFull = [];

      if (listCollections?.length && validateArray?.length) {
        resFull = [...listCollections, ...validateArray];
      } else if (listCollections?.length) {
        resFull = [...listCollections];
      } else if (validateArray?.length) {
        resFull = [...validateArray];
      }

      resFull?.forEach((item, index) => {
        const type = !isNullOrUndefined(item?.appsCount)
          ? item?.depositTypeName
          : item?.depositTypeName === 'transfer'
            ? 'Transferencia'
            : item?.depositTypeName === 'card'
              ? 'Tarjeta de crédito'
              : 'Efectivo';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              {item?.depositTypeName ? (
                type
              ) : (
                <>
                  {item?.cdType === 'cash' ? 'Billete de ' : 'Moneda de '}
                  {formatNumberWithoutDecimals(item?.cdValue)}
                </>
              )}
            </td>

            <td className='text-center px-2'>
              <span
                className={`${!isNullOrUndefined(item?.appsCount) ? `pointer text-decoration-underline ${styles.ordAquaMarineText}` : ''}`}
                onClick={() => {
                  if (!isNullOrUndefined(item?.appsCount)) {
                    setFiltersListCashClosingCurrency(state => ({
                      ...state,
                      depositTypeId: item?.depositType,
                    }));

                    getListCashClosingCurrency({
                      url: '/medical/admissions/dailyCashClosing/',
                      token: token,
                      objFilters: {
                        ...filtersListCashClosingCurrency,
                        depositTypeId: item?.depositTypeId,
                        dailyCashClosingDate: dailyCashClosingDate,
                        vouchersDetail: 1,
                        eaccount: idAccount,
                        userId,
                      },
                    }).then(res => {
                      if (res?.success) {
                        setDrawerVoucher(true);
                        setVoucherDepositTypeName(item?.depositTypeLabel);
                      }
                    });
                  }

                  setDrawerDetail(false);
                }}
              >
                {item?.cdQuantity || item?.appsCount || '-'}
              </span>
            </td>

            <td className='text-end px-3'>
              {item?.cdValue
                ? formatNumberWithoutDecimals(Number(item?.cdValue) * Number(item?.cdQuantity)) 
                : item?.depositTypeAmount ? formatNumberWithoutDecimals(item?.depositTypeAmount) : '-'
              }
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataVoucher = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`text-start px-3`} style={{ width: '150px' }}>
              <input
                value={item?.voucherNo || '-'}
                type='text'
                className='ord-roundInput2'
                style={{ color: '#6E6F7C' }}
              />
            </td>

            <td className='text-center px-2'>
              <span>{item?.bankName || '-'}</span>
            </td>

            <td className='text-end px-3'>
              {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '5px', marginTop: '10px' }}>
              <div className={styles.app_container_tras_notes}>
                <span className={styles.app_name_drawer}>{admisionistName || '-'}</span>
              </div>
              <p className={styles.app_description_drawer}>{item?.ccNote || '-'}</p>
              <div className={styles.app_container_date_drawer}>
                <span className={styles.app_date_drawer}>{`${item?.ccDate} ${item?.ccHour}`}</span>
              </div>
            </div>
            <div className={styles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  const headersCashClosing = [
    {
      title: 'Moneda',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Cantidad',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total',
      className: 'px-3 text-end col-4',
    },
  ];

  const headersDetailCashClosing = [
    {
      title: 'Voucher',
      className: 'px-3 text-start',
    },
    {
      title: 'Entidad',
      className: 'px-2 text-center',
    },
    {
      title: 'Total',
      className: 'px-3 text-end',
    },
  ];

  const headersWalletMovements = [
    {
      title: 'Paciente',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Identificación',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total devuelta',
      className: 'px-2 text-end col-4',
    },
  ];

  const formatWalletMovements = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-2`}>{item?.patientName || '-'}</td>

            <td className={`text-center px-2`}>
              <span>{item?.patientDocType || '-'}</span>&nbsp;
              <span>
                {formatteDocument(item?.patientDoc).format || '-'}
              </span>
            </td>

            <td className={`text-end px-2`}>
              {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListDailyActivity({
      token,
      url: '/medical/admissions/dailyCashClosing/',
      objFilters: { eaccount: idAccount, userId, ...filtersDailyActivity },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggers.filtersDailyActivity]);

  useEffect(() => {
    getSites({
      url: `/medical/admissions/sites/`,
      objFilters: { eaccount: idAccount, userId, status: 1 },
      token: token,
    });

    getListDepositType({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount: idAccount, userId },
    }).then(res => {
      if (res?.success) {
        const listDepositType = res?.results?.map(el => ({
          title: el?.depoTypeName,
          className: 'text-end px-2',
        }));

        const firstHeader = [
          {
            title: 'Sede',
            className: 'text-center px-2',
          },
          {
            title: 'Fecha',
            className: 'text-center px-2',
          },
          {
            title: 'Admisionista',
            className: 'text-center px-2 ',
          },
        ];

        const lastHeader = [
          {
            title: 'Recaudado',
            className: 'text-end px-2',
          },

          {
            title: 'Total cierre',
            className: 'text-end px-2',
          },

          {
            title: 'Diferencia',
            className: 'text-end px-2',
          },
          {
            title: <>&nbsp;</>,
            className: 'text-center px-2',
          },
        ];

        const allData = listDepositType?.length
          ? [...firstHeader, ...listDepositType, ...lastHeader]
          : [...firstHeader, ...lastHeader];

        setTableHeaderListActivity(allData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(loaderListDailyActivity ||
        loaderListDepositType ||
        loaderListCashClosingCurrency ||
        loadSites ||
        loaderListGetDailyCashClosingDate) &&
        loader}

      <div className='container-fluid'>
        <OrdGenericTemplate title='Reporte de caja'>
          <div className={styles.app_mr_100}>
            <Box display={'grid'} gridTemplateColumns={'160px 160px 250px 4fr'} mb={2}>
              <Box className={`px-0 align-self-end`}>
                <Form.Group className='px-2' controlId='ordType'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                    <span>&nbsp;Fecha desde</span>
                  </Form.Label>
                  <input
                    className={`ord-roundInput2 ${filtersDailyActivity.dateMin ? 'colorDateModuleAdmisionActive' : 'colorDateModuleAdmision'}`}
                    type='date'
                    id='date-start'
                    value={filtersDailyActivity.dateMin}
                    max={today()}
                    onChange={e => {
                      setFiltersDailyActivity(state => ({
                        ...state,
                        dateMin: e.target.value,
                        dateMax: '',
                      }));
                    }}
                  />
                </Form.Group>
              </Box>

              <Box className={`px-0 align-self-end`}>
                <Form.Group className=' px-2' controlId='ordType'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                    <span>&nbsp;Hasta</span>
                  </Form.Label>

                  <input
                    className={`ord-roundInput2 ${filtersDailyActivity.dateMax ? 'colorDateModuleAdmisionActive' : 'colorDateModuleAdmision'}`}
                    id='date-end'
                    type='date'
                    max={moment(new Date()).format('YYYY-MM-DD')}
                    value={filtersDailyActivity.dateMax || ''}
                    name='xx'
                    onChange={e => {
                      setFiltersDailyActivity(state => ({
                        ...state,
                        dateMax: e.target.value,
                      }));
                    }}
                  />
                </Form.Group>
              </Box>

              <Box className={`px-0 align-self-end`}>
                <Form.Group className=' px-2' controlId='ordType'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                    <span>&nbsp;Sedes</span>
                  </Form.Label>

                  <MultiSelect
                    key='1'
                    options={
                      listSites?.results?.sites?.map(el => ({
                        label: el?.siteName,
                        value: el?.siteId,
                      })) || []
                    }
                    isSelectAll={true}
                    value={optionSelected}
                    menuPlacement={'bottom'}
                    nameList={'sede'}
                    accentuation={'a'}
                    onChange={elements => {
                      setOptionSelected(elements);
                      setFiltersDailyActivity(state => ({
                        ...state,
                        sites: elements?.length
                          ? elements?.map(el => el?.value)?.join()
                          : undefined,
                      }));
                    }}
                  />
                </Form.Group>
              </Box>

              <Box className='align-self-end'>
                <Form.Group className=' text-start px-2' controlId='fName'>
                  <div
                    className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}
                  >
                    <input
                      className={`ord-roundInput ${styles.darkerGrayText} w-100`}
                      type='text'
                      placeholder='Escribe aquí para buscar'
                      autoComplete='off'
                      onChange={e => {
                        setFiltersDailyActivity(state => ({
                          ...state,
                          search: e.target.value,
                        }));
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          onChangeTriggerDailyActivity();
                        }
                      }}
                    />
                    <img
                      src={ordBlueSearch}
                      className='pointer'
                      alt='buscar'
                      onClick={() => onChangeTriggerDailyActivity()}
                    />
                  </div>
                </Form.Group>
              </Box>
            </Box>

            <OrdTable
              shadow
              className={'mt-2'}
              hasChildren={true}
              headers={tableHeaderListActivity}
              paginate={{
                activePage: filtersDailyActivity.page,
                totalPages: listDailyActivity?.rowTotal,
                perPage: filtersDailyActivity.perpage,
                pageRangeDisplayed: 3,
                showTextDetails: true,
                onChangePage: async e => {
                  setFiltersDailyActivity(state => ({
                    ...state,
                    page: e,
                  }));

                  onChangeTriggerDailyActivity();
                },
              }}
            >
              {formatDataListActivity(listDailyActivity?.results || [])}
            </OrdTable>
          </div>
        </OrdGenericTemplate>

        <Drawer
          anchor={'right'}
          open={drawerDetail}
          onClose={() => {
            setDrawerVoucher(false);
            setDrawerDetail(false);
            setDailyCashClosingDate(undefined);
            setVoucherDepositTypeName(undefined);
            setAdmisionistName(undefined);
          }}
        >
          <Box padding={3}>
            <Box mb={2}>
              <img
                className={`${styles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                onClick={() => {
                  setDrawerVoucher(false);
                  setDrawerDetail(false);
                  setDailyCashClosingDate(undefined);
                  setVoucherDepositTypeName(undefined);
                  setAdmisionistName(undefined);
                }}
                height={'28px'}
                width={'28px'}
              />
            </Box>

            <Box paddingX={4}>
              <Box>
                <Box className='d-flex' justifyContent={'space-between'} alignItems={'center'}>
                  <Box fontSize={'36px'} className={`${styles.ordDarkBlueText} fw-bold`} mb={0}>
                    Conteo General
                  </Box>

                  <OverlayTrigger
                    flip
                    shouldUpdatePosition={true}
                    placement='top'
                    delay={{ show: 150, hide: 150 }}
                    overlay={
                      <Tooltip
                        id='button-tooltip-2'
                        style={{ zIndex: '9999999' }}
                        className={`d-inline-flex ${styles.darkerGrayText}`}
                      >
                        <span>({listGetDailyCashClosingDate?.results?.dailyNotes?.length}) </span>
                        <span>
                          {listGetDailyCashClosingDate?.results?.dailyNotes?.length > 1
                            ? 'Notas'
                            : 'Nota'}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div
                      className='pointer d-flex position-relative'
                      onClick={() => {
                        setOptions(state => ({
                          ...state,
                          showNotes: true,
                        }));

                        setDrawerDetail(false);
                      }}
                    >
                      <img
                        alt='notes'
                        height={22}
                        src={countNotes}
                        width={22}
                        className={`${styles.filterOrdAquaMarine}`}
                      />
                      <span className={`${styles.textCountNotes2} ${styles.filterWhite}`}>
                        {listGetDailyCashClosingDate?.results?.dailyNotes?.length || 0}
                      </span>
                    </div>
                  </OverlayTrigger>
                </Box>

                <Text title='Recaudos en efectivo' classNameBox='mb-1' />
              </Box>

              <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
                <Box className='tableScroll'>
                  <OrdTable
                    className='mt-0'
                    headers={headersCashClosing}
                    hasChildren={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                  >
                    {formatDataCashClosingDate(
                      listGetDailyCashClosingDate?.results?.depositsDetail
                        ?.filter(el => el?.depositTypeLabel !== 'cash')
                        .concat(listGetDailyCashClosingDate?.results?.cashDetail) || [],
                    )}
                  </OrdTable>
                </Box>

                <Box className='tableScroll my-4'>
                  <Text title='Transferencias a bolsillo' classNameBox='mb-1' />

                  <OrdTable
                    className='mt-0'
                    headers={headersWalletMovements}
                    hasChildren={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                  >
                    {formatWalletMovements(listGetDailyCashClosingDate?.results?.walletMovements) ||
                      []}
                  </OrdTable>
                </Box>

                <Box className='ms-1 animate__animated animate__fadeIn mt-auto'>
                  <Box
                    borderRadius={3}
                    paddingX={3}
                    paddingY={2}
                    bgcolor={'#F5FCFD'}
                    className='d-flex gap-3 mt-4'
                  >
                    <Box>
                      <Text title='Total contado' fontSize={'12px'} classNameBox={'ms-1'} />
                      <NumberFormat
                        disabled
                        allowNegative={false}
                        className={`register-inputs ${styles.inputPlaceholder}`}
                        placeholder='$...'
                        style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                        isNumericString={true}
                        value={listGetDailyCashClosingDate?.results?.userTotalAmount || 0}
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        format={(valueStr) => {
                          if (!valueStr) return ''

                          return formatNumberWithoutDecimals(valueStr)
                        }}
                        allowLeadingZeros={false}
                      />
                    </Box>

                    <Box>
                      <Text title='Total en el sistema' fontSize={'12px'} classNameBox={'ms-1'} />
                      <NumberFormat
                        disabled
                        allowNegative={false}
                        className={`register-inputs ${styles.inputPlaceholder}`}
                        placeholder='$...'
                        style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                        isNumericString={true}
                        prefix={'$'}
                        value={listGetDailyCashClosingDate?.results?.systemTotalAmount || 0}
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        format={(valueStr) => {
                          if (!valueStr) return ''

                          return formatNumberWithoutDecimals(valueStr)
                        }}
                        allowLeadingZeros={false}
                      />
                    </Box>

                    <Box>
                      <Text title='Diferencia' fontSize={'12px'} classNameBox={'ms-1'} />
                      <input
                        disabled
                        type='text'
                        className={`register-inputs ${styles.inputPlaceholder}`}
                        style={{
                          maxHeight: '32px',
                          height: '32px',
                          borderColor: '#A3BAD1',
                          color:
                            Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                              Number(listGetDailyCashClosingDate?.results?.systemTotalAmount) <
                            0
                              ? '#F39682'
                              : '#58595b',
                        }}
                        value={`${
                          formatNumberWithoutDecimals(
                            Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                            Number(listGetDailyCashClosingDate?.results?.systemTotalAmount) 
                            > 0
                            ? 0
                            : Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                            Number(listGetDailyCashClosingDate?.results?.systemTotalAmount)
                          )}`
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor={'right'}
          open={options.showNotes}
          style={{ zIndex: 900 }}
          onClose={() => {
            setOptions({ ...options, showNotes: false });
            setDrawerDetail(true);
          }}
        >
          <div className={styles.app_drawer} style={{ width: '500px', maxWidth: '500px' }}>
            <div className={styles.app_container_close}>
              <img
                className={`${styles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                width={25}
                onClick={() => {
                  setDrawerDetail(true);
                  setOptions({ ...options, showNotes: false });
                }}
              />
            </div>

            <div
              className={`${styles.app_container_drawer} pt-0 px-5 mt-0`}
              style={{ width: '500px', maxWidth: '500px' }}
            >
              <span className={`${styles.app_title_drawer} mb-2`}>Notas</span>

              {listGetDailyCashClosingDate?.results?.dailyNotes?.length === 0 ? (
                <div className={styles.app_container_not_notes}>
                  <span className={styles.app_title_not_notes}>No hay notas aún</span>
                </div>
              ) : (
                <div className={styles.app_container_content_drawer}>
                  {formatDataComments(listGetDailyCashClosingDate?.results?.dailyNotes || [])}
                </div>
              )}
            </div>
          </div>
        </Drawer>

        <Drawer
          anchor={'right'}
          open={drawerVoucher}
          onClose={() => {
            setDrawerVoucher(false);
            setDrawerDetail(true);
          }}
        >
          <Box padding={3}>
            <Box mb={2}>
              <img
                className={`${styles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                onClick={() => {
                  setDrawerVoucher(false);
                  setDrawerDetail(true);
                }}
                height={'28px'}
                width={'28px'}
              />
            </Box>

            <Box paddingX={4}>
              <Box>
                <Box fontSize={'36px'} className={`${styles.ordDarkBlueText} fw-bold`} mb={0}>
                  Listado de{' '}
                  {voucherDepositTypeName?.toLowerCase() === 'transfer'
                    ? 'transferencias'
                    : 'vouchers'}
                </Box>
              </Box>

              <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
                <OrdTable
                  shadow
                  headers={headersDetailCashClosing}
                  hasChildren={true}
                  oneHeadDarkBlue={false}
                  lowerCase={false}
                  style={{ minWidth: '500px' }}
                >
                  {formatDataVoucher(listCashClosingCurrency?.results || [])}
                </OrdTable>

                <Box display='flex' columnGap='10px' justifyContent={'start'}>
                  <Button
                    backgroundHover={colors.ordAquaMarine}
                    backgroundColorHover={colors.ordAquaMarine}
                    backgroundColor={'white'}
                    color={colors.ordAquaMarine}
                    colorHover='white'
                    width='100px'
                    padding='8px'
                    fontSize='14px'
                    onClick={() => {
                      setDrawerVoucher(false);
                      setDrawerDetail(true);
                    }}
                  >
                    Regresar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </div>
    </>
  );
};

export default DailyActivity;
