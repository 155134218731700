
import {
  GET_LIST_INVENTORY_FAMILIES_TO_SELECT,
  GET_LIST_MANUFACTURERS_TO_SELECT,
  GET_ALL_ARTICLES,
  SET_INDEX_ARTICLES,
  SET_TOTAL_INDEX_ARTICLES,
  SUCCESS_UPLOAD,
  LOADING,
  OPTIONS_IVA
} from "../actions/actionTypes";

const initialState = {
  listInventoryFamilies: [],
  listManufacturers: [],
  articles: [],
  index:0,
  totalIndex:0,
  success_upload:false,
  loading:false,
  listOptions: []
};
export const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      };
    case SET_INDEX_ARTICLES:
      return{
        ...state,
        index:action.payload
      }
    case SET_TOTAL_INDEX_ARTICLES:
      return{
        ...state,
        totalIndex:action.payload
      }
    case GET_LIST_INVENTORY_FAMILIES_TO_SELECT:
      return {
        ...state,
        listInventoryFamilies: action.payload,
      };
    case GET_LIST_MANUFACTURERS_TO_SELECT:
      return {
        ...state,
        listManufacturers: action.payload,
      };
    case SUCCESS_UPLOAD:
      return{
        ...state,
        success_upload: action.payload,
      }
    case LOADING:
      return{
        ...state,
        loading:action.payload
      }
    case OPTIONS_IVA:
      return{
        ...state,
        listOptions:action.payload
      }
    default:
      return state;
  }
};
