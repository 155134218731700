import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_EVENTS_TAXCALENDAR, LOADING } from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getEventTaxCalendar = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/taxTask/getByDate/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      const { results } = res;
      dispatch({
        type: GET_EVENTS_TAXCALENDAR,
        payload: results,
      });
    })
    .catch(err => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Error al cargar los eventos',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      }).then(() => {
        dispatch({
          type: LOADING,
          payload: false,
        });
      });
      console.error(err.message);
    });
};

export const changeStatusEvent = (data, doAfter) => (dispatch, getState) => {
  customSwaltAlert({
    title: `¿Está seguro?`,
    text: 'Verifique la información',
    icon: 'warning',
  }).then(result => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/taxTask/changeStatus/`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',

          token: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            customSwaltAlert({
              icon: 'success',
              title: res.message,
              text: res.results.title,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            }).then(response => {
              dispatch({
                type: LOADING,
                payload: false,
              });
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: res.message,
              showCancelButton: false,
              footer: 'Si el problema persiste comuníquese con un asesor.',
            });
            dispatch({
              type: LOADING,
              payload: false,
            });
          }
        })
        .catch(res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
          dispatch({
            type: LOADING,
            payload: false,
          });
        });
    }
  });
};
