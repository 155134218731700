import { REQUISITION_FILTER } from './actionTypes';

import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import {
  GET_ALL_ARTICLES,
  SET_INDEX_ARTICLES,
  SET_TOTAL_INDEX_ARTICLES,
  LOADING,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const requisition_filter = (objFilters, doAfter) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: REQUISITION_FILTER,
        results: results,
        row_total: row_total,
      });
    })
    .catch(err => {
      console.error(err);
    })
    .then(() => {
      if (doAfter) doAfter();
    });
};

export const requisition_update = (data, objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/requisitions/status/`, {
    body: JSON.stringify(data),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(dato => {
      if (!dato.success || dato.code === 401) {
        return customSwaltAlert({
          icon: 'error',
          title: 'Intenta de nuevo ',
          text: dato.message,
          showCancelButton: false,
        });
      }
      customSwaltAlert({
        icon: 'success',
        title: 'Requisición ' + data.action,
        text: data.num_req,
        showCancelButton: false,
      });
      dispatch(requisition_filter(objFilters));
    });
};
export const getArticlesWithouIdAcc = (objFilters, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  dispatch({
    type: LOADING,
    payload: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/article?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      var { results, row_total, total_index } = res; // Obtenemos la propiedad data de la petición que contiene un array de objetos
      if (results)
        results = results.sort((a, b) => (new Date(a.create_at) > new Date(b.create_at) ? -1 : 1));
      dispatch({
        type: GET_ALL_ARTICLES,
        payload: results,
      });
      dispatch({
        type: SET_INDEX_ARTICLES,
        payload: row_total,
      });
      dispatch({
        type: SET_TOTAL_INDEX_ARTICLES,
        payload: total_index,
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: LOADING,
        payload: false,
      });
      customSwaltAlert({
        icon: 'error',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al intentar traer la información de los artículos.',
        footer: 'Si el problema persiste comunícate con un asesor.',
        showCancelButton: false,
      });
    })
    .then(() => {
      if (doAfter) doAfter();
    });
};
