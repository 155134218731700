//_--------------------. import hooks ⛳ -----------------------------//
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createWorkAccident } from '../../actions/payrollActions';

//----------------------------- ☪ Images--------------------------------//
import backIcon from '../../assets/img/icons/backIcon.svg';
import Attach from '../../assets/img/icons/insertar.svg';
import littleClose from '../../assets/img/icons/littleClose.svg';
import PDF from '../../assets/img/icons/pdf.svg';

// ---------------------------------------------------------------------//

// ----------- 🧮 components --------------------//
import Dropzone from 'react-dropzone';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
// ----------- --------------- --------------------//

// ----------------- 🦄 Styless -----------------------------//
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';

// ----------------- end styles-----------------------------//

// ---------------- functions 🛬 ---------------------------//
import { Col, Row } from 'react-bootstrap';
import { getCandidateList } from '../../actions/partnersActions';
import { MAX_SIZE_FILE, REGEX_VALID_PDF_TYPE } from '../../helpers/constants';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { today } from '../../helpers/helpers';
import SelectComponent from '../SelectComponent/SelectComponent';
import { useHasPermissionByTagModule } from "../../Hooks";
//-----------------------------------------------------------//
let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;

function RegisterAccident() {
  const reducers = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const initialState = {
    addAccident: {
      title: '',
      area: '',
      description_event: '',
      accident_date: '',
      reporter: undefined,
      involved: undefined,
      laboral_inhability: false,
      description_injury: '',
      entity_account: reducers.loginReducer.currentAccount.id,
      created_by: reducers.loginReducer.user_data.id,
      job_title: '',
      job_position: '',
      status: 'registered',
      documents: {
        result_file: {},
        other_docs: {},
        inhability_doc: {},
        furat: {},
      },
    },
  };
  const [state, setState] = useState(initialState);

  const handleSubmit = () => {
    dispatch(createWorkAccident(state.addAccident, () => setState(initialState)));
  };

  const renderCandidates = () => {
    let options = [{ label: 'Seleccionar...', value: '' }];
    reducers.partnersReducer?.employee_list?.forEach(item => {
      options.push({ label: item.full_name, value: item.id });
    });
    return options;
  };

  useEffect(() => {
    dispatch(
      getCandidateList({
        eaccount: reducers.loginReducer.currentAccount.id,
        full: 1,
        status: 'current',
        filtered: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeForm = event => {
    const { addAccident } = state;
    const { name, value } = event.target;
    addAccident[name] = value;
    setState({ ...state, addAccident });
  };

  const onChangeSelect = (event, name) => {
    const { addAccident } = state;
    const { value } = event;
    addAccident[name] = value;
    if (value) {
      if (name === 'reporter') {
        addAccident.job_title = reducers.partnersReducer.employee_list.find(
          x => x.id === parseInt(value),
        ).job_title;
        addAccident.job_position = reducers.partnersReducer.employee_list.find(
          x => x.id === parseInt(value),
        ).job_position_id;
        addAccident.area = reducers.partnersReducer.employee_list.find(
          x => x.id === parseInt(value),
        ).area_name;
      }
    } else {
      addAccident.job_title = '';
      addAccident.job_position = '';
      addAccident.area = '';
    }
    setState({ ...state, addAccident });
  };

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = error => {
        reject(error);
      };
    });
  }

  const onChangeDoc = async (e, name) => {
    var f = e.target.files[0];
    if (f) {
      var name_doc = f.name;
      var ext = f.name.split('.')[1];
      var type_doc = f.type;
      if (ext !== 'pdf' && ext !== 'zip' && ext !== 'rar') {
        return customSwaltAlert({
          icon: 'warning',
          title: 'El formato del archivo debe ser PDF o ZIP',
          footer: 'El tamaño máximo permitido es de 5MB',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      let encode = await convertBase64(e.target.files[0]);
      let file64 = encode.split(',')[1];
      let { documents } = state.addAccident;
      documents[name].filename = name_doc;
      documents[name].file_base64 = file64;
      documents[name].type_doc = type_doc;
      documents[name].route = 'work-accidents';

      setState({
        ...state,
        addAccident: {
          ...state.addAccident,
          documents,
        },
      });
    }
  };

  const cleanFile = event => {
    event.stopPropagation();
    setState({
      ...state,
      addAccident: {
        ...state.addAccident,
        documents: {
          result_file: {},
          other_docs: {},
          inhability_doc: {},
          furat: {},
        },
      },
    });
  };

  const validateFile = async acceptedFile => {
    let size = acceptedFile[0].size;
    if (size > MAX_SIZE_FILE) {
      customSwaltAlert({
        icon: 'warning',
        title: 'El archivo es demasiado pesado',
        footer: 'El tamaño máximo permitido es de 5MB',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
    if (new RegExp(REGEX_VALID_PDF_TYPE).test(acceptedFile.type)) {
      customSwaltAlert({
        icon: 'warning',
        title: 'El formato del archivo debe ser PDF o ZIP',
        footer: 'El tamaño máximo permitido es de 5MB',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const myPermission = () =>{
    return reducers.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'WorkAcc',
    );
  }

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission()?.read });

  return (
    <>
      <div className='d-flex container'>
        {reducers.payrollReducer.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <section className='d-flex flex-column col-8'>
          <ul>
            <li className='d-flex'>
              <div
                style={{ alignSelf: 'end', margin: '33px 10px 0px -27px', placeItems: 'self-end' }}
                className='d-flex align-items-end align-self-end align-place-end'
              >
                <img
                  src={backIcon}
                  alt='back'
                  height='30px'
                  className='cursorPointer'
                  onClick={() => history.push('/nomina/seguridadYsalud/accidentesLaborales')}
                  style={{ marginBottom: '16px', marginRight: '10px' }}
                />
                <h1 className={tableStyles.f40}>Registrar accidente</h1>
              </div>
            </li>
            <li className='text-primary fw-bold mx-3'>Información general</li>
            <Row className='d-flex justify-content-between mt-3'>
              <Col xs={4} className='d-flex flex-column col-4'>
                <label htmlFor='reporter' className='labelFont  '>
                  Reportante
                </label>
                <SelectComponent
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  options={renderCandidates()}
                  onChange={e => onChangeSelect(e, 'reporter')}
                  value={renderCandidates().find(x => x.value === state.addAccident.reporter)}
                />
              </Col>
              <Col xs={4} className='d-flex flex-column col-4'>
                <label htmlFor='job_title' className='labelFont '>
                  Cargo
                </label>
                <input
                  type='text'
                  className='register-inputs '
                  name='job_title'
                  disabled
                  value={state.addAccident.job_title}
                />
              </Col>
              <Col xs={4} className='d-flex flex-column col-4'>
                <label htmlFor='accident_date' className='labelFont  mx-2'>
                  Fecha{asterisk}
                </label>
                <input
                  type='date'
                  className='register-inputs'
                  name='accident_date'
                  max={today()}
                  onChange={e => onChangeForm(e)}
                  value={state.addAccident.accident_date}
                />
              </Col>
            </Row>
            <Row className='d-flex mt-3'>
              <Col xs={4} className='d-flex flex-column col-4'>
                <label htmlFor='area_name' className='labelFont'>
                  Área
                </label>
                <input
                  type='text'
                  className='register-inputs'
                  name='area_name'
                  disabled
                  value={state.addAccident.area}
                />
              </Col>
              <Col xs={8} className='d-flex flex-column col-8'>
                <label htmlFor='title' className='labelFont '>
                  Título{asterisk}
                </label>
                <input
                  type='text'
                  className='register-inputs'
                  name='title'
                  onChange={e => onChangeForm(e)}
                  value={state.addAccident.title}
                />
              </Col>
            </Row>
          </ul>
          <ul>
            <li className='text-primary fw-bold mx-3'>Descripción</li>
            <div className='d-flex flex-column mt-3'>
              <label htmlFor='description_event' className='labelFont'>
                Descripción de la situación{asterisk}
              </label>
              <textarea
                name='description_event'
                id=''
                cols='30'
                rows='5'
                className='textArea darkGray px-2 '
                style={{ border: '1px solid #7FADDE' }}
                onChange={e => onChangeForm(e)}
                value={state.addAccident.description_event}
              ></textarea>
            </div>
            <div className='d-flex flex-column mt-4'>
              <label htmlFor='collaborators' className='labelFont'>
                Colaborador afectado{asterisk}
              </label>
              <SelectComponent
                placeholder=''
                options={renderCandidates()}
                styles={customSelectNewDark}
                value={renderCandidates().find(x => x.value === state.addAccident.involved)}
                onChange={e =>
                  setState({
                    ...state,
                    addAccident: { ...state.addAccident, involved: e.value },
                  })
                }
              />
            </div>
          </ul>
          <ul>
            <li className='text-primary fw-bold mx-3'>Incapacidad</li>

            <div className='d-flex mt-3'>
              <input
                type='checkbox'
                name='laboral_inhability'
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                onChange={e =>
                  setState({
                    ...state,
                    addAccident: {
                      ...state.addAccident,
                      laboral_inhability: e.target.checked,
                    },
                  })
                }
                checked={state.addAccident.laboral_inhability}
              />
              <label htmlFor='laboral_inhability' className='mx-2 darkGray'>
                ¿Generó incapacidad?{asterisk}
              </label>

              <div style={{ position: 'relative' }}>
                <label
                  htmlFor='inhability_doc'
                  style={{ maxWidth: '275px' }}
                  className={
                    state.addAccident.laboral_inhability
                      ? 'labelInputFile bg-transparent register-inputs'
                      : 'labelInputFileDisabled register-inputs'
                  }
                >
                  <input
                    id='inhability_doc'
                    type='file'
                    accept='application/pdf,.zip,.rar,.7zip'
                    name='inhability_doc'
                    className='d-none'
                    onChange={e => onChangeDoc(e, 'inhability_doc')}
                    disabled={!state.addAccident.laboral_inhability}
                    // style={{border: "1px solid #7FADDE", borderRadius: "5px"}}
                  />
                  {state.addAccident.documents.inhability_doc?.filename ? (
                    state.addAccident.documents.inhability_doc?.filename
                  ) : (
                    <label className='labelFontGray opacity-25' style={{ zIndex: -1 }}>
                      Cargar documento
                    </label>
                  )}
                  <img src={Attach} alt='agregar archivo' width='14' />
                </label>
                {state.addAccident.documents.inhability_doc?.filename && (
                  <img
                    className='hoverPointer'
                    src={littleClose}
                    alt='discard file'
                    width='16'
                    height='16'
                    style={{
                      position: 'absolute',
                      left: '95%',
                      bottom: '70%',
                      zIndex: 2,
                    }}
                    onClick={() =>
                      setState({
                        ...state,
                        addAccident: {
                          ...state.addAccident,
                          documents: {
                            ...state.addAccident.documents,
                            inhability_doc: {},
                          },
                        },
                      })
                    }
                  />
                )}
              </div>
            </div>
            <div className='d-flex flex-column mt-3 mb-5'>
              <label htmlFor='description_injury' className='labelFont'>
                Descripción de la lesión{asterisk}
              </label>
              <input
                type='text'
                name='description_injury'
                className='register-inputs'
                onChange={e => onChangeForm(e)}
                value={state.addAccident.description_injury}
              />
            </div>
          </ul>
        </section>

        {/* middle border */}
        <hr
          style={{
            border: 'none',
            borderLeft: '1px solid hsla(200, 10%, 50%, 100)',
            height: '65vh',
            width: '1px',
            margin: '8rem 1rem 1rem 2rem',
          }}
        />

        {/* rigth section */}
        <section className='d-flex flex-column col-4 justify-content-between'>
          <div>
            <h1>ㅤ </h1>
            <ul className='mt-4'>
              <li className='text-primary fw-bold mx-3'>Evidencia</li>
              <label htmlFor='furat' className='labelFont'>
                Anexar FURAT{asterisk}
              </label>
              <div style={{ position: 'relative' }}>
                <label
                  htmlFor='furatFile'
                  className='labelInputFile bg-transparent w-100 darkGray register-inputs'
                >
                  <input
                    id='furatFile'
                    type='file'
                    name='furat'
                    accept='application/pdf,.zip,.rar,.7zip'
                    className='d-none'
                    onChange={e => onChangeDoc(e, 'furat')}
                  />
                  {state.addAccident.documents.furat?.filename ? (
                    state.addAccident.documents.furat?.filename
                  ) : (
                    <label className='labelFontGray opacity-25' style={{ zIndex: -1 }}>
                      Cargar documento
                    </label>
                  )}
                  <img src={Attach} alt='agregar archivo' width='14' />
                </label>

                {state.addAccident.documents.furat?.filename && (
                  <img
                    className='hoverPointer'
                    src={littleClose}
                    alt='discard file'
                    width='16'
                    height='16'
                    style={{
                      position: 'absolute',
                      left: '95.5%',
                      bottom: '65.5%',
                      zIndex: 2,
                    }}
                    onClick={() =>
                      setState({
                        ...state,
                        addAccident: {
                          ...state.addAccident,
                          documents: {
                            ...state.addAccident.documents,
                            furat: {},
                          },
                        },
                      })
                    }
                  />
                )}
              </div>

              <label htmlFor='report_by' className='labelFont'>
                Anexos{asterisk}
              </label>
              <Dropzone
                onDrop={acceptedFile => validateFile(acceptedFile)}
                accept={'application/pdf'}
                name='other_docs'
                className='rounded'
              >
                {({ getRootProps, getInputProps }) => (
                  <section
                    className='w-100 h-100 p-1 text-center mx-auto border-dark-blue rounded-3 '
                    style={{ position: 'relative,', border: '1px solid #7FADDE' }}
                  >
                    <div {...getRootProps()}>
                      {!!state.addAccident.documents.other_docs?.filename && (
                        <img
                          className='hoverPointer'
                          src={littleClose}
                          alt='discard file'
                          width='16'
                          height='16'
                          style={{
                            position: 'absolute',
                            right: '0',
                            top: '0',
                            zIndex: 2,
                          }}
                          onClick={e => cleanFile(e)}
                        />
                      )}
                      <input
                        {...getInputProps()}
                        type='file'
                        name='other_docs'
                        onChange={e => onChangeDoc(e, 'other_docs')}
                        accept={'application/pdf'}
                        multiple={false}
                        disabled={
                          reducers.payrollReducer.accidentDetails?.result_file ? true : false
                        }
                      />
                      {state.addAccident.documents.other_docs?.filename ? (
                        <div id='2' className={'text-muted text-center px-3 pt-3'}>
                          <img src={PDF} alt='pdf icon' />
                          <p className={`darkGray`}>
                            {state.addAccident.documents.other_docs?.filename}
                          </p>
                        </div>
                      ) : (
                        <>
                          <p
                            className={`text-muted text-center "px-3 pt-3 pb-5": "p-5"} hoverPointer`}
                          >
                            {'Soltar archivo aquí'}
                          </p>
                          <div className='d-flex justify-content-end'>
                            <img src={Attach} alt='añadir' />
                          </div>
                        </>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </ul>
          </div>
          {myPermission()?.create && (
            <div className='d-flex justify-content-end pb-4'>
              <div className='d-flex justify-content-around w-75'>
                <button
                  className='btnOutlineClearBlue'
                  onClick={() => history.push('/nomina/seguridadYSalud/accidentesLaborales')}
                >
                  Cancelar
                </button>
                <button className='btn-primary rounded' onClick={() => handleSubmit()}>
                  Registrar
                </button>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}

export default RegisterAccident;
