// by jhan ortegon

import { Component } from 'react';

// icons - images
import { AddCircleOutline, Delete } from '@material-ui/icons';
import backArrow from '../../assets/img/icons/atras-icon.svg';
import Search from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

// css
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import valeStyles from '../TableAccAccounts/AccAccounts_CSS.module.scss';
import IndividualStyles from './tableCorporate.module.scss';

// hooks
import { connect } from 'react-redux';

// helpers
import * as deepcopy from 'deepcopy';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { getDateFormat } from '../../helpers/getDateFormat';
import { expRegList, generateId, validExpReg } from '../../helpers/helpers';
import { nitCalculate } from '../../helpers/nitCalculate.js';

// services
import {
  activationDesactivation,
  createCorportateClient,
  getCIIU,
  getCorporateClients,
  updateCorporateClient,
} from '../../actions/corporateClientsActions';

import {
  city_get,
  getCompanyType,
  getCountries,
  province_get,
} from '../../actions/locationActions';

// Components
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import CustomPopup from '../Popup/customPopup';

class TableCorporateClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: 'Crear Cliente - Empresa',
      modalbtnYes: true,
      filters: {
        page: 1,
        search: '',
        perpage: 10,
        id_account: this.props.currentAccount.id,
        enabled: '',
        companyType: '',
      },
      corporateClients: {
        companyType: '',
        companyTypeId: '',
        copyName: '',
        Nit: '',
        id_ciiu: '',
        created_at: getDateFormat(),
        id_country: '',
        id_province: '',
        id_city: '',
        address: '',
        phone: '',
        // whatsapp:"",
        email: '',
        comments: '',
        enabled: true,
        id_account: this.props.currentAccount.id,
        verificationCode: '',
        contacts: [],
      },
      // headerState: ["Razón Social", "NIT", "CIIU", "Tipo", "est."],
      headerState: [
        {
          tittle: 'Razón Social2',
          className: 'text-start px-2',
        },
        {
          tittle: 'Nit',
          className: 'text-start',
        },
        {
          tittle: 'Ciiu',
          className: 'text-center',
        },
        {
          tittle: 'Estado',
          className: 'w100',
        },
      ],
      editRecord: false,
      detailModal: false,
      modal: false,
    };
  }
  getInitialState = () => {
    this.setState({
      titleModal: 'Crear Cliente - Empresa',
      modalbtnYes: true,
      filters: {
        page: 1,
        search: '',
        perpage: 10,
        id_account: this.props.currentAccount.id,
        enabled: '',
        companyType: '',
      },
      corporateClients: {
        companyTypeId: '',
        companyType: '',
        copyName: '',
        Nit: '',
        id_ciiu: '',
        created_at: getDateFormat(),
        id_country: '',
        id_province: '',
        id_city: '',
        address: '',
        phone: '',
        // whatsapp:"",
        email: '',
        comments: '',
        enabled: true,
        id_account: this.props.currentAccount.id,
        verificationCode: '',
        contacts: [],
      },
      // headerState: ["Razón Social", "NIT", "CIIU", "Tipo", "est."],
      headerState: [
        {
          tittle: 'Razón Social',
          className: 'text-start px-2',
        },
        {
          tittle: 'RNC',
          className: 'text-start',
        },
        {
          tittle: 'Ciiu',
          className: 'text-center',
        },
        {
          tittle: 'Estado',
          className: 'w100',
        },
      ],
      editRecord: false,
      detailModal: false,
      modal: false,
    });
  };

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'CliEmp');

  componentDidMount() {
    this.props.getCorporateClients(this.state.filters);
    this.getInitialState();
    this.props.getCountries();
    this.props.city_get({});
    this.props.province_get({});
    this.props.getCIIU();
    this.props.getCompanyType();
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getCorporateClients(this.state.filters);
  }

  handleClick(e) {
    e.preventDefault();
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getCorporateClients(this.state.filters);
  }

  toggleModal = () => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };

  onChangeForm = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'Nit') {
      if (value.length <= 15) {
        let { corporateClients } = this.state;
        corporateClients[name] = value;
        this.setState({ corporateClients });
      }
    } else if (name === 'verificationCode') {
      if (value.length <= 1) {
        let { corporateClients } = this.state;
        corporateClients[name] = value;
        this.setState({ corporateClients });
      }
    } else {
      let { corporateClients } = this.state;
      corporateClients[name] = value;
      this.setState({ corporateClients });
    }
    this.changeBtnYes();
  };

  validateEmail = e => {
    const name = e.target.name;
    const value = e.target.value;
    if (validExpReg(value, expRegList.email)) {
      let { corporateClients } = this.state;
      corporateClients[name] = value;
      this.setState({ corporateClients });
    } else {
      let { corporateClients } = this.state;
      corporateClients[name] = '';
      this.setState({ corporateClients });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Este correo no es válido`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  onChangeSelect = e => {
    const name = e.name;
    const value = e.value;

    let { corporateClients } = this.state;
    corporateClients[name] = value;
    this.setState({ corporateClients });
    this.changeBtnYes();
  };

  onChangeSelectCompany = ({ value, name }) => {
    let { corporateClients } = this.state;
    corporateClients[name] = value;
    this.setState({ corporateClients });
    this.changeBtnYes();
  };

  changeBtnYes() {
    let { modalbtnYes } = this.state;
    if (
      this.state.corporateClients.copyName !== '' &&
      // this.state.corporateClients.companyType !== "" &&
      this.state.corporateClients.Nit !== '' &&
      this.state.corporateClients.id_ciiu !== '' &&
      this.state.corporateClients.id_country !== '' &&
      this.state.corporateClients.id_province !== '' &&
      this.state.corporateClients.id_city !== '' &&
      this.state.corporateClients.address !== '' &&
      this.state.corporateClients.phone !== '' &&
      // this.state.corporateClients.whatsapp !== "" &&
      this.state.corporateClients.email !== '' &&
      this.state.corporateClients.verificationCode !== ''
    ) {
      modalbtnYes = false;
      this.setState({ modalbtnYes });
    } else {
      modalbtnYes = true;
      this.setState({ modalbtnYes });
    }
  }
  onChangeFiltersForm = e => {
    if (e.label) {
      const value = e.value;
      let { filters } = this.state;
      filters.page = 1;
      filters.enabled = value;
      this.setState({ filters });
      this.props.getCorporateClients(this.state.filters);
    } else {
      const name = e.target.name;
      const value = e.target.value;
      let { filters } = this.state;
      filters.page = 1;
      filters[name] = value;
      this.setState({ filters });
      this.props.getCorporateClients(this.state.filters);
    }
  };

  onChangeSearch = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  handleSubmit() {
    if (!this.state.detailModal) {
      if (this.state.editRecord) {
        if (!this.filtersToCreate()) {
          // let { corporateClients } = this.state;
          // corporateClients.Nit = corporateClients.Nit + this.state.corporateClients.verificationCode;
          // this.setState({ corporateClients });
          this.props.updateCorporateClient(
            {
              ...this.state.corporateClients,
              Nit: this.state.corporateClients.Nit + this.state.corporateClients.verificationCode,
            },
            this.state.filters,
            () =>
              this.setState({
                modal: false,
              }),
          );
        }
      } else {
        if (!this.filtersToCreate()) {
          this.props.createCorportateClient(
            {
              ...this.state.corporateClients,
              Nit: this.state.corporateClients.Nit + this.state.corporateClients.verificationCode,
            },
            this.state.filters,
            () =>
              this.setState({
                modal: false,
              }),
          );
        }
      }
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  }

  filtersToCreate = () => {
    let sw = false;
    if (
      this?.state?.corporateClients?.phone?.length < 7 ||
      this?.state?.corporateClients?.phone?.length > 10
    ) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El número teléfono o celular digitado es erróneo.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      sw = true;
      return sw;
    }

    if (this.state.corporateClients.contacts.length < 1) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe diligenciar al menos un contacto.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      sw = true;
      return sw;
    }

    if (
      this.state.corporateClients.contacts.some(item => {
        return !(item.name && item.job_position && item.phone && item.email);
      })
    ) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe diligenciar la información de los contactos.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      sw = true;
      return sw;
    }

    if (this?.state?.corporateClients?.Nit?.length <= 5) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El número RNC digitado es demasiado corto.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
      sw = true;
      return sw;
    }
    if (
      this?.state?.corporateClients?.copyName === '' ||
      // this?.state?.corporateClients?.companyType === "" ||
      this?.state?.corporateClients?.id_ciiu === '' ||
      this?.state?.corporateClients?.Nit === '' ||
      this?.state?.corporateClients?.id_country === '' ||
      this?.state?.corporateClients?.id_province === '' ||
      this?.state?.corporateClients?.id_city === '' ||
      this?.state?.corporateClients?.address === '' ||
      this?.state?.corporateClients?.phone === '' ||
      // this?.state?.corporateClients?.whatsapp === "" ||
      this?.state?.corporateClients?.email === ''
    ) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Favor llenar todos los campos necesarios para la creación del cliente-empresa.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

      sw = true;
      return sw;
    }
  };

  handleEditRecord = client => {
    let { modal, corporateClients, titleModal, detailModal, modalbtnYes } = this.state;
    modal = !this.state.modal;
    detailModal = false;
    modalbtnYes = true;
    corporateClients = deepcopy(client);
    corporateClients.verificationCode = corporateClients.Nit.slice(corporateClients.Nit.length - 1);
    corporateClients.Nit = corporateClients.Nit.slice(0, corporateClients.Nit.length - 1);
    corporateClients.contacts = corporateClients.contacts.map(item => ({
      index: generateId(),
      ...item,
    }));

    corporateClients.companyTypeId = client.companyTypeId;

    corporateClients.created_at = getDateFormat();
    titleModal = 'Editar Cliente - Empresa';

    this.setState({
      modal,
      corporateClients,
      editRecord: true,
      titleModal,
      detailModal,
      modalbtnYes,
    });
  };
  handleDetailRecord = client => {
    let { modal, corporateClients, titleModal, detailModal, modalbtnYes } = this.state;
    detailModal = true;
    modalbtnYes = false;
    modal = !this.state.modal;
    corporateClients = deepcopy(client);
    corporateClients.verificationCode = corporateClients.Nit.slice(corporateClients.Nit.length - 1);

    corporateClients.Nit = corporateClients.Nit.slice(0, corporateClients.Nit.length - 1);
    corporateClients.created_at = getDateFormat();
    titleModal = 'Detalle Cliente - Empresa';
    this.setState({
      modal,
      corporateClients,
      titleModal,
      detailModal,
      modalbtnYes,
      editRecord: false,
    });
  };
  renderCorporateClients(CorporateClients, ciiu) {
    let ciiuClass = ciiu.filter(x => x.value === CorporateClients.id_ciiu);
    return (
      <tr key={CorporateClients.id} className='hover-table-row text-center align-middle'>
        <td className='col-md-5 text-start px-2'>{CorporateClients.copyName}</td>
        <td className='col-md-2 text-start'>
          {(
            CorporateClients.Nit.substring(0, CorporateClients.Nit.length - 1) +
            '-' +
            CorporateClients.Nit.substring(CorporateClients.Nit.length - 1)
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        </td>
        <td className='col-md-2'>{ciiuClass.length > 0 ? ciiuClass[0].klass : '-'}</td>
        {/* <td className="col-md-2 text-start text-uppercase">
          {CorporateClients.companyType === "Convenios_especiales" ? "Covenios especiales" : CorporateClients.companyType}
        </td> */}
        <td className='col-md-1'>
          <div className={tableStyles.groupElems}>
            <div
              className={`${
                CorporateClients.enabled ? tableStyles.greenState : tableStyles.redState
              } mx-1`}
            >
              {CorporateClients.enabled ? 'Habilitado' : 'Inhabilitado'}
            </div>
            {!this.props.location?.state?.accounting && (
              <>
                {this.myPermission()?.edit && (
                  <CustomPopup
                    triggerSrc={threeDots}
                    showEdit={this.myPermission()?.edit}
                    editClickEvent={() => this.handleEditRecord(CorporateClients)}
                    showEnable={this.myPermission()?.edit}
                    showDetails={this.myPermission()?.read}
                    textDetails='Ver Detalle'
                    showDetailsEvent={() => this.handleDetailRecord(CorporateClients)}
                    isEnabled={CorporateClients.enabled}
                    enableClickEvent={() =>
                      this.props.activationDesactivation(
                        CorporateClients.id,
                        CorporateClients.enabled,
                        this.state.filters,
                        CorporateClients.copyName,
                      )
                    }
                  />
                )}
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }
  maxlengthVal(value, length) {
    if (value.length > length) {
      value = value.slice(0, 14);
    }
    return value;
  }

  onRemoveContact(index) {
    const contacts = this.state.corporateClients.contacts.filter(item => item.index !== index);
    this.setState({
      corporateClients: {
        ...this.state.corporateClients,
        contacts,
      },
    });
    this.changeBtnYes();
  }

  onAddContact() {
    const contacts = this.state.corporateClients.contacts;
    const option = [
      ...contacts,
      {
        index: generateId(),
        name: '',
        job_position: '',
        email: '',
        phone: '',
      },
    ];
    this.setState({
      corporateClients: {
        ...this.state.corporateClients,
        contacts: option,
      },
    });
    this.changeBtnYes();
  }

  onUpdateContact(contact, key, value) {
    const options = this.state.corporateClients.contacts.map(item => {
      if (item.index === contact.index) {
        return { ...item, [key]: value };
      }
      return item;
    });

    this.setState({
      corporateClients: {
        ...this.state.corporateClients,
        contacts: options,
      },
    });
    this.changeBtnYes();
  }

  render() {
    let optionsCiius = [];
    if (this.props.ciiu) {
      this.props.ciiu.forEach(item => {
        optionsCiius.push({
          value: item.id,
          label: `${item.klass} - ${item.description}`,
          name: 'id_ciiu',
          klass: item.klass,
        });
      });
    }
    let listCorporateClients;
    if (this.props.corporateClients !== undefined) {
      const corporateClients = this.props.corporateClients;
      listCorporateClients = corporateClients.map(x => {
        return this.renderCorporateClients(x, optionsCiius);
      });
    }
    let renderHeaders = this.state.headerState.map((header, index) => {
      return (
        <th key={index}>
          <div
            className={`${header === 'est.' || header === '' ? tableStyles.groupElems : ''} ${header.className}`}
          >
            {header.tittle}
          </div>
        </th>
      );
    });

    let countriesOptions = [];
    if (this.props.countries) {
      this.props.countries.forEach(item => {
        countriesOptions.push({
          value: item.id,
          label: item.name,
          name: 'id_country',
        });
      });
    }
    let provincesOptions = [];
    if (this.props.provinces) {
      this.props.provinces.forEach(item => {
        provincesOptions.push({
          value: item.id,
          label: item.name,
          name: 'id_province',
        });
      });
    }
    let citiesOptions = [];
    if (this.props.cities) {
      this.props.cities.forEach(item => {
        citiesOptions.push({
          value: item.id,
          label: item.name,
          name: 'id_city',
        });
      });
    }
    return (
      <div className={tableStyles.container}>
        {this.props.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {this.myPermission()?.read ? (
          <div className={tableStyles.tableArea}>
            <Row className='d-flex'>
              <Col
                xs={10}
                style={{
                  marginLeft:
                    window.location.pathname !== '/administracion/corporateClients' && '-40px',
                }}
              >
                <h1 className={tableStyles.title}>
                  {window.location.pathname !== '/administracion/corporateClients' && (
                    <img
                      onClick={() => this.props.history.push('/contabilidad/terceros')}
                      src={backArrow}
                      alt='icon'
                      height={40}
                      className={`mr-2 mb-2 cursorPointer`}
                    ></img>
                  )}
                  Clientes - Empresas
                </h1>
              </Col>
            </Row>

            <div>
              <div className={`flex-direction-row align-middle ${valeStyles.widtDiv}`}>
                <div className='d-flex gap-4'>
                  {this.myPermission()?.read && (
                    <form
                      className='w-100 d-grid gap-2 align-items-end mb-1'
                      onSubmit={e => this.handleClick(e)}
                      style={{ gridTemplateColumns: '200px 300px' }}
                    >
                      <div className={`${IndividualStyles.ItemInicio}`}>
                        <p className={`${tableStyles.crudModalLabel}`}>Estado</p>

                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          name='enabled'
                          className={`${tableStyles.widthSelect} text-secondary w-100`}
                          onChange={this.onChangeFiltersForm}
                          options={[
                            { value: '', label: 'Seleccionar...' },
                            { value: 0, label: 'Inhabilitado' },
                            { value: 1, label: 'Habilitado' },
                          ]}
                          placeholder='Seleccionar...'
                          styles={customSelectNewDark}
                          maxHeight={26}
                        />
                      </div>
                      <div
                        className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemLarge}`}
                        style={{ 'align-self': 'flex-end' }}
                      >
                        <div className='d-flex w-100'>
                          <input
                            className={`${tableStyles.SearchNew}`}
                            name='search'
                            type='text'
                            placeholder='Buscar...'
                            onChange={this.onChangeSearch}
                            value={this.state.filters.search}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                this.handleClick(e);
                              }
                            }}
                          />
                          <img
                            src={Search}
                            alt='Search icon'
                            height='24px'
                            className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                            onClick={e => this.handleClick(e)}
                          />
                        </div>
                      </div>
                    </form>
                  )}

                  {this.props.location?.state?.accounting ? (
                    false
                  ) : this.myPermission()?.create ? (
                    <div className='w-100 d-flex justify-content-end'>
                      {this.myPermission()?.create && (
                        <div
                          className='d-flex justify-content-end groupAddButton align-items-center'
                          style={{ marginTop: '15px', width: '200px' }}
                          onClick={() => this.toggleModal(true)}
                        >
                          <label htmlFor='newAccident' className='darkGray fw-bold'>
                            Nuevo cliente
                          </label>
                          <button className='addCheckButton mx-2' />
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            <ModalNew
              hideFooter={
                this.state.editRecord ? !this.myPermission()?.edit : !this.myPermission()?.create
              }
              title={this.state.titleModal}
              show={this.state.modal}
              size='lg'
              onHide={() => this.toggleModal(false)}
              btnYesName={'Aceptar'}
              hideCancelButton={this.state.detailModal ? () => this.handleSubmit() : null}
              btnYesEvent={() => this.handleSubmit()}
              btnYesDisabled={this.state.modalbtnYes}
            >
              <div className='row gy-2'>
                <div className='col-12'>
                  <div className={`${IndividualStyles.backgroundModal}`}>
                    <label className={`${IndividualStyles.subtitle}`}>Información general</label>
                    <div className='row gx-3 '>
                      <div className='col-lg-8'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Razón social<span className='text-danger'>*</span>
                        </small>
                        <input
                          className='register-inputs'
                          name='copyName'
                          type='text'
                          onChange={this.onChangeForm}
                          value={this.state.corporateClients.copyName}
                          disabled={this.state.detailModal}
                        />
                      </div>

                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          RNC<span className='text-danger'>*</span>
                        </small>
                        <div className='d-flex'>
                          <NumberFormat
                            className={`register-inputs ${IndividualStyles.nitBig}`}
                            name='Nit'
                            type='tel'
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            placeholder='000.000.000'
                            disabled={this.state.detailModal}
                            value={this.state.corporateClients.Nit}
                            isAllowed={values => values.value.length <= 9} //max 10 digitos
                            onValueChange={values => {
                              const { value } = values;
                              let { corporateClients } = this.state;
                              corporateClients.Nit = value;
                              corporateClients.verificationCode = nitCalculate(
                                this.state.corporateClients.Nit,
                              );
                              this.setState({ corporateClients });
                              this.changeBtnYes();
                            }}
                          />
                        </div>
                      </div>

                      {/* <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Código empresa ProgramasTOP
                        </small>
                        <input
                          className='register-inputs'
                          name='code_top'
                          value={this.state.corporateClients.code_top}
                          onChange={this.onChangeForm}
                          disabled={this.state.detailModal}
                        />
                      </div> */}

                      <div className='col-lg-5'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Código CIIU y actividad económica<span className='text-danger'>*</span>
                        </small>
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          name='id_ciiu'
                          placeholder='Seleccionar...'
                          defaultValue={optionsCiius.find(
                            elem => elem.value === this.state.corporateClients.id_ciiu,
                          )}
                          options={[{ label: 'Seleccionar...', value: '' }, ...optionsCiius]}
                          styles={customSelectNewDark}
                          isDisabled={this.state.detailModal}
                          onChange={e => this.onChangeSelect(e)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Fecha de creación
                        </small>
                        <input
                          className='register-inputs'
                          name='copyName'
                          type='text'
                          style={{ color: '#005DBF' }}
                          value={this.state.corporateClients.created_at}
                          disabled={this.state.detailModal}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className='px-3'>
                    <label className={IndividualStyles.subtitle}>Oficina principal</label>
                    <div className='row'>
                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          País<span className='text-danger'>*</span>
                        </small>
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          name='id_country'
                          options={[{ label: 'Seleccionar...', value: '' }, ...countriesOptions]}
                          placeholder='Seleccionar...'
                          styles={customSelectNewDark}
                          isDisabled={this.state.detailModal}
                          defaultValue={countriesOptions.find(
                            elem => elem.value === this.state.corporateClients.id_country,
                          )}
                          onChange={e => {
                            this.onChangeSelect(e);
                            this.props.province_get({ country: e.value });
                          }}
                        />
                      </div>

                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Departamento<span className='text-danger'>*</span>
                        </small>
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          name='id_province'
                          placeholder='Seleccionar...'
                          options={[{ label: 'Seleccionar...', value: '' }, ...provincesOptions]}
                          styles={customSelectNewDark}
                          isDisabled={this.state.detailModal}
                          defaultValue={provincesOptions.find(
                            elem => elem.value === this.state.corporateClients.id_province,
                          )}
                          onChange={e => {
                            this.onChangeSelect(e);
                            this.props.city_get({ province: e.value });
                          }}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Ciudad<span className='text-danger'>*</span>
                        </small>
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          name='id_city'
                          styles={customSelectNewDark}
                          placeholder='Seleccionar...'
                          options={[{ label: 'Seleccionar...', value: '' }, ...citiesOptions]}
                          isDisabled={this.state.detailModal}
                          defaultValue={citiesOptions.find(
                            elem => elem.value === this.state.corporateClients.id_city,
                          )}
                          onChange={e => {
                            this.onChangeSelect(e);
                          }}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Dirección<span className='text-danger'>*</span>
                        </small>
                        <input
                          className='register-inputs'
                          name='address'
                          type='text'
                          onChange={this.onChangeForm}
                          disabled={this.state.detailModal}
                          value={this.state.corporateClients.address}
                        />
                      </div>

                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-secondary ms-1'>
                          Celular<span className='text-danger'>*</span>
                        </small>
                        <input
                          className='register-inputs'
                          name='phone'
                          type='number'
                          onChange={this.onChangeForm}
                          disabled={this.state.detailModal}
                          value={this.state.corporateClients.phone}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                          Correo electrónico<span className='text-danger'>*</span>
                        </small>
                        <input
                          className='register-inputs'
                          name='email'
                          type='email'
                          onChange={this.onChangeForm}
                          // onBlur={this.validateEmail}
                          onBlur={this.validateEmail}
                          disabled={this.state.detailModal}
                          value={this.state.corporateClients.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 px-4'>
                  <label className={`${IndividualStyles.subtitle}`}>Contactos</label>

                  <div className='col-lg-12 d-flex justify-content-end'>
                    <button
                      type='button'
                      onClick={this.onAddContact.bind(this)}
                      className={`${tableStyles.buttonTextPrimary} mb-3`}
                    >
                      <AddCircleOutline fontSize='small' />
                      <small className='ms-2'>Agregar</small>
                    </button>
                  </div>

                  <div
                    className='row g-0 p-2 text-white fw-bold small'
                    style={{
                      background: '#005dbf',
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,
                    }}
                  >
                    <div className='col'>Nombre</div>
                    <div className='col'>Cargo</div>
                    <div className='col'>Teléfono</div>
                    <div className='col'>Correo</div>
                    <div className='col-1'></div>
                  </div>
                  <div
                    className={`${tableStyles.odd_rows} overflow-auto small`}
                    style={{ maxHeight: 130 }}
                  >
                    {this.state.corporateClients.contacts.length ? (
                      this.state.corporateClients.contacts.map(item => (
                        <div
                          key={item.index}
                          className='row gx-2 p-1 mr-2 align-items-center text-muted'
                        >
                          <div className='col'>
                            <input
                              value={item.name}
                              className='register-inputs'
                              type='text'
                              placeholder='Escribe...'
                              onChange={({ target }) =>
                                this.onUpdateContact(item, 'name', target.value)
                              }
                            />
                          </div>
                          <div className='col'>
                            <input
                              value={item.job_position}
                              className='register-inputs'
                              type='text'
                              placeholder='Escribe...'
                              onChange={({ target }) =>
                                this.onUpdateContact(item, 'job_position', target.value)
                              }
                            />
                          </div>
                          <div className='col'>
                            <input
                              value={item.phone}
                              className='register-inputs'
                              type='number'
                              placeholder='Escribe...'
                              onChange={({ target }) =>
                                this.onUpdateContact(item, 'phone', target.value)
                              }
                            />
                          </div>
                          <div className='col'>
                            <input
                              value={item.email}
                              className='register-inputs'
                              type='email'
                              placeholder='Escribe...'
                              onChange={({ target }) =>
                                this.onUpdateContact(item, 'email', target.value)
                              }
                            />
                          </div>
                          <div className='col-1 text-end'>
                            {!item.id && (
                              <Delete
                                fontSize='small'
                                className='pointer hoverable'
                                onClick={() => this.onRemoveContact(item.index)}
                              />
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='col-12 py-2 text-muted text-center'>
                        No hay contactos asociados
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <small style={{ fontSize: 12 }} className='text-primary ms-1'>
                    Comentarios
                  </small>
                  <textarea
                    className={`register-inputsTextArea ${tableStyles.outlineNone}`}
                    style={{ color: '#58595b' }}
                    name='comments'
                    type='text'
                    onChange={this.onChangeForm}
                    disabled={this.state.detailModal}
                    value={this.state.corporateClients.comments}
                  />
                </div>
              </div>
            </ModalNew>
            {this.myPermission()?.read && (
              <GenericTableNew
                fontFamilyTable={'fontFamilyTable'}
                dark={true}
                headers={renderHeaders}
              >
                {listCorporateClients}
              </GenericTableNew>
            )}

            {this.myPermission()?.read && (
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {this.props.totalcorporateClients ? this.state.filters.page : ''}
                  {' de '}
                  {Math.ceil(this.props.totalcorporateClients / this.state.filters.perpage)
                    ? Math.ceil(this.props.totalcorporateClients / this.state.filters.perpage)
                    : ''}{' '}
                  ({this.props.totalcorporateClients} encontrados)
                </p>
                <Pagination
                  activePage={this.state.filters.page}
                  itemsCountPerPage={10}
                  totalItemsCount={this.props.totalcorporateClients}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  corporateClients: state.corporateclientsReducer.corporateClients,
  totalcorporateClients: state.corporateclientsReducer.totalcorporateClients,
  ciiu: state.corporateclientsReducer.ciiu,
  countries: state.locationReducer.countries,
  cities: state.locationReducer.cities,
  provinces: state.locationReducer.provinces,
  companyType: state.companyTypeReducer.data,
  loading: state.corporateclientsReducer.corporate_loading,
});

const mapDispatchToProps = dispatch => ({
  getCorporateClients: filters => {
    dispatch(getCorporateClients(filters));
  },
  createCorportateClient: (data, filters, doAfter) => {
    dispatch(createCorportateClient(data, filters, doAfter));
  },
  updateCorporateClient: (data, filters, doAfter) => {
    dispatch(updateCorporateClient(data, filters, doAfter));
  },
  getCIIU: () => {
    dispatch(getCIIU());
  },

  getCountries: () => {
    dispatch(getCountries());
  },

  getCompanyType: () => {
    dispatch(getCompanyType());
  },

  city_get: async filters => {
    dispatch(city_get(filters));
  },
  province_get: async filters => {
    dispatch(province_get(filters));
  },
  activationDesactivation: (obj, status, filters, description) => {
    dispatch(activationDesactivation(obj, status, filters, description));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableCorporateClient);
