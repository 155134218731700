import { ACTIVE_FILTER_CHECK_TELE } from "../actions/actionTypes";

const initialState = {
  active:false,
  checked:false
};

export const filterCheckTeleScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_FILTER_CHECK_TELE:
      return {
        ...state,
        active: action.payload,
      };
      case 'CHECKED_FILTER_CHECK_TELE':
      return {
        ...state,
        checked: action.payload,
      };
    default:
      return state;
  }
};
