import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddCircleOutline } from '@material-ui/icons';
import Pagination from 'react-js-pagination';
//
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
import SearchIcon from '../../../assets/img/icons/lupa.svg';
//
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlert, loader } from '../../../helpers';
import ModifyRipsData from './Modify/ModifyRipsData';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

export default function RipsData() {
  const store = useSelector(state => state);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    name: '',
    eaccount: store.loginReducer.currentAccount.id,
  });

  const [isOpenModifyModal, setIsOpenModifyModal] = useState(false);

  const { trigger: getRipsData, load: ripsLoader, results: ripsDataList } = useGetMethod();
  const { trigger: getEquivalent, results: equivalent } = useGetMethod();

  const { trigger: updateStatus, load: updateStatusLoader } = usePostMethod();
  const { load: updateLoader, trigger: updateRipsData } = usePostMethod();

  const onSuccessAction = async () => {
    await getRipsData({
      url: '/receivable/ripData/',
      objFilters: { ...filters, page: 1, name: '' },
      token: store.loginReducer.Authorization,
    });
  };

  const onUpdateStatus = item => {
    updateStatus({
      token: store.loginReducer.Authorization,
      body: {
        id: item.id,
        status: item.status === '1' ? 0 : 1,
        eaccount: store.loginReducer.currentAccount.id,
      },
      method: 'PUT',
      url: '/receivable/ripData/',
      succesAction: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: result.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await onSuccessAction();
          }
        });
      },
    });
  };

  const onFilterSearch = e => {
    e.preventDefault();
    const { name } = e.target;
    setFilters({ ...filters, name: name.value });
  };

  const onModifyRipsData = (result = []) => {
    const isNotValid = result.some(item => !(item.name && item.equivalent));

    if (isNotValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, asegúrese de diligenciar todos los campos.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    updateRipsData({
      token: store.loginReducer.Authorization,
      body: {
        dataRip: result,
        eaccount: store.loginReducer.currentAccount.id,
        created_by: store.loginReducer.user_data.id,
      },
      method: 'PUT',
      url: '/receivable/ripData/',
      succesAction: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: result.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await onSuccessAction();
            setIsOpenModifyModal(false);
          }
        });
      },
      doAfterException: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const formattedEquivalent = useMemo(
    () =>
      equivalent.results?.map(el => ({
        value: el.id,
        label: el.label,
      })) || [],
    [equivalent.results],
  );

  const renderFilters = () => {
    return (
      <div className='d-flex align-items-end'>
        <form onSubmit={onFilterSearch} className='d-flex flex-grow-1 me-3'>
          <input
            placeholder='Buscar...'
            className={`${tableStyles.SearchNew} w-100 me-2`}
            name='name'
          />
          <img
            style={{ width: '1.5rem' }}
            src={SearchIcon}
            alt='User icon'
            className={`${tableStyles.iconSvgMargin2} pointer`}
            onClick={async () => {
              await getRipsData({
                url: '/receivable/ripData/',
                objFilters: filters,
                token: store.loginReducer.Authorization,
              });
            }}
          />
        </form>
        <div className={tableStyles.buttonTextPrimary} onClick={() => setIsOpenModifyModal(true)}>
          <small className='mr-2' style={{ whiteSpace: 'nowrap' }}>
            Modificar Datos RIPS
          </small>
          <AddCircleOutline stroke='2' fontSize='small' />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-3'>
        Datos RIPS
      </th>,
      <th key={2} className='text-start'>
        Homólogo
      </th>,
      <th key={3} className='text-center'>
        Estado
      </th>,
      <th key={4} className='text-center'>
        &nbsp;
      </th>,
    ];

    return (
      <GenericTableNew lowerCase headers={tableHeaders} dark={true}>
        {ripsDataList?.results?.map(item => {
          return (
            <tr key={item.id}>
              <td className='text-start ps-3'>{item.name}</td>
              <td className='text-start'>{item.label}</td>
              <td>
                <div
                  className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
                >
                  {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
                </div>
              </td>
              <td className='text-center'>
                <CustomPopupExtend
                  showEnable={true}
                  position='right'
                  triggerSrc={ThreeDots}
                  isEnabled={item.status === '1'}
                  enableClickEvent={() => {
                    customSwaltAlert({
                      icon: 'warning',
                      title: '¿Está seguro?',
                      text: `Se ${item.status === '1' ? 'deshabilitará' : 'habilitará'} el dato RIPS: ${item.name}`,
                      confirmButtonText: 'Si, continuar',
                      cancelButtonText: 'Cancelar',
                      showCancelButton: true,
                    }).then(result => {
                      if (result.isConfirmed) {
                        onUpdateStatus(item);
                      }
                    });
                  }}
                />
              </td>
            </tr>
          );
        })}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {ripsLoader && loader}
        {updateStatusLoader && loader}
        {updateLoader && loader}

        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Datos RIPS</h1>
            {renderFilters()}
            {renderTable()}
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(ripsDataList?.row_count / filters.perpage)
                  ? Math.ceil(ripsDataList?.row_count / filters.perpage)
                  : '1'}{' '}
                ({ripsDataList?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={ripsDataList?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
        <ModifyRipsData
          isOpen={isOpenModifyModal}
          onClose={() => setIsOpenModifyModal(false)}
          ripsData={ripsDataList?.results}
          equivalent={formattedEquivalent}
          onSubmit={onModifyRipsData}
        />
      </>
    );
  };

  useEffect(() => {
    async function fetchAsyncData() {
      await getRipsData({
        url: '/receivable/ripData/',
        objFilters: filters,
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [filters, store.loginReducer.Authorization, getRipsData]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getEquivalent({
        url: '/receivable/ripData/getEquivalent/',
        objFilters: {},
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [filters, store.loginReducer.Authorization, getEquivalent]);

  return render();
}
