import {
    GET_AGREEMENTS,
    LOADING,
    GET_AGREEMENTS_PROVIDERS,
    GET_AGREEMENT_DETAILS
  } from "../actions/actionTypes";
  
  const initialState = {
    loading: false,
    agreements: [],
    listProviders: [],
    row_total: 0,
    agreementDetails:{},
    agreements_loading: false,
    
    chosen_agreement:{},
    chosen_agreement_loading: false,
    
  };
  export const agreementReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOADING:
        return { ...state, loading: action.payload };
  
      case GET_AGREEMENTS:
        return {
          ...state,
          agreements: action.payload,
          row_total: action.row_total,
        };

      case GET_AGREEMENTS_PROVIDERS:
        return {
          ...state,
          listProviders: action.payload,          
        };

      case GET_AGREEMENT_DETAILS:
        return {
          ...state,
          agreementDetails: action.payload,
        };
      
      default:
        return state;
    }
  };
  