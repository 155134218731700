import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { Col, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { expRegList, isEmptyOrUndefined, message, validExpReg } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';
import { createNewResolution, enableNewResolution } from '../../actions/bankNoteActions';
import moment from 'moment';
import { customSwaltAlert } from '../../helpers';
import { Title } from '../../shared/Title';
import { Radio, RadioGroup } from '@mui/material';
import { MultiSelect } from '../../shared';
import { useGetMethod } from '../../Hooks';

export const NewResolution = () => {
  const location = useLocation();
  let isEnable = location?.state?.isEnable;
  let isDetail = location?.state?.isDetail;
  let billInfo = location?.state?.billInfo;
  const store = useSelector(state => state);
  const history = useHistory();
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const [isLoading, setIsLoading] = useState(false);
  const token = store.loginReducer.Authorization;
  const [inputs, setInputs] = useState({
    eaccount: idEnterprise,
    nbr: '',
    sequence: '',
    authDate: '',
    dueDate: '',
    prefix: '',
    origin: '',
    end: '',
    user: idAccount,
    type: 'Autoriza',
    typeBill: 'pos',
    users: [],
  });

  const regexNumbers = /^[0-9]*$/;
  const [optionSelected, setOptionSelected] = useState([]);
  const [listBillingTypes, setListBillingTypes] = useState([]);

  //////// HTTPS GET ////////////
  const { results: listUsers, load: loaderListUsers, trigger: getListUsers } = useGetMethod();
  const { results: billTypes, load: loadTypeBills, trigger: getBillTypes } = useGetMethod();

  useEffect(() => {
    if (isEnable || isDetail) {
      setInputs({
        id: billInfo.id,
        eaccount: idEnterprise,
        nbr: billInfo?.nbr,
        sequence: billInfo?.sequence,
        authDate: billInfo?.authDate,
        dueDate: billInfo?.dueDate,
        prefix: billInfo?.prefix,
        origin: billInfo?.origin,
        end: billInfo?.end,
        user: idAccount,
        type: billInfo?.type,
        comments: billInfo?.comments,
        typeBill: billInfo?.typeBill,
        users: billInfo?.users?.map(el => ({ id: el?.value })) || [],
      });

      setOptionSelected(
        billInfo?.users?.map(el => ({
          label: `${el?.firstName} ${el?.lastName} - ${el?.docNum}`,
          value: el?.id,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getListUsers({
      token,
      url: '/users/select',
      objFilters: { active: 1 },
    });
  }, [getListUsers, token]);

  useEffect(() => {
    getBillTypes({
      token,
      url: '/admin/resolutions/types'
    })
    setListBillingTypes(billTypes.results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBillingTypes, getBillTypes, token ]);

  const handleBack = () => {
    history.push('/administracion/Facturacion/ResolucionesFacturacion');
  };

  const handleSubmit = async () => {
    const validateInputDateEnd = moment(inputs.authDate).format('YYYYMMDD');
    const maxDate = moment(inputs.dueDate).format('YYYYMMDD');

    if (
      isEmptyOrUndefined(inputs.nbr) ||
      isEmptyOrUndefined(inputs.sequence) ||
      isEmptyOrUndefined(inputs.authDate) ||
      isEmptyOrUndefined(inputs.dueDate) ||
      isEmptyOrUndefined(inputs.origin) ||
      isEmptyOrUndefined(inputs.end) ||
      !inputs?.users?.length
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor llene los campos obligatorios',
        showCancelButton: false,
      });
    }

    if (maxDate < validateInputDateEnd) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'La fecha de finalización no puede ser menor a la fecha de aprobación',
        showCancelButton: false,
      });
    }

    const inputsNumber = {
      origin: Number(inputs?.origin),
      end: Number(inputs?.end),
      sequence: Number(inputs?.sequence),
      nbr: Number(inputs?.nbr),
    };

    try {
      setIsLoading(true);
      const result = isEnable
        ? await enableNewResolution(
            {
              ...inputs,
              ...inputsNumber,
              typeBill: billInfo.typeBill.id,
              type: inputs.type === 'Autoriza' ? 'allow' : 'enabled',
            },
            token,
          )
        : await createNewResolution(
            {
              ...inputs,
              ...inputsNumber,
              type: inputs.type === 'Autoriza' ? 'allow' : 'enabled',
            },
            token,
          );
      if (result.success) {
        setIsLoading(false);
        setInputs({
          id: '',
          eaccount: idEnterprise,
          nbr: '',
          sequence: '',
          authDate: '',
          dueDate: '',
          prefix: '',
          origin: '',
          end: '',
          user: idAccount,
          typeBill: 'pos',
          users: [],
          type: 'Autoriza',
        });
        handleBack();
        const messageText = `se ha ${
          isEnable ? 'actualizado' : 'creado'
        } la resolución: ${inputs?.prefix}`;
        const tittle = `${isEnable ? 'Actualizado exitosamente' : 'Creado exitosamente'}`;
        return message('success', tittle, messageText, undefined, true);
      } else {
        setIsLoading(false);
        return message('error', 'Ha ocurrido un error', result.message, undefined, true);
      }
    } catch (error) {
      setIsLoading(false);
      message('error', 'Error', error.message, undefined, true);
    }
  };
  // ANCHOR RETURN
  return (
    <>
      {isLoading || loaderListUsers || loadTypeBills ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <div className={tableStyles.container} style={{ paddingTop: '30px' }}>
        <div className={tableStyles.tableArea}>
          <Title
            title={
              isEnable
                ? `Habilitar resolución`
                : isDetail
                  ? 'Detalle resolución'
                  : 'Nueva resolución'
            }
            onClickIcon={handleBack}
          />

          <Row style={{ rowGap: '5px' }}>
            <div className='d-flex flex-column mt-4'>
              <span className={`${tableStyles.darkBlueText}`}>Tipo de facturación</span>
              <div className='d-flex'>
                <RadioGroup
                  row
                  value={inputs.typeBill}
                  onChange={(event) => {
                    if (!isDetail && !isEnable) {
                      setInputs(state => ({
                        ...state,
                        typeBill: event.target.value,
                      }));
                    }
                  }}
                >
                  {billTypes?.results &&
                    billTypes?.results.map(_type => {
                      return (
                        <div key={_type.id}>
                          {!billInfo ? (
                            <>
                              <Radio
                                name={_type.name}
                                value={_type.id}
                                className={`${tableStyles.darkBlueText}`}
                              />
                              <label htmlFor={_type.name} className={`${tableStyles.darkBlueText} ${tableStyles.f12} `}>
                                {_type.name}
                              </label>
                            </>
                          ) : (
                            <>
                              <Radio
                                name={_type.name}
                                value={_type.id}
                                checked={billInfo?.typeBill.id === _type.id}
                                className={`${tableStyles.darkBlueText}`}
                              />
                              <label htmlFor={_type.name} className={`${tableStyles.darkBlueText} ${tableStyles.f12} `}>
                                {_type.name}
                              </label>
                            </>
                          )}
                        </div>
                      )
                    })
                  }
                </RadioGroup>
              </div>
            </div>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>Prefijo</p>
              <input
                onChange={e => {
                  if (!isDetail) {
                    setInputs({ ...inputs, prefix: e.target.value });
                  }
                }}
                disabled={isDetail}
                value={inputs.prefix}
                type='text'
                placeholder='Escribe...'
                className='new_register-inputs'
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Factura actual
                <span className={'text-danger'}>*</span>
              </p>
              <input
                disabled={isEnable || isDetail}
                onChange={e => {
                  if (
                    !isDetail & !isEnable & validExpReg(e.target.value, expRegList.number) ||
                    e.target.value === ''
                  ) {
                    setInputs({ ...inputs, sequence: e.target.value });
                  }
                }}
                value={inputs.sequence}
                type='number'
                placeholder='Escribe...'
                className='new_register-inputs'
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Primera factura
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  if (!isDetail & regexNumbers.test(e.target.value)) {
                    setInputs({ ...inputs, origin: e.target.value });
                  }
                }}
                disabled={isDetail}
                type='number'
                value={inputs.origin}
                placeholder='Escribe...'
                className='new_register-inputs'
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Última factura
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  if (!isDetail & regexNumbers.test(e.target.value)) {
                    setInputs({ ...inputs, end: e.target.value });
                  }
                }}
                disabled={isDetail}
                type='text'
                value={inputs.end}
                placeholder='Escribe...'
                className='new_register-inputs'
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Número de resolución
                <span className={'text-danger'}>*</span>
              </p>
              <input
                onChange={e => {
                  if (isNaN(Number(e.target.value))) return

                  if (!isDetail) {
                    setInputs({ ...inputs, nbr: e.target.value });
                  }
                }}
                disabled={isDetail}
                value={inputs.nbr}
                placeholder='Escribe...'
                className='new_register-inputs'
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Usuario
                <span className={'text-danger'}>*</span>
              </p>
              <div>
                <MultiSelect
                  key='1'
                  options={
                    listUsers?.results?.map(el => ({
                      label: `${el?.first_name} ${el?.first_surname} - ${el?.doc_num}`,
                      value: el?.id,
                    })) || []
                  }
                  isSelectAll={true}
                  value={optionSelected}
                  menuPlacement={'bottom'}
                  nameList={'usuario'}
                  accentuation={'o'}
                  onChange={elements => {
                    if (!isDetail) {
                      setOptionSelected(elements);

                      setInputs({
                        ...inputs,
                        users: elements?.length ? elements?.map(el => ({ id: el?.value })) : [],
                      });
                    }
                  }}
                />
              </div>
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de aprobación
                <span className={'text-danger'}>*</span>
              </p>
              <input
                disabled={isDetail}
                onChange={e => {
                  if (!isDetail) {
                    setInputs({ ...inputs, authDate: e.target.value });
                  }
                }}
                value={inputs.authDate}
                type='date'
                className='new_register-inputs'
                style={{ fontWeight: 'lighter' }}
                name='position'
              />
            </Col>

            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de fin
                <span className={'text-danger'}>*</span>
              </p>
              <input
                disabled={isDetail}
                onChange={e => {
                  if (!isDetail) {
                    setInputs({ ...inputs, dueDate: e.target.value });
                  }
                }}
                value={inputs.dueDate}
                type='date'
                min={inputs.authDate}
                className='new_register-inputs'
                name='position'
                style={{ fontWeight: 'lighter' }}
              />
            </Col>

            {isDetail ? (
              <Col xs={12}>
                <textarea
                  disabled
                  value={inputs?.comments || '-'}
                  placeholder={'Escribir...'}
                  className={`register-inputs text-secondary py-1 px-2`}
                  style={{
                    minHeight: '10rem',
                    outline: 'none',
                    borderColor: '#01a0f6',
                    marginTop: '12px',
                  }}
                />
              </Col>
            ) : (
              ''
            )}

            {!isDetail ? (
              <Col xs={12} className={`pt-3 customTabs__Footer mt-2`}>
                <Button onClick={() => handleSubmit()} className={tableStyles.btnPrimary}>
                  {isDetail ? 'Actualizar' : 'Guardar'}
                </Button>

                <Button
                  variant='outline-primary'
                  onClick={() => handleBack()}
                  className={tableStyles.btnSecondary}
                >
                  Cancelar
                </Button>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </div>
      </div>
    </>
  );
};
