import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeComplaints } from '../../actions/complaintsAction';
import Atras from '../../assets/img/icons/atras-icon.svg';
import Send from '../../assets/img/icons/send.svg';
import ModalNew from '../../components/Layouts/ModalNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert } from '../../helpers';
import { getFilenameByURL } from '../../helpers/helpers';
import Styles from './tableStyles.module.scss';

function ReportComplaintDetail(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const idEmployees = props.info.complaint_id;
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState({ show: false });
  const [employees, setEmployees] = useState([]);
  const asterisk = <b style={{ color: '#FF8B00' }}>*</b>;
  const [filters, setFilters] = useState({
    result_by: counter.loginReducer.user_data.id,
    result_by_name:
      counter.loginReducer.user_data.first_name +
      ' ' +
      counter.loginReducer.user_data.first_surname,
    entity_account: counter.loginReducer.currentAccount.id,
  });
  useEffect(() => {
    if (Array.isArray(props.info.collaborators_involved)) {
      setEmployees(props.info.collaborators_involved.map(x => x.employee));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal2.show]);

  const isBoxSelected = id_req => {
    if (Array.isArray(employees)) {
      let target = employees.find(x => x === id_req);
      return !!target;
    }
  };
  const changeBoxSelected = item => {
    let tempSelected = employees;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x === item);
      if (target) {
        tempSelected = tempSelected.filter(x => x !== item);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }

      setEmployees(tempSelected);
    }
  };
  function handleClick() {
    if (props?.setDetails) {
      props?.setDetails({ show: false });
    }
    setEmployees([]);
  }
  const sendForm = params => {
    if (showModal2.params === 'wakeupcall' || showModal2.params === 'disprocess') {
      filters.implicated_employees = employees;
    }
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se enviará ${props.info?.type === 'Reporte' ? 'el reporte' : 'la queja'}: ${props.info.concept}`,
      icon: 'warning',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(
          changeComplaints(
            filters,
            () => handleClick(),
            params ? params : showModal2.params,
            idEmployees,
            props.info,
          ),
        );
      }
    });
  };

  return (
    <>
      {!!counter.agreementReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div>
          <div className='d-flex'>
            <img
              alt='w'
              className={`${tableStyles.title} mr-3 cursorPointer`}
              style={{ width: '20px', marginTop: '3.1rem' }}
              src={Atras}
              onClick={handleClick}
            />
            <h1 className={tableStyles.title}>Reportes y quejas</h1>
          </div>
          <p className='subtitleBlue' style={{ marginLeft: '2.5rem' }}>
            {props.info.concept}
          </p>
        </div>
        <div
          className={`pt-3 pb-3`}
          style={{
            borderRadius: '10px',
            paddingLeft: '24px',
          }}
        >
          <section className='d-flex'>
            {/* Rows */}
            <div className='d-flex flex-column w-25'>
              <ul>
                <li className='text-primary fw-bold'>Información general</li>
              </ul>
              <ul>
                <li className='darkGray fw-bold list-unstyled'>Fecha:</li>
                <li className='darkGray fw-bold list-unstyled mt-2'>Reportante:</li>
                <li className='darkGray fw-bold list-unstyled mt-2'>Cargo:</li>
                <li className='darkGray fw-bold list-unstyled mt-2'>Área:</li>
              </ul>
            </div>
            {/* details show */}
            <div className='d-flex flex-column'>
              <ul>
                <li className='text-primary fw-bold list-unstyled'>ㅤ </li>
              </ul>
              <ul>
                <li className='darkGray list-unstyled'>
                  {props.info.report_date ? props.info.report_date : '-'}
                </li>
                <li className='darkGray list-unstyled mt-2'>
                  {props.info.report ? props.info.report : '-'}
                </li>
                <li className='darkGray list-unstyled mt-2'>
                  {props.info.job_position ? props.info.job_position : '-'}
                </li>
                <li className='darkGray list-unstyled mt-2'>
                  {props.info.area ? props.info.area : '-'}
                </li>
              </ul>
            </div>
          </section>
          <section className='d-flex'>
            {/* Rows */}
            <div className='d-flex flex-column w-25'>
              <ul>
                <li className='text-primary fw-bold'>Descripción</li>
              </ul>
              <ul>
                <li className='darkGray fw-bold list-unstyled mt-2'>Colaboradores implicados:</li>
                <li className='darkGray list-unstyled d-flex'>
                  <div className='d-flex' style={{ flex: 'none' }}>
                    {props.info.collaborators_involved.map((x, index) => {
                      let long = props.info.collaborators_involved.length;
                      return (
                        <div key={index} style={{ marginRight: '0.5rem' }}>
                          {x.full_name}
                          {index + 1 === long ? '' : ','}
                        </div>
                      );
                    })}
                  </div>
                </li>
              </ul>
            </div>
          </section>
          <section className='d-flex' style={{ marginTop: '-12px' }}>
            <ul>
              <li className='darkGray fw-bold list-unstyled mt-2'>Descripción de la situación:</li>
              <li className='darkGray list-unstyled' style={{ width: '67%' }}>
                {props.info.report_description}
              </li>
            </ul>
          </section>
          <section className='d-flex'>
            <div className='d-flex flex-column'>
              <ul>
                <li className='text-primary fw-bold'>Evidencia</li>
              </ul>
              <ul>
                <div className='d-flex'>
                  {props.info.supports.map((x, ind) => {
                    return (
                      <a key={ind} href={x?.support_file} className='text-primary mr-3'>
                        {getFilenameByURL(x?.support_file)}
                      </a>
                    );
                  })}
                </div>
              </ul>
            </div>
          </section>
          {props.info.status !== 'Recibido' ? (
            <section style={{ marginLeft: '1rem' }}>
              <Button
                className={`${Styles.btn_1} mr-3`}
                onClick={() => {
                  setShowModal(true);
                  setShowModal2({ ...showModal2, params: 'received' });
                }}
              >
                Recibido
              </Button>
              <Button
                className={`${Styles.btn_2} mr-3`}
                onClick={() => {
                  setShowModal2({ ...showModal2, params: 'sendto' });
                  sendForm('sendto');
                }}
              >
                <img src={Send} alt='d' /> Enviar a Comité
              </Button>
              <Button
                className={`${Styles.btn_3} mr-3`}
                onClick={() =>
                  setShowModal2({ ...showModal2, show: true, type: '1', params: 'wakeupcall' })
                }
              >
                Llamado de atención
              </Button>
              <Button
                className={`${Styles.btn_4}`}
                onClick={() =>
                  setShowModal2({ ...showModal2, show: true, type: '2', params: 'disprocess' })
                }
              >
                Proceso disciplinario
              </Button>
            </section>
          ) : (
            ''
          )}
        </div>
        {/* </div> */}
        <ModalNew
          size='400'
          title='Mensaje de recibido'
          onHide={() => {
            setShowModal(false);
            setFilters({
              result_by: counter.loginReducer.user_data.id,
              result_by_name:
                counter.loginReducer.user_data.first_name +
                ' ' +
                counter.loginReducer.user_data.first_surname,
              entity_account: counter.loginReducer.currentAccount.id,
            });
          }}
          show={showModal}
          btnYesName='Enviar'
          btnYesEvent={() => sendForm()}
          btnYesDisabled={filters?.justification ? false : true}
        >
          <>
            <div className='d-grid'>
              <label className={`${tableStyles.crudModalLabel}`}>Justificación{asterisk}</label>
              <textarea
                className={IndividualStyles.textAreaClear}
                onChange={e =>
                  setFilters({
                    ...filters,
                    justification: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </>
        </ModalNew>

        <ModalNew
          size='700'
          title={showModal2.type === '1' ? 'Llamado de atención' : 'Generar proceso disciplinario'}
          onHide={() => {
            setShowModal2({ show: false });
            setEmployees([]);
          }}
          show={showModal2.show}
          subtitle={props.info.concept}
          // const [showModal, setShowModal] = useState(false);
          btnYesName='Guardar'
          btnYesEvent={() => sendForm()}
          btnYesDisabled={employees.length <= 0 || !!filters.justification === false ? true : false}
        >
          <>
            <div className='d-grid'>
              <label className={`${tableStyles.crudModalLabel}`}>
                <b>Colaboradores implicados</b>
              </label>
              <div className='row' style={{ paddingLeft: '5px' }}>
                {props.info.collaborators_involved.map((x, ind) => {
                  return (
                    <div className='col-6' key={ind}>
                      <input
                        type='checkbox'
                        className='border border-dark-blue form-check-input p1 check-dark-blue'
                        checked={isBoxSelected(x.employee)}
                        // onChange={e => changeBoxSelected(x)}
                        onChange={e => {
                          if (e.target.checked) {
                            employees.push(x.employee);
                          } else {
                            changeBoxSelected(x.employee);
                          }
                        }}
                      />
                      <span style={{ marginLeft: '5px' }}>{x.full_name}</span>
                    </div>
                  );
                })}
              </div>
              <label className={`${tableStyles.crudModalLabel}`}>
                {showModal2.type === '1' ? 'Anotación' : 'Justificación'}
                {asterisk}
              </label>
              <textarea
                className={IndividualStyles.textAreaClear}
                onChange={e =>
                  setFilters({
                    ...filters,
                    justification: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </>
        </ModalNew>
      </div>
    </>
  );
}
export default ReportComplaintDetail;
