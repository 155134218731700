import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Crear from '../../assets/img/icons/add-check.svg';
import Filtrar from '../../assets/img/icons/auctionDetailLupa2.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
// import CreateContract from "../CreateContract";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useGetMethod } from '../../Hooks/useFetch';
import { getDisciplinaryProcess } from '../../actions/DisciplinaryProcessesAction';
import { getAllConCenter } from '../../actions/consumptionCenterActions';
import { getSelectStatus } from '../../actions/contractAction';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { formatToRcSelect, isEmptyOrUndefined, loader } from '../../helpers/helpers';
import SelectComponent from '../SelectComponent/SelectComponent';
import { useHasPermissionByTagModule } from "../../Hooks";

function DisciplinaryProcesses() {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const jobTitle = store.loginReducer?.user_data?.payrollInfo?.job_title;
  const areaName = store.loginReducer?.user_data?.payrollInfo?.area_name;
  const idAccount = store.loginReducer.currentAccount.id;

  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [filters, setFilter] = useState({
    search: '',
    page: 1,
    perpage: 10,
    status: undefined,
    entity_account: counter.loginReducer.currentAccount.id,
    area_id: undefined,
  });
  counter.contractReducer.results = [
    {
      candidate: 'Andrea María Lopez Perez',
      position: 'Andrea María Lopez Perez',
      area: 'Contabilidad',
      star_date: 'Citación',
      status: 'Finalizado',
      color: '#00A55120',
      fontcolor: '#00A551',
    },
    {
      candidate: 'Andrea María Lopez Perez',
      position: 'Andrea María Lopez Perez',
      area: 'Contabilidad',
      star_date: 'Citación',
      status: 'En gestión',
      color: '#00A55120',
      fontcolor: '#E9B419',
    },
    {
      candidate: 'Andrea María Lopez Perez',
      position: 'Andrea María Lopez Perez',
      area: 'Contabilidad',
      star_date: 'Citación',
      status: 'Pendiente',
      color: '#00A55120',
      fontcolor: '#5942CD',
    },
  ];

  let history = useHistory();

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'ListProces',
  );

  useEffect(() => {
    getAreaOptions({
      url: '/consumption_center/',
      objFilters: {
        eaccount: idAccount,
        active: 1,
        lite: 1,
        handleResponse: 1,
      },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    dispatch(getSelectStatus({ prefix: 'disciplinary_process' }));
    dispatch(getDisciplinaryProcess(filters));
    dispatch(getAllConCenter({ eaccount: counter.loginReducer.currentAccount.id, lite: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(getDisciplinaryProcess(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);

  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    dispatch(getDisciplinaryProcess(filters));
  };

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
    dispatch(getDisciplinaryProcess(filters));
  };

  const header = [
    <th key={1} className='pl-1 col-3 text-start' style={{ paddingLeft: '10px' }}>
      Reportante
    </th>,
    <th key={2} className='pl-1 col-2 text-start'>
      Reportado
    </th>,
    <th key={3} className='col-2 text-start'>
      Área
    </th>,
    <th key={4} className='col-2 text-center'>
      Últ. Acción
    </th>,
    <th key={5} className='col-1 text-center'>
      Fecha
    </th>,
    <th key={6} className='col-1 text-center'>
      Estado
    </th>,
    <th key={7} className='col-1 text-center'></th>,
  ];
  const body = [];
  if (Array.isArray(counter.DisciplinaryProcessesReducer.results)) {
    counter.DisciplinaryProcessesReducer.results.forEach((item, index) => {
      body.push(
        <tr key={index}>
          <td className='text-start col-3' style={{ paddingLeft: '10px' }}>
            {item.reporter}
          </td>
          <td className='text-start col-2'>{item.involved}</td>
          <td className='text-start col-2'>{item.area_name}</td>
          <td className='text-center col-2'>{item.lastActionValue ?? '-'}</td>
          <td className='text-center col-1'>{convertDateToLatinFormat(item.date)}</td>
          <td className='text-center col-1'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: item.statusBackground,
              }}
            >
              <b style={{ color: item.statusColor }}>{item.statusValue}</b>
            </div>
          </td>

          <td className='col-1 cursorPointer'>
            <img
              onClick={() =>
                history.push({
                  pathname: '/nomina/ProcesosDisciplinarios/Detalle',
                  state: item,
                })
              }
              src={Filtrar}
              alt=''
              height='20px'
            />
          </td>
        </tr>,
      );
    });
  }

  // ------------------------------------------------------------
  const {
    results: areaOptionsResults,
    load: areaOptionsLoader,
    trigger: getAreaOptions,
  } = useGetMethod();

  const optionConsumptionCenter = formatToRcSelect(
    areaOptionsResults?.results,
    'id',
    'description',
  );

  // let optionConsumptionCenter = [{ value: "", label: "Seleccionar...", name: "area" }]
  // if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
  //     counter.consumptionCenterReducer.consumption_center.map((item) => {
  //         optionConsumptionCenter.push({
  //             value: item.id,
  //             label: item.description,
  //         });
  //     })
  // }

  const optionStatus = [{ value: '', label: 'Seleccionar...', name: 'status' }];

  if (Array.isArray(counter.contractReducer.resultsSlectStatus)) {
    counter.contractReducer.resultsSlectStatus.forEach(item => {
      optionStatus.push({
        label: item.value,
        value: item.name,
        name: 'status',
      });
    });
  }

  const renderPrincipal = (
    <>
      {' '}
      {areaOptionsLoader && loader}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {counter.DisciplinaryProcessesReducer.loading_process && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className='d-flex'>
          <h1 className={tableStyles.title}>Listado de procesos disciplinarios</h1>
        </div>

        {!!myPermission?.read && (
          <>
            <Row>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Área</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  key={'area'}
                  onChange={e => {
                    setFilter({ ...filters, area_id: e.value, page: 1 });
                    dispatch(getDisciplinaryProcess({ ...filters, area_id: e.value, page: 1 }));
                  }}
                  options={optionConsumptionCenter}
                  value={optionConsumptionCenter.find(x => x.value === filters.area_id)}
                ></SelectComponent>
              </Col>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Estado</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  key={'state'}
                  onChange={e => {
                    setFilter({ ...filters, status: e.value, page: 1 });
                    dispatch(getDisciplinaryProcess({ ...filters, status: e.value, page: 1 }));
                  }}
                  // noOptionsMessage={() => "No hay datos"}
                  options={optionStatus}
                  value={optionStatus.find(x => x.value === filters.status)}
                ></SelectComponent>{' '}
              </Col>
              <Col xs={4} className='d-flex align-self-end gap-1'>
                <input
                  className={tableStyles.SearchNew}
                  value={filters.search}
                  placeholder='Buscar...'
                  type='text'
                  onChange={e => setFilter({ ...filters, search: e.target.value, page: 1 })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters();
                    }
                  }}
                ></input>
                <img
                  className='hoverPointer'
                  alt='iconSearch'
                  style={{}}
                  src={Lupa}
                  height='24px'
                  onClick={e => searchFilters(e)}
                />
              </Col>
              {myPermission?.create ? (
                <Col xs={4} className='align-self-end'>
                  <div className='d-flex justify-content-end'>
                    <div
                      onClick={() => {
                        if (isEmptyOrUndefined(areaName) || isEmptyOrUndefined(jobTitle)) {
                          return customSwaltAlert({
                            icon: 'warning',
                            title: 'Error',
                            text: `Para crear un proceso disciplinario, es necesario que el usuario cuente tanto con área como con cargo`,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false,
                          });
                        }
                        history.push('/nomina/nuevoProcesoDisciplinario');
                      }}
                      className={tableStyles.createNomModule}
                    >
                      <b className={`mr-2`}>Crear proceso</b>
                      <img src={Crear} alt='User' />
                      <div></div>
                    </div>
                  </div>
                </Col>
              ) : null}
              {/* <Col xs={5}>
    </Col> */}
            </Row>

            <div></div>
            <GenericTable headers={header} dark={true}>
              {body}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={` ${paginationStyles.paginationText} darkGray`}>
                pag. {filters.page}
                {' de '}
                {Math.ceil(counter.DisciplinaryProcessesReducer.row_total / filters.perpage)
                  ? Math.ceil(counter.DisciplinaryProcessesReducer.row_total / filters.perpage)
                  : '1'}{' '}
                ({' ' + counter.DisciplinaryProcessesReducer.row_total}
                {' encontrados'})
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={counter.DisciplinaryProcessesReducer.row_total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={`${paginationStyles.itemClassPrev} `}
                itemClassNext={`${paginationStyles.itemClassNext} `}
                itemClassFirst={`${paginationStyles.itemClassFirst} `}
                itemClassLast={`${paginationStyles.itemClassLast} `}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </>
        )}
      </div>
    </>
  );

  return renderPrincipal;
}
export default DisciplinaryProcesses;
