import { useEffect, useMemo, useState } from "react";
import { isEmptyOrUndefined } from "../../helpers/helpers";
import downloadIcon from '../../assets/img/icons/downloadArrow.svg';
import { usePostMethod } from "../../Hooks";
import { useSelector } from "react-redux";

export const SupportVisualizer = ({file}) => {
  const [isImage, setIsImage] = useState(false)
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const validExtensions = useMemo(() => [
    'jpeg',
    'jpg',
    'png',
    'heic',
  ], []);

  const { trigger: getPreSignedImg } = usePostMethod();
  useEffect(() => {

    if(!isEmptyOrUndefined(file)){
      const extension_file = file.split('.')
      setIsImage(
        validExtensions.includes(extension_file[extension_file.length-1].toLowerCase())
      )
    }

  }, [file, validExtensions])

  const downloadImage = url => {
    getPreSignedImg({
      url: '/payroll/presigned_url/',
      method: 'POST',
      token: token,
      body: {
        url_image: url
      },
      noAlert: true,
      doAfterSuccess: ({ results }) => {
        const parsedUrl = new URL(url);
        const filename = parsedUrl.pathname.split('/').pop();
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = results;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const a = document.createElement('a');
          a.download = `${filename}`;
          a.href = canvas.toDataURL('image/png');
          a.click();
        };
      },
    });
  }

  return (
    <>
    {isImage
      ? <div className='d-flex flex-column'>
          <div
          style={{ width:'100%', height:'5vh', textAlign:'end', alignContent:'center', alignSelf:'end'}}
          >
            <img
              title='Descargar'
              alt='descargar'
              onClick={() => downloadImage(file)}
              style={{cursor:'pointer'}}
              src={downloadIcon}
            />
          </div>
          <img
            src={file}
            style={{ width: '100%', objectFit:'contain' }}
            alt='soporte'/>
        </div>
      : <iframe
          src={file}
          key={1}
          title='document'
          style={{ width: '100%', height: '66vh' }}
          className='pb-4'
        />
    }
    </>
  )
}
