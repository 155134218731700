import { useEffect, useState } from 'react';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import backIcon from '../../assets/img/icons/atras-icon.svg';
import sendIconWhite from '../../assets/img/icons/send.svg';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { convertMoneyFormat, loader } from '../../helpers/helpers';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import InterestGenerate from './InterestGenerate';
import SeveranceGenerate from './SeveranceGenerate';
import { useHasPermissionByTagModule } from '../../Hooks';

const PayrollGenerate = () => {
  const store = useSelector(state => state);
  const location = useLocation();
  const idTemplate = location?.state?.data;
  const infoPayroll = location?.state?.infoPayroll;
  const status = location?.state?.status;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'jobWea',
  );
  const history = useHistory();
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const goBack = () => {
    history.push('/nomina/planillaDeCesantias');
  };
  const [profile, setProfile] = useState(1);
  const [loading, setLoading] = useState(false);
  const { load: interestLoader, trigger: getInterestPayroll } = useGetMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  const [filters, setFilters] = useState({
    search: '',
    severanceId: idTemplate,
    eaccount: idAccount,
    daysToPay: '',
  });
  const [trigger, setTrigger] = useState(0);
  const [detailPayroll, setDetailPayroll] = useState([]);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})
  useEffect(() => {
    setLoading(true);
    getInterestPayroll({
      url: '/payroll/severance/detail/',
      objFilters: filters,
      token: token,
      doAfterSuccess: res => {
        let resCopy = [];
        res.results.forEach(employee => {
          resCopy.push({
            ...employee,
            totalInterest: (
              Number(employee?.severanceInterestInsc) + Number(employee?.severanceInterest)
            ).toFixed(2),
            severanceInterestInsc: (
              (Number(employee?.severancePaymentInsc) * Number(employee?.totalDaysWork) * 0.12) /
              360
            ).toFixed(2),
            oldSeverancePaymentInsc: employee?.severancePaymentInsc,
          });
        });
        setDetailPayroll(resCopy);
        setLoading(false);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const sendToAccountingFunction = () => {
    let status = true;
    let detailPayrollCopy = [...detailPayroll];
    detailPayrollCopy.forEach((elem, ind) => {
      if (elem.severancePaymentInsc === '0') {
        detailPayrollCopy[ind].severanceInterestInsc = '0';
      }

      if (Number(elem.severancePaymentInsc) > Number(elem.oldSeverancePaymentInsc)) {
        status = false;
        return customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: `Para el empleado ${elem.candidateFullName}, el valor de la cesantía INCS debe ser menor o igual a ${convertMoneyFormat(elem.oldSeverancePaymentInsc)}`,
          showCancelButton: false,
        });
      }
    });
    setDetailPayroll(detailPayrollCopy);

    if (status) {
      customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se enviará a contabilidad la planilla de cesantías del año ${infoPayroll.paymentYear}`,
      }).then(result => {
        if (result.isConfirmed) {
          updatePayroll({
            url: '/payroll/severance/',
            token: token,
            method: 'PUT',
            body: {
              severanceId: idTemplate,
              eaccount: idAccount,
              status: 'review',
              detail: detailPayroll,
            },
            succesAction: () => {
              goBack();
              customSwaltAlert({
                title: `Enviada exitosamente`,
                text: `Se ha enviado a contabilidad planilla del año ${infoPayroll.paymentYear} `,
                icon: 'success',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };
  return (
    <>
      {(loading || interestLoader || updatePayrollLoader) && loader}
      <OrdGenericTemplate
        showBackArrow={true}
        backArrowAction={() => {
          goBack();
        }}
        backIcon={backIcon}
        title={profile === 1 ? 'Detalle planilla de cesantías' : 'Detalle planilla de intereses'}
        className='px-4 my-2 w-90'
        titleSize={12}
        titleStyle={{ fontSize: '32px', marginTop: '15px' }}
      >
        <div style={{ marginLeft: '2rem', marginTop: '20px' }}>
          <div className='simpleTabs__Container'>
            <TabContainer defaultActiveKey={profile}>
              <Nav className='flex-row simpleTabs'>
                <Nav.Item key={1}>
                  <Nav.Link eventKey={1} onClick={() => setProfile(1)} style={{ width: '200px' }}>
                    Cesantías
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item key={2}>
                  <Nav.Link eventKey={2} onClick={() => setProfile(2)} style={{ width: '200px' }}>
                    Intereses
                  </Nav.Link>
                </Nav.Item>
                <div style={{ flex: 1 }} className='simpleTabs__filler'></div>
              </Nav>
              <TabContent
                className='simpleTabs__relative'
                style={{ minHeight: '500px', overflowX: 'hidden' }}
              >
                <TabPane key={'panelTab_1'} eventKey={1} className='simpleTabs__Area'>
                  <SeveranceGenerate
                    profile={profile}
                    idTemplate={idTemplate}
                    setLoading={setLoading}
                    filters={filters}
                    setFilters={setFilters}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    interestPayrollResults={detailPayroll}
                    setDetailPayroll={setDetailPayroll}
                    status={status}
                  />
                </TabPane>
                <TabPane key={'panelTab_2'} eventKey={2} className='simpleTabs__Area'>
                  <InterestGenerate
                    profile={profile}
                    idTemplate={idTemplate}
                    setLoading={setLoading}
                    filters={filters}
                    setFilters={setFilters}
                    trigger={trigger}
                    setTrigger={setTrigger}
                    interestPayrollResults={detailPayroll}
                    setDetailPayroll={setDetailPayroll}
                    status={status}
                  />
                </TabPane>
              </TabContent>
            </TabContainer>
          </div>

          <div className={`customTabs__Footer mt-4 ml-1`}>
            {status === 'loaded' && (
              <button
                onClick={() => sendToAccountingFunction()}
                className={tableStyles.btnPrimaryAlt}
                style={{ width: '180px' }}
              >
                <img alt='icon' src={sendIconWhite} /> Enviar a contabilidad
              </button>
            )}
            <button
              onClick={() => {
                goBack();
              }}
              className={tableStyles.btnSecondary}
            >
              Cancelar
            </button>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default PayrollGenerate;
