import { useState, useEffect } from "react";

export const useDebounce = (val, delay) => {
    const [ debounceVal, setDebounceVal ] = useState(val);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceVal(val);
        }, delay);

        return () => {
            clearTimeout(handler);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val]);

    return debounceVal;
}