//All actions for Login component
import { CLASSIFICATION_GET } from './actionTypes';
import { convertFilterToString } from '../helpers/convertToFilter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const class_get = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`http://localhost:8008/gateway-web/api/v1/admin/classification/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      //console.error(res)
      const { results, row_total } = res;
      dispatch({
        type: CLASSIFICATION_GET,
        payload: results,
        row_total: row_total,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const class_edit_active = (codigo, data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  let status = data.activo ? 'habilitarr' : 'deshabilitar';

  Swal.fire({
    title: `¿Está seguro de ${status} el registro?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#003f80',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, estoy seguro',
    cancelButtonText: 'Cancelar',
  }).then(result => {
    if (result.isConfirmed) {
      fetch(`http://localhost:8008/gateway-web/api/v1/admin/classification/${codigo}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.status === 200) {
            MySwal.fire({
              icon: 'success',
              title: `¡Clasificación, '${data.shownName}' modificada exitosamente!`,
              text: 'El registro de clasificación cambio su estado correctamente',
            });
          } else {
            console.error(res.status + ' ' + res.message);
          }
        })
        .then(() => {
          executeThisAfter();
        })
        .catch(err => {
          console.error(err);
          MySwal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'No se pudo cambiar el estado',
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
        });
    }
  });
};

export const class_edit = (codigo, data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`http://localhost:8008/gateway-web/api/v1/admin/classification/${codigo}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.status === 200) {
        MySwal.fire({
          icon: 'success',
          title: `¡Clasificación, '${data.descripcion}' modificada exitosamente!`,
          text: 'El registro de clasificación se cambio correctamente',
        });
      } else {
        console.error(res.status + ' ' + res.message);
      }
    })
    .then(() => {
      executeThisAfter();
    })
    .catch(err => {
      console.error(err);
      MySwal.fire({
        icon: 'error',
        title: 'Error al guardar',
        text: 'No se pudo cambiar el estado',
        footer: 'Si el problema persiste comuníquese con un asesor.',
      });
    });
};

export const class_insert = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`http://localhost:8008/gateway-web/api/v1/admin/classification/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.status === 200) {
        MySwal.fire({
          icon: 'success',
          title: '¡Clasificación creada!',
          text: `Clasificación, ${data.descripcion} creada exitosamente`,
        });
      } else {
        console.error(res.message);
      }
    })
    .then(() => {
      executeThisAfter();
    })
    .catch(err => {
      console.error(err);
      MySwal.fire({
        icon: 'error',
        title: 'Error al crear',
        text: 'No se pudo crear el registro',
        footer: 'Si el problema persiste comuníquese con un asesor.',
      });
    });
};
