import { useState } from 'react';
import { Col } from 'react-bootstrap';
import alert from '../../assets/img/icons/alert-circle-orange.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
export const OrdCustomToast = ({ icon, text, onClose, position = 'bottomLeft', width }) => {
  let location = tableStyles.bottomLeftCorner;

  if (position === 'topRigth') {
    location = tableStyles.topRightCorner;
  }
  if (position === 'topLeft') {
    location = tableStyles.topLeftCorner;
  }
  if (position === 'bottomRight') {
    location = tableStyles.bottomRightCorner;
  }
  if (position === 'bottomLeft') {
    location = tableStyles.bottomLeftCorner;
  }
  const [animation, setAnimation] = useState('animate__fadeInUp');
  return (
    <>
      <div className='mb-5'>
        <div
          className={`m-2 p-1 ${tableStyles.bgClearYellow}  d-flex justify-content-start animate__animated ${animation} animate__faster  ${tableStyles.bdRadiusZeroEight} ${location}`}
          style={{ width: width || '50%', maxWidth: '100%', zIndex: '1500', fontSize: '13px' }}
        >
          <Col xs={1}>
            &nbsp;
            <img className={``} alt='alert' src={icon || alert}></img>
          </Col>

          <Col xs={10}>
            <p className={`${tableStyles.ordDarkGrayText} m-0`}>{text || 'Some alert text'}</p>
          </Col>
          {onClose && (
            <Col
              onClick={() => {
                setAnimation('animate__fadeOutDown');
                setTimeout(() => {
                  onClose();
                }, 700);
              }}
              className={`text-end cursorPointer text-secondary px-3`}
              xs={1}
            >
              <b>✖</b>
            </Col>
          )}
        </div>
      </div>
    </>
  );
};
