//styles
import tableStyles from "../components/Layouts/tableStyle.module.scss";

//components
import {Tooltip } from "react-bootstrap";

export const renderTooltip = (e) => (
    <Tooltip >
      <div className={`${tableStyles.f12} ${tableStyles.ordDarkGrayText} fw-bold`}>
        {e}
      </div>
    </Tooltip>
  )
  