import {
  GET_PURCHASEORDERSTATUS,
  GET_ARTICLES_IN_PURCHASE_ORDERS,
  GET_INFO_PROVIDER_IN_PURCHASE_ORDERS,
  GET_PROGRAMMING_IN_PURCHASE_ORDERS,
  STATUSPO_MODAL_JUSTIFY,
} from '../actions/actionTypes';

const initialState = {
  po: [],
  po_total: 0,
  articles_po: [],
  info_provider: '',
  programming: [],
  programming_total: 0,
  modalJustify: false,
  po_loading: false,
};

export const statusPOReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUSPO_MODAL_JUSTIFY:
      return {
        ...state,
        modalJustify: action.show,
      };
    case GET_PURCHASEORDERSTATUS:
      return {
        ...state,
        po: action.results,
        po_total: action.row_total,
        po_loading: action.loading,
      };
    case GET_ARTICLES_IN_PURCHASE_ORDERS:
      return {
        ...state,
        articles_po: action.results,
      };
    case GET_INFO_PROVIDER_IN_PURCHASE_ORDERS:
      return {
        ...state,
        info_provider: action.results,
      };
    case GET_PROGRAMMING_IN_PURCHASE_ORDERS:
      return {
        ...state,
        programming: action.results,
        programming_total: action.row_total,
      };
    default:
      return state;
  }
};
