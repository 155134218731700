import { useDispatch, useSelector } from "react-redux"
import { onSetBlockTexTarea } from "../actions/onSetBlockTexTarea"

const useCalendarBlockTextarea = () => {

    const { comment } = useSelector(state => state.blockTextareaReducer)

    const dispatch = useDispatch()

    const setBlockTextarea = (event) => {
        dispatch(onSetBlockTexTarea(event))
    }

    return {
        setBlockTextarea,
        comment
    }
}

export default useCalendarBlockTextarea