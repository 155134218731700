import { Box } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { usePostMethod } from '../../../Hooks';
//
import editPencil from '../../../assets/img/icons/editPencil.svg';
import backIcon from '../../../assets/img/icons/iconBack.svg';

//
import { customSwaltAlertAsistencial, loader, onlyCharacters } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
//
import { OrdAccordion } from '../../../OrderingModule/OrderingComponents/OrdAccordion';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import {
  CkEditorConsent,
  Text,
  TextGrayInBrackets,
  TextGrayOnBrackets,
} from '../componentsConsents/';

export const CreateOrUpdateConsent = () => {
  const { load: loaderConsentsValidation, trigger: postConsentsValidation } = usePostMethod();

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const { state } = useLocation();
  const history = useHistory();
  const { action } = state;

  const [data, setData] = useState({
    dataTemporary: {
      nameTemp: state?.data?.name || '',
      codeTemp: state?.data?.code || '',
      versionTemp: state?.data?.version || '',
      effectiveDateTemp: state?.data?.validity || '',
    },
    httpData: {
      name: state?.data?.name || '',
      code: state?.data?.code || '',
      version: state?.data?.version || '',
      validity: state?.data?.validity || '',
      bodyOfText: {
        introduction: state?.data?.introduction || state?.data?.bodyOfText?.introduction || '',
        meaning: state?.data?.meaning || state?.data?.bodyOfText?.meaning || '',
        risks: state?.data?.risks || state?.data?.bodyOfText?.risks || '',
        recomendations:
          state?.data?.recomendations || state?.data?.bodyOfText?.recommendations || '',
        benefits: state?.data?.benefits || state?.data?.bodyOfText?.benefits || '',
        declarations: state?.data?.declarations || state?.data?.bodyOfText?.declarations || '',
        withdrawal: state?.data?.withdrawal || state?.data?.bodyOfText?.withdrawal || '',
      },
      revocationOfConsent: {
        revocation: state?.data?.revocation || state?.data?.revocationOfConsent?.revocation || '',
      },
      footer: state?.data?.footer || '',
      id: state?.data?.id || '',
    },
    modal: false,
  });

  const { httpData, modal, dataTemporary } = data;

  const onchangeDataHttp = ({ target }) => {
    setData(state => ({
      ...state,
      dataTemporary: { ...state.dataTemporary, [target?.name]: target.value },
    }));
  };

  const onchangeDataHttpValidText = value => {
    const valid = onlyCharacters(value);
    if (valid) {
      setData(state => ({
        ...state,
        dataTemporary: {
          ...state.dataTemporary,
          nameTemp: value,
        },
      }));
    }
  };

  const onchangeDataHttpAccordion = ({ category, key, value }) => {
    setData(state => ({
      ...state,
      httpData: {
        ...state.httpData,
        [category]: {
          ...state.httpData[category],
          [key]: value,
        },
      },
    }));
  };

  const onChangeStateModal = isShow => {
    setData(state => ({ ...state, modal: isShow }));
  };

  const btnNoEventOnHide = () => {
    setData(state => ({
      ...state,
      modal: false,
      // httpData: { ...state.httpData, ...dataAction }
    }));
  };

  const validationRequestModal = () => {
    const isValid = Object.values(dataTemporary).every(el => el.trim().length > 0);

    if (!isValid) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Todos los campos son obligatorios`,
        showCancelButton: false,
      });
    }

    postConsentsValidation({
      token: token,
      method: 'POST',
      url: '/medical/admissions/consentsValidation/',
      body: {
        name: dataTemporary?.nameTemp,
        code: dataTemporary?.codeTemp,
        version: dataTemporary?.versionTemp,
        validity: dataTemporary?.effectiveDateTemp,
        cntId: state?.data?.id || null,
      },
      doAfterSuccess: () => {
        setData(state => ({
          ...state,
          modal: false,
          httpData: {
            ...state.httpData,
            name: dataTemporary.nameTemp,
            code: dataTemporary.codeTemp,
            version: dataTemporary.versionTemp,
            validity: dataTemporary.effectiveDateTemp,
            cntId: dataTemporary.id || null,
          },
        }));
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  const validationPreview = () => {
    const isValidDataModal = Object.values(dataTemporary || {})?.every(
      el => el?.trim()?.length > 0,
    );
    const isValidBodyOfText = Object.values(httpData?.bodyOfText || {})?.every(
      el => el?.trim()?.length > 0,
    );
    const isValidRevocationOfConsent = Object.values(httpData?.revocationOfConsent || {})?.every(
      el => el?.trim()?.length > 0,
    );
    const isValidFooter = httpData?.footer?.length;
    const isValid =
      isValidDataModal && isValidBodyOfText && isValidRevocationOfConsent && isValidFooter;

    if (!isValid) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Todos los campos son obligatorios`,
        showCancelButton: false,
      });
    }

    history.push({
      state: {
        action: state?.action === 'update' ? 'newUpdate' : 'save',
        data: { httpData },
        currentAccount: state?.currentAccount,
      },
      pathname: `/asistencial/tmAdmisiones/consentimientos/detail`,
    });

    // return customSwaltAlertAsistencial({
    //     icon: "success",
    //     title: `falta hacer la parte 3 de react pdf`,
    //     // text: `Todos los campos son obligatorios`,
    //     showCancelButton: false,
    // })
  };

  const renderAttentionData = () => (
    <Box>
      <Box className='row mb-2'>
        <Box className='col-3 '>
          <TextGrayOnBrackets title={'Fecha'} textBracket={'Fecha de creación'} />
        </Box>
      </Box>

      <Box className='row mb-2'>
        <Box className='col-6 '>
          <TextGrayOnBrackets title={'Paciente'} textBracket={'Nombre paciente'} />
        </Box>
        <Box className='col-3 '>
          <TextGrayOnBrackets title={'Identificación'} textBracket={'Número identificación'} />
        </Box>
        <Box className='col-3 '>
          <TextGrayOnBrackets title={'Teléfono'} textBracket={'Número de teléfono'} />
        </Box>
      </Box>

      <Box className='row mb-2'>
        <Box className='col-6 '>
          <TextGrayOnBrackets title={'Empresa del cliente'} textBracket={'Nombre paciente'} />
        </Box>
        <Box className='col-6 '>
          <TextGrayOnBrackets title={'Plan o contrato'} textBracket={'Número identificación'} />
        </Box>
      </Box>

      <Box className='row mb-2'>
        <Box className='col-6 '>
          <TextGrayOnBrackets title={'Procedimiento'} textBracket={'Nombre del procedimiento'} />
        </Box>
        <Box className='col-3 '>
          <TextGrayOnBrackets title={'Ojo a operarse'} textBracket={'Nombre del órgano'} />
        </Box>
        <Box className='col-3 '>
          <TextGrayOnBrackets title={'Responsables'} textBracket={'Nombre del responsable'} />
        </Box>
      </Box>
    </Box>
  );

  const renderBodyOfText = () => (
    <>
      <CkEditorConsent
        titleText={'Introducción'}
        data={state?.data?.introduction || data?.httpData?.bodyOfText?.introduction}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'introduction',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Significado del procedimiento'}
        data={state?.data?.meaning || data?.httpData?.bodyOfText?.meaning}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'meaning',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Riesgos'}
        data={state?.data?.risks || data?.httpData?.bodyOfText?.risks}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'risks',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Beneficios'}
        data={state?.data?.benefits || data?.httpData?.bodyOfText?.benefits}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'benefits',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Recomendaciones'}
        data={state?.data?.recomendations || data?.httpData?.bodyOfText?.recomendations}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'recomendations',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Comentarios adicionales'}
        data={state?.data?.additionalComments || data?.httpData?.bodyOfText?.additionalComments}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'additionalComments',
            value: data,
          });
        }}
      />

      {/* <Box className="mb-4">
                <Box style={{ color: '#CBCBD0' }}>Comentarios adicionales</Box>
                <Box
                    style={{ height: '50px', border: '1px solid #CBCBD0', borderRadius: '5px' }}
                    className="w-100"
                />
            </Box> */}

      <CkEditorConsent
        titleText={'Declaratorias'}
        data={state?.data?.declarations || data?.httpData?.bodyOfText?.declarations}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'declarations',
            value: data,
          });
        }}
      />

      <CkEditorConsent
        titleText={'Consentimiento o desistimiento informado'}
        data={state?.data?.withdrawal || data?.httpData?.bodyOfText?.withdrawal}
        onChange={(event, editor) => {
          const data = editor.getData();
          onchangeDataHttpAccordion({
            category: 'bodyOfText',
            key: 'withdrawal',
            value: data,
          });
        }}
      />
    </>
  );

  const renderSignatures = () => (
    <Box className='row'>
      <Box className='col-4'>
        <div className='w-75'>
          <TextGrayInBrackets typeSignature={'Firma del Paciente'} typeId={'C.C'} />
        </div>
      </Box>

      <Box className='col-4'>
        <div className='w-75'>
          <TextGrayInBrackets typeSignature={'Firma del acompañante'} typeId={'C.C'} />
        </div>
      </Box>

      <Box className='col-4'>
        <div className='w-75'>
          <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'C.C'} />
        </div>
      </Box>
    </Box>
  );

  const renderRevocationOfConsent = () => (
    <>
      <Box className='row mb-4'>
        <Box className='col-4'>
          <TextGrayOnBrackets title={'Fecha'} textBracket={'Fecha firma'} />
        </Box>
      </Box>

      <Box className='mb-2'>
        <CkEditorConsent
          titleText={'Revocatoria'}
          data={state?.data?.revocation || data?.httpData?.revocationOfConsent?.revocation}
          onChange={(event, editor) => {
            const data = editor.getData();
            onchangeDataHttpAccordion({
              category: 'revocationOfConsent',
              key: 'revocation',
              value: data,
            });
          }}
        />
      </Box>

      <Box className='row'>
        <Box className='col-4'>
          <div className='w-75'>
            <TextGrayInBrackets typeSignature={'Paciente o representante legal'} typeId={'C.C'} />
          </div>
        </Box>

        <Box className='col-4'>
          <div className='w-75'>
            <TextGrayInBrackets typeSignature={'Acompañante(s)'} typeId={'C.C'} />
          </div>
        </Box>

        <Box className='col-4'>
          <div className='w-75'>
            <TextGrayInBrackets typeSignature={'Cirujano'} typeId={'R.M.'} />
          </div>
        </Box>
      </Box>
    </>
  );

  const renderFooter = () => (
    <>
      <CkEditorConsent
        titleText={'Pie de página'}
        data={state?.data?.footer || data?.httpData?.footer}
        onChange={(event, editor) => {
          const data = editor.getData();

          setData(state => ({
            ...state,
            httpData: {
              ...state.httpData,
              footer: data,
            },
          }));
        }}
      />
    </>
  );

  return (
    <>
      {loaderConsentsValidation && loader}

      <Box className='w-100'>
        <Box
          className={`${tableStyles.layoutBaseOutPaddingBottom}`}
          style={{ height: '83vh', overflow: 'scroll' }}
        >
          <div className='d-flex gap-3 align-items-center'>
            <img
              alt='regresar'
              className={`${tableStyles.filterOrdAquaMarine} pointer`}
              onClick={() => history.goBack()}
              src={backIcon}
            />
            <div className={`${tableStyles.f40} ${tableStyles.ordDarkBlueText} fw-bold`}>
              {action === 'create' ? 'Crear' : action === 'update' ? 'Editar' : 'Duplicar'}{' '}
              Consentimiento
            </div>
          </div>

          <Box className={`${tableStyles.styleCardConsent} mb-4`}>
            <Box className='d-flex flex-column gap-3'>
              <img
                width={137}
                src={state?.data.logoAccount || state?.currentAccount?.entityLogo || ''}
                alt='logo'
              />

              <Box>
                <Box className={`${tableStyles.darkerBlueText} fw-bold`}>
                  {state?.data?.bussinesNameAccount || state?.currentAccount?.bussines_name || ''}
                </Box>

                <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                  <Text
                    title={'Nit'}
                    subtitle={state?.data?.nitAccount || state?.currentAccount?.nit || ''}
                  />
                  <Text
                    title={'Teléfono'}
                    subtitle={state?.data?.phoneAccount || state?.currentAccount?.phone || ''}
                  />
                  <Text
                    title={'Email'}
                    subtitle={state?.data?.emailAccount || state?.currentAccount?.email || ''}
                  />
                  <Text
                    title={'Dirección'}
                    subtitle={state?.data?.addressAccount || state?.currentAccount?.address || ''}
                  />
                </Box>
              </Box>
            </Box>

            <Box className='d-flex flex-column align-items-end justify-content-around gap-3'>
              <img
                width={25}
                src={editPencil}
                alt='backIcon'
                className={`${tableStyles.filterOrdAquaMarine} pointer`}
                onClick={() => onChangeStateModal(true)}
              />

              <Box className='text-end'>
                <Box
                  className={`${tableStyles.f14} ${tableStyles.ordAquaMarineText} text-uppercase fw-bold`}
                >
                  {httpData?.name}
                </Box>
                <Box className={`d-flex gap-2 ${tableStyles.f12} ${tableStyles.darkerGrayText}`}>
                  <Text title={'Código'} subtitle={httpData?.code}></Text>
                  <Text title={'Versión'} subtitle={httpData?.version}></Text>
                  <Text title={'Vigencia'} subtitle={httpData?.validity}></Text>
                </Box>
              </Box>
            </Box>
          </Box>

          <OrdAccordion
            key={1}
            iconArrow
            colorTitle={tableStyles.ordAquaMarineTextImportant}
            carBodyClass='pt-0'
            paddingLeft={'1.8rem'}
            title={'Datos de atención'}
            isEnabled={true}
            body={renderAttentionData()}
          />

          <OrdAccordion
            key={2}
            iconArrow
            title={'Cuerpo de texto'}
            carBodyClass='pt-0'
            paddingLeft={'1.8rem'}
            isEnabled={true}
            body={renderBodyOfText()}
            colorTitle={tableStyles.ordAquaMarineTextImportant}
          ></OrdAccordion>

          <OrdAccordion
            title={'Firmas'}
            iconArrow
            key={3}
            paddingLeft={'1.8rem'}
            carBodyClass='pt-0'
            isEnabled={true}
            body={renderSignatures()}
            colorTitle={tableStyles.ordAquaMarineTextImportant}
          />

          <OrdAccordion
            key={4}
            iconArrow
            title={'Revocatoria de consentimiento'}
            carBodyClass='pt-0'
            isEnabled={true}
            body={renderRevocationOfConsent()}
            colorTitle={tableStyles.ordAquaMarineTextImportant}
          />

          <OrdAccordion
            key={5}
            iconArrow
            title={'Pie de página'}
            carBodyClass='pt-0'
            isEnabled={true}
            body={renderFooter()}
            colorTitle={tableStyles.ordAquaMarineTextImportant}
          />
        </Box>

        <Box
          className={`d-flex gap-3 justify-content-end align-items-start ${tableStyles.footerBottomConsent}`}
        >
          <Box className='d-flex gap-3 justify-content-end align-items-start'>
            <button
              style={{ width: '192px' }}
              className={`${tableStyles.btnCancelAdmission}`}
              onClick={() => history.goBack(-1)}
            >
              Cancelar
            </button>

            <button
              style={{ width: '192px' }}
              className={`${tableStyles.btnSaveAdmission}`}
              onClick={validationPreview}
            >
              Previsualizar
            </button>
          </Box>
        </Box>
      </Box>

      <OrdModal
        title={'Encabezado consentimiento'}
        show={modal}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={550}
        onHide={btnNoEventOnHide}
        btnYesEvent={() => validationRequestModal()}
        btnNoEvent={btnNoEventOnHide}
      >
        <div className={`mb-2 ${tableStyles.ordAquaMarineText} fw-bold`}>Consentimiento</div>

        <div className='mb-4'>
          <div className={`${tableStyles.darkerBlueText} fw-light`}>&nbsp;Nombre</div>
          <input
            className={`${tableStyles.styleInputText} w-100 mb-1`}
            placeholder='Escribir...'
            type='text'
            name='nameTemp'
            value={dataTemporary.nameTemp}
            onChange={e => onchangeDataHttpValidText(e.target.value)}
          />

          <div className='d-flex gap-3'>
            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>&nbsp;Código</div>
              <input
                className={`${tableStyles.styleInputText} w-100 mb-1`}
                placeholder='Escribir...'
                type='text'
                name='codeTemp'
                value={dataTemporary.codeTemp}
                onChange={onchangeDataHttp}
              />
            </div>

            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>&nbsp;Versión</div>
              <input
                className={`${tableStyles.styleInputText} w-100 mb-1`}
                placeholder='Escribir...'
                type='text'
                name='versionTemp'
                value={dataTemporary.versionTemp}
                onChange={onchangeDataHttp}
              />
            </div>

            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>
                &nbsp;Fecha de vigencia
              </div>
              <input
                className={`w-100 ord-roundInput pointer ${tableStyles.inputDate} ${tableStyles.boxShadowNone}`}
                type='date'
                name='effectiveDateTemp'
                value={dataTemporary.effectiveDateTemp}
                onChange={onchangeDataHttp}
              />
            </div>
          </div>
        </div>
      </OrdModal>
    </>
  );
};
