import { FORMULAS_DATA } from "../actions/actionTypes";

const initialState = {
  formulaInfo: {},
  showPdf: false,
};
export const assistanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORMULAS_DATA:
      return {
        ...state,
        formulaInfo: action.payload,
        showPdf: action.showPdf,
      };

    default:
      return state;
  }
};
