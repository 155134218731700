import { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
//
import generateTemplate from '../../assets/img/icons/darkBlueDownload.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { getNiifAccounts } from '../../actions/pucActions';
import { API_VERSION, URL_GATEWAY } from '../../helpers/constants';
import { convertFilterToString } from '../../helpers/convertToFilter';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import {
  downloadExcel,
  formatToRcSelect,
  genericDownloaFile,
  loader,
  optionsSelect,
  today,
  validateEmptyString,
  valueSelect,
} from '../../helpers/helpers';

import GenericTable from '../../components/Layouts/GenericTableNew';
// import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import ModalNew from '../../components/Layouts/ModalNew';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import SelectComponent from '../SelectComponent/SelectComponent';
import { useHasPermissionByTagModule } from "../../Hooks";
// import tableStylesScroll from "../../genericTableScroll.module.scss";
//
const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]; //

function getColorStatus(textStatus) {
  switch (textStatus) {
    case 'loaded':
      return {
        status_es: 'Borrador',
        color: '#58595B1A',
        fontcolor: '#58595B',
      };
    case 'review':
      return {
        status_es: 'En revisión',
        color: '#58595B1A',
        fontcolor: '#58595B',
      };
    case 'approved':
      return {
        status_es: 'Aprobada',
        color: '#00A5511A',
        fontcolor: '#00A551',
      };
    case 'rejected':
      return {
        status_es: 'Rechazada',
        color: '#EC1C241A',
        fontcolor: '#EC1C24',
      };
    case 'processed':
      return {
        status_es: 'Procesada',
        color: '#00A5511A',
        fontcolor: '#00A551',
      };
    case 'cancelled':
      return {
        status_es: 'Cancelada',
        color: '#EC1C241A',
        fontcolor: '#EC1C24',
      };
    default:
      return {
        status_es: textStatus,
        color: '#00A551201A',
        fontcolor: '#00A551',
      };
  }
}

function PaymentsTemplate() {
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const counter = useSelector(state => state);
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandepay',
  );
  const token = counter.loginReducer.Authorization;
  const { trigger: getListInfo, load: getListInfoLoader } = useGetMethod();
  const {
    results: getBankingEntitiesResults,
    load: getBankingEntitiesLoader,
    trigger: getBankingEntitiesMethod,
  } = useGetMethod();
  const { load: locationsBancolombiaLoader, trigger: getPaymentTemplateBancolombia } =
    usePostMethod();
  const { load: locationsLoader, trigger: getPaymentTemplate } = useGetMethod();
  const bankingOptions = formatToRcSelect(getBankingEntitiesResults?.results, 'id', 'description');
  const [showPaymentDetail, setShowPaymentDetail] = useState({
    show: false,
    id: '',
    bankId: '',
    bankLabel: '',
    bank_number: '',
    sequence: '',
    transmission_date: '',
    application_date: '',
    account_type: '',
  });
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const store = useSelector(state => state);
  const idAccount = store.loginReducer.currentAccount.id;
  const numberWithCommas = x => {
    if (x) {
      let parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return parts.join('.');
    } else {
      return x;
    }
  };
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilter] = useState({
    page: 1,
    perpage: 10,
    entity_account: counter.loginReducer.currentAccount.id,
    status: 'approved',
  });
  const [step, setStep] = useState(1);
  const [foundedNiif, setFoundedNiif] = useState([
    { value: '', label: 'Digite el nombre o número de la cuenta' },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [nominaList, setNominaList] = useState([]);
  const [nominaTotal, setNominaTotal] = useState([]);
  const sequencyOptions = [
    { label: 'Seleccionar...', value: '' },
    { label: 'A', value: 'A' },
    { label: 'B', value: 'B' },
    { label: 'C', value: 'C' },
    { label: 'D', value: 'D' },
    { label: 'E', value: 'E' },
    { label: 'F', value: 'F' },
    { label: 'G', value: 'G' },
    { label: 'H', value: 'H' },
    { label: 'I', value: 'I' },
    { label: 'J', value: 'J' },
    { label: 'K', value: 'K' },
    { label: 'L', value: 'L' },
    { label: 'M', value: 'M' },
    { label: 'N', value: 'N' },
    { label: 'O', value: 'O' },
    { label: 'P', value: 'P' },
    { label: 'Q', value: 'Q' },
    { label: 'R', value: 'R' },
    { label: 'S', value: 'S' },
    { label: 'T', value: 'T' },
    { label: 'U', value: 'U' },
    { label: 'V', value: 'V' },
    { label: 'W', value: 'W' },
    { label: 'X', value: 'X' },
    { label: 'Y', value: 'Y' },
    { label: 'Z', value: 'Z' },
  ];
  const handleFilter = e => {
    let nombreEncontrados;
    let expresion = new RegExp(`${e.toUpperCase()}.*`, 'i');

    if (e.length > 0) {
      nombreEncontrados = storage?.pucReducer?.niifAccounts?.filter(
        dat =>
          (expresion.test(dat.description) || expresion.test(dat.complete_account)) &&
          dat.num_count !== '',
      );
      setFoundedNiif(
        formatToRcSelect(
          nombreEncontrados,
          'num_count',
          'complete_account',
          'account_type',
          '',
          'description',
        ),
      );
    } else {
      setFoundedNiif([{ value: '', label: 'Digite el nombre o número de la cuenta' }]);
    }
  };

  const {
    trigger: getMonths,
    results: monthOptions,
    load: monthLoader,
  } = useGetMethod();

  const renderTooltipPaymentDetail = () => (
    <Tooltip>
      <span style={{ color: '#c99489' }}>Generar planilla</span>
    </Tooltip>
  );
  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: storage.loginReducer.currentAccount.id,
        active: 1,
        bank: 1,
      }),
    );
    getBankingEntitiesMethod({
      url: '/admin/bankingEntities',
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });
    getMonths({
      url: '/payroll/months',
      token: token
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);


  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  //fetch zone -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const fetchData = async () => {
    setIsLoading(true);
    let str_filters = convertFilterToString(filters);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll_spreadsheets/?${str_filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        setNominaList(res.results);
        setNominaTotal(res.rowTotal);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err.message);
        setIsLoading(false);
      });
  };
  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };
  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = e => {
    e.preventDefault();
    const filtered = employees.filter(employee =>
      employee.employee.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredEmployees(filtered);
  };

  useEffect(() => {
    if (step === 2) {
      const fetchData = async () => {
        try {
          if (
            nominaList.find(obj => obj.PS_ID === showPaymentDetail.id).type_payroll === 'payroll'
          ) {
            const result = await getListInfo({
              url: '/payroll/payroll-template/',
              objFilters: {
                id: showPaymentDetail.id,
                entity_account: counter.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
            if (!result.success) {
              customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar',
              });
            } else {
              const mappedData = result.data.map(item => ({
                id: item.candidate_id,
                employee: item.employee,
              }));
              setEmployees(mappedData);
              setFilteredEmployees(mappedData);
            }
          } else {
            const result = await getListInfo({
              url: '/payroll/detail_bonus_pay',
              objFilters: {
                id: showPaymentDetail.id,
                eaccount: counter.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
            if (!result.success) {
              customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: result.message,
                confirmButtonText: 'Aceptar',
              });
            } else {
              const mappedData = result.results.map(item => ({
                id: item.candidateId,
                employee: item.nameEmployee,
              }));
              setEmployees(mappedData);
              setFilteredEmployees(mappedData);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const nextStep = () => {
    let validations = validateEmptyString([
      {
        field: showPaymentDetail.bankId,
        message: 'La entidad bancaria es obligatoria para generar',
      },
      {
        field: showPaymentDetail.bank_number,
        message: 'La cuenta a debitar es obligatoria para generar',
      },
      { field: showPaymentDetail.sequence, message: 'La secuencia  es obligatoria para generar' },
      {
        field: showPaymentDetail.transmission_date,
        message: 'La fecha de transmisión es obligatoria para generar',
      },
      {
        field: showPaymentDetail.application_date,
        message: 'La fecha de aplicación es obligatoria para generar',
      },
    ]);
    if (validations.valid) {
      setStep(2);
    }
  };

  useEffect(() => {
    setAllSelected(employees.length > 0 && selectedEmployees.length === employees.length);
  }, [selectedEmployees, employees]);

  const handleAllCheckboxChange = () => {
    if (allSelected) {
      setSelectedEmployees([]);
    } else {
      const allEmployeeIds = employees.map(employee => employee.id);
      setSelectedEmployees(allEmployeeIds);
    }
    setAllSelected(!allSelected);
  };

  const headersModal = [
    <th key={1} className='text-center px-2'>
      <input
        type='checkbox'
        onChange={({ target }) => handleAllCheckboxChange(target.checked)}
        checked={allSelected}
      />
    </th>,
    <th key={2} style={{ flex: 3, paddingLeft: 14 }} className='pl-2 text-start pr-4'>
      empleado{' '}
    </th>,
  ];

  const header = [
    <th key={1} className='pl-1 col-2 text-start' style={{ paddingLeft: '10px' }}>
      Descripción
    </th>,
    <th key={2} className='col-1 text-center'>
      Año
    </th>,
    <th key={2} className='col-1 text-center'>
      Mes
    </th>,
    <th key={3} className='col-1 text-center'>
      Periodo
    </th>,
    <th key={4} className='col-1 text-center'>
      No. de empleados
    </th>,
    <th key={5} className='col-2 text-center'>
      <p style={{ margin: 0, paddingRight: '15px', textAlign: 'end' }}>Total</p>
    </th>,
    <th key={6} className='col-1 text-center'>
      Estado
    </th>,
    <th key={7} className='col-1 text-center'></th>,
  ];
  const body = [];
  if (Array.isArray(nominaList)) {
    nominaList.forEach(item => {
      const statuscolors = getColorStatus(item.status);
      const textMonth = isNaN(parseInt(item.payment_month))
        ? '-'
        : monthNames[parseInt(item.payment_month) - 1];
      body.push(
        <tr key={'payroll' + item.PS_ID}>
          <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
            <b style={{ color: '#005DBF' }}>{item.description}</b>
          </td>
          <td className='text-center col-1'>{item.payment_year}</td>
          <td className='text-center col-1'>{textMonth}</td>
          <td className='text-center col-1'>{item.range}</td>
          <td className='text-center col-1'>{item.employee_number}</td>
          <td className='text-end col-2' style={{ paddingRight: '15px' }}>
            $ {numberWithCommas(item.total_amount)}
          </td>
          <td className='text-center col-1'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: statuscolors.color,
              }}
            >
              <b style={{ color: statuscolors.fontcolor }}>{statuscolors.status_es}</b>
            </div>
          </td>

          <td className='col-1'>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipPaymentDetail()}
            >
              <img
                alt='icon'
                src={generateTemplate}
                className='cursorPointer'
                onClick={() =>
                  setShowPaymentDetail({
                    ...showPaymentDetail,
                    show: true,
                    id: item.PS_ID,
                  })
                }
              />
            </OverlayTrigger>
          </td>
        </tr>,
      );
    });
  }
  let optionConsumptionCenter = [{ value: '', label: 'Seleccione área', name: 'area' }];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.forEach(item => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }
  const closePaymentDetailModal = () => {
    setShowPaymentDetail({
      ...showPaymentDetail,
      show: false,
      bankId: '',
      bankLabel: '',
      bank_number: '',
      id: '',
      transmission_date: '',
      application_date: '',
      sequence: '',
    });
    setSelectedEmployees([]);
    setStep(1);
    setSearchTerm('');
    setFilteredEmployees(employees);
  };

  const handleCheckboxChange = id => {
    setSelectedEmployees(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(employeeId => employeeId !== id)
        : [...prevSelected, id],
    );
  };

  const renderListModal = filteredEmployees.map(employee => (
    <tr key={employee.id}>
      <td className='text-center px-2'>
        <input
          type='checkbox'
          checked={selectedEmployees.includes(employee.id)}
          onChange={() => handleCheckboxChange(employee.id)}
        />
      </td>
      <td className={`text-start`} style={{ flex: 3, paddingLeft: 14 }}>
        {employee.employee}
      </td>
    </tr>
  ));

  const getPaymentTemplateFunction = () => {
    let validations = validateEmptyString([
      {
        field: showPaymentDetail.bankId,
        message: 'La entidad bancaria es obligatoria para generar',
      },
    ]);
    let body = {
      type_payroll: nominaList.find(obj => obj.PS_ID === showPaymentDetail.id).type_payroll,
      bank_id: showPaymentDetail.bankId,
      eaccount: idAccount,
      payroll_id: showPaymentDetail.id,
    };
    let url = '/payroll/payment_sheet_excel';
    if (showPaymentDetail.bankLabel === 'BANCOLOMBIA') {
      url = '/payroll/payment_sheet_txt';
      body = {
        ...body,
        bank_number: showPaymentDetail.bank_number,
        sequence: showPaymentDetail.sequence,
        transmission_date: showPaymentDetail.transmission_date,
        application_date: showPaymentDetail.application_date,
        account_type: showPaymentDetail.account_type,
        employees: selectedEmployees,
      };
      validations = validateEmptyString([
        {
          field: showPaymentDetail.bankId,
          message: 'La entidad bancaria es obligatoria para generar',
        },
        {
          field: showPaymentDetail.bank_number,
          message: 'La cuenta a debitar es obligatoria para generar',
        },
        {
          field: showPaymentDetail.sequence,
          message: 'La secuencia  es obligatoria para generar',
        },
        {
          field: showPaymentDetail.transmission_date,
          message: 'La fecha de transmisión es obligatoria para generar',
        },
        {
          field: showPaymentDetail.application_date,
          message: 'La fecha de aplicación es obligatoria para generar',
        },
      ]);
    }
    if (validations.valid && showPaymentDetail.bankLabel === 'BANCOLOMBIA') {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        showCancelButton: true,
        text: `Se generará una planilla de pago con la entidad bancaria ${showPaymentDetail.bankLabel}`,
        confirmButtonColor: '#005DBF',
        cancelButtonColor: '#fff',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
      }).then(response => {
        if (response.isConfirmed) {
          getPaymentTemplateBancolombia({
            method: 'POST',
            url: url,
            body: body,
            token: token,
            doAfterSuccess: data => {
              genericDownloaFile(
                `data:text/plain;base64,${data.results.base64.split("'")[1]}`,
                'planillaDePago.txt',
              );
              setTrigger(trigger + 1);
              closePaymentDetailModal();
            },
            doAfterException: results => {
              customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: results.message,
                confirmButtonText: 'Aceptar',
              });
            },
          });
        }
      });
    }
    if (validations.valid && showPaymentDetail.bankLabel !== 'BANCOLOMBIA') {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        showCancelButton: true,
        text: `Se generará una planilla de pago con la entidad bancaria ${showPaymentDetail.bankLabel}`,
        confirmButtonColor: '#005DBF',
        cancelButtonColor: '#fff',
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
      }).then(response => {
        if (response.isConfirmed) {
          getPaymentTemplate({
            url: url,
            objFilters: body,
            token: token,
            doAfterSuccess: data => {
              if (showPaymentDetail.bankLabel === 'BANCO DAVIVIENDA') {
                downloadExcel(data.results.base64, data.results.filename, true);
                customSwaltAlert({
                  showCancelButton: false,
                  icon: 'success',
                  title: data.message,
                  confirmButtonText: 'Aceptar',
                });
              } else {
                customSwaltAlert({
                  showCancelButton: false,
                  icon: 'warning',
                  title: 'Intenta de nuevo',
                  text: 'No existe planilla de pago para esta entidad bancaria',
                  confirmButtonText: 'Aceptar',
                });
              }
              setTrigger(trigger + 1);
              closePaymentDetailModal();
            },
            doAfterException: results => {
              customSwaltAlert({
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: results.message,
                confirmButtonText: 'Aceptar',
              });
            },
          });
        }
      });
    }
  };
  const renderPrincipal = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {(locationsLoader ||
        getBankingEntitiesLoader ||
        isLoading ||
        getListInfoLoader ||
        locationsBancolombiaLoader ||
        monthLoader) &&
        loader}
      <ModalNew
        title={'Generar planilla de pago'}
        subtitle={showPaymentDetail.bankLabel === 'BANCOLOMBIA' ? `paso ${step} de 2` : ''}
        show={showPaymentDetail.show}
        btnYesName={
          showPaymentDetail.bankLabel === 'BANCOLOMBIA' && step === 1 ? 'continuar' : 'generar'
        }
        btnNoName={'Cancelar'}
        size={500}
        onHide={() => closePaymentDetailModal()}
        btnYesEvent={
          showPaymentDetail.bankLabel === 'BANCOLOMBIA' && step === 1
            ? () => nextStep()
            : () => getPaymentTemplateFunction()
        }
        btnNoEvent={() => closePaymentDetailModal()}
      >
        <div className='mb-4'>
          {step === 1 && (
            <Row className='d-flex px-1'>
              <label className={tableStyles.crudModalLabel}>Entidad bancaria</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNewDark}
                options={bankingOptions}
                onChange={e =>
                  setShowPaymentDetail({
                    ...showPaymentDetail,
                    bankId: e.value,
                    bankLabel: e.label,
                    bank_number: '',
                    application_date: '',
                    sequence: '',
                    transmission_date: '',
                  })
                }
                value={bankingOptions.find(x => x.value === showPaymentDetail.bankId)}
              />
            </Row>
          )}
          {showPaymentDetail.bankLabel === 'BANCOLOMBIA' && step === 1 && (
            <>
              <Row className='d-flex px-1'>
                <label className={tableStyles.crudModalLabel}>Cuenta a debitar</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={foundedNiif}
                  placeholder='Seleccionar...'
                  onInputChange={e => {
                    handleFilter(e);
                  }}
                  styles={customSelectNewDark}
                  onChange={e =>
                    setShowPaymentDetail({
                      ...showPaymentDetail,
                      bank_number: e.value,
                      account_type: e.extra,
                    })
                  }
                />
              </Row>
              <Row className='d-flex px-1'>
                <label className={tableStyles.crudModalLabel}>Secuencia</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  options={sequencyOptions}
                  value={sequencyOptions.find(x => x.value === showPaymentDetail.bankId)}
                  onChange={e =>
                    setShowPaymentDetail({
                      ...showPaymentDetail,
                      sequence: e.value,
                    })
                  }
                />
              </Row>
              <Row className='d-flex '>
                <label className={tableStyles.crudModalLabel}>&nbsp;Fecha de transmisión</label>
                <div className='px-3'>
                  <input
                    className='register-inputs'
                    style={{ color: '#7FADDE', fontWeight: '700' }}
                    type='date'
                    value={showPaymentDetail.transmission_date}
                    onChange={e =>
                      setShowPaymentDetail({
                        ...showPaymentDetail,
                        transmission_date: e.target.value,
                        application_date: '',
                      })
                    }
                    disabled={false}
                  ></input>
                </div>
              </Row>
              <Row className='d-flex '>
                <label className={tableStyles.crudModalLabel}>&nbsp;Fecha de aplicación</label>
                <div className='px-3'>
                  <input
                    className='register-inputs'
                    style={{ color: '#7FADDE', fontWeight: '700' }}
                    type='date'
                    value={showPaymentDetail.application_date}
                    min={showPaymentDetail.transmission_date ?? today()}
                    onChange={e =>
                      setShowPaymentDetail({
                        ...showPaymentDetail,
                        application_date: e.target.value,
                      })
                    }
                    disabled={false}
                  ></input>
                </div>
              </Row>
            </>
          )}

          {showPaymentDetail.bankLabel === 'BANCOLOMBIA' && step === 2 && (
            <>
              <Row className='d-flex px-1'>
                <form className='d-flex flex-grow-1 me-3 pt-2' onSubmit={handleSearchSubmit}>
                  <input
                    placeholder='Buscar...'
                    className={`${tableStyles.SearchNew} w-50 me-2 `}
                    name='name'
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <button type='submit' style={{ border: 'none', background: 'none' }}>
                    <img
                      style={{ width: '1.5rem' }}
                      src={Lupa}
                      alt='iconSearch'
                      className={`${tableStyles.iconSvgMargin2} pointer`}
                    />
                  </button>
                </form>
              </Row>
              <div style={{ flex: 1 }}>
                <GenericTable
                  headers={headersModal}
                  dark={true}
                  body={renderListModal}
                  typeHead={'2'}
                  maxHeight={500}
                ></GenericTable>
              </div>
            </>
          )}
        </div>
      </ModalNew>
      <div className='d-flex'>
        <h1 className={tableStyles.title}>Planillas de pagos</h1>
      </div>
      {!!myPermission?.read && (
        <>
          <Row>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Mes</p>
              <SelectComponent
                value={valueSelect({
                  list: monthOptions.results,
                  findLabel: 'name',
                  findId: 'id',
                  value: filters.month
                })}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                key={'state' + trigger}
                onChange={e => {
                  setFilter({
                    ...filters,
                    month: e.value,
                    page: 1,
                  });
                  setTrigger(trigger + 1);
                }}
                options={optionsSelect({
                  list: monthOptions.results,
                  label: 'name',
                  value: 'id'
                })}
              ></SelectComponent>{' '}
            </Col>
            <Col xs={4} className='d-flex gap-2'>
              <div className=''>
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className='register-inputs'
                  type='number'
                  step='1'
                  placeholder='Escribir...'
                  value={filters.year}
                  onChange={e =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                      page: 1,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters();
                    }
                  }}
                ></input>
              </div>
              <img
                className='hoverPointer align-self-end'
                alt='iconSearch'
                style={{}}
                src={Lupa}
                height='24px'
                onClick={e => searchFilters(e)}
              />
            </Col>
          </Row>
          <div></div>
          <GenericTable headers={header} dark={true}>
            {body}
          </GenericTable>

          <div className={paginationStyles.wrapper}>
            <p className={` ${paginationStyles.paginationText} darkGray`}>
              pag. {filters.page}
              {' de '}
              {Math.ceil(nominaTotal / filters.perpage)
                ? Math.ceil(nominaTotal / filters.perpage)
                : '1'}{' '}
              ({' ' + nominaTotal}
              {' encontrados'})
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={nominaTotal}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={`${paginationStyles.itemClassPrev} `}
              itemClassNext={`${paginationStyles.itemClassNext} `}
              itemClassFirst={`${paginationStyles.itemClassFirst} `}
              itemClassLast={`${paginationStyles.itemClassLast} `}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </>
      )}
    </div>
  );

  return renderPrincipal;
}
export default PaymentsTemplate;
