import { Close } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Atras from '../../assets/img/icons/iconBack.svg';

import { useGetMethod } from '../../Hooks';
import { getCollectionsLettersDetail } from '../../actions/collectionActions';
import { PayLegalCollection, sendLetter } from '../../actions/receiptOfInvoicesActions';
import GenericTable from '../../components/Layouts/GenericTableNew';
import { convertMoneyFormat, loader } from '../../helpers/helpers';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import modalStyles from '../../components/Layouts/modalnew.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

function PrelegalCollection(props) {
  const statusConversion = useMemo(
    () => ({
      persuasivo: 'persuasive',
      'pre-jurídico': 'pre-juridical',
      jurídico: 'juridical',
      castigo: 'punishment',
    }),
    [],
  );

  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const idEnterprise = storage.loginReducer.currentAccount.id;

  const [showletter, setShowletter] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chooseLetter, setChooseLetter] = useState({});
  const [showTemplate, setShowTemplate] = useState(false);
  const [showContacts, setShowContacts] = useState({
    isOpen: false,
    contacts: [],
  });
  const [template, setTemplate] = useState('');
  const [collectionLetter, setCollectionLetter] = useState([]);

  const { trigger: getCompany, load: companyLoader } = useGetMethod();

  const handleRadio = elem => {
    setChooseLetter(elem);
  };

  useEffect(() => {
    if (props.invoices) setInvoices(props.invoices);
  }, [props.invoices]);

  useEffect(() => {
    (async function () {
      const result = await getCollectionsLettersDetail(
        {
          eaccount: idEnterprise,
          collectionType: statusConversion[props.currentStatus],
          status: '1',
        },
        storage.loginReducer?.Authorization,
      );
      if (result.success) {
        setCollectionLetter(result.results);
      }
    })();
  }, [idEnterprise, props.currentStatus, storage.loginReducer?.Authorization, statusConversion]);

  const sendToPay = () => {
    dispatch(
      PayLegalCollection(
        {
          client: props.invoices[0].client,
          invoices: invoices.map(el => el.id),
          contacts: contacts.map(el => el.id),
          letter: chooseLetter.id,
          preview: false,
          status: statusConversion[props.currentStatus],
          state: props.currentStatus,
          eaccount: storage.loginReducer.currentAccount.id,
          user: storage.loginReducer.user_data.id,
        },
        () => {
          props.setshowReceipt({ show: false });
          props.setSelectedRows([]);
          setShowContacts(false);
        },
      ),
    );
  };

  const onCheckAll = checked => {
    if (checked) {
      setInvoices(props.invoices);
    } else {
      setInvoices([]);
    }
  };

  const onChangeCheck = invoice => {
    let options = [];
    const isSelected = invoices.some(el => el.id === invoice.id);

    if (isSelected) {
      options = invoices.filter(el => el.id !== invoice.id);
    } else {
      options = [...invoices, invoice];
    }
    setInvoices(options);
  };

  const closeLetter = () => {
    setShowletter(false);
  };

  const onChangeContactCheck = contact => {
    let options = [];
    const isSelected = contacts.some(el => el.id === contact.id);

    if (isSelected) {
      options = contacts.filter(el => el.id !== contact.id);
    } else {
      options = [...contacts, contact];
    }

    setContacts(options);
  };

  const getTemplate = async () => {
    try {
      setIsLoading(true);
      const result = await sendLetter({
        client: props.invoices[0].client,
        invoices: invoices.map(el => el.id),
        status: statusConversion[props.currentStatus],
        letter: chooseLetter.id,
        eaccount: storage.loginReducer.currentAccount.id,
        preview: true,
        user: storage.loginReducer.user_data.id,
        contacts: ['0'],
      });

      if (result.success) {
        setShowTemplate(true);
        setTemplate(result.results);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenClients = async () => {
    const result = await getCompany({
      url: '/coporateClients/',
      objFilters: {
        id: props.invoices[0]?.client,
        id_account: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
    });
    if (result.results?.[0]) {
      setShowContacts({
        isOpen: true,
        contacts: result.results[0].contacts,
      });
    }
  };

  const body = () => {
    if (collectionLetter.length) {
      return collectionLetter.map((elem, i) => {
        return (
          <div key={i} className='row g-0 align-items-center py-2'>
            <div className='col-1 ps-3 pe-2'>
              <input
                type='radio'
                className='cursorPointer'
                name='c1'
                checked={elem.id === chooseLetter?.id}
                onChange={() => handleRadio(elem)}
              />
            </div>
            <div className='col px-2'>{elem.name ? elem.name : '-'}</div>
          </div>
        );
      });
    } else {
      return (
        <p className='text-muted text-center mt-3'>
          No hay cartas de cobro para este tipo de cobro.
        </p>
      );
    }
  };

  const equalInvoices = useMemo(() => {
    return _.isEqual(_.sortBy(invoices, 'id'), _.sortBy(props.invoices, 'id'));
  }, [invoices, props.invoices]);

  const header = [
    <th key={1} className='px-2'>
      <Form.Check
        type='checkbox'
        className='m-0 pt-1 ms-1'
        onChange={({ target }) => onCheckAll(target.checked)}
        checked={equalInvoices}
      />
    </th>,
    <th key={2} style={{ whiteSpace: 'nowrap' }} className='text-start px-2'>
      No. Factura{' '}
    </th>,
    <th key={3} style={{ whiteSpace: 'nowrap' }} className='text-center px-2'>
      Fecha factura
    </th>,
    <th key={4} style={{ whiteSpace: 'nowrap' }} className='text-center px-2'>
      Fecha radicación
    </th>,
    <th key={5} style={{ whiteSpace: 'nowrap' }} className='text-center px-2'>
      Fecha Vencimiento
    </th>,
    <th key={6} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      Valor de factura
    </th>,
    <th key={7} className='text-end px-2'>
      Saldo
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(props.invoices)) {
      table = props.invoices.map((item, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='px-2' width={30}>
              <Form.Check
                type='checkbox'
                className='pt-1 ms-1'
                checked={invoices.some(el => el.id === item.id)}
                onChange={() => onChangeCheck(item)}
              />
            </td>
            <td className='text-start text-uppercase px-2'>{item.sequence}</td>
            <td className='px-2'>
              {moment(item?.date).isValid()
                ? moment(item?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='px-2'>
              {moment(item?.filing_date).isValid()
                ? moment(item?.filing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='px-2'>
              {moment(item?.due_date).isValid()
                ? moment(item?.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='text-end px-2'>{convertMoneyFormat(item.amount)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(item.pending_amount)}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const renderFields = () => {
    return (
      <div className='row mt-4'>
        <div className='col-3'>
          <small className='text-primary ms-1'>Empresa</small>
          <input
            className='register-inputs'
            type='text'
            defaultValue={props.invoices[0]?.clientNit + '-' + props.invoices[0]?.clientName}
            readOnly
          />
        </div>

        <div className='col-3'>
          <small className='text-primary ms-1'>Carta de cobro</small>
          <input
            className='register-inputs bg-img-prejuridico'
            type='text'
            defaultValue={chooseLetter.name !== '' ? chooseLetter.name : ''}
            readOnly
            onClick={() => setShowletter(true)}
            placeholder='Seleccionar...'
          />
        </div>
      </div>
    );
  };

  const renderLetterModal = () => {
    return (
      <Modal
        show={showletter}
        centered
        className={`${modalStyles.container}`}
        dialogClassName={modalStyles.modal_width_md}
        size='sm'
        onHide={closeLetter}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            Cartas de cobro
          </h3>
          <Close className='pointer text-secondary' onClick={closeLetter} />
        </div>
        <hr className='m-0' />
        <div className='d-flex flex-column py-3 px-4'>
          <div
            className='row g-0 py-2 text-white fw-bold small'
            style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <div className='col-1 ps-3 pe-2'></div>
            <div className='col px-2'>Nombre</div>
          </div>
          <div className={`${tableStyles.odd_rows} overflow-auto small`} style={{ maxHeight: 200 }}>
            {body()}
          </div>

          <div className='align-self-center mt-4'>
            <button
              onClick={getTemplate}
              className={`${tableStyles.btnSecondary} me-3`}
              disabled={!chooseLetter?.id}
            >
              Vista previa
            </button>
            <button className={`${tableStyles.btnPrimaryAlt}`} onClick={closeLetter}>
              Seleccionar
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const renderCollectionLetter = () => {
    return (
      <Modal
        show={showTemplate}
        className={`${modalStyles.container}`}
        onHide={() => setShowTemplate(false)}
        size='lg'
        centered
      >
        <div className='d-flex flex-column p-3'>
          <Close
            className='pointer text-secondary align-self-end'
            onClick={() => {
              setShowTemplate(false);
            }}
          />
          <div className='p-4' dangerouslySetInnerHTML={{ __html: template }}></div>
        </div>
      </Modal>
    );
  };

  const renderSendLetter = () => {
    return (
      <Modal
        show={showContacts.isOpen}
        centered
        className={`${modalStyles.container}`}
        dialogClassName={modalStyles.modal_width_md}
        size='sm'
        onHide={() => {
          setShowContacts({
            isOpen: false,
            contacts: [],
          });
          setContacts([]);
        }}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            Enviar cobro
          </h3>
          <Close
            className='pointer text-secondary'
            onClick={() => {
              setShowContacts({
                isOpen: false,
                contacts: [],
              });
              setContacts([]);
            }}
          />
        </div>
        <hr className='m-0' />
        <div className='d-flex flex-column py-3 px-4'>
          <p>Seleccione los contactos a los que desea enviar la carta de cobro.</p>

          {showContacts.contacts.length ? (
            showContacts.contacts.map(item => (
              <div key={item.id} className='px-5 pb-4 d-flex align-items-center'>
                <Form.Check
                  type='checkbox'
                  className='m-0'
                  onChange={() => onChangeContactCheck(item)}
                  checked={contacts.some(el => el.id === item.id)}
                />
                <div>{item.name}</div>
                <div className='mx-3'> | </div>
                <div className='text-primary'>{item.email}</div>
              </div>
            ))
          ) : (
            <div className='text-muted text-center py-4'>No hay contactos para listar.</div>
          )}

          <div className='align-self-center mt-4'>
            <button
              className={`${tableStyles.btnSecondary} me-3`}
              onClick={() => {
                setShowContacts({
                  isOpen: false,
                  contacts: [],
                });
                setContacts([]);
              }}
            >
              Cancelar
            </button>
            <button className={`${tableStyles.btnPrimaryAlt}`} onClick={sendToPay}>
              Aceptar
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {!!storage.invoiceReducer.loading_PayLegalCollection && loader}
      {(isLoading || companyLoader) && loader}

      <div className='container-fluid px-5 my-4'>
        <div className='d-flex'>
          <img
            className='pointer mr-3'
            src={Atras}
            onClick={() => props.setshowReceipt({ show: false })}
            alt='Back'
          />
          <h1 className='m-0'>Cobro {props.currentStatus}</h1>
        </div>

        {renderFields()}

        <GenericTable headers={header} dark={true}>
          {renderList()}
        </GenericTable>

        <div className='d-flex justify-content-end mt-4'>
          <Button className={tableStyles.btnPrimaryAlt} onClick={onOpenClients}>
            Enviar cobro
          </Button>
        </div>
      </div>

      {renderLetterModal()}
      {renderCollectionLetter()}
      {renderSendLetter()}
    </>
  );
}
export default PrelegalCollection;
