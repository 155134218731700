import { memo, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import treasuryStyles from '../prioritize-bills-to-pay.module.scss';

import close from '../../../../assets/img/icons/modalClose.svg';
import { ordCustomSelect } from '../../../../components/Layouts/react-select-custom';

import Select from 'react-select';
import { usePostMethod } from '../../../../Hooks';

import { customSwaltAlert, isEmptyOrUndefined, loader } from '../../../../helpers';

export const PrioritizeBillsToPayConfig = memo(({ handleClose, config, onUpdateSuccess }) => {
  const title =
    config.action === 'UPDATE' ? 'Editar' : config.action === 'CREATE' ? 'Crear' : 'Detalle';

  // prioridad
  const [priority, setPriority] = useState('');
  const [priority_name, setPriority_name] = useState('');

  // tipos de pagos.
  const [ptId, setPtId] = useState('');
  const [ptName, setPtName] = useState('');

  const { load: loaderUpdate, trigger: onUpdate } = usePostMethod();
  const { load: loaderCreate, trigger: onCreate } = usePostMethod();

  useEffect(() => {
    if (config?.data?.ptId) {
      setPtId(config?.data?.paymentType?.id || '');
      setPtName(config?.data?.paymentType?.name || '');

      setPriority(config?.data?.priority || '');
      setPriority_name(config?.data?.priority_name || '');
    }
  }, [config?.data, setPriority, setPriority_name, setPtId, setPtName]);

  const handleUpdate = () => {
    const isValidPriority = !isEmptyOrUndefined(priority);
    const isValidPaymentType = !isEmptyOrUndefined(ptId);

    if (!isValidPriority || !isValidPaymentType) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Todos los campos son obligatorios',
        showCancelButton: false,
      });
    }

    if (config.action === 'CREATE') {
      onCreate({
        url: '/cash/priority',
        token: config.token,
        method: 'POST',
        noAlert: true,
        body: { priority, ptId, eaccount: config.eaccount },
        doAfterSuccess: res => {
          customSwaltAlert({
            icon: 'success',
            title: 'Creado exitosamente',
            text: res.message,
            showCancelButton: false,
          }).finally(() => {
            setPriority('');
            setPriority_name('');
            setPtId('');
            setPtName('');
            handleClose();
            onUpdateSuccess();
          });
        },
        doAfterException: res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
          });
        },
      });
    } else {
      onUpdate({
        url: `/cash/priority/${config.data.id}`,
        token: config.token,
        method: 'PUT',
        noAlert: true,
        body: { priority, ptId, eaccount: config.eaccount },
        doAfterSuccess: res => {
          customSwaltAlert({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: res.message,
            showCancelButton: false,
          }).finally(() => {
            setPriority('');
            setPriority_name('');
            setPtId('');
            setPtName('');
            handleClose();
            onUpdateSuccess();
          });
        },
        doAfterException: res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
          });
        },
      });
    }
  };

  return (
    <>
      {(loaderUpdate || loaderCreate) && loader}

      <Modal
        centered
        show={config.show}
        onHide={() => {
          setPriority('');
          setPriority_name('');
          setPtId('');
          setPtName('');
          handleClose();
        }}
      >
        <Modal.Header className={`${treasuryStyles.modalHeader} justify-content-center`}>
          <img
            className={treasuryStyles.close}
            src={close}
            alt='close'
            width={22}
            height={22}
            onClick={() => {
              setPriority('');
              setPriority_name('');
              setPtId('');
              setPtName('');
              handleClose();
            }}
          />
          <Modal.Title style={{ fontWeight: 'bolder' }}>{`${title} prioridad de pago`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <div className={treasuryStyles.gridModalBlock}>
            <div className='w-100'>
              <div>
                Tipo de pago <b className={treasuryStyles.asterisk}>*</b>
              </div>
              <Select
                styles={ordCustomSelect}
                className='text-secondary w-100'
                placeholder={'Seleccionar...'}
                noOptionsMessage={() => 'No hay datos'}
                isDisabled={config.action === 'DETAIL'}
                value={ptId ? { label: ptName, value: ptId } : undefined}
                options={config.options.paymentType}
                onChange={({ value, label }) => {
                  setPtName(label);
                  setPtId(value);
                }}
              />
            </div>
          </div>

          <div className={treasuryStyles.gridModalBlock}>
            <div className='w-100'>
              <div>
                Jerarquía/Escala <b className={treasuryStyles.asterisk}>*</b>
              </div>
              <Select
                styles={ordCustomSelect}
                className='text-secondary w-100'
                placeholder={'Seleccionar...'}
                noOptionsMessage={() => 'No hay datos'}
                isDisabled={config.action === 'DETAIL'}
                value={priority ? { label: priority_name, value: priority } : undefined}
                options={config.options.priority}
                onChange={({ value, label }) => {
                  setPriority_name(label);
                  setPriority(value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='px-5 py-4' style={{ borderTop: '0px' }}>
          {config.action !== 'DETAIL' && (
            <>
              <Button
                className={treasuryStyles.btnCancel}
                onClick={() => {
                  setPriority('');
                  setPriority_name('');
                  setPtId('');
                  setPtName('');
                  handleClose();
                }}
              >
                Cancelar
              </Button>
              <Button className={treasuryStyles.btnPrimary} onClick={handleUpdate}>
                Guardar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});

PrioritizeBillsToPayConfig.displayName = 'PrioritizeBillsToPayConfig';
