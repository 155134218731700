import { useEffect, useState } from 'react';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../helpers';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../Hooks';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import rowIcon from '../../assets/img/icons/arrowDerechaCalendario.svg';
import { Drawer } from '@material-ui/core';
import { Col, Form, Row } from 'react-bootstrap';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import Select from 'react-select';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';

const ReassignmentReading = () => {
  const store = useSelector(state => state);

  const [detailedClass, setDetailedClass] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Fecha examen', className: 'col-2 text-start px-2' },
      { title: 'A lectura por', className: 'col-2 text-start px-2' },
      { title: 'Servicio', className: 'col-2 text-start px-2' },
      { title: 'Paciente', className: 'col-3 text-start px-2' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
    startDate: '',
    endDate: '',
  });
  const [data, setData] = useState({
    nameClassificationDetail: '',
    stcId: null,
    createdBy: store.loginReducer.currentAccount.id,
    responsible: null,
  });
  const [trigger, setTrigger] = useState(0);

  const {
    load: detailedClassificationLoader,
    results: detailedClassificationResults,
    trigger: getDetailedClassification,
  } = useGetMethod();
  const { load: doctorLoader, results: doctorResults, trigger: getDoctorMethod } = useGetMethod();
  const { load: statusLoader, results: statusResults, trigger: statusMethod } = useGetMethod();
  const {
    load: doctorReaderLoader,
    results: doctorReaderResults,
    trigger: doctorReaderMethod,
  } = useGetMethod();

  const { load: sendReassignmentLoader, trigger: updateReassignmentReadingMethod } =
    usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getDetailedClassification({
        url: '/medical/clinicHistory/readingAssignment/',
        objFilters: { ...filters, eaccount: store.loginReducer.currentAccount.id, page: 1 },
        token: store.loginReducer.Authorization,
      });

      await getDoctorMethod({
        url: '/medical/clinicHistory/doctorsResponsibleForReading/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
        token: store.loginReducer.Authorization,
      });

      await statusMethod({
        url: '/medical/status/report_builder/',
        objFilters: {
          ...filters,
          eaccount: store.loginReducer.currentAccount.id,
          view: 'adxReading',
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDoctorMethod,
    statusMethod,
    getDetailedClassification,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    sendReassignmentLoader,
    trigger,
  ]);

  // const doctorReaderOptions = formatToRcSelect(doctorReaderResults?.results, "id", "name", "", "", "",);
  const doctorFilterOptions = formatToRcSelect(doctorResults?.results, 'id', 'name', '', '', '');
  const statusOptions = formatToRcSelect(statusResults?.results, 'id', 'description', '', '', '');

  const handleOpenEditModal = async item => {
    await doctorReaderMethod({
      url: '/medical/clinicHistory/doctorsAvailableToRead/',
      objFilters: { eaccount: store.loginReducer.currentAccount.id, srvId: item.srvId },
      token: store.loginReducer.Authorization,
    });
    setDetailedClass({
      ...detailedClass,
      showModal: true,
      isEditing: true,
    });
    setData({
      ...data,
      ...item,
    });
  };

  const handleCloseModal = () => {
    setDetailedClass({ ...detailedClass, showModal: false, isEditing: false });
    setData({
      nameClassificationDetail: '',
      srvId: [],
      createdBy: store.loginReducer.currentAccount.id,
      stcId: null,
    });
  };

  const handleUpdateReassignmentReading = async () => {
    await updateReassignmentReadingMethod({
      url: '/medical/clinicHistory/readingAssignment/',
      method: 'PUT',
      token: store.loginReducer.Authorization,
      body: {
        adxId: data?.adxId,
        assignBy: store.loginReducer.currentAccount.id,
        responsibleId: data?.responsible,
      },
      succesAction: () => {
        setFilters({
          ...filters,
          page: 1,
        });
        handleCloseModal();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: 'Reasignación exitosa',
          text: 'Se ha reasignado la lectura exitosamente.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleSubmit = () => {
    if (data.responsible) {
      handleUpdateReassignmentReading();
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Debe modificar el campo 'Reasignar a', para poder guardar la información de lectura`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const renderRow = row => {
    return (
      <tr key={row.id} className={`hover-table-row`}>
        <td className='text-start px-2 col-2'>{row?.date}</td>
        <td className='text-start px-2 col-2'>{row?.responsibleName}</td>
        <td className='text-start px-2 col-2'>{row?.srvName}</td>
        <td className='text-start px-2 col-3 '>{row?.patientName}</td>
        <td className='px-2 col-2'>
          <div className=' d-flex justify-content-center'>
            <div
              style={{
                backgroundColor: row.statusBgColor || '#E8E8EA',
                color: row.statusFontColor || '#6E6F7C',
                borderRadius: 16,
                padding: '4px 10px',
              }}
            >
              {row.statusName}
            </div>
          </div>
        </td>
        <td className='text-end col-1'>
          <div className='d-flex justify-content-center'>
            {row?.statusPrefix === 'pending_reading' && (
              <img
                alt='icon'
                src={rowIcon}
                className='cursorPointer'
                onClick={() => {
                  handleOpenEditModal(row);
                }}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={detailedClass.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: detailedClassificationResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDetailedClassification({
              url: '/medical/clinicHistory/readingAssignment/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
                page: e,
                perpage: 10,
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {detailedClassificationResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    let doctorReaderOptions = [{ value: '', label: 'Seleccionar...' }];
    doctorReaderResults?.results?.length > 0 &&
      doctorReaderResults?.results?.forEach(element => {
        doctorReaderOptions.push({
          value: element.id,
          label: `${element.name} - (${element?.pending_readings_by_doctor} pendientes) `,
        });
      });
    return (
      <Drawer
        anchor={'right'}
        open={detailedClass.showModal}
        onClose={handleCloseModal}
        classes={{ width: '100%' }}
      >
        <div className={`${tableStyles.tlndrawer__header}`}>
          <button className={`${tableStyles.tlndrawer__close}`} onClick={handleCloseModal}></button>
          <div className={`${tableStyles.tlndrawer__title} w-100 text-start px-3`}>
            <span>Información lectura</span>
          </div>
        </div>
        <div className='d-flex flex-column ' style={{ height: '100vh' }}>
          <div className={`p-5 ${tableStyles.tlndrawer__content}`} style={{ width: '500px' }}>
            <Row>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Servicio</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data.srvName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Atendido por</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data.attendedBy}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column ' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>A lectura por</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>
                      {data.responsibleName} ({data?.pendingReadingsByResponsible} pendientes)
                    </span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Reasignar a </b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={doctorReaderOptions}
                    className='text-secondary'
                    value={
                      doctorReaderOptions.length > 0 &&
                      doctorReaderOptions?.find(
                        item =>
                          item.value ===
                          (data.responsible ? data.responsible : data?.respponsibleId),
                      )
                    }
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={option => {
                      setData({
                        ...data,
                        adxId: Number(data?.adxId),
                        responsible: option?.value,
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='px-5 py-3'>
            <Row>
              <Col xs={12}>
                <div className='text-end'>
                  <button
                    onClick={handleCloseModal}
                    className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
                  >
                    Cancelar
                  </button>
                  <button
                    className={`${tableStyles.ordBtnPrimary} btn`}
                    // disabled={validateInput()}
                    onClick={handleSubmit}
                  >
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    );
  };

  const renderHeader = () => {
    return (
      <div className='row align-items-end'>
        <div className='col-4'>
          <input
            placeholder='Escribe aquí para buscar...'
            className={`${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} pl-2 w-100`}
            onChange={({ target }) => {
              setFilters(prev => ({ ...prev, search: target.value, page: 1 }));
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </div>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>A lectura por</span>
            </div>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            // value={selectDefaultValue}
            options={doctorFilterOptions}
            onChange={option => {
              setTrigger(trigger + 1);
              setFilters(prev => ({ ...prev, responsibleId: option.value, page: 1 }));
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Estado</span>
            </div>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            // value={selectDefaultValue}
            options={statusOptions}
            onChange={option => {
              setTrigger(trigger + 1);
              setFilters(prev => ({ ...prev, status: option.value, page: 1 }));
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Desde</span>
            </div>
          </Form.Label>
          <Form.Control
            type='date'
            className='ord-roundInput'
            onChange={({ target }) =>
              setFilters(prev => ({ ...prev, startDate: target.value, page: 1 }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Hasta</span>
            </div>
          </Form.Label>
          <Form.Control
            type='date'
            className='ord-roundInput'
            onChange={({ target }) =>
              setFilters(prev => ({ ...prev, endDate: target.value, page: 1 }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </Form.Group>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className={`${tableStyles.tlnContainer}`}>
          <OrdGenericTemplate
            className=''
            titleSize={12}
            title={'Consulta de ayudas diagnósticas con lectura '}
          >
            <>
              <section className='pr-8 mt-2'>
                <>
                  {detailedClassificationLoader && loader}
                  {sendReassignmentLoader && loader}
                  {doctorReaderLoader && loader}
                  {statusLoader && loader}
                  {doctorLoader && loader}
                  {renderModal()}
                  <div>
                    {renderHeader()}
                    {renderTable()}
                  </div>
                </>
              </section>
            </>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
};

export default ReassignmentReading;
