// by jhan ortegon

import { useMemo } from 'react';

// icons - images
import Trash from '../../assets/img/icons/canecaGris.svg';
import lupaClear from '../../assets/img/icons/lupaClear.svg';
import Agregar from '../../assets/img/icons/agregarEspecialidad.svg';

// css
import '../Layouts/customTabs.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';

// Components
import Select from 'react-select';
import ModalNew from '../Layouts/ModalNew';
import Pagination from 'react-js-pagination';
import GenericTableNew from '../Layouts/GenericTableNew';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import { generateId } from '../../helpers/helpers';

const ModalArticle = props => {
  const articlesData = useMemo(() => {
    const articles = props.selectedCC?.articles;

    if (articles) {
      const opt = articles.filter(art =>
        art.description
          .trim()
          .toLowerCase()
          .includes(props.filtersArticle.search.trim().toLowerCase()),
      );
      return opt;
    }
    return [];
  }, [props.filtersArticle.search, props.selectedCC?.articles]);

  const handlePageChange = val => {
    props.setFilterArticle({
      ...props.filtersArticle,
      page: val,
    });
  };

  const articleCheckHandle = article => {
    let articleChangesTemp = props.articleChanges;
    let exists = articleChangesTemp.find(x => x.id === article.id);
    if (exists) {
      articleChangesTemp = articleChangesTemp.filter(x => x !== exists);
    } else {
      articleChangesTemp.push(article);
    }
    props.setArticleChanges(articleChangesTemp);
  };

  const articleCheckAllStatus = () => {
    let allEqual = props.articleChanges.length > 0;

    articlesData.forEach(elem => {
      let exists = props.articleChanges.find(x => x.id === elem.id);
      if (!exists) {
        allEqual = false;
      }
    });

    return allEqual;
  };

  const articleNewAdd = elem => {
    props.setselectedCC(state => ({
      ...state,
      articles: [...state.articles, elem],
    }));
    props.setFilterArticle({
      search: '',
      page: 1,
      perpage: 10,
    });
  };

  const articleNewDelete = elem => {
    props.setselectedCC(state => ({
      ...state,
      articles: state.articles?.filter(art => art.id !== elem.id),
    }));
  };

  const articleCheckAllHandle = () => {
    let articleChangesTemp = props.articleChanges;

    if (!articleCheckAllStatus()) {
      articlesData.forEach(elem => {
        let exists = articleChangesTemp.some(x => x.id === elem.id);
        if (!exists) articleChangesTemp.push(elem);
      });
    } else {
      articleChangesTemp = [];
    }

    props.setArticleChanges(articleChangesTemp);
  };

  const renderList = () => {
    let table = [];

    if (articlesData.length > 0) {
      table = articlesData.map(x => {
        return (
          <tr key={'ReqModalArticleList' + x.id} className='hover-table-row'>
            <td width={80} className={`text-start px-2`}>
              {x.id}
            </td>
            <td className={`text-start`}>{x.description}</td>
            <td width={50}>
              <input
                className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
                type='checkbox'
                name={`selectedArt${x.article_id}`}
                onChange={() => articleCheckHandle(x)}
                checked={!!props.articleChanges.find(change => change.id === x.id)}
              />
            </td>
            <td width={30}>
              {x.newArticle && (
                <img
                  src={Trash}
                  alt='Eliminar'
                  className={`icons-popUp text-center ${reqStyles.adjustIcon} ${reqStyles.orangeIcon}`}
                  onClick={() => articleNewDelete(x)}
                />
              )}
            </td>
          </tr>
        );
      });
    }
    else {
      table = [
        <tr key={1}>
          <td colSpan={4}>No hay artículos en el centro de consumo seleccionado.</td>
        </tr>
      ]
    }

    return table;
  };

  const paginateList = () => {
    let fullList = renderList();

    fullList = fullList.slice(
      props.filtersArticle.perpage * (props.filtersArticle.page - 1),
      props.filtersArticle.perpage * props.filtersArticle.page,
    );

    if (articlesData?.length < 1 && props.filtersArticle.search.trim() !== '') {
      let newRow = (
        <tr key='infinityyyyyyyyy' className='hover-table-row'>
          <td className={`col-md-2 text-start`}>
            <div className={`${reqStyles.newArticle}`}>NUEVO</div>
          </td>
          <td className={`col-md-8`}>
            <div className={`${reqStyles.newArticle}`}>{props.filtersArticle.search.trim()}</div>
          </td>
          <td className={`col-md-2`}>
            <img
              src={Agregar}
              alt='Agregar'
              className={`${reqStyles.adjustIcon}  ${reqStyles.orangeIcon}`}
              onClick={() =>
                articleNewAdd({
                  id: generateId().slice(0, 4),
                  description: props.filtersArticle.search.trim().toUpperCase(),
                  newArticle: true,
                  ammount: 1,
                })
              }
            />
          </td>
        </tr>
      );

      fullList.push(newRow);
    }
    return fullList;
  };

  const allCheckButton = (
    <input
      className={`form-check-input mx-0 ${tableStyles.myCheckboxInput}`}
      type='checkbox'
      name={`selectedArtAll`}
      onChange={() => articleCheckAllHandle()}
      checked={articleCheckAllStatus()}
    />
  );

  const renderHeaders = [
    <th key={`ReqModalArticle1`} className='px-2 text-start'>
      Código
    </th>,
    <th key={`ReqModalArticle2`}>Descripción</th>,
    <th key={`ReqModalArticle99`} className='text-center'>
      {allCheckButton}
    </th>,
    <th key={'delete'}></th>,
  ];

  return (
    <ModalNew
      title={props.title}
      show={props.show}
      size='lg'
      btnYesEvent={() => props.savearticles()}
      onHide={props.onHide}
    >
      <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
      <div className={tableStyles.mainRow}>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          {Array.isArray(props.optionCostCenter) && props.optionCostCenter.length > 1 ? (
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'selectCC' + props.selectedCC?.value}
              defaultValue={props.selectedCC}
              options={[{ label: 'Seleccionar...', value: '' }, ...props.optionCostCenter]}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              isDisabled
            />
          ) : (
            <input
              className={tableStyles.inputText}
              type='text'
              placeholder=''
              value={props.selectedCC?.label}
              disabled
            />
          )}
        </div>

        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <input
            className='register-inputs'
            type='text'
            placeholder='Escribir...'
            value={props.filtersArticle.search}
            onChange={e =>
              props.setFilterArticle({
                ...props.filtersArticle,
                search: e.target.value,
                page: 1,
              })
            }
          />
        </div>

        <img
          src={lupaClear}
          alt='filtrar'
          className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
        />

        <div className={`${reqStyles.ItemTiny} ${reqStyles.orangeText}`}>
          {articlesData?.length < 1 && props.filtersArticle.search.trim() !== ''
            ? 'Artículo no encontrado'
            : ''}
        </div>
      </div>

      <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>

      <div className={paginationStyles.wrapperReverse}>
        <Pagination
          activePage={props.filtersArticle.page}
          itemsCountPerPage={props.filtersArticle.perpage}
          totalItemsCount={articlesData?.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
          itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
          itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
          itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </ModalNew>
  );
};

export default ModalArticle;
