import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { mergeRegister } from '@lexical/utils';
import { ArrowDropDown } from '@material-ui/icons';
import { useBeautifulMentions } from 'lexical-beautiful-mentions';

import '../LetterEditor.scss';

const LowPriority = 1;

function Divider() {
  return <div className='divider' />;
}

export default function ToolbarPlugin({ variables = [], labels = {} }) {
  const { insertMention } = useBeautifulMentions();

  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isAlignLeft, setIsAlignLeft] = useState(false);
  const [isAlignRight, setIsAlignRight] = useState(false);
  const [isAlignCenter, setIsAlignCenter] = useState(false);
  const [isAlignJustify, setIsAlignJustify] = useState(false);

  const [blockType, setBlockType] = useState('paragraph');

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsAlignLeft(selection.hasFormat('left'));
      setIsAlignRight(selection.hasFormat('right'));
      setIsAlignCenter(selection.hasFormat('center'));
      setIsAlignJustify(selection.hasFormat('justify'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, updateToolbar]);

  const formatList = listType => {
    if (listType === 'ordered' && blockType !== 'ordered') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, 'ordered');
      setBlockType('ordered');
    } else if (listType === 'unordered' && blockType !== 'unordered') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, 'unordered');
      setBlockType('unordered');
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      setBlockType('paragraph');
    }
  };

  return (
    <div className='toolbar' ref={toolbarRef}>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label='Format Bold'
      >
        <i className='format bold' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label='Format Italics'
      >
        <i className='format italic' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label='Format Underline'
      >
        <i className='format underline' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')}
        aria-label='Format Strikethrough'
      >
        <i className='format strikethrough' />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        aria-label='Left Align'
        className={'toolbar-item spaced ' + (isAlignLeft ? 'active' : '')}
      >
        <i className='format left-align' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className={'toolbar-item spaced ' + (isAlignCenter ? 'active' : '')}
        aria-label='Center Align'
      >
        <i className='format center-align' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className={'toolbar-item spaced ' + (isAlignRight ? 'active' : '')}
        aria-label='Right Align'
      >
        <i className='format right-align' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className={'toolbar-item spaced ' + (isAlignJustify ? 'active' : '')}
        aria-label='Justify Align'
      >
        <i className='format justify-align' />
      </button>
      <Divider />
      <button
        onClick={() => formatList('ordered')}
        aria-label='Ordered list'
        className={'toolbar-item spaced ' + (blockType === 'ordered' ? 'active' : '')}
      >
        <i className='format ordered' />
      </button>
      <button
        onClick={() => formatList('unordered')}
        className={'toolbar-item spaced ' + (blockType === 'unordered' ? 'active' : '')}
        aria-label='Unordered list'
      >
        <i className='format unordered' />
      </button>
      <Divider />
      <button
        onClick={() => {
          editor.dispatchCommand(INDENT_CONTENT_COMMAND, 'indent');
        }}
        className={'toolbar-item spaced'}
        aria-label='Format Indent'
      >
        <i className='format indent' />
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, 'outdent');
        }}
        className={'toolbar-item spaced'}
        aria-label='Format Outdent'
      >
        <i className='format outdent' />
      </button>
      <Divider />
      <Dropdown>
        <Dropdown.Toggle as={VariableDropdown}>
          <span>Variables</span>
          <ArrowDropDown />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {variables.map(item => (
            <Dropdown.Item
              key={item}
              className='dropdown-variable-item'
              onClick={() => insertMention({ trigger: '@', value: item, data: labels })}
            >
              {labels[item]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

const VariableDropdown = forwardRef(function VariableDropdown({ children, onClick }, ref) {
  return (
    <div
      ref={ref}
      className='dropdown-variable'
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});
