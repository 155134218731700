import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

import { useGetMethod } from '../../Hooks/useFetch';
import {
  changeStatusService,
  creatServices,
  getCups,
  getIva,
  getListStatus,
  getOneService,
  getServicesInADM,
  getTypesServices,
  updateServices,
} from '../../actions/billingActions';
import { getNiifAccounts } from '../../actions/pucActions';
import { customSwaltAlert, getEnvs } from '../../helpers';
import {
  formatToRcSelect,
  handleFilterSelectDynamic,
  isEmptyOrUndefined,
  today,
} from '../../helpers/helpers';

import GenericTable from '../../components/Layouts/GenericTableNew';
import ModalNew from '../../components/Layouts/ModalNew';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { MultiSelect } from '../../shared';

import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

const defaultMessageDynamicCups = 'Nombre o código CUPS';
const defaultMessageDynamicNiif = 'Nombre o código de la cuenta';
const defaultMessageDynamicReturnNiif = 'Nombre o código de la cuenta';

function Services() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const token = storage.loginReducer.Authorization;
  const { URL_PROTOCOL, URL_BASE, API_VERSION, URL_GATEWAY } = getEnvs();
  const creatServiceInitialState = {
    name: '',
    cup_code: '',
    incomeNiif: '',
    returnNiif: '',
    description: '',
    price: '',
    vat_id: '',
    type_id: undefined,
    type_label: '',
    sheetId: null,
    eaccount: storage.loginReducer.currentAccount.id,
    enabled_telemedicine: '',
    vatIdTmed: '',
    priceTmed: '',
    optimalTimeQty: '',
    optimalTimeId: undefined,
    optimalTimeOrdQty: undefined,
    optimalTimeOrdId: undefined,
    recommendations: '',
    cntId: null,
    complexityLevel: [],
    surgeryTypeId: undefined,
  };

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'ServicesAD',
    );

  const [modalAlertcontrol, setModalAlertcontrol] = useState({
    show: false,
  });
  const [optionSelectedComplexityLevel, setOptionSelectedComplexityLevel] = useState([]);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    typeId: '',
    status: '',
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });
  const [dynamicCups, setDynamicCups] = useState([{ value: '', label: defaultMessageDynamicCups }]);
  const [selectOptionsCups, setSelectOptionsCups] = useState([]);

  const [dynamicNiif, setDynamicNiif] = useState([{ value: '', label: defaultMessageDynamicNiif }]);
  const [selectOptionsNiif, setSelectOptionsNiif] = useState([]);

  const [dynamicReturnNiif, setDynamicReturnNiif] = useState([
    { value: '', label: defaultMessageDynamicReturnNiif },
  ]);
  const [selectOptionsReturnNiif, setSelectOptionsReturnNiif] = useState([]);
  const [selectOptionsConsents, setSelectOptionsConsents] = useState([]);
  const [creatService, setcreatService] = useState(creatServiceInitialState);
  const [loading, setLoading] = useState(true);
  const [listTypeSurgery, setListTypeSurgery] = useState([]);
  const [loaderListTypeSurgery, setLoaderListTypeSurgery] = useState(false);

  const { results: times, load: timesLoader, trigger: getTimes } = useGetMethod();

  const {
    results: listConsents,
    load: loaderListConsents,
    trigger: getListConsents,
  } = useGetMethod();

  const {
    results: listComplexityLevel,
    load: loaderListComplexityLevel,
    trigger: getListComplexityLevel,
  } = useGetMethod();

  const { results: attentionSheets, trigger: getAttentionSheets } = useGetMethod();

  const stylesConsent = {
    ...customSelectNewDark,
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: 13,
        overflowX: 'hidden',
        backgroundColor: state.isDisabled
          ? '#58595B'
          : state.isSelected
            ? '#003F80'
            : state.isFocused
              ? '#ccdce4'
              : '#fff',
        color: state.data.extra <= today() ? '#f39682' : state.isSelected ? 'white' : '#58595B',
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        zIndex: 1000000,
      };
    },
    singleValue: (provided, state) => {
      const transition = 'opacity 300ms';
      const color = state.data.extra <= today() ? '#f39682' : '#58595B';
      return { ...provided, transition, color };
    },
  };

  const getListTypeSurgery = useCallback(async () => {
    try {
      setLoaderListTypeSurgery(true);
      const req = await fetch(
        `${URL_PROTOCOL}${URL_BASE}${URL_GATEWAY}${API_VERSION}/surgery/surgeries/types`,
        {
          headers: { Authorization: token },
        },
      );

      const defaultSelect = { label: 'Seleccionar...', value: '' };

      const res = await req.json();

      const results = res?.data?.length
        ? [
            defaultSelect,
            ...(res.data?.map(surgeryType => ({
              ...surgeryType,
              label: surgeryType.name,
              value: surgeryType.id,
            })) ?? []),
          ]
        : [defaultSelect];

      setLoaderListTypeSurgery(false);
      setListTypeSurgery(results);
      return results;
    } catch (error) {
      setLoaderListTypeSurgery(false);
    }
  }, [API_VERSION, URL_BASE, URL_GATEWAY, URL_PROTOCOL, token]);

  const getOptionsCupsSelect = useCallback(() => {
    if (Array.isArray(storage.billingReducer.cups)) {
      let data = [];
      storage.billingReducer.cups.forEach(item => {
        data.push({
          value: item.id,
          label: item.value,
          key: item.id + 'cup',
        });
      });

      setSelectOptionsCups(state => [...state, ...data]);
    }
  }, [storage.billingReducer.cups]);

  const getOptionsNiifSelect = useCallback(() => {
    if (Array.isArray(storage.pucReducer.niifAccounts)) {
      let data = [];
      storage.pucReducer.niifAccounts.forEach(item => {
        data.push({
          value: item.id,
          label: item.complete_account + ' - ' + item.description,
          key: item.id + 'niff',
        });
      });

      setSelectOptionsNiif(state => [...state, ...data]);

      setSelectOptionsReturnNiif(state => [...state, ...data]);
    }
  }, [storage.pucReducer.niifAccounts]);

  useEffect(() => {
    dispatch(getTypesServices({ listAll: 1 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getListStatus({
        eaccount: storage.loginReducer.currentAccount.id,
        type: 'service',
      }),
    );
    dispatch(getIva({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getNiifAccounts({ eaccount: storage.loginReducer.currentAccount.id }));

    getListConsents({
      url: '/medical/admissions/consents/',
      token: token,
    });

    getListComplexityLevel({
      token,
      url: '/medical/offered-services/complexity-levels',
      objFilters: { eaccount: storage.loginReducer.currentAccount.id },
    });

    getAttentionSheets({
      token,
      url: '/medical/clinicHistory/sheets/',
      objFilters: {},
    });
  }, [
    dispatch,
    getListComplexityLevel,
    getListConsents,
    getAttentionSheets,
    storage.loginReducer.currentAccount.id,
    token,
  ]);

  useEffect(() => {
    getTimes({
      url: '/medical/time/',
      objFilters: { id_account: storage.loginReducer.currentAccount.id },
      token: storage.loginReducer.Authorization,
    });
    getListTypeSurgery();
  }, [
    getListTypeSurgery,
    getTimes,
    storage.loginReducer.Authorization,
    storage.loginReducer.currentAccount.id,
  ]);

  useEffect(() => {
    dispatch(
      getCups({ eaccount: storage.loginReducer.currentAccount.id, search: creatService.cup_code }),
    );
  }, [creatService.cup_code, dispatch, storage.loginReducer.currentAccount.id]);

  useEffect(() => {
    dispatch(
      getServicesInADM(
        {
          page: 1,
          perpage: 10,
          typeId: '',
          status: '',
          search: '',
          eaccount: storage.loginReducer.currentAccount.id,
        },
        () => setLoading(false),
      ),
    );
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  useEffect(() => {
    if (!selectOptionsCups.length) {
      getOptionsCupsSelect();
    }
  }, [getOptionsCupsSelect, selectOptionsCups.length, storage.billingReducer.cups]);

  useEffect(() => {
    if (!selectOptionsNiif.length) {
      getOptionsNiifSelect();
    }
  }, [getOptionsNiifSelect, selectOptionsNiif.length, storage.pucReducer.niifAccounts]);

  const formattedTimes = formatToRcSelect(times?.results, 'id', 'name', 'value');

  const onGetOneService = ({ srvId }) => {
    if (srvId) {
      dispatch(
        getOneService(
          {
            srvId,
            idAccount: storage?.loginReducer?.currentAccount.id,
          },
          res => {
            setLoading(false);

            if (res.length) {
              handleFilterSelectDynamic({
                keyboardValue: res[0]?.cupCode ?? '',
                listFilter: selectOptionsCups,
                labelField: 'label',
                valueField: 'value',
                defaultMessage: defaultMessageDynamicCups,
              }).then(data => {
                setDynamicCups(data);
              });

              setDynamicNiif(selectOptionsNiif.filter(el => el?.value === res[0]?.incomeNiif));

              setDynamicReturnNiif(
                selectOptionsReturnNiif.filter(el => el?.value === res[0]?.returnNiif),
              );

              const consentsList = listConsents?.results
                ?.filter(el => el?.id === res[0]?.cntId)
                .map(item => ({
                  label: `${item?.code}-${item?.name}-${item?.version}`,
                  value: item?.id,
                }));

              const defaultValueConsents = { label: 'Seleccionar...', value: '' };

              const responseConsents = consentsList?.length
                ? [defaultValueConsents, ...consentsList]
                : [defaultValueConsents];

              setSelectOptionsConsents(responseConsents);

              const complexityLevel =
                res[0]?.complexityLevel?.map(el => ({
                  ...el,
                  label: el?.complexityClassification,
                  value: el?.weightClassification,
                })) || [];

              const service = res[0] || {};

              setcreatService({
                name: service.name,
                cup_code: service.cupCode,
                income_niif: service.incomeNiif,
                return_niif: service.returnNiif,
                description: service.description,
                price: service.price,
                vat_id: service.vatId,
                type_id: service.typeId,
                eaccount: storage.loginReducer.currentAccount.id,
                enabled_telemedicine: service.couldBeTmed,
                priceTmed: service.priceTmed,
                vatIdTmed: service.vatIdTmed,
                optimalTimeQty: service.optimalTimeQty,
                optimalTimeId: service.optimalTimeId,
                optimalTimeOrdQty: service.optimalTimeOrdQty,
                optimalTimeOrdId: service.optimalTimeOrdId,
                recommendations: service.recommendations,
                cntId: service.cntId,
                sheetId: service.sheetId,
                incomeNiif: service.incomeNiif,
                idAccount: storage.loginReducer.currentAccount.id,
                returnNiif: service.returnNiif,
                srv_id: service.srvId,
                complexityLevel: complexityLevel,
                type_label: service.serviceType.toLowerCase(),
                surgeryTypeId: service.surgeryTypeId,
                expiredTimeId: service.expiredTimeId,
                expiredValue: service.expiredValue,
              });

              setOptionSelectedComplexityLevel(complexityLevel);
            }
            setLoading(false);
          },
        ),
      );
    }
  };

  const creat = () => {
    if (creatService?.type_label?.toLowerCase() === 'cirugía' && isEmptyOrUndefined(creatService.surgeryTypeId)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tipo de cuenta es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (
      creatService?.type_label?.toLowerCase() === 'Ayuda Dx'?.toLocaleLowerCase() &&
      (isEmptyOrUndefined(creatService.expiredTimeId) ||
        isEmptyOrUndefined(creatService.expiredValue))
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo tiempo de vigencia Ayuda Dx es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    setModalAlertcontrol({
      ...modalAlertcontrol,
      show: false,
    });

    if (!modalAlertcontrol.creat) {
      setLoading(true);
      dispatch(
        updateServices(
          {
            srvId: creatService.srv_id,
            name: creatService.name,
            cupCode: creatService.cup_code,
            incomeNiif: creatService.incomeNiif,
            returnNiif: creatService.returnNiif,
            description: creatService.description,
            price: creatService.price,
            eaccount: storage.loginReducer.currentAccount.id,
            vatId: creatService.vat_id,
            typeId: creatService.type_id,
            couldBeTmed: creatService.enabled_telemedicine,
            priceTelemedicine: creatService.priceTmed,
            priceTmed: creatService.priceTmed,
            optimalTimeQty: creatService.optimalTimeQty,
            optimalTimeId: creatService.optimalTimeId,
            optimalTimeOrdQty: creatService.optimalTimeOrdQty,
            sheetId: creatService.sheetId,
            vatIdTmed: creatService.vatIdTmed,
            optimalTimeOrdId: creatService.optimalTimeOrdId,
            recommendations: creatService.recommendations,
            cntId: creatService?.cntId,
            complexityLevel: creatService?.complexityLevel.length
              ? creatService?.complexityLevel
              : undefined,
            surgeryTypeId: creatService?.surgeryTypeId,

            expiredTimeId: creatService?.expiredTimeId,
            expiredValue: creatService?.expiredValue,
          },
          () => {
            dispatch(getServicesInADM(filters, () => setLoading(false)));
            setcreatService(creatServiceInitialState);
            setSelectOptionsConsents([]);
          },
        ),
      );
    } else {
      setLoading(true);
      dispatch(
        creatServices(
          {
            price: Number(creatService.price),
            status: 'Habilitado',
            name: creatService.name,
            cupCode: String(creatService.cup_code),
            incomeNiif: creatService.incomeNiif,
            returnNiif: creatService.returnNiif,
            description: creatService.description,
            idAccount: storage.loginReducer.currentAccount.id,
            vatId: creatService.vat_id,
            typeId: creatService.type_id,
            couldBeTmed: creatService.enabled_telemedicine || false,
            medicalAuthRequired: false,
            protocolReviewRequired: false,
            priceTmed: creatService.priceTmed,
            vatIdTmed: creatService.vatIdTmed,
            sheetId: creatService.sheetId,
            optimalTimeQty: Number(creatService.optimalTimeQty),
            optimalTimeId: Number(creatService.optimalTimeId),
            optimalTimeOrdQty: Number(creatService.optimalTimeOrdQty),
            optimalTimeOrdId: Number(creatService.optimalTimeOrdId),
            recommendations: creatService.recommendations,
            cntId: creatService?.cntId,
            acronym: '1',
            complexityLevel: creatService?.complexityLevel.length
              ? creatService?.complexityLevel
              : undefined,
            surgeryTypeId: creatService?.surgeryTypeId,
            expiredTimeId: creatService?.expiredTimeId,
            expiredValue: creatService?.expiredValue,
          },
          () => {
            dispatch(getServicesInADM(filters, () => setLoading(false)));
            setcreatService(creatServiceInitialState);
            setSelectOptionsConsents([]);
          },
        ),
      );
    }
  };

  const changeStatus = (id_service, elem) => {
    setLoading(true);
    dispatch(
      changeStatusService(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          id: id_service,
          status: elem.status === 'disabled' ? 'enabled' : 'disabled',
          elem,
        },
        () => {
          dispatch(getServicesInADM(filters, () => setLoading(false)));
        },
      ),
    );
  };
  const handleSumit = e => {
    setLoading(true);
    e.preventDefault();
    dispatch(getServicesInADM({ ...filters, page: 1 }, () => setLoading(false)));
  };

  let optionsServices = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.billingReducer.get_types_services)) {
    storage.billingReducer.get_types_services.forEach(item => {
      optionsServices.push({
        value: item.serviceTypeId,
        label: item.serviceType,
        key: item.id + 'services',
      });
    });
  }
  let optionsStatus = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.billingReducer.status)) {
    storage.billingReducer.status.forEach(item => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + 'status',
      });
    });
  }

  let optionsIva = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.billingReducer.iva)) {
    storage.billingReducer.iva.forEach(item => {
      optionsIva.push({
        value: item.id,
        label: item.short_name,
        key: item.id + 'iva',
      });
    });
  }

  let optionsNiif = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.forEach(item => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }

  let optionsCUPS = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.billingReducer.cups)) {
    storage.billingReducer.cups.forEach(item => {
      optionsCUPS.push({
        value: item.id,
        label: item.value,
        key: item.id + 'cup',
      });
    });
  }

  const optionsSheets = useMemo(() => {
    if (attentionSheets.results) {
      const opt = attentionSheets.results.map(item => ({
        value: item.sheetId,
        label: item.name,
      }));
      const result = [{ label: 'Seleccionar...', value: null }, ...opt];
      return result;
    }
    return [];
  }, [attentionSheets.results]);

  const header = [
    <th key={1} className='text-start px-2'>
      Descripción
    </th>,
    <th key={2} className='text-center px-2'>
      Tipo de servicio
    </th>,
    <th key={3} className='text-center w100 px-2'>
      Estado
    </th>,
    <th key={4} className='text-center px-2'>
      ㅤ
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.billingReducer.services_ADM)) {
      table = storage.billingReducer.services_ADM.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-start px-2 col-5'>{x?.name || '-'}</td>
            <td className='text-center px-2 col-3'>{x?.serviceType || '-'}</td>
            <td className='px-2 col-3'>
              <div
                className={`
                        ${IndividualStyles.popupReqActive} ${
                          x.status === 'disabled' ? tableStyles.redState : tableStyles.greenState
                        }
                    `}
              >
                {x.status === 'disabled' ? 'Inhabilitado' : 'Habilitado'}
              </div>
            </td>
            <td className='px-2 col-1'>
              <CustomPopupExtend
                triggerSrc={threeDots}
                noHover
                showEdit={myPermission()?.edit}
                editText='Editar'
                editClickEvent={() => {
                  setModalAlertcontrol({
                    ...modalAlertcontrol,
                    show: true,
                    edit: true,
                    creat: false,
                    service: x.srvId,
                  });
                  onGetOneService({ srvId: x.srvId });
                }}
                showDetails={myPermission()?.read}
                showDetailsEvent={() => {
                  setModalAlertcontrol({
                    ...modalAlertcontrol,
                    show: true,
                    edit: false,
                    creat: false,
                    service: x.srvId,
                  });
                  onGetOneService({ srvId: x.srvId });
                }}
                textDetails='Ver Detalle'
                showEnable={myPermission()?.edit}
                isEnabled={x.status === 'enabled'}
                enableClickEvent={() => changeStatus(x.srvId, x)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  return (
    <>
      {(loading ||
        timesLoader ||
        loaderListConsents ||
        loaderListComplexityLevel ||
        loaderListTypeSurgery) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <ModalNew
        hideFooter={modalAlertcontrol.edit ? !myPermission()?.edit : !myPermission()?.create}
        size='620'
        title={modalAlertcontrol.creat ? 'Crear Servicio' : 'Servicio'}
        subtitle={modalAlertcontrol.creat ? '' : modalAlertcontrol.edit ? 'Editar' : 'Detalle'}
        show={modalAlertcontrol.show}
        btnYesName={modalAlertcontrol.creat ? 'Guardar' : 'Editar'}
        btnYesDisabled={
          !!creatService.name === '' ||
          !!creatService.cup_code === '' ||
          !creatService.type_id ||
          !!creatService.incomeNiif === '' ||
          !!creatService.returnNiif === '' ||
          !!creatService.description === '' ||
          !!creatService.recommendations === '' ||
          !!creatService.vat_id === '' ||
          !!creatService.price === '' ||
          (creatService.enabled_telemedicine === true
            ? !!creatService.priceTmed === '' || !!creatService.vatIdTmed === ''
            : false)
        }
        btnYesEvent={modalAlertcontrol.edit ? () => creat() : null}
        onHide={() => {
          setOptionSelectedComplexityLevel([]);
          setModalAlertcontrol({
            ...modalAlertcontrol,
            show: false,
          });
          setcreatService(creatServiceInitialState);
          setSelectOptionsConsents([]);
          setDynamicCups([{ value: '', label: defaultMessageDynamicCups }]);
          setDynamicNiif([{ value: '', label: defaultMessageDynamicNiif }]);
          setDynamicReturnNiif([{ value: '', label: defaultMessageDynamicReturnNiif }]);
        }}
        btnNoEvent={() => {
          setOptionSelectedComplexityLevel([]);
          setModalAlertcontrol({
            ...modalAlertcontrol,
            show: false,
          });
          setcreatService(creatServiceInitialState);
          setSelectOptionsConsents([]);
        }}
      >
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <Row className='d-flex '>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Nombre del servicio</b>
              <span className={'text-danger'}>*</span>
            </p>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsClearBlue}
              name='position'
              style={{ color: '#58595B' }}
              value={creatService.name || ''}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  name: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            />
          </Col>
        </Row>
        <Row className='d-flex '>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Código CUPS</b>
              <span className={'text-danger'}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={[{ label: 'Seleccionar...', value: '' }, ...selectOptionsCups]}
              placeholder={defaultMessageDynamicCups}
              styles={customSelectNewDark}
              value={dynamicCups.find(item => item.value === Number(creatService.cup_code))}
              onInputChange={e => {
                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsCups,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicCups,
                }).then(data => setDynamicCups(data));
              }}
              onChange={e => {
                setcreatService({
                  ...creatService,
                  cup_code: e.value,
                });
              }}
            />
          </Col>

          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tipo de servicio</b>
              <span className={'text-danger'}>*</span>
            </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              value={optionsServices.find(item => item.value === creatService.type_id)}
              options={optionsServices}
              onChange={e => {
                if (!['cirugia'].includes(e?.label.toLowerCase())) {
                  setOptionSelectedComplexityLevel([]);
                  setcreatService({
                    ...creatService,
                    type_id: e.value,
                    type_label: e.label,
                    complexityLevel: [],
                    surgeryTypeId: undefined,
                  });
                } else {
                  setcreatService({
                    ...creatService,
                    type_id: e.value,
                    type_label: e.label,
                  });
                }
              }}
              isDisabled={!modalAlertcontrol.edit}
            />
          </Col>

          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Hoja de atención</b>
              <span className={'text-danger'}>*</span>
            </p>
            <Select
              id='attention_sheet'
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              value={
                optionsSheets?.find(sheet => sheet.value === creatService?.sheetId) || {
                  label: 'Seleccionar...',
                  value: '',
                }
              }
              options={optionsSheets}
              onChange={e => {
                setcreatService({
                  ...creatService,
                  sheetId: e.value,
                });
              }}
            />
          </Col>

          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tipo de cirugía</b>
              <span className={'text-danger'}>
                {creatService?.type_label?.toLowerCase() === 'cirugía' ? '*' : ''}
              </span>
            </p>
            <Select
              isDisabled={
                creatService?.type_label?.toLowerCase() !== 'cirugía' || !modalAlertcontrol.edit
              }
              noOptionsMessage={() => 'No hay datos'}
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              value={
                listTypeSurgery?.find(surgery => surgery.id === creatService?.surgeryTypeId) || {
                  label: 'Seleccionar...',
                  value: '',
                }
              }
              options={listTypeSurgery}
              onChange={e => {
                setcreatService({
                  ...creatService,
                  surgeryTypeId: e.value,
                  surgeryTypeLabel: e.label,
                });
              }}
            />
          </Col>

          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Grado de complejidad</b>
            </p>

            <MultiSelect
              key='1'
              size={'verySmall'}
              isDisabled={
                !['cirugía'].includes(creatService?.type_label?.toLowerCase()) ||
                !modalAlertcontrol.edit
              }
              options={
                listComplexityLevel?.results?.map(el => ({
                  ...el,
                  label: el?.complexityClassification,
                  value: el?.weightClassification,
                })) || []
              }
              isSelectAll={true}
              value={optionSelectedComplexityLevel}
              menuPlacement={'bottom'}
              nameList={''}
              accentuation={'o'}
              onChange={elements => {
                setOptionSelectedComplexityLevel(elements);
                setcreatService({
                  ...creatService,
                  complexityLevel: elements,
                });
              }}
            />
          </Col>

          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Cuenta de ingreso</b>
              <span className={'text-danger'}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={[{ label: 'Seleccionar...', value: '' }, ...selectOptionsNiif]}
              placeholder={defaultMessageDynamicNiif}
              styles={customSelectNewDark}
              value={dynamicNiif.find(item => item.value === Number(creatService.incomeNiif))}
              onInputChange={e => {
                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsNiif,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicNiif,
                }).then(data => setDynamicNiif(data));
              }}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  incomeNiif: e.value,
                })
              }
            />
          </Col>

          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Cuenta de devolución</b>
              <span className={'text-danger'}>*</span>
            </p>

            <Select
              isDisabled={!modalAlertcontrol.edit}
              noOptionsMessage={() => 'No hay datos'}
              options={[{ label: 'Seleccionar...', value: '' }, ...selectOptionsNiif]}
              placeholder={defaultMessageDynamicReturnNiif}
              styles={customSelectNewDark}
              value={dynamicReturnNiif.find(item => item.value === creatService.returnNiif)}
              onInputChange={e => {
                handleFilterSelectDynamic({
                  keyboardValue: e,
                  listFilter: selectOptionsReturnNiif,
                  labelField: 'label',
                  valueField: 'value',
                  defaultMessage: defaultMessageDynamicReturnNiif,
                }).then(data => setDynamicReturnNiif(data));
              }}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  returnNiif: e.value,
                })
              }
            />
          </Col>
          {creatService?.type_label?.toLowerCase() === 'cirugía' && (
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                <b>Consentimiento</b>
              </p>

              <Select
                styles={stylesConsent}
                isDisabled={!modalAlertcontrol.edit}
                noOptionsMessage={() => 'No hay datos'}
                options={
                  listConsents?.results?.length
                    ? [
                        { label: 'Seleccionar...', value: '', extra: today() + 1 },
                        ...(listConsents?.results?.map(el => ({
                          label: `${el?.code}-${el?.name}-${el?.version}`,
                          value: el?.id,
                          extra: el?.validity,
                        })) ?? []),
                      ]
                    : [{ label: 'Seleccionar...', value: '', extra: today() + 1 }]
                }
                placeholder={'Seleccionar...'}
                value={selectOptionsConsents[1]}
                onChange={({ label, value, extra }) => {
                  setcreatService({
                    ...creatService,
                    cntId: value,
                  });

                  setSelectOptionsConsents([
                    { label: 'Seleccionar...', value: '', extra: today() + 1 },
                    { label, value, extra },
                  ]);
                }}
              />
            </Col>
          )}
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tiempo de vigencia Ayuda Dx</b>
              {creatService?.type_label?.toLowerCase() === 'Ayuda Dx'?.toLowerCase() ? (
                <span className={'text-danger'}>*</span>
              ) : (
                ''
              )}
            </p>
            <div className='d-flex'>
              <input
                type='number'
                disabled={creatService.type_label?.toLowerCase() !== 'Ayuda Dx'?.toLowerCase()}
                className={IndividualStyles.registerInputsClearBlue}
                name='expiredValue'
                placeholder='Escribir...'
                style={{ color: '#58595B', flex: 1, marginRight: '1rem' }}
                value={creatService.expiredValue || ''}
                onChange={e =>
                  setcreatService({
                    ...creatService,
                    expiredValue: e.target.value,
                  })
                }
              />
              <div style={{ flex: 4 }}>
                <Select
                  isDisabled={creatService.type_label?.toLowerCase() !== 'Ayuda Dx'?.toLowerCase()}
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  value={formattedTimes.find(item => item.value === creatService.expiredTimeId)}
                  options={formattedTimes}
                  onChange={e =>
                    setcreatService({
                      ...creatService,
                      expiredTimeId: e.value,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tiempo óptimo del servicio</b>
              <span className={'text-danger'}>*</span>
            </p>
            <div className='d-flex'>
              <input
                type='number'
                className={IndividualStyles.registerInputsClearBlue}
                name='position'
                placeholder='Escribir...'
                style={{ color: '#58595B', flex: 1, marginRight: '1rem' }}
                value={creatService.optimalTimeQty || ''}
                onChange={e =>
                  setcreatService({
                    ...creatService,
                    optimalTimeQty: e.target.value,
                  })
                }
                disabled={!modalAlertcontrol.edit}
              />
              <div style={{ flex: 4 }}>
                <Select
                  disabled={!modalAlertcontrol.edit}
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  value={formattedTimes.find(item => item.value === creatService.optimalTimeId)}
                  options={formattedTimes}
                  onChange={e =>
                    setcreatService({
                      ...creatService,
                      optimalTimeId: e.value,
                    })
                  }
                  isDisabled={!modalAlertcontrol.edit}
                />
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Tiempo óptimo del ordenamiento</b>
              <span className={'text-danger'}>*</span>
            </p>
            <div className='d-flex'>
              <input
                type='number'
                placeholder='Escribir...'
                className={IndividualStyles.registerInputsClearBlue}
                name='position'
                style={{ color: '#58595B', flex: 1, marginRight: '1rem' }}
                value={creatService.optimalTimeOrdQty || ''}
                onChange={e =>
                  setcreatService({
                    ...creatService,
                    optimalTimeOrdQty: e.target.value,
                  })
                }
                disabled={!modalAlertcontrol.edit}
              />
              <div style={{ flex: 4 }}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  value={formattedTimes.find(item => item.value === creatService.optimalTimeOrdId)}
                  options={formattedTimes}
                  onChange={e =>
                    setcreatService({
                      ...creatService,
                      optimalTimeOrdId: e.value,
                    })
                  }
                  isDisabled={!modalAlertcontrol.edit}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Descripción</b>
              <span className={'text-danger'}>*</span>
            </p>

            <textarea
              className={IndividualStyles.registerInputsClearBlue}
              placeholder='Escribir...'
              style={{ height: '7rem', color: '#58595B' }}
              rows='40'
              cols='40'
              name='justification'
              type='text'
              value={creatService.description || ''}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  description: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            ></textarea>
          </Col>
        </Row>
        <Row className='d-flex mt-4'>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Recomendaciones</b>
              <span className={'text-danger'}>*</span>
            </p>

            <textarea
              className={IndividualStyles.registerInputsClearBlue}
              style={{ height: '7rem', color: '#58595B' }}
              rows='40'
              cols='40'
              name='justification'
              type='text'
              value={creatService.recommendations}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  recommendations: e.target.value,
                })
              }
              disabled={!modalAlertcontrol.edit}
            ></textarea>
          </Col>
        </Row>
        <Row className={`w-100 d-flex mt-5 rounded m-0 ${tableStyles.bgClearBlue}`}>
          <Col xs={8} className={`p-0`}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>Costo del servicio</b>
              <span className={'text-danger'}>*</span>
            </p>
            <NumberFormat
              allowNegative={false}
              placeholder='Escribir...'
              style={{ padding: 0, color: '#58595B' }}
              className={`${IndividualStyles.registerInputsClearBlue} pl1`}
              prefix={'$'}
              name='position'
              thousandsGroupStyle='thousand'
              thousandSeparator={'.'}
              decimalSeparator={','}
              value={creatService.price || ''}
              onValueChange={values => {
                const { value } = values;
                setcreatService({
                  ...creatService,
                  price: value,
                });
              }}
              disabled={!modalAlertcontrol.edit}
            />
          </Col>
          <Col xs={4} style={{ paddingRight: '0px' }}>
            <p className={`${tableStyles.crudModalLabel} `}>
              <b>IVA</b>
              <span className={'text-danger'}>*</span>
            </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              value={optionsIva.find(item => item.value === creatService.vat_id)}
              options={optionsIva}
              onChange={e =>
                setcreatService({
                  ...creatService,
                  vat_id: e.value,
                })
              }
              isDisabled={!modalAlertcontrol.edit}
            />
          </Col>
        </Row>
        <div className='d-flex mt-3 align-items-center'>
          <input
            type='checkbox'
            name='handleTelemedicine'
            className='border border-dark-blue form-check-input p1 check-dark-blue'
            onChange={() =>
              setcreatService({
                ...creatService,
                enabled_telemedicine:
                  Number(creatService.enabled_telemedicine) === 1 ? false : true,
              })
            }
            checked={Number(creatService.enabled_telemedicine) === 0 ? false : true}
          />
          <label htmlFor='handleTelemedicine' className={`${tableStyles.crudModalLabel}`}>
            Habilitado Telemedicina
          </label>
        </div>
        {!!(Number(creatService.enabled_telemedicine) === 1) && (
          <Row className={`w-100 d-flex mt-3 rounded m-0 ${tableStyles.bgClearBlue}`}>
            <Col xs={8} className={`p-0`}>
              <p className={`${tableStyles.crudModalLabel} `}>
                <b>Valor predefinido del servicio</b>
                <span className={'text-danger'}>*</span>
              </p>
              <NumberFormat
                allowNegative={false}
                placeholder='Escribir...'
                style={{ padding: 0, color: '#58595B' }}
                className={`${IndividualStyles.registerInputsClearBlue} pl1`}
                prefix={'$'}
                name='position'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                value={creatService.priceTmed || ''}
                onValueChange={values => {
                  const { floatValue } = values;
                  setcreatService({
                    ...creatService,
                    priceTmed: floatValue,
                  });
                }}
                disabled={!modalAlertcontrol.edit}
              />
            </Col>
            <Col xs={4} style={{ paddingRight: '0px' }}>
              <p className={`${tableStyles.crudModalLabel} `}>
                <b>IVA</b>
                <span className={'text-danger'}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder={'Seleccionar...'}
                styles={customSelectNewDark}
                value={optionsIva.find(item => item.value === creatService.vatIdTmed)}
                options={optionsIva}
                onChange={e =>
                  setcreatService({
                    ...creatService,
                    vatIdTmed: e.value,
                  })
                }
                isDisabled={!modalAlertcontrol.edit}
              />
            </Col>
          </Row>
        )}
      </ModalNew>

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex'>
          <h1 className={tableStyles.title}>Servicios</h1>
        </div>

        {myPermission()?.read && (
          <>
            <div className='row gap-2'>
              <div className='col-2'>
                <p className={tableStyles.crudModalLabel}>Tipo de servicio</p>
              </div>

              <div className='col-2'>
                <p className={tableStyles.crudModalLabel}>Estado</p>
              </div>
            </div>
          </>
        )}

        <Row className='d-flex align-items-center gap-2' style={{ marginTop: '-5px' }}>
          {myPermission()?.read && (
            <>
              <Col xs={2}>
                <div className='d-flex'>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    Name='optionsTypes'
                    className={'w-100'}
                    placeholder='Seleccionar...'
                    options={optionsServices}
                    styles={customSelectNewDark}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        typeId: e.value,
                      });
                      setLoading(true);

                      dispatch(
                        getServicesInADM({ ...filters, typeId: e.value }, () => setLoading(false)),
                      );
                    }}
                  />
                </div>
              </Col>

              <Col xs={2}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  Name='optionsMovements'
                  className={'w-100'}
                  placeholder='Seleccionar...'
                  options={optionsStatus}
                  styles={customSelectNewDark}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      status: e.value,
                    });
                    setLoading(true);
                    dispatch(
                      getServicesInADM({ ...filters, status: e.value }, () => setLoading(false)),
                    );
                  }}
                />
              </Col>

              <Col xs={8} style={{ display: 'contents' }}>
                <div className='d-flex align-items-center'>
                  <form onSubmit={handleSumit}>
                    <input
                      className={tableStyles.searchUsersAlt}
                      type='text'
                      placeholder='Buscar...'
                      value={filters.search}
                      style={{
                        marginTop: '3px',
                        height: '25px',
                      }}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          search: e.target.value,
                        })
                      }
                    />
                    <button>
                      <img
                        src={Lupa}
                        alt='User icon'
                        className={`${tableStyles.iconSvgMargin2}`}
                        style={{ width: '1.4rem' }}
                        onClick={() => {
                          setLoading(true);
                          setFilters({ ...filters, page: 1 });
                          dispatch(
                            getServicesInADM({ ...filters, page: 1 }, () => setLoading(false)),
                          );
                        }}
                      />
                    </button>
                  </form>
                </div>
              </Col>
            </>
          )}

          {myPermission()?.create && (
            <Col className='d-flex justify-content-end align-items-center'>
              {myPermission()?.create ? (
                <div
                  className='d-flex justify-content-end groupAddButton align-items-center'
                  style={{ width: '160px' }}
                  onClick={() =>
                    setModalAlertcontrol({
                      ...modalAlertcontrol,
                      show: true,
                      edit: true,
                      creat: true,
                    })
                  }
                >
                  <label htmlFor='newAccident' className='darkGray fw-bold'>
                    Crear servicio
                  </label>
                  <button className='addCheckButton mx-2' style={{ marginBottom: '1.5px' }} />
                </div>
              ) : (
                ''
              )}
            </Col>
          )}
        </Row>
        {myPermission()?.read && (
          <GenericTable fontFamilyTable={'fontFamilyTable'} headers={header} dark={true}>
            {renderList()}
          </GenericTable>
        )}
        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.billingReducer.services_ADM?.length ? filters.page : ''}
              {' de '}
              {Math.ceil(storage.billingReducer?.services_ADM_total / filters.perpage)
                ? Math.ceil(storage.billingReducer?.services_ADM_total / filters.perpage)
                : ''}{' '}
              ({storage.billingReducer?.services_ADM_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.billingReducer?.services_ADM_total}
              pageRangeDisplayed={5}
              onChange={e => {
                setFilters({ ...filters, page: e });
                if (filters.page !== e) {
                  setLoading(true);
                  dispatch(getServicesInADM({ ...filters, page: e }, () => setLoading(false)));
                }
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;
