//import SASS && Mui material && icons
import { Box } from '@mui/material';

export const RenderRow = ({ title, subTitle, subTitleList }) => (
  <>
    <Box className={`fw-bold`}>{title}</Box>

    {subTitle && <Box>{subTitle}</Box>}

    {subTitleList?.length && (
      <Box>
        {subTitleList?.map((el, key) => (
          <Box key={key}>{el}</Box>
        ))}
      </Box>
    )}
  </>
);
