import { Close } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { usePostMethod } from '../../../Hooks';
import file from '../../../assets/img/icons/AdjuntarRecto.svg';
import { customSwaltAlert, loader, onChangeFile } from '../../../helpers';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function ChangeStatus({
  isOpen,
  onClose,
  onSuccess,
  currentStatus = '',
  nextStatus = '',
  selectedRows = [],
}) {
  const storage = useSelector(state => state);

  const [invoices, setInvoices] = useState([]);
  const [payload, setPayload] = useState({
    docType: '',
    reason: '',
    fileName: null,
  });

  const { load: statusLoader, trigger: changeStatus } = usePostMethod();

  const clientId = useMemo(() => {
    return storage.invoiceReducer.one_balance.id;
  }, [storage.invoiceReducer.one_balance.id]);

  useEffect(() => {
    if (selectedRows && isOpen) setInvoices(selectedRows);
  }, [selectedRows, isOpen]);

  const onCloseModal = () => {
    setInvoices([]);
    setPayload({
      ...payload,
      docType: '',
      fileName: null,
      reason: '',
    });
    onClose();
  };

  const onChangeCheck = invoice => {
    let options = [];
    const isSelected = invoices.some(el => el.sequence === invoice.sequence);

    if (isSelected) {
      options = invoices.filter(el => el.sequence !== invoice.sequence);
    } else {
      options = [...invoices, invoice];
    }
    setInvoices(options);
  };

  const onChangeSignature = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 3 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 3MB.',
        confirmButtonText: 'Aceptar',
      });
      setPayload({
        ...payload,
        reason: '',
        docType: '',
        fileName: null,
      });
      e.target.value = null;
      return;
    }

    setPayload({
      ...payload,
      reason: file.file,
      docType: file.typeFile,
      fileName: file.fileName,
    });
  };

  const onSubmit = () => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se cambiará el estado de: ${formattedCurrentStatus} a ${nextStatus}`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        changeStatus({
          token: storage.loginReducer.Authorization,
          body: {
            ...payload,
            client: clientId,
            invoices: invoices.map(el => el.id),
          },
          method: 'PUT',
          url: '/receivable/balance/changeStatus/',
          succesAction: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                setPayload({
                  docType: '',
                  fileName: null,
                  reason: '',
                });
                setInvoices([]);
                onSuccess();
              }
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const formattedCurrentStatus = currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1);

  const render = () => {
    return (
      <>
        {statusLoader && loader}
        <Modal
          show={isOpen}
          centered
          className={modalStyles.container}
          dialogClassName={modalStyles.modal_width_md}
          onHide={onCloseModal}
        >
          <div className='d-flex align-items-center justify-content-between py-3 px-4'>
            <div></div>
            <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
              Cambio de estado
            </h3>
            <Close className='pointer text-secondary' onClick={onCloseModal} />
          </div>
          <hr className='m-0' />
          <div className='py-3 px-4 d-flex flex-column'>
            <div className='row'>
              <div className='col-lg-4'>
                <small className='text-primary ms-1'>Estado actual</small>
                <input
                  type='text'
                  className='register-inputs'
                  defaultValue={formattedCurrentStatus}
                  disabled
                />
              </div>
              <div className='col-lg-4'>
                <small className='text-primary ms-1'>Próximo estado</small>
                <input type='text' className='register-inputs' defaultValue={nextStatus} disabled />
              </div>
              <div className='col-lg-4'>
                <small className='mb-1'>
                  <span className='text-primary ms-1'>Motivo</span>
                  <span className='text-danger'>*</span>
                </small>

                <label
                  htmlFor='signature'
                  className='register-inputs d-flex align-items-center pointer overflow-hidden'
                >
                  <span
                    className={`flex-grow-1 ${tableStyles.elipsis}`}
                    style={{ maxWidth: '100%' }}
                  >
                    {payload.fileName ?? 'Cargar...'}
                  </span>
                  <input
                    id='signature'
                    type='file'
                    accept='image/png, image/jpeg, application/pdf'
                    className='d-none'
                    onChange={onChangeSignature}
                  />
                  <img src={file} alt='agregar archivo' className={tableStyles.iconFileStyle} />
                </label>
              </div>
            </div>

            <div className='row mt-4' style={{ maxHeight: 130, overflowY: 'auto' }}>
              {selectedRows.map(item => (
                <div key={item.sequence} className='col-4 py-1'>
                  <Form.Check
                    type='checkbox'
                    label={item.sequence.toUpperCase()}
                    checked={invoices.some(el => el.sequence === item.sequence)}
                    onChange={() => onChangeCheck(item)}
                  />
                </div>
              ))}
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button onClick={onCloseModal} className={`${tableStyles.btnSecondary} me-3`}>
                Cancelar
              </button>
              <button className={`${tableStyles.btnPrimaryAlt}`} onClick={onSubmit}>
                Guardar
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return render();
}
