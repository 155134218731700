import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_ONE_PINVOICE, GET_PENDING_VENDOR_INVOICES, LOADING } from './actionTypes';
import { customSwaltAlert } from '../helpers';

export const pendingVendorInvoices = (objFilters, loading) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (loading) {
        loading();
      }
      const { results } = res;
      dispatch({
        type: GET_PENDING_VENDOR_INVOICES,
        payload: results,
      });
    })
    .catch(err => {
      if (loading) {
        loading();
      }
      console.error(err.message);
    });
};

export const getOnePinvoice = objFilters => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pinvoices/${objFilters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_ONE_PINVOICE,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const create_Orderpay = (data, filters, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  customSwaltAlert({
    icon: 'warning',
    title: '¿Estas Seguro?',
    text: 'Se creará una orden de pago',
    showCancelButton: true,
  }).then(result => {
    if (result.isConfirmed) {
      dispatch({
        type: LOADING,
        payload: true,
      });
      fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pay_order/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            dispatch({
              type: LOADING,
              payload: false,
            });
            customSwaltAlert({
              icon: 'success',
              title: 'Orden de pago creada exitosamente',
              text: `${res.consec}`,
              showCancelButton: false,
            }).then(response => {
              if (response.isConfirmed) {
                doAfter();
              }
            });
          } else {
            dispatch({
              type: LOADING,
              payload: false,
            });
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${res.message}`,
              showCancelButton: false,
            });
          }
        })
        .catch(err => {
          dispatch({
            type: LOADING,
            payload: false,
          });
          console.error(err);
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `No se pudo cambiar el estado`,
            showCancelButton: false,
          });
        });
    }
  });
};
