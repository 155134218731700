import ReactSelect from 'react-select';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../../Hooks';

import { chunkPagination, loader } from '../../../helpers';

import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import stylePagination from '../../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

import Pagination from 'react-js-pagination';

export default function AddService({
  open = false,
  onClose = () => null,
  onSaveServices = () => null,
  doctor,
  modality,
  site,
}) {
  const idAccount = useSelector(store => store.loginReducer.currentAccount.id);
  const token = useSelector(store => store.loginReducer.Authorization);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [serviceType, setServiceType] = useState(null);

  const [selectedServices, setSelectedServices] = useState([]);

  const {
    results: offeredServicesResults,
    load: offeredServicesLoader,
    trigger: getOfferedServices,
  } = useGetMethod();

  const {
    trigger: getServiceTypes,
    results: serviceTypesResults,
    load: serviceTypesLoader,
  } = useGetMethod();

  const offeredServicesOptions = useMemo(() => {
    if (offeredServicesResults?.results) {
      return chunkPagination(
        offeredServicesResults.results
          .filter(opt =>
            serviceType?.value
              ? opt.serviceTypeName.trim() === serviceType.label.trim().toUpperCase()
              : opt,
          )
          .filter(opt =>
            searchValue.length
              ? opt.srvName?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
              : opt,
          )
          .map(opt => ({
            id: `${opt.srvId}${opt.ctypeId}`,
            serviceId: opt.srvId,
            serviceType: opt.serviceTypeName,
            name: opt.srvName,
            groupId: opt.attGroupId,
            groupName: opt.attGroupName,
            ctypeId: opt.ctypeId,
            ctypeName: opt.ctypeName,
          })),
        5,
      );
    }
    return [];
  }, [offeredServicesResults?.results, searchValue, serviceType]);

  const serviceTypesOptions = useMemo(() => {
    if (serviceTypesResults?.results) {
      const validServiceTypes = serviceTypesResults?.results.map(opt => ({
        label: opt.serviceType,
        value: opt.serviceTypeId,
      }));
      return [{ label: "Seleccionar...", value: null }, ...validServiceTypes];
    }
    return [];
  }, [serviceTypesResults?.results]);

  useEffect(() => {
    if (open) {
      void getOfferedServices({
        url: '/medical/schedules/serviceSiteCtype',
        objFilters: {
          eaccount: idAccount,
          siteId: site,
          doctorId: doctor,
          modality: modality,
        },
        token: token,
      });

      void getServiceTypes({
        url: '/medical/generals/serviceType/',
        objFilters: { listAll: 1 },
        token: token,
      });
    }
  }, [open, getOfferedServices, idAccount, token, getServiceTypes, doctor, modality, site]);

  const onCloseModal = () => {
    onClose();
    setSearchValue('');
    setSelectedServices([]);
    setServiceType(null);
  };

  const onCheckService = service => {
    const serviceExists = selectedServices.some(serv => serv.id === service.id);

    if (!serviceExists) {
      setSelectedServices(state => [...state, service]);
    } else {
      setSelectedServices(state => state.filter(serv => serv.id !== service.id));
    }
  };

  const validateCheck = () =>
    offeredServicesOptions
      .flat()
      .map(opt => opt.id)
      .every(id => selectedServices.some(serv => serv.id === id));

  const onCheckAll = () => {
    const services = offeredServicesOptions.flat().map(opt => opt.id);
    const allSelected = services.every(id => selectedServices.some(serv => serv.id === id));

    if (allSelected) {
      setSelectedServices(state => state.filter(service => !services.includes(service.id)));
    } else {
      setSelectedServices(state => [
        ...state,
        ...offeredServicesOptions
          .flatMap(opt => opt)
          .filter(service => !state.includes(service.id)),
      ]);
    }
  };

  const renderTableHeaders = () => {
    return [
      {
        title: (
          <input
            type='checkbox'
            className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
            checked={validateCheck()}
            onChange={() => onCheckAll()}
          />
        ),
        className: 'px-2 text-center',
      },
      { title: 'Tipo de servicio', className: 'px-2 text-start' },
      { title: 'Servicio', className: 'px-2 text-start' },
      { title: 'Tipo', className: 'px-2 text-center' },
    ];
  };

  const renderComponent = () => {
    return (
      <OrdModal
        title='Agregar servicio'
        show={open}
        onHide={() => onCloseModal()}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size={750}
        btnNoEvent={() => onCloseModal()}
        btnYesEvent={() => {
          if (selectedServices.length > 0) {
            onSaveServices(selectedServices);
            onCloseModal();
          }
        }}
      >
        {(offeredServicesLoader || serviceTypesLoader) && loader}
        <div className='row align-items-end'>
          <div className='col-lg-4'>
            <label className={`${tableStyles.ordDarkBlueText} fw-bold`}>Tipo de servicio</label>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={serviceTypesOptions}
              className='text-secondary '
              placeholder='Seleccionar...'
              styles={ordCustomSelect}
              onChange={option => setServiceType(option)}
            />
          </div>
          <div className='col-lg-8'>
            <input
              type='text'
              className='ord-roundInput w-100'
              placeholder='Escribe...'
              onChange={({ target }) => setSearchValue(target.value)}
            />
          </div>
        </div>

        <OrdTable
          headers={renderTableHeaders()}
          className={`${tableStyles.shade}`}
          hasChildren
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        >
          {offeredServicesOptions[currentPage - 1]?.length ? (
            offeredServicesOptions[currentPage - 1]?.map(service => (
              <tr key={service.id}>
                <td width={40} className='px-2'>
                  <input
                    type='checkbox'
                    className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
                    checked={selectedServices.some(serv => serv.id === service.id)}
                    onChange={() => onCheckService(service)}
                  />
                </td>
                <td className='text-start px-2'>{service.serviceType}</td>
                <td className='text-start px-2'>
                  <div title={service.name} className='text-truncate' style={{ width: 350 }}>
                    {service.name}
                  </div>
                </td>
                <td className='px-2'>{service.ctypeName || '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className='text-center' colSpan={4}>
                No hay información para mostrar
              </td>
            </tr>
          )}
        </OrdTable>
        <Pagination
          className
          activePage={currentPage}
          itemsCountPerPage={5}
          totalItemsCount={offeredServicesOptions?.flatMap(list => list).length}
          pageRangeDisplayed={3}
          onChange={page => {
            setCurrentPage(page);
          }}
          itemClassPrev={stylePagination.itemClassPrev}
          itemClassNext={stylePagination.itemClassNext}
          itemClassFirst={stylePagination.itemClassFirst}
          itemClassLast={stylePagination.itemClassLast}
          itemClass={stylePagination.itemClass}
        />
        <br />
      </OrdModal>
    );
  };

  return renderComponent();
}
