import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import blueShoppingCar from '../../assets/img/icons/blueShoppingCar.svg';
import costCenterIcon from '../../assets/img/icons/costCenterIcon.svg';
import dontaion from '../../assets/img/icons/dontaion.svg';
import Consum from '../../assets/img/icons/Consum.svg';
import entryAdjusment from '../../assets/img/icons/entryAdjusment.svg';
import outPutSettings from '../../assets/img/icons/outPutSettings.svg';
import storageMove from '../../assets/img/icons/storageMove.svg';
import leftDonation from '../../assets/img/icons/leftDonation.svg';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export const InventoryTable = () => {
  let localStyles = {
    grayText: {
      color: 'gray',
    },
    centerAndJustify: {
      textAlign: 'center',
      margin: '0 auto',
      padding: '10px 0px 5px 0px',
      width: '100%',
      height: '3rem',
    },
  };
  let history = useHistory();
  const [idSel, setIdSel] = useState('');

  const showBottomTooltip = e => {
    setTimeout(() => {
      if (e.target.id !== '') {
        switch (e.target.id) {
          case '1':
            setIdSel('1');
            break;
          case '2':
            setIdSel('2');
            break;
          case '3':
            setIdSel('3');
            break;
          case '4':
            setIdSel('4');
            break;
          case '5':
            setIdSel('5');
            break;
          case '6':
            setIdSel('6');
            break;
          case '7':
            setIdSel('7');
            break;
          case '8':
            setIdSel('8');
            break;

          default:
            setIdSel('');
            break;
        }
      }
    }, 1000);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <h1 className={`${tableStyles.title} `} style={{ marginLeft: '4%' }}>
          Registrar movimiento
        </h1>
        <Row className=' d-flex'>
          <Col
            className={`${tableStyles.darkGrayText} ${tableStyles.horizontalBottomLine} pt-3 `}
            xs={12}
          >
            <p
              className={`fzXLarger pt-2`}
              style={{ borderBottom: '1px solid #00000033', color: 'gray' }}
            >
              Entradas
            </p>
          </Col>
          <Col xs={4}>
            <div
              className='bottonn cursorPointer'
              onClick={() => history.push('/compras/entradaPorCompra')}
              name='testName'
              style={{ width: '75%', borderRadius: '20px' }}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={blueShoppingCar} alt='' />
                <div className='borhov'>
                  <p
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    id='1'
                    onMouseEnter={id => showBottomTooltip(id)}
                  >
                    Entrada por compra
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              className='bottonn cursorPointer'
              style={{ width: '75%', borderRadius: '20px' }}
              onClick={() => history.push('/compras/entradaPorConsignacion')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={costCenterIcon} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='2'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} `}
                  >
                    Entrada por consignación
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              className='bottonn cursorPointer'
              style={{ width: '75%', borderRadius: '20px' }}
              onClick={() => history.push('/compras/entradaPorDonacion')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={dontaion} alt='' />
                <div className='borhov'>
                  <p
                    className={`${tableStyles.darkGrayText} texto-`}
                    id='3'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    onMouseEnter={id => showBottomTooltip(id)}
                  >
                    Entrada por donación
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col
            className={`${tableStyles.darkGrayText} ${tableStyles.horizontalBottomLine} pt-3 `}
            xs={12}
          >
            <p
              className={`fzXLarger pt-2`}
              style={{ borderBottom: '1px solid #00000033', color: 'gray' }}
            >
              Movimientos internos
            </p>
          </Col>
          <Col xs={4}>
            <div
              className='bottonn cursorPointer'
              style={{ width: '75%', borderRadius: '20px' }}
              onClick={() => history.push('/compras/salidaconsumo')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={Consum} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='4'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} texto-`}
                  >
                    Salida por consumo
                  </p>
                </div>
                {idSel === '4' && (
                  <small className={`${tableStyles.fluorBlue} fzXsmall`}>{/* El id es 4 */}</small>
                )}
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              style={{ width: '75%', borderRadius: '20px' }}
              className='bottonn cursorPointer'
              onClick={() => history.push('/compras/ajusteDeEntrada')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={entryAdjusment} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='5'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} texto-`}
                  >
                    Ajuste de entrada
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              style={{ width: '75%', borderRadius: '20px' }}
              className='bottonn cursorPointer'
              onClick={() => history.push('/compras/ajusteDeSalida')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={outPutSettings} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='6'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} texto-`}
                  >
                    Ajuste de salida
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              style={{ width: '75%', borderRadius: '20px' }}
              className='bottonn cursorPointer'
              onClick={() => history.push('/compras/trasladoDeAlmacen')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={storageMove} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='7'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} texto-`}
                  >
                    Traslado de almacén
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4}>
            <div
              style={{ width: '75%', borderRadius: '20px' }}
              className='bottonn cursorPointer'
              onClick={() => history.push('/compras/Donacion')}
            >
              <div className='col-auto text-center'>
                <img className='hovimg' src={leftDonation} alt='' />
                <div className='borhov'>
                  <p
                    onMouseEnter={id => showBottomTooltip(id)}
                    id='8'
                    style={(localStyles.centerAndJustify, localStyles.grayText)}
                    className={`${tableStyles.darkGrayText} texto- pt-2 pb-2`}
                  >
                    Donación
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
