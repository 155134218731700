import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_NOTE_TYPES, GET_NOTE_DETAILS, LOADING } from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getNotesTypes = params => (dispatch, getState) => {
  dispatch({ type: LOADING, payload: true });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({ type: LOADING, payload: false });
      if (res.success) {
        dispatch({
          type: GET_NOTE_TYPES,
          payload: res.results,
          row_total: res.row_count,
        });
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Ocurrió un error al traer los tipos de notas`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    });
};

export const createNote = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const eaccount = getState().loginReducer.currentAccount.id;
  if (!data.name)
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar una descripción',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    });
  if (!data.nature)
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar un tipo de nota',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    });
  if (!data.niif)
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar una cuenta niif',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    });
  if (!data.category)
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar un módulo',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    });
  data.eaccount = eaccount;
  dispatch({ type: LOADING, payload: true });
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({ type: LOADING, payload: false });
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado la nota: ${data.name}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        if (doAfter) doAfter();
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `ocurrió un error al crear la nota`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    });
};

export const getNoteDetails = (id_note, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const currentAccount = getState().loginReducer.currentAccount.id;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/?id=${id_note}&eaccount=${currentAccount}`,
    {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    },
  )
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: GET_NOTE_DETAILS,
          payload: res.results,
        });
        if (doAfter) {
          doAfter(res.results);
        }
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    })
    .catch(() => {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer el detalle de la nota',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    });
};

export const editNoteType = (data, doAfter) => (dispatch, getState) => {
  dispatch({ type: LOADING, payload: true });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => {
      dispatch({ type: LOADING, payload: false });
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizada exitosamente',
          text: `Se actualizo la nota ${data?.id} exitosamente`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        if (doAfter) doAfter();
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al actualizar el tipo de nota',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    });
};
export const changeStatusNoteType = (data, doAfter) => (dispatch, getState) => {
  dispatch({ type: LOADING, payload: true });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/changeStatus/`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  })
    .then(response => response.json())
    .then(res => {
      dispatch({ type: LOADING, payload: false });
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ${data?.status === 'enabled' ? 'deshabilitó' : 'habilitó'} el tipo de nota: ${data?.name}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
        if (doAfter) doAfter();
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al actualizar el tipo de nota',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    });
};
