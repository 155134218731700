import { Box } from '@mui/material';
import styled from 'styled-components';

const StyledText = styled.p`
  color: ${({ color, theme }) => (color ? color : theme.colors.ordDarkBlueText)};
  font-size: ${({ fontSize, theme }) => (fontSize ? fontSize : theme.sizes.title.sx)};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'lighter')};
  order: ${({ order }) => (order ? order : '0')};
  display: ${({ display }) => (display ? display : 'inline-flex')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'auto')};
  transition: all 0.3s;
  /* :hover{
        color: ${({ color, opacity, theme }) => (opacity ? color : theme.colors.ordDarkBlueText)};
    } */
`;

export const Text = ({
  pointer,
  title,
  color,
  fontSize,
  padding,
  margin,
  fontWeight,
  iconPath,
  iconAlt,
  iconHeight,
  iconWidth,
  iconPosition,
  textPosition,
  handleClick,
  classNameImg,
  classNameBox,
  textAlign,
  classText,
  required,
}) => {
  return (
    <Box
      className={classNameBox}
      display={'flex'}
      alignItems='center'
      columnGap='10px'
      sx={pointer && { cursor: 'pointer' }}
      onClick={handleClick}
      textAlign={textAlign}
    >
      <StyledText
        color={color}
        fontSize={fontSize}
        padding={padding}
        margin={margin}
        fontWeight={fontWeight}
        order={textPosition}
        className={classText}
      >
        {title}

        {required && <span style={{ color: '#f39682' }}>*</span>}
      </StyledText>
      {iconPath && (
        <img
          order={iconPosition}
          src={iconPath}
          alt={iconAlt}
          height={iconHeight}
          width={iconWidth}
          style={{ order: { iconPosition } }}
          className={`${classNameImg} pointer`}
        />
      )}
    </Box>
  );
};
