import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import showDedu from '../../assets/img/icons/addFile.svg';
import lupa from '../../assets/img/icons/auctionDetailLupa2.svg';

import { getDeductionList, getDiscountsAndPayouts } from '../../actions/partnersActions';
import { convertMoneyFormat } from '../../helpers/helpers';

import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import GenericTableNew from '../Layouts/GenericTableNew';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import ModalNew from '../Layouts/ModalNew';

import tableStyles from '../Layouts/tableStyle.module.scss';

export const DiscountsAndPayout = ({ idCandidate }) => {
  const store = useSelector(state => state);
  const idEnterprise = store.loginReducer?.currentAccount?.id;
  const current = store.partnersReducer?.discountAndpayOuts?.current;
  const settled = store.partnersReducer?.discountAndpayOuts?.settled;
  const [showPaymentDetail, setShowPaymentDetail] = useState({
    show: false,
    detailsPayment: {},
  });

  const listPaymentDetail = () => {
    let elemMap;
    if (showPaymentDetail?.detailsPayment?.details_payments !== undefined) {
      const elem2 = showPaymentDetail?.detailsPayment?.details_payments;
      elemMap = elem2.map(elem => {
        return renderPaymentDetail(elem);
      });
    }
    return elemMap;
  };

  const renderPaymentDetail = elem => {
    const myDate = moment(elem.Fecha, 'DD/MM-YYYY').format('YYYY/MM/DD');

    return (
      <tr key={elem.code}>
        <td className='col-md-2 text-center'>{elem.number}</td>
        <td className='col-md-4 text-center'>{myDate}</td>
        <td className='col-md-2 text-center'>
          {convertMoneyFormat(
            showPaymentDetail?.detailsPayment?.fee_value
              ? showPaymentDetail?.detailsPayment?.fee_value
              : '0',
          )}
        </td>
      </tr>
    );
  };

  const paymentDetailsHeaders = [
    <th style={{ borderTopLeftRadius: '10px', textAlign: 'center' }} key={`Hash`}>
      #
    </th>,
    <th key={`Date`} style={{ textAlign: 'center' }}>
      Fecha
    </th>,
    <th style={{ borderTopRightRadius: '10px' }} key={`ValueDetailP`}>
      Valor cuotas
    </th>,
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeductionList({ type: 1, entity_account: idEnterprise }));
    dispatch(
      getDiscountsAndPayouts({
        employee: idCandidate,
        entity_account: idEnterprise,
      }),
    );
  }, [dispatch, idCandidate, idEnterprise]);

  const [showDetailPayment, setShowDetailPayment] = useState({
    show: false,
    fileUrl: '',
  });

  const closeShowDetailPayment = () => {
    setShowDetailPayment({ ...showDetailPayment, show: false, fileUrl: '' });
  };

  const [showDeduDetails, setShowDeduDetails] = useState({
    show: false,
    detailsPayment: {},
  });

  const closeShowDeduDetails = () => {
    setShowDeduDetails({ ...showDeduDetails, show: false });
  };

  const deduDetailHeaders = [
    <div style={{ borderTopLeftRadius: '10px' }} className='px-2 text-center col-5' key={`Hash`}>
      No.
    </div>,
    <div style={{}} className='px-2 text-center col-5' key={`Hash`}>
      Fecha
    </div>,
    <div style={{ borderTopRightRadius: '10px ' }} key={`ValueDetailP`} className='col-2'>
      &nbsp;
    </div>,
  ];

  const listDeduDetail = () => {
    let elemMap;
    if (showDeduDetails?.detailsPayment?.length > 0) {
      const elem2 = showDeduDetails?.detailsPayment;
      elemMap = elem2.map(elem => {
        return (
          <section2 key={elem.code} className={`d-flex`}>
            <div className='col-md-5 text-center'>{elem.number}</div>
            <div className='col-md-5 text-center'>{elem.Fecha}</div>
            <div className='col-md-2 text-center'>
              <img
                alt='icon'
                src={lupa}
                className={`${tableStyles.svgDarkBlueFilter} cursorPointer`}
                onClick={() => {
                  setShowDetailPayment({
                    ...showDetailPayment,
                    show: true,
                    fileUrl: elem?.support_file ?? 'showMessage',
                    name: `Pago No. ${elem.number}`,
                  });
                }}
              />
            </div>
          </section2>
        );
      });
    }
    return elemMap;
  };

  return (
    <div>
      <ModalNew
        size='400'
        title='Detalle de deducción'
        show={showDeduDetails?.show}
        onHide={() => closeShowDeduDetails()}
      >
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <GenericTableScroll
            headers={deduDetailHeaders}
            dark={true}
            body={listDeduDetail()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>
      </ModalNew>

      <ModalNew
        title={'Detalle del pago'}
        show={showDetailPayment?.show}
        btnNoName={'Cancelar'}
        size={1200}
        onHide={() => closeShowDetailPayment()}
        btnNoEvent={() => closeShowDetailPayment()}
      >
        <div className='mb-4 d-flex justify-center'>
          {showDetailPayment?.fileUrl === 'showMessage' ? (
            <div
              style={{ width: '100%' }}
              className='d-flex align-self-center text-center justify-content-center p-5'
            >
              {' '}
              <span
                className={`${tableStyles.ordClearGrayText} ${tableStyles.f14}
              align-self-center
              `}
              >
                No hay anexo disponible
              </span>
            </div>
          ) : (
            <RenderMedicalFormulasPdf
              fileUrl={showDetailPayment.fileUrl}
              title={showDetailPayment?.name}
              goBack={true}
              goBackSmallDelete={true}
              backAction={() => {
                closeShowDetailPayment();
              }}
            />
          )}
        </div>
      </ModalNew>

      {/* /* ------------------------------ PAYMENT MODAL ----------------------------- */}
      <ModalNew
        size='540'
        title='Detalle de pagos'
        subtitle={showPaymentDetail?.detailsPayment?.third}
        show={showPaymentDetail?.show}
        onHide={() => setShowPaymentDetail(false)}
      >
        <Row className={`${tableStyles.bgColorClearBlue}`}>
          <Col xs={2}>
            <p className={`${tableStyles.crudModalLabel} `}>No. de cuotas</p>
            <input
              disabled={true}
              value={showPaymentDetail?.detailsPayment?.installments}
              type='text'
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>Valor cuotas</p>
            <input
              disabled={true}
              type='text'
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.fee_value
                  ? showPaymentDetail?.detailsPayment?.fee_value
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Total adeudado 2022</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.total
                  ? showPaymentDetail?.detailsPayment?.total
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Saldo a la fecha</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.balance_date
                  ? showPaymentDetail?.detailsPayment?.balance_date
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Descontado a la fecha</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.discounted_date
                  ? showPaymentDetail?.detailsPayment?.discounted_date
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
        </Row>
        <GenericTableNew headers={paymentDetailsHeaders}>{listPaymentDetail()}</GenericTableNew>
      </ModalNew>

      <Row>
        <Col className={`mt-4 ${tableStyles.scrollThis}`} xs={6}>
          <h5 className={tableStyles.darkBlueText}>
            <b>Descuentos y libranzas vigentes</b>
          </h5>

          <Row className='mb-5'>
            <Col xs={12}>
              <table
                style={{
                  fontFamily: ' PT Sans ',
                }}
              >
                <thead>
                  <tr>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Fecha inicio
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tipo
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tercero
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Valor cuota
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Cantidad
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {current?.length > 0 &&
                    current.map((e, i) => {
                      return (
                        <tr key={i}>
                          <th
                            onClick={() =>
                              setShowPaymentDetail({
                                show: true,
                                detailsPayment: e,
                              })
                            }
                            scope='row'
                            className={`${tableStyles.darkBlueText} cursorPointer`}
                          >
                            {e.start_date}
                          </th>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.type}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.third}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            {convertMoneyFormat(e.fee_value && e.fee_value)}
                          </td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.count}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
          <h5 className={tableStyles.darkBlueText}>
            <b>Historial de deducciones</b>
          </h5>
          <Row>
            <Col xs={12}>
              <table
                style={{
                  fontFamily: ' PT Sans ',
                }}
              >
                <thead>
                  <tr>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Fecha
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tipo
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tercero
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Valor
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {settled?.length > 0 &&
                    settled.map((e, i) => {
                      return (
                        <tr key={i}>
                          <th
                            onClick={() =>
                              setShowPaymentDetail({
                                show: true,
                                detailsPayment: e,
                              })
                            }
                            scope='row'
                            className={`${tableStyles.darkBlueText} cursorPointer`}
                          >
                            {e.start_date}
                          </th>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.type}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.third}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            {convertMoneyFormat(e.fee_value && e.fee_value)}
                          </td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            <img
                              alt='icon'
                              src={showDedu}
                              className={`cursorPointer ${tableStyles.svgDarkBlueFilter}`}
                              onClick={() => {
                                setShowDeduDetails({
                                  ...showDeduDetails,
                                  show: true,
                                  detailsPayment: e.details_payments,
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
