import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import file from '../../../assets/img/icons/AdjuntarRecto.svg';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlert, loader, onChangeFile } from '../../../helpers';
import { usePostMethod } from '../../../Hooks';

export default function CreatePreDownload({ isOpen, onClose, onSuccess }) {
  const storage = useSelector(state => state);

  const [payload, setPayload] = useState({
    fileName: null,
    fileNameSupport: null,
    support: '',
    base64: '',
    docType: '',
    eaccount: storage.loginReducer.currentAccount.id,
    created_by: storage.loginReducer.user_data.id,
  });

  const { load: statusLoader, trigger: submitFile } = usePostMethod();

  const onCloseModal = () => {
    onClose();
    setPayload({
      fileName: null,
      base64: '',
      docType: '',
      eaccount: storage.loginReducer.currentAccount.id,
      created_by: storage.loginReducer.user_data.id,
    });
  };

  const onChangeAttach = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 5 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 5MB.',
        confirmButtonText: 'Aceptar',
      });
      setPayload({
        ...payload,
        base64: '',
        fileName: null,
      });
      e.target.value = null;
      return;
    }

    setPayload({
      ...payload,
      base64: file.file,
      fileName: file.fileName,
    });
  };

  const onChangeSupport = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 5 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 5MB.',
        confirmButtonText: 'Aceptar',
      });
      setPayload({
        ...payload,
        support: '',
        docType: '',
        fileNameSupport: null,
      });
      e.target.value = null;
      return;
    }

    setPayload({
      ...payload,
      support: file.file,
      docType: file.typeFile,
      fileNameSupport: file.fileName,
    });
  };

  const onSubmit = () => {
    submitFile({
      token: storage.loginReducer.Authorization,
      body: payload,
      method: 'POST',
      url: '/receivable/preDownload/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Creado exitosamente',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            onCloseModal();
            onSuccess();
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const render = () => {
    return (
      <>
        {statusLoader && loader}

        <Modal
          show={isOpen}
          centered
          className={modalStyles.container}
          dialogClassName={modalStyles.modal_width_md}
          onHide={onCloseModal}
        >
          <div className='d-flex align-items-center justify-content-between py-3 px-4'>
            <div></div>
            <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
              Crear pre-descarga
            </h3>
            <Close className='pointer text-secondary' onClick={onCloseModal} />
          </div>
          <hr className='m-0' />
          <div className='p-4'>
            <p>
              Adjunta tu plantilla de Excel previamente descargada para una integración preciosa.
              Recomendamos usar la <span className='text-primary'>plantilla oficial</span>. Si
              alguno de los valores de recaudo que importes ya se encuentran registrados en el
              sistema, se actualizará automáticamente.
            </p>
            <div className='row'>
              <div className='col'>
                <small className='text-primary ms-1'>
                  Adjuntar plantilla
                  <span className='text-danger'>*</span>
                </small>
                <label className='register-inputs d-flex align-items-center pointer overflow-hidden'>
                  <span
                    className={`flex-grow-1 ${tableStyles.elipsis}`}
                    style={{ maxWidth: '100%' }}
                  >
                    {payload.fileName ?? 'Cargar...'}
                  </span>
                  <input
                    type='file'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    className='d-none'
                    onChange={onChangeAttach}
                  />
                  <img src={file} alt='agregar archivo' className={tableStyles.iconFileStyle} />
                </label>
              </div>
              <div className='col'>
                <small className='text-primary ms-1'>
                  Adjuntar anexo
                  <span className='text-danger'>*</span>
                </small>
                <label className='register-inputs d-flex align-items-center pointer overflow-hidden'>
                  <span
                    className={`flex-grow-1 ${tableStyles.elipsis}`}
                    style={{ maxWidth: '100%' }}
                  >
                    {payload.fileNameSupport ?? 'Cargar...'}
                  </span>
                  <input
                    type='file'
                    accept='.pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    className='d-none'
                    onChange={onChangeSupport}
                  />
                  <img src={file} alt='agregar archivo' className={tableStyles.iconFileStyle} />
                </label>
              </div>
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button onClick={onCloseModal} className={`${tableStyles.btnSecondary} me-3`}>
                Cancelar
              </button>
              <button className={`${tableStyles.btnPrimaryAlt}`} onClick={onSubmit}>
                Guardar
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return render();
}
