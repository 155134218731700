//User's reducer

import {
  GET_CON_CENTER_ONE,
  GET_CON_CENTER,
  SET_INDEX_CON_CENTER,
  GET_AUX_NIIF,
  LOADING,
} from "../actions/actionTypes";

const initialState = {
  consumption_center: [],
  totalConsumption: 0,
  auxNiif: [],
  displayError: false,
  error: null,
  token: "",
  chosen_cc: {},
  loading: false,
  loading_cc: false,
};
export const consumptionCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CON_CENTER:
      return {
        ...state,
        consumption_center: action.payload,
        token: action.token,
        loading_cc: action.loading
      };

    case GET_CON_CENTER_ONE:
      return {
        ...state,
        chosen_cc: action.payload,
      };
    case SET_INDEX_CON_CENTER:
      return {
        ...state,
        totalConsumption: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };
    case GET_AUX_NIIF:
      return {
        ...state,
        auxNiif: action.payload,
        displayError: false,
        error: null,
        token: action.token,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};
