// hooks
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';

// styles
import 'react-datepicker/dist/react-datepicker.css';

// component
import NumberFormat from "react-number-format";
import { AssignFeesComponent } from "./AssignFeesComponent";

export const AssignFees = () => {

    const MAX_LIMIT = 100;
    const history = useHistory();
    const store = useSelector(state => state);
    const token = store.loginReducer.Authorization;

    const { idContract, idDoctor } = useParams();

    const [filters, setFilters] = useState({
        page: 1,
        perpage: 10,
        userMd: idDoctor,
        companyType: undefined,
        idContractMd: idContract
    });

    const [payloadUpdate, setPayloadUpdate] = useState({
        idUserMd: Number(idDoctor),
        companyType: undefined
    });

    const [listServices, setListServices] = useState(undefined);

    const header = [
        {
            title: 'Servicio',
            className: 'col-10 text-start px-3',
        },
        {
            title: 'Porcentaje',
            className: 'col-2 text-center px-3',
        },
    ];

    const {
        results: listCompanyTypes,
        trigger: getListCompanyTypes,
        load: loadListCompanys,
    } = useGetMethod();

    const {
        load: loadUpdateService,
        trigger: updateService
    } = usePostMethod();

    const {
        load: loadListServices,
        trigger: getListServices
    } = useGetMethod();

    const options = listCompanyTypes?.results?.map(({ id, name, tag }) => ({
        value: tag,
        label: name,
        id,
    }));

    const handleChangeSelect = (e) => {
        if (e.id) {
            getListServices({
                token,
                url: '/medical/doctor/serviceTimesPerDoctor/',
                objFilters: {
                    userMd: Number(filters.userMd),
                    companyType: e.id,
                    idContractMd: Number(filters.idContractMd),
                    page: filters.page,
                    perpage: filters.perpage
                }
            }).then(res => {
                if (res.success) {
                    setListServices(res);
                    formatData(res?.results);
                }
            });

            setFilters(state => ({
                ...state,
                companyType: e.id
            }));

            setPayloadUpdate(state => ({
                ...state,
                companyType: e.id
            }));
        }
    };

    const goToBack = () => history.push('/cuentasMedicas/Contratos');

    const OnUpdateService = ({ feesPercentage, idService }) => {
        updateService({
            url: '/medical/doctor/fees-percentages/',
            token: token,
            method: 'PUT',
            noAlert: true,
            body: { ...payloadUpdate, feesPercentage, idService }
        });
    };

    const onChangeValue = ({ feesPercentage, idService }) => {
        const formatData = listServices?.results?.map(service => {
            if (service.idService === idService) {
                return { ...service, feesPercentage: feesPercentage };
            }

            return service;
        });

        setListServices(state => ({ ...state, results: formatData }));
    };

    const formatData = array => {
        let tempList = [];
        if (Array.isArray(array)) {
            array.forEach((item) => {
                tempList.push(
                    <tr key={item.idService} className={`hover-table-row`}>
                        <td className='text-start px-3'>
                            {item?.name || '-'}
                        </td>
                        <td className='text-center px-3'>
                            <NumberFormat
                                suffix='%'
                                decimalScale={1}
                                decimalSeparator='.'
                                allowNegative={false}
                                value={String(item?.feesPercentage) || ''}
                                className='register-inputs text-center'
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    const newValue = floatValue !== undefined ? floatValue : -1;
                                    return newValue <= MAX_LIMIT;
                                }}
                                onValueChange={(values) => {
                                    const { floatValue } = values;
                                    const newValue = floatValue;
                                    onChangeValue({ feesPercentage: newValue, idService: item.idService });
                                }}
                                onBlur={(e) => {
                                    const formatValue = e.target.value.replaceAll('%', '');
                                    OnUpdateService({ feesPercentage: Number(formatValue), idService: item.idService });
                                    if (Number(formatValue) === 0) onChangeValue({ feesPercentage: 0, idService: item.idService });
                                }}

                            />
                        </td>
                    </tr>,
                );
            });
        }
        return tempList;
    };

    useEffect(() => {
        getListCompanyTypes({
            token,
            url: '/medical/generals/companyTypes/',
        });
    }, [getListCompanyTypes, token]);

    return (
        <AssignFeesComponent
            actions={{ goToBack, handleChangeSelect }}
            data={{ MAX_LIMIT, options, header, formatData, listServices }}
            loadings={{ loadUpdateService, loadListServices, loadListCompanys }}
        />
    );
};
