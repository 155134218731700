import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Highcharts from 'highcharts';
import Chart from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';
import HighchartsReact from 'highcharts-react-official';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import topArrow from '../../assets/img/icons/topArrow.svg';

import { generateId, month, year } from '../../helpers/helpers';
import {
  donutOptions,
  higChartMultiVerticalOneLine,
  highChartHorizontalLine,
  singleLineOptions,
} from '../../helpers/constants';
import {
  getBillingByType,
  getCapita,
  getConsum,
  getGlossesDetail,
} from '../../actions/managementActions';

import tableStyles from '../Layouts/tableStyle.module.scss';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

const getFivePreviousYear = () => {
  let array = [];
  let current_year = year();
  for (let step = 0; step <= 5; step++) {
    array.push(current_year - step);
  }
  return array;
};
export const Billing = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'billng',
  );
  let capitaAndFreeList = store?.managementReducer?.capitaAndFreeList;
  let glossesDetail = store?.managementReducer?.glossesDetail;
  let billingByType = store?.managementReducer?.billingByType;

  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
  });
  const [filtersGloss, setFiltersGloss] = useState({
    month: month(),
    year: year(),
  });
  const [showButton, setShowButton] = useState(false);

  let formatter = new Intl.NumberFormat(APP_LANGUAGE, {
    style: 'currency',
    currency: CURRENCY,
  });

  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 15000);
  }, [showButton]);

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/gerencia/inicio');
    }

    dispatch(
      getConsum({
        month: filters.month,
        year: filters.year,
        account: store.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getCapita({
        account: store.loginReducer.currentAccount.id,
        year: filters.year,
        month: filters.month,
      }),
    );
    dispatch(
      getBillingByType({
        entity_account: store.loginReducer.currentAccount.id,
        year: filters.year,
        month: filters.month,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    dispatch(
      getGlossesDetail({
        account: store.loginReducer.currentAccount.id,
        year: filtersGloss.year,
        month: filtersGloss.month,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersGloss]);

  return (
    <>
      {showButton && (
        <a
          width='25px'
          className={`${tableStyles.noTextDecoration} ${tableStyles.backToTop} p-3 center`}
          href='#home'
        >
          <img className={`center`} src={topArrow} alt='' />
        </a>
      )}
      <div
        className={`${tableStyles.container}`}
        style={{ overflowX: 'hidden' }}
        onScroll={() => setShowButton(true)}
      >
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={10} className={``} id='home'>
              <h1 className={tableStyles.title}>Indicadores de facturación</h1>
            </Col>
            <Col
              xs={2}
              className={`text-end d-flex justify-content-between`}
              style={{ paddingTop: '5rem' }}
            >
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Billing'>
                  <b>Facturación</b>
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Gloss'>
                  <b>Glosas</b>{' '}
                </a>
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row className='justify-content-between mb-2' id='Billing'>
            <Col xs={3} className=' text-start'>
              <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                <b>Facturación</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filters.month}
                onChange={e => setFilters({ ...filters, month: Number(e.target.value) })}
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>

              <select
                value={filters.year}
                onChange={e => setFilters({ ...filters, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map(e => {
                  return (
                    <option key={generateId()} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={8}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`text-start `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Facturación según el tipo de factura</b>

                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartHorizontalLine({
                          series: billingByType?.data?.length > 0 ? billingByType.data : [],
                          categories: ['Tipo de Factura'],
                        })}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={2} className='p-0 m-0'>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>Facturado capita por empresa</b>
                  </p>
                  <div className={`${tableStyles.scrollThis}`}>
                    {capitaAndFreeList?.final_capita?.length > 0 ? (
                      capitaAndFreeList?.final_capita?.map((e, i) => {
                        return (
                          <>
                            <Row>
                              <Col xs={6}>
                                {' '}
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                                >
                                  <span className='mr-1 text-info'>{i + 1}</span>
                                  {e.copyName}
                                </span>
                              </Col>
                              <Col xs={6}>
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                                >
                                  {formatter.format(e.total)}
                                </span>
                              </Col>
                            </Row>
                          </>
                        );
                      })
                    ) : (
                      <h6 className={`${tableStyles.darkGrayText} mt-5`}> Sin información</h6>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={2} className='p-0 m-0'>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>Factura libre por empresa</b>
                  </p>
                  <div className={`${tableStyles.scrollThis}`}>
                    {capitaAndFreeList?.final_libre?.length > 0 ? (
                      capitaAndFreeList?.final_libre?.map((e, i) => {
                        return (
                          <>
                            <Row>
                              <Col xs={6}>
                                {' '}
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                                >
                                  <span className='mr-1 text-info'>{i + 1}</span>
                                  {e.copyName}
                                </span>
                              </Col>
                              <Col xs={6}>
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.fw700End}`}
                                >
                                  {formatter.format(e.total)}
                                </span>
                              </Col>
                            </Row>
                          </>
                        );
                      })
                    ) : (
                      <h6 className={`${tableStyles.darkGrayText} mt-5`}> Sin información</h6>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            {/* /* ---------------------------- Billing behavior ---------------------------- */}
            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Comportamiento de lo facturado</b>
                    </p>
                    <Chart
                      options={singleLineOptions({
                        leftTitle: ' Días',
                        bottomTitle: 'Meses',
                        categories:
                          capitaAndFreeList?.labels?.length > 0 ? capitaAndFreeList?.labels : [],
                      })}
                      series={
                        capitaAndFreeList?.final_behaviour?.length > 0
                          ? capitaAndFreeList?.final_behaviour
                          : []
                      }
                      type='line'
                      height={450}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* /* ---------------------------- GLOSSES SECTION --------------------------- */}
          <hr></hr>
          <Row className='justify-content-between mb-2' id='Gloss'>
            <Col xs={3} className=' text-start'>
              <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                <b>Glosas</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filtersGloss.month}
                onChange={e =>
                  setFiltersGloss({
                    ...filtersGloss,
                    month: Number(e.target.value),
                  })
                }
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>

              <select
                value={filtersGloss.year}
                onChange={e =>
                  setFiltersGloss({
                    ...filtersGloss,
                    year: Number(e.target.value),
                  })
                }
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map(e => {
                  return (
                    <option key={generateId()} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={4}>
              <div className={`${tableStyles.cardBoxContainer}`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Total facturado</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {formatter.format(glossesDetail.monto_total)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Monto de glosas registradas</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {formatter.format(glossesDetail.glosas_registradas)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={2}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div title='Glosas registradas'>
                    <p className={`text-secondary ${tableStyles.ellipsis}`}>Glosas registradas</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {glossesDetail.monto_registrado}%
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={2}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Glosas vencidas</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {glossesDetail.glosas_vencidas}%
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={4}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Glosas gestionadas vs glosas pendientes</b>
                    </p>
                    {glossesDetail?.labels_gestionadas_vs_pendientes?.length > 0 > 0 &&
                    glossesDetail?.data_gestionadas_vs_pendientes?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          legendPosition: 'bottom',
                          labels:
                            glossesDetail?.labels_gestionadas_vs_pendientes?.length > 0
                              ? glossesDetail?.labels_gestionadas_vs_pendientes
                              : [],
                        })}
                        series={
                          glossesDetail?.data_gestionadas_vs_pendientes?.length > 0
                            ? glossesDetail?.data_gestionadas_vs_pendientes
                            : []
                        }
                        type='donut'
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                        height={900}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Glosas según concepto general</b>
                    </p>
                    {glossesDetail?.labels_segun_concepto?.length > 0 &&
                    glossesDetail?.data_segun_concepto?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          legendPosition: 'bottom',
                          labels:
                            glossesDetail?.labels_segun_concepto?.length > 0
                              ? glossesDetail?.labels_segun_concepto
                              : [],
                        })}
                        series={
                          glossesDetail?.data_segun_concepto?.length > 0
                            ? glossesDetail?.data_segun_concepto
                            : []
                        }
                        type='donut'
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                        height={900}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={8}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Comportamiento de facturas glosadas</b>
                    </p>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={higChartMultiVerticalOneLine({
                        leftTitle: '&nbsp',
                        series:
                          glossesDetail?.comportamiento_glosas?.length > 0
                            ? glossesDetail?.comportamiento_glosas
                            : [],
                        categories:
                          glossesDetail?.labels_comportamiento?.length > 0
                            ? glossesDetail?.labels_comportamiento
                            : [],
                        height: 536,
                      })}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>{' '}
        </div>
      </div>
    </>
  );
};
