import { customSwaltAlert } from '../helpers';
import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_ENTERPRISE_LIST, GET_BILLING_SITES, GET_INVOICING_MINUTES } from './actionTypes';

export const getEnterpriseList = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/receivable/client/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_ENTERPRISE_LIST,
        payload: res.results,
      });
    })
    .catch(error => {
      console.error(error.message);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error?.message,
        showCancelButton: false,
      });
    });
};
export const getBillingSites = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/site/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_BILLING_SITES,
        payload: res.results,
      });
    })
    .catch(error => {
      console.error(error.message);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error?.message,
        showCancelButton: false,
      });
    });
};
export const getAllMinutes = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_INVOICING_MINUTES,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/invoicing/receiptRecord/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.results?.length < 1) {
        dispatch({
          type: GET_INVOICING_MINUTES,
          payload: res.results,
          rowCount: Number(res.row_count),
          loading: false,
        });
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Sin información',
          showCancelButton: false,
        });
      }
      dispatch({
        type: GET_INVOICING_MINUTES,
        payload: res.results,
        rowCount: Number(res.row_count),
        loading: false,
      });
    })
    .catch(error => {
      dispatch({
        type: GET_INVOICING_MINUTES,
        payload: [],
        rowCount: 0,
        loading: false,
      });
      console.error(error.message);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error?.message,
        showCancelButton: false,
      });
    });
};

export const generateNewRip = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/receivable/rips/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let results = await query.json();

    if (results?.statusCode === 400) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: results?.message,
        showCancelButton: false,
      });
    }
    if (results.success) {
      return results;
    }
  } catch (error) {
    console.error(error.message);
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: error?.message,
      showCancelButton: false,
    });
  }
};
