import { Box, Drawer } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import closeOrange from '../../../assets/img/icons/closeOrange.svg';
import add from '../../../assets/img/icons/orangeNoBGArrow.svg';
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import PopUp from '../../../components/Popup/customPopUpExtends';
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../../helpers';

export default function SuppliesTypes() {
  const [tabLabel, setTabLabel] = useState({ id: '', prefix: '' });
  const store = useSelector(state => state);
  const headersDetail = [
    { title: 'Familia de inventario', className: 'col-7 text-start px-2 f12' },
    {
      title: tabLabel.prefix === 'specials' ? 'Evidencia obligatoria' : '',
      className: 'col-4 text-center px-2 f12',
    },
    { title: <>&nbsp;</>, className: 'col-2' },
  ];
  const [supplies, setSupplies] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Familia de inventario', className: 'col-9 text-start px-2' },
      {
        title: tabLabel.prefix === 'specials' ? 'Evidencia obligatoria' : '',
        className: 'col-2 text-center px-2',
      },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [data, setData] = useState({
    familySelect: '',
    dataToSend: [],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
  });
  const [trigger, setTrigger] = useState(0);
  const {
    load: familySuppliesLoader,
    results: familySuppliesResults,
    trigger: getFamilySupplies,
  } = useGetMethod();
  const {
    load: suppliesListLoader,
    results: suppliesList,
    trigger: getSuppliesList,
  } = useGetMethod();
  const {
    load: FamilyLoader,
    results: familyResults,
    trigger: getFamilyListMethod,
  } = useGetMethod();
  const { load: createDilatationServiceLoader, trigger: createDetailClassificationMethod } =
    usePostMethod();
  const { load: updateDilatationServiceLoader, trigger: updateInventoryFamilyMethod } =
    usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getSuppliesList({
        url: '/medical/supplies/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
        token: store.loginReducer.Authorization,
        doAfterSuccess: res => {
          if (tabLabel.id === '') {
            setTabLabel({ id: res.results[0].id, prefix: res.results[0].prefix });
            getFamilyList(res.results[0].id);
          } else {
            getFamilyList(tabLabel.id);
          }
        },
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const getFamilyList = (id, search) => {
    async function fetchAsyncData() {
      await getFamilySupplies({
        url: '/medical/clinicHistory/inventoryFamilyBySupply/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
          supId: id ?? tabLabel.id,
          ...filters,
          search: search ?? filters.search,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  };

  useEffect(() => {
    async function fetchAsyncData() {
      await getFamilyListMethod({
        url: '/inventory_Family/params/',
        objFilters: { id_account: store.loginReducer.currentAccount.id },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const familyOptions = formatToRcSelect(familyResults?.results, 'id', 'description', '', '', '');
  const onCloseDrawer = () => {
    setSupplies({ ...supplies, showModal: false, isEditing: false });
    setData({ dataToSend: [], familySelect: '' });
    // setTrigger(trigger + 1);
  };
  const handleCreateDetailClassification = async () => {
    await createDetailClassificationMethod({
      url: '/medical/clinicHistory/inventoryFamilyBySupply/',
      method: 'POST',
      token: store.loginReducer.Authorization,
      body: data.dataToSend,
      succesAction: results => {
        setTrigger(trigger + 1);
        onCloseDrawer();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: 'Creado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };
  const handleUpdateInventoryFamily = async (dataComing, type) => {
    if (type === 'delete') {
      await updateInventoryFamilyMethod({
        url: '/medical/clinicHistory/inventoryFamilyBySupply/',
        method: 'DELETE',
        token: store.loginReducer.Authorization,
        body: dataComing ? dataComing : data,
        succesAction: results => {
          setTrigger(trigger + 1);
          onCloseDrawer();
          customSwaltAlertAsistencial({
            icon: 'success',
            titleColor: '#1ABCD2',
            title: 'Actualizado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            titleColor: '#1ABCD2',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    } else {
      await updateInventoryFamilyMethod({
        url: '/medical/clinicHistory/inventoryFamilyBySupply/',
        method: 'PUT',
        token: store.loginReducer.Authorization,
        body: dataComing,
        succesAction: results => {
          setTrigger(trigger + 1);
          onCloseDrawer();
          customSwaltAlertAsistencial({
            icon: 'success',
            titleColor: '#1ABCD2',
            title: 'Actualizado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            titleColor: '#1ABCD2',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    }
  };
  const onSubmit = async () => {
    if (data?.dataToSend.length > 0) {
      await handleCreateDetailClassification();
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Seleccione al menos una familia de inventario para añadir`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };
  const renderRow = row => {
    return (
      <tr key={row.risId} className={`hover-table-row`}>
        <td className='text-start px-2 col-9'>{row?.description}</td>
        <td className='text-center px-2 col-2'>
          {tabLabel.prefix === 'specials' && (
            <input
              type='checkbox'
              className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
              checked={Boolean(row.evidence)}
              disabled
            />
          )}
        </td>
        <td className='text-end col-1'>
          <PopUp
            showEnable={true}
            position='right'
            triggerSrc={ThreeDots}
            showEdit={tabLabel.prefix === 'specials' ? true : false}
            editText={
              row.evidence === 1
                ? 'Inhabilitar evidencia obligatoria'
                : 'Habilitar evidencia obligatoria'
            }
            editClickEvent={() => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: '¿Está seguro?',
                text: `Se ${row.evidence === 1 ? 'Inhabilitará' : 'habilitará'} la evidencia obligatoria: ${row.description}`,
                confirmButtonText: 'Si, continuar',
              }).then(async rs => {
                if (rs.isConfirmed) {
                  await handleUpdateInventoryFamily({
                    risId: row?.risId,
                    infId: row?.infId,
                    supId: row?.supId,
                    evidence: row.evidence === 1 ? 0 : 1,
                  });
                }
              });
            }}
            isEnabled={row.active === 1}
            enableText={row.active === 1 ? 'Eliminar' : 'Habilitar'}
            enableClickEvent={() => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: '¿Está seguro?',
                text: `Se ${row.active === 1 ? 'Eliminará' : 'habilitará'} la familia de inventario: ${row.description}`,
                confirmButtonText: 'Si, continuar',
              }).then(async rs => {
                if (rs.isConfirmed) {
                  await handleUpdateInventoryFamily(
                    {
                      risId: row?.risId,
                      active: row.active === 1 ? false : true,
                    },
                    'delete',
                  );
                }
              });
            }}
          />
        </td>
      </tr>
    );
  };
  const renderRowDetail = row => {
    return (
      <tr key={row.infId} className={`hover-table-row`}>
        <td className='text-start px-2 col-7'>
          {familyOptions.find(item => item.value === row.infId)?.label}
        </td>
        <td className='text-center px-2 col-4'>
          {tabLabel.prefix === 'specials' && (
            <input
              type='checkbox'
              className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
              onChange={e => {
                const isChecked = e.target.checked;
                const tempArr = data.dataToSend;
                const dataSend = tempArr.map(item =>
                  item.infId === row.infId ? { ...item, evidence: isChecked ? 1 : 0 } : item,
                );
                setData({ ...data, dataToSend: dataSend });
              }}
            />
          )}
        </td>
        <td className='text-end col-2'>
          <div
            className={'d-flex justify-content-end align-items-center CursorPointer svgIconDark'}
            onClick={() => {
              const tempArr = data.dataToSend;
              const dataSend = tempArr.filter(x => x.infId !== row.infId);
              setData({ ...data, dataToSend: dataSend });
            }}
            style={{ marginLeft: '3%' }}
          >
            <label className={` px-4 fw-bold ${tableStyles.orangeLightText} `}>
              <img alt='icon' src={closeOrange} className='pl-2' />
            </label>
          </div>
        </td>
      </tr>
    );
  };
  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={supplies.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: familySuppliesResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getFamilySupplies({
              url: '/medical/clinicHistory/inventoryFamilyBySupply/',
              objFilters: {
                eaccount: store.loginReducer.currentAccount.id,
                supId: tabLabel.id,
                page: e,
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {familySuppliesResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };
  const onAddVariableFunction = () => {
    if (data.dataToSend.some(item => item.infId === data.familySelect)) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Ya ha sido añadido`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    } else if (data.familySelect === '') {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Seleccione al menos una familia de inventario para añadir`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    } else {
      let tempArr = data.dataToSend;
      tempArr.push({
        infId: data.familySelect,
        supId: tabLabel.id,
        eaccount: store.loginReducer.currentAccount.id,
        name: data.Family,
      });
      setData({ ...data, dataToSend: tempArr, familySelect: '' });
    }
  };
  const renderTableDetails = () => {
    return (
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 500 }}>
        <OrdTable
          // key={triggerDetail}
          className={`${tableStyles.shade}`}
          headers={headersDetail}
          hasChildren={true}
          style={{ borderRadius: '10px', overflow: 'hidden', maxHeight: 280 }}
        >
          {data?.dataToSend?.length > 0 &&
            data?.dataToSend?.map(variable => renderRowDetail(variable))}
        </OrdTable>
      </div>
    );
  };
  const renderModal = () => {
    return (
      <Drawer
        anchor={'right'}
        open={supplies.showModal}
        onClose={() => {
          onCloseDrawer();
        }}
        PaperProps={{ style: { width: '40%' } }}
      >
        <Box padding={3} className='d-flex align-items-center'>
          <div
            className={`text-muted  me-4 ms-0 cursorPointer`}
            onClick={() => {
              onCloseDrawer();
            }}
          >
            <b style={{ fontSize: '25px' }}>✖</b>
          </div>
          <h2 className='text-muted m-0 fw-bold'>Detalle del servicio</h2>
        </Box>
        <hr className='m-0' />
        <Box className='d-flex flex-column justify-content-between h-100 f12 px-4 py-2'>
          <div className='d-flex flex-column jus'>
            <Form.Group className='mb-3 text-start' controlId='2'>
              <Form.Label className={`m-0 ${tableStyles.text_blueHC}`}>
                {/* <span className="px-1 f12 fw-bold">{selectedService.name}</span> */}
              </Form.Label>
            </Form.Group>
            <div className='row'>
              <div className='col-4'>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    {/* <span className="px-1 f12"> <b>CUP:</b> {selectedService.cup}</span> */}
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    {/* <span className="px-1 f12"><b>Tipo:</b> {selectedService.serviceType}</span> */}
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
            <div className={`${tableStyles.app_back_search}`}>
              <Row className='align-items-center'>
                <Col className={`f12 ${tableStyles.ordDarkBlueText}`}>Familia de inventario</Col>
              </Row>
              <div className={`${tableStyles.app_back_body}`} id='ad'>
                <Fragment key={'a'}>
                  <Row className=' align-items-center flex-nowrap border-top pt-2'>
                    <Col xs={12} className=''>
                      <Select
                        classNamePrefix={tableStyles.selectMenu}
                        options={familyOptions}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        tableStyles={ordCustomSelect}
                        value={familyOptions?.find(item => item.value === data.familySelect)}
                        onChange={e => {
                          setData({
                            ...data,
                            familySelect: e.value,
                            nameFamily: e.label,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                </Fragment>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <div
                className={
                  'd-flex justify-content-end align-items-center CursorPointer svgIconDark mt-3'
                }
                onClick={() => onAddVariableFunction()}
                style={{ marginLeft: '3%' }}
              >
                <label className={` f12 fw-bold ${tableStyles.orangeLightText} `}>
                  Añadir
                  <img alt='icon' src={add} className='pl-2' />
                </label>
              </div>
            </div>
            {renderTableDetails()}
          </div>
          <div className='d-flex flex-column'>
            <div className='align-self-end py-4'>
              <button
                className={`mr-2 btn ${tableStyles.tlnBtnPrimaryligth}`}
                onClick={() => onCloseDrawer()}
              >
                Cancelar
              </button>
              <button className={`${tableStyles.tlnBtnPrimary} btn`} onClick={() => onSubmit()}>
                Guardar
              </button>
            </div>
          </div>
        </Box>
      </Drawer>
    );
  };
  const render = () => {
    return (
      <>
        {(updateDilatationServiceLoader ||
          FamilyLoader ||
          createDilatationServiceLoader ||
          suppliesListLoader ||
          familySuppliesLoader) &&
          loader}
        {renderModal()}
        <div className={`w-80`}>
          <OrdGenericTemplate
            className='w-100'
            titleSize={12}
            title={'Tipos de insumos'}
            titleClass={'mx-3'}
          >
            <div className=''>
              <Row className=' mx-3 pr-8 mt-2'>
                {suppliesList?.results?.length > 0 &&
                  suppliesList?.results?.map(item => {
                    return (
                      <Col
                        xs={3}
                        key={item.id}
                        onClick={() => {
                          setSupplies({
                            showModal: false,
                            isEditing: false,
                            headers: [
                              {
                                title: 'Familia de inventario',
                                className: 'col-9 text-start px-2',
                              },
                              {
                                title: item.prefix === 'specials' ? 'Evidencia obligatoria' : '',
                                className: 'col-2 text-center px-2',
                              },
                              { title: <>&nbsp;</>, className: 'col-1' },
                            ],
                          });
                          setTabLabel({ id: item.id, prefix: item.prefix });
                          getFamilyList(item.id);
                          setFilters({ ...filters, page: 1, search: '' });
                        }}
                        className={` text-center ${tabLabel.id === item.id ? tableStyles.tabActive_asistencial : tableStyles.tabDisable}`}
                      >
                        {item.name}
                      </Col>
                    );
                  })}
              </Row>
              <OrdGenericTemplate
                buttonNewLabel={'Nueva familia'}
                buttonNewAction={() => {
                  setSupplies({ ...supplies, showModal: true });
                }}
                searchWithNew={{
                  colSize: '12',
                  WSize: '100',
                  searchWith: '50',
                  onChange: e => {
                    e.preventDefault();
                    setFilters({ ...filters, search: e.target.value });
                    getFamilyList(null, e.target.value);
                  },
                  buttonNewLabel: 'Nueva familia',
                  buttonNewAction: () => {
                    setSupplies({ ...supplies, showModal: true });
                  },
                }}
              >
                {renderTable()}
              </OrdGenericTemplate>
            </div>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
