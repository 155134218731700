import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Spinner } from 'react-bootstrap';

import pdfNextPage from '../../assets/img/icons/pdfNextPage.svg';
import pdfBackPage from '../../assets/img/icons/pdfBackPage.svg';
import downloadDoc from '../../assets/img/icons/downloadDoc.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export const PdfViewer = ({ file, downloadable, containerClass, pdfWidth }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleZoomIn = () => {
    setScale(prevScale => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(prevScale => Math.max(0.1, prevScale - 0.1));
  };

  const download = () => {
    const link = document.createElement('a');
    link.href = file;
    link.setAttribute('download', 'documento.pdf');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className='w-100 m-0 p-0 overflow-auto'>
      <div className='d-flex justify-content-end mb-2'>
        <button className='btn btn-light mx-2' onClick={handleZoomOut}>
          -
        </button>
        <button className='btn btn-light mx-2' onClick={handleZoomIn}>
          +
        </button>
      </div>
      <div style={{ overflowX: 'hidden', maxHeight: '500px', overflowY: 'auto' }}>
        <Document
          className={`m-0 p-0 d-flex justify-content-center bg-light ${containerClass}`}
          error='Ha ocurrido un error al cargar el documento'
          file={file}
          loading={<Spinner animation='border' variant='primary' />}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={pdfWidth || 300} pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
      <div className='d-flex justify-content-between'>
        <div className='d-flex justify-content-between'>
          <div className=''>
            <button onClick={pageNumber === 1 ? null : () => setPageNumber(pageNumber - 1)}>
              <img alt='Atras' src={pdfBackPage}></img>
            </button>
            <button onClick={numPages === pageNumber ? null : () => setPageNumber(pageNumber + 1)}>
              <img alt='Adelante' src={pdfNextPage}></img>
            </button>
          </div>
          <span className={`text-secondary ml-3 pt-1`}>
            Página {pageNumber} de {numPages}
          </span>
        </div>
        <div className={``}>
          {downloadable && (
            <img
              alt='Descargar'
              width='25px'
              className={'cursorPointer'}
              src={downloadDoc}
              onClick={() => download()}
            ></img>
          )}
        </div>
      </div>
    </div>
  );
};
