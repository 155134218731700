import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logOutUser } from '../../actions/loginActions';

export const Logout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const onRedirectHome = () => history.push('/categoryselect');

    const location = pathname.split('/');

    if (location?.length === 3) {
      dispatch(logOutUser(onRedirectHome));
    }
  }, [dispatch, history, pathname]);

  return <></>;
};
