import { memo, useEffect, useState } from 'react';

// css
import treasuryStyles from '../types-costs-tc.module.scss';
import paginationStyles from '../../../../components/Layouts/pagination.module.scss';

// components
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-js-pagination';
import GenericTableNew from '../../../../components/Layouts/GenericTableNew';

// icons
import { FiTrash2 } from 'react-icons/fi';
import { AddCircleOutline } from '@material-ui/icons';

// hooks
import { useGetMethod, usePostMethod } from '../../../../Hooks';

// helpers
import { customSwaltAlert, generateId, loader } from '../../../../helpers';

export const TypesCostsTcConfig = memo(({ config, handleClose, onUpdateSuccess }) => {
  const initialStateFilters = {
    page: 1,
    perpage: 10,
  };

  const [data, setData] = useState([]);
  const [listPaymentTypes, setListPaymentTypes] = useState({});

  const [filters, setFilters] = useState(initialStateFilters);

  const { load: loaderListPaymentTypes, trigger: getListPaymentTypes } = useGetMethod();

  const { load: loaderUpdate, trigger: onUpdate } = usePostMethod();

  const onAddPaymenType = () => {
    const item = {
      foreignKey: generateId(),
      eaccount: config.eaccount,
      name: '',
    };

    setFilters(state => ({ ...state, page: 1 }));
    setData(data.length ? [item, ...data] : [item]);
    setListPaymentTypes(state => ({
      ...state,
      rowTotal: state.rowTotal + 1,
      data: state?.data?.length ? [item, ...state.data] : [item],
    }));
  };

  const onRemovePaymentType = id => {
    const newSendData = data.filter(el => el.foreignKey !== id);
    const newData = listPaymentTypes.data.filter(el => el.foreignKey !== id);

    setData(newSendData);
    setListPaymentTypes(state => ({
      ...state,
      data: newData,
      rowTotal: state.rowTotal - 1,
    }));
  };

  const onChangeValue = ({ target, foreignKey, item, key }) => {
    const newDataListPaymentType = listPaymentTypes.data.map(el => {
      if (el.foreignKey === foreignKey) {
        return {
          ...el,
          [key]: target.value,
        };
      }

      return el;
    });

    let newData = [];
    const isExistPaymentType = data.filter(el => el.foreignKey === foreignKey).length;

    if (isExistPaymentType) {
      newData = data.map(el => {
        if (el.foreignKey === foreignKey) {
          return {
            ...el,
            [key]: target.value,
          };
        }

        return el;
      });
    } else {
      newData = data.length
        ? [...data, { ...item, [key]: target.value }]
        : [{ ...item, [key]: target.value }];
    }

    setData(newData);
    setListPaymentTypes({ ...listPaymentTypes, data: newDataListPaymentType });
  };

  const handleUpdate = () => {
    const isValidName = data.every(el => el.name.length > 0);

    const isInValidRepeatName = listPaymentTypes?.data?.some(
      (objeto, index) => listPaymentTypes?.data?.findIndex(o => o.name === objeto.name) !== index,
    );

    if (!isValidName) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Agregue un nombre correcto en el campo tipo de gasto',
        showCancelButton: false,
      });
    }

    if (isInValidRepeatName) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No puede existir más de un tipo de gasto con el mismo nombre',
        showCancelButton: false,
      });
    }

    onUpdate({
      url: '/cash/expense_types',
      token: config.token,
      method: 'POST',
      noAlert: true,
      body: { data },
      doAfterSuccess: res => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: res.message,
          showCancelButton: false,
        }).finally(() => {
          setFilters(state => ({ ...state, page: 1 }));
          handleClose();
          onUpdateSuccess();
        });
      },
      doAfterException: res => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  //////// HEADERS TABLE ////////////
  const headerTable = [
    <th key={1} className='text-start px-2'>
      Tipo de gasto
    </th>,

    <th key={2} className='text-center px-2'>
      Acciones
    </th>,
  ];

  //////// BODY TABLE ////////////
  const bodyTable = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item.id} className={`hover-table-row`}>
            <td className='text-start px-2'>
              <input
                type='text'
                placeholder='Escribir...'
                value={item.name}
                className={`${treasuryStyles.input} w-100`}
                onChange={e =>
                  onChangeValue({
                    item,
                    key: 'name',
                    target: e.target,
                    foreignKey: item.foreignKey,
                  })
                }
              />
            </td>
            <td className='text-center px-2'>
              {!item.id && (
                <FiTrash2
                  className='pointer'
                  size={21}
                  onClick={() => onRemovePaymentType(item.foreignKey)}
                />
              )}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const renderTable = () => {
    return (
      <div>
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            style={{ justifySelf: 'end', columnGap: 10 }}
            className={treasuryStyles.btnPrimaryModal}
            onClick={onAddPaymenType}
          >
            <span>Agregar gasto</span>
            <AddCircleOutline stroke={3} fontSize='small' />
          </button>
        </div>
        <GenericTableNew dark={true} headers={headerTable}>
          {bodyTable(
            listPaymentTypes?.data?.slice(
              (filters.page === 1 ? 0 : filters.page - 1) * filters.perpage,
              filters.page === 1 ? 9 : filters.perpage * filters.page - 1,
            ) || [],
          )}
        </GenericTableNew>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {filters.page}
            {' de '}
            {Math.ceil(listPaymentTypes?.rowTotal / filters.perpage)
              ? Math.ceil(listPaymentTypes?.rowTotal / filters.perpage)
              : '1'}{' '}
            ({listPaymentTypes?.rowTotal} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={listPaymentTypes?.rowTotal}
            pageRangeDisplayed={5}
            onChange={page => setFilters({ ...filters, page: page })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (config.show) {
      getListPaymentTypes({
        token: config.token,
        url: '/cash/expense_types',
        objFilters: { eaccount: config.eaccount },
      }).then(res => {
        if (res.success) {
          const newData = res.data.map(el => ({
            ...el,
            foreignKey: generateId(),
            eaccount: config.eaccount,
          }));
          setListPaymentTypes({ ...res, data: newData });
          setData([]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.show, config.token, getListPaymentTypes]);

  return (
    <>
      {(loaderListPaymentTypes || loaderUpdate) && loader}
      <Modal
        centered
        size='700'
        show={config.show}
        onHide={() => {
          handleClose();
          setFilters(state => ({ ...state, page: 1 }));
        }}
      >
        <Modal.Header className={`${treasuryStyles.modalHeader} justify-content-center`}>
          <Modal.Title style={{ fontWeight: 'bolder' }}>Modificar tipos de gastos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>{renderTable()}</Modal.Body>
        <Modal.Footer className='px-5 py-4' style={{ borderTop: '0px' }}>
          <Button className={treasuryStyles.btnCancel} onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleUpdate} className={treasuryStyles.btnPrimary}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

TypesCostsTcConfig.displayName = 'TypesCostsTcConfig';
