import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Layouts/customTabs.scss';
import podStyles from '../TableApproveAuthorizations/PODetail.module.scss';
import '../TableUsers/tableUserStyle.scss';
import pendingStyles from './AuctionPending.module.scss';
import detailStyles from './CurrentAuctionDetails.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';
// import "../TableUsers/tableUserStyle.scss";
// import "../Layouts/customTabs.scss";
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';

import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../Layouts/pagination.module.scss';

import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import Trash from '../../assets/img/icons/canecaGris.svg';
import checkboxTrue from '../../assets/img/icons/checkboxTrue.svg';

import TipoCheck from '../../assets/img/icons/TipoCheck.svg';
import TipoUnchecked from '../../assets/img/icons/TipoUnchecked.svg';
import calendarIcon from '../../assets/img/icons/date.svg';

import { creat_purchaseOrder } from '../../actions/purchaseOrderActions';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';

function DetailsModalPurchase(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const today = new Date();

  const [filters, setFilter] = useState({
    pagePO: 1,
    pageAuth: 1,
    perpage: 7,
  });

  const [carrito, setcarrito] = useState({
    step: 1,
  });

  const [infoPO, setinfoPO] = useState([]);

  useEffect(() => {
    setinfoPO([...props.selectedProviders]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const handleProviderDateChange = (index, val) => {
    let newinfoPO = [...infoPO];
    newinfoPO[index].date = val;
    setinfoPO(newinfoPO);
  };

  const renderinfoPO = () => {
    let temparray = [];

    if (Array.isArray(infoPO)) {
      infoPO.forEach((x, index) => {
        let providerRow = (
          <div className='d-flex mb-1'>
            <div
              key={'provider_id_' + x.provider_id + '_index_' + index}
              className={` ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={`${IndividualStyles.crudModalLabel} `}>&nbsp;</p>
              <input
                className={`register-inputs ${detailStyles.inputBoxCart} `}
                type='text'
                value={index + 1}
                disabled={true}
              />
            </div>

            <div className={` ${pendingStyles.Item}`}>
              <p className={`${detailStyles.carritoLabel} `}>&nbsp;</p>
              <div className={pendingStyles.ItemSeparator}></div>
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f3}`}>
              <p className={`${detailStyles.carritoLabel} `}>Proveedor</p>
              <input
                className={`register-inputs ${detailStyles.inputBoxCart} `}
                type='text'
                value={x.provider_name}
                disabled={true}
              />
            </div>

            <div className={` ${pendingStyles.Item}`}>
              <p className={`${IndividualStyles.crudModalLabel} `}>&nbsp;</p>
              <div className={pendingStyles.ItemSeparator}></div>
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}
            >
              <p className={`${detailStyles.carritoLabel} `}>Fecha de Entrega</p>
              <input
                className={`register-inputs ${detailStyles.inputBoxCart} ${podStyles.registerInputsBlue}`}
                type='date'
                value={x.date}
                onChange={e => handleProviderDateChange(index, e.target.value)}
                min={today.toISOString().slice(0, 10)}
              />
            </div>

            <div className={`${pendingStyles.Item}`}>
              <p className={`${detailStyles.carritoLabel} `}>&nbsp;</p>
              <img
                src={x.date ? TipoCheck : TipoUnchecked}
                alt='checked'
                className={detailStyles.IconCheckType}
              />
            </div>
          </div>
        );

        temparray.push(providerRow);
      });
    }
    return temparray;
  };

  const [
    renderListPo,
    renderListAuth,
    authorised_articles,
    reqauthorized_articles,
    totalCompra,
    totalItems,
  ] = useMemo(() => {
    let tablePo = [];
    let tableAuth = [];
    let authorised_articles = [];
    let reqauthorized_articles = [];

    let totalCompra = 0,
      totalItems = 0;
    const articles = props.selectedList;
    if (Array.isArray(articles)) {
      articles.forEach((x, index) => {
        let xSubtotal = x.ref?.qty_article * x.ref?.unit_price;
        let xTotal = xSubtotal + xSubtotal * x.ref?.iva * 0.01 - xSubtotal * x.ref?.discount * 0.01;

        let newRow = (
          <tr key={'tableRowPoAuth' + index} className='hover-table-row'>
            <td className={`col-md-1`}>
              <img
                src={Trash}
                alt='trash'
                className={detailStyles.iconTrash}
                onClick={() => props.unsetArticleProviderSelected(x.article_id)}
              />
            </td>
            <td className={`col-md-4`}>
              <p className={`m-0 ${detailStyles.rowName}`}>{x.ref?.article_ref?.description}</p>
            </td>
            <td className={`col-md-4`}>
              <p className={`m-0 ${detailStyles.rowName}`}>{x?.ref?.provider_name}</p>
            </td>
            <td className={`col-md-1`}>{x.ref?.qty_article}</td>
            <td className={`col-md-2  ${detailStyles.rowValue}`}>
              <p className={`m-0 ${detailStyles.rowValue}`}>{props.formatter.format(xTotal)}</p>
            </td>
          </tr>
        );

        let newpreparedarticle = {
          auction_id: props.selectedAuction.id,
          provider_id: x.provider_id,
          quotation_id: x.ref.id_quotation,
          id_article: x.article_id,
          article_qty: x.ref.qty_article,
          unit_price: x.ref.unit_price,
          iva: x.ref.iva,
          discount: x.ref.discount,
          iva_value: x.ref.iva_value,
          discount_value: x.ref.discount_value,
        };

        if (x.ref?.article_ref?.req_authorization) {
          //botom list
          tableAuth.push(newRow);
          reqauthorized_articles.push({
            ...newpreparedarticle,
            status: 2,
          });
        } else {
          //top list
          totalCompra += xTotal;
          totalItems += x.ref?.qty_article;
          tablePo.push(newRow);
          authorised_articles.push(newpreparedarticle);
        }
      });
    }

    return [
      tablePo,
      tableAuth,
      authorised_articles,
      reqauthorized_articles,
      totalCompra,
      totalItems,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, props.selectedList]);

  const paginateList = (fullList, mypage) => {
    fullList = fullList.slice(filters.perpage * (mypage - 1), filters.perpage * mypage);
    return fullList;
  };

  const renderHeaders = [
    <th key={`HeaderCarrito1`}>&nbsp;</th>,
    <th key={`HeaderCarrito2`}>Descripción</th>,
    <th key={`HeaderCarrito3`}>Proveedor</th>,
    <th key={`HeaderCarrito5`}>Cantidad</th>,
    <th key={`HeaderCarrito7`}>Valor&nbsp;Total</th>,
  ];

  const btnYesDisabled = () => {
    let res = false;

    infoPO.forEach(x => {
      if (!!x.date === false) {
        res = true;
      }
    });

    return res;
  };

  const btnYes = () => {
    if (carrito.step === 2) {
      let error = '';

      infoPO.forEach((x, index) => {
        if (!!x.date === false) {
          error = `La orden #${index + 1} no tiene fecha asignada`;
        }
      });

      if (error) {
        customSwaltAlert({
          icon: 'error',
          title: 'Datos Incompletos',
          text: error,
          showCancelButton: false,
        });
        return null;
      }

      dispatch(
        creat_purchaseOrder(
          {
            account_id: counter.loginReducer.currentAccount.id,
            user_created: counter.loginReducer.user_data.id,

            infoPO: infoPO,
            authorised_articles: authorised_articles,
            reqauthorized_articles: reqauthorized_articles,

            bussiness_name: counter.loginReducer.currentAccount.bussines_name,
            auction_title: counter.individualAuctionReducer.selected_indauction.description,
            auction_code: counter.individualAuctionReducer.selected_indauction.auction_number,
            auction_id: counter.individualAuctionReducer.selected_indauction.id,
            id_warehouse: counter.individualAuctionReducer.selected_indauction.id_warehouse,
            user_email: counter.userReducer.user_data.email,
          },
          () => {
            props.updateparent();
          },
          () => {
            props.setDetails({ show: false });
          },
        ),
      );
    } else {
      setcarrito({ ...carrito, step: 2 });
    }
  };

  const additionalContent = (
    <div>
      <div className='d-flex mb-1'>
        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel} `}>Total de compra</p>
          <input
            className={`register-inputs ${detailStyles.inputBox} `}
            type='text'
            value={props.formatter.format(totalCompra)}
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel} `}>Número de articulos</p>
          <input
            className={`register-inputs ${detailStyles.inputBox} `}
            type='text'
            value={totalItems}
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>&nbsp;</div>
      </div>

      <div className='d-flex'>
        <img src={checkboxTrue} alt='check' className={detailStyles.adjustIconPart2} />
        <p className={` ${detailStyles.textPart2}`}>
          Los siguientes productos serán enviados a ordenes de compra
        </p>
      </div>

      <GenericTableNew headers={renderHeaders}>
        {paginateList(renderListPo, filters.pagePO)}
      </GenericTableNew>

      <div className={paginationStyles.wrapperReverse}>
        <Pagination
          activePage={filters.pagePO}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={renderListPo.length}
          pageRangeDisplayed={5}
          onChange={val => setFilter({ ...filters, pagePO: val })}
          itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
          itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
          itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
          itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );

  return (
    <ModalNew
      title='Carrito'
      subtitle={`paso ${carrito.step} de 2`}
      additionalContent={carrito.step === 2 ? null : additionalContent}
      show={props.show}
      size='750'
      onHide={props.onHide}
      btnNoName={carrito.step === 2 ? 'Atrás' : 'Cancelar'}
      btnNoEvent={carrito.step === 2 ? () => setcarrito({ ...carrito, step: 1 }) : props.onHide}
      btnYesEvent={btnYes}
      btnYesName='Continuar'
      btnYesDisabled={carrito.step === 2 ? btnYesDisabled() : false}
    >
      {carrito.step === 1 ? (
        <div>
          <div className='d-flex'>
            <img src={AlertTriangle} alt='alert' className={detailStyles.adjustIconPart2} />
            <p className={` ${detailStyles.textPart2} ${detailStyles.textPart2Orange}`}>
              Los siguientes productos serán enviados a autorización
            </p>
          </div>

          <GenericTableNew headers={renderHeaders}>
            {paginateList(renderListAuth, filters.pageAuth)}
          </GenericTableNew>

          <div className={paginationStyles.wrapperReverse}>
            <Pagination
              activePage={filters.pageAuth}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={renderListAuth.length}
              pageRangeDisplayed={5}
              onChange={val => setFilter({ ...filters, pageAuth: val })}
              itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
              itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
              itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
              itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className='d-flex mb-1'>
            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={`${IndividualStyles.crudModalLabel} `}>Total de compra</p>
              <input
                className={`register-inputs ${detailStyles.inputBox} `}
                type='text'
                value={props.formatter.format(totalCompra)}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={`${IndividualStyles.crudModalLabel} `}>Número de articulos</p>
              <input
                className={`register-inputs ${detailStyles.inputBox} `}
                type='text'
                value={totalItems}
                disabled={true}
              />
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>&nbsp;</div>
          </div>

          <div className={detailStyles.sectionCart}>
            <img src={calendarIcon} alt='x' />
            <p>Seleccione las fechas de entrega de las ordenes de compra a generar</p>
          </div>
          {renderinfoPO()}
        </div>
      )}
    </ModalNew>
  );
}

export default DetailsModalPurchase;
