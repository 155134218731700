// by jhan ortegon

// react
import { useEffect, useState, useCallback } from 'react';

// icons - images
import Add from '../../assets/img/icons/crearCuenta.svg';
import Attach from '../../assets/img/icons/Adjuntar.svg';
import Watch from '../../assets/img/icons/relojAzulClaro.svg';
import Watch2 from '../../assets/img/icons/Reloj.svg';
import Trash from '../../assets/img/icons/canecaAzul.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Word from '../../assets/img/icons/word.svg';
import PDF from '../../assets/img/icons/pdf.svg';
import Send from '../../assets/img/icons/send.svg';

// redux
import { useSelector, useDispatch } from 'react-redux';

// css
import '../Layouts/customTabs.scss';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';
import genericTableStyles from '../Layouts/genericTable.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// helpers
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { customSelectNewDark } from '../Layouts/react-select-custom';

// services
import {
  creat_indauction,
  indauction_articles_filter,
} from '../../actions/IndividualAuctionActions';
import { warehouse_supplier_active } from '../../actions/storageActions';

// Components
import ReactSelect from 'react-select';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import ModalDocuments from './IndividualDocuments';
import ModalArticle from './IndividualModalArticle';
import ModalProgramming from './IndividualProgramming';
import GenericTableNew from '../Layouts/GenericTableNew';
import CustomNumUpDown from '../Layouts/CustomNumUpDown/CustomNumUpDown';

const TableIndividualAuction = ({ history, location }) => {
  const counter = useSelector(state => state);

  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(1);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });

  const [reqState, setreqState] = useState({
    auction_title: '',
    date_expiry: '',
    hour_expiry: '',
    terms: '',
    priority: '',
    id_warehouse: '',
    id_user: counter.loginReducer.user_data.id,
    account: counter.loginReducer.currentAccount.id,
    account_session: counter.loginReducer.currentAccount.name,
    id_consumption_center: 0,
    articulos: [],
    pay_conditions: '',
    coin: '',
    document: [],
    file: false,
    observations: ' ',
    qty_total: 0,
  });

  //======================================================================= Permission about the functionality InAuction

  const myPermission = useCallback(
    () =>
      counter.loginReducer.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === 'InAuction',
      ),
    [counter.loginReducer.currentAccount?.profile?.permission],
  );

  //======================================================================= show modals
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
  });
  const [modalDocumentsControl, setmodalDocumentsControl] = useState({
    show: false,
  });
  const [modalProgrammingControl, setmodalProgrammingControl] = useState({
    show: false,
  });
  //======================================================================= show modals

  useEffect(() => {
    const agreementData = location?.state;
    if (agreementData) {
      let newArticles = [];
      agreementData?.quotation_ref?.quotation_article_ref?.map(article => {
        return newArticles.push({
          id: article.id_article,
          ammount: 1,
          description: article.article_ref.description,
        });
      });
      setreqState({
        ...reqState,
        auction_title: agreementData?.name,
        date_expiry: '',
        hour_expiry: '',
        terms: '',
        articulos: newArticles,
        priority: '',
        id_consumption_center: 0,
        pay_conditions: '',
        id_warehouse: agreementData?.quotation_ref?.individual_auctions?.warehouse_ref?.id,
        coin: '',
        document: [],
        file: ' ',
        observations: ' ',
        qty_total: 0,
      });
    }

    if (!myPermission()?.create) {
      history?.push('/compras/inicio');
    }
    dispatch(indauction_articles_filter({ eaccount: counter.loginReducer.currentAccount.id }));
    dispatch(warehouse_supplier_active(counter.loginReducer.currentAccount.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    counter.loginReducer.currentAccount.id,
    dispatch,
    history,
    location?.state,
    myPermission,
  ]);

  const docType = data => {
    let iconDoc;
    if (reqState?.document) {
      switch (reqState.document[data]?.type) {
        case 'application/pdf':
          iconDoc = PDF;
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          'application/msword':
          iconDoc = Word;
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          'csv' ||
          'application/vnd.ms-excel':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  const resetReq = () => {
    setreqState({
      ...reqState,
      auction_title: '',
      date_expiry: '',
      hour_expiry: '',
      terms: '',
      articulos: [],
      priority: '',
      id_consumption_center: 0,
      pay_conditions: '',
      id_warehouse: '',
      coin: '',
      document: [],
      file: false,
      observations: ' ',
      qty_total: 0,
      programacion: [],
    });
    setTrigger(trigger + 1);
  };

  //======================================================================= actions buttons

  const buttonCancel = () => {
    if (reqState?.articulos.length > 0) {
      customSwaltAlert({
        title: '¿Está seguro de descartar los cambios de esta subasta?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Sí`,
        denyButtonText: `No`,
        customClass: {
          confirmButton: 'order-2',
          denyButton: 'order-3',
        },
      }).then(result => {
        if (result.isConfirmed) {
          resetReq();
          if (location?.state) {
            history.push('/compras/convenios');
          }
        }
      });
    } else if (location?.state) {
      history.push('/compras/convenios');
    }
  };

  const buttonOk = () => {
    let error = '';
    let qtyT = 0;
    reqState.articulos.map(x => {
      return (qtyT = qtyT + x.ammount);
    });

    if (reqState?.articulos?.length < 1) {
      error = 'No tiene Artículos';
    }

    if (error) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'error',
        title: 'Error',
        text: error,
      });
      return null;
    }

    dispatch(
      creat_indauction(
        {
          ...reqState,
          qty_total: qtyT,
        },
        () => resetReq(),
      ),
    );
  };

  const ButtonArticle = () => {
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
  };

  const ButtonDocuments = () => {
    setmodalDocumentsControl({
      ...modalDocumentsControl,
      show: true,
    });
  };

  //======================================================================= end actions buttons

  const removeArticle = id => {
    let articleTemp = reqState.articulos;
    let articleProgramming = reqState.programacion;
    articleTemp = articleTemp?.filter(x => x.id !== id);
    articleProgramming = articleProgramming?.filter(x => x.article_id !== id);

    setreqState({
      ...reqState,
      articulos: articleTemp,
      programacion: articleProgramming,
    });
  };
  const Disappears = pos => {
    if (Array.isArray(reqState.document)) {
      let temp = reqState.document;
      temp = temp.filter((elem, index) => index !== pos);

      setreqState({
        ...reqState,
        document: temp,
      });
    }
  };
  const ButtonProgramming = obj => {
    setmodalProgrammingControl({
      ...modalProgrammingControl,
      id: obj.id,
      articulo: obj,
      show: true,
    });
  };

  const editArticleAmmount = (id, ammount) => {
    let tempReqArt = reqState.articulos;
    if (Array.isArray(tempReqArt)) {
      let target = tempReqArt.find(x => x.id === id);
      if (target) {
        target.ammount = Math.max(1, ammount);
        setreqState({
          ...reqState,
          articulos: tempReqArt,
        });
      }
    }
  };

  const renderHeaders = [
    <th key={`reqTH1`} className='px-2'>
      Código
    </th>,
    <th key={`reqTH2`}>Artículo</th>,
    <th key={`reqTH3`}>Cantidad</th>,
    <th key={`reqTH4`}></th>,
    <th key={`reqTH99`}></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(reqState.articulos)) {
      table = reqState.articulos.map(x => {
        return (
          <tr
            key={'requisition' + x.id}
            className={`hover-table-row ${x.newArticle ? genericTableStyles.orange : ''}`}
          >
            <td className={`col-md-2`}>{x.id}</td>
            <td className={`col-md-6 text-start`}>{x.description}</td>
            <td className={`col-md-2`}>
              <CustomNumUpDown
                id={x.id}
                value={x.ammount}
                onChange={editArticleAmmount}
              ></CustomNumUpDown>
            </td>
            <td className={`col-md-1`}>
              {reqState.programacion?.find(y => y.article_id === x.id) ? (
                <img
                  src={Watch2}
                  alt='Reloj'
                  className={`icons-popUp  ${x.newArticle ? IndividualStyles.orangeIcon : ''}`}
                  onClick={() => ButtonProgramming(x)}
                />
              ) : (
                <img
                  src={Watch}
                  alt='Reloj'
                  className={`icons-popUp ${IndividualStyles.btnNew} ${x.newArticle ? IndividualStyles.orangeIcon : ''}`}
                  onClick={() => ButtonProgramming(x)}
                />
              )}
            </td>
            <td className={`col-md-1`}>
              <img
                src={Trash}
                alt='Eliminar'
                className={`icons-popUp ${IndividualStyles.btnNew} ${x.newArticle ? IndividualStyles.orangeIcon : ''}`}
                onClick={() => removeArticle(x.id)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateList = () => {
    const fullList = renderList();
    return fullList.slice(filters.perpage * (filters.page - 1), filters.perpage * filters.page);
  };

  var optionStorage = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.storageReducer.list_new)) {
    counter.storageReducer?.list_new?.forEach(elem => {
      optionStorage.push({ value: elem.id, label: elem.address });
    });
  }
  //======================================================================= for individualModalArticle
  const optionInventoryFamily = () => {
    let tempModules = [];
    if (Array.isArray(counter.individualAuctionReducer.familiainventario)) {
      tempModules = counter.individualAuctionReducer.familiainventario.map(elem => {
        let label = elem.description;
        let id_account = elem.id_account;
        return {
          value: elem.id,
          label: label,
          id_account: id_account,
          articles: elem.articles,
        };
      });
    }
    return tempModules;
  };

  //======================================================================= for individualModalArticle

  return (
    <div className={tableStyles.container} style={{ padding: '0 6rem' }}>
      {counter.individualAuctionReducer.createIndauction_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={IndividualStyles.container1}>
        <div className={IndividualStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Crear subasta </h1>
        </div>
      </div>

      <div className={IndividualStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className='d-flex'>
          <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
            <p className={IndividualStyles.crudModalLabel}>Título de Subasta</p>
            <input
              className={`register-inputs`}
              name='auction_title'
              type='text'
              value={reqState.auction_title}
              onChange={e =>
                setreqState({
                  ...reqState,
                  auction_title: e.target.value,
                })
              }
              disabled={false}
              placeholder='Escribir...'
            />
          </div>

          <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemTiny2}`}>
            <p className={IndividualStyles.crudModalLabel}>Fecha de Vencimiento</p>
            <input
              className={`register-inputs `}
              name='date'
              type='date'
              value={reqState.date_expiry}
              onChange={e =>
                setreqState({
                  ...reqState,
                  date_expiry: e.target.value,
                })
              }
              placeholder='Escribir...'
            />
          </div>

          <div className={IndividualStyles.ItemMedium}>
            <div className={`${IndividualStyles.ItemSmall}`}>
              <p className={IndividualStyles.crudModalLabel}>Hora</p>
              <input
                className={`register-inputs `}
                name='hour'
                type='time'
                value={reqState.hour_expiry}
                onChange={e =>
                  setreqState({
                    ...reqState,
                    hour_expiry: e.target.value,
                  })
                }
                disabled={false}
              />
            </div>
          </div>
        </div>

        {/* ============================= ROW 2 =================================== */}
        <div className='d-flex'>
          <div className={`${IndividualStyles.inputMargin}`} style={{ display: 'flex', flex: 6 }}>
            <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemTiny}`}>
              <p className={IndividualStyles.crudModalLabel}>Tipo de Subasta</p>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { value: '', label: 'Seleccionar...' },
                  { value: 'normal', label: 'Normal' },
                  { value: 'priority', label: 'Prioritaria' },
                ]}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                onChange={e =>
                  setreqState({
                    ...reqState,
                    priority: e.value,
                  })
                }
                // value={reqState.priority}
                value={[
                  { value: '', label: 'Seleccionar...' },
                  { value: 'normal', label: 'Normal' },
                  { value: 'priority', label: 'Prioritaria' },
                ].find(x => x.value === reqState.priority)}
              />
            </div>

            <div className={`${IndividualStyles.ItemTiny}`}>
              <p className={IndividualStyles.crudModalLabel}>Moneda</p>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={[
                  { value: '', label: 'Seleccionar...' },
                  { value: 'peso', label: 'Peso colombiano' },
                  { value: 'Dolar', label: 'Dolar estadunidense' },
                ]}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                onChange={e =>
                  setreqState({
                    ...reqState,
                    coin: e.value,
                  })
                }
                // value={reqState.priority}
                value={[
                  { value: '', label: 'Seleccionar...' },
                  { value: 'peso', label: 'Peso colombiano' },
                  { value: 'Dolar', label: 'Dolar estadunidense' },
                ].find(x => x.value === reqState.coin)}
              />
            </div>
          </div>

          <div className={`${IndividualStyles.ItemTiny_new}`}>
            <p className={IndividualStyles.crudModalLabel}>Condiciones de Pago</p>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={[
                { value: '', label: 'Seleccionar...' },
                { value: 'prepayment', label: 'Pago anticipado' },
                { value: 'delivery', label: 'Pago contraentrega' },
                { value: 'pay20', label: 'Pago a 20 días' },
                { value: 'pay30', label: 'Pago a 30 días' },
                { value: 'pay60', label: 'Pago a 60 días' },
                { value: 'pay90', label: 'Pago a 90 días' },
              ]}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              onChange={e =>
                setreqState({
                  ...reqState,
                  pay_conditions: e.value,
                })
              }
              value={[
                { value: '', label: 'Seleccionar...' },
                { value: 'prepayment', label: 'Pago anticipado<' },
                { value: 'delivery', label: 'Pago contraentrega<' },
                { value: 'pay20', label: 'Pago a 20 días' },
                { value: 'pay30', label: 'Pago a 30 días' },
                { value: 'pay60', label: 'Pago a 60 días' },
                { value: 'pay90', label: 'Pago a 90 días' },
              ].find(x => x.value === reqState.pay_conditions)}
            />
          </div>
        </div>
        {/* ============================= ROW 3 =================================== */}
        <div className='d-flex'>
          <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemSmall}`}>
            <p className={IndividualStyles.crudModalLabel}>Términos y condiciones</p>
            <textarea
              className={`${IndividualStyles.textArea_new} ${IndividualStyles.textarea} px-2`}
              name='terms'
              type='text'
              placeholder='Escribir...'
              value={reqState.terms}
              onChange={e =>
                setreqState({
                  ...reqState,
                  terms: e.target.value,
                })
              }
            />
          </div>
          <div className={`${IndividualStyles.ItemTiny_new}`}>
            <p className={IndividualStyles.crudModalLabel}>Dirección de Entrega</p>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={optionStorage}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              onChange={e =>
                setreqState({
                  ...reqState,
                  id_warehouse: e.value,
                })
              }
              value={optionStorage.find(x => x.value === reqState.id_warehouse)}
            />
          </div>
        </div>
        {/* ============================= ROW 4 =================================== */}
        <div
          className={`${IndividualStyles.imagePadding} ${IndividualStyles.negativeMargin} ${IndividualStyles.justify} d-flex`}
        >
          <div className=''>
            {reqState.document[2] ? (
              <div id='2' className={` ${IndividualStyles.buttonCreatCount} mx-3`}>
                <div className={IndividualStyles.deleteicon}>
                  <span onClick={() => Disappears(2)}>x</span>
                  <img
                    src={docType(2)}
                    alt='añadir'
                    className={`${IndividualStyles.imagePaddingType}`}
                  />
                  <label className={`${IndividualStyles.labelNew} ${IndividualStyles.ellipsis}`}>
                    {reqState.document[2]?.file_name}
                  </label>
                </div>
              </div>
            ) : (
              <span></span>
            )}

            {reqState.document[1] ? (
              <div id='1' className={`${IndividualStyles.buttonCreatCount} mx-3`}>
                <div className={IndividualStyles.deleteicon}>
                  <span onClick={() => Disappears(1)}>x</span>
                  <img
                    src={docType(1)}
                    alt='añadir'
                    className={`${IndividualStyles.imagePaddingType}`}
                  />
                  <label className={`${IndividualStyles.labelNew} ${IndividualStyles.ellipsis}`}>
                    {reqState.document[1]?.file_name}
                  </label>
                </div>
              </div>
            ) : (
              <span></span>
            )}

            {reqState.document[0] ? (
              <div id='0' className={`${IndividualStyles.buttonCreatCount} mx-3`}>
                <div className={`${IndividualStyles.deleteicon}`}>
                  <span onClick={() => Disappears(0)}>x</span>
                  <img
                    src={docType(0)}
                    alt='añadir'
                    className={`${IndividualStyles.imagePaddingType}`}
                  />
                  <label className={`${IndividualStyles.labelNew} ${IndividualStyles.ellipsis}`}>
                    {reqState.document[0]?.file_name}
                  </label>
                </div>
              </div>
            ) : (
              <span></span>
            )}
          </div>

          <div className=''>
            <img
              src={Attach}
              onClick={() => ButtonDocuments()}
              alt='añadir'
              className={`${IndividualStyles.btn} mx-3`}
            />
            <img
              src={Add}
              onClick={() => ButtonArticle()}
              alt='añadir'
              className={` ${IndividualStyles.btn}`}
            />
          </div>
        </div>
      </div>

      <div className={IndividualStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {reqState.articulos.length < 1 ? (
            <tr key='theOnlyOne'>
              <td>&nbsp;</td>
              <td>
                <b className={tableStyles.textCenter}>
                  <span className={IndividualStyles.tableButton} onClick={() => ButtonArticle()}>
                    Agregar Artículo
                  </span>
                </b>
              </td>
              <td>-</td>
              <td>&nbsp;</td>
            </tr>
          ) : (
            paginateList()
          )}
        </GenericTableNew>
      </div>

      <ModalArticle
        title='Artículos'
        key={'modalArticle' + trigger}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
        reqState={reqState}
        optionInventoryFamily={optionInventoryFamily()}
        setreqState={setreqState}
      />

      <ModalDocuments
        title='Anexar Documento'
        key={'modalDocuments' + trigger}
        show={modalDocumentsControl.show}
        onHide={() =>
          setmodalDocumentsControl({
            ...modalDocumentsControl,
            show: false,
          })
        }
        reqState={reqState}
        setreqState={setreqState}
      />

      {modalProgrammingControl.show ? (
        <ModalProgramming
          title='Programación de entregas'
          key={'modalProgramming' + trigger}
          show={modalProgrammingControl.show}
          onHide={() =>
            setmodalProgrammingControl({
              ...modalProgrammingControl,
              show: false,
            })
          }
          articulo={modalProgrammingControl.articulo}
          id={modalProgrammingControl.id}
          reqState={reqState}
          setreqState={setreqState}
        />
      ) : (
        ''
      )}

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {reqState?.articulos?.length ? filters.page : ''}
            {' de '}
            {Math.ceil(reqState?.articulos?.length / filters.perpage)
              ? Math.ceil(reqState?.articulos?.length / filters.perpage)
              : ''}{' '}
            ({reqState?.articulos?.length} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={reqState?.articulos?.length}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <div className={`${IndividualStyles.ItemSmall}`}>
          <p className={IndividualStyles.crudModalLabel}>Observaciones</p>
          <textarea
            id='txtArea'
            rows='10'
            cols='10'
            className={IndividualStyles.textArea}
            value={reqState.observations}
            onChange={e =>
              setreqState({
                ...reqState,
                observations: e.target.value,
              })
            }
            placeholder='Escribir...'
          ></textarea>
        </div>

        <div className={IndividualStyles.bottomRow}>
          <Button
            className={tableStyles.btnPrimary}
            onClick={() => buttonOk()}
            disabled={
              !!reqState.auction_title === false ||
              !!reqState.date_expiry === false ||
              !!reqState.hour_expiry === false ||
              !!reqState.pay_conditions === false ||
              !!reqState.coin === false ||
              !!reqState.priority === false ||
              !!reqState.id_warehouse === false ||
              reqState.articulos?.length <= 0
            }
          >
            <img src={Send} alt='send' /> <span>Enviar</span>
          </Button>
          <Button className={tableStyles.btnSecondary} onClick={() => buttonCancel()}>
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableIndividualAuction;
