import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lupa from '../../assets/img/icons/lupa.svg';
import lupaTransparent from '../../assets/img/icons/lupaTransparent.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import glossStyles from './Glosses.module.scss';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { useHistory } from 'react-router-dom';

import { getListCompanies, getHistoryStatus } from '../../actions/billingActions';

import { getGloss } from '../../actions/GlossActions';
import CurrentGlossesDetails from './CurrentGlossesDetails';
import { Title } from '../../shared';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,
});

function CurrentGlosses() {
  let history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(0);

  const [detail, setDetail] = useState({ show: false });

  const [filters, setFilters] = useState({
    eaccount: counter.loginReducer.currentAccount.id,
    user: counter.loginReducer.user_data.id,
    page: 1,
    perpage: 10,
    multistatus: ['registered', 'inprocess'],
  });

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'glosesCurr',
  );

  useEffect(() => {
    if (!myPermission?.create || !myPermission?.edit) {
      history.push('/categoryselect');
    }
    dispatch(
      getListCompanies({
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getHistoryStatus({
        type: 'objection',
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getGloss({
        ...filters,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  let optionCompanies = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(counter.billingReducer.companies)) {
    let ordered = counter.billingReducer.companies.sort((a, b) =>
      a.id > b.id ? 1 : b.id > a.id ? -1 : 0,
    );
    ordered.forEach(item => {
      optionCompanies.push({
        key: item.id,
        value: item.id,
        label: item.copyName,

        id: item.id,
        companyType: item.companyType,
        copyName: item.copyName,
        notification_email: item.notification_email,
        Nit: item.Nit,
        address: item.address,
        phone: item.phone,
        email: item.email,
        cityId: item.cityId,
        city: item.city,
      });
    });
  }

  let optionStatus = [{ key: 'default', value: '', label: 'Seleccionar...', name: '' }];
  if (Array.isArray(counter.billingReducer.billing_history_status)) {
    counter.billingReducer.billing_history_status.forEach(item => {
      optionStatus.push({
        key: item.id,
        value: item.id,
        label: item.value,
        id: item.id,
        name: item.name,
        prefix: item.prefix,
        color_id: item.color_id,
        background: item.background,
        fontcolor: item.fontcolor,
      });
    });
  }

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(counter.glossReducer.glosses)) {
      counter.glossReducer.glosses.forEach((item, index) => {
        tempList.push(
          <tr key={index}>
            <td className='text-center'>{item.invoice_nbr}</td>
            <td className='text-center'>
              {item.invoiceFilingDate ? convertDateToLatinFormat(item.invoiceFilingDate) : '-'}
            </td>
            <td className='text-center'>{item.date ? convertDateToLatinFormat(item.date) : '-'}</td>
            <td className='text-center'>{item.clientNit}</td>
            <td className='text-center'>{item.clientName}</td>
            <td className='text-center'>{item.type}</td>
            <td className='text-center px-2'>
              <div
                style={{
                  margin: '0 auto',
                  width: '110px',
                  padding: '6px',
                  borderRadius: '100px',
                  backgroundColor: `${item.fontcolor}14`,
                  color: `${item.fontcolor}`,
                  fontWeight: 'bold',
                }}
              >
                {item.status}
              </div>
            </td>
            <td className='text-end'>
              <span style={{ paddingRight: '8px' }}>
                {item.total_amount ? formatter.format(item.total_amount) : '-'}
              </span>
            </td>
            <td className='text-center px-2' style={{ cursor: 'pointer' }}>
              <img
                src={lupaTransparent}
                alt=''
                srcSet=''
                onClick={() => setDetail({ show: true, item: item })}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const header3 = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      F. radicación
    </th>,
    <th key={3} className='text-center'>
      Fecha glosa
    </th>,
    <th key={4} className='text-center'>
      NIT
    </th>,
    <th key={5} className='text-center'>
      Empresa
    </th>,
    <th key={6} className='text-center'>
      Tipo
    </th>,
    <th key={7} className='text-center'>
      Estado
    </th>,
    <th key={8} className='text-end px-2'>
      Valor glosa
    </th>,
    <th key={9} className='text-center'></th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const returnMain = (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <Title
          title='Glosas vigentes'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/facturacion/inicio');
          }}
        />
        <div>
          <div className='div gap-2'>
            <div
              style={{ alignItems: 'end' }}
              className={`${glossStyles.f3} display-grid ${tableStyles.containerSelect}`}
            >
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>Empresa</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionCompanies.find(option => option.value === filters.client)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      client: e.id,
                    })
                  }
                  options={optionCompanies}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </form>
            </div>

            <div
              style={{ alignItems: 'end' }}
              className={`${glossStyles.f2}  display-grid ${tableStyles.containerSelect}`}
            >
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>Estado</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionStatus.find(option => option.name === filters.status)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      status: e.name,
                    })
                  }
                  options={optionStatus}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </form>
            </div>

            <div
              style={{ alignItems: 'end' }}
              className={`${glossStyles.f2}  display-grid ${tableStyles.containerSelect}`}
            >
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>No. Factura</label>
                <input
                  placeholder='Escribir...'
                  className={IndividualStyles.registerInputs}
                  type='text'
                  style={{ borderColor: '#01A0F6' }}
                  value={filters.invoiceSeq}
                  onChange={e => setFilters({ ...filters, invoiceSeq: e.target.value })}
                />
              </form>
            </div>

            <div style={{ alignItems: 'end' }} className={`display-grid`}>
              <label className={tableStyles.crudModalLabel}>Fecha factura</label>
              <div className={tableStyles.deleteicon}>
                {filters.invoiceDate ? (
                  <span onClick={() => setFilters({ ...filters, invoiceDate: '' })}>x</span>
                ) : (
                  ''
                )}
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    type='date'
                    style={{ color: '#01A0F6', fontWeight: 'bold', borderColor: '#01A0F6' }}
                    value={filters.invoiceDate}
                    onChange={e => setFilters({ ...filters, invoiceDate: e.target.value })}
                  />
                </form>
              </div>
            </div>

            <div style={{ alignItems: 'end' }} className={`display-grid`}>
              <label className={tableStyles.crudModalLabel}>Fecha radicación</label>
              <div className={tableStyles.deleteicon}>
                {filters.filingDate ? (
                  <span onClick={() => setFilters({ ...filters, filingDate: '' })}>x</span>
                ) : (
                  ''
                )}
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    type='date'
                    style={{ color: '#01A0F6', fontWeight: 'bold', borderColor: '#01A0F6' }}
                    value={filters.filingDate}
                    onChange={e => setFilters({ ...filters, filingDate: e.target.value })}
                  />
                </form>
              </div>
            </div>

            <div style={{ alignItems: 'end' }} className={`display-grid`}>
              <form onSubmit={e => handleSearch(e)}>
                <i>
                  <img
                    className='mt-4'
                    style={{ width: '25px', cursor: 'pointer' }}
                    src={Lupa}
                    alt=''
                    srcSet=''
                    onClick={() => {
                      setTrigger(trigger + 1);
                      setFilters({ ...filters, page: 1 });
                    }}
                  />
                </i>
              </form>
            </div>
          </div>
        </div>
        <div>
          <GenericTable headers={header3} dark={true}>
            {renderList()}
          </GenericTable>
        </div>

        {counter.glossReducer.glosses_loading && (
          <div className='mx-auto'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.glossReducer.glosses_total ? filters.page : ''}
            {' de '}
            {Math.ceil(counter.glossReducer.glosses_total / filters.perpage)
              ? Math.ceil(counter.glossReducer.glosses_total / filters.perpage)
              : ''}{' '}
            ({counter.glossReducer.glosses_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={counter.glossReducer.glosses_total}
            pageRangeDisplayed={5}
            onChange={val => {
              setTrigger(trigger + 1);
              setFilters({ ...filters, page: val });
            }}
            itemClassPrev={`${paginationStyles.itemClassPrev} `}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </>
  );

  const returnDetails = (
    <CurrentGlossesDetails
      detail={detail}
      setDetail={setDetail}
      goBack={() => {
        setDetail({ show: false });
        setTrigger(trigger + 1);
      }}
      updateParent={() => setTrigger(trigger + 1)}
    ></CurrentGlossesDetails>
  );

  return detail.show ? returnDetails : returnMain;
}
export default CurrentGlosses;
