//classification' reducer

import {
  CLASSIFICATION_GET,
  CLASSIFICATION_EDIT,
  CLASSIFICATION_POST,
 
} from "../actions/actionTypes";

const initialState = {
  classification: [{ codigo: "1234" }],
  total: 0,
  displayError: false,
  error: null,
  token: "",
};
export const classificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLASSIFICATION_GET:
      return {
        ...state,
        classification: action.payload,
        total: action.row_total,
        error: null,
        token: action.token,
      };

    case CLASSIFICATION_EDIT:
      return {
        ...state,
        displayError: false,
        error: null,
        token: action.token,
      };

    case CLASSIFICATION_POST:
      return {
        ...state,
        displayError: false,
        error: null,
        token: action.token,
      };

    default:
      return state;
  }
};
