import Select from 'react-select';
import styled from 'styled-components';
import { ordCustomSelect2 } from '../../../Layouts/react-select-custom';
import { Input, Text } from '../../atoms';

const StyledBoxInputSelectText = styled.div`
  padding: ${({ padding }) => (padding ? padding : '0px')};
  grid-template-columns: ${({ gridTemplateColumns }) =>
    gridTemplateColumns ? gridTemplateColumns : '60% 40%'};
  margin: ${({ margin }) => (margin ? margin : '0')};
  display: grid;
  width: ${({ width }) => (width ? width : '296px')};
`;

export const BoxInputSelectText = ({
  width,
  nameInput,
  valueInput,
  placeholderInput,
  typeInput,
  margin,
  arrayOptions,
  nameSelect,
  handleChangeSelect,
  handleChangeInput,

  titleText,
  className,
  valueSelect,
  gridTemplateColumns,
  textActive,
  classNameTextBlock,
  disabled,
  hideTextActive,
  placeholderSelect,
}) => {
  return (
    <div>
      <Text title={titleText} margin='0px 0px 0px 2px' />
      <StyledBoxInputSelectText
        margin={margin}
        width={width}
        gridTemplateColumns={gridTemplateColumns}
      >
        <Input
          classNameInput={className}
          disabled={disabled}
          name={nameInput}
          value={valueInput}
          placeholder={placeholderInput || 'Escribir...'}
          type={typeInput}
          width={'100%'}
          borderBottomRightRadius={'0'}
          borderTopRightRadius='0'
          borderRight='none'
          onChange={handleChangeInput}
        />

        {textActive && !hideTextActive ? (
          <span className={`textCustom text-secondary ${classNameTextBlock}`}>{textActive}</span>
        ) : (
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={arrayOptions}
            className='text-secondary w-100'
            placeholder={placeholderSelect || 'Seleccionar...'}
            value={valueSelect}
            name={nameSelect}
            styles={ordCustomSelect2}
            onChange={handleChangeSelect}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'neutral0',
              },
            })}
          ></Select>
        )}
      </StyledBoxInputSelectText>
    </div>
  );
};
