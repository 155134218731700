import { Box, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import edit from '../../assets/img/icons/app_editar.svg';
import rowIcon from '../../assets/img/icons/arrowDerechaCalendario.svg';
import closeIcon from '../../assets/img/icons/close_insurer_voluntary.svg';
import cancel from '../../assets/img/icons/closeOrange.svg';
import add from '../../assets/img/icons/orangeNoBGArrow.svg';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import styles from '../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';

export default function AssistanceLots() {
  const store = useSelector(state => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const [filtersAdd, setFiltersAdd] = useState({
    center: null,
    family: null,
    search: '',
  });
  const filters = {
    enabled: 1,
    eaccount: store.loginReducer.currentAccount.id,
  };
  const [trigger, setTrigger] = useState(0);
  const [config, setConfig] = useState({
    showDrawer: false,
    isEditing: false,
    headers: [
      { title: 'Código', className: 'col-4 text-start px-2' },
      { title: 'Nombre', className: 'col-6 text-start' },
      { title: <>&nbsp;</>, className: 'col-2' },
    ],
  });

  const headersDetail = [
    { title: 'Nombre', className: 'col-4 text-start px-2 f12' },
    { title: 'Cantidad', className: 'col-2 text-center f12' },
    { title: 'Fecha de Vto.', className: 'col-3 text-center f12' },
    { title: 'Estado', className: 'col-2 text-center f12' },
    { title: <>&nbsp;</>, className: 'col-1' },
  ];
  const [tempLot, setTempLot] = useState({
    relCcCombination: null,
    quantity: '',
    expirationDate: '',
    active: 1,
    artId: null,
    lotName: '',
    createdBy: store.loginReducer.user_data.id,
    eaccount: store.loginReducer.currentAccount.id,
  });
  const [selectedService, setSelectedService] = useState({});
  const [lotsList, setLotsList] = useState([]);
  const [articlesList, setArticlesList] = useState([]);
  const { trigger: getArticlesList, loader: dxLoader } = useGetMethod();
  const { trigger: getCenterList, results: centerResults } = useGetMethod();
  const { trigger: getFamilyResults, results: familyResults } = useGetMethod();
  const { trigger: getFamilyResultsTable } = useGetMethod();
  const { load: updateServiceLoader, trigger: updateService } = usePostMethod();
  const [triggerTable, setTriggerTable] = useState(0);
  const centerList = formatToRcSelect(centerResults?.results, 'id', 'description');
  const familyList = formatToRcSelect(familyResults.results, 'invFamilyId', 'description');

  function obtenerListaDeArticlesConRelCombination(data) {
    const articlesList = [];
    data.results.forEach(family => {
      family.articles.forEach(article => {
        articlesList.push({
          ...article,
          relCombination: family.relCombination,
        });
      });
    });
    return articlesList;
  }

  useEffect(() => {
    async function fetchAsyncData() {
      await getCenterList({
        url: '/consumption_center/',
        objFilters: {
          eaccount,
          handleResponse: 1,
          active: 1,
          lite: 1,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [store.loginReducer.Authorization, getCenterList, eaccount]);
  useEffect(() => {
    async function fetchAsyncData() {
      if (filtersAdd.center) {
        await getFamilyResults({
          url: '/medical/clinicHistory/assistance-lots/inventory-family',
          objFilters: {
            eaccount,
            consumptionCenterId: filtersAdd.center,
          },
          token: store.loginReducer.Authorization,
        });
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [store.loginReducer.Authorization, eaccount, getFamilyResults, filtersAdd.center]);

  useEffect(() => {
    async function fetchAsyncData() {
      if (filtersAdd.center && filtersAdd.family) {
        await getFamilyResultsTable({
          url: '/medical/clinicHistory/assistance-lots/inventory-family',
          objFilters: {
            eaccount,
            search: filtersAdd.search,
            consumptionCenterId: filtersAdd.center,
            invFamilyId: filtersAdd.family,
          },
          token: store.loginReducer.Authorization,
          doAfterSuccess: data => {
            const articles = obtenerListaDeArticlesConRelCombination(data);
            setArticlesList(articles);
          },
        });
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.loginReducer.Authorization,
    trigger,
    filtersAdd.center,
    eaccount,
    getFamilyResultsTable,
    filtersAdd.family,
  ]);

  const geLotsFunction = async (item, relCombination) => {
    await getArticlesList({
      url: '/medical/clinicHistory/assistance-lots',
      objFilters: { ...filters, artId: item.id },
      token: store.loginReducer.Authorization,
      doAfterSuccess: res => {
        onOpenDrawer({
          ...res.results[0],
          relCombination,
          artId: item.id,
          artName: item.name,
          barcode: item.barCode,
        });
      },
    });
  };

  const onCloseDrawer = () => {
    setConfig({ ...config, showDrawer: false, isEditing: false });
    setSelectedService({});
    setTempLot({
      relCcCombination: null,
      quantity: '',
      expirationDate: '',
      active: 1,
      artId: null,
      lotName: '',
      createdBy: store.loginReducer.user_data.id,
      eaccount: store.loginReducer.currentAccount.id,
    });
    setTrigger(trigger + 1);
  };

  const onOpenDrawer = service => {
    setSelectedService(service);
    setLotsList(service.lots);
    setConfig({
      ...config,
      showDrawer: true,
    });
  };

  const onAddVariable = (isEditing, active, item) => {
    const bodyEdit = item
      ? {
          ...item,
          active,
          quantity: item.artQty,
          expirationDate: item.expirationDate,
          lotName: item.name,
          assId: item.assId,
        }
      : {
          quantity: Number(tempLot.quantity),
          artId: selectedService.artId,
          expirationDate: tempLot.expirationDate,
          lotName: tempLot.lotName,
          assId: tempLot.assId,
          active: tempLot.active,
        };
    const bodyCreate = {
      quantity: Number(tempLot.quantity),
      expirationDate: tempLot.expirationDate,
      lotName: tempLot.lotName,
      relCcCombination: selectedService.relCombination,
      artId: selectedService.artId,
      active: 1,
      createdBy: store.loginReducer.user_data.id,
      eaccount: store.loginReducer.currentAccount.id,
    };
    updateService({
      url: '/medical/clinicHistory/assistance-lots',
      token: store.loginReducer.Authorization,
      method: isEditing ? 'PUT' : 'POST',
      noAlert: true,
      body: isEditing ? bodyEdit : bodyCreate,
      doAfterSuccess: res => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Proceso exitoso',
          text: `Se ha ${item ? (active === 0 ? 'deshabilitó' : 'habilitó') : isEditing ? 'editado' : 'añadido'} el lote exitosamente`,
          showCancelButton: false,
        });
        if (isEditing) {
          let tempListLots = lotsList?.map(x =>
            x?.assId === (item?.assId ?? tempLot?.assId)
              ? {
                  ...x,
                  active: item ? active : tempLot.active,
                  artQty: item?.artQty ?? tempLot.quantity,
                  expirationDate: item?.expirationDate ?? tempLot.expirationDate,
                  name: item?.name ?? tempLot.lotName,
                }
              : x,
          );

          setTriggerTable(trigger + 1);
          setLotsList(tempListLots);
        } else {
          const tempListLots = [
            ...(lotsList || []),
            {
              active: tempLot.active,
              artQty: tempLot.quantity,
              expirationDate: tempLot.expirationDate,
              name: tempLot.lotName,
              relCcCombination: tempLot.relCcCombination,
              assId: res?.results,
            },
          ];
          setLotsList(tempListLots);
        }
        setConfig({ ...config, isEditing: false });
        setTempLot({
          relCcCombination: null,
          quantity: '',
          artQty: null,
          expirationDate: '',
          active: 1,
          artId: null,
          lotName: '',
          createdBy: store.loginReducer.user_data.id,
          eaccount: store.loginReducer.currentAccount.id,
        });
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  const renderRow = item => {
    return (
      <tr key={item.id} className={`hover-table-row`}>
        <td className='text-start px-2'>{item.barCode}</td>
        <td className='text-start'>{item.name}</td>
        <td className='text-end col-1 pe-3'>
          <div className='d-flex justify-content-center'>
            <img
              alt='icon'
              src={rowIcon}
              className='cursorPointer'
              onClick={() => {
                geLotsFunction(item, item.relCombination);
              }}
            />
          </div>
        </td>
      </tr>
    );
  };
  const renderRowDetail = item => {
    return (
      <tr key={`${item}`} className={`hover-table-row`}>
        <td className='text-start px-2'>{item.name}</td>
        <td className='text-center'> {item.artQty}</td>
        <td className='text-start'>{item.expirationDate}</td>
        <td className='text-center'>
          {item.active === 1 ? (
            <div className={styles.app_container_state}>
              <span className={styles.app_state_true}>Habilitado</span>
            </div>
          ) : (
            <div className={styles.app_container_state}>
              <span className={styles.app_state_false}>Deshabilitado</span>
            </div>
          )}
        </td>
        <td className='text-center px-2'>
          <div className='d-flex justify-content-end'>
            <img
              className={`mx-2 ${styles.colorNewOrderHover}`}
              src={edit}
              width={10}
              alt=''
              onClick={() => {
                setConfig(state => ({ ...state, isEditing: true }));
                setTempLot({ ...item, lotName: item.name, quantity: item.artQty });
              }}
            />
            <img
              className={styles.colorNewOrderHover}
              src={cancel}
              width={10}
              alt=''
              onClick={() => {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: '¿Está seguro?',
                  text: `Se ${item.active === 1 ? 'deshabilitará' : 'habilitará'} el lote: ${item.name}`,
                  confirmButtonText: 'Si, continuar',
                }).then(rs => {
                  if (rs.isConfirmed) {
                    onAddVariable(true, item.active === 1 ? 0 : 1, item);
                  }
                });
              }}
            />
            <div className='px-2'></div>
          </div>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${styles.shade}`}
        headers={config.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
      >
        {articlesList?.map(service => renderRow(service))}
      </OrdTable>
    );
  };
  const renderTableDetails = () => {
    return (
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 280 }} key={triggerTable}>
        <OrdTable
          className={`${styles.shade}`}
          headers={headersDetail}
          hasChildren={true}
          style={{ borderRadius: '10px', overflow: 'hidden', maxHeight: 280 }}
        >
          {lotsList?.length > 0 && lotsList?.map(variable => renderRowDetail(variable))}
        </OrdTable>
      </div>
    );
  };

  const renderModal = () => {
    if (selectedService) {
      return (
        <Drawer
          anchor={'right'}
          open={config.showDrawer}
          onClose={() => {
            onCloseDrawer();
          }}
          PaperProps={{ style: { width: '40%' } }}
        >
          <Box padding={2} className='d-flex align-items-center'>
            <div
              className={`text-muted  me-4 ms-0 cursorPointer`}
              onClick={() => {
                onCloseDrawer();
              }}
            >
              <img alt='icon' height={15} src={closeIcon} className='pl-2' />
            </div>
            <h4 className='text-muted m-0 fw-bold'>Lotes</h4>
          </Box>
          <hr className='m-0' />
          <Box className='d-flex flex-column justify-content-between h-100 f12 px-4 py-2'>
            <div className='d-flex flex-column jus'>
              <Form.Group className='mb-3 text-start' controlId='2'>
                <Form.Label className={`m-0 ${styles.text_blueHC}`}>
                  <h5 className='px-1 f16 fw-bold'>{selectedService.artName}</h5>
                </Form.Label>
              </Form.Group>
              <div className='row'>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className='px-1 f12'>
                      <b>Articulo:</b> <span className='text-muted'>{selectedService.artName}</span>
                    </span>
                  </Form.Label>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                    <span className='px-1 f12'>
                      <b>Código:</b>
                      <span className='text-muted'> {selectedService.barcode}</span>
                    </span>
                  </Form.Label>
                </Form.Group>
              </div>

              <div className={`${styles.app_back_search}`}>
                <div className={`${styles.app_back_body}`} id='ad'>
                  <Row className=' align-items-center d-flex pt-2 '>
                    <Col xs={12} className='pb-2'>
                      <span className={`f12 ${styles.ordDarkBlueText}`}> Nombre</span>
                      <Form.Control
                        className={`form-control text-muted ord-roundInput`}
                        type='text'
                        placeholder='Escribir...'
                        value={tempLot.lotName}
                        style={{ fontSize: 12 }}
                        onChange={({ target }) => setTempLot({ ...tempLot, lotName: target.value })}
                      />
                    </Col>
                    <Col xs={6} className=''>
                      <span className={`f12 ${styles.ordDarkBlueText}`}> Cantidad</span>
                      <Form.Control
                        className={`form-control text-muted ord-roundInput`}
                        type='number'
                        placeholder='Escribir...'
                        value={tempLot?.quantity}
                        style={{ fontSize: 12 }}
                        onChange={({ target }) =>
                          setTempLot({ ...tempLot, quantity: target.value })
                        }
                      />
                    </Col>
                    <Col xs={6} className=''>
                      <span className={`f12 ${styles.ordDarkBlueText}`}>Fecha de Vto.</span>
                      <Form.Control
                        className={`form-control text-muted ord-roundInput`}
                        type='date'
                        placeholder='Escribir...'
                        value={tempLot.expirationDate}
                        style={{ fontSize: 12 }}
                        onChange={({ target }) =>
                          setTempLot({ ...tempLot, expirationDate: target.value })
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div
                  className={
                    'd-flex justify-content-end align-items-center CursorPointer svgIconDark mt-3'
                  }
                  onClick={() => onAddVariable(config.isEditing)}
                  style={{ marginLeft: '3%' }}
                >
                  <label className={` f12 fw-bold ${styles.orangeLightText} `}>
                    {config.isEditing ? 'Editar lote' : 'Agregar lote'}

                    <img alt='icon' src={add} className='pl-2' />
                  </label>
                </div>
              </div>
              {renderTableDetails()}
            </div>
          </Box>
        </Drawer>
      );
    }
  };

  const render = () => {
    return (
      <>
        {dxLoader && loader}
        {updateServiceLoader && loader}

        <div className={`${styles.tlnContainer} `}>
          <OrdGenericTemplate className='' titleSize={12} title={'Lotes asistenciales'}>
            <>
              <div className='row '>
                <div className='col-3'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                    <b className='text-start'> &nbsp;Centros de consumos</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={centerList}
                    className='text-secondary text-start'
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={event => setFiltersAdd({ ...filtersAdd, center: event.value })}
                  ></Select>
                </div>
                <div className='col-3'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} `}>
                    <b className='text-start'> &nbsp;Familias de inventario</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={familyList}
                    className='text-secondary text-start'
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={event => setFiltersAdd({ ...filtersAdd, family: event.value })}
                  ></Select>
                </div>
                <div className='col-6 align-self-end'>
                  <input
                    placeholder='Escribe aquí para buscar'
                    className={`${ordComponentStyles.backgroundImage} ${styles.appInputDate} pl-2 w-50`}
                    onChange={event => {
                      setFiltersAdd({ ...filtersAdd, search: event.target.value });
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setTrigger(trigger + 1);
                      }
                    }}
                  />
                </div>
              </div>
              {renderTable()}
            </>
          </OrdGenericTemplate>
        </div>
        {renderModal()}
      </>
    );
  };

  return render();
}
