import { useRef, useState } from 'react';
import { default as ReactSelect, components } from 'react-select';
import {
  selectMultiple,
  selectMultipleSmall,
  selectMultipleVerySmall,
} from '../components/Layouts/react-select-custom';

export const MultiSelect = props => {
  const [selectInput, setSelectInput] = useState('');
  const isAllSelected = useRef(false);
  const selectAllLabel = useRef('Seleccionar todos');
  const allOption = { value: '*', label: selectAllLabel.current };
  const nameList = props?.nameList || 'item';
  const accentuation = props?.accentuation || 'a';

  const filterOptions = (options, input) =>
    options?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()));

  const comparator = (v1, v2) => v1.value - v2.value;

  let filteredOptions = filterOptions(props?.options, selectInput);
  let filteredSelectedOptions = filterOptions(props?.value, selectInput);

  const Option = props => (
    <components.Option {...props}>
      {props?.value === '*' && !isAllSelected.current && filteredSelectedOptions?.length > 0 ? (
        <input
          style={{ cursor: 'pointer' }}
          key={props?.value}
          type='checkbox'
          ref={input => {
            if (input) input.indeterminate = true;
          }}
        />
      ) : (
        <input
          style={{ cursor: 'pointer' }}
          key={props?.value}
          type='checkbox'
          defaultChecked={props?.isSelected || isAllSelected.current}
        />
      )}
      <label style={{ marginLeft: '5px', cursor: 'pointer', color: '#58595b' }}>
        {props?.label}
      </label>
    </components.Option>
  );

  const Input = props => (
    <>
      {selectInput.length === 0 ? (
        <components.Input autoFocus={props?.selectProps?.menuIsOpen} {...props}>
          {props?.children}
        </components.Input>
      ) : (
        <div style={{ border: '1px dotted gray' }}>
          <components.Input autoFocus={props?.selectProps?.menuIsOpen} {...props}>
            {props?.children}
          </components.Input>
        </div>
      )}
    </>
  );

  const customFilterOption = ({ value, label }, input) =>
    (value !== '*' && label.toLowerCase().includes(input.toLowerCase())) ||
    (value === '*' && filteredOptions?.length > 0);

  const onInputChange = (inputValue, event) => {
    if (event.action === 'input-change') setSelectInput(inputValue);
    else if (event.action === 'menu-close' && selectInput !== '') setSelectInput('');
  };

  const onKeyDown = e => {
    if ((e.key === ' ' || e.key === 'Enter') && !selectInput) e.preventDefault();
  };

  const handleChange = selected => {
    if (
      selected.length > 0 &&
      !isAllSelected.current &&
      (selected[selected.length - 1].value === allOption.value ||
        JSON.stringify(filteredOptions) === JSON.stringify(selected.sort(comparator)))
    )
      return props.onChange(
        [
          ...(props.value ?? []),
          ...props.options.filter(
            ({ label }) =>
              label.toLowerCase().includes(selectInput?.toLowerCase()) &&
              (props.value ?? []).filter(opt => opt.label === label).length === 0,
          ),
        ].sort(comparator),
      );
    else if (
      selected.length > 0 &&
      selected[selected.length - 1].value !== allOption.value &&
      JSON.stringify(selected.sort(comparator)) !== JSON.stringify(filteredOptions)
    )
      return props.onChange(selected);
    else
      return props.onChange([
        ...(props.value?.filter(
          ({ label }) => !label.toLowerCase().includes(selectInput?.toLowerCase()),
        ) ?? []),
      ]);
  };

  const ValueContainer = ({ children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
      const plural =
        values?.length === 1 ? ` seleccionad${accentuation}` : `s seleccionad${accentuation}s`;
      values = `${values.length} ${nameList}${plural}`;
    }

    return (
      <components.ValueContainer {...props}>
        <span style={{ color: '#58595b' }}>{values}</span>
        {input}
      </components.ValueContainer>
    );
  };

  if (props?.isSelectAll && props?.options?.length !== 0) {
    isAllSelected.current =
      JSON.stringify(filteredSelectedOptions) === JSON.stringify(filteredOptions);

    if (filteredSelectedOptions?.length > 0) {
      if (filteredSelectedOptions?.length === filteredOptions?.length)
        selectAllLabel.current = `Todos (${filteredOptions?.length}) seleccionados`;
      else {
        const plural =
          filteredSelectedOptions.length === 1
            ? `${nameList} seleccionad${accentuation}`
            : `${nameList}s seleccionad${accentuation}s`;
        selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions?.length} ${plural}`;
      }
    } else selectAllLabel.current = 'Seleccionar todos';

    allOption.label = selectAllLabel.current;

    return (
      <ReactSelect
        {...props}
        noOptionsMessage={() => 'No hay datos'}
        placeholder='Seleccionar...'
        inputValue={selectInput}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        options={props?.options?.length ? [allOption, ...props.options] : [allOption]}
        onChange={handleChange}
        components={{
          Option: Option,
          Input: Input,
          ValueContainer,
          ...props?.components,
        }}
        filterOption={customFilterOption}
        menuPlacement={props?.menuPlacement ?? 'auto'}
        styles={
          props.size === 'small'
            ? selectMultipleSmall
            : props.size === 'verySmall'
              ? selectMultipleVerySmall
              : selectMultiple
        }
        isMulti
        closeMenuOnSelect={false}
        tabSelectsValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        blurInputOnSelect={false}
        isSearchable
      />
    );
  }

  return (
    <ReactSelect
      {...props}
      styles={
        props.size === 'small'
          ? selectMultipleSmall
          : props.size === 'verySmall'
            ? selectMultipleVerySmall
            : selectMultiple
      }
      noOptionsMessage={() => 'No hay datos'}
      placeholder='Seleccionar...'
      inputValue={selectInput}
      onInputChange={onInputChange}
      filterOption={customFilterOption}
      components={{
        Input: Input,
        ValueContainer,
        ...props?.components,
      }}
      menuPlacement={props?.menuPlacement ?? 'auto'}
      onKeyDown={onKeyDown}
      tabSelectsValue={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      blurInputOnSelect={true}
      isSearchable
      closeMenuOnSelect={false}
    />
  );
};
