import { Drawer } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
//
import { usePostMethod } from '../../../Hooks';
import file from '../../../assets/img/icons/AdjuntarRecto.svg';
import { customSwaltAlert, loader, onChangeFile } from '../../../helpers';
//
import styles from '../../../components/Layouts/tableStyle.module.scss';

export default function LegalizeAction({ isOpen, selectedItems = [], onClose }) {
  const storage = useSelector(state => state);

  const firstItem = useMemo(() => selectedItems?.[0], [selectedItems]);

  const { trigger: legalizeAction, load: filingActionLoader } = usePostMethod();

  const [legalizePayload, setLegalizePayload] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    type: '',
    name: '',
    identification: '',
    date: moment().format('YYYY-MM-DD'),
    filingNumber: '',
    invoices: undefined,
    minutes: undefined,
    typeDoc: '',
    file64: '',
  });

  useEffect(() => {
    (async function () {
      if (firstItem) {
        setLegalizePayload(prev => ({
          ...prev,
          invoices: !firstItem.filing_status ? selectedItems.map(item => item.id) : undefined,
          minutes: firstItem.filing_status ? selectedItems.map(item => item.id) : undefined,
          date: firstItem.filing_date ?? firstItem.radicationDate,
          type: firstItem.type_filing,
        }));
      }
    })();
  }, [
    firstItem,
    selectedItems,
    storage.loginReducer.Authorization,
    storage.loginReducer.currentAccount.id,
  ]);

  const onCloseDrawer = () => {
    setLegalizePayload(prev => ({
      ...prev,
      type: '',
      date: moment().format('YYYY-MM-DD'),
      filingNumber: '',
      file64: '',
      identification: undefined,
      invoices: undefined,
      minutes: undefined,
      name: undefined,
      typeDoc: '',
      fileName: null,
    }));
    onClose();
  };

  const onLegalizeAction = () => {
    if (
      (legalizePayload.type === 'on_site' &&
        (!legalizePayload.filingNumber ||
          !legalizePayload.file64 ||
          !legalizePayload.name ||
          !legalizePayload.identification)) ||
      (legalizePayload.type === 'virtual' &&
        (!legalizePayload.filingNumber || !legalizePayload.file64))
    ) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, asegúrese de diligenciar todos los campos.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    legalizeAction({
      token: storage.loginReducer.Authorization,
      body: legalizePayload,
      method: 'POST',
      url: '/receivable/receiptRecord/file/',
      succesAction: () => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Información guardada',
          text: 'Se legalizó la radicación de la(s) factura(s).',
          confirmButtonText: 'Aceptar',
        }).then(result => {
          if (result.isConfirmed)
            // onSuccess();
            onCloseDrawer();
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onChangeDoc = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 3 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 3MB.',
        confirmButtonText: 'Aceptar',
      });
      setLegalizePayload({
        ...legalizePayload,
        file64: '',
        typeDoc: '',
        fileName: null,
      });
      e.target.value = null;
      return;
    }

    setLegalizePayload({
      ...legalizePayload,
      typeDoc: file.typeFile,
      fileName: file.fileName,
      file64: file.file,
    });
  };

  const render = () => {
    return (
      <>
        {filingActionLoader && loader}
        <Drawer
          open={isOpen}
          anchor='right'
          onClose={() => onCloseDrawer()}
          PaperProps={{ style: { width: 360 } }}
        >
          <div className='d-flex flex-column p-4 h-100'>
            <div
              className={`${styles.tlndrawer__close} align-self-end`}
              onClick={() => onCloseDrawer()}
            ></div>
            <h4 className='text-primary m-0 my-4 fw-bold'>Legalización de factura</h4>

            <div className='d-flex flex-column flex-grow-1'>
              <div className='row gy-3'>
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>Fecha de radicación</span>
                  </small>
                  <input
                    className='register-inputs'
                    defaultValue={moment(legalizePayload.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                    disabled
                  />
                </div>
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>No. de radicación</span>
                  </small>
                  <NumberFormat
                    className='register-inputs'
                    value={legalizePayload.filingNumber}
                    onValueChange={option => {
                      setLegalizePayload(prev => ({
                        ...prev,
                        filingNumber: option.formattedValue,
                      }));
                    }}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    decimalSeparator={undefined}
                  />
                </div>
                {legalizePayload.type === 'on_site' && (
                  <>
                    <div className='col-12'>
                      <small className='text-primary ms-1'>
                        <span>Nombre del receptor</span>
                      </small>
                      <input
                        type='text'
                        className='register-inputs'
                        value={legalizePayload.name}
                        onChange={({ target }) =>
                          setLegalizePayload(prev => ({ ...prev, name: target.value }))
                        }
                      />
                    </div>
                    <div className='col-12'>
                      <small className='text-primary ms-1'>
                        <span>Identificación del receptor</span>
                      </small>
                      <input
                        type='text'
                        className='register-inputs'
                        value={legalizePayload.identification}
                        onChange={({ target }) =>
                          setLegalizePayload(prev => ({ ...prev, identification: target.value }))
                        }
                      />
                    </div>
                  </>
                )}
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>Tipo de radicación</span>
                  </small>
                  <input
                    type='text'
                    className='register-inputs'
                    defaultValue={legalizePayload.type === 'on_site' ? 'Física' : 'Virtual'}
                    disabled
                  />
                </div>
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>Anexar</span>
                  </small>
                  <label
                    htmlFor='signature'
                    className='register-inputs d-flex align-items-center pointer overflow-hidden'
                  >
                    <span className={`flex-grow-1 ${styles.elipsis}`} style={{ maxWidth: '100%' }}>
                      {legalizePayload?.fileName ?? 'Cargar...'}
                    </span>
                    <input
                      id='signature'
                      type='file'
                      accept='image/png, image/jpeg, .pdf'
                      className='d-none'
                      onChange={onChangeDoc}
                    />
                    <img src={file} alt='agregar archivo' className={styles.iconFileStyle} />
                  </label>
                </div>
              </div>
              <div className='flex-grow-1'></div>
              <button
                onClick={() => onLegalizeAction()}
                className={`align-self-center ${styles.btnPrimaryAlt} mt-4 py-2`}
              >
                Guardar
              </button>
            </div>
          </div>
        </Drawer>
      </>
    );
  };

  return render();
}
