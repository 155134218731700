// by jhan ortegon

import { Component } from 'react';

// icons - images
import SearchIcon from '../../assets/img/icons/lupa.svg';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import CheckMark from '../../assets/img/icons/CheckMark.svg';
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import agregarEspecialidad from '../../assets/img/icons/agregarEspecialidad.svg';

// css
import 'reactjs-popup/dist/index.css';
import style from './providers.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// hooks
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

// helpers
import {
  defaultFilters,
  PAGE,
  PERPAGE,
  ProviderStatus,
  ProviderTypeShort,
} from '../../helpers/constants';
import deepcopy from 'deepcopy';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';

// services
import {
  cleanDrafts,
  getProviders,
  getEditProvider,
  getCIIU,
  updateProvider,
} from '../../actions/providersActions';

// Components
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
import Loader from 'react-loader-spinner';
import Pagination from 'react-js-pagination';
import withReactContent from 'sweetalert2-react-content';
import GenericTableNew from '../Layouts/GenericTableNew';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { customSelectNewDark } from '../Layouts/react-select-custom';

const MySwal = withReactContent(Swal);

class TableProviders extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getProviders({ ...defaultFilters, id_account: this.props.currentAccount.id });
    this.props.cleanDrafts();
    this.props.getEditProvider(null);
    this.setState(this.initialState);
  }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      modal: false,
      editRecord: false,
      redirect: null,
      addProvider: {
        description: null,
        barcode: null,
        property_: null,
        consignment: null,
        handle_batch: null,
        handle_serial: null,
        reference: null,
        cost_price: null,
        average_cost: null,
        tradename: null,
        data_sheet: null,
        active: null,
        manufacturer: '',
        inventory_family: null,
        measurement_units: [],
        add_info: [],
        provider_type: '',
      },
      filters: {
        search: '',
        perpage: PERPAGE,
        page: PAGE,
      },
      headerState: [
        {
          tittle: 'ㅤ',
          className: 'text-start',
        },
        {
          tittle: 'NIT / RUT',
          className: 'text-center px-2',
        },
        {
          tittle: 'Nombre/Razón Social',
          className: 'text-start',
        },
        {
          tittle: 'CIIU',
          className: 'text-center',
        },
        {
          tittle: 'Tipo',
          className: 'text-center',
        },
        {
          tittle: 'Inscripción',
          className: 'text-center',
        },
        {
          tittle: 'Actualización',
          className: 'text-center',
        },
        {
          tittle: 'Revisado',
          className: 'text-center',
        },
        {
          tittle: 'Estado',
          className: 'text-center w100',
        },
        {
          tittle: 'ㅤ',
          className: 'text-start',
        },
      ],
    };
  }

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(x => x.functionality?.prefix === 'prov');

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  }

  handleEditRecord = provider => {
    let { addProvider, redirect } = this.state;
    addProvider = deepcopy(provider);
    if (provider.provider_type === 'natural') {
      redirect = '/administracion/proveedores/selector/persona-natural';
    } else {
      redirect = '/administracion/proveedores/selector/persona-juridica';
    }
    this.props.getEditProvider(addProvider);
    this.setState({ addProvider, redirect });
  };

  // Search puc when user was typing
  onKeyUpSearch = e => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getProviders(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  onKeyDownNumber = e => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: 'error',
        title: 'Este campo solo acepta números',
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  handleDeleteRecord = provider => {
    this.props.updateProvider(
      {
        id: provider.id,
        status:
          provider.status === 'enabled'
            ? 'disabled'
            : provider.status === 'disabled'
              ? 'enabled'
              : provider.status,
      },
      () => this.props.getProviders(this.state.filters),
    );
  };

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal } = this.state;
    modal = !modal;
    this.setState({ modal });
  };

  // Method to get css style for circle in status
  getStatusClass = status => {
    switch (status) {
      case 'incomplete':
        return tableStyles.yellowState;

      case 'disabled':
        return tableStyles.redState;

      case 'enabled':
        return tableStyles.greenState;

      default:
        return tableStyles.redState;
    }
  };

  getClassCIIU = id_ciiu => {
    let klass = '-';
    this.props?.ciiu?.forEach(item => {
      if (item.id === parseInt(id_ciiu)) klass = item.klass;
    });
    return klass;
  };

  // Method to render providers in table
  renderProviders = provider => {
    let created_at = provider.created_at ? convertDateToLatinFormat(provider.created_at) : '-';
    let updated_at = provider.updated_at ? convertDateToLatinFormat(provider.updated_at) : '-';
    let circleClass = this.getStatusClass(provider.status);
    let ciiu = this.getClassCIIU(provider.ciiu);
    let checked = '-';
    if (provider.status !== 'incomplete' && !provider.checked) {
      checked = <img src={AlertTriangle} alt='No revisado' width='20' />;
    } else if (provider.status === 'enabled' && provider.checked) {
      checked = <img src={CheckMark} alt='No revisado' width='20' />;
    }
    const renderTooltip = props => (
      <Tooltip id='button-tooltip' {...props}>
        {ProviderStatus[provider.status]}
      </Tooltip>
    );
    return (
      <tr key={`prov-${provider.id}`} className='hover-table-row align-middle'>
        <td>ㅤ</td>
        <td className='text-center'>
          {provider.provider_type === 'natural' && provider.docNumLglRepresent !== ''
            ? provider.docNumLglRepresent
            : provider.nit
              ? provider.nit
              : '-'}
        </td>
        <td>
          {provider.provider_type === 'natural' &&
          provider.firstNameLglRepresent + provider.firstSurnameLglRepresent !== ''
            ? provider.firstNameLglRepresent + ' ' + provider.firstSurnameLglRepresent
            : provider.name
              ? provider.name
              : '-'}
        </td>
        <td className='text-center'>{ciiu}</td>
        <td className='text-center'>
          {provider.provider_type ? ProviderTypeShort[provider.provider_type] : '-'}
        </td>
        <td className='text-center'>{created_at}</td>
        <td className='text-center'>{updated_at}</td>
        <td className='text-center'>{checked}</td>
        <td>
          <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <div className={circleClass}>
              {provider.status === 'incomplete'
                ? 'Incompleto'
                : provider.status === 'enabled'
                  ? 'Habilitado'
                  : provider.status === 'disabled'
                    ? 'Inhabilitado'
                    : 'Inhabilitado'}
            </div>
          </OverlayTrigger>
        </td>
        <td>
          <img
            className='pointer'
            src={Filtrar}
            alt='lupa'
            onClick={() => {
              this.handleEditRecord(provider);
            }}
          />
        </td>
      </tr>
    );
  };

  onChangeSelect = event => {
    let { name, value } = event.target;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  onChangeReactSelect1 = event => {
    let { value } = event;
    let { filters } = this.state;
    filters['provider_type'] = value;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  onChangeReactSelect2 = event => {
    let { value } = event;
    let { filters } = this.state;
    filters['provider_type'] = value;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  // ANCHOR RENDER METHOD
  render() {
    let renderHeaders = this.state.headerState
      ? this.state.headerState.map((header, key) => {
          return (
            <th key={key} className={`${header.className}`}>
              {header.tittle}
            </th>
          );
        })
      : '';
    var renderProviders = this.props.providers
      ? this.props.providers.map(provider => {
          return this.renderProviders(provider);
        })
      : '';

    if (this.state.redirect) {
      let provider = this.state.addProvider;
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { provider },
          }}
        />
      );
    }

    return (
      <div className='w-100'>
        {this.props.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={`${tableStyles.container}`} style={{ padding: '0rem 8rem' }}>
          <div className='row'>
            <h1 className='top-h1'>Proveedores</h1>
          </div>

          {this.myPermission()?.read && (
            <div className='d-flex align-items-end'>
              <Form.Group className={`${style.selectGroup} me-3`} style={{ width: '20%' }}>
                <label htmlFor='personType' className={`${style.labelFont}`}>
                  Tipo de persona
                </label>
                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  name='provider_type'
                  id='personType_id'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  maxHeight={26}
                  onChange={this.onChangeReactSelect1}
                  options={[
                    { value: '', label: 'Seleccionar...' },
                    { value: 'natural', label: 'Natural' },
                    { value: 'legal_person', label: 'Jurídica' },
                  ]}
                />
              </Form.Group>
              <Form.Group className={`${style.selectGroup} me-3`} style={{ width: '20%' }}>
                <label htmlFor='active' className={`${style.labelFont}`}>
                  Estado
                </label>

                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  name='status'
                  id='status_id'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  maxHeight={26}
                  onChange={this.onChangeReactSelect2}
                  options={[
                    { value: '', label: 'Seleccionar...' },
                    { value: 'enabled', label: 'Activo' },
                    { value: 'disabled', label: 'Inactivo' },
                    { value: 'incomplete', label: 'Incompleto' },
                  ]}
                />
              </Form.Group>
              <Form.Group className='d-flex flex-grow-1 me-3'>
                <input
                  className={`${tableStyles.searchUsersAlt} `}
                  type='text'
                  name='search'
                  placeholder='Buscar...'
                  onKeyUp={this.onKeyUpSearch}
                  style={{ height: '25px' }}
                />
                <img
                  src={SearchIcon}
                  alt='buscar'
                  className='icons-svg-top-table float-left hoverPointer '
                  onClick={this.handleSearch}
                  style={{ width: '1.4rem' }}
                />
              </Form.Group>

              {this.myPermission()?.create && (
                <div className='col-md-3 mt-3 d-flex justify-content-end '>
                  <div className={`${style.groupBounding}`} style={{ whiteSpace: 'nowrap' }}>
                    <Link
                      className='align-bottom fw-bold text-decoration-none mt-2 align-items-center'
                      to='/administracion/proveedores/selector'
                    >
                      <label
                        htmlFor='newAccident '
                        className='darkGray fw-bold'
                        style={{ marginTop: '10px', whiteSpace: 'nowrap' }}
                      >
                        Nueva Vinculación
                      </label>
                    </Link>
                    <img
                      src={agregarEspecialidad}
                      alt='añadir'
                      className={`${style.btnAddTable} hoverPointer`}
                      onClick={this.toggleModal}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {this.myPermission()?.read && (
            <GenericTableNew
              fontFamilyTable={'fontFamilyTable'}
              headers={renderHeaders}
              dark={true}
              lowerCase={true}
            >
              {renderProviders}
            </GenericTableNew>
          )}

          {this.myPermission()?.read && (
            <div className={`${paginationStyles.wrapper} justify-content-between`}>
              <p style={{ display: 'inline' }}>
                Pag. {this.state.filters.page} de{' '}
                {Math.ceil(this.props.total_index / this.state.filters.perpage)
                  ? Math.ceil(this.props.total_index / this.state.filters.perpage)
                  : ''}{' '}
                ({this.props.total_index} encontrados)
              </p>
              <Pagination
                activePage={this.state.filters.page}
                itemsCountPerPage={this.state.filters.perpage}
                totalItemsCount={this.props.total_index}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChange.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  isAuth: state.loginReducer.Authenticated,
  providers: state.providerReducer.providers,
  row_total: state.providerReducer.row_total,
  total_index: state.providerReducer.total_index,
  ciiu: state.providerReducer.ciiu,
  loading: state.providerReducer.provider_loading,
});

const mapDispatchToProps = dispatch => ({
  getProviders: params => {
    dispatch(getProviders(params));
  },
  cleanDrafts: () => {
    dispatch(cleanDrafts());
  },
  getEditProvider: provider => {
    dispatch(getEditProvider(provider));
  },
  getCIIU: () => {
    dispatch(getCIIU());
  },
  updateProvider: (data, doafter) => {
    dispatch(updateProvider(data, doafter));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableProviders);
