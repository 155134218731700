import { Radio } from '@material-ui/core';
import deepcopy from 'deepcopy';
import { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Add from '../../../assets/img/icons/ADD.svg';
import Adjuntar from '../../../assets/img/icons/Adjuntar.svg';
import backArrow from '../../../assets/img/icons/atras-icon.svg';
import blueBall from '../../../assets/img/icons/blueBall.svg';
import circleGreenCheck from '../../../assets/img/icons/circleGreenCheck.svg';
import minus from '../../../assets/img/icons/grayMinus.svg';

import {
  GET_EMPLOYEEE_EDUCATION,
  GET_EMPLOYEE_EXPERIENCE,
  GET_EMPLOYEE_INFO,
} from '../../../actions/actionTypes';
import { get_doc_types } from '../../../actions/deductionsAccrualsAction';
import { city_get, province_get } from '../../../actions/locationActions';
import {
  getCandidateInfo,
  getEducationLevel,
  getEmployeeEducation,
  getEmployeeExperience,
  updateCollaborator,
} from '../../../actions/partnersActions';
import { getCountries } from '../../../actions/providersActions';
import { customSwaltAlert, formatteDocument } from '../../../helpers';
import {
  bloodType,
  genderList,
  maritalStatusList,
  parentList,
  shirtSize,
} from '../../../helpers/constants';
import {
  convertBase64,
  expRegList,
  formatToRcSelect,
  isEmptyOrUndefined,
  placeMyIcon,
  rhFactor,
  validExpReg,
} from '../../../helpers/helpers';

import SelectComponent from '../../SelectComponent/SelectComponent';

import IndividualStyles from '../../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSelectNew } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';

export const AccordionForm = props => {
  const history = useHistory();
  const store = useSelector(state => state);
  const dispatch = useDispatch();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );
  const token = store.loginReducer.Authorization;
  const arrCountries = store.providerReducer.countries;
  const arrDocTypes = store.deductionsAccrualsReducer.allDocTypes;
  const arrProvinces = store.locationReducer.provinces;
  const arrEducationlvl = store.partnersReducer.educationLevel;
  let employeeInfo = store.partnersReducer.employee_info;
  let arrSections = employeeInfo?.sections;

  const [isLoading, setIsLoading] = useState(false);
  const [fullInfoGen, setFullInfoGen] = useState(false);
  const [inputs, setInputs] = useState({
    nacionality: employeeInfo?.country_id,
    fullName: employeeInfo?.full_name,
    ocupation: employeeInfo?.job_title,
    province: employeeInfo?.province_id,
    city: employeeInfo?.city_id,
    docType: employeeInfo?.doc_type,
    docNumber: employeeInfo?.doc_number,
    bornDate: employeeInfo?.birthdate,
    gender: employeeInfo?.gender,
    maritalStatus: employeeInfo?.marital_status,
    sons: employeeInfo?.children_qty,
    phone: employeeInfo?.mobile,
    email: employeeInfo?.email,
    address: employeeInfo?.home_address,
    emergencyContactName: employeeInfo?.emergency_contact,
    kinShip: employeeInfo?.emercont_relationship,
    emergencyPhone: employeeInfo?.emercont_mobile,
    blood: employeeInfo?.blood_type,
    bloodType: employeeInfo?.rh_factor,
    weight: employeeInfo?.weight_kg,
    height: employeeInfo?.heigth_cm,
    shirtSize: employeeInfo?.shirt_size,
    pantSize: employeeInfo?.pant_size,
    shoeSize: employeeInfo?.shoe_size,
    doc_issued: employeeInfo?.doc_issued,
    id_candidate: employeeInfo?.id_candidate,
    id_contract: employeeInfo?.id_contract,
    allergies: employeeInfo?.allergies,
    disabilities: employeeInfo?.disabilities,
    medicines: employeeInfo?.medicines,
    diseases: employeeInfo?.diseases,
  });
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);

  const formattedCountries = formatToRcSelect(arrCountries, 'id', 'name');
  const formattedDoctTypes = formatToRcSelect(arrDocTypes, 'id', 'description');
  const formattedProvinces = formatToRcSelect(arrProvinces, 'id', 'name');
  const formattedEdlvl = formatToRcSelect(arrEducationlvl, 'id', 'description');
  const [info, setInfo] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setExperience();
    dispatch(getCountries());
    dispatch(get_doc_types());
    dispatch(getEducationLevel());
    dispatch(
      getCandidateInfo(
        {
          id_contract: props?.hvDetail?.data?.id_contract ?? employeeInfo?.id_contract,
          id_candidate: props?.hvDetail?.data?.id_candidate ?? employeeInfo?.id_candidate,
        },
        () => setIsLoading(false),
      ),
    );

    dispatch(
      getEmployeeEducation({
        id_contract: props?.hvDetail?.data?.id_contract ?? employeeInfo?.id_contract,
        id_candidate: props?.hvDetail?.data?.id_candidate ?? employeeInfo?.id_candidate,
        education: 1,
      }),
    );
    dispatch(
      getEmployeeExperience({
        id_contract: props?.hvDetail?.data?.id_contract ?? employeeInfo?.id_contract,
        id_candidate: props?.hvDetail?.data?.id_candidate ?? employeeInfo?.id_candidate,
        workExperience: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (employeeInfo) {
      Object.keys(employeeInfo)?.length > 0 &&
        setInputs({
          nacionality: employeeInfo?.country_id,
          fullName: employeeInfo?.full_name,
          ocupation: employeeInfo?.job_title,
          province: employeeInfo?.province_id,
          city: employeeInfo?.city_id,
          docType: employeeInfo?.doc_type,
          docNumber: employeeInfo?.doc_number,
          bornDate: employeeInfo?.birthdate,
          gender: employeeInfo?.gender,
          maritalStatus: employeeInfo?.marital_status,
          sons: employeeInfo?.children_qty,
          phone: employeeInfo?.mobile,
          email: employeeInfo?.email,
          address: employeeInfo?.home_address,
          emergencyContactName: employeeInfo?.emergency_contact,
          kinShip: employeeInfo?.emercont_relationship,
          emergencyPhone: employeeInfo?.emercont_mobile,
          blood: employeeInfo?.blood_type,
          bloodType: employeeInfo?.rh_factor,
          weight: employeeInfo?.weight_kg,
          height: employeeInfo?.heigth_cm,
          shirtSize: employeeInfo?.shirt_size,
          pantSize: employeeInfo?.pant_size,
          shoeSize: employeeInfo?.shoe_size,
          doc_issued: employeeInfo?.doc_issued,
          allergies: employeeInfo?.allergies,
          disabilities: employeeInfo?.disabilities,
          medicines: employeeInfo?.medicines,
          diseases: employeeInfo?.diseases,
        });
    }
  }, [employeeInfo]);

  useEffect(() => {
    setExperience([...store.partnersReducer.experience]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.partnersReducer.experience.length]);

  useEffect(() => {
    setEducation(
      store.partnersReducer.education.map(e => ({
        ...e,
        education_level: e.id,
        target: {
          name: 'education_level',
          value: e.id,
        },
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.partnersReducer.education.length]);

  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs?.nacionality) &&
      !isEmptyOrUndefined(inputs?.fullName) &&
      !isEmptyOrUndefined(inputs?.ocupation) &&
      !isEmptyOrUndefined(inputs?.province) &&
      !isEmptyOrUndefined(inputs?.city) &&
      !isEmptyOrUndefined(inputs?.docType) &&
      !isEmptyOrUndefined(inputs?.docNumber) &&
      !isEmptyOrUndefined(inputs?.bornDate) &&
      !isEmptyOrUndefined(inputs?.gender) &&
      !isEmptyOrUndefined(inputs?.maritalStatus) &&
      !isEmptyOrUndefined(inputs?.sons) &&
      !isEmptyOrUndefined(inputs?.phone) &&
      !isEmptyOrUndefined(inputs?.email) &&
      !isEmptyOrUndefined(inputs?.address) &&
      !isEmptyOrUndefined(inputs?.emergencyContactName) &&
      !isEmptyOrUndefined(inputs?.kinShip) &&
      !isEmptyOrUndefined(inputs?.emergencyPhone) &&
      !isEmptyOrUndefined(inputs?.blood) &&
      !isEmptyOrUndefined(inputs?.bloodType) &&
      !isEmptyOrUndefined(inputs?.weight) &&
      !isEmptyOrUndefined(inputs?.height) &&
      !isEmptyOrUndefined(inputs?.shirtSize) &&
      !isEmptyOrUndefined(inputs?.pantSize) &&
      !isEmptyOrUndefined(inputs?.shoeSize) &&
      !isEmptyOrUndefined(inputs?.allergies) &&
      !isEmptyOrUndefined(inputs?.disabilities) &&
      !isEmptyOrUndefined(inputs?.medicines) &&
      !isEmptyOrUndefined(inputs?.diseases)
    ) {
      setFullInfoGen(true);
    } else {
      setFullInfoGen(false);
    }
  }, [inputs]);

  useEffect(() => {
    if (!isEmptyOrUndefined(inputs.province)) {
      dispatch(city_get({ province: inputs.province }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.province]);

  useEffect(() => {
    if (!isEmptyOrUndefined(inputs.nacionality)) {
      dispatch(province_get({ country: inputs.nacionality }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.nacionality]);

  const onChangeDoc = async (e, supportType, index, elem) => {
    // ANCHOR  on change doc
    const file = e.target.files[0];

    if (file === undefined) {
      let findFileIndex = info.findIndex(file => file.support_type === elem.prefix);
      if (findFileIndex !== -1) {
        let infoCopy = [...info];
        infoCopy = infoCopy.filter(file => file.support_type !== elem.prefix);
        setInfo(infoCopy);
      }
    }

    const validExtensions = ['zip', 'pdf', 'rar'];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = validExtensions.find(obj => obj === actFileExt[actFileExt.length - 1]);
      if (isEmptyOrUndefined(extension)) {
        e.target.value = '';
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }

      if (file.size > 50000000) {
        e.target.value = '';
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `Su archivo no puede tener un tamaño superior a 5MB`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        if (file.size <= 0) {
          e.target.value = '';
          return customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `Por favor, adjunte un archivo valido.`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
    } else {
      return console.error('Archivo vacio: ', file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    let ext = type_doc.split('/');
    let finalName = `${name_doc}.${ext[1]}`;

    let findFileIndex = info.findIndex(file => file.support_type === elem.prefix);
    if (findFileIndex !== -1) {
      let infoCopy = [...info];
      infoCopy[findFileIndex] = [
        {
          base64: file64,
          filename: finalName,
          type_doc: type_doc,
          support_type: supportType,
        },
      ];
    } else {
      setInfo([
        ...info,
        {
          base64: file64,
          filename: finalName,
          type_doc: type_doc,
          support_type: supportType,
        },
      ]);
    }
  };

  const [expCounter, setExpCounter] = useState(0);
  const [eduCounter, setEduCounter] = useState(0);

  const addEducation = () => {
    let key = 'education' + eduCounter;
    const toAdd = {
      description: '',
      education_level: '',
      graduation_date: '',
      id: key,
      institution_name: '',
      education_type: '',
    };
    education.push(toAdd);
    setEduCounter(eduCounter + 1);
  };

  const addExperience = () => {
    let key = 'experience' + expCounter;
    const toAdd = {
      id: key,
      end_date: '',
      start_date: '',
      description: '',
      company_name: '',
    };
    experience.push(toAdd);
    setExpCounter(expCounter + 1);
  };
  const deleteExperience = e => {
    let newArr = experience.filter(x => x.id !== e.id);
    dispatch({
      type: GET_EMPLOYEE_EXPERIENCE,
      payload: newArr,
    });
  };
  const deleteEducation = e => {
    let newArr = education.filter(x => x.id !== e.id);
    dispatch({
      type: GET_EMPLOYEEE_EDUCATION,
      payload: newArr,
    });
  };

  const handleChangeExperience = (e, i) => {
    const { name, value } = e.target;
    let copy = deepcopy(experience);
    copy[i][name] = value;
    setExperience(copy);
  };
  const handleChangeEducation = (e, i) => {
    const { name, value } = e.target;
    let copy = deepcopy(education);
    copy[i][name] = value;
    if (name === 'education_level') {
      copy[i].education_type = e.target.education_type;
    }
    setEducation(copy);
  };

  const renderSections = () => {
    let sections = [];
    if (Array.isArray(arrSections)) {
      arrSections.forEach((elem, index) => {
        let docs = [];
        if (Array.isArray(employeeInfo?.documents)) {
          const filteredDocs = employeeInfo?.documents.filter(doc => doc.section === elem.section);
          filteredDocs.forEach((elem2, index2) => {
            let filename = elem2?.s3_location?.split('/').pop();
            let ext = filename?.split('.')[1];

            const generaterow = (
              <div key={elem2.id}>
                <div className='d-flex justify-content-between'>
                  <Col xs={4} className='d-flex'>
                    &nbsp;
                    <Radio
                      checked={
                        !isEmptyOrUndefined(elem2.s3_location)
                          ? true
                          : info.findIndex(x => x.support_type === elem2.prefix) >= 0
                            ? true
                            : false
                      }
                      disabled={true}
                      style={{ color: '#00A551' }}
                    />
                    &nbsp;
                    <label
                      htmlFor={`checkBox-${elem2.id}`}
                      className=' text-secondary'
                      style={{ fontSize: '15px', alignSelf: 'center' }}
                    >
                      {elem2.doc_description}
                    </label>
                  </Col>
                  <Col
                    xs={8}
                    className='d-flex align-items-center'
                    style={{
                      marginRight: isEmptyOrUndefined(placeMyIcon(ext)) ? '0.5%' : '',
                    }}
                  >
                    <Col
                      xs={6}
                      className='d-flex justify-content-between ml-5 '
                      style={{
                        width: '40%',
                        border: '1px solid #01a0f6',
                        borderRadius: '5px',
                        height: '1.5rem',
                      }}
                    >
                      <input
                        style={{
                          textIndent: '-170px',
                        }}
                        type='file'
                        className='text-secondary cursorPointer'
                        name='documents'
                        id='file'
                        onChange={e => {
                          onChangeDoc(e, elem2.prefix, index2, elem2);
                        }}
                        multiple={false}
                      />
                      <img
                        className='cursorPointer'
                        width='25px'
                        src={Adjuntar}
                        title='descargar'
                        alt='icon'
                      />
                    </Col>
                    {isEmptyOrUndefined(placeMyIcon(ext)) && <></>}
                    &nbsp; &nbsp;
                    {elem2.s3_location ? (
                      <Col xs={6}>
                        &nbsp; &nbsp;
                        <img src={placeMyIcon(ext)} alt='icon'></img>
                        <input
                          readOnly={true}
                          // style={{ border: "none" }}
                          className={`register-inputs ${tableStyles.clearBlueText} hoverPointer 
                      ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput}`}
                          onClick={
                            isEmptyOrUndefined(elem2.s3_location)
                              ? null
                              : () => (window.location.href = elem2.s3_location)
                          }
                          style={{
                            backgroundColor: isEmptyOrUndefined(elem2.s3_location)
                              ? 'transparent'
                              : 'white',
                            border: 'none',
                          }}
                          value={
                            isEmptyOrUndefined(elem2.s3_location)
                              ? null
                              : elem2.s3_location.split('/').pop()
                          }
                        />
                      </Col>
                    ) : (
                      <Col xs={6}>&nbsp; &nbsp;</Col>
                    )}
                    &nbsp;
                  </Col>
                </div>
              </div>
            );
            docs.push(generaterow);
          });
        }
        const generateSec = (
          <div key={index}>
            <div>
              <p
                style={{ fontSize: 'inherit' }}
                className={`
              ${tableStyles.crudModalLabel}
              display-4 pb-2 text-dark `}
              >
                <b className={tableStyles.blackGrayBoldFont}>{elem.section}</b>
              </p>
              {docs}
            </div>
          </div>
        );
        sections.push(generateSec);
      });
    }
    return <div key={'sectionwrap'}>{sections}</div>;
  };

  const handleSubmit = async () => {
    try {
      let fd = fullDocuments() ? 20 : 0;
      let fI = fullInfoGen ? 20 : 0;
      let fEd = fullEducation() ? 20 : 0;
      let fEx = fullExperience() ? 20 : 0;
      let total = fd + fI + fEd + fEx;
      let validExp;
      experience.forEach(exp => {
        if (
          isEmptyOrUndefined(exp.end_date) ||
          isEmptyOrUndefined(exp.start_date) ||
          isEmptyOrUndefined(exp.description) ||
          isEmptyOrUndefined(exp.company_name)
        ) {
          validExp = false;
        } else {
          validExp = true;
        }
      });
      if (!validExp) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Todos los campos de experiencia son obligatorios',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      let validEducation;
      education.forEach(edu => {
        if (
          isEmptyOrUndefined(edu.description) ||
          isEmptyOrUndefined(edu.education_type) ||
          isEmptyOrUndefined(edu.graduation_date) ||
          isEmptyOrUndefined(edu.institution_name)
        ) {
          validEducation = false;
        } else {
          validEducation = true;
        }
      });
      if (!validEducation) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Todos los campos de educación son obligatorios',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }

      if (!fullInfoGen) {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `Todos los campos de información general son obligatorios`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      setIsLoading(true);
      const result = await updateCollaborator(
        {
          id_candidate: employeeInfo.id_candidate,
          id_contract: employeeInfo.id_contract,
          document: info,
          full_name: inputs.fullName,
          ocupation: inputs.ocupation,
          province_id: inputs.province,
          province_name: inputs.province.label,
          city_id: inputs.city,
          city_name: inputs.city.label,
          doc_type: inputs.docType,
          doc_number: inputs.docNumber,
          birthdate: inputs.birthdate,
          gender: inputs.gender,
          marital_status: inputs.maritalStatus,
          children_qty: inputs.sons,
          mobile: inputs.phone,
          email: inputs.email,
          home_address: inputs.address,
          emergency_contact: inputs.emergencyContactName,
          emercont_relationship: inputs.kinShip,
          emercont_mobile: inputs.emergencyPhone,
          blood_type: inputs.blood,
          rh_factor: inputs.bloodType,
          weight_kg: inputs.weight,
          heigth_cm: inputs.height,
          shirt_size: inputs.shirtSize,
          pant_size: inputs.pantSize,
          shoe_size: inputs.shoeSize,
          allergies: inputs.allergies,
          disabilities: inputs.disabilities,
          medicines: inputs.medicines,
          diseases: inputs.diseases,
          workExperience: experience,
          education: education,
          percentage: total + 20,
        },
        token,
      );

      if (result.success) {
        setIsLoading(false);
        handleBack();
        return customSwaltAlert({
          icon: 'success',
          title: 'Finalizada exitosamente',
          text: `Se ha finalizado la hoja de vida de: ${inputs?.fullName || ''}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        setIsLoading(false);
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    } catch (error) {
      setIsLoading(false);
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ha ocurrido un error',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */

  const handleBack = () => {
    dispatch({
      type: GET_EMPLOYEE_INFO,
      payload: {},
    });
    if (props?.setHvDetail) {
      props?.setHvDetail({
        ...props?.hvDetail,
        show: false,
        data: '',
        trigger: props?.hvDetail?.trigger + 1,
      });
    } else {
      history.push('/nomina/tmPersonal');
    }

    dispatch({
      type: GET_EMPLOYEE_EXPERIENCE,
      payload: [],
    });
    dispatch({
      type: GET_EMPLOYEEE_EDUCATION,
      payload: [],
    });
  };

  const fullEducation = () => {
    let temp = false;
    education.forEach(e => {
      if (
        !isEmptyOrUndefined(e.description) &&
        !isEmptyOrUndefined(e.education_level) &&
        !isEmptyOrUndefined(e.graduation_date) &&
        !isEmptyOrUndefined(e.institution_name)
      ) {
        temp = true;
      } else {
        temp = false;
      }
    });
    return temp;
  };
  const fullExperience = () => {
    let temp = false;
    experience.forEach(e => {
      if (
        !isEmptyOrUndefined(e.end_date) &&
        !isEmptyOrUndefined(e.start_date) &&
        !isEmptyOrUndefined(e.description) &&
        !isEmptyOrUndefined(e.company_name)
      ) {
        temp = true;
      } else {
        temp = false;
      }
    });
    return temp;
  };

  //ANCHOR full documents
  const fullDocuments = () => {
    let temp = false;
    let docsLength = employeeInfo?.documents?.length;
    let docCounter = 0;
    let docInfoCounter = 0;

    if (employeeInfo?.documents?.length > 0) {
      employeeInfo?.documents?.forEach(e => {
        if (!isEmptyOrUndefined(e.s3_location)) {
          docCounter = docCounter + 1;
        }
      });
    }
    if (info.length > 0) {
      info?.forEach(e => {
        if (!isEmptyOrUndefined(e.base64)) {
          docInfoCounter = docInfoCounter + 1;
        }
      });
      docInfoCounter = docInfoCounter + docCounter;
    }
    if (docCounter === docsLength || docsLength === docInfoCounter) {
      temp = true;
    }

    return temp;
  };

  return (
    <>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={`${tableStyles.bgColorWhite} w-100 p-2 container`}>
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={backArrow}
                alt='icon'
                className={`mr-2 mb-2 cursorPointer`}
              ></img>
              Hoja de vida
            </h1>
          </Col>
        </Row>
        {/* /* -------------------------------- Documents ------------------------------- */}
        <Accordion className='w-100'>
          <div className='w-100 mt-4 p-2 d-flex'>
            {/* Empty circle div */}

            {fullDocuments() ? (
              <div className={`mr-3`}>
                <img src={circleGreenCheck} alt='icon'></img>
              </div>
            ) : (
              <div className={`${tableStyles.emptyCircle} mr-3`}></div>
            )}

            <div className='w-100 ml-1'>
              <Accordion.Toggle eventKey={1} className={`bg-light w-100 accordionToggleButton`}>
                <div
                  style={{ backgroundColor: '#ffffff00', borderRadius: '12px' }}
                  className={`d-flex`}
                >
                  <div
                    style={{
                      width: '100%',
                      marginTop: '-8px',
                      'margin-left': '',
                      'border-radius': '10px ',
                    }}
                    className={`bg-light`}
                  >
                    <p
                      className={`text-secondary m-1`}
                      style={{
                        'text-align': 'left',
                        padding: '0 0 0 5px',
                      }}
                    >
                      <code className={`text-secondary`}>{`>`}</code>
                      <b> Documentos</b>
                    </p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={1}>
                <Card.Body
                  className={`bg-light mb-3`}
                  style={{
                    width: '100%',
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  <Row>
                    <Col
                      xs={11}
                      style={{
                        marginLeft: '56px',
                      }}
                    >
                      {renderSections()}
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
        {/* /* -------------------------------- General info ------------------------------- */}
        <Accordion className='w-100'>
          <div className='w-100 mt-1 p-2 d-flex'>
            {fullInfoGen ? (
              <div className={`mr-3`}>
                <img src={circleGreenCheck} alt=''></img>
              </div>
            ) : (
              <div className={`${tableStyles.emptyCircle} mr-3`}></div>
            )}
            <div className='w-100 ml-1'>
              <Accordion.Toggle eventKey={2} className={`bg-light w-100 accordionToggleButton`}>
                <div
                  style={{ backgroundColor: '#ffffff00', borderRadius: '12px' }}
                  className={`d-flex`}
                >
                  <div
                    style={{
                      width: '100%',
                      marginTop: '-8px',
                      'margin-left': '',
                      'border-radius': '10px ',
                    }}
                    className={`${tableStyles.bgColorClearBlue}`}
                  >
                    <p
                      className={`${tableStyles.darkBlueText} m-1`}
                      style={{
                        'text-align': 'left',
                        padding: '0 0 0 5px',
                      }}
                    >
                      <code className={`${tableStyles.darkBlueText}`}>{`>`}</code>
                      <b> Información general</b>
                    </p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={2}>
                <Card.Body
                  className={`${tableStyles.bgColorClearBlue} mb-3`}
                  style={{
                    width: '100%',
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  {/* /* ----------------------------- BASIC INFO ROW ----------------------------- */}
                  <Row className=''>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Nombre completo
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.string) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, fullName: e.target.value });
                          }
                        }}
                        value={inputs.fullName}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Profesión
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.string) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, ocupation: e.target.value });
                          }
                        }}
                        value={inputs.ocupation}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Nacionalidad
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={formattedCountries.find(obj => obj.value === inputs?.nacionality)}
                        options={formattedCountries}
                        onChange={e => {
                          setInputs({ ...inputs, nacionality: e.value });
                        }}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Departamento
                        <span className={'text-warning'}>*</span>
                      </p>

                      <SelectComponent
                        value={formattedProvinces.find(obj => obj.value === inputs?.province)}
                        options={formattedProvinces}
                        onChange={e => {
                          setInputs({ ...inputs, province: e.value });
                        }}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>

                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Lugar de expedición
                        <span className={'text-warning'}>*</span>
                      </p>

                      <input
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.string) ||
                            e.target.value === ''
                          ) {
                            setInputs({
                              ...inputs,
                              doc_issued: e.target.value,
                            });
                          }
                        }}
                        value={inputs.doc_issued}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>

                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Tipo de documento
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={formattedDoctTypes.find(obj => obj.value === inputs.docType)}
                        onChange={e => setInputs({ ...inputs, docType: e.value })}
                        options={formattedDoctTypes}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        No. de documento
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, docNumber: formatteDocument(e.target.value).value });
                          } else {
                            setInputs({ ...inputs, docNumber: 0 });
                          }
                        }}
                        value={formatteDocument(inputs.docNumber).format}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Fecha de nacimiento
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        type='date'
                        value={inputs.bornDate}
                        onChange={e => setInputs({ ...inputs, bornDate: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Genero
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={genderList.find(obj => obj.value === inputs?.gender)}
                        onChange={e => setInputs({ ...inputs, gender: e.value })}
                        options={genderList}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Estado civil
                        <span className={'text-warning'}>*</span>
                      </p>

                      <SelectComponent
                        value={maritalStatusList.find(obj => obj.value === inputs.maritalStatus)}
                        options={maritalStatusList}
                        onChange={e => setInputs({ ...inputs, maritalStatus: e.value })}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        No. de hijos
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.sons}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, sons: e.target.value });
                          } else {
                            setInputs({ ...inputs, sons: 0 });
                          }
                        }}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>

                    {/* /* ------------------------------- CONTACT ROW ------------------------------ */}
                  </Row>
                  <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                    <b>Contacto</b>
                  </h6>
                  <Row className=''>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Celular o teléfono
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.phone}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, phone: e.target.value });
                          } else {
                            setInputs({ ...inputs, phone: 0 });
                          }
                        }}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Correo electrónico
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.email}
                        type='email'
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                        onChange={e => setInputs({ ...inputs, email: e.target.value })}
                      />
                    </Col>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Dirección de domicilio
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.address}
                        onChange={e => setInputs({ ...inputs, address: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                  </Row>
                  {/* /* -------------------------------EMERGENCY CONTACT ROW ------------------------------ */}
                  <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                    <b>Contacto de emergencia</b>
                  </h6>
                  <Row className=''>
                    <Col xs={6}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Nombre del contacto
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.emergencyContactName}
                        onChange={e =>
                          setInputs({
                            ...inputs,
                            emergencyContactName: e.target.value,
                          })
                        }
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Parentesco
                        <span className={'text-warning'}>*</span>
                      </p>

                      <SelectComponent
                        value={parentList.find(obj => obj.value === inputs.kinShip)}
                        options={parentList}
                        onChange={e => setInputs({ ...inputs, kinShip: e.value })}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Teléfono
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.emergencyPhone}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({
                              ...inputs,
                              emergencyPhone: e.target.value,
                            });
                          } else {
                            setInputs({
                              ...inputs,
                              emergencyPhone: 0,
                            });
                          }
                        }}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                  </Row>
                  {/* /* ----------------------------- OTHERS  ----------------------------- */}
                  <Row className=''>
                    <h6 className={`${tableStyles.darkBlueText} mt-3`}>
                      <b>Otros datos</b>
                    </h6>

                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Grupo sanguíneo
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={bloodType.find(obj => obj.value === inputs.blood)}
                        options={bloodType}
                        onChange={e => setInputs({ ...inputs, blood: e.value })}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        RH
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={rhFactor.find(obj => obj.value === inputs.bloodType)}
                        options={rhFactor}
                        onChange={e => setInputs({ ...inputs, bloodType: e.value })}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Peso(KG)
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.weight}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, weight: e.target.value });
                          } else {
                            setInputs({ ...inputs, weight: 0 });
                          }
                        }}
                        placeholder='Peso en  kilogramos'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Estatura(CM)
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.height}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, height: e.target.value });
                          } else {
                            setInputs({ ...inputs, height: 0 });
                          }
                        }}
                        placeholder='Estatura en centimetros'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Alergias
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.allergies}
                        onChange={e => setInputs({ ...inputs, allergies: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Discapacidades
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.disabilities}
                        onChange={e => setInputs({ ...inputs, disabilities: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Medicamentos
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.medicines}
                        onChange={e => setInputs({ ...inputs, medicines: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Enfermedades
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.diseases}
                        onChange={e => setInputs({ ...inputs, diseases: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Talla de camisa
                        <span className={'text-warning'}>*</span>
                      </p>
                      <SelectComponent
                        value={shirtSize.find(obj => obj.value === inputs.shirtSize)}
                        options={shirtSize}
                        onChange={e => setInputs({ ...inputs, shirtSize: e.value })}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={customSelectNew}
                      ></SelectComponent>
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Talla de pantalón
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.pantSize}
                        onChange={e => setInputs({ ...inputs, pantSize: e.target.value })}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Talla de calzado
                        <span className={'text-warning'}>*</span>
                      </p>
                      <input
                        value={inputs.shoeSize}
                        onChange={e => {
                          if (
                            validExpReg(e.target.value, expRegList.number) ||
                            e.target.value === ''
                          ) {
                            setInputs({ ...inputs, shoeSize: e.target.value });
                          } else {
                            setInputs({ ...inputs, shoeSize: 0 });
                          }
                        }}
                        placeholder='Escribe...'
                        className='register-inputs text-secondary'
                        name='position'
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
        <Accordion className='w-100'>
          <div className='w-100 mt-1 p-2 d-flex'>
            {fullEducation() ? (
              <div className={`mr-3`}>
                <img src={circleGreenCheck} alt=''></img>
              </div>
            ) : (
              <div className={`${tableStyles.emptyCircle} mr-3`}></div>
            )}
            <div className='w-100 ml-1'>
              <Accordion.Toggle eventKey={1} className={`bg-light w-100 accordionToggleButton`}>
                <div
                  style={{ backgroundColor: '#ffffff00', borderRadius: '12px' }}
                  className={`d-flex`}
                >
                  <div
                    style={{
                      width: '100%',
                      marginTop: '-8px',
                      'margin-left': '',
                      'border-radius': '10px ',
                    }}
                    className={`${tableStyles.bgColorClearBlue}`}
                  >
                    <p
                      className={`${tableStyles.darkBlueText} m-1`}
                      style={{
                        'text-align': 'left',
                        padding: '0 0 0 5px',
                      }}
                    >
                      <code className={`${tableStyles.darkBlueText}`}>{`>`}</code>
                      <b> Educación y formación</b>
                    </p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={1}>
                <Card.Body
                  className={`${tableStyles.bgColorClearBlue} mb-3`}
                  style={{
                    width: '100%',
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  {/* /* ---------------------- CONDITIONAL EDUCATION RENDER ---------------------- */}
                  {education.length > 0 ? (
                    education.map((e, i) => {
                      return (
                        <Row key={i} className=''>
                          <Col xs={1} style={{ width: '1.333333%' }}>
                            <button
                              className={`mt-4`}
                              onClick={i === 0 ? null : () => deleteEducation(e)}
                            >
                              <img
                                src={i === 0 ? blueBall : minus}
                                style={{ marginTop: '', width: '16px' }}
                                alt='icon'
                              ></img>
                            </button>
                          </Col>
                          <Col xs={3}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Tipo de formación
                              <span className={'text-warning'}>*</span>
                            </p>
                            <SelectComponent
                              value={formattedEdlvl.find(
                                x => x.value === education[i]?.education_level,
                              )}
                              options={formattedEdlvl}
                              className='text-secondary'
                              placeholder={'Seleccionar...'}
                              styles={customSelectNew}
                              onChange={e => {
                                handleChangeEducation(
                                  {
                                    ...e,
                                    target: {
                                      education_type: e.label,
                                      name: 'education_level',
                                      value: e.value,
                                    },
                                  },
                                  i,
                                );
                              }}
                            ></SelectComponent>
                          </Col>
                          <Col xs={3}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Fecha de graduación
                              <span className={'text-warning'}>*</span>
                            </p>
                            <input
                              onChange={e => handleChangeEducation(e, i)}
                              value={e.graduation_date}
                              type='date'
                              placeholder='Escribe...'
                              className='register-inputs text-secondary'
                              name='graduation_date'
                            />
                          </Col>
                          <Col xs={5}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Nombre de la institución
                              <span className={'text-warning'}>*</span>
                            </p>
                            <input
                              onChange={e => handleChangeEducation(e, i)}
                              style={{ width: '107.5%' }}
                              value={e.institution_name}
                              placeholder='Escribe...'
                              className='register-inputs text-secondary'
                              name='institution_name'
                            />
                          </Col>
                          <Col xs={12}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              &nbsp;&nbsp;&nbsp;&nbsp; Descripción
                              <span className={'text-warning'}>*</span>
                            </p>
                            <textarea
                              onChange={e => handleChangeEducation(e, i)}
                              value={e.description}
                              style={{
                                height: '3rem',
                                width: '95%',
                                marginLeft: '2%',
                              }}
                              rows='5'
                              cols='40'
                              name='description'
                              className='register-inputs text-secondary'
                            />
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  <Row className='d-flex' style={{ width: '100%' }}>
                    <Col xs={6}>
                      <button onClick={addEducation}>
                        <div className='d-flex'>
                          <img width='17px' src={Add} alt=''></img>
                          <p className={`${tableStyles.crudModalLabel} text-secondary`}>
                            Agregar formación
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
        {/* /* -------------------------------- Experiencia------------------------------- */}
        <Accordion className='w-100'>
          <div className='w-100 mt-1 p-2 d-flex'>
            {/* Empty circle div */}
            {fullExperience() ? (
              <div className={`mr-3`}>
                <img src={circleGreenCheck} alt=''></img>
              </div>
            ) : (
              <div className={`${tableStyles.emptyCircle} mr-3`}></div>
            )}
            <div className='w-100 ml-1'>
              <Accordion.Toggle eventKey={1} className={`bg-light w-100 accordionToggleButton`}>
                <div
                  style={{ backgroundColor: '#ffffff00', borderRadius: '12px' }}
                  className={`d-flex`}
                >
                  <div
                    style={{
                      width: '100%',
                      marginTop: '-8px',
                      'margin-left': '',
                      'border-radius': '10px ',
                    }}
                    className={`${tableStyles.bgColorClearBlue}`}
                  >
                    <p
                      className={`${tableStyles.darkBlueText} m-1`}
                      style={{
                        'text-align': 'left',
                        padding: '0 0 0 5px',
                      }}
                    >
                      <code className={`${tableStyles.darkBlueText}`}>{`>`}</code>
                      <b> Experiencia</b>
                    </p>
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={1}>
                <Card.Body
                  // style
                  className={`${tableStyles.bgColorClearBlue} mb-3`}
                  style={{
                    width: '100%',
                    borderRadius: '0 0 10px 10px',
                  }}
                >
                  {experience.length > 0 ? (
                    experience.map((e, i) => {
                      return (
                        <Row className='' key={`Row-experience${i}`}>
                          <Col xs={1} style={{ width: '1.333333%' }}>
                            <button
                              className={`mt-4`}
                              onClick={
                                i === 0 ? null : () => deleteExperience(e)
                                // i > 0 ? () => deleteExperience(e) : null
                              }
                            >
                              <img
                                src={i === 0 ? blueBall : minus}
                                style={{ marginTop: '', width: '16px' }}
                                alt=''
                              ></img>
                            </button>
                          </Col>
                          <Col xs={5}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Nombre de la empresa
                              <span className={'text-warning'}>*</span>
                            </p>
                            <input
                              onChange={e => handleChangeExperience(e, i)}
                              value={e.company_name}
                              type='text'
                              placeholder='Escribe...'
                              className='register-inputs text-secondary'
                              name='company_name'
                            />
                          </Col>
                          <Col xs={3} style={{ width: '26.5%' }}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Fecha de inicio
                              <span className={'text-warning'}>*</span>
                            </p>
                            <input
                              value={e.start_date}
                              type='date'
                              onChange={e => handleChangeExperience(e, i)}
                              placeholder='Escribe...'
                              className='register-inputs text-secondary'
                              name='start_date'
                            />
                          </Col>
                          <Col xs={3} style={{ width: '26.5%' }}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              Fecha final
                              <span className={'text-warning'}>*</span>
                            </p>
                            <input
                              value={e.end_date}
                              onChange={e => handleChangeExperience(e, i)}
                              type='date'
                              placeholder='Escribe...'
                              className='register-inputs text-secondary'
                              name='end_date'
                            />
                          </Col>
                          <Col xs={12}>
                            <p className={`${tableStyles.crudModalLabel} `}>
                              &nbsp;&nbsp;&nbsp;&nbsp; Descripción
                              <span className={'text-warning'}>*</span>
                            </p>
                            <textarea
                              name='description'
                              onChange={e => handleChangeExperience(e, i)}
                              value={e.description}
                              style={{
                                height: '3rem',
                                width: '95%',
                                marginLeft: '2%',
                              }}
                              rows='5'
                              cols='40'
                              className='register-inputs text-secondary'
                            />
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <></>
                  )}
                  <Row className='d-flex' style={{ width: '100%' }}>
                    <Col xs={6}>
                      <button onClick={addExperience}>
                        <div className='d-flex'>
                          <img width='17px' src={Add} alt=''></img>
                          <p className={`${tableStyles.crudModalLabel} text-secondary`}>
                            Agregar experiencia
                          </p>
                        </div>
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
        <div className={`customTabs__Footer mt-5`}>
          {!!myPermission.edit && (
            <Button
              onClick={() => handleSubmit()}
              className={tableStyles.btnPrimary}
              // disabled={able ? false : true}
              // disabled={isEmptyOrUndefined(inputs.colaborator) ? true : false}
              disabled={inputs.colaborator === null ? true : false}
            >
              Guardar
            </Button>
          )}

          <Button
            variant='outline-primary'
            onClick={() => handleBack()}
            className={tableStyles.btnSecondary}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};
