import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import estetoscopic from '../../assets/img/icons/estetoscopic.svg';
import medicine from '../../assets/img/icons/medicine.svg';
import pad from '../../assets/img/icons/pad.svg';
import references from '../../assets/img/icons/references.svg';
import { OrdBox } from '../OrderingComponents/OrdBox';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate';

export const Consult = () => {
  let history = useHistory();
  return (
    <div className='d-flex w-100 mx-auto container pt-3'>
      <OrdGenericTemplate title={'Consultas'}>
        <label
          style={{
            color: '#6E6F7C',
            fontSize: '24px',
            padding: '4px',
          }}
        >
          Selecciona el tipo de consulta a realizar
        </label>
        <Col>
          <Row className={`justify-content-between p-3`}>
            <OrdBox
              img={medicine}
              titleCardBox={'Historial de Medicamentos'}
              buttonAction={() => history.push('/ordenamientos/Consultas/Medicamentos')}
            ></OrdBox>
            <OrdBox
              img={estetoscopic}
              titleCardBox={'Historial de Servicios'}
              buttonAction={() => history.push('/ordenamientos/Consultas/Servicios')}
            ></OrdBox>
            <OrdBox
              img={references}
              titleCardBox={'Historial de Referencias'}
              buttonAction={() => history.push('/ordenamientos/Consultas/Referencias')}
            ></OrdBox>
            <OrdBox
              img={pad}
              titleCardBox={'Historial de Incapacidades'}
              buttonAction={() => history.push('/ordenamientos/Consultas/Incapacidades')}
            ></OrdBox>
          </Row>
        </Col>
      </OrdGenericTemplate>
    </div>
  );
};
