//hooks
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../Hooks';

//helpers
import { customSwaltAlertAsistencial, loader } from '../../../helpers';

//img
import ordDownload from '../../../assets/img/icons/ordDownload.svg';

//components
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

export const TableBills = ({ listBills, idOrder }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const siteId = store.userReducer.site;

  //////// HTTPS GET ////////////
  const { results: base64, load: loaderGetBase64, trigger: getBase64 } = useGetMethod();

  const onGetBase64 = ({ sequence }) => {
    getBase64({
      token: token,
      url: `/medical/admissions/provider-orders/${idOrder}/payments/`,
      objFilters: {
        eaccount,
        siteId,
        sequence,
      },
    }).then(res => {
      if (!res?.success) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      }

      document.querySelector('#downloaded').click();
    });
  };

  //////// HEADERS TABLE ////////////
  const headers = [
    {
      title: 'Factura',
      className: `px-2 text-center col-2`,
    },

    {
      title: 'Fecha',
      className: `px-2 text-center col-4`,
    },

    {
      title: 'Acción',
      className: `px-2 text-center col-4`,
    },
  ];

  //////// BODY TABLE ////////////
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='px-2'>{item?.sequence}</td>

            <td className='px-2'>{item?.date}</td>

            <td className='px-2'>
              <a
                hidden
                id='downloaded'
                download={`factura_${item?.sequence}_${item?.date}`}
                href={`data:application/pdf;base64,${base64?.results?.base64?.split("'")[1]}`}
              >
                &nbsp;
              </a>

              <img
                height={15}
                width={15}
                className='pointer'
                src={ordDownload}
                alt='descargar factura'
                onClick={() => onGetBase64({ sequence: item?.sequence })}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      {loaderGetBase64 && loader}

      <OrdTable
        shadow
        style={{ width: '400px', marginLeft: 'auto', marginTop: 'auto' }}
        headers={headers}
        hasChildren={true}
        oneHeadDarkBlue={false}
        lowerCase={false}
      >
        {formatData(listBills || [])}
      </OrdTable>
    </>
  );
};
