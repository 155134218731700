import { 
    INDIVIDUALAUCTION_GET_ONE,
    INDIVIDUALAUCTION_GET_ONE_LOADING,
    QUOTATION_FILTER,
    QUOTATION_FILTER_LOADING,
    INDIVIDUALAUCTION_FILTER,
   
    CURRENTAUCTION,
    PURCHASEORDER_GET_ONE,
    INDIVIDUALAUCTION_ARTICLE,
    PURCHASEORDER_GET_ONE_LOADING,
    AUTHORIZE_PURCHASE_ORDER,
    INDIVIDUALAUCTION_FILTER_LOADING,

    UPDATE_INDAUCTION_LOADING,
    CREATE_INDAUCTION_LOADING
    
    } from "../actions/actionTypes";
  
  const initialState = {
    familiainventario:[],
    cc_total:0,
    indauction: [],
    indauction_total:0,
    indauction_loading: false,
    selected_indauction:{},
    selected_poauction:{},
    selected_poauction_loading:false,
    selected_loading:false,
    quotations:[],
    quotations_loading:false,
    error: null,
    loading: false,
    loadingP: false,
    auctions:[],
    apo:[],
    apo_total:0,
    auctions_total:0,
    qty:[],
    qty_total:0,
    updateIndauction_loading: false,
    createIndauction_loading :false
  };

  export const individualAuctionReducer = (state = initialState, action) => {
    switch (action.type) {
        case INDIVIDUALAUCTION_ARTICLE:
            return {
                ...state,
                familiainventario: action.results,
                cc_total: action.row_total,
                error: null
            };
        case QUOTATION_FILTER_LOADING:
            return {
                ...state,
                quotations_loading: action.loading,
            };   
        case QUOTATION_FILTER:
            return {
                ...state,
                quotations: action.res,
                quotations_loading: action.loading,
            };
        
        case INDIVIDUALAUCTION_GET_ONE_LOADING:
            return {
                ...state,
                selected_loading: action.loading,
            };     
        case INDIVIDUALAUCTION_GET_ONE:
            return {
                ...state,
                selected_indauction: action.res,
                selected_loading: action.loading,
            };

        case INDIVIDUALAUCTION_FILTER:
            return {
                ...state,
                indauction: action.results,
                indauction_total: action.row_total,
                indauction_loading: action.loading,
                error: null
            };
        case INDIVIDUALAUCTION_FILTER_LOADING:
            return {
                ...state,
                indauction_loading: action.loading
            };

        case CURRENTAUCTION:
            return {
                ...state,
                auctions:action.results,
                auctions_total:action.row_total,
            };
        case AUTHORIZE_PURCHASE_ORDER:
            return {
                ...state,
                apo:action.results,
                apo_total:action.row_total,
            }
        case PURCHASEORDER_GET_ONE:
            return{
                ...state,
                selected_poauction: action.res,
                selected_poauction_loading: action.loadingP,
            }
        case PURCHASEORDER_GET_ONE_LOADING:
            return{
                ...state,
                selected_poauction_loading: action.loadingP,
            }
        
        case UPDATE_INDAUCTION_LOADING:
            return{
                ...state,
                updateIndauction_loading: action.loading,
            }
        case CREATE_INDAUCTION_LOADING:
            return{
                ...state,
                createIndauction_loading: action.loading,
            }
        default:
            return state;
    }
  };