import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, OverlayTrigger, Tooltip, ProgressBar, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import Chart from 'react-apexcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import redArrow from '../../assets/img/icons/redArrow.svg';
import yellowExclude from '../../assets/img/icons/yellowExclude.svg';
import greenDownArrow from '../../assets/img/icons/greenDownArrow.svg';
import Question from '../../assets/img/icons/question.svg';
import Saldos from '../../assets/img/icons/Saldos.svg';

import { month, year, day, isEmptyOrUndefined, generateId } from '../../helpers/helpers';
import {
  donutOptions,
  doubleLineAreaOptions,
  highChartVerticalLines,
} from '../../helpers/constants';
import {
  getAccToPay,
  getBalanceIncomeVsOutCome,
  getOverAllConsumption,
  getTopClients,
  getTopProviders,
  getTotalIncome,
  getAccountsReceivable,
  getBalanceInAcc,
  getDetailOfAccountBalances,
  getProfitBalance,
  getConsum,
  getWalletByAges,
} from '../../actions/managementActions';
import { getNiifAccounts } from '../../actions/pucActions';

import ModalNew from '../../components/Layouts/ModalNew';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { customSelectNewDark } from '../Layouts/react-select-custom';

import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import 'reactjs-popup/dist/index.css';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

export const Dashboard = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'glbInd',
  );
  const token = store.loginReducer.Authorization;
  let topClients = store?.managementReducer?.topClients;
  let incomeVsOutcome = store?.managementReducer?.incomeVsOutcome;
  let topProviders = store?.managementReducer?.topProviders;
  let accountsReceivable = store?.managementReducer?.receivableList;
  let balanceInAccount = store?.managementReducer?.balanceInAccount;
  let detailBalance = store?.managementReducer?.detailBalance;
  const consum = store?.managementReducer?.consum?.dashboard;
  let sign = store?.managementReducer?.walletByAges?.results?.balanceStatus;

  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
    day: day(),
  });
  const [filtersAcc, setFiltersAcc] = useState({
    month: month(),
    year: year(),
    day: day(),
    id_client: '',
  });
  const [showModal2, setShowModal2] = useState(false);
  const [overAllCon, setOverAllCon] = useState('0');
  const [totalIncome, setTotalIncome] = useState(0);
  const [accToPay, setAccToPay] = useState(0);

  let formatter = new Intl.NumberFormat(APP_LANGUAGE, {
    style: 'currency',
    currency: CURRENCY,
  });
  let optionsNiif = [];

  const [indicator, setIndicator] = useState({
    overAllConsum: 'Estable',
    accToPay: 'Estable',
    available: 'Estable',
  });

  if (Array.isArray(store.pucReducer.niifAccounts)) {
    store.pucReducer.niifAccounts.forEach(item => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }
  const getOverAll = async () => {
    const result = await getOverAllConsumption(
      {
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      },
      token,
    );
    if (result?.success) {
      setOverAllCon(result.result[0]?.overall_consumption);
      setIndicator({ ...indicator, overAllConsum: result.indicator });
    }
  };

  const getTinCome = async () => {
    const result = await getTotalIncome(
      {
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      },
      token,
    );
    if (result?.success) {
      setTotalIncome(result.result[0]?.saldo);
      setIndicator({ ...indicator, available: result.indicator });
    }
  };
  const toPay = async () => {
    const result = await getAccToPay(
      {
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      },
      token,
    );
    if (result?.success) {
      setAccToPay(result.result[0]?.totalAmount);
      setIndicator({ ...indicator, accToPay: result.indicator });
    }
  };

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/gerencia/inicio');
    }
    getOverAll();
    getTinCome();
    toPay();
    dispatch(
      getTopClients({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      }),
    );
    dispatch(
      getTopClients({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      }),
    );
    dispatch(
      getBalanceIncomeVsOutCome({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      }),
    );
    dispatch(
      getTopProviders({
        year: filters.year,
        month: filters.month,
        year_end: filters.year_end,
        account: store.loginReducer.currentAccount.id,
        dashboard: 1,
        month_end: filters.month_end,
        top: filters.top,
        sort: filters.sort,
      }),
    );
    dispatch(
      getAccountsReceivable({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
        client: '',
      }),
    );
    dispatch(
      getBalanceInAcc({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
        day: filters.day,
      }),
    );
    dispatch(
      getProfitBalance({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
        day: filters.day,
      }),
    );
    dispatch(
      getConsum({
        month: filters.month,
        year: filters.year,
        account: store.loginReducer.currentAccount.id,
      }),
    );

    dispatch(
      getWalletByAges({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
        client: filters.client,
      }),
    );
    dispatch(
      getNiifAccounts({
        eaccount: store.loginReducer.currentAccount.id,
        check_info_bank: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!isEmptyOrUndefined(filtersAcc.id_client)) {
      dispatch(
        getDetailOfAccountBalances({
          entity_account: store.loginReducer.currentAccount.id,
          month: filtersAcc.month,
          year: filtersAcc.year,
          id_client: filtersAcc.id_client,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAcc]);

  const getFivePreviousYear = () => {
    let array = [];
    let current_year = year();
    for (let step = 0; step <= 5; step++) {
      array.push(current_year - step);
    }
    return array;
  };

  const totalAccountsReceivable = () => {
    let total = 0;
    accountsReceivable?.data?.forEach(x => {
      total = total + x;
    });
    return formatter.format(total);
  };

  const totalDetailBalance = () => {
    let total = 0;
    detailBalance?.forEach(x => {
      total = total + Number(x.valor);
    });
    return formatter.format(total);
  };

  // --------------- Table 💥  ----------------//

  const generalConsumPopOver = props => (
    <Tooltip placement='top-end' id='button-tooltip' {...props}>
      Total de salida de consumo en el mes
    </Tooltip>
  );
  const totalBalcneCustomPopOver = props => (
    <Tooltip placement='top-end' id='button-tooltip' {...props}>
      Total de saldo disponible en cuentas de banco y cajas
    </Tooltip>
  );

  const headerTable = [
    <div key={1} className={`col-7`}>
      Movimiento
    </div>,
    <div key={2} className={`col-2`}>
      Fecha
    </div>,
    <div key={3} className={`col-3`}>
      Valor
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    let total = 0;
    if (Array.isArray(detailBalance)) {
      detailBalance.forEach((item, index) => {
        total = total + item.valor;
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-start col-7' style={{ paddingLeft: '4px' }}>
              {item.details ? item.details : '-'}
            </div>
            <div className='text-center col-2'>{item.date ? item.date : '-'}</div>
            <div
              className='text-end col-3'
              style={
                item.valor > 0 ? { paddingRight: '4px' } : { paddingRight: '4px', color: '#EC1C24' }
              }
            >
              {item.valor ? formatter.format(item.valor) : '-'}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      <ModalNew
        title={'Detalle por cuenta'}
        show={showModal2}
        btnYesName='Aceptar'
        btnYesEvent={() => {
          setShowModal2(false);
          setFiltersAcc({ ...filtersAcc, id_client: '' });
        }}
        onHide={() => {
          setShowModal2(false);
          setFiltersAcc({ ...filtersAcc, id_client: '' });
        }}
        btnNoEvent={() => {
          setShowModal2(false);
          setFiltersAcc({ ...filtersAcc, id_client: '' });
        }}
        size='700'
      >
        <Row className='d-flex'>
          <Col xs={12}>
            <p className={tableStyles.crudModalLabel}>Banco </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsNiif}
              placeholder={''}
              className='text-secondary'
              styles={customSelectNewDark}
              onChange={e =>
                setFiltersAcc({
                  ...filtersAcc,
                  id_client: e.value,
                })
              }
            />
          </Col>
        </Row>
        {!isEmptyOrUndefined(filtersAcc.id_client) && (
          <>
            <div>
              <GenericTableScroll
                headers={headerTable}
                dark={false}
                body={renderList()}
                typeHead={'3'}
              ></GenericTableScroll>
            </div>

            <Row className='d-flex'>
              <Col xs={5}>
                <div className='display-grid mt-1'>
                  <label className={tableStyles.crudModalLabel}>Saldo actual</label>
                  <input
                    className={IndividualStyles.registerInputs}
                    style={{ color: '#005DBF', fontWeight: '700' }}
                    value={totalDetailBalance()}
                  ></input>
                </div>
              </Col>
            </Row>
          </>
        )}
      </ModalNew>

      <div className={`${tableStyles.container}`}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Dashboard gerencial</h1>
          <Row className='justify-content-between mb-2'>
            <Col xs={3} className=' text-start'></Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filters.month}
                onChange={e => setFilters({ ...filters, month: Number(e.target.value) })}
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>
              <select
                value={filters.year}
                onChange={e => setFilters({ ...filters, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map(e => {
                  return (
                    <option key={generateId()} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col xs={3} className='cursorPointer'>
              <div
                className={`${tableStyles.cardBoxContainer} mr-2 m-2`}
                onClick={() => history.push('/gerencia/indicadores/cartera')}
              >
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Cuentas por cobrar</p>
                    <p className={tableStyles.cardBoxBottomText}>{totalAccountsReceivable()}</p>
                  </div>
                  <img
                    src={sign === '+' ? greenDownArrow : sign === '-' ? redArrow : yellowExclude}
                    className={tableStyles.cardBoxBottomText}
                    alt=''
                  />
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>
                      Consumo general &nbsp;
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 150, hide: 400 }}
                        overlay={generalConsumPopOver()}
                      >
                        <img src={Question} style={{ width: '13px' }} alt='' />
                      </OverlayTrigger>
                    </p>
                    <p className={tableStyles.cardBoxBottomText}>{formatter.format(overAllCon)}</p>
                  </div>
                  <img
                    src={
                      indicator.overAllConsum === 'Subió'
                        ? greenDownArrow
                        : indicator.overAllConsum === 'Bajó'
                          ? redArrow
                          : yellowExclude
                    }
                    className={tableStyles.cardBoxBottomText}
                    alt=''
                  />
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Cuentas por pagar</p>
                    <p className={tableStyles.cardBoxBottomText}>{formatter.format(accToPay)}</p>
                  </div>
                  <img
                    src={
                      indicator.accToPay === 'Subió'
                        ? greenDownArrow
                        : indicator.accToPay === 'Bajó'
                          ? redArrow
                          : yellowExclude
                    }
                    className={tableStyles.cardBoxBottomText}
                    alt=''
                  />
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>
                      Saldo disponible&nbsp;{' '}
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 150, hide: 400 }}
                        overlay={totalBalcneCustomPopOver()}
                      >
                        <img src={Question} style={{ width: '13px' }} alt='' />
                      </OverlayTrigger>
                    </p>
                    <p className={tableStyles.cardBoxBottomText}>{formatter.format(totalIncome)}</p>
                  </div>
                  <img
                    src={
                      indicator.available === 'Subió'
                        ? greenDownArrow
                        : indicator.available === 'Bajó'
                          ? redArrow
                          : yellowExclude
                    }
                    className={tableStyles.cardBoxBottomText}
                    alt=''
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={8}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Balance de ingresos vs gastos y costos</b>
                    </p>
                    <Chart
                      options={doubleLineAreaOptions({
                        categories:
                          incomeVsOutcome?.labels?.length > 0 ? incomeVsOutcome?.labels : [],
                        bottomTitle: 'Días',
                        leftTitle: 'Monto',
                        legendPosition: 'top',
                        colors: ['#005DBF', '#FF8B00'],
                      })}
                      series={incomeVsOutcome?.series?.length > 0 ? incomeVsOutcome?.series : []}
                      type='area'
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className={`${tableStyles.cardBoxContainer}`} style={{ height: '69vh' }}>
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Gastos por centro de consumo</b>
                    </p>

                    {consum?.labels?.length > 0 && consum?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels: consum?.labels?.length > 0 ? consum.labels : [],
                        })}
                        series={consum?.data?.length > 0 ? consum.data : []}
                        type='donut'
                        height={900}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                        height={900}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={6} className='cursorPointer' onClick={() => setShowModal2(true)}>
              <div className={`${tableStyles.cardBoxContainer}`}>
                <div className={`d-flex  justify-content-between`}>
                  <p className='text-secondary'>Saldos en cuentas</p>
                  <img src={Question} style={{ width: '13px' }} alt='' />
                </div>
                <p className={tableStyles.cardBoxBottomText}>
                  <img src={Saldos} width={'25px'} alt='' />
                  {formatter.format(balanceInAccount?.total_balance) || '0'}
                </p>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={highChartVerticalLines({
                    seriesName: 'Saldo en cuenta',
                    series: balanceInAccount?.series?.length > 0 ? balanceInAccount.series : [],
                  })}
                />
              </div>
            </Col>
            <Col xs={3} className='p-0 m-0'>
              <div className={`${tableStyles.cardBoxContainer} `} style={{ height: '77vh' }}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>TOP Proveedores</b>
                    <br></br>
                    <span className={tableStyles.f12}>Total facturado ult. 6 meses</span>{' '}
                  </p>
                  <div className={`${tableStyles.scrollThis}`}>
                    {topProviders?.result?.map((e, i) => {
                      return (
                        <>
                          <div className='d-flex '>
                            <span className='mr-1 text-info'>{i + 1}</span>
                            <div>
                              <div className={`d-flex justify-content-between ${tableStyles.f12}`}>
                                <span
                                  className={`${tableStyles.darkGrayText} ${tableStyles.ellipsis}`}
                                >
                                  {e.name}
                                </span>
                                <span className={`${tableStyles.darkGrayText}`}>
                                  {e.puntos + '%'}
                                </span>
                              </div>
                              <ProgressBar
                                style={{
                                  height: '5px',
                                }}
                                title={`${e.name} - ${e.cash}`}
                                className={`mb-1 rounded cursorPointer`}
                                now={e.puntos}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3} className='p-0 m-0'>
              <div className={`${tableStyles.cardBoxContainer} `} style={{ height: '77vh' }}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>TOP Clientes</b>
                    <br></br>
                    <span className={tableStyles.f12}>Total facturado ult. 6 meses</span>
                  </p>
                  <div className={`${tableStyles.scrollThis}`}>
                    {topClients.length > 0 &&
                      topClients.map((e, i) => {
                        return (
                          <>
                            <div className='d-flex'>
                              <span className='mr-1 text-info'>{i + 1}</span>
                              <div className=''>
                                <div
                                  className={`d-flex justify-content-between ${tableStyles.f12}`}
                                >
                                  <span
                                    className={`${tableStyles.darkGrayText}
                      ${tableStyles.ellipsis}
                      `}
                                  >
                                    {e.name}
                                  </span>
                                  <span className={`${tableStyles.darkGrayText}`}>
                                    {e.puntos + '%'}
                                  </span>
                                </div>
                                <ProgressBar
                                  style={{
                                    height: '5px',
                                  }}
                                  title={`${e.name} - ${e.cash}`}
                                  className={`mb-1 rounded cursorPointer`}
                                  now={e.puntos}
                                />
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
