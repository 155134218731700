//Login's reducer

import {
  GET_BUSINESS_TYPE,
  GET_SOCIETY_TYPE,
  GET_NIIF_ACCOUNTS,
  SET_INDEX_NIIF,
  GET_LOCAL_ACCOUNTS,
  SET_INDEX_LOCAL_ACCOUNTS,
  GET_ALL_ACCOUNTS,
  SET_TOTAL_INDEX_PUC,
  SET_INDEX_PUC,
  CREATE_PUC_ACCOUNT_SUCCESS,
  PUC_LOADING
} from "../actions/actionTypes";

const initialState = {
  niifAccounts: [],
  localAccounts: [],
  allAccounts: [],
  businessType: [],
  societyType: [],
  totalNiifAccount: 0,
  totalLocalAccount: 0,
  totalIndexAccount: 0,
  totalIndexPuc:0,
  createSucces: false,
  renderAccounts:false,
  puc_loading: false
};
export const pucReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NIIF_ACCOUNTS:
      return {
        ...state,
        niifAccounts: action.payload,
      };
    case PUC_LOADING:
      return {
        ...state,
        puc_loading: action.payload,
      };
    case GET_ALL_ACCOUNTS:
      return {
        ...state,
        allAccounts: action.payload,
        puc_loading: action.loading
      };
    case SET_INDEX_NIIF:
      return {
        ...state,
        totalNiifAccount: action.payload,
      };
    case GET_LOCAL_ACCOUNTS:
      return {
        ...state,
        localAccounts: action.payload,
      };
    case SET_INDEX_LOCAL_ACCOUNTS:
      return {
        ...state,
        totalLocalAccount: action.payload,
      };
    case SET_INDEX_PUC:
      return{
        ...state,
        totalIndexAccount:action.payload
      }
    case SET_TOTAL_INDEX_PUC:
      return{
        ...state,
        totalIndexPuc:action.payload
      }
    case CREATE_PUC_ACCOUNT_SUCCESS:
      return{
        ...state,
        createSucces:action.payload
      }
    case GET_BUSINESS_TYPE:
      return{
        ...state,
        businessType:action.payload
      }
    case GET_SOCIETY_TYPE:
      return{
        ...state,
        societyType: action.payload
      }
    default:
      return state;
  }
};
