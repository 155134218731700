import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import anonymus from '../../../../assets/img/icons/anonymus.svg';
import backArrow from '../../../../assets/img/icons/atras-icon.svg';

import { loader } from '../../../../helpers/helpers';
import { useGetMethod } from '../../../../Hooks/useFetch';

import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import TableComponentNom from './Components/TableComponentNom';

import 'reactjs-popup/dist/index.css';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import { useHasPermissionByTagModule } from '../../../../Hooks';

const DetailAnswerSurvey = () => {
  const store = useSelector(state => state);
  const history = useHistory();
  const location = useLocation();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'evalPerf',
  );
  const dataCandidate = location?.state?.data ?? {};
  const dataDetail = location?.state?.dataDetail ?? {};
  const action = location?.state?.action ?? {};
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;

  const {
    results: detailAnswerSurvey,
    load: detailAnswerSurveyLoader,
    trigger: detailAnswerSurveyMethod,
  } = useGetMethod();

  const [trigger, setTrigger] = useState(0);
  const [triggerAverage, setTriggerAverage] = useState(0);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    detailAnswerSurveyMethod({
      url: '/payroll/performance_evaluation_detail',
      objFilters: {
        id: dataDetail.id,
        idUserAssessed: dataCandidate.idUserAssessed,
        eaccount: idAccount,
      },
      token: token,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
        setTriggerAverage(triggerAverage + 1);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goBack = () => {
    history.push({
      pathname: '/nomina/EvaluacionDeDesempeño/Evaluacion',
      state: { data: dataDetail, action: action },
    });
  };

  const headers = [
    { label: 'Sección', className: `` },
    { label: 'Tipo', className: `text-center` },
    { label: 'Número de preguntas', className: `text-center` },
    { label: 'Promedio', className: `text-center col-2` },
  ];

  const [averageTotal, setAverageTotal] = useState(0);

  const body = () => {
    let bodyTable = [];
    if (detailAnswerSurvey?.results?.sections?.length > 0) {
      detailAnswerSurvey?.results?.sections?.length > 0 &&
        detailAnswerSurvey?.results?.sections.forEach(elem => {
          const bodyAccordion = (
            <div className='p-2' style={{ fontSize: '12px' }}>
              <Row>
                <Col
                  xs={10}
                  className={`d-flex flex-column ${tableStyles.tlnTextGray}`}
                  style={{ borderRight: '1px solid #CECECE' }}
                >
                  <div>
                    <b className={tableStyles.tlnTextDark}> Preguntas</b>
                  </div>
                </Col>
                <Col xs={2} className={`text-center ${tableStyles.tlnTextGray}`}>
                  <div>
                    <b className={tableStyles.tlnTextDark} style={{ fontSize: '12px' }}>
                      Resultados
                    </b>
                  </div>
                </Col>
              </Row>
              {elem.questions.length > 0 &&
                elem.questions.map(question => {
                  return (
                    <Row key={question.idAsk}>
                      <Col
                        xs={10}
                        className={`d-flex flex-column ${tableStyles.tlnTextGray}`}
                        style={{ borderRight: '1px solid #CECECE' }}
                      >
                        <div>
                          <span>{question.label}</span>
                        </div>
                      </Col>
                      <Col xs={2} className={`d-flex flex-column ${tableStyles.tlnTextGray}`}>
                        <div
                          className='d-flex justify-content-center text-center'
                          style={{ fontSize: '12px', color: '#6E6F7C', marginRight: '0.5rem' }}
                        >
                          {question.score}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
            </div>
          );

          bodyTable.push({
            label: ``,
            className: ``,
            bodyAccordion: bodyAccordion,
            data: [
              { label: elem.description, className: `` },
              {
                label: elem.type === 'specific' ? 'Específica' : 'Transversal',
                className: `text-center`,
              },
              { label: elem.numQuestions, className: `text-center` },
              { label: elem.averageQuestions, className: `text-center col-2` },
            ],
          });
        });
    }
    return bodyTable;
  };

  useEffect(() => {
    if (detailAnswerSurvey?.results?.sections?.length > 0) {
      let averageTotal = 0;
      detailAnswerSurvey?.results?.sections?.forEach(elem => {
        averageTotal += elem.averageQuestions;
      });
      setAverageTotal(averageTotal);
    }
  }, [detailAnswerSurvey, averageTotal]);
  return (
    <>
      {detailAnswerSurveyLoader && loader}
      <OrdGenericTemplate
        className='px-4 my-2 w-90'
        titleSize={12}
        style={{ overflow: 'auto', height: 'min-content' }}
        titleStyle={{ fontSize: '32px', marginTop: '40px' }}
      >
        <div className={`${tableStyles.container}`}>
          <Row className='d-flex'>
            <div className='d-flex'>
              <h1 className={tableStyles.title}>
                <img
                  onClick={() => goBack()}
                  alt='icon'
                  src={backArrow}
                  className={`mr-2 mb-2 cursorPointer`}
                ></img>
              </h1>

              <div className='mt-3 d-flex  ' style={{ marginLeft: '2rem' }}>
                <Card
                  border='primary'
                  className='mt-3 mb-2'
                  style={{
                    maxWidth: '115px',
                    maxHeight: '112px',
                    objectFit: 'cover',
                  }}
                >
                  <Card.Body className='m-0 p-0'>
                    <img
                      style={{
                        maxWidth: '110px',
                        maxHeight: '150px',
                        objectFit: 'cover',
                      }}
                      src={dataCandidate.photoCandidate ?? anonymus}
                      alt='imagen usuario'
                    />
                  </Card.Body>
                </Card>
                &nbsp; &nbsp;
                <div>
                  <h1 className={`${tableStyles.darkBlueText} mb-0 pt-0`}>
                    <b>{dataCandidate?.nameCandidate}</b>
                  </h1>
                  <h6 className={`${tableStyles.darkBlueText}`}>
                    <b>{dataCandidate?.jobPosition}</b>
                  </h6>
                  <h6 className={`${tableStyles.darkBlueText}`}>
                    <b> Evaluador | </b>
                    {dataCandidate.NameSupervisorPos}
                  </h6>
                </div>
              </div>
            </div>
          </Row>
          <Row className='p-4'>
            <TableComponentNom key={trigger} hasAccordion headers={headers} body={() => body()} />
            <div className={`"d-flex justify-content-end text-end" ${tableStyles.tlnTextDark}`}>
              <Row className='my-4'>
                <Col xs={4}></Col>
                <Col xs={6} className='text-center'>
                  <b> PROMEDIO TOTAL</b>
                </Col>
                <Col
                  xs={2}
                  className='text-center'
                  style={{ paddingRight: '2rem' }}
                  key={triggerAverage}
                >
                  <span>{averageTotal}</span>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default DetailAnswerSurvey;
