import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import DrawerTable from '../../AppointmentModule/Components/DrawerTable';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import '../../OrderingModule/orderingStyles.css';
import { getMedOrdActv, getPatientEvents, getPatientInfo } from '../../actions/patientActions';
import alertCircle from '../../assets/img/icons/alert-circle-auxiliary-red.svg';
import ordSearchClearBlue from '../../assets/img/icons/ordSearchClearBlue.svg';
import toOrderImg from '../../assets/img/icons/toOrderImg.svg';
import x from '../../assets/img/icons/x.svg';
import {
  ordCustomSelect,
  ordCustomSelectGreen,
} from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { maritalStatusList } from '../../helpers/constants';
import {
  convertDateToLatinFormat,
  formatToRcSelect,
  generateId,
  getPermission,
  isEmptyOrUndefined,
  message,
  today,
} from '../../helpers/helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { OrdCard } from '../OrderingComponents/OrdCard';
import OrdModal from '../OrderingComponents/OrdModal';
import { MultiTabForm } from './MultiTabForm';
import { formatteDocument } from './../../helpers';

export const Order = props => {
  let { fromTeleMed } = props;

  const [patientInfo, setPatientInfo] = useState({});
  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: '',
  });
  const [trigger, setTrigger] = useState(1);
  const location = useLocation();
  let isCloning = location.state && location.state.isCloning;
  let isCloningServices = location.state && location.state.isCloningServices;
  let cloneData = location.state && location.state.cloneData;
  const [detail, setDetail] = useState({
    showPacientDetail: false,
    showMultiTab: false,
    showCards: false,
    showEmptyEvents: false,
    showMedOrdActv: false,
  });
  const [dataForm, setDataForm] = useState(null);
  const [modalCancelOrd, setModalCancelOrd] = useState({
    show: false,
    reasonShow: false,
    allSelected: false,
  });
  const [dataCancel, setDataCancel] = useState({
    medicineIds: [],
    idReason: '',
    medicalOrdId: '',
  });
  const store = useSelector(state => state);
  const idEnterprise = store.loginReducer?.currentAccount?.id;
  const token = store.loginReducer?.Authorization;
  const idUser = store.loginReducer?.user_data.id;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'order', listPermission });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    eaccount: idEnterprise,
    document: isCloning
      ? cloneData?.patient?.document
      : fromTeleMed?.externalLink
        ? fromTeleMed?.data?.document
        : isCloningServices
          ? cloneData?.patient?.document
          : '',
    docType: fromTeleMed?.externalLink
      ? fromTeleMed?.data?.docType
      : isCloningServices
        ? cloneData?.patient?.docType || 1
        : isCloning
          ? cloneData?.patient?.docType || 1
          : 1, //TODO:
    consultDate: today(),
    createdBy: idUser,
    searchInTop: true,
  });

  // ------ 🀄 functions ------------//
  const getInitialState = async () => {
    return setPatientInfo({});
  };
  useEffect(() => {
    getDocumentTypes({
      url: '/payroll/document_types/',
      token: token,
    });
    getCancellationOrderReason({
      url: '/medical/CancellationOrderReason/',
      objFilters: { idAccount: idEnterprise, status: 'enabled' },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCloning) {
      getInfo();
    }
    if (isCloningServices) {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloning]);

  useEffect(() => {
    if (fromTeleMed?.externalLink) {
      if (
        !isEmptyOrUndefined(fromTeleMed?.data?.document) &&
        !isEmptyOrUndefined(fromTeleMed?.data?.docType)
      ) {
        getInfo();
      }

      // getInfo()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTeleMed?.externalLink]);

  useEffect(() => {
    if (patientInfo?.events?.length >= 0 && !fromTeleMed?.externalLink) {
      setDetail({
        ...detail,
        event: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo]);
  useEffect(() => {
    let resMedOrdActv = getMedOrdActv(
      {
        idAccount: idEnterprise,
        orderActives: 1,
        documentPatient: filters.document,
      },
      token,
    );
    setPatientInfo({ ...patientInfo, medOrdActvs: resMedOrdActv?.results });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfo = async e => {
    e?.preventDefault();
    setLoading(true);
    getInitialState().then(async () => {
      setDetail({ ...detail, showEmptyEvents: false });
      let resPatientInfo = await getPatientInfo(filters, token);
      let resEvents = await getPatientEvents(filters, token);
      let resMedOrdActv = await getMedOrdActv(
        {
          idAccount: idEnterprise,
          orderActives: 1,
          documentPatient: filters.document,
        },
        token,
      );
      if (!resPatientInfo?.success) {
        setLoading(false);
        const msg = resPatientInfo?.status >= 400 &&  resPatientInfo?.status < 500 ? "Intenta de nuevo" : "Error"
        const icon = resPatientInfo?.status >= 400 &&  resPatientInfo?.status < 500 ? "warning" : "error"
        return message(icon, msg, resPatientInfo?.message, null, null, true);
      }
      if (!resEvents?.success) {
        setLoading(false);
        setDetail({
          ...detail,
          showEmptyEvents: true,
          showCards: false,
          showMultiTab: false,
          showMedOrdActv: false,
        });
        return message('error', 'Error', resEvents?.message);
      } else if (resEvents?.results.length === 0) {
        setDetail({
          ...detail,
          showEmptyEvents: true,
          showCards: false,
          showMultiTab: false,
          showMedOrdActv: false,
        });
      } else {
        if (fromTeleMed?.externalLink) {
          setDetail({
            ...detail,
            showMultiTab: true,
            showCards: false,
            event:
              (resEvents?.results?.length > 0 &&
                resEvents?.results.find(x => x.clhId === Number(fromTeleMed?.data?.clhId))) ??
              null,
          });
          setDataForm({
            contractName:
              resEvents?.results?.length > 0 &&
              resEvents?.results?.find(x => x.clhId === Number(fromTeleMed?.data?.clhId))
                ?.contractName,
            corporateClientName:
              resEvents?.results?.length > 0 &&
              resEvents?.results?.find(x => x.clhId === Number(fromTeleMed?.data?.clhId))
                ?.cclientName,
          });
        } else {
          setDetail({
            ...detail,
            showEmptyEvents: false,
            showCards: true,
            showMultiTab: false,
            showMedOrdActv: false,
          });
        }
      }
      setPatientInfo({
        ...resPatientInfo?.results,
        events: resEvents?.results,
        medOrdActvs: resMedOrdActv?.results,
      });

      setLoading(false);
    });
  };
  let documentTypes = [];
  const { results: resDocumentTypes, trigger: getDocumentTypes } = useGetMethod();
  const formattedDiagnostics = formatToRcSelect(
    detail?.event?.diagnostics,
    'id',
    'description',
    '',
    '',
    'id',
    false,
  );
  const formattedExtendedDiagnostics = formatToRcSelect(
    detail?.event?.extendedDiagnostics,
    'id',
    'description',
    '',
    '',
    '',
    false,
  );

  const { results: reasons, trigger: getCancellationOrderReason } = useGetMethod();
  const formattedReasons = formatToRcSelect(
    reasons?.results,
    'id',
    'description',
    '',
    '',
    '',
    false,
  );
  const { load: cancelLoader, trigger: CancelOrder } = usePostMethod();
  const [selectedDiag, setSelectedDiag] = useState(null);

  if (resDocumentTypes?.success) {
    resDocumentTypes.results.forEach(item => {
      documentTypes.push({
        label: item.description,
        value: item.id,
      });
    });
  }

  const checkAllBox = checked => {
    setModalCancelOrd({ ...modalCancelOrd, allSelected: checked });
    let tempSelected = dataCancel?.medicineIds;
    if (checked) {
      const medicinesArray = fullDialogScreen?.medicineIds?.medicine;
      // eslint-disable-next-line array-callback-return
      medicinesArray?.map(elem => {
        let exists = tempSelected?.find(x => x.mdcId === elem.mdcId);
        if (!!exists === false) {
          tempSelected?.push(elem?.mdcId);
        }
      });
    } else {
      tempSelected = [];
    }
    setTrigger(trigger + 1);
    setDataCancel({ ...dataCancel, medicineIds: tempSelected });
  };
  const changeBoxSelected = item => {
    let tempSelected = dataCancel?.medicineIds;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected?.find(x => x === item); // Bool
      if (target) {
        tempSelected = tempSelected.filter(x => x !== item); // Si lo encuentra eliminalo
      } else {
        tempSelected.push(item); // Sino Añadelo
      }
      setDataCancel({ ...dataCancel, medicineIds: tempSelected });
    }
    if (tempSelected?.length === fullDialogScreen?.medicineIds?.medicine.length) {
      setModalCancelOrd({ ...modalCancelOrd, allSelected: true });
    } else {
      setModalCancelOrd({ ...modalCancelOrd, allSelected: false });
    }
  };
  const cancelMedicationOrder = () => {
    if (!dataCancel?.idReason) {
      return message(
        'info',
        `<span class=${tableStyles.ordClearBlueText}>Campos vacíos</span>`,
        'El motivo es obligatorio',
      );
    }
    if (dataCancel?.medicineIds.length === 0) {
      return message(
        'info',
        `<span class=${tableStyles.ordClearBlueText}>Campos vacíos</span>`,
        'Se debe seleccionar mínimo un medicamento',
      );
    }
    let body = {
      medicineIds: dataCancel?.medicineIds,
      idReason: dataCancel?.idReason,
      medicalOrdId: dataCancel?.medicalOrdId,
      allSelected: modalCancelOrd?.allSelected,
    };
    CancelOrder({
      url: '/medical/cancelMedicationOrder/',
      token: token,
      method: 'POST',
      body: body,
      doAfterSuccess: res => {
        getInfo();
        if (modalCancelOrd?.allSelected) {
          setFullDialogScreen({ id: dataCancel?.medicalOrdId, show: false });
        } else {
          setFullDialogScreen({ id: res.results, show: true });
        }
        setDataCancel({ idReason: '', medicalOrdId: res.results, medicineIds: [] });
        setModalCancelOrd({ reasonShow: false, show: false, allSelected: false });
      },
      succesAction: () =>
        message('success', `<span class=${tableStyles.ordClearBlueText}>Orden cancelada</span>`),
    });
  };
  const medicinesChecks = () => {
    let checks = fullDialogScreen?.medicineIds?.medicine.map((medicine, index) => {
      return (
        <Row xs={6} className={`d-flex justify-content-center  `} key={generateId()}>
          <div className={`d-flex w-100 `}>
            <input
              className={`border border-dark-blue form-check-input p1 check-dark-blue ${tableStyles.cursorPointer} `}
              key={index + trigger}
              type='checkbox'
              name='edit'
              checked={dataCancel?.medicineIds?.find(x => x === medicine.mdcId)}
              onClick={() => changeBoxSelected(medicine?.mdcId)}
            />
            <p style={{ color: '#6E6F7C' }} className={`ml-2 pl-3 `}>
              {medicine?.name}
            </p>
          </div>
        </Row>
      );
    });
    return checks;
  };
  const cardBodyAct = det => {
    let headers = [
      { title: 'Código', className: 'px-2 text-start  col-2' },
      { title: 'Nombre', className: 'px-2 text-start col-6' },
      { title: 'Cantidad ordenada', className: 'px-2 text-start col-4' },
    ];
    let body = det?.medicine?.map((medicine, index) => {
      return (
        <>
          <tr key={index}>
            <td className={`col-2 text-start px-2`}>{medicine?.mdcId} </td>
            <td className={`col-6 text-start px-2`}>{medicine?.name} </td>
            <td className={`col-4 text-start px-2`}>{medicine?.meoQtySTR} unds. </td>
          </tr>
        </>
      );
    });
    return (
      <>
        <Row className='mt-0'>
          <Col xs={detail.showPacientDetail ? 5 : 4}>
            <span>
              <b>Fecha de orden</b>
            </span>
          </Col>
          <Col xs={detail.showPacientDetail ? 7 : 8}>
            <span>{det?.date}</span>
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col xs={detail.showPacientDetail ? 5 : 4}>
            <span>
              <b>Ordenado por</b>
            </span>
          </Col>
          <Col xs={detail.showPacientDetail ? 7 : 8}>
            <span>{det?.doctor?.name}</span>
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col xs={detail.showPacientDetail ? 5 : 4}>
            <span>
              <b>Validez</b>
            </span>
          </Col>
          <Col xs={detail.showPacientDetail ? 7 : 8}>
            <span>{det.validUntilFormat}</span>
          </Col>
        </Row>
        <Row className='mt-0'>
          <Col xs={detail.showPacientDetail ? 5 : 4}>
            <span>
              <b>Observaciones</b>
            </span>
          </Col>
          <Col xs={detail.showPacientDetail ? 7 : 8}>
            <span>{det.morObservations}</span>
          </Col>
        </Row>
        <div className='mt-4'>
          <DrawerTable
            headers={headers}
            hasChildren={true}
            oneHeadDarkBlue={false}
            drawerThead={true}
            drawerPadding={true}
            drawerTable
          >
            {body}
          </DrawerTable>
        </div>
      </>
    );
  };
  const appendEvents = () => {
    let newArray = [];
    if (patientInfo?.events) {
      patientInfo.events.forEach(event => {
        if (fromTeleMed?.externalLink) {
          if (event.clhId === Number(fromTeleMed?.data?.clhId)) {
            newArray.push({
              title: `Evento #${event.clhId}`,
              clhId: event.clhId,
              detail: event.diagnostics.map(diagnostic => {
                return {
                  name: diagnostic?.id,
                  description: diagnostic?.description,
                };
              }),
            });
          }
        } else {
          newArray.push({
            title: `Evento #${event.clhId}`,
            detail: event.diagnostics.map(diagnostic => {
              return {
                name: diagnostic?.id,
                description: diagnostic?.description,
              };
            }),
          });
        }
      });
    }

    return newArray;
  };
  let events = appendEvents();

  return (
    <>
      <FullScreenDialog
        id='FullScreenDialog'
        onHide={() => setFullDialogScreen({ show: false })}
        isOpen={fullDialogScreen.show}
      >
        <RenderMedicalFormulasPdf
          withPagination
          pdfParams={{
            url: '/medical/pdfMedicationOrder/',
            id: fullDialogScreen.id,
          }}
          title={'Fórmula de medicamentos'}
          backAction={() => {
            setFullDialogScreen({ show: false });
            setDataCancel({ idReason: '', medicalOrdId: '', medicineIds: [] });
            setDetail({ ...detail, showMedOrdActv: true, showCards: false });
          }}
          cancel={{
            cancelAction: () => {
              setFullDialogScreen({ ...fullDialogScreen, show: false });
              setModalCancelOrd({ show: true });
            },
          }}
        />
      </FullScreenDialog>
      <OrdModal
        title={'Medicamentos a cancelar'}
        show={modalCancelOrd.show}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size='500'
        btnYesEvent={() => {
          setModalCancelOrd({ ...modalCancelOrd, show: false, reasonShow: true });
        }}
        onHide={() => {
          setModalCancelOrd({ show: false });
          setFullDialogScreen({ ...fullDialogScreen, show: true });
        }}
        btnNoEvent={() => {
          setModalCancelOrd({ show: false });
          setFullDialogScreen({ ...fullDialogScreen, show: true });
        }}
      >
        <Row xs={6} className={`d-flex justify-content-center  `}>
          <div className={`d-flex w-100 `}>
            <input
              className={`border border-dark-blue form-check-input p1 check-dark-blue ${tableStyles.cursorPointer} `}
              type='checkbox'
              name='edit'
              onClick={e => {
                checkAllBox(e.target.checked);
              }}
              checked={modalCancelOrd?.allSelected}
            />
            <p style={{ color: '#6e6f7c' }} className={`ml-2 pl-3 `}>
              Seleccionar todos
            </p>
          </div>
        </Row>
        {medicinesChecks()}
      </OrdModal>
      <OrdModal
        title={'Cancelar ordenamiento'}
        show={modalCancelOrd.reasonShow}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size='500'
        btnYesEvent={() => {
          cancelMedicationOrder();
        }}
        onHide={() => {
          setModalCancelOrd({ ...modalCancelOrd, reasonShow: false, show: true });
          setDataCancel({ ...dataCancel, idReason: '' });
        }}
        btnNoEvent={() => {
          setModalCancelOrd({ ...modalCancelOrd, reasonShow: false, show: true });
          setDataCancel({ ...dataCancel, idReason: '' });
        }}
      >
        <Col xs={12}>
          <Form.Group className='mb-3' controlId='fName'>
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <span className='text-start'>&nbsp;Motivo</span>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={formattedReasons}
              className='text-secondary'
              placeholder={'Seleccione'}
              styles={ordCustomSelect}
              onChange={e => setDataCancel({ ...dataCancel, idReason: e.value })}
            ></Select>
          </Form.Group>
        </Col>
      </OrdModal>

      {(!!loading || cancelLoader) && (
        <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
      )}
      <div
        className='w-100 d-flex'
        style={{
          height: 'fit-content',
        }}
      >
        {/* /* ---------------------------- LeftSide-- order ---------------------------- */}
        <Col
          style={{
            height: '141vh',
            maxHeight: '141vh',
          }}
          className={`${tableStyles.ordRightLineBlue} `}
          xs={detail.showPacientDetail ? 6 : 4}
        >
          {!detail.showPacientDetail ? (
            <div className='container-fluid '>
              <h2 className={`mx-3 ${tableStyles.ordDarkBlueText} px-4 mt-2`}>
                <b>&nbsp;{detail.showPacientDetail ? 'Detalle del paciente' : 'Ordenar'}</b>
              </h2>
              {/* /* -------------------------------- To order -------------------------------- */}
              {myPermission?.read && (
                <div className='m-3'>
                  {/* /* --------------------------- Special form Group --------------------------- */}
                  <Form.Group className={`${tableStyles.ordSearchSections} mb-4`} controlId='cc'>
                    <Form.Label className='m-0 pt-2'>
                      <h5 className={`${tableStyles.ordSubTitleStyle} ${tableStyles.f16}`}>
                        {/* Identificación del paciente */}
                        Documento de identificación
                      </h5>
                    </Form.Label>
                    <Row className='mx-3 mb-2 d-flex justify-content-between'>
                      <Col xs={7} style={{ paddingRight: '0' }}>
                        <form onSubmit={e => getInfo(e)}>
                          <Form.Control
                            className={`${tableStyles.ordClearBlueBorderAll} textSansCaption darkGray`}
                            style={{ height: '32px', color: 'gray' }}
                            type='text'
                            value={formatteDocument(filters.document).format}
                            onChange={e =>
                              setFilters({
                                ...filters,
                                document: formatteDocument(e.target.value).value,
                              })
                            }
                          />
                        </form>
                      </Col>
                      <Col xs={3} style={{ paddingRight: '0' }}>
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          options={documentTypes}
                          className='text-secondary'
                          placeholder={'C.C'}
                          styles={ordCustomSelectGreen}
                          value={documentTypes.filter(x => x.value === filters.docType)}
                          onChange={e => setFilters({ ...filters, docType: e.value })}
                        ></Select>
                      </Col>
                      <Col xs={2} className='m-0 p-0 align-self-center'>
                        <img
                          className='hoverPointer'
                          width='30px'
                          alt='search'
                          src={ordSearchClearBlue}
                          onClick={e => getInfo(e)}
                        ></img>
                      </Col>
                    </Row>
                    {/* <Row className='mx-3 mb-2 d-flex justify-content-between align-items-center'>
                      <Col xs={1}>
                        <input
                          className={` ${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
                          type='checkbox'
                          name='edit'
                          value='selectedAll'
                          checked={filters.searchInTop}
                          onClick={() =>
                            setFilters({ ...filters, searchInTop: !filters.searchInTop })
                          }
                        />
                      </Col>
                      <Col xs={11} style={{ fontSize: '13px', textAlign: 'start' }}>
                        <Form.Label
                          style={{ margin: '0', letterSpacing: '0.4px' }}
                          className={` ${tableStyles.ordAquaMarineText}`}
                        >
                          Buscar eventos en <b>ProgramasTOP</b>
                        </Form.Label>
                      </Col>
                    </Row> */}
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b>&nbsp;Primer nombre</b>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      placeholder=''
                      value={patientInfo?.firstName}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='lName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b>&nbsp;Primer apellido</b>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      value={patientInfo?.lastName}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='Enterprise'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b>&nbsp;Empresa</b>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      // value={
                      //   patientInfo?.contracts
                      //     ? patientInfo?.contracts?.filter((x) => x.isMain).pop()
                      //       ?.corporateClient
                      //     : ""
                      // }
                      value={dataForm?.corporateClientName || ''}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='Contract'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b>&nbsp;Contrato</b>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      // value={
                      //   patientInfo?.contracts
                      //     ? patientInfo?.contracts.filter((x) => x.isMain).pop()
                      //       ?.contractDescription
                      //     : ""
                      // }
                      value={dataForm?.contractName || ''}
                    />
                  </Form.Group>
                  <Form.Group className='mb-3' controlId='Contract'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b>&nbsp;Sede</b>
                    </Form.Label>
                    <Form.Control
                      disabled
                      className={`ord-roundInput`}
                      type='text'
                      value={detail?.event?.siteName || ''}
                    />
                  </Form.Group>
                  {!isEmptyOrUndefined(detail.event?.diagnostics) && (
                    <Form.Group className='mb-3' controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className='text-start'>&nbsp;Diagnóstico principal</b>
                      </Form.Label>
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        defaultValue={formattedDiagnostics.find(
                          x => x.value === detail?.event?.diagnostics[0]?.id,
                        )}
                        onChange={e => setSelectedDiag(e)}
                        options={formattedDiagnostics}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={ordCustomSelect}
                      ></Select>
                    </Form.Group>
                  )}
                  {!isEmptyOrUndefined(detail.event?.extendedDiagnostics) && (
                    <Form.Group className='mb-3' controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                        <b className='text-start'>&nbsp;Diagnóstico ampliado</b>
                      </Form.Label>
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        defaultValue={formattedExtendedDiagnostics.find(
                          x => x.value === detail?.event?.extendedDiagnostics[0]?.id,
                        )}
                        onChange={e => setSelectedDiag(e)}
                        options={formattedExtendedDiagnostics}
                        className='text-secondary'
                        placeholder={'Seleccionar...'}
                        styles={ordCustomSelect}
                      ></Select>
                    </Form.Group>
                  )}
                  <button
                    onClick={() =>
                      setDetail({ ...detail, showPacientDetail: true, showMedOrdActv: false })
                    }
                    className={`${tableStyles.outLineBigButton} mt-4 px-5`}
                  >
                    &nbsp; &nbsp;
                    <b>Detalle del paciente</b>
                  </button>
                  <button
                    disabled={!patientInfo?.medOrdActvs?.length > 0}
                    onClick={() =>
                      setDetail({
                        ...detail,
                        showMedOrdActv: true,
                        showCards: false,
                        showMultiTab: false,
                        showEmptyEvents: false,
                      })
                    }
                    className={`ordBtnBar mt-4 px-5`}
                  >
                    &nbsp; &nbsp;
                    <b>Ord. medicamentos activos</b>
                  </button>
                  <div className={`d-flex mt-4 w-100`}>
                    <div className={`d-flex h-100`}>
                      <img style={{ width: '15px' }} alt='info' src={alertCircle}></img>
                    </div>
                    <p className={`subLabel`}>
                      El/la paciente cuenta con {patientInfo?.medOrdActvs?.length} ordenamientos de
                      medicamentos activos
                    </p>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <img alt='order' src={toOrderImg}></img>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              // ${detail && tableStyles.scrollThis}
              className={`container-fluid `}
              // style={{ height: detail && "125vh" }}
            >
              <Row>
                <Col xs={12} className='d-flex justify-content-end'>
                  <div onClick={() => setDetail({ ...detail, showPacientDetail: false })}>
                    <img className='cursorPointer' src={x} alt='close'></img>
                  </div>
                </Col>
              </Row>
              <h2 className={`${tableStyles.ordDarkBlueText} px-4 mt-2`}>
                <b>
                  &nbsp;
                  {detail.showPacientDetail ? 'Detalle del paciente' : 'Ordenar'}
                </b>
              </h2>
              {/* /* -------------------------------- To order -------------------------------- */}
              <div className={`m-3`}>
                {/* /* --------------------------- Special form Group --------------------------- */}

                <Row
                  className={`${tableStyles.ordSearchSections} mx-3 justify-content-between text-start pb-2 mb-4`}
                >
                  <Col xs={6} className='px-4'>
                    <div className={`${tableStyles.pRight1Rem}`}>
                      <Form.Label className='align-self-middle m-0 pt-2 text-start'>
                        <span className={`${tableStyles.ordSubTitleStyle} `}>
                          {/* Identificación del paciente */}&nbsp;Identificación
                        </span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.document ? formatteDocument(patientInfo.document).format : formatteDocument(filters.document).format}
                      />{' '}
                    </div>
                  </Col>
                  <Col xs={6} className='px-4'>
                    <div className={`${tableStyles.pLeft1Rem}`}>
                      <Form.Label className='m-0 pt-2'>
                        <span className={`${tableStyles.ordSubTitleStyle} text-start`}>
                          &nbsp;Tipo de documento
                        </span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.docType}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Primer nombre</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.firstName}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Segundo nombre</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.otherNames}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Primer apellido</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.lastName}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Segundo apellido</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.secondSurname}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Fecha de nacimiento</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={convertDateToLatinFormat(patientInfo?.birthDate)}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Edad</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.age}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Whatsapp</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.whatsapp}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Género</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={
                          patientInfo?.gender === 'female'
                            ? 'Femenino'
                            : patientInfo?.gender === 'male'
                              ? 'Masculino'
                              : '-'
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Email</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.email}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Estado civil</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={
                          maritalStatusList?.find(e => e?.value === patientInfo?.maritalStatus)
                            ?.label || ''
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <h6 className={`${tableStyles.ordSubTitleStyle} pt-4 ml-3 text-start`}>
                  Información complementaria
                </h6>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Departamento</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.provinceName}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Municipio</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.cityName}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pRight1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Dirección</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.homeAddress}
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={6} className='px-4'>
                    <Form.Group className={`mb-3 ${tableStyles.pLeft1Rem}`} controlId='fName'>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                        <b>&nbsp;Grupo sanguíneo</b>
                      </Form.Label>
                      <Form.Control
                        disabled
                        className={`ord-roundInput`}
                        type='text'
                        value={patientInfo?.bloodType}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* /* --------------------------- Special form Group --------------------------- */}
                {patientInfo?.contracts?.length > 0 &&
                  patientInfo?.contracts?.map(contract => {
                    return (
                      <Row
                        key={generateId()}
                        className={`mx-2 ${
                          contract.isMain
                            ? tableStyles.ordSearchSections
                            : tableStyles.ordSearchSectionBLueOpacity
                        } mt-3 text-start pb-2`}
                      >
                        {' '}
                        <span
                          className={`${
                            contract.isMain
                              ? tableStyles.ordSubTitleStyle
                              : tableStyles.ordDarkBlueText
                          } fw-bold ml-3 mt-2`}
                        >
                          {contract.isMain ? 'Aseguradora obligatoria' : 'Aseguradora voluntaria'}
                        </span>
                        <Col className='mt-2 px-4' xs={6}>
                          <Form.Group className='mb-3 mr-3 ' controlId='fName'>
                            <Form.Label
                              className={`m-0 f-14 ${tableStyles.ordDarkBlueText} text-start`}
                            >
                              <b>&nbsp;Empresa</b>
                            </Form.Label>
                            <Form.Control
                              disabled
                              className={`ord-roundInput`}
                              type='text'
                              value={contract.corporateClient}
                            />
                          </Form.Group>
                        </Col>
                        <Col className='mt-2 px-4' xs={6}>
                          <Form.Group className='mb-3 ml-3' controlId='fName'>
                            <Form.Label
                              className={`m-0 f-14 ${tableStyles.ordDarkBlueText} text-start`}
                            >
                              <b>&nbsp;Contrato</b>
                            </Form.Label>
                            <Form.Control
                              disabled
                              className={`ord-roundInput`}
                              type='text'
                              value={contract?.contractDescription}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6} className='px-4'>
                          <Form.Group className='mb-3 mr-3' controlId='fName'>
                            <Form.Label
                              className={`m-0 f-14 ${tableStyles.ordDarkBlueText} text-start`}
                            >
                              <b>&nbsp;Origen</b>
                            </Form.Label>
                            <Form.Control
                              disabled
                              className={`ord-roundInput`}
                              type='text'
                              value={contract?.population}
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6} className='px-4'>
                          <Form.Group className='mb-3 ml-3' controlId='fName'>
                            <Form.Label
                              className={`m-0  f-14 ${tableStyles.ordDarkBlueText} text-start`}
                            >
                              <b>&nbsp;Grupo de ingreso</b>
                            </Form.Label>
                            <Form.Control
                              disabled
                              className={`ord-roundInput`}
                              type='text'
                              value={contract?.crtName}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </div>
          )}
        </Col>
        {/* /* --------------------- Right Side -- Detail , MultiTab -------------------- */}
        <Col
          xs={detail.showPacientDetail ? 6 : 8}
          style={{
            height: '141vh',
            maxHeight: '141vh',
          }}
          //       overflow-y: scroll;
          // max-height: 125vh;
          className={`${!detail.showMultiTab && tableStyles.ordBgAquaMBlueOpacity}`}
        >
          {/* /* -------------------------- MULTITABLE COMPONENT -------------------------- */}
          {detail.showMultiTab && myPermission?.read && (
            <MultiTabForm
              eventTitle={detail?.event?.title}
              clone={{
                isCloning: isCloning,
                isCloningServices: isCloningServices,
                cloneData: cloneData,
              }}
              event={{
                ...detail.event,
                incomingDiag: isEmptyOrUndefined(selectedDiag)
                  ? formattedDiagnostics.find(x => x.value === detail?.event?.diagnostics[0]?.id)
                  : selectedDiag,
              }}
              goBack={() => {
                setDetail({
                  ...detail,
                  showPacientDetail: false,
                  showMultiTab: false,
                  showCards: true,
                });

                setDataForm(null);
              }}
              documentPatient={filters?.document}
            ></MultiTabForm>
          )}
          {detail.showCards && myPermission?.read && (
            <div
              className={`px-3 pt-3  ${detail && tableStyles.scrollThis}`}
              style={{ height: '110vh' }}
            >
              <h4 className={`${tableStyles.ordDarkGrayText} ml-5 my-4`}>
                <b>Seleccione un evento para poder ordenar</b>
              </h4>
              {events.map((e, i) => {
                let cardBody = e.detail.map(det => {
                  return (
                    <>
                      <Row className='mt-0'>
                        <Col xs={detail.showPacientDetail ? 3 : 2}>
                          <span>
                            <b>{det.name}</b>
                          </span>
                        </Col>
                        <Col xs={detail.showPacientDetail ? 9 : 10}>
                          <span>{det.description}</span>
                        </Col>
                      </Row>
                    </>
                  );
                });
                return (
                  <>
                    <Col
                      className={`mb-2 px-5 cursorPointer`}
                      xs={11}
                      onClick={() => {
                        setDetail({
                          ...detail,
                          showMultiTab: true,
                          showCards: false,
                          event: fromTeleMed?.externalLink
                            ? patientInfo?.events.find(x => x.clhId === e.clhId)
                            : patientInfo?.events[i],
                        });
                        setDataForm({
                          contractName: patientInfo?.events[i]?.contractName,
                          corporateClientName: patientInfo?.events[i]?.cclientName,
                        });
                      }}
                    >
                      <OrdCard
                        className={`${tableStyles.cardHover}`}
                        titleClassName={`${tableStyles.ordDarkBlueText} ${tableStyles.f16}`}
                        title={
                          <h4>
                            <b> {e.title}</b>
                          </h4>
                        }
                        body={cardBody}
                      />
                    </Col>
                  </>
                );
              })}
            </div>
          )}
          {!!detail.showEmptyEvents && (
            <div
              className='d-flex justify-content-center m-auto flex-column text-center align-items-center'
              style={{ height: '70%' }}
            >
              <img src={alertCircle} alt='alerta' width='40px' />
              <p className='darkGray'>El paciente seleccionado no tiene eventos</p>
            </div>
          )}
          {detail.showMedOrdActv && (
            <div
              className={`px-3 pt-3  ${detail && tableStyles.scrollThis}`}
              style={{ height: '110vh' }}
            >
              {patientInfo?.medOrdActvs?.map(e => {
                return (
                  <>
                    <Col
                      className={`mb-4 px-5 cursorPointer`}
                      xs={11}
                      onClick={() => {
                        setDetail({
                          ...detail,
                          showMultiTab: false,
                          showCards: false,
                          showMedOrdActv: false,
                        });
                        setFullDialogScreen({
                          id: e.id,
                          show: true,
                          medicineIds: e,
                        });
                        setDataCancel({
                          ...dataCancel,
                          medicalOrdId: e.id,
                        });
                      }}
                    >
                      <OrdCard
                        className={`${tableStyles.cardHover}`}
                        titleClassName={`${tableStyles.ordDarkBlueText} ${tableStyles.f16}`}
                        title={
                          <h4>
                            <b> Fórmula de medicamentos</b>
                          </h4>
                        }
                        body={cardBodyAct(e)}
                      />
                    </Col>
                  </>
                );
              })}
            </div>
          )}
        </Col>
      </div>
    </>
  );
};
