import { Component } from 'react';

import '../TableUsers/tableUserStyle.scss';
import tableStyles from './tableStyle.module.scss';

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <table className={`table ${tableStyles.genericTable}`}>
        {' '}
        {/*"table table-striped user-table generic-table" */}
        <thead className={tableStyles.theadLight}>
          <tr>{this.props.headers}</tr>
        </thead>
        <tbody>{this.props.children}</tbody>
      </table>
    );
  }
}

export default GenericTable;
