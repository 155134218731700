import { Component } from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { connect } from 'react-redux';
import { Link, matchPath, withRouter } from 'react-router-dom';
import Cruz from '../../assets/img/icons/Cruz.svg';
import Hamburgesa from '../../assets/img/icons/Hamburgesa.svg';
import './sidebar.scss';
import style from '../../routes/fullLayout.module.css';
import HamburgesaOrd from '../../assets/img/icons/HamburgesaOrd.svg';
import { getEnvs, redirectOptions, removeAccents } from '../../helpers';

const { URL_ADMINISTRATION_SPA, URL_PROTOCOL } = getEnvs();

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsedMenu: true,
      itemActive: '',
    };
    const urlBase = `${URL_PROTOCOL}${URL_ADMINISTRATION_SPA}/validate?`;

    const token = `token=${this.props.token}`;
    const idAccount = `idAccount=${this.props.idAccount}`;
    const idUser = `idUser=${this.props.idUser}`;
    const idProfile = `idProfile=${this.props.idProfile}`;
    const userImage = `userImage=${this.props.userImage}`;
    const fullNameUser = `fullNameUser=${this.props.fullNameUser}`;

    this.redirectUrl = `${urlBase}&${idAccount}&${idUser}&${idProfile}&${userImage}&${fullNameUser}&${token}`;
    this.redirectOptions = redirectOptions;
  }

  onClickToggleMenu = () => {
    this.setState({ collapsedMenu: !this.state.collapsedMenu });
  };

  onClickToggleSubMenu = () => {
    if (this.state.collapsedMenu) {
      this.onClickToggleMenu();
    } else {
      let { itemActive } = this.state;
      if (itemActive !== '') {
        this.setState({ itemActive: '' });
      } else {
        this.setState({ itemActive: 'pro-inner-item-active' });
      }
    }
  };

  redirect(routePrefix = '', url = '') {
    if (this.redirectOptions.includes(routePrefix)) {
      return this.redirectUrl + `&redirect=${url}`;
    }
    return null;
  }

  renderFunctionalites(functionality, idCategory) {
    const myUrl = this.props?.location?.pathname;
    if (
      this.props.loginReducer?.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === functionality?.prefix,
      )?.read
    ) {
      return (
        <div>
          <MenuItem
            className={`${
              matchPath(myUrl, functionality?.url) ? 'menuItemActive' : null
            } activeHover`}
          >
            {this.redirectOptions.includes(functionality?.prefix) ? (
              <a
                href={
                  this.redirect(functionality.prefix, functionality.url) + `&idModule=${idCategory}`
                }
              >
                {functionality?.name}
              </a>
            ) : (
              <Link to={functionality?.url}>
                <p className='m-0' style={{ paddingLeft: '5px', whiteSpace: 'initial' }}>
                  {functionality?.name}
                </p>
              </Link>
            )}
          </MenuItem>
        </div>
      );
    } else {
      return null;
    }
  }
  renderModules(module) {
    const functionalities = module?.functionalities?.map(x => {
      return this.renderFunctionalites(x, module.id_category);
    });
    if (Array.isArray(functionalities)) {
      if (functionalities.length === 0) {
        return <span />;
      } else {
        return (
          <SubMenu
            title={module.name}
            icon={
              <div id={module.id}>
                <img
                  id={module.name + '-' + module.id}
                  alt={module.name}
                  src={module.img}
                  style={{ width: '30px', height: '30px' }}
                  className='icons-sidebar'
                />
              </div>
            }
            className={`${this.state.itemActive} `}
            onClick={this.onClickToggleSubMenu}
          >
            {functionalities}
          </SubMenu>
        );
      }
    }
  }
  isAssistanceModule = () => {
    let value = false;
    window.location.pathname.includes('ordenamientos')
      ? (value = true)
      : window.location.pathname.includes('asistencial')
        ? (value = true)
        : window.location.pathname.includes('telemedicina')
          ? (value = true)
          : window.location.pathname.includes('cuentasMedicas')
            ? (value = true)
            : window.location.pathname.includes('admision')
              ? (value = true)
              : (value = false);
    return value;
  };

  render() {
    const category = this.props?.loginReducer?.all_categories?.find(
      x =>
        removeAccents(x.name || '')?.toLowerCase() ===
        removeAccents(this.props.loginReducer.category || '')?.toLowerCase(),
    );
    const categoryOrder = category?.modules?.sort((a, b) => a.id - b.id);
    const modules = categoryOrder?.map(x => {
      return this.renderModules(x);
    }) || <></>;

    return (
      <div id='sidebar-layout' className={`${style.fullLayoutSideBar} shadow rounded d-inline-block full-height`}>
        <ProSidebar collapsed={this.state.collapsedMenu} className={style.zIndex800}>
          <Menu id='menu1' iconShape='square'>
            <MenuItem
              icon={
                <img
                  alt='Hamburger'
                  src={this.isAssistanceModule() ? HamburgesaOrd : Hamburgesa}
                  onClick={() => this.setState({ collapsedMenu: !this.state.collapsedMenu })}
                  className='Hamburger-sidebar'
                />
              }
            >
              <div style={{ textAlign: 'end' }}>
                <img
                  src={Cruz}
                  alt='Ocultar'
                  onClick={this.onClickToggleMenu}
                  className='CruzBar'
                ></img>
              </div>
            </MenuItem>
            {modules}
          </Menu>
        </ProSidebar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.loginReducer.Authorization,
  idAccount: state.loginReducer.currentAccount.id,
  idUser: state.loginReducer.user_data.id,
  idProfile: state.loginReducer.currentAccount.profile.id,
  userImage: state.loginReducer.user_data.image,
  fullNameUser: `${state.loginReducer.user_data.first_name || ''} ${state.loginReducer.user_data.first_surname || ''} ${state.loginReducer.user_data.second_surname || ''}`,
});

export default connect(mapStateToProps)(withRouter(Sidebar));
