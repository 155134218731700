import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from '../../actions/consultAction';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import Select from 'react-select';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import ModalNew from '../../components/Layouts/ModalNew';
import { PdfViewer } from '../../components/Layouts/PdfViewer';
import { message } from '../../helpers/helpers';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { convertFilterToString } from '../../helpers/convertToFilter';
import { API_VERSION, URL_GATEWAY } from '../../helpers/constants';
import { Title } from '../../shared';

function ConsultDocuments() {
  let history = useHistory();
  function handleClick() {
    history.push('/tesoreria/TipoDeConsulta');
  }

  const [trigger, setTrigger] = useState(1);
  const counter = useSelector(state => state); //entra aca para acceder a todos los reducers
  const token = counter.loginReducer.Authorization;
  const dispatch = useDispatch(); //usa este metodo para ejecutar un action
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState('');

  const [Documents, setDocuments] = useState({
    entity_account: counter.loginReducer.currentAccount.id,
    page: 1,
    perpage: 10,
    doc_type: 1,
    consec_since: '',
    consec_until: '',
    date_since: '',
    date_until: '',
  });
  const [dataForGetPdf, setDataForGetPdf] = useState({
    selected_id: '',
    doc: '',
  });

  const getPdf = async () => {
    setBase64('');
    const treatedDocuments = {
      id: dataForGetPdf.selected_id,
      entity_account: Documents.entity_account,
      doc: dataForGetPdf.doc,
    };
    delete treatedDocuments.page;
    delete treatedDocuments.perpage;
    const myfilters = convertFilterToString(treatedDocuments);

    await fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pdf_doc/?${myfilters}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          let treatPdf = '';
          if (Array.isArray(res.pdf)) {
            treatPdf = res.pdf[0]?.key.replace(/^b'/gm, '').replace(/=*'$/gm, '');
          }
          setBase64(treatPdf);
        } else {
          setShowPdf(false);
          message('error', 'Ha ocurrido un error');
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (showPdf) {
      getPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPdf]);

  useEffect(
    () => {
      dispatch(getDocuments(Documents));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Documents.page, trigger, Documents.doc_type, Documents.date_since, Documents.date_until], //cada vez que esto cambie, ejecutar la funcion de arriba.
  );
  const handlePageChange = val => {
    setDocuments({
      ...Documents,
      page: val,
    });
    getDocuments(Documents);
  };
  const header = [
    <th key={1} className='text-center'>
      No. Documento
    </th>,
    <th key={2} className='text-center'>
      Tercero{' '}
    </th>,
    <th key={3} className='text-center'>
      Detalle
    </th>,
    <th key={4} className='text-center'>
      Fecha
    </th>,
    <th key={5} className='text-center'>
      &nbsp;
    </th>,
  ];
  const body = [];
  var optionDocType = [{ label: 'Seleccionar', value: '' }];
  optionDocType.push(
    {
      value: 1,
      label: 'Órdenes de pago',
      doc: 10,
      key: 'qg1',
    },
    {
      value: 3,
      label: 'Transferencias',
      doc: 3,
      key: 'qg3',
    },
    {
      value: 4,
      label: 'Traslado de fondos',
      doc: 3,
      key: 'qg4',
    },
  );
  if (Array.isArray(counter.consultReducer.documents)) {
    counter.consultReducer.documents.forEach(item => {
      body.push(
        <tr>
          <td className='text-center col-2'>{item.consecutive}</td>
          <td className='text-center col-3'>{item.name ? item.name : '-'}</td>
          <td className='text-center col-4'>{item.details}</td>
          <td className='text-center col-2'>{convertDateToLatinFormat(item.date.split('T')[0])}</td>
          <td className='text-center col-1'>
            {Documents.doc_type === 3 || Documents.doc_type === 1 ? (
              ''
            ) : (
              <img
                alt=''
                src={Filtrar}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowPdf(true);
                  setDataForGetPdf({
                    ...dataForGetPdf,
                    selected_id: item.id,
                  });
                }}
              />
            )}
          </td>
        </tr>,
      );
    });
  }
  const handleSumit = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };
  const handleSumit1 = e => {
    setDocuments({
      ...Documents,
      doc_type: e.value,
      // doc: e.doc,
    });
    setDataForGetPdf({
      ...dataForGetPdf,
      doc: e.doc,
    });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {counter.consultReducer.documents_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className='d-flex mb-4' style={{ marginTop: '30px', alignItems: 'end' }}>
          <Title title='Consulta de documentos' onClickIcon={handleClick} />
        </div>
        <div>
          <div className='div align-items-end' style={{ gap: '0.5rem 1rem', flexWrap: 'wrap' }}>
            <div className={tableStyles.containerSelect}>
              <label className={tableStyles.stylesLabel}>Tipo de documento</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder={'Seleccionar...'}
                styles={customSelectNewDark}
                options={optionDocType}
                value={optionDocType.find(doc => doc.value === Documents.doc_type)}
                onChange={e => handleSumit1(e)}
              />
            </div>

            <div className={tableStyles.containerSelect}>
              <form onSubmit={handleSumit} style={{ display: 'contents' }}>
                <label className={tableStyles.stylesLabel}>Del n° de documento</label>
                <input
                  className={IndividualStyles.registerInputsGris}
                  name='consec_since'
                  type='text'
                  placeholder='Escribir...'
                  onChange={e =>
                    setDocuments({
                      ...Documents,
                      consec_since: e.target.value,
                    })
                  }
                />
              </form>
            </div>

            <div className={tableStyles.containerSelect}>
              <form onSubmit={handleSumit} style={{ display: 'contents' }}>
                <label className={tableStyles.stylesLabel}>Hasta n° de documento</label>
                <input
                  placeholder='Escribir...'
                  className={IndividualStyles.registerInputsGris}
                  name='consec_until'
                  type='text'
                  onChange={e =>
                    setDocuments({
                      ...Documents,
                      consec_until: e.target.value,
                    })
                  }
                />
              </form>
            </div>
          </div>

          <div className='div'>
            <div className='col-3 display-grid'>
              <label className={tableStyles.crudModalLabel}>Fecha desde</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                name='date_until'
                type='date'
                onChange={e =>
                  setDocuments({
                    ...Documents,
                    date_since: e.target.value,
                  })
                }
              />
            </div>
            <div className=' display-grid'>
              <label className={`${tableStyles.crudModalLabel} mt-4`}>__</label>
            </div>

            <div className='col-3 display-grid mr-3'>
              <label className={tableStyles.crudModalLabel}>Hasta</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                name='date_until'
                type='date'
                onChange={e =>
                  setDocuments({
                    ...Documents,
                    date_until: e.target.value,
                  })
                }
              />
            </div>
            <div className='col-1 display-grid  mr-3' style={{ alignContent: 'end' }}>
              <label className=''>
                {' '}
                <img
                  alt=''
                  className={tableStyles.cursorPointer}
                  onClick={() => {
                    setDocuments({ ...Documents, page: 1 });
                    dispatch(getDocuments({ ...Documents, page: 1 }));
                  }}
                  src={Lupa}
                />
              </label>
            </div>
          </div>
        </div>
        <GenericTable headers={header} dark={true}>
          {body}
        </GenericTable>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.consultReducer.totalDocuments ? Documents.page : ''}
            {' de '}
            {Math.ceil(counter.consultReducer.totalDocuments / Documents.perpage)
              ? Math.ceil(counter.consultReducer.totalDocuments / Documents.perpage)
              : ''}{' '}
            ({counter.consultReducer.totalDocuments} encontrados)
          </p>
          <Pagination
            activePage={Documents.page}
            itemsCountPerPage={Documents.perpage}
            totalItemsCount={counter.consultReducer.totalDocuments}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
      <ModalNew
        title='Consulta de documentos'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default ConsultDocuments;
