import { Drawer } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod } from '../../../Hooks';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import ordComponentStyles from '../../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import rowIcon from '../../../assets/img/icons/arrowDerechaCalendario.svg';
import excelClearGray from '../../../assets/img/icons/excelClearGray.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { downloadExcel, formatteDocument, formatToRcSelect, loader } from '../../../helpers';

const SurgeryBook = () => {
  const store = useSelector(state => state);

  const [detailedClass, setDetailedClass] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Fecha', className: 'col-1 text-start px-2' },
      { title: 'Paciente', className: 'col-2 text-start px-2' },
      { title: 'Cirugía', className: 'col-1 text-start px-2' },
      { title: 'Órgano', className: 'col-1 text-center px-2' },
      { title: 'Quirófano', className: 'col-1 text-center' },
      { title: 'Situaciones inesperadas', className: 'col-2 text-center' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
    startDate: '',
    endDate: '',
  });
  const [data, setData] = useState({
    nameClassificationDetail: '',
    stcId: null,
    createdBy: store.loginReducer.currentAccount.id,
    responsible: null,
  });
  const [trigger, setTrigger] = useState(0);

  const {
    load: detailedClassificationLoader,
    results: detailedClassificationResults,
    trigger: getDetailedClassification,
  } = useGetMethod();
  const { load: statusLoader, results: statusResults, trigger: statusMethod } = useGetMethod();
  const { load: surgeryLoader, results: surgeryResults, trigger: surgeryMethod } = useGetMethod();
  const { trigger: getExcel, load: excelLoading } = useGetMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getDetailedClassification({
        url: '/medical/clinicHistory/surgery-book/',
        objFilters: { ...filters, eaccount: store.loginReducer.currentAccount.id, page: 1 },
        token: store.loginReducer.Authorization,
      });

      await surgeryMethod({
        url: '/medical/offered-service',
        objFilters: {
          type: 'surgery',
          status: 'enabled',
          slim: 1,
        },
        token: store.loginReducer.Authorization,
      });

      await statusMethod({
        url: '/medical/admissions/statusFilters/',
        objFilters: {
          view: 'surgery_book',
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    statusMethod,
    getDetailedClassification,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    trigger,
  ]);

  const statusOptions = formatToRcSelect(
    statusResults?.results,
    'value',
    'description',
    '',
    '',
    '',
  );
  const surgeryOptions = formatToRcSelect(surgeryResults?.results, 'id', 'name', '', '', '');

  const handleOpenEditModal = async item => {
    setDetailedClass({
      ...detailedClass,
      showModal: true,
      isEditing: true,
    });
    setData({
      ...data,
      ...item,
    });
  };

  const handleCloseModal = () => {
    setDetailedClass({ ...detailedClass, showModal: false, isEditing: false });
    setData({
      nameClassificationDetail: '',
      srvId: [],
      createdBy: store.loginReducer.currentAccount.id,
      stcId: null,
    });
  };

  const getExcelFunction = () => {
    getExcel({
      url: '/medical/clinicHistory/surgery-book/',
      objFilters: {
        search: filters.search,
        startDate: filters.startDate,
        endDate: filters.endDate,
        status: filters.status,
        eaccount: store.loginReducer.currentAccount.id,
        download: 1,
      },
      token: store.loginReducer.Authorization,
      doAfterSuccess: res => {
        downloadExcel(res.results.base64, res.results.filename, true);
      },
    });
  };

  const renderRow = row => {
    return (
      <tr key={row.id} className={`hover-table-row`}>
        <td className='text-start px-2 col-1'>{row?.date}</td>
        <td className='text-start px-2 col-2'>
          {row?.patient?.fullName}
          <br />
          <span className='text-muted'>
            {row?.patient?.documentType} - {formatteDocument(row?.patient?.document).format}
          </span>
        </td>
        <td className='text-start px-2 col-1'>{row?.serviceMain}</td>
        <td className='text-center px-2 col-1 '>{row?.organ}</td>
        <td className='text-center px-2 col-1 '>{row?.qx ?? '-'}</td>
        <td className='text-center px-2 col-2 '>{row?.unexpectedSituationNum}</td>
        <td className='px-2 col-2'>
          <div className=' d-flex justify-content-center'>
            <div
              style={{
                backgroundColor: row?.status?.bgColor || '#E8E8EA',
                color: row?.status?.fontColor || '#6E6F7C',
                borderRadius: 16,
                padding: '4px 10px',
              }}
            >
              {row?.status?.name}
            </div>
          </div>
        </td>
        <td className='text-end col-1'>
          <div className='d-flex justify-content-center'>
            <img
              alt='icon'
              src={rowIcon}
              className='cursorPointer'
              onClick={() => {
                handleOpenEditModal(row);
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={detailedClass.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: detailedClassificationResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDetailedClassification({
              url: '/medical/clinicHistory/surgery-book/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
                page: e,
                perpage: 10,
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {detailedClassificationResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    return (
      <Drawer
        anchor={'right'}
        open={detailedClass.showModal}
        onClose={handleCloseModal}
        classes={{ width: '100%' }}
      >
        <div className={`${tableStyles.tlndrawer__header}`}>
          <button className={`${tableStyles.tlndrawer__close}`} onClick={handleCloseModal}></button>
          <div className={`${tableStyles.tlndrawer__title} w-100 text-start px-3`}>
            <span>Detalle de Cirugía</span>
          </div>
        </div>
        <div className='d-flex flex-column ' style={{ height: '100vh' }}>
          <div className={`p-5 ${tableStyles.tlndrawer__content}`} style={{ width: '500px' }}>
            <Row className='d-flex justify-between pb-3'>
              <div className='d-flex justify-content-between align-items-center'>
                <div>
                  <b className={`m-0 px-1 ${tableStyles.infoPatient}`}>
                    {data?.serviceMain ?? '-'}
                  </b>
                </div>

                <div
                  className='d-flex'
                  style={{
                    backgroundColor: data?.status?.bgColor ? data?.status?.bgColor : '#E8E8EA',
                    color: data?.status?.fontColor ? data?.status?.fontColor : '#6E6F7C',
                    borderRadius: 16,
                    padding: '4px 10px',
                  }}
                >
                  {data?.status?.name}
                </div>
              </div>
            </Row>
            <Row>
              <Col xs={12} className='d-flex flex-column'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Paciente:</b> {data?.patient?.fullName ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Cédula:</b>{' '}
                  {formatteDocument(data?.patient?.document).format ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Edad:</b> {data?.patient?.age ?? '-'} años
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Aseguradora:</b> {data?.cclientName ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Contrato:</b> {data?.contractName ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Cirugía:</b> {data?.serviceMain ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Órgano:</b> {data?.organ ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Cirujano principal:</b> {data?.surgeonName ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Cirujano ayudante:</b> {data?.supportSurgeon ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Instrumentador:</b> {data?.supportNurse ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Rotador:</b> {data?.supporRotator ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Anestesiólogo:</b> {data?.anesName ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Tipo de anestesia:</b> {data?.anesthesia ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Quirófano:</b> {data?.qx ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Insumos especiales:</b> {data?.specialSupplies ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Situaciones inesperadas:</b>{' '}
                  {data?.unexpectedSituationNum ?? '-'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Causo suspensión:</b>{' '}
                  {data?.status?.value === 'suspended' ? 'SÍ' : 'No'}
                </Form.Label>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <b className='px-1'>Causo cancelación:</b>{' '}
                  {data?.status?.value === 'failed' ? 'SÍ' : 'No'}
                </Form.Label>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    );
  };

  const renderHeader = () => {
    return (
      <div className='row align-items-end'>
        <div className='col-12 row'>
          <div className='col-3 align-self-end'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'></div>
            </Form.Label>
            <input
              placeholder='Escribe aquí para buscar...'
              className={`${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} pl-2 w-100`}
              onChange={({ target }) => {
                setFilters(prev => ({ ...prev, search: target.value, page: 1 }));
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </div>

          <Form.Group className='col-2'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Cirugía</span>
              </div>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              options={surgeryOptions}
              onChange={option => {
                setFilters(prev => ({ ...prev, serviceSurgeryId: option.value, page: 1 }));
                setTrigger(trigger + 1);
              }}
            />
          </Form.Group>
          <Form.Group className='col-2'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Estado</span>
              </div>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              options={statusOptions}
              onChange={option => {
                setTrigger(trigger + 1);
                setFilters(prev => ({ ...prev, status: option.value, page: 1 }));
              }}
            />
          </Form.Group>
          <Form.Group className='col-2'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Fecha incio</span>
              </div>
            </Form.Label>
            <Form.Control
              type='date'
              className='ord-roundInput'
              onChange={({ target }) =>
                setFilters(prev => ({ ...prev, startDate: target.value, page: 1 }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </Form.Group>
          <Form.Group className='col-2'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Fecha fin</span>
              </div>
            </Form.Label>
            <Form.Control
              type='date'
              className='ord-roundInput'
              onChange={({ target }) =>
                setFilters(prev => ({ ...prev, endDate: target.value, page: 1 }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </Form.Group>
          <div className='col-1 d-flex justify-content-center align-self-end'>
            <div onClick={getExcelFunction}>
              <img src={excelClearGray} alt='excel' className='cursorPointer' />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className={`${tableStyles.tlnContainer}`}>
          <OrdGenericTemplate className='' titleSize={12} title={'Libro de cirugía'}>
            <>
              <section className='pr-8 mt-2'>
                <>
                  {detailedClassificationLoader && loader}
                  {statusLoader && loader}
                  {excelLoading && loader}
                  {surgeryLoader && loader}
                  {renderModal()}
                  <div>
                    {renderHeader()}
                    {renderTable()}
                  </div>
                </>
              </section>
            </>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
};

export default SurgeryBook;
