
import {
    GET_VAUCHER_HISTORY,
    GET_JOURNAL_TYPE,
    GET_THIRD_PARTIES,
    GET_CASH_FLOW,
    GET_TASK,
    GET_EVENT_TYPE,
    GET_ACCOUNTS_PAYABLE

}   from "../actions/actionTypes";

const initialState = {
   listVaucherHistory:[],
   listVaucherHistory_total:0,
   listJournalType:[],
   thirdParties:[],
   listCashFlow:[],
   listTasks:[],
   listEventTypes:[],
   accountsP_loading:false,
   voucherH_loading: false,
   cashFlow_loading : false,
   listTasks_loading: false
};

export const accountingConsultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VAUCHER_HISTORY:
            return {
                ...state,
                listVaucherHistory:  action.payload,
                listVaucherHistory_total: action.total,
                voucherH_loading: action.loading
            };
        case GET_JOURNAL_TYPE:
            return {
                ...state,
                listJournalType:  action.payload,
            };
        case GET_THIRD_PARTIES:
            return {
                ...state,
                thirdParties:  action.payload,
            };
        case GET_CASH_FLOW:
            return {
                ...state,
                listCashFlow:  action.payload,
                cashFlow_loading : action.loading
            };
        case GET_TASK:
            return {
                ...state,
                listTasks:  action.payload,
                listTasks_loading: action.loading
            };
        case GET_EVENT_TYPE:
            return {
                ...state,
                listEventTypes:  action.payload,
            };
        case GET_ACCOUNTS_PAYABLE:
            return {
                ...state,
                accountsP_loading:  action.loading,
            };

        default:
            return state;
    }
};