import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  generateNewRip,
  getAllMinutes,
  getBillingSites,
  getEnterpriseList,
} from '../../actions/ripsActions';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { months } from '../../helpers/constants';
import { formatToRcSelect, isEmptyOrUndefined, message } from '../../helpers/helpers';
import { Title } from '../../shared';
function GenerateRips() {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'generateRi',
  );
  const [inputs, setInputs] = useState({
    month: '',
    year: '',
  });
  const idEnterprise = store.loginReducer.currentAccount.id;
  const enterPriseList = store.ripsReducer?.enterPriseList;
  const billingSites = store.ripsReducer?.billingSites;
  const invoicingList = store.ripsReducer?.invoicingList;
  const token = store.loginReducer.Authorization;
  const [allowSend, setallowSend] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selectedList, setselectedList] = useState([]);
  const [filters, setFilters] = useState({
    filing_status: 'filed',
    eaccount: idEnterprise,
    client: '',
    invoice: '',
    site: '',
    minuteNumber: '',
    page: 1,
    perpage: 5,
  });
  const formattedEnterprises = formatToRcSelect(enterPriseList, 'id', 'copyName', '', '', 'Nit');
  const formattedSites = formatToRcSelect(billingSites, 'id', 'description');
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/cartera/inicio');
    }
    dispatch(getEnterpriseList({ eaccount: idEnterprise }));
    dispatch(getBillingSites({ eaccount: idEnterprise }));
    if (!isEmptyOrUndefined(filters.client)) {
      dispatch(getAllMinutes(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  const listElem = () => {
    let elemMap;
    if (invoicingList !== undefined) {
      const elem2 = invoicingList;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };
  useEffect(() => {
    if (!isEmptyOrUndefined(inputs.month) && !isEmptyOrUndefined(inputs.year)) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs]);

  // IS BOX ALL SELECTED
  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(invoicingList) && Array.isArray(selectedList)) {
      invoicingList.forEach(item => {
        if (selectedList.find(itemb => item.id === itemb.id)) {
          //f
        } else {
          res = false;
        }
      });
    }
    return res;
  };

  // CHECKBOX ALL
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(invoicingList) && Array.isArray(tempSelected)) {
        invoicingList.forEach(x => {
          let newChange = {
            id: x.id,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  // ONE CHECKBOX
  const isBoxSelected = id => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id);
      return !!target;
    }
  };
  // CHANGE ONE CHECKBOX
  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          id: item.id,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const renderElement = elem => {
    return (
      <tr key={'invo' + elem.id}>
        <td key={elem.id} className='text-center'>
          <input
            type='checkbox'
            value='selectedAll'
            checked={isBoxSelected(elem.id)}
            onChange={() => changeBoxSelected(elem)}
          />
        </td>
        <td key={elem.id} className='text-center'>
          {elem.sequence}
        </td>
        <td key={elem.id} className='text-center'>
          {elem.clientNit}
        </td>
        <td key={elem.id} className='text-center'>
          {elem.clientName}
        </td>
      </tr>
    );
  };
  const download = url => {
    window.location.href = 'data:application/x-zip-compressed;base64,' + url;
  };
  const handleSubmit = async () => {
    if (selectedList.length <= 0) {
      return message(
        'info',
        'No se han seleccionado actas',
        'Se debe seleccionar al menos un acta',
      );
    }

    const result = await generateNewRip(
      {
        eaccount: idEnterprise,
        minutes: selectedList,
        month: inputs.month,
        year: Number(inputs.year),
      },
      token,
    );

    if (result?.success) {
      let file = result?.results?.base64;
      download(file);
      setTrigger(trigger + 1);
      setselectedList([]);
      return message('success', 'Éxito', ' Rips generados exitosamente'); //TODO:GENERAR ARCHIVO
    }
  };

  const header = [
    <th key={1} className='text-center'>
      <input
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2} className='text-center'>
      No. Acta
    </th>,
    <th key={3} className='text-center'>
      NIT
    </th>,
    <th key={4} className='text-center'>
      Empresa
    </th>,
  ];

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {store.ripsReducer.invoicingList_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Generar RIPS'
            onClickIcon={() => {
              history.push('/cartera/inicio');
            }}
          />
        </div>

        <div className='d-flex gap-3 align-items-end'>
          <div className={tableStyles.containerSelect}>
            {' '}
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.stylesLabel}>Empresa</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, client: e.value })}
                options={formattedEnterprises}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
              ></Select>{' '}
            </form>
          </div>

          <div className={tableStyles.containerSelect}>
            {' '}
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.stylesLabel}>Sede que factura</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, site: e.value })}
                options={formattedSites}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
              ></Select>{' '}
            </form>
          </div>

          <div className={tableStyles.containerSelect}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.stylesLabel}>No. Acta</label>
              <input
                placeholder='Escribe...'
                className='register-inputs text-secondary'
                onChange={e => setFilters({ ...filters, minuteNumber: e.target.value })}
              />
            </form>
          </div>

          <div className={tableStyles.containerSelect}>
            <form className='d-flex' onSubmit={e => handleSearch(e)}>
              <div>
                <label className={tableStyles.stylesLabel}>No. Factura</label>
                <input
                  placeholder='Escribe...'
                  className='register-inputs text-secondary'
                  onChange={e => setFilters({ ...filters, invoice: e.target.value })}
                />{' '}
              </div>
            </form>
          </div>
          <div className=' display-grid cursorPointer' onClick={e => handleSearch(e)}>
            <label className='cursorPointer'>
              <img className='cursorPointer' src={Lupa} alt='12' />
            </label>
          </div>
        </div>
        {invoicingList?.length > 0 && (
          <>
            <GenericTable headers={header} dark={true}>
              {listElem()}
            </GenericTable>

            <div className={paginationStyles.wrapper}>
              <p className={`${paginationStyles.paginationText} text-secondary`}>
                Pag. {store.ripsReducer.invoicingList ? filters.page : ''}
                {' de '}
                {Math.ceil(store.ripsReducer.rowCount / filters.perpage)
                  ? Math.ceil(store.ripsReducer.rowCount / filters.perpage)
                  : ''}{' '}
                ({store.ripsReducer.rowCount} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={store.ripsReducer.rowCount}
                pageRangeDisplayed={5}
                onChange={e => setFilters({ ...filters, page: e })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
            <Row className='d-flex justify-content-end'>
              {' '}
              <Col xs={3}>
                <label className={tableStyles.crudModalLabel}>Mes</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={e => setInputs({ ...inputs, month: e.value })}
                  options={months}
                  className='text-secondary'
                  placeholder={'Seleccione'}
                  styles={customSelectNew}
                ></Select>
              </Col>{' '}
              <Col xs={3}>
                <label className={tableStyles.crudModalLabel}>Año</label>
                <input
                  placeholder='Escribe...'
                  className='register-inputs text-secondary'
                  type='number'
                  maxLength='4'
                  onChange={e => setInputs({ ...inputs, year: e.target.value })}
                />
              </Col>{' '}
              <Col xs={3} className='pt-4 text-end'>
                <Button
                  className={tableStyles.btnPrimary}
                  onClick={() => handleSubmit()}
                  disabled={allowSend ? false : true}
                >
                  Generar RIPS
                </Button>
              </Col>{' '}
            </Row>
          </>
        )}
      </div>
    </>
  );
}
export default GenerateRips;
