import { GET_CHECK_RETURN } from "../actions/actionTypes";


const initialState = {
    results: [],
    total: 0,
    loading: false,
};
export const ReassignmentChecksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHECK_RETURN:
            return {
                ...state,
                results: action.payload? action.payload : state.results,
                total:  action.total? action.total : state.total,
                loading: action.loading
            };
        default:
            return state;
    }
};

