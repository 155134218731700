import Swal from 'sweetalert2';

export const customSwaltAlertAsistencial = (props = {}) => {
  const {
    icon = 'warning',
    title = '',
    titleColor = '#00B4CC',
    textColor = '#58595B',
    text = '',
    showCancelButton = true,
    confirmButtonColor = '#00B4CC',
    cancelButtonColor = '#003F80',
    confirmButtonText = `${!props.showCancelButton ? 'Aceptar' : 'Sí, continuar'}`,
    cancelButtonText = 'Cancelar',
    timer,
    footer,
    showDenyButton = false,
    denyButtonText = `Denegar`,
    denyButtonColor = `rgba(255, 139, 0, 3)`,
    doAfterConfirm,
  } = props;

  return Swal.fire({
    title: `<span style=color:${titleColor}>${title}</span>`,
    html: `<span style=color:${textColor}>${text}</span>`,
    icon: icon,
    timer,
    footer,
    showCancelButton: showCancelButton,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    showDenyButton: showDenyButton,
    denyButtonText: denyButtonText,
    denyButtonColor: denyButtonColor,
    doAfterConfirm: doAfterConfirm,
    customClass: {
      confirmButton: `ordBtnPrimaryModuleAssitance`,
      cancelButton: `ordBtnSecondaryModuleAssistance`,
      denyButton: `ordBtnDenyModuleAsistencial`,
    },
  });
};
