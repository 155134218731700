import Pagination from 'react-js-pagination';
import tableStyles from './drawerTableStyles.module.scss';

function DrawerTable(props) {
  return (
    <>
      <div className={`${tableStyles.drawerTableContainer} d-flex px-2`}>
        <table
          className={`    
            ${props?.isAnAccTable ? 'fold-table' : props?.drawerTable ? `${tableStyles.genericTable}` : 'table'}
          ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ''}
          ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ''}
          ${props?.className ? props.className : ''}
          ${props?.shadow ? tableStyles.shade : ''}
          ${props?.drawerPadding ? tableStyles.paddingTable : ''}
          `}
          style={props?.style}
        >
          <thead
            className={`${tableStyles.thead} ${
              props.clearHeader
                ? ''
                : props.dark
                  ? tableStyles.theadDark
                  : props.drawerThead
                    ? tableStyles.theadWhite
                    : tableStyles.theadLight
            }`}
          >
            {' '}
            <tr>
              {props?.headers?.length > 0 &&
                props?.headers.map((e, i) => {
                  return (
                    <th className={e.className} key={`header${i}`}>
                      {e.title}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody className={`${props?.fTdClearBlue ? tableStyles.firstdClearBlue : ''}`}>
            {props?.body?.length > 0 &&
              props?.body.map((e, i) => {
                return (
                  <>
                    <tr key={'table' + i}>
                      {e.map((content, i) => {
                        return (
                          <td
                            id={'td' + i}
                            className={content?.className || ''}
                            key={`content${i}`}
                            style={content?.style || {}}
                          >
                            {content?.text ? content.text : content}
                          </td>
                        );
                      })}
                    </tr>
                  </>
                );
              })}
            {props?.hasChildren && props.children}
          </tbody>
          {!!props.footer && <tfoot>{props.footer}</tfoot>}
        </table>
      </div>

      {/* /* ------------------------------- PAGINATION ------------------------------- */}

      {props?.paginate && (
        <div className={tableStyles.wrapper}>
          {props?.paginate?.showTextDetails && (
            <>
              <p className={`${tableStyles.paginationText} text-secondary`}>
                Pag. {props?.paginate?.activePage || ''}
                {' de '}
                {Math.ceil(props?.paginate?.totalPages / props?.paginate?.perPage)
                  ? Math.ceil(props?.paginate?.totalPages / props?.paginate?.perPage)
                  : ''}
                {' - '}
                {props?.paginate?.totalPages} encontrados
              </p>
            </>
          )}
          <Pagination
            activePage={props?.paginate?.activePage}
            itemsCountPerPage={props?.paginate?.perPage}
            totalItemsCount={props?.paginate?.totalPages}
            pageRangeDisplayed={props?.paginate?.pageRangeDisplayed || 5}
            onChange={e => props?.paginate?.onChangePage(e)}
            itemClassPrev={tableStyles.itemClassPrev}
            itemClassNext={tableStyles.itemClassNext}
            itemClassFirst={tableStyles.itemClassFirst}
            itemClassLast={tableStyles.itemClassLast}
            itemClass={tableStyles.itemClass}
          />
        </div>
      )}
    </>
  );
}

export default DrawerTable;
