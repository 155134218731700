import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
//
import { useGetMethod } from "../../../../Hooks";
import { loader } from "../../../../helpers";
//
import IconBack from "../../../../assets/img/icons/iconBack.svg";

import tableStyles from "../../../../components/Layouts/tableStyle.module.scss";
import { useMemo } from "react";
import moment from "moment";

export default function RipsDetail() {
    const store = useSelector(store => store);
    const history = useHistory();
    const { id } = useParams();

    const { trigger: getRipsDetail, load: ripsDetailLoader, results: ripsDetail } = useGetMethod();

    useEffect(() => {
        async function fetchAsyncData() {
            await getRipsDetail({
                url: "/receivable/rips/getDetail/",
                objFilters: { id },
                token: store.loginReducer.Authorization
            });
        }
        fetchAsyncData().catch(error => console.error("Error: ", error));
    }, [
        getRipsDetail,
        id,
        store.loginReducer.Authorization
    ]);

    const ripsDetailResult = useMemo(() => ripsDetail.results?.[0], [ripsDetail]);

    const render = () => {
        return (
            <>
                {ripsDetailLoader && loader}

                <div className={tableStyles.tlnContainer}>
                    <div className={`${tableStyles.tableArea}`} style={{ paddingBottom: 80 }}>
                        <div className="d-flex align-items-center mt-5 mb-3">
                            <img onClick={() => history.goBack()} src={IconBack} className="pointer me-3" alt="" />
                            <h1 className="m-0">Detalle RIPS</h1>
                        </div>
                        <div className="card p-3 mt-4 border-0" style={{ borderRadius: 10, backgroundColor: "#F5F7FA" }}>
                            <p className="text-primary fw-bold">Información general</p>
                            <div className="row align-items-start">
                                <div className="col">
                                    <small className="text-primary">Vigente hasta</small>
                                    <small className="d-block text-muted nowrap">
                                        {moment(ripsDetailResult?.valid_until, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                    </small>
                                </div>
                                <div className="col">
                                    <small className="text-primary">Tipo de archivo</small>
                                    <small className="d-block text-muted nowrap">{ripsDetailResult?.rip_type}</small>
                                </div>
                                <div className="col">
                                    <small className="text-primary">Nombre</small>
                                    <small className="d-block text-muted nowrap">{ripsDetailResult?.name}</small>
                                </div>
                                <div className="col-lg-4">
                                    <small className="text-primary">Descripción</small>
                                    <small className="d-block text-muted nowrap">{ripsDetailResult?.description}</small>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <h5 className="text-primary fw-bold">Empresa cliente</h5>
                            <div className="row g-0 py-1 px-2 text-white fw-bold" style={{ background: "#005dbf", borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                                <div className="col me-3">Empresa</div>
                                <div className="col me-3">Contrato</div>
                            </div>

                            <div className={tableStyles.odd_rows}>
                                {ripsDetailResult?.rip_companies_info?.map((item, index) => (
                                    <div key={index} className="row g-0 align-items-center py-2">
                                        <div className="col px-2">
                                            {item.company.name}
                                        </div>
                                        <div className="col d-flex align-items-center flex-wrap pe-2" style={{ gap: 3 }}>
                                            {item.contracts?.map(el => (
                                                <div key={el.id} className={`${tableStyles.inputVariable}`}>{el.name}</div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <h5 className="text-primary fw-bold mt-4">Datos RIPS</h5>
                            <div className="row g-0 py-1 px-2 text-white fw-bold" style={{ background: "#005dbf", borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                                <div className="col me-3">Dato</div>
                                <div className="col me-3">Descripción</div>
                            </div>

                            <div className={tableStyles.odd_rows}>
                                {ripsDetailResult?.rips_info?.map(item => (
                                    <div key={item.data_rip_id} className="row g-0 align-items-center py-2">
                                        <div className="col px-2">
                                            {item.data_rip_name}
                                        </div>
                                        <div className="col d-flex align-items-center pe-2">
                                            {item.data_rip_descriptions}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return render();
}