import deepcopy from 'deepcopy';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export const Conditions = ({ getInitialList, initialList = {} }) => {
  const [trigger] = useState(0);
  const [items, setItems] = useState([]);
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;

  /* ------------------------------------ - ----------------------------------- */
  useEffect(() => {
    const filters = {
      page: 1,
      search: '',
      perpage: 10,
      idAccount: idEnterprise,
    };
    getInitialList({
      multipleGet: [
        {
          url: '/medical/operator',
          objFilters: filters,
          token: token,
          requestName: 'conditions',
        },
      ],
      token: token,
    });
  }, [getInitialList, token, idEnterprise]);

  const handleChangeConditions = (e, i) => {
    const { name, value } = e.target;
    let copy = deepcopy(items);
    copy[i][name] = value;
    setItems(copy);
  };
  return (
    <>
      <div className='mb-3'>
        {initialList?.conditions?.results?.length > 0 &&
          initialList?.conditions?.results?.map((e, i) => {
            return (
              <>
                <Row>
                  <Col
                    xs={5}
                    className={`${tableStyles.ordSearchSections} my-2 ml-2`}
                    style={{ borderRadius: '10px' }}
                  >
                    <Row className={``}>
                      <Col xs={12}>
                        <Form.Group className='mb-3 text-start' controlId='lName'>
                          <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                            <b> &nbsp; Condición</b>
                          </Form.Label>
                          <Form.Control
                            key={trigger}
                            disabled
                            value={`${e.name} (${e.value})`}
                            name='condition'
                            onChange={e => handleChangeConditions(e, i)}
                            className={`ord-roundInput`}
                            type='text'
                            placeholder=''
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            );
          })}
      </div>
    </>
  );
};
