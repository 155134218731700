import styles from '../styles.module.scss';

export const ConditionalMessage = ({ hasSchedules }) => {
  return (
    <div className='d-flex align-items-center justify-content-center h-100'>
      {hasSchedules ? (
        <div className='d-flex justify-content-center w-100 h-100 justify-align-center align-middle align-self-center'>
          <div className='d-flex w-100 h-100 align-items-center justify-content-center justify-align-center align-middle align-self-center'>
            <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
              Seleccione especialista
            </span>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center justify-align-center align-middle align-self-center'>
          <div className='d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center'>
            <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
              No hay agendas disponibles
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
