import { useEffect, useState } from 'react';
import { Accordion, Form, Modal, useAccordionToggle } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import {
  AddCircleOutline,
  Cached,
  CheckCircleOutlineRounded,
  Close,
  ErrorOutline,
} from '@material-ui/icons';
import Pagination from 'react-js-pagination';
import { ModeOutlined } from '@mui/icons-material';
import moment from 'moment';
//
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
import SearchIcon from '../../../assets/img/icons/lupa.svg';
//
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlert, loader } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';
import './Rips.scss';
import { Tooltip } from '@material-ui/core';

export default function Rips() {
  const store = useSelector(state => state);
  const history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    ripType: '',
    name: '',
    validUntil: '',
  });
  const [historyModal, setHistoryModal] = useState({
    open: false,
    ripId: null,
  });

  const { trigger: getRips, results: ripsResult, load: ripsLoader } = useGetMethod();
  const { trigger: getRipsHistory, results: historyResult, load: historyLoader } = useGetMethod();
  const { trigger: updateStatus, load: updateStatusLoader } = usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      if (historyModal.ripId) {
        await getRipsHistory({
          url: '/receivable/rips/history/',
          objFilters: {
            ripId: historyModal.ripId,
          },
          token: store.loginReducer.Authorization,
        });
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getRipsHistory, store.loginReducer.Authorization, historyModal.ripId]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getRips({
        url: '/receivable/rips/',
        objFilters: {
          ...filters,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [filters, store.loginReducer.currentAccount.id, store.loginReducer.Authorization, getRips]);

  const onCloseHistoryModal = () => {
    setHistoryModal({ open: false, ripId: null });
  };

  const onUpdateStatus = item => {
    updateStatus({
      token: store.loginReducer.Authorization,
      body: {
        id: item.id,
        status: item.status === '1' ? 0 : 1,
        eaccount: store.loginReducer.currentAccount.id,
        created_by: store.loginReducer.user_data.id,
      },
      method: 'PUT',
      url: '/receivable/rips/',
      succesAction: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: result.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await getRips({
              url: '/receivable/rips/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
          }
        });
      },
      doAfterException: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onSubmitSearch = e => {
    e.preventDefault();
    const { search } = e.target;
    setFilters({ ...filters, name: search.value });
  };

  const renderModal = () => {
    return (
      <Modal
        show={historyModal.open}
        size='md'
        centered
        className={`${modalStyles.container}`}
        onHide={onCloseHistoryModal}
      >
        <div className='d-flex align-items-center justify-content-between pt-3 px-4'>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold`}>Historial</h3>
          <Close className='pointer text-secondary' onClick={onCloseHistoryModal} />
        </div>
        <div className='py-3 px-4'>
          <div className='p-3 d-flex flex-column' style={{ overflowY: 'scroll', maxHeight: 300 }}>
            {historyResult.results?.length ? (
              historyResult.results.map(item => (
                <Accordion key={item.id} className='timeline-accordion'>
                  <div className='d-flex flex-column'>
                    <div className='fs-6 fw-bold text-muted'>
                      {moment(item.update_at).format('DD/MM/YYYY')}
                    </div>
                    <CustomToggle eventKey={item.id} text={item.description} />
                    <Accordion.Collapse eventKey={item.id} className='mb-3'>
                      <div className='d-flex flex-column ps-2 text-muted' style={{ fontSize: 12 }}>
                        <span>
                          <span className='text-primary'>•</span>{' '}
                          {item.tag === 'created' ? 'Creado' : 'Modificado'} por: {item.email}
                        </span>
                        <span>
                          <span className='text-primary'>•</span> Hora:{' '}
                          {moment(item.update_at).format('hh:mm A')}
                        </span>
                      </div>
                    </Accordion.Collapse>
                  </div>
                  <div className='timeline-container'>
                    {item.tag === 'created' && (
                      <div className='timeline-circle'>
                        <CheckCircleOutlineRounded className='fs-4 text-white' />
                      </div>
                    )}
                    {item.tag === 'updated' && (
                      <div className='timeline-circle'>
                        <Cached className='fs-4 text-white' />
                      </div>
                    )}
                    {item.tag === 'edited' && (
                      <div className='timeline-circle'>
                        <ModeOutlined className='fs-4 text-white' />
                      </div>
                    )}
                    {historyResult.results[historyResult.results?.length - 1].id !== item.id ? (
                      <div className='dashed-bottom'></div>
                    ) : (
                      <div className='h-100'></div>
                    )}
                  </div>
                </Accordion>
              ))
            ) : (
              <h5 className='text-muted text-center'>No se encontró un historial</h5>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const renderFilters = () => {
    const ripsTypes = [
      { label: 'Seleccionar...', value: '', extraValue: '' },
      { label: 'CT', value: 'CT', extraValue: 'Archivo de control' },
      { label: 'AF', value: 'AF', extraValue: 'Archivo de transacciones' },
      { label: 'US', value: 'US', extraValue: 'Archivo de usuarios' },
      { label: 'AC', value: 'AC', extraValue: 'Archivo de consulta' },
      { label: 'AP', value: 'AP', extraValue: 'Archivo de procedimientos' },
      { label: 'AM', value: 'AM', extraValue: 'Archivo de medicamentos ' },
      { label: 'AT', value: 'AT', extraValue: 'Archivo de otros servicios ' },
      { label: 'MIA', value: 'MIA', extraValue: 'Archivo para COOSALUD' },
      { label: 'MEGAPLANO', value: 'MEGAPLANO', extraValue: 'Archivo para Positiva' },
    ];

    return (
      <div className='d-flex align-items-end'>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Tipo de RIPS</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={ripsTypes}
            onChange={option => setFilters({ ...filters, ripType: option.value })}
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Vigente hasta</small>
          <Form.Control
            type='date'
            className='register-inputs'
            onChange={({ target }) => setFilters({ ...filters, validUntil: target.value })}
          />
        </Form.Group>
        <form onSubmit={onSubmitSearch} className='d-flex flex-grow-1 me-3'>
          <input
            type='text'
            placeholder='Buscar...'
            name='search'
            className={`${tableStyles.SearchNew} w-100 me-2`}
          />
          <img
            style={{ width: '1.5rem' }}
            src={SearchIcon}
            alt='User icon'
            className={`${tableStyles.iconSvgMargin2} pointer`}
            onClick={async () => {
              await getRips({
                url: '/receivable/rips/',
                objFilters: {
                  ...filters,
                  eaccount: store.loginReducer.currentAccount.id,
                },
                token: store.loginReducer.Authorization,
              });
            }}
          />
        </form>
        <div
          className={tableStyles.buttonTextPrimary}
          onClick={() => history.push('/administracion/TMDeCartera/rips/crear')}
        >
          <small className='mr-2' style={{ whiteSpace: 'nowrap' }}>
            Crear nuevo RIPS
          </small>
          <AddCircleOutline stroke='2' fontSize='small' />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-3'>
        Fecha de vigencia
      </th>,
      <th key={2} className='text-center'>
        Tipo RIPS
      </th>,
      <th key={3} className='text-start'>
        Nombre
      </th>,
      <th key={4} className='text-center'>
        No. Datos
      </th>,
      <th key={5} className='text-center'>
        No. Empresas
      </th>,
      <th key={6} className='text-center'>
        No. Contratos
      </th>,
      <th key={7} className='text-center'>
        Estado
      </th>,
      <th key={8} className='text-center'>
        &nbsp;
      </th>,
    ];

    return (
      <GenericTableNew lowerCase headers={tableHeaders} dark={true}>
        {ripsResult?.results?.map(item => (
          <tr key={item.id}>
            <td className='text-start align-middle ps-3'>
              <div className='d-flex align-items-center'>
                <span className='me-2'>{item.valid_until}</span>
                {item.alert === true && (
                  <Tooltip title='Próximo a vencer'>
                    <ErrorOutline className='text-danger' fontSize='small' />
                  </Tooltip>
                )}
              </div>
            </td>
            <td className='text-center'>{item.rip_type}</td>
            <td className='text-start px-0'>{item.name}</td>
            <td className='text-center'>{item.noData}</td>
            <td className='text-center'>{item.noCompany}</td>
            <td className='text-center'>{item.noContract}</td>
            <td>
              <div
                className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
              >
                {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
              </div>
            </td>
            <td className='text-center'>
              <CustomPopupExtend
                showEnable={true}
                showDetails={true}
                showEdit={true}
                position='right'
                triggerSrc={ThreeDots}
                showClone
                cloneText='Historial'
                isEnabled={item.status === '1'}
                cloneEvent={() => setHistoryModal({ open: true, ripId: item.id })}
                editClickEvent={() => {
                  history.push('/administracion/TMDeCartera/rips/crear', { id: item.id });
                }}
                showDetailsEvent={() => {
                  history.push(`/administracion/TMDeCartera/rips/Detalle/${item.id}`);
                }}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.status === '1' ? 'deshabilitará' : 'habilitará'} el RIPS: ${item.name}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onUpdateStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>
        ))}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {ripsLoader && loader}
        {updateStatusLoader && loader}
        {historyLoader && loader}

        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>RIPS</h1>
            {renderFilters()}
            {renderTable()}
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(ripsResult?.row_count / filters.perpage)
                  ? Math.ceil(ripsResult?.row_count / filters.perpage)
                  : '1'}{' '}
                ({ripsResult?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={ripsResult?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
        {renderModal()}
      </>
    );
  };

  return render();
}

function CustomToggle({ text, eventKey }) {
  const onClick = useAccordionToggle(eventKey);
  return (
    <small className='text-primary pointer' onClick={onClick}>
      {text}
    </small>
  );
}
