import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import backArrow from '../../assets/img/icons/atras-icon.svg';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';

import { Button, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { expRegList, isEmptyOrUndefined, loader, today, validExpReg } from '../../helpers/helpers';
import ModalNew from '../Layouts/ModalNew';
import { PdfViewer } from '../Layouts/PdfViewer';
import DetailSurvey from './DetailSurvey';
import { useHasPermissionByTagModule } from '../../Hooks';

export const NewSurvey = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const history = useHistory();
  const [showPdf, setShowPdf] = useState(false);
  const detail = location?.state?.detail;
  const conclusion = location?.state?.conclusion;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'position',
  );
  const surveyData = location?.state?.data;
  const [allowSend, setallowSend] = useState(false);
  const idEnterprise = store.loginReducer.currentAccount.id;
  const { load: surveyListLoader, trigger: getSurveyListMethod } = useGetMethod();
  const token = store.loginReducer.Authorization;
  const [inputs, setInputs] = useState({
    title: '',
    startDate: '',
    endDate: '',
    surveyed: '',
    satisfaction: '',
    conclusions: '',
    actions: '',
    fileName: '',
    base64: '',
    ext: '',
  });
  const [dataDetail, setDataDetail] = useState({
    title: '',
    startDate: '',
    endDate: '',
    numberRespondents: '',
    conclusions: '',
    actionsToTake: '',
  });

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    getSurveyListMethod({
      url: '/payroll/workenviroment',
      objFilters: {
        id: surveyData?.id,
        eaccount: idEnterprise,
      },
      doAfterSuccess: res => {
        setDataDetail({
          title: res?.results?.title,
          startDate: res?.results?.startDate,
          endDate: res?.results?.endDate,
          numberRespondents: res?.results?.numberRespondents,
          conclusions: res?.results?.conclusions,
          actionsToTake: res?.results?.actionsToTake,
        });
      },
      token: token,
    });

    if (conclusion) {
      if (!isEmptyOrUndefined(inputs.conclusions) && !isEmptyOrUndefined(inputs.actions)) {
        setallowSend(true);
      } else {
        setallowSend(false);
      }
    } else {
      if (
        !isEmptyOrUndefined(inputs.title) &&
        !isEmptyOrUndefined(inputs.startDate) &&
        !isEmptyOrUndefined(inputs.endDate) &&
        !isEmptyOrUndefined(inputs.surveyed) &&
        !isEmptyOrUndefined(inputs.conclusions) &&
        !isEmptyOrUndefined(inputs.actions)
      ) {
        setallowSend(true);
      } else {
        setallowSend(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const handleBack = () => {
    history.push('/nomina/seguridadYsalud/climaLaboral');
  };
  const { load: createSurveyLoader, trigger: createSurveyMethod } = usePostMethod();

  const sendConclusionFunction = () => {
    createSurveyMethod({
      url: '/payroll/workenviroment',
      method: 'PUT',
      body: {
        id: surveyData.id,
        status: surveyData.status,
        conclusions: inputs.conclusions,
        actionsToTake: inputs.actions,
      },
      token: token,
      doAfterSuccess: info => {
        handleBack();
        customSwaltAlert({
          icon: 'warning',
          title: 'Información',
          text: `${info.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${error.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  return (
    <>
      {(createSurveyLoader || surveyListLoader) && loader}
      {/* {isLoading && (
        <div className="loading">
          <Loader type="Oval" color="#003f80" height={100} width={100} />
        </div>
      )} */}
      {/* /* ------------------------------- PDF VIEWER ------------------------------- */}
      <ModalNew
        title='Contratos'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer file={surveyData?.results_file}></PdfViewer>
      </ModalNew>
      <div className='w-100 container'>
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                alt='icon'
                height='30px'
                src={backArrow}
                className={`mr-2 mb-1 cursorPointer`}
              ></img>
              <span className={`${tableStyles.f40}`}>
                {detail && conclusion
                  ? 'Conclusiones de la encuesta'
                  : detail
                    ? 'Detalle de encuesta'
                    : 'Registrar encuesta'}
              </span>
            </h1>
          </Col>
        </Row>
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <div className='d-flex justify-content-between'>
          <div className='mt-3 ' style={{ width: '75%', paddingLeft: '2rem' }}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Título principal
              <span className={'text-danger'}>*</span>
            </p>
            <input
              value={detail ? dataDetail.title : inputs.title}
              disabled={detail ? true : false}
              className='register-inputs text-secondary'
              name='position'
              onChange={e => setInputs({ ...inputs, title: e.target.value })}
            />

            <Row className='mt-2'>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha inicio
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  value={detail ? dataDetail.startDate : inputs.date}
                  type={detail ? 'text' : 'date'}
                  //   value={inputs.date}
                  disabled={detail ? true : false}
                  max={today()}
                  onChange={e => setInputs({ ...inputs, date: e.target.value })}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha fin
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  value={detail ? dataDetail.endDate : inputs.date}
                  type={detail ? 'text' : 'date'}
                  //   value={inputs.date}
                  disabled={detail ? true : false}
                  max={today()}
                  onChange={e => setInputs({ ...inputs, date: e.target.value })}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
              <Col xs={4}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  No. de encuestados
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  disabled={detail ? true : false}
                  onChange={e => {
                    if (validExpReg(e.target.value, expRegList.number) || e.target.value === '') {
                      setInputs({ ...inputs, surveyed: e.target.value });
                    } else {
                      setInputs({ ...inputs, surveyed: 0 });
                    }
                  }}
                  value={detail ? dataDetail.numberRespondents : inputs.surveyed}
                  className='register-inputs text-secondary'
                  name='position'
                />
              </Col>
            </Row>
            {surveyData?.status !== 'in_progress' && (
              <>
                <h6 className={`${tableStyles.darkBlueText} mt-4`}>
                  <b>• Conclusiones</b>
                </h6>

                <Row className=''>
                  <Col xs={12}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Descripción de conclusiones
                      <span className={'text-danger'}>*</span>
                    </p>
                    <textarea
                      value={detail ? dataDetail.conclusions : inputs.conclusions}
                      // disabled={detail && conclusion ? false : detail ? true : false}
                      disabled={(detail && !conclusion) || surveyData?.conclusions ? true : false}
                      onChange={e => setInputs({ ...inputs, conclusions: e.target.value })}
                      placeholder={'Escribir...'}
                      rows='10'
                      cols='10'
                      style={{ height: '6rem' }}
                      className={`register-inputs text-secondary`}
                    ></textarea>
                  </Col>
                </Row>
                <h6 className={`${tableStyles.darkBlueText} mt-4`}>
                  <b>• Accionar a tomar</b>
                </h6>

                <Row className=''>
                  <Col xs={12}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Descripción de acciones a tomar basándose en resultados
                      <span className={'text-danger'}>*</span>
                    </p>
                    <textarea
                      value={detail ? dataDetail.actionsToTake : inputs.actions}
                      // disabled={detail && conclusion ? false : detail ? true : false}
                      disabled={(detail && !conclusion) || surveyData?.conclusions ? true : false}
                      onChange={e => setInputs({ ...inputs, actions: e.target.value })}
                      placeholder={'Escribir...'}
                      rows='10'
                      cols='10'
                      style={{ height: '6rem' }}
                      className={`register-inputs text-secondary`}
                    ></textarea>
                  </Col>
                </Row>
              </>
            )}

            {detail && !conclusion ? (
              <></>
            ) : (
              <div className={`customTabs__Footer mt-4 ml-1`}>
                <Button
                  onClick={() => sendConclusionFunction()}
                  className={tableStyles.btnPrimary}
                  disabled={allowSend ? false : true}
                >
                  Registrar
                </Button>

                <Button
                  variant='outline-primary'
                  onClick={() => handleBack()}
                  className={tableStyles.btnSecondary}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </div>
        </div>
        {detail && !conclusion && (
          <div style={{ width: '75%', paddingLeft: '2rem' }} className='my-2'>
            <b className={`${tableStyles.darkBlueText} ${tableStyles.f24} py-4 text-center mt-5`}>
              Preguntas y respuestas
            </b>
            <DetailSurvey idSurvey={surveyData.id} />
          </div>
        )}
      </div>
    </>
  );
};
