import { Card, Col, Row } from 'react-bootstrap';
import ordComponentStyles from './ordComponentStyles.module.scss';
export const OrdCard = props => {
  return (
    <>
      <Card className={`${ordComponentStyles.ordCard} ${props.className} `}>
        <Col className={`d-flex`}>
          <Row className={`col-12 mx-0`}>
            <Col xs={props?.buttons ? 8 : 12} className={`px-0`}>
              {props?.title && <b className={`${props.titleClassName}`}>{props?.title}</b>}
            </Col>
            {props?.buttons && (
              <Col xs={4} className={`d-flex justify-content-end`}>
                <Row className={`d-flex justify-content-between`}>
                  {props?.buttons?.length > 0 &&
                    props?.buttons.map((e, i) => {
                      return (
                        <Col
                          key={i}
                          className={`cursorPointer`}
                          onClick={() => e.action(props)}
                          xs={3}
                        >
                          {e?.btnIcon}
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            )}

            {props?.body && <div>{props?.body}</div>}
            {props?.subInfo && (
              <Row className={`d-flex `}>
                <label style={props?.subInfo?.style}>
                  <b>{props?.subInfo?.title}</b>
                  {props?.subInfo?.text && props?.subInfo?.text}
                </label>
              </Row>
            )}
            {/* /* -------------------------- If you want a footer -------------------------- */}
            {props?.footer && (
              <Row className={`d-flex`}>
                <label style={props?.footer?.style}>
                  <b>{props?.footer?.title}</b>
                  {props?.footer?.text && props?.footer?.text}
                </label>
              </Row>
            )}
          </Row>
          {/* <Row className={`d-flex col-1 h-100 justify-content-around`}
                    >
                       
                    </Row> */}
        </Col>
      </Card>
    </>
  );
};
/* ------------------------------- Example use ------------------------------ */
// let buttons = [
//     {
//         btnTitle: "Cargar excel",
//         btnVariant: "gray",
//         // btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
//     {
//         btnTitle: "Segundo botón",
//         btnVariant: "darkBlue",
//         btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
//     {
//         btnTitle: "tercer botón",
//         btnVariant: "clearBlue",
//         // btnSize: 3,
//         btnIcon: <img src={add} ></img>,
//         action: () => alert('Cargar excel')
//     },
// ]
