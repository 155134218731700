import { useEffect, useState } from 'react';
import '../../../OrderingModule/orderingStyles.css';
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';
import { useHistory } from 'react-router-dom';
import SelectSites from '../../../AdmissionModule/SelectSites';
import { useSelector } from 'react-redux';
import { onCapitalLetter } from '../../../helpers/helpers';
export const AdmissionHomeContent = () => {
  const [siteSelected, setSiteSelected] = useState({
    siteId: '',
    siteName: '',
  });
  const store = useSelector(state => state);
  const siteId = store.userReducer.site;
  const { all_categories } = useSelector(state => state?.loginReducer);
  const accountingModules = all_categories?.find(cat => cat?.name === 'Admisión');
  const { modules } = accountingModules;
  const storage = useSelector(state => state);
  let history = useHistory();

  const contentDynamic = modules?.map(module => {
    const textJoin = onCapitalLetter({ text: module?.name });
    return {
      title: textJoin,
      content: module?.functionalities?.map(subModule => {
        let subModuleName = onCapitalLetter({ text: subModule?.name });
        let returnValue = <></>;
        let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
          x => x.functionality?.prefix === subModule?.prefix,
        )?.read;
        if (permissionValidation) {
          returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
        }
        return returnValue;
      }),
    };
  });

  useEffect(() => {
    const sidebarLayout = document.querySelector('#sidebar-layout');

    if (sidebarLayout) {
      if (!siteId) {
        sidebarLayout.style.width = 0;
      } else {
        sidebarLayout.style.width = 'initial';
      }
    }

    return () => {
      if (sidebarLayout) sidebarLayout.style.width = 'initial';
    }
  }, [siteId])

  return (
    <>
      {siteId === '' ? (
        <div className='d-flex align-self-center'>
          <SelectSites siteSelected={siteSelected} setSiteSelected={setSiteSelected} />
        </div>
      ) : (
        <div>
          {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
          {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
        </div>
      )}
    </>
  );
};
