import { useEffect, useRef, useState } from 'react';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { useSelector } from 'react-redux';
import {Form, Modal } from 'react-bootstrap';
import { Box } from '@mui/material';
import { Button } from '../../components/UI/atoms';
import OrdTable from './OrdTable';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import './TextConfig.scss';
import ordRightArrow from '../../assets/img/icons/ordRightArrow.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { customSwaltAlertAsistencial } from '../../helpers';


const detailSegments = {
    medications: 'medicationObservation',
    generalMedications: 'generalMedicationsObservation',
    combo: 'comboObservation',
    consultationDiagnosticAids: 'consultationDiagnosticAidsObservation',
    serviceCombo: 'srvCombosObservation',
    proceduresSurgery: 'proceduresSurgeryObservation',
    reasonForReference: 'reasonForReference',
    referencePlan: 'referencePlan',
    expectation: 'expectation',
    assist: 'assistObservation',
    inability: 'inabilityObservation'
};

const TextConfigModal =({ onClose, onSelectItem, current, segment, sheet, onLoading, ...rest }) => {
    const store = useSelector(store => store);
    const accountId = store.loginReducer.currentAccount.id
    const userId = store.loginReducer.user_data.id
    const selectedTextRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [textPayload, setTextPayload] = useState({
        currentReason: '',
        selectedText: '',
        isSelectedTextDisabled: true
    });
    const [currentSheet, setCurrentSheet] = useState('');
    const [selectedText, setSelectedText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [charCount, setCharCount] = useState(2000);
    const prevLoadingState = useRef(false);

    
    const { results: predefinedTextsResult,  trigger: getPredefinedTexts} = useGetMethod();

    const { trigger: postCreatePredefinedText} = usePostMethod();

    const { trigger: putUpdatePredefinedText } = usePostMethod();

    useEffect(() => {
        if (!textPayload.isSelectedTextDisabled)
            selectedTextRef.current?.focus();
    }, [textPayload.isSelectedTextDisabled]);

    useEffect(() => {
        if (typeof onLoading === 'function' && isLoading !== prevLoadingState.current) {
            onLoading(isLoading);
            prevLoadingState.current = isLoading;
        }
    },[onLoading, isLoading])

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                getPredefinedTexts({
                    token: store.loginReducer.Authorization,
                    url: '/medical/clinicHistory/predefinedTexts/',
                    objFilters: {
                        eaccount: accountId,
                        type: 'non-institutional',
                        search: searchValue,
                        users: [userId ],
                        component: currentSheet,
                        dtlSegmentPrefix: segment ? segment : detailSegments[current],
                        active: 1
                    },
                    doAfterExceptionResults: true
                });
            }
        }
        fetchData();
    }, [getPredefinedTexts, 
        searchValue, 
        userId, 
        accountId, 
        rest.isOpen,
        store.loginReducer.Authorization,
        current,
        currentSheet,
        segment]);

    const handleSearch = (value) => setSearchValue(value);

    const handleOnCloseModal = () => {
        setSelectedText(null);
        setTextPayload({
            id: undefined,
            dtlSegmentPrefix: undefined,
            currentReason: '',
            isSelectedTextDisabled: true,
            selectedText: ''
        });
        setSearchValue('');
        onClose();
    };

    const handleSelectText = (text) => {
        setSelectedText(text);
        setTextPayload({
            ...textPayload,
            creatable: false,
            id: text.id,
            dtlSegmentPrefix: segment ? segment : detailSegments[current],
            selectedText: text.description,
            isSelectedTextDisabled: true,
            currentReason: text.description
        });
        setCharCount(2000 - text.description.length);
    };

    const handleAddRow = (item) => {
        onSelectItem(segment ? segment : current, item.description);
        setSearchValue('');
        setTextPayload({
            id: undefined,
            dtlSegmentPrefix: undefined,
            currentReason: '',
            isSelectedTextDisabled: true,
            selectedText: ''
        });
        setSelectedText(null);
    }

    const handleUpdateJustification = (value) => {
        if (value.length <= 2000) {
            setTextPayload({
                ...textPayload,
                currentReason: value
            });
            setCharCount(2000 - value.length);
        }
    };

    const isSuccessRequest = async () => {
        setIsLoading(true)
        getPredefinedTexts({
            token: store.loginReducer.Authorization,
            url: '/medical/clinicHistory/predefinedTexts/',
            objFilters: {
                eaccount: accountId,
                type: 'non-institutional',
                search: searchValue,
                users: [userId],
                active: 1,
                component: currentSheet,
                dtlSegmentPrefix: segment ? segment : detailSegments[current]
            },
            doAfterExceptionResults: true,
            doAfterSuccess:()=>{
                setIsLoading(false)
            },
            doAfterException:()=>{
                setIsLoading(false)
            }
        });
        setTextPayload({
            id: undefined,
            currentReason: '',
            selectedText: '',
            isSelectedTextDisabled: true
        });
        setSelectedText(null);
    };

    const handleCreatePredefinedText = async () => {
        setIsLoading(true);
        postCreatePredefinedText({
            token: store.loginReducer.Authorization,
            body: {
                type: 'non-institutional',
                createdBy: userId ,
                description: textPayload.currentReason,
                dtlSegmentPrefix: segment ? segment : detailSegments[current],
                eaccount: accountId,
                component: currentSheet,
                users: [userId]
            },
            method: 'POST',
            url: '/medical/clinicHistory/predefinedTexts/',
            succesAction: () => {
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'success',
                    title: 'Creado exitosamente',
                    text: `Se ha creado texto predefinido exitosamente.`,
                    confirmButtonText: 'Aceptar',
                })
                setIsLoading(false);
                isSuccessRequest();
            },
            doAfterException: results => {
                setIsLoading(false);
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: results.message,
                    confirmButtonText: 'Aceptar',
                });
            },
          });
    };

    const handleUpdatePredefinedtext = async () => {
        setIsLoading(true);
        putUpdatePredefinedText({
            token: store.loginReducer.Authorization,
            body: {
                id: textPayload.id,
                updatedBy: userId ,
                type: 'non-institutional',
                description: textPayload.currentReason,
                dtlSegmentPrefix: segment ? segment : detailSegments[current],
                eaccount: accountId,
                component: currentSheet,
                active: true
            },
            method: 'PUT',
            url: '/medical/clinicHistory/predefinedTexts/',
            succesAction: () => {
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'success',
                    title: 'Editado exitosamente',
                    text: `Se ha editado texto predefinido exitosamente.`,
                    confirmButtonText: 'Aceptar',
                })
                setIsLoading(false);
                isSuccessRequest();
            },
            doAfterException: results => {
                setIsLoading(false);
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: results.message,
                    confirmButtonText: 'Aceptar',
                });
            },
          });
    };

    const handleDeletePredefinedText = async () => {
        setIsLoading(true);
        putUpdatePredefinedText({
            token: store.loginReducer.Authorization,
            body: {
                active: false,
                eaccount: accountId,
                id: textPayload.id,
                updatedBy: userId,
                component: currentSheet, 
            },
            method: 'PUT',
            url: '/medical/clinicHistory/predefinedTexts/',
            succesAction: () => {
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'success',
                    title: 'Eliminado exitosamente',
                    text: `Se ha eliminado texto predefinido exitosamente.`,
                    confirmButtonText: 'Aceptar',
                })
                setIsLoading(false);
                isSuccessRequest();
            },
            doAfterException: results => {
                setIsLoading(false);
                customSwaltAlertAsistencial({
                    showCancelButton: false,
                    icon: 'warning',
                    title: 'Intenta de nuevo',
                    text: results.message,
                    confirmButtonText: 'Aceptar',
                });
            },
          });
    };

    useEffect(() => {
        if (sheet) {
            setCurrentSheet(sheet);
        }
    }, [sheet]);

    useEffect(() => {
        if (!textPayload.isSelectedTextDisabled)
            selectedTextRef.current?.focus();
    }, [textPayload.isSelectedTextDisabled]);

    const handleAddNewText = async () => {
        setCharCount(2000);
        setTextPayload({
            dtlSegmentPrefix: segment ? segment : detailSegments[current],
            selectedText: '',
            currentReason: '',
            isSelectedTextDisabled: false,
            creatable: true
        });
    };

    const renderSelectedText = (item) => {
        if (textPayload.creatable) {
            return null;
        }

        return (
            <div  style={{ marginTop: 10 ,width: '100%' }}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: '0.8rem', color: '#6c757d'}}><strong>Código:</strong> {item.id}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: '0.8rem', color: '#6c757d'}}><strong>Módulo:</strong> {item.moduleName}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: '0.8rem', color: '#6c757d'}}><strong>Componente:</strong> {item.componentName}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: '0.8rem', color: '#6c757d'}}><strong>Segmento:</strong> {item.segName}</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{ fontSize: '0.8rem', color: '#6c757d'}}><strong>Detalle del segmento:</strong> {item.dtlSegmentName}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTextArea = () => {
        return (
            <div className='textarea__with-header mt-3'>
                <div className='textarea-header'>
                    <span>Descripción</span>
                </div>
                <textarea
                    title='Descripción'
                    ref={selectedTextRef}
                    value={textPayload.currentReason}
                    rows={6}
                    readOnly={textPayload.isSelectedTextDisabled}
                    onChange={({ target }) => handleUpdateJustification(target.value)}
                />
                <div className='px-2' style={{fontSize: '0.8rem'}}>{charCount} caracteres restantes</div>
            </div>
        );
    };

    const renderRow = (item) => {
        return (
            <tr key={item.id}>
                <td
                    style={{ minWidth: '60px', cursor:'pointer' }}
                    onClick={() => handleAddRow(item)}
                >
                    {item.id}
                </td>
                <td
                    style={{ width: '100%', cursor:'pointer', textAlign: 'left' }}
                    onClick={() => handleAddRow(item)}
                >
                    {item.description}
                </td>
                <td style={{paddingRight: 13}}>
                    <img
                     alt={'felcha derecha'}
                     className={`cursorPointer`}
                     onClick={() => handleSelectText(item)}
                     src={ordRightArrow}
                    />
                </td>
            </tr>
        );
    };
    const headers = [
        {
          title: `Código`,
          className:'text-center'
        },
        {
          title: 'Descripción',
        },
        {
          title: <>&nbsp;</>,
        },
      ];
    const renderTable = () => {
        return (
            <div style={{ maxHeight: '300px', overflowY: 'auto'}}>
                <OrdTable headers={headers} hasChildren={true} style={{borderCollapse: 'collapse'}} theadClassName="textconfig-head">
                    {predefinedTextsResult?.results?.map(item => renderRow(item))}
                </OrdTable>
            </div>
        );
    };

    
    const render = () => {
        return (
            
            <Modal
             show={rest.isOpen}
             size='lg'
             centered
             className={`${tableStyles.container} textconfig-modal`}
             onHide={handleOnCloseModal}
             {...rest}
            >
                <CloseIcon 
                    onClick={() => handleOnCloseModal()}
                    style={{cursor: 'pointer', color: '#CECECE', position: 'absolute', right: 23 , top: 23, fontWeight: 600 }}
                />
                <h2 className={`${tableStyles.ordDarkBlueText} ${tableStyles.fontFamiTitle}`}>
                    Textos predefinidos no institucionales
                </h2>
                <div className='row gx-5 w-100 mt-3'>
                    <div className='col'>
                        <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                            <Form.Control
                                className={`ord-roundInput-search`}
                                type='text'
                                onChange={e => handleSearch(e.target.value)}
                                placeholder='Escribe código o descripción...'
                            />
                             <img alt='buscar' src={ordBlueSearch} className='pointer'></img>
                        </div>
                        {renderTable()}
                        <div className='d-flex justify-content-between align-items-center'>
                            <span className='text-muted fw-bold' style={{fontSize: '0.8rem'}}>Total</span>
                            <span className='text-muted' style={{fontSize: '0.7rem'}}>{predefinedTextsResult?.results?.length}</span>
                            <div></div>
                        </div>
                    </div>
                    <div className='col d-flex flex-column'>
                        <div style={{display: 'flex', alignItems: 'end', with:'100%', justifyContent: 'end'}}>
                            <Button
                                backgroundColorHover="auto"
                                backgroundColor="#00B4CC"
                                border={'auto'}
                                cursor={ 'pointer'}
                                onClick={handleAddNewText}
                                padding='10px'
                            >
                                <Box display='flex' columnGap={'5px'} justifyContent='center' alignItems={'center'}>
                                    <span style={{fontSize: '0.7rem'}}>Agregar</span>
                                    <AddCircleOutlineIcon style={{fontSize: '0.8rem'}} />
                                </Box>
                            </Button>
                        </div>
                        {selectedText ? (
                            <>
                                {renderSelectedText(selectedText)}
                                {renderTextArea()}
                            </>
                        ) : (
                            !textPayload.isSelectedTextDisabled ? (
                                <div className='textarea__with-header mt-3'>
                                    <div className='textarea-header'>
                                        <span>Descripción</span>
                                    </div>
                                    <textarea
                                        title='Descripción'
                                        ref={selectedTextRef}
                                        value={textPayload.currentReason}
                                        rows={6}
                                        readOnly={textPayload.isSelectedTextDisabled}
                                        onChange={({ target }) => handleUpdateJustification(target.value)}
                                    />
                                    <div className='px-2' style={{fontSize: '0.7rem'}} >{charCount} caracteres restantes</div>
                                </div>
                            ) : (
                                <h3 className='text-muted text-center mt-4' style={{fontSize: '1.3rem'}}>
                                    Seleccione un texto predefinido
                                </h3>
                            )
                        )}
                        <div className='d-flex justify-content-end align-items-center mt-1'>
                            {textPayload.isSelectedTextDisabled
                                ? textPayload.selectedText ? (
                                    <>
                                        <EditIcon 
                                            onClick={() => setTextPayload({
                                                ...textPayload,
                                                isSelectedTextDisabled: false,
                                                creatable: false
                                            })}
                                            style={{cursor: 'pointer', color: '#6E6F7C'}}
                                        />
                                        <DeleteIcon 
                                            onClick={handleDeletePredefinedText}
                                            style={{cursor: 'pointer', color: '#6E6F7C'}}
                                        />  
                                    </>
                                ) : null
                                : !isLoading ? (
                                    <>
                                        <CheckIcon 
                                            onClick={textPayload.creatable ? handleCreatePredefinedText : handleUpdatePredefinedtext}
                                            style={{cursor: 'pointer', color: '#83C036'}}
                                        />
                                        <CloseIcon 
                                            onClick={() => setTextPayload({
                                                ...textPayload,
                                                isSelectedTextDisabled: true,
                                                currentReason: textPayload.selectedText,
                                            })}
                                            style={{cursor: 'pointer', color: '#F39682'}}
                                        />
                                    </>
                                ) : (
                                    <div className='small-loader'></div>
                                )}
                        </div>
                    </div>
                </div>

            </Modal>
        );

    };

    return render();
}

export default TextConfigModal
