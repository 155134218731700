import moment from 'moment'

const formatHours = (hours) => {

    let am = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']
    let result = moment(hours).format('LT')

    return am.includes(result.split(':')[0]) ? result += ' AM' : result += ' PM'
}

export default formatHours