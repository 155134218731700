import Adjuntar from '../../../assets/img/icons/Adjuntar.svg';
import send from '../../../assets/img/icons/send.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';
import showDedu from '../../../assets/img/icons/addFile.svg';
import lupa from '../../../assets/img/icons/auctionDetailLupa2.svg';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import ModalNew from '../../Layouts/ModalNew';
import { Radio } from '@material-ui/core';
import PDF from '../../../assets/img/icons/pdf.svg';
import Image from '../../../assets/img/icons/Imagen.svg';
import { Button, Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import {
  convertBase64,
  convertMoneyFormat,
  expRegList,
  isEmptyOrUndefined,
  message,
  placeMyIcon,
  validExpReg,
  validateEmptyString,
} from '../../../helpers/helpers';
import { customSelectNew } from '../../Layouts/react-select-custom';
import GenericTableNew from '../../Layouts/GenericTableNew';
import GenericTableScroll from '../../Layouts/GenericTableScroll';
import {
  addDiscountOrPayout,
  getDeductionList,
  getDiscountsAndPayouts,
} from '../../../actions/partnersActions';
import { formatToRcSelect } from '../../../helpers/helpers';
import NumberFormat from 'react-number-format';
import Loader from 'react-loader-spinner';
import CustomPopupExtend from '../../Popup/customPopUpExtends';

import { usePostMethod } from '../../../Hooks/useFetch';
import { RenderMedicalFormulasPdf } from '../../../AsistanceModule/RenderMedicalFormulasPdf';
import SelectComponent from '../../SelectComponent/SelectComponent';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import { useEffect, useState } from 'react';

export const DiscountsAndPayout = ({ idCandidate }) => {
  const store = useSelector(state => state);
  const idEnterprise = store.loginReducer?.currentAccount?.id;
  const nitEnterprise = store.loginReducer?.currentAccount?.nit;
  const nameEnterprise = store.loginReducer?.currentAccount?.name;
  const arrDeductions = store.partnersReducer?.deductionList;
  const current = store.partnersReducer?.discountAndpayOuts?.current;
  const settled = store.partnersReducer?.discountAndpayOuts?.settled;
  const idAccount = store.loginReducer?.user_data?.id;
  const token = store.loginReducer?.Authorization;
  const [inputs, setInputs] = useState({
    type: '',
    deduction: { value: '' },
  });
  const [allowSend, setallowSend] = useState(false);
  const [showPaymentDetail, setShowPaymentDetail] = useState({
    show: false,
    detailsPayment: {},
  });
  const [trigger, setTrigger] = useState(0);
  const formattedDeductions = formatToRcSelect(
    arrDeductions,
    'adjustments_id',
    'description',
    'nit',
    'third_parties_description',
  );
  const [isLoading, setIsLoading] = useState(false);
  const selectedDeduction = arrDeductions?.find(
    x => x?.adjustments_id === inputs?.deduction?.value,
  );

  const [registerPaymentModal, setRegisterPaymentModal] = useState({
    show: false,
    modalType: '',
    paymentType: '',
    value: '',
    file: {
      base64: '',
      fileName: '',
      ext: '',
    },
    detailsPayment: '',
    reduceType: '',
  });

  const third = () => {
    let t;

    if (!isEmptyOrUndefined(selectedDeduction?.recipient_id)) {
      if (!isEmptyOrUndefined(inputs?.deduction?.extra)) {
        t = inputs?.deduction?.extra + '-' + inputs.deduction?.extra2;
      }
    } else {
      t = nitEnterprise + '-' + nameEnterprise;
    }

    return t;
  };

  const listPaymentDetail = () => {
    let elemMap;
    if (showPaymentDetail?.detailsPayment?.details_payments !== undefined) {
      const elem2 = showPaymentDetail?.detailsPayment?.details_payments;
      elemMap = elem2.map(elem => {
        return renderPaymentDetail(elem);
      });
    }
    return elemMap;
  };
  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.deduction) &&
      !isEmptyOrUndefined(inputs.type) &&
      !isEmptyOrUndefined(inputs.startDate) &&
      !isEmptyOrUndefined(inputs.quoteValue) &&
      !isEmptyOrUndefined(inputs.quoteNumber)
    ) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs]);
  const renderPaymentDetail = elem => {
    const myDate = moment(elem.Fecha, 'DD/MM-YYYY').format('YYYY/MM/DD');

    return (
      <tr key={elem.code}>
        <td className='col-md-2 text-center'>{elem.number}</td>
        <td className='col-md-4 text-center'>{myDate}</td>
        <td className='col-md-2 text-center'>
          {convertMoneyFormat(elem?.payment_value ? elem?.payment_value : '0')}
        </td>
        <td className='col-md-2 text-center'>
          <div>
            {elem?.support_file && (
              <img
                alt='icon'
                src={lupa}
                className={`${tableStyles.svgDarkBlueFilter} cursorPointer`}
                onClick={() => {
                  setShowDetailPayment({
                    ...showDetailPayment,
                    show: true,
                    fileUrl: elem?.support_file ?? 'showMessage',
                    name: `Pago No. ${elem.number}`,
                  });
                }}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  const paymentDetailsHeaders = [
    <th style={{ borderTopLeftRadius: '10px', textAlign: 'center' }} key={`Hash`}>
      #
    </th>,
    <th key={`Date`} style={{ textAlign: 'center' }}>
      Fecha
    </th>,
    <th key={`ValueDetailP1`}>Valor cuotas</th>,
    <th style={{ borderTopRightRadius: '10px' }} key={`ValueDetail`}></th>,
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDeductionList({ type: 1, entity_account: idEnterprise, status: 'enabled' }));
    dispatch(
      getDiscountsAndPayouts({
        employee: idCandidate,
        entity_account: idEnterprise,
      }),
    );
  }, [idEnterprise, idCandidate, dispatch]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await addDiscountOrPayout(
        {
          employee: idCandidate,
          adjustment: inputs.deduction.value,
          type: inputs.type,
          start_date: inputs.startDate,
          installments: inputs.quoteNumber,
          fee_value: inputs.quoteValue,
          description: inputs.description,
          entity_account: idEnterprise,
          created_by: idAccount,
          base64: inputs.base64,
          filename: inputs.fileName,
        },
        token,
      );

      if (result.success) {
        setInputs({});
        setTrigger(trigger + 1);
        dispatch(getDeductionList({ type: 1, entity_account: idEnterprise }));
        dispatch(
          getDiscountsAndPayouts({
            employee: idCandidate,
            entity_account: idEnterprise,
          }),
        );
        setIsLoading(false);
        return message(
          'success',
          'Éxito',
          'Descuento o libranza guardada exitosamente',
          undefined,
          true,
        );
      } else {
        setIsLoading(false);
        return message('warning', 'Advertencia', result.message, undefined, true);
      }
    } catch (error) {
      setIsLoading(false);
      message('error', 'Error', 'Ha ocurrido un error', undefined, true);
      return console.error(error);
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const onChangeDoc = async e => {
    const file = e.target.files[0];
    const validExtensions = ['PDF', 'PNG', 'JPG', 'JPEG'];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = validExtensions.find(
        obj => obj === actFileExt[actFileExt.length - 1]?.toUpperCase(),
      );

      if (isEmptyOrUndefined(extension)) {
        e.target.value = '';
        onClearFileRegisterPaymentModal();
        return customSwaltAlert({
          icon: 'warning',
          title: 'Advertencia!',
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ]?.toLowerCase()}`,
          footer: 'Extensiones permitidas: PDF, PNG, JPG',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      if (file.size > 5000000) {
        e.target.value = '';
        return customSwaltAlert({
          title: 'Intenta de nuevo',
          text: 'Su archivo no puede tener un tamaño superior a 5MB',
          icon: 'warning',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        if (file.size <= 0) {
          e.target.value = '';
          return customSwaltAlert({
            title: 'Intenta de nuevo',
            text: 'Por favor adjunte un archivo valido',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
    } else {
      return console.error('Archivo vacio: ', file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    let ext = type_doc.split('/');
    let finalName = `${name_doc}.${ext[1]}`;
    setInputs({
      ...inputs,
      base64: file64,
      fileName: finalName,
      ext: ext[1],
    });
  };

  const onClearFileRegisterPaymentModal = () => {
    setRegisterPaymentModal({
      ...registerPaymentModal,
      file: {
        base64: '',
        fileName: '',
        ext: '',
      },
    });
  };

  const onChangeDocRegister = async e => {
    const file = e.target.files[0];
    const validExtensions = ['PDF', 'PNG', 'JPG', 'JPEG'];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = validExtensions.find(
        obj => obj === actFileExt[actFileExt.length - 1]?.toUpperCase(),
      );

      if (isEmptyOrUndefined(extension)) {
        e.target.value = '';
        onClearFileRegisterPaymentModal();
        return customSwaltAlert({
          icon: 'warning',
          title: 'Advertencia!',
          text: `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ]?.toLowerCase()}`,
          footer: 'Extensiones permitidas: PDF, PNG, JPG',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }

      if (file.size > 5000000) {
        e.target.value = '';
        onClearFileRegisterPaymentModal();
        return customSwaltAlert({
          title: 'Intenta de nuevo',
          text: 'Su archivo no puede tener un tamaño superior a 5MB',
          icon: 'warning',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        if (file.size <= 0) {
          onClearFileRegisterPaymentModal();
          e.target.value = '';
          return customSwaltAlert({
            title: 'Intenta de nuevo',
            text: 'Por favor adjunte un archivo valido',
            icon: 'warning',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      }
    } else {
      onClearFileRegisterPaymentModal();
      return console.error('Archivo vacio: ', file);
    }

    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    let ext = type_doc.split('/');
    let finalName = `${name_doc}.${ext[1]}`;

    setRegisterPaymentModal({
      ...registerPaymentModal,
      file: {
        base64: file64,
        fileName: finalName,
        ext: ext[1],
      },
    });
  };

  const handleIconInput = iconExtension => {
    if (!isEmptyOrUndefined(iconExtension)) {
      const extension = iconExtension?.toLowerCase();

      if (extension === 'pdf') {
        return PDF;
      }

      if (['jpg', 'jpeg', 'png'].includes(extension)) {
        return Image;
      }
    }
  };

  // -------------- ANCHOR NEW STATES ----------------------

  const { load: registerPaymentLoader, trigger: postRegisterPayment } = usePostMethod();
  const { trigger: onChangeEditPayment } = usePostMethod();

  const closeRegisterPayment = () => {
    setRegisterPaymentModal({
      ...registerPaymentModal,
      show: false,
      paymentType: '',
      value: '',
      file: {
        base64: '',
        fileName: '',
        ext: '',
      },
      detailsPayment: '',
    });
  };

  const sendRegisterPayment = () => {
    let valueStatus = false;
    let cutDownValidation = { valid: '' };
    let validations = validateEmptyString([
      { field: registerPaymentModal.file.base64, message: "El campo 'Anexo' es obligatorio" },
      { field: registerPaymentModal.value, message: "El campo 'Valor' es obligatorio" },
      { field: registerPaymentModal.paymentType, message: 'El tipo de pago es obligatorio' },
    ]);
    if (registerPaymentModal.paymentType === 'partial') {
      valueStatus =
        registerPaymentModal.value < registerPaymentModal.detailsPayment.balance_date
          ? true
          : message(
              'info',
              'Validar valor',
              'El valor del abono debe ser inferior al valor del saldo a la fecha',
            );
      cutDownValidation = validateEmptyString([
        {
          field: registerPaymentModal.reduceType,
          message: 'Debe escoger una opción para aplicar el abono',
        },
      ]);
    } else {
      valueStatus = true;
      cutDownValidation.valid = true;
    }

    let data = {
      id: registerPaymentModal.detailsPayment.id_adjustment,
      paymentType: registerPaymentModal.paymentType,
      paymentValue: registerPaymentModal.value,
      fileName: registerPaymentModal.file.fileName,
      fileBase64: registerPaymentModal.file.base64,
    };

    if (registerPaymentModal.reduceType !== '') {
      data.reduceType = registerPaymentModal.reduceType;
    }

    if (validations?.valid && valueStatus && cutDownValidation?.valid) {
      customSwaltAlert({
        title: `¿Está seguro?`,
        text: `Se registrará un pago a ${registerPaymentModal.detailsPayment.third}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          postRegisterPayment({
            url: '/payroll/adjustment-subscriptions/employee/',
            method: 'POST',
            body: data,
            token: token,
            succesAction: info => {
              setTrigger(trigger + 1);
              closeRegisterPayment();
              dispatch(
                getDiscountsAndPayouts({
                  employee: idCandidate,
                  entity_account: idEnterprise,
                }),
              );
              customSwaltAlert({
                title: `${info.title}`,
                icon: 'success',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
            doAfterException: error => {
              customSwaltAlert({
                title: `Intenta de nuevo`,
                text: `${error.message}`,
                icon: 'warning',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  const editPayment = () => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se editará el pago de ${registerPaymentModal.detailsPayment.third}'`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        onChangeEditPayment({
          url: '/payroll/adjustment-subscriptions/employee/',
          method: 'PUT',
          body: {
            id_adjustment: registerPaymentModal?.detailsPayment?.id_adjustment,
            fee_value: parseFloat(
              registerPaymentModal?.detailsPayment?.fee_value?.replace(/\$/g, '').replace(/,/g, ''),
            ),
            installments: parseInt(registerPaymentModal?.detailsPayment?.installments),
            type_adjustment: registerPaymentModal?.detailsPayment?.type_tag,
          },
          token: token,
          succesAction: info => {
            setTrigger(trigger + 1);
            closeRegisterPayment();
            dispatch(
              getDiscountsAndPayouts({
                employee: idCandidate,
                entity_account: idEnterprise,
              }),
            );
            customSwaltAlert({
              title: `${info.message}`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
          doAfterException: error => {
            customSwaltAlert({
              title: `Intenta de nuevo`,
              text: `${error.message}`,
              icon: 'warning',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  let provisionalOptions = [
    {
      value: '',
      label: 'Seleccionar...',
    },
    { value: 'partial', label: 'Abono' },
    { value: 'full', label: 'Pago total' },
  ];

  const [showDetailPayment, setShowDetailPayment] = useState({
    show: false,
    fileUrl: '',
  });

  const closeShowDetailPayment = () => {
    setShowDetailPayment({
      ...showDetailPayment,
      show: false,
      fileUrl: '',
    });
  };

  // ANCHOR DETAILS DEDUCC

  const [showDeduDetails, setShowDeduDetails] = useState({
    show: false,
    detailsPayment: {},
  });

  const closeShowDeduDetails = () => {
    setShowDeduDetails({
      ...showDeduDetails,
      show: false,
    });
  };

  // ANCHOR DEDU TABLE
  const deduDetailHeaders = [
    <div style={{ borderTopLeftRadius: '10px' }} className='px-2 text-center col-5' key={`Hash`}>
      No.
    </div>,
    <div style={{}} className='px-2 text-center col-5' key={`Hash`}>
      Fecha
    </div>,
    <div style={{ borderTopRightRadius: '10px ' }} key={`ValueDetailP`} className='col-2'>
      &nbsp;
    </div>,
  ];

  const listDeduDetail = () => {
    let elemMap;
    if (showDeduDetails?.detailsPayment?.length > 0) {
      const elem2 = showDeduDetails?.detailsPayment;
      elemMap = elem2.map(elem => {
        return (
          <section2 key={elem.code} className={`d-flex`}>
            <div className='col-md-5 text-center'>{elem.number}</div>
            <div className='col-md-5 text-center'>{elem.Fecha}</div>
            <div className='col-md-2 text-center'>
              <img
                alt='icon'
                src={lupa}
                className={`${tableStyles.svgDarkBlueFilter} cursorPointer`}
                onClick={() => {
                  setShowDetailPayment({
                    ...showDetailPayment,
                    show: true,
                    fileUrl: elem?.support_file ?? 'showMessage',
                    name: `Pago No. ${elem.number}`,
                  });
                }}
              />
            </div>
          </section2>
        );
      });
    }
    return elemMap;
  };

  let optionsType = [
    { value: '', label: 'Seleccionar...' },
    { value: 'discount', label: 'Descuento' },
    { value: 'order', label: 'Libranza' },
  ];

  const deleteRegister = e => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se eliminará el registro del ${String(e.type).toLowerCase()}, del tercero '${e?.third}'`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        postRegisterPayment({
          url: '/payroll/adjustment-subscriptions/employee/',
          method: 'DELETE',
          body: {
            id: e?.id_adjustment,
            employee: idCandidate,
          },
          token: token,
          succesAction: info => {
            setTrigger(trigger + 1);
            dispatch(
              getDiscountsAndPayouts({
                employee: idCandidate,
                entity_account: idEnterprise,
              }),
            );
            customSwaltAlert({
              title: `${info.message}`,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
          doAfterException: error => {
            customSwaltAlert({
              title: `Intenta de nuevo`,
              text: `${error.message}`,
              icon: 'warning',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderModal = () => {
    if (showDetailPayment?.fileUrl === 'showMessage') {
      return (
        <div
          style={{ width: '100%' }}
          className='d-flex align-self-center text-center justify-content-center p-5'
        >
          <span className={`${tableStyles.ordClearGrayText} ${tableStyles.f14} align-self-center`}>
            No hay anexo disponible
          </span>
        </div>
      );
    }

    if (showDetailPayment?.fileUrl?.split('.')?.at(-1) !== 'pdf') {
      return (
        <img
          alt='paymentFile'
          style={{ width: '70%', height: '100%', margin: 'auto' }}
          src={showDetailPayment.fileUrl}
        />
      );
    }

    return (
      <RenderMedicalFormulasPdf
        fileUrl={showDetailPayment.fileUrl}
        title={showDetailPayment?.name}
        goBack={true}
        goBackSmallDelete={true}
        backAction={() => {
          closeShowDetailPayment();
        }}
      />
    );
  };

  useEffect(() => {
    const installments = parseInt(registerPaymentModal?.detailsPayment?.installments);
    const feeValue = parseFloat(
      registerPaymentModal?.detailsPayment?.fee_value?.replace(/\$/g, '').replace(/,/g, ''),
    );
    const total = installments * feeValue;
    setRegisterPaymentModal(prevState => ({
      ...prevState,
      detailsPayment: {
        ...prevState?.detailsPayment,
        total: total,
      },
    }));
  }, [
    registerPaymentModal?.detailsPayment?.installments,
    registerPaymentModal?.detailsPayment?.fee_value,
  ]);

  return (
    <div>
      {isLoading || registerPaymentLoader ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      {/* SHOW DETAIL DEDU */}
      <ModalNew
        size='400'
        title='Detalle de deducción'
        show={showDeduDetails?.show}
        onHide={() => closeShowDeduDetails()}
      >
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
          <GenericTableScroll
            headers={deduDetailHeaders}
            dark={true}
            body={listDeduDetail()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>
      </ModalNew>

      {/* SHOW PAYMENT DETAIL */}

      <ModalNew
        title={'Detalle del pago'}
        show={showDetailPayment?.show}
        btnNoName={'Cancelar'}
        size={1200}
        onHide={() => closeShowDetailPayment()}
        btnNoEvent={() => closeShowDetailPayment()}
      >
        <div className='mb-4 d-flex justify-center'>{renderModal()}</div>
      </ModalNew>
      {/*----------------------------------REGISTER PAYMENT MODAL ---------------------- */}

      <ModalNew
        title={registerPaymentModal.modalType === 'register' ? 'Registrar pago' : 'Editar pago'}
        show={registerPaymentModal.show}
        btnYesName={registerPaymentModal.modalType === 'register' ? 'Generar' : 'Aceptar'}
        btnNoName={'Cancelar'}
        size={500}
        onHide={() => closeRegisterPayment()}
        btnYesEvent={
          registerPaymentModal.modalType === 'register' ? sendRegisterPayment : editPayment
        }
        btnNoEvent={() => closeRegisterPayment()}
      >
        <div className='mb-4'>
          <Row className={`${tableStyles.bgColorClearBlue}`}>
            <Col xs={3}>
              <p className={`${tableStyles.crudModalLabel} `}>No. de cuotas</p>
              <input
                disabled={registerPaymentModal.modalType === 'edit' ? false : true}
                onChange={e => {
                  setRegisterPaymentModal(prevState => ({
                    ...prevState,
                    detailsPayment: {
                      ...prevState.detailsPayment,
                      installments: e.target.value,
                    },
                  }));
                }}
                value={registerPaymentModal?.detailsPayment?.installments}
                type='text'
                className='register-inputs text-secondary'
              />
            </Col>

            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>Valor cuotas</p>
              <NumberFormat
                disabled={registerPaymentModal.modalType === 'edit' ? false : true}
                onChange={e =>
                  setRegisterPaymentModal(prevState => ({
                    ...prevState,
                    detailsPayment: {
                      ...prevState.detailsPayment,
                      fee_value: e.target.value,
                    },
                  }))
                }
                className={`register-inputs text-secondary`}
                allowNegative={false}
                prefix='$'
                placeholder='$0'
                thousandsGroupStyle='thousand'
                thousandSeparator={','}
                decimalSeparator={'.'}
                value={
                  registerPaymentModal?.detailsPayment?.fee_value
                    ? registerPaymentModal?.detailsPayment?.fee_value
                    : '-'
                }
              />
            </Col>
            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>Total adeudado</p>
              <input
                disabled={true}
                value={convertMoneyFormat(
                  registerPaymentModal?.detailsPayment?.total
                    ? registerPaymentModal?.detailsPayment?.total
                    : '0',
                )}
                className='register-inputs text-secondary'
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>Saldo a la fecha</p>
              <input
                disabled={true}
                value={convertMoneyFormat(
                  registerPaymentModal?.detailsPayment?.balance_date
                    ? registerPaymentModal?.detailsPayment?.balance_date
                    : '0',
                )}
                className='register-inputs text-secondary'
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>Descontado a la fecha</p>
              <input
                disabled={true}
                value={convertMoneyFormat(
                  registerPaymentModal?.detailsPayment?.discounted_date
                    ? registerPaymentModal?.detailsPayment?.discounted_date
                    : '0',
                )}
                className='register-inputs text-secondary'
              />
            </Col>
          </Row>
          {/* {registerPaymentModal.modalType === "edit" &&
            <div style={{ justifyContent: "end" }} className="ml-auto">
              <label htmlFor="" className={`${tableStyles.crudModalLabel} `}> Recalcular</label>
              <Button className={tableStyles.btnPrimary}  >

              </Button>
            </div>
          } */}

          {registerPaymentModal.modalType === 'register' && (
            <Form.Group controlId='servicio' className='py-2'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Tipo de pago
                  <span className={'text-warning'}>*</span>
                </p>{' '}
              </Form.Label>
              <SelectComponent
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
                options={provisionalOptions}
                value={provisionalOptions.find(x => x.value === registerPaymentModal.paymentType)}
                onChange={e =>
                  setRegisterPaymentModal({
                    ...registerPaymentModal,
                    paymentType: e.value,
                    value:
                      e.value === 'full'
                        ? registerPaymentModal?.detailsPayment?.balance_date
                          ? registerPaymentModal?.detailsPayment?.balance_date
                          : ''
                        : '',
                    reduceType: '',
                  })
                }
              />
            </Form.Group>
          )}

          {registerPaymentModal.paymentType === 'partial' &&
            registerPaymentModal.modalType === 'register' && (
              <div className=''>
                <Form.Group controlId='fName'>
                  <div className='d-flex'>
                    <Radio
                      checked={registerPaymentModal.reduceType === 'reduceMonthly' ? true : false}
                      onClick={() => {
                        setRegisterPaymentModal({
                          ...registerPaymentModal,
                          reduceType: 'reduceMonthly',
                        });
                      }}
                      name='alphabetic'
                      id='alphabetic'
                      style={{ color: '#005dbf' }}
                    />

                    <Form.Label className={`mt-1 ${tableStyles.ordDarkBlueText}`}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Reducir cuota mensual
                        <span className={'text-warning'}>*</span>
                      </p>{' '}
                    </Form.Label>
                  </div>
                  <div className='d-flex'>
                    <Radio
                      checked={registerPaymentModal.reduceType === 'reduceQuota' ? true : false}
                      onClick={() => {
                        setRegisterPaymentModal({
                          ...registerPaymentModal,
                          reduceType: 'reduceQuota',
                        });
                      }}
                      name='alphabetic'
                      style={{ color: '#005dbf' }}
                      id='alphabetic'
                    />
                    <Form.Label className={`pt-2 ${tableStyles.ordDarkBlueText}`}>
                      <p className={`${tableStyles.crudModalLabel} `}>
                        Reducir número de cuotas
                        <span className={'text-warning'}>*</span>
                      </p>{' '}
                    </Form.Label>
                  </div>
                </Form.Group>
              </div>
            )}
          {registerPaymentModal.modalType === 'register' && (
            <>
              <div>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Valor
                  <span className={'text-warning'}>*</span>
                </p>

                <NumberFormat
                  key={'valuQuote' + trigger}
                  id={`valueToFocus`}
                  disabled={registerPaymentModal.paymentType === 'full' ? true : false}
                  onValueChange={e => {
                    if (validExpReg(e.floatValue, expRegList.number) || e.floatValue === '') {
                      setRegisterPaymentModal({ ...registerPaymentModal, value: e.floatValue });
                    }
                  }}
                  className={`register-inputs text-secondary`}
                  name='let'
                  allowNegative={false}
                  prefix='$'
                  placeholder='$0'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  value={registerPaymentModal.value}
                />
              </div>
              <div>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Anexos
                  <span className={'text-warning'}>*</span>
                </p>
                <label
                  htmlFor='documentsNR'
                  className='d-flex justify-content-between '
                  style={{
                    border: '1px solid #7FADDE',
                    borderRadius: '5px',
                    height: '1.8rem',
                  }}
                >
                  <div
                    className={`rounded
            ${
              !isEmptyOrUndefined(registerPaymentModal.file.fileName) &&
              tableStyles.bgColorClearBlue
            }`}
                  >
                    {!isEmptyOrUndefined(registerPaymentModal.file.fileName) && (
                      <img
                        className='cursorPointer mx-2'
                        width='5%'
                        src={handleIconInput(registerPaymentModal.file.ext)}
                        alt='true'
                      />
                    )}
                    <input
                      key={'attached' + trigger}
                      style={{
                        textIndent: '-140px',
                        fontSize: '13px',
                      }}
                      type='file'
                      accept='.pdf,.png,.jpg,.jpge'
                      className='text-secondary cursorPointer'
                      name='documentsNR'
                      onChange={e => onChangeDocRegister(e)}
                      multiple={false}
                    />
                  </div>
                  <img
                    className='cursorPointer'
                    width='20px'
                    src={Adjuntar}
                    title='Adjuntar'
                    alt='icon'
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </ModalNew>

      {/* /* ------------------------------ PAYMENT MODAL ----------------------------- */}
      <ModalNew
        size='540'
        title='Detalle de pagos'
        subtitle={showPaymentDetail?.detailsPayment?.third}
        show={showPaymentDetail?.show}
        onHide={() => setShowPaymentDetail(false)}
      >
        <Row className={`${tableStyles.bgColorClearBlue}`}>
          <Col xs={2}>
            <p className={`${tableStyles.crudModalLabel} `}>No. de cuotas</p>
            <input
              disabled={true}
              value={showPaymentDetail?.detailsPayment?.installments}
              type='text'
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={4}>
            <p className={`${tableStyles.crudModalLabel} `}>Valor cuotas</p>
            <input
              disabled={true}
              type='text'
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.fee_value
                  ? showPaymentDetail?.detailsPayment?.fee_value
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Total adeudado 2022</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.total
                  ? showPaymentDetail?.detailsPayment?.total
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Saldo a la fecha</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.balance_date
                  ? showPaymentDetail?.detailsPayment?.balance_date
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
          <Col xs={6}>
            <p className={`${tableStyles.crudModalLabel} `}>Descontado a la fecha</p>
            <input
              disabled={true}
              value={convertMoneyFormat(
                showPaymentDetail?.detailsPayment?.discounted_date
                  ? showPaymentDetail?.detailsPayment?.discounted_date
                  : '0',
              )}
              className='register-inputs text-secondary'
            />
          </Col>
        </Row>
        <GenericTableNew headers={paymentDetailsHeaders}>{listPaymentDetail()}</GenericTableNew>
      </ModalNew>

      <Row>
        {/* /* ------------------------- ADD DISCOUNT OR PAYOUT ------------------------- */}
        <Col className='mt-4' xs={5}>
          <h5 className={tableStyles.darkBlueText}>
            <b>Agregar descuento o libranza</b>
          </h5>

          <p className={`${tableStyles.crudModalLabel} `}>
            Seleccionar deducción
            <span className={'text-warning'}>*</span>
          </p>
          <SelectComponent
            key={'deduction' + trigger}
            onChange={e => {
              setInputs({ ...inputs, deduction: e });
            }}
            options={formattedDeductions}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={customSelectNew}
            value={formattedDeductions.find(x => x.value === inputs?.deduction?.value)}
          ></SelectComponent>
          <p className={`${tableStyles.crudModalLabel} `}>
            Tercero
            <span className={'text-warning'}>*</span>
          </p>
          <input
            key={'Third' + trigger}
            value={third()}
            disabled={true}
            className='register-inputs text-secondary'
          />
          <Row>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Tipo
                <span className={'text-warning'}>*</span>
              </p>
              <SelectComponent
                key={'type' + trigger}
                options={optionsType}
                onChange={e => setInputs({ ...inputs, type: e.value })}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
                value={optionsType.find(x => x.value === inputs.type)}
              ></SelectComponent>
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Fecha de inicio
                <span className={'text-warning'}>*</span>
              </p>
              <input
                key={'date' + trigger}
                onChange={e => setInputs({ ...inputs, startDate: e.target.value })}
                value={inputs.startDate}
                type='date'
                className='register-inputs text-secondary'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Valor de cuotas
                <span className={'text-warning'}>*</span>
              </p>

              <NumberFormat
                key={'valuQuote' + trigger}
                id={`valueToFocus`}
                onValueChange={e => {
                  if (validExpReg(e.floatValue, expRegList.number) || e.floatValue === '') {
                    setInputs({ ...inputs, quoteValue: e.floatValue });
                  } else {
                    setInputs({ ...inputs, quoteValue: 0 });
                  }
                }}
                className={`register-inputs text-secondary`}
                name='let'
                allowNegative={false}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                value={inputs.quoteValue}
              />
            </Col>
            <Col xs={6}>
              <p className={`${tableStyles.crudModalLabel} `}>
                No. de cuotas
                <span className={'text-warning'}>*</span>
              </p>

              <NumberFormat
                key={'feeNumber' + trigger}
                id={`valueToFocus`}
                onValueChange={e => {
                  if (validExpReg(e.floatValue, expRegList.number) || e.floatValue === '') {
                    setInputs({ ...inputs, quoteNumber: e.floatValue });
                  } else {
                    setInputs({ ...inputs, quoteNumber: 0 });
                  }
                }}
                className={`register-inputs text-secondary`}
                allowNegative={false}
                value={inputs.quoteNumber}
              />
            </Col>
          </Row>
          <p className={`${tableStyles.crudModalLabel} `}>
            Valor total
            <span className={'text-warning'}>*</span>
          </p>
          <NumberFormat
            key={'totalvalue' + trigger}
            disabled={true}
            className={`register-inputs text-secondary`}
            allowNegative={false}
            prefix='$'
            thousandsGroupStyle='thousand'
            thousandSeparator
            value={
              !isNaN(inputs.quoteNumber * inputs.quoteValue)
                ? inputs.quoteNumber * inputs.quoteValue
                : ''
            }
          />

          <p className={`${tableStyles.crudModalLabel} `}>Descripción</p>
          <textarea
            key={'descr' + trigger}
            onChange={e => setInputs({ ...inputs, description: e.target.value })}
            placeholder={'Escribir...'}
            id='txtArea'
            rows='10'
            cols='10'
            style={{ height: '4rem' }}
            className={`register-inputs text-secondary`}
          ></textarea>
          <p className={`${tableStyles.crudModalLabel} `}>Anexos</p>
          <label
            htmlFor='documentsNR'
            className='d-flex justify-content-between '
            style={{
              border: '1px solid #7FADDE',
              borderRadius: '5px',
              height: '1.8rem',
            }}
          >
            <div
              className={`rounded
            ${!isEmptyOrUndefined(inputs.fileName) && tableStyles.bgColorClearBlue}`}
            >
              {!isEmptyOrUndefined(inputs.fileName) && (
                <img
                  className='cursorPointer ml-3 m-0 p-0 pb-2'
                  width='5%'
                  src={placeMyIcon(inputs.ext)}
                  alt='true'
                />
              )}
              <input
                key={'attached' + trigger}
                style={{
                  textIndent: '-170px',
                }}
                type='file'
                className='text-secondary cursorPointer'
                name='documentsNR'
                onChange={e => onChangeDoc(e)}
                multiple={false}
              />
            </div>
            <img
              className='cursorPointer'
              width='20px'
              src={Adjuntar}
              title='Adjuntar'
              alt='icon'
            />
          </label>
          <div className={`customTabs__Footer mt-2 p-0`}>
            <Button
              className={tableStyles.btnPrimary}
              onClick={() => handleSubmit()}
              disabled={allowSend ? false : true}
            >
              <img className='cursorPointer' src={send} alt='icon' />
              &nbsp; Enviar
            </Button>
          </div>
        </Col>
        {/* /* ---------------------- VERTICAL LINE --------------------- */}
        <Col className=' d-flex justify-content-center' xs={1}>
          <div style={{ width: '20%' }}>&nbsp;</div>
          <hr
            className='m-0 p-0'
            style={{
              border: 'none',
              borderLeft: '1px solid hsla(200, 10%, 50%, 100)',
              height: '100vh',
              width: '1px',
            }}
          ></hr>
        </Col>
        {/* /* ---------------------- CURRENT DISCOUNTS AND PAYOUTS --------------------- */}
        <Col className={`mt-4 ${tableStyles.scrollThis}`} xs={6}>
          <h5 className={tableStyles.darkBlueText}>
            <b>Descuentos y libranzas vigentes</b>
          </h5>

          <Row className='mb-5'>
            <Col xs={12}>
              <table
                style={{
                  fontFamily: ' PT Sans ',
                }}
              >
                <thead>
                  <tr>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Fecha inicio
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tipo
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tercero
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Valor cuota
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Cantidad
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {current?.length > 0 &&
                    current.map((e, i) => {
                      return (
                        <tr key={i}>
                          <th
                            onClick={() =>
                              setShowPaymentDetail({
                                show: true,
                                detailsPayment: e,
                              })
                            }
                            scope='row'
                            className={`${tableStyles.darkBlueText} cursorPointer`}
                          >
                            {e.start_date}
                          </th>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.type}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.third}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            {convertMoneyFormat(e.fee_value && e.fee_value)}
                          </td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.count}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            <CustomPopupExtend
                              triggerSrc={threeDots}
                              noHover={true}
                              showDetails={true}
                              showDetailsEvent={() => {
                                setShowDetailPayment({
                                  ...showDetailPayment,
                                  show: true,
                                  name: e?.third,
                                  fileUrl: e?.support_file ?? 'showMessage',
                                });
                              }}
                              textDetails='Ver anexo'
                              showRegister={true}
                              registerEvent={() => {
                                setRegisterPaymentModal({
                                  ...registerPaymentModal,
                                  modalType: 'register',
                                  show: true,
                                  detailsPayment: e,
                                });
                              }}
                              registerText='Registrar pago'
                              showDelete={e?.details_payments?.length > 0 ? false : true}
                              deleteEvent={() => {
                                deleteRegister(e);
                              }}
                              deleteText='Eliminar'
                              showEdit={e?.type_tag === 'discount' ? true : false}
                              editText='Editar descuento'
                              editClickEvent={() => {
                                setRegisterPaymentModal({
                                  ...registerPaymentModal,
                                  modalType: 'edit',
                                  show: true,
                                  detailsPayment: e,
                                });
                              }}
                            ></CustomPopupExtend>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
          <h5 className={tableStyles.darkBlueText}>
            <b>Historial de deducciones</b>
          </h5>
          <Row>
            <Col xs={12}>
              <table
                style={{
                  fontFamily: ' PT Sans ',
                }}
              >
                <thead>
                  <tr>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Fecha
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tipo
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Tercero
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      Valor
                    </th>
                    <th className={`col-md-2 ${tableStyles.blackGrayBoldFont} `} scope='col'>
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {settled?.length > 0 &&
                    settled.map((e, i) => {
                      return (
                        <tr key={i}>
                          <th
                            onClick={() =>
                              setShowPaymentDetail({
                                show: true,
                                detailsPayment: e,
                              })
                            }
                            scope='row'
                            className={`${tableStyles.darkBlueText} cursorPointer`}
                          >
                            {e.start_date}
                          </th>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.type}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>{e.third}</td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            {convertMoneyFormat(e.fee_value && e.fee_value)}
                          </td>
                          <td className={`text-secondary ${tableStyles.fw700End}`}>
                            <img
                              alt='icon'
                              src={showDedu}
                              className={`cursorPointer ${tableStyles.svgDarkBlueFilter}`}
                              onClick={() => {
                                setShowDetailPayment({
                                  ...showDetailPayment,
                                  show: true,
                                  name: e?.third,
                                  fileUrl: e?.support_file ?? 'showMessage',
                                });
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
