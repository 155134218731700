import styled from "styled-components";
// import tableStyles from "../components/Layouts/tableStyle.module.scss";

export const Button = styled.button`
  display: inline-block;
  color: palevioletred;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
  display: block;
`;

export const BoxSelect = styled.div`
  width: 300px;
`;

export const Title = styled.span`
  font-weight: bold;
`;

export const ButtonSubmit = styled.button`
  padding: 10px;
  background-color: #00b4cc;
  color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  width: 140px;
  text-align: center;
`;

export const BoxButton = styled.div`
  padding: 10px;
  background-color: white;
`;

export const ItemService = styled.div`
  padding-left: 15px;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 340px;
`;
