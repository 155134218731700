import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { message } from "../helpers/helpers";
import { GET_STATUS_PAYMENT } from "./actionTypes";

export const getStatusPayment = (objFilters) => (dispatch, getState) => {
    dispatch({
      type: GET_STATUS_PAYMENT,
      loading: true
    });
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/medical/status/${objFilters}/`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch({
          type: GET_STATUS_PAYMENT,
          payload: res.results,
          rowTotal: res.row_total,
          loading: false
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STATUS_PAYMENT,
          payload: [],
          rowTotal: 0,
          loading: false
        });
        console.error(error.message);
        message(
          "error",
          "Error",
          "Ha ocurrido un error al cargar la información"
        );
      });
  };