import { Close } from '@material-ui/icons';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import { useGetMethod, usePostMethod } from '../../../Hooks';
import { convertMoneyFormat, customSwaltAlert, loader } from '../../../helpers';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function DownloadTemplate({ isOpen, onClose }) {
  const storage = useSelector(state => state);

  const [companyData, setCompanyData] = useState({
    companyId: '',
    companyNit: '',
  });
  const [consignmentsResult, setConsignmentsResult] = useState([]);

  const { trigger: getConsignments, load: consignmentsLoader } = useGetMethod();
  const { load: statusLoader, trigger: downloadTemplate } = usePostMethod();

  const onCloseModal = () => {
    onClose();
    setCompanyData({
      companyId: '',
      companyNit: '',
    });
    setConsignmentsResult([]);
  };

  const onSubmit = () => {
    downloadTemplate({
      token: storage.loginReducer.Authorization,
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        client: companyData.companyId,
      },
      method: 'POST',
      url: '/receivable/preDownload/download/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Descargado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            const anchor = document.createElement('a');
            anchor.target = '_blank';
            anchor.href = results.results.base64;
            anchor.download = results.results.filename;
            anchor.click();

            onCloseModal();
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onSelectCompany = async option => {
    setCompanyData({
      companyId: option.value,
      companyNit: option.nit,
    });

    if (option.value) {
      const result = await getConsignments({
        url: '/receivable/corporateDeposit/',
        objFilters: {
          page: 1,
          perpage: 9999,
          eaccount: storage.loginReducer.currentAccount.id,
          dateFrom: '',
          dateUntil: '',
          client: option.value,
          niif: '',
          status: '',
        },
        token: storage.loginReducer.Authorization,
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
      setConsignmentsResult(result.results);
    } else {
      setConsignmentsResult([]);
    }
  };

  const optionsClient = useMemo(() => {
    let options = [{ value: '', label: 'Seleccionar...', nit: '' }];
    storage.invoiceReducer.clients.forEach(item => {
      options.push({
        value: item.id,
        label: item.copyName,
        nit: item.Nit + '-' + item.Nit.slice(item.Nit.length - 1),
      });
    });
    return options;
  }, [storage.invoiceReducer.clients]);

  const render = () => {
    return (
      <>
        {(statusLoader || consignmentsLoader) && loader}

        <Modal
          show={isOpen}
          centered
          size='lg'
          className={modalStyles.container}
          onHide={onCloseModal}
        >
          <div className='d-flex align-items-center justify-content-between py-3 px-4'>
            <div></div>
            <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
              Descargar plantilla
            </h3>
            <Close className='pointer text-secondary' onClick={onCloseModal} />
          </div>
          <hr className='m-0' />
          <div className='p-4'>
            <div className='row'>
              <div className='col-4'>
                <small className='text-primary ms-1'>
                  Empresa
                  <span className='text-danger'>*</span>
                </small>
                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                  options={optionsClient}
                  onChange={option => onSelectCompany(option)}
                />
              </div>
              <div className='col-4'>
                <small className='text-primary ms-1'>NIT</small>
                <input
                  type='text'
                  className='register-inputs'
                  defaultValue={companyData.companyNit}
                  disabled
                />
              </div>
            </div>

            <div
              className='row g-0 py-2 text-white fw-bold small mt-4'
              style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            >
              <div className='col-2 d-flex align-items-center px-2'>Identificador</div>
              <div className='col-2 px-2 d-flex align-items-center'>Fecha de consignación</div>
              <div className='col-3 px-2 d-flex align-items-center'>Tipo de consignación</div>
              <div className='col-3 px-2 d-flex align-items-center'>Cuenta contable</div>
              <div className='col-2 text-end px-2 d-flex align-items-center'>Valor recaudo</div>
            </div>
            <div
              className={`${tableStyles.odd_rows} overflow-auto small`}
              style={{ maxHeight: 200 }}
            >
              {consignmentsResult?.length ? (
                consignmentsResult.map((item, i) => (
                  <div key={i} className='row g-0 align-items-center py-2 small'>
                    <div className='col-2 px-2'>{item.identifier}</div>
                    <div className='col-2 px-2'>
                      {moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                    </div>
                    <div className='col-3 px-2'>{item.type}</div>
                    <div className='col-3 px-2'>{item.niif}</div>
                    <div className='col-2 text-end px-2'>{convertMoneyFormat(item.amount)}</div>
                  </div>
                ))
              ) : (
                <p className='text-muted text-center mt-3'>
                  Seleccione una empresa para descargar plantilla.
                </p>
              )}
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button onClick={onCloseModal} className={`${tableStyles.btnSecondary} me-3`}>
                Cancelar
              </button>
              <button className={`${tableStyles.btnPrimaryAlt}`} onClick={onSubmit}>
                Guardar
              </button>
            </div>
          </div>
        </Modal>
      </>
    );
  };

  return render();
}
