import { GET_DISCIPLINARY_PROCESS } from "../actions/actionTypes";


const initialState = {
    results: [],
    row_total: 0,
    loading_process: false
};
export const DisciplinaryProcessesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DISCIPLINARY_PROCESS:
            return {
                ...state,
                results: action.payload,
                row_total: action.rows,
                loading_process: action.loading
            };
        default:
            return state;
    }
};

