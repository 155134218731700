
import {
    GET_RECEIPT_OF_INVOICES,
    GET_STATUS,
    GET_CLIENT,
    GET_DETAIL_RECORD,
    GET_HISTORY,
    GET_ONE_MOVEMENT,
    GET_CONTRACTS,
    GET_ACCOUNTABLES,
    GET_BALANCE_SUM,
    GET_BALANCE_TOTAL,
    GET_ONE_BALANCE,
    GET_PROVISION,
    GET_DETAIL_DETERIORATE,
    SEND_INVOICE,
    GET_CALENDAR,
    PAYLEGAL_COLLECTION_LOADING,
    GET_DAY_CALENDAR,
    GET_DETAIL_CALENDAR,
    GET_TYPE_ACTIVITY,
    GET_COLLECTION_LETTER,
} from "../actions/actionTypes";

const initialState = {
    invoices: [],
    invoices_total: 0,
    status: [],
    clients: [],
    detail: [],
    history: [],
    history_total: 0,
    movements_for_invoice: [],
    movements_for_invoice_total: 0,
    contracts: [],
    contracts_total: 0,
    accountables: [],
    accountables_total: 0,
    balance_sum: [],
    balance_sum_total: 0,
    balance_total: [],
    balance_total_total: 0,
    one_balance: [],
    one_balance_total: 0,
    detail_deteriorate: [],
    send_inv: 0,
    calendar: [],
    loading_PayLegalCollection: false,
    dayCalendar: [],
    DetailCalendar: [],
    typeActivity: [],
    loading_det_receipt_invoice: false,
    loading_receipt_invoice: false,
    loading_get_one_balance:false,
    collectionLetter: [],
    history_loading: false,
    balance_sum_loading: false,
    provision_loading: false,
    loading_calendar_detail: false,
    loadingProvision: false,
    rowTotal: 0

};

export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECEIPT_OF_INVOICES:
            return {
                ...state,
                invoices: action.results,
                invoices_total: action.total,
                loading_receipt_invoice: action.loading,

            };
        case GET_STATUS:
            return {
                ...state,
                status: action.results,
            };
        case GET_CLIENT:
            return {
                ...state,
                clients: action.results,
            };
        case GET_DETAIL_RECORD:
            return {
                ...state,
                detail: action.results,
                rowTotal: action.rowTotal,
                loading_det_receipt_invoice: action.loading,

            };

        case GET_HISTORY:
            return {
                ...state,
                history: action.results,
                history_total: action.total,
                history_loading: action.loading
            };
        case GET_ONE_MOVEMENT:
            return {
                ...state,
                movements_for_invoice: action.results,
                movements_for_invoice_total: action.total
            };
        case GET_CONTRACTS:
            return {
                ...state,
                contracts: action.results,
                contracts_total: action.total
            };
        case GET_ACCOUNTABLES:
            return {
                ...state,
                accountables: action.results,
                accountables_total: action.total
            };
        case GET_BALANCE_SUM:
            return {
                ...state,
                balance_sum: action.results,
                balance_sum_total: action.total,
                balance_sum_loading: action.loading
            };
        case GET_BALANCE_TOTAL:
            return {
                ...state,
                balance_total: action.results,
                balance_total_total: action.total
            };
        case GET_ONE_BALANCE:
            return {
                ...state,
                one_balance: action.results,
                one_balance_total: action.total,
                loading_get_one_balance: action.loading,
            };
        case GET_PROVISION:
            return {
                ...state,
                provision: action.results,
                rowTotal: action.rowTotal,
                loadingProvision: action.loading
            };
        case GET_DETAIL_DETERIORATE:
            return {
                ...state,
                detail_deteriorate: action.results,
                provision_loading: action.loading,
            };
        case SEND_INVOICE:
            return {
                ...state,
                send_inv: action.results,
            };
        case GET_CALENDAR:
            return {
                ...state,
                calendar: action.results,
            };
        case PAYLEGAL_COLLECTION_LOADING:
            return {
                ...state,
                loading_PayLegalCollection: action.loading,
            };
        case GET_DAY_CALENDAR:
            return {
                ...state,
                dayCalendar: action.results,
                loadingCalendar: action.loading
            };
        case GET_DETAIL_CALENDAR:
            return {
                ...state,
                DetailCalendar: action.results,
                loading_calendar_detail: action.loading
            };
        case GET_TYPE_ACTIVITY:
            return {
                ...state,
                typeActivity: action.results,
            };
        case GET_COLLECTION_LETTER:
            return {
                ...state,
                collectionLetter: action.results,
            };
        default:
            return state;
    }
};
