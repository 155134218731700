/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  formatToRcSelect,
  generateId,
  loader,
  today,
  validateEmptyString,
} from '../../../../helpers/helpers';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';
import {
  customSelectNewDark,
  customSelectNewDarkNewNom,
} from '../../../Layouts/react-select-custom';
import 'reactjs-popup/dist/index.css';
import trash from '../../../../assets/img/icons/trash.svg';
import addIcon from '../../../../assets/img/icons/addbutton.svg';
import closeIconBlue from '../../../../assets/img/icons/CloseIconBlue.svg';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import CardAccordionComponent from './CardAccordionComponent';
import Select from 'react-select';
import { customSwaltAlert } from '../../../../helpers/customSwaltAlert';

const Questions = ({ dataSections, dataDetail, handleBack, detail, edit }) => {
  // SECTION STATES ----------------------
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;
  const { results: positionOptionsResults, trigger: getPositionOptions } = useGetMethod();
  const { results: dependenciesOptionsResults, trigger: getDependenciesOptions } = useGetMethod();
  const dependenciesOptions = formatToRcSelect(
    dependenciesOptionsResults?.results,
    'id',
    'title',
    '',
    '',
    '',
  );
  //  const [loading, (detail || edit) &&  setLoading] = useState(false);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'evalPerf',
  );
  const [sections, setSections] = useState([
    {
      idSection: generateId(),
      title: '',
      description: '',
      type: 'specific',
      idDependece: undefined,
      questions: [
        {
          idAsk: generateId(),
          label: '',
          assessed: { idPosition: '', idLeader: '' },
        },
      ],
    },
    {
      idSection: generateId(),
      title: '',
      description: '',
      type: 'transversal',
      idDependece: null,
      questions: [
        {
          idAsk: generateId(),
          label: '',
        },
      ],
    },
  ]);

  const [triggerSelects, setTriggerSelects] = useState(0);

  useEffect(() => {
    if (detail || edit) {
      setSections(dataSections);
    }
  }, [dataSections]);

  useEffect(() => {
    if (detail || edit) {
      getDependenciesOptions({
        url: '/payroll/dependencies',
        objFilters: {
          eaccount: idAccount,
        },
        token: token,
      });
    } else {
      getDependenciesOptions({
        url: '/payroll/dependencies',
        objFilters: {
          eaccount: idAccount,
          active: 1,
        },
        token: token,
      });
    }

    getPositionOptions({
      url: '/payroll/job_position/',
      objFilters: {
        // supervisor_pos: supervisor,
        active: 1,
        entity_account: idAccount,
      },
      token: token,
      doAfterSuccess: results => {
        if (detail || edit) {
          dataSections.forEach(e => {
            setTimeout(() => {
              let tempArrSect = sections;
              let findSection = tempArrSect.find(x => x.idSection === e.idSection);
              let newDependecies = formatToRcSelect(
                results?.results?.filter(x => x.dependencie_id === e.idDependece),
                'id',
                'job_title',
                'supervisor_pos',
                '',
                '',
              );
              findSection.positionOptions = newDependecies;
              setSections(tempArrSect);
            }, 1000);
          });
        }
      },
    });
  }, [token, sections]);

  const optionsType = [
    { value: '', label: 'Seleccionar...' },
    { value: 'specific', label: 'Especifico' },
    { value: 'transversal', label: 'Transversal' },
  ];

  const addSection = () => {
    let tempArr = sections;
    tempArr.push({
      idSection: generateId(),
      title: '',
      description: '',
      type: '',
      idDependece: undefined,
      questions: [
        {
          idAsk: generateId(),
          label: '',
        },
      ],
    });
    setSections(tempArr);
  };

  const deleteSection = idSection => {
    let tempArr = sections;
    let findIndexOf = tempArr.findIndex(e => e.idSection === idSection);
    tempArr.splice(findIndexOf, 1);
    setSections(tempArr);
  };

  const addQuestion = idSection => {
    let tempArr = sections;
    let findSection = tempArr.find(e => e.idSection === idSection);
    if (findSection.type === 'specific') {
      findSection.questions.push({
        idAsk: generateId(),
        label: '',
        assessed: { idPosition: '', idLeader: '' },
      });
    } else {
      findSection.questions.push({
        idAsk: generateId(),
        label: '',
      });
    }

    setSections(tempArr);
  };

  const deleteQuestion = (idSection, idQuestion) => {
    let tempArr = sections;
    let findSection = tempArr.find(e => e.idSection === idSection);
    let indexOfSection = tempArr.findIndex(e => e.idSection === idSection);
    let indexOfQuestion = findSection.questions.findIndex(e => e.idAsk === idQuestion);
    tempArr[indexOfSection].questions.splice(indexOfQuestion, 1);
    setSections(tempArr);
  };

  // ---------FILES --------------------

  const onChangeInputsTitle = (label, value, idSection) => {
    let tempArrSect = sections;
    let findSection = tempArrSect.find(e => e.idSection === idSection);
    findSection[label] = value;

    if (label === 'type' && value !== 'specific') {
      // DELETING PROPERTIES OF JSON THAT WE DON'T NEED ANYMORE, JUST WHEN THE TYPE CHANGE
      findSection.idDependece = null;
      findSection.questions = [
        {
          idAsk: generateId(),
          label: '',
        },
      ];
    }

    if (label === 'type' && value === 'specific') {
      findSection.questions = [
        {
          idAsk: generateId(),
          label: '',
          assessed: { idPosition: '', idLeader: '' },
        },
      ];
      findSection.idDependece = undefined;
    }
    if (label === 'idDependece') {
      findSection?.questions?.forEach((e, ind) => {
        let elem = e;
        elem = { ...e, assessed: { idPosition: '', idLeader: '' } };
        findSection.questions[ind] = elem;
      });
    }
    if (label === 'type') {
      getDependenciesOptions({
        url: '/payroll/dependencies',
        objFilters: {
          eaccount: idAccount,
          active: 1,
        },
        token: token,
      });
    } else {
      setTriggerSelects(triggerSelects + 1);
    }

    setSections(tempArrSect);
  };

  const onChangeBodyQuestions = (label, value, idSection, idQuestion) => {
    // A FUNCTION TO SET THE STATE IN A DYNAMIC WAY OF THE BODY CARD (QUESTIONS)
    let tempArrSect = sections;
    let findSection = tempArrSect.find(e => e.idSection === idSection);
    let findQuestion = findSection.questions.find(e => e.idAsk === idQuestion);
    if (label === 'type' && value !== 'specific') {
      // DELETING PROPERTIES OF JSON THAT WE DON'T NEED ANYMORE, JUST WHEN THE TYPE CHANGE
      delete findQuestion.idPosition;
      delete findQuestion.idArea;
      delete findQuestion.idLeader;
      delete findQuestion.positionOptions;
    }

    if (label === 'assessed') {
      findQuestion[label] = {
        idPosition: value.value,
        idLeader: value.extra,
      };
      setTriggerSelects(triggerSelects + 1);
    } else {
      findQuestion[label] = value;
    }
    // WE ARE SEARCHING THE INDEX OF THE SECTION AND THE QUESTION TO SET THE DATA - I DIDN'T KNEW OTHER WAY
    let indexOfSection = tempArrSect.findIndex(e => e.idSection === idSection);
    let indexOfQuestion = findSection.questions.findIndex(e => e.idAsk === idQuestion);
    tempArrSect[indexOfSection].questions[indexOfQuestion] = findQuestion;

    setSections(tempArrSect);
  };

  const getPositionOptionsFunction = (supervisor, idSection) => {
    let tempArrSect = sections;
    let findSection = tempArrSect.find(e => e.idSection === idSection);
    let newDependecies = formatToRcSelect(
      positionOptionsResults?.results?.filter(e => e.dependencie_id === supervisor),
      'id',
      'job_title',
      'supervisor_pos',
      '',
      '',
    );
    findSection.positionOptions = newDependecies;
    setSections(tempArrSect);
  };
  // !SECTION -------------------------

  const buttonsCard = elem => {
    let findIndexOf = sections.findIndex(e => e.idSection === elem.idSection);
    let returnArr;
    if (findIndexOf === 0 || findIndexOf === 1 || detail) {
      returnArr = [];
    } else {
      returnArr = [
        {
          icon: trash,
          function: () => deleteSection(elem.idSection),
          styleIcon: { height: '15px' },
        },
      ];
    }
    return returnArr;
  };

  const bodyTitle = elem => {
    return (
      <>
        <Row className='' key={elem.idSection}>
          <Col xs={8}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Título
              <span className={'text-danger'}>*</span>
            </p>
            <input
              key={elem.idSection}
              defaultValue={elem.title}
              readOnly={detail ? true : false}
              className={`register-inputs`}
              name='title'
              onChange={e => {
                onChangeInputsTitle('title', e.target.value, elem.idSection);
              }}
            />
          </Col>
          <Col xs={elem?.type === 'specific' ? 2 : 4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Tipo
              <span className={'text-danger'}>*</span>
            </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={elem.idSection + 'charge'}
              styles={customSelectNewDark}
              placeholder={'Seleccionar...'}
              value={optionsType.find(
                x => x.value === sections?.find(e => e?.idSection === elem?.idSection).type,
              )}
              onChange={e => {
                onChangeInputsTitle('type', e.value, elem.idSection);
              }}
              isSearchable={!detail}
              menuIsOpen={detail ? false : undefined}
              options={optionsType}
            />
          </Col>
          {elem?.type === 'specific' && (
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Dependencia
                <span className={'text-danger'}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={elem.idSection + 'section' + triggerSelects}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                value={dependenciesOptions?.find(
                  x =>
                    x.value === sections?.find(e => e?.idSection === elem?.idSection)?.idDependece,
                )}
                onChange={e => {
                  onChangeInputsTitle('idDependece', e.value, elem.idSection);
                  getPositionOptionsFunction(e.value, elem.idSection);
                }}
                isSearchable={!detail}
                menuIsOpen={detail ? false : undefined}
                options={dependenciesOptions}
              />
            </Col>
          )}
        </Row>
        <Row className=''>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Descripción
              <span className={'text-danger'}>*</span>
            </p>
            <textarea
              key={elem.idSection}
              defaultValue={elem.description}
              readOnly={detail ? true : false}
              placeholder={'Escribir...'}
              rows='6'
              cols='6'
              className={`${tableStyles.textareaNom}`}
              style={{ height: '2rem' }}
              name='description'
              onChange={e => {
                onChangeInputsTitle('description', e.target.value, elem.idSection);
              }}
            ></textarea>
          </Col>
        </Row>
      </>
    );
  };

  const bodyCard = elem => {
    return elem.type === '' && !detail ? (
      <Row className='d-flex justify-content-center py-4'>
        <span className={`text-center ${tableStyles.ordClearGrayText}`}>
          Seleccione el tipo de sección.
        </span>
      </Row>
    ) : elem.type === 'specific' &&
      (elem.idDependece === '' || elem.idDependece === undefined) &&
      !detail ? (
      <Row className='d-flex justify-content-center py-4'>
        <span className={`text-center ${tableStyles.ordClearGrayText}`}>
          Seleccione el campo Dependencia para poder crear las preguntas.
        </span>
      </Row>
    ) : (
      <div>
        {elem?.questions?.map(question => {
          let findIndexOf = sections
            ?.find(e => e?.idSection === elem?.idSection)
            ?.questions?.findIndex(e => e?.idAsk === question?.idAsk);
          let hasBorder = elem.questions.length > 1 ? '1px solid #CBCBD0' : '0px';
          let showAddButton = findIndexOf === elem.questions.length - 1;
          return elem.type === 'specific' &&
            (elem.idDependece === '' ||
              elem.idDependece === null ||
              elem.idDependece === undefined) ? (
            <Row className='d-flex justify-content-center py-4' key={question.idAsk}>
              <span className={`text-center ${tableStyles.ordClearGrayText}`}>
                Seleccione el campo Dependencia para poder crear las preguntas.
              </span>
            </Row>
          ) : (
            <div
              key={question.idAsk}
              style={{ paddingBottom: '20px', paddingTop: '10px', borderBottom: `${hasBorder}` }}
            >
              <Row className='' key={question.idAsk} style={{ position: '' }}>
                <Col xs={elem.type === 'specific' ? 8 : 11}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Pregunta
                    <span className={'text-danger'}>*</span>
                  </p>
                  <input
                    key={question.idAsk}
                    defaultValue={question.label}
                    readOnly={detail ? true : false}
                    className={`register-inputs`}
                    name='position'
                    onChange={e => {
                      onChangeBodyQuestions(
                        'label',
                        e.target.value,
                        elem.idSection,
                        question.idAsk,
                      );
                    }}
                  />
                </Col>
                {elem.type === 'specific' && (
                  <Col xs={3}>
                    <p className={`${tableStyles.crudModalLabel} `}>
                      Cargo
                      <span className={'text-danger'}>*</span>
                    </p>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      key={question.idAsk + triggerSelects}
                      styles={customSelectNewDarkNewNom}
                      placeholder={'Seleccione...'}
                      value={elem?.positionOptions?.find(
                        x =>
                          x.value ===
                          sections
                            .find(e => e?.idSection === elem?.idSection)
                            ?.questions?.find(e => e.idAsk === question?.idAsk)?.assessed
                            ?.idPosition,
                      )}
                      onChange={e => {
                        onChangeBodyQuestions('assessed', e, elem.idSection, question.idAsk);
                      }}
                      isSearchable={!detail}
                      menuIsOpen={detail ? false : undefined}
                      options={elem.positionOptions}
                      className='menu-outer-top'
                    />
                  </Col>
                )}
                {!detail && (
                  <Col xs={1} className='d-flex justify-content-center align-self-end'>
                    {showAddButton && (
                      <button
                        className=''
                        onClick={() => {
                          addQuestion(elem.idSection);
                        }}
                      >
                        <img alt='addIcon' src={addIcon} />
                      </button>
                    )}
                    {elem.questions.length > 1 && (
                      <button
                        className='pl-2'
                        onClick={() => {
                          deleteQuestion(elem.idSection, question.idAsk);
                        }}
                      >
                        <img alt='deleteIcon' src={closeIconBlue} />
                      </button>
                    )}
                  </Col>
                )}
              </Row>
            </div>
          );
        })}
      </div>
    );
  };

  // ----------------------------------------------

  const { load: surveyCreateLoader, trigger: sendSurveyFunction } = usePostMethod();
  const sendSurvey = (body, method, button) => {
    customSwaltAlert({
      icon: 'success',
      title: `${method === 'PUT' ? 'Actualizado exitosamente' : 'Creado exitosamente'}`,
      text: `Se ha ${method === 'PUT' ? 'actualizado' : 'creado'} la evaluación: ${dataDetail?.title}`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${
        button === '1'
          ? dataDetail.status === 'draft'
            ? 'guardará'
            : method === 'PUT'
              ? 'editará'
              : 'creará'
          : dataDetail.status === 'draft'
            ? 'editará el borrador de '
            : 'guardará el borrador de '
      } una evaluación `,
      confirmButtonText: 'Si, Continuar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        sendSurveyFunction({
          url: '/payroll/performance_evaluation',
          token: token,
          noAlert: true,
          method: method,
          body: body,
          doAfterSuccess: () => {
            customSwaltAlert({
              icon: 'success',
              title: `${method === 'PUT' ? 'Actualizado exitosamente' : 'Creado exitosamente'}`,
              text: `Se ha ${method === 'PUT' ? 'actualizado' : 'creado'} la evaluación: ${dataDetail?.title}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            handleBack();
          },
          doAfterException: err => {
            customSwaltAlert({
              icon: 'error',
              title: err.title,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
              text: err.message,
            });
          },
        });
      }
    });
  };

  const surveyValidations = (type, button) => {
    let sameStringValidations = true;
    let method;
    if (type === 'edit' || (dataDetail.status === 'draft' && type === 'draft')) {
      method = 'PUT';
    } else {
      method = 'POST';
    }
    let data = {
      title: dataDetail.title,
      eaccount: idAccount,
      createdBy: idUser,
      description: dataDetail.description,
      startDate: dataDetail.startDate,
      endDate: dataDetail.endDate,
    };
    let dataSections = [];
    let statusSurvey;
    if (type === 'draft') {
      statusSurvey = 'draft';
    } else {
      let startDate = dataDetail.startDate;
      let todayDate = today();
      if (todayDate === startDate) {
        statusSurvey = 'in_progress';
      } else {
        statusSurvey = 'to_start';
      }
    }

    sections.forEach(e => {
      let filterArr = sections.filter(i => i.title === e.title);
      let filterArrT = sections.filter(i => i.idSection !== e.idSection);
      if (filterArr.length > 1) {
        return (sameStringValidations = false);
      } else {
        filterArrT.forEach(x => {
          if (e.title === x.title) {
            return (sameStringValidations = false);
          } else {
            return (sameStringValidations = true);
          }
        });
      }
    });

    let validations = validateEmptyString([
      {
        field: dataDetail.description,
        message: "El campo 'Descripción de la evaluación', es obligatorio",
      },
      { field: dataDetail.title, message: "El campo 'Titulo evaluación', es obligatorio" },
      { field: dataDetail.endDate, message: "El campo 'Fecha fin', es obligatorio" },
      { field: dataDetail.startDate, message: "El campo 'Fecha inicio', es obligatorio" },
    ]);

    let sectionDataValidations = { valid: false };
    let sectionDataQuestions = { valid: false };
    let numberOfQuestionsValidation = false;

    sections?.every((section, ind) => {
      let questionsData = [];
      sectionDataValidations = validateEmptyString([
        {
          field: section.description,
          message: `El campo 'Descripción' en la sección ${ind + 1}, es obligatorio`,
        },
        {
          field: section.type,
          message: `El campo 'Tipo' en la sección ${ind + 1}, es obligatorio`,
        },
        {
          field: section.title,
          message: `El campo 'Titulo' en la sección ${ind + 1}, es obligatorio`,
        },
      ]);

      if (sectionDataValidations.valid) {
        numberOfQuestionsValidation =
          section?.questions?.length === 0
            ? customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Verifique las preguntas de las secciones. Las secciones deben de llevar como mínimo 1 pregunta ',
                showCancelButton: false,
              })
            : true;

        section?.questions?.every(question => {
          if (section?.type === 'specific') {
            sectionDataQuestions = validateEmptyString([
              {
                field: question.label,
                message: `El campo 'Pregunta' en la sección ${ind + 1}, debe estar asociado a cada una de las preguntas inmersas en dicha sección`,
              },
              {
                field: question?.assessed?.idPosition,
                message: `El campo 'Cargo' en la sección ${ind + 1}, debe estar asociado a cada una de las preguntas inmersas en dicha sección`,
              },
            ]);
          } else {
            sectionDataQuestions = validateEmptyString([
              {
                field: question.label,
                message: `El campo 'Pregunta' en la sección ${ind + 1}, debe estar asociado a cada una de las preguntas inmersas en dicha sección`,
              },
            ]);
          }
          if (!sectionDataQuestions.valid) {
            return false;
          }
          return true;
        });
      }

      if (!sectionDataValidations.valid || !sectionDataQuestions.valid) {
        return false;
      }
      if (edit) {
        // CLEANING QUESTIONS OF THE SECTION TO SEND THE INFO => ⬇️
        section?.questions?.forEach(question => {
          if (section.type === 'specific') {
            questionsData.push({
              label: question.label,
              assessed: {
                idAssessed: Number.isInteger(question?.assessed?.idAssessed)
                  ? question?.assessed?.idAssessed
                  : null,
                idPosition: question?.assessed?.idPosition,
                idLeader: question?.assessed?.idLeader,
              },
              idAsk: Number.isInteger(question.idAsk) ? question.idAsk : null,
            });
          } else {
            questionsData.push({
              label: question.label,
              idAsk: Number.isInteger(question.idAsk) ? question.idAsk : null,
            });
          }
        });
        // CLEANING SECTIONS DATA TO SEND THE INFO
        dataSections.push({
          title: section.title,
          description: section.description,
          idDependece: section.idDependece,
          type: section.type,
          idSection: Number.isInteger(section.idSection) ? section.idSection : null,
          questions: questionsData,
        });
      } else {
        // CLEANING QUESTIONS OF THE SECTION TO SEND THE INFO => ⬇️
        section?.questions?.forEach(question => {
          if (section.type === 'specific') {
            questionsData.push({
              label: question.label,
              assessed: {
                idPosition: question?.assessed?.idPosition,
                idLeader: question?.assessed?.idLeader,
              },
            });
          } else {
            questionsData.push({
              label: question.label,
            });
          }
        });
        // CLEANING SECTIONS DATA TO SEND THE INFO
        dataSections.push({
          title: section.title,
          description: section.description,
          idDependece: section.idDependece,
          type: section.type,
          questions: questionsData,
        });
      }
      return true;
    });

    let body;

    if (edit) {
      body = {
        ...data,
        status: statusSurvey,
        sections: dataSections,
        id: dataDetail.id,
      };
    } else {
      body = {
        ...data,
        status: statusSurvey,
        sections: dataSections,
      };
    }

    if (!sameStringValidations) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Las secciones no pueden tener el mismo título, cambia uno de ellos para continuar',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (
      validations.valid &&
      sectionDataValidations.valid &&
      numberOfQuestionsValidation &&
      sectionDataQuestions.valid &&
      sameStringValidations
    ) {
      sendSurvey(body, method, button);
    } else {
      // message("error", "Ha ocurrido un error","Intente nuevamente, si el error persiste contacte a soporte")
    }
  };

  //  const renderTooltipAdd = () => <Tooltip>Añadir sección</Tooltip>;
  const renderTooltipAdd = props => <Tooltip {...props}>Añadir sección</Tooltip>;

  return (
    <>
      {/* {(dependenciesOptionsLoader || positionOptionsLoader || loading ||surveyCreateLoader) && loader} */}
      {(!detail || !edit) && surveyCreateLoader && loader}
      <div className='my-4'>
        {sections.length > 0 &&
          sections?.map((elem, index) => {
            let findSection = sections?.find(e => e?.idSection === elem?.idSection);
            return (
              <div className='my-4' key={elem.idSection}>
                <CardAccordionComponent
                  key={elem.idSection}
                  title={`• Sección ${index + 1} de ${sections.length}`}
                  subtitle={` ${findSection?.questions?.length} pregunta(s)`}
                  buttons={buttonsCard(elem)}
                  bodyTitle={bodyTitle(elem)}
                  bodyCard={bodyCard(elem)}
                />
              </div>
            );
          })}
      </div>
      {!detail && (
        <Row className='d-flex justify-content-center my-4 text-center'>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipAdd}
          >
            <button
              onClick={() => {
                addSection();
              }}
            >
              <img alt='addIcon' src={addIcon} />
            </button>
          </OverlayTrigger>
        </Row>
      )}

      {!detail && (
        <div className={`customTabs__Footer mt-4 ml-1`}>
          {(dataDetail.status === 'draft' ? !!myPermission?.create : !!myPermission?.edit) && (
            <button
              onClick={() => {
                let action = edit ? 'edit' : 'create';
                surveyValidations(action, '1');
              }}
              className={tableStyles.btnPrimary}
              style={{ width: '150px' }}
            >
              {dataDetail.status === 'draft'
                ? 'Guardar'
                : edit && dataDetail.status !== 'draft'
                  ? 'Editar'
                  : 'Crear'}
            </button>
          )}
          {(dataDetail.status === 'draft' ? !!myPermission?.edit : !!myPermission?.edit) && (
            <button
              onClick={() => {
                surveyValidations('draft', '2');
              }}
              className={tableStyles.btnSecondary}
            >
              {dataDetail.status === 'draft' ? 'Editar borrador' : 'Guardar borrador'}
            </button>
          )}
        </div>
      )}
    </>
  );
};
export default Questions;
