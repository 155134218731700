//All actions for Login component
import { CUENTAS_GET, USERS_GET } from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { customSwaltAlert } from './../helpers/customSwaltAlert';
import { convertFilterToString } from '../helpers/convertToFilter';

export const accounts_get = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: CUENTAS_GET,
    payload: [],
    row_total: 0,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      //console.error(res)
      const { results, row_total } = res;
      dispatch({
        type: CUENTAS_GET,
        payload: results,
        row_total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: CUENTAS_GET,
        payload: [],
        row_total: 0,
        loading: false,
      });
      console.error(err);
    });
};

export const users_get = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: USERS_GET,
    payload: [],
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/generals/medicalUsers/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      //console.error(res)
      const { results } = res;
      dispatch({
        type: USERS_GET,
        payload: results,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: USERS_GET,
        payload: [],
        loading: false,
      });
      console.error(err);
    });
};

export const accounts_edit_active =
  (codigo, data, bussines_name, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;

    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `La cuenta ${bussines_name} se ${data.active ? 'habilitará' : 'deshabilitará'}`,
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch({
          type: CUENTAS_GET,
          loading: true,
        });

        fetch(`${URL_GATEWAY}${API_VERSION}/export/accounts/${codigo}/`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
          .then(response => response.json())
          .then(res => {
            dispatch({
              type: CUENTAS_GET,
              loading: false,
            });

            if (res.status === 200) {
              customSwaltAlert({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha ${data.active ? 'habilitado' : 'deshabilitado'} la cuenta: ${bussines_name}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            } else {
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: `${res.message}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          })
          .then(() => {
            executeThisAfter();
          })
          .catch(() => {
            dispatch({
              type: CUENTAS_GET,
              loading: false,
            });

            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'No se pudo cambiar el estado',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          });
      }
    });
  };

export const accounts_edit = (codigo, data, executeThisAfter) => (dispatch, getState) => {
  if (data.bussines_name.trim()) {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/${codigo}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.status === 200) {
          customSwaltAlert({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: `La cuenta: ${data.name} ha sido actualizada`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'intenta de nuevo',
            text: `${res.message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      })
      .then(() => {
        executeThisAfter();
      })
      .catch(err => {
        if (executeThisAfter) executeThisAfter();
        console.error(err);

        customSwaltAlert({
          icon: 'warning',
          title: 'intenta de nuevo',
          text: 'No se pudo cambiar el estado',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      });
  } else {
    customSwaltAlert({
      icon: 'warning',
      title: 'Validar datos',
      text: 'Razón Social no puede estar vació',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};

export const accounts_insert = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.status === 200) {
        customSwaltAlert({
          icon: 'success',
          title: 'Creada exitosamente',
          text: `Se ha creado la cuenta: ${data.bussines_name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    })
    .then(() => {
      executeThisAfter();
    })
    .catch(err => {
      if (executeThisAfter) executeThisAfter();
      console.error(err);

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No se pudo crear el registro',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};
