import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { RenderMedicalFormulasPdf } from '../../../../AsistanceModule/RenderMedicalFormulasPdf';
import FullScreenDialog from '../../../../OrderingModule/OrderingComponents/FullScreenDialog';

//styles
import styles from '../styles.module.scss';
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import CreatableSelect from 'react-select/creatable';
import { Form } from 'react-bootstrap';
import { ordCustomSelect } from './../../../../components/Layouts/react-select-custom';

import sendIcon from '../../../../assets/img/icons/icon_message.svg';

export const RenderPdf = ({
  isOpenPdf,
  onHidePdf,
  appId,
  setModalReminder,
  closeModalReminder,
  stateInputReminder,
  handleInputChangeReminder,
  handleKeyDownReminder,
  modalReminder,
  createReminder,
}) => {
  return (
    <FullScreenDialog onHide={onHidePdf} isOpen={isOpenPdf}>
      <div className='d-flex'>
        <div>
          <RenderMedicalFormulasPdf
            pdfParams={{
              url: '/medical/appointment/pdf/',
              objFilters: { appIds: String(appId) },
              doAfterSuccess: response => {
                setModalReminder(state => ({ ...state, fileBase64: response.results[0].base64 }));
              },
            }}
            title={'Recordatorio de cita'}
            goBack={true}
            goBackSmallDelete={true}
            backAction={closeModalReminder}
          />
        </div>
        <div
          className=' d-flex justify-content-center'
          style={{ height: '100vh', width: '100vh', backgroundColor: '#F6FEFF' }}
        >
          <div className='p-2 align-self-center'>
            <div>
              <b className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f32}`}>Enviar</b>
            </div>
            <div className={`${styles.app_container_calendar}`}>
              <Form.Group className='mb-3 text-start' controlId='to'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                  <span> &nbsp;Para</span>
                </Form.Label>

                <CreatableSelect
                  components={{ DropdownIndicator: null }}
                  inputValue={stateInputReminder.inputValue}
                  className={`${tableStyles.heightSelectApp}`}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={e => handleInputChangeReminder(e, 'onchange')}
                  onInputChange={e => handleInputChangeReminder(e, 'oninputchange')}
                  onKeyDown={handleKeyDownReminder}
                  placeholder='Escribe...'
                  styles={ordCustomSelect}
                  value={stateInputReminder.value}
                />
              </Form.Group>
              <Form.Group className='mb-3 text-start' controlId='subject'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                  <span> &nbsp;Asunto</span>
                </Form.Label>
                <Form.Control
                  className={`ord-roundInput `}
                  type='text'
                  placeholder=''
                  value={modalReminder.emailSubject}
                  onChange={e => {
                    setModalReminder({ ...modalReminder, emailSubject: e.target.value });
                  }}
                />
              </Form.Group>
              <div className='my-2' style={{ maxWidth: '350px' }}>
                <CKEditor
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setModalReminder({ ...modalReminder, emailBody: data });
                  }}
                  data={modalReminder.emailBody}
                />
              </div>
              <div className={`d-flex my-2 `}>
                <span className={`${tableStyles.appPdfReminderPin} px-2`}>
                  Recordatorio de cita PDF
                </span>
              </div>
              <div className=' d-flex justify-content-end align-items-end align-self-end my-2 '>
                <button onClick={createReminder} className={`${tableStyles.ordBtnPrimary} btn`}>
                  Enviar{' '}
                  <img alt='sendIcon' src={sendIcon} className={`${tableStyles.svgWhiteFilter}`} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FullScreenDialog>
  );
};
