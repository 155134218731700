//components
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useGetMethod, usePostMethod } from '../../Hooks';
import GenericTableNew from '../../components/Layouts/GenericTableNew';
import { convertMoneyFormat, customSwaltAlert, genericDownloadExcel } from '../../helpers';

//Icons
import avion from '../../assets/img/icons/avion.svg';
import Excel from '../../assets/img/icons/excel.svg';
import IconBack from '../../assets/img/icons/iconBack.svg';

//Styles
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function PreDownloadDetail() {
  const storage = useSelector(state => state);
  const history = useHistory();
  const status = useParams();
  const location = useLocation();
  const preDownload = new URLSearchParams(location.search).get('preDownload');

  const { trigger: getDetailPreDownload, results: preDownloadDetail } = useGetMethod();
  const { trigger: changeState } = usePostMethod();
  const { trigger: postDocExcel } = usePostMethod();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });

  const [totals, setTotals] = useState({
    grossValue: 0,
    withholdingTax: 0,
    rete_Ica: 0,
    collectedValue: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getDetailPreDownload({
          url: '/receivable/preDownload/getDetail/',
          objFilters: {
            eaccount: storage.loginReducer.currentAccount.id,
            preDownload,
            ...filters,
          },
          token: storage.loginReducer.Authorization,
        });

        if (!result.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: result.message,
            confirmButtonText: 'Aceptar',
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [
    filters,
    preDownload,
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getDetailPreDownload,
  ]);

  useEffect(() => {
    if (Array.isArray(preDownloadDetail?.results)) {
      const grossValue = preDownloadDetail.results
        .reduce((acc, x) => acc + parseFloat(x?.gross_value || 0), 0)
        .toString()
        .replace('.', ',');
      const withholdingTax = preDownloadDetail.results
        .reduce((acc, x) => acc + parseFloat(x?.withholding_tax || 0), 0)
        .toString()
        .replace('.', ',');
      const rete_Ica = preDownloadDetail.results
        .reduce((acc, x) => acc + parseFloat(x?.reteIca || 0), 0)
        .toString()
        .replace('.', ',');
      const collectedValue = preDownloadDetail.results
        .reduce((acc, x) => acc + parseFloat(x?.collected || 0), 0)
        .toString()
        .replace('.', ',');

      const newTotals = {
        grossValue,
        withholdingTax,
        rete_Ica,
        collectedValue,
      };
      setTotals(newTotals);
    }
  }, [preDownloadDetail]);

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/preDownload/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        preDownload: preDownload,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderButtons = () => {
    return (
      <>
        <div className='d-flex justify-content-end mb-3'>
          {status.id === 'Pendiente' && (
            <button
              className='btn btn-primary d-flex fw-bold justify-content-end mt-1 mb-3'
              onClick={() => changeStatus('send')}
            >
              <img className='mr-2 align-self-center' src={avion} alt='' /> Enviar a contabilidad
            </button>
          )}

          {status.id === 'Borrador' && (
            <div>
              <button
                className={`${tableStyles.btnSecondary} mr-3 mt-1 mb-3`}
                onClick={() => checkDelete()}
              >
                Eliminar borrador
              </button>
              <button
                className={`${tableStyles.btnPrimary} mt-1 mb-3`}
                onClick={() => changeStatus('save')}
              >
                Guardar
              </button>
            </div>
          )}
        </div>
        <div className='mb-4'></div>
      </>
    );
  };

  const checkDelete = () => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: preDownloadDetail.copyName
        ? `Se eliminará la pre-descarga: ${preDownloadDetail.copyName} `
        : 'Se eliminará la pre-descarga',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        changeStatus('delete');
      }
    });
  };

  const changeStatus = Action => {
    changeState({
      url: '/receivable/preDownload/save/',
      method: 'POST',
      body: {
        action: Action,
        preDownload,
        eaccount: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Enviado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        }).then(result => {
          if (result.isConfirmed) {
            if (Action === 'delete' || Action === 'save') {
              history.goBack();
            }
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderList = () => {
    let table = [];
    if (Array.isArray(preDownloadDetail?.results)) {
      table = preDownloadDetail?.results?.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row' style={{ height: '40px' }}>
            <td className='text-start text-nowrap px-1 mx-2'>{x?.identifer || '-'}</td>
            <td className='text-start text-nowrap px-4 mx-2'>{x?.Nit || '-'}</td>
            <td className='text-start text-nowrap px-4 mx-2'>{x?.client || '-'}</td>
            <td className='text-center text-nowrap px-4 mx-2'>{x?.invoice_sequence || '-'}</td>
            <td className='text-center text-nowrap px-4 mx-2'>
              {x?.filing_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') || '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>
              {convertMoneyFormat(x?.gross_value) || '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>
              {convertMoneyFormat(x?.withholding_tax) || '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>
              {convertMoneyFormat(x?.reteIca) || '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>
              {x?.iva ? `${Math.floor(x.iva)}%` : '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>{x?.adjustment || '-'}</td>
            <td className='text-end text-nowrap px-4 mx-2'>
              {convertMoneyFormat(x?.collected) || '-'}
            </td>
            <td className='text-end text-nowrap px-4 mx-2'>{x?.accounting_account || '-'}</td>
            <td className='text-center text-nowrap px-4 mx-2'>
              {x?.consignment_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') || '-'}
            </td>
          </tr>
        );
      });
    }

    return table;
  };

  const renderTable = () => {
    const headers = [
      <th key={1} className='text-start px-1 py-3 mx-2 text-nowrap'>
        Identificador
      </th>,
      <th key={2} className='text-start px-4 py-3 mx-2 text-nowrap'>
        NIT
      </th>,
      <th key={3} className='text-start px-4 py-3 mx-2 text-nowrap'>
        Empresa
      </th>,
      <th key={4} className='text-center px-4 py-3 mx-2 text-nowrap'>
        No.Factura
      </th>,
      <th key={5} className='text-center px-4 py-3 mx-2 text-nowrap'>
        Fecha radicación
      </th>,
      <th key={6} className='text-end px-4 py-3 mx-2 text-nowrap'>
        Valor Bruto
      </th>,
      <th key={7} className='text-end w-20 px-4 ps-3 py-3 mx-2 text-nowrap'>
        Retefuente
      </th>,
      <th key={8} className='text-end px-4 py-3 mx-2 text-nowrap'>
        ReteICA
      </th>,
      <th key={9} className='text-center px-4 py-3 mx-2 text-nowrap'>
        IVA
      </th>,
      <th key={10} className='text-end px-4 py-3 mx-2 text-nowrap'>
        Ajuste
      </th>,
      <th key={11} className='text-end w-20 px-4 ps-3 py-3 mx-2 text-nowrap'>
        Valor recaudado
      </th>,
      <th key={12} className='text-center px-4 py-3 mx-2 text-nowrap'>
        Cuenta contable
      </th>,
      <th key={13} className='text-center px-4 py-3 mx-2 text-nowrap'>
        Fecha consignación
      </th>,
    ];

    const footer = [
      <tr key={1} style={{ height: '40px' }}>
        <td />
        <td />
        <td />
        <td />
        <td className='text-secondary fw-bold text-uppercase'>TOTALES</td>
        <td className='text-secondary fw-bold text-uppercase px-4 mx-2 text-end'>
          {String(totals.grossValue).includes(',')
            ? convertMoneyFormat(totals.grossValue) || '-'
            : `${convertMoneyFormat(totals.grossValue)},00` || '-'}
        </td>
        <td className='text-secondary fw-bold text-uppercase px-4 mx-2 text-end'>
          {String(totals.withholdingTax).includes(',')
            ? convertMoneyFormat(totals.withholdingTax) || '-'
            : `${convertMoneyFormat(totals.withholdingTax)},00` || '-'}
        </td>
        <td className='text-secondary fw-bold text-uppercase px-4 mx-2 text-end'>
          {String(totals.rete_Ica).includes(',')
            ? convertMoneyFormat(totals.rete_Ica) || '-'
            : `${convertMoneyFormat(totals.rete_Ica)},00` || '-'}
        </td>
        <td />
        <td />
        <td className='text-secondary fw-bold text-uppercase px-4 mx-2 text-end'>
          {String(totals.collectedValue).includes(',')
            ? convertMoneyFormat(totals.collectedValue) || '-'
            : `${convertMoneyFormat(totals.collectedValue)},00` || '-'}
        </td>
      </tr>,
    ];
    return (
      <div className='table-responsive mb-1 overflow-auto'>
        <GenericTableNew headers={headers} footer={footer} dark={true} shadow>
          {renderList()}
        </GenericTableNew>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className='container'>
          <div className='d-flex align-items-center my-5'>
            <img onClick={() => history.goBack()} src={IconBack} className='pointer me-3' alt='' />
            <h1 className='m-0'>Detalle pre - descarga</h1>
          </div>

          <div className='d-flex justify-content-end'>
            {(status.id === 'Pendiente' || status.id === 'Enviado') && (
              <img
                alt='Excel'
                className='pointer'
                width={25}
                src={Excel}
                onClick={() => downloadExcelDoc()}
              />
            )}
          </div>

          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(preDownloadDetail?.row_count / filters.perpage)
                ? Math.ceil(preDownloadDetail?.row_count / filters.perpage)
                : '1'}{' '}
              ({preDownloadDetail?.row_count} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={preDownloadDetail?.row_count}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

          {renderButtons()}
        </div>
      </>
    );
  };

  return render();
}
