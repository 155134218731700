import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import * as React from 'react';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />;
});
export default function FullScreenDialog(props) {
  let { isOpen, onHide } = props;
  const handleClose = () => {
    onHide(false);
  };
  return (
    <div>
      <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
        {props.children || 'Put your content in'}
      </Dialog>
    </div>
  );
}
