import { Box } from '@mui/material';
import { useTheme } from 'styled-components';

export const ProgressBar = ({ className, bg1, bg2 }) => {
  const { colors } = useTheme();

  const styleBar = {
    height: '17px',
    width: '17px',
    borderRadius: '100%',
    background: colors.ordAquaMarine,
  };

  const styleLinear = {
    height: '3px',
    background: colors.darkGray2,
  };

  return (
    <div className={className}>
      <Box
        sx={{ color: colors.ordAquaMarine }}
        style={{
          display: 'grid',
          gridTemplateColumns: '58% 1fr',
          fontWeight: 'bold',
          marginBottom: '5px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Información básica</div>
          <div>Información adicional</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div>Recaudo</div>
        </div>
      </Box>

      <div style={{ display: 'grid', gridTemplateColumns: '17px 1fr 1fr', fontWeight: 'bold' }}>
        <div style={styleBar}></div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 17px', alignItems: 'center' }}>
          <div
            style={styleLinear}
            className={`${bg1 ? 'ordAquaMarine' : ''} ${bg2 ? 'ordAquaMarine' : ''}`}
          ></div>
          <div style={styleBar}></div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 17px', alignItems: 'center' }}>
          <div style={styleLinear} className={`${bg2 ? 'ordAquaMarine' : ''}`}></div>
          <div style={styleBar}></div>
        </div>
      </div>
    </div>
  );
};
