import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import Dropzone from 'react-dropzone';

import grayShoppingCar from '../../assets/img/icons/grayShoppingCar.svg';
import Search from '../../assets/img/icons/lupa.svg';
import excelClearBlue from '../../assets/img/icons/excelClearBlue.svg';
import Adjuntar from '../../assets/img/icons/Adjuntar.svg';
import Authorizate from '../../assets/img/icons/Authorizate.svg';
import atrasIcon from '../../assets/img/icons/atras-icon.svg';
import miniShearchIcon from '../../assets/img/icons/miniShearchIcon.svg';

import {
  message,
  formatToRcSelect,
  isEmptyOrUndefined,
  convertMoneyFormat,
  convertDateToLatinFormat,
  generateId,
} from '../../helpers/helpers';
import { storage_get } from '../../actions/storageActions';
import { getWarehouseEntries, getWEProblems } from '../../actions/warehouseActions';
import { getProviders } from '../../actions/providersActions';
import { getwhEntryDetails, saveWareHouseMovement } from '../../actions/inventoryActions';
import { MAX_SIZE_FILE } from '../../helpers/constants';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';

import ModalNew from '../Layouts/ModalNew';
import GenericTableNew from '../Layouts/GenericTableNew';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { SkeletonTemplate } from '../Layouts/SkeletonTemplate';

import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import Inventory from './Inventory.module.scss';

const MULTIPLE_REGEX_VALID_FILE = /^.+\.(([pP][dD][fF]))|(xlsx|xls|csv)|(doc|docx)$/;
const type_file =
  'csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf';

const classes = {
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 300,
  },
  text: {
    color: '#58595B',
  },
};
const localStyles = {
  blueInputText: {
    color: '#005dbf',
  },
  footerStyles: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '310%',
  },
  noBorder: { border: 'none', padding: '8px' },
};

function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
}

export const EntryByPurchase = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();

  const arrProviders = store.providerReducer.providers;
  const token = store.loginReducer.Authorization;
  const arrWhEntries = store.entryWarehouseReducer.warehouseEntries;
  const arrWareHouseDetails = store.inventoryReducer?.wareHouseDetails;
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;

  const [selectedDate, setSelectedDate] = useState('');
  const [entryNumber, setEntryNumber] = useState('');
  const [titleScndModal, setTitleScndModal] = useState('');
  const [currentLot, setCurrentLot] = useState([]);
  const [justification, setJustification] = useState('');
  const [disableInput, setDisableInput] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
  });
  const [billNumber, setBillNumber] = useState('');
  const [fillpTable, setFillpTable] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [filters, setFilters] = useState({
    perpage: 10,
    page: 1,
    search: '',
    we_status: 'authorized',
    we_donation: 0,
    eaccount: idEnterprise,
  });
  const [info, setInfo] = useState({
    file64: '',
    fileName: '',
    type_doc: '',
  });

  useEffect(() => {
    dispatch(
      getProviders({
        id_account: 12,
        slim: 1,
      }),
    );
    dispatch(storage_get());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWarehouseEntries(filters));
  }, [dispatch, filters, filters.page]);

  let ext = info.type_doc.split('/');
  let fileName = ext[1];
  let finalName = `${info.fileName}.${fileName}`;

  const provFormatted = formatToRcSelect(arrProviders, 'id', 'name');

  const sendToApprove = async () => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Revise la información',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        if (info.file64 === '' && isEmptyOrUndefined(arrWareHouseDetails?.we_billDocument)) {
          return message(
            'warning',
            'Intenta de nuevo',
            'Por favor cargue un documento de factura',
            undefined,
            true,
          );
        }
        if (billNumber === '' && isEmptyOrUndefined(arrWareHouseDetails?.we_billNumber)) {
          return message(
            'warning',
            'Intenta de nuevo',
            'Por favor digite el número de la factura',
            undefined,
            true,
          );
        }
        const send = async () => {
          try {
            setLoading(true);
            let result = await saveWareHouseMovement(
              idEnterprise,
              arrWareHouseDetails.justification,
              arrWareHouseDetails.observations,
              idAccount,
              'purchaseEntry',
              arrWareHouseDetails.warehouse_id,
              null,
              arrWareHouseDetails.id,
              null,
              info.file64,
              isEmptyOrUndefined(billNumber) ? arrWareHouseDetails?.we_billNumber : billNumber,
              finalName,
              arrWareHouseDetails.articles,
              token,
            );

            if (result?.success) {
              handleBack();
              setLoading(false);
              return message('success', 'Movimiento guardado', result?.results, undefined, true);
            } else {
              setLoading(false);
              return message(
                'warning',
                'Intenta de nuevo',
                'Ha ocurrido un error',
                undefined,
                true,
              );
            }
          } catch (error) {
            setLoading(false);
            return message('warning', 'Intenta de nuevo', error.message, undefined, true);
          }
        };

        send();
      }
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setLoading(true);
    const filters = {
      we_number: entryNumber,
      perpage: 10,
      eaccount: idEnterprise,
      page: 1,
      entry_date: selectedDate,
      we_provider_id: selectedProvider,
      we_status: 'authorized',
      we_donation: 0,
    };
    dispatch(getWarehouseEntries(filters, () => setLoading(false)));
  };
  const renderElement = elem => {
    return (
      <tr key={elem.code}>
        <td className='col-md-1 text-center'> {elem.we_number}</td>
        <td className='col-md-1 text-center'>{convertDateToLatinFormat(elem?.entry_date)}</td>
        <td className='col-md-3 text-start'>
          {!isEmptyOrUndefined(elem?.provider_name) ? elem?.provider_name : '-'}
        </td>
        <td
          className='col-md-2 text-center cursorPointer'
          onClick={() => handleClickFirstModal(elem)}
          value={elem.we_number}
        >
          <div className={`d-flex`} style={{ justifyContent: 'flex-end', marginRight: '5px' }}>
            <img src={miniShearchIcon} alt='Search icon' />
          </div>
        </td>
      </tr>
    );
  };
  const secondRenderElement = elem => {
    return (
      <tr key={elem?.id_article}>
        <td className='col-md-2 text-center'>{elem?.id_article}</td>
        <td className='col-md-4 text-start'>{elem?.description}</td>

        <td className='col-md-1 text-center cursorPointer'>
          <u onClick={() => handleClickSecondModal(elem?.lots)}>
            <b className={tableStyles.darkBlueText}>{1}</b>
          </u>
        </td>
        <td className='col-md-2 text-center'>{elem?.qty_ordened}</td>
        <td className='col-md-2 text-center'>{elem?.qty_received}</td>
      </tr>
    );
  };
  const thirdRenderElement = elem => {
    return (
      <tr key={elem.code}>
        <td className='col-md-2 text-center'>
          {convertDateToLatinFormat(elem.lot_date_expiration)}
        </td>
        <td className='col-md-4 text-center'>{elem.lot_name}</td>
        <td className='col-md-2 text-center'>{elem.lot_qty_received}</td>
      </tr>
    );
  };
  const pTableRenderElem = (elem, i) => {
    return (
      <tr key={elem.code}>
        <td className='col-md-1 text-center'>{i + 1}</td>
        <td className='col-md-1 text-center'>{elem.id_article}</td>
        <td className='col-md-2 text-start'>{elem.description}</td>
        <td className='col-md-1 text-center cursorPointer'>
          <u onClick={() => handleClickSecondModal(elem?.lots)}>
            <b className={tableStyles.darkBlueText}>{elem.lots.length}</b>
          </u>
        </td>
        <td className='col-md-2 text-center'>{elem?.qty_received}</td>
        <td className='col-md-1 text-center'>{convertMoneyFormat(elem.unit_price)}</td>
        <td className='col-md-2 text-center'>
          {elem.discount}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {convertMoneyFormat(elem.discount_value)}
        </td>
        <td className='col-md-2 text-center'>
          {elem.iva}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {convertMoneyFormat(elem.iva_value)}
        </td>
        <td className='col-md-2 text-center'>{convertMoneyFormat(elem.total)}</td>
      </tr>
    );
  };
  const listElem = () => {
    let elemMap;
    if (!isEmptyOrUndefined(arrWhEntries)) {
      const elem2 = arrWhEntries;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const secondListElem = () => {
    let elemMap;

    if (arrWareHouseDetails !== undefined) {
      const elem2 = arrWareHouseDetails;
      if (elem2.articles) {
        elemMap = elem2.articles.map(elem => {
          return secondRenderElement(elem);
        });
      }
    }
    return elemMap;
  };
  const thirdListElem = () => {
    let elemMap;
    if (currentLot !== undefined) {
      const elem2 = currentLot;
      elemMap = elem2.map(elem => {
        return thirdRenderElement(elem);
      });
    }
    return elemMap;
  };
  const pTableListElem = () => {
    let elemMap;
    if (arrWareHouseDetails !== undefined) {
      const elem2 = arrWareHouseDetails;
      if (elem2?.articles?.length > 0) {
        elemMap = elem2?.articles?.map((elem, i) => {
          return pTableRenderElem(elem, i);
        });
      }
    }
    return elemMap;
  };

  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: '10px' }} className='px-2' key={generateId()}>
      No. Entrada
    </th>,
    <th key={generateId()}>Fecha</th>,
    <th key={generateId()}>Proveedor</th>,
    <th style={{ borderTopRightRadius: '10px' }} key={generateId()}>
      &nbsp;
    </th>,
  ];
  const renderHeadersSecond = [
    <th style={{ borderTopLeftRadius: '10px' }} key={generateId()}>
      Código
    </th>,
    <th className='text-start' key={generateId()}>
      Descripción de Artículo
    </th>,
    <th key={generateId()}>Lote</th>,
    <th key={generateId()}>Cantidad ordenada</th>,
    <th style={{ borderTopRightRadius: '10px' }} key={generateId()}>
      Cantidad Recibida
    </th>,
  ];
  const renderHeadersThird = [
    <th style={{ borderTopLeftRadius: '10px' }} key={generateId()}>
      Vencimiento
    </th>,
    <th key={generateId()}>Lote</th>,
    <th style={{ borderTopRightRadius: '10px' }} key={generateId()}>
      Cantidad
    </th>,
  ];

  const renderHeadersPTable = [
    <th className='pb-3' style={{ borderTopLeftRadius: '10px' }} key={generateId()}>
      Item
    </th>,
    <th className='pb-3' key={generateId()}>
      Código
    </th>,
    <th className='pb-3' key={generateId()}>
      Descripción de articulo
    </th>,
    <th className='pb-3' key={generateId()}>
      Lote
    </th>,
    <th className='pb-3' key={generateId()}>
      Cant.
    </th>,
    <th className='pb-3' key={generateId()}>
      Valor Unidad
    </th>,
    <th key={generateId()}>
      Descuento <br></br> % &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $
    </th>,
    <th key={generateId()}>
      IVA <br></br> % &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; $
    </th>,
    <th className='pb-3' key={generateId()}>
      Total
    </th>,

    <th style={{ borderTopRightRadius: '10px' }} key={generateId()}></th>,
  ];

  const handleClickFirstModal = elem => {
    setLoading(true);
    dispatch(getWEProblems());
    dispatch(getwhEntryDetails(elem.id, () => setLoading(false)));
    setTitleScndModal(elem.we_number.toString());
    secondListElem();

    setmodalArtControl({
      ...modalArtControl,
      show: false,
      showSecond: true,
    });
  };
  const handleClickSecondModal = elem => {
    setCurrentLot(elem);
    setmodalArtControl({
      ...modalArtControl,
      showThird: true,
    });
  };

  const handleClickEntrybyStorage = () => {
    setLoading(true);
    dispatch(getWarehouseEntries(filters, () => setLoading(false)));
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
  };
  const handleBring = () => {
    setFillpTable(true);
    setDisableInput(false);
    setmodalArtControl({
      ...modalArtControl,
      showSecond: false,
      show: false,
    });
  };
  const handleBack = () => {
    history.push('/compras/registrarMovimiento');
  };
  const download = () => {
    window.location.href = arrWareHouseDetails?.we_billDocument;
  };

  const validateFile = async acceptedFile => {
    let size = acceptedFile[0].size;
    if (size > MAX_SIZE_FILE) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        footer: 'El archivo es demasiado pesado, el tamaño máximo permitido es de 5MB',
        showCancelButton: false,
      });
    }
    if (new RegExp(MULTIPLE_REGEX_VALID_FILE).test(acceptedFile.type)) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        footer:
          'El formato del archivo debe ser PDF, WORD, EXCEL, el tamaño máximo permitido es de 5MB',
        showCancelButton: false,
      });
    }
  };
  const onChangeDoc = async e => {
    var f = e.target.files[0];
    var name_doc = f.name.split('.')[0];
    var type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    setInfo({
      ...info,
      file64: file64,
      fileName: name_doc,
      type_doc: type_doc,
    });
  };
  return (
    <>
      <ModalNew
        size='700'
        title='Entradas de almacén'
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
      >
        <Row className='d-flex'>
          <Col xs={3} className='align-self-end'>
            <form onSubmit={e => handleSearch(e)}>
              <p className={tableStyles.crudModalLabel}>No. Entrada</p>
              <input
                onChange={e => setEntryNumber(e.target.value)}
                className='register-inputs text-secondary'
                name='requisition_date'
                type='text'
                placeholder='Escribir...'
              />
            </form>
          </Col>

          <Col xs={4}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              onChange={e => setSelectedDate(e.target.value)}
              className='register-inputs text-secondary'
              name='id_consumption_center'
              type='date'
            />
          </Col>

          <Col xs={4} className='align-self-end'>
            <div>
              <form onSubmit={e => handleSearch(e)}>
                <p className={tableStyles.crudModalLabel}>Proveedor</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary'
                  defaultValue={selectedProvider}
                  onChange={e => setSelectedProvider(e.value)}
                  options={provFormatted}
                  styles={customSelectNewDark}
                  placeholder='Seleccionar...'
                />
              </form>
            </div>
          </Col>
          <Col
            onClick={e => handleSearch(e)}
            xs={1}
            className={`pt-3 cursorPointer align-self-end`}
            style={{ 'align-self': 'center' }}
          >
            <div>
              <img src={Search} alt='Search icon' height={24} />
            </div>
          </Col>
        </Row>

        <GenericTableNew headers={renderHeadersFirst}>{listElem()}</GenericTableNew>

        <div className={paginationStyles.wrapper} style={{ flexDirection: 'row-reverse' }}>
          <div>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.entryWarehouseReducer.row_total} // Esta jugada
              pageRangeDisplayed={2}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
          <div>
            <p className={paginationStyles.paginationText}>
              Pag. {store.entryWarehouseReducer.warehouseEntries ? filters.page : ''}
              {' de '}
              {Math.ceil(store.entryWarehouseReducer.row_total)
                ? Math.ceil(store.entryWarehouseReducer.row_total / filters.perpage)
                : ''}{' '}
              ({store.entryWarehouseReducer?.row_total} encontrados)
            </p>
          </div>
        </div>
      </ModalNew>
      <ModalNew
        size='940'
        title={`Entrada No. ${titleScndModal}`}
        show={modalArtControl.showSecond}
        btnYesEvent={() => handleBring()}
        btnYesName={'Traer'}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showSecond: false,
            show: true,
          })
        }
      >
        <Row className='d-flex'>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Orden de compra</p>
            <input
              style={localStyles.blueInputText}
              className='register-inputs'
              type='text'
              readOnly={true}
              value={arrWareHouseDetails?.purchaseOrder_number}
            />
          </Col>

          <Col xs={3}>
            <div>
              <p className={tableStyles.crudModalLabel}>Almacén</p>
              <input
                style={localStyles.blueInputText}
                className='register-inputs'
                type='text'
                readOnly={true}
                value={arrWareHouseDetails?.warehouse_description}
              />
            </div>
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>No. de Factura o remisión</p>
            <input
              style={localStyles.blueInputText}
              readOnly={true}
              className='register-inputs'
              name='requisition_date'
              type='number'
              value={arrWareHouseDetails?.we_billNumber}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Fecha</p>
            <input
              style={localStyles.blueInputText}
              className='register-inputs'
              type='date'
              value={arrWareHouseDetails?.entry_date}
            />
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersSecond}>
          {secondListElem()}
        </GenericTableNew>

        <Col xs={12}>
          <p className={tableStyles.crudModalLabel}>Observaciónes</p>
          <textarea
            id='txtArea'
            rows='10'
            cols='10'
            style={{ height: '3rem' }}
            className={`register-inputs`}
            readOnly={true}
            disabled={true}
            value={arrWareHouseDetails?.observations}
          ></textarea>
        </Col>
        <Row className={`${tableStyles.clearBlueBg}`}>
          <Row>
            <p className={`${tableStyles.fluorBlue} ml-2 pt-2`}>
              <img className={'ml-3'} src={Authorizate} alt='' />
              &nbsp; Entrada autorizada
            </p>
          </Row>
          <Col xs={4}>
            <p className={tableStyles.crudModalLabel}>¿Cúal era el problema?</p>

            <input
              className='register-inputs text-secondary'
              type='text'
              readOnly={true}
              value={
                arrWareHouseDetails?.we_problem !== null ? arrWareHouseDetails?.we_problem : '-'
              }
            />
          </Col>
          <Col xs={8}>
            <p className={tableStyles.crudModalLabel}>Información adicional</p>
            <input
              className='register-inputs text-secondary'
              type='text'
              readOnly={true}
              value={
                arrWareHouseDetails?.we_additional_info !== ''
                  ? arrWareHouseDetails?.we_additional_info
                  : '-'
              }
            />
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        size='200'
        title='Lotes'
        show={modalArtControl.showThird}
        hideCancelButton={true}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            showThird: false,
          })
        }
      >
        <GenericTableNew dark={true} headers={renderHeadersThird}>
          {thirdListElem()}
        </GenericTableNew>
      </ModalNew>

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={atrasIcon}
                className={`mr-2 mb-2 cursorPointer`}
                alt='a'
              ></img>
              Entrada por compra
              <img width='3.5%' src={grayShoppingCar} className={`ml-3`} alt='w'></img>
            </h1>
          </Col>
        </Row>
        <Row className=' d-flex'>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Tipo de movimiento</p>
            <input
              className='register-inputs'
              name='requisition_date'
              type='text'
              style={localStyles.blueInputText}
              disabled={disableInput}
              value={'Entrada por compra'}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Entrada por almacén</p>
            <input
              placeholder={'Buscar...'}
              onClick={() => handleClickEntrybyStorage()}
              className={` ${tableStyles.articleSearch} register-inputs  cursorPointer text-secondary`}
              name='requisition_date'
              type='text'
              value={disableInput ? '' : arrWareHouseDetails?.we_number}
              readOnly={true}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>
              {disableInput ? <>&nbsp;</> : 'Orden de compra'}
            </p>
            <input
              disabled={disableInput}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='text'
              value={disableInput ? '' : arrWareHouseDetails?.purchaseOrder_number}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>
              {disableInput ? <>&nbsp;</> : 'Fecha'}
            </p>
            <input
              disabled={disableInput}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='text'
              value={disableInput ? '' : convertDateToLatinFormat(arrWareHouseDetails?.entry_date)}
            />
          </Col>
          <Col xs={3}>
            {disableInput ? <>&nbsp;</> : <p className={tableStyles.crudModalLabel}>Proveedor</p>}
            <input
              readOnly={true}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='text'
              value={disableInput ? '' : arrWareHouseDetails?.provider_name}
            />
          </Col>
          <Col xs={3}>
            {disableInput ? <>&nbsp;</> : <p className={tableStyles.crudModalLabel}>NIT</p>}
            <input
              readOnly={true}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='text'
              value={disableInput ? '' : arrWareHouseDetails?.nit}
            />
          </Col>
          <Col xs={3}>
            {disableInput ? <>&nbsp;</> : <p className={tableStyles.crudModalLabel}>Almacén</p>}
            <input
              disabled={disableInput}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='text'
              value={disableInput ? '' : arrWareHouseDetails?.warehouse_description}
            />
          </Col>
          <Col xs={3}>
            {disableInput ? <>&nbsp;</> : <p className={tableStyles.crudModalLabel}>No. Factura</p>}
            <input
              onChange={e => setBillNumber(e.target.value)}
              disabled={disableInput}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='number'
              value={disableInput ? '' : arrWareHouseDetails?.we_billNumber}
            />
          </Col>

          <Col xs={12}>
            {fillpTable ? (
              <>
                <p className={tableStyles.crudModalLabel}>Factura</p>
                <Col xs={12} className={`d-flex mt-3`}>
                  <div className={` ${Inventory.buttonCreatCount}  d-flex `}>
                    <img width='25px' src={excelClearBlue} alt='add' />
                    <Dropzone
                      onDrop={acceptedFile => validateFile(acceptedFile)}
                      accept={type_file}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className='d-flex'>
                          <input
                            {...getInputProps()}
                            type='file'
                            disabled={
                              arrWareHouseDetails.we_billDocument === null
                                ? false
                                : arrWareHouseDetails.we_billDocument !== null && true
                            }
                            name='documents'
                            onChange={onChangeDoc}
                            accept={type_file}
                            multiple={false}
                          />
                          {info.fileName ? (
                            <p
                              style={{ margin: '0' }}
                              className={`${IndividualStyles.crudModalLabelGris} ${IndividualStyles.ellipsisNew} hoverPointer`}
                            >
                              {info.fileName}{' '}
                            </p>
                          ) : (
                            <p className={`${IndividualStyles.crudModalLabelGris} hoverPointer`}>
                              {arrWareHouseDetails?.we_billDocument !== null &&
                                arrWareHouseDetails?.we_billNumber && (
                                  <p>{arrWareHouseDetails?.we_billNumber}</p>
                                )}
                            </p>
                          )}
                          {isEmptyOrUndefined(arrWareHouseDetails?.we_billDocument) ? (
                            <img
                              className='cursorPointer'
                              src={Adjuntar}
                              alt='añadir'
                              style={{ marginLeft: 'auto', width: '18px' }}
                            />
                          ) : (
                            <img
                              className='cursorPointer'
                              onClick={() => download()}
                              width='25px'
                              src={Adjuntar}
                              alt='add'
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Col>

                <GenericTableNew dark={true} headers={renderHeadersPTable}>
                  {pTableListElem()}
                </GenericTableNew>

                <Row className={`w-100 m-0`}>
                  <Col xs={9} className={`${tableStyles.clearBlueBg} px-3`}>
                    <p className={`${tableStyles.crudModalLabel} pt-2`}>Observaciones</p>
                    <textarea
                      value={justification}
                      onChange={e => setJustification(e.target.value)}
                      style={{ height: '3rem' }}
                      rows='10'
                      cols='10'
                      className={`register-inputs text-secondary`}
                    ></textarea>
                  </Col>

                  <Col xs={3} className={``} style={{ paddingRight: 0 }}>
                    <Paper className={classes.root}>
                      <TableContainer style={classes.container}>
                        <Table stickyHeader aria-label='sticky table'>
                          <TableRow className={Inventory.stickyValues1}>
                            <TableCell
                              align='left'
                              colSpan={0}
                              style={localStyles.noBorder}
                              className={`${Inventory.stickyValues4} text-secondary`}
                            >
                              Subtotal
                            </TableCell>
                            <TableCell
                              style={localStyles.noBorder}
                              align='center'
                              className={`${Inventory.stickyValues4} text-secondary`}
                            >
                              {convertMoneyFormat(arrWareHouseDetails?.subtotal)}
                            </TableCell>
                          </TableRow>
                          <TableRow className={Inventory.stickyValues3}>
                            <TableCell
                              style={localStyles.noBorder}
                              align='left'
                              className={'text-secondary'}
                            >
                              IVA
                            </TableCell>
                            <TableCell
                              className={'text-secondary'}
                              style={localStyles.noBorder}
                              align='center'
                            >
                              {convertMoneyFormat(arrWareHouseDetails?.iva)}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            style={localStyles.noBorder}
                            className={`${Inventory.stickyValues4} text-secondary`}
                          >
                            <TableCell
                              style={localStyles.noBorder}
                              align='left'
                              colSpan={0}
                              className={'text-secondary'}
                            >
                              Total
                            </TableCell>
                            <TableCell
                              style={localStyles.noBorder}
                              align='center'
                              className={'text-secondary'}
                            >
                              {convertMoneyFormat(arrWareHouseDetails?.total)}
                            </TableCell>
                          </TableRow>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </Col>
                </Row>
                <div className={`customTabs__Footer mt-5`}>
                  <Button onClick={() => sendToApprove()} className={tableStyles.btnPrimary}>
                    Guardar
                  </Button>
                  <Button onClick={() => handleBack()} className={tableStyles.btnSecondary}>
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <SkeletonTemplate></SkeletonTemplate>
                <div className={`customTabs__Footer mt-5`}>
                  <Button className={tableStyles.btnPrimary} disabled={true}>
                    Guardar
                  </Button>
                  <Button onClick={() => handleBack()} className={tableStyles.btnSecondary}>
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
