import { useEffect, useState } from 'react';
import { Col, Form, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import alert from '../assets/img/icons/alertBlue.svg';
import deleteIcon from '../assets/img/icons/close.svg';
import CreatableSelect from 'react-select/creatable';
import { ordCustomSelect } from '../components/Layouts/react-select-custom';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { formatToRcSelect, loader, message } from '../helpers/helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ParameterCommets = () => {
  const location = useLocation();
  let obvData = location.state && location.state.observationData;
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  let history = useHistory();
  let srvObs = {
    inputValue: '',
    value: [],
  };

  useEffect(() => {
    if (Array.isArray(obvData?.srvObs)) {
      obvData?.srvObs.forEach(con => {
        srvObs.value.push({
          value: con.description,
          label: con.description,
        });
      });
      setData({ ...data, srvObs: srvObs });
    }

    if (Array.isArray(obvData?.contractObs)) {
      obvData.contractObs.forEach(contract => {
        contract.comments = [];
        let newJson = {
          inputValue: '',
          value: [],
        };
        if (Array.isArray(contract?.contractObs)) {
          contract?.contractObs?.forEach(con => {
            newJson.value.push({
              value: con.sroDescription,
              label: con.sroDescription,
              contractSrvId: con.contractSrvId,
            });
            contract.comments.push(con.sroDescription);
          });
          contract.contractObs = newJson;
        }
        // ---------------ANCHOR STATUS SELECT-------------------------------
        data.statusSelect.push(true);
        data?.options?.push([{}]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [data, setData] = useState({
    srvObs: obvData?.srvObs,
    commentsByContracts: obvData?.contractObs,
    options: [],
    statusSelect: [],
  });

  const renderTooltipAlert = () => (
    <Tooltip> Presiona enter para separar cada observación </Tooltip>
  );

  //   -------------- SECTION SELECT GENERALS 🍕   ----------

  const [stateInput, setStateInput] = useState({
    inputValue: '',
    value: [],
  });
  const createOption = label => ({
    label,
    value: label,
  });
  const handleInputChange = inputValue => {
    if (typeof inputValue == 'string') {
      setStateInput({ ...stateInput, inputValue: inputValue });
      data.srvObs.inputValue = inputValue;
      let tempArray = [];
      stateInput.value.forEach(elem => {
        tempArray.push(elem.label);
      });
    } else if (Array.isArray(inputValue)) {
      setStateInput({ ...stateInput, value: inputValue });
      data.srvObs.value = inputValue;
    }
  };

  const onTab = event => {
    const { value } = data.srvObs;
    let tempArray = value;
    tempArray.push(createOption(event.target.value));
    setStateInput({ inputValue: '', value: tempArray });
    data.srvObs.value = tempArray;
    data.srvObs.inputValue = '';
  };
  const handleKeyDown = event => {
    const { inputValue } = data.srvObs;

    if (inputValue && ['Enter', 'Tab'].includes(event.key)) {
      onTab(event);
    }
  };
  //   -------------- !SECTION SELECT 🍕   ----------

  // ------------------ SECTION SELECTS ROW ----------------
  const {
    results: companyOptionsResults,
    load: companyOptionsLoader,
    trigger: getCompanyOptions,
  } = useGetMethod();
  const { load: contractOptionsLoader, trigger: getTypeOptions } = useGetMethod();
  const { load: createObservationLoader, trigger: createObservationMethod } = usePostMethod();
  const companyOptions = formatToRcSelect(companyOptionsResults?.results, 'id', 'name', '', '', '');

  useEffect(() => {
    getCompanyOptions({
      url: '/medical/client/',
      objFilters: { status: 'enabled', eaccount: idAccount },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.statusSelect]);

  const getContractsFunction = (idCompany, index) => {
    data.statusSelect[index] = false;
    getTypeOptions({
      url: '/medical/client/contract/',
      objFilters: {
        status: 'enabled',
        client: idCompany,
        srvId: obvData.srvId,
        eaccount: idAccount,
      },
      token: token,
      doAfterSuccess: res => {
        if (data.options.length > 0) {
          data.options[index] = formatToRcSelect(
            res?.results,
            'id',
            'name',
            'contractSrvId',
            '',
            '',
          );
        }
      },
    });
  };

  const addService = () => {
    data?.commentsByContracts?.push({
      idCompany: '',
      contractId: '',
      comments: {
        inputValue: '',
        value: [],
      },
      contractObs: {
        inputValue: '',
        value: [],
      },
    });
    data?.options?.push([{ value: '', label: 'Seleccione' }]);
    data.statusSelect.push(false);
  };

  const deleteItem = item => {
    data.commentsByContracts.splice(item, 1);
    data.options.splice(item, 1);
    data.statusSelect.splice(item, 1);
  };
  //   -------------- SECTION SELECT GENERALS ROW🍕   ----------

  const [stateInputRow, setStateInputRow] = useState({
    inputValue: '',
    value: [],
  });
  const createOptionRow = label => ({
    label,
    value: label,
  });
  const handleInputChangeRow = (inputValue, index) => {
    if (typeof inputValue == 'string') {
      setStateInputRow({ ...stateInputRow, inputValue: inputValue });
      data.commentsByContracts[index].contractObs.inputValue = inputValue;
      let tempArray = [];
      stateInputRow.value.forEach(elem => {
        tempArray.push(elem.label);
      });
      data.commentsByContracts[index].comments = tempArray;
    } else if (Array.isArray(inputValue)) {
      setStateInputRow({ ...stateInputRow, value: inputValue });
      data.commentsByContracts[index].contractObs.value = inputValue;
    }
  };

  const handleKeyDownRow = (event, index) => {
    const { inputValue, value } = data.commentsByContracts[index].contractObs;
    if (inputValue && ['Enter', 'Tab'].includes(event.key)) {
      let tempArray = value;
      tempArray.push(createOptionRow(event.target.value));
      setStateInputRow({ inputValue: '', value: tempArray });
      data.commentsByContracts[index].contractObs.value = tempArray;
      data.commentsByContracts[index].contractObs.inputValue = '';
    }
  };
  //   -------------- !SECTION SELECT 🍕   ----------

  // ------------------ !SECTION SELECTS ROW ----------------

  const [sendData, setSendData] = useState({
    srvId: obvData.srvId,
    userId: userId,
    srvObs: [],
    contractObs: [],
  });

  const createObservation = () => {
    data.srvObs.value.forEach(elem => {
      if (data.srvObs.value.length > 0) {
        sendData.srvObs.push(elem.value);
      } else {
        setSendData({ ...sendData, srvObs: [] });
      }
    });
    data.commentsByContracts.length > 0 &&
      data.commentsByContracts.forEach(elem => {
        if (elem.comments.length > 0) {
          sendData?.contractObs.push({
            contractId: elem.contractId,
            idCompany: elem.idCompany,
            contractObs: elem.comments,
            contractSrvId: elem?.contractSrvId ? elem?.contractSrvId : '',
          });
        } else {
          setSendData({ ...sendData, contractObs: [] });
        }
      });

    let status;

    data.srvObs.value.length > 0 ? (status = true) : (status = false);
    data.commentsByContracts.forEach(elem => {
      elem.contractId !== '' && elem.idCompany !== '' && elem.contractObs?.value?.length > 0
        ? (status = true)
        : (status = false);
      return status;
    });

    if (status) {
      createObservationMethod({
        url: '/medical/offeredService/observations/',
        token: token,
        method: 'POST',
        body: { ...sendData, userId: userId },
        doAfterSuccess: () => {
          history.push('/asistencial/configuracionObservaciones');
          setSendData({
            srvId: obvData.srvId,
            srvObs: [],
            contractObs: [],
          });
        },
      });
    } else {
      message('info', 'Campos vacíos', 'Por favor llene todos los campos');
    }
  };

  return (
    <>
      {(createObservationLoader || contractOptionsLoader || companyOptionsLoader) && loader}
      <OrdGenericTemplate
        showBackArrow={true}
        backArrowAction={() => history.push('/asistencial/configuracionObservaciones')}
        title={obvData?.srvName}
        titleSize={12}
        className='mx-2 py-2 w-90'
        titleStyle={{ marginLeft: '1rem', textTransform: 'capitalize' }}
      >
        <div className={`px-5`}>
          <Row className='mb-5'>
            <b className={`${tableStyles.tlnTextPrimary} ${tableStyles.f24}`}>
              Parametrización de observaciones
            </b>
          </Row>
          <div className='my-3 '>
            <div className='d-flex justify-content-between my-3'>
              <div>
                <b className={`${tableStyles.f16} ${tableStyles.tlnTextPrimary} `}>
                  Observaciones generales
                </b>
              </div>
              <div>
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltipAlert()}
                >
                  <img alt='alert' src={alert} />
                </OverlayTrigger>
              </div>
            </div>

            <div className={`${tableStyles.app_back_search}  d-flex py-4 `}>
              <CreatableSelect
                components={{ DropdownIndicator: null }}
                inputValue={data.srvObs.inputValue}
                className={`${tableStyles.heightSelectApp}`}
                isClearable
                isMulti
                menuIsOpen={false}
                onChange={e => handleInputChange(e, 'onchange')}
                onInputChange={e => handleInputChange(e, 'oninputchange')}
                onKeyDown={e => {
                  handleKeyDown(e);
                }}
                placeholder='Escriba una observación...'
                styles={ordCustomSelect}
                value={data.srvObs.value}
              />
            </div>
          </div>
          <div className='mt-5 '>
            <div className='mb-3'>
              <b className={`${tableStyles.f16} ${tableStyles.tlnTextPrimary} `}>
                Observaciones por contrato
              </b>
            </div>
            <Row className='mb-2'>
              <Col xs={3}>
                <b className={`${tableStyles.f14} ${tableStyles.appDarkBlueText} px-4`}>
                  &nbsp;Empresa (
                  {data?.commentsByContracts?.length ? data?.commentsByContracts?.length : '0'})
                </b>
              </Col>
              <Col xs={3}>
                <b className={`${tableStyles.f14} ${tableStyles.appDarkBlueText}`}>
                  &nbsp;Contratos (
                  {data?.commentsByContracts?.length ? data?.commentsByContracts?.length : '0'})
                </b>
              </Col>
              <Col xs={6}>
                <b className={`${tableStyles.f14} ${tableStyles.appDarkBlueText}`}>
                  &nbsp;Observaciones
                </b>
              </Col>
            </Row>
            {data.options.length > 0 &&
              data.commentsByContracts.length > 0 &&
              data.commentsByContracts.map((row, index) => {
                // //console.log("index: ", index);
                // //console.log("datajum: ", data?.options);
                const contractOptionsV =
                  data?.options.length > 0
                    ? data?.options[index]
                    : [{ value: '', label: 'Seleccione' }];

                // //console.log("casi: ", contractOptionsV);
                return (
                  <Row key={index} className=''>
                    <Col xs={3} className='d-flex  '>
                      <span
                        className={`${tableStyles.f14} ${tableStyles.appDarkBlueText} px-2 align-self-center`}
                      >
                        {index + 1}
                      </span>
                      &nbsp;
                      <Form.Group
                        className='mb-3 text-start d-flex w-100 align-self-center`'
                        controlId={index}
                      >
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          key={index}
                          options={companyOptions}
                          className='text-secondary w-100 align-self-center`'
                          placeholder={'Seleccione'}
                          value={companyOptions?.find(x => x.value === row.idCompany)}
                          styles={ordCustomSelect}
                          onChange={e => {
                            data.commentsByContracts[index].idCompany = e.value;
                            data.commentsByContracts[index].contractId = '';

                            getContractsFunction(e.value, index);
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId={index}>
                        {data.statusSelect[index] === true ? (
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={row.contractName}
                          />
                        ) : (
                          <Select
                            noOptionsMessage={() => 'No hay datos'}
                            key={index}
                            options={contractOptionsV}
                            className='text-secondary '
                            placeholder={'Seleccione'}
                            value={contractOptionsV?.find(x => x.value === row.contractId)}
                            styles={ordCustomSelect}
                            onChange={e => {
                              data.commentsByContracts[index].contractId = e.value;
                              data.commentsByContracts[index].contractSrvId = e.extra;
                            }}
                          />
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs={6} className='d-flex'>
                      <CreatableSelect
                        key={index}
                        components={{ DropdownIndicator: null }}
                        inputValue={data.commentsByContracts[index].contractObs.inputValue}
                        className={`w-100`}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={e => handleInputChangeRow(e, index)}
                        onInputChange={e => handleInputChangeRow(e, index)}
                        onKeyDown={e => {
                          handleKeyDownRow(e, index);
                        }}
                        placeholder='Escriba una observación...'
                        styles={ordCustomSelect}
                        value={data.commentsByContracts[index].contractObs.value}
                      />
                      <button onClick={() => deleteItem(index)} className='px-2 mb-2'>
                        <img alt='delete' src={deleteIcon} width='25px' />
                      </button>
                    </Col>
                  </Row>
                );
              })}

            <Row>
              <div className='d-flex justify-content-end align-self-center mx-4'>
                <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
                  Agregar empresa + contrato
                </b>
                <button
                  className='addCheckButton mx-2'
                  style={{ marginBottom: '1rem' }}
                  onClick={() => {
                    addService();
                  }}
                />
              </div>
            </Row>
          </div>
        </div>
        <div
          style={{
            padding: ' 1rem 2rem',
            borderTop: '1px solid #',
          }}
          className='d-flex justify-content-end align-items-end align-self-end border-top bg-white'
        >
          <div
            className='px-4 w-100 d-flex justify-content-end align-items-end align-self-end mb-2 '
            style={{ width: '100%' }}
          >
            <button
              onClick={() => history.push('/asistencial/configuracionObservaciones')}
              className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
            >
              Cancelar
            </button>
            <button
              onClick={() => createObservation()}
              className={`${tableStyles.ordBtnPrimary} btn`}
            >
              Guardar
            </button>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};
export default ParameterCommets;
