import styled from 'styled-components';

export const StyledSelect = styled.select`
  color: ${({ color, theme }) => (color ? color : theme.colors.ordDarkGray)};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};
  font-size: ${({ fontSize, theme }) => (fontSize ? fontSize : theme.sizes.title.sx)};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  width: ${({ width }) => (width ? width : '296px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'lighter')};
  border: ${({ border, theme }) => (border ? border : `1px solid ${theme.colors.darkBlueInput}`)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '6px')};
  height: ${({ height }) => (height ? height : '32px')};
  padding: ${({ padding }) => (padding ? padding : '0px 10px')};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius ? borderBottomLeftRadius : 'auto'};
  border-top-left-radius: ${({ borderTopLeftRadius }) =>
    borderTopLeftRadius ? borderTopLeftRadius : 'auto'};
  outline: 0;
`;

export const Select = ({
  name,
  arrayOptions = [],
  handleChangeSelect,
  onblurSelect,
  color,
  fontSize,
  padding,
  margin,
  fontWeight,
  width,
  border,
  borderRadius,
  height,
  boxShadow,
  borderTopLeftRadius,
  borderBottomLeftRadius,
  backgroundColor,
  valueSelect,
  className,
}) => {
  return (
    <StyledSelect
      className={`${className} animate__animated animate__fadeIn`}
      color={color}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      padding={padding}
      margin={margin}
      fontWeight={fontWeight}
      onChange={handleChangeSelect}
      onBlur={onblurSelect}
      name={name}
      width={width}
      border={border}
      borderRadius={borderRadius}
      height={height}
      boxShadow={boxShadow}
      borderTopLeftRadius={borderTopLeftRadius}
      borderBottomLeftRadius={borderBottomLeftRadius}
      value={valueSelect}
    >
      <option value={-1}>selecciona...</option>
      {arrayOptions.map(element => (
        <option key={element.id} value={element.id}>
          {element.value}
        </option>
      ))}
    </StyledSelect>
  );
};
