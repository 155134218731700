import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Box } from '@mui/material';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import addbutton from '../../../../../../src/assets/img/icons/addbutton.svg';
import alertBlue from '../../../../../../src/assets/img/icons/alertBlue.svg';

import { BasicTemplate } from '../../../../Templates/basicTemplate/BasicTemplate';
import useForm from './../../../../../Hooks/useForm';
import { useGetMethod, usePostMethod } from './../../../../../Hooks/useFetch';
import { generateId, isEmptyOrUndefined, loader } from '../../../../../helpers/helpers';
import { findDuplicates } from '../../../../../helpers/findDuplicates';
import { renderToolImportance } from '../../../../../helpers/renderToolImportance';
import { customSwaltAlertAsistencial } from '../../../../../helpers/customSwaltAlertAsistencial';

import { Title, Line, Text } from '../../../../UI/atoms/';
import {
  BoxCheckText,
  BoxSelectText,
  BoxFooter,
  BoxInputSelectText,
} from './../../../../UI/molecules/';

import styles from '../../../../../../src/components/Layouts/tableStyle.module.scss';
import TmAdmissionStyles from "./tmAdmissionStyles.module.scss"
import { getEnvs } from "../../../../../helpers";

const { CURRENCY } = getEnvs();

export const TmAdmissionSettings = () => {
  const { sizes, colors } = useTheme();
  const [dataCurrencyDefault, setDataCurrencyDefault] = useState(false);
  const store = useSelector(state => state);
  const idAccount = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [listSelectedCollectionsPartialClosures, setListSelectedCollectionsPartialClosures] =
    useState([]);
  const [listSelectedCollectionsSarlaft, setListSelectedCollectionsSarlaft] = useState([]);

  const { results: listConfig, trigger: getListConfig, load: loadListConfig } = useGetMethod();

  const { trigger: sendSave, load: loadSave } = usePostMethod();

  const {
    results: listDepositType,
    trigger: getListDepositType,
    load: loadListDepositType,
  } = useGetMethod();

  const {
    results: listCurrency,
    trigger: getListCurrency,
    load: loadListCurrency,
  } = useGetMethod();

  const [depositTypes, setDepositTypes] = useState([]);
  const [depositTypesCopy, setDepositTypesCopy] = useState([]);
  const [depositTypesSarlaft, setDepositTypesSarlaft] = useState([]);
  const [currency, setCurrency] = useState([]);

  const { byAmount, byPatientCount, deposits, patientQty, sarlaft } = listConfig?.results || {};

  const { handleChangeCheck, handleChange, handleChangeSelect, valuesForm, setValuesForm } =
    useForm({
      pcIsCountPatients: byPatientCount || false,
      pcIsByAmount: byAmount || false,
      pcCountPatients: '',
      pcCollectionType: '',
      pcValueCollection: '',
      pcCoinType: '',
      psCollectionType: '',
      psValueCollection: '',
      psMinValueCoinType: '',
    });

  const singleCollection = {
    amount: null,
    currency: null,
    type: null,
    id: generateId(),
  };

  const { pcCountPatients, pcIsByAmount, pcIsCountPatients, psCollectionType, pcValueCollection } =
    valuesForm;

  const [pcCollections, setPcCollections] = useState(deposits || []);
  const [psCollections, setPsCollections] = useState(sarlaft || []);
  const [singleCollectionFirst, setSingleCollectionFirst] = useState({
    amount: null,
    currency: null,
    type: null,
    labelCurrent: null,
    labelType: '',
    id: generateId(),
  });
  const [singleCollectionFirstPS, setSingleCollectionFirstPS] = useState({
    amount: null,
    currency: null,
    labelCurrent: null,
    type: null,
    labelType: '',
    id: generateId(),
  });

  const addPcCollection = () => {
    setPcCollections(state => [...state, singleCollection]);
  };

  const addPSCollection = () => {
    setPsCollections(state => [...state, singleCollection]);
  };

  const removePcCollection = id => {
    const filter = pcCollections.filter(el => el.id !== id);
    setPcCollections(filter);
  };

  const removePsCollection = id => {
    const filter = psCollections.filter(el => el.id !== id);
    setPsCollections(filter);
  };

  const handleSave = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `Verifique la información`,
      confirmButtonText: 'Si, Continuar',
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        if (pcIsCountPatients && pcCountPatients < 1) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: `La cantidad de pacientes debe ser mayor a 0`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }

        if (pcIsByAmount) {
          const isValidFirsGroup = Object.values(singleCollectionFirst).every(e => e !== '');
          let isValidPcCollections = true;

          let pcAmountsNegative = 0;

          pcCollections.forEach(state => {
            if (Number(state.amount <= 0)) {
              pcAmountsNegative = 1;
              return;
            }

            if (state?.currency === null) {
              state.currency = 1;
            }

            if (state.amount === null || state.currency === null || state.type === null) {
              isValidPcCollections = false;
            }
          });

          if (pcAmountsNegative) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los valores no pueden ser negativos`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }

          if (Number(pcValueCollection) <= 0 || Number(psCollectionType) <= 0) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los valores no pueden ser negativos`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }

          if (isValidFirsGroup === false) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los campos de cierres parciales deben ir llenos`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }

          if (isValidPcCollections === false) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los campos de cierres parciales deben ir llenos`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        }

        if (psCollections.length > 0) {
          let isValidPsCollections = true;
          let psAmountsNegative = 0;

          psCollections.forEach(state => {
            if (Number(state.amount <= 0)) {
              psAmountsNegative = 1;
              return;
            }

            if (state?.currency === null) {
              state.currency = 1;
            }

            if (state.amount === null || state.currency === null || state.type === null) {
              isValidPsCollections = false;
            }
          });

          if (psAmountsNegative) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los valores no pueden ser negativos`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }

          if (isValidPsCollections === false) {
            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `Los campos de la política sarlaft deben estar llenos `,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
        }

        const dataSingle = {
          ...singleCollectionFirst,
          currency: singleCollectionFirst.currency
            ? singleCollectionFirst.currency
            : dataCurrencyDefault?.id,
          labelCurrent: singleCollectionFirst.currency
            ? listCurrency?.results?.find(e => e.id === singleCollectionFirst.currency)?.label
            : dataCurrencyDefault?.label,
        };

        const single =
          singleCollectionFirst.amount !== null || singleCollectionFirst.amount !== undefined
            ? [dataSingle]
            : [];

        const dataCP = pcCollections.length ? [dataSingle, ...pcCollections] : single;

        const isValidCP = findDuplicates(dataCP, 'type');

        const dataSingleSarlaft = {
          ...singleCollectionFirstPS,
          currency: singleCollectionFirstPS.currency
            ? singleCollectionFirstPS.currency
            : dataCurrencyDefault?.id,
          labelCurrent: singleCollectionFirstPS.currency
            ? listCurrency?.results?.find(e => e.id === singleCollectionFirstPS.currency)?.label
            : dataCurrencyDefault?.label,
        };

        const singleSarlaft =
          singleCollectionFirstPS.amount !== null || singleCollectionFirstPS.amount !== undefined
            ? [dataSingleSarlaft]
            : [];

        const dataSarlaft = psCollections.length
          ? [dataSingleSarlaft, ...psCollections]
          : singleSarlaft;
        const isValidSarlaft = findDuplicates(dataSarlaft, 'type');

        if (isValidCP.length || isValidSarlaft.length) {
          return customSwaltAlertAsistencial({
            icon: 'warning',
            title: `Intenta de nuevo`,
            text: `El tipo de recaudo no puede estar repetido`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }

        const body = {
          eaccount: idAccount,
          byPatientCount: isEmptyOrUndefined(pcIsCountPatients) ? false : pcIsCountPatients,
          patientQty: !pcIsCountPatients ? null : pcCountPatients,
          byAmount: isEmptyOrUndefined(pcIsByAmount) ? false : pcIsByAmount,
          createdBy: idUser,
          sarlaft: dataSarlaft,
          deposits: !pcIsByAmount ? [] : dataCP,
        };

        sendSave({
          url: '/medical/generals/cashConfig/',
          token: token,
          method: 'post',
          body: body,
          succesAction: response => {
            if (response.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: `Asignación creada`,
                text: `${response.message}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          },

          doAfterException: response => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `${response.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  useEffect(() => {
    getListConfig({
      url: `/medical/generals/cashConfig/`,
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });

    getListDepositType({
      token: token,
      url: `/medical/generals/depositType/`,
      objFilters: { eaccount: idAccount },
    }).then(res => {
      if (res.success) {
        const result = res?.results?.map(e => ({ ...e, value: e.id }));
        setDepositTypes(result);
        setDepositTypesCopy(result);
        setDepositTypesSarlaft(result);
      }
    });

    getListCurrency({
      url: `/medical/generals/currency/`,
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });
  }, [getListConfig, getListCurrency, getListDepositType, idAccount, token]);

  useEffect(() => {
    if (listCurrency?.results?.length) {
      setDataCurrencyDefault(listCurrency?.results?.find(e => e?.label === CURRENCY));
    }
  }, [listCurrency]);

  useEffect(() => {
    let filterPcCollection = [];
    let filterSarlaft = [];

    let objPcCollections = { x: 'x' };
    let objPsCollections = { x: 'x' };

    listConfig?.results?.deposits?.forEach((e, i) => {
      let idPC = generateId();

      if (i > 0) {
        filterPcCollection.push({ ...e, id: idPC });

        objPcCollections[`pcCollectionType${idPC}`] = e.type;
        objPcCollections[`pcValueCollection${idPC}`] = e.amount;
        objPcCollections[`pcCoinType${idPC}`] = e.currency;
      }
    });

    listConfig?.results?.sarlaft?.forEach((e, i) => {
      let idSarlaft = generateId();
      if (i > 0) {
        filterSarlaft.push({ ...e, id: idSarlaft });

        objPsCollections[`psValueCollection${idSarlaft}`] = e.type;
        objPsCollections[`psCollectionType${idSarlaft}`] = e.amount;
        objPsCollections[`psMinValueCoinType${idSarlaft}`] = e.currency;
      }
    });

    setPcCollections(filterPcCollection);
    setPsCollections(filterSarlaft);

    setValuesForm({
      pcIsCountPatients: byPatientCount,
      pcIsByAmount: byAmount,
      pcCountPatients: patientQty,
      pcCollectionType: deposits?.[0].type,
      pcValueCollection: deposits?.[0].amount,
      pcCoinType: deposits?.[0].currency,
      ...objPcCollections,
      ...objPsCollections,
      psValueCollection: sarlaft?.[0].type,
      psCollectionType: sarlaft?.[0].amount,
      psMinValueCoinType: sarlaft?.[0].currency,
    });

    setSingleCollectionFirst({
      type: deposits?.[0].type,
      amount: deposits?.[0].amount,
      currency: deposits?.[0].currency,
    });

    setSingleCollectionFirstPS({
      type: sarlaft?.[0].type,
      amount: sarlaft?.[0].amount,
      currency: sarlaft?.[0].currency,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listConfig]);

  useEffect(() => {
    setCurrency(listCurrency?.results?.map(e => ({ ...e, value: e.id })));
  }, [listCurrency]);

  useEffect(() => {
    if (listDepositType?.results?.length) {
      const res = [valuesForm?.pcCollectionType].concat(pcCollections?.map(el => el?.type));
      const clearListDepositTypes = [];

      depositTypes?.forEach(el => {
        if (!res.includes(el?.value)) {
          clearListDepositTypes.push(el);
        }
      });

      setDepositTypesCopy(clearListDepositTypes);
      setListSelectedCollectionsPartialClosures(res);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesForm?.pcCollectionType, pcCollections, listDepositType]);

  useEffect(() => {
    const res = [valuesForm?.psValueCollection].concat(psCollections?.map(el => el?.type));
    setListSelectedCollectionsSarlaft(res);

    if (listDepositType?.results?.length) {
      const res = [valuesForm?.psValueCollection].concat(psCollections?.map(el => el?.type));
      const clearListDepositTypes = [];

      depositTypes?.forEach(el => {
        if (!res.includes(el?.value)) {
          clearListDepositTypes.push(el);
        }
      });

      setDepositTypesSarlaft(clearListDepositTypes);
      setListSelectedCollectionsSarlaft(res);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesForm?.psValueCollection, psCollections, listDepositType]);

  return (
    <>
      {(loadListConfig || loadSave || loadListDepositType || loadListCurrency) && loader}
      <Box className={TmAdmissionStyles.tmAdmissionSettingsContainer}>
        <section className={TmAdmissionStyles.tmAdmissionSettingsContent}>
          <BasicTemplate title={'Configuración'} marginTitle={'0 0 35px 0'}>
            <Row class Name={'animate__animated animate__fadeIn'}>
              <Col xs={4}>
                <Title
                  fontSize={sizes.title.md}
                  title={'Cierres parciales'}
                  color={colors.ordAquaMarine}
                />

                <Box mt={'1rem'}>
                  <BoxCheckText
                    valueCheck={valuesForm?.pcIsCountPatients}
                    handleChangeCheck={handleChangeCheck}
                    nameCheck={'pcIsCountPatients'}
                    titleText='Por cantidad de pacientes'
                    fontWeight={'bold'}
                  />

                  {valuesForm.pcIsCountPatients ? (
                    <BoxInputSelectText
                      backgroundColorSelect={'white'}
                      textActive='Pacientes'
                      titleText='Cada:'
                      nameInput={'pcCountPatients'}
                      valueInput={valuesForm.pcCountPatients}
                      handleChangeInput={handleChange}
                      typeInput='number'
                    />
                  ) : (
                    ''
                  )}
                </Box>

                <Box mt={'1rem'}>
                  <BoxCheckText
                    valueCheck={valuesForm?.pcIsByAmount}
                    handleChangeCheck={handleChangeCheck}
                    nameCheck={'pcIsByAmount'}
                    titleText='Por monto'
                    fontWeight={'bold'}
                  />

                  {valuesForm.pcIsByAmount && (
                    <>
                      <BoxSelectText
                        valueSelect={
                          valuesForm?.pcCollectionType
                            ? {
                              label: listDepositType?.results?.find(
                                e => e.id === valuesForm?.pcCollectionType,
                              )?.label,
                              value: valuesForm?.pcCollectionType,
                            }
                            : ''
                        }
                        arrayOptions={depositTypesCopy}
                        titleText='Forma de pago'
                        handleChangeSelect={e => {
                          handleChangeSelect(e, 'pcCollectionType');
                          const isExist = pcCollections.filter(
                            e => e.id === singleCollectionFirst.id,
                          ).length;

                          if (isExist) {
                            const filter = pcCollections.find(state => {
                              if (state.id === singleCollectionFirst.id) {
                                return { ...state, type: e.value, labelType: e.label };
                              }
                              return state;
                            });

                            setSingleCollectionFirst(filter);
                          } else {
                            setSingleCollectionFirst({
                              ...singleCollectionFirst,
                              type: e.value,
                              labelType: e.label,
                            });
                          }
                        }}
                      />

                      <Box mt={'1rem'}>
                        <BoxInputSelectText
                          backgroundColorSelect={'white'}
                          arrayOptions={currency}
                          valueSelect={
                            valuesForm?.pcCoinType
                              ? {
                                label: listCurrency?.results?.find(
                                  e => e.id === valuesForm?.pcCoinType,
                                )?.label,
                                value: valuesForm?.pcCoinType,
                              }
                              : { label: CURRENCY, value: 1 }
                          }
                          titleText='Cada:'
                          nameInput={'pcValueCollection'}
                          valueInput={valuesForm.pcValueCollection}
                          handleChangeInput={e => {
                            handleChange(e);

                            const isExist = pcCollections.filter(
                              e => e.id === singleCollectionFirst.id,
                            ).length;

                            if (isExist) {
                              const filter = pcCollections.find(state => {
                                if (state.id === singleCollectionFirst.id) {
                                  return { ...state, amount: e.target.value };
                                }
                                return state;
                              });

                              setSingleCollectionFirst(filter);
                            } else {
                              setSingleCollectionFirst({
                                ...singleCollectionFirst,
                                amount: e.target.value,
                              });
                            }
                          }}
                          typeInput='number'
                          handleChangeSelect={e => {
                            handleChangeSelect(e, 'pcCoinType');
                            const isExist = pcCollections.filter(
                              e => e.id === singleCollectionFirst.id,
                            ).length;

                            if (isExist) {
                              const filter = pcCollections.find(state => {
                                if (state.id === singleCollectionFirst.id) {
                                  return { ...state, currency: e.value, labelCurrent: e.label };
                                }
                                return state;
                              });

                              setSingleCollectionFirst(filter);
                            } else {
                              setSingleCollectionFirst({
                                ...singleCollectionFirst,
                                currency: e.value,
                                labelCurrent: e.label,
                              });
                            }
                          }}
                        />
                      </Box>

                      {pcCollections.map((element, indice) => (
                        <div key={indice}>
                          <Line />

                          <Box display={'flex'} alignItems='end' columnGap={'5px'}>
                            <BoxSelectText
                              arrayOptions={depositTypesCopy}
                              titleText='Forma de pago'
                              nameSelect={`pcCollectionType${element.id}`}
                              valueSelect={
                                valuesForm[`pcCollectionType${element.id}`] || ''
                                  ? {
                                    label:
                                      listDepositType?.results?.find(
                                        e => e.id === valuesForm[`pcCollectionType${element.id}`],
                                      )?.label || '',
                                    value: valuesForm[`pcCollectionType${element.id}`] || '',
                                  }
                                  : ''
                              }
                              handleChangeSelect={e => {
                                handleChangeSelect(e, `pcCollectionType${element.id}`);

                                const filter = pcCollections.map(state => {
                                  if (state.id === element.id) {
                                    return { ...state, type: e.value, labelType: e.label };
                                  }
                                  return state;
                                });

                                setPcCollections(filter);
                              }}
                            />
                            <Text
                              title={'x'}
                              margin='0px 0px 5px 0px'
                              pointer
                              handleClick={() => removePcCollection(element.id, indice)}
                            />
                          </Box>

                          <BoxInputSelectText
                            valueSelect={
                              valuesForm[`pcCoinType${element.id}`] || ''
                                ? {
                                  label:
                                    listCurrency?.results?.find(
                                      e => e.id === valuesForm[`pcCoinType${element.id}`],
                                    )?.label || '',
                                  value: valuesForm[`pcCoinType${element.id}`] || '',
                                }
                                : { label: CURRENCY, value: 1 }
                            }
                            backgroundColorSelect={'white'}
                            arrayOptions={currency}
                            titleText='Cada:'
                            nameInput={`pcValueCollection${element.id}`}
                            valueInput={valuesForm[`pcValueCollection${element.id}`]}
                            handleChangeInput={e => {
                              handleChange(e);

                              const filter = pcCollections.map(state => {
                                if (state.id === element.id) {
                                  return { ...state, amount: e.target.value };
                                }
                                return state;
                              });

                              setPcCollections(filter);
                            }}
                            typeInput='number'
                            handleChangeSelect={e => {
                              handleChangeSelect(e, `pcCoinType${element.id}`);

                              const filter = pcCollections.map(state => {
                                if (state.id === element.id) {
                                  return { ...state, currency: e.value, labelCurrent: e.label };
                                }
                                return state;
                              });

                              setPcCollections(filter);
                            }}
                          />
                        </div>
                      ))}

                      {listSelectedCollectionsPartialClosures?.length <
                        listDepositType?.results?.length ? (
                        <Text
                          title={'Otro tipo de recaudo'}
                          classNameBox='hoverB'
                          iconPath={addbutton}
                          classNameImg={styles.svgBlueFilter}
                          iconHeight='20px'
                          iconWidth={'20px'}
                          fontWeight='bold'
                          margin='1rem 0'
                          pointer
                          handleClick={addPcCollection}
                        />
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Box>
              </Col>

              <Col xs={1} />

              <Col xs={4}>
                <Box display={'flex'} columnGap='10px'>
                  <Title
                    fontSize={sizes.title.md}
                    title={'Política SARLAFT'}
                    color={colors.ordAquaMarine}
                  />
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    className={styles.app_container_tool_websc}
                    overlay={renderToolImportance(
                      'Sistema de administración del riesgo de lavado de activos y financiación del terrorismo',
                    )}
                  >
                    <img src={alertBlue} alt='alertBlue' />
                  </OverlayTrigger>
                </Box>

                <BoxSelectText
                  valueSelect={
                    valuesForm?.psValueCollection
                      ? {
                        label: listDepositType?.results?.find(
                          e => e.id === valuesForm?.psValueCollection,
                        )?.label,
                        value: valuesForm?.psValueCollection,
                      }
                      : ''
                  }
                  arrayOptions={depositTypesSarlaft}
                  titleText='Forma de pago'
                  nameSelect={'psValueCollection'}
                  handleChangeSelect={e => {
                    handleChangeSelect(e, `psValueCollection`);

                    const isExist = psCollections.filter(
                      e => e.id === singleCollectionFirstPS.id,
                    ).length;

                    if (isExist) {
                      const filter = psCollections.find(state => {
                        if (state.id === singleCollectionFirstPS.id) {
                          return { ...state, type: e.value, labelType: e.label };
                        }
                        return state;
                      });

                      setSingleCollectionFirstPS(filter);
                    } else {
                      setSingleCollectionFirstPS({
                        ...singleCollectionFirstPS,
                        type: e.value,
                        labelType: e.label,
                      });
                    }
                  }}
                />

                <Box mt={'1rem'}>
                  <BoxInputSelectText
                    valueSelect={
                      valuesForm?.psMinValueCoinType
                        ? {
                          label: listCurrency?.results?.find(
                            e => e.id === valuesForm?.psMinValueCoinType,
                          )?.label,
                          value: valuesForm?.psMinValueCoinType,
                        }
                        : { label: CURRENCY, value: 1 }
                    }
                    backgroundColorSelect={'white'}
                    arrayOptions={currency}
                    titleText='Mínimo para SARLAFT:'
                    nameInput={'psCollectionType'}
                    valueInput={valuesForm.psCollectionType}
                    handleChangeInput={e => {
                      handleChange(e);

                      const isExist = psCollections.filter(
                        e => e.id === singleCollectionFirstPS.id,
                      ).length;

                      if (isExist) {
                        const filter = psCollections.find(state => {
                          if (state.id === singleCollectionFirstPS.id) {
                            return { ...state, amount: e.target.value };
                          }
                          return state;
                        });

                        setSingleCollectionFirstPS(filter);
                      } else {
                        setSingleCollectionFirstPS({
                          ...singleCollectionFirstPS,
                          amount: e.target.value,
                        });
                      }
                    }}
                    typeInput='number'
                    nameSelect={'psMinValueCoinType'}
                    handleChangeSelect={e => {
                      handleChangeSelect(e, `psMinValueCoinType`);

                      const isExist = psCollections.filter(
                        e => e.id === singleCollectionFirstPS.id,
                      ).length;

                      if (isExist) {
                        const filter = psCollections.find(state => {
                          if (state.id === singleCollectionFirstPS.id) {
                            return { ...state, currency: e.value, labelCurrent: e.label };
                          }
                          return state;
                        });

                        setSingleCollectionFirstPS(filter);
                      } else {
                        setSingleCollectionFirstPS({
                          ...singleCollectionFirstPS,
                          currency: e.value,
                          labelCurrent: e.label,
                        });
                      }
                    }}
                  />
                </Box>

                {psCollections.map((element, indice) => (
                  <div key={indice}>
                    <Line />
                    <Box display={'flex'} alignItems='end' columnGap={'5px'}>
                      <BoxSelectText
                        valueSelect={
                          valuesForm[`psValueCollection${element.id}`] || ''
                            ? {
                              label:
                                listDepositType?.results?.find(
                                  e => e.id === valuesForm[`psValueCollection${element.id}`],
                                )?.label || '',
                              value: valuesForm[`psValueCollection${element.id}`] || '',
                            }
                            : ''
                        }
                        arrayOptions={depositTypesSarlaft}
                        titleText='Forma de pago'
                        nameSelect={`psValueCollection${element.id}`}
                        handleChangeSelect={e => {
                          handleChangeSelect(e, `psValueCollection${element.id}`);

                          const filter = psCollections.map(state => {
                            if (state.id === element.id) {
                              return { ...state, type: e.value, labelType: e.label };
                            }
                            return state;
                          });

                          setPsCollections(filter);
                        }}
                      />

                      <Text
                        title={'x'}
                        margin='0px 0px 5px 0px'
                        pointer
                        handleClick={() => removePsCollection(element.id)}
                      />
                    </Box>

                    <BoxInputSelectText
                      valueSelect={
                        valuesForm[`psMinValueCoinType${element.id}`] || ''
                          ? {
                            label:
                              listCurrency?.results?.find(
                                e => e.id === valuesForm[`psMinValueCoinType${element.id}`],
                              )?.label || '',
                            value: valuesForm[`psMinValueCoinType${element.id}`] || '',
                          }
                          : { label: CURRENCY, value: 1 }
                      }
                      gridTemplateColumns={`60% 40%`}
                      backgroundColorSelect={'white'}
                      arrayOptions={currency}
                      titleText='Mínimo para SARLAFT:'
                      nameInput={`psCollectionType${element.id}`}
                      valueInput={valuesForm[`psCollectionType${element.id}`]}
                      handleChangeInput={e => {
                        handleChange(e);

                        const filter = psCollections.map(state => {
                          if (state.id === element.id) {
                            return { ...state, amount: e.target.value };
                          }
                          return state;
                        });

                        setPsCollections(filter);
                      }}
                      typeInput='number'
                      nameSelect={`psMinValueCoinType${element.id}`}
                      handleChangeSelect={e => {
                        handleChangeSelect(e, `psMinValueCoinType${element.id}`);

                        const filter = psCollections.map(state => {
                          if (state.id === element.id) {
                            return { ...state, currency: e.value, labelCurrent: e.label };
                          }
                          return state;
                        });

                        setPsCollections(filter);
                      }}
                    />
                  </div>
                ))}

                {listSelectedCollectionsSarlaft?.length < listDepositType?.results?.length ? (
                  <Text
                    title={'Otro tipo de recaudo'}
                    classNameBox='hoverB'
                    iconPath={addbutton}
                    classNameImg={styles.svgBlueFilter}
                    iconHeight='20px'
                    iconWidth={'20px'}
                    fontWeight='bold'
                    margin='1rem 0'
                    pointer
                    handleClick={addPSCollection}
                  />
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </BasicTemplate>
        </section>

        <section className={TmAdmissionStyles.tmAdmissionSettingsActionsButtonsContainer}>
          <BoxFooter
            bgSave={colors.ordAquaMarine}
            marginLeft={'5.1em'}
            padding='0 10em 0 0'
            backgroundColor={'transparent'}
            onclickSave={handleSave}
            onclickCancel={() => history.push('/asistencial/inicio')}
          />
        </section>
      </Box>
    </>
  );
};
