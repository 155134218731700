import { Send } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
//
import Excel from '../../assets/img/icons/excel.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import LupaClear from '../../assets/img/icons/lupaClear.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
//
import { useGetMethod } from '../../Hooks';
import { usePostMethod } from '../../Hooks/useFetch';
import {
  getClient,
  getContracts,
  getHistory,
  getStatus,
} from '../../actions/receiptOfInvoicesActions';
import GenericTable from '../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { genericDownloadExcel, loader } from '../../helpers/helpers';
import FilingAction from './FilingAction/FilingAction';
import LegalizeAction from './LegalizeAction/LegalizeAction';
//
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert } from '../../helpers';

function InvoiceHistory() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    table: 'invoice',
    client: '',
    balance: '',
    contractType: '',
    status: '',
    dateFrom: '',
    dateUntil: '',
    sequence: '',
    site: '',
    rangeFrom: '',
    rangeUntil: '',
    receiptStatus: 'received',
  });
  const [showOpt, setShowOpt] = useState(false);
  const [canCheck, setCanCheck] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filingModalOpen, setFilingModalOpen] = useState(false);
  const [legalizeModalOpen, setLegalizeModalOpen] = useState(false);

  const { trigger: postDocExcel } = usePostMethod();
  const { trigger: getContractTypes, results: contractTypes } = useGetMethod();
  const { results: siteOptionsResults, trigger: getSiteOptions } = useGetMethod();

  useEffect(() => {
    getSiteOptions({
      url: '/medical/generals/site/',
      objFilters: { eaccount: storage.loginReducer.currentAccount.id },
      token: storage.loginReducer.Authorization,
    });
  }, [getSiteOptions, storage.loginReducer.currentAccount.id, storage.loginReducer.Authorization]);

  useEffect(() => {
    (async function () {
      await getContractTypes({
        url: '/invoicing/contract/type/',
        objFilters: {
          eaccount: storage.loginReducer.currentAccount.id,
        },
        token: storage.loginReducer.Authorization,
      });
    })();
  }, [
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getContractTypes,
  ]);

  useEffect(() => {
    dispatch(
      getHistory({
        page: 1,
        perpage: 10,
        eaccount: storage.loginReducer.currentAccount.id,
        receiptStatus: 'received',
      }),
    );
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getStatus({ type: 'invoiceHis' }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  useEffect(() => {
    if (filters.client) {
      dispatch(
        getContracts({ eaccount: storage.loginReducer.currentAccount.id, client: filters.client }),
      );
    }
  }, [filters.client, dispatch, storage.loginReducer.currentAccount.id]);

  let optionsStatus = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.status)) {
    storage.invoiceReducer.status.forEach(item => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + 'status',
      });
    });
  }

  let optionsClient = [{ key: 'default1', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsBalance = [
    { key: 'default2', value: '', label: 'Seleccionar...', id: '' },
    { key: 'default3', value: 'ASC', label: 'Menor a mayor', id: '1' },
    { key: 'default4', value: 'DESC', label: 'Mayor a menor', id: '2' },
  ];

  const onSuccessDownload = () => {
    dispatch(
      getHistory({
        ...filters,
        page: 1,
      }),
    );
    setSelectedRows([]);
    setCanCheck(null);
  };

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const onShowFilters = () => {
    if (showOpt) {
      setShowOpt(false);
    } else {
      setShowOpt(true);
    }
  };

  const applyFilters = event => {
    event.preventDefault();
    const {
      client,
      contractType,
      status,
      balance,
      rangeFrom,
      rangeUntil,
      dateFrom,
      dateUntil,
      table,
      site,
    } = event.target;
    dispatch(
      getHistory({
        client: client.value,
        contractType: contractType.value,
        status: status.value,
        balance: balance.value,
        rangeFrom: rangeFrom.value,
        rangeUntil: rangeUntil.value,
        dateFrom: dateFrom.value,
        dateUntil: dateUntil.value,
        table: table.value,
        site: site.value,
        page: 1,
        perpage: 10,
        eaccount: storage.loginReducer.currentAccount.id,
        receiptStatus: 'received',
      }),
    );
    setFilters(prev => ({ ...prev, page: 1 }));
  };

  const onCheckAll = checked => {
    if (checked) {
      const updatedSelection = storage.invoiceReducer.history?.data;
      setSelectedRows(updatedSelection);

      const firstChecked = updatedSelection[0];
      setCanCheck({
        companyNit: firstChecked.clientNit,
        contract: firstChecked.contract,
        status: firstChecked.status.toLowerCase(),
      });
    } else {
      setSelectedRows([]);
      setCanCheck(null);
    }
  };

  const onCheckItems = el => {
    const isSelected = selectedRows.some(item => item.id === el.id);
    let updatedSelection = [];

    if (isSelected) {
      updatedSelection = selectedRows.filter(item => item.id !== el.id);
    } else {
      updatedSelection = [...selectedRows, el];
    }
    setSelectedRows(updatedSelection);

    if (updatedSelection.length > 0) {
      const firstChecked = updatedSelection[0];
      setCanCheck({
        companyNit: firstChecked.clientNit,
        contract: firstChecked.contract,
        status: firstChecked.filing_status?.toLowerCase() || firstChecked.status?.toLowerCase(),
      });
    } else {
      setCanCheck(null);
    }
  };

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/invoice/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        receiptStatus: filters.receiptStatus,
        table: filters.table,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  function isEveryEqual(array = [], propsToCompare = []) {
    if (array.length === 0) {
      return true;
    }

    const getValues = obj => propsToCompare.map(prop => obj[prop]);

    return array.every((obj, _, array) => {
      const reference = getValues(array[0]);
      const currentValues = getValues(obj);
      return JSON.stringify(currentValues) === JSON.stringify(reference);
    });
  }

  const onCloseFilingAction = () => {
    setFilingModalOpen(false);
  };

  const onFilingAction = () => {
    setFilingModalOpen(true);
  };

  const onCloseLegalizeAction = () => {
    setLegalizeModalOpen(false);
  };

  const onLegalizeAction = item => {
    if (item) {
      setSelectedRows([item]);
      setCanCheck({
        companyNit: item.clientNit,
        contract: item.contract,
        status: item.filing_status?.toLowerCase() || item.status?.toLowerCase(),
      });
    }
    setLegalizeModalOpen(true);
  };

  const canSelectAll = useMemo(() => {
    return isEveryEqual(storage.invoiceReducer.history?.data, ['clientNit', 'contract', 'status']);
  }, [storage.invoiceReducer.history?.data]);

  const optionsContracts = useMemo(
    () =>
      contractTypes.results?.map(item => ({
        label: item.value,
        value: item.value,
      })) || [],
    [contractTypes.results],
  );

  const optionsSites = useMemo(
    () =>
      siteOptionsResults.results?.map(item => ({
        label: item.site,
        value: item.siteId,
      })) || [],
    [siteOptionsResults.results],
  );

  const header = [
    <th key={1} className='text-center px-2' style={{ width: 20 }}>
      {canSelectAll && (
        <Form.Check
          type='checkbox'
          className='pt-1'
          onChange={({ target }) => onCheckAll(target.checked)}
        />
      )}
    </th>,
    ...(storage.invoiceReducer.history?.header?.map(th => {
      if (th.path === 'clientName' || th.path === 'contract' || th.path === 'site') {
        return (
          <th className='text-start px-2' style={{ whiteSpace: 'nowrap' }} key={th.path}>
            {th.name}
          </th>
        );
      }
      if (th.path === 'pendingAmount' || th.path === 'totalAmount') {
        return (
          <th className='text-end px-2' key={th.path}>
            {th.name}
          </th>
        );
      }
      return (
        <th className='text-center px-2' style={{ whiteSpace: 'nowrap' }} key={th.path}>
          {th.name}
        </th>
      );
    }) || []),
    <th key={2} className='text-center px-2'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (storage.invoiceReducer.history?.data) {
      table = storage.invoiceReducer.history?.data?.map(item => {
        return (
          <tr key={item.id} className='hover-table-row'>
            <td className='px-2' width={20}>
              {item?.status !== 'Radicado' && item?.filing_status !== 'Radicado' && (
                <Form.Check
                  type='checkbox'
                  className='pt-1'
                  checked={selectedRows.some(el => el.id === item.id)}
                  onChange={() => onCheckItems(item)}
                  disabled={
                    canCheck &&
                    !(
                      item.clientNit === canCheck.companyNit &&
                      item.contract === canCheck.contract &&
                      (item.status?.toLowerCase() === canCheck.status ||
                        item.filing_status?.toLowerCase() === canCheck.status)
                    )
                  }
                />
              )}
            </td>
            <td className='text-uppercase px-2'>{item.sequence}</td>
            <td className='px-2'>
              {moment(item.facturationDate || item.exp_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </td>
            <td className='px-2'>
              {item.filing_date
                ? moment(item.filing_date, 'YYYY-MM-DD').format('DD/MM/YYYY') === 'Fecha inválida'
                  ? '-'
                  : moment(item.filing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : moment(item.radicationDate, 'YYYY-MM-DD').format('DD/MM/YYYY') ===
                  'Fecha inválida'
                  ? '-'
                  : moment(item.radicationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </td>
            <td className='px-2'>{item.clientNit}</td>
            <td className='text-start px-2'>{item.clientName}</td>
            <td className='px-2 text-start'>{item.contract}</td>
            <td className='px-2 text-start'>{item.site}</td>
            <td className='px-2 text-end'>{'$' + numberWithCommas(item.pendingAmount)}</td>
            <td className='px-2 text-end'>{'$' + numberWithCommas(item.totalAmount)}</td>
            <td className='text-center px-2'>
              <div
                style={{
                  backgroundColor: item.filing_status_background ?? item.status_background,
                  color: item.filing_status_fontcolor ?? item.status_fontcolor,
                }}
                className={tableStyles.baseStatus}
              >
                {item.filing_status ?? item.status}
              </div>
            </td>
            <td className='text-center px-2'>
              {(item.status === 'Radicado' || item.filing_status === 'Radicado') && (
                <CustomPopupExtend
                  showDetails
                  showOtroSi
                  otroSi='Visualizar'
                  position='left'
                  triggerSrc={ThreeDots}
                  showDetailsEvent={() =>
                    history.push(
                      `/cartera/Factura/HistoricoDeRadicacion/Detalle/${item.id}?type=${item?.filing_status ? 'minute' : 'invoice'}`,
                    )
                  }
                  otroSiEvent={() => {
                    const anchor = document.createElement('a');
                    anchor.href = item.filing_file_url;
                    anchor.target = '_blank';
                    anchor.click();
                  }}
                />
              )}
              {(item.status === 'Sin Radicar' || item.filing_status === 'Sin Radicar') && (
                <CustomPopupExtend
                  showDetails
                  position='left'
                  triggerSrc={ThreeDots}
                  showDetailsEvent={() =>
                    history.push(
                      `/cartera/Factura/HistoricoDeRadicacion/Detalle/${item.id}?type=${item?.filing_status ? 'minute' : 'invoice'}`,
                    )
                  }
                />
              )}
              {(item.status === 'R. Sin Legalizar' ||
                item.filing_status === 'R. Sin Legalizar') && (
                  <CustomPopupExtend
                    showDetails
                    sendToAccount
                    sendToAccountText='Legalizar'
                    position='left'
                    triggerSrc={ThreeDots}
                    sendToAccountTextEvent={() => onLegalizeAction(item)}
                    showDetailsEvent={() =>
                      history.push(
                        `/cartera/Factura/HistoricoDeRadicacion/Detalle/${item.id}?type=${item?.filing_status ? 'minute' : 'invoice'}`,
                      )
                    }
                  />
                )}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const minutesInvoices = [
    { label: 'Factura', value: 'invoice' },
    { label: 'Acta', value: 'minute' },
  ];

  return (
    <>
      {storage.invoiceReducer.history_loading && loader}
      <div className='container-fluid px-5'>
        <h1 className={tableStyles.title}>Histórico de radicación</h1>
        <div className={IndividualStyles.container2}>
          <div className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin} `}>
            <div className={`${IndividualStyles.Boton} `} onClick={() => onShowFilters()}>
              <img
                src={LupaClear}
                alt='añadir'
                className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2} ${tableStyles.cursorPointer}`}
              />
              <span className={`${IndividualStyles.proArrowWrapper}`}>
                {showOpt ? (
                  <span className={`${IndividualStyles.proArrow2}`}> </span>
                ) : (
                  <span className={`${IndividualStyles.proArrow}`}> </span>
                )}
              </span>
            </div>
          </div>
        </div>

        {showOpt && (
          <div className='mb-3'>
            <form className='row gy-2 gx-3' onSubmit={applyFilters}>
              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Empresa</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsClient}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      client: e.value,
                    })
                  }
                  name='client'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Tipo de contrato</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={[{ label: 'Seleccionar...', value: '' }, ...optionsContracts]}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      contractType: e.value,
                    })
                  }
                  name='contractType'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Estado</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsStatus}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      status: e.value,
                    })
                  }
                  name='status'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Acta/Factura</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={minutesInvoices}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      table: e.value,
                    });
                    setSelectedRows([]);
                    setCanCheck(null);
                  }}
                  value={minutesInvoices.find(item => item.value === filters.table)}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  name='table'
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>
                  Rango de {filters.table === 'invoice' ? 'factura' : 'acta'} desde
                </label>
                <input
                  type='text'
                  className={IndividualStyles.registerInputsGris}
                  placeholder='Escribir...'
                  value={filters.rangeFrom}
                  onChange={({ target }) =>
                    setFilters({
                      ...filters,
                      rangeFrom: target.value,
                    })
                  }
                  name='rangeFrom'
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>
                  Rango de {filters.table === 'invoice' ? 'factura' : 'acta'} hasta
                </label>
                <input
                  type='text'
                  className={IndividualStyles.registerInputsGris}
                  placeholder='Escribir...'
                  value={filters.rangeUntil}
                  onChange={({ target }) =>
                    setFilters({
                      ...filters,
                      rangeUntil: target.value,
                    })
                  }
                  name='rangeUntil'
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Fecha desde</label>
                <div className={tableStyles.deleteicon}>
                  {filters.date_from ? (
                    <span onClick={() => setFilters({ ...filters, date_from: '' })}>x</span>
                  ) : (
                    ''
                  )}
                  <input
                    className={IndividualStyles.registerInputsBlue}
                    type='date'
                    value={filters.date_from}
                    onChange={e =>
                      setFilters({
                        ...filters,
                        dateFrom: e.target.value,
                      })
                    }
                    name='dateFrom'
                  />
                </div>
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Fecha hasta</label>
                <div className={tableStyles.deleteicon}>
                  {filters.date_until ? (
                    <span onClick={() => setFilters({ ...filters, date_until: '' })}>x</span>
                  ) : (
                    ''
                  )}
                  <input
                    className={IndividualStyles.registerInputsBlue}
                    type='date'
                    value={filters.date_until}
                    onChange={e =>
                      setFilters({
                        ...filters,
                        dateUntil: e.target.value,
                      })
                    }
                    name='dateUntil'
                  />
                </div>
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Saldo</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={optionsBalance}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      balance: e.value,
                    })
                  }
                  name='balance'
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </div>

              <div className='col-lg-2'>
                <label className={tableStyles.stylesLabel}>Sedes</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={[{ label: 'Seleccionar...', value: '' }, ...optionsSites]}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      site: e.value,
                    })
                  }
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                  name='site'
                />
              </div>

              <div className='col d-flex justify-content-end align-self-end'>
                <button name='submit' type='submit'>
                  <img
                    className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                    src={Lupa}
                    alt='filters'
                    style={{ width: '25px' }}
                  />
                </button>
                <img
                  src={Excel}
                  className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                  alt='excel'
                  style={{ width: '25px' }}
                  onClick={() => downloadExcelDoc()}
                />
              </div>
            </form>
          </div>
        )}

        <div className='table-responsive mb-3'>
          <GenericTable headers={header} dark={true}>
            {renderList()}
          </GenericTable>
        </div>

        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.invoiceReducer.history ? filters.page : ''}
              {' de '}
              {Math.ceil(storage.invoiceReducer?.history_total / filters.perpage)
                ? Math.ceil(storage.invoiceReducer.history_total / filters.perpage)
                : ''}{' '}
              ({storage.invoiceReducer.history_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.invoiceReducer.history_total}
              pageRangeDisplayed={5}
              onChange={page => {
                setFilters({ ...filters, page: page });
                dispatch(
                  getHistory({
                    ...filters,
                    page: page,
                  }),
                );
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
        {canCheck && canCheck.status && (
          <div className='d-flex justify-content-end'>
            {canCheck.status === 'r. sin legalizar' && (
              <button
                className={`${tableStyles.btnPrimaryAlt} d-flex align-items-center justify-content-center mt-4 py-2`}
                onClick={() => onLegalizeAction()}
              >
                <Send style={{ fontSize: 15 }} />
                <span className='ms-2'>Legalizar</span>
              </button>
            )}
            {canCheck.status === 'sin radicar' && (
              <button
                className={`${tableStyles.btnPrimaryAlt} d-flex align-items-center justify-content-center mt-4 py-2`}
                onClick={onFilingAction}
              >
                <Send style={{ fontSize: 15 }} />
                <span className='ms-2'>Radicar</span>
              </button>
            )}
          </div>
        )}
        <div className='pb-5'></div>
      </div>

      {selectedRows.length ? (
        <>
          <FilingAction
            isOpen={filingModalOpen}
            onClose={onCloseFilingAction}
            selectedItems={selectedRows}
            onSuccess={onSuccessDownload}
          />
          <LegalizeAction
            isOpen={legalizeModalOpen}
            onClose={onCloseLegalizeAction}
            selectedItems={selectedRows}
            onSuccess={onSuccessDownload}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
}
export default InvoiceHistory;
