import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import {
  GET_ABSENTEEISM,
  GET_ACC_TO_PAY_BY_PROV,
  GET_BALANCE_IN_ACCOUNT,
  GET_BUSSINESS_WALLET,
  GET_CONSUM,
  GET_CURRICULUM_ADVANCES,
  GET_DEBT_TOTAL_ACC,
  GET_INCOME_VS_OUTCOME,
  GET_INVENTORY_ROTATION,
  GET_PROBLEMS_IN_ENTRY_WH,
  GET_PROVISIONED_VS_COLLECTED,
  GET_PROVISIONED_VS_COLLECTED_BY_CLIENT,
  GET_RADICATIONS,
  GET_TOP_CLIENTS,
  GET_TOP_PROVIDERS,
  GET_TOTAL_ACC,
  GET_TOTAL_ACC_TO_PAY,
  GET_TOTAL_INVESTMENT,
  GET_UNFILED_INVOICES,
  WALLET_BY_AGES,
  ACCOUNTS_RECEIVABLE,
  GET_DETAIL_OF_BALANCES,
  GET_FREE_AND_CAPITA,
  GET_GLOSSES_DETAIL,
  GET_BILLING_BY_TYPE,
  GET_PROFIT_BALANCE,
  DISTRIBUTION_BANK_BALANCE,
  EXPIRED_SUPPLIES,
  CPX_ROTATION,
  PERIOD_EXPENSES,
} from "./actionTypes";

export const getRadications = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/filedVsUnfiledInvoices/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_RADICATIONS,
        payload: res,
      });
    })
    .catch((error) => {
      console.error(error.message);
      return message(
        "error",
        "Error",
        "No ha sido posible cargar los datos, por favor intentelo nuevamente"
      );
    });
};
export const unFiledInvoices = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/unfiledInvoices/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_UNFILED_INVOICES,
        payload: res,
      });
    })
    .catch((error) => {
      console.error(error.message);
      return message(
        "error",
        "Error",
        "No ha sido posible cargar los datos, por favor intentelo nuevamente"
      );
    });
};
export const clientBussinessWallet = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/portfolioByCustomer/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      dispatch({
        type: GET_BUSSINESS_WALLET,
        payload: res,
      });
    })
    .catch((error) => {
      console.error(error.message);
      return message(
        "error",
        "Error",
        "No ha sido posible cargar los datos, por favor intentelo nuevamente"
      );
    });
};
export const getConsum = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/consumption_center/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_CONSUM,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      return message(
        "error",
        "Error",
        "No ha sido posible cargar los datos, por favor intentelo nuevamente"
      );
    });
};

export const getTotalAcc = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/totalReceivables/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_TOTAL_ACC,
          payload: res.result,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getTopClients = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/top_clients/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res?.success) {
        dispatch({
          type: GET_TOP_CLIENTS,
          payload: res.result,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getBalanceInAcc = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/balance_per_account/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_BALANCE_IN_ACCOUNT,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getBalanceIncomeVsOutCome =
  (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/management/balance_revenues_expenses/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_INCOME_VS_OUTCOME,
            payload: res,
          });
        } else {
          return message(
            "error",
            "Error",
            "No ha sido posible cargar los datos, por favor intentelo nuevamente"
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
      });
  };
export const getDebtTotalAcc = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/CurrentTotalProvisioned/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_DEBT_TOTAL_ACC,
          payload: res.data,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getTotalAccToPay = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/treasury_vs/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_TOTAL_ACC_TO_PAY,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getTopProviders = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/top_providers/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_TOP_PROVIDERS,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getProvisionedVsCollected =
  (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    dispatch({
      type: GET_PROVISIONED_VS_COLLECTED,
      payload: { loading: true },
    });
    fetch(
      `${URL_GATEWAY}${API_VERSION}/management/provisionedVSCollected/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_PROVISIONED_VS_COLLECTED,
            payload: { ...res, loading: false },
            loading: false,
          });
        } else {
          return message(
            "error",
            "Error",
            "No ha sido posible cargar los datos, por favor intentelo nuevamente"
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_PROVISIONED_VS_COLLECTED,
          payload: { loading: false },
        });
        console.error(error.message);

        // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
      });
  };
export const getProvisionedVsCollectedByClient =
  (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/management/provisionedVSCollectedByClient/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_PROVISIONED_VS_COLLECTED_BY_CLIENT,
            payload: res,
          });
        } else {
          return message(
            "error",
            "Error",
            "No ha sido posible cargar los datos, por favor intentelo nuevamente"
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
      });
  };
export const getWalletByAges = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: WALLET_BY_AGES,
    payload: { loading: true },
  });
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/receivable/balance/getBalanceChart/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: WALLET_BY_AGES,
          payload: { ...res, loading: false },
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      dispatch({
        type: WALLET_BY_AGES,
        payload: { loading: true },
      });
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getOverAllConsumption = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/overall_consumption/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};
export const getTotalIncome = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/total_income/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};
export const getAccToPay = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/accounts_payable_balance/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};
export const getActiveProviders = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/providers_actives/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};

export const getInventoryRotation = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/inv_rot/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_INVENTORY_ROTATION,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getEmployeeNumber = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/totalEmployees/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};
export const getAccidentPercentage = async (objFilters, token) => {
  try {
    const filters = convertFilterToString(objFilters);
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/management/accidentRate/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return query.json();
  } catch (error) {
    return message(
      "error",
      "Error",
      "No ha sido posible cargar los datos, por favor intentelo nuevamente"
    );
  }
};

export const getCurriculumAdvances = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/resumePreview/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_CURRICULUM_ADVANCES,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getAbseteenism = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/absenteeism/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_ABSENTEEISM,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getTotalInvestment = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/totalPayrollInvestment/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_TOTAL_INVESTMENT,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getAccToPayByProv = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/accounts_payable_provider/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_ACC_TO_PAY_BY_PROV,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getProblemsInEntryWh = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/warehouse_problems/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_PROBLEMS_IN_ENTRY_WH,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getDetailOfAccountBalances =
  (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/management/detailsOfAccountBalances/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: GET_DETAIL_OF_BALANCES,
            payload: res.result,
          });
        } else {
          return message(
            "error",
            "Error",
            "No ha sido posible cargar los datos, por favor intentelo nuevamente"
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
      });
  };

export const getAccountsReceivable = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/receivable/balance/getBalanceChart/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: ACCOUNTS_RECEIVABLE,
          payload: res.results.series,
          categories: res.results.categories,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getCapita = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/indicador_facturacion/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_FREE_AND_CAPITA,
          payload: res,
          categories: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getDistributionOfBankBalances =
  (objFilters) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(
      `${URL_GATEWAY}${API_VERSION}/management/accounts_per_bank/?${filters}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatch({
            type: DISTRIBUTION_BANK_BALANCE,
            payload: res,
          });
        } else {
          return message(
            "error",
            "Error",
            "No ha sido posible cargar los datos, por favor intentelo nuevamente"
          );
        }
      })
      .catch((error) => {
        console.error(error.message);
        // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
      });
  };
export const getGlossesDetail = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/gloss_objection_get/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_GLOSSES_DETAIL,
          payload: res,
          categories: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getBillingByType = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/invoicingByType/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_BILLING_BY_TYPE,
          payload: res,
          categories: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getProfitBalance = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/profitBalance/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: GET_PROFIT_BALANCE,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
export const getExpiredSupplies = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/management/expired_supplies/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: EXPIRED_SUPPLIES,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getCPXRotation = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/pinvoices/cxpRotationChart/?${filters}`,
    {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: CPX_ROTATION,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};

export const getPeriodExpenses = (objFilters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/management/period_expenses/?${filters}`, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res.success) {
        dispatch({
          type: PERIOD_EXPENSES,
          payload: res,
        });
      } else {
        return message(
          "error",
          "Error",
          "No ha sido posible cargar los datos, por favor intentelo nuevamente"
        );
      }
    })
    .catch((error) => {
      console.error(error.message);
      // return message("error", "Error", "No ha sido posible cargar los datos, por favor intentelo nuevamente");
    });
};
