import ReactSelect from 'react-select';
import Pagination from 'react-js-pagination';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../Hooks';

import Lupa from '../../assets/img/icons/lupa.svg';
import downloadIcon from '../../assets/img/icons/downloadPdf.svg';

import { customSwaltAlert, downloaFile, loader } from '../../helpers';
import { formatNumberWithoutDecimals } from '../../helpers/numberFormatting';

import GenericTableNew from '../../components/Layouts/GenericTableNew';

import { customSelectNew } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

const NOTE_TYPE_OPTIONS = [
  { label: 'Seleccionar...', value: null },
  { label: 'Débito', value: 'debit' },
  { label: 'Crédito', value: 'credit' },
];

const NOTES_HEADER = [
  <th key={1} className='px-2 text-start'>
    No. de factura
  </th>,
  <th key={2} className='px-2 text-center'>
    Fecha
  </th>,
  <th key={2} className='px-2 text-center'>
    Tipo
  </th>,
  <th key={3} className='px-2 text-end'>
    Valor factura </th>,
  <th key={4} className='px-2 text-end'>
    Nuevo valor factura
  </th>,
  <th key={5} className='px-2 text-center'>
    Creado por
  </th>,
  <th key={6} className='px-2'></th>,
];

export default function NotesHistoryInvoicing() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const eaccount = useSelector(store => store.loginReducer.currentAccount.id);

  const [noteFilters, setNoteFilters] = useState({
    searchNote: '',
    startDate: null,
    endDate: null,
    noteType: null,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 10,
  });

  const {
    trigger: getNotesHistory,
    load: notesHistoryLoader,
    results: notesHistoryResults,
  } = useGetMethod();

  const {
    trigger: getNotePDF,
    load: notePDFLoader,
  } = useGetMethod();

  useEffect(() => {
    void getNotesHistory({
      url: '/medical/admissions/invoicing-notes',
      token: token,
      objFilters: {
        startDate: noteFilters.startDate,
        endDate: noteFilters.endDate,
        noteType: noteFilters.noteType,
        search: noteFilters.searchNote,
        page: pagination.page,
        perpage: pagination.perpage,
        eaccount: eaccount,
        table: 'inv',
      },
    });
  }, [
    getNotesHistory,
    token,
    eaccount,
    noteFilters.startDate,
    noteFilters.endDate,
    noteFilters.noteType,
    noteFilters.searchNote,
    pagination.page,
    pagination.perpage,
  ]);

  const onSubmitSearch = event => {
    event.preventDefault();

    const { searchNote } = event.target;

    setNoteFilters(state => ({ ...state, searchNote: searchNote.value.trim() }));
  };

  const downloadNote = (note) => {
    getNotePDF({
      url: '/medical/invoicing/download-note/',
      token: token,
      objFilters: {
        eaccount,
        noteId: note.id,
        table: 'inv'
      },
    }).then((results) => {
        if (!results?.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          })
          return
        }

        downloaFile(
          results?.results?.key,
          `${note.noteType}_note_${note.id}.pdf`
        )
      })
  }

  const renderFilters = () => {
    return (
      <div className='row justify-content-start align-items-end'>
        <div className='col-lg-2'>
          <small className='text-primary ms-1'>Fecha desde</small>
          <input
            className={`register-inputs`}
            type='date'
            onChange={({ target }) => {
              setNoteFilters(state => ({ ...state, startDate: target.value }));
            }}
            value={noteFilters.startDate}
            max={noteFilters.endDate || new Date().toISOString('en-US').split('T')[0]}
          />
        </div>
        <div className='col-lg-2'>
          <small className='text-primary ms-1'>Fecha hasta</small>
          <input
            className={`register-inputs`}
            type='date'
            onChange={({ target }) => {
              setNoteFilters(state => ({ ...state, endDate: target.value }));
            }}
            value={noteFilters.endDate}
            min={noteFilters.startDate}
            max={new Date().toISOString('en-US').split('T')[0]}
          />
        </div>
        <div className='col-lg-2'>
          <small className='text-primary ms-1'>Tipo de nota</small>
          <ReactSelect
            placeholder='Seleccionar...'
            styles={customSelectNew}
            noOptionsMessage={() => 'No hay datos'}
            name='noteType'
            className='text-secondary'
            onChange={option => {
              setNoteFilters(state => ({ ...state, noteType: option.value }));
            }}
            value={NOTE_TYPE_OPTIONS.find(opt => opt.value === noteFilters.noteType)}
            options={NOTE_TYPE_OPTIONS}
          />
        </div>
        <form onSubmit={onSubmitSearch} className='d-flex col-lg-4'>
          <input
            className={tableStyles.SearchNew}
            type='text'
            placeholder='Buscar nota...'
            name='searchNote'
            autoComplete='off'
          />
          <button type='submit' className='ms-2'>
            <img width={22} src={Lupa} alt='buscar' />
          </button>
        </form>
      </div>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {(notesHistoryLoader || notePDFLoader) && loader}
        <div className='container-fluid px-5'>
          <h1 className={`${tableStyles.title}`}>Historial de notas</h1>
          {renderFilters()}

          <GenericTableNew headers={NOTES_HEADER} dark={true}>
            {notesHistoryResults?.results &&
              notesHistoryResults.results.map(note => (
                <tr key={note.id}>
                  <td className='px-2 text-start'>{`${note?.resolutionPrefix ?? ''}${note.invoiceId}`}</td>
                  <td className='px-2 text-center'>
                    {moment(note.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  </td>
                  <td className='px-2 text-center'>
                    {note.noteType === "debit" ? "Débito" : "Crédito"}
                  </td>
                  <td className='px-2 text-end'>
                    {formatNumberWithoutDecimals(note.originalValue)}
                  </td>
                  <td className='px-2 text-end'>{formatNumberWithoutDecimals(note.newValue)}</td>
                  <td className='px-2 text-center'>
                    {note.createdBy.firstName} {note.createdBy.lastName}
                  </td>
                  <td>
                    <button>
                      <img width={18} src={downloadIcon} alt='' onClick={() => downloadNote(note)}/>
                    </button>
                  </td>
                </tr>
              ))}
          </GenericTableNew>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {pagination.page}
              {' de '}
              {Math.ceil(notesHistoryResults?.rowTotal / pagination.perpage)
                ? Math.ceil(notesHistoryResults?.rowTotal / pagination.perpage)
                : '1'}{' '}
              ({notesHistoryResults?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={pagination.page}
              itemsCountPerPage={pagination.perpage}
              totalItemsCount={notesHistoryResults?.rowTotal}
              pageRangeDisplayed={5}
              onChange={page => setPagination({ perpage: 10, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </>
    );
  };

  return renderComponent();
}
