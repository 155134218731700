import { GET_EVENTS_TAXCALENDAR, LOADING } from "../actions/actionTypes";

const initialState = {
    results: [],
    loading:false,
};
export const TaxCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_TAXCALENDAR:
            return {
                ...state,
                results: action.payload,
            };
        case LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};
