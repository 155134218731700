//components
import { AddCircleOutline } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import download from '../../assets/img/icons/download.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';

import { useGetMethod } from '../../Hooks';
import { getClient } from '../../actions/receiptOfInvoicesActions';
import GenericTableNew from '../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { convertMoneyFormat, customSwaltAlert, loader } from '../../helpers';
import CreatePreDownload from './Create/CreatePreDownload';
import DownloadTemplate from './DownloadTemplate/DownloadTemplate';

import ReactSelect from 'react-select';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function PreDownload() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    dateUntil: '',
    dateFrom: '',
    client: '',
    status: '',
  });
  const [createPreDownload, setCreatePreDownload] = useState(false);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const { trigger: getPreDownload, results: preDownload, load: preDownloadLoad } = useGetMethod();

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getPreDownload({
          url: '/receivable/preDownload/',
          objFilters: {
            ...filters,
            dateFrom: filters.dateUntil ? filters.dateFrom : '',
            dateUntil: filters.dateFrom ? filters.dateUntil : '',
            eaccount: storage.loginReducer.currentAccount.id,
          },
          token: storage.loginReducer.Authorization,
        });

        if (!result.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: result.message,
            confirmButtonText: 'Aceptar',
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getPreDownload,
    filters,
  ]);

  const optionsClient = useMemo(() => {
    let options = [{ value: '', label: 'Seleccionar...' }];
    storage.invoiceReducer.clients.forEach(item => {
      options.push({
        value: item.id,
        label: item.copyName,
      });
    });
    return options;
  }, [storage.invoiceReducer.clients]);

  const onChangeFilters = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const onSuccess = async () => {
    await getPreDownload({
      url: '/receivable/preDownload/',
      objFilters: {
        ...filters,
        dateFrom: filters.dateUntil ? filters.dateFrom : '',
        dateUntil: filters.dateFrom ? filters.dateUntil : '',
        eaccount: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
    });
  };

  const statusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Borrador', value: 'loaded' },
    { label: 'Pendiente', value: 'pending' },
    { label: 'Enviado', value: 'send' },
  ];

  const renderFilters = () => {
    return (
      <Row className='g-0 align-items-end px-1 flex-wrap'>
        <Col lg={2}>
          <Form.Group className='px-2' controlId='ordType'>
            <small className={`m-0 text-primary `}>
              <span>&nbsp;Fecha desde</span>
            </small>
            <input
              className={`register-inputs`}
              type='date'
              id='date-start'
              onChange={({ target }) => onChangeFilters('dateFrom', target.value)}
            />
          </Form.Group>
        </Col>

        <Col lg={2}>
          <Form.Group className=' px-2' controlId='ordType'>
            <small className={`m-0 text-primary `}>
              <span>&nbsp; Fecha hasta</span>
            </small>
            <input
              className={`register-inputs`}
              id='date-end'
              type='date'
              onChange={({ target }) => onChangeFilters('dateUntil', target.value)}
            />
          </Form.Group>
        </Col>

        <Col lg={2}>
          <Form.Group className='px-2' controlId='ordType'>
            <small className={`m-0 text-primary `}>
              <span>&nbsp;Empresa</span>
            </small>

            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              options={optionsClient}
              onChange={option => onChangeFilters('client', option.value)}
            />
          </Form.Group>
        </Col>

        <Col lg={2}>
          <Form.Group className='px-2' controlId='ordType'>
            <small className={`m-0 text-primary `}>
              <span>&nbsp;Estado</span>
            </small>

            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              options={statusSelect}
              onChange={option => onChangeFilters('status', option.value)}
            />
          </Form.Group>
        </Col>

        <Col lg={4} md={4} sm={6} className='d-flex mt-2 '>
          <Form.Group
            className={`${tableStyles.buttonTextPrimary} `}
            onClick={() => setDownloadTemplate(true)}
          >
            <small className='mr-2 px-0' style={{ whiteSpace: 'nowrap' }}>
              Descargar plantilla
            </small>
            <img className='mr-2 px-0' src={download} alt='' width={14} />
          </Form.Group>

          <Form.Group
            className={`${tableStyles.buttonTextPrimary} px-2`}
            onClick={() => setCreatePreDownload(true)}
          >
            <small style={{ whiteSpace: 'nowrap' }}>Crear pre - descarga</small>
            <AddCircleOutline stroke='2' fontSize='small' />
          </Form.Group>
        </Col>
      </Row>
    );
  };

  const renderList = () => {
    let table = [];

    if (Array.isArray(preDownload?.results)) {
      table = preDownload?.results?.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-center px-2'>
              {x?.created_at.split(' ')[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') || '-'}
            </td>
            <td className='text-start px-2 '>{x?.copyName || '-'}</td>
            <td className='text-end px-2 '>{convertMoneyFormat(x?.sum_gross) || '-'}</td>
            <td className='text-end px-2 '>{convertMoneyFormat(x?.sum_withholding_tax) || '-'}</td>
            <td className='text-end px-2 '>{convertMoneyFormat(x?.sum_reteIca) || '-'}</td>
            <td className='text-end px-2 '>{convertMoneyFormat(x?.sum_collected) || '-'}</td>
            <td className='text-center w-20 col-1 ps-3'>
              <div
                className='rounded-pill p-1'
                style={{
                  backgroundColor: `${x.background}`,
                }}
              >
                <b style={{ color: `${x.fontcolor}` }}>{x.status}</b>
              </div>
            </td>
            <td className='text-center col-1 w-15'>
              <CustomPopupExtend
                showDetails
                showOtroSi
                otroSi='Descargar anexo'
                otroSiEvent={() => {
                  const link = document.createElement('a');
                  link.href = x.support;
                  link.target = '_blank';
                  link.download = 'anexo';

                  document.body.appendChild(link);

                  link.click();

                  document.body.removeChild(link);
                }}
                triggerSrc={ThreeDots}
                alt='iconSearch'
                showDetailsEvent={() =>
                  history.push(
                    `/cartera/Recaudo/PreDescarga/Detalle/${x.status}?preDownload=${x?.id}`,
                  )
                }
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const renderTable = () => {
    const headers = [
      <th key={1} style={{ whiteSpace: 'nowrap' }} className='text-center px-2 '>
        Fecha creación
      </th>,
      <th key={2} className='text-start px-2 '>
        Empresa
      </th>,
      <th key={3} style={{ whiteSpace: 'nowrap' }} className='text-end px-2 '>
        Total valor bruto
      </th>,
      <th key={4} style={{ whiteSpace: 'nowrap' }} className='text-end  px-2'>
        Total retefuente
      </th>,
      <th key={5} style={{ whiteSpace: 'nowrap' }} className='text-end  px-2'>
        Total reteICA
      </th>,
      <th key={6} style={{ whiteSpace: 'nowrap' }} className='text-end  px-2 '>
        Total valor recaudado
      </th>,
      <th key={7} className='text-center w-20 px-2 ps-3'>
        Estado
      </th>,
      <th key={8} className='text-center w-15 px-2'></th>,
    ];
    return (
      <GenericTableNew headers={headers} dark={true} shadow>
        {renderList()}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {preDownloadLoad && loader}
        <div className='container-fluid px-5'>
          <h1 className='mb-4'>Pre - Descarga</h1>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(preDownload?.row_count / filters.perpage)
                ? Math.ceil(preDownload?.row_count / filters.perpage)
                : '1'}{' '}
              ({preDownload?.row_count} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={preDownload?.row_count}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>

        <CreatePreDownload
          isOpen={createPreDownload}
          onClose={() => setCreatePreDownload(false)}
          onSuccess={onSuccess}
        />

        <DownloadTemplate isOpen={downloadTemplate} onClose={() => setDownloadTemplate(false)} />
      </>
    );
  };

  return render();
}
