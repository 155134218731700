import { useEffect } from 'react';
import { usePatientAdmissionStore } from '../../../Hooks';
import { useHistory } from 'react-router';

export const ValidationRouteAdminPatient = ({ children }) => {
  const history = useHistory();
  const { events, activeEvent } = usePatientAdmissionStore();
  const path = '/admision/admitirPacientes';

  useEffect(() => {
    if (!events?.length || !activeEvent?.progress) {
      history.replace('/admision/inicio');
    } else {
      if (activeEvent?.progress === 1) history.replace(path + '/informacionBasica');
      if (activeEvent?.progress === 2) history.replace(path + '/informacionAdicional');
      if (activeEvent?.progress === 3) history.replace(path + '/recaudos');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{events?.length && activeEvent?.progress ? children : ''}</>;
};
