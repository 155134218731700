import _ from 'lodash';
import { AddCircleOutline } from '@material-ui/icons';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../Hooks';

import IconBack from '../../assets/img/icons/ordBackArrow.svg';

import { customSwaltAlertAsistencial, loader } from '../../helpers';

import AddDay from './AddDay/AddDay';
import DayForm from './DayForm/DayForm';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function CreateSchedule({
  doctorId,
  isEditingSchedule = false,
  schedule,
  onGoBack = () => null,
}) {
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);
  const token = useSelector(store => store.loginReducer.Authorization);

  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const [scheduleDays, setScheduleDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [isOpenAddDay, setIsOpenAddDay] = useState(false);
  const [schedulePayload, setSchedulePayload] = useState({
    schName: '',
    schDescription: '',
    schColor: '',
    modality: 'on_site',
  });
  const [editPayload, setEditPayload] = useState(null);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const { load: detailScheduleLoader, trigger: getDetailSchedule } = useGetMethod();

  useEffect(() => {
    if (isEditingSchedule && schedule.schId && schedule.schName) {
      void getDetailSchedule({
        url: '/medical/schedules/detail/',
        objFilters: {
          eaccount: accountId,
          schName: schedule.schName,
          doctorId: doctorId,
        },
        token: token,
        doAfterSuccess: res => {
          if (res?.results) {
            setScheduleDays(res.results);
            const currentSchedule = res.results.find(sche => sche.schId === schedule.schId);
            if (currentSchedule) {
              const formattedServices = currentSchedule.services?.map(service => ({
                serviceId: service.srvId,
                name: service.srvName,
                srvTypeName: service.typeName,
                ctypeName: service.ctypeName,
                groupName: service.attGroupName,
                groupId: service.attGroupId,
                ctypeId: service.ctypeId,
              }));
              const formattedAttGroups = currentSchedule.attGroup.map(group => ({
                id: group.attGroupId,
                name: group.attGroupName,
                value: group.percentage,
              }));
              setSchedulePayload({
                schId: currentSchedule.schId,
                schName: currentSchedule.schName,
                schDescription: currentSchedule.schDescription,
                modality: currentSchedule.modality.modalityTag,
                schColor: currentSchedule.schColor,
              });
              setSelectedDay(currentSchedule.dow);
              setEditPayload({
                startTime: currentSchedule.beginsAt,
                endTime: currentSchedule.endsAt,
                site: currentSchedule.siteId,
                type: currentSchedule.crtyId,
                room: currentSchedule.conRoomId,
                helper: currentSchedule.assistantId,
                allowExtraQuota: !!currentSchedule.allowExtraQuota,
                extraQuotaQuantity: currentSchedule.schExtraQuota,
                services: _.groupBy(formattedServices, service => service.srvTypeName),
                attGroups: formattedAttGroups,
              });
            }
          }
        },
      });
    }
  }, [
    accountId,
    doctorId,
    getDetailSchedule,
    isEditingSchedule,
    schedule.schId,
    schedule.schName,
    token,
    triggerUpdate,
  ]);

  const changeDay = scheduleDay => {
    setSelectedDay(scheduleDay.dow);
    if (!scheduleDay?.isCreating) {
      setSchedulePayload(state => ({ ...state, schId: scheduleDay.schId }));
      const formattedServices = scheduleDay.services?.map(service => ({
        serviceId: service.srvId,
        name: service.srvName,
        srvTypeName: service.typeName,
        ctypeName: service.ctypeName,
        groupName: service.attGroupName,
        groupId: service.attGroupId,
        ctypeId: service.ctypeId,
      }));
      const formattedAttGroups = scheduleDay.attGroup.map(group => ({
        id: group.attGroupId,
        name: group.attGroupName,
        value: group.percentage,
      }));
      setEditPayload({
        startTime: scheduleDay.beginsAt,
        endTime: scheduleDay.endsAt,
        site: scheduleDay.siteId,
        type: scheduleDay.crtyId,
        room: scheduleDay.conRoomId,
        helper: scheduleDay.assistantId,
        allowExtraQuota: !!scheduleDay.allowExtraQuota,
        extraQuotaQuantity: scheduleDay.schExtraQuota,
        services: _.groupBy(formattedServices, service => service.srvTypeName),
        attGroups: formattedAttGroups,
      });
    } else {
      setEditPayload(null);
      setSchedulePayload(state => ({ ...state, schId: null }));
    }
  };

  const onAddNewDay = (dow, clonedDay = null) => {
    setSelectedDay(dow.value);
    setIsFieldTouched(true);

    const dayExists = scheduleDays.some(schedule => schedule.dow === dow.value);
    if (!dayExists) {
      setScheduleDays(state => [
        ...state,
        {
          dow: dow.value,
          dowName: dow.label,
          isCreating: true,
        },
      ]);
    }
    if (clonedDay !== null) {
      const scheduleDay = scheduleDays.find(schedule => schedule.dow === clonedDay);
      const formattedServices = scheduleDay.services?.map(service => ({
        serviceId: service.srvId,
        name: service.srvName,
        srvTypeName: service.typeName,
        ctypeName: service.ctypeName,
        groupName: service.attGroupName,
        groupId: service.attGroupId,
        ctypeId: service.ctypeId,
      }));
      const formattedAttGroups = scheduleDay.attGroup.map(group => ({
        id: group.attGroupId,
        name: group.attGroupName,
        value: group.percentage,
      }));
      setEditPayload({
        startTime: scheduleDay.beginsAt,
        endTime: scheduleDay.endsAt,
        site: scheduleDay.siteId,
        type: scheduleDay.crtyId,
        room: scheduleDay.conRoomId,
        helper: scheduleDay.assistantId,
        allowExtraQuota: !!scheduleDay.allowExtraQuota,
        extraQuotaQuantity: scheduleDay.schExtraQuota,
        services: _.groupBy(formattedServices, service => service.srvTypeName),
        attGroups: formattedAttGroups,
        isCreating: true,
      });
    } else {
      setEditPayload(null);
    }
  };

  const onChangeDay = scheduleDay => {
    if (isFieldTouched) {
      customSwaltAlertAsistencial({
        title: '¿Está seguro?',
        text: 'Se perderán los cambios realizados si no ha guardado la información de la agenda',
        confirmButtonText: 'Sí, continuar',
        icon: 'warning',
      }).then(response => {
        if (response.isConfirmed) {
          changeDay(scheduleDay);
          setIsFieldTouched(false);
        }
      });
    } else {
      changeDay(scheduleDay);
    }
  };

  const renderComponent = () => {
    return (
      <div className='container-fluid py-3 px-5'>
        {detailScheduleLoader && loader}
        <div className='d-flex align-items-center'>
          <img
            onClick={() => {
              onGoBack();
            }}
            src={IconBack}
            className='pointer me-3'
            alt=''
          />
          <h2 className={`m-0 ${tableStyles.ordDarkBlueText} fw-bold`}>
            {isEditingSchedule ? 'Configurar' : 'Nueva'} agenda
          </h2>
        </div>

        <div className='row align-items-end mt-4'>
          <div className='col-lg-4'>
            <b className={tableStyles.ordDarkBlueText}>
              Nombre<span className='text-danger'>*</span>
            </b>
            <input
              type='text'
              disabled={isEditingSchedule}
              className='ord-roundInput w-100'
              placeholder='Escribe...'
              value={schedulePayload.schName}
              onChange={({ target }) => {
                setSchedulePayload(state => ({ ...state, schName: target.value }));
              }}
            />
          </div>
          <div className='col-lg-4'>
            <b className={tableStyles.ordDarkBlueText}>
              Descripción<span className='text-danger'>*</span>
            </b>
            <input
              type='text'
              className='ord-roundInput w-100'
              placeholder='Escribe...'
              value={schedulePayload.schDescription}
              onChange={({ target }) => {
                setSchedulePayload(state => ({ ...state, schDescription: target.value }));
              }}
            />
          </div>
          <div className='col-lg-1'>
            <b className={tableStyles.ordDarkBlueText}>
              Color<span className='text-danger'>*</span>
            </b>
            <input
              type='color'
              className='ord-roundInput p-1'
              value={schedulePayload.schColor}
              onChange={({ target }) => {
                setSchedulePayload(state => ({ ...state, schColor: target.value }));
              }}
            />
          </div>
          <div className='col-lg-3 d-flex justify-content-end'>
            <button
              className={`btn d-flex align-items-center ${tableStyles.ordBtnPrimary}`}
              onClick={() => setIsOpenAddDay(true)}
              disabled={
                (!isEditingSchedule && scheduleDays.length > 0) ||
                scheduleDays.some(schedule => schedule.isCreating)
              }
            >
              <span className='me-2'>Agregar día</span>
              <AddCircleOutline />
            </button>
          </div>
        </div>

        {scheduleDays.length ? (
          <ul className='nav nav-tabs ord-tabs mt-4'>
            {scheduleDays
              .sort((a, b) => a.dow - b.dow)
              .map(schedule => (
                <li key={schedule.dow} className='position-relative nav-item'>
                  <div
                    className={`nav-link ${selectedDay === schedule.dow ? 'active' : ''}`}
                    onClick={() => onChangeDay(schedule)}
                  >
                    {schedule.dowName}
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <h5 className='text-muted text-center mt-5'>No hay día seleccionado para agendar.</h5>
        )}

        {selectedDay !== null && (
          <DayForm
            key={editPayload}
            doctor={doctorId}
            schedule={schedulePayload}
            day={selectedDay}
            onCancelForm={() => {
              customSwaltAlertAsistencial({
                title: '¿Está seguro?',
                text: 'Al cancelar la agenda del día perderá todos sus cambios',
                icon: 'warning',
              }).then(response => {
                if (response.isConfirmed) {
                  const newSchedules = scheduleDays.filter(
                    schedule => schedule.dow !== selectedDay && !schedule?.isCreating,
                  );
                  if (newSchedules.length) {
                    changeDay(newSchedules[0]);
                    setScheduleDays(newSchedules);
                  } else {
                    onGoBack();
                  }
                  setIsFieldTouched(false);
                }
              });
            }}
            isFieldTouched={isFieldTouched}
            onTouchField={isTouched => {
              setIsFieldTouched(isTouched);
            }}
            editPayload={editPayload}
            onSuccess={() => {
              setTriggerUpdate(state => state + 1);
            }}
          />
        )}

        <AddDay
          open={isOpenAddDay}
          onClose={() => setIsOpenAddDay(false)}
          busyDays={scheduleDays.map(schedule => ({
            value: schedule.dow,
            label: schedule.dowName,
          }))}
          onSubmit={values => {
            if (isFieldTouched) {
              customSwaltAlertAsistencial({
                title: '¿Está seguro?',
                text: 'Se perderán los cambios realizados si no ha guardado la información de la agenda',
                confirmButtonText: 'Sí, continuar',
                icon: 'warning',
              }).then(response => {
                if (response.isConfirmed) {
                  onAddNewDay(values.day, values.clonedDay);
                  setIsFieldTouched(false);
                }
              });
            } else {
              onAddNewDay(values.day, values.clonedDay);
            }
          }}
        />
      </div>
    );
  };

  return renderComponent();
}
