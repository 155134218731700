// by jhan ortegon

import { useEffect, useState } from 'react';

// icons - images
import lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

// css
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// helpers
import { customSwaltAlert } from '../../helpers';
import { getPermission } from '../../helpers/helpers';

// services
import {
  functional_edit,
  functional_edit_active,
  functional_get,
  functional_get_niif,
  functional_insert,
} from '../../actions/functionalActions';

// Components
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { customSelectNewDark, customSelectNewDarkMulti } from '../Layouts/react-select-custom';
import CustomPopup from '../Popup/customPopup';

function TableFunctional(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const listPermission = counter.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'UnFun', listPermission });

  const [unconfirmedFilters, setUnconfirmedFilters] = useState({
    search: '',
    active: '',
  });

  const initialStateFilters = {
    search: '',
    active: '',
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
  };

  //filtros para el functional_get
  const [filters, setFilter] = useState(initialStateFilters);

  //estado interno para manejar tanto la data de functional_edit como el modal que lo abre
  const [putState, setPutState] = useState({
    usage: '',
    id: '',
    account: undefined,
    description: '',
    sites: [],
    modalShow: false,
    eaccount: counter.loginReducer.currentAccount.id,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      functional_get(
        {
          perpage: 10,
          eaccount: filters.eaccount,
          page: 1,
        },
        () => setLoading(false),
      ),
    );
  }, [dispatch, filters.eaccount]);

  useEffect(() => {
    dispatch(functional_get_niif(counter.loginReducer.currentAccount.id));
  }, [counter.loginReducer.currentAccount.id, dispatch]);

  useEffect(() => {
    const { history } = props;
    if (!myPermission?.read) {
      history?.push('/administracion/inicio');
    }
  }, [myPermission?.read, props]);

  const handleSearchButton = () => {
    setLoading(true);

    setFilter({
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      page: 1,
    });

    dispatch(
      functional_get(
        {
          ...filters,
          search: unconfirmedFilters.search,
          active: unconfirmedFilters.active,
          page: 1,
        },
        () => setLoading(false),
      ),
    );
  };

  const handleResetPutState = () => {
    setPutState({
      usage: '',
      id: '',
      account: undefined,
      description: '',
      active: '',
      sites: [],
      modalShow: false,
      eaccount: counter.loginReducer.currentAccount.id,
    });
  };

  const handleChange = event => {
    setUnconfirmedFilters({
      ...unconfirmedFilters,
      search: event.target.value,
    });
  };

  const handlePageChange = val => {
    if (filters.page !== val) {
      setLoading(true);
    }
    setFilter({
      ...filters,
      page: val,
    });
  };

  function handleSubmit() {
    if (putState.description === '') {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Se requiere descripción',
        showCancelButton: false,
      });
      return null;
    }
    if (!Array.isArray(putState.sites) || putState.sites.length < 1) {
      customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: 'Se requiere una o más sedes',
        showCancelButton: false,
      });
      return null;
    }
    if (putState.account.isNaN) {
      customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: 'Se requiere una cuenta',
        showCancelButton: false,
      });
      return null;
    }
    if (putState.usage === 'PUT') {
      return dispatch(
        functional_edit(putState.id, putState, () => {
          handleResetPutState();
          dispatch(functional_get(initialStateFilters, () => setLoading(false)));
        }),
      );
    }
    if (putState.usage === 'POST') {
      return dispatch(
        functional_insert(putState, () => {
          handleResetPutState();
          dispatch(functional_get(initialStateFilters, () => setLoading(false)));
        }),
      );
    }
    return null;
  }

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-4 text-start' style={{ paddingLeft: '5px' }}>
          {elem.description}
        </td>
        <td className='col-6 text-center'>{elem.account}</td>
        <td className='col-1'>
          <div className={tableStyles.groupElems}>
            <div className={elem.active ? tableStyles.greenState : tableStyles.redState}>
              {elem.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
          </div>
        </td>
        <td className='col-1'>
          {myPermission?.edit && (
            <CustomPopup
              triggerSrc={threeDots}
              showEdit={myPermission?.edit}
              editClickEvent={() =>
                setPutState({
                  ...putState,
                  id: elem.id,
                  account: elem.account,
                  description: elem.description,
                  sites: elem.sites,
                  usage: 'PUT',
                  modalShow: true,
                })
              }
              showEnable={myPermission?.edit}
              isEnabled={elem.active}
              enableClickEvent={() =>
                dispatch(
                  functional_edit_active(
                    elem.id,
                    { active: !elem.active },
                    elem.description,
                    () => {
                      dispatch(functional_get(initialStateFilters, () => setLoading(false)));
                    },
                  ),
                )
              }
            />
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (counter.functionalReducer.units !== undefined) {
      const elem2 = counter.functionalReducer.units;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th style={{ whiteSpace: 'nowrap' }} key={0} className='px-2'>
      Descripción
    </th>,
    <th style={{ whiteSpace: 'nowrap' }} key={1} className='text-center'>
      Cta. Contable
    </th>,
    <th style={{ whiteSpace: 'nowrap' }} key={2} className='text-center '>
      Estado
    </th>,
    <th style={{ whiteSpace: 'nowrap' }} key={3}>
      ㅤ
    </th>,
  ];

  //=============================================================================================

  const optionsNiifs = () => {
    let tempNiifs = [];
    if (counter.functionalReducer.niifs !== undefined) {
      const elem2 = counter.functionalReducer.niifs;
      tempNiifs = elem2.map(elem => {
        return {
          value: elem.complete_account,
          label: elem.complete_account + ': ' + elem.description,
        };
      });
    }
    tempNiifs.unshift({ label: 'Seleccionar...', value: '' });
    return tempNiifs;
  };

  const optionsSites = () => {
    const sites = counter.functionalReducer.sites;
    let coolerSites = sites;

    if (sites !== undefined) {
      coolerSites = sites.map(elem => {
        return {
          ...elem,
          isDisabled: elem.id_account !== counter.loginReducer.currentAccount.id || !elem.active,
        };
      });
    }
    coolerSites.unshift({ label: 'Seleccionar...', value: null });
    return coolerSites;
  };

  const optionSitesValue = () => {
    let selectedOptions = [];
    putState.sites.forEach(mySiteId => {
      let foundOption = optionsSites().find(site => site.value === mySiteId);
      if (foundOption) {
        selectedOptions.push(foundOption);
      }
    });
    return selectedOptions;
  };

  const optionSitesOnChange = e => {
    const newSites = e
      .filter(elem => {
        return elem.value != null;
      })
      .map(elem => elem.value);
    setPutState({
      ...putState,
      sites: newSites,
    });
  };
  const handleSumit = e => {
    e.preventDefault();
    setFilter({
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      page: 1,
    });
    dispatch(
      functional_get(
        {
          ...filters,
          search: unconfirmedFilters.search,
          active: unconfirmedFilters.active,
          page: 1,
        },
        () => setLoading(false),
      ),
    );
  };

  return (
    <div className={tableStyles.container}>
      {(counter.functionalReducer.loading || loading) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <h1 className={tableStyles.title} style={{ marginLeft: '76px' }}>
        Unidades Funcionales
      </h1>
      <div className={tableStyles.tableArea}>
        <div className={tableStyles.mainRow}>
          {myPermission?.read && (
            <form onSubmit={handleSumit}>
              <input
                className={tableStyles.searchUsersAlt}
                type='text'
                placeholder='Buscar...'
                onChange={handleChange}
              />

              <button onClick={() => handleSearchButton()}>
                <img
                  src={lupa}
                  alt='User icon'
                  className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                />
              </button>
            </form>
          )}

          <div className={tableStyles.groupElems}>
            {' '}
            {/* <img
                                src={Export}
                                alt="exportar"
                                className={tableStyles.iconSvg}
                            />  */}
            {myPermission?.create && (
              <div
                className='d-flex justify-content-end groupAddButton align-items-center'
                style={{ marginLeft: 'auto' }}
                onClick={() => setPutState({ ...putState, usage: 'POST', modalShow: true })}
              >
                <label htmlFor='newAccident' className='darkGray fw-bold'>
                  Crear unidad funcional
                </label>
                <button className='addCheckButton mx-2' style={{ marginBottom: '1.5px' }} />
              </div>
            )}
          </div>
        </div>

        {putState.modalShow ? (
          <ModalNew
            hideFooter={putState.usage === 'PUT' ? !myPermission?.edit : !myPermission?.create}
            title='Unidades funcionales'
            show={putState.modalShow}
            onHide={() => handleResetPutState()}
            btnYesEvent={() => handleSubmit()}
          >
            <div className='row bottom-margin-10'>
              <p className='label-inputs'>Descripción</p>
              <div className='col-md-12'>
                <input
                  className='registerInputs2'
                  name='description'
                  type='text'
                  placeholder='Descripción unidad funcional'
                  defaultValue={putState.description}
                  onChange={event => setPutState({ ...putState, description: event.target.value })}
                />
              </div>
            </div>

            <div className='row bottom-margin-10'>
              <p className='label-inputs'>Cuenta contable</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                value={optionsNiifs().find(option => option.value === String(putState.account))}
                onChange={e => setPutState({ ...putState, account: e.value })}
                options={optionsNiifs()}
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
              />
            </div>

            <div className='row bottom-margin-10'>
              <p className='label-inputs'>Sedes</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                defaultValue={optionSitesValue()}
                onChange={e => optionSitesOnChange(e)}
                options={optionsSites()}
                isMulti
                placeholder='Seleccionar...'
                styles={customSelectNewDarkMulti}
              />
            </div>
          </ModalNew>
        ) : null}

        {myPermission?.read && (
          <>
            <GenericTableNew
              fontFamilyTable={'fontFamilyTable'}
              headers={renderHeaders}
              dark={true}
              shadow
            >
              {listElem()}
            </GenericTableNew>

            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {counter.functionalReducer.total ? filters.page : ''}
                {' de '}
                {Math.ceil(counter.functionalReducer.total / filters.perpage)
                  ? Math.ceil(counter.functionalReducer.total / filters.perpage)
                  : ''}{' '}
                ({counter.functionalReducer.total} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={10}
                totalItemsCount={counter.functionalReducer.total}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TableFunctional;
