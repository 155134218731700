import { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import IndividualStyles from '../TableIndividualAuction/IndividualAuction_CCS.module.scss';

export const SlideDownButton = props => {
  const [changeRadius, setChangeRadius] = useState('20px');
  const [showOpt, setShowOpt] = useState({
    show: false,
    arrow: false,
  });
  const ShowOptions = () => {
    if (showOpt?.show && showOpt?.arrow) {
      setShowOpt({
        ...showOpt,
        show: false,
        arrow: false,
      });
    } else {
      setShowOpt({
        ...showOpt,
        show: true,
        arrow: true,
      });
    }
  };
  return (
    <>
      <div style={{ width: '100%' }}>
        <Accordion className={props?.accClass}>
          <Accordion.Toggle
            onClick={
              changeRadius === '20px' ? () => setChangeRadius('0px') : () => setChangeRadius('20px')
            }
            eventKey='0'
            className={props.dFlex ? 'd-block' : 'd-flex'}
            style={{
              // marginLeft: '90%',
              marginLeft: props.mLeft ? props.mLeft : '',
              color: '#005dbf',
              width: props.width,
              textAlign: props.textAlign ? props.textAlign : '',
              // flexDirection: props.direction,
              backgroundColor: props.colorBg ? props.colorBg : '#F2F7FC',
              borderRadius: `20px 20px ${changeRadius} ${changeRadius}`,
              padding: props?.paddingAcc,
              // padding: "10px",
            }}
          >
            <b className={props.widthText ? 'w-100' : ''}>
              {props.iconTitle && (
                <img src={props.iconTitle} className={props.iconTitleClass} alt='' />
              )}
              {props.iconAlter && (
                <img
                  onClick={() => ShowOptions()}
                  src={props.iconAlter}
                  className={IndividualStyles.proArrow}
                  alt=''
                />
              )}
              {props.title}
            </b>
            {props.iconAfter && (
              <div>
                <img
                  style={{ width: props.iconSize ? props.iconSize : '15px' }}
                  onClick={() => ShowOptions()}
                  src={props.iconAfter}
                  alt=''
                />
              </div>
            )}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body
              style={{
                backgroundColor: props.bgBody,
                width: props.bodyWidth ? props.bodyWidth : props.width,
                borderRadius: '0 0 20px 20px',
              }}
            >
              {props.body ? props.body : 'Body here!'}
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </>
  );
};
