import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getComplaints } from '../../actions/complaintsAction';
import { getSelectStatus } from '../../actions/contractAction';
import lupa from '../../assets/img/icons/auctionDetailLupa2.svg';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import SelectComponent from '../SelectComponent/SelectComponent';
import ReportComplaintDetail from './ReportComplaintDetail';
import { useHasPermissionByTagModule } from "../../Hooks";
function Complaint() {
  const counter = useSelector(state => state);
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'RepQue',
  );

  const dispatch = useDispatch();
  const [details, setDetails] = useState({ show: '' });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    type: undefined,
    state: '',
    search: '',
    entity_account: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    status: undefined,
  });

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    dispatch(getComplaints(filters));
    dispatch(getSelectStatus({ prefix: 'complaints' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (details.show === false) {
      dispatch(getComplaints(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.show]);
  useEffect(() => {
    dispatch(getComplaints(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);
  const handlePageChange = val => {
    setFilters({
      ...filters,
      page: val,
    });
  };
  const searchFilters = () => {
    setFilters({
      ...filters,
      page: 1,
    });
    dispatch(getComplaints(filters));
  };
  const header = [
    <th key={1} className='pl-1 col-1 text-start' style={{ paddingLeft: '10px' }}>
      Tipo
    </th>,
    <th key={2} className='col-3 text-start'>
      Reportante
    </th>,
    <th key={3} className='col-3 text-start'>
      Concepto
    </th>,
    <th key={4} className='col-2 text-start'>
      Área
    </th>,
    <th key={5} className='col-1 text-center'>
      Fecha
    </th>,
    <th key={6} className='col-1 text-center'>
      Estado
    </th>,
    <th key={7} className='col-1 text-center'>
      ㅤ{' '}
    </th>,
  ];
  const body = [];
  if (Array.isArray(counter?.complaintsReducer?.allReportsComplaints)) {
    counter.complaintsReducer.allReportsComplaints.forEach(item => {
      body.push(
        <tr>
          <td className='text-start col-1' style={{ paddingLeft: '10px' }}>
            {item?.type}
          </td>
          <td className='text-start col-3'>{item?.report}</td>
          <td className='text-start col-3'>{item?.concept}</td>
          <td className='text-start col-2'>{item?.area}</td>
          <td className='text-center col-1'>{item?.report_date}</td>
          <td className='text-center col-1'>
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: item.background,
              }}
            >
              <b style={{ color: item.fontcolor }}>{item.status}</b>
            </div>
          </td>

          <td className='text-center col-1'>
            {' '}
            <i>
              <img
                src={lupa}
                type='button'
                onClick={() =>
                  setDetails({ ...details, show: true, details: true, edit: false, info: item })
                }
                alt='complaint_search'
                className='hoverPointer'
                height='20px'
              />
            </i>
          </td>
        </tr>,
      );
    });
  }
  const optionStatus = [{ value: '', label: 'Seleccionar...', name: 'status' }];

  if (Array.isArray(counter.contractReducer.resultsSlectStatus)) {
    counter.contractReducer.resultsSlectStatus.forEach(item => {
      optionStatus.push({
        label: item.value,
        value: item.name,
        name: 'status',
      });
    });
  }
  const opntionType = [
    { value: '', label: 'Seleccionar...', name: 'status' },
    { value: 'complaint', label: 'Queja', name: 'status' },
    { value: 'report', label: 'Reporte', name: 'status' },
  ];
  const renderPrincipal = (
    <>
      {!!counter.agreementReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex'>
          <h1 className={tableStyles.title}>Reportes y quejas</h1>
        </div>
        {!!myPermission?.read && (
          <>
            <Row>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Tipo</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  key={'area'}
                  value={opntionType.filter(e => e.value === filters.type)}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      type: e.value,
                      page: 1,
                    });
                    dispatch(getComplaints({ ...filters, type: e.value, page: 1 }));
                  }}
                  options={opntionType}
                ></SelectComponent>
              </Col>
              <Col xs={2}>
                <p className={tableStyles.crudModalLabel}>Estado</p>
                <SelectComponent
                  styles={customSelectNewDark}
                  placeholder={'Seleccionar...'}
                  value={optionStatus.filter(e => e.value === filters.status)}
                  key={'state'}
                  onChange={e => {
                    setFilters({
                      ...filters,
                      status: e.value,
                      page: 1,
                    });
                    dispatch(getComplaints({ ...filters, status: e.value, page: 1 }));
                  }}
                  // noOptionsMessage={() => "No hay datos"}
                  options={optionStatus}
                ></SelectComponent>{' '}
              </Col>
              <Col xs={4} className='d-flex align-self-end gap-1'>
                <input
                  className={tableStyles.SearchNew}
                  value={filters.search}
                  placeholder='Buscar...'
                  type='text'
                  onChange={e => setFilters({ ...filters, search: e.target.value, page: 1 })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters();
                    }
                  }}
                ></input>
                <img
                  className='hoverPointer'
                  alt='iconSearch'
                  style={{}}
                  src={Search}
                  height='24px'
                  onClick={() => searchFilters()}
                />
              </Col>
              {/* <Col xs={5}>
          </Col> */}
            </Row>
            <GenericTableNew dark={true} headers={header}>
              {body}
            </GenericTableNew>
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(counter?.complaintsReducer?.totalReportsComplaints / filters.perpage)
                  ? Math.ceil(counter?.complaintsReducer?.totalReportsComplaints / filters.perpage)
                  : '1'}{' '}
                ({counter?.complaintsReducer?.totalReportsComplaints}
                {' encontrados'})
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={counter?.complaintsReducer?.totalReportsComplaints}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                itemClassPrev={`${paginationStyles.itemClassPrev} `}
                itemClassNext={`${paginationStyles.itemClassNext} `}
                itemClassFirst={`${paginationStyles.itemClassFirst} `}
                itemClassLast={`${paginationStyles.itemClassLast} `}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
  return details.show ? (
    <ReportComplaintDetail
      setDetails={setDetails}
      info={details.info}
      edit={details.edit}
      details={details.details}
      show={details.show}
    />
  ) : (
    renderPrincipal
  );
}
export default Complaint;
