import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as deepcopy from 'deepcopy';
import { Link } from 'react-router-dom';
import { FiSend } from 'react-icons/fi';

import UserIcon from '../../assets/img/icons/IconEmail.svg';
import ERP from '../../assets/img/icons/ERP.svg';
import SALUD from '../../assets/img/icons/Salud.svg';
import img from '../../assets/img/ForgotPwdImage.svg';

import { forgotPassword } from '../../actions/loginActions';
import { validateEmail } from '../../helpers/helpers';
import { responseSwal } from '../../helpers/responseSwal';

import './login-style.scss';
import '../../index.scss';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }
  componentDidMount() {
    const { history } = this.props;
    if (this.props.isAuth) {
      history.push('/inicio');
    }
  }

  onChange = e => {
    let name = e.target.name;
    const value = e.target.value;
    if (name === 'email') this.setState({ email: value });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isAuth !== this.props.isAuth) {
      const { history } = this.props;
      if (this.props.isAuth) {
        history.push('/inicio');
      }
    }
  }
  checkForgotPassword() {
    let { email } = this.state;
    let changeemail = deepcopy(email);
    if (!validateEmail(changeemail)) {
      return responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Correo no válido',
      });
    } else {
      this.props.forgotPassword(this.state);
    }
  }

  render() {
    return (
      <div style={{ height: '100vh', display: 'flex' }}>
        <div
          className='container-50'
          style={{ width: '50%', height: '100%', backgroundColor: '#F8FAFB' }}
        >
          <div className='container-form-login' id='div afuera'>
            <form id='formLogin' onSubmit={this.handleSubmit}>
              <div style={{ display: 'table-caption' }} className=''>
                <img src={ERP} alt='ERP' style={{ 'padding-left': '40px' }} />
              </div>
              <div style={{ display: 'table-caption' }} className='w-75 mx-auto mt-2 '>
                <img
                  src={SALUD}
                  alt='SALUD'
                  style={{ 'padding-left': '30px', 'margin-bottom': '20px' }}
                />
              </div>
              <div style={{ textAlign: 'start' }} className='w-75 mx-auto mt-2'>
                <p className='subtitle1'>¿Olvidó su contraseña?</p>
                <label className='subtitle2'>
                  Ingrese su correo electrónico y le enviaremos una contraseña provisional de
                  ingreso
                </label>
              </div>
              <div className='form-group-login'>
                <div
                  style={{ 'background-color': '#FFFF' }}
                  className='input-group mb-3 input-blue-border mt-3 w-75 mx-auto'
                >
                  <img
                    src={UserIcon}
                    alt='User icon'
                    className='input-email-icon input-group-prepend'
                  />
                  <input
                    type='email'
                    placeholder='CORREO ELECTRÓNICO'
                    name='email'
                    className='p-3 border-0 input-text-login  w-75'
                    style={{
                      'background-color': '#FFFF',
                      'outline-style': 'none',
                    }}
                    value={this.state.email}
                    onChange={this.onChange}
                    aria-describedby='basic-addon1'
                    aria-label='Usuario'
                  />
                </div>
              </div>
              <div
                style={{ display: 'flex', 'justify-content': 'space-between' }}
                className=' bg-transparent mx-auto w-75'
              >
                <div className=''>
                  <Link to='/login'>
                    <Button className='btnBack'>Atrás</Button>
                  </Link>
                </div>
                <Button
                  variant='primary'
                  className='btnSendFgtPwd d-flex'
                  style={{ alignItems: 'center', placeContent: 'center' }}
                  onClick={() => this.checkForgotPassword()}
                  disabled={!this.state.email}
                >
                  <FiSend style={{ marginRight: '10px' }} />
                  <div>Enviar</div>
                </Button>
              </div>
            </form>
          </div>
        </div>

        <div className='container-50 carousel-fade' style={{ width: '50%', height: '100%' }}>
          <img className='MarginImage' src={img} alt='forgotPwdImg'></img>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: account => {
    dispatch(forgotPassword(account));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
