import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FolderOutlined, InsertDriveFileOutlined, LinkOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
//
import IconBack from '../../../../assets/img/icons/iconBack.svg';
import { useGetMethod } from '../../../../Hooks';
import { loader } from '../../../../helpers';
//
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import './Details.scss';

export default function CompanyParamDetail() {
  const history = useHistory();
  const store = useSelector(store => store);

  const { id } = useParams();

  const {
    trigger: getParamsDetail,
    load: paramsDetailLoader,
    results: paramsDetail,
  } = useGetMethod();
  const {
    trigger: getParamsDocuments,
    load: paramsDocumentsLoader,
    results: detailDocuments,
  } = useGetMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getParamsDetail({
        url: '/receivable/companyParams/getDetails/',
        objFilters: {
          paramId: id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [id, store.loginReducer.Authorization, getParamsDetail]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getParamsDocuments({
        url: '/receivable/companyParams/getDocuments/',
        objFilters: {
          paramId: id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [id, store.loginReducer.Authorization, getParamsDocuments]);

  const splitStringWithVariables = (string = '') => {
    // Utilizar expresión regular para encontrar todas las variables entre llaves
    let partes = string.split(/\{([^}]+)\}/);

    // Inicializar un arreglo para almacenar las partes finales
    let resultado = [];

    // Iterar sobre las partes y agregarlas al resultado
    for (let i = 0; i < partes.length; i++) {
      // Incluso índices contienen texto normal
      if (i % 2 === 0) {
        resultado.push(partes[i]);
      } else {
        // Índices impares contienen variables entre llaves
        resultado.push('{' + partes[i] + '}');
      }
    }

    // Imprimir el resultado
    return resultado;
  };

  const render = () => {
    const detail = paramsDetail.results?.[0];
    const container = detailDocuments.results?.[0];

    return (
      <>
        {paramsDetailLoader && loader}
        {paramsDocumentsLoader && loader}

        <div className={tableStyles.tlnContainer}>
          <div className={tableStyles.tableArea}>
            <div className='d-flex align-items-center mt-5 mb-3'>
              <img
                onClick={() => history.goBack()}
                src={IconBack}
                className='pointer me-3'
                alt=''
              />
              <h1 className='m-0'>Detalle parametrización</h1>
            </div>

            <div className='p-4'>
              {Boolean(detail) && (
                <>
                  <h5 className='text-primary fw-bold mt-3'>Ficha técnica</h5>
                  <div
                    className='card p-3 my-4 border-0 shadow'
                    style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
                  >
                    <div className='row align-items-end'>
                      <div className='col-lg-3'>
                        <small className='text-primary'>Empresa cliente</small>
                        <p className='text-muted'>{detail.companyName}</p>
                      </div>

                      <div className='col-lg-3'>
                        <small className='text-primary'>NIT</small>
                        <p className='text-muted'>{detail.companyNit}</p>
                      </div>
                      <div className='col-lg-3'>
                        <small className='text-primary'>Contrato</small>
                        <p className='text-muted' style={{ whiteSpace: 'nowrap' }}>
                          {detail.contractName}
                        </p>
                      </div>
                      <div className='col'></div>
                    </div>
                    <div className='row align-items-end'>
                      <div className='col-lg-3'>
                        <small className='text-primary'>Tipo de radicación</small>
                        <p className='text-muted'>{detail.filingType}</p>
                      </div>
                      <div className='col-lg-3'>
                        <small className='text-primary'>Día máximo de radicación</small>
                        <p className='text-muted'>{detail.maxDayFiling} de cada mes</p>
                      </div>
                      <div className='col-lg-3'>
                        <small className='text-primary'>Modalidad de radicación</small>
                        <p className='text-muted'>{detail.modality}</p>
                      </div>
                      <div className='col'>
                        {detail.groupedBy && (
                          <>
                            <small className='text-muted'>Agrupado por</small>
                            <p className='text-primary'>{detail.groupedBy}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {detail.serviceTypes && (
                    <>
                      <h5 className='text-primary fw-bold mt-3'>
                        Documentos por tipos de servicios
                      </h5>

                      <div className='row g-3'>
                        {detail.serviceTypes.map((el, key) => (
                          <div key={key} className='col-lg-6'>
                            <small className='text-primary'>{el.serviceName}</small>
                            <div className='service-chips-wrapper'>
                              {el.files &&
                                el.files.map(val => (
                                  <small key={val.id} className='service-chips'>
                                    {val.value}
                                  </small>
                                ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}

              {Boolean(container) && (
                <>
                  <h5 className='text-primary fw-bold mt-4'>Parametrización carpetas</h5>

                  <div
                    className='card p-3 my-4 border-0 shadow'
                    style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
                  >
                    <div className='fs-5 mb-3 d-flex align-items-center text-primary fw-bold mt-3'>
                      <FolderOutlined />
                      <span className='ms-2'>Carpeta contenedora</span>
                    </div>

                    <div className='d-flex align-items-center justify-content-start'>
                      <span className='text-primary'>Nombre:</span>
                      &nbsp;
                      {splitStringWithVariables(container.containerRefName || '').map(name => {
                        if (name.includes('{') || name.includes('}')) {
                          return (
                            <div key={name} className={tableStyles.inputVariable}>
                              {name.replace(/[{}]/g, '')}
                            </div>
                          );
                        }
                        return <span key={name}>{name}</span>;
                      })}
                    </div>
                  </div>

                  {container.children.map((item, index) => (
                    <div
                      key={item.id + `-${index}`}
                      className='card p-3 my-4 border-0 shadow'
                      style={{ borderRadius: 10 }}
                    >
                      <div className='text-primary fs-5 mb-3 d-flex align-items-center fw-bold mt-3'>
                        {item.type === 'folder' && (
                          <>
                            <FolderOutlined />
                            <span className='ms-2'>Carpeta</span>
                          </>
                        )}
                        {item.type === 'file' && (
                          <>
                            <InsertDriveFileOutlined />
                            <span className='ms-2'>Documento</span>
                          </>
                        )}
                      </div>

                      {item.type === 'folder' && (
                        <>
                          <div className='d-flex align-items-center justify-content-start'>
                            <span className='text-primary'>Nombre:</span>
                            &nbsp;
                            {splitStringWithVariables(item.refName || '').map(name => {
                              if (name.includes('{') || name.includes('}')) {
                                return (
                                  <div key={name} className={tableStyles.inputVariable}>
                                    {name.replace(/[{}]/g, '')}
                                  </div>
                                );
                              }
                              return <span key={name}>{name}</span>;
                            })}
                          </div>
                          <hr />
                          <h6 className='text-primary fw-bold'>Documentos</h6>
                          {item.files.map((file, index) => (
                            <div
                              className='d-flex align-items-center mt-2'
                              key={file.id + `-${index}`}
                            >
                              <div className='d-flex align-items-center' style={{ width: '30%' }}>
                                <LinkOutlined className='text-muted' />
                                <span
                                  className='text-primary ms-2'
                                  style={{ whiteSpace: 'nowrap' }}
                                >
                                  {file.document}
                                </span>
                              </div>
                              <div className='d-flex align-items-center justify-content-start'>
                                <span className='text-primary'>Nombre:</span>
                                &nbsp;
                                {splitStringWithVariables(file.refName || '').map(name => {
                                  if (name.includes('{') || name.includes('}')) {
                                    return (
                                      <div key={name} className={tableStyles.inputVariable}>
                                        {name.replace(/[{}]/g, '')}
                                      </div>
                                    );
                                  }
                                  return <span key={name}>{name}</span>;
                                })}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {item.type === 'file' && (
                        <>
                          <div className='d-flex align-items-center mt-2'>
                            <div className='d-flex align-items-center' style={{ width: '30%' }}>
                              <LinkOutlined className='text-muted' />
                              <span className='text-primary ms-2' style={{ whiteSpace: 'nowrap' }}>
                                {item.document}
                              </span>
                            </div>
                            <div className='d-flex align-items-center justify-content-start'>
                              <span className='text-primary'>Nombre:</span>
                              &nbsp;
                              {splitStringWithVariables(item.refName || '').map(name => {
                                if (name.includes('{') || name.includes('}')) {
                                  return (
                                    <div key={name} className={tableStyles.inputVariable}>
                                      {name.replace(/[{}]/g, '')}
                                    </div>
                                  );
                                }
                                return <span key={name}>{name}</span>;
                              })}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return render();
}
