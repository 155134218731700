import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Radio } from '@material-ui/core';

import add from '../assets/img/icons/addFile.svg';
import icon from '../assets/img/icons/bioWel.svg';

import { convertBase64, message, today } from '../helpers/helpers';

import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { appBlueSelect } from '../components/Layouts/react-select-custom';

import tableStyles from '../components/Layouts/tableStyle.module.scss';
import styles from '../components/Header/headerStyle.module.scss';

const BillingForm = () => {
  const [data, setData] = useState({
    nit: '',
    additionalCode: '',
    ciuuCode: '',
    creationDate: today(),
    businessName: '',
    countryId: '',
    providenceId: '',
    cityId: '',
    address: '',
    email: '',
    phoneNumber: '',
    cellphoneNumber: '',
    comments: '',
    certificationDocument: '',
    rutDocument: '',
    dataProcessingPolicy: '',
  });

  const [fields, setFields] = useState({
    idAPP: '',
    transacCode: '',
    bank: '',
    file: '',
    typeFile: '',
    idUser: '',
    fileName: '',
  });

  const onChangeDoc = async e => {
    var f = e.target.files[0];
    if (f) {
      var name_doc = f.name;
      var ext = f.name.split('.')[1];
      var type_doc = f.type;
      if (ext !== 'pdf' && ext !== 'zip' && ext !== 'rar') {
        return message(
          'error',
          'El formato del archivo debe ser PDF o ZIP',
          'El tamaño máximo permitido es de 5MB',
        );
      }
      let encode = await convertBase64(e.target.files[0]);
      let file64 = encode.split(',')[1];
      setFields({
        ...fields,
        fileName: name_doc,
        file: file64,
        typeFile: type_doc.split('/')[1],
      });
    }
  };
  return (
    <>
      <div
        className={`${styles.headerContainer} d-flex  `}
        style={{ borderBottom: '4px solid #00B4CC', height: '60px', backgroundColor: '#fff' }}
      >
        <img alt='icon' src={icon} height='40px' className='mx-3  align-self-center' />
      </div>
      <OrdGenericTemplate
        className='w-90 my-4 '
        showBottomBorder={false}
        title={'Formulario para facturación persona jurídica'}
        titleSize={12}
        titleClass={'mx-5'}
      >
        <div className='w-90 mx-5 my-3'>
          <Col xs={5} className={` my-3`}>
            <div className='w-100 p-2 d-flex align-items-center '>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f15} d-flex flex-column w-100`}
              >
                <b> &nbsp;•&nbsp;Información General</b>
              </Form.Label>
            </div>
          </Col>

          <div className={`${tableStyles.ordClearBlueCard} `}>
            <Row className={`p-4 `}>
              <Col xs={4} className='d-flex'>
                <Col xs={10}>
                  <Form.Group className='mb-3 text-start mr-3' controlId='fName'>
                    <Form.Label
                      className={`m-0 ${tableStyles.ordAquaMarineText} ${tableStyles.f12}`}
                    >
                      <span>
                        {' '}
                        &nbsp;NIT{' '}
                        <span className={`${tableStyles.ordOrangeText}`}>
                          <span className={`${tableStyles.ordOrangeText}`}>*</span>
                        </span>
                      </span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.appBillingInput}`}
                      type='text'
                      placeholder='Escribe...'
                      value={data.nit}
                      onChange={e => {
                        setData({ ...data, nit: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Form.Group className='mb-3 text-start ml-2' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f12}`}>
                      <span> &nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.appBillingInput}`}
                      type='text'
                      placeholder=''
                      value={data.additionalCode}
                      onChange={e => {
                        setData({ ...data, additionalCode: e.target.value });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Col>

              <Col xs={8}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Razón social
                      <span className={`${tableStyles.ordOrangeText}`}>
                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                      </span>
                    </span>
                  </Form.Label>

                  <Form.Control
                    className={`ord-roundInput ${tableStyles.appBillingInput}
                    `}
                    type='text'
                    placeholder='Escribe...'
                    value={data.businessName}
                    onChange={e => {
                      setData({ ...data, businessName: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={4}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>
                    <span> &nbsp;Fecha de creación</span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.appBillingInputDate}
                    `}
                    type='date'
                    placeholder=''
                    value={data.creationDate}
                    onChange={e => {
                      setData({ ...data, creationDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={8}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Código CIIU y actividad económica
                      <span className={`${tableStyles.ordOrangeText}`}>
                        <span className={`${tableStyles.ordOrangeText}`}>*</span>
                      </span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={appBlueSelect}
                    onChange={e => {
                      setData({ ...data, ciuuCode: e.target.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className='w-100 p-2 d-flex align-items-center '>
            <Form.Label
              className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f15} d-flex w-100`}
            >
              <b> &nbsp;•&nbsp;Oficina Principal</b>
            </Form.Label>
          </div>
          <div className={``}>
            <Row className={`pb-4 pt-2`}>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;País<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={appBlueSelect}
                    onChange={e => {
                      setData({ ...data, countryId: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Departamento<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={appBlueSelect}
                    onChange={e => {
                      setData({ ...data, countryId: e.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Ciudad<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={appBlueSelect}
                    onChange={e => {
                      setData({ ...data, countryId: e.value });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Dirección<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.appBillingInput}`}
                    type='text'
                    placeholder='Escribe...'
                    value={data.finalValue}
                    onChange={e => {
                      setData({ ...data, finalValue: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Correo Electrónico
                      <span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput  ${tableStyles.appBillingInput}
 `}
                    type='text'
                    placeholder='Escribe...'
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Teléfono<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.appBillingInput}`}
                    type='text'
                    placeholder='Escribe...'
                    value={data.finalValue}
                    onChange={e => {
                      setData({ ...data, finalValue: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray}  ${tableStyles.f12}`}>
                    <span>
                      {' '}
                      &nbsp;Celular<span className={`${tableStyles.ordOrangeText}`}>*</span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput  ${tableStyles.appBillingInput}`}
                    type='text'
                    placeholder='Escribe...'
                  />
                </Form.Group>
              </Col>
              <div>
                <Form.Group className='mb-3' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.tlnTextGray} ${tableStyles.f12}`}>
                    <span className='text-start'>&nbsp;Comentarios</span>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    className={`  ${tableStyles.appBillingInput} `}
                    type='text'
                    placeholder='Escribe...'
                    value={data.srvPrice}
                    onChange={e => {
                      setData({ ...data, startDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </div>
            </Row>
          </div>

          <Row>
            <div className=''>
              <Col xs={8} className='d-flex'>
                <Col xs={1} className='d-flex'>
                  <Radio
                    checked={data.certificationDocument === 'name' ? false : true}
                    onClick={() => {
                      setData({ ...data, certificationDocument: 'creation' });
                    }}
                    name='alphabetic'
                    className={'text-info'}
                    id='alphabetic'
                  />
                </Col>

                <Col xs={6} className='d-flex'>
                  <span
                    className={`${tableStyles.tlnTextGray} ${tableStyles.f12} align-self-center `}
                  >
                    Certificado de Cámara de Comercio no mayor a 90 días
                  </span>
                </Col>

                <Col xs={4} className='align-self-center'>
                  <Form.Group
                    className='text-start d-flex align-self-center px-2'
                    controlId='fName'
                  >
                    <label
                      htmlFor='furatFile'
                      className={`bg-transparent align-self-center w-100 ${tableStyles.appBillingInputFile} ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f12} `}
                      style={{
                        borderRadius: '5px',
                        border: '1px solid #a3bad1',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '10px',
                        padding: '0.25rem 1rem',
                        overflow: 'hidden',
                        width: '172px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {fields?.fileName !== '' ? fields?.fileName : 'Seleccionar...'}
                      <input
                        id='furatFile'
                        type='file'
                        name='furat'
                        accept='application/pdf'
                        className={`${tableStyles.appBillingInput} px-4 d-none w-100`}
                        placeholder='Cargar documento'
                        onChange={e => onChangeDoc(e)}
                      />

                      <img
                        src={add}
                        alt='agregar archivo'
                        width='14'
                        style={{ marginLeft: '4rem' }}
                      />
                    </label>
                  </Form.Group>
                </Col>
              </Col>
              <Col xs={8} className='d-flex my-2'>
                <Col xs={1} className='d-flex '>
                  <Radio
                    checked={data.certificationDocument === 'name' ? false : true}
                    onClick={() => {
                      setData({ ...data, certificationDocument: 'creation' });
                    }}
                    name='alphabetic'
                    className={'text-info'}
                    id='alphabetic'
                  />
                </Col>

                <Col xs={6} className='d-flex'>
                  <span
                    className={`${tableStyles.tlnTextGray} ${tableStyles.f12} align-self-center `}
                  >
                    Fotocopia de RUT
                  </span>
                </Col>

                <Col xs={4} className='align-self-center'>
                  <Form.Group className='text-start align-self-center px-2' controlId='fName'>
                    <label
                      htmlFor='furatFile'
                      className={`bg-transparent w-100 ${tableStyles.appBillingInputFile} ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f12} `}
                      style={{
                        borderRadius: '5px',
                        border: '1px solid #A3E4EC',
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingRight: '10px',
                        padding: '0.25rem 1rem',
                        overflow: 'hidden',
                        width: '172px',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {fields?.fileName !== '' ? fields?.fileName : 'Seleccionar...'}
                      <input
                        id='furatFile'
                        type='file'
                        name='furat'
                        accept='application/pdf'
                        className={`${tableStyles.appBillingInput} px-4 d-none w-100`}
                        placeholder='Cargar documento'
                        onChange={e => onChangeDoc(e)}
                      />

                      <img
                        src={add}
                        alt='agregar archivo'
                        width='14'
                        style={{ marginLeft: '4rem' }}
                      />
                    </label>
                  </Form.Group>
                </Col>
              </Col>
            </div>
          </Row>
          <div
            className={`${tableStyles.ordBillingBgBlue} ${tableStyles.tlnTextGray} d-flex justify-content-center py-4 my-4`}
          >
            <input
              type='checkbox'
              name='handleTelemedicine'
              className={` ${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
              checked={data.allowExtraQuota}
            />
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}  mx-2`}>
              He leído y estoy de acuerdo con la{' '}
              <b className={`${tableStyles.globalUnderline}`}>política de tratamiento de datos</b>{' '}
            </span>
          </div>

          <div className='d-flex w-full justify-content-end'>
            <div className='mx-4'>
              <button className={`${tableStyles.ordBtnSecondary} p-2`}>Cancelar </button>
            </div>
            <div>
              <button className={`${tableStyles.ordBtnPrimary}  p-2`}>Enviar</button>
            </div>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default BillingForm;
