import { useState } from 'react';
import { Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import '../../OrderingModule/orderingStyles.css';
import ordBackArrow from '../../assets/img/icons/ordBackArrow.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { isEmptyOrUndefined } from '../../helpers/helpers';
export const MultiTabComponent = props => {
  let { tabs, className, spaceLeft, noFiller, title, goBack } = props;
  const [profile] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className={`simpleTabs__Container m-0 ${className}`}>
      {!isEmptyOrUndefined(title) && (
        <div className={`${tableStyles.app_multitab_background}`}>
          <Col className={`${tableStyles.ordBgAquaMBlueOpacity}`} xs={2}></Col>
          <Col className={``} xs={12}>
            <h2 className={`${tableStyles.ordDarkBlueText}`}>
              <img
                onClick={() => goBack() ?? null}
                src={ordBackArrow}
                className={`mr-2 mb-2 cursorPointer`}
                alt='Atrás'
              ></img>
              {title}
            </h2>
          </Col>
        </div>
      )}
      <div className='simpleTabs__Container m-0'>
        <TabContainer defaultActiveKey={profile}>
          <Nav className='flex-row '>
            {/* /* ------------------------------ Spacing left ------------------------------ */}
            {!isEmptyOrUndefined(spaceLeft) && (
              <Col
                xs={spaceLeft}
                className={` ${tableStyles.app_multitab_background}`}
                style={{
                  borderBottom: '1px solid #cecece',
                }}
              ></Col>
            )}
            {tabs?.length > 0 &&
              tabs?.map(tab => {
                return (
                  <Nav.Item className={`${tableStyles.app_multitab_background}`} key={tab.id}>
                    <Nav.Link
                      onClick={() => {
                        tab?.onFunction();
                        setActiveTab(tab.id);
                      }}
                      // className={`${activeTab === tab.id && tableStyles.app_simpleTabsActive } ${tableStyles.ordDarkBlueText}`}
                      className={`${activeTab === tab?.id ? `bg-white ${tableStyles.app_simpleTabsActive}` : tableStyles.app_simpleTabs} text-center  ${tableStyles.app_multitab_title}`}
                      style={{ width: '170px' }}
                      eventKey={tab.id}
                    >
                      {tab.tabName}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            {!noFiller && (
              <div
                style={{ flex: 1 }}
                className={`${tableStyles.app_multitab_sin_background} `}
              ></div>
            )}
          </Nav>
          <TabContent className='simpleTabs__relative' style={{ overflowY: 'unset' }}>
            {tabs?.length > 0 &&
              tabs.map(tab => {
                return (
                  <TabPane key={tab?.id} eventKey={tab?.id}>
                    {tab?.tabContent}
                  </TabPane>
                );
              })}
          </TabContent>
        </TabContainer>
      </div>
    </div>
  );
};

/* --------------------------- HOW TO USE EXAMPLE: -------------------------- */
// eslint-disable-next-line no-lone-blocks
{
  /* <MultiTabComponent
tabs={[
    {
        id: 1,
        tabName: 'Medicamentos',
        tabContent: <div className="bg-danger" >Holaasdafsdf </div>
    },
    {
        id: 2,
        tabName: 'Dos',
        tabContent: "Content 2"
    },
    {
        id: 3,
        tabName: 'tres',
        tabContent: "Content 3"
    },
]}
className={`pl-4`}
spaceLeft={1}
noFiller={true}
title={'Post'}
goBack={() => alert('goback')}
></MultiTabComponent> */
}
