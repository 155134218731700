import styled from 'styled-components';
import { Input, Text } from '../../atoms';

const StyledBoxInputText = styled.div`
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  width: ${({ width }) => (width ? width : '296px')};
  display: flex;
  flex-direction: column;
`;

export const BoxInputText = ({
  width,
  padding,
  margin,
  titleText,
  valueInput,
  handleChangeInput,
  nameInput,
  placeholder,
  widthInput,
}) => {
  return (
    <StyledBoxInputText padding={padding} margin={margin} width={width}>
      <Text title={titleText} margin='0px 0px 0px 2px' />

      <Input
        checked={valueInput}
        onChange={handleChangeInput}
        name={nameInput}
        placeholder={placeholder}
        width={widthInput}
      />
    </StyledBoxInputText>
  );
};
