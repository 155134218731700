import { useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import downArrowAgua from '../../assets/img/icons/downArrowAgua.svg';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import ordComponentStyles from './ordComponentStyles.module.scss';

export const OrdAccordion = props => {
  const [active, setActive] = useState(false);

  return (
    <>
      <Accordion key={props.key} className={`w-100 ${ordComponentStyles.ordAccordion} mb-4`}>
        <Accordion.Toggle
          eventKey={props.key ? props.key : 1}
          className={`w-100 accordionToggleButton  `}
        >
          <Card.Body>
            <Row
              onClick={() => setActive(!active)}
              className={`d-flex justify-content-center`}
              style={{ alignItems: 'center' }}
            >
              <Col xs={5} className='d-flex justify-content-start'>
                <span className={`text-secondary d-flex align-items-center`}>
                  {props.iconArrow && active && (
                    <img className='m-3' src={downArrowAgua} alt='arrow' />
                  )}

                  {props.iconArrow && !active && (
                    <img
                      className='m-3'
                      style={{ rotate: '270deg' }}
                      src={downArrowAgua}
                      alt='arrow'
                    />
                  )}

                  {!props.iconArrow && (
                    <code
                      className={`m-3 accordionToggleButton ${props.colorTitle ? props.colorTitle : 'text-secondary'}`}
                    >
                      {`>`}
                    </code>
                  )}

                  <b className={props.colorTitle}>{props.title}</b>
                </span>
              </Col>
              <Col xs={4} className='d-flex justify-content-start'>
                {props?.subtitle && (
                  <span className={`text-secondary`}>
                    <b>{props.subtitle} :</b>
                    <span className={`text-secondary`}> {props.subtitleVal}</span>
                  </span>
                )}
              </Col>
              <Col className='d-flex justify-content-end' xs={2}>
                <span className={`text-secondary`}>
                  <b
                    style={{
                      color: props?.fontcolor,
                      backgroundColor: props?.backgroundColor,
                      borderRadius: '10px',
                      padding: '2px 22px',
                    }}
                  >
                    {props?.status}
                  </b>
                </span>
              </Col>
              <Col xs={1}>
                {props.triggerSrc && (
                  <CustomPopupExtend
                    showEnable={true}
                    isEnabled={props?.isEnabled}
                    triggerSrc={props.triggerSrc && props.triggerSrc}
                    enableClickEvent={() => props?.enableClickEvent()}
                    showEdit={true}
                    editText='Editar'
                    editClickEvent={() => props?.editClickEvent()}
                  ></CustomPopupExtend>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey={props.key ? props.key : 1}>
          <Card.Body className={props.carBodyClass ? props.carBodyClass : ''}>
            <Row>
              <Col xs={12} style={{ paddingLeft: props.paddingLeft ? props.paddingLeft : '3rem' }}>
                {props?.body}
                {props?.footer}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};
