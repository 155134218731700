const validInputTypeNumber = ({ name, value, min, max, messageMin, messageMax }, { setFormError, formError }) => {

    if (value.trim().length < min) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMin, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
            }
        })
        return
    }

    if (value.trim().length > max) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMax, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
            }
        })
        return
    }

    const filter = formError
    delete filter[name]
    setFormError(filter)
}

const validInputTypeText = ({ name, value, min, max, messageMin, messageFirstLetter, messageMax }, { setFormError, formError }) => {

    if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(value[0])) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageFirstLetter, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageFirstLetter}</span>
            }
        })
        return
    }

    if (value.trim().length < min) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMin, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
            }
        })
        return
    }

    if (value.trim().length > max) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMax, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
            }
        })
        return
    }

    const filter = formError
    delete filter[name]
    setFormError(filter)

}

const validInputTypeDate = ({ name, value, maxDate, message, messageMax }, { setFormError, formError }) => {


    if (value.trim() === '') {
        setFormError({
            ...formError,
            [name]: {
                messageError: message, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{message}</span>
            }
        })
        return

    }

    if (value.trim() >= maxDate) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMax, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
            }
        })
        return

    }

    const filter = formError
    delete filter[name]
    setFormError(filter)

}

const validInputTypeEmail = ({ name, value, min, max, messageMin, messageInvalidEmail, messageMax }, { setFormError, formError }) => {
    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (value.trim().length < min) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMin, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMin}</span>
            }
        })
        return
    }

    if (value.trim().length > max) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageMax, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageMax}</span>
            }
        })
        return
    }

    if (!validEmail.test(value)) {
        setFormError({
            ...formError,
            [name]: {
                messageError: messageInvalidEmail, className: 'border border-danger outlineDanger',
                component: <span style={{ fontSize: '13px' }} className="animate__animated animate__fadeIn text-danger">{messageInvalidEmail}</span>
            }
        })
        return
    }


    const filter = formError
    delete filter[name]
    setFormError(filter)

}

export const handleChangeValidation = (objValidation, objSet) => {
    switch (objValidation.type) {
        case 'number':
            validInputTypeNumber(objValidation, objSet)
            break;
        case 'text':
            validInputTypeText(objValidation, objSet)
            break;
        case 'date':
            validInputTypeDate(objValidation, objSet)
            break;
        case 'email':
            validInputTypeEmail(objValidation, objSet)
            break;
        // case 'check':
        //     validInputTypeCheck(objValidation)
        //     break;
        // case 'radio':
        //     validInputTypeRadio(objValidation)
        //     break;
        default:
            new Error('type no validado')
            break;
    }
}


