import { Box } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export const CkEditorConsent = ({ data, onChange, titleText }) => {
    return (
        <Box className="mb-4">
            <div className={`${tableStyles.darkerBlueText} fw-light`}>
                &nbsp;{titleText}
            </div>
            <CKEditor
                editor={ClassicEditor}
                onChange={onChange}
                data={data}
            />
        </Box>
    )
}