import {
  INDIVIDUALAUCTION_GET_ONE,
  INDIVIDUALAUCTION_GET_ONE_LOADING,
  PURCHASEORDER_GET_ONE,
  PURCHASEORDER_GET_ONE_LOADING,
  INDIVIDUALAUCTION_FILTER,
  INDIVIDUALAUCTION_ARTICLE,
  QUOTATION_FILTER,
  QUOTATION_FILTER_LOADING,
  CURRENTAUCTION,
  AUTHORIZE_PURCHASE_ORDER,
  INDIVIDUALAUCTION_FILTER_LOADING,
  UPDATE_INDAUCTION_LOADING,
  CREATE_INDAUCTION_LOADING,
} from './actionTypes';

import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import IndividualStyles from '../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { message } from '../helpers/helpers';
import { responseSwal } from '../helpers/responseSwal';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const creat_indauction = (data, executeThisAfter) => (dispatch, getState) => {
  dispatch({
    type: CREATE_INDAUCTION_LOADING,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;

  customSwaltAlert({
    icon: 'warning',
    showCancelButton: true,
    title: '<span style=color:#01A0F6>¿Está seguro?<span>',
    text: 'Esta acción es irreversible',
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Si, Enviar',
    focusConfirm: false,
    customClass: {
      confirmButton: `${IndividualStyles.btnPrimary}`,
      cancelButton: `${IndividualStyles.btnSecondary}`,
    },
  }).then(result => {
    if (result.isConfirmed) {
      fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            dispatch({
              type: CREATE_INDAUCTION_LOADING,
              loading: false,
            });
            customSwaltAlert({
              icon: 'success',
              title: '<span style=color:#01A0F6>Subasta enviada<span>',
              html: (
                <p>
                  {res.description} <br></br> {res.auction_number}
                </p>
              ),
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          } else {
            dispatch({
              type: CREATE_INDAUCTION_LOADING,
              loading: false,
            });
            customSwaltAlert({
              showCancelButton: false,
              icon: 'error',
              title: 'Error',
              text: `${res.message}`,
            });
          }
        })
        .then(() => {
          if (executeThisAfter) {
            executeThisAfter();
          }
        })
        .catch(err => {
          dispatch({
            type: CREATE_INDAUCTION_LOADING,
            loading: false,
          });
          console.error(err);
          customSwaltAlert({
            showCancelButton: false,
            icon: 'error',
            title: 'Error al crear',
            text: 'No se pudo crear la subasta',
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
        });
    }
  });
};

export const indauction_articles_filter = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/articles/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: INDIVIDUALAUCTION_ARTICLE,
        results: results,
        row_total: row_total,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const current_auction_filter =
  (objFilters, doafter = null) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/current/?${filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { results, row_total } = res;
        if (doafter) {
          doafter();
        }
        dispatch({
          type: CURRENTAUCTION,
          results: results,
          row_total: row_total,
        });
      })
      .catch(err => {
        if (doafter) {
          doafter();
        }
        console.error(err);
      });
  };
export const aprove_purchase_order_filter = (objFilters, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/purchaseOrder/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      if (results) {
        dispatch({
          type: AUTHORIZE_PURCHASE_ORDER,
          results: results,
          row_total: row_total,
        });
      } else {
        responseSwal({ icon: 'error', title: 'Error', text: res.message });
      }
    })
    .catch(err => {
      console.error(err);
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error al traer las ordenes de compra',
      });
    })
    .then(() => {
      if (doAfter) doAfter();
    });
};

export const indauction_filter = (objFilters, doafter) => (dispatch, getState) => {
  dispatch({
    type: INDIVIDUALAUCTION_FILTER_LOADING,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      if (doafter) {
        doafter();
      }
      dispatch({
        type: INDIVIDUALAUCTION_FILTER,
        results: results,
        row_total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      if (doafter) {
        doafter();
      }
      dispatch({
        type: INDIVIDUALAUCTION_FILTER_LOADING,
        loading: false,
      });
    });
};

export const indauction_get_one = id => (dispatch, getState) => {
  dispatch({
    type: INDIVIDUALAUCTION_GET_ONE_LOADING,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: INDIVIDUALAUCTION_GET_ONE,
        res: res,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: INDIVIDUALAUCTION_GET_ONE_LOADING,
        loading: false,
      });
    });
};

export const purchase_order_get_one = id => (dispatch, getState) => {
  dispatch({
    type: PURCHASEORDER_GET_ONE_LOADING,
    loadingP: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: PURCHASEORDER_GET_ONE,
        res: res,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: PURCHASEORDER_GET_ONE_LOADING,
        loading: false,
      });
    });
};

export const update_indauc =
  (data, executeThisAfter, title = 'Subasta enviada', loading = null) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({
      type: UPDATE_INDAUCTION_LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          dispatch({
            type: UPDATE_INDAUCTION_LOADING,
            loading: false,
          });
          customSwaltAlert({
            icon: 'success',
            title: `<span style=color:#01A0F6>${title}<span>`,
            html: (
              <p>
                {res.description} <br /> ID #{res.auction_number}
              </p>
            ),
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(() => {
            if (executeThisAfter) {
              executeThisAfter();
            }
          });
        } else {
          dispatch({
            type: UPDATE_INDAUCTION_LOADING,
            loading: false,
          });
          customSwaltAlert({
            icon: 'error',
            title: 'Error',
            text: `${res.message}`,
            showCancelButton: false,
          });
        }
        if (loading) {
          loading();
        }
      })
      .catch(err => {
        console.error(err);
        customSwaltAlert({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'No se pudo actualizar la subasta',
          footer: 'Si el problema persiste comuníquese con un asesor.',
          showCancelButton: false,
        });
        if (loading) {
          loading();
        }
      });
  };

export const quotation_filter = objFilters => (dispatch, getState) => {
  dispatch({
    type: QUOTATION_FILTER_LOADING,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/quotation/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: QUOTATION_FILTER,
        res: results,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: QUOTATION_FILTER_LOADING,
        loading: false,
      });
    });
};

export const reject_indauc = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/individualAuction/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'error',
          title: 'Error',
          text: `${res.message}`,
          showCancelButton: false,
        });
      }
    })
    .catch(err => {
      console.error(err);
      customSwaltAlert({
        icon: 'error',
        title: 'Error al actualizar',
        text: 'No se pudo actualizar la subasta',
        footer: 'Si el problema persiste comuníquese con un asesor.',
        showCancelButton: false,
      });
    });
};

export const getPdfTest = async (data, token) => {
  let filters = convertFilterToString(data);
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/treasury/pdf_doc/?${filters}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    return query.json();
  } catch (error) {
    console.error(error.message);
    return message('error', 'Error', 'No ha sido posible cargar el documento');
  }
};
