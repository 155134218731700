import {
  GET_BALANCE_IN_ACCOUNT,
  GET_BUSSINESS_WALLET,
  GET_CONSUM,
  GET_DEBT_TOTAL_ACC,
  GET_INCOME_VS_OUTCOME,
  GET_INVENTORY_ROTATION,
  GET_PROVISIONED_VS_COLLECTED,
  GET_PROVISIONED_VS_COLLECTED_BY_CLIENT,
  GET_RADICATIONS,
  GET_TOP_CLIENTS,
  GET_TOTAL_ACC,
  GET_TOTAL_ACC_TO_PAY,
  GET_UNFILED_INVOICES,
  WALLET_BY_AGES,
  GET_TOP_PROVIDERS,
  GET_CURRICULUM_ADVANCES,
  GET_ABSENTEEISM,
  GET_TOTAL_INVESTMENT,
  GET_ACC_TO_PAY_BY_PROV,
  GET_PROBLEMS_IN_ENTRY_WH,
  ACCOUNTS_RECEIVABLE,
  GET_DETAIL_OF_BALANCES,
  GET_FREE_AND_CAPITA,
  GET_GLOSSES_DETAIL,
  GET_BILLING_BY_TYPE,
  GET_PROFIT_BALANCE,
  DISTRIBUTION_BANK_BALANCE,
  EXPIRED_SUPPLIES,
  CPX_ROTATION,
  PERIOD_EXPENSES,
} from "../actions/actionTypes";

const initialState = {
  radicationList: [],
  unfiledList: [],
  bussinnessWalletList: [],
  consum: {},
  totalAcc: 0,
  totalDebtAcc: 0,
  provisionedVsCollected: {},
  provisionedVsCollectedByClient: {},
  walletByAges: {},
  inventoryRotation: {},
  topClients: {},
  balanceInAccount: {},
  incomeVsOutcome: {},
  totalAccToPay: {},
  topProviders: {},
  curriculumAdvances: {},
  absenteeism: {},
  totalInvestment: {},
  accToPayByProv: {},
  entryWhProblems: {},
  receivableList: [],
  receivableCategories: [],
  detailBalance: [],
  capitaAndFreeList: {},
  glossesDetail: {},
  billingByType: {},
  profitBalance: {},
  distributionBankBalance: [],
  expiredSuppliesList: [],
  cpxRotationList: [],
  periodExpensesList: [],
};
export const managementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RADICATIONS:
      return {
        ...state,
        radicationList: action.payload,
      };
    case GET_UNFILED_INVOICES:
      return {
        ...state,
        unfiledList: action.payload,
      };
    case GET_BUSSINESS_WALLET:
      return {
        ...state,
        bussinnessWalletList: action.payload,
      };
    case GET_CONSUM:
      return {
        ...state,
        consum: action.payload,
      };
    case GET_TOTAL_ACC:
      return {
        ...state,
        totalAcc: action.payload,
      };
    case GET_DEBT_TOTAL_ACC:
      return {
        ...state,
        totalDebtAcc: action.payload,
      };
    case GET_PROVISIONED_VS_COLLECTED:
      return {
        ...state,
        provisionedVsCollected: action.payload,
      };
    case GET_PROVISIONED_VS_COLLECTED_BY_CLIENT:
      return {
        ...state,
        provisionedVsCollectedByClient: action.payload,
      };
    case WALLET_BY_AGES:
      return {
        ...state,
        walletByAges: action.payload,
      };
    case GET_INVENTORY_ROTATION:
      return {
        ...state,
        inventoryRotation: action.payload,
      };

    case GET_TOP_CLIENTS:
      return {
        ...state,
        topClients: action.payload,
      };
    case GET_BALANCE_IN_ACCOUNT:
      return {
        ...state,
        balanceInAccount: action.payload,
      };
    case GET_INCOME_VS_OUTCOME:
      return {
        ...state,
        incomeVsOutcome: action.payload,
      };
    case GET_TOTAL_ACC_TO_PAY:
      return {
        ...state,
        totalAccToPay: action.payload,
      };
    case GET_TOP_PROVIDERS:
      return {
        ...state,
        topProviders: action.payload,
      };
    case GET_CURRICULUM_ADVANCES:
      return {
        ...state,
        curriculumAdvances: action.payload,
      };
    case GET_ABSENTEEISM:
      return {
        ...state,
        absenteeism: action.payload,
      };
    case GET_TOTAL_INVESTMENT:
      return {
        ...state,
        totalInvestment: action.payload,
      };
    case GET_ACC_TO_PAY_BY_PROV:
      return {
        ...state,
        accToPayByProv: action.payload,
      };
    case GET_PROBLEMS_IN_ENTRY_WH:
      return {
        ...state,
        entryWhProblems: action.payload,
      };
    case DISTRIBUTION_BANK_BALANCE:
      return {
        ...state,
        distributionBankBalance: action.payload,
      };

    case ACCOUNTS_RECEIVABLE:
      return {
        ...state,
        receivableList: action.payload,
        receivableCategories: action.categories,
      };
    case GET_DETAIL_OF_BALANCES:
      return {
        ...state,
        detailBalance: action.payload,
      };
    case GET_FREE_AND_CAPITA:
      return {
        ...state,
        capitaAndFreeList: action.payload,
      };
    case GET_GLOSSES_DETAIL:
      return {
        ...state,
        glossesDetail: action.payload,
      };
    case GET_BILLING_BY_TYPE:
      return {
        ...state,
        billingByType: action.payload,
      };
    case GET_PROFIT_BALANCE:
      return {
        ...state,
        profitBalance: action.payload,
      };
    case EXPIRED_SUPPLIES:
      return {
        ...state,
        expiredSuppliesList: action.payload,
      };
    case CPX_ROTATION:
      return {
        ...state,
        cpxRotationList: action.payload,
      };
    case PERIOD_EXPENSES:
      return {
        ...state,
        periodExpensesList: action.payload,
      };
    default:
      return state;
  }
};
