//CIE-10's reducer

import {
 
    GET_CIETEN,
    SET_INDEX_CIETEN
} from "../actions/actionTypes";
    
    const initialState = {
      cieten:[],
      totalCieten:0,
      page:1
    };
    export const cietenReducer = (state = initialState, action) => {
      switch (action.type) {
        case GET_CIETEN:
          return {
            ...state,
            cieten: action.payload
          };
        case SET_INDEX_CIETEN:
          return {
            ...state,
            totalCieten: action.payload,
          };
        default:
          return state;
      }
    };
    