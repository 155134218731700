import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import {
  GET_CHECK_DEPOSIT_BANK,
  GET_DEPOSIT_BANK,
  GET_ONE_DEPOSIT_BANK,
  GET_SELECT_MONTH,
  CREATE_CONSIGNMENT,
} from './actionTypes';
import { customSwaltAlert } from '../helpers';

const numberWithCommas = x => {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
};

export const getDepositBank = (objFilters, loading) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/corporate_deposit/daily/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      loading();
      const { combinedList, deposits_total } = res;
      dispatch({
        type: GET_DEPOSIT_BANK,
        payload: combinedList,
        total: deposits_total,
      });
    })
    .catch(err => {
      loading();
      console.error(err.message);
    });
};

export const getDepositCheck = objFilters => (dispatch, getselectMonth) => {
  let filters = convertFilterToString(objFilters);
  const token = getselectMonth().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/corporate_deposit/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { combinedList, deposits_total, deposits_total_peding } = res;
      dispatch({
        type: GET_CHECK_DEPOSIT_BANK,
        payload: combinedList,
        total: deposits_total,
        deposits_total_peding: deposits_total_peding,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getOneDeposit = (objFilters, loading) => (dispatch, getselectMonth) => {
  let filters = convertFilterToString(objFilters);
  const token = getselectMonth().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/corporate_deposit/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      loading();
      const { combinedList, deposits_total, deposits_total_peding } = res;
      dispatch({
        type: GET_ONE_DEPOSIT_BANK,
        payload: combinedList,
        total: deposits_total,
        deposits_total_peding: deposits_total_peding,
      });
    })
    .catch(err => {
      loading();
      console.error(err.message);
    });
};

export const getSelectMonth = objFilters => (dispatch, getselectMonth) => {
  let filters = convertFilterToString(objFilters);
  const token = getselectMonth().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/cutoff_dates/range/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_SELECT_MONTH,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const create_Consignment = (data, executeThisAfter) => (dispatch, getselectMonth) => {
  const token = getselectMonth().loginReducer.Authorization;
  dispatch({
    type: CREATE_CONSIGNMENT,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/corporate_deposit/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: CREATE_CONSIGNMENT,
          loading: false,
        });
        executeThisAfter();
        customSwaltAlert({
          icon: 'success',
          title: 'Registrado exitosamente',
          text: `${data.banco ? data.banco : 'No.' + data.identifier}  <br/> $${numberWithCommas(data.amount)}`,
          showCancelButton: false,
        });
      } else {
        dispatch({
          type: CREATE_CONSIGNMENT,
          loading: false,
        });

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
        });
      }
    })
    .then(() => {
      if (executeThisAfter) {
        dispatch({
          type: CREATE_CONSIGNMENT,
          loading: false,
        });
        executeThisAfter();
      }
    })
    .catch(err => {
      dispatch({
        type: CREATE_CONSIGNMENT,
        loading: false,
      });
      console.error(err);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Error al crear`,
        showCancelButton: false,
      });
    });
};

export const update_coporate_deposit = (data, executeThisAfter) => (dispatch, getselectMonth) => {
  const token = getselectMonth().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/treasury/corporate_deposit/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Registrado exitosamente',
          text: 'La consignación del cheque fue registrada correctamente',
          showCancelButton: false,
        });
        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
        });
      }
    })
    .catch(err => {
      console.error(err);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `error al crear`,
        showCancelButton: false,
      });
    });
};
