import { GET_NOTE_DETAILS,  GET_NOTE_TYPES } from "../actions/actionTypes"


const initialState = {
    notes: [],
    row_total:0,
    noteDetail:{}
}

export const noteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTE_TYPES:
            return {
                ...state,
                notes:action.payload,
                row_total:action.row_total
            }
        case GET_NOTE_DETAILS:
            return {
                ...state,
                noteDetail:action.payload
            }
        default:
            return state
    }
}