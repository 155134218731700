import {
  GET_CORPORATECLIENTS, SET_CORPORATECLIENTS, GET_CIIU,
} from "../actions/actionTypes";

const initialState = {
  corporateClients: [],
  allcorporateClients: [],
  totalcorporateClients: 0,
  displayError: false,
  error: null,
  Authorization:"",
  ciiu: [], 
  corporate_loading: false
 };
export const corporateclientsReducer = (state = initialState, action) => {
  switch (action.type) {
      case GET_CORPORATECLIENTS:
          return{
              ...state,
              corporateClients: action.payload,
              totalcorporateClients: action.total,
              Authorization:action.Authorization,
              corporate_loading :action.loading
          };
      case SET_CORPORATECLIENTS:
          return{
              ...state,
              totalcorporateClients: action.payload,
              displayError: false,
              error: null,
              authorization: action.Authorization
          };
      case GET_CIIU:
        return{
          ...state,
          ciiu: action.payload,
        };
  default:
      return state;
  }
  
}