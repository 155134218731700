import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AddCircleOutline, Close } from '@material-ui/icons';
import Pagination from 'react-js-pagination';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';
//
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
import file from '../../../assets/img/icons/AdjuntarRecto.svg';
//
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlert, loader, onChangeFile } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import modalStyles from '../../../components/Layouts/modalnew.module.scss';

const statusList = [
  { label: 'Seleccionar...', value: '' },
  { label: 'Habilitado', value: 1 },
  { label: 'Deshabilitado', value: 0 },
];

export default function PaymentTypes() {
  const store = useSelector(state => state);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    status: null,
    eaccount: store.loginReducer.currentAccount.id,
  });
  const [dataModal, setDataModal] = useState({
    isOpen: false,
    payload: {
      name: '',
      responsible: '',
      signature: '',
      docType: '',
      fileName: null,
      docWeight: 0,
      letter_body: '',
      eaccount: store.loginReducer.currentAccount.id,
      created_by: store.loginReducer.user_data.id,
    },
  });
  const [detailModal, setDetailModal] = useState({
    isOpen: false,
    data: null,
  });

  const {
    trigger: getPaymentTypes,
    results: paymentTypes,
    load: paymentTypesLoader,
  } = useGetMethod();
  const { trigger: getPaymentDetails, load: paymentDetailsLoader } = useGetMethod();
  const { trigger: getUsers, results: userList, load: userListLoader } = useGetMethod();

  const { trigger: createPaymentType, load: createLoader } = usePostMethod();
  const { trigger: updatePaymentType, load: updateLoader } = usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getUsers({
        url: '/payroll/personal/',
        objFilters: {
          page: 1,
          entity_account: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [store.loginReducer.currentAccount.id, store.loginReducer.Authorization, getUsers]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getPaymentTypes({
        url: '/receivable/collectionTypes/',
        objFilters: filters,
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    filters,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getPaymentTypes,
  ]);

  const onCloseDataModal = () => {
    setDataModal({
      isOpen: false,
      payload: {
        eaccount: store.loginReducer.currentAccount.id,
        created_by: store.loginReducer.user_data.id,
      },
    });
  };

  const onCloseDetailModal = () => {
    setDetailModal({
      isOpen: false,
      data: null,
    });
  };

  const onChangePayload = (value, key) => {
    const opt = {
      ...dataModal,
      payload: {
        ...dataModal?.payload,
        [key]: value,
      },
    };
    setDataModal(opt);
  };

  const onCreateInterest = () => {
    const isValid =
      dataModal.payload.signature &&
      dataModal.payload.responsible &&
      dataModal.payload.name &&
      dataModal.payload.letter_body;

    if (!isValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (dataModal.payload) {
      createPaymentType({
        token: store.loginReducer.Authorization,
        body: dataModal.payload,
        method: 'POST',
        url: '/receivable/collectionTypes/',
        succesAction: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Creado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
          }).then(async response => {
            if (response.isConfirmed) {
              await getPaymentTypes({
                url: '/receivable/collectionTypes/',
                objFilters: {
                  ...filters,
                  eaccount: store.loginReducer.currentAccount.id,
                },
                token: store.loginReducer.Authorization,
              });
              setDataModal({
                isOpen: false,
                payload: {
                  eaccount: store.loginReducer.currentAccount.id,
                  created_by: store.loginReducer.user_data.id,
                },
              });
            }
          });
        },
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    }
  };

  const onUpdateInterest = () => {
    const isValid =
      dataModal.payload.signature &&
      dataModal.payload.responsible &&
      dataModal.payload.name &&
      dataModal.payload.letter_body;

    if (!isValid) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    if (dataModal.payload && dataModal.payload.id) {
      updatePaymentType({
        token: store.loginReducer.Authorization,
        body: dataModal.payload,
        method: 'PUT',
        url: '/receivable/collectionTypes/',
        succesAction: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
          }).then(async response => {
            if (response.isConfirmed) {
              await getPaymentTypes({
                url: '/receivable/collectionTypes/',
                objFilters: {
                  ...filters,
                  eaccount: store.loginReducer.currentAccount.id,
                },
                token: store.loginReducer.Authorization,
              });
              setDataModal({
                isOpen: false,
                payload: {
                  eaccount: store.loginReducer.currentAccount.id,
                  created_by: store.loginReducer.user_data.id,
                },
              });
            }
          });
        },
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    }
  };

  const onChangeStatus = item => {
    updatePaymentType({
      token: store.loginReducer.Authorization,
      body: {
        id: item.id,
        status: item.status === '1' ? '0' : '1',
        eaccount: store.loginReducer.currentAccount.id,
      },
      method: 'PUT',
      url: '/receivable/collectionTypes/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await getPaymentTypes({
              url: '/receivable/collectionTypes/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onLoadUsers = async (inputValue = '', callback) => {
    const result = await getUsers({
      url: '/payroll/personal/',
      objFilters: {
        page: 1,
        perpage: 10,
        entity_account: store.loginReducer.currentAccount.id,
        search: inputValue,
      },
      token: store.loginReducer.Authorization,
    });
    const formattedUserList = result.results?.map(el => ({
      label: el.full_name,
      value: el.id_candidate,
    }));
    callback(formattedUserList);
  };

  const onChangeSignature = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 3 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 3MB.',
        confirmButtonText: 'Aceptar',
      });
      setDataModal({
        ...dataModal,
        payload: {
          ...dataModal.payload,
          signature: '',
          docType: '',
          fileName: null,
        },
      });
      e.target.value = null;
      return;
    }

    setDataModal({
      ...dataModal,
      payload: {
        ...dataModal.payload,
        signature: file.file,
        docType: file.typeFile,
        fileName: file.fileName,
        docWeight: file.fileSize,
      },
    });
  };

  const renderDetails = () => {
    return (
      <Modal
        show={detailModal.isOpen}
        centered
        size={700}
        className={`${modalStyles.container}`}
        onHide={onCloseDetailModal}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            Detalle tipo de cobro
          </h3>
          <Close className='pointer text-secondary' onClick={onCloseDetailModal} />
        </div>
        <hr className='m-0' />
        <div className='d-flex flex-column p-5'>
          <div>
            <small className='text-primary'>Tipo de cobro</small>
            <p>{detailModal.data?.name}</p>
          </div>
          <div className='mt-3'>
            <small className='text-primary'>Cuerpo de carta de cobro</small>
            <p style={{ whiteSpace: 'pre-wrap' }}>{detailModal.data?.letterBody}</p>
          </div>
          <div className='d-flex flex-column align-items-end w-100 mt-3'>
            <div className='d-flex flex-column'>
              <small className='text-primary'>Responsable</small>
              <img src={detailModal.data?.signature} alt='Firma usuario img-fluid' width={150} />
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  const renderModal = () => {
    const formattedUserList =
      userList.results
        ?.map(el => ({
          label: el.full_name,
          value: el.id_user,
        }))
        .filter(el => !!el.value) || [];

    return (
      <Modal
        show={dataModal.isOpen}
        centered
        size={700}
        className={`${modalStyles.container}`}
        onHide={onCloseDataModal}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            {dataModal.payload?.id ? 'Editar' : 'Crear'} tipo de cobro
          </h3>
          <Close className='pointer text-secondary' onClick={onCloseDataModal} />
        </div>
        <hr className='m-0' />
        <div className='py-3 px-4'>
          <div className='p-3 d-flex flex-column'>
            <div className='row align-items-end mt-2'>
              <div className='col-4'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Tipo de cobro</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <Form.Control
                    type='text'
                    className='register-inputs'
                    value={dataModal.payload?.name}
                    onChange={({ target }) => {
                      onChangePayload(target.value, 'name');
                    }}
                    placeholder='Escribir...'
                  />
                </Form.Group>
              </div>
              <div className='col-4'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Responsable</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <ReactSelectAsync
                    noOptionsMessage={() => 'No hay datos'}
                    loadOptions={onLoadUsers}
                    defaultOptions={[{ value: '', label: 'Seleccionar...' }, ...formattedUserList]}
                    onChange={option => onChangePayload(option.value, 'responsible')}
                    value={formattedUserList?.find(
                      el => el.value === dataModal.payload.responsible,
                    )}
                    styles={customSelectNewDark}
                    placeholder='Seleccionar...'
                  />
                </Form.Group>
              </div>
              <div className='col-4'>
                <Form.Group>
                  <div className='d-flex justify-content-between align-items-center'>
                    <small>
                      <span className='text-primary ms-1'>Firma</span>
                      <span className='text-danger'>*</span>
                    </small>
                    <small style={{ fontSize: 11 }} className='text-danger'>
                      Máximo 3MB
                    </small>
                  </div>

                  <label
                    htmlFor='signature'
                    className='register-inputs d-flex align-items-center pointer overflow-hidden'
                  >
                    <span
                      className={`flex-grow-1 ${tableStyles.elipsis}`}
                      style={{ maxWidth: '100%' }}
                    >
                      {dataModal.payload.fileName ?? 'Cargar...'}
                    </span>
                    <Form.Control
                      id='signature'
                      type='file'
                      accept='image/png, image/jpeg'
                      className='d-none'
                      onChange={onChangeSignature}
                    />
                    <img src={file} alt='agregar archivo' className={tableStyles.iconFileStyle} />
                  </label>
                </Form.Group>
              </div>
              <div className='col-12 mt-3'>
                <Form.Group>
                  <small>
                    <span className='text-primary ms-1'>Cuerpo de carta de cobro</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <textarea
                    className='register-inputs'
                    onChange={({ target }) => onChangePayload(target.value, 'letter_body')}
                    value={dataModal.payload?.letter_body}
                    placeholder='Escribir...'
                    maxLength={1000}
                    style={{ minHeight: 200 }}
                  />
                </Form.Group>
              </div>
            </div>

            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button onClick={onCloseDataModal} className={`${tableStyles.btnSecondary} me-3`}>
                Cancelar
              </button>
              <button
                className={`${tableStyles.btnPrimaryAlt}`}
                onClick={() => (dataModal.payload?.id ? onUpdateInterest() : onCreateInterest())}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderFilters = () => {
    return (
      <div className='d-flex align-items-end'>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Estado</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={statusList}
            onChange={option =>
              setFilters(prev => ({
                ...prev,
                status: option.value,
              }))
            }
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <div className='flex-grow-1'></div>
        <div
          className={tableStyles.buttonTextPrimary}
          onClick={() =>
            setDataModal({
              isOpen: true,
              payload: {
                eaccount: store.loginReducer.currentAccount.id,
                created_by: store.loginReducer.user_data.id,
              },
            })
          }
        >
          <small className='mr-2'>Crear tipo de cobro</small>
          <AddCircleOutline stroke='2' fontSize='small' />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-3'>
        Tipo de cobro
      </th>,
      <th key={2} className='text-center'>
        Responsable
      </th>,
      <th key={3} className='text-center'>
        Estado
      </th>,
      <th key={4} className='text-center'>
        &nbsp;
      </th>,
    ];

    return (
      <GenericTableNew headers={tableHeaders} dark={true}>
        {paymentTypes?.results?.map(item => {
          const splittedUrl = item?.signature?.trim()?.split('/');
          const fileName = splittedUrl?.[splittedUrl?.length - 1];
          const splittedFileName = fileName?.trim()?.split('.');
          const extension = splittedFileName?.[splittedFileName.length - 1]?.toLowerCase();

          return (
            <tr key={item.id}>
              <td className='text-start align-middle ps-3'>{item.name}</td>
              <td>{item.responsibleName}</td>
              <td>
                <div
                  className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
                >
                  {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
                </div>
              </td>
              <td className='text-center'>
                <CustomPopupExtend
                  showEdit={true}
                  showEnable={true}
                  showDetails={true}
                  position='right'
                  triggerSrc={ThreeDots}
                  isEnabled={item.status === '1'}
                  editClickEvent={() =>
                    setDataModal({
                      isOpen: true,
                      payload: {
                        ...dataModal.payload,
                        id: item.id,
                        responsible: Number(item.responsible),
                        name: item.name,
                        letter_body: item.letterBody,
                        signature: item?.signature,
                        fileName: fileName,
                        docType: extension,
                      },
                    })
                  }
                  showDetailsEvent={async () => {
                    const response = await getPaymentDetails({
                      url: '/receivable/collectionTypes/getDetail/',
                      objFilters: { id: item.id },
                      token: store.loginReducer.Authorization,
                    });
                    if (response.results)
                      setDetailModal({
                        isOpen: true,
                        data: response.results[0],
                      });
                  }}
                  enableClickEvent={() => {
                    customSwaltAlert({
                      icon: 'warning',
                      title: '¿Está seguro?',
                      text: `Se ${item.status === '1' ? 'deshabilitará' : 'habilitará'} el tipo de cobro: ${item.name}.`,
                      confirmButtonText: 'Si, continuar',
                      cancelButtonText: 'Cancelar',
                      showCancelButton: true,
                    }).then(result => {
                      if (result.isConfirmed) {
                        onChangeStatus(item);
                      }
                    });
                  }}
                />
              </td>
            </tr>
          );
        })}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {paymentTypesLoader && loader}
        {createLoader && loader}
        {updateLoader && loader}
        {userListLoader && loader}
        {paymentDetailsLoader && loader}

        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Tipos de cobro</h1>
            {renderFilters()}
            {renderTable()}
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(paymentTypes?.row_count / filters.perpage)
                  ? Math.ceil(paymentTypes?.row_count / filters.perpage)
                  : '1'}{' '}
                ({paymentTypes?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={paymentTypes?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
        {renderModal()}
        {renderDetails()}
      </>
    );
  };

  return render();
}
