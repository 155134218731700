import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';
import {
  GET_SELECT_STATUS,
  GET_WORK_ACCIDENTS,
  GET_WORK_ACCIDENT_DETAILS,
  LOADING,
} from './actionTypes';

import {
  CREATE_ENDOWNMENT_GROUPS,
  GET_ENDOWNMENT_ARTICLES,
  GET_ENDOWNMENT_DELIVERIES,
  GET_ENDOWNMENT_DELIVERIES_PDF,
  GET_ENDOWNMENT_GROUPS,
  GET_INFO_PAYROLL,
  GET_PERIODS_PAYROLL,
  POST_ENDOWNMENT_DELIVERIES,
} from './actionTypes';

export const getInfoPayroll = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payrollPayment/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_INFO_PAYROLL,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getPeriodsPayroll = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/PeriodsPayrollPayment/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_PERIODS_PAYROLL,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const savePay =
  (data, doAfter = null) =>
  (dispatch, getState) => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se registra un pago de nomina a: ${data?.observations}`,
      icon: 'warning',
    }).then(result => {
      if (result.isConfirmed) {
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/treasury/payments/`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',

            token: token,
          },
        })
          .then(response => response.json())
          .then(res => {
            if (res.success) {
              customSwaltAlert({
                icon: 'success',
                title: 'Pago de nómina registrado',
                text: 'Pago ' + res.result.sequence,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              }).then(response => {
                if (response.isConfirmed) {
                  if (doAfter) doAfter();
                } else {
                  if (doAfter) doAfter();
                }
              });
            } else {
              customSwaltAlert({
                icon: 'warning',
                title: 'Error al registrar pago de nómina',
                text: res.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
          })
          .catch(res => {
            customSwaltAlert({
              icon: 'warning',
              title: 'Error al registrar pago de nómina',
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          });
      }
    });
  };

export const get_endownment_groups = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_ENDOWNMENT_GROUPS,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment-group?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;

      dispatch({
        type: GET_ENDOWNMENT_GROUPS,
        payload: results,
        //payload: res,
        total: row_count,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err.message);
      dispatch({
        type: GET_ENDOWNMENT_GROUPS,
        loading: false,
      });
    });
};

export const create_endownment_groups =
  (data, doAfter = null) =>
  (dispatch, getCreate) => {
    dispatch({
      type: CREATE_ENDOWNMENT_GROUPS,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment-group`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { success, message } = res;

        if (success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha creado la dotación: ${data.description}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(() => {
            if (doAfter) {
              doAfter();
            }
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Error al guardar cambios',
            text: message,
            footer: 'Si el problema persiste comuníquese con un asesor.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }

        dispatch({
          type: CREATE_ENDOWNMENT_GROUPS,
          loading: false,
        });
      })
      .catch(err => {
        console.error(err.message);
        dispatch({
          type: CREATE_ENDOWNMENT_GROUPS,
          loading: false,
        });
      });
  };

export const edit_endownment_groups =
  (data, doAfter = null) =>
  (dispatch, getCreate) => {
    dispatch({
      type: CREATE_ENDOWNMENT_GROUPS,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment-group`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { success } = res;

        if (success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: `Se ha actualizado la dotación: ${data.description}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(() => {
            if (doAfter) {
              doAfter();
            }
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            footer: 'Si el problema persiste comuníquese con un asesor.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }

        dispatch({
          type: CREATE_ENDOWNMENT_GROUPS,
          loading: false,
        });
      })
      .catch(err => {
        console.error(err.message);
        dispatch({
          type: CREATE_ENDOWNMENT_GROUPS,
          loading: false,
        });
      });
  };

export const get_endownment_articles = id_group => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment_articles/${id_group}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_ENDOWNMENT_ARTICLES,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const get_endownment_deliveries = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_ENDOWNMENT_DELIVERIES,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment_deliveries/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;

      dispatch({
        type: GET_ENDOWNMENT_DELIVERIES,
        payload: results,
        total: row_count,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err.message);
      dispatch({
        type: GET_ENDOWNMENT_DELIVERIES,
        loading: false,
      });
    });
};

export const insert_endowment_deliveries =
  (data, doAfter = null) =>
  (dispatch, getCreate) => {
    dispatch({
      type: POST_ENDOWNMENT_DELIVERIES,
      loading: true,
    });
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment_deliveries/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { success, message } = res;

        if (success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Datos guardados exitosamente',
            //text: "Guardado",
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(() => {
            if (doAfter) {
              doAfter();
            }
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Error al guardar cambios',
            text: message,
            footer: 'Si el problema persiste comuníquese con un asesor.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }

        dispatch({
          type: POST_ENDOWNMENT_DELIVERIES,
          loading: false,
        });
      })
      .catch(err => {
        console.error(err.message);
        dispatch({
          type: POST_ENDOWNMENT_DELIVERIES,
          loading: false,
        });
      });
  };

export const get_endownment_deliveries_pdf = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment_deliveries/pdf/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (!res.message) {
        downloadPDF(res.key);
        dispatch({
          type: GET_ENDOWNMENT_DELIVERIES_PDF,
          payload: res.key,
        });
      }
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getSelectStatus = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/status/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_SELECT_STATUS,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

// GET WORK ACCIDENTS
export const getWorkAccidents = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  dispatch({ type: LOADING, payload: true });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/work-accidents?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({ type: LOADING, payload: false });
        if (objFilters.id) {
          dispatch({
            type: GET_WORK_ACCIDENT_DETAILS,
            payload: res.results,
          });
        } else {
          dispatch({
            type: GET_WORK_ACCIDENTS,
            payload: res.results,
            row_total: res.row_total,
          });
        }
      } else {
        dispatch({ type: LOADING, payload: false });
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al traer la información de los accidentes',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    })
    .catch(() => {
      dispatch({ type: LOADING, payload: false });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer la información de los accidentes',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

export const updateWorkAccidents = (data, doAfter) => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/work-accidents`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      if (res.success) {
        if (doAfter) doAfter();
        return customSwaltAlert({
          icon: 'success',
          title: 'Resultado registrado',
          message: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    })
    .catch(res => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `${res.message}`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

export const createWorkAccident = (data, doAfter) => (dispatch, getState) => {
  if (!data.reporter) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe seleccionar el reportante',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }

  if (!data.accident_date) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La fecha es obligatoria',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (new Date(data.accident_date) > new Date()) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La fecha no debe ser mayor a la fecha actual',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data.title) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'El título es obligatorio',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data.description_event) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La descripción de la situación es obligatoria',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data.involved) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'El colaborador afectado es obligatorio',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data.description_injury) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La descripción de la lesión es obligatoria',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data.documents?.furat?.filename) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'El campo FURAT es obligatorio',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  if (!data?.documents?.other_docs?.filename) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Los anexos son obligatorios',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/work-accidents`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        if (doAfter) doAfter();
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al guardar el registro',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

// END WORK ACCIDENTS

function downloadPDF(pdf) {
  const treatPdf = pdf.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$

  const linkSource = `data:application/pdf;base64,${treatPdf}`;
  const downloadLink = document.createElement('a');
  const fileName = 'vct_illustration.pdf';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
