//costcenter' reducer

import {
  GET_LIST_NIIF,
  GET_LIST_LOAD,

} from "../actions/actionTypes";

const initialState = {
  total: 0,
  error: null,
  Authorization: "",
  loading: false,
};

export const listNiifReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_LIST_LOAD:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_LIST_NIIF:
      return {
        ...state,
        cc: action.results,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };


    default:
      return state;
  }
};
