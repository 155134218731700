import ReactSelect from 'react-select';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../../Hooks';

import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';
import downloadIcon from '../../../assets/img/icons/downloadArrow2.svg';

import { customSwaltAlert, downloaFile, loader } from '../../../helpers';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';

import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

const NOTE_TYPE_OPTIONS = [
  { label: 'Seleccionar...', value: null },
  { label: 'Débito', value: 'debit' },
  { label: 'Crédito', value: 'credit' },
];

const NOTES_HEADER = [
  { title: 'No. de factura', className: 'px-2' },
  { title: 'Fecha', className: 'px-2 text-center' },
  { title: 'Tipo', className: 'px-2 text-center' },
  { title: 'Valor factura', className: 'px-2 text-end' },
  { title: 'Nuevo valor factura', className: 'px-2 text-end' },
  { title: 'Creado por', className: 'px-2 text-center' },
  { title: '', className: 'px-2' },
];

export default function NotesHistoryAdmissions() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const eaccount = useSelector(store => store.loginReducer.currentAccount.id);

  const [noteFilters, setNoteFilters] = useState({
    searchNote: '',
    startDate: null,
    endDate: null,
    noteType: null,
  });
  const [pagination, setPagination] = useState({
    page: 1,
    perpage: 10,
  });

  const {
    trigger: getNotesHistory,
    load: notesHistoryLoader,
    results: notesHistoryResults,
  } = useGetMethod();

  const {
    trigger: getNotePDF,
    load: notePDFLoader,
  } = useGetMethod();

  useEffect(() => {
    void getNotesHistory({
      url: '/medical/admissions/invoicing-notes',
      token: token,
      objFilters: {
        startDate: noteFilters.startDate,
        endDate: noteFilters.endDate,
        noteType: noteFilters.noteType,
        search: noteFilters.searchNote,
        page: pagination.page,
        perpage: pagination.perpage,
        eaccount: eaccount,
        table: 'app',
      },
    });
  }, [
    getNotesHistory,
    token,
    eaccount,
    noteFilters.startDate,
    noteFilters.endDate,
    noteFilters.noteType,
    noteFilters.searchNote,
    pagination.page,
    pagination.perpage,
  ]);

  const onSubmitSearch = event => {
    event.preventDefault();

    const { searchNote } = event.target;

    setNoteFilters(state => ({ ...state, searchNote: searchNote.value.trim() }));
  };

  const downloadNote = (note) => {
    getNotePDF({
      url: '/medical/invoicing/download-note/',
      token: token,
      objFilters: {
        eaccount,
        noteId: note.id,
        table: 'app'
      },
    }).then((results) => {
        if (!results?.success) {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results?.message,
            confirmButtonText: 'Aceptar',
          })
        }

        downloaFile(
          results?.results?.key,
          `${note.noteType}_note_${note.id}.pdf`
        )
      })
  }

  const renderFilters = () => {
    return (
      <div className='row justify-content-start align-items-end'>
        <div className='col-lg-2'>
          <small className={`ms-1 ${tableStyles.ordDarkBlueText} fw-bold`}>Fecha desde</small>
          <input
            className={`ord-roundInput2`}
            type='date'
            onChange={({ target }) => {
              setNoteFilters(state => ({ ...state, startDate: target.value }));
            }}
            value={noteFilters.startDate}
            max={noteFilters.endDate || new Date().toISOString('en-US').split('T')[0]}
          />
        </div>
        <div className='col-lg-2'>
          <small className={`ms-1 ${tableStyles.ordDarkBlueText} fw-bold`}>Fecha hasta</small>
          <input
            className={`ord-roundInput2`}
            type='date'
            onChange={({ target }) => {
              setNoteFilters(state => ({ ...state, endDate: target.value }));
            }}
            value={noteFilters.endDate}
            min={noteFilters.startDate}
            max={new Date().toISOString('en-US').split('T')[0]}
          />
        </div>
        <div className='col-lg-2'>
          <small className={`ms-1 ${tableStyles.ordDarkBlueText} fw-bold`}>Tipo de nota</small>
          <ReactSelect
            placeholder='Seleccionar...'
            styles={ordCustomSelect}
            noOptionsMessage={() => 'No hay datos'}
            name='noteType'
            className='text-secondary'
            onChange={option => {
              setNoteFilters(state => ({ ...state, noteType: option.value }));
            }}
            value={NOTE_TYPE_OPTIONS.find(opt => opt.value === noteFilters.noteType)}
            options={NOTE_TYPE_OPTIONS}
          />
        </div>
        <form
          onSubmit={onSubmitSearch}
          className={`d-flex col-lg-4 ${tableStyles.appGrayInputSearch}`}
        >
          <input
            className={`w-100 ord-roundInput ${tableStyles.appInputDate}`}
            type='text'
            placeholder='Buscar nota...'
            name='searchNote'
            autoComplete='off'
          />
          <button type='submit' className='me-2'>
            <img width={16} src={ordBlueSearch} alt='buscar' />
          </button>
        </form>
      </div>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {(notesHistoryLoader || notePDFLoader) && loader}
        <div className='container-fluid px-5'>
          <div className={`${tableStyles.ordDarkBlueText} fs-1 fw-bold py-4`}>
            Historial de notas
          </div>
          {renderFilters()}

          <OrdTable
            headers={NOTES_HEADER}
            shadow
            hasChildren={true}
            oneHeadDarkBlue={false}
            lowerCase={false}
            paginate={{
              activePage: pagination.page,
              totalPages: notesHistoryResults?.rowTotal,
              perPage: pagination.perpage,
              showTextDetails: true,
              onChangePage: async e => {
                setPagination({
                  perpage: 10,
                  page: e,
                });
              },
            }}
          >
            {notesHistoryResults?.results &&
              notesHistoryResults.results.map(note => (
                <tr key={note.id}>
                  <td className='px-2 text-start'>{`${note?.resolutionPrefix ?? ''}${note.invoiceId}`}</td>
                  <td className='px-2 text-center'>
                    {moment(note.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                  </td>
                  <td className='px-2 text-center'>{note.noteType === "debit" ? "Débito" : "Crédito"}</td>
                  <td className='px-2 text-end'>
                    {formatNumberWithoutDecimals(note.originalValue)}
                  </td>
                  <td className='px-2 text-end'>{formatNumberWithoutDecimals(note.newValue)}</td>
                  <td className='px-2 text-center'>
                    {note.createdBy.firstName} {note.createdBy.lastName}
                  </td>
                  <td>
                    <button>
                      <img src={downloadIcon} alt='' onClick={() => downloadNote(note)} />
                    </button>
                  </td>
                </tr>
              ))}
          </OrdTable>
        </div>
      </>
    );
  };

  return renderComponent();
}
