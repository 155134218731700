import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
//
import ThreeDots from "../assets/img/icons/threeDots.svg";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import PopUp from "../components/Popup/customPopUpExtends";
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import OrdModal from "../OrderingModule/OrderingComponents/OrdModal";
import OrdTable from "../OrderingModule/OrderingComponents/OrdTable";
//
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from "../helpers";
import { useGetMethod, usePostMethod } from "../Hooks";
//
import tableStyles from "../components/Layouts/tableStyle.module.scss";

const HEADERS = [
    { title: "Código", className: "col-3 text-center" },
    { title: "Impresión diagnóstica CIE10", className: "col-6 text-start" },
    { title: "Estado", className: "col-2 text-center" },
    { title: (<>&nbsp;</>), className: "col-1" },
];

export default function Cie10View() {
    const store = useSelector((state) => state);

    const [trigger, setTrigger] = useState(0);
    const [impression, setImpression] = useState({
        showModal: false,
        isEditing: false,
        data: {
            code: "",
            cie10TypeId: "",
            description: "",
        }
    });
    const [filters, setFilters] = useState({
        page: 1,
        search: "",
        perpage: 10,
    });

    const {
        load: impressionServicesLoader,
        results: impressionServices,
        trigger: getImpressionsService
    } = useGetMethod();

    const {
        results: typeList,
        trigger: getTypeList
    } = useGetMethod();

    const {
        load: createDilatationServiceLoader,
        trigger: createDilatationService
    } = usePostMethod();

    const {
        load: updateDilatationServiceLoader,
        trigger: updateDilatationService
    } = usePostMethod();

    useEffect(() => {
        async function fetchAsyncData() {
            await getImpressionsService({
                url: "/admin/cie-ten",
                objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
                token: store.loginReducer.Authorization
            });
        }
        fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getImpressionsService,
        store.loginReducer.Authorization,
        store.loginReducer.currentAccount.id,
        createDilatationServiceLoader,
        updateDilatationServiceLoader,
        filters.page,
        trigger
    ]);

    useEffect(() => {
        async function fetchAsyncData() {
            await getTypeList({
                url: "/admin/cie-ten-types",
                objFilters: { eaccount: store.loginReducer.currentAccount.id },
                token: store.loginReducer.Authorization
            });
        }
        fetchAsyncData().catch(error => console.error(error));
    }, [
        getTypeList,
        store.loginReducer.Authorization,
        store.loginReducer.currentAccount.id
    ]);

    const typeOptions = formatToRcSelect(
        typeList?.results,
        'id',
        'name',
        '',
        '',
        '',
    );

    const handleOpenEditModal = (item) => {
        setImpression({
            ...impression,
            showModal: true,
            isEditing: true,
            data: item
        });

    };

    const handleCloseModal = () => {
        setImpression({ ...impression, data: { code: "", cie10TypeId: "", description: "" }, showModal: false, isEditing: false });
    };

    const handleCreateDilatationService = async () => {
        await createDilatationService({
            url: "/admin/cie-ten",
            method: "POST",
            noAlert: true,
            token: store.loginReducer.Authorization,
            body: impression.data,
            doAfterSuccess: (res) => {
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Creado con éxito",
                    text: res.message,
                    showCancelButton: false,
                });
            }
        });
    };

    const handleUpdateImpression = async (isChangingStatus, value, data) => {
        await updateDilatationService({
            url: `/admin/cie-ten/${data?.code ?? impression?.data?.code}`,
            method: isChangingStatus ? "PATCH" : "PUT",
            noAlert: true,
            token: store.loginReducer.Authorization,
            body: isChangingStatus ? { data, enabled: value } : impression.data,
            doAfterSuccess: (res) => {
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Actualizado con éxito",
                    text: res.message,
                    showCancelButton: false,
                });
            }
        });
    };







    const handleSubmit = async () => {
        if (impression.isEditing) {
            await handleUpdateImpression();
        } else {
            await handleCreateDilatationService();
        }
        handleCloseModal();
        setTrigger(state => state + 1);
    };

    const renderRow = (impression) => {
        return (
            <tr key={impression.id} className={`hover-table-row`} >
                <td>{impression.code}</td>
                <td className="text-start">{impression.description}</td>
                <td className="text-center"><div
                    className={`${impression.enabled === true ? tableStyles.greenState : tableStyles.redState}`}
                >
                    {impression.enabled === true ? 'Habilitado' : 'Deshabilitado'}
                </div></td>
                <td className="text-end">
                    <PopUp
                        showEdit={true}
                        showEnable={true}
                        position="right"
                        triggerSrc={ThreeDots}
                        editClickEvent={() => handleOpenEditModal(impression)}
                        isEnabled={impression.enabled === true}
                        enableText={impression.enabled === true ? "Deshabilitar" : "Habilitar"}
                        enableClickEvent={() => {
                            customSwaltAlertAsistencial({
                                icon: "warning",
                                title: "¿Está seguro?",
                                text: `¿Desea ${impression.enabled === true ? "Deshabilitar" : "Habilitar"} el estado?`,
                                confirmButtonText: "Si, continuar",
                            }).then(async result => {
                                if (result?.isConfirmed) {
                                    await handleUpdateImpression(true,
                                        impression.enabled === true ? false : true, impression
                                    );
                                }
                            });
                        }}
                    />
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <OrdTable
                className={`${tableStyles.shade}`}
                headers={HEADERS}
                hasChildren={true}
                style={{ borderRadius: "10px", overflow: "hidden" }}
                paginate={{
                    activePage: filters.page,
                    totalPages: impressionServices?.rowTotal,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    onChangePage: (e) => {
                        setFilters({ ...filters, page: e });
                    },
                    showTextDetails: true
                }}
            >
                {impressionServices?.results?.map(impression => renderRow(impression))}
            </OrdTable>
        );
    };

    const renderModal = () => {





        return (
            <OrdModal
                title={`${impression.isEditing ? "Editar" : "Nueva"} impresión diagnóstica`}
                titleClassName="fs-5"
                show={impression.showModal}
                btnYesName="Guardar"
                btnNoName="Cancelar"
                size="md"
                onHide={handleCloseModal}
                btnYesEvent={() => handleSubmit()}
                btnNoEvent={handleCloseModal}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    className="py-4"
                >

                    <Form.Group className="" >
                        <Form.Label className={tableStyles.app_title_input}>
                            <div>
                                <span className={tableStyles.app_title_new_item}>Código</span>
                                <span style={{ color: "#FFFF00" }}>*</span>
                            </div>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Escribir..."
                            className="ord-roundInput"
                            value={impression?.data?.code}
                            onChange={({ target }) => {
                                setImpression((state) => ({ ...state, data: { ...state.data, code: target.value } }));
                            }}
                            disabled={impression.isEditing}
                        />
                    </Form.Group>
                    <Form.Group className="mt-2" >
                        <Form.Label className={tableStyles.app_title_input}>
                            <div>
                                <span className={tableStyles.app_title_new_item}>Descripción</span>
                                <span style={{ color: "#FFFF00" }}>*</span>
                            </div>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Escribir..."
                            className="ord-roundInput"
                            value={impression?.data?.description}
                            onChange={({ target }) => {
                                setImpression((state) => ({ ...state, data: { ...state.data, description: target.value } }));
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <Form.Label className={tableStyles.app_title_input}>
                            <div>
                                <span className={tableStyles.app_title_new_item}>Tipo</span>
                                <span style={{ color: "#FFFF00" }}>*</span>
                            </div>
                        </Form.Label>
                        <Select
                            noOptionsMessage={() => "No hay datos"}
                            value={typeOptions.find(type => type.value === impression?.data?.cie10TypeId)}
                            onChange={(e) => {
                                setImpression((state) => ({ ...state, data: { ...state.data, cie10TypeId: e.value } }));
                            }}
                            options={typeOptions}
                            className="text-secondary"
                            placeholder={"Seleccionar..."}
                            styles={ordCustomSelect}
                        />
                    </Form.Group>

                </form>
            </OrdModal >
        );
    };

    const render = () => {
        return (
            <>
                {impressionServicesLoader && loader}
                {createDilatationServiceLoader && loader}
                {updateDilatationServiceLoader && loader}
                {renderModal()}
                <div className={`${tableStyles.tlnContainer} `}>
                    <OrdGenericTemplate
                        title="Impresiones diagnósticas - CIE10"
                        titleSize={12}
                        buttonNewLabel={"Agregar CIE10"}
                        buttonNewAction={() => {
                            setImpression({ ...impression, showModal: true });
                        }}
                        searchWithNew={{
                            colSize: "12",
                            WSize: "100",
                            onSubmit: (e) => {
                                e.preventDefault();
                                setTrigger(state => state + 1);
                            },
                            onChange: (e) => {
                                setFilters({ ...filters, search: e.target.value });
                            },
                            buttonNewLabel: "Agregar CIE10",
                            buttonNewAction: () => { setImpression({ ...impression, showModal: true }); },
                        }}
                    >
                        {renderTable()}
                    </OrdGenericTemplate>
                </div>
            </>
        );
    };

    return render();
}