import Plus from '../../../assets/img/icons/iconNumericPlus.svg';
import Minus from '../../../assets/img/icons/iconNumericMinus.svg';

import styles from './CustomNumUpDown.module.scss';

function CustomNumUpDown(props) {
  const changeTic = props.tic ? props.tic : 1;

  const myOnChange = newval => {
    if (props.onChange) {
      props.onChange(props.id, newval);
    } else {
      return null;
    }
  };

  const numberDown = () => {
    myOnChange(props.value - changeTic);
  };

  const numberUp = () => {
    myOnChange(props.value + changeTic);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.btn} ${styles.btnDown}`}>
        <img src={Minus} alt='minus' onClick={numberDown} />
      </div>
      <input
        name='numericUpDown'
        className={styles.input}
        type='number'
        pattern='[0-9]'
        onChange={e => myOnChange(e.target.value)}
        onFocus={event => event.target.select()}
        value={props.value}
      />
      <div className={`${styles.btn} ${styles.btnUp}`}>
        <img src={Plus} alt='plus' onClick={props.diableUp ? null : numberUp} />
      </div>
    </div>
  );
}

export default CustomNumUpDown;
