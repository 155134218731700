import { useEffect, useState } from 'react';
import { isEmptyOrUndefined, loader } from '../../helpers/helpers';
import { useSelector } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Form, OverlayTrigger, Row } from 'react-bootstrap';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import CustomPopupStyles from '../../../src/components/Popup/customPopup.module.scss';
import threeDots from '../../assets/img/icons/threeDots.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import add from '../../assets/img/icons/grayAdd.svg';
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { RenderOrderTypeTooltip } from './../../components/RenderOrderTypeTooltip';
import { customSwaltAlertAsistencial } from '../../helpers/customSwaltAlertAsistencial';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';

const AnulationReasons = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;

  const [data, setData] = useState({
    listData: null,
    listDataFilters: {
      listActive: [],
      listBlock: [],
      listInactive: [],
    },
    filters: {
      canDescription: null,
      cancellationReasonId: undefined,
      trigger: 0,
    },
    modal: {
      isEditing: null,
      show: null,
    },
    dataSchema: {
      canApp: 1,
      canSurgery: 0,
      canDescription: null,
      canEnabled: null,
      canId: null,
      canLocked: null,
      canReplacement: null,
      eaccount,
      createdBy,
      actionType: null,
      cancellationReasonId: undefined,
    },
  });

  const { modal, dataSchema, filters, listDataFilters } = data;

  const [listCancellationsTypes, setListCancellationsTypes] = useState([]);

  //  ---------------------------Petitions -------------------------

  const { load: loaderUpdateData, trigger: updateData } = usePostMethod();

  const { load: loaderCreateData, trigger: createData } = usePostMethod();

  const {
    results: listCancellations,
    trigger: getListCancellations,
    load: loadListCancellations,
  } = useGetMethod();

  const { trigger: getListCancellationsTypes, load: loadListCancellationsTypes } = useGetMethod();

  // //  --------------------------- Modal -------------------------
  const postModal = () => {
    setData(state => ({
      ...state,
      modal: { ...state.modal, show: true, isEditing: false },
      dataSchema: {
        canApp: 1,
        canSurgery: 0,
        canDescription: null,
        canEnabled: null,
        canId: null,
        canLocked: null,
        canReplacement: null,
        eaccount,
        createdBy,
        actionType: null,
      },
    }));
  };

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        isEditing: null,
        show: null,
      },
      dataSchema: {
        ...state.dataSchema,
        cancellationReasonId: undefined,
        canDescription: '',
      },
    }));
  };

  //  --------------------------- Methods ------------------------------

  const editDataAction = () => {
    updateData({
      url: `/medical/generals/cancellationReason/`,
      token: token,
      method: 'PUT',
      body: dataSchema,
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: `Motivo de anulación Actualizado`,
          text: `${dataSchema.canDescription}`,
          showCancelButton: false,
        });
        closeModal();
        setData(state => ({
          ...state,
          filters: { ...state.filters, trigger: state.filters.trigger + 1 },
        }));
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
        });
        closeModal();
      },
    });
  };

  const createDataAction = () => {
    createData({
      url: `/medical/generals/cancellationReason/`,
      token: token,
      method: 'POST',
      body: dataSchema,
      succesAction: () => {
        closeModal();
        setData(state => ({
          ...state,
          filters: { ...state.filters, trigger: state.filters.trigger + 1 },
        }));
        customSwaltAlertAsistencial({
          icon: 'success',
          title: `Motivo de anulación creado`,
          text: `${dataSchema.canDescription}`,
          showCancelButton: false,
        });
      },
      doAfterException: res => {
        closeModal();
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
        });
      },
    });
  };

  const disabledDataAction = item => {
    const updateMessage = item.canEnabled ? 'inhabilitado' : 'habilitado';

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `Se ${item.canEnabled ? 'inhabilitará' : 'habilitará'} el motivo de anulación: ${item.canDescription}`,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        updateData({
          url: `/medical/generals/cancellationReason/`,
          token: token,
          method: 'PUT',
          body: item.canEnabled ? { ...item, canEnabled: 0 } : { ...item, canEnabled: 1 },
          succesAction: () => {
            closeModal();
            setData(state => ({
              ...state,
              filters: { ...state.filters, trigger: state.filters.trigger + 1 },
            }));
            customSwaltAlertAsistencial({
              icon: 'success',
              title: `Motivo de anulación ${updateMessage}`,
              text: `${item.canDescription}`,
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            closeModal();
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${res.message}`,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  // -------------------------------- Validations -----------------------
  const validationRequest = item => {
    if (isEmptyOrUndefined(dataSchema?.cancellationReasonId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El motivo es obligatorio`,
        showCancelButton: false,
      });
    }

    if (dataSchema?.canDescription === null || dataSchema?.canDescription?.length <= 0) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `La descripción es obligatoria`,
        showCancelButton: false,
      });
    }

    modal.isEditing ? editDataAction(item) : createDataAction();
  };

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    if (filters.trigger) {
      if (filters.canDescription) {
        getListCancellations({
          url: `/medical/generals/cancellationReason/`,
          objFilters: { admissions: 1, canDescription: filters.canDescription },
          token: token,
        });
      } else {
        getListCancellations({
          url: `/medical/generals/cancellationReason/`,
          objFilters: {
            admissions: 1,
            canDescription: filters.canDescription,
            cancellationReasonId: filters.cancellationReasonId,
          },
          token: token,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.trigger]);

  useEffect(() => {
    if (loadListCancellations === false) {
      if (!listCancellations?.results?.length) {
        setData(state => ({
          ...state,
          listData: [],
          listDataFilters: {
            listActive: [],
            listBlock: [],
            listInactive: [],
          },
        }));
      } else {
        const filter = listCancellations?.results;
        const listActive = listCancellations?.results?.filter(el => el?.canEnabled === 1);
        const listInactive = listCancellations?.results?.filter(el => el?.canEnabled === 0);
        const listBlock = listCancellations?.results?.filter(el => el?.canLocked === 1);

        setData(state => ({
          ...state,
          listData: filter,
          listDataFilters: {
            listActive,
            listBlock,
            listInactive,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCancellations]);

  useEffect(() => {
    getListCancellations({
      url: `/medical/generals/cancellationReason/`,
      objFilters: { admissions: 1 },
      token: token,
    });

    getListCancellationsTypes({
      token,
      objFilters: { eaccount },
      url: `/medical/generals/cancellation-reasons/types`,
    }).then(res => {
      if (res?.results?.length) {
        const defaultValue = { label: 'Seleccionar...', value: '' };

        const format = res?.results?.map(cancellation => ({
          ...cancellation,
          label: cancellation?.cancellationReason,
          value: cancellation.cancellationReasonId,
        }));

        setListCancellationsTypes([defaultValue, ...format]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderComponent = ({ list }) => {
    return list?.map((item, index) => (
      <div key={index}>
        {!item.canEnabled || item.canLocked ? (
          <OverlayTrigger
            placement='right'
            delay={{ show: 100, hide: 100 }}
            overlay={RenderOrderTypeTooltip('Motivo inhabilitado')}
          >
            <>
              <div className={`${ordComponentStyles.ordCardFlex} my-3 `}>
                <div className='w-100' style={{ opacity: 0.4 }}>
                  <div className='my-2'>
                    <div className='d-flex justify-content-between '>
                      <div className='d-flex gap-2'>
                        <div style={{ width: '100px', fontWeight: 'bold' }}>Descripción:</div>
                        <span>{item.canDescription || '-'}</span>
                      </div>
                    </div>
                  </div>

                  <div className='my-2'>
                    <div className='d-flex justify-content-between '>
                      <div className='d-flex gap-2'>
                        <div style={{ width: '100px', fontWeight: 'bold' }}>Motivo:</div>
                        <span>{item.cancellationReason || '-'}</span>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div className='d-flex'>
                      <div style={{ width: '100px', fontWeight: 'bold' }}>Tipo:</div>
                      <div className='d-flex gap-4'>
                        {item.canApp === 1 && (
                          <div className={`${tableStyles.ordRedButtonWidthAuto} text-center`}>
                            {' '}
                            Anulación{' '}
                          </div>
                        )}
                        {item.canReplacement === 1 && (
                          <div
                            className={`${tableStyles.ordOrangeButtonWidthAuto} text-center`}
                            style={{ width: 'auto !important' }}
                          >
                            {' '}
                            Reemplazo{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {item.canLocked ? (
                    <div className='d-flex justify-content-end'>
                      {item.canApp === 1 && (
                        <div className={`${tableStyles.ordGrayButtonWidthAuto} text-center `}>
                          {' '}
                          Bloqueado{' '}
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                {!item.canEnabled ? (
                  <div>
                    <CustomPopupExtend
                      triggerSrc={threeDots}
                      extraButtons={[
                        {
                          text: 'Habilitar',
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            disabledDataAction(item);
                          },
                        },
                      ]}
                    ></CustomPopupExtend>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          </OverlayTrigger>
        ) : (
          <div className={`${ordComponentStyles.ordCardFlex} my-3 `}>
            <div className='w-100'>
              <div className='my-2'>
                <div className='d-flex justify-content-between '>
                  <div className='d-flex gap-2'>
                    <div style={{ width: '100px', fontWeight: 'bold' }}>Descripción:</div>
                    <span>{item.canDescription || '-'}</span>
                  </div>
                </div>
              </div>

              <div className='my-2'>
                <div className='d-flex justify-content-between '>
                  <div className='d-flex gap-2'>
                    <div style={{ width: '100px', fontWeight: 'bold' }}>Motivo:</div>
                    <span>{item.cancellationReason || '-'}</span>
                  </div>
                </div>
              </div>

              <div className='d-flex'>
                <div className='d-flex'>
                  <div style={{ width: '100px', fontWeight: 'bold' }}>Tipo:</div>
                  <div className='d-flex gap-4'>
                    {item.canApp === 1 && (
                      <div className={`${tableStyles.ordRedButtonWidthAuto} text-center`}>
                        {' '}
                        Anulación{' '}
                      </div>
                    )}
                    {item.canReplacement === 1 && (
                      <div
                        className={`${tableStyles.ordOrangeButtonWidthAuto} text-center`}
                        style={{ width: 'auto !important' }}
                      >
                        {' '}
                        Reemplazo{' '}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {item.canLocked ? (
                <div className='d-flex justify-content-end'>
                  {item.canApp === 1 && (
                    <div className={`${tableStyles.ordGrayButtonWidthAuto} text-center `}>
                      {' '}
                      Bloqueado{' '}
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>

            <div>
              <CustomPopupExtend
                triggerSrc={threeDots}
                extraButtons={[
                  {
                    text: 'Editar',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      setData(state => ({
                        ...state,
                        modal: { isEditing: true, show: true },
                        dataSchema: { ...state.dataSchema, ...item },
                      }));
                    },
                  },
                  {
                    text: 'Inhabilitar',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      disabledDataAction(item);
                    },
                  },
                ]}
              ></CustomPopupExtend>
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      {(loadListCancellations ||
        loaderUpdateData ||
        loaderCreateData ||
        loadListCancellationsTypes) &&
        loader}

      <OrdModal
        title={modal.isEditing ? 'Editar motivo' : 'Agregar motivo'}
        show={modal.show}
        btnYesName={'Guardar'}
        btnNoName={'Cancelar'}
        size={550}
        onHide={() => closeModal()}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => closeModal()}
        sizeBody={`${tableStyles.modalHeight}`}
        btnYesStyle={tableStyles.btnYesStyle}
        btnCancelStyle={tableStyles.btnCancelStyle}
      >
        <div>
          <Row>
            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Motivo</b>
                <span className='text-danger'>*</span>
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                isDisabled={modal.isEditing}
                options={listCancellationsTypes}
                className='text-secondary align-self-end '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                value={
                  dataSchema.cancellationReasonId
                    ? {
                        value: dataSchema.cancellationReasonId,
                        label: dataSchema.cancellationReason,
                      }
                    : undefined
                }
                onChange={({ value, label }) => {
                  if (!modal.isEditing) {
                    setData(state => ({
                      ...state,
                      dataSchema: {
                        ...state.dataSchema,
                        cancellationReasonId: value,
                        cancellationReason: label,
                      },
                    }));
                  }
                }}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Descripción</b>
                <span className='text-danger'>*</span>
              </Form.Label>
              <textarea
                defaultValue={modal.isEditing ? dataSchema?.canDescription : ''}
                value={dataSchema?.canDescription}
                placeholder={'Escribir...'}
                rows='70'
                cols='60'
                style={{ height: '112px' }}
                className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
                // key={trigger + "observations"}
                onChange={e =>
                  setData(state => ({
                    ...state,
                    dataSchema: { ...state.dataSchema, canDescription: e.target.value },
                  }))
                }
              ></textarea>
            </Form.Group>
          </Row>
          <Row className=''>
            <Col xs={6} className={`d-flex justify-content-center  `}>
              <div
                className={`d-flex w-100 justify-content-center text-end p-3  ${tableStyles.cardCheckAppointment}`}
                style={{ marginRight: '2px', marginBottom: '0px', marginTop: '-2px' }}
              >
                <input
                  className={`border border-dark-blue form-check-input p1 check-dark-blue ${tableStyles.cursorPointer}`}
                  defaultChecked={modal.isEditing ? dataSchema?.canReplacement : 0}
                  type='checkbox'
                  name='edit'
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      dataSchema: { ...state.dataSchema, canReplacement: e.target.checked ? 1 : 0 },
                    }))
                  }
                />
                <b className={`ml-2 pl-3 ${tableStyles.tlnTextGray}`}>Reemplazo</b>
              </div>
            </Col>
          </Row>
        </div>
      </OrdModal>

      <OrdGenericTemplate
        className={`${tableStyles.W900} mt-4 mx-2`}
        style={{ width: '650px' }}
        showBottomBorder={false}
        title={'Motivos de anulación y reemplazo'}
        titleSize={12}
      >
        <header className='d-grid gap-4' style={{ gridTemplateColumns: '1fr 2fr 1fr' }}>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={listCancellationsTypes}
            className='text-secondary align-self-end '
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            onChange={({ value }) => {
              setData(state => ({
                ...state,
                filters: {
                  ...state.filters,
                  cancellationReasonId: value,
                  trigger: filters.trigger + 1,
                },
              }));
            }}
          />

          <div
            className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12} align-self-end `}
          >
            <input
              className={`ord-roundInput ${tableStyles.darkerGrayText} w-100`}
              type='text'
              placeholder='Escribe aquí para buscar'
              autoComplete='off'
              onChange={e => {
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, canDescription: e.target.value },
                }));
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, trigger: filters.trigger + 1 },
                  }));
                }
              }}
            />
            <img src={ordBlueSearch} className='pointer' alt='buscar' />
          </div>

          <div
            style={{ height: '32px' }}
            className={`d-flex justify-content-end align-items-center ${tableStyles.hoverOrdAquaMarine} pointer gap-2`}
            onClick={() => postModal()}
          >
            <label className='ordGrayText fw-bold pointer'>Agregar motivo</label>
            <img src={add} alt='agregar motivo' height={20} width={20} />
          </div>
        </header>

        <div className='mx-1 mb-5'>
          <RenderComponent list={listDataFilters?.listActive} />
          <RenderComponent list={listDataFilters?.listInactive} />
          <RenderComponent list={listDataFilters?.listBlock} />
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default AnulationReasons;
