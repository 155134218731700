//_--------------------. import hooks ⛳ -----------------------------//
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
//----------------------------- ☪ Images--------------------------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
// ---------------------------------------------------------------------//

// ----------- 🧮 components --------------------//
import GenericTableNew from '../Layouts/GenericTableNew';
import Loader from 'react-loader-spinner';
import { Redirect, useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { AddCircleOutline } from '@material-ui/icons';
import ReactSelect from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
// ----------- --------------- --------------------//

// ----------------- 🦄 Styless -----------------------------//
// import paginationStyles from "../Layouts/pagination.module.scss";
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';

// ---------------- functions 🛬 ---------------------------//
import {
  getCollectionsLetters,
  getCollectionsLettersDetail,
} from '../../actions/collectionActions';
import { responseSwal } from '../../helpers/responseSwal';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { customSwaltAlert, loader } from '../../helpers';
import LetterPreview from './LetterPreview';
//-----------------------------------------------------------//

export const CollectionLetter = () => {
  const reducers = useSelector(state => state);
  let history = useHistory();
  const dispatch = useDispatch();
  const [collectionLetters, setCollectionLetters] = useState({
    result: [],
    rowCount: 0,
  });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    status: '',
    search: '',
    collectionType: '',
    eaccount: reducers.loginReducer.currentAccount.id,
  });
  const [viewDetails, setViewDetails] = useState({ isOpen: false, data: '' });
  const [isLoading, setIsLoading] = useState();
  const { trigger: getPaymentTypes, results: paymentTypes } = useGetMethod();
  const { trigger: updateStatus, load: updateStatusLoader } = usePostMethod();

  const myPermission = () =>
    reducers.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'collectLet',
    );

  useEffect(() => {
    async function fetchAsyncData() {
      await getPaymentTypes({
        url: '/receivable/collectionTypes/',
        objFilters: {
          page: 1,
          perpage: 1000,
          eaccount: reducers.loginReducer.currentAccount.id,
          status: 1,
        },
        token: reducers.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    reducers.loginReducer.currentAccount.id,
    reducers.loginReducer.Authorization,
    getPaymentTypes,
  ]);

  useEffect(() => {
    (async function () {
      try {
        dispatch({ type: 'LOADING', payload: true });

        let res = await getCollectionsLetters(filters, reducers.loginReducer?.Authorization);
        setCollectionLetters({
          result: res.results,
          rowCount: res.row_count,
        });
        if (!res.success) {
          responseSwal({ icon: 'error', title: 'Error', text: res.message });
        }
      } finally {
        dispatch({ type: 'LOADING', payload: false });
      }
    })();
  }, [dispatch, filters, reducers.loginReducer?.Authorization]);

  const viewDetalis = async id => {
    try {
      setIsLoading(true);
      let res = await getCollectionsLettersDetail(
        {
          id: id,
          eaccount: reducers.loginReducer.currentAccount.id,
        },
        reducers.loginReducer?.Authorization,
      );
      if (!res.success) {
        responseSwal({ icon: 'error', title: 'Error', text: res.message });
        return;
      }
      const letter = res.results?.[0];
      setViewDetails({
        isOpen: true,
        data:
          letter.header +
          letter.greetings +
          letter.subject +
          letter.body +
          letter.good_bye +
          letter.signature,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const editDetalis = id => {
    history.push({
      pathname: `/administracion/TMDeCartera/DetalleCarta/${id}`,
      state: { edit: true },
    });
  };

  const handleSumit = e => {
    e.preventDefault();
    const { search } = e.target;
    setFilters({ ...filters, page: 1, name: search.value });
  };

  const onUpdateStatus = item => {
    updateStatus({
      token: reducers.loginReducer.Authorization,
      body: {
        id: item?.id,
        status: item?.status === '1' ? 0 : 1,
        eaccount: reducers.loginReducer.currentAccount.id,
        collectionType: item?.collection_type_tag,
      },
      method: 'PUT',
      url: '/receivable/collectionLetterTemplate/',
      succesAction: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: result.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            try {
              dispatch({ type: 'LOADING', payload: true });
              let res = await getCollectionsLetters(filters, reducers.loginReducer?.Authorization);
              setCollectionLetters({
                result: res.results,
                rowCount: res.row_count,
              });
            } finally {
              dispatch({ type: 'LOADING', payload: false });
            }
          }
        });
      },
    });
  };

  const renderRow = () => {
    return collectionLetters.result?.map((item, key) => {
      return (
        <tr key={key} className='text-start'>
          <td className='ps-2'>{item.collection_type_name}</td>
          <td>{item.name}</td>
          <td className='text-center'>
            <div
              className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
            >
              {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
            </div>
          </td>
          <td>
            <CustomPopupExtend
              triggerSrc={threeDots}
              showEdit={true}
              showEnable={true}
              position='right top'
              isEnabled={item.status === '1'}
              editText='Editar'
              editClickEvent={() => editDetalis(item.id)}
              showDetails={true}
              showDetailsEvent={() => viewDetalis(item.id)}
              textDetails='Ver Detalle'
              noHover
              enableClickEvent={() => {
                customSwaltAlert({
                  icon: 'warning',
                  title: '¿Está seguro?',
                  text: `Se ${item.status === '1' ? 'deshabilitará' : 'habilitará'} la carta de cobro: ${item.name}`,
                  confirmButtonText: 'Si, continuar',
                  cancelButtonText: 'Cancelar',
                  showCancelButton: true,
                }).then(result => {
                  if (result.isConfirmed) {
                    onUpdateStatus(item);
                  }
                });
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const formattedPaymentTypes =
    paymentTypes.results?.map(el => ({
      label: el.name,
      value: el.id,
    })) || [];

  const renderFilters = () => {
    return (
      <div className='d-flex align-items-end mt-3'>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Tipo de cobro</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            onChange={option => setFilters({ ...filters, collectionType: option.value, page: 1 })}
            styles={customSelectNewDark}
            options={[{ label: 'Seleccionar...', value: '' }, ...formattedPaymentTypes]}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <form onSubmit={handleSumit} className='d-flex me-3 w-25'>
          <input
            type='text'
            placeholder='Buscar...'
            className={tableStyles.SearchNew}
            name='search'
          />
          <img
            height={22}
            src={Lupa}
            alt='User icon'
            className={`${tableStyles.iconSvgMargin2} pointer`}
            onClick={() => {
              setFilters({ ...filters, page: 1 });
            }}
          />
        </form>
        <div className='flex-grow-1'></div>

        {!!myPermission().create && (
          <div
            className={tableStyles.buttonTextPrimary}
            onClick={() => history.push('/administracion/TMDeCartera/CrearCarta')}
          >
            <small className='mr-2' style={{ whiteSpace: 'nowrap' }}>
              Crear carta de cobro
            </small>
            <AddCircleOutline stroke='2' fontSize='small' />
          </div>
        )}
      </div>
    );
  };

  const render = () => {
    return (
      <>
        {isLoading && loader}
        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            {!myPermission().read && <Redirect to='/administracion/inicio' />}

            {(reducers.payrollReducer.loading || updateStatusLoader) && (
              <div className='loading'>
                <Loader type='Oval' color='#003f80' height={100} width={100} />
              </div>
            )}

            <div className='d-flex px-5 flex-column container-fluid'>
              <h1>Cartas de cobro</h1>
              {/* filters section */}
              {renderFilters()}

              {/* table */}
              <GenericTableNew
                dark={true}
                headers={[
                  <th key={1} className='text-start ps-2'>
                    Tipo de cobro
                  </th>,
                  <th key={2} className='text-start'>
                    Nombre carta de cobro
                  </th>,
                  <th key={3} className='text-center'>
                    Estado
                  </th>,
                  <th key={4} style={{ width: '5%' }}></th>,
                ]}
              >
                {renderRow()}
              </GenericTableNew>
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {filters.page}
                  {' de '}
                  {Math.ceil(collectionLetters.rowCount / filters.perpage)
                    ? Math.ceil(collectionLetters.rowCount / filters.perpage)
                    : '1'}{' '}
                  ({collectionLetters.rowCount} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={collectionLetters.rowCount}
                  pageRangeDisplayed={5}
                  onChange={page => setFilters({ ...filters, page: page })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </div>
          </div>
        </div>
        <LetterPreview
          content={viewDetails.data}
          isOpen={viewDetails.isOpen}
          onClose={() => setViewDetails({ isOpen: false, data: '' })}
        />
      </>
    );
  };

  return render();
};

export default CollectionLetter;
