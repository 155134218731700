import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useDispatch, useSelector } from 'react-redux';
import { getEntryWarehouse } from '../../actions/InvoiceReceiptActions';
import {
  create_Orderpay,
  getOnePinvoice,
  pendingVendorInvoices,
} from '../../actions/paymentActions';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import NumberFormat from 'react-number-format';
import Loader from 'react-loader-spinner';
import { Tooltip } from '@material-ui/core';
import {
  GET_ENTRY_WAREHOUSE,
  GET_PENDING_VENDOR_INVOICES,
  GET_ONE_PINVOICE,
} from '../../actions/actionTypes';
import { customSwaltAlert } from '../../helpers';
import { Title } from '../../shared';

function PaymentOrders() {
  let history = useHistory();
  // funcion para formatear valores de respuesta
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'PaymentOrd',
    );
  useEffect(() => {
    if (!myPermission()?.read || !myPermission()?.read) {
      history.push('/tesoreria/inicio');
    }
  });

  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);

  useEffect(() => {
    dispatch(getEntryWarehouse({ account: counter.loginReducer.currentAccount.id }));
    setselectedList1([]);
    setDataCreateOrder({
      entity_account: counter.loginReducer.currentAccount.id,
      details: '',
      created_by: counter.loginReducer.user_data.id,
      rel_payorder_pinvoices: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const [getSearch, setSearch] = useState({});

  const [selectedList, setselectedList] = useState([]);

  var numeros = selectedList,
    totalSelect = 0;
  if (Array.isArray(selectedList)) {
    numeros.forEach(function (numero) {
      totalSelect += numero.total_amount;
    });
  }

  const [Provaider, setProvaider] = useState({
    provider_nit: '',
    provider_name: '',
    provider_id: '',
  });
  const [selectedList1, setselectedList1] = useState([]);
  const [detallefactura, setdetallefactura] = useState([]);
  const [dataCreateOrder, setDataCreateOrder] = useState({
    entity_account: counter.loginReducer.currentAccount.id,
    details: '',
    created_by: counter.loginReducer.user_data.id,
    rel_payorder_pinvoices: [],
  });

  const isBoxSelected = id_req => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id_req);
      return !!target;
    }
  };

  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    // let tempSelected2 = selectedList1;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      // let target2 = tempSelected2.find(x => x.id == item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }
      setselectedList([...tempSelected]);
    }
  };
  const changeBoxSelected2 = item => {
    let tempSelected2 = selectedList1;
    if (Array.isArray(tempSelected2)) {
      let target2 = tempSelected2.find(x => x.id === item.id);

      if (target2) {
        tempSelected2 = tempSelected2.filter(x => x.id !== item.id);
      }
      setselectedList1(tempSelected2);
    }
  };

  const isBoxAllSelected = () => {
    let res = true;

    if (Array.isArray(counter.paymentReducer.results) && Array.isArray(selectedList)) {
      counter.paymentReducer.results.forEach(item => {
        if (selectedList.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(counter.paymentReducer.results) && Array.isArray(tempSelected)) {
        counter.paymentReducer.results.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };
  const difDate = f => {
    let curr = new Date();
    curr.setDate(curr.getDate() - 1);
    let date = curr.toISOString().substr(0, 10);
    let date_1 = new Date(date);
    let date_2 = new Date(f);

    let day_as_milliseconds = 86400000;
    let diff_in_millisenconds = date_2 - date_1;
    let diff_in_days = diff_in_millisenconds / day_as_milliseconds;
    return diff_in_days;
  };

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeRadius] = useState('20px');
  const [changeValue, setChangeValue] = useState({ valor: 0, errorClass: 'd-none' });
  const headertableOrderPay = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha de vencimiento
    </th>,
    <th key={3} className='text-end'>
      Neto a pagar
    </th>,
    <th key={4}>ㅤ</th>,
    <th key={5}>ㅤ</th>,
  ];

  const headerModal = [
    <th key={1} className='text-center'>
      <input
        className='border border-dark-blue form-check-input p1 check-dark-blue'
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2} className='text-center'>
      No. Factura
    </th>,
    <th key={3} className='text-center'>
      Vence
    </th>,
    <th key={4} className='text-center'>
      Neto a pagar
    </th>,
  ];
  const bodyModal = [];
  if (Array.isArray(counter.paymentReducer.results)) {
    counter.paymentReducer?.results?.forEach((item, index) => {
      let dif = difDate(item.due_date);
      let color = dif <= 0 ? '#EC1C24' : dif >= 1 && dif <= 5 ? '#E9B419' : '#00A551';
      let tooltip =
        dif <= 0 ? 'Vencida' : dif >= 1 && dif <= 5 ? 'Próxima a Vencer' : 'Lejos de Vencer';
      bodyModal.push(
        <Tooltip key={index} title={tooltip}>
          <tr key={index}>
            <td className='text-center'>
              <input
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                type='checkbox'
                checked={isBoxSelected(item.id)}
                onChange={() => changeBoxSelected(item)}
              />
            </td>
            <td className='text-center' style={{ color: color, fontWeight: '600' }}>
              {item.invoice_nbr}
            </td>
            <td className='text-center' style={{ color: color, fontWeight: '600' }}>
              {convertDateToLatinFormat(item.due_date)}
            </td>
            <td className='text-center' style={{ color: color, fontWeight: '600' }}>
              ${numberWithCommas(item?.total_amount)}
            </td>
          </tr>
        </Tooltip>,
      );
    });
  }
  let optionEntryWareHouse = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.InvoiceReceiptReducer.resultsEntryWareHouse)) {
    counter.InvoiceReceiptReducer.resultsEntryWareHouse.forEach(item => {
      const label = `${item.nit} - ${item.name} ${item.we_entry > 0 ? '*' : ''}`;
      optionEntryWareHouse.push({
        value: item.id,
        label: label,
        valueNit: item.nit,
        valueName: item.name,
        we_entry: item.we_entry,
      });
    });
  }
  const nfo_modal = () => {
    setShowModal(true);
    if (Provaider.provider_id) {
      dispatch(
        pendingVendorInvoices(
          {
            multistatus: [3, 4],
            provider_id: Provaider.provider_id,
            search: getSearch.search ? getSearch.search : '',
            due_date: getSearch.due_date ? getSearch.due_date : '',
          },
          () => setLoading(false),
        ),
      );
      setselectedList([...selectedList1]);
    }
  };

  const searchModal = () => {
    if (Provaider.provider_id) {
      dispatch(
        pendingVendorInvoices(
          {
            multistatus: [3, 4],
            provider_id: Provaider.provider_id,
            search: getSearch.search ? getSearch.search : '',
            due_date: getSearch.due_date ? getSearch.due_date : '',
          },
          () => setLoading(false),
        ),
      );
    }
  };

  const Fundetallefactura = x => {
    setShowModal2(true);
    dispatch(getOnePinvoice(x.id));
    setdetallefactura(x);
  };

  const detallefactura123 = x => {
    setselectedList1(x);
    setShowModal(false);
    setSearch({
      ...getSearch,
      search: '',
      due_date: '',
    });
  };

  const envioDeDato = () => {
    // selectedList1.forEach((item) => {
    //     dataCreateOrder.rel_payorder_pinvoices.push({ pinvoice: item.id, amount: item.total_amount })
    // })
    const templist = selectedList1.map(item => ({ pinvoice: item.id, amount: item.total_amount }));
    dispatch(
      create_Orderpay(
        {
          ...dataCreateOrder,
          rel_payorder_pinvoices: templist,
          amount_to_pay: totalSelect ? totalSelect : '',
          provider: Provaider['provider_id'],
        },
        {},
        () => resetStatus(),
      ),
    );
  };

  const bodytableOrderPay = [];
  const footertableOrderPay = [];
  if (Array.isArray(selectedList1)) {
    selectedList1.forEach(item => {
      bodytableOrderPay.push(
        <tr>
          <td className='text-center'>{item.invoice_nbr}</td>
          <td className='text-center'>{convertDateToLatinFormat(item.due_date)}</td>
          <td className='text-end'>
            ${numberWithCommas(item?.changedAmount ? item?.changedAmount : item?.total_amount)}{' '}
          </td>
          <td className='text-center'>
            <img
              alt=''
              onClick={() => Fundetallefactura(item)}
              className='ml-2'
              style={{ width: '' }}
              src={Filtrar}
            />
          </td>
          <td className='text-center'>
            <div onClick={() => changeBoxSelected2(item)}>
              <b style={{ color: '#005DBF' }}>X</b>
            </div>
          </td>
        </tr>,
      );
    });

    let suma = 0;
    numeros.forEach(function (numero) {
      suma += numero.changedAmount ? numero.changedAmount : numero.total_amount;
    });
    footertableOrderPay.push(
      <td className='text-center'></td>,
      <td
        className='text-center'
        style={{
          color: '#005DBF',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        Saldo
      </td>,
      <td
        className='text-end'
        style={{
          color: '#005DBF',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        ${numberWithCommas(suma)}
      </td>,
      <td className='text-center'></td>,
    );
  }

  const changeProvider = value => {
    if (value.we_entry > 0) {
      setProvaider({
        provider_nit: '',
        provider_name: '',
        provider_id: '',
      });
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El proveedor seleccionado tiene entradas de almacén pendientes por legalizar',
        showCancelButton: false,
      });
      // setProvaider({
      //     ...Provaider,
      //     provider_nit: 0,
      //     provider_name: "",
      //     provider_id: 0
      // })
    } else {
      setProvaider({
        ...Provaider,
        provider_nit: value.valueNit,
        provider_name: value.valueName,
        provider_id: value.value,
      });
      setTrigger2(trigger2 + 1);
      setselectedList([]);
      setselectedList1([]);
      setDataCreateOrder({
        entity_account: counter.loginReducer.currentAccount.id,
        details: '',
        created_by: counter.loginReducer.user_data.id,
        rel_payorder_pinvoices: [],
      });
    }
  };

  const validateValue = id => {
    let auxi = null;
    selectedList1.forEach((item, index) => {
      if (item.id === id) auxi = index;
    });
    if (changeValue.valor > selectedList1[auxi].total_amount) {
      // if (changeValue.valor > counter.paymentReducer?.resultsOnePinvoices?.total_amount) {
      let error = document.getElementById('messageError');
      error.classList.remove('d-none');
    } else {
      let error = document.getElementById('messageError');
      error.classList.add('d-none');
      // selectedList1[auxi].total_amount = changeValue.valor
      selectedList1[auxi].changedAmount = changeValue.valor;
      setShowModal2(false);
      setChangeValue({ valor: 0, errorClass: 'd-none' });
    }
  };
  const closeModalInvoice = () => {
    setShowModal(false);
    setSearch({
      ...getSearch,
      search: '',
      due_date: '',
    });
  };
  const resetStatus = () => {
    setTrigger(trigger + 1);
    setTrigger2(trigger2 + 1);
    setProvaider({
      provider_nit: '',
      provider_name: '',
      provider_id: '',
    });
    setselectedList1({});
    setselectedList({});
    setdetallefactura({});
    dispatch({
      type: GET_PENDING_VENDOR_INVOICES,
      payload: {},
    });
    dispatch({
      type: GET_ENTRY_WAREHOUSE,
      payload: {},
    });
    dispatch({
      type: GET_ONE_PINVOICE,
      payload: {},
    });
  };
  //console.log("Loading=>",loading)
  return (
    <>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <Title
          title='Ordenes de pago'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/tesoreria/inicio');
          }}
        />
        <div>
          <ModalNew
            show={showModal2}
            onHide={() => setShowModal2(false)}
            title={'Detalle de factura'}
            subtitle={detallefactura?.invoice_nbr}
            hideCancelButton
            btnYesEvent={() => {
              validateValue(counter.paymentReducer.resultsOnePinvoices.id);
            }}
            btnYesName={'Aceptar'}
            size={'550'}
          >
            <div>
              <div className='display-grid'>
                <label className={tableStyles.crudModalLabel}>Tercero</label>
                <input
                  className={IndividualStyles.registerInputsGris}
                  type='text'
                  value={
                    counter.paymentReducer.resultsOnePinvoices.provider_name
                      ? counter.paymentReducer.resultsOnePinvoices.provider_name
                      : '-'
                  }
                  disabled
                />
              </div>
              <div className='d-flex'>
                <div className='display-grid mr-3'>
                  <label className={tableStyles.crudModalLabel}>Vencimiento</label>
                  <input
                    className={IndividualStyles.registerInputsBlue}
                    type='text'
                    disabled
                    value={
                      counter.paymentReducer.resultsOnePinvoices.due_date
                        ? convertDateToLatinFormat(
                            counter.paymentReducer.resultsOnePinvoices.due_date,
                          )
                        : '-'
                    }
                  />
                </div>
                <div className='display-grid'>
                  <label className={tableStyles.crudModalLabel}>Saldo</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter.paymentReducer?.resultsOnePinvoices?.total_amount
                        ? counter.paymentReducer?.resultsOnePinvoices?.total_amount
                        : '0'
                    }
                  />
                </div>
              </div>
              <div className='mt-4'>
                <h5 className={tableStyles.titleTax}>Impuesto</h5>
              </div>
              <div className='d-flex'>
                <div className='display-grid mr-3'>
                  <label className={tableStyles.crudModalLabel}>Valor antes de IVA</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter.paymentReducer?.resultsOnePinvoices?.base_amount
                        ? counter.paymentReducer?.resultsOnePinvoices?.base_amount
                        : '0'
                    }
                  />
                </div>
                <div className='display-grid'>
                  <label className={tableStyles.crudModalLabel}>IVA</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter.paymentReducer?.resultsOnePinvoices?.tax_amount
                        ? counter.paymentReducer?.resultsOnePinvoices?.tax_amount
                        : '0'
                    }
                  />
                </div>
              </div>
              <div className='display-grid'>
                <label className={tableStyles.crudModalLabel}>Intereses por Mora</label>
                <NumberFormat
                  prefix='$'
                  allowNegative={false}
                  className={`${IndividualStyles.registerInputsGris} w-100`}
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  disabled
                  value={
                    counter.paymentReducer?.resultsOnePinvoices?.late_pay_int
                      ? counter.paymentReducer?.resultsOnePinvoices?.late_pay_int
                      : '0'
                  }
                />
              </div>
              <div className='d-flex'>
                <div className='display-grid mr-3'>
                  <label className={tableStyles.crudModalLabel}>ReteIVA</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter.paymentReducer?.resultsOnePinvoices?.rete_iva
                        ? counter.paymentReducer?.resultsOnePinvoices?.rete_iva
                        : '0'
                    }
                  />
                </div>
                <div className='display-grid'>
                  <label className={tableStyles.crudModalLabel}>ReteICA</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter.paymentReducer?.resultsOnePinvoices?.rete_ica
                        ? counter.paymentReducer?.resultsOnePinvoices?.rete_ica
                        : '0'
                    }
                  />
                </div>
              </div>
              <div className='d-flex'>
                <div className='display-grid mr-3'>
                  <label className={tableStyles.crudModalLabel}>Si paga antes del</label>
                  <input
                    className={IndividualStyles.registerInputsBlue}
                    type='text'
                    disabled
                    // value={counter.paymentReducer.resultsOnePinvoices?.base_amount ?
                    //     counter.paymentReducer.resultsOnePinvoices?.base_amount
                    //     : 0}
                  />
                </div>
                <div className='display-grid'>
                  <label className={tableStyles.crudModalLabel}>Descuento</label>
                  <NumberFormat
                    prefix='$'
                    allowNegative={false}
                    className={`${IndividualStyles.registerInputsGris} w-100`}
                    thousandsGroupStyle='thousand'
                    thousandSeparator
                    disabled
                    value={
                      counter?.paymentReducer?.resultsOnePinvoices?.discount
                        ? counter?.paymentReducer?.resultsOnePinvoices?.discount
                        : '0'
                    }
                  />
                </div>
              </div>
              <div className=' col-6 display-grid'>
                <label className={tableStyles.crudModalLabel}>NETO A PAGAR</label>
                <NumberFormat
                  prefix='$'
                  allowNegative={false}
                  className={`${IndividualStyles.registerInputsGris} w-100`}
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  name='foreignBankAmount'
                  value={
                    //changeValue?.valor ?
                    changeValue.valor
                    //:
                    //counter.paymentReducer?.resultsOnePinvoices?.total_amount
                  }
                  onValueChange={e => {
                    if (e.floatValue > counter.paymentReducer?.resultsOnePinvoices?.total_amount) {
                      // let error = document.getElementById("messageError")
                      // error.classList.remove("d-none");
                      setChangeValue({
                        ...changeValue,
                        valor: counter.paymentReducer?.resultsOnePinvoices?.total_amount,
                        errorClass: '',
                      });
                    } else {
                      // let error = document.getElementById("messageError")
                      // error.classList.add("d-none");
                      setChangeValue({
                        ...changeValue,
                        valor: e.floatValue,
                        errorClass: 'd-none',
                      });
                    }
                  }}
                />
                <label
                  className={`${changeValue.errorClass} ${tableStyles.crudModalLabel}`}
                  style={{ color: 'red' }}
                  id='messageError'
                >
                  ESTE CAMPO NO PUEDE SER <br />
                  MAYOR AL SALDO
                </label>
              </div>
            </div>
          </ModalNew>

          <ModalNew
            show={showModal}
            onHide={() => closeModalInvoice()}
            title={'Facturas por pagar'}
            subtitle={Provaider.provider_nit + ' - ' + Provaider.provider_name}
            btnYesEvent={() => detallefactura123(selectedList)}
            btnYesName={'Seleccionar'}
            size={'500'}
          >
            <Row className='d-flex'>
              <Col xs={5}>
                <form
                  onSubmit={event => {
                    event.preventDefault();
                    searchModal();
                    setLoading(true);
                  }}
                >
                  <label className={tableStyles.crudModalLabel}>No. factura</label>
                  <input
                    placeholder='Escribir...'
                    className={IndividualStyles.registerInputsGris}
                    type='text'
                    onChange={e =>
                      setSearch({
                        ...getSearch,
                        search: e.target.value,
                      })
                    }
                  ></input>
                </form>
              </Col>
              <Col xs={5}>
                <label className={tableStyles.crudModalLabel}>Fecha</label>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  onChange={e =>
                    setSearch({
                      ...getSearch,
                      due_date: e.target.value,
                    })
                  }
                ></input>
              </Col>
              <Col xs={2} className='d-flex align-items-end'>
                <i
                  onClick={() => {
                    searchModal();
                    setLoading(true);
                  }}
                >
                  <img
                    className='pointer'
                    alt='buscar'
                    style={{ width: '25px', marginTop: '25px' }}
                    src={Lupa}
                  />
                </i>
              </Col>
            </Row>
            <GenericTable headers={headerModal}>{bodyModal}</GenericTable>
            <Row className='d-flex mb-4'>
              <Col xs={6}>
                <label className={tableStyles.crudModalLabel}>Total seleccionado</label>
                <NumberFormat
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsGris}`}
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  value={totalSelect}
                  disabled={true}
                  // onValueChange={(e) => setCreate({
                  //     ...getCreate,
                  //     amount: e.value
                  // })}
                />
              </Col>
            </Row>
          </ModalNew>
          <div className='div'>
            <div className='d-flex flex-column mr-3' style={{ flex: 1 }}>
              <label className={tableStyles.crudModalLabel}>
                Proveedor
                <span className='text-warning'>*</span>
              </label>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                key={'proveedor' + trigger}
                styles={customSelectNewDark}
                options={optionEntryWareHouse}
                // value={optionEntryWareHouse.find(x => x.value === Provaider.provider_id)}
                onChange={e => changeProvider(e)}
              />
            </div>
            <div className='d-flex flex-column' style={{ flex: 1 }}>
              <label className={tableStyles.crudModalLabel}>
                Detalle
                <span className='text-warning'>*</span>
              </label>
              <input
                key={'detallew' + trigger2}
                className={IndividualStyles.registerInputsGris}
                type='text'
                placeholder='Escribir...'
                onChange={e =>
                  setDataCreateOrder({
                    ...dataCreateOrder,
                    details: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <button
            onClick={() => (Provaider.provider_nit === '' ? null : (nfo_modal(), setLoading(true)))}
            className='text-start mt-3'
            style={{
              color: Provaider.provider_nit === '' ? '#005DBF' : '#005DBF',
              backgroundColor: Provaider.provider_nit === '' ? '#F2F7FC' : '#F2F7FC',
              borderRadius: `20px 20px ${changeRadius} ${changeRadius}`,
              padding: '10px',
              width: '100%',
            }}
          >
            <b>+ Agregar factura a pagar</b>
          </button>
        </div>

        <GenericTable headers={headertableOrderPay} footer={footertableOrderPay} dark={true}>
          {bodytableOrderPay}
        </GenericTable>
        <div className='d-flex justify-content-end py-5'>
          <button className={`mr-3 ${tableStyles.btnSecondary}`} onClick={() => resetStatus()}>
            Cancelar
          </button>

          <button
            className={tableStyles.btnPrimary}
            onClick={() => envioDeDato()}
            disabled={selectedList1.length <= 0 || dataCreateOrder.details === '' ? true : false}
          >
            Crear OP
          </button>
        </div>
      </div>
    </>
  );
}
export default PaymentOrders;
