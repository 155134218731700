import {
    GET_DEDUCTION_ACCRUALS,
    GET_THIRD_PARTIES,
    GET_DOCUMENT_TYPES,
    GET_RECIPIENT_TYPES,

  } from "../actions/actionTypes";
  
  const initialState = {
    allDeductionsAccruals: [],
    totalDeductionAccruals: 0,
    allThirdParties: [],
    allDocTypes : [],
    allRecipientTypes : [],
    Authorization:"",
    loading: true
   };
  export const deductionsAccrualsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_DEDUCTION_ACCRUALS:
            return{
                ...state,
                allDeductionsAccruals: action.payload,
                totalDeductionAccruals: action.total,
                Authorization:action.Authorization,
                loading: action.loading
            };
        case GET_THIRD_PARTIES:
            return{
                ...state,
                allThirdParties: action.payload,
                Authorization:action.Authorization
            };
        case GET_DOCUMENT_TYPES:
            return{
                ...state,
                allDocTypes: action.payload,
                Authorization:action.Authorization
            };
        case GET_RECIPIENT_TYPES:
            return{
                ...state,
                allRecipientTypes: action.payload,
                Authorization:action.Authorization
            };
        default:
            return state;
    }
    
  }