import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bankMoveStyle from '../../TreasuryModule/MovimientosBancarios/bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button, Col, Row } from 'react-bootstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { getListCompanies } from '../../actions/billingActions';
import { getSettledBilling, getGlossType, postGloss } from '../../actions/GlossActions';
import { customSwaltAlert } from './../../helpers/customSwaltAlert';
import { Title } from '../../shared';

function GlossRegister() {
  let history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(0);

  const [filters, setFilters] = useState({
    eaccount: counter.loginReducer.currentAccount.id,
    user: counter.loginReducer.user_data.id,
    total: '',
  });

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'Glosesregi',
  );

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };
  useEffect(() => {
    if (!myPermission?.create) {
      history.push('/categoryselect');
    }

    dispatch(
      getListCompanies({
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getGlossType({
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters.client) {
      dispatch(
        getSettledBilling({
          client: filters.client,
          eaccount: counter.loginReducer.currentAccount.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  let optionCompanies = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.billingReducer.companies)) {
    counter.billingReducer.companies.forEach(item => {
      optionCompanies.push({
        key: item.id,
        value: item.id,
        label: item.copyName,

        id: item.id,
        companyType: item.companyType,
        copyName: item.copyName,
        notification_email: item.notification_email,
        Nit: item.Nit,
        address: item.address,
        phone: item.phone,
        email: item.email,
        cityId: item.cityId,
        city: item.city,
      });
    });
  }

  let optionFacturas = counter.glossReducer.facturas_radicadas?.length
    ? [{ label: 'Seleccionar...', value: '' }]
    : [];
  if (Array.isArray(counter.glossReducer.facturas_radicadas)) {
    counter.glossReducer.facturas_radicadas.forEach(item => {
      optionFacturas.push({
        key: item.id,
        value: item.id,
        label: item.sequence,

        id: item.id,
        total_amount: item.total_amount,
        pending_amount: item.pending_amount,
        sequence: item.sequence,
        exp_date: item.exp_date,
        corporate_client: item.corporate_client,
        clientName: item.clientName,
        clientNit: item.clientNit,
        contract_name: item.contract_name,
        prefix: item.prefix,
        status_background: item.status_background,
        status_fontcolor: item.status_fontcolor,
        status: item.value,
      });
    });
  }

  let optionGlossType = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.glossReducer.gloss_types)) {
    counter.glossReducer.gloss_types.forEach(item => {
      optionGlossType.push({
        key: item.id,
        value: item.id,
        label: item.obj_name,

        id: item.id,
        obj_code: item.obj_code,
        obj_concept: item.obj_concept,
        obj_name: item.obj_name,
        fullname: item.fullname,
      });
    });
  }

  const resetFields = () => {
    setFilters({
      eaccount: counter.loginReducer.currentAccount.id,
      user: counter.loginReducer.user_data.id,
    });
    setTrigger(trigger + 1);
  };

  const okButtonDisabled =
    !!filters.client === false ||
    !!filters.invoice === false ||
    !!filters.type === false ||
    !!filters.date === false ||
    !!filters.value === false ||
    !!filters.accountable === false ||
    !!filters.registrator === false ||
    !!filters.observations === false;

  const okButton = () => {
    let error = '';

    if (!!filters.client === false) {
      error = 'Seleccione una empresa';
    }
    if (!!filters.invoice === false) {
      error = 'Seleccione una factura';
    }
    if (!!filters.type === false) {
      error = 'Tipo de glosa es de caracter obligatorio';
    }
    if (!!filters.date === false) {
      error = 'Fecha es de caracter obligatorio';
    }
    if (!!filters.value === false) {
      error = 'Valor de glosa es de caracter obligatorio';
    }
    if (!!filters.accountable === false) {
      error = 'Responsable de glosa es de caracter obligatorio';
    }
    if (!!filters.registrator === false) {
      error = 'Responsable de registro es de caracter obligatorio';
    }
    if (!!filters.observations === false) {
      error = 'Escriba una descripción';
    }

    if (error) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

      return null;
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
      confirmButtonText: 'Sí, Continuar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(postGloss(filters, () => resetFields()));
      }
    });
  };

  return (
    <>
      {counter.glossReducer.gloss_register_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall} style={{ marginTop: '30px' }}>
              <Title
                title='Registro de glosas'
                className={'mb-2'}
                onClickIcon={() => {
                  history.push('/facturacion/inicio');
                }}
              />
            </div>
          </div>
          <div className={`pt-3 pb-3`}>
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <Row className='d-flex'>
              <Col xs={6}>
                <p className={tableStyles.crudModalLabel}>Empresa</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'empresa' + trigger}
                  value={optionCompanies.find(option => option.id === filters.client)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      client: e.id,
                    })
                  }
                  options={optionCompanies}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </Col>

              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>No. Factura</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'no.' + trigger}
                  value={optionFacturas.find(option => option.id === filters.invoice)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      invoice: e.id,
                      total: e.total_amount,
                    })
                  }
                  options={optionFacturas}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </Col>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Total factura</p>
                <input
                  placeholder='Escribir...'
                  key={'totalAmount' + trigger}
                  style={{ backgroundColor: '#F5F7FA' }}
                  className='register-inputs'
                  name='total_Amount'
                  type='text'
                  value={filters.total ? '$' + numberWithCommas(filters.total) : ''}
                  readOnly
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={8}>
                <p className={tableStyles.crudModalLabel}>Tipo de glosa</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'type' + trigger}
                  value={optionGlossType.find(option => option.id === filters.type)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      type: e.id,
                    })
                  }
                  options={optionGlossType}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Fecha de glosa</p>

                <div className={tableStyles.deleteicon}>
                  {filters.date ? (
                    <span onClick={() => setFilters({ ...filters, date: '' })}>x</span>
                  ) : (
                    ''
                  )}
                  <input
                    key={'date' + trigger}
                    className='register-inputs'
                    style={{ color: '#005DBF', fontWeight: 'bold' }}
                    name='requisition_date'
                    type='date'
                    value={filters.date}
                    onChange={e => setFilters({ ...filters, date: e.target.value })}
                    min={new Date().toISOString().slice(0, 10)}
                  />
                </div>
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Valor de la glosa</p>
                <NumberFormat
                  placeholder='Escribir...'
                  key={'value' + trigger}
                  allowNegative={false}
                  className={`register-inputs`}
                  name='requisition_date'
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  onValueChange={values => {
                    const { floatValue } = values;
                    setFilters({ ...filters, value: floatValue });
                  }}
                  isAllowed={values => {
                    const { floatValue } = values;
                    if (floatValue > Number(filters.total)) {
                      return false;
                    } else {
                      if (Number(floatValue) !== '') {
                        if (floatValue <= 0) {
                          return false;
                        } else {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    }
                  }}
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Responsable de glosa</p>
                <input
                  placeholder='Escribir...'
                  key={'resp' + trigger}
                  className='register-inputs'
                  name='requisition_date'
                  type='text'
                  value={filters.accountable}
                  onChange={e => setFilters({ ...filters, accountable: e.target.value })}
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Responsable de registro</p>
                <input
                  placeholder='Escribir...'
                  key={'regi' + trigger}
                  className='register-inputs'
                  name='requisition_date'
                  type='text'
                  value={filters.registrator}
                  onChange={e => setFilters({ ...filters, registrator: e.target.value })}
                />
              </Col>
            </Row>

            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <Row className='d-flex'>
              <Col>
                <p className={tableStyles.crudModalLabel}>Descripción</p>
                <textarea
                  key={'area' + trigger}
                  style={{ height: '7rem', maxHeight: '7rem' }}
                  placeholder='Escribir...'
                  rows='5'
                  cols='40'
                  className='register-inputs'
                  name='requisition_date'
                  value={filters.observations}
                  onChange={e => setFilters({ ...filters, observations: e.target.value })}
                />
              </Col>
            </Row>
            {/*--------------------------------- ROW 4 --------------------------------- */}
            <Row className='d-flex'>
              <Col xs={12} className='d-flex' style={{ justifyContent: 'flex-end' }}>
                <Button
                  style={{ outline: 0, padding: 8 }}
                  className={`${tableStyles.btnPrimary} mt-4`}
                  onClick={() => okButton()}
                  disabled={okButtonDisabled}
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
export default GlossRegister;
