//import SASS && Mui material && icons
import { Box } from '@mui/material';
import tableStyle from "../../../components/Layouts/tableStyle.module.scss";

export const GridInfoCite = ({ title, subTitle, printData, mt = 0 }) => {
  // ${tableStyle.elipsis} 
  return (
    <Box mt={mt}>
      <Box className={`${tableStyle.f16} ${tableStyle.ordAquaMarineText} fw-bold`}>{title}</Box>
      <Box mb={1} className={`${tableStyle.f16} ${tableStyle.ordDarkBlueVariantNew}`}>{subTitle}</Box>
      <Box
        className={`${tableStyle.f14} ${tableStyle.ordDarkGrayText}`}
        display={'grid'}
        gridTemplateColumns={'1fr 1fr'}
      >
        {printData && printData()}
      </Box>
    </Box>
  )
}