import lupaClear from '../../assets/img/icons/lupaClear.svg';
import lupaTransparent from '../../assets/img/icons/lupaTransparent.svg';
import GenericTableNew from '../Layouts/GenericTableNew';

import { useMemo, useState } from 'react';

import moment from 'moment';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import ModalNew from '../Layouts/ModalNew';
import '../Layouts/customTabs.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import ReqModalReq from './ReqModalReq';
import reqStyles from './Requisitions.module.scss';

function ModalClone(props) {
  const store = useSelector(state => state);

  const [filters, setFilter] = useState({
    search: '',
    date: '',
    page: 1,
    perpage: 10,
  });

  const [modalCloneControl, setmodalCloneControl] = useState({
    show: false,
    req: {},
  });

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const filteredRequisitions = useMemo(() => {
    let options =
      store.requisitionReducer.requisitions?.filter(
        rr => rr.id_consumption_center === props.selectedCC.value,
      ) || [];

    if (options.length > 0 && filters.search.length > 0) {
      return options.filter(e =>
        e.concept.toLowerCase().includes(filters.search.trim().toLowerCase()),
      );
    }

    return options;
  }, [filters.search, props.selectedCC.value, store.requisitionReducer.requisitions]);

  // const filteredArticles = () => {
  //   let onlyArticles = [];
  //   if (Array.isArray(store.requisitionReducer.requisitions)) {
  //     store.requisitionReducer.requisitions
  //       .filter(rr => rr.id_consumption_center === props.cc_id)
  //       .forEach(x => {
  //         let dateX = new Date(x.requisition_date);
  //         //no se por que esto no explota pero bueno ahi va
  //         // ☝ que este comentario de arriba quede como recuerdo de lo que NO hay que hacer.
  //         // NO hagamos código mediocre, hagamos código de calidad.
  //         let dateInput = filters.date.split('-');
  //         dateInput = new Date(dateInput[0], dateInput[1] - 1, dateInput[2]);
  //         let filterDate = filters.date
  //           ? dateX?.getDate() === dateInput?.getDate() &&
  //             dateX?.getMonth() === dateInput?.getMonth() &&
  //             dateX?.getFullYear() === dateInput?.getFullYear()
  //           : true;
  //         if (
  //           (x?.concept?.toUpperCase().includes(filters.search.trim().toUpperCase()) ||
  //             x?.num_req?.toString().toUpperCase().includes(filters.search.trim().toUpperCase())) &&
  //           filterDate
  //         ) {
  //           onlyArticles.push(x);
  //         }
  //       });
  //   }

  //   onlyArticles = onlyArticles.sort((a, b) => {
  //     let aa = new Date(a.created_at);
  //     let bb = new Date(b.created_at);
  //     return bb - aa;
  //   });

  //   return onlyArticles;
  // };

  const renderList = () => {
    let table = [];
    if (filteredRequisitions.length > 0) {
      table = filteredRequisitions.map(x => {
        let date = new Date(x.requisition_date);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const formatDate = `${day}/${month}/${year}`;
        return (
          <tr key={'renderList' + x.num_req} className='hover-table-row'>
            <td className={`col-md-2 px-2 text-start`}>{x.num_req}</td>
            <td className={`col-md-3 text-center`}>{formatDate}</td>
            <td className={`col-md-5 text-start`}>{x.concept}</td>
            <td className={`col-md-2`}>
              <img
                src={lupaTransparent}
                alt='detalles'
                className={`icons-popUp ${reqStyles.adjustIcon}`}
                onClick={() => verReqDetalles(x)}
              />
            </td>
          </tr>
        );
      });
    } else {
      table = [
        <tr key={1}>
          <td colSpan={4} className='text-center'>
            No hay requisiciones en el centro de consumo seleccionado.
          </td>
        </tr>,
      ];
    }

    return table;
  };

  const verReqDetalles = req => {
    setmodalCloneControl({
      ...modalCloneControl,
      show: true,
      req: req,
    });
  };

  const paginateList = () => {
    let fullList = renderList();
    fullList = fullList.slice(filters.perpage * (filters.page - 1), filters.perpage * filters.page);
    return fullList;
  };

  const renderHeaders = [
    <th key={`ReqModalClone1`} className='px-2'>
      N° Req
    </th>,
    <th key={`ReqModalClone2`} className='text-center'>
      Fecha
    </th>,
    <th key={`ReqModalClone3`}>Concepto</th>,
    <th key={`ReqModalClone99`}> </th>,
  ];

  const justArticles = () => {
    let articles = [];
    if (Array.isArray(store.requisitionReducer?.req_chosen?.article_list)) {
      articles = store.requisitionReducer.req_chosen.article_list.map(x => {
        return {
          ...x.article,
          ammount: x.qty_article,
        };
      });
    }
    return articles;
  };

  const btnClonar = () => {
    props.savearticles();
    props.setreqState({
      ...props.reqState,
      concept: modalCloneControl.req.concept,
      urgent: modalCloneControl.req.urgent,
      observations: modalCloneControl.req.observations,
      requisition_date: moment(new Date()).format('DD/MM/YYYY'),

      id_consumption_center: modalCloneControl.req.id_consumption_center,
      cc_name: modalCloneControl.req.cc_ref?.description,
      annual_budget: props.selectedCC.annual_budget,
      articulos: justArticles(modalCloneControl.req.article_list),
    });
    setmodalCloneControl({ show: false });
    props.onHide();
  };

  return modalCloneControl.show ? (
    <ReqModalReq
      reqState={modalCloneControl.req}
      reqId={modalCloneControl.req?.id}
      show={true}
      btnClonar={btnClonar}
      onHide={() =>
        setmodalCloneControl({
          ...modalCloneControl,
          show: false,
        })
      }
    />
  ) : (
    <ModalNew title={props.title} show={props.show} size='lg' onHide={props.onHide}>
      <div className={tableStyles.mainRow}>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Fecha</p>

          <div className={tableStyles.deleteicon}>
            {filters.date ? <span onClick={() => setFilter({ ...filters, date: '' })}>x</span> : ''}

            <input
              type='date'
              className={`register-inputs ${tableStyles.crudModalInputDate}`}
              value={filters.date}
              onChange={e => setFilter({ ...filters, date: e.target.value })}
              placeholder='Escribir...'
            />
          </div>
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Concepto</p>
          <div className='d-flex align-items-end'>
            <input
              className='register-inputs'
              type='text'
              placeholder='Escribir...'
              value={filters.search}
              onChange={e =>
                setFilter({
                  ...filters,
                  search: e.target.value,
                })
              }
            />
            <img
              src={lupaClear}
              alt='filtrar'
              className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
            />
          </div>
        </div>
      </div>

      <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>

      <div className={paginationStyles.wrapperReverse}>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={filteredRequisitions.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
          itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
          itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
          itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </ModalNew>
  );
}

export default ModalClone;
