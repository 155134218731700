import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Close from '../../assets/img/icons/modalClose.svg';
import Cerrar from '../../assets/img/icons/Cerrar.svg';

import tableStyles from './tableStyle.module.scss';
import 'animate.css';

export const DialogModalFullScreen = props => {
  const [animation, setAnimation] = useState(
    props.animationIn ? props.animationIn : 'animate__fadeInUp',
  );

  const [image, setImage] = useState(Close);
  return (
    <>
      <div className={`animate__animated  container-fluid ${animation}`}>
        <Row className={`${tableStyles.bgColorClearBlue} d-flex justify-content-between shadow `}>
          <Col xs={1} className='d-flex justify-content-center'>
            <img
              alt='animate'
              onMouseEnter={() => setImage(Cerrar)}
              onMouseLeave={() => setImage(Close)}
              onClick={() => {
                setAnimation(props.animationOut ? props.animationOut : 'animate__fadeOutDown');

                setTimeout(() => {
                  props.closeDialog();
                }, 700);
              }}
              width='30%'
              className={`cursorPointer `}
              src={image}
            ></img>
          </Col>
          <Col xs={11} className={tableStyles.darkBlueText}>
            <p
              style={{
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '35px',
              }}
              className='m-2'
            >
              {props.title ? props.title : 'Your dialog title here! 😁😁😄 '}
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='w-100'>
            {props.children}
          </Col>
        </Row>
      </div>
    </>
  );
};
