//react
import { useEffect, useState } from 'react';

//components
import Select from 'react-select';
import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import { appCustomSelectNew } from '../../../Layouts/react-select-custom';

//hooks
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../../Hooks/useFetch';

//helpers
import { loader } from '../../../../helpers/helpers';

//icons
import ordBlueSearch from '../../../../../src/assets/img/icons/ordBlueSearch.svg';

//css
import { Box } from '@mui/material';
import moment from 'moment';
import styles from '../../../../components/Layouts/tableStyle.module.scss';
import { formatNumberWithoutDecimals } from '../../../../helpers/numberFormatting';

export const QueriesOrderDetail = ({ onBackState, provider }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(0);

  const eaccount = store.loginReducer.currentAccount.id;

  const [filters, setFilters] = useState({
    eaccount,
    dateMax: '',
    dateMin: '',
    search: '',
    generalResponse: 0,
    partialPayments: 0,
    page: 1,
    perpage: 10,
    providerId: provider?.providerId,
    orderTypeId: provider?.type,
  });

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showImportance: false,
    header: [
      {
        title: 'No. Orden',
        className: 'px-2 text-center',
      },
      {
        title: 'Fecha orden',
        className: 'px-2 text-center',
      },
      {
        title: 'Paciente',
        className: 'px-2 text-start',
      },
      {
        title: 'Empresa',
        className: 'px-2 text-start',
      },
      {
        title: 'Contrato',
        className: 'px-2 text-start',
      },
      {
        title: 'Servicio',
        className: 'px-2 text-start col-3',
      },
      {
        title: 'Total',
        className: 'px-3 text-end',
      },
    ],
  });

  const {
    results: listProviderOrders,
    load: loaderListProviderOrders,
    trigger: getListProviderOrders,
  } = useGetMethod();

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-center px-2'>{`${item?.sitePrefix}${item?.id}`}</td>

            <td className='text-center px-2'>
              <div>{moment(item?.date)?.format('YYYY-MM-DD')}</div>
              <div>{moment(item?.date)?.format('h:mm a')}</div>
            </td>

            <td className='text-start px-2'>{item?.patient?.name || '-'}</td>

            <td className='text-start px-2'>{item?.companyName || '-'}</td>

            <td className='text-start px-2'>{item?.contractName || '-'}</td>

            <td className='text-start px-2'>
              <div className={`${styles.ellipsis}`} style={{ width: '300px' }}>
                {item?.orderServices?.map(el => el?.name)?.join() || '-'}
              </div>
            </td>

            <td className='text-end px-3'>
              {item?.totalPaidAmount ? formatNumberWithoutDecimals(item?.totalPaidAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListProviderOrders({
      token: token,
      objFilters: { ...filters },
      url: '/medical/admissions/provider-orders/',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {loaderListProviderOrders && loader}

      <OrdModal
        title='Tipo de importancia'
        show={services.showImportance}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={() => setServices({ ...services, showImportance: false })}
        btnNoEvent={() => setServices({ ...services, showImportance: false })}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={appCustomSelectNew}
          />
        </div>
      </OrdModal>

      <div className='container-fluid pt-3'>
        <OrdGenericTemplate
          showBackArrow
          titleSize='12'
          title='Detalle de ordenes'
          backArrowAction={onBackState}
        >
          <div className={`${styles.app_mr_100} asistencialDateColorIcon`}>
            <Box
              gap={2}
              marginBottom={2}
              display={'grid'}
              gridTemplateColumns={'170px 170px 1fr 160px 210px'}
              alignItems={'end'}
            >
              <Box>
                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                  <b className='text-start'>&nbsp;Fecha desde</b>
                </span>
                <input
                  className={`w-100 ord-roundInput pointer ${styles.inputDate} ${styles.boxShadowNone}`}
                  type='date'
                  value={filters.dateMin}
                  onChange={e => {
                    setFilters({ ...filters, dateMin: e.target.value, dateMax: '' });
                  }}
                />
              </Box>

              <Box>
                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                  <b className='text-start'>&nbsp;Hasta</b>
                </span>
                <input
                  min={filters?.dateMin || ''}
                  disabled={!filters?.dateMin}
                  className={`w-100 ord-roundInput pointer  ${styles.inputDate} ${styles.boxShadowNone}`}
                  type='date'
                  value={filters.dateMax}
                  onChange={e => {
                    setFilters({ ...filters, dateMax: e.target.value });
                  }}
                />
              </Box>

              <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                <input
                  className={`ord-roundInput ${styles.appInputDate} w-100`}
                  type='text'
                  placeholder='Escribe aquí para buscar'
                  autoComplete='off'
                  onChange={e => {
                    setFilters({ ...filters, search: e.target.value });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                      setFilters({ ...filters, page: 1 });
                    }
                  }}
                />
                <img
                  src={ordBlueSearch}
                  className='pointer'
                  alt='buscar'
                  onClick={() => setTrigger(trigger + 1)}
                />
              </Box>

              <Box className={`d-flex gap-2 ${styles.f14}`}>
                <div className={`fw-bold ${styles.ordDarkBlueText}`}>Total ordenes:</div>
                <div className='text-secondary'>{listProviderOrders?.rowTotal || '-'}</div>
              </Box>

              <Box className={`d-flex gap-2 ${styles.f14}`}>
                <div className={`fw-bold ${styles.ordDarkBlueText}`}>Recaudado:</div>
                <div className='text-secondary'>
                  {formatNumberWithoutDecimals(listProviderOrders?.results[0]?.ordersTotalAmount || 0)}
                </div>
              </Box>
            </Box>

            <OrdTable
              shadow
              className={'mt-0'}
              hasChildren={true}
              headers={services.header}
              paginate={{
                activePage: filters.page,
                totalPages: listProviderOrders?.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 3,
                showTextDetails: true,
                onChangePage: async e => {
                  setFilters(state => ({
                    ...state,
                    page: e,
                  }));

                  setTrigger(trigger + 1);
                },
              }}
            >
              {formatData(listProviderOrders?.results)}
            </OrdTable>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
