export const convertFilterToString = (obj = {}) => {
  var str = [];

  for (var p in obj)
    if (Object.hasOwnProperty.call(obj, p)) {
      if (checkValidValue(obj[p]) && obj[p].length !== 0) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }

  return str.join('&');
};

const checkValidValue = variable => {
  if (variable === undefined || variable === null) {
    return false;
  }
  return true;
};
