import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
//
import IconBack from '../../../../assets/img/icons/iconBack.svg';
import { usePostMethod } from '../../../../Hooks';
import { customSwaltAlert, loader } from '../../../../helpers';
//
import DataSheet from './Tabs/DataSheet';
import FolderParams from './Tabs/FolderParams';
//
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';

export default function CreateCompanyParams() {
  const history = useHistory();
  const store = useSelector(store => store);

  const [currentTab, setCurrentTab] = useState('data_sheet');
  const [companyParamsPayload, setCompanyParamsPayload] = useState({
    companyId: null,
    companyNit: '',
    contractId: null,
    contractServices: [],
    filingType: null,
    filingMaxDay: null,
    modality: null,
    folderParams: {
      mainFolder: '',
      childrenFiles: [],
    },
    eaccount: store.loginReducer.currentAccount.id,
    created_by: store.loginReducer.user_data.id,
  });

  const { trigger: createCompanyParam, load: createCompanyParamLoader } = usePostMethod();

  function validatePayload(payload, excludedKey) {
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        if (key === excludedKey) {
          continue; // Saltar la validación de esta clave
        }

        const value = payload[key];

        // Verificar si el valor es nulo, undefined o una cadena vacía
        if (
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '')
        ) {
          return false;
        }

        // Si el valor es un arreglo, verificar cada elemento
        if (Array.isArray(value)) {
          for (const element of value) {
            if (!validatePayload(element, excludedKey)) {
              return false;
            }
          }
        }

        // Si el valor es un objeto, llamar recursivamente a la función
        if (typeof value === 'object' && !Array.isArray(value)) {
          if (!validatePayload(value, excludedKey)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  const onCreateCompanyParam = async () => {
    await createCompanyParam({
      token: store.loginReducer.Authorization,
      body: companyParamsPayload,
      method: 'POST',
      url: '/receivable/companyParams/',
      succesAction: () => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado la parametrización de la empresa: ${companyParamsPayload.companyName} / ${companyParamsPayload.contractName}`,
          confirmButtonText: 'Aceptar',
        }).then(response => {
          if (response.isConfirmed) {
            history.goBack();
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderTabs = () => {
    return (
      <Nav variant='tabs'>
        <Nav.Item>
          <Nav.Link
            onClick={() => setCurrentTab('data_sheet')}
            eventKey='link-1'
            className={tableStyles.darkBlueText}
            style={{
              color: currentTab === 'data_sheet' ? '#495057' : '',
              backgroundColor: currentTab === 'data_sheet' ? '#fff' : '#F5F7FA',
              borderColor:
                currentTab === 'data_sheet'
                  ? '#dee2e6 #dee2e6 #fff'
                  : 'transparent transparent #dee2e6',
            }}
          >
            Ficha técnica
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => setCurrentTab('folder_params')}
            eventKey='link-2'
            className={tableStyles.darkBlueText}
            style={{
              color: currentTab === 'folder_params' ? '#495057' : '',
              backgroundColor: currentTab === 'folder_params' ? '#fff' : '#F5F7FA',
              borderColor:
                currentTab === 'folder_params'
                  ? '#dee2e6 #dee2e6 #fff'
                  : 'transparent transparent #dee2e6',
            }}
          >
            Parametrización de carpetas
          </Nav.Link>
        </Nav.Item>
      </Nav>
    );
  };

  const render = () => {
    return (
      <>
        {createCompanyParamLoader && loader}
        <div className={tableStyles.tlnContainer}>
          <div className={tableStyles.tableArea}>
            <div className='d-flex align-items-center mt-5 mb-3'>
              <img
                onClick={() => history.goBack()}
                src={IconBack}
                className='pointer me-3'
                alt=''
              />
              <h1 className='m-0'>Crear parametrización</h1>
            </div>
            <div className='overflow-hidden'>
              {renderTabs()}
              <div className='d-flex flex-column overflow-auto p-4' style={{ maxHeight: '50vh' }}>
                <DataSheet
                  companyParamsPayload={companyParamsPayload}
                  setCompanyParamsPayload={setCompanyParamsPayload}
                  isCurrentTab={currentTab === 'data_sheet'}
                />
                <FolderParams
                  companyParamsPayload={companyParamsPayload}
                  setCompanyParamsPayload={setCompanyParamsPayload}
                  isCurrentTab={currentTab === 'folder_params'}
                />
              </div>
            </div>
            <div className='d-flex justify-content-end align-items-center mt-4'>
              <button
                onClick={() => history.goBack()}
                className={`${tableStyles.btnSecondary} py-2 me-3`}
              >
                Cancelar
              </button>
              <button
                className={`${tableStyles.btnPrimaryAlt} py-2`}
                onClick={() =>
                  currentTab === 'data_sheet'
                    ? setCurrentTab('folder_params')
                    : onCreateCompanyParam()
                }
                disabled={!validatePayload(companyParamsPayload, 'folderParams')}
              >
                {currentTab === 'data_sheet' ? 'Continuar' : 'Guardar'}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return render();
}
