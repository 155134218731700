import { useEffect, useState, useMemo } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import Descarga from '../../assets/img/icons/download.svg';
import Add from '../../assets/img/icons/AddBlue.svg';
import Question from '../../assets/img/icons/question.svg';
import Expand from '../../assets/img/icons/arrows-expand.svg';
import Reduc from '../../assets/img/icons/redu.svg';
import Alert from '../../assets/img/icons/alert-circle.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import genericTableStyles from '../../components/Layouts/genericTable.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import '../RecaudoCartera/bankdeposit.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import ModalNew from '../../components/Layouts/ModalNew';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Switch from 'react-switch';
import {
  create_Consignment,
  getDepositBank,
  getDepositCheck,
  getSelectMonth,
  update_coporate_deposit,
} from '../../actions/depositbankAction';
import Select from 'react-select';
import { message, today } from '../../helpers/helpers';
import { customSelectNewDark, customSelectNew } from '../../components/Layouts/react-select-custom';
import { customSelectMonth } from '../../components/Layouts/react-select-custom';
import { getCorporateClients } from '../../actions/corporateClientsActions';
import { getNiifAccounts } from '../../actions/AccountsActions';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Title } from '../../shared';

function BankDeposit() {
  var hoy = new Date();
  const history = useHistory();
  function getMonthName(month) {
    const d = new Date();
    d.setMonth(month - 1);
    const monthName = d.toLocaleString('default', { month: 'long' });
    return monthName;
  }

  const numberWithCommas = x => {
    if (x) {
      var parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return parts.join('.');
    } else {
      return '0';
    }
  };
  const counter = useSelector(state => state);
  const [getCreate, setCreate] = useState({
    entity_account: counter.loginReducer.currentAccount.id,
    corporate_client: 0,
    transaction_date: '',
    deposit_type: 0,
    identifier: '',
    niff_account: 0,
    amount: '',
    anticipated_payment: 0,
    observations: '',
    cutoff_date: 0,
    day: '',
    name: '',
    status: '',
    banco: '',
  });
  const [update, setUpdate] = useState({
    id: 0,
    niff_account: 0,
    status: 2,
  });
  const [getSearch, setSearch] = useState({
    day: '',
    name: '',
    status: '',
  });
  const [getselectMonth, setSelectMonth] = useState({
    year: '',
    month: '',
  });
  const [modalDetail, setModalDetail] = useState([]);
  const [modalDetailCheck, setModalDetailCheck] = useState([]);

  const dispatch = useDispatch();
  const myPermission = counter?.loginReducer?.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'ConsigDiar',
  );
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const [loading, setLoading] = useState(true);
  const [triggerSelect, setTriggerSelect] = useState(1);
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/tesoreria/inicio');
    }
    dispatch(
      getDepositBank(
        {
          year: getselectMonth.year !== '' ? getselectMonth.year : hoy.getFullYear(),
          month: getselectMonth.month !== '' ? getselectMonth.month : hoy.getMonth() + 1,
          name: getSearch.name !== '' ? getSearch.name : '',
          day: getSearch.day !== '' ? getSearch.day : '',
          notstatus: 1,
          status: getSearch.status !== '' ? getSearch.status : '',
          entity_account: counter.loginReducer.currentAccount.id,
        },
        () => setLoading(false),
      ),
    );
    dispatch(
      getDepositCheck({
        entity_account: counter.loginReducer.currentAccount.id,
        deposit_type: 2,
        status: 1,
      }),
    );
    dispatch(
      getSelectMonth({
        eaccount: counter.loginReducer.currentAccount.id,
        year: hoy.getFullYear(),
        month: hoy.getMonth() + 1,
      }),
    );
    dispatch(
      getCorporateClients({
        id_account: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getNiifAccounts({
        eaccount: counter.loginReducer.currentAccount.id,
        check_info_bank: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getDepositBank(
        {
          year: getselectMonth.year !== '' ? getselectMonth.year : hoy.getFullYear(),
          month: getselectMonth.month !== '' ? getselectMonth.month : hoy.getMonth() + 1,
          name: getSearch.name !== '' ? getSearch.name : '',
          day: getSearch.day !== '' ? getSearch.day : '',
          notstatus: 1,
          status: getSearch.status !== '' ? getSearch.status : '',
          entity_account: counter.loginReducer.currentAccount.id,
        },
        () => setLoading(false),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger2, getSearch.day, getSearch.status]);

  let arrayBill = [];
  if (Array.isArray(counter.depositBankReducer.combinedList)) {
    counter.depositBankReducer.combinedList.forEach(item => {
      let found = arrayBill.findIndex(b => b.niff_account?.id === item.niff_account?.id);

      if (!isNaN(found) && found >= 0) {
        arrayBill[found].deposits.push(item);
      } else {
        let newbill = {
          niff_account: item.niff_account,
          deposits: [item],
        };
        arrayBill.push(newbill);
      }
    });
  }
  let optionsMonths = [];
  for (let index = 0; index < 32; index++) {
    if (index === 0) {
      optionsMonths.push({
        value: '',
        label: 'Seleccionar...',
      });
    } else {
      optionsMonths.push({
        value: index,
        label: index,
      });
    }
  }

  const header3 = [
    <th key={1} className={`text-start ${genericTableStyles.theadBottomless}`}>
      Dia
    </th>,
    <th key={2} className={`text-end ${genericTableStyles.theadBottomless}`}></th>,
    <th key={3} className={`text-start ${genericTableStyles.theadBottomless}`}>
      Empresa
    </th>,
    <th key={4} className={`text-center ${genericTableStyles.theadBottomless}`}>
      Valor
    </th>,
  ];

  const [renderList] = useMemo(() => {
    let table = [];
    let totalac = 0;
    if (Array.isArray(arrayBill)) {
      table = arrayBill.map((item, key) => {
        totalac = 0;
        const [body3] = [
          item.deposits.map(e => {
            if (e.status_desc === 'Sin Legalizar') {
              totalac += e.amount;
              return (
                <tr className='bg-table tableHover' key={'w24423' + e.id}>
                  <td style={{ fontSize: '14px', color: '#EC1C24' }} className='text-start'>
                    {e.transday}
                  </td>
                  <td className='text-end'>
                    {e.anticipated_payment ? <img alt='' className='ml-3 mr-2' src={Alert} /> : ''}
                  </td>
                  <td style={{ fontSize: '14px', color: '#EC1C24' }} className='text-start col-8'>
                    {e.corporate_client.copyName}
                  </td>
                  <td style={{ fontSize: '14px', color: '#EC1C24' }} className='text-center'>
                    {'$' + e.amount ? numberWithCommas(e.amount) : '0'}
                  </td>
                </tr>
              );
            } else if (e.status_desc === 'Parcialmente Legalizado') {
              totalac += e.amount;
              return (
                <tr className='bg-table tableHover' key={'w2423' + e.id}>
                  <td style={{ fontSize: '14px', color: '#E9B419' }} className='text-start'>
                    {e.transday}
                  </td>
                  <td className='ml-3 text-end'></td>
                  <td style={{ fontSize: '14px', color: '#E9B419' }} className='text-start col-8'>
                    {e.corporate_client.copyName}
                  </td>
                  <td style={{ fontSize: '14px', color: '#E9B419' }} className='text-center'>
                    {'$' + e.amount ? numberWithCommas(e.amount) : '0'}
                  </td>
                </tr>
              );
            } else {
              totalac += e.amount;
              return (
                <tr className='bg-table tableHover' key={'w2d4321' + item.id}>
                  <td style={{ fontSize: '14px' }} className='text-start'>
                    {e.transday}
                  </td>
                  <td className='ml-3 text-end'></td>
                  <td style={{ fontSize: '14px' }} className='text-start col-8'>
                    {e.corporate_client.copyName}
                  </td>
                  <td style={{ fontSize: '14px' }} className='text-center'>
                    {'$' + e.amount ? numberWithCommas(e.amount) : '0'}
                  </td>
                </tr>
              );
            }
          }),
        ];

        return (
          <div key={key} className='divbill'>
            <div>
              <h5 className='text-center titlebill'>
                {item.niff_account.name_bank} - {item.niff_account.account_type}{' '}
              </h5>
              <p className='text-center numberbill'>{item.niff_account.complete_account}</p>
            </div>
            <GenericTable
              headers={header3}
              clearHeader={true}
              footer={[
                <td key={1} className='text-start' style={{ color: '#003F80' }}>
                  <b></b>
                </td>,
                <td key={1} className='text-start' style={{ color: '#003F80' }}>
                  <b></b>
                </td>,
                <td key={1} className='text-start' style={{ color: '#003F80' }}>
                  <b>Total</b>
                </td>,
                <td key={1} className='text-center'>
                  <b style={{ color: '#005DBF' }}>
                    {' '}
                    {'$' + totalac ? numberWithCommas(totalac) : '0'}
                  </b>
                </td>,
              ]}
            >
              {body3}
            </GenericTable>
          </div>
        );
      });
    }
    return [table, totalac];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayBill]);

  const modalSubmitInfo = item => {
    setShowModalDetail(true);
    setModalDetail(item);
  };
  const modalSubmitInfoCheck = item => {
    setShowModalDetailCheck(true);
    setModalDetailCheck(item);
    setUpdate({ ...update, id: item.id });
  };

  const body = [];
  const footer = [];
  const renderTablePrimary = () => {
    if (Array.isArray(counter.depositBankReducer.combinedList)) {
      counter.depositBankReducer.combinedList.forEach(item => {
        if (item.status_id !== 1) {
          if (item.status_desc === 'Sin Legalizar') {
            body.push(
              <tr className='bg-table tableHover' key={'w2dfsdf243' + item.id}>
                <td className='text-end' style={{ color: '#EC1C24', paddingRight: '5px' }}>
                  {item.transday}
                </td>
                <td className='text-end tooltip-text'>
                  {item.anticipated_payment ? <img alt='' className='mr-2' src={Alert} /> : ''}
                  {item.anticipated_payment ? (
                    <span className='tooltip-content'>Pago anticipado</span>
                  ) : (
                    ''
                  )}
                </td>
                <td
                  className='text-start tooltip-text'
                  style={{ color: '#EC1C24', cursor: 'pointer' }}
                  onClick={() => modalSubmitInfo(item)}
                >
                  <span className='tooltip-content'>No legalizado</span>
                  {item.niff_account.name_bank} -{item.niff_account.complete_account}-
                  {item.niff_account.account_type}
                </td>
                <td className='text-start' style={{ color: '#EC1C24' }}>
                  {item.corporate_client.copyName}
                </td>
                <td className='text-end' style={{ color: '#EC1C24', paddingRight: '20px' }}>
                  {' '}
                  {'$' + numberWithCommas(item.amount)}
                </td>
              </tr>,
            );
          } else if (item.status_desc === 'Parcialmente Legalizado') {
            body.push(
              <tr className='bg-table tableHover' key={'w2efgds1343' + item.id}>
                <td className='text-end' style={{ color: '#E9B419', paddingRight: '5px' }}>
                  {item.transday}
                </td>
                <td className='text-end' style={{ color: '#E9B419' }}></td>
                <td
                  className='text-start tooltip-text'
                  style={{ color: '#E9B419', cursor: 'pointer' }}
                  onClick={() => modalSubmitInfo(item)}
                >
                  <span className='tooltip-content'>Parcialmente legalizado</span>
                  {item.niff_account.name_bank} -{item.niff_account.complete_account}-
                  {item.niff_account.account_type}
                </td>
                <td className='text-start' style={{ color: '#E9B419' }}>
                  {item.corporate_client.copyName}
                </td>
                <td className='text-end' style={{ color: '#E9B419', paddingRight: '20px' }}>
                  {' '}
                  {'$' + numberWithCommas(item.amount)}
                </td>
              </tr>,
            );
          } else {
            body.push(
              <tr className='bg-table tableHover' key={'w2lñjhhfd3443' + item.id}>
                <td className='text-end' style={{ color: '#E9B419', paddingRight: '5px' }}>
                  {item.transday}
                </td>
                <td className='text-end'></td>
                <td
                  className='text-start'
                  style={{ cursor: 'pointer' }}
                  onClick={() => modalSubmitInfo(item)}
                >
                  {item.niff_account.name_bank} -{item.niff_account.complete_account}-
                  {item.niff_account.account_type}
                </td>
                <td className='text-start'>{item.corporate_client.copyName}</td>
                <td className='text-end' style={{ paddingRight: '20px' }}>
                  {' '}
                  {'$' + numberWithCommas(item.amount)}
                </td>
              </tr>,
            );
          }
        }
      });

      footer.push(
        <td colSpan='3'></td>,
        <td className='text-center' style={{ color: '#003F80' }}>
          <b>Total</b>
        </td>,
        <td className='text-end'>
          <b style={{ color: '#005DBF' }}>
            {' '}
            {'$' + numberWithCommas(counter.depositBankReducer.deposits_total)}
          </b>
        </td>,
      );
    }
  };

  const header2 = [
    <th key={1} className={`${genericTableStyles.theadBottomless} text-center`}>
      No. Cheque
    </th>,
    <th key={2} className={`${genericTableStyles.theadBottomless} text-center`}>
      Valor
    </th>,
  ];
  const body2 = [];
  const footer2 = [];

  if (Array.isArray(counter.depositBankReducer.combinedListCheck)) {
    counter.depositBankReducer.combinedListCheck.forEach(item => {
      body2.push(
        <tr
          className='bg-table hoverPointer'
          key={'w2wrfdsvfh43_43' + item.id}
          onClick={() => modalSubmitInfoCheck(item)}
        >
          <td className='text-start' style={{ color: '#01A0F6', paddingLeft: '20px' }}>
            {item.identifier}
          </td>
          <td className='text-end' style={{ paddingRight: '20px' }}>
            {item.amount ? '$ ' + numberWithCommas(item.amount) : '-'}
          </td>
        </tr>,
      );
    });

    footer2.push(
      <td style={{ color: '#003F80' }}>
        <b>Total</b>
      </td>,
      <td>
        <b style={{ color: '#01A0F6' }}>
          {counter.depositBankReducer.deposits_total_check
            ? '$ ' + numberWithCommas(counter.depositBankReducer.deposits_total_check)
            : '-'}
        </b>
      </td>,
    );
  }
  function openTable(e) {
    e.preventDefault();
    let elemento = document.getElementById(12346);
    let elemento2 = document.getElementById(1234);
    elemento.classList.add('d-none');
    elemento2.classList.remove('d-none');
  }
  function closeTable(e) {
    e.preventDefault();
    let elemento = document.getElementById(12346);
    let elemento2 = document.getElementById(1234);
    elemento.classList.remove('d-none');
    elemento2.classList.add('d-none');
  }

  const [showModal, setShowModal] = useState(false);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalDetailCheck, setShowModalDetailCheck] = useState(false);

  const header = [
    <th key={1} className={`${genericTableStyles.theadBottomless} text-center`}>
      Día
    </th>,
    <th key={2} className={`${genericTableStyles.theadBottomless} text-center`}></th>,
    <th key={3} className={`${genericTableStyles.theadBottomless} text-center`}>
      Cuenta
    </th>,
    <th key={4} className={`${genericTableStyles.theadBottomless} text-center`}>
      Empresa
    </th>,
    <th key={5} className={`${genericTableStyles.theadBottomless} text-center`}>
      Valor
    </th>,
  ];
  const options = [];
  if (Array.isArray(counter.depositBankReducer.results)) {
    counter.depositBankReducer.results.forEach(item => {
      options.push({
        value: `${item.year}-${item.month}`,
        label: item.TAG,
        year: item.year,
        month: item.month,
      });
    });
  }

  var optionClients = [];
  if (Array.isArray(counter.corporateclientsReducer.corporateClients)) {
    counter.corporateclientsReducer.corporateClients.forEach(item => {
      if (item.enabled === true) {
        optionClients.push({
          value: item.id,
          label: item.Nit + ' - ' + item.copyName,
          key: item.id + 'wweh',
        });
      }
    });
  }
  var optionNiifAccounts = [];
  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.forEach(item => {
      optionNiifAccounts.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.name_bank,
        key: item.id + 'qg',
      });
    });
  }

  const resetReq = () => {
    setCreate({
      ...getCreate,
      entity_account: counter.loginReducer.currentAccount.id,
      corporate_client: 0,
      transaction_date: '',
      deposit_type: 0,
      identifier: '',
      niff_account: 0,
      amount: '',
      anticipated_payment: false,
      observations: '',
      cutoff_date: 0,
      banco: '',
      menssage: '',
      status: 0,
    });
    setTrigger(trigger + 1);
  };

  const send_create_Consignment = () => {
    if (getCreate.transaction_date > today()) {
      return message('warning', 'Fecha invalida', 'La fecha no puede ser superior al dia en curso');
    }
    dispatch(
      create_Consignment({ ...getCreate }, () => {
        resetReq();
        setTriggerSelect(triggerSelect + 1);
        setCreate({
          ...getCreate,
          entity_account: counter.loginReducer.currentAccount.id,
          corporate_client: 0,
          transaction_date: '',
          deposit_type: 0,
          identifier: '',
          niff_account: 0,
          amount: '',
          anticipated_payment: false,
          observations: '',
          cutoff_date: 0,
          banco: '',
          menssage: '',
          status: 0,
        });
        setShowModalCheck(false);
        setShowModal(false);
      }),
    );
  };

  const check_register = () => {
    dispatch(
      update_coporate_deposit(update, () => {
        setTrigger(trigger + 1);
        setShowModalDetailCheck(false);
      }),
    );
  };

  const handleSumit = e => {
    e.preventDefault();
    setTrigger2(trigger2 + 1);
  };
  return (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: '3rem', marginTop: '30px' }}
    >
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {counter.depositBankReducer?.creatC_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {/* modal crear Consignaciones */}
      <ModalNew
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setCreate({
            entity_account: counter.loginReducer.currentAccount.id,
            corporate_client: 0,
            transaction_date: '',
            deposit_type: 0,
            identifier: '',
            niff_account: 0,
            amount: '',
            anticipated_payment: false,
            observations: '',
            cutoff_date: 0,
            banco: '',
            menssage: '',
            status: 0,
          });
        }}
        title={'Consignación'}
        btnYesEvent={send_create_Consignment}
        btnYesDisabled={
          !!getCreate.corporate_client === 0 ||
          !!getCreate.transaction_date === '' ||
          !!getCreate.deposit_type === 0 ||
          !!(getCreate.identifier?.length < 3) ||
          !!getCreate.niff_account === 0 ||
          !!getCreate.banco === '' ||
          !!(getCreate.amount?.length < 3)
        }
        btnYesName={'Agregar'}
        size={'450'}
      >
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Consignante
              <span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Consignante' + triggerSelect}
              placeholder={`Seleccionar...`}
              styles={customSelectNew}
              options={optionClients}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  corporate_client: e.value,
                  entity_account: counter.loginReducer.currentAccount.id,
                })
              }
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Fecha
              <span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={`${IndividualStyles.registerInputsBlue} register-inputs`}
              style={{ color: '#005DBF' }}
              max={today()}
              type='date'
              value={getCreate.transaction_date}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  transaction_date: e.target.value,
                })
              }
            ></input>
          </Col>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Tipo de consignación
              <span className='text-danger'>*</span>
            </label>
            <Select
              placeholder='Seleccionar...'
              styles={customSelectNew}
              options={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Transferencia', value: '1' },
                { label: 'Consignación', value: '3' },
                { label: 'Tarjeta de crédito', value: '4' },
              ]}
              // className={"register-inputs"}
              value={
                getCreate.deposit_type
                  ? { label: getCreate.deposit_typeLabel, value: getCreate.deposit_type }
                  : ''
              }
              onChange={({ value, label }) =>
                setCreate({
                  ...getCreate,
                  deposit_type: value,
                  deposit_typeLabel: label,
                })
              }
            ></Select>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Identificador
              <span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={'register-inputs'}
              type='text'
              value={getCreate.identifier}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  identifier: e.target.value,
                })
              }
              minLength={7}
              maxLength={12}
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Cuenta contable
              <span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Cuenta' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNew}
              options={optionNiifAccounts}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  niff_account: e.value,
                  banco: e.label,
                })
              }
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Valor
              <span className='text-danger'>*</span>
            </label>
            <NumberFormat
              placeholder={'Escribir...'}
              allowNegative={false}
              id='cost_price'
              className={`register-inputs`}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={getCreate.amount}
              onValueChange={e =>
                setCreate({
                  ...getCreate,
                  amount: e.value,
                })
              }
              minLength={3}
              maxLength={12}
            />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid'>
            &nbsp;<label className={tableStyles.stylesLabel}>Observaciones</label>
            <textarea
              className={`register-inputs`}
              placeholder='Escribir...'
              style={{ height: '6rem', maxHeight: '6rem', outline: 'none', padding: '5px' }}
              rows='3'
              cols='30'
              value={getCreate.observations}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  observations: e.target.value,
                })
              }
            >
              Escribir...
            </textarea>
          </Col>
        </Row>
        &nbsp;
        <label className='d-flex align-middle mt-4'>
          <Switch
            className='mt-1'
            // activeBoxShadow
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={22}
            width={50}
            onColor='#005DBF'
            label='Secondary'
            onChange={e =>
              setCreate({
                ...getCreate,
                anticipated_payment: e === true ? 1 : 0,
              })
            }
            checked={getCreate.anticipated_payment ? true : false}
          />
          <span
            className='ml-3 mt-3'
            style={{
              fontSize: '12px',
              color: '#58595B',
              verticalAlign: 'super',
            }}
          >
            Pago Anticipado
          </span>
        </label>
      </ModalNew>

      <ModalNew
        show={showModalCheck}
        title={'Recepción de Cheque'}
        btnYesEvent={send_create_Consignment}
        btnYesDisabled={
          !!getCreate.corporate_client === 0 ||
          !!getCreate.transaction_date === '' ||
          !!(getCreate.identifier?.length < 7) ||
          !!(getCreate.amount?.length < 3)
        }
        btnYesName={'Agregar'}
        size={'450'}
        onHide={() => {
          setShowModalCheck(false);
          setCreate({
            entity_account: counter.loginReducer.currentAccount.id,
            corporate_client: 0,
            transaction_date: '',
            deposit_type: 0,
            identifier: '',
            niff_account: 0,
            amount: '',
            anticipated_payment: false,
            observations: '',
            cutoff_date: 0,
            banco: '',
            menssage: '',
            status: 0,
          });
        }}
      >
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Consignante<span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Consignante2' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              options={optionClients}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  corporate_client: e.value,
                  entity_account: counter.loginReducer.currentAccount.id,
                })
              }
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Fecha<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={`${IndividualStyles.registerInputsBlue} register-inputs`}
              type='date'
              value={getCreate.transaction_date}
              max={today()}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  transaction_date: e.target.value,
                })
              }
            ></input>
          </Col>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              No. cheque<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsGris}
              type='text'
              value={getCreate.identifier}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  identifier: e.target.value,
                  deposit_type: 2,
                  status: 1,
                })
              }
              minLength={7}
              maxLength={12}
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Valor<span className='text-danger'>*</span>
            </label>
            <NumberFormat
              placeholder={'Escribir...'}
              allowNegative={false}
              id='cost_price'
              className={`${IndividualStyles.registerInputsGris}`}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={getCreate.amount}
              onValueChange={e =>
                setCreate({
                  ...getCreate,
                  amount: e.value,
                  menssage: 'Cheque Recibido',
                })
              }
              maxLength={12}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col className='d-grid'>
            &nbsp;<label className={tableStyles.stylesLabel}>Observaciones</label>
            <textarea
              placeholder='Escribir...'
              style={{ height: '6rem', maxHeight: '6rem', outline: 'none', padding: '5px' }}
              className={IndividualStyles.textAreaBig}
              rows='3'
              cols='30'
              value={getCreate.observations}
              onChange={e =>
                setCreate({
                  ...getCreate,
                  observations: e.target.value,
                })
              }
            >
              Escribir...
            </textarea>
          </Col>
        </Row>
        &nbsp;
        <label className='d-flex align-middle mt-4'>
          <Switch
            className='mt-1'
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={22}
            width={50}
            onColor='#005DBF'
            label='Secondary'
            onChange={e =>
              setCreate({
                ...getCreate,
                anticipated_payment: e === true ? 1 : 0,
              })
            }
            checked={getCreate.anticipated_payment ? true : false}
          />
          <span
            className='ml-3 mt-3'
            style={{
              fontSize: '12px',
              color: '#58595B',
              verticalAlign: 'super',
            }}
          >
            Pago Anticipado
          </span>
        </label>
      </ModalNew>

      <Title
        title='Consignaciones diarias'
        className={'mb-2'}
        onClickIcon={() => {
          history.push('/tesoreria/inicio');
        }}
      />

      <div className='d-flex justify-content-end'>
        <div className='text-end mb-2 mr-3 d-flex'>
          {myPermission?.create ? (
            <button
              className='mr-3 botton-create groupAddButton d-flex align-items-center justify-content-center'
              onClick={() => setShowModal(true)}
            >
              <b>Consignación</b>
              <button className='addCheckButton mx-2' />
            </button>
          ) : (
            ''
          )}
          <button className='mr-1'>
            <img src={Descarga} alt={'download'} style={{ width: '20px' }} />
          </button>
        </div>
      </div>

      <Select
        noOptionsMessage={() => 'No hay datos'}
        id='select-mes'
        defaultValue={hoy.getFullYear()}
        placeholder={`${getMonthName(hoy.getMonth() + 1)} ${hoy.getFullYear()}`}
        name='Select-month'
        styles={customSelectMonth}
        options={options}
        onChange={e =>
          setSelectMonth(
            {
              year: e.year,
              month: e.month,
            },
            setTrigger(trigger + 1),
          )
        }
      ></Select>

      <div id='12346' className='div mt-3'>
        <div className={`div1 p-3 ${tableStyles.shade}`} style={{ borderRadius: '10px' }}>
          <Col className='text-end'>
            <button onClick={openTable}>
              <img alt='' src={Expand}></img>
            </button>
          </Col>
          <div className='div gap-3 align-items-end'>
            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Día</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                options={optionsMonths}
                placeholder='Seleccionar...'
                onChange={e =>
                  setSearch({
                    ...getSearch,
                    day: e.value,
                  })
                }
              ></Select>
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Estado</label>
              <Select
                placeholder='Seleccionar...'
                styles={customSelectNewDark}
                options={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Sin Legalizar', value: '2' },
                  { label: 'Parcialmente Legalizado', value: '3' },
                  { label: 'Legalizado', value: '4' },
                ]}
                onChange={({ value, label }) =>
                  setSearch({
                    ...getSearch,
                    status: value,
                    statusLabel: label,
                  })
                }
              ></Select>
            </div>

            <div className='d-flex gap-2 align-items-end'>
              <form onSubmit={handleSumit}>
                <input
                  placeholder='Buscar...'
                  className={`inputsearch ${tableStyles.containerSearchAdmin}`}
                  type='text'
                  onChange={e =>
                    setSearch({
                      ...getSearch,
                      name: e.target.value,
                    })
                  }
                />
              </form>

              <img
                className={`pointer ${tableStyles.iconSvg}`}
                alt='search'
                src={Lupa}
                onClick={() => setTrigger2(trigger2 + 1)}
              />
            </div>
          </div>
          <div style={{ overflow: 'scroll' }}>
            {renderTablePrimary()}
            <GenericTable headers={header} clearHeader={true} footer={footer}>
              {body}
            </GenericTable>
          </div>
        </div>
        <div style={{ width: '29%' }}>
          <div>
            <div
              className={`ml-3 p-3 ${tableStyles.shade}`}
              style={{ borderRadius: '10px', width: '100%' }}
            >
              <div className='tooltip-text'>
                <span className={`tooltip-content hoverHelp`}>
                  Cheques entregados a tesorería sin consignar
                </span>
                <p className={`hoverPointer`} style={{ color: '#003f80', textAlign: 'center' }}>
                  <img
                    alt=''
                    className='mr-1'
                    src={Question}
                    data-title='Cheques entregados a tesorería sin consignar'
                  ></img>
                  Recepción de Cheques
                </p>
              </div>
              {myPermission?.create ? (
                <button
                  className='center hover-blue d-flex gap-2'
                  onClick={() => setShowModalCheck(true)}
                >
                  <img alt='' src={Add} />
                  <b className='ml-1'>Nuevo Cheque</b>
                </button>
              ) : (
                ''
              )}
              <GenericTable
                headers={header2}
                theadBottomless={true}
                clearHeader={true}
                footer={footer2}
              >
                {body2}
              </GenericTable>
            </div>
          </div>
        </div>
      </div>

      {/* segundo */}
      <div id='1234' className='div d-none'>
        <div className={`p-3 ${tableStyles.shade}`} style={{ borderRadius: '10px' }}>
          <Col className='text-end'>
            {' '}
            <button>
              <img alt='' onClick={closeTable} id='123478' src={Reduc}></img>
            </button>
          </Col>
          <div id='listBill' className='d-flex' style={{ overflow: 'scroll' }}>
            {renderList}
          </div>
        </div>
      </div>
      <ModalNew
        show={showModalDetail}
        title={'Detalle'}
        subtitle={
          <span
            style={{
              color:
                modalDetail?.status_desc === 'Sin Legalizar'
                  ? '#EC1C24'
                  : modalDetail?.status_desc === 'Parcialmente Legalizado'
                    ? '#E9B419'
                    : ' ',
            }}
          >
            {modalDetail?.status_desc ? modalDetail?.status_desc : ''}
          </span>
        }
        size={'450'}
        onHide={() => {
          setShowModalDetail(false);
        }}
      >
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Consignante
              <span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Consignante' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              defaultValue={{
                label: modalDetail?.corporate_client?.copyName,
                value: 0,
              }}
              isDisabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Fecha<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={`${IndividualStyles.registerInputsBlue} register-inputs`}
              style={{ color: '#005DBF' }}
              type='date'
              value={modalDetail.transaction_date}
              disabled
            ></input>
          </Col>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Tipo de consignación<span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              styles={customSelectNewDark}
              placeholder='Seleccionar...'
              defaultValue={{
                label:
                  modalDetail.deposit_type === 1
                    ? 'Transferencia'
                    : modalDetail.deposit_type === 2
                      ? 'Cheque'
                      : modalDetail.deposit_type === 3
                        ? 'Consignación'
                        : modalDetail.deposit_type === 4
                          ? 'Tarjeta de crédito'
                          : '',
                value: 0,
              }}
              isDisabled
            ></Select>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Identificador<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsGris}
              type='text'
              value={modalDetail.identifier}
              disabled
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Cuenta contable<span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Cuenta' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              defaultValue={{
                label:
                  modalDetail?.niff_account?.complete_account +
                  ' - ' +
                  modalDetail?.niff_account?.name_bank,
                value: 0,
              }}
              isDisabled
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Valor<span className='text-danger'>*</span>
            </label>
            <NumberFormat
              placeholder={'Escribir...'}
              allowNegative={false}
              id='cost_price'
              className={`${IndividualStyles.registerInputsGris}`}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={modalDetail.amount}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid'>
            &nbsp;<label className={tableStyles.stylesLabel}>Observaciones</label>
            <textarea
              style={{ height: '6rem', maxHeight: '6rem', outline: 'none', padding: '5px' }}
              placeholder='Escribir...'
              className={IndividualStyles.textAreaBig}
              rows='3'
              cols='30'
              value={modalDetail.observations}
              disabled
            >
              Escribir...
            </textarea>
          </Col>
        </Row>
        &nbsp;
        <label className='d-flex align-middle mt-4'>
          <Switch
            className='mt-1'
            // activeBoxShadow
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={22}
            width={50}
            onColor='#005DBF'
            label='Secondary'
            checked={modalDetail.anticipated_payment ? true : false}
            disabled
          />
          <span
            className='ml-3 mt-3'
            style={{
              fontSize: '12px',
              color: '#58595B',
              verticalAlign: 'super',
            }}
          >
            Pago Anticipado
          </span>
        </label>
      </ModalNew>
      <ModalNew
        show={showModalDetailCheck}
        title={'Detalle'}
        subtitle={
          <span>
            Cheque No.{' '}
            {modalDetailCheck?.identifier ? numberWithCommas(modalDetailCheck?.identifier) : ''}
          </span>
        }
        size={'450'}
        onHide={() => {
          setShowModalDetailCheck(false);
          setUpdate({ ...update, niff_account: 0 });
        }}
        btnYesEvent={check_register}
        btnYesDisabled={update.niff_account === 0}
        btnYesName={'Registrar'}
      >
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Consignante<span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Consignante' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              defaultValue={{
                label: modalDetailCheck?.corporate_client?.copyName,
                value: 0,
              }}
              isDisabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Fecha<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={`${IndividualStyles.registerInputsBlue} register-inputs`}
              style={{ color: '#005DBF' }}
              type='date'
              value={modalDetailCheck.transaction_date}
              disabled
            ></input>
          </Col>
          <Col xs={6}>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Tipo de consignación<span className='text-danger'>*</span>
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              defaultValue={{
                label:
                  modalDetailCheck.deposit_type === 1
                    ? 'Transferencia'
                    : modalDetailCheck.deposit_type === 2
                      ? 'Cheque'
                      : modalDetailCheck.deposit_type === 3
                        ? 'Consignación'
                        : modalDetailCheck.deposit_type === 4
                          ? 'Tarjeta de crédito'
                          : '',
                value: 0,
              }}
              isDisabled
            ></Select>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Identificador<span className='text-danger'>*</span>
            </label>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsGris}
              type='text'
              value={modalDetailCheck.identifier}
              disabled
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            &nbsp;
            <label className={tableStyles.stylesLabel}>
              Valor<span className='text-danger'>*</span>
            </label>
            <NumberFormat
              placeholder={'Escribir...'}
              allowNegative={false}
              id='cost_price'
              className={`${IndividualStyles.registerInputsGris}`}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={modalDetailCheck.amount}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid'>
            &nbsp;<label className={tableStyles.stylesLabel}>Observaciones</label>
            <textarea
              style={{ height: '6rem', maxHeight: '6rem', outline: 'none', padding: '5px' }}
              placeholder={'Escribir...'}
              className={IndividualStyles.textAreaBig}
              rows='3'
              cols='30'
              value={modalDetailCheck.observations}
              disabled
            >
              Escribir...
            </textarea>
          </Col>
        </Row>
        &nbsp;
        <label className='d-flex align-middle mt-4'>
          <Switch
            className='mt-1'
            checkedIcon={false}
            uncheckedIcon={false}
            handleDiameter={22}
            width={50}
            onColor='#005DBF'
            label='Secondary'
            checked={modalDetailCheck.anticipated_payment ? true : false}
            disabled
          />
          <span
            className='ml-3 mt-3'
            style={{
              fontSize: '12px',
              color: '#58595B',
              verticalAlign: 'super',
            }}
          >
            Pago Anticipado
          </span>
        </label>
        <Row>
          <Col className='d-grid'>
            &nbsp;
            <label className={tableStyles.subtitle} style={{ paddingTop: '10px' }}>
              Registrar consignación del cheque
            </label>
            &nbsp;<label className={tableStyles.stylesLabel}>Cuenta contable*</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={'Cuenta' + triggerSelect}
              placeholder='Seleccionar...'
              styles={customSelectNew}
              options={optionNiifAccounts}
              onChange={e =>
                setUpdate({
                  ...update,
                  niff_account: e.value,
                })
              }
            />
          </Col>
        </Row>
      </ModalNew>
    </div>
  );
}
export default BankDeposit;
