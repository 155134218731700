import Popup from 'reactjs-popup';
import Gear from '../../assets/img/icons/engraneGris.svg';
import { generateId } from '../../helpers';
import styles from './customPopup.module.scss';

function CustomPopup(props) {
  return (
    <Popup
      trigger={
        <button>
          <img
            src={props.triggerSrc ? props.triggerSrc : Gear}
            alt='configuracion'
            className='icons-gear'
          />
        </button>
      }
      position='right center'
      styles={styles.popupContent}
    >
      <ul className={styles.popUpUl}>
        {props.showEdit ? (
          <li onClick={() => props.editClickEvent()} className={styles.popUpLiEdit}>
            Editar
          </li>
        ) : null}

        {props.showEnable ? (
          <li
            onClick={() => props.enableClickEvent()}
            className={props.isEnabled ? styles.popUpLiActiveRed : styles.popUpLiActive}
          >
            {props.isEnabled ? 'Deshabilitar' : 'Habilitar'}
          </li>
        ) : null}
        {/* /* ---------------------------- Add more buttons ---------------------------- */}
        {props.newButton
          ? props.newButton.length > 0 &&
            props.newButton.map(e => {
              return (
                <li
                  key={generateId()}
                  onClick={() => e.btnAction(e)}
                  className={styles.popUpShowDetails}
                >
                  {e.btnText}
                </li>
              );
            })
          : null}
      </ul>
    </Popup>
  );
}

export default CustomPopup;

// newButton: [
//   {
//     btnAction: func,
//     btnText: string,
//     btnIcon: string, //not implemented
//   },
// ];
