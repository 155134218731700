import { useState, useEffect } from 'react';
import { Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { useGetMethod } from '../../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { loader } from '../../../helpers/helpers';
import approved from '../../../assets/img/icons/app_aprovado.svg';
import assign from '../../../assets/img/icons/app_asignarCita.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Pagination from 'react-js-pagination';
import stylePagination from '../../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FullScreenDialog from '../../../OrderingModule/OrderingComponents/FullScreenDialog';
import { RenderMedicalFormulasPdf } from '../../RenderMedicalFormulasPdf';
import { customSwaltAlertAsistencial, getExternalUrl, historyRedirect } from '../../../helpers';
import { Box } from '@mui/material';

export const OrderingPatientTab = ({ patient, idPatient }) => {
  const infoPatient = patient?.results;
  const [profile] = useState(1);
  let history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [triggerConsult, setTriggerConsult] = useState(1);
  const [triggerProcedures, setTriggerProcedures] = useState(1);
  const [triggerMedicines, setTriggerMedicines] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [fullDialogScreen, setFullDialogScreen] = useState({
    isOpen: false,
    id: undefined,
    ordTag: undefined,
  });

  const [fullDialogScreenQueries, setFullDialogScreenQueries] = useState({
    isOpen: false,
    id: undefined,
    ordTag: undefined,
  });

  const [fullDialogScreenProced, setFullDialogScreenProced] = useState({
    isOpen: false,
    id: undefined,
    ordTag: undefined,
  });

  const [filterConsults, setFilterConsult] = useState({
    patient: idPatient,
    typeList: 'cons',
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
  });

  const [filterProcedures, setFilterProcedures] = useState({
    patient: idPatient,
    typeList: 'proc',
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
  });

  const [filterMedicines, setFilterMedicines] = useState({
    patient: idPatient,
    startDate: '',
    endDate: '',
    search: '',
    page: 1,
    perpage: 10,
  });

  const isCategory = store?.loginReducer?.newCategories?.find((category) =>
    category?.description === "Portal Asistencial"
  )?.data?.find((item) => item?.title === "Cirugía");

  const renderTooltipAssign = props => (
    <Tooltip {...props}>
      <div>
        <span>Asignar Cita</span>
      </div>
    </Tooltip>
  );

  const renderTooltipApproved = props => (
    <Tooltip {...props}>
      <div>
        <span>Gestionado</span>
      </div>
    </Tooltip>
  );

  const {
    results: consultsPatient,
    load: loadConsultsPatient,
    trigger: getConsultsPatient,
  } = useGetMethod();

  const {
    results: proceduresPatient,
    load: loadProceduresPatient,
    trigger: getProceduresPatient,
  } = useGetMethod();

  const {
    results: medicinePatient,
    load: loadMedicinePatient,
    trigger: getMedicinePatient,
  } = useGetMethod();

  useEffect(() => {
    getConsultsPatient({
      url: `/medical/patient/service/`,
      token: token,
      objFilters: filterConsults,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerConsult]);

  useEffect(() => {
    getProceduresPatient({
      url: `/medical/patient/service/`,
      token: token,
      objFilters: filterProcedures,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerProcedures]);

  useEffect(() => {
    getMedicinePatient({
      url: `/medical/patient/medicine/`,
      token: token,
      objFilters: filterMedicines,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerMedicines]);

  const formDataMedicines = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card
            key={index}
            className={`${styles.app_container_card_medicine_2} ${styles.app_container_scroll} h-auto w-auto w-100`}
            style={{ maxWidth: '100%' }}
            onClick={() => {
              setFullDialogScreen(state => ({
                ...state,
                isOpen: true,
                id: item?.medOrdId,
                ordTag: item?.ortTag,
              }));
            }}
          >
            <div key={index} className={styles.app_card_medicine}>
              <div className={styles.app_container_medicine}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card} style={{ paddingRight: '16px' }}>
                  {item?.date ? item?.date : '-'}
                </span>
              </div>
            </div>

            <div>
              {item?.medicines?.map((item, index) => {
                return (
                  <div key={index} className={styles.app_card_medicine}>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Medicamento</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.medicine ? item?.medicine : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Cantidad</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.dosis ? item?.dosis : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Frecuencia</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.frequency ? item?.frequency : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine_time}>
                      <span className={styles.app_title_info_card_medicine}>Tiempo</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.period ? item?.period : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Presentación</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.presentation ? item?.presentation : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Concentración</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.concentration ? item?.concentration : '-'}
                      </span>
                    </div>
                    <div className={styles.app_container_medicine}>
                      <span className={styles.app_title_info_card_medicine}>Vía</span>
                      <span className={styles.app_subtitle_info_card_medicine}>
                        {item?.route ? item?.route : '-'}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>,
        );
      });
    }
    return tempList;
  };

  const formDataConsult = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card
            className={styles.app_card_custom}
            key={index}
            onClick={() => {
              setFullDialogScreenQueries(state => ({
                ...state,
                isOpen: true,
                id: item?.idSer || item?.serviceId,
                ordTag: item?.ortTag || item?.serviceType,
              }));
            }}
          >
            <Box
              key={index}
              className='px-2'
              display={'grid'}
              gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr 40px'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              gap={2}
            >
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card}>{item.orderDate}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Cups</span>
                <span className={styles.app_subtitle_info_card}>{item.cup}</span>
              </div>
              <div className={`${styles.app_container_w} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>{item?.serviceType}</span>
                <span className={styles.app_subtitle_info_card}>{item.offeredService}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5} text-center`}>
                <span className={styles.app_title_info_card}>Ojo</span>
                <span className={styles.app_subtitle_info_card}>{item.bodyOrgan}</span>
              </div>
              <div className={`${styles.app_container_w} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Médico</span>
                <span className={styles.app_subtitle_info_card}>{item.fullnameMedical}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Fecha sugerida</span>
                <span className={styles.app_subtitle_info_card}>{item.dateSuggested}</span>
              </div>
              <div className={styles.app_container_icon}>
                {item.appAssign === 0 ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipAssign}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '25px' }}
                        src={assign}
                        className={`cursorPointer`}
                        onClick={() =>
                          historyRedirect({
                            history,
                            path: '/citas/RegistroCita',
                            params: { infoPatient: { ...infoPatient, alertId: infoPatient.id }, procedure: item },
                          })
                        }
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipApproved}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '30px' }}
                        src={approved}
                        className={`cursorPointer`}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </Box>
          </Card>,
        );
      });
    }
    return tempList;
  };

  const formDataProcedures = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <Card
            className={styles.app_card_custom}
            key={index}
            onClick={() => {
              setFullDialogScreenProced(state => ({
                ...state,
                isOpen: true,
                id: item?.idSer || item?.serviceId,
                ordTag: item?.ortTag || item?.serviceType,
              }));
            }}
          >
            <Box
              key={index}
              className='px-2'
              display={'grid'}
              gridTemplateColumns={'1fr 1fr 1fr 1fr 1fr 1fr 40px'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
              gap={2}
            >
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Fecha</span>
                <span className={styles.app_subtitle_info_card}>{item.orderDate}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Cups</span>
                <span className={styles.app_subtitle_info_card}>{item.cup}</span>
              </div>
              <div className={`${styles.app_container_w} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Procedimiento</span>
                <span className={styles.app_subtitle_info_card}>{item.offeredService}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5} text-center`}>
                <span className={styles.app_title_info_card}>Ojo</span>
                <span className={styles.app_subtitle_info_card}>{item.bodyOrgan}</span>
              </div>
              <div className={`${styles.app_container_w} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Médico</span>
                <span className={styles.app_subtitle_info_card}>{item.fullnameMedical}</span>
              </div>
              <div className={`${styles.app_container_info_card} ${styles.rowGap5}`}>
                <span className={styles.app_title_info_card}>Fecha sugerida</span>
                <span className={styles.app_subtitle_info_card}>{item.dateSuggested}</span>
              </div>
              <div className={styles.app_container_icon}>
                {item.appAssign === 0 ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipAssign}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '25px' }}
                        src={assign}
                        className={`cursorPointer`}
                        onClick={(e) =>{
                          e.stopPropagation();
                          historyRedirect({
                            path: `${getExternalUrl({
                              loginReducer: store.loginReducer, category: isCategory
                            })}&`,
                            params: { patientId: infoPatient.id, surgeryState: 'searchProgramming',serviceId: item?.serviceId },
                            isInternal: false,
                            getParams: { redirect: 'surgery/surgery-scheduling' },
                            useQueryParams: true
                          })
                        } 
                        }
                      />
                    </div>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltipApproved}
                  >
                    <div>
                      <img
                        alt={'icon'}
                        style={{ width: '30px' }}
                        src={approved}
                        className={`cursorPointer`}
                      />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </Box>
          </Card>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      {(loadConsultsPatient || loadProceduresPatient || loadMedicinePatient) && loader}
      <div>
        <TabContainer id='list-group-tabs-example' defaultActiveKey={profile}>
          <Row>
            <div className='col-lg-3'>
              <ListGroup className={styles.app_list_group}>
                <ListGroup.Item
                  action
                  className={activeTab === 1 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={1}
                  onClick={() => setActiveTab(1)}
                >
                  Consultas y ayudas dx
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={activeTab === 2 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={2}
                  onClick={() => setActiveTab(2)}
                >
                  Medicamentos
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={activeTab === 3 ? styles.app_list_group_active : styles.app_list_item}
                  eventKey={3}
                  onClick={() => setActiveTab(3)}
                >
                  Procedimientos y/o cirugías
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className='col-lg-9'>
              <TabContent>
                {/* Consult */}
                <TabPane eventKey={1}>
                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerConsult(triggerConsult + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterConsult({
                                  ...filterConsults,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='startDate'>
                          <Form.Control
                            type='date'
                            name='startDate'
                            placeholder='Desde'
                            value={filterConsults.startDate}
                            className={styles.app_roundInput}
                            onChange={e => {
                              setFilterConsult({
                                ...filterConsults,
                                startDate: e.target.value,
                              });
                              setTriggerConsult(triggerConsult + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='endDate'>
                          <Form.Control
                            type='date'
                            name='endDate'
                            placeholder='Hasta'
                            value={filterConsults.endDate}
                            className={styles.app_roundInput}
                            onChange={e => {
                              setFilterConsult({
                                ...filterConsults,
                                endDate: e.target.value,
                              });
                              setTriggerConsult(triggerConsult + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div key={triggerConsult + 1} className={styles.app_container_card_scroll}>
                      {formDataConsult(consultsPatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {consultsPatient ? filterConsults.page : ''}
                        {' de '}
                        {Math.ceil(consultsPatient.rowTotal / filterConsults.perpage)
                          ? Math.ceil(consultsPatient.rowTotal / filterConsults.perpage)
                          : ''}{' '}
                        ({consultsPatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterConsults.page}
                        itemsCountPerPage={filterConsults.perpage}
                        totalItemsCount={consultsPatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterConsult({ ...filterConsults, page: e });
                          setTriggerConsult(triggerConsult + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
                {/* Medicines */}
                <TabPane eventKey={2}>
                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterMedicines({
                                  ...filterMedicines,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='startDate'>
                          <Form.Control
                            className={styles.app_roundInput}
                            name='startDate'
                            placeholder='Desde'
                            type='date'
                            onChange={e => {
                              setFilterMedicines({
                                ...filterMedicines,
                                startDate: e.target.value,
                              });
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='endDate'>
                          <Form.Control
                            className={styles.app_roundInput}
                            type='date'
                            name='endDate'
                            onChange={e => {
                              setFilterMedicines({
                                ...filterMedicines,
                                endDate: e.target.value,
                              });
                              setTriggerMedicines(triggerMedicines + 1);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div key={triggerMedicines + 1} className={styles.app_container_card_scroll}>
                      {formDataMedicines(medicinePatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {medicinePatient ? filterMedicines.page : ''}
                        {' de '}
                        {Math.ceil(medicinePatient.rowTotal / filterMedicines.perpage)
                          ? Math.ceil(medicinePatient.rowTotal / filterMedicines.perpage)
                          : ''}{' '}
                        ({medicinePatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterMedicines.page}
                        itemsCountPerPage={filterMedicines.perpage}
                        totalItemsCount={medicinePatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterMedicines({ ...filterMedicines, page: e });
                          setTriggerMedicines(triggerMedicines + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
                {/* Procedures */}
                <TabPane eventKey={3}>
                  {loadProceduresPatient && loader}

                  <div className='col-11'>
                    <Row className={styles.app_container_options}>
                      <Col xs={6}>
                        <Form.Group>
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                          >
                            <Form.Control
                              type='text'
                              placeholder='Escribe aquí para buscar'
                              onChange={e => {
                                e.preventDefault();
                                setFilterProcedures({
                                  ...filterProcedures,
                                  search: e.target.value,
                                });
                              }}
                              className={`${styles.app_background_out_image} ${styles.app_roundSearch} col-8 pl-2`}
                            />
                          </form>
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='startDate'>
                          <Form.Control
                            type='date'
                            name='startDate'
                            onChange={e => {
                              setFilterProcedures({
                                ...filterProcedures,
                                startDate: e.target.value,
                              });
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                            className={styles.app_roundInput}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group controlId='endDate'>
                          <Form.Control
                            type='date'
                            name='endDate'
                            onChange={e => {
                              setFilterProcedures({
                                ...filterProcedures,
                                endDate: e.target.value,
                              });
                              setTriggerProcedures(triggerProcedures + 1);
                            }}
                            className={styles.app_roundInput}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <div key={triggerConsult + 1} className={styles.app_container_card_scroll}>
                      {/* {formDataConsult(consultsPatient.results)} */}
                      {formDataProcedures(proceduresPatient.results)}
                    </div>
                    <div
                      className={`${stylePagination.wrapper}`}
                      style={stylePagination.endPagination}
                    >
                      <p className={stylePagination.paginationText}>
                        Pag. {proceduresPatient ? filterProcedures.page : ''}
                        {' de '}
                        {Math.ceil(proceduresPatient.rowTotal / filterProcedures.perpage)
                          ? Math.ceil(proceduresPatient.rowTotal / filterProcedures.perpage)
                          : ''}{' '}
                        ({proceduresPatient.rowTotal} encontrados)
                      </p>
                      <Pagination
                        activePage={filterProcedures.page}
                        itemsCountPerPage={filterProcedures.perpage}
                        totalItemsCount={consultsPatient.rowTotal}
                        pageRangeDisplayed={5}
                        onChange={e => {
                          setFilterProcedures({ ...filterProcedures, page: e });
                          setTriggerProcedures(triggerProcedures + 1);
                        }}
                        itemClassPrev={stylePagination.itemClassPrev}
                        itemClassNext={stylePagination.itemClassNext}
                        itemClassFirst={stylePagination.itemClassFirst}
                        itemClassLast={stylePagination.itemClassLast}
                        itemClass={stylePagination.itemClass}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </Row>
        </TabContainer>
      </div>

      <FullScreenDialog
        onHide={() => {
          setFullDialogScreen(state => ({
            ...state,
            isOpen: false,
            id: undefined,
            ordTag: undefined,
          }));
        }}
        isOpen={fullDialogScreen.isOpen}
      >
        <RenderMedicalFormulasPdf
          title={'Fórmula de medicamentos'}
          doAfterException={err => {
            setFullDialogScreen(state => ({
              ...state,
              isOpen: false,
              id: undefined,
              ordTag: undefined,
            }));

            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: err.message,
              showCancelButton: false,
            });
          }}
          pdfParams={{
            url: '/medical/pdfMedicationOrder/', //ESPERAR URL
            id: fullDialogScreen.id,
            ordTag: fullDialogScreen.ordTag,
          }}
          backAction={() => {
            setFullDialogScreen(state => ({
              ...state,
              isOpen: false,
              id: undefined,
              ordTag: undefined,
            }));
          }}
          withPagination={true}
        />
      </FullScreenDialog>

      <FullScreenDialog
        onHide={() =>
          setFullDialogScreenQueries({ id: undefined, isOpen: false, ordTag: undefined })
        }
        isOpen={fullDialogScreenQueries.isOpen}
      >
        <RenderMedicalFormulasPdf
          title={'Ordenamiento de servicios'}
          withPagination={true}
          pdfParams={{
            url: '/medical/pdfserviceOrder/', //ESPERAR URL
            id: fullDialogScreenQueries.id,
            ordTag: fullDialogScreenQueries.ordTag,
          }}
          backAction={() => {
            setFullDialogScreenQueries({ id: undefined, isOpen: false, ordTag: undefined });
          }}
          doAfterException={err => {
            setFullDialogScreenQueries(state => ({
              ...state,
              isOpen: false,
              id: undefined,
              ordTag: undefined,
            }));

            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: err.message,
              showCancelButton: false,
            });
          }}
        />
      </FullScreenDialog>

      <FullScreenDialog
        onHide={() =>
          setFullDialogScreenProced({ id: undefined, isOpen: false, ordTag: undefined })
        }
        isOpen={fullDialogScreenProced.isOpen}
      >
        <RenderMedicalFormulasPdf
          title={'Ordenamiento de servicios'}
          withPagination={true}
          pdfParams={{
            url: '/medical/pdfserviceOrder/', //ESPERAR URL
            id: fullDialogScreenProced.id,
            ordTag: fullDialogScreenProced.ordTag,
          }}
          backAction={() => {
            setFullDialogScreenProced({ id: undefined, isOpen: false, ordTag: undefined });
          }}
          doAfterException={err => {
            setFullDialogScreenProced(state => ({
              ...state,
              isOpen: false,
              id: undefined,
              ordTag: undefined,
            }));

            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: err.message,
              showCancelButton: false,
            });
          }}
        />
      </FullScreenDialog>
    </>
  );
};
