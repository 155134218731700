import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../Hooks';
import iconPrint from '../../../assets/img/icons/iconPrint.svg';
import backArrow from '../../../assets/img/icons/ordBackArrow.svg';
import ordLeftTriangle from '../../../assets/img/icons/ordLeftTriangle.svg';
import ordRightTriangle from '../../../assets/img/icons/ordRightTriangle.svg';
import ordWhiteBackArrow from '../../../assets/img/icons/ordWhiteBackArrow.svg';
import { PdfViewer } from '../../../components/Layouts/PdfViewer';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { isEmptyOrUndefined, loader, message } from '../../../helpers/helpers';
export const RenderAppointmentPdf = props => {
    const store = useSelector(state => state);
    const token = store.loginReducer.Authorization;
    let {
        backAction,
        finalize,
        withPagination,
        title,
        pdfParams = [],
        doAfterException,
        goBack,
        cancel,
        goBackSmallDelete,
        base64File,
        fileUrl,
    } = props;
    const [pdfFilters, setPdfFilters] = useState({
        activePage: 0,
    });
    const [back, setBack] = useState(backArrow);
    /* ----------------------------- FETCHS SECTION ----------------------------- */
    /* ----------------------------- GET ----------------------------- */
    const { results: appointmentResults, trigger: getPdfOrder, load: getPdfLoader } = useGetMethod();
    /* -----------------------------  ----------------------------- */
    useEffect(() => {
      if (pdfParams[pdfFilters.activePage]) {
        const pdfObject = pdfParams[pdfFilters.activePage];
        if (
          (!isEmptyOrUndefined(pdfObject?.url) && !isEmptyOrUndefined(pdfObject?.objFilters)) ||
          (!isEmptyOrUndefined(pdfObject?.url) &&
            !isEmptyOrUndefined(pdfObject?.objFilters) &&
            !isEmptyOrUndefined(pdfObject?.doAfterSuccess)) ||
          (!isEmptyOrUndefined(pdfObject?.url) && !isEmptyOrUndefined(pdfObject?.id))
        ) {
          getPdfOrder({
            url: pdfObject.url,
            objFilters: pdfObject?.objFilters ? pdfObject?.objFilters : {},
            token: token,
            doAfterSuccess: pdfObject?.doAfterSuccess
              ? pdfObject?.doAfterSuccess
              : response => {
                  if (response.status !== 200) {
                    return message('warning', ' Advertencia', response.message);
                  }
                },
            doAfterException: error => {
              doAfterException?.(error);
            },
          });
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfFilters.activePage]);

    const pdfDecoded = useMemo(() => {
        if (appointmentResults.results?.base64)
            return appointmentResults.results.base64?.split("'")[1];
        return "";
    }, [appointmentResults.results?.base64]);


    const paginate = op => {
        let limit = (pdfParams.length ?? 1) - 1;

        if (op === 'next') {
            pdfFilters.activePage !== limit &&
                setPdfFilters({ ...pdfFilters, activePage: pdfFilters.activePage + 1 });
        } else {
            pdfFilters.activePage >= 1 &&
                setPdfFilters({ ...pdfFilters, activePage: pdfFilters.activePage - 1 });
        }
    };
    const download = file => {
        let win = window.open();
        win.document.write(
            '<iframe src="' +
            file +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
        );
    };

    return (
        <>
            {getPdfLoader && loader}
            <div className={`container mb-5`}>
                <Row className='justify-content-center mb-4 ml-5'>
                    {title && (
                        <Col xs={12}>
                            <h1 className={tableStyles.ordDarkBlueText}>
                                <img
                                    onClick={() => backAction()}
                                    src={backArrow}
                                    className={`mr-2 mb-2 cursorPointer`}
                                    alt='Atrás'
                                ></img>
                                {title || 'Información de la fórmula'}
                            </h1>
                        </Col>
                    )}
                </Row>
                <div className=''>
                    <div className='d-flex justify-content-center '>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            {!goBackSmallDelete && goBack && (
                                <div
                                    className='d-flex justify-items-start align-items-start '
                                    style={{
                                        width: '49.6rem',
                                        marginLeft: '5rem',
                                    }}
                                >
                                    <img
                                        onClick={() => backAction()}
                                        src={backArrow}
                                        className={`mr-2 mb-2 cursorPointer`}
                                        alt='Atrás'
                                        style={{
                                            width: '15px',
                                        }}
                                    ></img>
                                </div>
                            )}

                            <div
                                className='mb-4 d-flex'
                                style={{
                                    width: '49.6rem',
                                    marginLeft: '5rem',
                                }}
                            // style={{ width: "85%", marginLeft: "9rem" }}
                            >
                                <Col xs={2} className={`mb-2`}>
                                    {!!withPagination && (
                                        <Row>
                                            <Col onClick={() => paginate('back')} xs={2}>
                                                <img src={ordLeftTriangle} alt='Atrás' className={`cursorPointer`}></img>
                                            </Col>
                                            <Col
                                                className={`
                                d-flex justify-content-center align-items-center
                                 ${tableStyles.selectedPdfPage} ${tableStyles.ordClearBlueText}`}
                                                xs={1}
                                            >
                                                {pdfFilters.activePage + 1}
                                            </Col>
                                            <Col className={`text-secondary`} xs={1}>
                                                /
                                            </Col>
                                            <Col className={`text-secondary`} xs={1}>
                                                {pdfParams.length}
                                            </Col>
                                            <Col onClick={() => paginate('next')} xs={3}>
                                                <img
                                                    src={ordRightTriangle}
                                                    alt='Adelante'
                                                    className={`cursorPointer`}
                                                ></img>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                                <Col
                                    className={`d-flex justify-content-end`}
                                    xs={10}
                                >
                                    <img
                                        src={iconPrint}
                                        alt='imprimir'
                                        onClick={() =>
                                            download(
                                                fileUrl
                                                    ? fileUrl
                                                    : `data:application/pdf;base64,${base64File ? base64File : pdfDecoded}`,
                                            )
                                        }
                                        width='25'
                                        className={`cursorPointer`}
                                    ></img>
                                </Col>
                            </div>

                            {!isEmptyOrUndefined(pdfDecoded) ? (
                                <Col
                                    className={` ${tableStyles.shade}`}
                                    style={{
                                        width: '49.6rem',
                                        marginLeft: '5rem',
                                    }}
                                    xs={9}
                                >
                                    <PdfViewer
                                        pdfWidth={790}
                                        containerClass={`${tableStyles.w54}`}
                                        file={
                                            fileUrl
                                                ? fileUrl
                                                : `data:application/pdf;base64,${base64File ? base64File : pdfDecoded}`
                                        }
                                    ></PdfViewer>
                                </Col>
                            ) : (
                                'Sin información'
                            )}
                        </div>
                        <Col className='justify-content-end ml-5'>
                            &nbsp;
                            {!goBack && (
                                <button
                                    onClick={() => backAction()}
                                    className={`btn d-flex  px-5 ${tableStyles.ordBtnSecondary}`}
                                    style={{ minWidth: '165px', maxWidth: '165px', height: '35px', justifyContent: 'center', alignItems: 'center' }}
                                    onMouseEnter={() => setBack(ordWhiteBackArrow)}
                                    onMouseLeave={() => setBack(backArrow)}
                                >
                                    <img
                                        src={back}
                                        alt='Atrás'
                                        width={back === backArrow ? '7px' : '15px'}
                                    ></img>
                                    &nbsp;
                                    <span>Regresar</span>
                                </button>
                            )}
                            &nbsp;
                            {finalize && (
                                <button
                                    onClick={() => finalize?.finalizeAction()}
                                    className={`btn d-flex  px-5 btn ${tableStyles.ordDarkBlueBtn}`}
                                    style={{ minWidth: '165px', height: '35px' }}
                                >
                                    <span>
                                        &nbsp;
                                        {finalize?.title || 'Finalizar'}
                                        &nbsp;
                                    </span>
                                </button>
                            )}
                            {cancel && (
                                <button
                                    onClick={() => cancel?.cancelAction()}
                                    className={`btn d-flex btn ordBtnBar`}
                                    style={{ minWidth: '165px', height: '35px', fontSize: '13.5px', width: '87%' }}
                                >
                                    <span>
                                        &nbsp;
                                        {cancel?.title || 'Cancelar ordenamiento'}
                                        &nbsp;
                                    </span>
                                </button>
                            )}
                        </Col>
                    </div>
                </div>
            </div>
        </>
    );
};
