import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  GET_THIRD_PARTIES,
  GET_REPLACES,
  LOADING_REGISTER_VOUCHERS,
} from "./actionTypes";
import { message } from "../helpers/helpers";
import { customSwaltAlert } from "../helpers/customSwaltAlert";

const MySwal = withReactContent(Swal);

export const listThirdParties = (objFilters) => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/third/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      dispatch({
        type: GET_THIRD_PARTIES,
        payload: results,
        total: total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};

export const createVoucherRegistration =
  (data, doAfter) => (dispatch, getState) => {

    customSwaltAlert({
      icon: "warning",
      title: "¿Está seguro?",
      text: "Se creará un comprobante nuevo",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: LOADING_REGISTER_VOUCHERS,
          loading: true,
        });
        const token = getState().loginReducer.Authorization;
        fetch(`${URL_GATEWAY}${API_VERSION}/accounting/journalVoucher/`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            Authorization: token,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.success) {
              dispatch({
                type: LOADING_REGISTER_VOUCHERS,
                loading: false,
              });

              customSwaltAlert({
                icon: "success",
                title: "Creado exitosamente",
                text: `Se ha creado un comprobante No: ${res.results.docNbr}`,
                showCancelButton: false,
                confirmButtonText: "Aceptar",
              }).then((response) => {
                if (response.isConfirmed) {
                  doAfter();
                } else {
                  doAfter();
                }
              });
            } else {
              dispatch({
                type: LOADING_REGISTER_VOUCHERS,
                loading: false,
              });

              customSwaltAlert({
                icon: "warning",
                title: "Intenta de nuevo",
                text: res.message,
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                footer: "Si el problema persiste comuníquese con un asesor.",
              })

            }
          })
          .catch((res) => {
            dispatch({
              type: LOADING_REGISTER_VOUCHERS,
              loading: false,
            });

            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              showCancelButton: false,
              confirmButtonText: "Aceptar",
              footer: "Si el problema persiste comuníquese con un asesor.",
            })

          });
      }
    });
  };

export const createClosedVoucherRegistration =
  (data, doAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/journalVoucher/finEndYear/`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          MySwal.fire({
            icon: "success",
            title: "Comprobante generado",
            text:
              "Comprobante de cierre de actividades " +
              data.date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$3/$2/$1"),
          }).then((response) => {
            if (response.isConfirmed) {
              doAfter();
            } else {
              doAfter();
            }
          });
        } else {
          doAfter();
          MySwal.fire({
            icon: "error",
            title: "Error al generar comprobante",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
          });
        }
      })
      .catch((res) => {
        doAfter();
        MySwal.fire({
          icon: "error",
          title: "Error al generar comprobante",
          text: res.message,
          footer: "Si el problema persiste comuníquese con un asesor.",
        });
      });
  };

export const replaceVoucherCode = (data, doAfter) => (dispatch, getState) => {
  
  customSwaltAlert({
    icon: "warning",
    title: "¿Está seguro?",
    text: "Verifique la información",
  }).then((result) => {
    if (result.isConfirmed) {
      const token = getState().loginReducer.Authorization;
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/thirdReplacement/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",

          token: token,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {

            customSwaltAlert({
              icon: "success",
              title: "Tercero reemplazado exitosamente",
              text: "se ha reemplazado el tercero: " + data.nit1 + " " + data.thirdName1,
              confirmButtonText:'Aceptar',
              showCancelButton:false

            }).then((response) => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: "warning",
              title: "Intenta de nuevo",
              text: res.message,
              footer: "Si el problema persiste comuníquese con un asesor.",
              confirmButtonText:'Aceptar',
              showCancelButton:false
            })
          }
        })
        .catch((res) => {
          customSwaltAlert({
            icon: "warning",
            title: "Intenta de nuevo",
            text: res.message,
            footer: "Si el problema persiste comuníquese con un asesor.",
            confirmButtonText:'Aceptar',
            showCancelButton:false
          })
        });
    }
  });
};

export const getReplaces = (objFilters) => (dispatch, getCreate) => {
  dispatch({
    type: GET_REPLACES,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/thirdReplacement/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, total } = res;
      if (results?.length === 0) {
        MySwal.fire({
          icon: "info",
          text: "No hay registros para mostrar",
          confirmButtonText: "Aceptar",
        });
      }
      dispatch({
        type: GET_REPLACES,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_REPLACES,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const sendEachParam = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    //console.log("Ha ocurrido un error al cargar la información");
    return message("error", "Error", "Ha ocurrido un error");
  }
};
export const removeParam = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/accounting/accountParameter/`,
      {
        method: "DELETE",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    //console.log("Ha ocurrido un error al cargar la información");
    return message("error", "Error", "Ha ocurrido un error");
  }
};

// export const sendEachParam = async (data, token) => {
//   let filters = convertFilterToString(data);
//   try {
//     const query = await fetch(
//       `${URL_GATEWAY}${API_VERSION}/treasury/pdf_doc/?${filters}`,
//       {
//         method: "GET",
//         headers: {
//           accept: "application/json",
//           Authorization: token,
//           "Content-Type": "application/json",
//         },
//       }
//     );
//     return query.json();
//   } catch (error) {
//     console.error(error.message);
//     return message("error", "Error", "No ha sido posible cargar el documento");
//   }
// };
