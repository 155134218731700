import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import deleteIcon from '../../assets/img/icons/cleanCloseIcon.svg';
import iconProvisions from '../../assets/img/icons/cloneIcon.svg';
import Search from '../../assets/img/icons/lupa.svg';
import detail from '../../assets/img/icons/lupaTransparent.svg';
import send from '../../assets/img/icons/paperplane.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import StylesPay from '../../components/GeneratePayroll/generatePayroll.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import {
  convertMoneyFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  loader,
  message,
  today,
} from '../../helpers/helpers';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNew } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import SelectComponent from '../SelectComponent/SelectComponent';
import '../TableUsers/tableUserStyle.scss';
import { useHasPermissionByTagModule } from "../../Hooks";

export const SeverancePayroll = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'planCes',
  );
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    year: '',
    endDate: '',
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });
  const {
    results: severancePayrollResults,
    load: severeancePayrollLoader,
    trigger: getSeverancePayroll,
  } = useGetMethod();
  const { load: deletePayrollLoader, trigger: deletePayrollMethod } = usePostMethod();
  const {
    results: severancePayPeriodResults,
    load: severancePayPeriodLoader,
    trigger: getSeverancePayPeriod,
  } = useGetMethod();
  const { load: loaderGeneratePayrollSeverance, trigger: generatePayrollSeverance } =
    usePostMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  const formattedBonusSalary = formatToRcSelect(
    severancePayPeriodResults?.last_severence,
    'year',
    'label',
    '',
    '',
    '',
  );

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    getSeverancePayroll({
      url: '/payroll/severance/',
      objFilters: filters,
      token: token,
    });

    getSeverancePayPeriod({
      url: '/payroll/settlement-cycles-reasons/',
      objFilters: {
        eaccount: idAccount,
        end_date: today(),
      },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const renderElement = elem => {
    const optionElem = {
      detail: {
        text: <span>Ver detalle</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: detail,
        event: myPermission?.read
          ? () => {
              goToDetailPayrollFunction(elem?.severanceId, elem?.status?.name, elem);
            }
          : null,
      },
      sendToTreasury: {
        text: <span>Enviar a tesorería</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: send,
      },
      delete: {
        text: <span>Eliminar</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: deleteIcon,

        event: myPermission?.edit
          ? () => {
              customSwaltAlert({
                icon: 'warning',
                title: `¿Está seguro?`,
                text: `Se eliminará la planilla del periodo: ${elem.paymentYear}.`,
                showCancelButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  deletePayrollFunction(elem?.severanceId);
                }
              });
            }
          : null,
      },
      sendToAccounting: {
        text: <span>Enviar a contabilidad</span>,
        class: CustomPopupStyles.popUpGeneric,
        icon: send,
        event: () => {
          sendToAccountingFunction(elem.severanceId, elem.paymentYear);
        },
      },
      failed: {
        text: 'Ver fallo',
        icon: iconProvisions,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          message('warning', 'Intenta de nuevo', elem?.cron_log_message, undefined, true);
        },
      },
    };
    const options = {
      loaded: [optionElem.detail, optionElem.delete, optionElem.sendToAccounting],
      review: [optionElem.detail],
      rejected: [optionElem.detail, optionElem.delete],
      approved: [optionElem.detail, optionElem.sendToTreasury],
      payed: [optionElem.detail],
      failed: [optionElem.failed, optionElem.delete],
    };

    return (
      <tr key={elem.id}>
        <td className='col-md-3 text-start ' style={{ paddingLeft: '2rem' }}>
          <b className={`${tableStyles.crudModalLabel}`}>{elem.paymentYear ?? '-'}</b>
        </td>
        <td className='col-md-2 text-center'>{elem.paymentDate ?? '-'}</td>
        <td className='col-md-2 text-center'>{elem.totalEmployees ?? '-'}</td>
        <td className='col-md-3 text-end'>{convertMoneyFormat(elem?.totalAmount ?? '-')}</td>
        <td className='col-md-2 text-center px-4'>
          <div
            className='rounded-pill p-1'
            style={{
              backgroundColor: elem?.status?.bgColor,
            }}
          >
            <b style={{ color: elem?.status?.fontColor }}>{elem?.status?.value}</b>
          </div>
        </td>
        <td className='col-md-1 text-center cursorPointer'>
          {elem.status.name !== 'in_process' ? (
            <div>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                className={`align-self-end`}
                extraButtons={options?.[elem?.status?.name]}
              />
            </div>
          ) : (
            <div style={{ width: '24px' }}>&nbsp;</div>
          )}
        </td>
      </tr>
    );
  };
  const listElem = () => {
    let elemMap;
    if (severancePayrollResults?.results?.length > 0) {
      const elem2 = severancePayrollResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };
  const renderHeaders = [
    <th key={1} className='text-start' style={{ paddingLeft: '1.8rem' }}>
      &nbsp; Año
    </th>,
    <th key={1} className='text-center'>
      Fecha de pago
    </th>,
    <th key={3} className='text-center'>
      No. de empleados
    </th>,
    <th key={4} className='text-end'>
      Total
    </th>,
    <th key={4} className='text-center'>
      Estado
    </th>,
    <th key={5} className='text-center'>
      &nbsp;
    </th>,
  ];
  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const sendToAccountingFunction = (id, paymentYear) => {
    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `Se enviará a contabilidad la planilla de cesantías`,
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        updatePayroll({
          url: '/payroll/severance/',
          token: token,
          method: 'PUT',
          body: {
            severanceId: id,
            eaccount: idAccount,
            status: 'review',
          },
          succesAction: () => {
            setTrigger(trigger + 1);
            customSwaltAlert({
              title: `Enviada exitosamente`,
              text: `Se ha enviado a contabilidad planilla del año ${paymentYear}. `,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const deletePayrollFunction = id => {
    deletePayrollMethod({
      url: '/payroll/severance/',
      method: 'DELETE',
      body: {
        severanceId: id,
        eaccount: idAccount,
      },
      token: token,
      succesAction: () => {
        setTrigger(trigger + 1);
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Planilla de cesantías eliminada`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  //   ------------------ MODAL GENERATE -----------------------

  const [showGenerateModal, setShowGenerateModal] = useState({
    show: false,
    payment_date: '',
    period: '',
    created_by: idUser,
  });

  const handleClickGenerate = () => {
    setShowGenerateModal({ ...showGenerateModal, show: true });
  };

  const closeGenerateModal = () => {
    setShowGenerateModal({ ...showGenerateModal, show: false, payment_date: '', period: '' });
  };

  const generatePayrollFunction = () => {
    let validations = true;

    if (isEmptyOrUndefined(showGenerateModal.period)) {
      validations = false;
      return customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `El campo 'Periodo de cesantías' es obligatorio para generar la planilla`,
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(showGenerateModal.payment_date)) {
      validations = false;
      return customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `El campo 'Fecha de pago' es obligatorio para generar la planilla`,
        showCancelButton: false,
      });
    }

    if (validations) {
      customSwaltAlert({
        title: `¿Está seguro?`,
        text: `Se generará la planilla del periodo: ${showGenerateModal.period}`,
        icon: 'warning',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          generatePayrollSeverance({
            url: '/payroll/severance/',
            token: token,
            noAlert: true,
            method: 'POST',
            body: {
              eaccount: idAccount,
              period: showGenerateModal.period,
              payment_date: showGenerateModal.payment_date,
              created_by: idUser,
            },
            doAfterSuccess: res => {
              getSeverancePayroll({
                url: '/payroll/severance/',
                objFilters: { eaccount: idAccount, severanceId: res.results },
                token: token,
                doAfterSuccess: () => {
                  closeGenerateModal();
                  setTrigger(trigger + 1);
                  // goToDetailPayrollFunction(results?.results?.severanceId, results?.results?.status?.name, result.results)
                },
              });
            },
            doAfterException: res => {
              message('warning', `Intente de nuevo`, res.message, undefined, true);
            },
          });
        }
      });
    }
  };

  const goToDetailPayrollFunction = (id, status, elem) => {
    history.push({
      pathname: '/nomina/planillaDeCesantias/planilla',
      state: { data: id, status: status, infoPayroll: elem },
    });
  };

  return (
    <>
      {(severeancePayrollLoader ||
        updatePayrollLoader ||
        loaderGeneratePayrollSeverance ||
        severancePayPeriodLoader ||
        deletePayrollLoader) &&
        loader}
      <ModalNew
        title={'Generar planilla de cesantías'}
        show={showGenerateModal.show}
        btnYesName={'Guardar'}
        btnNoName={'Cancelar'}
        size={500}
        onHide={() => closeGenerateModal()}
        btnYesEvent={myPermission?.create ? () => generatePayrollFunction() : null}
        btnNoEvent={() => closeGenerateModal()}
      >
        <div className=''>
          <Row className='my-2'>
            <Col className='d-block' xs={12}>
              <p className={`${tableStyles.crudModalLabel}`}>Periodo de cesantías</p>
              <SelectComponent
                key={`select`}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
                options={formattedBonusSalary}
                value={formattedBonusSalary.find(e => e.value === showGenerateModal?.period)}
                onChange={e => {
                  setShowGenerateModal({ ...showGenerateModal, period: e.value });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className='d-block' xs={12}>
              <p className={`${tableStyles.crudModalLabel}`}>Fecha de pago</p>
              <input
                onChange={e =>
                  setShowGenerateModal({ ...showGenerateModal, payment_date: e.target.value })
                }
                type='date'
                placeholder='Escribe...'
                className={`register-inputs`}
                name='position'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
          </Row>

          <div className={`customTabs__Footer mt-4 ml-1`}></div>
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        {store.workEnvReducer.listWorkEnvPoll_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Planillas de cesantías</h1>

          {!!myPermission?.read && (
            <>
              <Row>
                <Col xs={6} className='d-flex align-items-end gap-1 mt-2'>
                  <Col xs={4}>
                    <p className={tableStyles.crudModalLabel}>Año</p>
                    <input
                      onChange={e => setFilters({ ...filters, year: e.target.value, page: 1 })}
                      type='text'
                      placeholder='Escribir...'
                      style={{ color: '#7FADDE' }}
                      className={`register-inputs`}
                      name='position'
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          handleSearch(e);
                        }
                      }}
                    />
                  </Col>
                  <img
                    className='hoverPointer'
                    alt='iconSearch'
                    style={{}}
                    src={Search}
                    height='24px'
                    onClick={e => handleSearch(e)}
                  />
                </Col>
                <Col xs={6} className='align-self-end'>
                  {!!myPermission?.create && (
                    <div className='d-flex justify-content-end gap-2'>
                      <span
                        className='darkGray fw-bold align-self-center'
                        style={{ color: '#58595B', fontSize: '13px' }}
                      >
                        Generar planilla{' '}
                      </span>
                      <div
                        className={StylesPay.btnpreviw}
                        style={{ width: '31px', height: '31px' }}
                        onClick={() => handleClickGenerate()}
                      ></div>
                    </div>
                  )}
                </Col>
              </Row>
              <GenericTableNew headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>
              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {filters.page}
                  {' de '}
                  {Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                    ? Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                    : '1'}{' '}
                  ({severancePayrollResults?.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={severancePayrollResults?.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={e => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
