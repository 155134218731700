import { useState, useEffect } from 'react';
import arrow from '../../assets/img/icons/ordBackArrow.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import Nav from 'react-bootstrap/Nav';
import TabPane from 'react-bootstrap/TabPane';
import TabContent from 'react-bootstrap/TabContent';
import TabContainer from 'react-bootstrap/TabContainer';
import user from '../../assets/img/icons/user.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import { InfoPatientTab } from '../MultiTabFormPatient/InfoPatientTab';
import { DatingHistoryTab } from '../MultiTabFormPatient/AppointmentHistoryTab';
import { OrderingPatientTab } from '../MultiTabFormPatient/OrderingPatientTab';
import { Form } from 'react-bootstrap';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import Select from 'react-select';
import { formatToRcSelect, loader, message, swalConfirm } from '../../helpers/helpers';
import { appCustomSelectNew } from '../../components/Layouts/react-select-custom';
import { Drawer } from '@material-ui/core';
import closeIcon from '../../assets/img/icons/close.svg';
import iconTrans from '../../assets/img/icons/trashNotes.svg';
import iconMessage from '../../assets/img/icons/icon_message.svg';

export const DetailPatient = props => {
  let { event } = props;
  const history = useHistory();
  const location = useLocation();
  const [profile] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const idPatient = location.state.idPatient;
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;

  const { results: patient, load: loadPatient, trigger: getPatient } = useGetMethod();
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    getPatient({
      url: `/medical/patient/?id=${idPatient}`,
      objFilters: { status: 'enabled', eaccount: idAccount },
      token: token,
    });
  }, [trigger, getPatient, idAccount, idPatient, token]);

  const {
    results: generalComments,
    load: loadGeneralComments,
    trigger: getGeneralComments,
  } = useGetMethod();

  const { trigger: getDetailPatient } = useGetMethod();

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showImportance: false,
    header: [
      {
        title: 'Paciente',
        className: 'col-3 text-start px-3',
      },
      {
        title: 'No. Identificación',
        className: 'col-2 text-start px-3',
      },
      {
        title: 'Edad',
        className: 'col-1 text-start',
      },
      {
        title: 'EPS',
        className: 'col-3 text-start',
      },
      {
        title: 'Última atención',
        className: 'col-2 text-center',
      },
      {
        title: 'Notas',
        className: 'col-1 text-start',
      },
      {
        title: 'Alerta',
        className: 'col-2 text-start',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-2 text-end px-3',
      },
    ],
  });

  const [comments, setComments] = useState({
    userId: idUser,
    entity: 'userClient',
    comment: '',
    id: '',
  });

  const [patientSelect, setPatientSelect] = useState({
    cum_id: patient?.results?.alertId,
  });

  const { load: loadCreateMarkPatient, trigger: sendMarkPatient } = usePostMethod();
  const { results: importancies, trigger: getImportancies } = useGetMethod();
  const { trigger: deleteComment } = usePostMethod();
  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();

  const OnSubmitMarkPatient = () => {
    sendMarkPatient({
      url: '/medical/patient/',
      token: token,
      method: 'PUT',
      body: patientSelect,
      doAfterSuccess: () => {
        getPatient({
          url: `/medical/patient/?id=${idPatient}`,
          objFilters: { status: 'enabled', eaccount: idAccount },
          token: token,
        });
        setServices({
          ...services,
          showImportance: false,
        });
        setTrigger(trigger + 1);
      },
    });
  };

  const formatedImportances = formatToRcSelect(importancies.results, 'id', 'description');

  useEffect(() => {
    getImportancies({
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
      token: token,
    });
  }, [getImportancies, token]);

  const getIdNotes = id => {
    deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        setServices({ ...services, showNotes: false });
      },
    });
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '16px' }}>
              <div className={styles.app_container_tras_notes}>
                <span className={styles.app_name_drawer}>{item.userName}</span>
                <img
                  src={iconTrans}
                  width={15}
                  alt='tras-notes'
                  className='cursorPointer'
                  onClick={() => {
                    setServices({ ...services, showDrawer: true });
                    swalConfirm({
                      title: `<span class=${styles.ordClearBlueText} >¿Desea eliminar la nota?</span>`,
                      text: ` `,
                      confirmButtonText: 'Si, continuar',
                      doAfterConfirm: () => {
                        getIdNotes(item.id);
                        message('success', 'Nota eliminada');
                        setTrigger(trigger + 1);
                      },
                    });
                  }}
                />
              </div>
              <p className={styles.app_description_drawer}>{item.comment}</p>
              <div className={styles.app_container_date_drawer}>
                <span className={styles.app_date_drawer}>{item.date}</span>
              </div>
            </div>
            <div className={styles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  const fullNameUser =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;

  const onSubmitComment = e => {
    e.preventDefault();
    sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: comments,
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        setComments({ ...comments, comment: '' });
        setTrigger(trigger + 1);

        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });

        message('success', 'Nota añadida');
      },
    });
  };

  return (
    <>
      {loadCreateMarkPatient && loader}
      <Drawer
        anchor={'right'}
        open={services.showDrawer}
        style={{ zIndex: 900 }}
        onClose={() => setServices({ ...services, showDrawer: false })}
      >
        <div className={styles.app_drawer}>
          <div className={styles.app_container_close}>
            <img
              src={closeIcon}
              width={25}
              className={'cursorPointer'}
              alt='closeIcon'
              onClick={() => {
                setComments({ ...comments, id: '' });
                setServices({ ...services, showDrawer: false });
              }}
            />
          </div>
          <div className={styles.app_container_drawer}>
            <span className={styles.app_title_drawer}>Notas</span>
            {loadGeneralComments && loader}
            {generalComments.rowTotal === 0 ? (
              <div className={styles.app_container_not_notes}>
                <span className={styles.app_title_not_notes}>No hay notas aún</span>
              </div>
            ) : (
              <div className={styles.app_container_content_drawer}>
                {formatDataComments(generalComments.results)}
              </div>
            )}

            <div className={styles.app_container_form_drawer}>
              {loadCreateComments && loader}
              <Form.Group style={{ marginBottom: '10px' }}>
                <Form.Label className={styles.app_title_input}>
                  <span>{fullNameUser}</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  maxLength='170'
                  value={comments.comment}
                  onChange={e => setComments({ ...comments, comment: e.target.value })}
                  placeholder='Escribir...'
                />
              </Form.Group>
              <div className={styles.app_container_date_drawer}>
                <img
                  src={iconMessage}
                  className={'cursorPointer'}
                  alt='iconMessage'
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <OrdModal
        title='Tipo de importancia'
        show={services.showImportance}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={() => setServices({ ...services, showImportance: false })}
        btnYesEvent={() => OnSubmitMarkPatient()}
        btnNoEvent={() => setServices({ ...services, showImportance: false })}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={formatedImportances}
            className='text-secondary'
            value={formatedImportances.filter(x => x.value === patientSelect.cum_id)}
            placeholder={'Seleccionar...'}
            onChange={e => {
              setPatientSelect({ ...patientSelect, cum_id: e.value });
            }}
            styles={appCustomSelectNew}
          />
        </div>
      </OrdModal>
      <div className='col-lg-12 col-xl-12 col-xxl-12'>
        <div className={styles.app_container_multitab}>
          <div className={styles.app_container_info}>
            <img
              src={arrow}
              alt='atras'
              onClick={() => {
                history.push('/admision/listadoDePacientes');
              }}
              style={{ cursor: 'pointer' }}
            />
            <div
              style={{
                backgroundImage: `url(${patient.results?.urlPhoto || user})`,
              }}
              className={styles.app_container_photo}
            />
            <span className={`${styles.app_title_detail_patient} w-100`}>
              {patient.results?.firstName} {patient.results?.otherNames} {patient.results?.lastName}{' '}
              {''}
              {patient.results?.secondSurname}
            </span>
            <div className='d-flex justify-content-end w-100 gap-2'></div>
          </div>

          <div className='simpleTabs__Container m-0'>
            <TabContainer defaultActiveKey={profile}>
              <Nav className={`flex-row`}>
                <Nav.Item className={`${styles.app_multitab_background}`} key={1}>
                  <Nav.Link
                    onClick={() => setActiveTab(1)}
                    className={`${activeTab === 1 ? styles.app_simpleTabsActive : styles.app_simpleTabs} ${styles.app_multitab_title}`}
                    eventKey={1}
                  >
                    Información del paciente
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={`${styles.app_multitab_background}`} key={2}>
                  <Nav.Link
                    onClick={() => setActiveTab(2)}
                    className={`${activeTab === 2 ? styles.app_simpleTabsActive : styles.app_simpleTabs} ${styles.app_multitab_title}`}
                    eventKey={2}
                  >
                    Historial de citas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className={`${styles.app_multitab_background}`} key={3}>
                  <Nav.Link
                    onClick={() => setActiveTab(3)}
                    className={`${activeTab === 3 ? styles.app_simpleTabsActive : styles.app_simpleTabs} ${styles.app_multitab_title}`}
                    eventKey={3}
                  >
                    Ordenamientos
                  </Nav.Link>
                </Nav.Item>
                <div style={{ flex: 1 }} className={`${styles.app_multitab_sin_background}`} />
              </Nav>
              <TabContent className='simpleTabs__relative' style={{ overflowY: 'unset' }}>
                <TabPane eventKey={1} key={'panelTab_1'}>
                  <InfoPatientTab patient={patient} loadPatient={loadPatient} event={event} />
                </TabPane>
                <TabPane eventKey={2} key={'panelTab_2'}>
                  <DatingHistoryTab event={event} />
                </TabPane>
                <TabPane eventKey={3} key={'panelTab_3'}>
                  <OrderingPatientTab patient={patient} event={event} />
                </TabPane>
              </TabContent>
            </TabContainer>
          </div>
        </div>
      </div>
    </>
  );
};
