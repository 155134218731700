import { Component } from 'react';
import { connect } from 'react-redux';

class TableServices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <h1>Services works</h1>;
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TableServices);
