export const theme = {

  colors: {
    darkerBlue: '#003f80',
    darkBlue: '#005dbf',
    darkBlueInput: '#A3BAD1',
    darkBlue2: '#003F80',
    clearBlue: '#01a0f6',
    tenueBlue: '#d5e3f2',
    whiteBlue: '#ccdce4',
    whiteGray: '#a9abae',
    darkerGray: '#58595b',
    darkGray: '#58595b',
    darkGray2: '#CBCBD0',
    darkGray3: '#E8E8EA',
    parragraphColor: '#606062',
    bgFormBlue: '#eff4fb',
    opaqueBlue: '#f2f7fc',
    green: '#00a551',
    red: '#ec1c24',
    orange: '#ff8b00',
    yellow: '#f2c94c',
    diabledBg: '#f4f4f4',
    clearGray: '#cecece',
    Clearorange: 'rgba(255, 139, 0, 0.15)',
    tableWithoutSelect: '#005dbf 0.6',
    tableSelect: '#005dbf 0.2',
    tableEven: '#f5f7fa',
    tableHover: '#d2dde8',
    tableAddition: 'rgba(255, 139, 0, 0.3)',
    tableAdditionHover: 'rgba(255, 139, 0, 0.5)',
    tableTransladar: 'rgba(255, 139, 0, 0.15)',
    tableTransladarHover: 'rgba(255, 139, 0, 0.2)',
    tableText: '#697387',
    paginationSelected: '#f5f7fa',
    paginationHover: '#d2dde8',
    bg: '#f3f8fa',
    white: '#fff',
    black: '#282936',
    greenEnabledOld: '#0edd4e',
    greenEnabled: '#00a551',
    redDisabled: '#ec1c24',
    yellowAux: '#f2c94c',
    backgroundDarkBlue: 'rgba(0, 93, 191, 0.1)',
    darkBlueHover: '#003f80',
    bgInputDisabled: '#f4f4f4',
    clearBlueBorder: '#9bdee7',
    gray: '#A2A3AB',

    /* -------------------------------- ORDERNING ------------------------------- */
    ordAquaMarine: '#00b4cc',
    ordDarkGray: '#6e6f7c',

    /* ---------------------------------- Table --------------------------------- */
    ordTableHeadBgColor: '#d6f3f7',
    ordTableTextColor: '#00b4cc',
    ordDarkBlueText: '#1a538d',
    ordBtnDarkBlueText: '#003f80',
    ordBtnDarkBlueOpacity60: '#5C84AD',
    ordBgAquaMBlueOpacity: '#f5fcfd',
    ordBgAquaMBlueOpacity40: '#a3e4ec',
    ordBgAquaMBlueOpacity60: '#5ccfde',
    ordBgAquaMBlueOpacity50: '#c4e5e7',
    ordClearYellow: '#fcf1e1',
    ordClearYellowOpacity20: '#fefaf5',
    ordDarkBlueOpacity: '#003f8096',


  },
  sizes: {
    title: {
      lg: '40px',
      md: '24px',
      xs: '16px'
    }

  }

}