import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//
import Lupa from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';

import { useGetMethod } from '../../../Hooks/useFetch';
import { getNiifAccounts } from '../../../actions/pucActions';
import { API_VERSION, URL_GATEWAY } from '../../../helpers/constants';
import { convertFilterToString } from '../../../helpers/convertToFilter';
import { loader, optionsSelect, valueSelect } from '../../../helpers/helpers';

import GenericTable from '../../../components/Layouts/GenericTableNew';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import SelectComponent from '../../SelectComponent/SelectComponent';
import { useHasPermissionByTagModule } from '../../../Hooks';
import CustomPopupExtend from '../../Popup/customPopUpExtends';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function NewsSheet() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const counter = useSelector(state => state);
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandepay',
  );
  const token = counter.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(1);
  const [headers, setHeaders] = useState([]);
  const [filters, setFilter] = useState({
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [newsSheetList, setNewsSheetList] = useState();
  const [newsSheetListTotal, setNewsSheetListTotal] = useState();

  const { trigger: getMonths, results: monthOptions, load: monthLoader } = useGetMethod();

  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: storage.loginReducer.currentAccount.id,
        active: 1,
        bank: 1,
      }),
    );

    getMonths({
      url: '/payroll/months',
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  useHasPermissionByTagModule({ module: 'nomina', hasRead: myPermission?.read });

  //fetch zone -_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_
  const fetchData = async () => {
    setIsLoading(true);
    let str_filters = convertFilterToString(filters);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/accrued/sheet?${str_filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        onSetHeadersTable(res.results);
        setNewsSheetList(res.results);
        setNewsSheetListTotal(res.row_total);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err.message);
        setIsLoading(false);
      });
  };

  const searchFilters = () => {
    setFilter({
      ...filters,
      page: 1,
    });
    setTrigger(trigger + 1);
  };
  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };

  const onSetHeadersTable = data => {
    const formatData = data?.header?.map(el => (
      <th
        key={el?.path}
        className={el?.path === 'description' ? 'col-3 px-2 text-start' : 'text-center'}
      >
        {el?.name}
      </th>
    ));

    formatData.push(<th>&nbsp;</th>);

    setHeaders(formatData);
  };

  const renderBody = () => {
    if (Array.isArray(newsSheetList?.data)) {

      return newsSheetList?.data?.map(item => {
        const textMonth = isNaN(parseInt(item.month))
          ? '-'
          : monthNames[parseInt(item.month) - 1];

        return (
          <tr key={'payroll' + item.month + item.year}>
            <td className='text-start col-2' style={{ paddingLeft: '10px' }}>
              <b style={{ color: '#005DBF' }}>{item.description}</b>
            </td>
            <td className='text-center'>{item.year}</td>
            <td className='text-center'>{textMonth}</td>
            <td className='text-center'>{item.count_employees}</td>
            <td className=''>
              <CustomPopupExtend
                noHover
                position='right center'
                triggerSrc={threeDots}
                showDetails={true}
                showDetailsEvent={() =>
                  history.push(`/nomina/NovedadesAprobadas/PlanillaDeDevengados/${item?.year}/${item?.month}`)
                }
              />
            </td>
          </tr>
        );
      });
    }

    return [];
  };

  let optionConsumptionCenter = [{ value: '', label: 'Seleccione área', name: 'area' }];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.forEach(item => {
      optionConsumptionCenter.push({
        value: item.id,
        label: item.description,
      });
    });
  }

  const renderPrincipal = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {(isLoading || monthLoader) && loader}

      <div className='d-flex'>
        <h1 className={tableStyles.title}>Planillas de devengados</h1>
      </div>

      {!!myPermission?.read && (
        <>
          <Row>
            <Col xs={2}>
              <p className={tableStyles.crudModalLabel}>Mes</p>
              <SelectComponent
                value={valueSelect({
                  list: monthOptions.results,
                  findLabel: 'name',
                  findId: 'id',
                  value: filters.month,
                })}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                key={'state' + trigger}
                onChange={e => {
                  setFilter({
                    ...filters,
                    month: e.value,
                    page: 1,
                  });
                  setTrigger(trigger + 1);
                }}
                options={optionsSelect({
                  list: monthOptions.results,
                  label: 'name',
                  value: 'id',
                })}
              ></SelectComponent>{' '}
            </Col>
            <Col xs={4} className='d-flex gap-2'>
              <div className=''>
                <p className={tableStyles.crudModalLabel}>Año</p>
                <input
                  className='register-inputs'
                  type='number'
                  placeholder='Escribir...'
                  value={filters.year}
                  onChange={e =>
                    setFilter({
                      ...filters,
                      year: e.target.value,
                      page: 1,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      searchFilters();
                    }
                  }}
                ></input>
              </div>
              <img
                className='hoverPointer align-self-end'
                alt='iconSearch'
                style={{}}
                src={Lupa}
                height='24px'
                onClick={e => searchFilters(e)}
              />
            </Col>
          </Row>
          <div></div>
          <GenericTable headers={headers} dark={true}>
            {renderBody()}
          </GenericTable>

          <div className={paginationStyles.wrapper}>
            <p className={` ${paginationStyles.paginationText} darkGray`}>
              pag. {filters.page}
              {' de '}
              {Math.ceil(newsSheetListTotal / filters.perpage)
                ? Math.ceil(newsSheetListTotal / filters.perpage)
                : '1'}{' '}
              ({' ' + newsSheetListTotal}
              {' encontrados'})
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={newsSheetListTotal}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClassPrev={`${paginationStyles.itemClassPrev} `}
              itemClassNext={`${paginationStyles.itemClassNext} `}
              itemClassFirst={`${paginationStyles.itemClassFirst} `}
              itemClassLast={`${paginationStyles.itemClassLast} `}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </>
      )}
    </div>
  );

  return renderPrincipal;
}
export default NewsSheet;
