import icon from '../assets/img/icons/iconBack.svg'
import styles from '../components/Layouts/tableStyle.module.scss'
import { themes } from '../helpers'

export const Title = ({ title = 'Titulo', backIconNone, backIcon, onClickIcon, className, theme = themes.ADMINISTRATIVO }) => {

    const handleClickIcon = () => {
        onClickIcon && onClickIcon()
    }

    return (
        <div 
            className={`${className} d-flex align-items-center`}
            style={{gap:'13px'}}
        >
            {
                !backIconNone ? (
                    <img
                        width={32}
                        height={32}
                        src={backIcon || icon}
                        alt="regresar"
                        onClick={handleClickIcon}
                        className={`pointer`}
                        style={{ marginLeft: '-45px' }}
                    />
                ) : ''
            }
            <h1
                className={`${styles.f45} ${theme === themes.ADMINISTRATIVO ? styles.darkBlueText : styles.ordDarkBlueText} fw-bold m-0`}
            >
                {title}
            </h1>
        </div>
    )

}