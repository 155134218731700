// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState, useEffect } from 'react';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Imprimir from '../../assets/img/icons/imprimir.svg';

//-------------- scss styles 😄   --------------
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

//-------------- actions 😄   --------------
import { useHistory } from 'react-router-dom';

import { getCashFlow } from '../../actions/accountingConsultsActions';
import { PdfViewer } from '../Layouts/PdfViewer';
import { getPdfCashFlow } from '../../actions/consultAction';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from '../../shared';

function CashFlow() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateUntil: '',
    search: '',
    amountFrom: '',
    amountUntil: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [info, setInfo] = useState([]);

  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState('');
  const getPdf = async () => {
    const result = await getPdfCashFlow(filters, storage.loginReducer.Authorization);
    if (result?.success) {
      return setBase64(result?.result?.base64.split("'")[1]);
    } else {
      setShowPdf(false);

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: result?.message,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };
  useEffect(() => {
    if (showPdf) {
      getPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPdf]);
  //-------------- first actions 😄   --------------

  useEffect(() => {
    dispatch(getCashFlow(filters, setInfo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  let optionsNiif = [{ key: 'default', value: '', label: 'Seleccione cuenta', id: '' }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.forEach(item => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }

  let optionsExpired = [{ key: 'default1', value: '', label: 'Seleccione un vencimiento', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsExpired.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  // --------------- Table 💥  ----------------//

  const header = [
    <div key={1} className={`col-3 text-start px-2`}>
      Descripción
    </div>,
    <div key={2} className={`col-3 text-start`}>
      Tipo de movimiento
    </div>,
    <div key={3} className={`col-3 text-center`}>
      Fecha movimiento
    </div>,
    <div key={4} className={`col-3 text-end px-2`}>
      Valor
    </div>,
  ];

  const header2 = [
    <div key={1} className={`col-3 text-start px-2`}>
      Descripción
    </div>,
    <div key={2} className={`col-3 text-start`}>
      Tipo de movimiento
    </div>,
    <div key={3} className={`col-3 text-center`}>
      Fecha movimiento
    </div>,
    <div key={4} className={`col-3 text-end px-2`}>
      Valor
    </div>,
  ];

  var Total1 = 0;
  const renderList = () => {
    let tempList = [];
    if (Array.isArray(info.incoming)) {
      info.incoming.forEach((item, index) => {
        Total1 = Total1 + Number(item.amount);
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-start col-3 px-2'>{item.observations}</div>
            <div className='text-start col-3 '>{item.typeName}</div>
            <div className='text-center col-3 '>
              {item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className='text-end col-3 px-2'>
              {item.amount ? '$' + numberWithCommas(item.amount) : '$0'}
            </div>
          </section2>,
        );
      });

      tempList.push(
        <section2 className={`d-flex`} key={'849302'}>
          <div className='text-start col-3 '>{}</div>
          <div className='text-start col-3 '>{}</div>
          <div
            className='text-center col-3 '
            style={{ fontWeight: '700', fontSize: '15px', color: '#58595B' }}
          >
            {'Total'}
          </div>
          <div
            className='text-end col-3 px-2'
            style={{ fontWeight: '700', fontSize: '15px', color: '#58595B' }}
          >
            {'$' + numberWithCommas(Total1)}
          </div>
        </section2>,
      );
    }

    return tempList;
  };

  var Total2 = 0;
  const renderList2 = () => {
    let tempList = [];
    //let total=0;
    let indexT = 0;
    if (Array.isArray(info.outgoing)) {
      info.outgoing.forEach((item, index) => {
        Total2 = Total2 + Number(item.amount);
        indexT = index;
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-start col-3 px-2'>{item.observations}</div>
            <div className='text-start col-3'>{item.typeName}</div>
            <div className='text-center col-3'>
              {item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className='text-end col-3 px-2'>
              {item.amount ? '$' + numberWithCommas(item.amount) : '$0'}
            </div>
          </section2>,
        );
      });
      tempList.push(
        <section2 className={`d-flex`} key={indexT + 1}>
          <div className='text-start col-3'>{}</div>
          <div className='text-start col-3'>{}</div>
          <div
            className='text-center col-3'
            style={{ fontWeight: '700', fontSize: '15px', color: '#58595B' }}
          >
            {'Total'}
          </div>
          <div
            className='text-end col-3 px-2'
            style={{ fontWeight: '700', fontSize: '15px', color: '#58595B' }}
          >
            {'$' + numberWithCommas(Total2)}
          </div>
        </section2>,
      );
    }
    return tempList;
  };

  // --------------- Functions 💥  ----------------//
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {storage.accountingConsultsReducer.cashFlow_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Flujo de caja'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        <Row className='d-flex'>
          <Col xs={3}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Fecha desde</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                value={filters.dateFrom}
                onChange={e =>
                  setFilters({
                    ...filters,
                    dateFrom: e.target.value,
                  })
                }
                placeholder='Escribir...'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              ></input>
            </form>
          </Col>
          <Col xs={3}>
            <label className={tableStyles.crudModalLabel}>Hasta</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='date'
              value={filters.dateUntil}
              onChange={e =>
                setFilters({
                  ...filters,
                  dateUntil: e.target.value,
                })
              }
              placeholder='Escribir...'
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            ></input>
          </Col>
          <Col xs={3}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Valor desde</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                value={filters.amountFrom}
                onValueChange={values => {
                  const { value } = values;
                  setFilters({
                    ...filters,
                    amountFrom: value,
                  });
                }}
                placeholder='Escribir...'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              ></NumberFormat>
            </form>
          </Col>
          <Col xs={3}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Valor hasta</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                value={filters.amountUntil}
                onValueChange={values => {
                  const { value } = values;
                  setFilters({
                    ...filters,
                    amountUntil: value,
                  });
                }}
                placeholder='Escribir...'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              ></NumberFormat>
            </form>
          </Col>
        </Row>

        <Row className='d-flex'>
          <Col xs={9} style={{ alignSelf: 'end' }}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={`${tableStyles.crudModalLabel}`}></label>
              <input
                className={tableStyles.SearchNew}
                placeholder='Buscar por descripción o tipo de movimiento...'
                type='text'
                onChange={e =>
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              ></input>
            </form>
          </Col>

          <div className=' col display-grid' style={{ alignContent: 'end' }}>
            <label className=''>
              {' '}
              <img
                className={`${tableStyles.cursorPointer}`}
                src={Lupa}
                height='24px'
                alt='icon'
                onClick={() => setTrigger(trigger + 1)}
              />
            </label>
          </div>

          <div className=' display-grid col-1' style={{ alignContent: 'end' }}>
            <label className=''>ㅤ </label>
            <div className='col text-end'>
              <img
                className={`${tableStyles.cursorPointer} `}
                style={{ paddingLeft: '5px', paddingRight: '5px' }}
                src={Imprimir}
                alt='idconPDF'
                onClick={() => setShowPdf(true)}
              />
              <img alt='' className={`${tableStyles.cursorPointer} `} src={Excel} />
            </div>
          </div>
        </Row>

        <Row>
          <label
            className={`${tableStyles.crudModalLabel}`}
            style={{
              fontWeight: '700',
              fontStyle: 'bold',
              fontSize: '15px',
              marginTop: '10px',
            }}
          >
            Ingresos
          </label>
        </Row>

        <div>
          <GenericTableScroll
            headers={header}
            dark={true}
            body={renderList()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>
        <Row>
          <label
            className={`${tableStyles.crudModalLabel}`}
            style={{
              fontWeight: '700',
              fontStyle: 'bold',
              fontSize: '15px',
              color: '#01A0F6',
            }}
          >
            Egresos
          </label>
        </Row>
        <div>
          <GenericTableScroll
            headers={header2}
            dark={true}
            body={renderList2()}
            typeHead={'3'}
          ></GenericTableScroll>
        </div>

        <Row>
          <Col xs={10} style={{ alignSelf: 'end' }}></Col>
          <Col xs={2} style={{ alignSelf: 'end' }}>
            <label className={`${tableStyles.crudModalLabel}`}>Diferencia</label>
            <NumberFormat
              allowNegative={false}
              style={{
                padding: 0,
                color: '#00A551',
                textAlign: 'end',
                paddingRight: '2px',
              }}
              className={IndividualStyles.registerInputs}
              name='valueNote'
              thousandsGroupStyle='thousand'
              thousandSeparator={'.'}
              decimalSeparator={','}
              prefix={'$'}
              value={Total1 - Total2}
              // onValueChange={(values) => {
              //     const { value } = values;
              //     setcreatEgress({
              //         ...creatEgress,
              //         amount: value,
              //     })
              //     }}
            ></NumberFormat>
          </Col>
        </Row>
      </div>
      <ModalNew
        title='Flujo de caja'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default CashFlow;
