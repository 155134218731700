import { BasicTemplate as StyledTemplate, Title } from './../../UI/atoms/';

export const BasicTemplate = ({ children, title, marginTitle }) => {
  return (
    <StyledTemplate>
      <Title title={title} margin={marginTitle} />
      {children}
    </StyledTemplate>
  );
};
