// Array for required fields in provider form / persona natural
export const supportFilesArray = {
    chamberCommerce:"Cámara de comercio",
    rutPhotocopy:"RUT",
    docPhotocopy:"Fotocopia cedula",
    socialSecurity:"Certificado pago de aportes en línea",
    rentDeclaration:"Declaración de renta",
    bankAccount:"Certificado de cuenta bancaria original",
    disciplinaryRecords:"Certificado de antecedentes disciplinarios de la persona",
    fiscalResponsability   :"Certificado de antecedentes de responasbilidad fiscal",
}
export const generalInformationCheckArray = {
    dilig_date:"Fecha de diligenciamiento",
    name:"Razón social",
    nit:"NIT",
    //verDigit:"Dígito de verificación",
    company_type:"Tipo de empresa",
    society_type:"Tipo de sociedad",
    country:"País",
    province:"Departamento",
    city:"Ciudad",
    address:"Dirección",
    phone:"Teléfono",
    cellphone:"Celular",
    businessActivity:"Fecha de diligenciamiento",
    classification:"Fecha de diligenciamiento",
    ciiu:"Actividad económica"
}
export const financialInformationCheckArray = {
    totalAssets:"Total activos",
    totalPassives:"Total pasivos",
    patrimony:"Patrimonios",
    monthlyIncome:"Ingresos mensuales",
    monthlyExpenses:"Egresos mensuales",
    otherIncome:"Otros ingresos",
    otherIncomeConcept:"Concepto de otros ingresos",
    cortDate:"Información a corte de"
}
export const legalRepresentArray = {
    firstNameLglRepresent:"",
    firstSurnameLglRepresent:"",
    docTypeLglRepresent:"",
    docNumLglRepresent:"",
    expLocationLglRepresent:"",
    emailLglRepresent:"",
    phoneLglRepresent:"",
    cellphoneLglRepresent:"",
    personType_shareholder_rpst:"",    
    expLocationShareholder:"",
}

export const naturalPersonOnLglReprsent = {
    fisrtNameShareholder_lgl_rpst:"",
    fisrtSurnameShareholder_lgl_rpst:"",
    docTypeShareholder_lgl_rpst:"",
    docNumShareholder_lgl_rpst:"",
}
export const legalPersonOnLglReprsent = {
    businessName_lgl_rprst:"",
    nitShareholder_lgl_rpst:"",
}

export const requiredFieldsDeclaration = [
    "authConsultBackground",
    "authVerifyInfoDoc",
    "authVisitRecord",
    "authProcessPersonalData"
]

export const generalInformationCheckArrayNatural = {
    firstNameLglRepresent:"",
    firstSurnameLglRepresent:"",
    docTypeLglRepresent:"",
    docNumLglRepresent:"",
    expLocationLglRepresent:"",
    emailLglRepresent:"",
    phoneLglRepresent:"",
    cellphoneLglRepresent:"",
    country:"",
    province:"",
    city:"",
    address:"",
    businessActivity:"",
    occupation:"",
    profession:"",
}
export const supportFilesArrayNatural = {
    rutPhotocopy:"RUT",
    docPhotocopy:"Fotocopia cedula",
    bankAccount:"Certificado de cuenta bancaria original",
}
export const requiredFieldsBankInfo = [
    "bankAccountType",
    "bankingEntity",
    "accountNumber",
    "accountHolder",
    "countryBankInfo",
    "provinceBankInfo",
    "cityBankInfo",
    "bankBranch",
]

export const requiredFieldExtraForeignCurrency = [
    "foreignBankingEntity",
    "foreignBankingType",
    "foreignNumberBankAccount",
    "foreignBankAmount",
    "foreignBankCountry",
    "foreignBankCity",
    "foreignCoin",
]

export const requiredFieldsExtraCommercialReferences = [
    "nameCommercialReference",
    "phoneCommercialReference",
    "cellphoneCommercialReference",
    "emailCommercialReference",
    "countryCommercialReference",
    "provinceCommercialReference",
    "cityCommercialReference",
    "addressCommercialReference",
    "supportFileCommercialReference",
]
