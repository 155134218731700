import GearGray from '../../assets/img/icons/engraneGris.svg';
import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import boxReceiptStyles from '../RecibosDeCaja/boxReceiptStyles.module.scss';
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useDispatch, useSelector } from 'react-redux';
import { getCorporateClients } from '../../actions/corporateClientsActions';
import { getProviders } from '../../actions/providersActions';
import { getPinvoices, sentCrossingAccounts } from '../../actions/crossingAccountsAction';
import NumberFormat from 'react-number-format';
import { getInvoices } from '../../actions/cashReceiptAction';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { getNiifAccounts } from '../../actions/AccountsActions';
import { GET_INVOICES, GET_PINVOICES } from '../../actions/actionTypes';
import { useHistory } from 'react-router';
import Loader from 'react-loader-spinner';
import { Title } from '../../shared';

function CrossingAccounts() {
  const [showModal, setShowModal] = useState(false);
  const [showModalPinvoice, setShowModalPinvoice] = useState(false);
  const [changeRadius] = useState('20px');
  const [, setItemPinvoice] = useState({ pinvoice: {} });
  const [selectedListInvoice, setselectedListInvoice] = useState([]);
  const [selectedListPinvoice, setselectedListPinvoice] = useState([]);
  const [changeProCli, setChangeProCli] = useState([]);
  const [searchPinvoices, setSearchPinvoices] = useState([]);
  const [showRenderPinvoices, setShowRenderPinvoices] = useState({ show: false });
  const [searchInvoices, setSearchInvoices] = useState([]);
  const [showModalAjust, setShowModalAjust] = useState(false);
  const [sumTotalPinvoice, setSumTotalPinvoice] = useState([]);
  const [sumTotalInvoice, setSumTotalInvoice] = useState([]);
  const [valuedifer, setValuedifer] = useState('');
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const [trigger3, setTrigger3] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sentInvoiceAll, setsentInvoiceAll] = useState({
    created_by: counter.loginReducer.user_data.id,
    eaccount: counter.loginReducer.currentAccount.id,
    doc_type: 7,
    facturas: [],
  });
  let history = useHistory();

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'CruCta',
  );

  useEffect(() => {
    if (!myPermission?.create || !myPermission?.read) {
      history.push('tesoreria/inicio');
    }
  });

  useEffect(() => {
    dispatch(getCorporateClients({ id_account: counter.loginReducer.currentAccount.id }));
    dispatch(getProviders({ eaccount: counter.loginReducer.currentAccount.id, status: 'enabled' }));
    dispatch(getNiifAccounts({ eaccount: counter.loginReducer.currentAccount.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (changeProCli?.provaider) {
      dispatch(
        getPinvoices(
          {
            provider_id: changeProCli?.provaider ? changeProCli?.provaider : '',
            invoice_nbr: searchPinvoices?.search ? searchPinvoices?.search : '',
            due_date: searchPinvoices?.date ? searchPinvoices?.date : '',
          },
          () => setLoading(false),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger2]);

  useEffect(() => {
    if (changeProCli?.client) {
      dispatch(
        getInvoices(
          {
            corporate_client: changeProCli?.client ? changeProCli?.client : '',
            exp_date: searchInvoices.exp_date ? searchInvoices.exp_date : '',
            sequence: searchInvoices.sequence ? searchInvoices.sequence : '',
          },
          () => setLoading(false),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger3]);

  // funcion para formatear valores a peso
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  // funcion para formatear la fecha
  const formatDate = date => {
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    let formatted_date = date.getFullYear() + '-' + month + '-' + date.getDate();
    return formatted_date;
  };
  const formatDateTwo = date => {
    let formatted_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return formatted_date;
  };
  const isBoxSelected = id_req => {
    if (Array.isArray(selectedListInvoice)) {
      let target = selectedListInvoice.find(x => x.id === id_req);
      return !!target;
    }
  };
  const changeBoxSelected = item => {
    let tempSelected = selectedListInvoice;
    let values = sumTotalInvoice;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      let target2 = values.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }

      if (target2) {
        values = values.filter(x => x.id !== item.id);
      }

      setselectedListInvoice(tempSelected);
      setSumTotalInvoice(values);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;

    if (Array.isArray(counter.cashReceipttReducer?.results) && Array.isArray(selectedListInvoice)) {
      counter.cashReceipttReducer?.results.forEach(item => {
        if (selectedListInvoice.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(counter.cashReceipttReducer.results) && Array.isArray(tempSelected)) {
        counter.cashReceipttReducer.results.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setselectedListInvoice(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedListInvoice([]);
    }
  };

  const isBoxSelectedTwo = id_req => {
    if (Array.isArray(selectedListPinvoice)) {
      let target = selectedListPinvoice.find(x => x.id === id_req);
      return !!target;
    }
  };
  const changeBoxSelectedTwo = item => {
    let tempSelected = selectedListPinvoice;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          ...item,
        };
        tempSelected.push(newChange);
      }
      setselectedListPinvoice(tempSelected);
    }
  };
  const isBoxAllSelectedTwo = () => {
    let res = true;

    if (Array.isArray(counter.PinvoiceReducer?.results) && Array.isArray(selectedListPinvoice)) {
      counter.PinvoiceReducer.results.forEach(item => {
        if (selectedListPinvoice.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelectedTwo = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(counter.PinvoiceReducer.results) && Array.isArray(tempSelected)) {
        counter.PinvoiceReducer.results.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setselectedListPinvoice(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedListPinvoice([]);
    }
  };

  var optionClients = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.corporateclientsReducer.corporateClients)) {
    counter.corporateclientsReducer.corporateClients.forEach(item => {
      if (!!item.enabled === true) {
        optionClients.push({
          value: item.id,
          label: item.Nit + ' - ' + item.copyName,
          key: item.id + 'wweeh',
        });
      }
    });
  }
  var optionProvider = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.providerReducer.providers)) {
    counter.providerReducer.providers.forEach(item => {
      optionProvider.push({
        value: item.id,
        label: item.nit + ' - ' + item.name,
        key: item.id + 'wwqweh',
      });
    });
  }
  var NiifAccountsModal = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.pucReducer.niifAccounts)) {
    counter.pucReducer.niifAccounts.forEach(item => {
      if (item.active === true) {
        NiifAccountsModal.push({
          value: item.id,
          label: item.complete_account + ' - ' + item.description,
          key: item.id + 'qc',
        });
      }
    });
  }
  const headerModalPinvoice = [
    <div key={1} className='col-1 text-center'>
      <input
        className='border border-dark-blue form-check-input p1 check-dark-blue'
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelectedTwo()}
        onChange={() => changeBoxAllSelectedTwo(!isBoxAllSelectedTwo())}
      />
    </div>,
    <div key={2} className='col text-start px-2'>
      No. Factura
    </div>,
    <div key={3} className='col text-start px-2'>
      Vence
    </div>,
    <div key={4} className='col text-end px-3'>
      Valor
    </div>,
  ];
  const bodyModalPinvoice = [];
  if (Array.isArray(counter.PinvoiceReducer.results)) {
    if (counter.PinvoiceReducer.results.length === 0) {
      bodyModalPinvoice.push(
        <section2 htmlFor='PinvoiceSelect'>
          <div className='col-12 text-center'>No se encontraron registros</div>
        </section2>,
      );
    } else {
      counter.PinvoiceReducer.results.forEach(item => {
        item.doc_type = 1;
        bodyModalPinvoice.push(
          <section2 htmlFor='PinvoiceSelect'>
            <div className='col-1 text-center'>
              <input
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                type='checkbox'
                checked={isBoxSelectedTwo(item.id)}
                onChange={() => changeBoxSelectedTwo(item)}
              />
            </div>
            <div className='col text-start px-2'>{item.invoice_nbr}</div>
            <div className='col text-start px-2'>{convertDateToLatinFormat(item.due_date)}</div>
            <div className='col text-end px-3'>$ {numberWithCommas(item.total_amount)}</div>
          </section2>,
        );
      });
    }
  }

  const addItemTable = item => {
    let tempSelected = selectedListPinvoice;
    let tempSelected2 = sumTotalPinvoice;
    let target = tempSelected.find(x => x.id === item.id);
    let target2 = tempSelected2.find(x => x.id === item.id);
    if (target) {
      tempSelected = tempSelected.filter(x => x.id !== item.id);
    } else {
      let newChange = {
        ...item,
      };
      tempSelected.push(newChange);
    }
    if (target2) {
      tempSelected2 = tempSelected2.filter(x => x.id !== item.id);
    }
    setselectedListPinvoice(tempSelected);
    setSumTotalPinvoice(tempSelected2);
    setItemPinvoice({
      pinvoice: {},
    });
    setShowModalPinvoice(false);
  };

  const headerModalInvoice = [
    <th key={1}>
      <input
        className='border border-dark-blue form-check-input p1 check-dark-blue'
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2}>No. factura</th>,
    <th key={3}>Fecha</th>,
    <th key={4}>Valor</th>,
  ];
  const bodyModalInvoice = [];
  if (Array.isArray(counter.cashReceipttReducer?.results)) {
    if (counter.cashReceipttReducer?.results.length === 0) {
      bodyModalInvoice.push(
        <tr className='col-12 text-center'>
          <td>No se encontraron registro</td>
        </tr>,
      );
    } else {
      counter.cashReceipttReducer?.results.forEach(item => {
        item.doc_type = 0;
        bodyModalInvoice.push(
          <tr>
            <td>
              <input
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                type='checkbox'
                checked={isBoxSelected(item.id)}
                onChange={() => changeBoxSelected(item)}
              />
            </td>
            <td>{item.sequence}</td>
            <td>{convertDateToLatinFormat(item.exp_date)}</td>
            <td>$ {numberWithCommas(item.total_amount)}</td>
          </tr>,
        );
      });
    }
  }

  const header1 = [
    <div key={1} className='col-2 text-center'>
      No. de Factura{' '}
    </div>,
    <div key={2} className='col-1 text-center'>
      Fecha
    </div>,
    <div key={3} className='col-2 text-center'>
      Saldo
    </div>,
    <div key={4} className='col-3 text-center'>
      Cuenta por pagar
    </div>,
    <div key={5} className='col-3 text-center'>
      Valor pagado
    </div>,
    <div key={6} className='col-1 text-center'>
      &nbsp;
    </div>,
  ];

  const findValueTotalAbono = () => {
    if (Array.isArray(sumTotalInvoice)) {
      let value = 0;
      sumTotalInvoice.forEach(curr => {
        if (curr.amount) {
          value += curr.amount;
        }
      });
      return value;
    } else {
      return 0;
    }
  };

  const findValueTotalPinvoice = () => {
    if (Array.isArray(sumTotalPinvoice)) {
      let value = 0;
      sumTotalPinvoice.map(curr => {
        if (curr.amount) {
          value += curr.amount;
        }
        return value;
      });
    } else {
      return 0;
    }
  };

  const editValueArray = (id, field, value, docNbr, account, date) => {
    let temp = [...sumTotalInvoice];
    const target = temp.findIndex(elem => elem.id === id);
    if (target >= 0) {
      temp[target][field] = value;
    } else {
      let upcoming = {
        id: id,
        doc_number: docNbr,
        doc_type: 1,
        doc_account: account,
        doc_date: date,
      };
      upcoming[field] = value;
      temp.push(upcoming);
    }
    setSumTotalInvoice(temp);
  };

  const editValueArray2 = (id, field, value, docNbr, account, date) => {
    let temp = [...sumTotalPinvoice];

    const target = temp.findIndex(elem => elem.id === id);
    if (target >= 0) {
      temp[target][field] = value;
    } else {
      let upcoming = {
        id: id,
        doc_number: docNbr,
        doc_type: 4,
        doc_account: account,
        doc_date: date,
      };
      upcoming[field] = value;
      temp.push(upcoming);
    }
    setSumTotalPinvoice(temp);
  };

  const renderTablePinvoices = () => {
    let table = [];
    if (Array.isArray(selectedListPinvoice)) {
      table = selectedListPinvoice.map(item => {
        return (
          <section2 key={item.id + 'qfye3'}>
            <div className='col-2 text-center'>{item?.invoice_nbr}</div>
            <div className='col-1 text-center'>{convertDateToLatinFormat(item?.due_date)}</div>
            <div className='col-2 text-center'>$ {numberWithCommas(item?.total_amount)}</div>
            <div className='col-3 text-center'>{item?.provider_name}</div>
            <div className='col-3 text-center'>
              <NumberFormat
                key={item.id + 'qs4qa3'}
                allowNegative={false}
                id='cost_price'
                maxLength='12'
                style={{
                  fontSize: '13px',
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                onValueChange={e => {
                  editValueArray2(
                    item?.id,
                    'amount',
                    e.floatValue,
                    item?.invoice_nbr,
                    item?.entity_account,
                    item?.due_date,
                  );
                }}
                disabled={valuedifer ? true : false}
                isAllowed={values => {
                  const { floatValue } = values;
                  if (floatValue > item?.total_amount) {
                    return false;
                  } else {
                    if (Number(floatValue) !== '') {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }}
              />
            </div>
            <div className='col-1 text-center' style={{ paddingRight: '1rem' }}>
              <div>
                <b
                  style={{ color: '#005DBF' }}
                  onClick={() => (valuedifer === '' ? addItemTable(item) : '')}
                >
                  X
                </b>
              </div>
            </div>
          </section2>
        );
      });
    }
    return table;
  };

  const footer1 = [
    <div key={1} className='d-flex  mb-5'>
      <div className='col-3 text-secondary' style={{ paddingLeft: '3rem' }}>
        Totales
      </div>
      <div className='col-4'></div>
      <div className='col-5 text-center'>
        <b style={{ color: '#005DBF' }}>$ {numberWithCommas(findValueTotalPinvoice())}</b>
      </div>
    </div>,
  ];

  const header2 = [
    <th key={1} className='text-center col-2'>
      Factura No.{' '}
    </th>,
    <th key={2} className='text-center'>
      Saldo
    </th>,
    <th key={3} className='text-center'>
      Retefuente
    </th>,
    <th key={4} className='text-center'>
      ReteIVA
    </th>,
    <th key={5} className='text-center'>
      ReteICA
    </th>,
    <th key={6} className='text-center'>
      Descuento
    </th>,
    <th key={7} className='text-center col-2'>
      Recibido{' '}
    </th>,
    <th key={8} className='text-center'>
      &nbsp;
    </th>,
  ];
  const renderTable = () => {
    let valor = 0;
    let table = [];
    if (Array.isArray(selectedListInvoice)) {
      table = selectedListInvoice.map(x => {
        if (x.pending_amount) {
          valor = x.pending_amount + valor;
        }
        return (
          <tr key={x.id + 'row744'}>
            <td className='text-center text-uppercase'>{x.sequence}</td>
            <td className='text-center'>$ {numberWithCommas(x.pending_amount)}</td>
            <td className='text-center'> - </td>
            <td className='text-center'> - </td>
            <td className='text-center'> - </td>
            <td className='text-center'> - </td>
            <td className='text-end' style={{ paddingRight: '1rem' }}>
              <NumberFormat
                key={x.id + 'qsx'}
                allowNegative={false}
                id='cost_price'
                style={{
                  fontSize: '13px',
                  border: 'none',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                }}
                className={`${IndividualStyles.registerInputsGris} ${boxReceiptStyles.hoverinputs}`}
                prefix='$'
                thousandsGroupStyle='thousand'
                thousandSeparator
                onValueChange={e => {
                  editValueArray(
                    x.id,
                    'amount',
                    e.floatValue,
                    x.sequence,
                    x.corporate_client,
                    x.exp_date,
                  );
                }}
                disabled={valuedifer ? true : false}
                isAllowed={values => {
                  const { floatValue } = values;
                  if (floatValue > x?.pending_amount) {
                    return false;
                  } else {
                    if (Number(floatValue) !== '') {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }}
              />
            </td>
            <td className='text-center' style={{ paddingRight: '1rem' }}>
              <div onClick={() => (valuedifer === '' ? changeBoxSelected(x) : '')}>
                <b style={{ color: '#005DBF' }}>X</b>
              </div>
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const footer2 = [
    <tr key={1}>
      <td className='text-secondary'>Totales</td>
      <td colSpan='5'></td>
      <td className='text-center'>
        <b style={{ color: '#01A0F6' }}>$ {numberWithCommas(findValueTotalAbono())}</b>
      </td>
    </tr>,
  ];
  let difere = 0;
  if (findValueTotalPinvoice() !== 0 && findValueTotalAbono() !== 0) {
    difere = numberWithCommas(findValueTotalPinvoice() - findValueTotalAbono());
  }
  const btnAjustModal = () => {
    setShowModalAjust(true);
  };
  const applyAdjustment = () => {
    setValuedifer('0');
    setShowModalAjust(false);
  };

  const sendPostCrossing = () => {
    sentInvoiceAll.id_sup_cli = changeProCli.client;
    sentInvoiceAll.sup_cli_name = changeProCli.clientName;
    sentInvoiceAll.total_amount = findValueTotalAbono();
    sentInvoiceAll.facturas = sumTotalPinvoice.concat(sumTotalInvoice);
    sentInvoiceAll.date = formatDateTwo(new Date());
    dispatch(sentCrossingAccounts(sentInvoiceAll, () => resetForm()));
  };
  const selectProvaider = e => {
    setChangeProCli({ ...changeProCli, provaider: e.value });
    setTrigger2(trigger2 + 1);
    setselectedListPinvoice([]);
  };
  const selectClient = e => {
    setChangeProCli({ ...changeProCli, client: e.value, clientName: e.label });
    setTrigger3(trigger3 + 1);
    setselectedListInvoice([]);
  };
  const resetForm = () => {
    setselectedListInvoice([]);
    setselectedListPinvoice([]);
    setChangeProCli([]);
    setTrigger(trigger + 1);
    setSumTotalPinvoice([]);
    setSumTotalInvoice([]);
    setValuedifer('');
    difere = 0;
    dispatch({
      type: GET_PINVOICES,
      payload: [],
      total: [],
    });
    dispatch({
      type: GET_INVOICES,
      payload: [],
      total: [],
      rows: [],
    });
  };
  const openModalPinvoice = () => {
    setShowModalPinvoice(valuedifer ? '' : true);
    setTrigger2(trigger2 + 1);
  };
  const openModalInvoices = () => {
    setShowModal(valuedifer ? '' : true);
    setTrigger3(trigger3 + 1);
  };
  const closeModalPinvoices = () => {
    setselectedListPinvoice([]);
    setShowModalPinvoice(false);
    setSearchPinvoices([]);
  };
  const closeModalInvoices = () => {
    setselectedListInvoice([]);
    setShowModal(false);
    setSearchInvoices([]);
  };
  const renderlisPinvoices = () => {
    setShowRenderPinvoices({ ...showRenderPinvoices, show: true });
    setShowModalPinvoice(false);
    // setTimeout(setShowRenderPinvoices({ ...showRenderPinvoices, show: false }),10000)
  };

  const handleSearchModal = e => {
    e.preventDefault();
    setTrigger3(trigger3 + 1);
    setLoading(true);
  };
  return (
    <>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <Title
          title='Cruce de cuentas por pagar y cobrar'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/tesoreria/inicio');
          }}
        />
        <div>
          <div className='div'>
            <div className='col-6 display-grid'>
              <label className={tableStyles.crudModalLabel}>Proveedor*</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'provider' + trigger}
                placeholder='Seleccionar...'
                options={optionProvider}
                styles={customSelectNewDark}
                onChange={e => {
                  selectProvaider(e);
                  setLoading(true);
                }}
                isDisabled={valuedifer !== '' ? true : false}
              />
            </div>
            <div className='col-6 display-grid ml-3' style={{ paddingRight: '1rem' }}>
              <label className={tableStyles.crudModalLabel}>Cliente*</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'client' + trigger}
                placeholder='Seleccionar...'
                options={optionClients}
                styles={customSelectNewDark}
                onChange={e => {
                  selectClient(e);
                  setLoading(true);
                }}
                isDisabled={valuedifer !== '' ? true : false}
              />
            </div>
          </div>

          <div className='div mb-4'>
            <div className='col-9 display-grid' style={{ paddingRight: '1rem' }}>
              <label className={tableStyles.crudModalLabel}>Detalle*</label>
              <input
                key={'detalle' + trigger}
                placeholder='Escribir...'
                className={IndividualStyles.registerInputsGris}
                type='text'
                onChange={e =>
                  setsentInvoiceAll({
                    ...sentInvoiceAll,
                    observations: e.target.value,
                  })
                }
                // disabled={valuedifer != "" ? true : false}
              />
            </div>
            <div className='col-3 display-grid'>
              <label className={tableStyles.crudModalLabel}>Fecha</label>
              <input
                className={`${IndividualStyles.registerInputsBlue} `}
                type='date'
                value={formatDate(new Date())}
                disabled={true}
              />
            </div>
          </div>
          <button
            onClick={() => (changeProCli?.provaider ? openModalPinvoice() : '')}
            className='text-start'
            style={{
              color: '#005DBF',
              backgroundColor: '#F2F7FC',
              borderRadius: `20px 20px ${changeRadius} ${changeRadius}`,
              padding: '10px',
              width: '100%',
            }}
          >
            <b>+ Agregar facturas por pagar</b>
          </button>
          <div>
            <GenericTableScroll
              headers={header1}
              dark={true}
              body={showRenderPinvoices.show === true ? renderTablePinvoices() : ''}
              typeHead={'2'}
            ></GenericTableScroll>
            {footer1}
          </div>
        </div>
        <ModalNew
          show={showModal}
          onHide={() => closeModalInvoices()}
          title={'Facturas por cobrar'}
          btnYesEvent={() => setShowModal(false)}
          btnYesName={'Seleccionar'}
          size={'620'}
        >
          <Row className='d-flex'>
            <Col xs={5}>
              <form onSubmit={e => handleSearchModal(e)}>
                <label className={tableStyles.crudModalLabel}>Consec. / cheque</label>
                <input
                  className={IndividualStyles.registerInputs}
                  type='text'
                  placeholder='Escribir...'
                  onChange={e =>
                    setSearchInvoices({
                      ...searchInvoices,
                      sequence: e.target.value,
                    })
                  }
                ></input>
              </form>
            </Col>
            <Col xs={5}>
              <label className={tableStyles.crudModalLabel}>Fecha</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                onChange={e =>
                  setSearchInvoices({
                    ...searchInvoices,
                    exp_date: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col xs={2} className='d-grid'>
              <label className={tableStyles.crudModalLabel}>ㅤ</label>
              <i>
                <img
                  alt='lupa'
                  style={{ width: '25px' }}
                  src={Lupa}
                  onClick={() => {
                    setTrigger3(trigger3 + 1);
                    setLoading(true);
                  }}
                />
              </i>
            </Col>
          </Row>
          <GenericTable headers={headerModalInvoice}>{bodyModalInvoice}</GenericTable>
        </ModalNew>
        <div>
          <button
            onClick={() => (changeProCli?.client ? openModalInvoices() : '')}
            className='text-start'
            style={{
              color: '#01A0F6',
              backgroundColor: '#F2F7FC',
              borderRadius: `20px 20px ${changeRadius} ${changeRadius}`,
              padding: '10px',
              width: '100%',
            }}
          >
            <b>+ Agregar facturas por cobrar</b>
          </button>
        </div>

        <div>
          <GenericTable headers={header2} footer={footer2}>
            {renderTable()}
          </GenericTable>
          <Row className='mb-5 mt-5'>
            <div className='d-flex'>
              <div className='d-grid'>
                <label className={tableStyles.crudModalLabel}>Diferencia</label>
                <input
                  className='register-inputs '
                  style={{ width: 'max-content', fontWeight: 'bold' }}
                  type='text'
                  placeholder='Escribir...'
                  value={valuedifer ? valuedifer : '$' + difere}
                  disabled
                />
              </div>
              <div className=' ml-3 d-grid'>
                <label>ㅤ</label>
                <img
                  alt='GearGray'
                  src={GearGray}
                  style={{ width: '25px' }}
                  onClick={() => (difere === 0 || valuedifer === '0' ? '' : btnAjustModal())}
                ></img>
              </div>
              <Col className='d-block text-end mt-4'>
                <button
                  className={`${boxReceiptStyles.btnSecondary} mr-3`}
                  onClick={() => resetForm()}
                >
                  Cancelar
                </button>
                <button
                  className={boxReceiptStyles.btnPrimary}
                  disabled={
                    difere === 0 || !!(valuedifer === '') || !!sentInvoiceAll.observations === false
                      ? true
                      : false
                  }
                  onClick={() => sendPostCrossing()}
                >
                  Guardar
                </button>
              </Col>
            </div>
          </Row>
        </div>
      </div>
      <ModalNew
        show={showModalPinvoice}
        onHide={() => closeModalPinvoices()}
        title={'Facturas por pagar'}
        subtitle={' '}
        btnYesEvent={() => renderlisPinvoices()}
        btnYesName={'Selecionar'}
        size={'620'}
      >
        <Row className='d-flex'>
          <Col xs={6}>
            <form
              onSubmit={e => {
                e.preventDefault();
                setTrigger2(trigger2 + 1);
                setLoading(true);
              }}
            >
              <label className={tableStyles.crudModalLabel}>No. Factura</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type='text'
                placeholder='Escribir...'
                onChange={e =>
                  setSearchPinvoices({
                    ...searchPinvoices,
                    search: e.target.value,
                  })
                }
              ></input>
            </form>
          </Col>
          <Col xs={5}>
            <label className={tableStyles.crudModalLabel}>Fecha</label>
            <input
              className={IndividualStyles.registerInputsBlue}
              type='date'
              onChange={e =>
                setSearchPinvoices({
                  ...searchPinvoices,
                  date: e.target.value,
                })
              }
            ></input>
          </Col>
          <Col xs={1} className='d-grid'>
            <label className={tableStyles.crudModalLabel}>ㅤ</label>
            <i>
              <img
                alt='lupa'
                src={Lupa}
                style={{ width: '25px' }}
                onClick={() => {
                  setTrigger2(trigger2 + 1);
                  setLoading(true);
                }}
              />
            </i>
          </Col>
        </Row>
        <GenericTableScroll
          headers={headerModalPinvoice}
          dark={true}
          body={bodyModalPinvoice}
          typeHead={'2'}
        ></GenericTableScroll>
      </ModalNew>
      <ModalNew
        show={showModalAjust}
        onHide={() => setShowModalAjust(false)}
        title={'Ajustes'}
        subtitle={' '}
        btnYesEvent={() => applyAdjustment()}
        btnYesName={'Aplicar'}
        size={'450'}
      >
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Valor de facturas por pagar</label>
            <NumberFormat
              allowNegative={false}
              id='cost_price'
              className={IndividualStyles.registerInputsBlue}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalPinvoice())}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Valor de facturas por cobrar</label>
            <NumberFormat
              allowNegative={false}
              id='cost_price'
              className={IndividualStyles.registerInputsBlue}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalAbono())}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Diferencia</label>
            <NumberFormat
              allowNegative={false}
              style={{ fontWeight: 'bold' }}
              id='cost_price'
              className={IndividualStyles.registerInputsGris}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalPinvoice() - findValueTotalAbono())}
              disabled={true}
            />
          </Col>
        </Row>
        <label className={`${tableStyles.crudModalLabel}`} style={{ marginTop: '1rem' }}>
          <b style={{ fontSize: '14px', marginTop: '10px' }}>Enviar direfencia a:</b>
        </label>
        <Row className='d-flex'>
          <Col>
            <p className={tableStyles.crudModalLabel}>Cuenta contable*</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              options={NiifAccountsModal}
              // onChange={(e) => setCreate({
              //     ...getCreate,
              //     niff_accountAjusment: e.value
              // })}
            />
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        show={showModalAjust}
        onHide={() => setShowModalAjust(false)}
        title={'Ajustes'}
        subtitle={' '}
        btnYesEvent={() => applyAdjustment()}
        btnYesName={'Aplicar'}
        size={'450'}
      >
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Valor de facturas por pagar</label>
            <NumberFormat
              allowNegative={false}
              id='cost_price'
              className={IndividualStyles.registerInputsBlue}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalPinvoice())}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Valor de facturas por cobrar</label>
            <NumberFormat
              allowNegative={false}
              id='cost_price'
              className={IndividualStyles.registerInputsBlue}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalAbono())}
              disabled={true}
            />
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col>
            <label className={tableStyles.crudModalLabel}>Diferencia</label>
            <NumberFormat
              allowNegative={false}
              style={{ fontWeight: 'bold' }}
              id='cost_price'
              className={IndividualStyles.registerInputsGris}
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={numberWithCommas(findValueTotalPinvoice() - findValueTotalAbono())}
              disabled={true}
            />
          </Col>
        </Row>
        <label className={`${tableStyles.crudModalLabel}`} style={{ marginTop: '1rem' }}>
          <b style={{ fontSize: '14px', marginTop: '10px' }}>Enviar direfencia a:</b>
        </label>
        <Row className='d-flex'>
          <Col>
            <p className={tableStyles.crudModalLabel}>Cuenta contable*</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              options={NiifAccountsModal}
            />
          </Col>
        </Row>
      </ModalNew>
    </>
  );
}
export default CrossingAccounts;
