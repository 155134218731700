//costcenter' reducer

import {
  GET_CURRENCY_ALL,
  LOAD_GET_CURRENCY_ALL
} from "../actions/actionTypes";

const initialState = {
  results: [],
  total: 0,
  error: null,
  Authorization: "",
  loading: false,
};
export const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GET_CURRENCY_ALL:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_CURRENCY_ALL:
      return {
        ...state,
        results: action.results,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };

    default:
      return state;
  }
};
