import { useEffect, useState } from 'react';
import { loader, onlyCharacters } from '../../helpers/helpers';
import { useSelector } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../../src/components/Popup/customPopup.module.scss';
import threeDots from '../../assets/img/icons/threeDots.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import addIcon from '../../assets/img/icons/add-check.svg';
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';
import OrdModal from './../../OrderingModule/OrderingComponents/OrdModal';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { useHistory } from 'react-router';

export const Consents = () => {
  // --------------------------- States  -------------------------
  const store = useSelector(state => state);
  const currentAccount = store.loginReducer.currentAccount;
  const history = useHistory();
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;

  const [data, setData] = useState({
    httpData: {
      name: '',
      code: '',
      version: '',
      validity: '',
    },

    filters: {
      page: 1,
      perpage: 10,
      pageRangeDisplayed: 3,
      search: '',
      status: '',
    },
    trigger: 1,
    modal: false,
  });

  const { httpData, filters, trigger, modal } = data;

  const headers = [
    {
      title: 'Código',
      className: 'col-2 text-center px-2',
    },

    {
      title: 'Nombre Consentimiento',
      className: 'col-3 text-center px-2',
    },

    {
      title: 'Versión',
      className: 'col-2 text-center px-2',
    },
    {
      title: 'Vigencia',
      className: 'col-2 text-center px-2',
    },
    {
      title: 'Estado',
      className: 'col-2 text-center px-2',
    },
    {
      title: '',
      className: 'col-1',
    },
  ];

  const onChangeStateModal = isShow => {
    setData(state => ({ ...state, modal: isShow, httpData: { code: '', name: '', validity: '', version:'' } }));
  };

  const onchangeDataHttp = e => {
    setData(state => ({
      ...state,
      httpData: { ...state.httpData, [e.target.name]: e.target.value },
    }));
  };

  const onchangeDataHttpValidText = value => {
    const valid = onlyCharacters(value);
    if (valid) {
      setData(state => ({
        ...state,
        httpData: {
          ...state.httpData,
          name: value,
        },
      }));
    }
  };

  //  --------------------------- Petitions -------------------------

  const { load: loaderUpdateConsent, trigger: updateConsent } = usePostMethod();

  const { results: listConsent, load: loaderListConsent, trigger: getListConsent } = useGetMethod();

  const { load: loaderConsentsValidation, trigger: postConsentsValidation } = usePostMethod();

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: false,
    }));
  };

  //  --------------------------- Methods ------------------------------

  const onChangeStatusConsent = item => {
    customSwaltAlertAsistencial({
      title: `¿Está seguro?`,
      text: `Se ${item.status === 1 ? 'inhabilitará' : 'habilitará'} el consentimiento: ${item?.name}`,
      confirmButtonText: `Si, continuar`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        updateConsent({
          url: '/medical/admissions/consents/',
          token: token,
          method: 'PUT',
          body: {
            ...item,
            createdBy,
            eAccount: eaccount,
            id: item?.id,
            code: item.code,
            status: item.status === 1 ? 0 : 1,
          },
          succesAction: () => {
            closeModal();

            customSwaltAlertAsistencial({
              icon: 'success',
              title: `Actualizado exitosamente`,
              text: `Se ha ${item.status === 1 ? 'inhabilitado' : 'habilitado'} el consentimiento: ${item?.name}`,
              showCancelButton: false,
            });

            setData(state => ({
              ...state,
              trigger: state.trigger + 1,
            }));
          },
          doAfterException: res => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `${res.message}`,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  // -------------------------------- Validations -----------------------
  const validationRequest = () => {
    const isValid = Object.values(httpData).every(el => el.trim().length > 0);

    if (!isValid) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `Todos los campos son obligatorios`,
        showCancelButton: false,
      });
    }

    postConsentsValidation({
      token: token,
      method: 'POST',
      url: '/medical/admissions/consentsValidation/',
      body: {
        name: httpData?.name,
        code: httpData?.code,
        version: httpData?.version,
        validity: httpData?.validity,
      },
      doAfterSuccess: () => {
        history.push({
          state: { action: 'create', data: httpData, currentAccount },
          pathname: `consentimientos/crear`,
        });
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  //-------------- format data to table 🍕   --------------------------
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-center px-2'>{item?.code || '-'}</td>
            <td className='text-center px-2'>{item?.name || '-'}</td>
            <td className='text-center px-2'>{item?.version || '-'}</td>
            <td className='text-center px-2'>{item?.validity || '-'}</td>
            <td className={`text-center`}>
              <div
                className={
                  item?.status === 0
                    ? tableStyles.redState
                    : item?.status === 1
                      ? tableStyles.greenState
                      : tableStyles.stateExpired
                }
              >
                {item?.status === 0
                  ? 'Inhabilitado'
                  : item?.status === 1
                    ? 'Habilitado'
                    : 'Vencido'}
              </div>
            </td>
            <td className='text-end px-2'>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                position='right center'
                extraButtons={[
                  item.status === 1
                    ? {
                        text: 'Editar',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          history.push({
                            state: {
                              action: 'update',
                              data: { ...item },
                              currentAccount,
                            },
                            pathname: `consentimientos/actualizar
                                                    `,
                          });
                        },
                      }
                    : undefined,

                  item.status === 1
                    ? {
                        text: 'Duplicar',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          history.push({
                            state: {
                              action: 'duplicate',
                              data: { ...item },
                              currentAccount,
                            },
                            pathname: `consentimientos/duplicar`,
                          });
                        },
                      }
                    : undefined,

                  item.status !== 2
                    ? {
                        text: item.status === 1 ? 'Inhabilitar' : 'Habilitar',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          onChangeStatusConsent(item);
                        },
                      }
                    : undefined,

                  {
                    text: 'Ver detalle',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      history.push({
                        state: {
                          action: 'detail',
                          data: { ...item },
                          currentAccount,
                          // data: { httpData, ...item }
                        },
                        pathname: `consentimientos/detalle`,
                      });
                    },
                  },
                ]}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //-------------- useEffect 🍕   -------------------------

  useEffect(() => {
    getListConsent({
      url: '/medical/admissions/consents/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  //-------------- Component --------------------------

  return (
    <>
      {(loaderUpdateConsent || loaderConsentsValidation || loaderListConsent) && loader}

      <div className={`${tableStyles.layoutBase}`}>
        <div className='mb-4'>
          <div className={`${tableStyles.f40} ${tableStyles.ordDarkBlueText} mb-4 fw-bold`}>
            Consentimientos
          </div>

          <div className='d-flex gap-3 align-items-end'>
            <div className={`${tableStyles.w220px}`}>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>&nbsp;Estado</div>
              <Select
                className='text-secondary'
                isDisabled={modal}
                options={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Habilitados', value: '1' },
                  { label: 'Vencidos', value: '2' },
                  { label: 'Inhabilitados', value: '0' },
                ]}
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  setData(state => ({
                    ...state,
                    trigger: state.trigger + 1,
                    filters: {
                      ...state.filters,
                      status: value,
                    },
                  }));
                }}
                noOptionsMessage={() => 'No hay datos'}
              />
            </div>

            <div
              className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12} ${tableStyles.w420px} align-self-end`}
            >
              <input
                className={`${tableStyles.appInputDate} w-100`}
                style={{ color: '#6E6F7C' }}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={({ target }) => {
                  setData(state => ({
                    ...state,
                    filters: {
                      ...state.filters,
                      search: target.value,
                    },
                  }));
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                    }));
                  }
                }}
              />
              <img
                src={ordBlueSearch}
                className='pointer'
                alt='buscar'
                onClick={() => {
                  setData(state => ({
                    ...state,
                    trigger: state.trigger + 1,
                  }));
                }}
              />
            </div>

            <div
              className={`${tableStyles.ordAquaMarineTextIconHover} d-flex gap-1 ms-auto`}
              onClick={() => onChangeStateModal(true)}
            >
              <span
                className={`${tableStyles.ordDarkGrayText} ${tableStyles.hoverFilterOrdAquaMarine} fw-bold`}
              >
                Crear consentimiento
              </span>
              <img src={addIcon} alt='addIcon' />
            </div>
          </div>
        </div>

        <OrdTable
          hasChildren={true}
          headers={headers}
          shadow
          paginate={{
            activePage: filters.page,
            totalPages: listConsent?.rowTotal,
            perPage: filters.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async e => {
              setData(state => ({
                ...state,
                trigger: state.trigger + 1,
                filters: { ...state.filters, page: e },
              }));
            },
            showTextDetails: true,
          }}
        >
          {' '}
          {formatData(listConsent?.results)}
        </OrdTable>
      </div>

      <OrdModal
        title={'Encabezado consentimiento'}
        show={modal}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={550}
        onHide={() => onChangeStateModal(false)}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => onChangeStateModal(false)}
      >
        <div className={`mb-2 ${tableStyles.ordAquaMarineText} fw-bold`}>Consentimiento</div>

        <div className='mb-4'>
          <div className={`${tableStyles.darkerBlueText} fw-light`}>
            &nbsp;Nombre<span className={'text-danger'}>*</span>
          </div>
          <input
            className={`${tableStyles.styleInputText} w-100 mb-1`}
            placeholder='Escribir...'
            type='text'
            name='name'
            pattern='^[A-Za-z]+$'
            value={httpData.name}
            onChange={e => onchangeDataHttpValidText(e.target.value)}
          />

          <div className='d-flex gap-3'>
            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>
                &nbsp;Código<span className={'text-danger'}>*</span>
              </div>
              <input
                className={`${tableStyles.styleInputText} w-100 mb-1`}
                placeholder='Escribir...'
                type='text'
                name='code'
                value={httpData.code}
                onChange={(e) => {
                  if(isNaN(Number(e.target.value))) return

                  onchangeDataHttp(e)
                }}
              />
            </div>

            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>
                &nbsp;Versión<span className={'text-danger'}>*</span>
              </div>
              <input
                className={`${tableStyles.styleInputText} w-100 mb-1`}
                placeholder='Escribir...'
                type='text'
                name='version'
                value={httpData.version}
                onChange={onchangeDataHttp}
              />
            </div>

            <div>
              <div className={`${tableStyles.darkerBlueText} fw-light`}>
                &nbsp;Fecha de vigencia<span className={'text-danger'}>*</span>
              </div>
              <input
                className={`w-100 ord-roundInput pointer ${tableStyles.inputDate} ${tableStyles.boxShadowNone}`}
                type='date'
                name='validity'
                value={httpData.validity}
                onChange={onchangeDataHttp}
              />
            </div>
          </div>
        </div>
      </OrdModal>
    </>
  );
};
