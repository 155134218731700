import { useEffect, useState } from "react";
import Select from "react-select";
import { Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
//
import { OrdGenericTemplate } from "../OrderingModule/OrderingComponents/OrdGenericTemplate";
import { ordCustomSelect } from "../components/Layouts/react-select-custom";
import { customSwaltAlertAsistencial, getPermission, loader } from "../helpers";
import { useGetMethod, usePostMethod } from "../Hooks";
//
import tableStyles from "../components/Layouts/tableStyle.module.scss";

export default function TimeParam() {
    const store = useSelector((state) => state);

    const [isDisabled, setIsDisabled] = useState(true);
    const [timeAttentionConfig, setTimeAttentionConfig] = useState({
        id: null,
        valueConfig: null,
        valueUnitId: null
    });

    const listPermission = store.loginReducer.currentAccount?.profile?.permission;
    const myPermission = getPermission({ prefix: 'setgg', listPermission });

    const {
        load: timeAttentionLoader,
        results: timeAttention,
        trigger: getTimeAttention
    } = useGetMethod();
    const {
        load: timesLoader,
        results: times,
        trigger: getTimes
    } = useGetMethod();
    const {
        load: updateTimeConfigLoader,
        trigger: updateTimeConfig
    } = usePostMethod();

    useEffect(() => {
        if (timeAttentionConfig.valueConfig && timeAttentionConfig.valueUnitId) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [timeAttentionConfig.valueConfig, timeAttentionConfig.valueUnitId]);

    useEffect(() => {
        if (timeAttention?.results) {
            const configurationItem = timeAttention?.results[0];

            setTimeAttentionConfig({
                id: configurationItem.id,
                valueConfig: configurationItem.catValueConfig,
                valueUnitId: configurationItem.valueUnit.valueUnitId
            });
        }
    }, [timeAttention?.results]);

    useEffect(() => {
        async function fetchData() {
            await getTimeAttention({
                url: "/medical/clinicHistory/configAttentionTime/",
                objFilters: {},
                token: store.loginReducer.Authorization
            });
        }

        fetchData().catch(error => console.error(error));
    }, [getTimeAttention, store.loginReducer.Authorization]);

    useEffect(() => {
        async function fetchData() {
            await getTimes({
                url: "/medical/time/",
                objFilters: {},
                token: store.loginReducer.Authorization,
            });
        }
        fetchData().catch(error => console.error(error));
    }, [getTimes, store.loginReducer.Authorization]);

    const handleInputChange = (event) => {
        setTimeAttentionConfig({
            ...timeAttentionConfig,
            valueConfig: event.target.value
        });
    };

    const handleSelectChange = (selectedOption) => {
        setTimeAttentionConfig({
            ...timeAttentionConfig,
            valueUnitId: selectedOption.value
        });
    };

    const handleUpdateTimeConfig = () => {
        updateTimeConfig({
            url: "/medical/clinicHistory/configAttentionTime/",
            method: "PUT",
            token: store.loginReducer.Authorization,
            body: timeAttentionConfig,
            succesAction: (results) => {
                customSwaltAlertAsistencial({
                    icon: "success",
                    title: "Parametrizado exitosamente",
                    text: results.message,
                    confirmButtonText: "Aceptar",
                    showCancelButton: false,
                });
            },
        });
    };

    const renderTimeParam = (item) => {
        const formattedOptions = times?.results?.map((time) => ({ label: time.name, value: time.id }));
        const currentTime = times?.results?.map(time => ({ label: time.name, value: time.id }))
            .find(time => time.value === timeAttentionConfig.valueUnitId);

        return (
            <Row key={item.id}>
                <Col xs={3}><b className={`${tableStyles.tlnTextPrimary}`}>{item.catDescription}</b></Col>
                <Row>
                    <Col xs={1}>
                        <Form.Group className="mb-3 text-start" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                <b className="px-1">Cantidad</b>
                            </Form.Label>
                            <Form.Control
                                name="value_config"
                                className={`ord-roundInput pleaceholderDate`}
                                type="number"
                                defaultValue={item.catValueConfig}
                                key={"value_config"}
                                onChange={handleInputChange}
                                min={0}
                                onKeyPress={(e) => {
                                    if (e.key === '-') {
                                        e.preventDefault();
                                    }
                                }}

                            />
                        </Form.Group>
                    </Col>
                    <Col xs={2}>
                        <Form.Group className="mb-3 text-start" controlId="fName">
                            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                                <b className="px-1">Unidad</b>
                            </Form.Label>
                            <Select noOptionsMessage={() => 'No hay datos'}
                                onChange={handleSelectChange}
                                options={formattedOptions}
                                value={currentTime}
                                className="text-secondary"
                                placeholder={"Seleccione"}
                                styles={ordCustomSelect}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Row>
        );
    };

    const render = () => {
        return (
            <div className={`${tableStyles.tlnContainer} mt-4`}>
                {timeAttentionLoader && loader}
                {timesLoader && loader}
                {updateTimeConfigLoader && loader}

                <OrdGenericTemplate title="Parametrización de tiempos" titleSize={12}>
                    {timeAttention?.results?.map((item) => renderTimeParam(item))}
                    <Row className="mb-5 mt-4">
                        <Col xs={1}></Col>
                        {myPermission?.create && (
                            <Col xs={2} className="d-flex justify-content-end">
                                <button
                                    disabled={isDisabled}
                                    onClick={handleUpdateTimeConfig}
                                    className={`${tableStyles.tlnBtnDark} w-auto text-white fw-bold`}
                                    style={{ marginRight: 6 }}>
                                    Guardar
                                </button>
                            </Col>
                        )}
                    </Row>
                </OrdGenericTemplate>
            </div>
        );
    };

    return render();
}