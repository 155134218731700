/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getCandidateList } from '../../../../actions/partnersActions';
import { getAllJobPositions } from '../../../../actions/positionsActions';
import { API_VERSION, URL_GATEWAY } from '../../../../helpers/constants';
import { customSwaltAlert } from '../../../../helpers/customSwaltAlert';
import { customSelectNew } from '../../../Layouts/react-select-custom';
import tableStyles from '../../../Layouts/tableStyle.module.scss';

function OvertimeRegister(props) {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = store.loginReducer.Authorization;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'minoreg',
  );

  const [trigger, setTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const idLeader = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;

  const [formData, setFormData] = useState({
    employee: '',
    job_position: '',
    contract: null,
    area_leader: idLeader,
    start_time: null,
    end_time: null,
    description: null,
    entity_account: store.loginReducer.currentAccount.id,
    created_by: store.loginReducer.user_data?.id,
    end_hour: null,
    start_hour: null,
    request_for: 'employee',
  });

  const sendPost = async data => {
    setIsLoading(true);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/register_overtime/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          setFormData({
            employee: '',
            job_position: '',
            contract: null,
            area_leader: idLeader,
            start_time: null,
            end_time: null,
            description: null,
            entity_account: store.loginReducer.currentAccount.id,
            created_by: store.loginReducer.user_data?.id,
            end_hour: null,
            start_hour: null,
            request_for: 'employee',
          });
          setTrigger(trigger + 1);
          customSwaltAlert({
            icon: 'success',
            title: 'Datos Guardados',
            text: res.message,
            showCancelButton: false,
          });
          // history.push("/miNomina/miNomina/hojaDeVida");
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
          });
        }
      })
      .catch(() => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Error de conexion, si este error continua contacte a soporte',
          showCancelButton: false,
        });
      });
    setIsLoading(false);
  };

  //end actions

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/miNomina/miNomina/hojaDeVida');
    }

    if (props.profile === 2) {
      dispatch(
        getAllJobPositions({
          slim: 1,
          forColabs: 1,
          leader: idLeader,
        }),
      );
    } else {
      setFormData({ ...formData, area_id: 0, job_position: 0 });
    }
  }, [props.profile]);

  useEffect(() => {
    //fetchData();
    if (!!formData?.area_id && !!formData?.job_position) {
      dispatch(
        getCandidateList({
          area_id: formData.area_id,
          status: 'current',
          job_position: formData?.job_position,
        }),
      );
    }
  }, [formData?.job_position, trigger]);

  const optionBoss1 = !formData?.job_position
    ? []
    : store?.partnersReducer?.employee_list?.map(candidate => ({
        ...candidate,
        label: candidate.full_name,
        value: candidate.id_candidate,
        contract: candidate.id_contract,
      }));
  optionBoss1?.unshift({ value: '', label: 'Seleccionar...' });

  const optionJobPos1 = store?.positionReducer?.jobsPositionsList?.map(jp => ({
    ...jp,
    label: jp.job_title,
    value: jp.id,
    area_id: jp.area_id,
    contract_id: jp.contract_id,
  }));

  optionJobPos1?.unshift({ value: '', label: 'Seleccionar...' });

  const uploadInhability = () => {
    let error = '';
    let daylenght = 0;
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

    if (!formData?.employee) {
      error = 'Debe seleccionar el empleado';
    }
    if (!formData?.job_position) {
      error = 'Debe seleccionar un Cargo';
    }
    if (!formData?.area_leader) {
      error = 'Debe seleccionar un jefe de área';
    }
    if (!formData?.description) {
      error = 'Debe digitar una descripción';
    }
    if (!formData?.start_time) {
      error = 'Debe seleccionar una fecha de entrada';
    }
    if (!formData?.end_time) {
      error = 'Debe seleccionar una fecha de salida';
    }
    if (!formData?.end_hour) {
      error = 'Debe seleccionar una hora de salida';
    }
    if (!formData?.start_hour) {
      error = 'Debe seleccionar una hora de entrada';
    }
    if (formData?.start_time && formData.end_time) {
      const sDate = new Date(formData.start_time);
      const eDate = new Date(formData.end_time);
      if (eDate < sDate) {
        error = 'La fecha de salida debe ser mayor a la fecha de entrada';
      } else {
        daylenght = Math.round(Math.abs((eDate.getTime() - sDate.getTime()) / oneDay));
      }
      if (daylenght >= 2) {
        error = 'No se pueden registrar más de dos días de turno.';
      }
      if (formData.start_time > new Date().toISOString().slice(0, 10)) {
        error = 'La fecha de entrada no debe ser mayor al día de hoy';
      }
      if (formData.end_time > new Date().toISOString().slice(0, 10)) {
        error = 'La fecha de salida no debe ser mayor al día de hoy';
      }
    }
    if (error !== '') {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
        showCancelButton: false,
      });
      return null;
    }

    sendPost({
      ...formData,
      start_time: String(formData?.start_time) + ' ' + String(formData.start_hour),
      end_time: String(formData.end_time) + ' ' + String(formData.end_hour),
    });
  };
  const asterisk = <b style={{ color: '#FF8B00' }}>*</b>;
  const renderToolTipStartDate = () => (
    <Tooltip>
      <span>Fecha de inicio de actividad laboral.</span>
    </Tooltip>
  );
  const renderToolTipStartTime = () => (
    <Tooltip>
      <span>Hora de inicio de actividad laboral.</span>
    </Tooltip>
  );
  const renderToolTipEndDate = () => (
    <Tooltip>
      <span>Fecha de fin de actividad laboral.</span>
    </Tooltip>
  );
  const renderToolTipEndTime = () => (
    <Tooltip>
      <span>Hora de fin de actividad laboral.</span>
    </Tooltip>
  );
  return (
    <div className='simpleTabs__wrap'>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <h4 className='simpleTabs__thirdTitle' style={{ marginTop: 24, marginBottom: 7 }}>
        Registro de horas extra
      </h4>
      <div className={`${tableStyles.genericGrayContainer} col-6`}>
        <div className={`d-flex`}>
          <div className='d-flex flex-column  mr-2' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>Cargo del empleado{asterisk}</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionJobPos1}
              key={'JobPos' + props.profile}
              value={optionJobPos1?.find(obj => obj.value === formData?.job_position)}
              onChange={e => {
                setFormData({
                  ...formData,
                  job_position: e.value,
                  area_id: e.area_id,
                  employee: null,
                });
                setTrigger(trigger + 1);
              }}
              placeholder={'Seleccionar ...'}
              styles={customSelectNew}
            />
          </div>
          <div className='d-flex flex-column' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>Empleado{asterisk}</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionBoss1}
              key={'Boss' + trigger}
              value={optionBoss1?.find(obj => obj.value === formData?.employee)}
              onChange={e => setFormData({ ...formData, employee: e.value, contract: e.contract })}
              placeholder={'Seleccionar...'}
              styles={customSelectNew}
            />
          </div>
        </div>

        <div className={`d-flex`}>
          <div className='d-flex flex-column  mr-2' style={{ flex: 1 }}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderToolTipStartDate()}
            >
              <p className={tableStyles.crudModalLabel}>Fecha de entrada{asterisk}</p>
            </OverlayTrigger>

            <input
              max={new Date().toISOString().slice(0, 10)}
              style={{ color: '#005dbf', fontWeight: 'bold' }}
              type='date'
              key={'Sdate' + trigger}
              className='register-inputs'
              value={formData?.start_time}
              onChange={e =>
                setFormData({
                  ...formData,
                  start_time: e.target.value,
                })
              }
            />
          </div>
          <div className='d-flex flex-column' style={{ flex: 1 }}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderToolTipStartTime()}
            >
              <p className={tableStyles.crudModalLabel}>Hora de entrada{asterisk}</p>
            </OverlayTrigger>
            <input
              type='time'
              key={'Edate' + trigger}
              style={{ color: '#005dbf', fontWeight: 'bold' }}
              className='register-inputs'
              value={formData?.start_hour}
              onChange={e =>
                setFormData({
                  ...formData,
                  start_hour: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className={`d-flex`}>
          <div className='d-flex flex-column mr-2' style={{ flex: 1 }}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderToolTipEndDate()}
            >
              <p className={tableStyles.crudModalLabel}>Fecha de salida{asterisk}</p>
            </OverlayTrigger>
            <input
              max={new Date().toISOString().slice(0, 10)}
              min={formData?.start_time}
              type='date'
              key={'outDate' + trigger}
              style={{ color: '#005dbf !important', fontWeight: 'bold' }}
              className='register-inputs'
              value={formData?.end_time}
              onChange={e =>
                setFormData({
                  ...formData,
                  end_time: e.target.value,
                })
              }
            />
            <style>{`
              input[type='date']::-webkit-datetime-edit {
                color: inherit !important;
              }

              input[type='date']::-webkit-datetime-edit-day-field {
                color: inherit !important;
              }

              input[type='date']::-webkit-datetime-edit-month-field {
                color: inherit !important;
              }

              input[type='date']::-webkit-datetime-edit-year-field {
                color: inherit !important;
              }
            `}</style>
          </div>
          <div className='d-flex flex-column' style={{ flex: 1 }}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderToolTipEndTime()}
            >
              <p className={tableStyles.crudModalLabel}>Hora de salida{asterisk}</p>
            </OverlayTrigger>
            <input
              type='time'
              key={'outHour' + trigger}
              style={{ color: '#005dbf', fontWeight: 'bold' }}
              className='register-inputs'
              value={formData?.end_hour}
              onChange={e =>
                setFormData({
                  ...formData,
                  end_hour: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className='d-flex flex-column' style={{ flex: 1 }}>
          <p className={tableStyles.crudModalLabel}>Descripción{asterisk}</p>
          <textarea
            key={'Descri' + trigger}
            className='register-inputs'
            style={{ height: '107px', minHeight: '107px', maxHeight: '107px', color: '#606062' }}
            value={formData?.description}
            onChange={e =>
              setFormData({
                ...formData,
                description: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className='d-flex flex-row-reverse col-6' style={{ marginTop: 16 }}>
        <button className={tableStyles.btnPrimary} onClick={() => uploadInhability()}>
          Enviar
        </button>
      </div>
    </div>
  );
}

export default OvertimeRegister;
