import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { GET_TEMP_WORK_CERT_NOM } from '../../actions/actionTypes';
import { create_workCertificate } from '../../actions/contractAction';
import { getCandidateList } from '../../actions/partnersActions';
import { getAllJobPositions } from '../../actions/positionsActions';
import { isEmptyOrUndefined, today } from '../../helpers/helpers';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import SelectComponent from '../SelectComponent/SelectComponent';
import { useHasPermissionByTagModule } from "../../Hooks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function WorkCertificate() {
  const history = useHistory();

  function goBack() {
    history.push('/nomina/inicio');
  }

  const counter = useSelector(state => state);
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'WkCertific',
  );
  const [trigger, setTrigger] = useState(1);
  const [trigger2, setTrigger2] = useState(1);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    employee: '',
    title_to: undefined,
    job_position: undefined,
    exp_date: today(),
    addressed_to: '',
    contract: 0,
    include_salary: false,
    include_notconst: false,
    include_average: false,
    entity_account: counter?.loginReducer?.currentAccount?.id,
    created_by: counter?.loginReducer?.user_data?.id,
    NOM: true,
  });

  let fileUrl = counter.contractReducer?.tempWorkCertificatePDFNOM;

  const [btnyes, setBtnyes] = useState(true);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    dispatch(
      getCandidateList({
        status: 'current',
        filtered: 1,
        eaccount: counter?.loginReducer?.currentAccount?.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTrigger(trigger + 1);
    if (data.employee !== 0) {
      dispatch(
        getAllJobPositions({
          slim: 1,
          active: 1,
          id_candidate: data.employee,
          onlyCurrent: 'current',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.employee]);
  useEffect(() => {
    if (!data.employee || !data.exp_date || !data.job_position || !data.addressed_to) {
      setBtnyes(true);
    } else {
      setBtnyes(false);
    }
  }, [data]);
  const optionCandidates = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.partnersReducer.employee_list)) {
    counter.partnersReducer.employee_list.forEach(item => {
      optionCandidates.push({
        label: item.full_name,
        value: item.id_candidate,
        name: 'candidates',
      });
    });
  }
  const optionPositions = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter?.positionReducer?.jobsPositionsList)) {
    counter?.positionReducer?.jobsPositionsList.forEach(item => {
      optionPositions.push({
        label: item.job_title,
        value: item.id,
        contract: item.contract_id,
        name: 'job_position',
      });
    });
  }

  const titleOptions = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Sin título', value: 'Sin título' },
    { label: 'Señor', value: 'Señor' },
    { label: 'Señora', value: 'Señora' },
    { label: 'Señores', value: 'Señores' },
  ];

  const generateWorkCertificate = async () => {
    await dispatch(create_workCertificate(data));
    setData({
      employee: 0,
      title_to: '',
      job_position: 0,
      exp_date: today(),
      addressed_to: '',
      contract: 0,
      include_salary: false,
      include_notconst: false,
      include_average: false,
      entity_account: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
      NOM: true,
    });
    setTrigger(trigger + 1);
    setTrigger2(trigger2 + 1);
  };

  return (
    <>
      {counter.contractReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={5} className='mr-2'>
              <h1 className={tableStyles.title} style={{ textWrap: 'balance' }}>
                Generar carta laboral
              </h1>
              <div
                style={{
                  backgroundColor: 'rgba(0, 93, 191, 0.05)',
                  borderRadius: '10px',
                  marginTop: '10px',
                  padding: '10px',
                }}
              >
                <Row className='d-flex'>
                  <Col xs={7}>
                    <div className='display-grid mt-1'>
                      <label className={tableStyles.crudModalLabel}>Colaborador</label>
                      <SelectComponent
                        styles={customSelectNewDark}
                        placeholder={'Seleccionar...'}
                        key={'colaborador' + trigger2}
                        onChange={e =>
                          setData({
                            ...data,
                            nameCandidate: e.label,
                            employee: e.value,
                            job_position: 0,
                          })
                        }
                        options={optionCandidates}
                        value={optionCandidates.find(x => x.value === data.employee)}
                      ></SelectComponent>
                    </div>
                  </Col>
                  <Col xs={5}>
                    <div className='display-grid mt-1'>
                      <label className={tableStyles.crudModalLabel}>Fecha</label>
                      <input
                        className={`register-inputs`}
                        type='date'
                        value={data.exp_date}
                        onChange={e =>
                          setData({
                            ...data,
                            exp_date: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                  </Col>
                </Row>

                <Row className='d-flex'>
                  <Col xs={12}>
                    <div className='display-grid mt-1'>
                      <label className={tableStyles.crudModalLabel}>Nombre del cargo</label>
                      <SelectComponent
                        styles={customSelectNewDark}
                        placeholder={'Seleccionar...'}
                        isDisabled={data.employee === ''}
                        key={'cargo' + trigger}
                        onChange={e =>
                          setData({
                            ...data,
                            job_position: e.value,
                            contract: e.contract,
                          })
                        }
                        options={optionPositions}
                        value={optionPositions.find(x => x.value === data.job_position)}
                      ></SelectComponent>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <div className='display-grid mt-1'>
                      <label className={tableStyles.crudModalLabel}>Título</label>
                      <SelectComponent
                        styles={customSelectNewDark}
                        placeholder={'Seleccionar...'}
                        key={'cargo' + trigger}
                        onChange={e => {
                          const valueAddress = e.value === 'Sin título' ? 'A quien interese' : '';
                          setData({
                            ...data,
                            title_to: e.value,
                            addressed_to: valueAddress,
                          });
                        }}
                        options={titleOptions}
                        value={titleOptions.find(x => x.value === data.title_to)}
                      ></SelectComponent>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className='display-grid mt-1'>
                      <label className={tableStyles.crudModalLabel}>Nombre</label>
                      <input
                        className={`register-inputs`}
                        value={data.addressed_to}
                        onChange={e =>
                          setData({
                            ...data,
                            addressed_to: e.target.value,
                          })
                        }
                        placeholder='Escribir...'
                      ></input>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className='pt-1'>
                    <label
                      className={tableStyles.crudModalLabel}
                      style={{
                        fontWeight: 'bold',
                        fontSize: '15px',
                      }}
                    >
                      Añadir información
                    </label>
                    <Col
                      className={'d-flex'}
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <input
                          className='border border-dark-blue form-check-input p1 check-dark-blue'
                          type='checkbox'
                          onChange={e =>
                            setData({
                              ...data,
                              include_salary: e.target.checked,
                            })
                          }
                          checked={data.include_salary}
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: '#58595b', marginLeft: '5px' }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Salario
                        </p>
                      </div>
                    </Col>
                    <Col
                      className={'d-flex'}
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <input
                          className='border border-dark-blue form-check-input p1 check-dark-blue'
                          type='checkbox'
                          checked={data.include_average}
                          onChange={
                            e =>
                              setData({
                                ...data,
                                include_average: e.target.checked,
                              })
                            // setInputs({ ...inputs, extraHour: e.target.checked })
                          }
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: '#58595b', marginLeft: '5px' }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Promedio salarial
                        </p>
                      </div>
                    </Col>
                    <Col
                      className={'d-flex'}
                      style={{
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <input
                          className='border border-dark-blue form-check-input p1 check-dark-blue'
                          type='checkbox'
                          checked={data.include_notconst}
                          onChange={e =>
                            setData({
                              ...data,
                              include_notconst: e.target.checked,
                            })
                          }
                        ></input>
                      </div>
                      <div>
                        <p
                          style={{ color: '#58595b', marginLeft: '5px' }}
                          className={`${tableStyles.crudModalLabel}`}
                        >
                          Ingreso no constitutivo
                        </p>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row
                  className={'pt-2 col-12'}
                  style={{
                    justifyContent: 'end',
                  }}
                >
                  <button
                    className={`col-1 ${tableStyles.btnSecondary}`}
                    style={{ justifyContent: 'center', display: 'flex' }}
                    onClick={() => goBack()}
                  >
                    Cancelar
                  </button>
                  {!!myPermission?.create && (
                    <button
                      style={{ marginLeft: '10px', cursor: btnyes ? 'not-allowed' : 'pointer' }}
                      className={`col-1 ${tableStyles.btnPrimary}`}
                      disabled={btnyes}
                      onClick={() => generateWorkCertificate()}
                    >
                      Generar
                    </button>
                  )}
                </Row>
              </div>
            </Col>

            <Col xs={6} className={`mb-4`} style={{ marginLeft: '2rem' }}>
              {!isEmptyOrUndefined(fileUrl) && (
                <>
                  <Row className={'pt-2 d-flex justify-content-end'}>
                    <Col xs={4}>
                      <Button
                        variant='outline-primary'
                        onClick={() =>
                          dispatch({
                            type: GET_TEMP_WORK_CERT_NOM,
                            payload: '',
                          })
                        }
                        className={tableStyles.btnSecondary}
                      >
                        Limpiar
                      </Button>
                    </Col>
                  </Row>
                  {fileUrl ? (
                    <iframe
                      src={fileUrl}
                      title='document'
                      style={{ width: '100%', height: '500px' }}
                      frameBorder='0'
                      className='pb-4'
                    />
                  ) : null}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default WorkCertificate;
