export const onValidatePermissionByTagModule = ({allCategories, module}) => {
  if (module === undefined || typeof module !== 'string') return false;

  let haspermission = false;
  if (module === 'nomina') {
      const pathname = decodeURIComponent(window.location.pathname);
      if (!pathname.includes('inicio')) {
          allCategories?.forEach(category => {
          category?.modules?.forEach(module => {
            module?.functionalities?.forEach(functionalitie => {
              if (functionalitie.url === pathname) {
                haspermission = true;
              }
            });
          });
        });
    } else {
      haspermission = true;
    }

    return haspermission;
  }

  return haspermission;
};
