import moment from 'moment';

//icons
import search from '../../../assets/img/icons/searchCalendar.svg';
import down from '../../../assets/img/icons/downArrowIcon.svg';
import arrowDerechaCalendario from '../../../assets/img/icons/arrowDerechaCalendario.svg';
import arrowIzquierdaCalendario from '../../../assets/img/icons/arrowIzquierdaCalendario.svg';
import userImg from '../../../assets/img/header/user-img.png';

//components
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'sassy-datepicker';
import Carousel, { consts } from 'react-elastic-carousel';

//styles
import styles from '../../../TelemedicineModule/VirtualDating/cardsStyles.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import stylesApp from '../../../AppointmentModule/RegisterAppointment/DetailPatient/styles.module.scss';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';

//hooks
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useGetMethod, usePostMethod } from '../../../Hooks';

//helpers
import {
  customSwaltAlertAsistencial,
  formatToRcSelect,
  loader,
  message,
  validateEmptyString,
} from '../../../helpers';

export const RescheduleAppointment = ({
  token,
  userId,
  idAccount,
  showModal,
  onCloseModal,
  appointment,
}) => {
  const history = useHistory();
  const appointmentDate = moment(appointment?.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
  const [, setDataDateRes] = useState('');
  const [ticketValue, setTicketValue] = useState('');
  const [triggerPicker, setTriggerPicker] = useState(0);
  const [scheduleDoctorTime, setScheduleDoctorTime] = useState('');
  const [dateInfo, setDateInfo] = useState({ date: '' });
  const [locationOptions, setLocationOptions] = useState([{ value: '', label: 'Seleccionar...' }]);
  const [siteOptions, setSiteOptions] = useState([{ value: '', label: 'Seleccionar...' }]);

  const [dataProgramming, setDataProgramming] = useState({
    oldDate: '',
    idService: '',
    servicesName: '',
    location: '',
    doctor: '',
    site: '',
    date: new Date(),
    idDoctor: '',
    insurance: '',
    selectedInsurance: {
      company: '',
      companyId: '',
      contractCorporateClient: '',
      idContractCorporateClient: '',
      popName: '',
      crtName: '',
      idPopulation: '',
      attGroupId: '',
      contractId: '',
    },
    showInsurances: false,
    showSchedule: false,
    idDoctorSch: '',
    idPatient: '',
  });

  const { load: editAppointmentLoader, trigger: editAppointmentMethod } = usePostMethod();

  const {
    results: doctorOptionsResults,
    load: doctorsLoader,
    trigger: getDoctorOptions,
  } = useGetMethod();

  const { load: locationLoader, trigger: getLocationOptions } = useGetMethod();

  const { load: loadAppointmentHistoryPatient } = useGetMethod();

  const { load: pdfMethodLoader } = useGetMethod();

  const {
    results: scheduleDoctor,
    load: scheduleDoctorLoader,
    trigger: getScheduleDoctor,
  } = useGetMethod();

  const { load: postTicketLoader, trigger: postTicket } = usePostMethod();

  const { trigger: getListApp } = useGetMethod();

  const { load: ticketLoader, trigger: getTicket } = useGetMethod();

  const { loader: detailLoaderPatient, trigger: getDetailPatient } = useGetMethod();

  const [doctorOptions, setDoctorOptions] = useState(
    formatToRcSelect(doctorOptionsResults?.results, 'mdId', 'mdName', '', '', ''),
  );

  const renderTooltipHourIcon = e => <Tooltip>{e}</Tooltip>;

  const renderTooltipSchedule = e => (
    <Tooltip>
      <div style={{ color: '#6e6f7c', fontSize: '12px', fontWeight: 'bold' }}>{e}</div>
    </Tooltip>
  );

  const editAppointment = () => {
    let appDateTime = `${moment(dateInfo.date).format('YYYY-MM-DD')} ${dataProgramming.hourStart}`;
    let appEndDatetime = `${moment(dateInfo.date).format('YYYY-MM-DD')} ${dataProgramming.hourEnd}`;

    let validations = validateEmptyString([
      { field: dateInfo.date, message: 'La fecha es obligatoria' },
      { field: dataProgramming.hourStart, message: 'La hora de la cita es obligatoria' },
      { field: dataProgramming.idDoctorSch, message: 'El doctor es obligatorio' },
    ]);

    if (validations.valid) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `<span>Se reprogramará el servicio ${dataProgramming?.serviceName}</span>
        <span class='d-block'>para la fecha: <b>${moment(dateInfo.date).format('YYYY-MM-DD')}</b></span>`,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          editAppointmentMethod({
            url: '/medical/appointment/',
            method: 'PUT',
            body: {
              id: dataProgramming.idApp,
              status: 'reprogrammed',
              idPatient: dataProgramming.idPatient,
              appDatetime: appDateTime,
              appEndDatetime: appEndDatetime,
              modality: dataProgramming.modality,
              popId: dataProgramming.selectedInsurance.idPopulation,
              contractId: dataProgramming.selectedInsurance.idContractCorporateClient,
              category: dataProgramming.selectedInsurance.crtName,
              eaccount: idAccount,
            },
            token: token,
            succesAction: info => {
              postTicketReprogramming(info.results);
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Reprogramado exitosamente',
                text: `Se ha reprogramado el servicio: ${dataProgramming?.serviceName}
                <span class='d-block'>para la fecha: <b>${moment(dateInfo.date).format('YYYY-MM-DD')}</b></span>`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              }).then(() => {
                closeProgrammingModal(info);
                history.replace('/admision/admitirPacientes');
              });
            },

            doAfterException: error => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error?.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  const closeProgrammingModal = () => {
    setTicketValue('');
    setDataProgramming({
      oldDate: '',
      idService: '',
      servicesName: '',
      location: '',
      doctor: '',
      site: '',
      date: '',
      idDoctor: '',
      insurance: '',
      selectedInsurance: {
        company: '',
        companyId: '',
        contractCorporateClient: '',
        idContractCorporateClient: '',
        popName: '',
        crtName: '',
        idPopulation: '',
        attGroupId: '',
        contractId: '',
      },
      showInsurances: false,
      showSchedule: false,
      idDoctorContract: '',
      idDoctorSch: '',
      hourStart: '',
      hourEnd: '',
      appointment: '',
    });
    onCloseModal();
    setDateInfo({ date: '' });
  };

  const showMoreInsurances = () => {
    setDataProgramming({ ...dataProgramming, showInsurances: !dataProgramming.showInsurances });
  };

  const changeInsuranceSelected = e => {
    setDataProgramming({
      ...dataProgramming,
      selectedInsurance: {
        company: e.companyTypeName,
        companyId: e.companyTypeId,
        contractCorporateClient: e.contractDescription,
        idContractCorporateClient: e.corporateClientId,
        contractId: e.contractId,
        popName: e.population,
        crtName: e.crtName,
        idPopulation: e.populationId,
        attGroupId: e.attGroupId,
        idCorporateClient: e.corporateClientId,
      },
      showInsurances: false,
    });
  };

  const getDoctorReprogramming = () => {
    getDoctorOptions({
      url: '/medical/appointment/medicalContract/',
      objFilters: {
        idAccount,
        srvId: appointment?.service?.id,
        city: dataProgramming?.location,
        popId: dataProgramming.selectedInsurance.idPopulation,
        modality: dataProgramming.modality,
      },
      token: token,
      doAfterSuccess: data => {
        setDoctorOptions(formatToRcSelect(data?.results, 'mdId', 'mdName', '', '', ''));
      },
      doAfterException: () => {
        setDataProgramming(state => ({ ...state, showSchedule: false, idDoctorSch: '' }));
        setDoctorOptions([]);
      },
    });
  };

  const postTicketSearchSchedule = () => {
    postTicket({
      url: '/medical/appointment/saveStepProcess/',
      method: 'POST',
      body: {
        consecutiveTicket: ticketValue,
        userId: userId,
        aleEvent: 'agenda_availability',
        appId: [],
      },
      token: token,
      noAlert: true,
    });
  };

  const searchDoctorSchedule = () => {
    let validations = validateEmptyString([
      {
        field: appointment?.service?.id,
        message: "El campo 'Servicio' es obligatorio para la búsqueda",
      },
      {
        field: dataProgramming.location,
        message: "El campo 'Ubicación' es obligatorio para la búsqueda",
      },
    ]);

    if (validations.valid) {
      getScheduleDoctor({
        url: '/medical/appointment/availability/',
        token: token,
        objFilters: {
          doctorId: dataProgramming?.doctor,
          siteId: dataProgramming?.site,
          attentionGroup: dataProgramming?.selectedInsurance?.attGroupId,
          eaccount: idAccount,
          idService: dataProgramming.idService,
          city: dataProgramming.location,
          idContractcorporateClient: dataProgramming?.selectedInsurance.idContractCorporateClient,
          idCorporateClient: dataProgramming?.selectedInsurance?.idCorporateClient,
          idPopulation: dataProgramming?.selectedInsurance.idPopulation,
        },
        doAfterSuccess: data => {
          postTicketSearchSchedule();
          setDataDateRes(data.date);
          setDataProgramming({ ...dataProgramming, showSchedule: true });
          let dateV;
          dateV = data.date?.split('/');
          dateV = `${dateV[2]}-${dateV[1]}-${dateV[0]}`;
          setDateInfo({ date: new Date(dateV) });
          setTriggerPicker(triggerPicker + 1);
          setScheduleDoctorTime();
        },
        doAfterException: data => {
          message('info', data.message);
        },
      });
    }
  };

  const changeDatePicker = newDate => {
    setDateInfo({ date: newDate });

    getScheduleDoctor({
      url: '/medical/appointment/availability/',
      objFilters: {
        doctorId: dataProgramming?.doctor,
        siteId: dataProgramming?.site,
        attentionGroup: dataProgramming?.selectedInsurance?.attGroupId,
        eaccount: idAccount,
        idService: dataProgramming.idService,
        city: dataProgramming.location,
        idContractcorporateClient: dataProgramming?.selectedInsurance.idContractCorporateClient,
        idCorporateClient: dataProgramming?.selectedInsurance?.idCorporateClient,
        idPopulation: dataProgramming?.selectedInsurance.idPopulation,
        dateSearch: moment(newDate).format('YYYY-MM-DD'),
      },
      token: token,
      doAfterSuccess: () => {
        // ANCHOR POST TICKET SCHEDULE
        postTicketSearchSchedule();
        setDataProgramming({ ...dataProgramming, showSchedule: true, idDoctorSch: '' });
        // dataProgramming.idDoctorSch
      },
    });
  };

  const openProgrammingModal = ({ srvId, modality }) => {
    // GET TICKET
    getTicket({
      url: '/medical/appointment/concecutive/',
      objFilters: {
        idConsec: 24,
      },
      token: token,
      doAfterSuccess: res => {
        setTicketValue(res.results);
      },
    });
    // -------------

    getLocationOptions({
      url: '/medical/appointment/locationService/',
      objFilters: { idAccount, srvId, modality },
      token: token,
      doAfterSuccess: res => {
        setLocationOptions(formatToRcSelect(res.results, 'id', 'name', 'sites'));
      },
    });
  };

  const postTicketReprogramming = appId => {
    let arrTemp = [];
    arrTemp.push(appId);
    postTicket({
      url: '/medical/appointment/saveStepProcess/',
      method: 'POST',
      body: {
        consecutiveTicket: ticketValue,
        userId: userId,
        aleEvent: 'reschedule_appointment',
        appId: arrTemp,
      },
      token: token,
      noAlert: true,
      // doAfterSuccess: (info) => {
      // },
      // doAfterException: (error) => {
      //   message("error", "Error", error.message);
      // },
    });
  };

  const renderArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowIzquierdaCalendario} alt='arrow' width={10} />
      ) : (
        <img src={arrowDerechaCalendario} alt='arrow' width={10} />
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  useEffect(() => {
    getListApp({
      url: '/medical/generals/appointments/',
      objFilters: { appId: appointment?.id, eaccount: idAccount },
      token: token,
      doAfterSuccess: res => {
        const e = res?.results[0];

        getDetailPatient({
          url: '/medical/patient/',
          objFilters: { id: e?.idPatient, eaccount: idAccount },
          token: token,
          doAfterSuccess: res => {
            setDataProgramming({
              ...dataProgramming,
              modality: e?.modality,
              oldDate: e?.dateApp,
              idService: e?.idService,
              serviceName: e?.serviceName,
              insurance: res?.results?.contracts,
              idApp: e?.idApp,
              selectedInsurance: {
                company: e?.company,
                companyId: e?.companyId,
                contractCorporateClient: e?.contractCorporateClient,
                idContractCorporateClient: e?.idContractCorporateClient,
                idPopulation: e?.idPopulation,
                popName: e?.population,
                crtName: e?.crtName,
                attGroupId: e?.attGroupId,
                idCorporateClient: e?.companyId,
              },
              idPatient: e?.idPatient,
            });
          },
        });

        openProgrammingModal({
          srvId: e?.idService,
          modality: e?.modality,
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.service?.id]);

  return (
    <>
      {(editAppointmentLoader ||
        doctorsLoader ||
        locationLoader ||
        scheduleDoctorLoader ||
        loadAppointmentHistoryPatient ||
        pdfMethodLoader ||
        ticketLoader ||
        detailLoaderPatient ||
        postTicketLoader) &&
        loader}

      <OrdModal
        show={showModal}
        title='Reprogramar cita'
        size={940}
        btnYesName={'Aceptar'}
        hideBtnYes={!dataProgramming?.showSchedule}
        btnYesEvent={() => editAppointment()}
        btnNoEvent={() => closeProgrammingModal()}
        onHide={() => {
          closeProgrammingModal();
        }}
      >
        <div className={`${tableStyles.app_back_search} d-flex`}>
          <Row className={``}>
            <Col xs={11}>
              <Row className={`d-flex`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fService'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <b> &nbsp;Aseguradora </b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={appointment?.service?.corporateClient || '-'}
                      // onChange={(e) => {
                      // }}
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={7} className="d-flex"> */}
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Contrato</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={appointment?.service?.corporateClient || '-'}
                      // onChange={(e) => {
                      // }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Población</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={dataProgramming?.selectedInsurance?.popName}
                      // onChange={(e) => {
                      // }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp; Grupo de ingreso</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={appointment?.service?.incomeGroup || '-'}
                      // onChange={(e) => {
                      // }}
                    />
                  </Form.Group>
                </Col>
                {/* </Col> */}
              </Row>
            </Col>

            <Col
              xs={1}
              className='d-flex align-self-center justify-content-center'
              style={{ marginTop: '1rem' }}
            >
              <button onClick={() => showMoreInsurances()} className='d-flex align-self-center'>
                <img src={down} alt='agregar archivo' width='30' />
              </button>
            </Col>
          </Row>
        </div>
        {dataProgramming.showInsurances &&
          dataProgramming.insurance.length > 0 &&
          dataProgramming.insurance.map((e, key) => {
            return (
              <div
                key={key}
                className={`${tableStyles.app_back_search} cursorPointer d-flex`}
                onClick={() => {
                  changeInsuranceSelected(e);
                }}
              >
                <Row className={``}>
                  <Col xs={12}>
                    <Row className={`d-flex`}>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fService'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <b> &nbsp;Aseguradora</b>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.companyTypeName} `}
                            // onChange={(e) => {
                            // }}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col xs={7} className="d-flex"> */}
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp;Contrato</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.contractDescription} `}
                            // onChange={(e) => {
                            // }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fSite'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp;Población</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.population} `}
                            // onChange={(e) => {
                            // }}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fSite'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp; Grupo de ingreso</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.crtName} `}
                            // onChange={(e) => {
                            // }}
                          />
                        </Form.Group>
                      </Col>
                      {/* </Col> */}
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        <div className={`${tableStyles.app_back_search} d-flex`}>
          <Row>
            <Col xs={11}>
              <Row className={` d-flex`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fService'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Servicio</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${appointment?.service?.id} - ${appointment?.service?.name}`}
                      // onChange={(e) => {
                      // }}
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={7} className="d-flex"> */}
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Ubicación</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={locationOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={locationOptions.find(x => x.value === dataProgramming.location)}
                      onChange={e => {
                        if (e.value === '') {
                          setDoctorOptions([]);
                          setDateInfo({ date: '' });
                          setDataProgramming(state => ({
                            ...state,
                            doctor: '',
                            showSchedule: false,
                            location: e.value,
                            site: '',
                          }));
                        } else {
                          setDataProgramming({ ...dataProgramming, location: e.value, site: '' });
                          setSiteOptions(formatToRcSelect(e.extra, 'id', 'siteName', ''));
                        }
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Sede</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={siteOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={siteOptions.find(x => x.value === dataProgramming.site)}
                      onChange={e => {
                        if (e.value) {
                          getDoctorReprogramming(e.value);
                        }
                        setDataProgramming({
                          ...dataProgramming,
                          site: e.value,
                          srvId: '',
                          showSchedule: false,
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp; Médico</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      value={doctorOptions.find(x => x.value === dataProgramming.doctor) || ''}
                      onChange={e => {
                        setDataProgramming({
                          ...dataProgramming,
                          doctor: e.value,
                          showSchedule: false,
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                {/* </Col> */}
              </Row>
            </Col>
            <Col
              xs={1}
              className='d-flex align-self-center justify-content-center'
              style={{ marginTop: '1rem' }}
            >
              <button onClick={() => searchDoctorSchedule()} className='d-flex align-self-center'>
                <img src={search} alt='searchIcon' width='30' />
              </button>
            </Col>
          </Row>
        </div>
        <Row className='mb-2'>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <span>Fecha actual</span>
              </Form.Label>
              <Form.Control
                value={appointmentDate}
                className={`ord-roundInput`}
                type='date'
                readOnly
              />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <span> Nueva fecha</span>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type='date'
                placeholder=''
                value={moment(dateInfo.date).format('YYYY-MM-DD')}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        {dataProgramming.showSchedule && (
          <Row>
            <Col xs={4}>
              <DatePicker
                minDate={new Date(moment(new Date()).format('YYYY-MM-DD'))}
                onChange={newDate => {
                  changeDatePicker(newDate);
                }}
                key={`datepicker + ${triggerPicker}`}
                selected={dateInfo?.date ? dateInfo.date : new Date()}
                style={{ border: '1px solid #00B4CC' }}
              />
            </Col>

            {scheduleDoctor?.results?.length > 0 ? (
              <>
                {dataProgramming.idDoctorSch !== '' ? (
                  <Col xs={4} className={` `}>
                    {dataProgramming.idDoctorSch !== '' && (
                      <div>
                        {scheduleDoctorTime?.map((sch, index) => {
                          let hourSch = sch?.time?.split(':')[0];
                          return (
                            <div key={index} className={`${stylesApp.app_container_map} d-flex`}>
                              <div className={`${stylesApp.line} align-self-center`}>
                                <div className={stylesApp.app_container_left_hours}>
                                  <span className={stylesApp.app_title}>{hourSch}</span>
                                </div>
                              </div>
                              <Carousel
                                itemsToShow={3}
                                itemsToScroll={3}
                                showArrows={true}
                                pagination={false}
                                renderArrow={renderArrow}
                              >
                                {sch.hours.map((hour, index) => {
                                  let hourCard = `${hour?.hourStart.slice(0, hour?.hourStart.length - 3)}`;
                                  return (
                                    <OverlayTrigger
                                      key={index}
                                      placement='top'
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipHourIcon(hour.nameSite)}
                                    >
                                      <div key={index} className={stylesApp.app_container_map}>
                                        <div
                                          className={
                                            hour.appointment
                                              ? stylesApp.app_container_hours_inactive
                                              : hour.hourStart === dataProgramming.hourStart
                                                ? stylesApp.app_container_hours_active
                                                : stylesApp.app_container_hours
                                          }
                                          onClick={() => {
                                            if (!hour.appointment) {
                                              if (dataProgramming.hourStart === hour.hourStart) {
                                                setDataProgramming({
                                                  ...dataProgramming,
                                                  idSchedule: '',
                                                  hourStart: '',
                                                  hourEnd: '',
                                                  appointment: '',
                                                  idTime: '',
                                                });
                                              } else {
                                                setDataProgramming({
                                                  ...dataProgramming,
                                                  idSchedule: hour.idSchedule,
                                                  hourStart: hour.hourStart,
                                                  hourEnd: hour.hourEnd,
                                                  appointment: hour.appointment,
                                                  idTime: '',
                                                });
                                              }
                                            }
                                          }}
                                        >
                                          <span
                                            style={{
                                              color:
                                                hour.hourStart === dataProgramming.hourStart
                                                  ? '#FFFFFF'
                                                  : '#6e6f7c',
                                            }}
                                            className={stylesApp.hours}
                                          >
                                            {hourCard}
                                          </span>
                                        </div>
                                      </div>
                                    </OverlayTrigger>
                                  );
                                })}
                              </Carousel>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Col>
                ) : (
                  <Col
                    xs={4}
                    className='d-flex justify-content-center justify-align-center align-middle align-self-center'
                  >
                    <div className='d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center'>
                      <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
                        Seleccione especialista
                      </span>
                    </div>
                  </Col>
                )}
                <Col xs={4}>
                  {scheduleDoctor?.results?.length > 0 &&
                    scheduleDoctor?.results?.map((item, index) => {
                      return (
                        <div
                          key={index + item.idDoctor}
                          className={
                            item.idSchedule === dataProgramming.idSchedule
                              ? `${styles.app_card_doctor_active} ${item.image === null ? tableStyles.filterWhiteImg : ''}`
                              : styles.app_card_doctor
                          }
                          style={{ flexDirection: 'column', color: '#6e6f7c' }}
                          onClick={() => {
                            if (dataProgramming.idSchedule === item.idDoctor) {
                              setDataProgramming({ ...dataProgramming, idSchedule: '' });
                            } else {
                              setDataProgramming({
                                ...dataProgramming,
                                idDoctorSch: item.idDoctor,
                                idDoctorContract: item.idDoctorContract,
                                idSchedule: item?.idSchedule,
                              });
                              setScheduleDoctorTime(item.schedule);
                            }
                          }}
                        >
                          <div className='d-flex gap-2'>
                            <img
                              className={`${styles.app_container_img}`}
                              src={item.image || userImg}
                              alt='doctor-img'
                              style={item?.image ? { filter: 'none' } : {}}
                            />

                            <div className={styles.container_name_doctor}>
                              <span
                                className={styles.app_title_card}
                                style={{
                                  color:
                                    item.idSchedule === dataProgramming.idSchedule
                                      ? '#FFFFFF'
                                      : '#6e6f7c',
                                }}
                              >
                                {item.doctor}
                              </span>
                            </div>
                          </div>

                          <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 250 }}
                            overlay={renderTooltipSchedule(item?.scheduleName || '-')}
                          >
                            <div
                              className='d-flex gap-2 align-items-center'
                              style={{
                                color:
                                  item.idSchedule === dataProgramming.idSchedule
                                    ? '#FFFFFF'
                                    : '#6e6f7c',
                              }}
                            >
                              <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Agenda:</div>
                              <div
                                className={tableStyles.elipsis}
                                style={{
                                  fontSize: '10px',
                                }}
                              >
                                {item?.scheduleName || '-'}
                              </div>
                            </div>
                          </OverlayTrigger>
                        </div>
                      );
                    })}
                </Col>
              </>
            ) : (
              <Col
                xs={8}
                className='d-flex justify-content-center justify-align-center align-middle align-self-center'
              >
                <div className='d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center'>
                  <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
                    No hay agendas disponibles
                  </span>
                </div>
              </Col>
            )}
          </Row>
        )}
      </OrdModal>
    </>
  );
};
