import { GET_SERVICES, GET_SPECIALTIES, GET_SYMPTON,GET_DOCTORS,GET_COMPANY,GET_STATE_APPOINTMENTS,GET_APPOINTMENTS } from "../actions/actionTypes";

const initialState = {
    listSympton: [],
    listSpecialties: [],
    listServices: [],
    listAppointments : [],
    listStateAppointments : [],
    listDoctors : [],
    listCompany: []

};
export const telemedicineReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SYMPTON:
            return {
                ...state,
                listSympton: action.payload
            };
        case GET_SPECIALTIES:
            return {
                ...state,
                listSpecialties: action.payload
            };
        case GET_SERVICES:
            return {
                ...state,
                listServices: action.payload
            };
        case GET_DOCTORS:
            return {
                ...state,
                listDoctors: action.payload
            };
        case GET_COMPANY:
            return {
                ...state,
                listCompany: action.payload
            };
        case GET_STATE_APPOINTMENTS:
            return {
                ...state,
                listStateAppointments: action.payload
            };
        case GET_APPOINTMENTS:
            return {
                ...state,
                listAppointments: action.payload
            };
        default:
            return state;
    }
};