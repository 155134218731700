import {
  GET_BANK_NOTES,
  GET_DOCUMENTS,
  GET_EGRESS,
  GET_RECEIPTBOX,
  GET_RECONSIGNMENTS,
  GET_PAYMENT_TYPES,
} from "../actions/actionTypes";

const initialState = {
  bankNote: [],
  totalBankNotes: 0,
  egress: [],
  totalEgress: 0,
  documents: [],
  totalDocuments: 0,
  receiptBox: [],
  totalReceiptBox: 0,
  reconsignments: [],
  totalReconsignments: 0,
  payment_types: [],
  documents_loading: false,
  egress_loading : false,
  bankNote_loading : false,
  reconsignments_loading: false,
  receiptBox_loading: false
};
export const consultReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_NOTES:
      return {
        ...state,
        bankNote: action.payload,
        totalBankNotes: action.total,
        bankNote_loading: action.loading
      };
    case GET_EGRESS:
      return {
        ...state,
        egress: action.payload,
        totalEgress: action.total,
        egress_loading: action.loading
      };
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        totalDocuments: action.total,
        documents_loading: action.loading
      };
    case GET_RECEIPTBOX:
      return {
        ...state,
        receiptBox: action.payload,
        totalReceiptBox: action.total,
        receiptBox_loading: action.loading
      };
    case GET_RECONSIGNMENTS:
      return {
        ...state,
        reconsignments: action.payload,
        totalReconsignments: action.total,
        reconsignments_loading : action.loading
      };
    case GET_PAYMENT_TYPES:
      return {
        ...state,
        payment_types:action.payload,
      }
    default:
      return state;
  }
};
