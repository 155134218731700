import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";
import { message } from "../helpers/helpers";
import {
  GET_BILLS_AND_VALUES,
  GET_NOTE_TYPES_LIST,
  GET_PROVIDERS_LIST,
  GET_PROVIDERS_NOTES,
  GET_RECORD_NOTES_LIST,
} from "./actionTypes";

export const getProvidersNotes = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_PROVIDERS_NOTES,
    loading: true,
  });

  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/?${filters}`,

    // ${id}
    {
      //change url
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, row_cont } = res;
      dispatch({
        type: GET_PROVIDERS_NOTES,
        payload: results,
        row_total: row_cont,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROVIDERS_NOTES,
        payload: [],
        row_total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getProvidersList = (objFilters) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/provider?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res;
      dispatch({
        type: GET_PROVIDERS_LIST,
        payload: results,
        row_total: row_total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};
export const listBIllsAndVal = (objFilters) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/inventory/provider/bills/?${filters}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res;
      dispatch({
        type: GET_BILLS_AND_VALUES,
        payload: results,
        row_total: row_total,
        // row_total_query_notes: row_total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};
export const getNoteTypeList = (objFilters) => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/noteTypes/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res;
      dispatch({
        type: GET_NOTE_TYPES_LIST,
        payload: results,
        // row_total: row_total,
        row_total_query_notes: row_total,
      });
    })
    .catch((err) => {
      console.error(err.message);
    });
};
export const noteRecords = (objFilters) => (dispatch, getState) => {
  dispatch({
    type: GET_RECORD_NOTES_LIST,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/records_notes/?${filters}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      const { results, row_total } = res;
      // const deleteThis = { billNumber: "123456", valor: "Borraar!" };
      dispatch({
        type: GET_RECORD_NOTES_LIST,
        payload: results,
        row_total: row_total,
        loading: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_RECORD_NOTES_LIST,
        payload: [],
        row_total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};
export const postRegisterNote = async (
  rn_providernote_id,
  rn_provider,
  rn_detail,
  rn_date,
  rn_account,
  rn_created_by,
  rm_note_type,
  bills,
  token
) => {
  try {
    let data = {
      rn_providernote_id,
      rn_provider,
      rn_detail,
      rn_date,
      rn_account,
      rn_created_by,
      rm_note_type,
      bills,
      token,
    };

    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/inventory/records_notes/`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message("error", "Error", error.message, undefined, true);
  }
};
