import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import { Button, Col, Row } from 'react-bootstrap';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import {
  getListCompanies,
  getListContracts,
  getOneListCompanies,
  getServices,
  getSites,
  generateInvoice,
} from '../../actions/billingActions';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Title } from '../../shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function FreeInvoice() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [selectedList, setselectedList] = useState([]);
  const [info, setInfo] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    client: '',
    contract: '',
    contractType: 2,
    ungroup: true
  });
  const [triggerSelect, setTriggerSelect] = useState(0);

  const [sendInfo, setSendInfo] = useState({
    observations: '',
    user: storage.loginReducer.user_data.id,
    site: '',
  });

  useEffect(() => {
    dispatch(getListContracts(info));
    dispatch(getOneListCompanies(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.client]);

  useEffect(() => {
    dispatch(getSites({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getListCompanies(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getServices(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.contract]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsContracts = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.contracts)) {
    storage.billingReducer.contracts.forEach(item => {
      optionsContracts.push({
        value: item.id,
        label: item.contract_name,
        key: item.id + 'contracts',
      });
    });
  }

  let optionsSites = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.sites)) {
    storage.billingReducer.sites.forEach(item => {
      optionsSites.push({
        value: item.id,
        label: item.description,
        key: item.id + 'sites',
      });
    });
  }

  const isBoxSelected = useCallback(
    id_req => {
      if (Array.isArray(selectedList)) {
        let target = selectedList.find(x => x.id === id_req);
        return target;
      }
    },
    [selectedList],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeBoxSelected = id => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== id);
      } else {
        let newChange = {
          id,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(storage.billingReducer.services) && Array.isArray(selectedList)) {
      storage.billingReducer.services.forEach(item => {
        if (selectedList.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(storage.billingReducer.services) && Array.isArray(tempSelected)) {
        storage.billingReducer.services.forEach(x => {
          let id = x.srv_id;
          let newChange = {
            id,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const header3 = [
    <th key={1} className='text-center'>
      <input
        type='checkbox'
        name=''
        id=''
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2} className='text-center'>
      Código
    </th>,
    <th key={3} className='text-center'>
      Nombre del servicio
    </th>,
    <th key={4} className='text-center'>
      Cantidad
    </th>,
    <th key={5} className='text-center'>
      %IVA
    </th>,
    <th key={6} className='text-center'>
      Valor IVA
    </th>,
    <th key={7} className='text-center'>
      Valor
    </th>,
  ];

  const [renderList, valorTotal, valorIvaTotal] = useMemo(() => {
    let table = [];
    let valor = 0;
    let valorIva = 0;
    if (Array.isArray(storage.billingReducer.services)) {
      table = storage.billingReducer.services.map((x, index) => {
        let found = selectedList.find(y => y.id === x.srv_id);
        if (found) {
          valor = valor + x.qty * x.price;
          valorIva = valorIva + x.qty * x.vatValue;
        }
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-center'>
              <input
                type='checkbox'
                name=''
                id=''
                checked={isBoxSelected(x.srv_id)}
                onChange={() => changeBoxSelected(x.srv_id)}
              />
            </td>
            <td>{x.cupCode}</td>
            <td>{x.name}</td>
            <td>{x.qty}</td>
            <td>{x.vat + '%'}</td>
            <td>{'$' + numberWithCommas(x.vatValue)}</td>
            <td>{'$' + numberWithCommas(x.price)}</td>
          </tr>
        );
      });
    }
    return [table, valor, valorIva];
  }, [selectedList, storage.billingReducer.services, changeBoxSelected, isBoxSelected]);
  var currDate = new Date().toISOString().substr(0, 10);
  const buttonGenerate = () => {
    dispatch(
      generateInvoice(
        {
          client: info.client,
          contract: info.contract,
          observations: sendInfo.observations,
          services: selectedList,
          eaccount: info.eaccount,
          site: sendInfo.site,
          user: sendInfo.user,
        },
        () => {
          setselectedList([]);
          setInfo({
            client: '',
            contract: '',
            observations: '',
            eaccount: storage.loginReducer.currentAccount.id,
            site: '',
            user: storage.loginReducer.user_data.id,
            ungroup: true
          });
          setSendInfo({
            observations: '',
            user: storage.loginReducer.user_data.id,
            site: '',
          });
          setTriggerSelect(triggerSelect + 1);
        },
      ),
    );
  };

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', paddingTop: '30px' }}
      >
        {storage.billingReducer.loading_generate_invoice && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {/* <h1 className={tableStyles.title}>Factura libre</h1> */}
        <Title
          title='Factura libre'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/facturacion/inicio');
          }}
        />
        <div>
          <div className='div gap-3 align-items-end'>
            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'client' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsCompanies}
                onChange={e =>
                  setInfo({
                    ...info,
                    client: e.value,
                  })
                }
                styles={customSelectNewDark}
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Contrato</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                key={'contract' + triggerSelect}
                options={optionsContracts}
                onChange={e =>
                  setInfo({
                    ...info,
                    contract: e.value,
                  })
                }
                styles={customSelectNewDark}
              />
            </div>

            <div className={tableStyles.containerDate}>
              &nbsp;<label className={tableStyles.stylesLabel}>Fecha </label>
              <input
                className={`${IndividualStyles.registerInputs} register-inputs`}
                style={{ color: '#005DBF', fontWeight: 'bold', background: '#F5F7FA' }}
                type='date'
                value={currDate}
                readOnly
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Dirección</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                placeholder='Escribir...'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                defaultValue={storage.billingReducer.one_company?.address}
                readOnly
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Ciudad</label>
              <input
                placeholder='Escribir...'
                className={IndividualStyles.registerInputs}
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                defaultValue={storage.billingReducer.one_company?.city}
                readOnly
              />
            </div>

            <div className={tableStyles.containerSelect}>
              &nbsp;<label className={tableStyles.stylesLabel}>Sedes </label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'site' + triggerSelect}
                options={optionsSites}
                placeholder='Seleccionar...'
                onChange={e =>
                  setSendInfo({
                    ...sendInfo,
                    site: e.value,
                  })
                }
                styles={customSelectNewDark}
              />
            </div>
          </div>
        </div>

        <div>
          <GenericTable headers={header3} dark={true}>
            {renderList}
          </GenericTable>
          <Row className={`w-100 m-0`}>
            <Col xs={10} className={`${tableStyles.clearBlueBg}`} style={{ paddingLeft: '0px' }}>
              <p className={`${tableStyles.crudModalLabel} pt-2`}>Observaciones</p>
              <textarea
                key={'observations' + triggerSelect}
                rows='10'
                cols='10'
                placeholder='Escribir...'
                className={IndividualStyles.textArea_new3}
                value={sendInfo.observations}
                onChange={e =>
                  setSendInfo({
                    ...sendInfo,
                    observations: e.target.value,
                  })
                }
              ></textarea>
            </Col>

            <Col xs={2} className={``} style={{ paddingRight: 0 }}>
              <div>
                <Col>
                  &nbsp;<label className={tableStyles.stylesLabel}>Valor</label>
                </Col>
                <Col>
                  <div
                    className={IndividualStyles.registerInputs}
                    style={{ background: '#F5F7FA', color: '#58595B' }}
                  >
                    {valorTotal ? '$' + numberWithCommas(Number(valorTotal)) : '$'}
                  </div>
                </Col>
                <Col>
                  &nbsp;<label className={tableStyles.stylesLabel}>Valor IVA</label>
                </Col>
                <Col>
                  <div
                    className={IndividualStyles.registerInputs}
                    style={{ background: '#F5F7FA', color: '#58595B' }}
                  >
                    {valorIvaTotal >= 0 ? '$' + numberWithCommas(valorIvaTotal) : '$'}
                  </div>
                </Col>
                <Col>
                  &nbsp;<label className={tableStyles.stylesLabel}>Total</label>
                </Col>
                <Col>
                  <div
                    className={IndividualStyles.registerInputs}
                    style={{ background: '#F5F7FA', color: '#58595B' }}
                  >
                    {valorIvaTotal >= 0 ? '$' + numberWithCommas(valorIvaTotal + valorTotal) : '$'}
                  </div>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
        <div className={IndividualStyles.bottomRow}>
          <Button
            style={{ padding: '8px', border: '0px' }}
            className={`${tableStyles.buttonExtra3} d-block mb-4`}
            onClick={() => buttonGenerate()}
            disabled={!!(selectedList.length === 0) || !!sendInfo.site === ''}
          >
            Generar factura
          </Button>
        </div>
      </div>
    </>
  );
}
export default FreeInvoice;
