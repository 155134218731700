import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button } from 'react-bootstrap';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useDispatch, useSelector } from 'react-redux';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import { getInvoicesToSend, getListCompanies, getInvoicesType } from '../../actions/billingActions';
import DetReceiptInvoicesInBilling from './DetReceiptInvoicesInBilling';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { customSwaltAlert } from './../../helpers/customSwaltAlert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Title } from '../../shared';

function SendingInvoices(props) {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [selectedList, setselectedList] = useState([]);
  const [details, setDetails] = useState({
    show: false,
  });
  const [trigger, setTrigger] = useState(0);

  const [filter, setFilter] = useState({
    page: 1,
    perpage: 10,
    date_until: '',
    date_from: '',
    type: '',
    sequence: '',
    client: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'sendingInv',
    );

  useEffect(() => {
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getInvoicesType({ eaccount: storage.loginReducer.currentAccount.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { history } = props;
    if (myPermission()?.create === false) {
      history?.push('/facturacion/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getInvoicesToSend(filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, filter.page]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsTypes = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.invoices_types)) {
    storage.billingReducer.invoices_types.forEach(item => {
      optionsTypes.push({
        value: item.id,
        label: item.type,
        key: item.id + 'types',
      });
    });
  }

  const isBoxSelected = id => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id);
      return !!target;
    }
  };
  const changeBoxSelected = invoice => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === invoice.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== invoice.id);
      } else {
        let newChange = {
          ...invoice,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const isBoxAllSelected = () => {
    let res = true;

    if (Array.isArray(storage.billingReducer.invoices_to_send) && Array.isArray(selectedList)) {
      storage.billingReducer.invoices_to_send.forEach(item => {
        if (selectedList.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(storage.billingReducer.invoices_to_send) && Array.isArray(tempSelected)) {
        storage.billingReducer.invoices_to_send.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.billingReducer.invoices_to_send)) {
      table = storage.billingReducer.invoices_to_send.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-center'>
              <input
                type='checkbox'
                name=''
                id=''
                checked={isBoxSelected(x.id)}
                onChange={() => changeBoxSelected(x)}
              />
            </td>
            <td>{x.sequence}</td>
            <td>{x.exp_date}</td>
            <td>{x.clientNit}</td>
            <td className='text-start'>{x.clientName}</td>
            <td>{x.type}</td>
            <td className='text-end'>{'$' + numberWithCommas(x.total_amount)}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const header3 = [
    <th key={1} className='text-center'>
      <input
        type='checkbox'
        name=''
        id=''
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={3} className='text-center'>
      Fecha factura
    </th>,
    <th key={4} className='text-center'>
      NIT
    </th>,
    <th key={5} className='text-start'>
      Empresa
    </th>,
    <th key={6} className='text-center'>
      Tipo
    </th>,
    <th key={7} className='text-end px-2'>
      Valor
    </th>,
  ];

  const buttonGenerate = () => {
    if (validateClient()) {
      setDetails({ show: true, selectedInvoices: selectedList });
    } else {
      return customSwaltAlert({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: 'Todas las facturas deben pertenecer al mismo cliente',
        showCancelButton: false,
      });
    }
  };

  const validateClient = () => {
    let status = true;
    let arrayNew = [];
    let duplicates = [];
    let notDuplicates = [];
    selectedList.forEach(item => {
      arrayNew.push(item.clientNit);
    });
    for (let i = 0; i < arrayNew.length; i++) {
      if (arrayNew[i + 1] === arrayNew[i]) {
        duplicates.push(arrayNew[i]);
      } else {
        notDuplicates.push(arrayNew[i]);
      }
    }
    if (duplicates.length === 1 || notDuplicates.length === 1) {
      status = true; //TODOS LOS PROVEEDORES SON IGUALES
    }
    if (notDuplicates.length > 1) {
      status = false; //LOS PROVEEDORES SON DIFERENTES
    }
    return status;
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilter({ ...filter, page: 1 });
    setTrigger(trigger + 1);
  };

  const principalRender = (
    <>
      {' '}
      {myPermission().read ? (
        <div
          className={` ml-5 ${tableStyles.container}`}
          style={{ marginRight: '3rem', marginTop: '30px' }}
        >
          {storage.billingReducer.invoices_to_send_loading && (
            <div className='loading'>
              <Loader type='Oval' color='#003f80' height={100} width={100} />
            </div>
          )}

          <Title
            title='Envío de facturas'
            className='mb-2'
            onClickIcon={() => {
              history.push('/facturacion/inicio');
            }}
          />

          <div>
            <div className='div gap-3'>
              <div className={`display-grid ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Empresa</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    placeholder='Seleccionar...'
                    options={optionsCompanies}
                    styles={customSelectNewDark}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        client: e.value,
                      })
                    }
                  />
                </form>
              </div>
              <div className={`display-grid ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>No. Factura</label>
                  <input
                    className={IndividualStyles.registerInputs}
                    placeholder='Escribir...'
                    type='text'
                    value={filter.sequence}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        sequence: e.target.value,
                      })
                    }
                  />
                </form>
              </div>
              <div className={`display-grid ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Tipo</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsTypes}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        type: e.value,
                      })
                    }
                  />
                </form>
              </div>
              <div className={`display-grid ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Fecha desde</label>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    style={{ color: '#005DBF', fontWeight: 'bold' }}
                    type='date'
                    value={filter.date_from}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        date_from: e.target.value,
                      })
                    }
                  />
                </form>
              </div>
              <div className={`display-grid ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Fecha hasta</label>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    style={{ color: '#005DBF', fontWeight: 'bold' }}
                    type='date'
                    value={filter.date_until}
                    onChange={e =>
                      setFilter({
                        ...filter,
                        date_until: e.target.value,
                      })
                    }
                  />
                </form>
              </div>
              <div className={`display-grid align-items-end ${tableStyles.containerSelect}`}>
                <form onSubmit={e => handleSearch(e)}>
                  <i>
                    <img
                      style={{ width: '25px' }}
                      src={Lupa}
                      alt=''
                      srcSet=''
                      onClick={() => {
                        setTrigger(trigger + 1);
                        setFilter({ ...filter, page: 1 });
                      }}
                    />
                  </i>
                </form>
              </div>
            </div>
          </div>
          <div>
            <GenericTable headers={header3} dark={true}>
              {renderList()}
            </GenericTable>
          </div>

          <div className={IndividualStyles.bottom}>
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {storage.billingReducer.invoices_to_send ? filter.page : ''}
                {' de '}
                {Math.ceil(storage.billingReducer?.invoices_to_send_total / filter.perpage)
                  ? Math.ceil(storage.billingReducer.invoices_to_send_total / filter.perpage)
                  : ''}{' '}
                ({storage.billingReducer.invoices_to_send_total} encontrados)
              </p>
              <Pagination
                activePage={filter.page}
                itemsCountPerPage={10}
                totalItemsCount={parseInt(storage.billingReducer.invoices_to_send_total)}
                pageRangeDisplayed={5}
                onChange={e => setFilter({ ...filter, page: e })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>

          <div className={IndividualStyles.bottomRow}>
            <Button
              style={{ padding: '8px', border: '0px' }}
              className={`${tableStyles.buttonExtra3} d-block`}
              onClick={() => buttonGenerate()}
              disabled={!!(selectedList.length === 0)}
            >
              Crear acta
            </Button>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
  return details.show ? (
    <DetReceiptInvoicesInBilling
      selectedInvoices={details.selectedInvoices}
      show={details.show}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}
export default SendingInvoices;
