import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, useMultiGetMethod, usePostMethod } from '../../Hooks/useFetch';
import OrdLupa from '../../assets/img/icons/OrdLupa.svg';
import desplegarGris from '../../assets/img/icons/desplegarGris.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { formatToRcSelect, loader, message, swalConfirm, today, formatteDocument } from '../../helpers';
import { OrdCard } from '../OrderingComponents/OrdCard';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingComponents/OrdModal';
import { OrdSlideDownButton } from '../OrderingComponents/OrdSlideDownButton';
import ordComponentStyles from '../OrderingComponents/ordComponentStyles.module.scss';

export const AuthServices = () => {
  const { load: authServicesLoader, trigger: getAuthServicesResults } = useGetMethod();
  const {
    results: contractsResults,
    load: contractsLoader,
    trigger: getContracts,
  } = useGetMethod();
  const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod();
  const { load: putAuthServiceLoader, trigger: putAuthService } = usePostMethod();

  function formatSelect(data, valueKey, labelKey, ...rest) {
    if (!data) return [];

    const options = data.map(item => {
      const label = item[labelKey].replace(/[^a-zA-Z\s]/g, ''); // Eliminar números y signos
      return {
        value: item[valueKey],
        label: label,
        ...rest.reduce((acc, key) => {
          acc[key] = item[key];
          return acc;
        }, {})
      };
    });

    options.unshift({
      value: undefined,
      label: 'Seleccionar'
    });

    return options;
  }

  const doctorOptions = formatSelect(
    unities?.medicalUsers?.results,
    'id',
    'user',
    '',
    '',
    '',
  );
  const contractsOptions = formatToRcSelect(
    contractsResults?.results,
    'id',
    'contractName',
    '',
    '',
    '',
  );
  const companiesOptions = formatToRcSelect(
    unities?.company?.results,
    'idCorporateClient',
    'nameCorporateClient',
    '',
    '',
    '',
  );
  const store = useSelector(state => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const idUser = store.loginReducer.user_data.id;
  const [rotate, setRotate] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [trigger2, setTrigger2] = useState(0);
  const [filters, setFilters] = useState({
    idAccount: idEnterprise,
    auth: 1,
    startDate: '',
    endDate: '',
  });
  const [justification, setJustification] = useState({
    showModal: false,
    justification: '',
  });
  const [data, setData] = useState({
    id: '',
    prefix: 'service',
    userId: idUser,
  });

  const renderTooltipRejected = () => (
    <Tooltip>
      <span>Rechazar</span>
    </Tooltip>
  );
  const renderTooltipApproved = () => (
    <Tooltip>
      <span>Aprobar</span>
    </Tooltip>
  );
  const renderTooltipSearch = () => (
    <Tooltip>
      <span>
        Permite búsqueda por documento del paciente, nombre del paciente y concepto de la justificación
      </span>
    </Tooltip>
  );
  const approvedButton = med => {
    setData({ ...data, action: 'open', id: med?.serviceId });
    swalConfirm({
      title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
      text: `Se aprobará el servicio ${med?.servType}, para paciente: ${med?.patient?.name}`,
      confirmButtonText: 'Si, continuar',
      doAfterConfirm: () => {
        putAuthService({
          url: '/medical/authOrder/',
          token: token,
          method: 'PUT',
          body: {
            id: med?.serviceId,
            prefix: 'service',
            userId: idUser,
            idAccount: idEnterprise,
            action: 'approved',
          },
          doAfterSuccess: () => {
            setTrigger(trigger + 1);
          },
        });
      },
    });
  };

  const rejectedButton = med => {
    setJustification({ ...justification, showModal: true });
    setData({ ...data, action: 'rejected', id: med?.serviceId });
  };

  let buttons = [
    {
      button: <img alt='btn' src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={desplegarGris}
        ></img>
      ),
    },
  ];

  const filterStateBtns =
    unities?.status?.results?.length > 0 &&
    unities?.status?.results?.map(item => {
      return {
        btnTitle: (
          <button
            style={{ color: item?.color }}
            className={`btn p-2 ${tableStyles.ordAuthBtn} ${tableStyles.f14}`}
          >
            {item?.number} {item?.description}(s)
          </button>
        ),
        btnSize: 3,
        action: () => {
          setFilters({ ...filters, status: item?.id });
          setTrigger(trigger + 1);
        },
      };
    });

  const titleCard = med => {
    let colorBg =
      med?.status?.staDescription === 'Pendiente'
        ? tableStyles.ordOrangeBgButton
        : med?.status?.staDescription === 'Aprobado'
          ? tableStyles.ordGreenBgButton
          : tableStyles.ordRedBgButton;
    let createdDate = med?.createdAtMedicalOrders.split('|');
    let date = createdDate[0];
    return (
      <Row className={`py-3 ${tableStyles.ordGrayBorderBottom}`}>
        <Col xs={3}>
          <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f16}`}>{med?.servType}</b>
        </Col>
        <Col xs={3}>
          <div className='d-flex'>
            <span className='pr-2'>
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                Fecha de solicitud: &nbsp;
              </b>
            </span>
            <span className={`text-secondary fw-normal`}> {date}</span>
          </div>
        </Col>
        <Col xs={2}>
          <b className={`${tableStyles.f14} ${colorBg}`}>{med?.status?.staDescription}</b>
        </Col>
        {med?.status?.staDescription === 'Pendiente' && (
          <Col className={`d-flex justify-content-end`} xs={4}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipRejected()}
            >
              <div
                className={tableStyles.denyCardRed}
                onClick={() => {
                  rejectedButton(med);
                }}
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
            &nbsp; &nbsp;
            {/* <div className={tableStyles.checkCardGreen}> &nbsp; &nbsp;</div> */}
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipApproved()}
            >
              <div
                className={tableStyles.checkCardGreen}
                onClick={() => {
                  approvedButton(med);
                }}
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
          </Col>
        )}
      </Row>
    );
  };
  const bodyCard = med => {
    return (
      <>
        {/* #1 */}
        <Row className={`p-0`}>
          <Col xs={3} className='col-3 m-0 p-0'>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Médico</b>
          </Col>
          <Col xs={3}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Paciente</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Identificación</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Empresa</b>
          </Col>
          <Col xs={2}>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Contrato</b>
          </Col>
        </Row>
        <Row className={`p-0`}>
          <Col xs={3} className='col-3 m-0 p-0'>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {' '}
              {med?.doctor?.name}
            </span>
          </Col>
          <Col xs={3}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {med?.patient?.name}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {' '}
              {med?.patient?.docTypeDesc} {formatteDocument(med?.patient?.document).format}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {med?.corporateClient?.name}
            </span>
          </Col>
          <Col xs={2}>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {med?.corporateClient?.contractName}
            </span>
          </Col>
        </Row>
        <Row className={`p-0`}>
          <Col xs={12} className='col-3 m-0 p-0'>
            <b className={`${tableStyles.ordClearBlueText} ${tableStyles.f14}`}>Justificación</b>
          </Col>
        </Row>
        <Row className={`p-0`}>
          <Col xs={12} className='col-3 m-0 p-0'>
            <span className={`${tableStyles.f14} text-secondary fw-normal`}>
              {med?.servJustification}
            </span>
          </Col>
        </Row>
      </>
    );
  };

  const handleSubmit = () => {
    putAuthService({
      url: '/medical/authOrder/',
      token: token,
      method: 'PUT',
      body: { ...data, idAccount: idEnterprise },
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
      },
    });
  };
  const [result, setResult] = useState([]);

  const renderServices = data => {
    let newArr = [];
    const statusOrder = ['Pendiente', 'Aprobado', 'Rechazado'];
    data?.results?.length > 0 &&
      data?.results?.forEach(res => {
        res?.services.forEach(med => {
          med.createdAtMedicalOrders = res?.createdAtMedicalOrders;
          med.doctor = res?.doctor;
          med.patient = res?.patient;
          med.corporateClient = res?.corporateClient;
          med.statusDes = med?.status?.staDescription;
          newArr.push(med);
        });
      });
    setResult(
      newArr.sort((a, b) => statusOrder.indexOf(a?.statusDes) - statusOrder.indexOf(b?.statusDes)),
    );
  };

  // --- GET SERVICES HISTORY-----
  useEffect(() => {
    getAuthServicesResults({
      url: '/medical/serviceOrder/',
      objFilters: filters,
      token: token,
      doAfterSuccess: data => renderServices(data),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // --- MULTI GET FILTERS VALUE-----
  useEffect(() => {
    getUnities({
      multipleGet: [
        {
          url: '/medical/generals/medicalUsers/',
          objFilters: { eaccount, status: 'enabled' },
          requestName: 'medicalUsers',
        },
        {
          url: '/medical/generals/company/',
          requestName: 'company',
        },
      ],
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {authServicesLoader && loader}
      {unitiesLoader && loader}
      {contractsLoader && loader}
      {putAuthServiceLoader && loader}
      <OrdModal
        title={'Justificación'}
        show={justification.showModal}
        btnYesName='Aceptar'
        hideCancelButton
        size='700'
        btnYesEvent={() => {
          if (data?.authComment) {
            if (data.authComment.length > 50) {
              swalConfirm({
                title: `<span class=${tableStyles.ordClearBlueText} >¿Está seguro?</span>`,
                text: `Recuerda que la presente justificación será enviada al paciente`,
                confirmButtonText: 'Sí, enviar',
                doAfterConfirm: () => {
                  handleSubmit();
                  setJustification({ ...justification, showModal: false });
                },
              });
              setData({ ...data, authComment: '' });
            } else {
              message('info', '', 'La justificación debe tener mínimo 50 caracteres');
            }
          } else {
            message('info', '', 'La justificación es obligatoria');
          }
        }}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className='mb-3' controlId='fName'>
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className='text-start'>
                &nbsp;Explique la razón por la que rechaza esta solicitud
              </b>
            </Form.Label>
            <textarea
              placeholder={'Escribir...'}
              onChange={e => setData({ ...data, authComment: e.target.value })}
              rows='25'
              cols='55'
              style={{ height: '7rem' }}
              className={`text-secondary ord-roundInput w-100`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>
      <div className='w-90 mx-1 pt-3'>
        <OrdGenericTemplate
          className='w-100'
          titleSize={6}
          colBtnSize={6}
          colbtnClass={'p-0 m-0'}
          title={'Autorizar servicios'}
          buttons={filterStateBtns}
        >
          <div className='w-100 mx-1'>
            <OrdSlideDownButton
              onToggleClick={() => setRotate(!rotate)}
              buttons={buttons}
              accordionClassName={ordComponentStyles.OrdSlideButton}
            >
              <Row className={`px-2`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Fecha desde</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type='date'
                      placeholder=''
                      value={filters.startDate}
                      onChange={e => {
                        if (e.target.value > today()) {
                          return message(
                            'info',
                            '',
                            "El filtro 'fecha desde', debe ser menor a la fecha actual",
                          );
                        } else if (filters.endDate !== '' && e.target.value > filters.endDate) {
                          return message(
                            'info',
                            '',
                            "El filtro 'fecha desde', debe ser menor al filtro 'fecha hasta'",
                          );
                        }
                        if (filters.endDate) {
                          setFilters({ ...filters, startDate: e.target.value });
                        } else {
                          setFilters({ ...filters, startDate: e.target.value, endDate: today() });
                        }
                        const diff = moment(e.target.value).diff(moment('1990-01-01'), 'years');
                        setFilters({ ...filters, startDate: e.target.value });
                        if (diff > 0) {
                          setTimeout(() => {
                            setTrigger(trigger + 1);
                          }, 100);
                        }
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setTrigger(trigger + 1);
                        }
                      }}
                    />{' '}
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp; Fecha hasta</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                      type='date'
                      placeholder=''
                      value={filters.endDate}
                      onChange={e => {
                        setFilters({ ...filters, endDate: e.target.value });
                        const diff = moment(e.target.value).diff(moment('1990-01-01'), 'years');
                        setFilters({ ...filters, endDate: e.target.value });
                        if (diff > 0) {
                          setTimeout(() => {
                            setTrigger(trigger + 1);
                          }, 100);
                        }
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setTrigger(trigger + 1);
                        }
                      }}
                    />{' '}
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Médico</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFilters({ ...filters, doctor: e.value });
                        setTrigger(trigger + 1);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Empresa</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={companiesOptions}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFilters({ ...filters, company: e.value, contract: null });
                        setTrigger(trigger + 1);
                        setTrigger2(trigger2 + 1);
                        getContracts({
                          url: '/medical/medicine/getContract/',
                          objFilters: { slim: 1, corporateClient: e.value },
                          requestName: 'contracts',
                          token: token,
                        });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b> &nbsp;Contrato</b>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={contractsOptions}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      key={'contract' + trigger2}
                      styles={ordCustomSelect}
                      onChange={e => {
                        setFilters({ ...filters, contract: e.value });
                        setTrigger(trigger + 1);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipSearch()}
                    >
                      <div className={`d-flex ${tableStyles.ordGrayInputSearch}`}>
                        <Form.Control
                          style={{ backGroundColor: 'red' }}
                          className={`ord-roundInput`}
                          type='text'
                          placeholder='Escribe aquí para buscar'
                          autoComplete='off'
                          onChange={e => {
                            setFilters({ ...filters, search: e.target.value });
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              setTrigger(trigger + 1);
                            }
                          }}
                        />
                        <img src={ordBlueSearch} alt='lupa' className='p-2'></img>
                      </div>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
            </OrdSlideDownButton>
            {result.length > 0 &&
              result?.map((med, i) => {
                return (
                  <OrdCard
                    key={i}
                    className='my-3'
                    title={titleCard(med)}
                    body={bodyCard(med)}
                  ></OrdCard>
                );
              })}
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
