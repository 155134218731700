//-------------- components 🍕   --------------------------------------------
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import Select from 'react-select';
import { ordCustomSelect } from '../../../src/components/Layouts/react-select-custom';

//-------------- styles 🍕   --------------------------------------------
import tableStyles from '../../../src/components/Layouts/tableStyle.module.scss';
//-------------- icons 🍕   --------------------------------------------
import Download from '../../assets/img/icons/pdfClearBlueIcon.svg';
import Excel from '../../assets/img/icons/excelVeryClearBlue.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useGetMethod } from '../../Hooks/useFetch';
import Loader from 'react-loader-spinner';
import {
  downloadBase64,
  downloaFile,
  formatToRcSelect,
  getPermission,
} from '../../helpers/helpers';
import { getStatusPayment } from '../../actions/payHistoryActions';

function PayHistory() {
  //-------------- const 🍕   --------------------------
  const [data, setData] = useState([]);
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({ perpage: 10, page: 1 });
  const { results: ListHistory, load: ListHistoryLoader, trigger: getListHistory } = useGetMethod();
  const { load: docPdfLoader, trigger: getDocPdf } = useGetMethod();
  const { load: statusLoader } = useGetMethod();
  const statusOptions = formatToRcSelect(
    store?.payHistoryReducer?.listStatusPayment,
    'value',
    'description',
    '',
    '',
    '',
  );
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'hispay', listPermission });
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };
  const headers = [
    {
      title: 'Factura',
      className: 'text-start px-2',
    },
    {
      title: 'Paciente',
      className: 'text-start px-2',
    },
    {
      title: 'Fecha',
      className: 'text-center px-2',
    },
    {
      title: 'Valor',
      className: 'text-end px-2',
    },
    {
      title: 'Estado',
      className: 'text-center px-2',
    },
  ];
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(e => {
        let container = (
          <div
            className='rounded-pill p-2 col-7'
            style={{ backgroundColor: `${e.bg_color}`, width: 'max-content' }}
          >
            <b style={{ color: `${e.font_color}` }}>{e.status_name}</b>
          </div>
        );
        tempList.push([
          {
            text: e.transac_code,
            className: 'text-start px-2',
          },
          {
            text: (
              <>
                {' '}
                <div>
                  {' '}
                  <div>{e.patient}</div>
                  <div>{e.doc_num}</div>
                </div>
              </>
            ),
            className: 'text-start px-2 col-3',
          },
          {
            text: (
              <>
                <div>
                  {' '}
                  <div>{e.pay_date}</div>
                  <div>{e.pay_time}</div>
                </div>
              </>
            ),
            className: 'text-center col-1',
          },
          {
            text: '$' + numberWithCommas(e.amount),
            className: 'px-2 text-end',
          },
          {
            text: container,
            className: 'px-2',
            style: { textAlign: '-webkit-center' },
          },
        ]);
      });
    }
    setData(tempList);
    return tempList;
  };
  useEffect(() => {
    dispatch(getStatusPayment('app_payment'));
    getListHistory({
      url: '/medical/generals/paymentHistory/',
      objFilters: filters,
      token: token,
      doAfterSuccess: results => formatData(results.results),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.status, filters.from, filters.until, filters.page, trigger]);
  //-------------- Actions 🍕   --------------------------
  const downloadDoc = () => {
    getDocPdf({
      url: '/medical/generals/paymentHistExportPdf/',
      objFilters: filters,
      token: token,
      doAfterSuccess: results => downloaFile(results.key),
    });
  };
  const downloadExcel = () => {
    getDocPdf({
      url: '/medical/appointment/paymentHistoryExcel/',
      objFilters: filters,
      token: token,
      doAfterSuccess: results =>
        downloadBase64(
          `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${results?.results?.split("'")[1]}`,
        ),
    });
  };
  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  //-------------- Principal return 🍕   --------------------------
  return (
    <div className={`${tableStyles.tlnContainer} mt-4`}>
      {(!!loading || ListHistoryLoader || docPdfLoader || statusLoader) && (
        // || detailPacientLoader
        <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
      )}
      <OrdGenericTemplate showBottomBorder={false} title={'Historial de pagos'}>
        <Row>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Fecha desde</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput pleaceholderDate`}
                type='date'
                placeholder=''
                onChange={e => setFilters({ ...filters, from: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Hasta</b>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput pleaceholderDate`}
                type='date'
                placeholder=''
                onChange={e => setFilters({ ...filters, until: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col xs={2}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='px-1'>Estado</b>
              </Form.Label>
              <Select
                options={statusOptions}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={e => setFilters({ ...filters, status: e.value })}
              ></Select>
            </Form.Group>
          </Col>
          <Col xs>
            {myPermission?.read && (
              <form onSubmit={e => handleSearch(e)}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                  <div className={`d-flex ${tableStyles.tlnBlueInputSearch}`}>
                    <>
                      <Form.Control
                        className={`${tableStyles.tlnRoundInputSearch}`}
                        type='text'
                        placeholder='Escribe aquí para buscar'
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                      />
                      <img className='cursorPointer' src={ordBlueSearch} alt={'search'}></img>
                    </>
                  </div>
                </Form.Group>
              </form>
            )}
          </Col>

          {myPermission?.read && (
            <>
              <Col xs={1} className='align-self-center' style={{ width: 'max-content' }}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b>ㅤ</b>
                  </Form.Label>
                  <div className='cursorPointer'>
                    <img alt={'download'} src={Download} onClick={() => downloadDoc()} />
                  </div>
                </Form.Group>
              </Col>
              <Col xs={1} className='align-self-center' style={{ width: 'max-content' }}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b>ㅤ</b>
                  </Form.Label>
                  <div className='cursorPointer'>
                    <img alt={'download'} src={Excel} onClick={() => downloadExcel()} />
                  </div>
                </Form.Group>
              </Col>
            </>
          )}
        </Row>

        {myPermission?.read && (
          <OrdTable
            headers={headers}
            body={data}
            className={tableStyles.ordTableShadow}
            paginate={{
              activePage: filters.page,
              totalPages: ListHistory?.rowTotal,
              perPage: filters.perpage,
              pageRangeDisplayed: 3,
              onChangePage: async e => {
                setFilters({ ...filters, page: e });
              },
              showTextDetails: true,
            }}
          ></OrdTable>
        )}
      </OrdGenericTemplate>
    </div>
  );
}
export default PayHistory;
