import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import backArrow from '../../../assets/img/icons/atras-icon.svg';
import avion from '../../../assets/img/icons/avion.svg';
import burbuje from '../../../assets/img/icons/burbuje.svg';
import Search from '../../../assets/img/icons/lupa.svg';

import { convertMoneyFormat, isEmptyOrUndefined, loader, formatteDocument } from '../../../helpers';
import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';

import GenericTableScroll from '../../Layouts/GenericTableScroll';
import ModalNew from '../../Layouts/ModalNew';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';

export const GeneratePremiumSchedule = ({ backPage, idTemplate, status, dataPayroll }) => {
  const store = useSelector(state => state);
  const history = useHistory();
  const token = store.loginReducer.Authorization;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'planPrim',
  );
  const idAccount = store.loginReducer.currentAccount.id;

  const [data, setData] = useState({
    trigger: 0,
    filters: {
      search: '',
      eaccount: idAccount,
      id: idTemplate,
    },
    modal: {
      show: false,
      isEditing: false,
      denial: '',
    },
  });
  const { filters, trigger } = data;
  const { load: premiumScheduleLoader, trigger: getPremiumSchedule } = useGetMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  const [detailPayroll, setDetailPayroll] = useState([]);

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    getPremiumSchedule({
      url: '/payroll/detail_bonus_pay',
      objFilters: filters,
      token: token,
      doAfterSuccess: res => {
        if (res.rowTotal === 0 && status === 'in_process') {
          history.push('/nomina/planillaDePrimas');
          return customSwaltAlert({
            icon: 'warning',
            title: `Registro vacío`,
            text: `La planilla de primas se encuentra en proceso de generación`,
            showCancelButton: false,
            confirmButtonText: 'Regresar',
          }).then(() => {
            backPage.setData(state => ({
              ...state,
              generatePremiumSchedule: false,
              trigger: state.trigger + 1,
            }));
          })
        }

        let resCopy = [];

        res.results.forEach(employee => {
          resCopy.push({ ...employee, oldInscBonusPayment: employee.inscBonusPayment });
        });
        setDetailPayroll(resCopy);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onChangeInputFunction = (value, elem) => {
    let insc = value?.split('$')[1]?.split(',');
    let inscValue = insc?.join('');
    let detailPayrollCopy = [...detailPayroll];
    let findJson = detailPayrollCopy.find(e => e.id === elem.id);
    let findJsonIndex = detailPayrollCopy.findIndex(e => e.id === elem.id);
    if (isEmptyOrUndefined(inscValue)) {
      findJson.inscBonusPayment = '0.00';
    } else {
      findJson.inscBonusPayment = inscValue;
    }
    detailPayrollCopy[findJsonIndex] = findJson;
    setDetailPayroll(detailPayrollCopy);
  };
  const renderElement = () => {
    let tempList = [];
    detailPayroll?.length > 0 &&
      detailPayroll?.forEach(elem => {
        tempList.push(
          <section2
            className={`d-flex`}
            key={elem.id}
            style={{ backgroundColor: elem.comment && '#FCF1E1' }}
          >
            <div className='text-start col-1 d-flex px-3'>{formatteDocument(elem?.docEmployee).format ?? '-'}</div>
            <div className='text-start col-2 px-3'> {elem?.nameEmployee ?? '-'}</div>
            <div className='text-center col-1'>{elem?.workDays ?? '-'}</div>
            <div className='text-end col-1 px-2'>
              {' '}
              {convertMoneyFormat(elem?.salaryEmployee) ?? '-'}
            </div>
            <div className='text-end col-1 px-2'>
              {' '}
              {convertMoneyFormat(elem?.averageSalary) ?? '-'}
            </div>
            <div className='text-end col-1 px-2'>
              {' '}
              {convertMoneyFormat(elem?.transportSupport) ?? '-'}
            </div>
            <div className='text-end col-1'> {convertMoneyFormat(elem?.bonusPayment ?? '-')}</div>
            <div
              className={`text-end col-2 ${(status === 'loaded' || status === 'rejected') && 'pl-5 d-flex justify-content-end'}`}
              style={{ paddingLeft: status === 'loaded' || status === 'rejected' ? '30px' : '0' }}
            >
              {elem.oldInscBonusPayment === '0' ||
              elem.oldInscBonusPayment === 0 ||
              elem.oldInscBonusPayment === '0.00' ? (
                convertMoneyFormat(elem?.oldInscBonusPayment ?? '-')
              ) : ((status === 'loaded' || status === 'rejected'))  ? (
                <NumberFormat
                  allowNegative={false}
                  className={`register-inputs text-end`}
                  prefix='$'
                  placeholder='$0'
                  thousandsGroupStyle='thousand'
                  thousandSeparator={','}
                  decimalSeparator={'.'}
                  defaultValue={elem?.inscBonusPayment}
                  onChange={e => onChangeInputFunction(e.target.value, elem)}
                />
              ) : (
                convertMoneyFormat(elem?.inscBonusPayment ?? '-')
              )}
            </div>
            <div className='text-end col-1 px-2'>
              {' '}
              {convertMoneyFormat(
                (Number(elem?.bonusPayment) + Number(elem?.inscBonusPayment)).toFixed(2),
              ) ?? '-'}
            </div>
            {elem.comment && (
              <div className='text-center col-1'>
                <img
                  onClick={() => {
                    openCommentModal(elem.comment);
                  }}
                  src={burbuje}
                  alt='burbuje'
                  className='pointer'
                />
              </div>
            )}
          </section2>,
        );
      });

    return tempList;
  };

  const renderHeaders = [
    <div key={1} className='text-start col-1 px-3'>
      ID
    </div>,

    <div key={2} className='text-start col-2 px-3'>
      Empleado
    </div>,
    <div key={5} className='text-center col-1'>
      Días laborados
    </div>,
    <div key={3} className='text-end col-1 px-3'>
      Salario
    </div>,
    <div key={4} className='text-end col-1 px-2' style={{textWrap:'balance'}}>
      Promedio salario variable
    </div>,
    <div key={10} className='text-end col-1 px-2' style={{textWrap:'balance'}}>
      Auxilio de transporte
    </div>,
    <div key={6} className='text-end col-1 px-2'>
      Primas
    </div>,

    <div key={7} className='col-2 text-end'>
      Primas INCS
    </div>,

    <div key={8} className='text-end col-1 px-2'>
      Total a pagar
    </div>,

    <div key={9} className='text-start col-1 px-2'>
      &nbsp;
    </div>,
  ];

  const sendToAccountingFunction = () => {
    let status = true;
    detailPayroll.forEach(employee => {
      if (Number(employee.inscBonusPayment) > Number(employee.oldInscBonusPayment)) {
        status = false;
        return customSwaltAlert({
          icon: 'warning',
          title: `Intenta de nuevo`,
          text: `Para el empleado ${employee.nameEmployee}, el valor de la prima INCS debe ser menor o igual a ${convertMoneyFormat(employee.oldInscBonusPayment)}`,
        });
      }
    });

    if (status) {
      customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se enviará a contabilidad la planilla de primas del año ${dataPayroll?.paymentYear}`,
      }).then(result => {
        if (result.isConfirmed) {
          updatePayroll({
            url: '/payroll/bonus_pay',
            token: token,
            method: 'PUT',
            body: {
              id: idTemplate,
              eaccount: idAccount,
              status: 'review',
              detail: detailPayroll,
            },
            succesAction: () => {
              backPage.setData(state => ({
                ...state,
                generatePremiumSchedule: false,
                trigger: state.trigger + 1,
              }));
              customSwaltAlert({
                title: `Enviada exitosamente`,
                text: `Se ha enviado a contabilidad planilla del año ${dataPayroll?.paymentYear} - Periodo ${dataPayroll?.range}. `,
                icon: 'success',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
          });
        }
      });
    }
  };

  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
  });

  const openCommentModal = comment => {
    setCommentModal({
      ...commentModal,
      show: true,
      comment: comment,
    });
  };
  const closeCommentModal = () => {
    setCommentModal({
      ...commentModal,
      show: false,
      cancelledReason: '',
    });
  };

  return (
    <>
      {(premiumScheduleLoader || updatePayrollLoader) && loader}
      <ModalNew
        title='Comentarios'
        show={commentModal.show}
        btnNoName={'Cerrar'}
        size='400'
        btnYesEvent={null}
        btnYesDisabled={true}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentarios de empleado</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          disabled={true}
          defaultValue={commentModal.comment}
          style={{
            height: '4rem',
            borderRadius: '5px',
            border: '1.5px solid #01A0F6',
            padding: '0 0.05rem',
            width: '100%',
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>

      <div className='w-90 mx-auto'>
        <h1
          className={`${tableStyles.title} d-flex gap-2 align-items-center`}
          style={{ marginLeft: '-30px' }}
        >
          <img
            onClick={() => {
              backPage.setData(state => ({
                ...state,
                generatePremiumSchedule: false,
                trigger: state.trigger + 1,
              }));
            }}
            src={backArrow}
            alt='backArrow'
            className={`pointer`}
            height='30px'
          />
          <span className={`${tableStyles.titleAdministrative}`}>Detalle planilla de primas</span>
        </h1>

        <Grid container spacing={3}>
          <Grid item xs={2}>
            <p className={tableStyles.crudModalLabel}>Días menores a</p>
            <input
              onChange={e =>
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, daysToPay: e.target.value },
                }))
              }
              type='number'
              value={filters.daysToPay}
              placeholder='Escribir...'
              className={`register-inputs`}
              name='position'
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />
          </Grid>
          <Grid item xs={6} className='d-flex align-items-end justify-content-end'>
            <input
              className={`${tableStyles.blueSearch} mr-2`}
              style={{ flex: 1, color: `#73a6dc` }}
              name='search'
              type='text'
              placeholder='Buscar...'
              value={filters.search}
              onChange={e =>
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, search: e.target.value },
                }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />

            <img
              src={Search}
              style={{ cursor: 'pointer' }}
              alt='Search icon'
              onClick={() => {
                setData(state => ({ ...state, trigger: state.trigger + 1 }));
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <GenericTableScroll
              headers={renderHeaders}
              dark={true}
              body={renderElement()}
              typeHead={'2'}
            ></GenericTableScroll>
          </Grid>
        </Grid>

        <Grid className='d-flex justify-content-end gap-3 mt-3'>
          <Button
            style={{ textTransform: 'none' }}
            className={tableStyles.btnCancelStyle}
            onClick={() => {
              backPage.setData(state => ({
                ...state,
                generatePremiumSchedule: false,
                trigger: state.trigger + 1,
              }));
            }}
          >
            Cancelar
          </Button>
          {(status === 'loaded' || status === 'rejected') && (
            <Button
              style={{ textTransform: 'none' }}
              className={`mx-2 ${tableStyles.btnYesStyle}`}
              onClick={() => sendToAccountingFunction()}
            >
              <div className='d-flex gap-2 mx-2 '>
                <img src={avion} alt='avion' />
                <span className=''>Enviar a contabilidad</span>
              </div>
            </Button>
          )}
        </Grid>
      </div>
    </>
  );
};
