import { Accordion, Card, Col } from 'react-bootstrap';
export const OrdSlideDownButton = props => {
  let { buttons, children, bodyClassName, onToggleClick, accordionClassName } = props;
  return (
    <>
      <Accordion className={`w-100 ${accordionClassName}`} defaultActiveKey='0'>
        <div className='w-100  p-2'>
          <Accordion.Toggle onClick={() => onToggleClick()} eventKey={'0'} className='d-flex w-100'>
            <div className={`w-100 d-flex justify-content-end `}>
              <Col className={`d-flex justify-content-end`} xs={12}>
                {buttons.length > 0 &&
                  buttons.map(e => {
                    return (
                      <>
                        {e.button}
                        &nbsp;
                        <span className={`mr-2 text-secondary`}>{e?.title || ''}</span>
                      </>
                    );
                  })}
              </Col>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse
            // key={"0"}
            eventKey={'0'}
          >
            <Card.Body className={bodyClassName}>{children}</Card.Body>
          </Accordion.Collapse>
        </div>
      </Accordion>
    </>
  );
};
