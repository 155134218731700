import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Excel from '../../assets/img/icons/excel.svg';
import iconBack from '../../assets/img/icons/iconBack.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

import { getOneBalance } from '../../actions/receiptOfInvoicesActions';
import GenericTable from '../../components/Layouts/GenericTableNew';
import { convertMoneyFormat, genericDownloadExcel, loader } from '../../helpers/helpers';
import ChangeStatus from './ChangeStatus/ChangeStatus';
import PrelegalCollection from './PrelegalCollection';

import { usePostMethod } from '../../Hooks';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert } from '../../helpers';

function CompanyBalance(props) {
  const statusConversion = {
    persuasivo: 'Pre-Jurídico',
    'pre-jurídico': 'Jurídico',
    jurídico: 'Castigo',
  };

  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showReceipt, setshowReceipt] = useState({
    show: false,
  });
  const [isChangeStatusOpen, setIsChangeStatusOpen] = useState(false);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    all: 1,
    id: props.client_id,
    orderByField: '',
    balanceOrder: '',
    invoiceSeq: '',
    status: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const { trigger: postDocExcel } = usePostMethod();

  useEffect(() => {
    dispatch(
      getOneBalance({
        page: 1,
        perpage: 10,
        all: 1,
        id: props.client_id,
        eaccount: storage.loginReducer.currentAccount.id,
      }),
    );
  }, [dispatch, props.client_id, storage.loginReducer.currentAccount.id]);

  const handleSearch = e => {
    e.preventDefault();
    dispatch(getOneBalance({ ...filters, page: 1 }));
  };

  const onCheckAll = checked => {
    if (checked) {
      const updatedSelection = storage.invoiceReducer.one_balance.detail;
      setSelectedRows(updatedSelection);

      const firstChecked = updatedSelection[0];
      setSelectedStatus(firstChecked.status.toLowerCase());
    } else {
      setSelectedRows([]);
      setSelectedStatus('');
    }
  };

  const onCheckItems = el => {
    const isSelected = selectedRows.some(item => item.id === el.id);
    let updatedSelection = [];

    if (isSelected) {
      updatedSelection = selectedRows.filter(item => item.id !== el.id);
    } else {
      updatedSelection = [...selectedRows, el];
    }
    setSelectedRows(updatedSelection);

    if (updatedSelection.length > 0) {
      const firstChecked = updatedSelection[0];
      setSelectedStatus(firstChecked.status.toLowerCase());
    } else {
      setSelectedStatus('');
    }
  };

  const onSuccessChangeStatus = () => {
    dispatch(
      getOneBalance({
        page: 1,
        perpage: 10,
        all: 1,
        id: props.client_id,
        eaccount: storage.loginReducer.currentAccount.id,
      }),
    );
    setIsChangeStatusOpen(false);
    setSelectedRows([]);
    setSelectedStatus('');
  };

  function isEveryEqual(array = [], propsToCompare = []) {
    if (array.length === 0) {
      return true;
    }

    const getValues = obj => propsToCompare.map(prop => obj[prop]);

    const allStatusRadicado = array.every(obj => obj.status === 'Radicado');
    if (allStatusRadicado) {
      return false;
    }

    return array.every((obj, _, array) => {
      const reference = getValues(array[0]);
      const currentValues = getValues(obj);
      return JSON.stringify(currentValues) === JSON.stringify(reference);
    });
  }

  const canSelectAll = useMemo(() => {
    return isEveryEqual(storage.invoiceReducer.one_balance.detail, ['status']);
  }, [storage.invoiceReducer.one_balance.detail]);

  const header = [
    <th key={1} className='text-center px-2'>
      <Form.Check
        type='checkbox'
        className='pt-1 ms-1'
        onChange={({ target }) => onCheckAll(target.checked)}
        disabled={!canSelectAll}
      />
    </th>,
    <th key={2} style={{ whiteSpace: 'nowrap' }} className='px-2 text-center'>
      No. Factura{' '}
    </th>,
    <th key={3} className='px-2 text-center'>
      Estado
    </th>,
    <th key={4} className='px-2 text-center'>
      Contrato
    </th>,
    <th key={5} style={{ whiteSpace: 'nowrap' }} className='px-2 text-center'>
      Fecha Factura
    </th>,
    <th key={6} style={{ whiteSpace: 'nowrap' }} className='px-2 text-center'>
      Fecha Radicación
    </th>,
    <th key={7} style={{ whiteSpace: 'nowrap' }} className='px-2 text-center'>
      Fecha Vencimiento
    </th>,
    <th key={8} style={{ whiteSpace: 'nowrap' }} className='px-2 text-end'>
      Valor de Factura
    </th>,
    <th key={9} style={{ whiteSpace: 'nowrap' }} className='px-2 text-end'>
      Abonos
    </th>,
    <th key={10} style={{ whiteSpace: 'nowrap' }} className='px-2 text-end'>
      Saldo de factura
    </th>,
    <th key={11} style={{ whiteSpace: 'nowrap' }} className='px-2 text-center'>
      Días de Vencimiento
    </th>,
    <th key={12} style={{ whiteSpace: 'nowrap' }} className='px-2 text-end'>
      Valor mora
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.one_balance.detail)) {
      table = storage.invoiceReducer.one_balance.detail.map((item, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-center px-2'>
              {(item.status === 'Persuasivo' ||
                item.status === 'Jurídico' ||
                item.status === 'Pre-Jurídico' ||
                item.status === 'Castigo') && (
                <Form.Check
                  type='checkbox'
                  className='pt-1 ms-1'
                  checked={selectedRows.some(el => el.id === item.id)}
                  onChange={() => onCheckItems(item)}
                  disabled={selectedStatus.length && item.status?.toLowerCase() !== selectedStatus}
                />
              )}
            </td>
            <td className='text-uppercase px-2'>{item.sequence}</td>
            <td className='text-center px-2'>
              <div
                className='rounded-pill p-1'
                style={{ backgroundColor: `${item.background}`, whiteSpace: 'nowrap' }}
              >
                <b style={{ color: `${item.fontcolor}` }}>{item.status}</b>
              </div>
            </td>
            <td className='px-2'>{item.contract_type}</td>
            <td className='px-2'>
              {moment(item?.date).isValid()
                ? moment(item?.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='px-2'>
              {moment(item?.filing_date).isValid()
                ? moment(item?.filing_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='px-2'>
              {moment(item?.due_date).isValid()
                ? moment(item?.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                : '-'}
            </td>
            <td className='text-end px-2'>{convertMoneyFormat(item.amount)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(item.payment)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(item.pending_amount)}</td>
            <td className='px-2'>{item.expiration_days}</td>
            <td className='text-end px-2'>{convertMoneyFormat(item.amountArrears)}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const optionsBalance = [
    { value: '', label: 'Seleccionar...' },
    { value: 'DESC', label: 'Mayor a menor' },
    { value: 'ASC', label: 'Menor a mayor' },
  ];

  const optionsStatus = [
    { value: '', label: 'Seleccionar...' },
    { value: 'filed', label: 'Radicado' },
    { value: 'unfiled', label: 'Sin radicar' },
    { value: 'current', label: 'Corriente' },
    { value: 'returned', label: 'Devuelta' },
    { value: 'objected', label: 'Glosada' },
    { value: 'persuasive.', label: 'Persuasivo' },
    { value: 'pre-juridical', label: 'Pre-Jurídico' },
    { value: 'juridical', label: 'Jurídico' },
    { value: 'punishment', label: 'Castigo de cartera' },
  ];

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/balance/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        consultType: 'company_balance',
        all: '1',
        id: props.client_id,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const render = () => {
    return (
      <div className='container-fluid px-5 mt-5'>
        <div className='d-flex'>
          <img
            className={`mr-3 cursorPointer`}
            src={iconBack}
            onClick={() => props.setDetails({ show: false })}
            alt=''
          />

          {!!storage.invoiceReducer.loading_get_one_balance && loader}
          <h1 className={tableStyles.title} style={{ margin: '0px' }}>
            Saldo empresa
          </h1>
        </div>

        <div className='bg-inputs row mx-0 gy-2'>
          <div className='col-lg-5'>
            <small className='text-primary ms-1'>Empresa</small>
            <input
              type='text'
              className='register-inputs'
              value={
                storage.invoiceReducer.one_balance ? storage.invoiceReducer.one_balance.client : ''
              }
              disabled
            />
          </div>
          <div className='col-lg-3'>
            <small className='text-primary ms-1'>Valor de anticipo</small>
            <input
              className='register-inputs'
              type='text'
              value={convertMoneyFormat(storage.invoiceReducer.one_balance?.anticipated)}
              disabled
            />
          </div>
          <div className='col-lg-4'>
            <label>
              <input
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                type='checkbox'
              />
              <small className='text-muted ms-2'>Mostrar facturas saldadas</small>
            </label>
            <label>
              <input
                className='border border-dark-blue form-check-input p1 check-dark-blue'
                type='checkbox'
              />
              <small className='text-muted ms-2'>Exportar con datos de pacientes</small>
            </label>
          </div>
          <div className='col-lg-3'>
            <small className='text-primary ms-1'>Saldo de cartera</small>
            <input
              className='register-inputs'
              type='text'
              value={convertMoneyFormat(storage.invoiceReducer.one_balance?.sum_pending)}
              disabled
            />
          </div>

          <div className='col-lg-3' style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
            <small className='text-primary ms-1'>Valor vencido</small>
            <input
              className='register-inputs'
              type='text'
              value={convertMoneyFormat(storage.invoiceReducer.one_balance?.expired)}
              disabled
            />
          </div>

          <div className='col-lg-3'>
            <small className='text-primary ms-1'>Valor por vencer</small>
            <input
              className='register-inputs'
              style={{ background: '#F5F7FA', fontSize: '13px' }}
              type='text'
              value={convertMoneyFormat(storage.invoiceReducer.one_balance?.not_due)}
              disabled
            />
          </div>

          <div className='col-lg-3'>
            <small className='text-primary ms-1'>Valor sin legalizar</small>
            <input
              className='register-inputs'
              style={{ background: '#F5F7FA', fontSize: '13px' }}
              type='text'
              value={convertMoneyFormat(storage.invoiceReducer.one_balance?.amountNotLegalized)}
              disabled
            />
          </div>
        </div>

        <form onSubmit={e => handleSearch(e)} className='row align-items-end'>
          <div className='col-lg-2'>
            <label className={tableStyles.stylesLabel}>No. Factura</label>
            <input
              className={IndividualStyles.registerInputs}
              type='text'
              value={filters.invoiceSeq}
              style={{ color: '#58595B' }}
              onChange={e =>
                setFilters({
                  ...filters,
                  invoiceSeq: e.target.value,
                })
              }
              placeholder={'Escribe...'}
            ></input>
          </div>

          <div className='col-lg-2'>
            <label className={tableStyles.stylesLabel}>Saldo de factura</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsBalance}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters({
                  ...filters,
                  balanceOrder: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_client'
            />
          </div>

          <div className='col-lg-2'>
            <label className={tableStyles.stylesLabel}>Estado</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsStatus}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters({
                  ...filters,
                  status: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_client'
            />
          </div>

          <div className='col d-flex justify-content-end'>
            <button type='submit'>
              <img src={Lupa} alt='' />
            </button>
            <button className='ms-3' onClick={() => downloadExcelDoc()}>
              <img src={Excel} alt='' />
            </button>
          </div>

          <div className='text-end' style={{ marginLeft: 'auto' }}></div>
        </form>

        <div className='table-responsive mb-2'>
          <GenericTable headers={header} dark={true}>
            {renderList()}
          </GenericTable>
        </div>

        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.invoiceReducer.one_balance ? filters.page : ''}
              {' de '}
              {Math.ceil(storage.invoiceReducer?.one_balance_total / filters.perpage)
                ? Math.ceil(storage.invoiceReducer.one_balance_total / filters.perpage)
                : ''}{' '}
              ({storage.invoiceReducer.one_balance_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.invoiceReducer.one_balance_total}
              pageRangeDisplayed={5}
              onChange={e => {
                setFilters({ ...filters, page: e });
                dispatch(getOneBalance({ ...filters, page: e }));
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>

        <div className='pb-4 d-flex justify-content-end'>
          <button
            className={`${tableStyles.btnSecondary} me-3`}
            onClick={() => {
              setSelectedRows([]);
              setSelectedStatus('');
            }}
            disabled={selectedRows.length < 1}
          >
            Cancelar
          </button>
          <button
            className={`${tableStyles.btnPrimary} px-3 me-3`}
            onClick={() => setIsChangeStatusOpen(true)}
            disabled={selectedRows.length < 1 || selectedStatus === 'castigo'}
          >
            {selectedStatus === 'persuasivo' && 'Cambiar a pre-jurídico'}
            {selectedStatus === 'pre-jurídico' && 'Cambiar a jurídico'}
            {selectedStatus === 'jurídico' && 'Cambiar a castigo'}
            {(selectedStatus === '' || selectedStatus === 'castigo') && 'Cambiar estado'}
          </button>
          <button
            className={tableStyles.btnPrimaryAlt}
            onClick={() => setshowReceipt({ show: true })}
            disabled={selectedRows.length < 1 || selectedStatus === 'persuasivo'}
          >
            Cobrar
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {showReceipt.show ? (
        <PrelegalCollection
          invoices={selectedRows}
          show={showReceipt.show}
          setshowReceipt={setshowReceipt}
          setDetails={props.setDetails}
          currentStatus={selectedStatus}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        render()
      )}
      <ChangeStatus
        isOpen={isChangeStatusOpen}
        onClose={() => setIsChangeStatusOpen(false)}
        currentStatus={selectedStatus}
        nextStatus={statusConversion[selectedStatus]}
        selectedRows={selectedRows}
        onSuccess={onSuccessChangeStatus}
      />
    </>
  );
}
export default CompanyBalance;
