import { useState } from 'react';

//styles
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';

//hooks
import { useLocation } from 'react-router';

//components
import { Col, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from './../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { CanceledOrders, CanceledProviders } from '.';

export const GeneralsOrdersCanceled = () => {
  const location = useLocation();
  const [newOrdenActive, setNewOrdenActive] = useState(false);
  const locationData = location?.state?.data?.tabActive || 'CanceledOrders';
  const [tabActive, setTabActive] = useState(locationData ? 'CanceledOrders' : 'CanceledProviders');

  return (
    <OrdGenericTemplate className=' mt-4 mx-2' title={'Órdenes anuladas'} titleSize={12}>
      <div
        className='asistencialDateColorIcon'
        style={{ width: '94%' }}
        onClick={() => {
          newOrdenActive && setNewOrdenActive(false);
        }}
      >
        <Row className={tableStyles.rowTabStyle}>
          <Col
            xs={2}
            className={`${tabActive === 'CanceledOrders' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
            onClick={() => setTabActive('CanceledOrders')}
          >
            <span>Servicios</span>
          </Col>

          <Col
            xs={2}
            className={`${tabActive === 'CanceledProviders' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
            onClick={() => setTabActive('CanceledProviders')}
          >
            <span>Proveedor</span>
          </Col>

          <Col xs={8} style={{ borderBottom: '1px solid #00B4CC' }}></Col>
        </Row>

        {tabActive === 'CanceledOrders' && <CanceledOrders />}

        {tabActive === 'CanceledProviders' && <CanceledProviders />}
      </div>
    </OrdGenericTemplate>
  );
};
