import { useDispatch, useSelector } from "react-redux"
import { onSetActiveEvent } from "../actions/onSetActiveEvent"
import { onSetEvents } from "../actions/onSetEvents"

const useCalendarStore = () => {

    const { events, activeEvent } = useSelector(state => state.calendarReducer)

    const dispatch = useDispatch()

    const setEvents = (event) => {
        dispatch(onSetEvents(event))
    }

    const setActiveEvent = (event) => {
        dispatch(onSetActiveEvent(event))
    }


    return {
        events,
        activeEvent,
        setEvents,
        setActiveEvent,
    }
}

export default useCalendarStore