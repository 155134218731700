import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  convertBase64,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
  today,
} from '../../../../helpers/helpers';
import { customSelectMulti, customSelectNew } from '../../../Layouts/react-select-custom';
import Adjuntar from '../../../../assets/img/icons/Adjuntar.svg';
import Close from '../../../../assets/img/icons/cleanCloseIcon.svg';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToLatinFormat } from '../../../../helpers/convertDateFormat';
import { getCandidateList, registerComplaint } from '../../../../actions/partnersActions';
import Loader from 'react-loader-spinner';

export const RandComplaint = () => {
  const store = useSelector(state => state);
  const animatedComponents = makeAnimated();
  const [documents, setDocuments] = useState([]);
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const fullName =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;
  let id_candidate = store?.loginReducer?.user_data?.payrollInfo?.id_candidate;
  const jobTitle = store.loginReducer?.user_data?.payrollInfo?.job_title;
  const jobId = store.loginReducer?.user_data?.payrollInfo?.id_jobPosition;
  const areaName = store.loginReducer?.user_data?.payrollInfo?.area_name;
  const arrEmployees = store.partnersReducer.employee_list;
  const token = store.loginReducer.Authorization;
  const areaId = store.loginReducer?.user_data?.payrollInfo?.id_area;
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();
  const formattedEmployees = formatToRcSelect(
    arrEmployees,
    'id_candidate',
    'full_name',
    'id_contract',
    'job_title',
    '',
    false,
  );
  const [allowSend, setallowSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCurrentColabs] = useState();
  const [trigger, setTrigger] = useState(0);
  useEffect(() => {
    if (
      !isEmptyOrUndefined(inputs.type) &&
      !isEmptyOrUndefined(inputs.concept) &&
      !isEmptyOrUndefined(inputs.description) &&
      documents.length > 0 &&
      !isEmptyOrUndefined(inputs.involved)
    ) {
      setallowSend(true);
    } else {
      setallowSend(false);
    }
  }, [inputs, documents]);
  useEffect(() => {
    dispatch(
      getCandidateList({
        status: 'current',
        filtered: 1,
        eaccount: idEnterprise,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async () => {
    setIsLoading(true);
    if (documents.length <= 0) {
      setIsLoading(false);
      return message('warning', 'Advertencia', 'Debe cargarse al menos un documento');
    }
    try {
      let result = await registerComplaint(
        {
          type: inputs.type,
          area_id: areaId,
          area_name: areaName,
          reporter: id_candidate,
          report_date: today(),
          title: inputs.concept,
          description: inputs.description,
          entity_account: idEnterprise,
          created_by: idAccount,
          status: 'pending',
          result_type: null,
          result_justification: null,
          result_by: null,
          job_position_id: jobId,
          date: inputs.date,
          employees_improvement: inputs.involved,
          // employees_improvement: [1, 2, 4,24,34,6,766,86],
          files: documents,
        },
        token,
      );

      if (result.success) {
        setIsLoading(false);
        setInputs({});
        setDocuments([]);
        setTrigger(trigger + 1);
        return message('success', 'Éxito', 'Reporte/Queja registrado exitosamente');
      }
    } catch (error) {
      setIsLoading(false);
      return console.error(error);
    }
  };
  /* ----------------------------- HANDLE CHANGES ----------------------------- */

  const onChangeDoc = async e => {
    const file = e.target.files[0];

    const validExtensions = [
      'zip',
      'ZIP',
      'pdf',
      'PDF',
      'rar',
      'RAR',
      'gif',
      'GIF',
      'jpeg',
      'png',
      'swf',
      'psd',
      'bmp',
      'tiff',
      'jpc',
      'jp2',
      'jpf',
      'jb2',
      'swc',
      'aiff',
      'wbmp',
      'xbm',
      'jpg',
      'mp4',
      'mov',
      'mpg',
      'flv',
      '3gp',
      'webm',
      'wmv',
      'xlsx',
      'xls',
    ];

    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = validExtensions.find(obj => obj === actFileExt[actFileExt.length - 1]);
      if (isEmptyOrUndefined(extension)) {
        e.target.value = '';
        return message(
          'warning',
          'Advertencia!',
          `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
        );
      }

      if (file.size > 250000000) {
        e.target.value = '';
        return message(
          'warning',
          'Advertencia',
          'Su archivo no puede tener un tamaño superior a 25MB',
        );
      } else {
        if (file.size <= 0) {
          e.target.value = '';
          return message('warning', 'Advertencia', 'Por favor, adjunte un archivo valido.');
        }
      }
      if (!isEmptyOrUndefined(file)) {
        // setSelectedFile(file);
      }
    } else {
      return console.error('Archivo vacio: ', file);
    }
    /* ------------------------------------ x ----------------------------------- */
    let f = e.target.files[0];
    let name_doc = f.name.split('.')[0];
    let type_doc = f.type;
    let encode = await convertBase64(e.target.files[0]);
    let base64 = encode.split(',')[1];
    let ext = type_doc.split('/');
    let finalName = `${name_doc}.${ext[1]}`;
    if (documents.find(x => x.base64 === base64)) {
      return message('warning', 'Archivo duplicado', 'Este archivo ya fue cargado');
    }

    setDocuments([
      ...documents,
      {
        filename: finalName,
        base64: base64,
        route: inputs.type,
      },
    ]);
  };
  const handleRemove = elem => {
    let newArr = documents.filter(x => x.base64 !== elem.base64);

    setDocuments(newArr);
  };
  const handleChangeColabs = e => {
    setCurrentColabs(e);
    let arrIdEnd = [];

    if (e.length > 0) {
      e.forEach(elem => {
        arrIdEnd.push(elem.value);
      });
    }
    setInputs({ ...inputs, involved: arrIdEnd });
  };
  return (
    <Row>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <Col className={`mt-1 rounded `} xs={7}>
        <h6 className={`${tableStyles.darkBlueText} mt-3`}>
          <b>• Registro de reporte/queja</b>
        </h6>
        <div className={`${tableStyles.bgColorClearBlue} ${tableStyles.bdRadiusZeroEight} p-2`}>
          {/* FIRST ROW */}
          <Row>
            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>Tipo</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setInputs({ ...inputs, type: e.value })}
                options={[
                  { value: 'complaint', label: 'Queja' },
                  { value: 'report', label: 'Reporte' },
                ]}
                key={`type ${trigger}`}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectNew}
              ></Select>
            </Col>
            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Reportante
                <span className={'text-warning'}>*</span>
              </p>
              <input
                value={fullName}
                disabled
                className='register-inputs text-secondary fw-bold '
              />
            </Col>
            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Cargo
                <span className={'text-warning'}>*</span>
              </p>
              <input
                value={jobTitle}
                disabled
                className='register-inputs text-secondary fw-bold '
              />
            </Col>
          </Row>
          {/* SECOND ROW */}
          <Row>
            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>Fecha</p>
              <input
                value={convertDateToLatinFormat(today())}
                disabled={true}
                min={today()}
                className='register-inputs text-secondary fw-bold '
              />
            </Col>

            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Área
                <span className={'text-warning'}>*</span>
              </p>
              <input
                value={areaName}
                disabled={true}
                className='register-inputs text-secondary fw-bold '
              />
            </Col>

            <Col xs={4}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Concepto
                <span className={'text-warning'}>*</span>
              </p>
              <input
                key={` Concept${trigger}`}
                value={inputs.concept}
                onChange={e => setInputs({ ...inputs, concept: e.target.value })}
                className='register-inputs text-secondary fw-bold '
              />
            </Col>
          </Row>
          {/* THIRD ROW */}
          <Row>
            <h6 className={`${tableStyles.darkBlueText} mt-4`}>
              <b>• Descripción</b>
            </h6>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Descripción de la situación
                <span className={'text-warning'}>*</span>
              </p>
              <textarea
                key={`description ${trigger}`}
                value={inputs.description}
                onChange={e => setInputs({ ...inputs, description: e.target.value })}
                placeholder={'Escribir...'}
                id='txtArea'
                rows='10'
                cols='10'
                style={{ minHeight: '5rem' }}
                className={`register-inputs text-secondary`}
              ></textarea>
            </Col>
          </Row>
          {/* FOURTH ROW */}
          <Row>
            <Col xs={12}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Colaboradores implicados
                <span className={'text-warning'}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                isMulti
                key={`Multi ${trigger}`}
                // value={inputs.employees_improvement}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={e => handleChangeColabs(e)}
                options={formattedEmployees}
                className='text-secondary'
                placeholder={'Seleccione'}
                styles={customSelectMulti}
              ></Select>
            </Col>
          </Row>{' '}
          {/* FIFTH ROW */}
          <h6 className={`${tableStyles.darkBlueText} mt-4`}>
            <b>• Evidencias</b>
          </h6>
          <Row style={{ width: '80%' }}>
            <Col xs={12}>
              <div className='display-grid'>
                <label className={`${tableStyles.crudModalLabel} m-0`}>
                  Anexos
                  <span className='text-warning'>*</span>
                </label>
              </div>
              <div
                style={{
                  height: '5rem',
                  width: '55%',
                  backgroundColor: '#ffffff',
                }}
                // className="bg-light"
              >
                <Dropzone key={` dropzone${trigger}`}>
                  {({ getRootProps, getInputProps }) => (
                    <section
                      className='w-100 h-100 p-1 text-center mx-auto cursorPointer'
                      style={{
                        border: 'solid 1px #005DBF',
                        borderRadius: '10px',
                      }}
                    >
                      <div {...getRootProps()} className='d-flex justify-content-between mt-4'>
                        <input
                          {...getInputProps()}
                          type='file'
                          key={`file ${trigger}`}
                          name='documents'
                          onChange={e => onChangeDoc(e)}
                          multiple={false}
                        />

                        <p className={`text-muted m-0 m-auto text-center  mt-2 hoverPointer`}>
                          {'Soltar archivo aquí'}
                        </p>

                        {<img src={Adjuntar} alt='añadir' className='pt-3' />}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </Col>
          </Row>
          {/* SISXTH ROW */}
          <Row style={{ width: '80%' }} className={`mt-4`}>
            <Col xs={12} className=' d-flex'>
              {documents.length > 0 &&
                documents.map((e, i) => {
                  return (
                    <div key={'container' + i + trigger} className='d-flex justify-content-between'>
                      <label key={'label' + i} className={`${tableStyles.crudModalLabel} m-0`}>
                        <img
                          alt=''
                          key={'close' + i}
                          onClick={() => handleRemove(e)}
                          className={`cursorPointer `}
                          src={Close}
                        ></img>
                        &nbsp;
                        <u style={{ marginRight: '1rem' }}>{e.filename} </u>
                      </label>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </div>

        <div className={`customTabs__Footer mt-2 p-0`}>
          <Button
            className={tableStyles.btnPrimary}
            onClick={() => handleSubmit()}
            disabled={allowSend ? false : true}
          >
            &nbsp; Enviar
          </Button>
        </div>
      </Col>
    </Row>
  );
};
