import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { count_users } from '../../actions/userActions';

import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import '../Layouts/customTabs.scss';

function GestionUsers() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(count_users());
  }, [dispatch]);

  return (
    <div className={`p-0 ${tableStyles.mainRow}`}>
      <div className='customTabs__Border customTabs__NumberDisplay'>
        <h2 className='customTabs__Subtitle'>Total de Usuarios</h2>
        <h3 className='customTabs__Number'>{counter.userReducer.countusers?.totalusers}</h3>
      </div>
      <div className='customTabs__Border customTabs__NumberDisplay customTabs__NumberDisplay--mid'>
        <h2 className='customTabs__Subtitle'>Usuarios Activos</h2>
        <h3 className='customTabs__Number'>{counter.userReducer.countusers?.total_active}</h3>
      </div>
      <div className='customTabs__Border customTabs__NumberDisplay'>
        <h2 className='customTabs__Subtitle'>Usuarios Inactivos</h2>
        <h3 className='customTabs__Number'>
          {counter.userReducer.countusers
            ? counter.userReducer.countusers?.totalusers -
              counter.userReducer.countusers?.total_active
            : ''}
        </h3>
      </div>
    </div>
  );
}
export default GestionUsers;
