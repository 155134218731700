import { Col, Row, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import backArrow from '../../../assets/img/icons/iconBack.svg';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loader, message } from '../../../helpers/helpers';
import { usePostMethod } from '../../../Hooks/useFetch';
import CardComponent from './CardComponent/CardComponent';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';

const NewRol = () => {
  const location = useLocation();
  const store = useSelector(state => state);
  const history = useHistory();
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const modulesInfo = location?.state?.data ?? {};
  const dataEdit = location?.state?.dataEdit ?? {};
  const isEditing = location?.state?.isEditing ?? false;
  const idProfile = location?.state?.idProfile;
  const { load: createRolLoader, trigger: createRolMethod } = usePostMethod();
  const goBackFunction = () => {
    history.push({
      state: { data: modulesInfo, idProfile: idProfile, isEditingAgain: isEditing },
      pathname: '/administracion/GestionDePermisos/NuevoRol',
    });
  };
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'gespermiss',
  );
  const [data, setData] = useState({
    rolName: '',
    modules: [],
  });
  const permissions = [
    { label: 'Crear', value: 'create' },
    { label: 'Leer', value: 'read' },
    { label: 'Editar', value: 'edit' },
    { label: 'Eliminar', value: 'delete' },
    { label: 'Aprobar', value: 'approve' },
    { label: 'Rechazar', value: 'reject' },
  ];
  useEffect(() => {
    if (!Object.keys(modulesInfo).length) {
      history.push('/administracion/GestionDePermisos/');
    }
    if (!myPermission?.read) {
      history.push('/categoryselect');
    }
    if (modulesInfo?.modules?.length > 0) {
      let tempArr = [];
      modulesInfo?.modules?.forEach(mod => {
        tempArr.push({ idModule: mod.id, sections: [] });
        setData({ rolName: modulesInfo.rolName, modules: tempArr });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEnterprise]);

  useEffect(() => {
    if (isEditing) {
      let tempArr = [];

      modulesInfo.modules.forEach(mod => {
        if (dataEdit.some(x => x.id === mod.id)) {
          let tempArrModule = [];
          dataEdit.forEach(m => {
            let tempArrSections = [];
            m.sections.forEach(s => {
              let tempArrPage = [];
              s.pages.forEach(p => {
                tempArrPage.push({
                  idPage: p.idPage,
                  read: p.permissions.read,
                  create: p.permissions.create,
                  edit: p.permissions.edit,
                  delete: p.permissions.delete,
                  approve: p.permissions.approve,
                  reject: p.permissions.reject,
                });
              });
              tempArrSections.push({ idSection: s.idSection, pages: tempArrPage });
            });
            tempArrModule.push({ idModule: m.id, sections: tempArrSections });
          });

          tempArr.push(tempArrModule.find(x => x.idModule === mod.id));
        } else {
          tempArr.push({ idModule: mod.id, sections: [] });
        }
      });
      setData({ ...data, modules: tempArr, rolName: modulesInfo.rolName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCheckBox = (e, value, elem) => {
    let tempArr = [...data.modules];
    let selectedModule = data?.modules?.find(e => e.idModule === elem.id);
    let selectedModuleIndex = data?.modules?.findIndex(e => e.idModule === elem.id);
    if (e.target.checked) {
      selectedModule.sections = [
        ...selectedModule.sections,
        {
          idSection: value.idSection,
          pages: [],
        },
      ];
      tempArr[selectedModuleIndex] = selectedModule;
      setData({ ...data, modules: tempArr });
    } else {
      let filterArrSect = selectedModule?.sections?.filter(x => x.idSection !== value.idSection);
      tempArr[selectedModuleIndex].sections = filterArrSect;
      setData({ ...data, modules: tempArr });
    }
  };

  const onChangePage = (e, value, sec, elem, selectedPageIndex, per) => {
    let tempArr = [...data.modules];
    let selectedModule = data?.modules?.find(e => e.idModule === elem.id);
    let selectedModuleIndex = data?.modules?.findIndex(e => e.idModule === elem.id);
    let selectedSectionIndex = selectedModule?.sections?.findIndex(
      e => e.idSection === sec.idSection,
    );

    if (e.target.checked) {
      tempArr[selectedModuleIndex].sections[selectedSectionIndex].pages = [
        ...(tempArr[selectedModuleIndex]?.sections[selectedSectionIndex]?.pages ?? []),
        {
          idPage: value.idPage,
          read: 0,
          create: 0,
          edit: 0,
          delete: 0,
          approve: 0,
          reject: 0,
        },
      ];
      if (selectedPageIndex) {
        onChangePermission(e, value, sec, elem, per);
      }
      setData({ ...data, modules: tempArr });
    } else {
      let filterArrPage = tempArr[selectedModuleIndex]?.sections[
        selectedSectionIndex
      ]?.pages.filter(x => x.idPage !== value.idPage);
      tempArr[selectedModuleIndex].sections[selectedSectionIndex].pages = filterArrPage;
      setData({ ...data, modules: tempArr });
    }
  };
  const onChangePermission = (e, page, sec, elem, per) => {
    let tempArr = [...data.modules];
    let selectedModule = data?.modules?.find(e => e.idModule === elem.id);
    let selectedModuleIndex = data?.modules?.findIndex(e => e.idModule === elem.id);
    let selectedSection = selectedModule?.sections?.find(e => e.idSection === sec.idSection);
    let selectedSectionIndex = selectedModule?.sections?.findIndex(
      e => e.idSection === sec.idSection,
    );
    let selectedPageFind = selectedSection?.pages?.some(e => e.idPage === page.idPage);
    let selectedPageIndex = selectedSection?.pages?.findIndex(e => e.idPage === page.idPage);
    if (e.target.checked) {
      if (selectedPageFind) {
        tempArr[selectedModuleIndex].sections[selectedSectionIndex].pages[selectedPageIndex][
          per.value
        ] = 1;
      } else {
        onChangePage(e, page, sec, elem, selectedPageIndex, per);
      }
      setData({ ...data, modules: tempArr });
    } else {
      tempArr[selectedModuleIndex].sections[selectedSectionIndex].pages[selectedPageIndex][
        per.value
      ] = 0;
      setData({ ...data, modules: tempArr });
    }
  };

  const createRol = method => {
    let tempArr = [];
    let status = true;
    data.modules.forEach(mod => {
      let moduleObject = modulesInfo?.modules.find(x => x.id === mod.idModule);
      if (mod.sections.length > 0) {
        mod.sections.forEach(section => {
          let sectionObject = moduleObject?.sections.find(x => x.idSection === section.idSection);
          if (section.pages.length > 0) {
            section.pages.forEach(page => {
              let pageObject = sectionObject?.pages.find(x => x.idPage === page.idPage);
              let permissionsFilter = [
                page.read,
                page.create,
                page.edit,
                page.delete,
                page.approve,
                page.reject,
              ];
              if (permissionsFilter.some(x => x === 1)) {
                tempArr.push({
                  idPage: page.idPage,
                  permissions: {
                    read: page.read,
                    create: page.create,
                    edit: page.edit,
                    delete: page.delete,
                    approve: page.approve,
                    reject: page.reject,
                  },
                });
              } else {
                message(
                  'info',
                  'Verificar módulos ',
                  `Verifique que en el módulo <b>'${moduleObject.name}'</b>, en la sección <b>'${sectionObject.nameSection}'</b>, en la página <b>'${pageObject.namePage}'</b> tenga asociado permisos`,
                  undefined,
                  true,
                );
                status = false;
              }
            });
          } else {
            message(
              'info',
              'Verificar módulos ',
              `Verifique que en el módulo <b>'${moduleObject.name}'</b>, en la sección <b>'${sectionObject.nameSection}'</b>  tenga asociado como mínimo una página con sus respectivos permisos`,
              undefined,
              true,
            );
            status = false;
          }
        });
      } else {
        message(
          'info',
          'Verificar módulos ',
          `Verifique que el módulo <b>'${moduleObject.name}'</b> tenga asociado como mínimo una sección y página con sus respectivos permisos`,
          undefined,
          true,
        );
        status = false;
      }
    });

    let body = {
      rolName: data.rolName,
      pages: tempArr,
      eaccount: idEnterprise,
    };

    if (method === 'edit') {
      body.idRol = idProfile;
    }

    if (status) {
      createRolMethod({
        url: '/security/permissions_by_profile/',
        method: isEditing ? 'PUT' : 'POST',
        body: body,
        token: token,
        succesAction: info => {
          handleBack();
          customSwaltAlert({
            icon: 'success',
            title: info.title,
            text: info.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterException: error => {
          customSwaltAlert({
            icon: 'error',
            title: 'Intenta de nuevo',
            text: error.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    }
  };

  const handleBack = () => {
    history.push('/administracion/GestionDePermisos');
  };

  const bodyCard = elem => {
    return (
      <>
        <Row>
          <Col xs={4}></Col>
          <Col xs={8} className=' d-flex text-center'>
            {permissions.map((per, key) => {
              return (
                <Col key={key} xs={2}>
                  <p className={`${tableStyles.crudModalLabel} `}>{per.label}</p>
                </Col>
              );
            })}
          </Col>
        </Row>
        {elem?.sections?.length > 0 &&
          elem?.sections?.map((sec, key) => {
            let isSelected = data?.modules
              ?.find(e => e?.idModule === elem?.id)
              ?.sections?.some(x => x?.idSection === sec?.idSection);
            return (
              <Row key={key}>
                <Col xs={12}>
                  <div className='d-flex gap-2'>
                    <input
                      checked={isSelected}
                      id={`checkBox-${sec.idSection}`}
                      key={`keyCheckbox-${sec.idSection}`}
                      onChange={e => onChangeCheckBox(e, sec, elem)}
                      className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                      type='checkbox'
                      style={{ width: '16px', height: '16px' }}
                    />
                    <span className={`${tableStyles.darkBlueDarker} ${tableStyles.f12} mt-1`}>
                      {sec.nameSection}
                    </span>
                  </div>
                  {isSelected &&
                    sec.pages.length > 0 &&
                    sec.pages.map((page, key) => {
                      let isSelectedPage = data?.modules
                        ?.find(e => e?.idModule === elem?.id)
                        ?.sections?.find(x => x?.idSection === sec?.idSection)
                        .pages.some(i => i?.idPage === page?.idPage);
                      return (
                        <Row key={key}>
                          <Col xs={4}>
                            <div className='d-flex gap-2 mx-4'>
                              <input
                                checked={isSelectedPage}
                                id={`checkBoxPage-${page.idPage}`}
                                key={`keyCheckboxPage-${page.idPage}`}
                                onChange={e => onChangePage(e, page, sec, elem)}
                                className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                                type='checkbox'
                                style={{ width: '16px', height: '16px' }}
                              />
                              <span
                                className={`${tableStyles.darkBlueDarker} ${tableStyles.f12} mt-1`}
                              >
                                {page.namePage}
                              </span>
                            </div>
                          </Col>
                          <Col xs={8} className={`d-flex justify-content-center text-center`}>
                            {permissions.map((per, key) => {
                              let isSelectedPermissionFind = data?.modules
                                ?.find(e => e?.idModule === elem?.id)
                                ?.sections?.find(x => x?.idSection === sec?.idSection)
                                ?.pages?.find(i => i?.idPage === page?.idPage);
                              let pagePermission = isSelectedPermissionFind?.[per.value];
                              return (
                                <Col xs={2} key={key}>
                                  <input
                                    checked={pagePermission === 1 ? true : false}
                                    id={`Permission-${page.idPage}`}
                                    key={`Permission-${page.idPage}`}
                                    onChange={e => onChangePermission(e, page, sec, elem, per)}
                                    className={`border border-dark-blue form-check-input p1 check-dark-blue`}
                                    type='checkbox'
                                    style={{ width: '16px', height: '16px' }}
                                  />
                                </Col>
                              );
                            })}
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            );
          })}
      </>
    );
  };

  return (
    <>
      {createRolLoader && loader}
      <div className='w-80 mx-4'>
        <Row>
          <Col xs={10}>
            <h1 className={`${tableStyles.title} d-flex`}>
              <img
                onClick={() => goBackFunction()}
                src={backArrow}
                height={32}
                width={32}
                alt='icon'
                className={`mr-3 cursorPointer`}
                style={{ marginTop: '10px' }}
              />
              <div className='flex-column'>
                <div>{isEditing ? 'Editar rol' : 'Nuevo rol'}</div>
                <div
                  className={`${tableStyles.f16} ${tableStyles.darkBlueText}  `}
                  style={{ fontWeight: 'normal ' }}
                >
                  Configuración de permisos y restricciones de los sub-módulos
                </div>
              </div>
            </h1>
          </Col>
        </Row>
        <div className='mx-4'>
          <Row className='my-4' style={{ marginLeft: '10px' }}>
            <div className='px-2 ml-2'>
              {modulesInfo?.modules?.length > 0 &&
                modulesInfo?.modules?.map((e, key) => {
                  return (
                    <CardComponent
                      key={key}
                      title={e.name}
                      subtitle={e.subtitle}
                      body={bodyCard(e)}
                    />
                  );
                })}
            </div>
          </Row>
          <Row>
            <div className={`customTabs__Footer mt-4 ml-1`}>
              <Button
                onClick={() => createRol(isEditing ? 'edit' : 'create')}
                className={tableStyles.btnPrimary}
              >
                {isEditing ? 'Editar' : 'Crear'}
              </Button>

              <Button
                variant='outline-primary'
                onClick={() => handleBack()}
                className={tableStyles.btnSecondary}
              >
                Cancelar
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default NewRol;
