import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import {
  GET_GENERAL_JOURNAL_REPORT,
  GET_GENERAL_BALANCE,
  GET_MAJOR_BALANCE_REPORT,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const journalReport = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_GENERAL_JOURNAL_REPORT,
    loadingJR: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/report/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;
      dispatch({
        type: GET_GENERAL_JOURNAL_REPORT,
        payload: results,
        total: row_count,
        loadingJR: false,
        dateReport: objFilters.dateReport,
        pagination: objFilters.pagination,
      });
    })
    .catch(err => {
      console.error(err.message);
      dispatch({
        type: GET_GENERAL_JOURNAL_REPORT,
        loadingJR: false,
      });
    });
};

export const generalReport = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_GENERAL_BALANCE,
    loadingGR: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/report/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;
      dispatch({
        type: GET_GENERAL_BALANCE,
        payload: results,
        total: row_count,
        loadingGR: false,
        dateReport2: objFilters.dateReport,
        pagination2: objFilters.pagination,
      });
    })
    .catch(err => {
      console.error(err.message);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Ocurrió un error al tratar de generar el informe`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

      dispatch({
        type: GET_GENERAL_BALANCE,
        loadingGR: false,
      });
    });
};
export const majorBalanceReport = (objFilters, doAfter) => (dispatch, getCreate) => {
  dispatch({
    type: GET_MAJOR_BALANCE_REPORT,
    loadingMB: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/report/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      doAfter();
      const { results, row_count } = res;
      dispatch({
        type: GET_MAJOR_BALANCE_REPORT,
        payload: results,
        total: row_count,
        loadingMB: false,
        dateReport3: objFilters.dateReport,
        pagination3: objFilters.pagination,
      });
    })
    .catch(err => {
      console.error(err.message);
      dispatch({
        type: GET_MAJOR_BALANCE_REPORT,
        loadingMB: false,
      });
    });
};

// ------------ ☘ Get info for auxiliary book ------------- //
export const getAuxiliaryBookInfo = async (params, token) => {
  const filters = convertFilterToString(params);
  const response = await fetch(`${URL_GATEWAY}${API_VERSION}/accounting/report/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  }).catch(err => {
    console.error(err);
  });
  if (response) {
    const data = await response.json();
    return data;
  } else {
    return null;
  }
};
// ------------ ☘ end info for auxiliary book ------------- //

// generate IncomeStatement report
export const generateIncomeStatementReport = async (params, token) => {
  if (!params.dateFrom || !params.dateUntil) {
    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: `Debe seleccionar el rango de fecha`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
    return { success: false };
  }
  const filters = convertFilterToString(params);
  const response = await fetch(`${URL_GATEWAY}${API_VERSION}/accounting/report/?${filters}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  });
  const data = await response.json();
  return data;
};
// end incomestatement report
