import moment from 'moment';
import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
//
import { useGetMethod } from '../../Hooks';
import GenericTableNew from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { convertMoneyFormat, customSwaltAlert, loader } from '../../helpers';

export default function InvoiceMovements() {
  const store = useSelector(state => state);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    status: null,
    eaccount: store.loginReducer.currentAccount.id,
    sequence: '',
  });
  const [inputs, setInputs] = useState({
    company: '',
    nit: '',
    confirmation: '',
  });
  const [invoiceMovements, setInvoiceMovements] = useState();

  const { trigger: getInvoiceMovements, load: invoiceMovementsLoad } = useGetMethod();

  let asterisk = <b style={{ color: '#FF8B00' }}>*</b>;

  const onGetInvoiceMovements = async function (sequence) {
    const result = await getInvoiceMovements({
      url: '/receivable/invoice/getMovements/',
      objFilters: { ...filters, sequence },
      token: store.loginReducer.Authorization,
    });
    if (!result.success) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: result.message,
        confirmButtonText: 'Aceptar',
      });
      setInputs({ company: '', nit: '', confirmation: '' });
      setInvoiceMovements(null);
      return;
    }
    setInputs({
      company: result?.results?.clientName,
      nit: result?.results?.clientNit,
      confirmation: result?.results?.clientNit?.slice(result?.results?.clientNit?.length - 1),
    });
    setInvoiceMovements(result?.results);
  };

  const onSubmitSearch = e => {
    e.preventDefault();
    const { search } = e.target;
    onGetInvoiceMovements(search.value);
  };

  const renderFilters = () => {
    return (
      <div className='row align-items-end'>
        <form onSubmit={onSubmitSearch} className='col-2'>
          <small className='text-primary ms-1'>No. factura</small>
          <input type='text' className='register-inputs' name='search' />
        </form>
        <div className='col-2'>
          <small className='text-primary ms-1'>Empresa</small>
          <input
            type='text'
            className='register-inputs'
            readOnly
            value={inputs.company}
            placeholder=''
          />
        </div>
        <div className='col-2'>
          <small className='text-primary ms-1'>NIT{asterisk}</small>
          <form className='d-flex'>
            <input
              type='text'
              className='register-inputs'
              readOnly
              defaultValue={inputs.nit}
              placeholder=''
            />
            <span className='text-muted'>—</span>
            <input
              type='text'
              className='register-inputs'
              style={{ width: 30 }}
              defaultValue={inputs.confirmation}
              readOnly
            />
          </form>
        </div>
      </div>
    );
  };
  const renderList = () => {
    let table = [{}];

    if (Array.isArray(invoiceMovements?.detail)) {
      table = invoiceMovements?.detail?.map((item, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-center px-2 '>
              {moment(item?.date, 'YYYY-MM-DD').format('DD/MM/YYYY') || '-'}
            </td>
            <td className='text-start px-2 ps-4'>{item?.doc_type || '-'}</td>
            <td className='text-center px-2 '>{item?.doc_number || '-'}</td>
            <td className='text-end px-2 '>{convertMoneyFormat(item?.amount) || '-'}</td>
            <td className='text-end px-2 '>
              {item?.amount - item?.balance === 0
                ? '-'
                : convertMoneyFormat(item?.amount - item?.balance)}
            </td>
            <td className='text-end px-2 '>{convertMoneyFormat(item?.balance) || '-'}</td>
          </tr>
        );
      });
    } else {
      table = [];
    }
    return table;
  };

  const renderTable = () => {
    const headers = [
      <th key={1} className='text-center  px-2'>
        Fecha
      </th>,
      <th key={2} className='text-start px-2 ps-4'>
        Tipo movimiento
      </th>,
      <th key={3} className='text-center px-2 '>
        No. documento
      </th>,
      <th key={4} className='text-end  px-2'>
        DEBE
      </th>,
      <th key={5} className='text-end  px-2'>
        HABER
      </th>,
      <th key={6} className='text-end  px-2 pe-3'>
        Saldo
      </th>,
    ];
    return (
      <GenericTableNew headers={headers} dark={true} shadow>
        {renderList()}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {invoiceMovementsLoad && loader}
        <div className='container'>
          <h1>Movimientos de Factura</h1>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(invoiceMovements?.row_count / filters.perpage)
                ? Math.ceil(invoiceMovements?.row_count / filters.perpage)
                : '1'}{' '}
              ({invoiceMovements?.row_count} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={invoiceMovements?.row_count}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </>
    );
  };

  return render();
}
