import styled from "styled-components";

// export const BoxTable = styled.div`
//   display: block;
//   /* max-width: 1400px; */
//   width: 90%;
//   margin: 30px 2%;
//   box-shadow: 0px 0px 8px #ccc;
//   border-top-right-radius: 15px;
//   border-top-left-radius: 15px;
//   overflow: hidden;
//   position: absolute;
//   background-color: #d6f3f7;
// `;

export const BoxHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  color: #00b4cc;
  font-weight: bold;
  background-color: #d6f3f7;
  padding: 5px 12px;
  border-bottom: 1px solid #00b4cc;
  text-align: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  > * {
    &:first-child {
      text-align: left;
    }
  }
`;

export const HeaderText = styled.div`
  width: 200px;
`;

export const BoxTableBody = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  /* text-align: center; */
  color: #697387;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #9bdee7;
  background-color: white;

  /* overflow: auto; */
`;

export const HeaderTextBody = styled.div`
  width: 200px;
`;

export const BoxColumnStatic = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const ChildrenColumnStatic = styled.div`
  color: #697387;
  font-size: 13px;
  font-weight: 500;
  background-color: white;
  border-bottom: 1px solid #9bdee7;
  padding: 12px 7px;
  /* 
  :hover {
    background-color: #d2dde8;
    transition: 0.3s all ease-in-out;
  } */
`;
export const BoxChildrenColumnBodyStatic = styled.div`
  display: grid;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 1px solid #9bdee7;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  background-color: white;
  height: 52px;
`;
export const ChildrenColumnBodyStatic = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 200px;
  height: 45px;
  padding: 6px 15px;
  align-items: center;
`;

export const ColumnBody = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const Div = styled.div`
  overflow: scroll;
`;

export const ColumStatic = styled.div`
  border-top-left-radius: 15px;
  > * {
    &:first-child {
      text-align: left;
      background-color: #d6f3f7;
      border-top-left-radius: 15px;
      color: #00b4cc;
      font-weight: bold;
      width: 25rem;

    }
  }
`;

export const BoxTable = styled.div`
  margin: 10px 0 40px 1.5rem !important;
  box-shadow: 0px 0px 8px #ccc;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  display: grid;
  grid-template-columns: 400px 1fr;
  /* height: 60vh; */
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccd9e6;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
    margin-top: 20px;
  }
  /* overflow:auto */

`;

export const ColumnDinamic = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 300px);
`;

export const BoxItemColumStatic = styled.div`
  /* margin-left: 270px; */
  /* overflow-x: auto; */

  /* max-height: 60vh; */
  border-top-right-radius: 15px;
  overflow-x: overlay;
  overflow-y: hidden;

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccd9e6;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
    margin-top: 20px;
  }
`;

export const ItemColumStatic = styled.div`
  /* padding: 4.9px; */
  /* border-bottom: 1px solid #9bdee7; */
  color: #6e6f7c;
`;

export const HeaderColumStatic = styled.div`
  padding: 10px;
  padding-left: 20px;
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 10px;
  width: 1900px;

`;

export const ItemColumDinamic = styled.div`
  padding: 10px;
  background-color: #d6f3f7;
  color: #00b4cc;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemColumDinamicBody = styled.div`
  /* padding: 8.9px; */
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #9bdee7;
  height: 40px;
  padding: 8px;
`;

export const ColumDinamicBody = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 300px);
`;

export const Input = styled.input`
  width: 120px;
  border: 1px solid #9bdee7;
  border-radius: 5px;
  caret-color: #9bdee7;
  outline: none;
  padding: 0 5px;
  border: 1px solid #9bdee7;
  color: #9bdee7;
`;

export const Alert = styled.div`
  font-weight: bold;
  padding: 15px;
  width: 80%;
  margin: 40px auto;
  background-color: #d6f3f7;
  text-align: center;
  box-shadow: 0px 0px 2px #ccc;
  border-radius: 5px;
  color: #1a538d;
`;

export const ButtonSubmit = styled.button`
  padding: 7px;
  background-color: #00b4cc;
  color: white;
  border-radius: 5px;
  margin-bottom: 30px;
  width: 140px;
  text-align: center;
`;

export const BoxButton = styled.div`
  padding: 10px 22px;
  background-color: white;
`;

export const BoxCloneTime = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
    border-bottom: 1px solid #9bdee7;
`;
