import { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
//
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import PopUp from '../../components/Popup/customPopUpExtends';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
//
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
//
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

import { Drawer } from '@material-ui/core';

export default function DetailedClassification() {
  const store = useSelector(state => state);

  const [detailedClass, setDetailedClass] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Clasificación detallada', className: 'col-5 text-start px-2' },
      { title: 'Clasificación general', className: 'col-4 text-start px-2' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
  });
  const [data, setData] = useState({
    nameClassificationDetail: '',
    stcId: null,
    createdBy: store.loginReducer.currentAccount.id,
  });

  const {
    load: detailedClassificationLoader,
    results: detailedClassificationResults,
    trigger: getDetailedClassification,
  } = useGetMethod();

  const {
    load: generalClassificationLoader,
    results: generalClassificationResults,
    trigger: getGeneralClassification,
  } = useGetMethod();
  const { load: servicesLoader, results: servicesResults, trigger: getServices } = useGetMethod();

  const { load: createDilatationServiceLoader, trigger: createDetailClassificationMethod } =
    usePostMethod();

  const { load: updateDilatationServiceLoader, trigger: updateDetailClassificationMethod } =
    usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getDetailedClassification({
        url: '/medical/clinicHistory/clasificationDetails/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [
    getDetailedClassification,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    createDilatationServiceLoader,
    updateDilatationServiceLoader,
    filters,
  ]);
  useEffect(() => {
    async function fetchAsyncData() {
      await getGeneralClassification({
        url: '/medical/clinicHistory/clasificationLevels/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, active: 1 },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [
    getGeneralClassification,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
  ]);
  useEffect(() => {
    async function fetchAsyncData() {
      await getServices({
        url: '/medical/generals/serviceType',
        objFilters: { eaccount: store.loginReducer.currentAccount.id },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [getServices, store.loginReducer.Authorization, store.loginReducer.currentAccount.id]);

  const servicesOptions = formatToRcSelect(
    servicesResults?.results,
    'serviceTypeId',
    'serviceType',
    '',
    '',
    '',
  );
  const generalsOptions = formatToRcSelect(
    generalClassificationResults?.results,
    'stcId',
    'nameClassification',
    '',
    '',
    '',
  );

  const onChangeServices = e => {
    let arrIdEnd = [];
    if (e.length > 0) {
      e.forEach(elem => {
        arrIdEnd.push(parseInt(elem.value));
      });
    }
    setData({ ...data, srvId: arrIdEnd });
  };
  const handleOpenEditModal = item => {
    setDetailedClass({
      ...detailedClass,
      showModal: true,
      isEditing: true,
    });
    setData({
      ...data,
      nameClassificationDetail: item?.nameClassificationDetail,
      srvId: item?.srvId,
      createdBy: store.loginReducer.currentAccount.id,
      stcId: item?.stcId,
      active: item?.active,
      decId: item?.decId,
    });
  };

  const handleCloseModal = () => {
    setDetailedClass({ ...detailedClass, showModal: false, isEditing: false });
    setData({
      nameClassificationDetail: '',
      srvId: [],
      createdBy: store.loginReducer.currentAccount.id,
      stcId: null,
    });
  };

  const handleCreateDetailClassification = async () => {
    await createDetailClassificationMethod({
      url: '/medical/clinicHistory/clasificationDetails/',
      method: 'POST',
      token: store.loginReducer.Authorization,
      body: data,
      succesAction: results => {
        handleCloseModal();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: results.title,
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleUpdateDetailClassification = async dataComing => {
    await updateDetailClassificationMethod({
      url: '/medical/clinicHistory/clasificationDetails/',
      method: 'PUT',
      token: store.loginReducer.Authorization,
      body: dataComing ? dataComing : data,
      succesAction: results => {
        handleCloseModal();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: 'Actualizado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleSubmit = async () => {
    if (data?.nameClassificationDetail && data.stcId && data.srvId) {
      if (detailedClass.isEditing) {
        await handleUpdateDetailClassification();
      } else {
        await handleCreateDetailClassification();
      }
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: `Intenta de nuevo`,
        text: `El nombre de la clasificación detallada es obligatorio`,
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const renderRow = row => {
    return (
      <tr key={row.id} className={`hover-table-row`}>
        <td className='text-start px-2 col-4'>{row?.nameClassificationDetail}</td>
        <td className='text-start px-2 col-5 '>{row?.nameClassification}</td>
        <td className='px-2 col-3'>
          {row?.active === 1 ? (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_true}>Habilitado</span>
            </div>
          ) : (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_false}>Deshabilitado</span>
            </div>
          )}
        </td>
        <td className='text-end'>
          <PopUp
            showEdit={true}
            showEnable={true}
            position='right'
            triggerSrc={ThreeDots}
            editClickEvent={() => handleOpenEditModal(row)}
            isEnabled={row.active === 1}
            enableText={row.active === 1 ? 'Deshabilitar' : 'Habilitar'}
            enableClickEvent={() => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: '¿Está seguro?',
                text: `Se ${row.active === 1 ? 'deshabilitará' : 'habilitará'} la clasificación detallada: ${row.nameClassificationDetail}`,
                confirmButtonText: 'Si, continuar',
              }).then(async rs => {
                if (rs.isConfirmed) {
                  await handleUpdateDetailClassification({
                    nameClassificationDetail: row?.nameClassificationDetail,
                    decId: row?.decId,
                    srvId: row?.srvId,
                    createdBy: store.loginReducer.currentAccount.id,
                    stcId: row?.stcId,
                    active: row.active === 1 ? 0 : 1,
                  });
                }
              });
            }}
          />
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={detailedClass.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: detailedClassificationResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDetailedClassification({
              url: '/medical/clinicHistory/clasificationDetails/',
              objFilters: { eaccount: store.loginReducer.currentAccount.id, page: e },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {detailedClassificationResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    return (
      <Drawer
        anchor={'right'}
        open={detailedClass.showModal}
        onClose={handleCloseModal}
        classes={{ width: '100%' }}
      >
        <div className={`${tableStyles.tlndrawer__header}`}>
          <button className={`${tableStyles.tlndrawer__close}`} onClick={handleCloseModal}></button>
          <div className={`${tableStyles.tlndrawer__title} w-100 text-start px-3`}>
            <span>{`${detailedClass.isEditing ? 'Editar' : 'Nueva'} clasificación detallada`}</span>
          </div>
        </div>
        <div className='d-flex flex-column ' style={{ height: '100vh' }}>
          <div className={`p-5 ${tableStyles.tlndrawer__content}`} style={{ width: '500px' }}>
            <Row>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Clasificación detallada</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput`}
                    type='text'
                    value={data?.nameClassificationDetail}
                    placeholder='Escribe...'
                    onChange={e => setData({ ...data, nameClassificationDetail: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Clasificación general</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={generalsOptions}
                    className='text-secondary'
                    value={generalsOptions.find(item => item.value === data.stcId)}
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setData({ ...data, stcId: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Servicios disponibles</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    isMulti
                    options={servicesOptions}
                    value={servicesOptions.filter(item => data?.srvId?.includes(item.value))}
                    className='text-secondary'
                    placeholder={'Seleccionar...'}
                    styles={ordCustomSelect}
                    onChange={e => onChangeServices(e)}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='px-5 py-3'>
            <Row>
              <Col xs={12}>
                <div className='text-end'>
                  <button
                    onClick={handleCloseModal}
                    className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
                  >
                    Cancelar
                  </button>
                  <button
                    className={`${tableStyles.ordBtnPrimary} btn`}
                    // disabled={validateInput()}
                    onClick={handleSubmit}
                  >
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    );
  };

  const render = () => {
    return (
      <>
        {generalClassificationLoader && loader}
        {detailedClassificationLoader && loader}
        {createDilatationServiceLoader && loader}
        {updateDilatationServiceLoader && loader}
        {servicesLoader && loader}
        {renderModal()}
        <div>
          <OrdGenericTemplate
            buttonNewLabel={'Nueva clasificación detallada'}
            buttonNewAction={() => {
              setDetailedClass({ ...detailedClass, showModal: true });
            }}
            searchWithNew={{
              colSize: '12',
              WSize: '100',
              searchWith: '50',
              onChange: e => {
                // e.preventDefault();
                setFilters({ ...filters, search: e.target.value });
              },
              buttonNewLabel: 'Nueva clasificación detallada',
              buttonNewAction: () => {
                setDetailedClass({ ...detailedClass, showModal: true });
              },
            }}
          >
            {renderTable()}
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
