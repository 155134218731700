/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReactToPrint from 'react-to-print';
import { getDetailSettlement } from '../../../actions/contractAction';
import backArrow from '../../../assets/img/icons/atras-icon.svg';
import iconPdf from '../../../assets/img/icons/pdfIcon.svg';
import { customSwaltAlert, formatteDocument } from '../../../helpers';
import {
  convertMoneyFormat,
  isEmptyOrUndefined,
  numberToWords,
  numberWithCommas,
} from '../../../helpers/helpers';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import './style.css';

export const Settlement = () => {
  const location = useLocation();
  const pdfRef = useRef(null);
  const [result, setresult] = useState(location.state?.result);
  const statusFinalized = location?.state?.statusFinalized;
  const contractInfo = location.state?.endContract?.data;
  const [isLoading] = useState(false);
  const [uniqueDeductions, setUniqueDeductions] = useState([]);
  const [totalDeductionsIncludingMiniPayroll, setTotalDeductionsIncludingMiniPayroll] = useState(0);
  const store = useSelector(state => state);
  const eaccount = store?.loginReducer?.currentAccount?.bussines_name;
  const nit = store?.loginReducer?.currentAccount?.nit;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const handleBack = () => {
    history.push('/nomina/contratos');
  };

  const depend_result = useMemo(() => ({ result }), [result]);

  useEffect(() => {
    if (statusFinalized) {
      getDetailsById();
    }
  }, []);

  const getDetailsById = async () => {
    const result = await getDetailSettlement({ contract: contractInfo?.contract_id }, token);
    if (result.success === false) {
      handleBack();
      return customSwaltAlert({
        icon: 'warning',
        title: 'Error',
        text: result?.message,
        showCancelButton: false,
      });
    }
    setresult(result);
  };

  useEffect(() => {
    if (result?.deductions.length > 0 || result?.miniPayroll?.deducted?.length > 0) {
      allDeduction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depend_result]);

  const allDeduction = () => {
    let allDeductionNames;
    let uniqueDeductionNames;

    allDeductionNames = [
      ...(result?.deductions?.map(deduction => deduction?.name.trim()) || []),
      ...(result?.miniPayroll?.deducted?.map(deducted => deducted.name_att.trim()) || []),
    ];

    uniqueDeductionNames =
      result?.miniPayroll?.deducted?.length > 0 || result?.deductions?.length > 0
        ? [...new Set(allDeductionNames)]
        : [];

    const tempArray = uniqueDeductionNames.map(name => {
      const deductionValue =
        result?.deductions?.find(deduction => deduction.name.trim() === name)?.value ||
        result?.miniPayroll?.deducted?.find(deducted => deducted.name_att.trim() === name)?.value;
      return { name, value: deductionValue };
    });

    setUniqueDeductions(tempArray);

    const tempTotalDeductionIncludingMiniPayroll = tempArray.reduce((accumulator, deduction) => {
      const value =
        typeof deduction.value === 'string' ? deduction.value : deduction.value.toString();
      return accumulator + parseFloat(value.replace(/,/g, '') || 0);
    }, 0);

    setTotalDeductionsIncludingMiniPayroll(tempTotalDeductionIncludingMiniPayroll);
  };

  const totalSettlementPayments =
    result?.settlement_payments?.length > 0
      ? result.settlement_payments.reduce((accumulator, payment) => {
          return accumulator + parseFloat(payment.value.replace(/,/g, '') || 0);
        }, 0)
      : 0;

  const totalAccruedInMiniPayroll =
    result?.miniPayroll?.accrued?.length > 0
      ? result.miniPayroll.accrued.reduce((accumulator, accrued) => {
          return accumulator + parseFloat(accrued.value.toString().replace(/,/g, '') || 0);
        }, 0)
      : 0;

  let totalPaymentsAndAccrued = totalSettlementPayments + totalAccruedInMiniPayroll;
  if (!isEmptyOrUndefined(result?.base_salary_accrued)) {
    totalPaymentsAndAccrued = totalPaymentsAndAccrued + result?.base_salary_accrued;
  }
  return (
    <div className='container'>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <Row>
        <Col xs={10}>
          <h1 className={tableStyles.title}>
            <img
              onClick={() => handleBack()}
              src={backArrow}
              className={`mr-2 mb-2 cursorPointer`}
              alt='icon'
            ></img>
            Detalle
          </h1>
        </Col>
        <Col className='text-end mr-2 mb-4' style={{ alignContent: 'end' }}>
          <ReactToPrint
            trigger={() => {
              return (
                <img
                  src={iconPdf}
                  alt='imprimir'
                  width='30'
                  className={`cursorPointer ${tableStyles.darkBlueText}`}
                />
              );
            }}
            content={() => pdfRef.current}
            documentTitle={`liquidacion_de_${(contractInfo?.candidate ?? '').split(' ').join('_').toLowerCase()}`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {result?.miniPayroll && Object.keys(result?.miniPayroll).length > 0 && (
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Nómina</b>
                </h6>
              </Row>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Días laborados</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {result?.miniPayroll?.daysPendingPayroll}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between '>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Devengado</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  ${result?.miniPayroll?.total_accrued}
                </Col>
              </Row>
              <hr className='m-0'></hr>

              <Row className='d-flex justify-content-between '>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Deducciones</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  ${result?.miniPayroll?.total_deducted}
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      <div className='w-100 container-fluid' style={{ margin: 0, padding: 0 }} ref={pdfRef}>
        <Row>
          <Col xs={10}>
            <h1 className={`${tableStyles.title} mt-2`}>
              Liquidación de contrato de trabajo {eaccount}
            </h1>
          </Col>
        </Row>
        {/* /* ----------------- SETTLEMENT EMPLOYEE - SETTLEMENT PERIOD ----------------- */}
        <Row>
          <Col xs={12}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Empleado a liquidar</b>
                </h6>
              </Row>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Nombre</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.candidate}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Documento de identidad</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {formatteDocument(contractInfo?.cand_docnumber).format}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Cargo</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.position}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between pb-3'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Causa de la liquidación</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {result?.liquidate_reason}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`} style={{ height: '100%' }}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Periodo de liquidación</b>
                </h6>
              </Row>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Fecha de inicio de contrato</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {contractInfo?.star_date}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Fecha de terminación de contrato</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {statusFinalized
                    ? moment(result?.end_date).format('DD/MM/YYYY')
                    : moment(location?.state?.endContractDate).format('DD/MM/YYYY')}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between pb-3'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Tiempo total laborado</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {result?.total_workdays + ' Días'}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Salario base de liquidación</b>
                </h6>
              </Row>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Sueldo básico</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {convertMoneyFormat(result?.base_salary)}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Auxilio de transporte</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {convertMoneyFormat(result?.aux_smmlv)}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between'>
                <Col className={`${tableStyles.darkGrayText} `} xs={6}>
                  <b>Total promedio salario variable</b>
                </Col>
                <Col className={`text-secondary text-end`} xs={6}>
                  {convertMoneyFormat(result?.total_average_aux_salary)}
                </Col>
              </Row>
              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between pb-2'>
                <Col className={`${tableStyles.darkBlueText} pt-3`} xs={6}>
                  <b>Total base de liquidación</b>
                </Col>
                <Col className={`${tableStyles.darkGrayText}  text-end pt-3`} xs={6}>
                  <b>{convertMoneyFormat(result?.total_base_settlement)}</b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* /* --------------------------- PAYMENT SETTLEMENT - DEDUCTIONS--------------------------- */}
        <Row className='mt-2'>
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Liquidación de pagos</b>
                </h6>
              </Row>
              <div style={{ maxWidth: '100%', msOverflowY: 'auto' }}>
                {result?.settlement_payments?.length > 0 &&
                  result?.settlement_payments?.map((settlement, index) => (
                    <React.Fragment key={index}>
                      <Row className='d-flex justify-content-between'>
                        <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                          <b>{settlement.name}</b>
                        </Col>
                        <Col className={`text-secondary text-end`} xs={6}>
                          {convertMoneyFormat(settlement.value)}
                        </Col>
                      </Row>
                      <hr className='m-0'></hr>
                    </React.Fragment>
                  ))}
                { !isEmptyOrUndefined(result?.base_salary_accrued) &&
                  <Row className='d-flex justify-content-between'>
                    <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                      <b>Salario base</b>
                    </Col>
                    <Col className={`text-secondary text-end`} xs={6}>
                      {convertMoneyFormat(result?.base_salary_accrued)}
                    </Col>
                  </Row>
                }
                <hr className='m-0'></hr>
                {result?.miniPayroll?.accrued?.length > 0 &&
                  result?.miniPayroll?.accrued?.map((accrued, index) => (
                    <React.Fragment key={index}>
                      <Row className='d-flex justify-content-between'>
                        <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                          <b>{accrued.name_att}</b>
                        </Col>
                        <Col className={`text-secondary text-end`} xs={6}>
                          {convertMoneyFormat(accrued.value)}
                        </Col>
                      </Row>
                      <hr className='m-0'></hr>
                    </React.Fragment>
                  ))}
              </div>

              <hr className='m-0'></hr>
              <Row className='d-flex justify-content-between pb-2 mt-auto'>
                <Col className={`${tableStyles.darkBlueText} pt-3 `} xs={6}>
                  <b>Total devengado</b>
                </Col>
                <Col className={`${tableStyles.darkGrayText}  text-end pt-3`} xs={6}>
                  <b>{convertMoneyFormat(totalPaymentsAndAccrued.toFixed(2))}</b>
                </Col>
              </Row>
            </Card>
          </Col>
          {/*//////// sección de deducciones////// */}
          <Col xs={6}>
            <Card className={`${tableStyles.cardBoxContainer}`} style={{ minHeight: '100%' }}>
              <Row className='mb-2'>
                <h6 className={`${tableStyles.darkBlueText} ${tableStyles.blackBottomBorder} pb-2`}>
                  <b>Deducciones</b>
                </h6>
              </Row>

              <div style={{ maxWidth: '100%', msOverflowY: 'auto' }}>
                {uniqueDeductions.map((deduction, index) => (
                  <React.Fragment key={index}>
                    <Row className='d-flex justify-content-between'>
                      <Col className={`${tableStyles.darkGrayText}`} xs={6}>
                        <b>{deduction.name}</b>
                      </Col>
                      <Col className={`text-secondary text-end`} xs={6}>
                        {convertMoneyFormat(deduction.value)}
                      </Col>
                    </Row>
                    <hr className='m-0'></hr>
                  </React.Fragment>
                ))}
              </div>

              <Row
                className='d-flex justify-content-between pb-2 mt-auto'
                // style={{ marginTop: "20%" }}
              >
                <Col className={`${tableStyles.darkBlueText} pt-3 `} xs={6}>
                  <b>Total deducciones</b>
                </Col>
                <Col className={`${tableStyles.darkGrayText}  text-end pt-3`} xs={6}>
                  <b>{convertMoneyFormat(totalDeductionsIncludingMiniPayroll?.toFixed(2))}</b>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <hr></hr>
        {/* //SI Y SOLO SI EL CONTRATO ESTÁ FINALIZADO */}
        {result?.status === 'loaded' && (
          <div className={`mt-2 pb-2 p-0 d-flex justify-content-end`}>
            <div className={`${tableStyles.darkBlueText} mr-3`}>
              <b>Valor liquidación</b>
            </div>
            <div className={`${tableStyles.darkGrayText} mr-1`}>
              <b>
                {convertMoneyFormat(
                  Math.round(totalPaymentsAndAccrued - totalDeductionsIncludingMiniPayroll),
                )}
              </b>
            </div>
          </div>
        )}
        <Row className='mt-4'>
          <h5 className={`${tableStyles.title} mt-2`} style={{ color: '#005DBF' }}>
            <b>{numberToWords(totalPaymentsAndAccrued - totalDeductionsIncludingMiniPayroll)}</b>
          </h5>
          <Col xs={12} className='mb-5'>
            <Card className={`${tableStyles.cardBoxContainer} p-3`}>
              <h5 className={`${tableStyles.darkBlueText}`}>
                <b>Se hace constar:</b>
              </h5>
              <hr className='mt-4' style={{ borderWidth: '10px' }} />
              <p
                className={`${tableStyles.darkGrayText}`}
                style={{ lineHeight: '2.6rem', marginBottom: '-24px', marginTop: '-24px' }}
              >
                1. Que el patrono ha incorporado en la presente liquidación los importes
                correspondientes a salarios, horas extras, descansos compensatorios, cesantías,
                vacaciones, prima de servicios, auxilio de transporte, y en sí, todo concepto
                relacionado con salarios, prestaciones o indemnizaciones causadas al quedar
                extinguido el contrado de trabajo.
              </p>
              <hr />
              <p
                className={`${tableStyles.darkGrayText}`}
                style={{ lineHeight: '2.6rem', marginBottom: '-24px', marginTop: '-24px' }}
              >
                2. Que con el pago del dinero anotado en la presente liquidación, queda transada
                cualquier diferencia relativa al contrato de trabajo extinguido, o a cualquier
                diferencia anterior. Por lo tanto, esta transacción tiene como efecto la terminación
                de las obligaciones provenientes de la relación laboral que existió entre{' '}
                <b>{eaccount}</b> y el trabajador, quienes declaran estar a paz y salvo por todo
                concepto.
              </p>
              <hr />
              <Row
                className='d-flex justify-content-between p-1 '
                style={{ marginTop: '6rem', marginBottom: '-5px' }}
              >
                <Col>
                  <hr style={{ width: '80%' }} />
                  <p>
                    <b>EL EMPLEADO</b>
                  </p>
                  <p>
                    <b>C.C. {formatteDocument(contractInfo?.cand_docnumber).format}</b>
                  </p>
                </Col>
                <Col className='text-end pr-3' style={{ position: 'relative' }}>
                  <hr style={{ width: '80%', position: 'absolute', right: '13px' }} />
                  <div style={{ position: 'absolute', bottom: '0', right: '13px' }}>
                    <p>
                      <b>EL EMPLEADOR</b>
                    </p>
                    <p>
                      <b>NIT. {numberWithCommas(nit)}</b>
                    </p>
                  </div>
                </Col>
              </Row>
              <hr />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
