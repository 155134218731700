import { API_VERSION, URL_GATEWAY } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";

export const getPatientInfo = async(objFilters, token) => {
    try {
        const filters = convertFilterToString(objFilters);
        const query = await fetch(`${URL_GATEWAY}${API_VERSION}/medical/patient/?${filters}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                accept: "application/json"
            }
        })
        const results = await query.json();
        return results
    } catch (e) {
        console.error(e)
        return {success:false, message:"Ocurrió un error al traer la información del paciente"}
    }
}
export const getPatientEvents = async(objFilters, token) => {
    try {
        const filters = convertFilterToString(objFilters);
        const query = await fetch(`${URL_GATEWAY}${API_VERSION}/medical/patient/events/?${filters}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                accept: "application/json"
            }
        })
        const results = await query.json();
        return results
    } catch (e) {
        console.error(e)
        return {success:false, message:"Ocurrió un error al traer el listado de eventos"}
    }
}
export const getMedOrdActv = async(objFilters, token) => {
    try {
        const filters = convertFilterToString(objFilters);
        const query = await fetch(`${URL_GATEWAY}${API_VERSION}/medical/medicationOrder/?${filters}`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
                accept: "application/json"
            }
        })
        const results = await query.json();
        return results
    } catch (e) {
        console.error(e)
        return {success:false, message:"Ocurrió un error al traer el listado de eventos"}
    }
}
