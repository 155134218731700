import { Modal } from 'react-bootstrap';
import { Close } from '@material-ui/icons';
//
import LetterEditor from '../LetterEditor/LetterEditor';
//
import modalStyles from '../../components/Layouts/modalnew.module.scss';
import '../LetterEditor/LetterEditor.scss';

export default function LetterPreview({ isOpen = false, onClose = () => null, content }) {
  const onCloseModal = () => {
    onClose();
  };

  const render = () => {
    return (
      <Modal show={isOpen} centered className={`${modalStyles.container}`} size='lg'>
        <div className='d-flex align-items-center justify-content-between pt-3 py-2 px-4 border-bottom'>
          <div></div>
          <Close className='pointer text-secondary' onClick={onCloseModal} />
        </div>

        <div className='p-3'>
          <LetterEditor defaultValue={content} readOnly />
        </div>
      </Modal>
    );
  };

  return render();
}
