import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import iconPdf from '../assets/img/icons/pdfIcon.svg';
import iconImg from '../assets/img/icons/Imagen.svg';
import iconCopy from '../assets/img/icons/ordBlueCopy.svg';

import { isEmptyOrUndefined, message } from '../helpers/helpers';
import { useGetMethod } from '../Hooks/useFetch';

import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { PdfViewer } from '../components/Layouts/PdfViewer';

import tableStyles from '../components/Layouts/tableStyle.module.scss';

function RenderAppPdf(props) {
  let { pdfParams } = props;
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;

  const { trigger: getPdfOrder } = useGetMethod();
  /* -----------------------------  ----------------------------- */
  useEffect(() => {
    if (!isEmptyOrUndefined(pdfParams?.url) && !isEmptyOrUndefined(pdfParams?.id)) {
      getPdfOrder({
        url: pdfParams?.url,
        objFilters: { id: Number(pdfParams?.id) || '' },
        token: token,
        doAfterSuccess: response => {
          if (response.status !== 200) {
            return message('warning', ' Advertencia', response.message);
          }
        },
      });
    }
  }, [props?.id, props?.url, getPdfOrder, pdfParams?.id, pdfParams?.url, token]);

  const download = file => {
    let win = window.open();
    win.document.write(
      '<iframe src="' +
        file +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
    );
  };

  return (
    <>
      <div className='w-100 px-4 pt-5'>
        <OrdGenericTemplate
          showBackArrow
          className='w-100'
          titleSize={6}
          colBtnSize={6}
          colbtnClass={'p-0 m-0'}
          title={'Recordatorio de cita'}
        >
          <div className='w-100w px-4 d-flex'>
            <Col xs={6} style={{ width: 'max-content' }}>
              <Row className='pb-2'>
                <Col xs></Col>
                <Col xs={1} style={{ width: 'max-content' }}>
                  <img
                    src={iconPdf}
                    alt='imprimir'
                    onClick={() => download(`data:application/pdf;base64,${''}`)}
                    width='20'
                    className={`cursorPointer ${tableStyles.filterGray}`}
                  />
                </Col>
                <Col xs={1} style={{ width: 'max-content' }}>
                  <img
                    src={iconImg}
                    alt='imprimir'
                    onClick={() => download(`data:application/pdf;base64,${''}`)}
                    width='20'
                    className={`cursorPointer ${tableStyles.filterGray}`}
                  />
                </Col>
                <Col xs={1} style={{ width: 'max-content' }}>
                  <img
                    src={iconCopy}
                    alt='imprimir'
                    onClick={() => download(`data:application/pdf;base64,${''}`)}
                    width='20'
                    className={`cursorPointer ${tableStyles.filterGray}`}
                  />
                </Col>
              </Row>
              <PdfViewer
                pdfWidth={700}
                containerClass={tableStyles.shade}
                file={`data:application/pdf;base64,${''}`}
              ></PdfViewer>
            </Col>
            <Col xs={5} className='px-5'>
              <h1 style={{ fontSize: '30px' }} className={`${tableStyles.ordDarkBlueText} m-0`}>
                Enviar
              </h1>
              <div
                className={`${tableStyles.ordSearchSections} my-2 ml-2 p-3`}
                style={{ borderRadius: '10px' }}
              >
                <Col xs={12}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b className='px-1'>Para</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type='text'
                      placeholder='ameliaferni@gmail.com'
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className='mb-3 text-start' controlId='fName'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                      <b className='px-1'>Asunto</b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput`}
                      type='text'
                      placeholder='Recordatorio de cita - Revisión de optometría'
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <CKEditor editor={ClassicEditor} />
                </Col>
              </div>
            </Col>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
}
export default RenderAppPdf;
