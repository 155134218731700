import { Button, Grid, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';
import backArrow from '../../../../assets/img/icons/atras-icon.svg';
import avion from '../../../../assets/img/icons/avion.svg';
import excelIcon from '../../../../assets/img/icons/excelIcon.svg';
import Search from '../../../../assets/img/icons/lupa.svg';
import note from '../../../../assets/img/icons/ordSugess.svg';
import { customSwaltAlert } from '../../../../helpers/customSwaltAlert';
import { convertMoneyFormat, downloadExcel, loader, formatteDocument } from '../../../../helpers';
import ModalNew from '../../../Layouts/ModalNew';
import TableScroll from '../../../Layouts/TableScroll/TableScroll';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import '../../../TableUsers/tableUserStyle.scss';

export const RoyaltyPaymentDetail = ({ backPage, idTemplate, status, dataPayroll }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'plandenom',
  );
  const idAccount = store.loginReducer.currentAccount.id;
  const history = useHistory();
  const [data, setData] = useState({
    trigger: 0,
    filters: {
      search: '',
      entity_account: idAccount,
      perks_id: idTemplate,
    },
  });
  const { filters, trigger } = data;
  const {
    results: PremiumScheduleResults,
    load: premiumScheduleLoader,
    trigger: getPremiumSchedule,
  } = useGetMethod();
  const { load: excelMethodLoader, trigger: getExcelMethod } = useGetMethod();
  const { load: updatePayrollLoader, trigger: updatePayroll } = usePostMethod();

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    getPremiumSchedule({
      url: '/payroll/perks_detail/',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const getExcelFunction = () => {
    getExcelMethod({
      url: '/payroll/perks_detail_excel/',
      objFilters: {
        perks_id: idTemplate,
        entity_account: idAccount,
      },
      token: token,
      doAfterSuccess: res => {
        downloadExcel(res.results.base64, res.results.filename, true);
      },
    });
  };
  const bodyTable = () => {
    let tempArr = [];
    PremiumScheduleResults?.results?.length > 0 &&
      PremiumScheduleResults?.results?.forEach((elem, ind) => {
        tempArr.push(
          <tr key={elem.id}>
            <td className={`col-6 px-3 text-start fw-bold`}>
              <Row className='d-flex'>
                <Col xs={1} className='px-2 text-center'>
                  {' '}
                  {ind + 1}
                </Col>
                <Col xs={5} className='px-2 text-start textHide' title={elem.full_name ?? '-'}>
                  {' '}
                  {elem.full_name.toUpperCase() ?? '-'}
                </Col>
                <Col xs={3} className='px-2 text-center'>
                  {formatteDocument(elem.doc_number).format ?? '-'}{' '}
                </Col>
                <Col xs={3} className='px-2 text-center'>
                  {elem.start_date ?? '-'}{' '}
                </Col>
              </Row>
            </td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.january ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.february ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.march ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.april ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.may ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.june ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.july ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.august ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.september ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.october ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.november ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.december ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.total ?? '-')}</td>
            <td className='col-2 text-end px-2'>{convertMoneyFormat(elem?.perk_value ?? '-')}</td>
          </tr>,
        );
      });
    return tempArr;
  };
  const renderHeaders = [
    <th key={1} className='text-start col-6 px-3'>
      <Row className='d-flex' style={{ width: 600 }}>
        <Col xs={1} className='px-2 text-center'>
          {' '}
          No.
        </Col>
        <Col xs={5} className='px-2 text-start'>
          Empleado
        </Col>
        <Col xs={3} className='px-2 text-center'>
          ID
        </Col>
        <Col xs={3} className='px-2 text-center'>
          F.Ingreso
        </Col>
      </Row>
    </th>,

    <th key={2} className='text-end col-2'>
      Enero
    </th>,
    <th key={2} className='text-end col-2'>
      Febrero
    </th>,
    <th key={2} className='text-end col-2'>
      Marzo
    </th>,
    <th key={2} className='text-end col-2'>
      Abril
    </th>,
    <th key={2} className='text-end col-2'>
      Mayo
    </th>,
    <th key={2} className='text-end col-2'>
      Junio
    </th>,
    <th key={2} className='text-end col-2'>
      Julio
    </th>,
    <th key={2} className='text-end col-2'>
      Agosto
    </th>,
    <th key={2} className='text-end col-2'>
      Septiembre
    </th>,
    <th key={2} className='text-end col-2'>
      Octubre
    </th>,
    <th key={2} className='text-end col-2'>
      Noviembre
    </th>,
    <th key={2} className='text-end col-2'>
      Diciembre
    </th>,
    <th key={2} className='text-end col-2'>
      Total
    </th>,
    <th key={2} className='text-end col-2'>
      Regalía
    </th>,
  ];

  const sendToAccountingFunction = () => {
    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `Se enviará a contabilidad la planilla de regalía pascual del año ${dataPayroll?.payment_year}`,
    }).then(result => {
      if (result.isConfirmed) {
        updatePayroll({
          url: '/payroll/perks/',
          token: token,
          method: 'PUT',
          body: {
            persk_id: idTemplate,
            entity_account: idAccount,
            status: 'review',
          },
          succesAction: () => {
            backPage.setData(state => ({
              ...state,
              generatePremiumSchedule: false,
              trigger: state.trigger + 1,
            }));
            customSwaltAlert({
              title: `Enviada exitosamente`,
              text: `Se ha enviado a contabilidad planilla del año ${dataPayroll?.payment_year} - Periodo ${dataPayroll?.period}. `,
              icon: 'success',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
  });

  const closeCommentModal = () => {
    setCommentModal({
      ...commentModal,
      show: false,
      cancelledReason: '',
    });
  };

  return (
    <>
      {(excelMethodLoader || premiumScheduleLoader || updatePayrollLoader) && loader}
      <ModalNew
        title='Comentarios'
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={'Cerrar'}
        size='400'
        btnYesEvent={null}
        btnYesDisabled={true}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentario</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          disabled={true}
          defaultValue={commentModal.comment}
          style={{
            height: '4rem',
            borderRadius: '5px',
            border: '1.5px solid #01A0F6',
            padding: '0 0.05rem',
            width: '100%',
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>

      <div className='w-90 mx-auto'>
        <h1
          className={`${tableStyles.title} d-flex gap-2 align-items-center`}
          style={{ marginLeft: '-30px' }}
        >
          <img
            onClick={() => {
              backPage.setData(state => ({
                ...state,
                generatePremiumSchedule: false,
                trigger: state.trigger + 1,
              }));
            }}
            src={backArrow}
            alt='backArrow'
            className={`pointer`}
            height='30px'
          />
          <span className={`${tableStyles.titleAdministrative}`}>
            Regalía pascual {dataPayroll.payment_year}
          </span>
          {status === 'rejected' && (
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={() => (
                <Tooltip>
                  <span> Añadir comentario </span>
                </Tooltip>
              )}
            >
              <img
                alt='goal'
                src={note}
                className={`align-self-center mx-2 ${tableStyles.svgFilterDarkBlue} cursorPointer`}
                onClick={() => {
                  setCommentModal({
                    ...commentModal,
                    show: true,
                    comment: dataPayroll?.comment ?? '',
                  });
                }}
                height='30px'
                width='30px'
              />
            </OverlayTrigger>
          )}
        </h1>

        <Row>
          <Col item xs={6} className='d-flex align-items-end justify-content-end'>
            <input
              className={`${tableStyles.blueSearch} mr-2`}
              style={{ flex: 1, color: `#73a6dc` }}
              name='search'
              type='text'
              placeholder='Buscar...'
              value={filters.search}
              onChange={e =>
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, search: e.target.value },
                }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />

            <img
              src={Search}
              style={{ cursor: 'pointer' }}
              alt='Search icon'
              onClick={() => {
                setData(state => ({ ...state, trigger: state.trigger + 1 }));
              }}
            />
          </Col>
          <Col item xs={6} className='d-flex align-items-end justify-content-end'>
            <img
              src={excelIcon}
              style={{ cursor: 'pointer' }}
              alt='Search icon'
              onClick={() => {
                getExcelFunction();
              }}
            />
          </Col>
        </Row>

        <Row className='py-4'>
          <TableScroll body={bodyTable()} headers={renderHeaders}></TableScroll>
        </Row>

        <Grid className='d-flex justify-content-end gap-3 mt-3'>
          <Button
            style={{ textTransform: 'none' }}
            className={tableStyles.btnCancelStyle}
            onClick={() => {
              backPage.setData(state => ({
                ...state,
                generatePremiumSchedule: false,
                trigger: state.trigger + 1,
              }));
            }}
          >
            Cancelar
          </Button>
          {status === 'loaded' && (
            <Button
              style={{ textTransform: 'none' }}
              className={tableStyles.btnYesStyle}
              onClick={() => sendToAccountingFunction()}
            >
              <div className='d-flex gap-2'>
                <img src={avion} alt='avion' />
                <span>Enviar a contabilidad</span>
              </div>
            </Button>
          )}
        </Grid>
      </div>
    </>
  );
};
