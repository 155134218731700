import { useState } from 'react';
import Pagination from 'react-js-pagination';
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import checkboxTrue from '../../assets/img/icons/checkboxTrue.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import '../Layouts/customTabs.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function ReqModalConsolidate(props) {
  const [compra, setcompra] = useState({
    page: 1,
    perpage: 10,
  });

  const [subasta, setsubasta] = useState({
    page: 1,
    perpage: 10,
  });

  const renderHeadersCompra = [
    <th key={`compra1`} className='px-2'>
      Item
    </th>,
    <th key={`compra2`}>Artículo</th>,
    <th key={`compra3`}>Solicitado</th>,
    <th key={`compra8`}>Almacenes</th>,
  ];

  const renderCompra = () => {
    let table = [];

    if (Array.isArray(props.filterCompra)) {
      table = props.filterCompra.map(x => {
        return (
          <tr key={'ReqModalConsolidateList' + x.id_article} className={`hover-table-row`}>
            <td className={`col-md-1 text-start px-2`}>{x.id_article}</td>
            <td className={`col-md-4`}>
              <p className='m-0 text-start'>{x.article?.description}</p>
            </td>
            <td className={`col-md-2`}>{x.qty_article}</td>
            <td className={`col-md-2`}>
              {Array.isArray(x?.warehousearticle) ? x.warehousearticle.length : '-'}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateListCompra = () => {
    const fullList = renderCompra();
    return fullList.slice(compra.perpage * (compra.page - 1), compra.perpage * compra.page);
  };

  const renderHeadersSubasta = [
    <th key={`subasta1`} className='px-2'>
      Item
    </th>,
    <th key={`subasta2`}>Artículo</th>,
    <th key={`subasta3`}>Cantidad</th>,
  ];

  const renderSubasta = () => {
    let table = [];

    if (Array.isArray(props.filterSubasta)) {
      table = props.filterSubasta.map(x => {
        return (
          <tr key={'ReqModalConsolidateList' + x.id_article} className={`hover-table-row`}>
            <td className={`col-md-1 text-start px-2`}>{x.id_article}</td>
            <td className={`col-md-4`}>
              <p className='m-0 text-start'>{x.article?.description}</p>
            </td>
            <td className={`col-md-1`}>{x.qty_article}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateListSubasta = () => {
    const fullList = renderSubasta();
    return fullList.slice(subasta.perpage * (subasta.page - 1), subasta.perpage * subasta.page);
  };

  return (
    <div className={reqStyles.container2}>
      <div className='d-flex'>
        <img src={checkboxTrue} alt='check' className={reqStyles.adjustIconPart2} />
        <p className={` ${reqStyles.textPart2}`}>
          Los siguientes productos serán Solicitados al almacén respectivo
        </p>
      </div>

      <GenericTableNew headers={renderHeadersCompra}>{paginateListCompra()}</GenericTableNew>
      <div className={paginationStyles.wrapper}>
        <p className={paginationStyles.paginationText}>
          Pag. {props.filterCompra?.length ? compra.page : ''}
          {' de '}
          {Math.ceil(props.filterCompra?.length / compra.perpage)
            ? Math.ceil(props.filterCompra?.length / compra.perpage)
            : ''}{' '}
          ({props.filterCompra?.length} encontrados)
        </p>
        <Pagination
          activePage={compra.page}
          itemsCountPerPage={compra.perpage}
          totalItemsCount={props.filterCompra?.length}
          pageRangeDisplayed={5}
          onChange={e => setcompra({ ...compra, page: e })}
          itemClassPrev={paginationStyles.itemClassPrev}
          itemClassNext={paginationStyles.itemClassNext}
          itemClassFirst={paginationStyles.itemClassFirst}
          itemClassLast={paginationStyles.itemClassLast}
          itemClass={paginationStyles.itemClass}
        />
      </div>

      {/* subastaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}
      <div className='d-flex'>
        <img src={AlertTriangle} alt='alert' className={reqStyles.adjustIconPart2Orange} />
        <p className={` ${reqStyles.textPart2} ${reqStyles.textPart2Orange}`}>
          Los siguientes artículos serán Subastados entre proveedores
        </p>
      </div>
      <GenericTableNew headers={renderHeadersSubasta}>{paginateListSubasta()}</GenericTableNew>
      <div className={paginationStyles.wrapper}>
        <p className={paginationStyles.paginationText}>
          Pag. {props.filterSubasta?.length ? subasta.page : ''}
          {' de '}
          {Math.ceil(props.filterSubasta?.length / subasta.perpage)
            ? Math.ceil(props.filterSubasta?.length / subasta.perpage)
            : ''}{' '}
          ({props.filterSubasta?.length} encontrados)
        </p>
        <Pagination
          activePage={subasta.page}
          itemsCountPerPage={subasta.perpage}
          totalItemsCount={props.filterSubasta?.length}
          pageRangeDisplayed={5}
          onChange={e => setsubasta({ ...subasta, page: e })}
          itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
          itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
          itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
          itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );
}

export default ReqModalConsolidate;
