import moreInfoQuestionMark from '../../assets/img/icons/moreInfoQuestionMark.svg';

import { updateArticle } from '../../actions/articlesActions';

import { useState } from 'react';

import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import ModalNew from '../Layouts/ModalNew';
import '../Layouts/customTabs.scss';
import { customSelectNew } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';

function ReqModalArticleCreate(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [addArticle, setaddArticle] = useState({
    ...props.reqArticle.article,

    add_info: [],
    active: true,
    id_account: counter.loginReducer.currentAccount.id,
    manufacturerOptions: props.optionsManufacturers,
    id_requisitions: props.reqArticle.id_requisitions,
    alsoSetReqArticleDone: true,
  });

  const btnAccept = () => {
    let fixedArticle = {
      ...addArticle,
    };

    dispatch(
      updateArticle(fixedArticle, () => {
        props.resetParent();
        props.onHide();
      }),
    );
  };

  const btnCancel = () => {
    //TODO
    props.onHide();
  };

  const optionUnidades = () => {
    let tempArray = [];
    let familyPicked = props.optionFamilia.find(x => x.value === addArticle.f_id);
    if (familyPicked) {
      if (Array.isArray(familyPicked.measurements_list)) {
        familyPicked.measurements_list.forEach(elem => {
          tempArray.push({
            value: elem.id,
            label: elem.description + ' (' + elem.prefix + ')',
          });
        });
      }
    }

    return tempArray;
  };

  const setAdditionalInfo = (id_add_info, id_article, newval) => {
    let tempAddArticle = addArticle;
    let temp = tempAddArticle.add_info.findIndex(x => x.id_add_info === id_add_info);

    if (tempAddArticle.add_info[temp]) {
      let new_add_info_article = {
        id_add_info: tempAddArticle.add_info[temp].id_add_info,
        id_article: tempAddArticle.add_info[temp].id_article,
        article_info: newval, //tempAddArticle.add_info[temp].article_info +
      };
      tempAddArticle.add_info[temp] = new_add_info_article;
    } else {
      let new_add_info_article = {
        id_add_info: id_add_info,
        id_article: id_article,
        article_info: newval,
      };
      tempAddArticle.add_info.push(new_add_info_article);
    }
    setaddArticle({ ...tempAddArticle });
  };

  const getAdditionalInfo = id_add_info => {
    let myMan = addArticle.add_info.find(x => x.id_add_info === id_add_info);
    if (myMan) {
      return myMan.article_info;
    } else {
      return '';
    }
  };

  const renderAdditionalInfo = () => {
    let temp = [];
    let familyPicked = props.optionFamilia.find(x => x.value === addArticle.f_id);
    if (familyPicked) {
      if (Array.isArray(familyPicked.additional_info)) {
        //add title if array has a value
        if (familyPicked.additional_info.length > 0) {
          temp.push(<h2 className={reqStyles.subtitle}>Información adicional</h2>);
        }

        //for every element in the array add a new field
        familyPicked.additional_info.forEach(elem => {
          let newfield = (
            <div className='d-flex'>
              <div key={elem.id} className={`${reqStyles.ItemSmall}`}>
                <p className={tableStyles.crudModalLabel}>{elem.info}</p>
                <input
                  className='register-inputs register-inputs--clearBlue col'
                  type='text'
                  value={getAdditionalInfo(elem.id)}
                  onChange={e => setAdditionalInfo(elem.id, addArticle.id, e.target.value)}
                />
              </div>
            </div>
          );
          temp.push(newfield);
        });
      }
    }
    return temp;
  };

  const manufacturerChange = newValue => {
    setaddArticle({
      ...addArticle,
      manufacturerObj: newValue,
      manufacturer: newValue?.label,
    });
  };

  const manufacturerCreate = inputValue => {
    const newOption = {
      value: inputValue,
      label: inputValue.toLowerCase().replace(/\W/g, ''),
    };
    setaddArticle({
      ...addArticle,
      manufacturerOptions: [...addArticle.manufacturerOptions, newOption],
      manufacturerObj: newOption,
      manufacturer: inputValue,
    });
  };

  return (
    <ModalNew
      title='Crear Artículo'
      show={props.show}
      size='550'
      btnYesName='Guardar'
      btnNoName='Cancelar'
      btnYesEvent={() => btnAccept()}
      onHide={() => btnCancel()}
    >
      <div className='d-flex'>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Descripción</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='text'
            value={addArticle.description}
            onChange={e => setaddArticle({ ...addArticle, description: e.target.value })}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Familia de inventario</p>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            defaultValue={props.optionFamilia.find(x => x.value === addArticle.f_id)}
            onChange={e =>
              setaddArticle({ ...addArticle, f_id: e.value, add_info: [], measurement_unit: '' })
            }
            options={props.optionFamilia}
            placeholder=''
            styles={customSelectNew}
            maxHeight={22}
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Nombre comercial</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='text'
            value={addArticle.tradename}
            onChange={e => setaddArticle({ ...addArticle, tradename: e.target.value })}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>&nbsp;</p>
          <div className={`d-flex`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='checkbox'
              name='needsAuth'
              onChange={() => setaddArticle({ ...addArticle, needsAuth: !addArticle.needsAuth })}
              checked={addArticle.needsAuth}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>
              Requerirá autorización
            </p>
            <Tooltip
              title={
                <p className='m-0 text-center'>
                  Requerirá autorización <br />
                  previa de un jefe o lider <br />
                  para cada orden de compra
                </p>
              }
            >
              <img src={moreInfoQuestionMark} alt='?' />
            </Tooltip>
          </div>
        </div>
      </div>

      <h2 className={reqStyles.subtitle}>Unidades de medida</h2>
      <div className={`d-flex`}>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Unidad de medida</p>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            key={'MU_' + addArticle.measurement_unit}
            defaultValue={optionUnidades().find(x => x.value === addArticle.measurement_unit)}
            onChange={e => setaddArticle({ ...addArticle, measurement_unit: e.value })}
            options={optionUnidades()}
            placeholder=''
            styles={customSelectNew}
            maxHeight={22}
          />
        </div>

        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemBase}`}>
          <p className={tableStyles.crudModalLabel}>&nbsp;</p>
          <div className={`d-flex`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='checkbox'
              name='unique'
              onChange={() =>
                addArticle.unique
                  ? setaddArticle({ ...addArticle, unique: !addArticle.unique })
                  : setaddArticle({ ...addArticle, unique: !addArticle.unique, quantity: 1 })
              }
              checked={addArticle.unique}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>Unidad única</p>
          </div>
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Cantidad de medida</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='number'
            value={addArticle.quantity}
            onChange={e => setaddArticle({ ...addArticle, quantity: e.target.value })}
            disabled={addArticle.unique}
          />
        </div>
      </div>

      {renderAdditionalInfo()}

      <div className='d-flex'>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Código de Barras</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='text'
            value={addArticle.barcode}
            onChange={e => setaddArticle({ ...addArticle, barcode: e.target.value })}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Referencia</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='text'
            value={addArticle.reference}
            onChange={e => setaddArticle({ ...addArticle, reference: e.target.value })}
          />
        </div>
      </div>

      <div className='d-flex my-2'>
        <div className={`${reqStyles.inputMargin} ${reqStyles.ItemMedium}`}>
          <div className={`${reqStyles.ItemMedium}`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='radio'
              name='consig_or_property'
              id='property_'
              value='property_'
              onChange={() =>
                setaddArticle({
                  ...addArticle,
                  property_: !addArticle.property_,
                  consignment: false,
                })
              }
              checked={addArticle.property_}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>Propiedad</p>
          </div>

          <div className={`${reqStyles.ItemMedium}`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='radio'
              name='consig_or_property'
              id='consignment'
              value='consignment'
              onChange={() =>
                setaddArticle({
                  ...addArticle,
                  property_: false,
                  consignment: !addArticle.consignment,
                })
              }
              checked={addArticle.consignment}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>Consignación</p>
          </div>
        </div>

        <div className={`${reqStyles.ItemMedium}`}>
          <div className={`${reqStyles.ItemMedium}`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='checkbox'
              name='handle_batch'
              onChange={() =>
                setaddArticle({ ...addArticle, handle_batch: !addArticle.handle_batch })
              }
              checked={addArticle.handle_batch}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>Maneja Lote</p>
          </div>

          <div className={`${reqStyles.ItemMedium}`}>
            <input
              className={`form-check-input ${reqStyles.checkbox}`}
              type='checkbox'
              name='handle_serial'
              onChange={() =>
                setaddArticle({ ...addArticle, handle_serial: !addArticle.handle_serial })
              }
              checked={addArticle.handle_serial}
            />
            <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>Maneja serial</p>
          </div>
        </div>
      </div>

      <div className='d-flex'>
        <div className={`${reqStyles.inputMargin}  ${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Laboratorio o Fabricante</p>

          <Creatable
            isClearable={true}
            onChange={manufacturerChange}
            onCreateOption={manufacturerCreate}
            options={addArticle.manufacturerOptions}
            value={addArticle.manufacturerObj}
            placeholder=''
            styles={customSelectNew}
            maxHeight={22}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Precio de Costo</p>
          <input
            className='register-inputs register-inputs--clearBlue col'
            type='number'
            value={addArticle.cost_price}
            onChange={e => setaddArticle({ ...addArticle, cost_price: e.target.value })}
          />

          <div className={`d-flex mt-2`}>
            <div className={`${reqStyles.ItemMedium}`}>
              <input
                className={`form-check-input ${reqStyles.checkbox}`}
                type='checkbox'
                name='iva'
                onChange={() => setaddArticle({ ...addArticle, iva: !addArticle.iva })}
                checked={!!addArticle.iva}
              />
              <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>iva (19%)</p>
            </div>

            <div className={`${reqStyles.ItemMedium}`}>
              <input
                className={`form-check-input ${reqStyles.checkbox}`}
                type='checkbox'
                name='reteiva'
                onChange={() => setaddArticle({ ...addArticle, reteiva: !addArticle.reteiva })}
                checked={!!addArticle.reteiva}
              />
              <p className={`${tableStyles.crudModalLabel} ${reqStyles.checkbox}`}>reteiva (15%)</p>
            </div>
          </div>
        </div>
      </div>
    </ModalNew>
  );
}

export default ReqModalArticleCreate;
