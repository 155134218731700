import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import specialTableStyles from '../Layouts/SpecialTable/SpecialTable.module.scss';
import '../Layouts/customTabs.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopUpExtends';
import '../TableUsers/tableUserStyle.scss';
import pendingStyles from './AuctionPending.module.scss';
import detailStyles from './CurrentAuctionDetails.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';

import { Checkbox, Tooltip } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Popup from 'reactjs-popup';
import popupStyles from '../Popup/customPopup.module.scss';

import Back from '../../assets/img/icons/atras-icon.svg';
import iconGearGray from '../../assets/img/icons/iconGearGray.svg';
import iconHandshake from '../../assets/img/icons/iconHandshake.svg';
import iconPrint from '../../assets/img/icons/iconPrint.svg';
import iconShopCart from '../../assets/img/icons/iconShopCart.svg';
// import engraneGris from "../../assets/img/icons/engraneGris.svg";
import specialTableArrow from '../../assets/img/icons/specialTableArrow.svg';
// import auctionDetailLupa from "../../assets/img/icons/auctionDetailLupa2.svg";
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import auctionDetailExpand from '../../assets/img/icons/auctionDetailExpand.svg';
import specialStyles from '../Layouts/SpecialTable/SpecialTable.module.scss';

import {
  getPdfTest,
  indauction_get_one,
  quotation_filter,
  update_indauc,
} from '../../actions/IndividualAuctionActions';
import { PURCHASEORDER_MODAL2, PURCHASEORDER_PRINCIPAL } from '../../actions/actionTypes';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { message } from '../../helpers/helpers';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { PdfViewer } from '../Layouts/PdfViewer';
import SpecialTable from '../Layouts/SpecialTable/SpecialTable';
import DetailsModalConvenio from './DetailsModalConvenio';
import DetailsModalCotizacion from './DetailsModalCotizacion';
import DetailsModalPurchase from './DetailsModalPurchase';
import DetailsModalPurchaseSummary from './DetailsModalPurchaseSummary';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function priority(key) {
  switch (key) {
    case 'normal':
      return 'Normal';
    case 'priority':
      return 'Prioritaria';
    default:
      return '-';
  }
}

function payConditions(key) {
  switch (key) {
    case 'prepayment':
      return 'Pago anticipado';
    case 'delivery':
      return 'Pago contraentrega';
    case 'pay20':
      return 'Pago a 20 días';
    case 'pay30':
      return 'Pago a 30 días';
    case 'pay60':
      return 'Pago a 60 días';
    case 'pay90':
      return 'Pago a 90 días';
    default:
      return '-';
  }
}

function textColor(percent) {
  if (percent >= 100) {
    return detailStyles.greenText;
  } else {
    if (percent <= 0) {
      return detailStyles.redText;
    } else {
      return detailStyles.yellowText;
    }
  }
}

const article_headers = [
  <p key={'special1'}>Proveedor</p>,
  <p key={'special2'}>Marca</p>,
  <p key={'special4'}>Precio&nbsp;Unit.</p>,
  <p key={'special5'} className={specialStyles.smallChild}>
    Cant.
  </p>,
  <p key={'special6'} className={specialStyles.smallChild}>
    IVA
  </p>,
  <p key={'special7'} className={specialStyles.smallChild}>
    Descuento
  </p>,
  <p key={'special8'}>Valor&nbsp;Total</p>,
  <p key={'special9'}>
    <img src={iconShopCart} className={detailStyles.tableGearIcon} alt='carrito' />
  </p>,
];

function CurrentAuctionDetails(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const store_indauc = counter.individualAuctionReducer.selected_indauction;
  const today = new Date();

  const [showPdf, setShowPdf] = useState(false);
  const [trigger, setTrigger] = useState(1);
  const [loading, setLoading] = useState(false);
  const [triggerMemoProvider, settriggerMemoProvider] = useState(1);
  const [triggerMemoArticles, settriggerMemoArticles] = useState(1);
  const [collapseAll, setCollapseAll] = useState(true);
  const [base64, setBase64] = useState('');
  const token = counter.loginReducer.Authorization;
  const getPdf = async () => {
    const result = await getPdfTest(
      {
        id: props?.selectedAuction?.id,
        doc: 8,
      },
      token,
    );

    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message('error', 'Ha ocurrido un error', 'No ha sido posible cargar el documento');
      return setShowPdf(false);
    }
  };
  useEffect(() => {
    getPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalDetails, setmodalDetails] = useState({
    show: false,
  });

  const [modalConvenioGeneral, setmodalConvenioGeneral] = useState({
    show: false,
  });

  const [modalConvenio, setmodalConvenio] = useState({
    show: false,
  });

  const [modalRejection, setmodalRejection] = useState({
    show: false,
    justification: '',
  });

  const [modalDateChange, setmodalDateChange] = useState({
    show: false,
    newdate: '',
    who_updated: counter.loginReducer.user_data.id,
  });

  const [modalPurchase, setmodalPurchase] = useState({
    show: false,
  });

  const [selectedList, setSelectedList] = useState([]);
  const [articleSearch, setarticleSearch] = useState('');

  useEffect(() => {
    if (props.selectedAuction?.id) {
      dispatch(indauction_get_one(props.selectedAuction.id));
      dispatch(quotation_filter({ indauc: props.selectedAuction.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show, trigger]);

  useEffect(() => {
    if (modalPurchase.show) {
      dispatch({
        type: PURCHASEORDER_MODAL2,
        show: true,
      });
    } else {
      dispatch({
        type: PURCHASEORDER_MODAL2,
        show: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPurchase.show]);

  const cancelarSubasta = () => {
    if (store_indauc.status === 'solved') {
      return null;
    }
    customSwaltAlert({
      icon: 'info',
      title: '¿Está seguro?',
      text: 'Esta acción es irreversible',
      confirmButtonText: 'Sí, Cancelar subasta',
      cancelButtonText: 'No, regresar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        setmodalRejection({ ...modalRejection, show: true });
      }
    });
  };

  const enviarDenegacion = () => {
    let error = '';

    if (!!modalRejection.justification === false) {
      error = 'Se requiere justificación';
    }

    if (error) {
      customSwaltAlert({
        icon: 'error',
        title: 'Datos Incompletos',
        text: error,
        showCancelButton: false,
      });
      return null;
    }

    setLoading(true);
    dispatch(
      update_indauc(
        {
          id: props.selectedAuction.id,
          status: 'rejected',
          rejection_justification: modalRejection.justification,
          rejection_user: counter.loginReducer.user_data.id,
        },
        () => {
          setmodalRejection({ show: false });
          props.setDetails({ show: false });
          props.setTrigger(props.trigger + 1);
        },
        'Subasta Cancelada',
        () => setLoading(false),
      ),
    );
  };

  const sendDateChange = () => {
    let error = '';

    //let datestart = new Date(store_indauc.date_expiry +"T"+store_indauc.hour_expiry);
    let datestart = today;
    let datend = new Date(modalDateChange.newdate);

    if (!!modalDateChange.newdate === false) {
      error = 'Debe escoger una fecha';
    }
    if (datestart >= datend) {
      error = 'Debe escoger una fecha mayor';
    }
    if (!!modalDateChange.justification === false) {
      error = 'Justifique la razon del cambio de fecha';
    }

    if (error) {
      customSwaltAlert({
        icon: 'error',
        title: 'Datos Incompletos',
        text: error,
        showCancelButton: false,
      });
      return null;
    }

    setLoading(true);
    dispatch(
      update_indauc(
        {
          id: props.selectedAuction.id,
          date_expiry: datend,
          hour_expiry: datend.toLocaleTimeString(),
          datechange_justification: modalDateChange.justification,
          datechange_user: counter.loginReducer.user_data.id,
        },
        () => {
          setmodalDateChange({ show: false });
          setTrigger(trigger + 1);
        },
        'Subasta Actualizada',
        () => setLoading(false),
      ),
    );
  };

  const isArticleProviderSelected = (article_id, provider_id) => {
    let found = selectedList.find(
      x => x.article_id === article_id && x.provider_id === provider_id,
    );
    return found ? true : false;
  };

  const unsetArticleProviderSelected = article_id => {
    let newSelectedList = selectedList.filter(x => x.article_id !== article_id);
    setSelectedList(newSelectedList);
    settriggerMemoArticles(triggerMemoArticles + 1);
  };

  const setArticleProviderSelected = (article_id, provider_id, ref) => {
    let foundExisting = selectedList.find(
      x => x.article_id === article_id && x.provider_id === provider_id,
    );

    let newSelectedList = selectedList.filter(x => x.article_id !== article_id);

    if (!!foundExisting === false) {
      newSelectedList.push({
        article_id: article_id,
        provider_id: provider_id,
        ref: ref,
      });
    }

    setSelectedList(newSelectedList);
    settriggerMemoArticles(triggerMemoArticles + 1);
  };

  const setAllArticleProviderSelected = quotation => {
    let newSelectedList = [];

    //for each quotation_article
    if (Array.isArray(quotation.quotation_article_ref)) {
      quotation.quotation_article_ref.forEach(z => {
        newSelectedList.push({
          article_id: z.id_article,
          provider_id: quotation.provider,
          ref: {
            ...z,
            provider_id: quotation.provider,
            provider_name: quotation.provider_ref?.name,
            quotation_total: quotation.total,
          },
        });
      });
    }

    setSelectedList(newSelectedList);
    settriggerMemoArticles(triggerMemoArticles + 1);
    settriggerMemoProvider(triggerMemoProvider + 1);
    setCollapseAll(false);
  };

  const solvedPercentage = useMemo(() => {
    let solvedtotal = 0;
    let solvedPercent = 0;
    if (
      Array.isArray(counter.individualAuctionReducer.quotations) &&
      counter.individualAuctionReducer.quotations.length > 1
    ) {
      counter.individualAuctionReducer.quotations.forEach(x => {
        if (x.status === 'solved') {
          solvedtotal++;
        }
      });
      solvedPercent = Math.round(
        (100 * solvedtotal) / counter.individualAuctionReducer.quotations.length,
      );
    } else {
      solvedPercent = '0';
    }
    return solvedPercent;
  }, [counter.individualAuctionReducer.quotations]);

  const selectedProviders = () => {
    let tempProvider = [];
    if (Array.isArray(counter.individualAuctionReducer.quotations)) {
      counter.individualAuctionReducer.quotations.forEach(x => {
        if (x.provider_ref) {
          let articlesum = {
            qty: 0,
            sumtotal: 0,
            sumsubtotal: 0,
            sumiva: 0,
            sumdiscount: 0,
          };

          selectedList.forEach(item => {
            if (item.provider_id === x.provider_ref?.id) {
              articlesum = {
                qty: articlesum.qty + item.ref?.qty_article,
                sumsubtotal: articlesum.sumsubtotal + item.ref?.qty_article * item.ref?.unit_price,
                sumiva:
                  articlesum.sumiva +
                  item.ref?.qty_article * item.ref?.unit_price * item.ref?.iva * 0.01,
                sumdiscount:
                  articlesum.sumdiscount +
                  item.ref?.qty_article * item.ref?.unit_price * item.ref?.discount * 0.01,
              };
            }
          });

          articlesum.sumtotal = articlesum.sumsubtotal + articlesum.sumiva - articlesum.sumdiscount;

          if (!!articlesum.qty > 0) {
            tempProvider.push({
              provider_id: x.provider_ref?.id,
              provider_name: x.provider_ref?.name,
              auction_id: x.individual_auction,
              pay_conditions: x.pay_conditions,
              id_quotation: x.id,

              ...articlesum,
            });
          }
        }
      });
    }
    return tempProvider;
  };

  const [listProviders, listProvidersLite] = useMemo(() => {
    let templist = [];
    let liteList = [];
    if (Array.isArray(counter.individualAuctionReducer.quotations)) {
      counter.individualAuctionReducer.quotations.forEach((x, index) => {
        const isOnHold = !!(x.status === 'requested');
        const validityformat = x.quote_validity ? new Date(x.quote_validity) : '';

        if (x.provider_ref) {
          //for the main list shown in the page
          templist.push(
            <div key={index} className={detailStyles.providerTable__Row}>
              <div
                className={`${detailStyles.providerTable__ProviderName} ${isOnHold ? detailStyles.clearGrayText : ''}`}
              >
                {x.provider_ref.name}
              </div>
              <div className={`${detailStyles.f1}`}>
                <b className={isOnHold ? detailStyles.clearGrayText : ''}>
                  {isOnHold ? '-' : formatter.format(x.total)}
                </b>
              </div>
              <div className={`${detailStyles.f1} ${isOnHold ? detailStyles.clearGrayText : ''}`}>
                {isOnHold
                  ? '-'
                  : x.quote_validity
                    ? validityformat.getDate() +
                      '/' +
                      validityformat.getMonth() +
                      '/' +
                      validityformat.getFullYear()
                    : '-'}
              </div>
              <div className={`${detailStyles.f1} ${isOnHold ? detailStyles.clearGrayText : ''}`}>
                {isOnHold ? '-' : payConditions(x.pay_conditions)}
              </div>
              <div className={detailStyles.providerTable__Icon}>
                {/* <img className={detailStyles.tableGearIcon} src={engraneGris} alt="engraneGris" /> */}
                <CustomPopup
                  showDetails={true}
                  position='left'
                  textDetails='Ver cotización'
                  showDetailsEvent={() => setmodalDetails({ show: true, Qid: x.id })}
                  checkEvent={() => setAllArticleProviderSelected(x)}
                  dealEvent={() => setmodalConvenio({ show: true, quotation: x })}
                  triggerDisabled={isOnHold}
                  triggerClass={detailStyles.providerGearIcon}
                />
              </div>
            </div>,
          );

          //for the modal in the handshake button at the top
          if (!isOnHold) {
            liteList.push(
              <tr key={index} className='hover-table-row'>
                <td className={`col-md-2`}>
                  <input
                    name={'selectConvenio'}
                    type='radio'
                    checked={modalConvenio?.quotation?.id === x.id}
                    onClick={() => {
                      setmodalConvenio({ ...modalConvenio, quotation: x });
                      settriggerMemoProvider(triggerMemoProvider + 1);
                    }}
                  />
                </td>
                <td className={`col-md-3`}>
                  <p className={`m-0 text-uppercase`}>
                    {
                      //x.provider_ref.name
                      x.quotation_number
                    }
                  </p>
                </td>
                <td className={`col-md-7`}>
                  <p className={`m-0 text-start`}>{x.provider_ref.name}</p>
                </td>
              </tr>,
            );
          }
        }
      });
    }
    //return both lists, used for 2 different things but created form the same process
    return [templist, liteList];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.individualAuctionReducer.quotations, triggerMemoProvider]);

  const renderProviders = () => (
    <div className={detailStyles.sectionContainer}>
      <p className={detailStyles.sectionTitle}>Lista de proveedores</p>

      <div className={`${detailStyles.providerTable} ${detailStyles.tableMargin}`}>
        <div className={detailStyles.providerTable__Header}>
          <div className={`${detailStyles.f3}`}>Proveedores</div>
          <div className={`${detailStyles.f1}`}>Total cotizado</div>
          <div className={`${detailStyles.f1}`}>Validez de cotización</div>
          <div className={`${detailStyles.f1}`}>Condiciones de pago</div>
          <div className={`${detailStyles.providerTable__Icon}`}>&nbsp;</div>
        </div>

        <div className={detailStyles.providerTable__Body}>{listProviders}</div>
      </div>
    </div>
  );

  const specialTableFooter = () => {
    return (
      <div className={pendingStyles.f1}>
        <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerTitle}`}>
          Última Compra
        </p>
        <div className='d-flex mx-1'>
          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Proveedor
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Marca
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f3}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Precio Unitario
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Cantidad
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const stringAIncludesB = (A, B) => {
    let AA = String(A).toLowerCase();
    let BB = String(B).toLowerCase();

    return AA.includes(BB);
  };

  const listArticles = useMemo(() => {
    let fullList = [];

    //for each article make a table
    if (
      Array.isArray(counter.individualAuctionReducer.selected_indauction?.article_list) &&
      Array.isArray(counter.individualAuctionReducer.quotations)
    ) {
      counter.individualAuctionReducer.selected_indauction.article_list.forEach((x, index) => {
        //filter by search
        if (
          stringAIncludesB(x.article?.description, articleSearch) ||
          stringAIncludesB(x.article?.id, articleSearch)
        ) {
          //theme
          let mytheme = '';
          if (x.article?.req_authorization) {
            mytheme = 'orange';
          } else {
            mytheme = index % 2 === 0 ? 'blue' : 'darkblue';
          }

          //supers
          let superColumns = [
            <p key='super1'>
              {index + 1}
              <img src={specialTableArrow} className={specialTableStyles.arrow} alt='>' />
              {x.article?.description}
            </p>,
            <p key='super2'>{x.article?.id}</p>,
            <p key='super3'>{x.qty_article} und</p>,
            <p key='super4'>
              {mytheme === 'orange' ? (
                <img src={AlertTriangle} className={detailStyles.filterWhite} alt='alert' />
              ) : (
                <span>&nbsp;</span>
              )}
            </p>,
          ];

          //headers are equal for all

          //body
          let bodyRows = [];
          //find providers of the current article to fill table body. gotta look into every article of every quotation

          //for each quotation
          counter.individualAuctionReducer.quotations.forEach(y => {
            if (y.status === 'solved') {
              // filter by only solved quotations

              let gottenthing = null;

              //for each quotation_article
              if (Array.isArray(y.quotation_article_ref)) {
                const tempobj = y.quotation_article_ref.find(z => z.id_article === x.id_article);
                if (!!tempobj && !!gottenthing === false) {
                  gottenthing = {
                    ...tempobj,
                    provider_id: y.provider,
                    provider_name: y.provider_ref?.name,
                    quotation_total: y.total,
                  };
                }
              }

              //if there the current article of the auction is also inside the quotation, then...
              if (gottenthing) {
                bodyRows.push(
                  <div key={'article' + gottenthing.id_article + ' ' + gottenthing.provider_id}>
                    <p>{gottenthing.provider_name}</p>
                    <p>{gottenthing.brand}</p>
                    {/* <p>{gottenthing.health_register}</p> */}
                    <p>{formatter.format(gottenthing.unit_price)}</p>
                    <p className={specialStyles.smallChild}>{gottenthing.qty_article}</p>
                    <p className={specialStyles.smallChild}>
                      {gottenthing.iva ? gottenthing.iva + '%' : '-'}
                    </p>
                    <p className={specialStyles.smallChild}>
                      {gottenthing.discount ? gottenthing.discount + '%' : '-'}
                    </p>
                    <p>
                      {formatter.format(
                        gottenthing.unit_price * gottenthing.qty_article +
                          gottenthing.unit_price *
                            gottenthing.qty_article *
                            gottenthing.iva *
                            0.01 -
                          //- gottenthing.iva_value
                          gottenthing.unit_price *
                            gottenthing.qty_article *
                            gottenthing.discount *
                            0.01,
                        //- gottenthing.discount_value
                      )}
                    </p>
                    <p>
                      <Checkbox
                        checked={isArticleProviderSelected(
                          gottenthing.id_article,
                          gottenthing.provider_id,
                        )}
                        onChange={() =>
                          setArticleProviderSelected(
                            gottenthing.id_article,
                            gottenthing.provider_id,
                            gottenthing,
                          )
                        }
                        name={'checkarticle' + y.provider + '-' + gottenthing.id_article}
                        color='primary'
                        style={{ padding: 0 }}
                      />
                    </p>
                  </div>,
                );
              }
            } // filter by only solved quotations
          });

          //footer is calculated by a separate "specialTableFooter" function cuz its too long otherwise

          //make special table with the 4 above sections and add them to full list
          fullList.push(
            <SpecialTable
              key={'specialtable' + index}
              theme={mytheme}
              collapse={collapseAll}
              superheaders={superColumns}
              headers={article_headers}
              body={bodyRows}
              footer={specialTableFooter(x)}
            />,
          );
        }
        //only add those in filter
      });
    }

    //return the entire thing
    return fullList;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    counter.individualAuctionReducer.selected_indauction,
    counter.individualAuctionReducer.quotations,
    collapseAll,
    articleSearch,
    triggerMemoProvider,
    triggerMemoArticles,
  ]);

  const btnBack = () => {
    dispatch({
      type: PURCHASEORDER_PRINCIPAL,
      show: false,
    });
  };

  return (
    <div className={tableStyles.container}>
      {(!!counter.individualAuctionReducer.selected_loading || loading) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={`d-flex ${detailStyles.titleBlock}`}>
        <img
          alt='volver'
          src={Back}
          className={detailStyles.backIcon}
          style={{ height: '30px' }}
          onClick={() => btnBack()}
        />
        <h1 className={detailStyles.mainTitle}>
          {store_indauc?.description ? store_indauc.description : 'Subasta'}
        </h1>
      </div>

      <div className={` ${detailStyles.bigContainer}`}>
        <div id='popup-root' />

        <div className={`${IndividualStyles.container2}`}>
          <div className={`d-flex`}>
            <div className={`d-flex ${detailStyles.underMainTitle} ${pendingStyles.f1}`}>
              <p>
                <span className={detailStyles.blueText}>#{store_indauc.auction_number}</span>
                &ensp;&ensp;
                <span className={textColor(solvedPercentage)}>{solvedPercentage}% Respondido</span>
              </p>
            </div>

            <div className={`d-flex flex-row-reverse ${pendingStyles.f1}`}>
              <Tooltip title='Crear Convenio'>
                <img
                  className={detailStyles.titleIcons}
                  src={iconHandshake}
                  alt='handshake'
                  onClick={() => setmodalConvenioGeneral({ show: true })}
                />
              </Tooltip>
              {/* /* -------------------------------- PDF Viwer ------------------------------- */}

              <ModalNew
                title='Detalle de subasta'
                show={showPdf}
                btnNoName={'Cancelar'}
                size='700'
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
              >
                <div>
                  <PdfViewer
                    downloadable
                    file={`data:application/pdf;base64,${base64}`}
                  ></PdfViewer>
                </div>
              </ModalNew>

              <Tooltip title='Imprimir'>
                <img
                  className={`${detailStyles.titleIcons} cursorPointer`}
                  src={iconPrint}
                  alt='imprimir'
                  onClick={() => setShowPdf(true)}
                />
              </Tooltip>

              <Popup
                trigger={
                  <img className={detailStyles.titleIcons} src={iconGearGray} alt='opciones' />
                }
                position='left'
                style={{ padding: 0 }}
                disabled={store_indauc.status === 'solved'}
              >
                <ul className={`${popupStyles.popUpUlAlt} hoverPointer`}>
                  <li className={popupStyles.popUpLiDelete} onClick={() => cancelarSubasta()}>
                    Cancelar subasta
                  </li>
                </ul>
              </Popup>

              <div
                className={detailStyles.btnCarrito}
                onClick={
                  store_indauc.status === 'solved' ? null : () => setmodalPurchase({ show: true })
                }
              >
                <p>Ir al Carrito</p>
                <img src={iconShopCart} alt='carrito' />
              </div>
            </div>
          </div>
        </div>

        <p className={detailStyles.sectionTitle}>Información general</p>
        <div className={IndividualStyles.container2}>
          {/* row 1 */}
          <div className={`d-flex`}>
            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
              onClick={() => setmodalDateChange({ show: true })}
            >
              <p className={IndividualStyles.crudModalLabel}>Fecha de vencimiento</p>
              <input
                className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputs}`}
                type='datetime-local'
                value={store_indauc.date_expiry + 'T' + store_indauc.hour_expiry}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Forma de pago</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={payConditions(store_indauc.pay_conditions)}
                disabled={true}
              />
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
              <p className={IndividualStyles.crudModalLabel}>Autor de la subasta</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={
                  store_indauc.user
                    ? store_indauc.user.first_name + ' ' + store_indauc.user.first_surname
                    : store_indauc.id_user
                }
                disabled={true}
              />
            </div>
          </div>

          {/* row 2 */}
          <div className={`d-flex`}>
            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Tipo de subasta</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={priority(store_indauc.priority)}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Dirección de entrega</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={store_indauc.warehouse?.address}
                disabled={true}
              />
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
              <p className={IndividualStyles.crudModalLabel}>Terminos y condiciones</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={store_indauc.terms}
                disabled={true}
              />
            </div>
          </div>

          {/* row 3 */}
          <div className={`d-flex`}>
            <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
              <p className={IndividualStyles.crudModalLabel}>Observaciones</p>
              <textarea
                id='txtArea'
                rows='10'
                cols='10'
                className={IndividualStyles.textArea}
                value={store_indauc.observations}
                disabled={true}
              ></textarea>
            </div>
          </div>
        </div>

        {/* {(!!counter.individualAuctionReducer.quotations_loading)?
                <div className="mx-auto">
                    <Loader type="Oval" color="#003f80" height={100} width={100} />
                </div>
            :
                renderProviders()
            } */}

        {renderProviders()}

        <div className={detailStyles.sectionContainer}>
          <div className='d-flex justify-content-between'>
            <p className={detailStyles.sectionTitle}>
              Lista de productos (
              {counter.individualAuctionReducer.selected_indauction?.article_list?.length})
            </p>
            <div
              style={{ alignSelf: 'flex-end' }}
              className={`${detailStyles.articleControlIcon}`}
              onClick={() => setCollapseAll(!collapseAll)}
            >
              <img
                className={` ${detailStyles.toggleAll} ${collapseAll ? detailStyles.rotate : ''}`}
                src={auctionDetailExpand}
                alt='Toggle All'
              />
            </div>

            <div
              style={{ alignSelf: 'flex-end', marginBottom: '2px' }}
              className={`
                            ${detailStyles.articleControlIcon} 
                            ${articleSearch !== '' ? detailStyles.expand : ''} 
                        `}
            >
              <input
                type='text'
                className={`
                                ${detailStyles.articleSearch} 
                                ${articleSearch !== '' ? detailStyles.expand : ''} 
                            `}
                onChange={e => setarticleSearch(e.target.value)}
              ></input>
            </div>
          </div>

          <div className={`d-flex flex-row-reverse`}></div>

          <table className={`table mb-0`}>
            <tr className='d-flex'>
              <th className={detailStyles.f3}>
                <span>&nbsp;</span>{' '}
              </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Código </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Cantidad </th>
              <th className={detailStyles.f1}>
                <span>&nbsp;</span>
              </th>
            </tr>
          </table>

          {listArticles}
        </div>
      </div>

      {/*zona detalles */}
      {Array.isArray(counter.individualAuctionReducer.quotations) && modalDetails.show && (
        <DetailsModalCotizacion
          show={modalDetails.show}
          Qid={modalDetails.Qid}
          onHide={() => setmodalDetails({ show: false })}
          payConditions={payConditions}
          formatter={formatter}
          parentTerms={store_indauc.terms}
        ></DetailsModalCotizacion>
      )}

      {/*zona convenio */}
      {modalConvenioGeneral.show && (
        <ModalNew
          title='Nuevo Convenio'
          show={modalConvenioGeneral.show}
          size='500'
          btnYesName='Aceptar'
          btnYesEvent={() => setmodalConvenio({ ...modalConvenio, show: true })}
          onHide={() => setmodalConvenioGeneral({ show: false })}
        >
          <GenericTableNew
            headers={[
              <th key={`1`}>&nbsp;</th>,
              <th key={`2`}>Cotización</th>,
              <th key={`3`}>Seleccionar proveedor</th>,
            ]}
          >
            {listProvidersLite}
          </GenericTableNew>
        </ModalNew>
      )}

      {modalConvenio.show && (
        <DetailsModalConvenio
          show={modalConvenio.show}
          quotation={modalConvenio.quotation}
          onHide={() => setmodalConvenio({ show: false })}
          payConditions={payConditions}
          formatter={formatter}
          parentDesc={store_indauc.description}
        ></DetailsModalConvenio>
      )}

      {/*zona rejection */}
      {modalRejection.show && (
        <ModalNew
          title='Denegación'
          size='400'
          show={modalRejection.show}
          onHide={() => setmodalRejection({ show: false })}
          btnYesName='Guardar'
          btnYesEvent={() => enviarDenegacion()}
          btnNoName='Cancelar'
        >
          <div className={`${pendingStyles.Item}`}>
            <p className={tableStyles.crudModalLabel}>Justificación</p>
            <textarea
              id='justification'
              rows='3'
              cols='10'
              className={`register-inputs ${detailStyles.justification}`}
              value={modalRejection.justification}
              onChange={e =>
                setmodalRejection({ ...modalRejection, justification: e.target.value })
              }
            ></textarea>
          </div>
        </ModalNew>
      )}

      {/*zona cambio fecha */}
      {modalDateChange.show && (
        <ModalNew
          title='Cambio de fecha'
          show={modalDateChange.show}
          size='400'
          btnYesName='Guardar'
          btnYesEvent={() => sendDateChange()}
          onHide={() => setmodalDateChange({ show: false })}
        >
          <div className={`${pendingStyles.Item}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Fecha de vencimiento anterior
            </p>
            <input
              className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputs}`}
              type='datetime-local'
              value={store_indauc.date_expiry + 'T' + store_indauc.hour_expiry}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.Item}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Nueva fecha*
            </p>
            <input
              className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputs}`}
              type='datetime-local'
              value={modalDateChange.newdate}
              min={today.toISOString().split('.')[0]}
              onChange={e => setmodalDateChange({ ...modalDateChange, newdate: e.target.value })}
            />
          </div>

          <div className={`${pendingStyles.Item}`}>
            <p className={tableStyles.crudModalLabel}>Justificación</p>
            <textarea
              id='justification2'
              rows='3'
              cols='10'
              className={`register-inputs ${detailStyles.justification}`}
              value={modalDateChange.justification}
              onChange={e =>
                setmodalDateChange({ ...modalDateChange, justification: e.target.value })
              }
            ></textarea>
          </div>
        </ModalNew>
      )}

      {/*purchase zone */}
      {modalPurchase.show && (
        <DetailsModalPurchase
          show={counter.purchaseOrderReducer.modalPart2}
          selectedAuction={props.selectedAuction}
          selectedProviders={selectedProviders()}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          unsetArticleProviderSelected={unsetArticleProviderSelected}
          onHide={() => setmodalPurchase({ ...modalPurchase, show: false })}
          formatter={formatter}
          setDetails={props.setDetails}
          updateparent={() => setTrigger(trigger + 1)}
          setfirstLayout={props.setDetails}
        ></DetailsModalPurchase>
      )}

      <DetailsModalPurchaseSummary
        hideparent={() => setmodalPurchase({ ...modalPurchase, show: false })}
      />
    </div>
  );
}

export default CurrentAuctionDetails;
