import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Layouts/customTabs.scss';
import '../TableUsers/tableUserStyle.scss';
import pendingStyles from './AuctionPending.module.scss';
import detailStyles from './CurrentAuctionDetails.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';
// import "../TableUsers/tableUserStyle.scss";
// import "../Layouts/customTabs.scss";
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';

import { Tooltip } from '@material-ui/core';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import iconDownload from '../../assets/img/icons/iconDownload.svg';
import iconHandshake from '../../assets/img/icons/iconHandshake.svg';

import { agreement_create } from '../../actions/agreementActions';

function DetailsModalConvenio(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);

  const today = new Date();

  const [nuevoConvenio, setnuevoConvenio] = useState({});

  const validityformat = props.quotation?.quote_validity
    ? new Date(props.quotation?.quote_validity)
    : '';

  useEffect(() => {
    setnuevoConvenio({
      provider: props.quotation?.provider_ref?.id,
      account: counter.loginReducer.currentAccount.id,

      quotation: props.quotation?.id,
      status: 'reqAuth',
      user_created: counter.loginReducer.user_data.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const renderList = () => {
    let table = [];
    let totalval = 0;
    const articles = props.quotation?.quotation_article_ref;
    if (Array.isArray(articles)) {
      table = articles.map(x => {
        if (!isNaN(x.qty_article * x.unit_price)) {
          totalval += x.qty_article * x.unit_price;
        }
        return (
          <tr key={'CotizacionList' + x.id} className='hover-table-row'>
            <td className={`col-md-1`}>{x.id_article}</td>
            <td className={`col-md-4`}>
              <p className={`m-0 ${detailStyles.rowName}`}>{x.article_ref?.description}</p>
            </td>
            <td className={`col-md-1`}>{x.brand}</td>
            <td className={`col-md-1`}>{props.formatter.format(x.unit_price)}</td>
            <td className={`col-md-1`}>{x.qty_article}</td>
            <td className={`col-md-2`}>{x.discount ? x.discount : '-'}</td>
            <td className={`col-md-2  ${detailStyles.rowValue}`}>
              <p className={`m-0 ${detailStyles.rowValue}`}>
                {props.formatter.format(x.qty_article * x.unit_price)}
              </p>
            </td>
          </tr>
        );
      });
    }

    table.push(
      <tr key={'CotizacionListTotal'} className='hover-table-row'>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-4`}>
          <p className={`m-0 ${detailStyles.rowName} ${detailStyles.rowTotal}`}>TOTALES</p>
        </td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-2`}>&nbsp;</td>
        <td className={`col-md-2`}>
          <p className={`m-0 ${detailStyles.rowValue} ${detailStyles.rowTotal}`}>
            {props.formatter.format(totalval)}
          </p>
        </td>
      </tr>,
    );

    return table;
  };

  const renderHeaders = [
    <th key={`HeaderCotizacion1`}>#</th>,
    <th key={`HeaderCotizacion2`}>Descripción</th>,
    <th key={`HeaderCotizacion3`}>Marca</th>,
    <th key={`HeaderCotizacion4`}>Precio&nbsp;Unt.</th>,
    <th key={`HeaderCotizacion5`}>Cant.</th>,
    <th key={`HeaderCotizacion6`}>Descuento</th>,
    <th key={`HeaderCotizacion7`}>Valor&nbsp;Total</th>,
  ];

  const btnYes = () => {
    let error = '';

    if (!!nuevoConvenio.name === false) {
      error = "El campo 'Nombre de convenio' es obligatorio";
    }
    if (!!nuevoConvenio.valid_from === false) {
      error = "El campo 'Vigencia desde' es obligatorio";
    }
    if (!!nuevoConvenio.valid_until === false) {
      error = "El campo 'Vigencia hasta' es obligatorio";
    }

    let startDate = new Date(nuevoConvenio.valid_from);
    let endDate = new Date(nuevoConvenio.valid_until);
    if (endDate < startDate) {
      error = 'el inicio de la vigencia no puede ser despues que el final';
    }

    if (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Datos Incompletos',
        text: error,
      });
      return null;
    }

    MySwal.fire({
      icon: 'info',
      title: '¿Está seguro?',
      text: 'Su solicitud se enviará a autorizaciones',
      confirmButtonText: 'Sí, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(agreement_create(nuevoConvenio, props.onHide()));
      }
    });
  };

  const additionalContent = (
    <div>
      <p className={detailStyles.sectionTitle}>Información del convenio</p>

      <div className='d-flex'>
        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Fecha de creación</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={today.getDate() + '/' + today.getMonth() + '/' + today.getFullYear()}
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Nombre de convenio*</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={nuevoConvenio.name}
            onChange={e => setnuevoConvenio({ ...nuevoConvenio, name: e.target.value })}
          />
        </div>

        <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Condiciones de pago</p>
          <input
            className={`register-inputs ${detailStyles.inputText}`}
            type='text'
            value={
              props.quotation?.pay_conditions
                ? props.payConditions(props.quotation?.pay_conditions)
                : '-'
            }
            disabled={true}
          />
        </div>
      </div>

      <div className='d-flex'>
        <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Vigencia desde*</p>
          <input
            className={`register-inputs ${detailStyles.inputText}`}
            type='date'
            value={nuevoConvenio.valid_from}
            onChange={e => setnuevoConvenio({ ...nuevoConvenio, valid_from: e.target.value })}
          />
        </div>

        <div className={`${pendingStyles.Item} ${pendingStyles.separator}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>&nbsp;</p>
          <p className={`m-0`}>-</p>
        </div>

        <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Hasta*</p>
          <input
            className={`register-inputs ${detailStyles.inputText}`}
            type='date'
            value={nuevoConvenio.valid_until}
            onChange={e => setnuevoConvenio({ ...nuevoConvenio, valid_until: e.target.value })}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          &nbsp;
        </div>
      </div>
    </div>
  );

  return (
    <ModalNew
      title='Nuevo Convenio'
      subtitle={props.quotation?.provider_ref?.name}
      additionalContent={additionalContent}
      show={props.show}
      size='940'
      onHide={props.onHide}
      btnYesEvent={btnYes}
      btnYesName={
        <p className='m-0'>
          <img className={detailStyles.buttonIcons} src={iconHandshake} alt='handshake' /> Solicitar
          Convenio
        </p>
      }
    >
      <p className={detailStyles.sectionTitle}>Detalle de cotización base</p>

      <div className='d-flex'>
        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>N° de Cotización</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={props.quotation?.quotation_number ? props.quotation?.quotation_number : '-'}
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Nombre de subasta de cotización</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={props.parentDesc ? props.parentDesc : '-'}
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Validez</p>
          <div className='d-flex'>
            <input
              className={`${pendingStyles.inputMargin}  register-inputs ${detailStyles.inputText}`}
              type='text'
              value={
                props.quotation?.quote_validity
                  ? validityformat.getDate() +
                    '/' +
                    validityformat.getMonth() +
                    '/' +
                    validityformat.getFullYear()
                  : '-'
              }
              disabled={true}
            />
            <Tooltip title='Descargar PDF'>
              <img
                src={iconDownload}
                alt='Descargar'
                className={`${detailStyles.adjustIconDescargar}`}
                //onClick={()=>dispatch(requisition_export_pdf())}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <GenericTableNew headers={renderHeaders}>{renderList()}</GenericTableNew>

      <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
        <p className={`${IndividualStyles.crudModalLabel}`}>Observaciones</p>
        <textarea
          id='txtArea'
          rows='10'
          cols='10'
          className={IndividualStyles.textArea}
          value={props.quotation?.observations}
          disabled={true}
        ></textarea>
      </div>
    </ModalNew>
  );
}

export default DetailsModalConvenio;
