import { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
//
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { AntSwitchBlue, customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../../helpers';

export default function GeneralParameters() {
  const store = useSelector(state => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;

  const [trigger, setTrigger] = useState(0);
  const [triggerCenter, setTriggerCenter] = useState(0);
  const [data, setData] = useState({
    anestesia: {
      eaccount,
      careTag: 'surgery',
      configTag: 'anesthesia_data_entry_time',
      valueConfig: '',
      timeId: '',
    },
    consumptionCenter: {
      eaccount,
      consumptionCenterId: '',
      createdBy,
      id: '',
      assistanceLots: 0, 
    },
  });

  const { trigger: getTimeOptions, results: timesResults, loader: dxLoader } = useGetMethod();
  const { trigger: getCenterMethod, loader: centerloaderFGet } = useGetMethod();
  const { trigger: getAnestesiaGet, loader: anestesiaLoaderD } = useGetMethod();
  const { trigger: getCenterOptions, results: variableTypes } = useGetMethod();
  const { load: anestesiaLoader, trigger: updateAnestesia } = usePostMethod();
  const { load: centerLoader, trigger: updateCenter } = usePostMethod();
  const consumptionCenterOptions = formatToRcSelect(variableTypes?.results, 'id', 'description');
  const unitsOptions = formatToRcSelect(timesResults?.results, 'tim_id', 'tim_name');

  // --------------------- get  --------------------------
  useEffect(() => {
    async function fetchAsyncData() {
      await getAnestesiaGet({
        url: '/medical/appointment/configuration/',
        objFilters: {
          careTag: 'surgery',
          configTag: 'anesthesia_data_entry_time',
          eaccount,
        },
        doAfterSuccess: anestesia => {
          getCenterMethod({
            url: '/medical/clinicHistory/generalConsumptionCenter/',
            objFilters: { eaccount },
            token: store.loginReducer.Authorization,
            doAfterSuccess: res => {
              if (res?.results?.consumptionCenterId) {
                setData({
                  anestesia: {
                    eaccount,
                    careTag: 'surgery',
                    configTag: 'anesthesia_data_entry_time',
                    valueConfig: anestesia?.results?.value ?? '',
                    timeId: anestesia?.results?.timeId ?? '',
                  },
                  consumptionCenter: {
                    eaccount,
                    assistanceLots: res.results.assistanceLots ?? 0, 
                    consumptionCenterId: res?.results?.consumptionCenterId ?? '',
                    createdBy,
                    id: res?.results?.id ?? '',
                  },
                });
                setTriggerCenter(triggerCenter + 1);
                setTrigger(trigger + 1);
              }
            },
          });
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.loginReducer.Authorization, getTimeOptions, createdBy, eaccount]);
  // --------------------- get options --------------------------
  useEffect(() => {
    async function fetchAsyncData() {
      await getCenterOptions({
        url: '/medical/consumptionCenters/',
        objFilters: { eaccount },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getCenterOptions, store.loginReducer.Authorization, eaccount]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getTimeOptions({
        url: '/medical/generals/times/',
        objFilters: {},
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [store.loginReducer.Authorization, getTimeOptions]);
  // -------------------------------------------------------------

  const onSubmit = async () => {
    if (
      data?.anestesia?.valueConfig &&
      data?.anestesia?.timeId &&
      data?.consumptionCenter?.consumptionCenterId
    ) {
      await updateAnestesia({
        url: '/medical/appointment/configuration/',
        method: 'PUT',
        token: store.loginReducer.Authorization,
        body: data?.anestesia,

        doAfterException: results => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            titleColor: '#1ABCD2',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
      await updateCenter({
        url: '/medical/clinicHistory/generalConsumptionCenter/',
        method: 'PUT',
        token: store.loginReducer.Authorization,
        body: data.consumptionCenter,
        succesAction: () => {
          customSwaltAlertAsistencial({
            icon: 'success',
            titleColor: '#1ABCD2',
            title: 'Actualizado exitosamente',
            text: 'Se ha actualizado exitosamente.',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterException: results => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            titleColor: '#1ABCD2',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        titleColor: '#1ABCD2',
        title: 'Intenta de nuevo',
        text: 'Debe llenar todos los campos',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const render = () => {
    return (
      <>
        {(dxLoader || centerLoader || centerloaderFGet || anestesiaLoader || anestesiaLoaderD) &&
          loader}
        {anestesiaLoader && loader}
        <div className={`${styles.tlnContainer} h-100`}>
          <OrdGenericTemplate className='h-100' titleSize={12} title={'Parámetros generales'}>
            <div className='d-flex flex-column justify-content-between h-100'>
              <div>
                <div className=' row px-2 align-items-center mt-3'>
                  <Col xs={5}>
                    <b className={`m-0 ${styles.text_blueHC}`}>
                      Intervalo de ingreso valores máquina de anestesia
                    </b>
                  </Col>
                  <Col xs={3}>
                    <Form.Control
                      key={trigger}
                      className={`form-control text-muted ord-roundInput`}
                      type='text'
                      placeholder='Escribir...'
                      value={data?.anestesia?.valueConfig}
                      style={{ fontSize: 12 }}
                      onChange={({ target }) => {
                        const dataTemp = data?.anestesia;
                        setData({
                          ...data,
                          anestesia: { ...dataTemp, valueConfig: target?.value },
                        });
                      }}
                    />
                  </Col>
                  <Col xs={3}>
                    <Select
                      key={trigger}
                      classNamePrefix={styles.selectMenu}
                      options={unitsOptions}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      value={unitsOptions?.find(item => item?.value === data?.anestesia?.timeId)}
                      onChange={e => {
                        const dataTemp = data.anestesia;
                        setData({ ...data, anestesia: { ...dataTemp, timeId: e?.value } });
                      }}
                    />
                  </Col>
                </div>
                <div className=' row px-2 align-items-center mt-4'>
                  <Col xs={5}>
                    <b className={`m-0 ${styles.text_blueHC}`}>
                      Centro de consumo general de cirugía
                    </b>
                  </Col>
                  <Col xs={3}>
                    <Select
                      key={triggerCenter}
                      classNamePrefix={styles.selectMenu}
                      options={consumptionCenterOptions}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      value={consumptionCenterOptions?.find(
                        item => item?.value === data?.consumptionCenter?.consumptionCenterId,
                      )}
                      onChange={e => {
                        const dataTemp = data.consumptionCenter;
                        setData({
                          ...data,
                          consumptionCenter: { ...dataTemp, consumptionCenterId: e?.value },
                        });
                      }}
                    />
                  </Col>
                  <Col xs={4}

                  >
                    <label
                      htmlFor='material-switch'
                      className='d-flex align-items-center justify-content-sstart gap-2'
                    >
                      <AntSwitchBlue
                        checked={Boolean(data.consumptionCenter.assistanceLots)}
                        onChange={e => {
                          const dataTemp = data.consumptionCenter;
                        setData({
                          ...data,
                          consumptionCenter: { ...dataTemp, assistanceLots: e.target.checked ? 1 : 0 }
                        }); 
                        }}
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      <span className="text-muted">{data.consumptionCenter.assistanceLots === 1 ? "Inhabilitar" : "Habilitar" }</span>
                    </label>
                  </Col>
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='row'>
                  <Col xs={11} className='mt-5 d-flex justify-content-end align-self-end px-3'>
                    <button className={`${styles.tlnBtnPrimary} btn`} onClick={() => onSubmit()}>
                      Actualizar
                    </button>
                  </Col>
                </div>
              </div>
            </div>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
