import { useSelector } from 'react-redux';

//import SASS && Mui material && icons
import { Drawer, Box } from '@mui/material';
import tableStyle from '../../../components/Layouts/tableStyle.module.scss';

//import Icons
import iconClose from '../../../assets/img/icons/modalClose.svg';
import iconSend from '../../../assets/img/icons/icon_message.svg';
import iconTrash from '../../../assets/img/icons/trashNotes.svg';
import { customSwaltAlertAsistencial, loader } from '../../../helpers';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { usePostMethod } from '../../../Hooks';

export const DrawerHistorialChat = ({
  anchor = 'right',
  open,
  title = '',
  chatList = [],
  onClose,
  onSuccess = () => null,
  patientId,
}) => {
  const store = useSelector(state => state);
  const idUser = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const fullNameUser =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;

  const [comments, setComments] = useState({
    entity: 'userClient',
    comment: '',
  });

  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();
  const { load: loaderDeleteComment, trigger: deleteComment } = usePostMethod();

  const onSubmitComment = () => {
    void sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: { ...comments, userId: idUser, id: patientId },
      doAfterSuccess: () => {
        onSuccess();
        setComments({ ...comments, comment: '' });
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Nota creada',
          text: 'Nota añadida exitosamente',
          showCancelButton: false,
        });
      },
    });
  };

  const onDeleteNote = id => {
    void deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      doAfterSuccess: () => {
        onSuccess();
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Nota eliminada',
          text: 'Nota eliminada exitosamente',
          showCancelButton: false,
        });
      },
    });
  };

  const renderChat = () => {
    return (
      <Box overflow={'scroll'} pb={0}>
        {chatList?.map(chat => (
          <>
            <Box key={chat.id} mb={1} width='100%'>
              <div className={tableStyle.app_container_tras_notes}>
                <span className={tableStyle.app_name_drawer}>{chat.name}</span>
                {idUser === chat.userId && (
                  <img
                    src={iconTrash}
                    width={15}
                    alt='trash-notes'
                    className='cursorPointer'
                    onClick={() => {
                      customSwaltAlertAsistencial({
                        title: '¿Está seguro?',
                        text: 'Se eliminará la nota',
                        confirmButtonText: 'Si, continuar',
                      }).then(response => {
                        if (response.isConfirmed) {
                          onDeleteNote(chat.id);
                        }
                      });
                    }}
                  />
                )}
              </div>
              <p className={tableStyle.app_description_drawer}>{chat?.message}</p>
              <div className={tableStyle.app_container_date_drawer}>
                <span className={tableStyle.app_date_drawer}>{chat?.date}</span>
              </div>
            </Box>
            <Box className={tableStyle.app_border_drawer} mb={3} />
          </>
        ))}
      </Box>
    );
  };
  const renderTextarea = () => {
    return (
      <div className={tableStyle.app_container_form_drawer}>
        <Form.Group style={{ marginBottom: '10px' }}>
          <Form.Label className={tableStyle.app_title_input}>
            <span>{fullNameUser}</span>
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={4}
            maxLength='170'
            value={comments.comment}
            onChange={e => setComments({ ...comments, comment: e.target.value })}
            placeholder='Escribir...'
          />
        </Form.Group>
        <div className={tableStyle.app_container_date_drawer}>
          <img
            src={iconSend}
            className={'cursorPointer'}
            alt='iconMessage'
            onClick={onSubmitComment}
          />
        </div>
      </div>
    );
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={() => onClose()}>
      {(loadCreateComments || loaderDeleteComment) && loader}
      <Box padding={3}>
        <Box mb={3}>
          <img
            className={`${tableStyle.closeDrawer} pointer`}
            src={iconClose}
            alt='close'
            onClick={() => onClose()}
            height={'28px'}
            width={'28px'}
          />
        </Box>

        <Box className={tableStyle.app_title_drawer} mb={2}>
          {title}
        </Box>

        <div className={tableStyle.app_container_content_drawer}>{renderChat()}</div>

        {renderTextarea()}
      </Box>
    </Drawer>
  );
};
