//react
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

//components
import { Box } from '@mui/material';
import Select from 'react-select';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { Button, Text } from '../../components/UI/atoms';
import { CardCollectionBase, CardCollectionDynamic } from '../Admissions/components';

//icons
import canceled from '../../assets/img/icons/Alert.svg';
import add from '../../assets/img/icons/grayAdd.svg';
import iconDiscount from '../../assets/img/icons/iconDiscount.svg';
import close from '../../assets/img/icons/modalClose.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import percent from '../../assets/img/icons/percent.svg';
import questionBlueClear from '../../assets/img/icons/questionBlueClear.svg';
import searchPatient from '../../assets/img/icons/searchPatient.svg';

//styles
import { useTheme } from 'styled-components';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import styles from '../../components/Layouts/tableStyle.module.scss';

//hooks
import { useGetMethod, usePostMethod } from '../../Hooks';

//helpers
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import {
  customSwaltAlertAsistencial,
  isEmptyOrUndefined,
  loader,
  formatteDocument
} from '../../helpers';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../helpers/numberFormatting';

const typesProviders = {
  glasses: 'Lentes',
  contactsGlasses: 'Lentes de contacto',
  laboratory: 'Laboratorio',
};

const Title = ({ text }) => (
  <ul className='mb-3 px-4'>
    <li className={`${styles?.ordAquaMarineTextImportant} ${styles.f17}`}>{text}</li>
  </ul>
);

const localeSeparators = getLocaleSeparators()

const CardValuesPay = ({ amount, totalAmount, discount, advance }) => {
  return (
    <Box sx={{ width: '300px' }} mb={4} ml={2} className={`${styles.ordDarkBlueText}`}>
      <Box display={'flex'} gap={1} justifyContent={'space-between'} mb={1}>
        <div>Valor del servicio</div>
        <div>{formatNumberWithoutDecimals(amount || 0)}</div>
      </Box>

      <Box display={'flex'} gap={1} justifyContent={'space-between'} mb={1}>
        <div>Abono</div>
        <div>{formatNumberWithoutDecimals(advance || 0)}</div>
      </Box>

      <Box display={'flex'} gap={1} justifyContent={'space-between'} mb={1}>
        <div>Descuento</div>
        <div>{formatNumberWithoutDecimals(discount || 0)}</div>
      </Box>

      <Box
        display={'flex'}
        gap={1}
        justifyContent={'space-between'}
        mb={1}
        className={`fw-bold ${styles.f17}`}
      >
        <div>Total a pagar</div>
        <div>{formatNumberWithoutDecimals(totalAmount || 0)}</div>
      </Box>
    </Box>
  );
};

export const NewOrderProvider = () => {
  //hooks
  const { colors } = useTheme();
  const location = useLocation();
  const history = useHistory();

  // local states
  const [search, setSearch] = useState('');
  const store = useSelector(state => state);
  const eaccount = store.loginReducer.currentAccount.id;
  const locationData = location?.state?.data;
  const subscription = location?.state?.data?.subscription;
  const subscriptionDetail = location?.state?.data?.subscriptionDetail;
  const token = store.loginReducer.Authorization;
  const siteId = store.userReducer.site;
  const userId = store.loginReducer.user_data.id;

  //states cardCollections
  const [siteResolutions, setSiteResolutions] = useState([])
  useMemo(() => {
    const tempArr = store.userReducer?.resolutions.map(res => ({value: res.id, label: res.name}))
    setSiteResolutions(tempArr) 
  }, [store.userReducer?.resolutions ])

  const [valuesPay, setValuesPay] = useState({
    amount: 0,
    discount: 0,
    advance: 0,
    amountTotal: 0,
    valueMissing: 0,
  });

  const [selectedCollections, setSelectedCollections] = useState([]);

  const [collectionCrud, setCollectionCrud] = useState({
    isActiveAddNewCollection: true,
    options: [],
  });

  const initialStateModal = {
    isOpen: false,
    temp: undefined,
    services: {
      listIdServicesChecked: [],
      trigger: 0,
      filters: {
        page: 1,
        perpage: 10,
        pageRangeDisplayed: 3,
        search: '',
        eaccount,
      },
    },

    providers: {
      trigger: 0,
      providerChecked: undefined,
      filters: {
        page: 1,
        perpage: 10,
        pageRangeDisplayed: 3,
        eaccount,
      },
    },
  };

  const initialStateModalDiscount = {
    isOpen: false,
    data: {
      hasDiscount: false,
      discountAmount: '',
      discountComments: '',
    },
  };

  //states modal laboratory
  const [modalTabLaboratory, setModalTabLaboratory] = useState(initialStateModal);
  const [modalDiscount, setModalDiscount] = useState(initialStateModalDiscount);

  //states triggers
  const [listTriggers, setListTriggers] = useState({
    triggerDocument: 0,
  });

  //selects
  const [listDocumentTypes, setListDocumentTypes] = useState(undefined);

  const [listInsurances, setListInsurances] = useState(undefined);

  const [listContracts, setListContracts] = useState(undefined);

  const [listIncomeGroup, setListIncomeGroup] = useState(undefined);

  const [listServices, setListServices] = useState(undefined);

  const [listDoctors, setListDoctors] = useState(undefined);

  const [listProviders, setListProviders] = useState(undefined);

  const [listServicesType, setListServicesType] = useState(undefined);

  const [listAffiliationTypes, setListAffiliationTypes] = useState(undefined);

  const [fieldStatus, setFieldStatus] = useState({
    documentNumberStatus: false,
    corporateClientStatus: false,
    authorizationStatus: false,
    cluAmountStatus: false,
  });

  //state http
  const [data, setData] = useState({
    userId, // general number
    siteId, // general number
    documentNumber: undefined, // general number
    patientName: undefined, // general number
    patientId: undefined, // general number
    typeId: undefined, // general number
    corporateClient: undefined, // general number
    providerId: undefined, // general number
    lensTypeId: undefined, // number
    laboratoryId: undefined, // number
    discountId: undefined, // number
    authorization: undefined, // general string
    cluAmount: undefined, // general number
    cClientAmount: undefined, // general number
    totalAmount: undefined, // general number
    hasDiscount: undefined, //number
    discountAmount: undefined, //number
    discountComments: undefined, //string
    insurance: undefined, //string
    insuranceTagName: undefined, //string
    insuranceLabel: undefined, //string
    populationId: undefined, //string
    contractId: undefined, //string
    category: undefined, //string
    objService: { value: '', label: '' },
    objProvider: { value: '', label: '' },
    objLaboratory: { value: '', label: '' },
    objGlasses: { value: '', label: '' },
    objDoctor: { value: '', label: '' },
    objListAffiliation: { value: '', label: '' },
    deposits: [],
    services: [],
    resolutionId: undefined,
  });

  const { triggerDocument } = listTriggers;

  const { corporateClientStatus, documentNumberStatus, authorizationStatus } = fieldStatus;

  const {
    category,
    documentNumber,
    patientName,
    patientId,
    populationId,
    contractId,
    insuranceTagName,
    objDoctor,
    objListAffiliation,
    authorization,
    cluAmount,
    services,
    insuranceLabel,
    insurance,
    resolutionId
  } = data;

  //////// HTTPS GET ////////////
  const { load: loaderListDocumentTypes, trigger: getListDocumentTypes } = useGetMethod();

  const { trigger: getListPatient, load: loaderListPatient } = useGetMethod();

  const { trigger: getListGlassesType, load: loaderListGlassesType } = useGetMethod();

  const { trigger: getInfoPatient, load: loaderInfoPatient } = useGetMethod();

  const { results: listBank, load: loaderListBank, trigger: getListBank } = useGetMethod();

  const {
    results: listDepositType,
    load: loaderListDepositType,
    trigger: getListDepositType,
  } = useGetMethod();

  const {
    results: listFranchise,
    load: loaderListFranchise,
    trigger: getListFranchise,
  } = useGetMethod();

  const {
    results: resultsServices,
    load: loaderListServices,
    trigger: getListServices,
  } = useGetMethod();

  const {
    results: resultsProviders,
    load: loaderListProviders,
    trigger: getListProviders,
  } = useGetMethod();

  const { load: loaderListDoctors, trigger: getListDoctors } = useGetMethod();

  const { load: loaderListServicesType, trigger: getListServicesType } = useGetMethod();

  const { load: loaderListLaboratory, trigger: getListLaboratory } = useGetMethod();

  const { load: loaderListAffiliationTypes, trigger: getListAffiliationTypes } = useGetMethod();

  //////// HTTPS POST ////////////
  const { load: loaderMedicalAppointmentPrice, trigger: postMedicalAppointmentPrice } =
    usePostMethod();

  const { trigger: sendDataForm, load: loaderSendDataForm } = usePostMethod();

  //////// EFFECTS ////////////
  useEffect(() => {
    if (!locationData) {
      history.push('/admisión/inicio');
    }
  }, [locationData, history]);

  useEffect(() => {
    if (subscriptionDetail) {
      const subscriptionValue = locationData?.bills
        ?.filter(el => !el.isCancelled)
        ?.reduce((accumulated, currentValue) => {
          return Number(accumulated || 0) + Number(currentValue.total || 0);
        }, 0);

      setData(state => ({
        ...state,
        documentNumber: locationData?.patient?.doc || '-',
        patientName: locationData?.patient?.name || '-',
        insurance: locationData?.companyName || '-',
        insuranceLabel: locationData?.companyName || '-',
        contractId: locationData?.contractName || '-',
        authorization: locationData?.authorization || '-',
        cluAmount: locationData?.totalAmount || 0,

        services: locationData?.orderServices?.length
          ? [
              {
                label: locationData?.orderServices[0]?.name || '-',
                value: locationData?.orderServices[0]?.name || '-',
              },
            ]
          : [],

        objProvider: {
          label: locationData?.providerName || '-',
          value: locationData?.providerName || '-',
        },

        objLaboratory: {
          label: locationData?.labName || '-',
          value: locationData?.labName || '-',
        },

        objGlasses: {
          label: locationData?.lensType || '-',
          value: locationData?.lensType || '-',
        },

        objListAffiliation: {
          label:
            locationData?.affiliationTypeName || locationData?.patient?.affiliationTypeName || '-',
          value: locationData?.affiliationTypeId || locationData?.patient?.affiliationTypeId || '-',
        },
      }));

      setListContracts({
        label: locationData?.contractName,
        value: locationData?.contractName,
      });

      setListIncomeGroup({
        label: locationData?.incomeGroupName || '-',
        value: locationData?.incomeGroupId || '-',
      });

      setValuesPay(state => ({
        ...state,
        amountTotal:
          locationData?.totalAmount -
          (subscriptionValue + Number(locationData?.discountAmount | 0)),
        amount: locationData?.totalAmount || 0,
        discount: Number(locationData?.discountAmount | 0),
        advance: subscriptionValue || 0,
      }));
    }
  }, [locationData, subscriptionDetail]);

  useEffect(() => {
    if (!subscriptionDetail) {
      if (triggerDocument && documentNumber) {
        getListPatient({
          token: token,
          objFilters: { docFilter: documentNumber },
          url: `/medical/patient/listPatient/`,
        }).then(res => {
          if (res?.results?.length > 1 || !res?.results?.length) {
            setData(state => ({
              ...state,
              patientName: '',
              patientId: undefined,
            }));

            return customSwaltAlertAsistencial({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: 'Paciente no encontrado',
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
            });
          }

          setData(state => ({
            ...state,
            patientName: `${res?.results[0]?.first_name} ${res?.results[0]?.last_name}`,
            patientId: res?.results[0]?.id,
            objListAffiliation: {
              value: res?.results[0]?.affiliationTypeId,
              label: res?.results[0]?.affiliationTypeName,
            },
          }));

          getInfoPatient({
            token: token,
            objFilters: { eaccount, id: res?.results[0]?.id },
            url: `/medical/patient/`,
          }).then(res => {
            if (res?.success) {
              const format = res?.results?.contracts?.map(insurance => ({
                ...insurance,
                label: insurance?.corporateClient,
                value: insurance?.corporateClientId,
              }));

              setListInsurances(format);

              onChangeFieldState({ key: 'corporateClientStatus', value: true });
            }
          });
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerDocument, eaccount, getInfoPatient, getListPatient, subscriptionDetail, token]);

  useEffect(() => {
    if (!subscriptionDetail) {
      if (modalTabLaboratory?.services?.trigger) {
        getListServices({
          url: '/medical/generals/serviceByContract/',
          token: token,
          objFilters: {
            ...modalTabLaboratory?.services?.filters,
            contractId,
            srvType: locationData?.serviceTypes?.[0],
          },
        }).then(res => {
          if (res.success) {
            const format = res?.results?.map(service => ({
              ...service,
              label: service?.srvName,
              value: service?.srvId,
            }));

            setListServices(format);
          }
        });
      }
    }
  }, [
    modalTabLaboratory?.services?.trigger,
    contractId,
    getListServices,
    locationData?.serviceTypes,
    subscriptionDetail,
    modalTabLaboratory.services.filters,
    token,
  ]);

  useEffect(() => {
    if (!subscriptionDetail) {
      if (modalTabLaboratory?.providers?.trigger) {
        getListProviders({
          url: '/medical/admissions/providers/',
          token: token,
          objFilters: {
            id_account: String(eaccount),
            service_type: listServicesType?.find(el => el?.tag === 'lab_studies')?.id,
          },
        }).then(res => {
          if (res.success) {
            const format = res?.results?.map(provider => ({
              ...provider,
              label: `${provider?.id}-${provider?.name}`,
              value: provider?.id,
            }));

            setListProviders(format);
          }
        });
      }
    }
  }, [
    modalTabLaboratory?.providers?.trigger,
    eaccount,
    getListProviders,
    listServicesType,
    subscriptionDetail,
    token,
  ]);

  useEffect(() => {
    getListBank({
      url: '/admin/bankingEntities',
      token: token,
      objFilters: { eaccount },
    });

    getListAffiliationTypes({
      url: '/medical/generals/affiliation-types/',
      objFilters: { eaccount },
      token: token,
    }).then(res => {
      if (res.success) {
        const format = res?.results?.map(affiliation => ({
          ...document,
          label: affiliation?.affiliationTypeName,
          value: affiliation?.affiliationTypeId,
        }));

        setListAffiliationTypes(format);
      }
    });

    getListDepositType({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount },
    }).then(res => {
      if (res?.success) {
        setCollectionCrud(state => ({
          ...state,
          options: res?.results
            ?.map(deposit => ({
              label: deposit?.depoTypeName,
              value: deposit?.depoTypeId,
              ...deposit,
            }))
            ?.filter(
              el =>
                el?.value !==
                selectedCollections?.find(state => state?.depoTypeId === el?.value)?.depoTypeId,
            ),
        }));
      }
    });

    getListFranchise({
      url: '/accounting/franchise/',
      token: token,
      objFilters: { eaccount },
    });

    if (!subscriptionDetail) {
      getListDocumentTypes({
        token,
        url: '/payroll/document_types/',
      }).then(res => {
        if (res.success) {
          const format = res?.results
            ?.filter(el => el?.active)
            ?.map(document => ({
              ...document,
              label: document?.description,
              value: document?.id,
            }));

          setListDocumentTypes(format);
        }
      });

      getListDoctors({
        url: '/medical/generals/medicalUsers/',
        token: token,
        objFilters: { eaccount, status: 'enabled' },
      }).then(res => {
        if (res.success) {
          const format = res?.results?.map(doctor => ({
            ...doctor,
            label: doctor?.user,
            value: doctor?.id,
          }));

          setListDoctors(format);
        }
      });

      getListServicesType({
        url: '/medical/services-type',
        token: token,
      }).then(res => {
        if (res.success) {
          getListProviders({
            url: '/medical/admissions/providers/',
            token: token,
            objFilters: {
              id_account: String(eaccount),
              service_type: res?.results?.find(el => el?.tag === 'lab_studies')?.id,
            },
          }).then(res => {
            if (res.success) {
              const format = res?.results?.map(provider => ({
                ...provider,
                label: `${provider?.id}-${provider?.name}`,
                value: provider?.id,
              }));

              setListProviders(format);
            }
          });

          const format = res?.results?.map(type => ({
            ...type,
            label: type?.name,
            value: type?.id,
          }));

          setListServicesType(format);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriptionDetail,
    token,
    eaccount,
    getListGlassesType,
    getListLaboratory,
    getListServicesType,
    getListDoctors,
    getListFranchise,
    getListDocumentTypes,
    getListAffiliationTypes,
    getListBank,
    getListDepositType,
    getListProviders,
  ]);

  //table
  const headers = [
    {
      title: 'Servicio',
      className: `px-4 text-start col-7`,
    },
    {
      title: 'Valor',
      className: `px-4 text-end col-4`,
    },
    {
      title: <>&nbsp;&nbsp;</>,
      className: 'text-center col-1',
    },
  ];

  const headerSidebar = [
    {
      title: '',
      className: `text-center`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center col-6`,
    },
    {
      title: 'Monto',
      className: `pe-4 text-end col-6`,
    },
  ];

  const servicesHeaders = [
    {
      title: 'Servicio',
      className: `px-4 text-start col-7`,
    },
    {
      title: 'Valor',
      className: `px-4 text-end col-4`,
    },
    {
      title: <>&nbsp;&nbsp;</>,
      className: 'text-center px-2 col-1',
    },
  ];

  const providersHeaders = [
    {
      title: 'Proveedor',
      className: `px-4 text-start col-7`,
    },
    {
      title: 'Nit',
      className: `px-4 text-center col-4`,
    },
    {
      title: <>&nbsp;&nbsp;</>,
      className: 'text-center px-2 col-1',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-4`}>{item?.srvName || '-'}</td>

            <td className={`text-end px-4`}>
            {item?.srvPrice ? formatNumberWithoutDecimals(item?.srvPrice) : '-'}
            </td>

            <td className={`text-end px-4`}>
              {item === 1 ? (
                '-'
              ) : (
                <img
                  className={`${styles.hoverOrdAquaMarine} pointer`}
                  src={close}
                  height={20}
                  width={20}
                  alt='close'
                  onClick={() => onDeleteService(item)}
                />
              )}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataSidebar = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.orderId} className={`hover-table-row `}>
            <td className={`text-center px-3`}>
              {item?.isCancelled ? (
                <OverlayTrigger
                  placement='top'
                  delay={{ show: 150, hide: 150 }}
                  overlay={<Tooltip>Pago anulado</Tooltip>}
                >
                  <img src={canceled} alt='pago anulado' height={16} width={16} />
                </OverlayTrigger>
              ) : (
                ''
              )}
            </td>
            <td className={`text-center px-2`}>{item?.date || '-'}</td>

            <td className={`text-end pe-4`}>
            {item?.total ? formatNumberWithoutDecimals(item?.total) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const servicesFormatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-4`}>{item?.srvName || '-'}</td>

            <td className={`text-end px-4`}>
            {item?.srvPrice ? formatNumberWithoutDecimals(item?.srvPrice) : '-'}
            </td>

            <td className={`text-center px-2`}>
              <input
                placeholder='Escribir...'
                className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear} form-check-input p1`}
                type='checkbox'
                checked={
                  isEmptyOrUndefined(modalTabLaboratory?.temp)
                    ? modalTabLaboratory?.services?.listIdServicesChecked?.filter(
                        el => el?.srvId === item?.srvId,
                      )?.length
                    : modalTabLaboratory?.temp?.services?.listIdServicesChecked?.filter(
                        el => el?.srvId === item?.srvId,
                      )?.length
                }
                onChange={({ target }) => {
                  const checked = target.checked;
                  const services = isEmptyOrUndefined(modalTabLaboratory?.temp)
                    ? modalTabLaboratory?.services?.listIdServicesChecked
                    : modalTabLaboratory?.temp?.services?.listIdServicesChecked;

                  const serviceExists = services?.filter(el => el?.srvId === item?.srvId).length;
                  let format = services;

                  if (!checked && serviceExists) {
                    format = services?.filter(el => el?.srvId !== item?.srvId);
                  }

                  if (checked && !serviceExists) {
                    format = services?.length ? [...services, item] : [item];
                  }

                  setModalTabLaboratory(state => ({
                    ...state,
                    temp: {
                      services: {
                        ...state.services,
                        listIdServicesChecked: format,
                      },
                      providers: {
                        ...state?.providers,
                        providerChecked: !format?.length
                          ? undefined
                          : state?.temp?.providers?.providerChecked,
                      },
                    },
                  }));

                  if (format?.length) {
                    onGetListProvidersByServices(format);
                  } else {
                    getListProviders({
                      url: '/medical/admissions/providers/',
                      token: token,
                      objFilters: {
                        id_account: String(eaccount),
                        service_type: listServicesType?.find(el => el?.tag === 'lab_studies')?.id,
                      },
                    }).then(res => {
                      if (res.success) {
                        const format = res?.results?.map(provider => ({
                          ...provider,
                          label: `${provider?.id}-${provider?.name}`,
                          value: provider?.id,
                        }));

                        setListProviders(format);
                      }
                    });
                  }
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const providersFormatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-4`}>{item?.name || '-'}</td>

            <td className={`text-center px-4`}>{item?.nit || '-'}</td>

            <td className={`text-center px-2`}>
              <div className={`${styles.formCheckInputRadioAsistencial} ${styles.radioClass}`}>
                <input
                  style={{ width: '16px', height: '16px' }}
                  type='radio'
                  checked={
                    isEmptyOrUndefined(modalTabLaboratory?.temp)
                      ? modalTabLaboratory?.providers.providerChecked === item?.id
                      : modalTabLaboratory?.temp?.providers.providerChecked === item?.id
                  }
                  name='providerRadio'
                  className={`pointer`}
                  onChange={() => {
                    if (!modalTabLaboratory?.temp?.services?.listIdServicesChecked?.length) {
                      return customSwaltAlertAsistencial({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: 'Seleccione un servicio',
                        showCancelButton: false,
                      });
                    }

                    setModalTabLaboratory(state => ({
                      ...state,
                      temp: {
                        ...state.temp,
                        providers: {
                          ...state.providers,
                          providerChecked: item?.id,
                        },
                      },
                    }));
                  }}
                />
              </div>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const onGetListProvidersByServices = listServices => {
    if (!listServices?.length) {
      return new Error('Digite una lista de servicios valida');
    }

    getListProviders({
      url: '/medical/admissions/providers/',
      token: token,
      objFilters: {
        eaccount,
        services: listServices?.map(el => el?.srvId)?.join(),
        service_type: listServicesType?.find(el => el?.tag === 'lab_studies')?.id,
      },
    });
  };

  const onChangeSelectServices = ({ idService, labelService }) => {
    const body = {
      userId,
      category,
      idService,
      population: populationId,
      idContractCorporateClient: contractId,
      patient: patientId,
      modality: 'on_site',
      affiliationTypeId: objListAffiliation?.value,
    };

    postMedicalAppointmentPrice({
      url: `/medical/appointment/medicalAppointmentPrice/`,
      token: token,
      method: 'POST',
      noAlert: true,
      body: body,
      doAfterSuccess: response => {
        if (response?.success) {
          const formatDataService = {
            value: idService,
            label: labelService,
            id: idService,
            cluAmount: Number(response?.results?.copaymentPrice),
            cClientAmount: Number(response?.results?.companyPrice),
            name: labelService,
          };

          setValuesPay({
            ...valuesPay,
            advance: 0,
            discount: 0,
            amount: Number(response?.results?.copaymentPrice),
            amountTotal: Number(response?.results?.copaymentPrice),
            valueMissing: Number(response?.results?.copaymentPrice),
          });

          setData(state => ({
            ...state,
            cluAmount: Number(response?.results?.copaymentPrice),
            services: [formatDataService],
          }));
        }
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onChangeTriggers = ({ key }) => {
    setListTriggers(state => ({
      ...state,
      [key]: state[key] + 1,
    }));
  };

  const onChangeState = ({ key, value }) => {
    setData(state => ({
      ...state,
      [key]: value,
    }));
  };

  const onChangeFieldState = ({ key, value }) => {
    setFieldStatus(state => ({
      ...state,
      [key]: value,
    }));
  };

  const onToggleModalLaboratory = isOpenParam => {
    setModalTabLaboratory(state => ({
      ...state,
      isOpen: isOpenParam | !state?.isOpen,
    }));
  };

  const onChangeValueCardDynamic = (fields, depoTypeId) => {
    if (fields?.amount) {
      const pendingAmount =
        fields?.amount > valuesPay.amountTotal ? Number(fields?.amount) - valuesPay.amountTotal : 0;

      const format = selectedCollections?.map(collection => {
        if (collection?.depoTypeId === depoTypeId) {
          return {
            ...collection,
            ...fields,
            pendingAmount,
            donateChangeName: '',
            donateChange: '',
            donation: '',
            turnedDelivered: '',
            turnedDeliveredName: '',
          };
        }
        return collection;
      });

      const totalAmountClient = format?.reduce((accumulated, currentValue) => {
        return Number(accumulated || 0) + Number(currentValue.amount || 0);
      }, 0);

      const valueMissing =
        Number(valuesPay.amountTotal || 0) -
        Number(totalAmountClient === 1 ? 0 : totalAmountClient);

      setValuesPay(prevState => ({
        ...prevState,
        valueMissing: fields?.amount?.length <= 1 ? valuesPay.amountTotal : valueMissing,
      }));

      setSelectedCollections(format);
    } else {
      const format = selectedCollections?.map(collection => {
        if (collection?.depoTypeId === depoTypeId) {
          return {
            ...collection,
            ...fields,
          };
        }
        return collection;
      });

      setSelectedCollections(format);
    }
  };

  const onClearHeaderState = ({ typeId, document, insurance, affiliation }) => {
    if (typeId) {
      setData(state => ({
        ...state,
        typeId,
        documentNumber: '',
        patientName: '',
        insurance: undefined,
        insuranceLabel: undefined,
        authorization: '',
        cluAmount: '',
        services: [],
        objService: { value: '', label: '' },
        objProvider: { value: '', label: '' },
        objGlasses: { value: '', label: '' },
        objLaboratory: { value: '', label: '' },
        objDoctor: { value: '', label: '' },
        objListAffiliation: { value: '', label: '' },
      }));

      setModalTabLaboratory(initialStateModal);
      setValuesPay({
        amount: 0,
        discount: 0,
        advance: 0,
        amountTotal: 0,
        valueMissing: 0,
      });
      setListInsurances(undefined);
      setListContracts(undefined);
      setListIncomeGroup(undefined);
      setListServices(undefined);
    }

    if (document) {
      setData(state => ({
        ...state,
        insurance: '',
        insuranceLabel: '',
        authorization: '',
        cluAmount: '',
        services: [],
        objService: { value: '', label: '' },
        objProvider: { value: '', label: '' },
        objGlasses: { value: '', label: '' },
        objLaboratory: { value: '', label: '' },
        objDoctor: { value: '', label: '' },
        objListAffiliation: { value: '', label: '' },
      }));
      setModalTabLaboratory(initialStateModal);
      setListContracts(undefined);
      setListIncomeGroup(undefined);
      setListInsurances(undefined);
      setListServices(undefined);
      setValuesPay({
        amount: 0,
        discount: 0,
        advance: 0,
        amountTotal: 0,
        valueMissing: 0,
      });
    }

    if (insurance) {
      setData(state => ({
        ...state,
        cluAmount: '',
        authorization: '',
        services: [],
        objService: { value: '', label: '' },
        objProvider: { value: '', label: '' },
        objGlasses: { value: '', label: '' },
        objLaboratory: { value: '', label: '' },
        objDoctor: { value: '', label: '' },
      }));
    }

    if (affiliation) {
      setData(state => ({
        ...state,
        cluAmount: '',
        services: [],
        objService: { value: '', label: '' },
        objProvider: { value: '', label: '' },
        objGlasses: { value: '', label: '' },
        objLaboratory: { value: '', label: '' },
        objDoctor: { value: '', label: '' },
        objListAffiliation: { value: '', label: '' },
      }));
    }

    setModalTabLaboratory(initialStateModal);

    setValuesPay({
      amount: 0,
      discount: 0,
      advance: 0,
      amountTotal: 0,
      valueMissing: 0,
    });
  };

  const onClearModalState = () => {
    setModalTabLaboratory(state => ({
      ...state,
      isOpen: false,
      temp: undefined,
      services: {
        ...state.services,
        trigger: state?.services?.trigger + 1,
        filters: {
          ...state.services?.filters,
          search: undefined,
        },
      },
    }));
  };

  const onClearModalDiscountState = () => {
    setModalDiscount(initialStateModalDiscount);
  };

  const onBtnYesModalLaboratory = () => {
    if (
      modalTabLaboratory?.temp === undefined &&
      modalTabLaboratory?.services?.listIdServicesChecked.length &&
      !isEmptyOrUndefined(modalTabLaboratory?.providers?.providerChecked)
    ) {
      setModalTabLaboratory(state => ({
        ...state,
        isOpen: false,
      }));

      return;
    }

    if (
      !modalTabLaboratory?.temp?.services?.listIdServicesChecked?.length ||
      isEmptyOrUndefined(modalTabLaboratory?.temp?.providers?.providerChecked)
    ) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un servicio y un proveedor válido',
        showCancelButton: false,
      });
    }

    const body = {
      userId,
      category,
      affiliationTypeId: objListAffiliation?.value,
      services: modalTabLaboratory?.temp?.services?.listIdServicesChecked
        ?.map(el => el?.srvId)
        ?.join(),
      population: populationId,
      idContractCorporateClient: contractId,
      patient: patientId,
      modality: 'on_site',
    };

    postMedicalAppointmentPrice({
      url: `/medical/appointment/medicalAppointmentPrice/`,
      token: token,
      method: 'POST',
      body: body,
      noAlert: true,
      doAfterSuccess: response => {
        if (response?.success) {
          const amountTotal = response?.results?.reduce((accumulated, currentValue) => {
            return Number(accumulated || 0) + Number(currentValue?.copaymentPrice || 0);
          }, 0);

          setValuesPay({
            ...valuesPay,
            amountTotal,
            advance: 0,
            discount: 0,
            amount: amountTotal,
            valueMissing: amountTotal,
          });

          setData(state => ({
            ...state,
            cluAmount: amountTotal,
          }));

          setModalTabLaboratory(state => ({
            ...state,
            temp: undefined,
            isOpen: false,
            providers: state?.temp?.providers,
            services: {
              ...state?.temp?.services,
              listIdServicesChecked: response?.results?.map(el => {
                const serviceName = state?.temp?.services?.listIdServicesChecked?.find(
                  service => service?.srvId === el?.srvId,
                )?.srvName;
                return {
                  contractServiceId: '',
                  label: serviceName,
                  srvName: serviceName,
                  name: serviceName,
                  srvId: el?.srvId,
                  id: el?.srvId,
                  value: el?.srvId,
                  srvPrice: Number(el?.copaymentPrice),
                  cluAmount: Number(el?.copaymentPrice),
                  cClientAmount: Number(el?.companyPrice),
                };
              }),
              filters: { ...state.services.filters, search: undefined },
              trigger: state?.services?.trigger + 1,
            },
          }));
        }
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  const onBtnYesModalDiscount = () => {
    const { discountComments, discountAmount } = modalDiscount.data;

    if (isEmptyOrUndefined(discountAmount) || Number(discountAmount) > 100) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un porcentaje de descuento valido',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(discountComments.trim())) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un comentario correcto',
        showCancelButton: false,
      });
    }

    setModalDiscount(state => ({
      ...state,
      isOpen: false,
      data: { ...state.data, hasDiscount: true },
    }));

    customSwaltAlertAsistencial({
      icon: 'success',
      title: 'Creada exitosamente',
      text: 'Se ha creado una solicitud de descuento para el paciente: ' + patientName,
      showCancelButton: false,
    });
  };

  const onDeleteService = service => {
    const format = modalTabLaboratory?.services?.listIdServicesChecked?.filter(
      el => el?.srvId !== service?.srvId,
    );
    setModalTabLaboratory(state => ({
      ...state,
      services: {
        ...state.services,
        listIdServicesChecked: format,
      },
      providers: {
        ...state.providers,
        providerChecked: format?.length ? state?.providers?.providerChecked : undefined,
      },
    }));

    const newAmount = valuesPay?.amountTotal - service?.srvPrice;

    setValuesPay(state => ({
      ...state,
      amount: newAmount,
      amountTotal: newAmount,
      valueMissing: newAmount,
    }));
  };

  const handleRemoveDeposit = depoTypeId => {
    const listDeposits = selectedCollections?.filter(el => el?.depoTypeId !== depoTypeId);

    const totalAmountClient = listDeposits?.reduce((accumulated, currentValue) => {
      return Number(accumulated || 0) + Number(currentValue.amount || 0);
    }, 0);

    const valueMissing =
      Number(valuesPay.amountTotal) - Number(totalAmountClient === 1 ? 0 : totalAmountClient);

    setValuesPay(prevState => ({ ...prevState, valueMissing }));

    setSelectedCollections(listDeposits);
  };

  const handleAddDeposit = () => {
    const x = selectedCollections?.map(el => el?.depoTypeId);

    const options = listDepositType?.results
      ?.filter(el => !x.includes(el?.depoTypeId))
      ?.map(el => ({
        label: el?.depoTypeName,
        value: el?.depoTypeId,
        ...el,
      }));

    setCollectionCrud(state => ({
      ...state,
      options,
      isActiveAddNewCollection: true,
    }));
  };

  const handleSaveForm = () => {
    const hasTabLaboratory = locationData?.pot_name?.toLocaleLowerCase() === 'laboratorio';

    const objCash = selectedCollections?.find(
      deposit => deposit?.depoTypeName?.toLocaleLowerCase() === 'efectivo',
    );

    const remainingValue =
      Number(objCash?.amount) > Number(valuesPay?.amountTotal)
        ? Number(objCash?.amount) - Number(valuesPay?.amountTotal)
        : Number(valuesPay?.amountTotal);

    if (Number(objCash?.donation || 0) > remainingValue) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El valor de la donación no puede ser mayor al valor disponible',
        showCancelButton: false,
      });
    }

    const fieldsAlwaysObligatory = {
      documentNumber,
      patientName,
      insurance,
      cluAmount,
      listContracts: listContracts?.value,
      listIncomeGroup: listIncomeGroup?.value,
      services: hasTabLaboratory ? modalTabLaboratory?.services : services?.length,
      affiliationTypeId: objListAffiliation?.value,
      authorization: insuranceTagName === 'prepaid' ? authorization : 1,
      resolutionId,
    };

    const fieldsObligatoryCollections = selectedCollections?.map(el => el?.depoTypeLabel);

    const fieldsCollectionsRequired = {};

    if (fieldsObligatoryCollections.includes('transfer')) {
      const field = selectedCollections?.find(el => el?.depoTypeLabel === 'transfer');
      fieldsAlwaysObligatory.deposits_transfer_amount = field?.amount;
      fieldsAlwaysObligatory.deposits_transfer_referenceNumber = field?.referenceNumber;
      fieldsAlwaysObligatory.deposits_transfer_bank = field?.bank;

      fieldsCollectionsRequired.deposits_transfer_amount = field?.amount;
      fieldsCollectionsRequired.deposits_transfer_referenceNumber = field?.referenceNumber;
      fieldsCollectionsRequired.deposits_transfer_bank = field?.bank;
    }

    if (fieldsObligatoryCollections.includes('cash')) {
      const field = selectedCollections?.find(el => el?.depoTypeLabel === 'cash');
      fieldsAlwaysObligatory.deposits_cash_amount = field?.amount;

      fieldsCollectionsRequired.deposits_cash_amount = field?.amount;
    }

    if (fieldsObligatoryCollections.includes('card')) {
      const field = selectedCollections?.find(el => el?.depoTypeLabel === 'card');
      fieldsAlwaysObligatory.deposits_card_amount = field?.amount;
      fieldsAlwaysObligatory.deposits_card_franchise = field?.franchise;
      fieldsAlwaysObligatory.deposits_card_referenceNumber = field?.referenceNumber;
      fieldsAlwaysObligatory.deposits_card_bank = field?.bank;

      fieldsCollectionsRequired.deposits_card_amount = field?.amount;
      fieldsCollectionsRequired.deposits_card_franchise = field?.franchise;
      fieldsCollectionsRequired.deposits_card_referenceNumber = field?.referenceNumber;
      fieldsCollectionsRequired.deposits_card_bank = field?.bank;
    }

    
    
    const isValid = Object.values(fieldsAlwaysObligatory).every(el => !isEmptyOrUndefined(el));

    const isValidCollections =
      subscriptionDetail && Object.values(fieldsCollectionsRequired)?.length
        ? Object.values(fieldsCollectionsRequired).every(el => !isEmptyOrUndefined(el))
        : false;

    if (!isValid) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Verifique los campos obligatorios',
        showCancelButton: false,
      });
    }

    if (!isValidCollections) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Verifique los campos obligatorios',
        showCancelButton: false,
      });
    }

    if (!subscription) {
      if (valuesPay.valueMissing > 0) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Debe ingresar el valor completo de la orden a pagar',
          showCancelButton: false,
        });
      }
    }

    let body = {};

    if (subscriptionDetail) {
      body = {
        resolutionId,
        userId,
        eaccount,
        siteId,
        hasDiscount: 0,
        contractId: listContracts?.value,
        totalAmount: valuesPay?.amountTotal,
        orderId: locationData?.id,
        id: locationData?.id,
        affiliationTypeId: locationData?.affiliationTypeId,
        deposits: selectedCollections?.map(deposit => ({
          depoTypeId: deposit?.depoTypeId,
          amount: Number(
            deposit?.pendingAmount ? deposit?.amount - deposit?.pendingAmount : deposit?.amount,
          ),
          pendingAmount:
            deposit?.depoTypeName?.toLocaleLowerCase() === 'efectivo'
              ? Number(deposit?.amount) > Number(valuesPay?.amountTotal)
                ? Number(deposit?.donation)
                  ? Number(deposit?.amount) -
                    (Number(valuesPay?.amountTotal) + Number(deposit?.donation))
                  : Number(deposit?.amount) - Number(valuesPay?.amountTotal)
                : 0
              : 0,
          donation: Number(deposit?.donation | 0), ////
          depoTypeName: deposit?.depoTypeName,
        })),
      };
    }

    if (!subscriptionDetail) {
      body = {
        eaccount,
        cluAmount,
        resolutionId, 
        userId, // general
        siteId, // general
        patientId, // general
        contractId: listContracts?.value,
        typeId: locationData?.pot_id, // general
        authorization, // general
        corporateClient: insurance, // general
        incomeGroup: listIncomeGroup?.value,
        affiliationTypeId: objListAffiliation?.value,
        hasDiscount: 0,

        services: hasTabLaboratory ? modalTabLaboratory?.services?.listIdServicesChecked : services, // general
        totalAmount: valuesPay?.amountTotal, // general
        discountAmount: valuesPay?.amountTotal,

        deposits: selectedCollections?.map(deposit => ({
          depoTypeId: deposit?.depoTypeId,
          amount: Number(
            deposit?.pendingAmount ? deposit?.amount - deposit?.pendingAmount : deposit?.amount,
          ),
          pendingAmount:
            deposit?.depoTypeName?.toLocaleLowerCase() === 'efectivo'
              ? Number(deposit?.amount) > Number(valuesPay?.amountTotal)
                ? Number(deposit?.donation)
                  ? Number(deposit?.amount) -
                    (Number(valuesPay?.amountTotal) + Number(deposit?.donation))
                  : Number(deposit?.amount) - Number(valuesPay?.amountTotal)
                : 0
              : 0,
          donation: Number(deposit?.donation | 0), ////
          depoTypeName: deposit?.depoTypeName,
        })),
      };

      if (modalDiscount?.data?.hasDiscount) {
        body.discountAmount = Number(modalDiscount?.data?.discountAmount);
        body.discountComments = modalDiscount?.data?.discountComments;
        body.hasDiscount = 1;
      } else {
        body.hasDiscount = 0;
      }
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(rs => {
      if (rs.isConfirmed) {
        sendDataForm({
          url: `/medical/admissions/provider-orders/`,
          token: token,
          method: 'POST',
          body: body,
          noAlert: true,
          doAfterSuccess: response => {
            if (response?.success) {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Creada exitosamente',
                text: `Se ha creado ${subscription ? 'un abono' : 'la orden'} exitosamente`,
                showCancelButton: false,
              }).then(() => {
                history.replace('/admision/ordenesProveedores');
              });
            }
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message || 'error x',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  //render jsx
  const getTitleBody = () => {
    if (locationData?.pot_name === typesProviders.contactsGlasses) return 'Lente de contacto';

    if (locationData?.pot_name === typesProviders.glasses) return 'Información del lente';

    return 'Servicios';
  };

  const renderHeader = () => {
    return (
      <div className='mt-4 mb-5'>
        <div className='d-flex justify-content-between'>
          <Title text={'Información del paciente'} />
          {locationData?.pot_name === typesProviders.contactsGlasses ? renderDiscount() : ''}
        </div>

        <Box ml={2}>
          <div
            className={`w-100 p-3`}
            style={{
              boxShadow: '0px 0px 3px #ccc',
              borderRadius: 10,
              background: '#F5FCFD',
            }}
          >
            <div
              className='d-grid gap-3 align-items-center'
              style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
            >
              <div>
                <Text
                  required
                  title={'Tipo de documento'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                />

                <Select
                  isDisabled={subscriptionDetail}
                  className='text-secondary '
                  defaultValue={
                    locationData?.patient?.docType ? { label: locationData?.patient?.docType } : ''
                  }
                  options={listDocumentTypes}
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={({ value }) => {
                    if (!subscriptionDetail) {
                      onClearHeaderState({ typeId: value });
                      onChangeFieldState({
                        key: 'documentNumberStatus',
                        value: true,
                      });
                    }
                  }}
                />
              </div>

              <div>
                <Text
                  title={'Documento'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />
                <div className='d-flex position-relative align-items-center'>
                  <input
                    type='text'
                    disabled={subscriptionDetail | !documentNumberStatus}
                    value={formatteDocument(documentNumber).format}
                    placeholder={'Escribir...'}
                    className={`ordInputAdmission2 w-100 pe-4`}
                    onChange={({ target }) => {
                      if (!subscriptionDetail) {
                        onChangeState({
                          key: 'documentNumber',
                          value: formatteDocument(target.value).value,
                        });
                      }
                    }}
                    onKeyDown={e => {
                      if (!subscriptionDetail) {
                        if (e.key === 'Enter' && documentNumber) {
                          onChangeTriggers({ key: 'triggerDocument' });
                          onClearHeaderState({ document: true });
                        }
                      }
                    }}
                  />

                  <img
                    alt='search'
                    src={ordBlueSearch}
                    className='position-absolute pointer'
                    style={{ right: 5 }}
                    onClick={() => onChangeTriggers({ key: 'triggerDocument' })}
                  />
                </div>
              </div>

              <div>
                <Text
                  title={'Paciente'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />
                <div>
                  <input
                    disabled={true}
                    value={patientName}
                    placeholder={'Escribir...'}
                    className={`ordInputAdmission2 w-100 pe-4`}
                  />
                </div>
              </div>

              <div>
                <Text
                  required
                  title={'Tipo de afiliado'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                />
                <Select
                  isDisabled={subscriptionDetail | isEmptyOrUndefined(patientName)}
                  styles={ordCustomSelect}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                  options={listAffiliationTypes}
                  value={!isEmptyOrUndefined(objListAffiliation?.value) ? objListAffiliation : ''}
                  onChange={({ value, label }) => {
                    onClearHeaderState({ affiliation: value });
                    setData(state => ({
                      ...state,
                      objListAffiliation: { value, label },
                    }));
                  }}
                />
              </div>
            </div>

            <div
              className='d-grid gap-3 align-items-center mt-4'
              style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
            >
              <div>
                <Text
                  required
                  title={'Aseguradora'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                />
                <Select
                  isDisabled={subscriptionDetail | !corporateClientStatus}
                  options={listInsurances || []}
                  value={insurance ? { label: insuranceLabel, value: insurance } : ''}
                  styles={ordCustomSelect}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={({
                    contractId,
                    contractName,
                    crtName,
                    value,
                    label,
                    populationId,
                    crtId,
                    companyTypeTag,
                  }) => {
                    if (!subscriptionDetail) {
                      setListContracts({
                        label: contractName,
                        value: contractId,
                      });
                      setListIncomeGroup({
                        label: crtName || '-',
                        value: crtId || '-',
                      });

                      setData(state => ({
                        ...state,
                        insuranceTagName: companyTypeTag,
                        category: crtName,
                        insurance: value,
                        insuranceLabel: label,
                        populationId,
                        contractId,
                      }));

                      onClearHeaderState({ insurance: true });

                      setFieldStatus(state => ({
                        ...state,
                        authorizationStatus: true,
                        cluAmountStatus: true,
                      }));

                      getListServices({
                        url: '/medical/generals/serviceByContract/',
                        token: token,
                        objFilters: {
                          eaccount,
                          contractId,
                          srvType: locationData?.serviceTypes?.[0],
                        },
                      }).then(res => {
                        if (res.success) {
                          const format = res?.results?.map(service => ({
                            ...service,
                            label: service?.srvName,
                            value: service?.srvId,
                          }));

                          setListServices(format);
                        }
                      });
                    }
                  }}
                />
              </div>

              <div>
                <Text
                  required
                  title={'Contrato'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                />
                <Select
                  isDisabled
                  value={listContracts || ''}
                  styles={ordCustomSelect}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                />
              </div>

              <div>
                <Text
                  required
                  title={'Grupo de ingreso'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                />
                <Select
                  isDisabled
                  value={listIncomeGroup || ''}
                  styles={ordCustomSelect}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                />
              </div>

              <div>
                <Text
                  title={'Autorización'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required={insuranceTagName === 'prepaid'}
                />

                <div style={{ position: 'relative' }} className='d-flex align-items-center'>
                  <input
                    style={{ paddingRight: '30px' }}
                    type='number'
                    value={authorization}
                    disabled={subscriptionDetail | !authorizationStatus}
                    placeholder={'Escribir...'}
                    className={`ordInputAdmission2 w-100`}
                    onChange={({ target }) => {
                      if (!subscriptionDetail) {
                        onChangeState({
                          key: 'authorization',
                          value: target.value,
                        });
                      }
                    }}
                  />

                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip className={`${styles.darkerGrayText}`}>
                        Solo Obligatorio para prepagada
                      </Tooltip>
                    }
                  >
                    <img
                      src={questionBlueClear}
                      alt='question'
                      height={20}
                      width={20}
                      style={{ position: 'absolute', right: 5 }}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    );
  };

  const renderDiscount = () => {
    return (
      <>
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 250 }}
          overlay={<Tooltip className={`${styles.darkerGrayText}`}>Solicitar descuento</Tooltip>}
        >
          <img
            height={27}
            width={27}
            src={iconDiscount}
            alt='iconDiscount'
            className={`${styles.hoverFilterOrdAquaMarine} pointer`}
            onClick={() => {
              if (!valuesPay.amountTotal) {
                customSwaltAlertAsistencial({
                  icon: 'warning',
                  title: `Intenta de nuevo`,
                  text: 'Verifique los campos obligatorios',
                  showCancelButton: false,
                  confirmButtonText: 'Aceptar',
                });
              } else {
                setModalDiscount(state => ({
                  ...state,
                  isOpen: true,
                }));
              }
            }}
          />
        </OverlayTrigger>

        <OrdModal
          title='Solicitar descuento'
          subtitle={patientName}
          show={modalDiscount?.isOpen}
          btnYesName={'Aceptar'}
          btnNoName={'Limpiar'}
          btnYesEvent={onBtnYesModalDiscount}
          onHide={() => {
            if (modalDiscount?.data?.hasDiscount) {
              setModalDiscount(state => ({
                ...state,
                isOpen: false,
              }));
            } else {
              onClearModalDiscountState();
            }
          }}
          btnNoEvent={() => {
            setModalDiscount({
              isOpen: true,
              data: {
                hasDiscount: false,
                discountAmount: '',
                discountComments: '',
              },
            });
          }}
        >
          <Box>
            <Box display={'grid'} gap={2} gridTemplateColumns={'1fr 1fr'}>
              <div>
                <Text
                  title={'Valor inicial'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <div>
                  <input
                    value={formatNumberWithoutDecimals(valuesPay?.amountTotal || 0)}
                    disabled={true}
                    placeholder={'Escribir...'}
                    className={`ordInputAdmission2 w-100`}
                  />
                </div>
              </div>

              <div>
                <Text
                  title={'Valor final'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <div>
                  <input
                    disabled={true}
                    placeholder={'Escribir...'}
                    className={`ordInputAdmission2 w-100`}
                    value={
                      formatNumberWithoutDecimals(
                        modalDiscount?.data?.discountAmount 
                          ? Number(valuesPay?.amountTotal * (1 - modalDiscount?.data?.discountAmount / 100))
                          : Number(valuesPay?.amountTotal)
                      )
                    }
                  />
                </div>
              </div>
            </Box>

            <Box my={2}>
              <div>
                <Text
                  title={'Cantidad'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <div className='d-flex position-relative align-items-center'>
                  <input
                    type='text'
                    maxLength={'4'}
                    className={`register-inputs`}
                    style={{ maxHeight: '32px', height: '32px' }}
                    value={modalDiscount?.data?.discountAmount}
                    placeholder={'Escribir...'}
                    onChange={({ target }) => {
                      if (/^[0-9]+([.])?([0-9]+)?$/.test(target.value)) {
                        setModalDiscount(state => ({
                          ...state,
                          data: {
                            ...state.data,
                            discountAmount: target.value,
                          },
                        }));
                      } else {
                        if (!target.value.length) {
                          setModalDiscount(state => ({
                            ...state,
                            data: {
                              ...state.data,
                              discountAmount: undefined,
                            },
                          }));
                        }
                      }
                    }}
                  />

                  <div className={styles.percentStyle}>
                    <img src={percent} alt='percentage' />
                  </div>
                </div>
              </div>
            </Box>

            <Box my={2}>
              <div>
                <Text
                  title={'Comentarios'}
                  color={colors.ordDarkBlueText}
                  fontSize={'14px'}
                  required
                />

                <div>
                  <textarea
                    value={modalDiscount?.data?.discountComments}
                    placeholder={'Escribir...'}
                    className={`register-inputs py-1 px-2`}
                    style={{
                      height: '5rem',
                      maxHeight: '5rem',
                    }}
                    onChange={({ target }) => {
                      setModalDiscount(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          discountComments: target.value,
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </Box>
          </Box>
        </OrdModal>
      </>
    );
  };

  const renderSidebar = () => {
    const valuesTotal = locationData?.bills?.reduce((accumulated, currentValue) => {
      return Number(accumulated || 0) + Number(currentValue.total || 0);
    }, 0);

    return (
      <div
        style={{
          marginTop: '90px',
          width: '80%',
          textAlign: 'center',
        }}
      >
        <div className={`${styles.f28} ${styles.ordAquaMarineTextImportant} fw-bold`}>
          Abonos realizados
        </div>
        <hr style={{ margin: '8px' }} />

        <OrdTable
          shadow={true}
          headers={headerSidebar}
          hasChildren={true}
          oneHeadDarkBlue={false}
          lowerCase={false}
        >
          {formatDataSidebar(locationData?.bills || [])}
        </OrdTable>

        <Box className={`${styles?.f17} ${styles.ordDarkBlueText} fw-bold row`}>
          <div className='col-6 text-center' style={{ paddingLeft: '90px' }}>
            Total
          </div>
          <div className='col-6 text-end pe-4'>
          {valuesTotal ? formatNumberWithoutDecimals(valuesTotal) : '-'}
          </div>
        </Box>
      </div>
    );
  };

  const renderResolutionField = (section)=>{
    return (
      <div className={`${section === 'laboratorio' ? 'col-6 py-4' : ''}`}>
        <Text
          required
          title={'Resolución de facturación'}
          color={colors.ordDarkBlueText}
          fontSize={'14px'}
        />
        <Select
          className='text-secondary '
          options={[{ label: 'Seleccionar...', value: undefined }, ...siteResolutions]}
          placeholder={'Seleccionar...'}
          styles={ordCustomSelect}
          value={siteResolutions.find(x => x.value === data.resolutionId)}
          noOptionsMessage={() => 'No hay datos'}
          onChange={({ value }) => {
            setData(state => ({
              ...state,
              resolutionId: value,
            }));
          }}
        />
      </div>
    );
  }

  const renderBody = () => {
    return (
      <div className='mt-4 mb-5'>
        <Title text={getTitleBody()} />

        <Box ml={2}>
          {locationData?.pot_name?.toLocaleLowerCase() === 'laboratorio'
            ? <> {renderBodyTabLaboratory()}
            {renderResolutionField("laboratorio")}
            </>
            : renderGlassesContactOrGlasses()}
          
        </Box>
      </div>
    );
  };

  const renderBodyTabLaboratory = () => {
    return (
      <div
        className={`w-100 p-3`}
        style={{
          boxShadow: '0px 0px 3px #ccc',
          borderRadius: 10,
          background: '#F5FCFD',
        }}
      >

        <div
          className='d-grid gap-3 align-items-center'
          style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
        >
          <div>
            <Text
              required
              title={'Ordenamientos'}
              color={colors.ordDarkBlueText}
              fontSize={'14px'}
            />

            <div className='d-flex position-relative align-items-center'>
              <input
                type='text'
                disabled={isEmptyOrUndefined(insurance)}
                value={'Buscar...'}
                placeholder={'Buscar...'}
                className={`ordInputAdmission2 w-100 pe-4`}
                onKeyDown={e => {
                  if (e.key === 'Enter' && !isEmptyOrUndefined(insurance)) {
                    onToggleModalLaboratory();
                  }
                }}
              />

              <img
                alt='search'
                src={searchPatient}
                className='position-absolute pointer'
                style={{
                  right: 5,
                  filter: isEmptyOrUndefined(insurance) ? 'grayscale(1)' : 'none',
                }}
                onClick={() => {
                  if (!isEmptyOrUndefined(insurance)) {
                    onToggleModalLaboratory();
                  }
                }}
              />
            </div>
          </div>

          <div>
            <Text required title={'Proveedor'} color={colors.ordDarkBlueText} fontSize={'14px'} />

            <input
              type='text'
              disabled={true}
              value={
                listProviders?.find(el => el?.id === modalTabLaboratory?.providers?.providerChecked)
                  ?.name || ''
              }
              placeholder={'Buscar...'}
              className={`ordInputAdmission2 w-100 pe-4`}
            />
          </div>
        </div>

        <Box mt={3}>
          <OrdTable
            shadow={modalTabLaboratory?.services?.listIdServicesChecked?.length}
            headers={headers}
            hasChildren={true}
            oneHeadDarkBlue={false}
            lowerCase={false}
          >
            {formatData(
              modalTabLaboratory?.services?.listIdServicesChecked?.length
                ? modalTabLaboratory?.services?.listIdServicesChecked
                : [],
            )}
          </OrdTable>

          <div
            style={{ marginRight: '5.8rem' }}
            className={`ms-auto gap-5 d-flex justify-content-end ${styles.f14} ${styles.ordDarkGrayText}`}
          >
            <span className={`${styles.ordAquaMarineTextImportant} fw-bold`}>Valor total</span>
            <span>{formatNumberWithoutDecimals(valuesPay.amountTotal)}</span>
          </div>
        </Box>
      </div>
    );
  };

  const renderModalTabLaboratory = () => {
    return (
      <OrdModal
        title='Relacionar ordenamiento'
        show={modalTabLaboratory?.isOpen}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'xl'}
        btnYesEvent={onBtnYesModalLaboratory}
        onHide={onClearModalState}
        btnNoEvent={onClearModalState}
      >
        <Box>
          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} columnGap={6}>
            <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
              <input
                className={`ord-roundInput ${styles.darkerGrayText} w-100`}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={e => {
                  setSearch(e.target.value);
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setModalTabLaboratory(state => ({
                      ...state,
                      services: {
                        ...state.services,
                        filters: {
                          ...state?.services?.filters,
                          search,
                        },
                        trigger: state?.services?.trigger + 1,
                      },
                    }));
                  }
                }}
              />
              <img
                src={ordBlueSearch}
                className={`pointer ${styles.svgTurquoiseFilter}`}
                alt='buscar'
                onClick={() => {
                  setModalTabLaboratory(state => ({
                    ...state,
                    services: {
                      ...state.services,
                      trigger: state?.services?.trigger + 1,
                      filters: {
                        ...state?.services?.filters,
                        search,
                      },
                    },
                  }));
                }}
              />
            </Box>
          </Box>

          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} columnGap={6}>
            <Box>
              <OrdTable
                shadow
                headers={servicesHeaders}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: modalTabLaboratory?.services?.filters.page,
                  totalPages: resultsServices?.rowTotal,
                  perPage: modalTabLaboratory?.services?.filters?.perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async e => {
                    setModalTabLaboratory(state => ({
                      ...state,
                      services: {
                        ...state.services,
                        filters: { ...state?.filters, page: Number(e) },
                        trigger: state?.services?.trigger + 1,
                      },
                    }));
                  },
                }}
              >
                {servicesFormatData(listServices?.length ? listServices : [])}
              </OrdTable>
            </Box>

            <Box>
              <OrdTable
                shadow
                headers={providersHeaders}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: modalTabLaboratory?.providers?.filters.page,
                  totalPages: resultsProviders?.rowTotal,
                  perPage: modalTabLaboratory?.providers?.filters?.perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async e => {
                    setModalTabLaboratory(state => ({
                      ...state,
                      providers: {
                        ...state.providers,
                        filters: { ...state?.filters, page: Number(e) },
                        trigger: state?.providers?.trigger + 1,
                      },
                    }));
                  },
                }}
              >
                {providersFormatData(listProviders || [])}
              </OrdTable>
            </Box>
          </Box>
        </Box>
      </OrdModal>
    );
  };

  const renderGlassesContactOrGlasses = () => {
    return (
      <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2}>
        <div>
          <Text required title={'Servicios'} color={colors.ordDarkBlueText} fontSize={'14px'} />

          <Select
            isDisabled={subscriptionDetail | isEmptyOrUndefined(insurance)}
            className='text-secondary'
            options={listServices}
            value={services?.length ? services[0] : ''}
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            noOptionsMessage={() => 'No hay datos'}
            onChange={({ value, label }) => {
              if (!subscriptionDetail) {
                onChangeSelectServices({
                  idService: value,
                  labelService: label,
                });
                setData(state => ({
                  ...state,
                  objService: { value, label },
                }));
              }
            }}
          />
        </div>

        {locationData?.pot_fields?.includes('pot_has_doctor') ? (
          <div>
            <Text required title={'Remitente'} color={colors.ordDarkBlueText} fontSize={'14px'} />

            <Select
              isDisabled={!services?.length}
              className='text-secondary '
              options={listDoctors}
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              value={!isEmptyOrUndefined(objDoctor?.value) ? objDoctor : ''}
              noOptionsMessage={() => 'No hay datos'}
              onChange={({ value, label }) => {
                setData(state => ({
                  ...state,
                  objDoctor: { value, label },
                }));
              }}
            />
          </div>
        ) : (
          ''
        )}

        <div>
          <Text
            title={'Valor autorizado'}
            color={colors.ordDarkBlueText}
            fontSize={'14px'}
            required
          /> 
          <NumberFormat
            allowNegative={false}
            className={`ordInputAdmission2 w-100`}
            disabled={subscriptionDetail | (insuranceTagName !== 'prepaid')}
            isNumericString={true}
            placeholder='Escribir...'
            value={cluAmount}
            thousandSeparator={localeSeparators.groupSeparator}
            decimalSeparator={localeSeparators.decimalSeparator}
            format={(valueStr) => {
              if (!valueStr) return ''

              return formatNumberWithoutDecimals(valueStr)
            }}
            allowLeadingZeros={false}
            onValueChange={({ value }) => {
              if (!subscriptionDetail) {
                if (insuranceTagName === 'prepaid') {
                  onChangeState({ key: 'cluAmount', value });
                }
              }
            }}
          />
        </div> 
        <div>
        {renderResolutionField()}
        </div>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box className='mb-5'>
        {locationData?.pot_name?.toLocaleLowerCase() !== 'laboratorio' ? (
          <CardValuesPay
            advance={subscriptionDetail ? valuesPay?.advance : 0}
            discount={subscriptionDetail ? valuesPay?.discount : 0}
            amount={cluAmount}
            totalAmount={subscriptionDetail ? valuesPay?.amountTotal : cluAmount}
          />
        ) : (
          ''
        )}

        <Title text={'Recaudo'} />

        {selectedCollections?.map((collection, key) => (
          <Box key={key} ml={2}>
            <CardCollectionDynamic
              key={collection?.depoTypeId}
              data={collection}
              onChangeValue={event => onChangeValueCardDynamic(event, collection?.depoTypeId)}
              handleRemoveDeposit={() => handleRemoveDeposit(collection?.depoTypeId)}
              totalAmount={valuesPay.amountTotal}
              listFranchise={listFranchise}
              listBank={listBank}
              valuesPay={valuesPay}
            />
          </Box>
        ))}

        {collectionCrud?.isActiveAddNewCollection || !selectedCollections?.length ? (
          <Box ml={2}>
            <CardCollectionBase
              col={12}
              isDisabled={Number(valuesPay?.amountTotal) === 0}
              className='mt-0'
              options={collectionCrud?.options?.filter(el => el?.depoTypeLabel !== 'wallet') || []}
              dataOptions={selectedCollections?.map(el => el?.value)}
              onChange={props => {
                if (!selectedCollections?.filter(e => e?.value === props?.value).length) {
                  setSelectedCollections(state => [...state, props]);

                  setCollectionCrud(state => ({
                    ...state,
                    isActiveAddNewCollection: false,
                  }));
                }
              }}
            />
          </Box>
        ) : (
          ''
        )}

        <Box ml={2} className='d-flex'>
          {selectedCollections.length < listDepositType?.results?.length &&
          !collectionCrud?.isActiveAddNewCollection ? (
            <button className={`${styles.appButtonHover}`} onClick={handleAddDeposit}>
              <b className={`${styles.tlnTextGray} ${styles.f14} ${styles.appTextHoverTurquoise}`}>
                &nbsp;Agregar otro recaudo
              </b>
              &nbsp;
              <img
                alt='addIcon'
                src={add}
                height='17px'
                className={`${styles.AppIconHoverTurquoise}`}
              />
            </button>
          ) : (
            ''
          )}

          <div
            className={`
              ms-auto fw-bold gap-5 d-flex
              ${
                valuesPay.valueMissing > 0
                  ? styles.orangeLightText
                  : styles.darkerBlueText
              }
          `}
          >
            <span>Faltante</span>
            <span>
            {formatNumberWithoutDecimals(valuesPay.valueMissing >= 0 ? valuesPay.valueMissing : 0)}
            </span>
          </div>
        </Box>
      </Box>
    );
  };

  const renderBtn = () => {
    return (
      <Box className='d-flex justify-content-end mb-5 gap-4'>
        <Button
          className={`btn d-flex gap-2 justify-content-center align-items-center ${styles.btnIconWhiteHover}`}
          width='100px'
          padding='8px'
          fontSize='14px'
          border={`2px solid ${colors.ordAquaMarine}`}
          backgroundColor={'white'}
          backgroundColorHover={colors.ordAquaMarine}
          colorHover={'white'}
          color={colors.ordAquaMarine}
          onClick={() => {
            history.goBack();
          }}
        >
          Cancelar
        </Button>

        <Button
          className={`btn d-flex gap-2 justify-content-center align-items-center`}
          width='100px'
          padding='8px'
          fontSize='14px'
          border='none'
          backgroundColor={colors.ordAquaMarine}
          backgroundColorHover={colors.ordAquaMarine + '90'}
          onClick={handleSaveForm}
        >
          Aceptar
        </Button>
      </Box>
    );
  };

  const main = () => {
    return (
      <>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
        {renderBtn()}
        {renderModalTabLaboratory()}
      </>
    );
  };

  return (
    <>
      {(loaderListDocumentTypes ||
        loaderSendDataForm ||
        loaderInfoPatient ||
        loaderListPatient ||
        loaderListBank ||
        loaderListDepositType ||
        loaderListFranchise ||
        loaderListServices ||
        loaderListDoctors ||
        loaderListServicesType ||
        loaderMedicalAppointmentPrice ||
        loaderListProviders ||
        loaderListGlassesType ||
        loaderListAffiliationTypes ||
        loaderListLaboratory) &&
        loader}

      <Box
        sx={subscriptionDetail ? { display: 'grid', gridTemplateColumns: '2fr 1fr' } : {}}
        gap={5}
      >
        <OrdGenericTemplate
          className='mt-4 mx-2'
          showBackArrow
          titleSize={12}
          title={
            subscriptionDetail
              ? 'Abonos a orden'
              : 'Órdenes de ' + String(locationData?.pot_name).toLocaleLowerCase()
          }
          backArrowAction={() => {
            history.push({
              pathname: '/admision/ordenesProveedores/',
              state: { data: { subscription } },
            });
          }}
        >
          {main()}
        </OrdGenericTemplate>

        {subscriptionDetail ? <div>{renderSidebar()}</div> : ''}
      </Box>
    </>
  );
};
