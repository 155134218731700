import { useEffect, useState } from 'react';
import PENDING from '../../assets/img/icons/Alert.svg';
import APPROVED from '../../assets/img/icons/Check Mark.svg';
import PREAPPROVED from '../../assets/img/icons/greenCircleCheck.svg';
import REJECTED from '../../assets/img/icons/extraBtnRedX.svg';
import PROCESSED from '../../assets/img/icons/grayCheckNew.svg';
import triggerSrc from '../../assets/img/icons/threeDots.svg';
import note from '../../assets/img/icons/ordSugess.svg';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import ModalNew from '../Layouts/ModalNew';
import { getVacactionsList, updateVacationStatus } from '../../actions/approvedNewsActions';
import { costcenter_get } from '../../actions/costcenterActions';
import { isEmptyOrUndefined, message } from '../../helpers/helpers';
import { PdfViewer } from '../Layouts/PdfViewer';
import Loader from 'react-loader-spinner';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';

export const VacationsMyPayRoll = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'myprvc',
  );

  const idAccount = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [elemDetail] = useState({});
  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
    userName: '',
  });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    search: '',
    status: '',
    dateFrom: '',
    dateUntil: '',
    area: '',
    user: idAccount,
  });

  const arrVacations = store.approvedNewsReducer?.vacationList;
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/configuracion/miperfil');
    }
    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      }),
    );
    dispatch(getVacactionsList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const getExtraButtons = elem => {
    const statusPending = () => {
      return [
        {
          text: 'Pre-aprobado por líder',
          icon: PREAPPROVED,
          event: () => handleAuth(elem),
        },
        {
          text: 'Denegar',
          icon: REJECTED,
          event: () =>
            setCommentModal({
              ...commentModal,
              show: true,
              comment: '',
              elem: elem,
              isEnabled: true,
            }),
        },
      ];
    };

    const statusPreApproved = () => {
      return [
        {
          text: 'Denegar',
          icon: REJECTED,
          event: myPermission.reject
            ? () =>
                setCommentModal({
                  ...commentModal,
                  show: true,
                  comment: '',
                  elem: elem,
                  isEnabled: true,
                })
            : null,
        },
      ];
    };

    const defaulStatus = () => [];

    switch (elem?.status) {
      case 'pending':
        return statusPending();
      case 'pre-approved':
        return statusPreApproved();
      default:
        return defaulStatus();
    }
  };

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.collaborator}
        </td>
        <td className='col-md-2 text-start'>&nbsp; {elem.job_title}</td>
        <td className='col-md-1 text-start'>{elem.type === 'paid' ? 'Pagadas' : 'Descansadas'}</td>
        <td className='col-md-2 text-center'>
          {!isEmptyOrUndefined(elem.count) ? elem.count + ' días' : '-'}
        </td>
        <td className='col-md-2 text-center'>{elem.date}</td>
        <td className='col-md-1 text-center'>{elem.period}</td>
        <td
          className='col-md-2 text-center cursorPointer'
          title={
            elem.status === 'pending'
              ? 'Pendiente'
              : elem.status === 'approved'
                ? 'Aprobado'
                : elem.status === 'pre-approved'
                  ? 'Pre-aprobado por líder'
                  : elem.status === 'rejected'
                    ? 'Rechazado'
                    : elem.status === 'processed' && 'Procesado'
          }
        >
          <img
            alt={'icon'}
            width={20}
            height={20}
            src={
              elem.status === 'pending'
                ? PENDING
                : elem.status === 'pre-approved'
                  ? PREAPPROVED
                  : elem.status === 'approved'
                    ? APPROVED
                    : elem.status === 'rejected'
                      ? REJECTED
                      : elem.status === 'processed' && PROCESSED
            }
          ></img>
        </td>
        <td className=' text-center '>
          {elem.comment !== '' && (
            <img
              alt='note'
              src={note}
              height='23px'
              className={`${tableStyles.svgFilterDarkBlue} cursorPointer`}
              onClick={() => {
                setCommentModal({ ...commentModal, show: true, comment: elem.comment });
              }}
            />
          )}
        </td>

        <td className='col-md-1 text-center cursorPointer'>
          <div>
            {(elem.status !== 'processed' && elem.status !== 'advance' && elem.status !== 'approved') && (
              <CustomPopupExtend
                noHover
                triggerSrc={triggerSrc}
                showUndo={ elem.status === 'pre-approved' || elem.status === 'rejected' }
                undoEvent={() => handleUndo(elem)}
                extraButtons={getExtraButtons(elem)}
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  const handleUndo = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `¿Desea deshacer esta solicitud?`,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          const send = async () => {
            try {
              await updateVacationStatus(
                {
                  status: 'pending',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
                res => {
                  if (res.success) {
                    setTrigger(trigger + 1);
                    return message(
                      'success',
                      'Se ha cambiado el estado',
                      res.message,
                      undefined,
                      true,
                    );
                  } else {
                    return message('warning', 'Intenta de nuevo', res.message, undefined, true);
                  }
                },
              );
            } catch (error) {
              message('warning', 'Intenta de nuevo', error.message, undefined, true);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('warning', 'Intenta de nuevo', error.message, undefined, true);
    }
  };

  const handleAuth = async elem => {
    try {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se aprobará la solicitud de vacaciones de ${elem?.collaborator}`,
        confirmButtonText: 'Sí, continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const send = async () => {
            try {
              await updateVacationStatus(
                {
                  status: 'pre-approved',
                  reviewed_by: idAccount,
                  id: elem.id,
                },
                token,
                res => {
                  if (res.success) {
                    setIsLoading(false);
                    setShowModal(false);
                    setTrigger(trigger + 1);
                    return message(
                      'success',
                      'Vacaciones autorizadas',
                      res.message,
                      undefined,
                      true,
                    );
                  } else {
                    setIsLoading(false);
                    return message('warning', 'Intenta de nuevo', res.message, undefined, true);
                  }
                },
              );
            } catch (error) {
              setIsLoading(false);
              message('warning', 'Intenta de nuevo', error.message, undefined, true);
            }
          };
          send();
        }
      });
    } catch (error) {
      console.error(error);
      return message('warning', 'Intenta de nuevo', error.message, undefined, true);
    }
  };
  const handleDeny = async elem => {
    if (commentModal.comment.length > 0) {
      try {
        customSwaltAlert({
          icon: 'warning',
          title: '¿Está seguro?',
          text: `Se denegará la solicitud de vacaciones de ${elem.collaborator}`,
          confirmButtonText: 'Sí, continuar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true,
        }).then(result => {
          if (result.isConfirmed) {
            const send = async () => {
              try {
                await updateVacationStatus(
                  {
                    status: 'rejected',
                    reviewed_by: idAccount,
                    id: elem.id,
                    comment: commentModal.comment,
                  },
                  token,
                  res => {
                    if (res.success) {
                      setShowModal(false);
                      closeCommentModal();
                      setTrigger(trigger + 1);
                      return message(
                        'success',
                        'Vacaciones rechazadas',
                        res.message,
                        undefined,
                        true,
                      );
                    } else {
                      return message('warning', 'Intenta de nuevo', res.message, undefined, true);
                    }
                  },
                );
              } catch (error) {
                message('warning', 'Intenta de nuevo', error.message, undefined, true);
              }
            };
            send();
          }
        });
      } catch (error) {
        console.error(error);
        return message('warning', 'Intenta de nuevo', error.message, undefined, true);
      }
    } else {
      return message(
        'warning',
        'Intenta de nuevo',
        'El comentario es obligatorio',
        undefined,
        true,
      );
    }
  };

  const listElem = () => {
    let elemMap;
    if (arrVacations !== undefined) {
      const elem2 = arrVacations;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Colaborador
    </th>,
    <th key={2} className='text-start'>
      &nbsp; Cargo
    </th>,
    <th key={3} className='text-start'>
      Tipo
    </th>,
    <th key={4} className='text-center'>
      Solicitud
    </th>,
    <th key={5} className='text-center'>
      Fechas solicitadas
    </th>,
    <th key={9} className='text-center'>
      Periodo de vacaciones
    </th>,
    <th key={6} className='text-center'>
      Estado
    </th>,
    <th key={7} className='text-center'>
      &nbsp;
    </th>,
    <th key={8}>&nbsp;</th>,
  ];

  // const handleClickDetail = async (elem) => {
  //   if (isEmptyOrUndefined(elem.file)) {
  //     return message(
  //       "info",
  //       "Sin soporte",
  //       "No se encontraron soportes para esta solicitud"
  //     );
  //   }

  //   setElemDetail(elem);
  //   setShowModal(true);
  // };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const closeCommentModal = () => {
    setCommentModal({ ...commentModal, show: false, comment: '', isEnabled: false, elem: {} });
  };

  return (
    <>
      {isLoading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      {store?.approvedNewsReducer?.loadingVct ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={''}
        subtitle={
          elemDetail.status === 'pending'
            ? 'En espera'
            : elemDetail.status === 'approved'
              ? 'Aprobado'
              : elemDetail.status === 'rejected'
                ? 'Rechazado'
                : elemDetail.status === 'processed' && 'Procesado'
        }
        subtitleStyle={
          elemDetail.status === 'pending'
            ? 'text-warning'
            : elemDetail.status === 'approved'
              ? 'text-success'
              : elemDetail.status === 'rejected'
                ? 'text-danger'
                : elemDetail.status === 'processed' && 'text-secondary'
        }
        size={'750'}
      >
        <PdfViewer file={elemDetail.file}></PdfViewer>
      </ModalNew>
      {/* <ModalNew
        title="Comentario"
        show={commentModal.show}
        btnNoName={"Cerrar"}
        size="400"
        btnYesEvent={() => handleDeny(commentModal.elem)}
        onHide={() =>
          closeCommentModal()
        }
        btnNoEvent={() =>
          closeCommentModal()
        }
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>
          Comentario
        </p>
        <textarea
          placeholder={"Escribir..."}
          id="txtArea"
          rows="10"
          cols="10"
          style={{
            height: "4rem", borderRadius: "5px", border: "1.5px solid #01A0F6", padding: "0 0.05rem",
            width: "100%"
          }}
          onChange={(e) => {
            setCommentModal({ ...commentModal, comment: e.target.value })
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew> */}
      <ModalNew
        title='Comentarios'
        show={commentModal.show}
        // btnYesName={showModalRejected.justify !== "" && "Guardar"}
        btnNoName={'Cerrar'}
        size='400'
        btnYesEvent={commentModal.isEnabled ? () => handleDeny(commentModal.elem) : null}
        btnYesDisabled={commentModal.isEnabled ? false : true}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentarios</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          disabled={commentModal.isEnabled ? false : true}
          onChange={e => setCommentModal({ ...commentModal, comment: e.target.value })}
          defaultValue={commentModal.comment}
          style={{
            height: '4rem',
            borderRadius: '5px',
            border: '1.5px solid #7FADDE',
            padding: '0 0.05rem',
            width: '100%',
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Autorizar vacaciones</h1>

          <Row>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                onChange={e => setFilters({ ...filters, status: e.value })}
                options={[
                  { value: '', label: 'Seleccionar...' },
                  { value: 'pre-approved', label: 'Pre-aprobado por líder' },
                  { value: 'approved', label: 'Aprobado' },
                  { value: 'rejected', label: 'Rechazado' },
                  { value: 'processed', label: 'Procesado' },
                  { value: 'advance', label: 'Anticipo' },
                ]}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={customSelectNew}
              ></Select>
            </Col>

            <Col className='d-block align-self-end' xs={4}>
              <div className='pt-4'>
                <form onSubmit={e => handleSearch(e)} className='d-flex'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className='cursorPointer'
                    width={'30px'}
                    height={'24px'}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.approvedNewsReducer.vacationList ? filters.page : ''}
              {' de '}
              {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                : '1'}{' '}
              ({store.approvedNewsReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.approvedNewsReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
