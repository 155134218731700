import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import '../../OrderingModule/orderingStyles.css';

export const PseudoSideBarAccordion = props => {
  let { accordionItems } = props;

  const location = useLocation();
  const counter = useSelector(state => state);

  const categories = counter.loginReducer?.newCategories;

  const path = location.pathname.split('/')[1];
  let portal;
  if (
    path === 'nomina' ||
    path === 'compras' ||
    path === 'contabilidad' ||
    path === 'tesoreria' ||
    path === 'facturacion' ||
    path === 'cartera' ||
    path === 'administracion' ||
    path === 'gerencia'
  ) {
    portal = 'Portal Administrativo';
  } else {
    portal = 'Portal Asistencial';
  }
  let categoryMo = categories.find(e => e?.description === portal);
  let moduleH = categoryMo?.data.find(e => e?.url === path);
  let state = {
    group: 'Portal Administrativo',
    catDetail: {
      home_img: moduleH.home_img,
      id: moduleH.id,
      img: moduleH.img,
      subtitle: moduleH.subtitle,
      title: 'Módulo de ' + moduleH.title,
      url: moduleH.url,
    },
  };

  let { group } = location?.state ?? state;

  return (
    <>
      {accordionItems?.length > 0 &&
        accordionItems.map((e, i) => {
          return (
            <Accordion key={i} className='w-100'>
              <div className='w-100  p-2 d-flex'>
                <div className='w-50 ml-1'>
                  <Accordion.Toggle
                    eventKey={i + 1}
                    className={`  w-100  text-start  ${group === 'Portal Asistencial' ? 'ord-darkGray' : 'ord-darkGray-admin'} `}
                  >
                    <h5>
                      {' '}
                      <b>{e.title}</b>
                    </h5>
                  </Accordion.Toggle>
                  <Accordion.Collapse key={'Collapse' + i} eventKey={i + 1}>
                    <Card.Body>
                      {e.content?.length > 0 &&
                        e.content.map((item, key) => {
                          return (
                            <Row key={key}>
                              <Col
                                onClick={() => item.action()}
                                xs={11}
                                className={props.ActiveBlue ? 'ord-darkBlue2' : 'ord-darkGraySub'}
                              >
                                <h5>{item?.subtitle}</h5>{' '}
                              </Col>
                            </Row>
                          );
                        })}
                    </Card.Body>
                  </Accordion.Collapse>
                </div>
              </div>
            </Accordion>
          );
        })}
    </>
  );
};
