import deepcopy from 'deepcopy';
import { Component } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactSelect from 'react-select';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  cleanDrafts,
  getCIIU,
  getEditProvider,
  getProviders,
} from '../../actions/providersActions';
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import CheckMark from '../../assets/img/icons/CheckMark.svg';
import magnify from '../../assets/img/icons/auctionDetailLupa2.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import { PAGE, PERPAGE, ProviderStatus, ProviderTypeShort } from '../../helpers/constants';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { Title } from '../../shared';
import GenericTableNew from '../Layouts/GenericTableNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNew } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import style from '../TableProviders/providers.module.scss';
const MySwal = withReactContent(Swal);

class AccountingProviders extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  componentDidMount() {
    this.props.getProviders({
      perpage: 10,
      page: 1,
      search: '',
      multistatus: 'enabled,disabled',
    });
    this.props.cleanDrafts();
    this.props.getEditProvider(null);
    this.props.getCIIU();
    this.setState(this.initialState);
  }

  // componentDidUpdate(prevProps) {

  // }

  componentWillUnmount() {
    this.setState(this.initialState);
  }

  get initialState() {
    return {
      modal: false,
      editRecord: false,
      redirect: null,
      addProvider: {
        description: null,
        barcode: null,
        property_: null,
        consignment: null,
        handle_batch: null,
        handle_serial: null,
        reference: null,
        cost_price: null,
        average_cost: null,
        tradename: null,
        data_sheet: null,
        active: null,
        manufacturer: '',
        inventory_family: null,
        measurement_units: [],
        add_info: [],
        provider_type: '',
      },
      filters: {
        search: '',
        perpage: PERPAGE,
        page: PAGE,
        multistatus: 'enabled,disabled',
      },
      headerState: [
        '',
        'NIT / RUT',
        'Nombre/Razón Social',
        'CIIU',
        'Tipo',
        'Inscripción',
        'Actualización',
        'Revisado',
        'Estado',
        '',
      ],
    };
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  }

  handleEditRecord = provider => {
    let { addProvider, redirect } = this.state;
    addProvider = deepcopy(provider);
    if (provider.provider_type === 'natural') {
      redirect = '/contabilidad/terceros/proveedores/persona-natural';
    } else {
      redirect = '/contabilidad/terceros/proveedores/persona-juridica';
    }
    this.props.getEditProvider(addProvider);
    this.setState({ addProvider, redirect });
  };

  //   toggleStatus = (account) => {
  //     // this.props.changeStatus(account);
  //   };

  // Search puc when user was typing
  onKeyUpSearch = e => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.search = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      this.setState({ filters });
      this.props.getProviders(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  onKeyDownNumber = e => {
    let { errorInputs } = this.state;
    if (e.keyCode === 69 || e.keyCode === 107 || e.keyCode === 109) {
      e.preventDefault();
      errorInputs = true;
      return MySwal.fire({
        icon: 'error',
        title: 'Este campo solo acepta números',
      });
    }
    errorInputs = false;
    this.setState({ errorInputs });
  };

  //   toggle visibilty of modal to edit or create record
  handleOnHide = () => {
    let { modal } = this.state;
    modal = !modal;
    this.setState({ modal });
  };

  // Method to get css style for circle in status
  getStatusClass = status => {
    switch (status) {
      case 'incomplete':
        return tableStyles.circleYellowAuto;

      case 'disabled':
        return tableStyles.circleRedAuto;

      case 'enabled':
        return tableStyles.circleGreenAuto;

      default:
        return tableStyles.circleRedAuto;
    }
  };

  getClassCIIU = id_ciiu => {
    let klass = '-';
    this.props?.ciiu?.forEach(item => {
      if (item.id === parseInt(id_ciiu)) klass = item.klass;
    });
    return klass;
  };

  // Method to render providers in table
  renderProviders = provider => {
    let created_at = provider.created_at ? convertDateToLatinFormat(provider.created_at) : '-';
    let updated_at = provider.updated_at ? convertDateToLatinFormat(provider.updated_at) : '-';
    let circleClass = this.getStatusClass(provider.status);
    let ciiu = this.getClassCIIU(provider.ciiu);
    let checked = '-';
    if (provider.checked) {
      checked = <img src={CheckMark} alt='Revisado' width='20' />;
    } else if (!provider.checked) {
      checked = <img src={AlertTriangle} alt='No revisado' width='18' />;
    }
    const renderTooltip = props => (
      <Tooltip id='button-tooltip' {...props}>
        {ProviderStatus[provider.status]}
      </Tooltip>
    );
    return (
      <tr key={`prov-${provider.id}`} className='hover-table-row align-middle'>
        <td></td>
        <td className='text-center'>
          {provider.provider_type === 'natural' && provider.docNumLglRepresent !== ''
            ? provider.docNumLglRepresent
            : provider.nit
              ? provider.nit
              : '-'}
        </td>
        <td className='text-center'>
          {provider.provider_type === 'natural' &&
          provider.firstNameLglRepresent + provider.firstSurnameLglRepresent !== ''
            ? provider.firstNameLglRepresent + ' ' + provider.firstSurnameLglRepresent
            : provider.name
              ? provider.name
              : '-'}
        </td>
        <td className='text-center'>{ciiu}</td>
        <td className='text-center'>
          {provider.provider_type ? ProviderTypeShort[provider.provider_type] : '-'}
        </td>
        <td className='text-center'>{created_at}</td>
        <td className='text-center'>{updated_at}</td>
        <td className='text-center'>{checked}</td>
        {/* <td>{provider.status ? provider.status : "-"}</td> */}
        <td>
          <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <div className={circleClass}></div>
          </OverlayTrigger>
        </td>
        <td>
          <button className='text-decoration-none' onClick={() => this.handleEditRecord(provider)}>
            <img src={magnify} alt='Ver detalle' width='20px' />
          </button>
        </td>
      </tr>
    );
  };

  onChangeSelect = event => {
    let { name, value } = event;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
    this.props.getProviders(this.state.filters);
  };

  typePersonOption = () => {
    let Options = [
      { value: '', label: 'Seleccionar...' },
      { value: 'natural', label: 'Natural' },
      { value: 'legal_person', label: 'Jurídica' },
    ];
    return Options;
  };

  statusOptions = () => {
    let Options = [
      { value: '', label: 'Seleccionar...' },
      { value: 'enabled', label: 'Activo' },
      { value: 'disabled', label: 'Inactivo' },
      { value: 'incomplete', label: 'Incompleto' },
    ];
    return Options;
  };

  // ANCHOR RENDER METHOD
  render() {
    let renderHeaders = this.state.headerState
      ? this.state.headerState.map(header => {
          return (
            <th key={1} className='text-center'>
              {header}
            </th>
          );
        })
      : '';
    var renderProviders = this.props.providers
      ? this.props.providers.map(provider => {
          return this.renderProviders(provider);
        })
      : '';

    if (this.state.redirect) {
      let provider = this.state.addProvider;
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: { provider },
          }}
        />
      );
    }

    return (
      <div className='w-100'>
        {this.props.loading ? (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        ) : null}
        <div className={`w-100 ${this.props.loading ? 'disabled' : ''}`}>
          <div className='container pb-5 mx-auto px-5'>
            <Row>
              <Col xs={10}>
                <div className={tableStyles.title}>
                  <Title
                    title='Proveedor'
                    onClickIcon={() => {
                      this.props.history.push('/contabilidad/terceros');
                    }}
                  />
                </div>
              </Col>
            </Row>
            {/* <div className="row">
                    <h1 className="top-h1">Proveedores</h1>
                </div> */}
            <div className='row'>
              <div className='col-md-16 align-middle '>
                <div className='row align-items-end'>
                  <div className={`${style.selectGroup} col-md-2`}>
                    <label htmlFor='personType' className={`${style.labelFont}`}>
                      Tipo de persona
                    </label>
                    <ReactSelect
                      noOptionsMessage={() => 'No hay datos'}
                      styles={customSelectNew}
                      name='provider_type'
                      id='personType_id'
                      onChange={this.onChangeSelect}
                      options={[
                        { value: '', label: 'Seleccionar...', name: 'provider_type' },
                        { value: 'natural', label: 'Natural', name: 'provider_type' },
                        { value: 'legal_person', label: 'Jurídica', name: 'provider_type' },
                      ]}
                      placeholder='Seleccionar...'
                    ></ReactSelect>
                  </div>
                  <div className={`${style.selectGroup} col-md-2`}>
                    <label htmlFor='active' className={`${style.labelFont}`}>
                      Estado
                    </label>
                    <ReactSelect
                      noOptionsMessage={() => 'No hay datos'}
                      name='status'
                      id='status_id'
                      styles={customSelectNew}
                      onChange={this.onChangeSelect}
                      options={[
                        { value: '', label: 'Seleccionar...' },
                        { value: 'enabled', label: 'Activo', name: 'status' },
                        { value: 'disabled', label: 'Inactivo', name: 'status' },
                        { value: 'incomplete', label: 'Incompleto', name: 'status' },
                      ]}
                      placeholder='Seleccionar...'
                    ></ReactSelect>
                  </div>
                  <div className='col-md-6 d-flex'>
                    <input
                      className={tableStyles.SearchNew}
                      type='text'
                      name='search'
                      placeholder='Buscar...'
                      onKeyUp={this.onKeyUpSearch}
                    />

                    <img
                      src={SearchIcon}
                      alt='buscar'
                      className='icons-svg-top-table float-left hoverPointer'
                      onClick={this.handleSearch}
                      height='24px'
                    />
                  </div>
                  {/*<div className="col-md-3 mt-3 d-flex justify-content-end">
                    <div className={`${style.groupBounding}`}>
                    <Link
                        className="align-bottom fw-bold text-decoration-none"
                        to="/administracion/proveedores/selector"
                    >
                        Nueva Vinculación
                    </Link>
                      <img
                        src={agregarEspecialidad}
                        alt="añadir"
                        className={`${style.btnAddTable} hoverPointer`}
                        onClick={this.toggleModal}
                    />
                    </div>
                    {/* <div className="mt-2">
                    <img
                        src={ExcelIcon}
                        alt="exportar"
                        className={`${style.btnExcelIcon} hoverPointer`}
                    />  
                    </div> 
                </div>*/}
                </div>
              </div>
            </div>

            <GenericTableNew headers={renderHeaders} dark={true} lowerCase={true}>
              {renderProviders}
            </GenericTableNew>
            <div className={`${paginationStyles.wrapper} justify-content-between`}>
              <p style={{ display: 'inline' }}>
                Pag. {this.state.filters.page} de{' '}
                {Math.ceil(this.props.total_index / this.state.filters.perpage)
                  ? Math.ceil(this.props.total_index / this.state.filters.perpage)
                  : ''}{' '}
                ({this.props.total_index} encontrados)
              </p>
              <Pagination
                activePage={this.state.filters.page}
                itemsCountPerPage={this.state.filters.perpage}
                totalItemsCount={this.props.total_index}
                // pageRangeDisplayed={10}
                activeClass={paginationStyles.activeClass}
                onChange={this.handlePageChange.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  providers: state.providerReducer.providers,
  row_total: state.providerReducer.row_total,
  total_index: state.providerReducer.total_index,
  ciiu: state.providerReducer.ciiu,
  loading: state.providerReducer.provider_loading,
});

const mapDispatchToProps = dispatch => ({
  getProviders: params => {
    dispatch(getProviders(params));
  },
  cleanDrafts: () => {
    dispatch(cleanDrafts());
  },
  getEditProvider: provider => {
    dispatch(getEditProvider(provider));
  },
  getCIIU: () => {
    dispatch(getCIIU());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountingProviders);
