import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {
  GET_PAYROLL_AUTHORIZATION,
  GET_ONE_PAYROLL_AUTHORIZATION,
  GET_PAYMENTS_AUTHORIZATION,
  GET_CARD_CREDIT,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

const MySwal = withReactContent(Swal);

export const getListPayrollAuthorization = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_PAYROLL_AUTHORIZATION,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payrollSheet/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, total } = res;
      if (results?.length === 0) {
        MySwal.fire({
          icon: 'info',
          text: 'No hay registros para mostrar',
          confirmButtonText: 'Aceptar',
        });
      }
      dispatch({
        type: GET_PAYROLL_AUTHORIZATION,
        payload: results,
        total: total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PAYROLL_AUTHORIZATION,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getOnePayrollAuthorization = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payrollSheet/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, total } = res;
      dispatch({
        type: GET_ONE_PAYROLL_AUTHORIZATION,
        payload: results,
        total: total,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const creatPayroll =
  (data, doAfter, loading = null, textMessage) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payrollSheet/auth/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        token: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          if (loading) {
            loading();
          }
          customSwaltAlert({
            icon: 'success',
            title: res.message,
            // text: res.results[0].monthES + " " + res.results[0].day + " - " + res.results[0].year,
            text: textMessage,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(response => {
            if (response.isConfirmed) {
              doAfter();
            } else {
              doAfter();
            }
          });
        } else {
          if (loading) {
            loading();
          }
          MySwal.fire({
            icon: 'error',
            title: 'Error en la acción sobre la plantilla',
            text: res.message,
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
        }
      })
      .catch(res => {
        if (loading) {
          loading();
        }
        MySwal.fire({
          icon: 'error',
          title: 'Error en la acción sobre la plantilla',
          text: res.message,
          footer: 'Si el problema persiste comuníquese con un asesor.',
        });
      });
  };

export const getPaymentsAuthorization = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_PAYMENTS_AUTHORIZATION,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/creditCardPayment/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;
      if (results?.length === 0) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'No hay registros para mostrar',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: GET_PAYMENTS_AUTHORIZATION,
        payload: results,
        total: row_count,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PAYMENTS_AUTHORIZATION,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getCardCredit = objFilters => (dispatch, getCreate) => {
  dispatch({
    type: GET_CARD_CREDIT,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/creditCard/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;

      dispatch({
        type: GET_CARD_CREDIT,
        payload: results,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_CARD_CREDIT,
        payload: [],
        loading: false,
      });
      console.error(err.message);
    });
};
export const createCreditCard = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/creditCard/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        MySwal.fire({
          icon: 'success',
          title: res.message,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        }).then(response => {
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: res.message,
          footer: 'Si el problema persiste comuníquese con un asesor.',
        });
      }
    })
    .catch(res => {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: res.message,
        footer: 'Si el problema persiste comuníquese con un asesor.',
      });
    });
};
export const registerCreditPayment =
  (data, doAfter, loading = null) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/accounting/creditCardPayment/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        token: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          customSwaltAlert({
            icon: 'success',
            title: res.message,
            text: res.results.sequence,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          }).then(response => {
            if (response.isConfirmed) {
              doAfter();
            } else {
              doAfter();
            }
          });
          if (loading) {
            loading();
          }
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });

          if (loading) {
            loading();
          }
        }
      })
      .catch(res => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          text: res.message,
          footer: 'Si el problema persiste comuníquese con un asesor.',
        });
        if (loading) {
          loading();
        }
      });
  };

export const authorizationPayment = (data, info, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  customSwaltAlert({
    icon: 'warning',
    title: '¿Esta seguro?',
    text: `Se autorizará el pago por tarjeta de crédito: ${info}`,
    showCancelButton: true,
  }).then(response => {
    if (response.isConfirmed) {
      fetch(`${URL_GATEWAY}${API_VERSION}/accounting/creditCardPayment/changeStatus/`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',

          token: token,
        },
      })
        .then(response => response.json())
        .then(res => {
          if (res.success) {
            customSwaltAlert({
              icon: 'success',
              showCancelButton: false,
              title: `Autorizado exitosamente`,
              text: res.message,
              confirmButtonText: 'Aceptar',
            }).then(response => {
              if (response.isConfirmed) {
                doAfter();
              } else {
                doAfter();
              }
            });
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: res.message,
              showCancelButton: false,
              confirmButtonText: 'Aceptar',
              footer: 'Si el problema persiste comuníquese con un asesor.',
            });
          }
        })
        .catch(res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
        });
    }
  });
};
