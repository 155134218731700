import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isEmptyOrUndefined, loader } from '../../helpers/helpers';
import treeDots from '../../assets/img/icons/threeDots.svg';
import addCheck from '../../assets/img/icons/add-check.svg';
import iconImportanceHigh from '../../assets/img/icons/iconImportanceHigh.svg';
import iconImportanceLow from '../../assets/img/icons/iconImportanceLow.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import styles from '../../components/Layouts/tableStyle.module.scss';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import PopUp from '../../components/Popup/customPopUpExtends';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Select from 'react-select';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import { customSwaltAlertAsistencial } from '../../helpers';
import { Box } from '@mui/material';

export const ImportancePatient = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(1);

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showModal: false,
    isEditing: false,
    header: [
      {
        title: 'Descripción de la importancia',
        className: 'col-3 text-start px-2',
      },
      {
        title: 'Pacientes asociados',
        className: 'text-center px-2',
      },
      {
        title: 'Clasificación',
        className: 'text-center px-2',
      },
      {
        title: 'Ícono',
        className: 'text-center px-2',
      },
      {
        title: 'Estado',
        className: 'text-center',
      },
      {
        title: <>&nbsp;</>,
        className: 'text-center',
      },
    ],
  });

  const [filters, setFilters] = useState({
    search: '',
    classification: '',
    page: 1,
    perpage: 10,
  });

  const [importance, setImportance] = useState({
    description: '',
    classification: { label: '', value: '' },
    objFilters: filters,
    status: '',
  });

  useEffect(() => {
    getImportancePatient({
      url: '/medical/markups/',
      token: token,
      objFilters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const {
    results: importancePatient,
    load: loadImportancePatient,
    trigger: getImportancePatient,
  } = useGetMethod();

  const { load: loadSendImportancePatient, trigger: sendImportancePatient } = usePostMethod();

  const onSubmitImportance = (item, edit) => {
    if (isEmptyOrUndefined(importance?.description) && !edit) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'campo descripción obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(importance?.classification?.value) && !edit) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'campo clasificación obligatorio',
        showCancelButton: false,
      });
    }

    sendImportancePatient({
      url: '/medical/markups/',
      token: token,
      noAlert: true,
      method: services.isEditing || edit ? 'PUT' : 'POST',
      body:
        services.isEditing || edit
          ? {
              classification: importance?.classification?.value,
              id: item?.id ? item?.id : importance.id,
              description: importance?.description ? importance?.description : null,
              status:
                item?.status === 'enabled' || importance.status === 'enabled'
                  ? 'disabled'
                  : 'enabled',
            }
          : {
              description: importance?.description,
              classification: importance?.classification?.value,
            },
      doAfterSuccess: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: `${services.isEditing ? 'Actualizado' : 'Creado'} exitosamente`,
          text: `Se ha ${services.isEditing ? 'actualizado' : 'creado'} la importancia: ${importance.description}`,
          showCancelButton: false,
        }).finally(() => {
          getImportancePatient({
            url: '/medical/markups/',
            token: token,
            objFilters: filters,
          });
          setFilters({ ...filters, search: '', status: '', description: '', classification: '' });
          setImportance({ id: '', description: '', status: '' });
          setServices({ ...services, showModal: false, isEditing: false });
          setTrigger(trigger + 1);
        });
      },
    });
  };

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-2'>{item.description}</td>
            <td className='text-center px-2'>{item.patientQty}</td>
            <td className='text-center px-2'>
              {item?.classification === 'low' ? (
                <div
                  className={`${styles.app_container_state} p-2 m-auto`}
                  style={{ width: '80px' }}
                >
                  <span className={styles.app_state_true}>Bajo</span>
                </div>
              ) : (
                <div
                  className={`${styles.ordRedBgButton} py-2 px-1 m-auto`}
                  style={{ width: '80px' }}
                >
                  <span className={styles.app_state_false}>alto</span>
                </div>
              )}
            </td>
            <td className='text-center px-2'>
              {item?.classification === 'low' ? (
                <img src={iconImportanceLow} alt='importancia baja' />
              ) : (
                <img src={iconImportanceHigh} alt='importancia alta' />
              )}
            </td>
            <td>
              {item.status === 'enabled' ? (
                <div className={`${styles.app_container_state} p-2`}>
                  <span className={styles.app_state_true}>{item.statusDescription}</span>
                </div>
              ) : (
                <div className={`${styles.ordRedBgButton} py-2 px-1`}>
                  <span className={styles.app_state_false}>{item.statusDescription}</span>
                </div>
              )}
            </td>
            <td className='text-center'>
              <PopUp
                showEdit={true}
                showEnable={true}
                position='right'
                triggerSrc={treeDots}
                editClickEvent={() => {
                  setServices({
                    ...services,
                    showModal: true,
                    isEditing: true,
                  });

                  setImportance({
                    ...importance,
                    id: item.id,
                    description: item.description,
                    classification: {
                      label: item?.classification === 'high' ? 'Alto' : 'Bajo',
                      value: item?.classification,
                    },
                  });
                }}
                isEnabled={item.status === 'enabled'}
                enableText={item.status === 'enabled' ? 'Inhabilitar' : 'Habilitar'}
                enableClickEvent={() => {
                  customSwaltAlertAsistencial({
                    icon: 'warning',
                    title: '¿Esta seguro?',
                    text: `Se ${
                      item.status === 'enabled' ? 'inhabilitará' : 'habilitará'
                    } la importancia: ${item.description}`,
                    showCancelButton: true,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      sendImportancePatient({
                        url: '/medical/markups/',
                        token: token,
                        noAlert: true,
                        method: 'PUT',
                        body: {
                          id: item?.id,
                          status: item?.status === 'enabled' ? 'disabled' : 'enabled',
                        },
                        doAfterSuccess: () => {
                          customSwaltAlertAsistencial({
                            icon: 'success',
                            title: `Actualizado exitosamente`,
                            text: `Se ha actualizado la importancia: ${item.description}`,
                            showCancelButton: false,
                          }).then(() => setTrigger(trigger + 1));
                        },
                      });
                    }
                  });
                }}
              />
            </td>
          </tr>,
        );
      });
      return tempList;
    }
  };

  return (
    <>
      {loadImportancePatient && loader}
      {loadSendImportancePatient && loader}
      <div className='container-fluid'>
        <OrdModal
          title={'Importancia de paciente'}
          show={services.showModal}
          btnYesName={'Aceptar'}
          btnNoName={'Cancelar'}
          size={'250'}
          onHide={() => {
            setServices({ ...services, showModal: false, isEditing: false });
            setImportance({});
          }}
          btnYesEvent={() => {
            onSubmitImportance();
          }}
          btnNoEvent={() => setServices({ ...services, showModal: false })}
        >
          <Form.Group>
            <Form.Label className={styles.app_title_input}>
              <div>
                <span className={styles.app_title_new_item}>Descripción</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>

            <form
              onSubmit={e => {
                e.preventDefault();
                onSubmitImportance();
              }}
            >
              <input
                type='text'
                placeholder='Escribir...'
                value={importance.description}
                onChange={e => {
                  setImportance({ ...importance, description: e.target.value });
                }}
                className={`ordInputAdmission2 w-100 ${styles.tlnTextGray}`}
              />

              <div className='mb-4'>
                <div className='mt-2'>
                  <span className={styles.app_title_new_item}>Clasificación</span>
                  <span style={{ color: '#FFFF00' }}>*</span>
                </div>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  value={
                    importance?.classification?.value || services?.isEditing
                      ? {
                          value: importance?.classification?.value,
                          label: importance?.classification?.label,
                        }
                      : undefined
                  }
                  onChange={e => {
                    setImportance({
                      ...importance,
                      classification: { label: e.label, value: e.value },
                    });
                  }}
                  options={[
                    { label: 'seleccionar', value: '' },
                    { label: 'Alto', value: 'high' },
                    { label: 'Bajo', value: 'low' },
                  ]}
                />
              </div>
            </form>
          </Form.Group>
        </OrdModal>
        <OrdGenericTemplate className='w-90' titleSize={12} title='Importancia de pacientes'>
          <div>
            <div
              className='d-grid gap-4 align-items-end w-100'
              style={{ gridTemplateColumns: '200px 1fr 150px' }}
            >
              <div className='d-flex flex-column '>
                <span style={{ color: '#1a538d' }}> &nbsp;Clasificación</span>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  onChange={e => {
                    setFilters({ ...filters, classification: e.value });
                  }}
                  options={[
                    { label: 'seleccionar', value: '' },
                    { label: 'Alto', value: 'high' },
                    { label: 'Bajo', value: 'low' },
                  ]}
                />
              </div>

              <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12}`}>
                <input
                  type='text'
                  className={`ord-roundInput ${styles.appInputDate} w-100 ${styles.tlnTextGray}`}
                  placeholder='Escribe aquí para buscar'
                  autoComplete='off'
                  value={filters.search}
                  onChange={e => {
                    setFilters({ ...filters, search: e.target.value });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                />
                <img
                  src={ordBlueSearch}
                  onClick={() => setTrigger(trigger + 1)}
                  className='pointer'
                  alt='buscar'
                />
              </Box>

              <div
                className={`d-flex gap-2 ordGroupAddButton align-items-center text-center ${styles.hoverOrdAquaMarine} ${styles.tlnTextGray} ${styles.fwBold}`}
                style={{ padding: '5px 10px' }}
                onClick={() => setServices({ ...services, showModal: true })}
              >
                <div className='bold'>Nuevo ítem</div>
                <img src={addCheck} alt='add' />
              </div>
            </div>

            <OrdTable
              shadow
              hasChildren={true}
              headers={services.header}
              paginate={{
                activePage: filters.page,
                totalPages: importancePatient?.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  const result = await getImportancePatient({
                    url: '/medical/markups/',
                    objFilters: { ...filters, page: e },
                    token: token,
                  });
                  setFilters({ ...filters, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(importancePatient.results)}
            </OrdTable>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
