import { Close, EmailOutlined, PeopleOutline, PhoneOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { usePostMethod } from '../../Hooks/useFetch';
import {
  getClient,
  getDayCalendar,
  resetDetailCalendar,
} from '../../actions/receiptOfInvoicesActions';
import Robot from '../../assets/img/icons/bx_bot.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Correo from '../../assets/img/icons/icon_Email.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import Tel from '../../assets/img/icons/phone.svg';
import { customSwaltAlert, genericDownloadExcel, loader } from '../../helpers';
import SituationPurseDetail from './SituationPurseDetail';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useGetMethod } from '../../Hooks';
import modalStyles from '../../components/Layouts/modalnew.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Style from '../../components/Payroll/payrollStyles.module.scss';

function SituationPurse() {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    year: '',
    month: '',
    client: '',
  });
  const [details, setDetails] = useState({ show: false });
  const [contactsModal, setContactsModal] = useState({
    isOpen: false,
    data: null,
  });
  const isLoading = store.invoiceReducer?.loadingCalendar;

  const { trigger: getCompany, load: companyLoader } = useGetMethod();
  const { trigger: postDocExcel } = usePostMethod();

  useEffect(() => {
    dispatch(getClient({ eaccount: store.loginReducer.currentAccount.id }));

    return () => {
      dispatch(resetDetailCalendar());
    };
  }, [dispatch, store.loginReducer.currentAccount.id]);

  const optionMonth = [
    { value: '', label: 'Seleccionar...' },
    { value: '1', label: 'Enero' },
    { value: '2', label: 'Febrero' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Mayo' },
    { value: '6', label: 'Junio' },
    { value: '7', label: 'Julio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ];

  const optionYear = [{ value: '', label: 'Seleccionar...' }];
  for (let step = 2020; step < 2050; step++) {
    optionYear.push({ value: step, label: step });
  }

  let optionsEvents = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(store.accountingConsultsReducer.listEventTypes)) {
    store.accountingConsultsReducer.listEventTypes.forEach((item, index) => {
      optionsEvents.push({
        value: item.id,
        label: item.name,
        key: index + 'eventType',
      });
    });
  }
  let optionsClient = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(store.invoiceReducer?.clients)) {
    store.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  const onSubmitFilters = async e => {
    e.preventDefault();

    const { year, month, client } = e.target;

    if (year.value === '') {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un año',
        showCancelButton: false,
      });
    }
    if (month.value === '') {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione un mes',
        showCancelButton: false,
      });
    }
    if (client.value === '') {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Seleccione una empresa',
        showCancelButton: false,
      });
    }
    dispatch(
      getDayCalendar({
        year: year.value ? year.value : '',
        month: month.value ? month.value : '',
        client: client.value ? client.value : '',
      }),
    );
  };

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/activity/excel/',
      method: 'POST',
      body: {
        ...filter,
      },
      token: store.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onOpenClients = async () => {
    if (!filter.client) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una empresa para listar los contactos.',
        showCancelButton: false,
      });
      return;
    }
    const result = await getCompany({
      url: '/coporateClients/',
      objFilters: {
        id: filter.client,
        id_account: store.loginReducer.currentAccount.id,
      },
      token: store.loginReducer.Authorization,
    });
    if (result.results?.[0]) {
      setContactsModal({
        isOpen: true,
        data: result.results[0],
      });
    }
  };

  const renderContactsModal = () => {
    return (
      <Modal
        show={contactsModal.isOpen}
        centered
        className={`${modalStyles.container}`}
        onHide={() => setContactsModal({ isOpen: false })}
      >
        {contactsModal.data && (
          <>
            <div className='d-flex align-items-center justify-content-between py-3 px-4'>
              <div></div>
              <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
                {contactsModal.data.copyName}
              </h3>
              <Close
                className='pointer text-secondary'
                onClick={() => setContactsModal({ data: null, isOpen: false })}
              />
            </div>
            <hr className='m-0' />
            <div className='px-4 py-2 overflow-hidden'>
              <div style={{ maxHeight: 400, overflowY: 'auto' }}>
                {contactsModal.data.contacts.length ? (
                  contactsModal.data.contacts.map(item => (
                    <div key={item.id} className='text-muted border-bottom py-3 small'>
                      <div className='fw-bold'>{item.name}</div>
                      <div className='d-flex align-items-center my-2'>
                        <PhoneOutlined className='text-primary me-2' />
                        <span>{item.phone}</span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <EmailOutlined className='text-primary me-2' />
                        <span>{item.email}</span>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-muted text-center py-4'>No hay contactos para listar.</div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal>
    );
  };

  const renderCalendar = (
    <div className='container-fluid px-5 mt-3'>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <h1>Situación de cartera</h1>

      <form onSubmit={onSubmitFilters} className={`row g-3 align-items-end`}>
        <div className='col-lg-3'>
          &nbsp;<label className={tableStyles.stylesLabel}>Año</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            placeholder='Seleccionar...'
            options={optionYear}
            styles={customSelectNewDark}
            name='year'
            value={optionYear.find(item => item.value === filter.year)}
            onChange={e =>
              setFilter({
                ...filter,
                year: e.value,
              })
            }
          />
        </div>

        <div className='col-lg-3'>
          &nbsp;<label className={tableStyles.stylesLabel}>Mes</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            placeholder='Seleccionar...'
            options={optionMonth}
            styles={customSelectNewDark}
            name='month'
            value={optionMonth.find(item => item.value === filter.month)}
            onChange={e =>
              setFilter({
                ...filter,
                month: e.value,
              })
            }
          />
        </div>

        <div className='col-lg-4'>
          &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            placeholder='Seleccionar...'
            options={optionsClient}
            styles={customSelectNewDark}
            name='client'
            value={optionsClient.find(item => item.value === filter.client)}
            onChange={e =>
              setFilter({
                ...filter,
                client: e.value,
                clientName: e.label,
              })
            }
          />
        </div>

        <div className='col d-flex align-items-center justify-content-end me-3'>
          <PeopleOutline
            className='text-primary pointer me-3'
            style={{ fontSize: 30 }}
            onClick={() => onOpenClients()}
          />
          <button className='me-3' type='submit'>
            <img src={Lupa} className={'cursorPointer'} alt='Lupa' />
          </button>
          <button type='button'>
            <img src={Excel} alt='Excel' onClick={() => downloadExcelDoc()} />
          </button>
        </div>
      </form>

      <div className='container-month mt-4'>
        <div className='container-nameday d-flex flex-nowrap'>
          <div className={Style.name_day} style={{ borderRadius: '10px 0 0 0' }}>
            <p>Dom</p>
          </div>
          <div className={Style.name_day}>
            <p>Lun</p>
          </div>
          <div className={Style.name_day}>
            <p>Mar</p>
          </div>
          <div className={Style.name_day}>
            <p>Mié</p>
          </div>
          <div className={Style.name_day}>
            <p>Jue</p>
          </div>
          <div className={Style.name_day}>
            <p>Vie</p>
          </div>
          <div className={Style.name_day} style={{ borderRadius: '0 10px 0 0' }}>
            <p>Sáb</p>
          </div>
        </div>

        <div className={Style.containerDay}>
          {Array.isArray(store?.invoiceReducer?.dayCalendar) &&
            store?.invoiceReducer?.dayCalendar?.map((item, ind) => (
              <div
                key={ind}
                className={`${Style.number_day}`}
                style={{
                  height: '150px',
                  cursor:
                    (item.day && item.date && !Array.isArray(item.data)) ||
                    item.date === moment().format('YYYY-MM-DD')
                      ? 'pointer'
                      : undefined,
                  background: !(
                    (item.day && item.date && !Array.isArray(item.data)) ||
                    item.date === moment().format('YYYY-MM-DD')
                  )
                    ? '#f8f8f8'
                    : undefined,
                }}
                onClick={() => {
                  if (
                    (item.day && item.date && !Array.isArray(item.data)) ||
                    item.date === moment().format('YYYY-MM-DD')
                  ) {
                    setDetails({
                      ...details,
                      show: true,
                      info: item,
                      filter: filter,
                    });
                  }
                }}
              >
                <p className='text-day'>{item.day}</p>
                {item?.data?.detail?.length >= 1 ? (
                  <div
                    className={Style.day_icons}
                    style={{ backgroundColor: '#005DBF', marginTop: '5px' }}
                  >
                    <div
                      onClick={() =>
                        setDetails({
                          ...details,
                          show: true,
                          info: item,
                          filter: filter,
                        })
                      }
                    >
                      <b className='text-white'>{item?.data.qty}</b>
                      {item?.data?.detail?.map((x, ind) => (
                        <img
                          key={ind}
                          alt=''
                          style={{
                            marginLeft: '5px',
                            width: '15px',
                            verticalAlign: 'baseline',
                          }}
                          src={
                            x.icon === 'bot'
                              ? Robot
                              : x.icon === 'email'
                                ? Correo
                                : x.icon === 'phone'
                                  ? Tel
                                  : ''
                          }
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  return details.show ? (
    <SituationPurseDetail
      setDetails={setDetails}
      info={details.info}
      filter={details.filter}
      edit={details.edit}
      details={details.details}
      show={details.show}
    />
  ) : (
    <>
      {companyLoader && loader}
      {renderCalendar}
      {renderContactsModal()}
    </>
  );
}
export default SituationPurse;
