import { useEffect, useState } from 'react';
import { OrdCard } from '../OrderingModule/OrderingComponents/OrdCard';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import outLineGrayTrash from '../assets/img/icons/outLineGrayTrash.svg';
import warningIcon from '../assets/img/icons/warningIcon.gif';
import penFullGray from '../assets/img/icons/penFullGray.svg';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import ordComponentStyles from '../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { Col, Form, Row } from 'react-bootstrap';
import { customSelectMulti } from '../components/Layouts/react-select-custom';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { formatToRcSelect, isEmptyOrUndefined, message } from '../helpers/helpers';
import Swal from 'sweetalert2';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
import Loader from 'react-loader-spinner';
export const NonPosDrugsJust = () => {
  const store = useSelector(state => state);
  let loader = (
    <div className='loading'>
      <Loader type='Oval' color='#003f80' height={100} width={100} />
    </div>
  );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [justification, setJustification] = useState({
    isEditing: false,
    isDeleting: false,
    showModal: false,
    pbjDescription: '',
    pbjName: '',
    pbjId: '',
    selectedMedicines: [],
    selectedJustifications: [],
  });
  const [currentDiagnostic, setCurrentDiagnostic] = useState(null);
  const [currentMedicines, setCurrentMedicines] = useState(null);
  const [data] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [noJustifyMedicines, setNoJustifyMedicines] = useState({
    showModal: false,
    data: [],
  });
  const [filters, setFilters] = useState({
    search: '',
    page: 1,
    perpage: 10,
  });
  const [foundedDiagnostics, setFoundedDiagnostics] = useState([
    { value: '', label: 'Escriba el codigo del diagnostico...', isDisabled: true },
  ]);
  /* ----------------------------- FETCHS SECTION ----------------------------- */
  /* ----------------------------- GET ----------------------------- */
  const { results: medicines, load: medicineLoader, trigger: getMedicines } = useGetMethod();
  const { results: diagnostics, load: diagnosticsLoader, trigger: getDiagnostics } = useGetMethod();
  const {
    results: medicalJustResults,
    load: medicalJustLoader,
    trigger: getMedicalJustResults,
  } = useGetMethod();
  const {
    results: noJustMedicines,
    load: noJustMedicinesLoader,
    trigger: getNoJustMedicines,
  } = useGetMethod();
  /* ---------------------------------- POST /PUT ---------------------------------- */
  const { load: justCreateLoader, trigger: justificationSubmit } = usePostMethod();
  /* ---------------------------------- DELETE ---------------------------------- */
  const { load: justDeleteLoader, trigger: justificationDelete } = usePostMethod();
  /* ------------------------------------ X ----------------------------------- */
  useEffect(
    () => {
      getMedicines({
        url: '/medical/medicine/',
        objFilters: { slim: 1, idAccount: idEnterprise },
        token: token,
      });
      getDiagnostics({
        url: '/medical/cie10diagnostics',
        objFilters: { slim: 1, enabled: 1 },
        token: token,
      });
      getMedicalJustResults({
        url: '/medical/justification',
        objFilters: { idAccount: idEnterprise, search: filters.search },
        token: token,
      });
      getNoJustMedicines({
        url: '/medical/medicine-no-justification-pos',
        objFilters: { idAccount: idEnterprise },
        token: token,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const nonJustHeaders = [
    {
      title: <>&nbsp;</>,
      className: 'col-1',
    },
    {
      title: 'Código',
      className: 'col-2',
    },
    {
      title: 'Nombre',
      className: 'col-6 text-start',
    },
  ];
  const [selectedNoJust, setSelectedNoJust] = useState([]);
  const changeBoxSelected = item => {
    let tempSelected = selectedNoJust;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x === item);
      if (target) {
        tempSelected = tempSelected.filter(x => x !== item);
      } else {
        tempSelected.push(item);
      }
      setSelectedNoJust(tempSelected);
    }
  };
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td>
              <>
                {' '}
                <input
                  key={index}
                  className='form-check-input styleCheckInputRatio mr-2 cursorPointer'
                  style={{ border: '2px solid #1a538d' }}
                  checked={selectedNoJust.find(x => x === item.mdcId)}
                  onChange={() => changeBoxSelected(item.mdcId)}
                  type='checkbox'
                ></input>
              </>
            </td>
            <td>{item.code}</td>
            <td>{item.name}</td>
          </tr>,
        );
      });
    }
    return tempList;
  };
  const formattedMedicines = formatToRcSelect(medicines.results, 'id', 'name', '', '', '', false);
  const animatedComponents = makeAnimated();
  const [ids, setIds] = useState({});
  const handleEdit = info => {
    let medicines = [];
    let diags = [];

    info?.subInfo?.medicines?.forEach(e => {
      formattedMedicines.filter(med => med.value === e.mdcId).forEach(di => medicines.push(di));
    });

    info?.subInfo?.diagnostics?.forEach(e => {
      diagnostics?.results?.filter(diag => diag.codigo === e.code).forEach(di => diags.push(di));
    });
    let formattedDiags = formatToRcSelect(diags, 'codigo', 'codigo', '', '', '', false);

    let arrIdDiag = [];
    let arrIdMed = [];
    if (formattedDiags.length > 0) {
      formattedDiags.forEach(elem => {
        arrIdDiag.push(elem.value);
      });
    }

    if (medicines.length > 0) {
      medicines.forEach(elem => {
        arrIdMed.push(elem.value);
      });
    }

    setJustification({
      isEditing: true,
      showModal: true,
      pbjDescription: info?.title,
      pbjName: info?.body,
      selectedMedicines: medicines,
      selectedJustifications: formattedDiags,
      pbjId: info?.subInfo?.pbjId,
    });

    setIds({
      arrIdDiag: arrIdDiag,
      arrIdMed: arrIdMed,
    });
  };
  const renderTooltipEdit = props => <Tooltip {...props}>Editar</Tooltip>;
  const renderTooltiDelete = props => <Tooltip {...props}>Eliminar</Tooltip>;
  const handleDelete = info => {
    Swal.fire({
      title: `<span style="color:#00b4cc;" >¿Está seguro?</span>`,
      text: `Se eliminará la justificación  \n "${info?.title}"`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      // setCurrentMsg(info?.title)
      if (result.isConfirmed) {
        justificationDelete({
          url: '/medical/justification',
          token: token,
          doAfterSuccess: () => {
            getMedicalJustResults({
              url: '/medical/justification',
              objFilters: { idAccount: idEnterprise, search: filters.search },
              token: token,
            });
            setJustification({ ...justification, showModal: false });
          },
          succesAction: () =>
            message(
              'success',
              `<strong class="${tableStyles.ordClearBlueText}">Se ha eliminado</strong>`,
              info?.title,
              'Aceptar',
            ),
          method: 'PUT',
          body: { status: 'disabled', pbjId: info?.subInfo?.pbjId },
        });
      }
    });
  };
  let buttons = [
    {
      btnVariant: 'gray',
      btnIcon: (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltipEdit}
        >
          <span>
            <img alt={'Editar'} className={`${tableStyles.editBlueHover}`} src={penFullGray}></img>
          </span>
        </OverlayTrigger>
      ),
      action: info => handleEdit(info),
    },
    {
      btnVariant: 'darkBlue',
      btnIcon: (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltiDelete}
        >
          <span className={`${tableStyles.deleteBlueHover}`}>
            {' '}
            <img alt='Eliminar' src={outLineGrayTrash}></img>{' '}
          </span>
        </OverlayTrigger>
      ),
      action: info => handleDelete(info),
    },
  ];
  const handleChangeMedicine = e => {
    setCurrentMedicines(e);
    let arrIdEnd = [];
    if (e.length > 0) {
      e.forEach(elem => {
        arrIdEnd?.push(elem?.value);
      });
    }
    setIds({
      ...ids,
      arrIdMed: arrIdEnd,
    });
    setJustification({ ...justification, selectedMedicines: arrIdEnd });
  };
  const handleDiagnostic = e => {
    setCurrentDiagnostic(e);
    let arrIdEnd = [];
    if (e.length > 0) {
      e.forEach(elem => {
        arrIdEnd?.push(elem?.value);
      });
    }
    setIds({
      ...ids,
      arrIdDiag: arrIdEnd,
    });
    setJustification({ ...justification, selectedJustifications: arrIdEnd });
  };
  const handleSubmit = () => {
    if (isEmptyOrUndefined(justification.pbjName)) {
      return message('info', 'Campos vacíos', 'El campo justificación es obligatorio', 'Aceptar');
    }
    if (isEmptyOrUndefined(justification.pbjDescription)) {
      return message(
        'info',
        'Campos vacíos',
        'El campo justifiación NO PBS  es obligatorio',
        'Aceptar',
      );
    }
    if (justification?.selectedMedicines?.length <= 0) {
      return message(
        'info',
        'Campos vacíos',
        'Debe seleccionar al menos un medicamento',
        'Aceptar',
      );
    }
    if (justification?.selectedJustifications?.length <= 0) {
      return message(
        'info',
        'Campos vacíos',
        'Debe seleccionar al menos un diagnóstico',
        'Aceptar',
      );
    }

    justificationSubmit({
      url: '/medical/justification',
      token: token,
      doAfterSuccess: () => {
        getMedicalJustResults({
          url: '/medical/justification',
          objFilters: { idAccount: idEnterprise, search: filters.search },
          token: token,
        });
        setJustification({
          isEditing: false,
          isDeleting: false,
          showModal: false,
          pbjDescription: '',
          pbjName: '',
          pbjId: '',
          selectedMedicines: [],
          selectedJustifications: [],
        });
        setIds({});
        setCurrentMedicines(null);
        setCurrentDiagnostic(null);
      },
      succesAction: () =>
        message(
          'success',
          // `Justificación PBS ${justification.isEditing ? 'actualizada' : 'creada'}`,
          `<strong class="${tableStyles.ordClearBlueText}">Justificación ${justification.isEditing ? 'actualizada' : 'creada</strong>'}`,
          // `<strong>HTML <u>example</u></strong>`,
          justification.pbjName || '-',
        ),
      method: justification.isEditing ? 'PUT' : 'POST',

      body: {
        pbjName: justification.pbjName,
        pbjDescription: justification.pbjDescription,
        medicines: justification.isEditing ? ids.arrIdMed : justification.selectedMedicines,
        diagnostics: justification.isEditing ? ids.arrIdDiag : justification.selectedJustifications,
        idAccount: idEnterprise,
        pbjId: justification.pbjId || '',
      },
    });
  };
  const handleFilter = e => {
    let nombreEncontrados;
    if (isEmptyOrUndefined(e)) {
      setFoundedDiagnostics([
        { value: '', label: 'Digite el número del diagnóstico', isDisabled: true },
      ]);
    }
    if (e?.length > 0) {
      let expresion = new RegExp(`${e?.toUpperCase()}.*`, 'i');
      if (e.length > 0) {
        nombreEncontrados = diagnostics.results?.filter(dat => expresion.test(dat.codigo));
        setFoundedDiagnostics(
          formatToRcSelect(nombreEncontrados, 'codigo', 'codigo', '', '', '', false),
        );
      } else {
        setFoundedDiagnostics([
          { value: '', label: 'Digite el número del diagnóstico', isDisabled: true },
        ]);
      }
    }
  };
  const renderTooltip = props => (
    <Tooltip {...props}>
      <div className=' fw-bold'>
        <span className={`${tableStyles.ordOrangeText}`}>Medicamentos sin justificación</span>
      </div>
    </Tooltip>
  );
  const handleBringMedicines = () => {
    let selectedMedicines = [];
    if (selectedNoJust.length > 0) {
      selectedNoJust.forEach(e => {
        let founded = formattedMedicines.find(x => x.value === e);
        selectedMedicines.push(founded);
      });
      setNoJustifyMedicines({ ...noJustifyMedicines, showModal: false });
      setJustification({ ...justification, showModal: true });
    } else {
      return message(
        'info',
        'Ningún medicamento escojido',
        'Por favor seleccione al menos un medicamento',
      );
    }
  };
  const renderTooltipCodes = code => <Tooltip>{code}</Tooltip>;
  return (
    <>
      {/*  IF YOU WANT TO SHOW A LOADING JUST PUT THE EXTRACTED VAR IN A FRAGMENT, OF COURSE INSIDE  YOUR RENDER METHOD */}
      {/* REMEMBER TO USE AN ALIAS TO DISMISS POSSIBLE BUGS AND DISTINCT ALL LOADERS */}
      {medicineLoader && loader}
      {diagnosticsLoader && loader}
      {justCreateLoader && loader}
      {medicalJustLoader && loader}
      {justDeleteLoader && loader}
      {/* /* ------------------------------- NEW JUSTIFICATION MODAL ------------------------------ */}
      <OrdModal
        title={justification.isEditing ? 'Editar justificación' : 'Nueva justificación'}
        show={justification.showModal}
        btnYesName={`${justification.isEditing ? 'Actualizar' : 'Guardar'}`}
        btnNoName='Cancelar'
        size='620'
        // btnYesEvent={justification.isEditing ? () => alert('to Edit') : () => handleSubmit()}
        btnYesEvent={() => handleSubmit()}
        onHide={() => {
          setJustification({ showModal: false, isEditing: false });
        }}
        btnNoEvent={() => {
          setJustification({ showModal: false, isEditing: false });
        }}
      >
        {/* /* --------------------------- Special form Group --------------------------- */}
        <Form.Group className={`mb-4 pt-2 text-start`} controlId='cc'>
          <Row className={`px-2`}>
            <Col xs={12}>
              <Form.Group className='mb-3' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                  <b className='text-start'>
                    &nbsp;Justificación<span className='text-danger'>*</span>
                  </b>
                </Form.Label>
                <Form.Control
                  value={justification.pbjName}
                  className={`ord-roundInput`}
                  type='text'
                  placeholder='Escribir...'
                  onChange={e => setJustification({ ...justification, pbjName: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className='mb-3' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                  <b className='text-start'>
                    &nbsp;Justificación NO PBS
                    <span className='text-danger'>*</span>
                  </b>
                </Form.Label>
                <textarea
                  value={justification.pbjDescription}
                  placeholder={'Escribir...'}
                  rows='25'
                  cols='55'
                  style={{ height: '5rem' }}
                  onChange={e =>
                    setJustification({ ...justification, pbjDescription: e.target.value })
                  }
                  className={`text-secondary ord-roundInput w-100`}
                ></textarea>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className='mb-3' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                  <b className='text-start'>&nbsp;Medicamentos</b>
                  <span className='text-danger'>*</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  isMulti
                  value={
                    currentMedicines === null ? justification.selectedMedicines : currentMedicines
                  }
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  onChange={e => handleChangeMedicine(e)}
                  options={formattedMedicines}
                  className={`text-secondary ${tableStyles.heightScrollSelectApp}`}
                  placeholder={'Seleccionar...'}
                  styles={customSelectMulti}
                ></Select>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className='mb-3' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                  <b className='text-start'>&nbsp;Diagnósticos</b>
                  <span className='text-danger'>*</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  isMulti
                  value={
                    currentDiagnostic === null
                      ? justification?.selectedJustifications
                      : currentDiagnostic
                  }
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  onInputChange={e => {
                    handleFilter(e);
                  }}
                  onChange={e => handleDiagnostic(e)}
                  options={foundedDiagnostics}
                  className={`text-secondary ${tableStyles.heightScrollSelectApp}`}
                  placeholder={'Seleccionar...'}
                  styles={customSelectMulti}
                ></Select>
              </Form.Group>
            </Col>
          </Row>
        </Form.Group>
      </OrdModal>
      {/* /* ------------------------------- NO JUSTIFICATED MEDICINES------------------------------ */}
      <OrdModal
        title={
          <h3 className={`p-3 fw-bold ${tableStyles.ordOrangeText}`}>
            Medicamentos sin justificación
          </h3>
        }
        show={noJustifyMedicines.showModal}
        btnYesName={<span className='px-3'>Crear justificación</span>}
        btnNoName='Cancelar'
        size='550'
        btnYesEvent={() => handleBringMedicines()}
        onHide={() => {
          setNoJustifyMedicines({ ...noJustifyMedicines, showModal: false });
        }}
        btnNoEvent={() => {
          setNoJustifyMedicines({ ...noJustifyMedicines, showModal: false });
        }}
      >
        {/* /* --------------------------- Special form Group --------------------------- */}
        <Form.Group className={`mb-4 pt-2 text-start`} controlId='cc'>
          <Row className={`px-2`}>
            <Col xs={12}>
              <input
                placeholder='Escribe aquí para buscar'
                className={`${ordComponentStyles.backgroundImage} col-12 pl-2`}
              ></input>
            </Col>
            <Col xs={12}>
              {noJustMedicinesLoader && loader}
              <OrdTable
                headers={nonJustHeaders}
                body={data}
                hasChildren={true}
                paginate={{
                  activePage: filters.page,
                  totalPages: noJustMedicines?.rowTotal,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async e => {
                    const result = await getNoJustMedicines({
                      url: '/medical/medicine-no-justification-pos',
                      objFilters: { idAccount: idEnterprise },
                      token: token,
                    });
                    setFilters({ ...filters, page: e });
                    formatData(result?.results);
                  },
                  showTextDetails: true,
                }}
              >
                {formatData(noJustMedicines.results)}
              </OrdTable>
            </Col>
          </Row>
        </Form.Group>
      </OrdModal>
      {/* /* ------------------------------ MAIN CONTENT ----------------------------- */}
      <OrdGenericTemplate
        className='w-90'
        titleSize={12}
        showBottomBorder={false}
        title={'Justificaciones medicamentos No POS'}
        searchWithNew={{
          onChange: e => {
            setFilters({ ...filters, search: e.target.value });
          },
          colSize: '12',
          onSubmit: e => {
            e.preventDefault();
            getMedicalJustResults({
              url: '/medical/justification',
              objFilters: { idAccount: idEnterprise, search: filters.search },
              token: token,
            });
            setTrigger(trigger + 1);
          },
          buttonNewLabel: 'Nueva justificación',
          buttonNewAction: () => {
            setJustification({ ...justification, showModal: true });
          },
        }}
        titleIcon={{
          icon:
            noJustMedicines?.results?.length > 0 ? (
              <>
                <OverlayTrigger
                  placement='right'
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                >
                  <span>
                    <img
                      alt={'icon'}
                      onClick={() => {
                        formatData(noJustMedicines.results);
                        setNoJustifyMedicines({ ...noJustifyMedicines, showModal: true });
                      }}
                      width={'55px'}
                      src={warningIcon}
                      className={`mr-2 mt-2 mb-2 cursorPointer`}
                    ></img>
                  </span>
                </OverlayTrigger>
              </>
            ) : (
              () =>
                message(
                  'info',
                  'Sin resultados',
                  ' No se encontraron datos para mostrar',
                  'Aceptar',
                )
            ),
        }}
      >
        {medicalJustResults.results?.length > 0 &&
          medicalJustResults.results.map((e, i) => {
            return (
              <div key={i} className=' mx-1 mb-2'>
                <OrdCard
                  key={trigger + i}
                  title={e?.pbjName}
                  body={e?.pbjDescription}
                  subInfo={{
                    medicines: e?.medicines,
                    pbjId: e?.pbjId,
                    diagnostics: e?.diagnostics,
                    style: { color: '#00B4CC' },
                    title: 'Medicamentos: ',
                    text: e?.medicines?.map((med, index) => {
                      return `${med?.mdcCode} - ${med?.mdcName}${index === e?.medicines?.length - 1 ? '' : ', '}`;
                    }),
                  }}
                  footer={{
                    style: { color: '#F39682' },
                    title: 'Diagnósticos: ',
                    text: e?.diagnostics?.map((eFooter, i) => {
                      return (
                        <OverlayTrigger
                          key={i}
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipCodes(eFooter.description)}
                        >
                          <span className='cursorPointer'>
                            {eFooter.code}
                            {i === e?.diagnostics?.length - 1 ? '' : ', '}
                          </span>
                        </OverlayTrigger>
                      );
                    }),
                  }}
                  buttons={buttons}
                ></OrdCard>
              </div>
            );
          })}
      </OrdGenericTemplate>
    </>
  );
};
