import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Accordion } from 'react-bootstrap';
import {
  Folder,
  FolderOutlined,
  InsertDriveFileOutlined,
  KeyboardArrowDown,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';
//
import IconBack from '../../../assets/img/icons/iconBack.svg';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { convertMoneyFormat, customSwaltAlert, loader } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function FilingHistoryDetails() {
  const params = useParams();
  const history = useHistory();
  const storage = useSelector(state => state);
  const location = useLocation();

  const filingType = new URLSearchParams(location.search).get('type');

  const [documents, setDocuments] = useState();
  const [selectedDocument, setSelectedDocument] = useState(false);

  const { trigger: getCompanyParams, load: companyParamsLoader } = useGetMethod();
  const { trigger: getParamsDocuments, load: documentsLoader } = useGetMethod();
  const {
    results: invoiceDetails,
    trigger: getInvoiceDetails,
    load: detailsLoader,
  } = useGetMethod();
  const { trigger: downloadFiling, load: downloadFilingLoader } = usePostMethod();

  const item = useMemo(() => invoiceDetails?.results?.[0], [invoiceDetails?.results]);

  useEffect(() => {
    if (params.id) {
      getInvoiceDetails({
        url: '/receivable/invoice/getDetail/',
        objFilters: {
          id: params.id,
          eaccount: storage.loginReducer.currentAccount.id,
          documentType: filingType,
        },
        token: storage.loginReducer.Authorization,
      });
    }
  }, [
    params.id,
    getInvoiceDetails,
    storage.loginReducer.currentAccount.id,
    filingType,
    storage.loginReducer.Authorization,
  ]);

  useEffect(() => {
    (async function () {
      if (item) {
        const paramsResponse = await getCompanyParams({
          url: '/receivable/companyParams/',
          objFilters: {
            companyId: item.client_id,
            contractId: item.contract_id,
            eaccount: storage.loginReducer.currentAccount.id,
            status: '1',
          },
          token: storage.loginReducer.Authorization,
        });
        const result = paramsResponse.results[0];
        if (result) {
          const docsResponse = await getParamsDocuments({
            url: '/receivable/companyParams/getDocuments/',
            objFilters: { paramId: result.id },
            token: storage.loginReducer.Authorization,
          });

          if (docsResponse.results[0]) setDocuments(docsResponse.results[0]);
        }
      }
    })();
  }, [
    item,
    getCompanyParams,
    storage.loginReducer.Authorization,
    storage.loginReducer.currentAccount.id,
    getParamsDocuments,
  ]);

  const onDownloadFile = () => {
    const anchorElement = document.createElement('a');
    anchorElement.style.display = 'none';

    downloadFiling({
      token: storage.loginReducer.Authorization,
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        invoices: filingType === 'invoice' ? [params.id] : undefined,
        minutes: filingType === 'minute' ? [params.id] : undefined,
      },
      method: 'POST',
      url: '/receivable/receiptRecord/download/',
      succesAction: response => {
        anchorElement.href = 'data:application/zip;base64,' + response.results;
        anchorElement.download = 'factura.zip';
        anchorElement.click();
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: response.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderDocumentsTable = () => {
    return (
      <>
        {documents && (
          <div className='col-lg-6 d-flex flex-column'>
            <div
              className='row g-0 py-2 px-2 text-white fw-bold small'
              style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
            >
              <div className='col-12 d-flex align-items-center px-2'>
                <Folder fontSize='small' />
                <span className='ms-2'>{documents.containerRealName}</span>
              </div>
            </div>

            <div
              className={`${tableStyles.odd_rows} card border-0 small shadow overflow-hidden`}
              style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
            >
              {documents?.children &&
                documents.children.map((item, index) => {
                  if (item.type === 'folder') {
                    const files = item.files || [];
                    return (
                      <div key={`${item.id}_${index}`} className='row g-0'>
                        <div className='col-12 px-4 py-2'>
                          <Accordion>
                            <Accordion.Toggle
                              className='d-flex align-items-center pointer w-100'
                              eventKey={`${index}`}
                            >
                              <div className='d-flex align-items-center'>
                                <FolderOutlined
                                  className={tableStyles.text_primary_alt}
                                  fontSize='small'
                                />
                                <span className='ms-2 text-muted'>{item.realName}</span>
                              </div>
                              <div className='flex-grow-1'></div>
                              <KeyboardArrowDown className='text-primary' />
                            </Accordion.Toggle>
                            <Accordion.Collapse className='text-muted' eventKey='0'>
                              <div>
                                <div className='p-1'></div>
                                {files.map((child, cIndex) => (
                                  <div
                                    key={`${child.id}_${index}_${cIndex}`}
                                    className={`${tableStyles.hoverable_row} d-flex align-items-center px-3 py-2 pointer`}
                                    onClick={() => setSelectedDocument(true)}
                                  >
                                    <InsertDriveFileOutlined
                                      className={tableStyles.text_primary_alt}
                                      fontSize='small'
                                    />
                                    <span className='ms-2 text-muted'>{child.realName}</span>
                                  </div>
                                ))}
                              </div>
                            </Accordion.Collapse>
                          </Accordion>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={`${item.id}_${index}`}
                        className={`row g-0 ${tableStyles.hoverable_row}`}
                      >
                        <div
                          className='col-12 d-flex align-items-center px-4 py-2 pointer'
                          onClick={() => setSelectedDocument(true)}
                        >
                          <InsertDriveFileOutlined
                            className={tableStyles.text_primary_alt}
                            fontSize='small'
                          />
                          <span className='ms-2 text-muted'>{item.realName}</span>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>

            {item?.status !== 'unfiled' && (
              <button
                className='btn btn-primary fw-bold align-self-end mt-5'
                onClick={onDownloadFile}
              >
                Descargar documentos
              </button>
            )}
          </div>
        )}
      </>
    );
  };

  const render = () => {
    return (
      <>
        {(detailsLoader || companyParamsLoader || documentsLoader || downloadFilingLoader) &&
          loader}

        <div className='container-fluid px-5'>
          <div className='d-flex align-items-center my-5'>
            <img onClick={() => history.goBack()} src={IconBack} className='pointer me-3' alt='' />
            <h1 className='m-0'>Detalle documentos</h1>
          </div>

          <div style={{ padding: '0 35px' }}>
            <h4 className={`${tableStyles.text_primary_alt} fw-bold`}>Información del cliente</h4>

            {item && (
              <div className='card p-3 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
                <div className='row small'>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Empresa cliente</div>
                    <div className='text-muted'>{item.clientName}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>NIT</div>
                    <div className='text-muted'>{item.clientNit}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Modalidad</div>
                    <div className='text-muted'>
                      {item.modality === 'group' ? 'Grupo' : 'Individual'}
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Contrato</div>
                    <div className='text-muted'>{item.contract}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>No. Factura</div>
                    <div className='text-muted'>{item.sequence}</div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='text-primary'>Valor Factura</div>
                    <div className='text-muted'>{convertMoneyFormat(item.totalAmount)}</div>
                  </div>
                </div>
              </div>
            )}

            <div className='row pt-3'>
              <div className='col-lg-6'>
                <div className='shadow p-3 text-center' style={{ borderRadius: 10 }}>
                  {!selectedDocument ? (
                    <div className='text-muted'>Seleccione un documento para previsualizar.</div>
                  ) : (
                    <img
                      className='img-fluid'
                      width={500}
                      src='https://www.smartsheet.com/sites/default/files/2022-02/IC-Simple-Fillable-and-Printable-Receipt-Template_WORD.png'
                      alt=''
                    />
                  )}
                </div>
              </div>

              {renderDocumentsTable()}
            </div>
          </div>
        </div>
      </>
    );
  };

  return render();
}
