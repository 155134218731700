import { useEffect, useState } from 'react';
import threeDots from '../../assets/img/icons/threeDots.svg';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import { convertDateToLatinFormat } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';

import { getPayrollParams, updatePayrrolParam } from '../../actions/accountingActions';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
export const AccountingSetup = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'reFac',
  );
  const idAccount = store.loginReducer.user_data.id;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const payrollParamList = store.accountingReducer?.payrollParamList;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
    description: '',
    active: '',
    type: '',
    status: '',
  });

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/administracion/inicio');
    }
    dispatch(getPayrollParams(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-3 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.description}
        </td>
        <td className='col-md-1 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.param}
        </td>
        <td className='col-md-1 text-end'>{elem.value}</td>
        <td className='col-md-2 text-center'>{convertDateToLatinFormat(elem.validFrom)}</td>
        <td className='col-md-2 text-center'>{convertDateToLatinFormat(elem.validUntil)}</td>
        <td className='col-md-1 text-center'>{elem.type === 'value' ? 'Valor' : 'Porcentaje'}</td>

        <td className='col-md-1 text-center cursorPointer'>
          <div className={elem.active === 1 ? tableStyles.greenState : tableStyles.redState}>
            {elem.active ? 'Habilitado' : 'Inhabilitado'}
          </div>
        </td>
        <td className='col-md-1 text-center cursorPointer'>
          <div>
            <CustomPopupExtend
              triggerSrc={threeDots}
              noHover
              showEdit={true}
              editClickEvent={() =>
                history.push({
                  pathname: '/administracion/configuracionContable-nueva',
                  state: {
                    paramInfo: elem,
                    isEditing: true,
                  },
                })
              }
              isEnabled={elem.active === 1}
              showEnable={myPermission?.delete && true}
              enableClickEvent={() =>
                customSwaltAlert({
                  icon: 'warning',
                  title: '¿Está seguro?',
                  text:
                    elem.active === 1
                      ? `Se va deshabilitar el parámetro de nómina: ${elem.description}`
                      : `Se va habilitar el parámetro de nómina: ${elem.description}`,
                  showCancelButton: true,
                }).then(result => {
                  if (result.isConfirmed) {
                    const send = async () => {
                      setIsLoading(true);
                      try {
                        let result = await updatePayrrolParam(
                          {
                            eaccount: idEnterprise,
                            createdBy: idAccount,
                            // eaccount: 31,
                            active: elem.active === 1 ? 0 : 1,
                            id: elem.id,
                          },
                          token,
                        );
                        if (result.success) {
                          setIsLoading(false);
                          setTrigger(trigger + 1);

                          customSwaltAlert({
                            icon: 'success',
                            title: 'Actualizado exitosamente',
                            text: `Se ha ${elem.active === 1 ? 'deshabilitado' : 'habilitado'} el parámetro de nómina: ${elem.description}`,
                            showCancelButton: false,
                          });
                        } else {
                          setIsLoading(false);
                          return customSwaltAlert({
                            icon: 'warning',
                            title: 'Intenta de nuevo',
                            text: result.message,
                            confirmButtonText: 'Aceptar',
                            showCancelButton: false,
                          });
                        }
                      } catch (error) {
                        setIsLoading(false);
                        customSwaltAlert({
                          icon: 'warning',
                          title: 'Intenta de nuevo',
                          text: result.message,
                          confirmButtonText: 'Aceptar',
                          showCancelButton: false,
                        });
                      }
                    };
                    send();
                  }
                })
              }
            />
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (payrollParamList !== undefined) {
      const elem2 = payrollParamList;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Descripción
    </th>,
    <th key={2} className='text-start'>
      &nbsp; Parámetro
    </th>,
    <th key={3} className='text-end'>
      Valor
    </th>,
    <th key={4} className='text-center'>
      Válido desde
    </th>,
    <th key={5} className='text-center'>
      Válido hasta
    </th>,
    <th key={6} className='text-center'>
      Tipo
    </th>,
    <th key={7} className='text-center'>
      Estado
    </th>,
    <th key={8}>&nbsp;</th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      {store?.accountingReducer?.ppLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Parámetros de nómina</h1>

          <div className='row'>
            <div className='col-2'>
              <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
            </div>
            <div className='col-2'>
              <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
            </div>
          </div>

          <Row className='align-items-center'>
            <Col className='d-block' xs={2}>
              <form onSubmit={e => handleSearch(e)}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={e => {
                    setFilters({ ...filters, active: e.value });
                    setTrigger(state => state + 1);
                  }}
                  options={[
                    { value: '', label: 'Seleccionar...' },
                    { value: 1, label: 'Habilitado' },
                    { value: 0, label: 'Inhabilitado' },
                  ]}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                ></Select>
              </form>
            </Col>

            <Col className='d-block' xs={2}>
              <form onSubmit={e => handleSearch(e)}>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={e => {
                    setFilters({ ...filters, type: e.value });
                    setTrigger(state => state + 1);
                  }}
                  options={[
                    { value: '', label: 'Seleccionar...' },
                    { value: 'percentage', label: 'Porcentaje' },
                    { value: 'value', label: 'Valor' },
                  ]}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNewDark}
                ></Select>{' '}
              </form>
            </Col>

            <Col className='d-block' xs={5}>
              <div className=''>
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={tableStyles.searchUsersAlt}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    width={'30px'}
                    style={{ marginBottom: '2px' }}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>

            <div
              className={`col-3 align-items-center justify-content-end d-flex`}
              onClick={() => history.push('/administracion/configuracionContable-nueva')}
            >
              <div className='groupBounding2 buttonCreatCount groupAddButton align-items-center '>
                <label className='darkGray fw-bold px-2'>Nuevo parámetro</label>

                <button className='addCheckButton mx-2' style={{ marginBottom: '1.5px' }} />
              </div>
            </div>
          </Row>

          <GenericTableNew fontFamilyTable={'fontFamilyTable'} headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.accountingReducer?.payrollParamList ? filters.page : ''}
              {' de '}
              {Math.ceil(store.accountingReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.accountingReducer.rowTotal / filters.perpage)
                : ''}{' '}
              ({store.accountingReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.accountingReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
