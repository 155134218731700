import { GET_CAUSATION_TYPE } from "../actions/actionTypes";

const initialState = {
    resultsCausatioType: []
};
export const causationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAUSATION_TYPE:
            return {
                ...state,
                resultsCausatioType: action.payload
            };
        default:
            return state;
    }
};