import styled from "styled-components";


export const Line = styled.hr`
    color: ${({ color, theme }) => color ? color : theme.colors.darkGray};
    background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.colors.darkGray};
    font-size: ${({ fontSize, theme }) => fontSize ? fontSize : theme.sizes.title.sx};
    padding: ${({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '1rem 0px'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'bolder'};
    height: ${({ height }) => height ? height : '1px'};
    width: ${({ width }) => width ? width : '296px'};
`