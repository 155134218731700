import { Drawer } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { useReactToPrint } from 'react-to-print';
//
import { useGetMethod } from '../../Hooks';
import { downloadExcel, formatToRcSelect, loader, formatteDocument } from '../../helpers';
//
import ChevronRight from '../../assets/img/icons/arrowDerechaCalendario.svg';
import ChevronLeft from '../../assets/img/icons/arrowIzquierdaCalendario.svg';
import DownloadPdf from '../../assets/img/icons/download.svg';
import ExcelIcon from '../../assets/img/icons/excelClearGray.svg';
import Download from '../../assets/img/icons/ordDownload.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
//
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
//
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import styles from '../../components/Layouts/tableStyle.module.scss';
import './PatientSafety.scss';

export default function PatientSafety() {
  const store = useSelector(state => state);
  const pdfRef = useRef();

  const [filters, setFilters] = useState({
    search: '',
    typeService: null,
    serviceType: '',
    page: 1,
    perpage: 10,
    date: '',
    status: '',
  });
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    service: null,
    currentSitutation: 0,
  });
  const [trigger, setTrigger] = useState(0);

  const {
    trigger: getPatientSafety,
    results: patientSafety,
    load: patientSafetyLoader,
  } = useGetMethod();
  const { trigger: getStatus, results: status } = useGetMethod();
  const { trigger: getTypeService, results: typeService } = useGetMethod();
  const { trigger: getExcel, load: excelLoading } = useGetMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getPatientSafety({
        url: '/medical/clinicHistory/patientSafetyPanel/',
        objFilters: {
          ...filters,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.loginReducer.Authorization,
    trigger,
    getPatientSafety,
    store.loginReducer.currentAccount.id,
  ]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getStatus({
        url: '/medical/clinicHistory/getAppStatusPatientSafetyPanel/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });

      await getTypeService({
        url: '/medical/generals/service-types/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    store.loginReducer.Authorization,
    getStatus,
    trigger,
    getTypeService,
    store.loginReducer.currentAccount.id,
  ]);

  const serviceTypeOptions = formatToRcSelect(typeService?.results, 'id', 'name', '', '', '', true);


  const getExcelFunction = () => {
    getExcel({
      url: '/medical/clinicHistory/unexpectedSituationsExcel/',
      objFilters: {
        search: filters.search,
        date: filters.date,
        status: filters.status,
        typeService: filters.serviceType,
        eaccount: store.loginReducer.currentAccount.id,

      },
      token: store.loginReducer.Authorization,
      doAfterSuccess: (res) => {
        downloadExcel(res.results.base64, res.results.filename, true);
      }
    });
  };

  const services = {
    headers: [
      { title: 'Paciente', className: 'col-2 text-start ps-3' },
      { title: 'Tipo de servicio', className: 'col-2 text-start ps-3' },
      { title: 'Servicio', className: 'col-2 text-start' },
      { title: 'Médico', className: 'col-2 text-start' },
      { title: 'Fecha cita', className: 'col-1 text-center' },
      { title: 'Situaciones inesperadas', className: 'col-2 text-center' },
      { title: 'Estado evento', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  };

  const onOpenDetailDrawer = service => {
    setDrawerConfig({ isOpen: true, service, currentSitutation: 0 });
  };

  const onPrintSituations = useReactToPrint({
    content: () => pdfRef.current,
  });

  const onDownload = async item => {
    setDrawerConfig({
      ...drawerConfig,
      service: item,
    });
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(onPrintSituations());
      }, 200);
    });
  };

  const renderRow = (item, index) => {
    return (
      <tr key={item.doctorDocNum + index} className={'hover-table-row'}>
        <td className='text-start ps-3'>
          <span className='d-block'>{item.patient.patienteFullName}</span>
          <span style={{ color: '#999' }}>
            {item.patient.docType} {formatteDocument(item.patient.patientDocument).format}
          </span>
        </td>
        <td className='text-start ps-3'>{item.serviceType ?? '-'}</td>
        <td className='text-start'>{item.service}</td>
        <td className='text-start'>{item.doctorFullName}</td>
        <td className='text-center'>{item?.appDate}</td>
        <td className='text-center'>{item.totalUnexpectedSituacions}</td>
        <td className='text-center'>
          <div
            className={`${styles.app_container_state} mx-auto`}
            style={{ background: item.app.bgColor }}
          >
            <span style={{ color: item.app.fontColor }}>{item.app.appStatus}</span>
          </div>
        </td>
        <td className='text-center'>
          <CustomPopupExtend
            showDetails={true}
            position='right'
            triggerSrc={ThreeDots}
            showDetailsEvent={() => onOpenDetailDrawer(item)}
            extraButtons={[
              {
                text: 'Descargar PDF',
                icon: DownloadPdf,
                event: () => onDownload(item),
              },
            ]}
          />
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${styles.shade}`}
        headers={services.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: patientSafety?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: e => {
            setFilters({ ...filters, page: e });
            setTrigger(trigger + 1);
          },
          showTextDetails: true,
        }}
      >
        {patientSafety?.results &&
          patientSafety.results.body.map((service, index) => renderRow(service, index))}
      </OrdTable>
    );
  };

  const renderHeader = () => {
    const selectDefaultValue = { label: 'Seleccionar...', value: '' };
    const formattedStatus =
      status?.results?.map(item => ({
        label: item.status,
        value: item.value,
      })) || [];

    return (
      <div className='row align-items-end'>
        <div className='col-5'>
          <input
            placeholder='Escribe aquí para buscar'
            className={`${ordComponentStyles.backgroundImage} ${styles.appInputDate} pl-2 w-100`}
            onChange={({ target }) => {
              setFilters(prev => ({ ...prev, search: target.value }));
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
                setFilters(state => ({ ...state, page: 1 }));
              }
            }}
          />
        </div>
        <Form.Group className='col-2'>
          <Form.Label className={styles.app_title_input}>
            <div className='ms-1'>
              <span className={styles.app_title_new_item}>Tipo</span>
            </div>
          </Form.Label>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            value={serviceTypeOptions.find(x => x.value === filters.serviceType)}
            options={serviceTypeOptions}
            onChange={option => {
              setFilters(prev => ({ ...prev, serviceType: option.value, page: 1 }));
              setTrigger(trigger + 1);
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={styles.app_title_input}>
            <div className='ms-1'>
              <span className={styles.app_title_new_item}>Estado evento</span>
            </div>
          </Form.Label>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            options={[selectDefaultValue, ...formattedStatus]}
            onChange={option => {
              setFilters(prev => ({ ...prev, page: 1, status: option.value }));
              setTrigger(trigger + 1);
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={styles.app_title_input}>
            <div className='ms-1'>
              <span className={styles.app_title_new_item}>Fecha cita</span>
            </div>
          </Form.Label>
          <Form.Control
            type='date'
            className='ord-roundInput'
            onChange={({ target }) =>
              setFilters(prev => ({ ...prev, page: 1, date: target.value }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </Form.Group>
        <div onClick={() => getExcelFunction()}
          className="pointer col-1">
          {/* <a
          className='col-1 text-center mb-1'
          href={excelResponse?.results?.base64}
          download='lista_seguridad_del_paciente'
        > */}
          <img src={ExcelIcon} alt='' />
          {/* </a>  */}
        </div>
      </div>
    );
  };

  const renderPdf = () => {
    return (
      <table border={0} ref={pdfRef} className='document w-100'>
        <thead>
          <tr>
            <td>
              <div>
                <div className='d-flex align-items-center justify-content-between'>
                  <img src={patientSafety.results?.header?.logo} alt='cofca_logo' width={150} />
                  <h4 className='text-secondary fw-bold m-0'>
                    {patientSafety.results?.header?.name_of_the_institution}
                  </h4>
                  <h5 className={`${styles.ordAquaMarineText} m-0 fw-bold`}>
                    Historia clínica digital
                  </h5>
                </div>
                <div className='row p-1 align-items-center bg-light text-muted mt-3'>
                  <div className='col-6'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <div className='fw-bold'>Paciente:</div>
                        <div className='fw-bold'>Documento:</div>
                      </div>
                      <div className='col-8'>
                        <div>{drawerConfig.service.patient?.patienteFullName}</div>
                        <div>{formatteDocument(drawerConfig.service.patient?.patientDocument).format}</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='row align-items-center'>
                      <div className='col-6'>
                        <div className='fw-bold nowrap'>Fecha de nacimiento:</div>
                        <div className='fw-bold'>Género:</div>
                      </div>
                      <div className='col-6'>
                        <div>{drawerConfig.service.patient?.patientBirthdate}</div>
                        <div>{drawerConfig.service.patient?.gender}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: 5 }}></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className='p-0 m-0'>
              <div className='d-flex justify-content-between text-secondary w-100 bg-primary-lighter px-2 py-1'>
                <div className={`${styles.darkerBlueText} fw-bold`}>
                  {drawerConfig.service.appDate}
                </div>
                <div className={`${styles.darkerBlueText} fw-bold`}>
                  Evento N. {drawerConfig.service.eventNumber} - {drawerConfig.service.scheduleName}
                </div>
                <div className={`${styles.darkerBlueText} fw-bold`}>
                  Edad: {drawerConfig.service.patient?.patient_age}
                </div>
              </div>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-3'>
                  <h6 className={`${styles.darkerBlueText} mt-4 fw-bold flex-grow-1`}>
                    Situaciones inesperadas {drawerConfig.service.service}
                  </h6>
                </div>

                {drawerConfig.service.UnexpectedSituacions?.map((item, index) => (
                  <div key={index} className='mb-4'>
                    <div className={`${styles.ordAquaMarineText} fw-bold`}>
                      {index + 1}. {item.standarClassification} - {item.detailedClassification}{' '}
                      {item.susReason && item.appStatus === "suspended" ? '(Causó suspensión de atención)' : ''}
                      {item.susReason && item.appStatus === "canceled" ? '(Causó cancelación de atención)' : ''}

                    </div>
                    <div style={{ whiteSpace: 'pre-line' }}>{item.sitDescription}</div>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className='align-bottom text-end text-muted position-fixed bottom-0 w-100'>
                <div className='d-inline text-end mx-2'>
                  <span className='fw-bold'>Teléfono: </span>
                  {patientSafety.results?.footer?.appointment_phone}
                </div>
                <div className='d-inline text-end mx-2'>
                  <span className='fw-bold'>Email: </span>
                  {patientSafety.results?.footer?.appointment_email}
                </div>
                <div className='d-inline text-end mx-2'>
                  <span className='fw-bold'>Dirección: </span>
                  {patientSafety.results?.footer?.address}
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    );
  };

  const renderDrawer = () => {
    if (drawerConfig.service) {
      const currentSitutation =
        drawerConfig.service.UnexpectedSituacions[drawerConfig.currentSitutation];

      return (
        <Drawer
          anchor={'right'}
          open={drawerConfig.isOpen}
          onClose={() => {
            setDrawerConfig({
              service: null,
              isOpen: false,
              currentSitutation: 0,
            });
          }}
          PaperProps={{ style: { width: 600 } }}
          hideBackdrop
        >
          <Box height='100%' display='flex' flexDirection='column' padding={3}>
            <div
              className={`${styles.tlndrawer__close} me-4 ms-0`}
              onClick={() => {
                setDrawerConfig({
                  service: null,
                  isOpen: false,
                });
              }}
            ></div>
            <div className='d-flex align-items-center mt-4'>
              <h3 className={`${styles.ordAquaMarineText} m-0 fw-bold flex-grow-1`}>
                {drawerConfig.service.service}
              </h3>

              <div
                className='py-1 px-3 me-3 text-center'
                style={{
                  whiteSpace: 'nowrap',
                  background: drawerConfig.service.app.bgColor,
                  borderRadius: '50px',
                }}
              >
                <span style={{ color: drawerConfig.service.app.fontColor }}>
                  {drawerConfig.service.app.appStatus}
                </span>
              </div>
              <img
                src={Download}
                width={20}
                alt=''
                className='pointer flex-shrink-0'
                onClick={onPrintSituations}
              />
            </div>

            <p className={`${styles.darkerBlueText} m-0 my-1`}>
              <strong>Paciente: </strong>
              {drawerConfig.service.patient.patienteFullName}
            </p>
            <p className={`${styles.darkerBlueText} m-0 my-1`}>
              <strong>Médico: </strong>
              {drawerConfig.service.doctorFullName}
            </p>
            <p className={`${styles.darkerBlueText} m-0 my-1`}>
              <strong>Fecha: </strong>
              {drawerConfig.service.appDate}
            </p>
            {currentSitutation ? (
              <>
                <p className={`${styles.darkerBlueText} m-0 my-1`}>
                  <strong>Situación inesperada: </strong>
                  {currentSitutation.standarClassification} -{' '}
                  {currentSitutation.detailedClassification}
                </p>
                <p className={`${styles.darkerBlueText} m-0 my-1`}>
                  <strong>Causó suspensión: </strong>
                  {currentSitutation.susReason && currentSitutation.appStatus === "suspended" ? 'Sí' : 'No'}
                </p>
                <p className={`${styles.darkerBlueText} m-0 my-1`}>
                  <strong>Causó cancelación: </strong>
                  {currentSitutation.susReason && currentSitutation.appStatus === "canceled" ? 'Sí' : 'No'}
                </p>
                <p className={`${styles.darkerBlueText} m-0 mt-4 fw-bold`}>
                  Descripción de la situación:{' '}
                </p>
                <p
                  className={`flex-grow-1 mt-4`}
                  style={{ whiteSpace: 'pre-line', overflowY: 'auto' }}
                >
                  {currentSitutation.sitDescription}
                </p>
              </>
            ) : (
              ''
            )}

            <div className='align-self-center'>
              <img
                className='text-primary pointer'
                src={ChevronLeft}
                onClick={() =>
                  setDrawerConfig(prev => ({
                    ...prev,
                    currentSitutation:
                      prev.currentSitutation >= 1
                        ? prev.currentSitutation - 1
                        : prev.currentSitutation,
                  }))
                }
                alt=''
              />
              <span className='text-secondary mx-3'>
                Situación {drawerConfig.currentSitutation + 1}
              </span>
              <img
                className='text-primary pointer'
                src={ChevronRight}
                onClick={() =>
                  setDrawerConfig(prev => ({
                    ...prev,
                    currentSitutation:
                      prev.currentSitutation < drawerConfig.service.UnexpectedSituacions.length - 1
                        ? prev.currentSitutation + 1
                        : prev.currentSitutation,
                  }))
                }
                alt=''
              />
            </div>
          </Box>
        </Drawer>
      );
    } else return '';
  };

  const render = () => {
    return (
      <>
        {patientSafetyLoader && loader}
        {excelLoading && loader}
        <div className={`${styles.tlnContainer} position-relative`}>
          <OrdGenericTemplate className='' titleSize={12} title={'Seguridad del paciente'}>
            <>
              <div>
                {renderHeader()}
                {renderTable()}
              </div>
              {drawerConfig.service ? (
                <div className='w-100 position-absolute d-none'>{renderPdf()}</div>
              ) : (
                ''
              )}
            </>
          </OrdGenericTemplate>
        </div>
        {renderDrawer()}
      </>
    );
  };

  return render();
}
