//All actions for user component
import { GET_ALL_MU, GET_MU, LOADING, SET_INDEX_MU } from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getMu = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_MU,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/measurements/params/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: GET_MU,
        payload: results,
        loading: false,
      });
      dispatch({
        type: SET_INDEX_MU,
        payload: row_total,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_MU,
        payload: [],
        loading: false,
      });
    });
};

export const getAllMu = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/measurements/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { data } = res;
      dispatch({
        type: GET_ALL_MU,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_ALL_MU,
        payload: [],
      });
    });
};

export const activationDeactivation =
  (id, status, filters, description) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let active = status ? 'deshabilitado' : 'habilitado';
    if (status === true) {
      status = 0;
    } else if (status === false) {
      status = 1;
    }

    let data = {
      id: id,
      active: status,
    };
    if (id != null && status != null) {
      customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se ${status ? 'habilitará' : 'deshabilitará'} la unidad de medida: ${description}`,
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          dispatch({
            type: LOADING,
            loading: true,
          });
          fetch(`${URL_GATEWAY}${API_VERSION}/measurements/activate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',

              Authorization: token,
            },
          })
            .then(response => response.json())
            .then(res => {
              dispatch({
                type: LOADING,
                loading: false,
              });
              if (res.status || !res.message) {
                customSwaltAlert({
                  icon: 'success',
                  title: 'Actualizada exitosamente',
                  text: `Se ha ${active} la unidad de medida: ${description}`,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });

                dispatch(getMu(filters));
              } else {
                dispatch({
                  type: LOADING,
                  loading: false,
                });
              }
            })
            .catch(() => {
              dispatch({
                type: LOADING,
                loading: false,
              });

              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'No se pudo cambiar el estado',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            });
        }
      });
    } else {
      dispatch({
        type: LOADING,
        loading: false,
      });

      customSwaltAlert({
        icon: 'error',
        title: 'Error al ingresar',
        text: 'Debe ingresar correo y contraseña para acceder al sistema.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

export const updateMu = (obj, filters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const { description, prefix } = obj;
  if (description !== '' && prefix !== '') {
    var data = obj;
    dispatch({
      type: LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/measurements/${obj.id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'success',
          title: 'Actualizada exitosamente',
          text: `Se ha actualizado la unidad de medida: ${obj.description}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        dispatch(getMu(filters));
      })
      .catch(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al intentar conectarse, por favor intente de nuevo.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      });
  } else {
    dispatch({
      type: LOADING,
      loading: false,
    });

    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar los datos obligatorios para actualizar el registro.',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};

export const createMu = (obj, filters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const { description, prefix } = obj;
  if (description !== '' && prefix !== '') {
    dispatch({
      type: LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/measurements/`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.status === 'success') {
          dispatch({
            type: LOADING,
            loading: false,
          });

          customSwaltAlert({
            icon: 'success',
            title: 'Creada exitosamente',
            text: `Se ha creado la unidad de medida: ${description}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });

          dispatch(getMu(filters));
        } else {
          dispatch({
            type: LOADING,
            loading: false,
          });

          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      });
  } else {
    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar los datos obligatorios para crear la unidad de medida.',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};
