import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bankMoveStyle from './bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { get_niifs } from '../../actions/billPaymentActions';
import { transfer_register } from '../../actions/bankNoteActions';
import Loader from 'react-loader-spinner';
import { customSwaltAlert } from '../../helpers';
import { Title } from '../../shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function TransferFunds() {
  const history = useHistory();
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    id_account: counter.loginReducer.currentAccount.id,
    eaccount: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    og_niif_account: 0,
    ds_niif_account: 0,
    date: '',
    details: '',
    amount: 0,
  });

  useEffect(() => {
    dispatch(
      get_niifs({
        lite: 1,
        eaccount: counter.loginReducer.currentAccount.id,
        check_info_bank: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    setFilters({
      id_account: counter.loginReducer.currentAccount.id,
      eaccount: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
      og_niif_account: 0,
      ds_niif_account: 0,
      date: '',
      details: '',
      amount: 0,
    });
  };

  let optionNiifAccounts = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.billPaymentReducer.niif)) {
    counter.billPaymentReducer.niif.forEach(item => {
      optionNiifAccounts.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        complete_account: item.complete_account,
        description: item.description,
        num_count: item.num_count,
        key: item.id,
      });
    });
  }

  const checkMandatoryInputs = type => {
    if (type === 'AND') {
      return (
        !!filters.og_niif_account &&
        !!filters.ds_niif_account &&
        !!filters.date &&
        !!filters.details &&
        !!filters.amount
      );
    }

    if (type === 'OR') {
      return (
        !!filters.og_niif_account ||
        !!filters.ds_niif_account ||
        !!filters.date ||
        !!filters.details ||
        !!filters.amount
      );
    }
  };

  const postTransfer = () => {
    let error = '';

    if (filters.og_niif_account === filters.ds_niif_account) {
      error = 'La transferencia no puede ser entre la misma cuenta';
    }

    if (!!filters.og_niif_account === false) {
      error = 'La cuenta de origen es obligatoria';
    }
    if (!!filters.ds_niif_account === false) {
      error = 'La cuenta destino es obligatoria';
    }
    if (!!filters.date === false) {
      error = 'La fecha es obligatoria';
    }
    if (!!filters.details === false) {
      error = 'La descripción es obligatoria';
    }
    if (!!filters.amount === false) {
      error = 'La cantidad de transferencia es obligatoria';
    }

    if (error) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
      });
      return null;
    }

    dispatch(transfer_register(filters, () => resetFilter()));
  };

  return (
    <>
      {!!counter.bankNoteReducer.transfer_register_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall} style={{ marginTop: '30px' }}>
              <Title
                title='Traslado de fondos entre cuentas'
                className={'mb-4'}
                onClickIcon={() => {
                  history.push('/tesoreria/inicio');
                }}
              />
            </div>
          </div>
          <div
            className={`pt-3 pb-3`}
            style={{
              backgroundColor: '#F2F7FC',
              borderRadius: '10px',
              paddingLeft: '24px',
            }}
          >
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%' }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  className='register-inputs'
                  style={{ color: '#01A0F6', fontWeight: 'bold' }}
                  name='requisition_date'
                  type='date'
                  value={filters.date}
                  onChange={e => setFilters({ ...filters, date: e.target.value })}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Cuenta bancaria de origen</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionNiifAccounts.filter(
                    option => option.value === filters.og_niif_account,
                  )}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      og_niif_account: e.value,
                    })
                  }
                  options={optionNiifAccounts}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Cuenta bancaria de destino</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionNiifAccounts.filter(
                    option => option.value === filters.ds_niif_account,
                  )}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      ds_niif_account: e.value,
                    })
                  }
                  options={optionNiifAccounts}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </Col>
            </Row>

            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%' }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Descripción</p>
                <textarea
                  style={{ height: '3rem', maxHeight: 'initial', padding: '5px' }}
                  placeholder='Escribir...'
                  rows='5'
                  cols='40'
                  className='register-inputs'
                  name='requisition_date'
                  value={filters.details}
                  onChange={e => setFilters({ ...filters, details: e.target.value })}
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 3 --------------------------------- */}
            <Row className='d-flex ' style={{ width: '100%' }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Efectivo</p>
                <NumberFormat
                  placeholder='Escribir...'
                  allowNegative={false}
                  id='efectivo'
                  className={`register-inputs`}
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  value={filters.amount}
                  onValueChange={values => {
                    const { floatValue } = values;
                    setFilters({ ...filters, amount: floatValue });
                  }}
                />
              </Col>
            </Row>
          </div>
          {/*--------------------------------- ROW 4 --------------------------------- */}
          <Row className='d-flex'>
            <Col xs={6} style={{ paddingLeft: '29px' }}></Col>
            <Col
              xs={6}
              className='d-flex'
              style={{ justifyContent: 'flex-end', paddingRight: '2.5rem' }}
            >
              <button
                className={`${tableStyles.btnSecondary} mt-4`}
                onClick={() => resetFilter()}
                disabled={!checkMandatoryInputs('OR')}
              >
                Cancelar
              </button>
              &nbsp;
              <button
                className={`${tableStyles.btnPrimaryAlt} mt-4`}
                onClick={() => postTransfer()}
                disabled={!checkMandatoryInputs('AND')}
              >
                Guardar
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default TransferFunds;
