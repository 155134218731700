import { customSwaltAlertAsistencial } from "./customSwaltAlertAsistencial";
import { isEmptyOrUndefined } from "./helpers";

export const onValidFielTypeFile = ({ id }) => {
   
    let archivo = document.getElementById(id).value;
    
    let extension = archivo.substring(archivo.lastIndexOf('.'), archivo.length);

    if (isEmptyOrUndefined(id)) {
        customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'Ingresa un id valido',
            showCancelButton: false
        })
        return false
    }
    
    if (document.getElementById(id).getAttribute('accept').split(',').indexOf(extension) < 0) {
        document.getElementById(id).value = ''
        customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Archivo inválido',
            text: 'No se permite la extensión: ' + extension,
            showCancelButton: false
        })
        return false
    }

    return true
}