import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export const TextGrayOnBrackets = ({ title, textBracket, active, className, classNameTitle }) => {

    const textSecondary = `{{${textBracket}}}`
    const color = '#CBCBD0'
    const colorActive = '#1e1e1e'
    const color2 = '#545454'

    return (
        <div className={`${className || ''} ${tableStyles.f13}`} style={active ? { color: '#6e6f7c' } : { color }}>
            <div className={classNameTitle || ''} style={{ fontSize: '0.8rem', height: '17px' }}>{title}</div>
            <div style={active ? { height: '20px', width: '100%' } : {}} >{active ? '' : textSecondary}</div>
            <hr style={{ color: active ? colorActive : color2 }} className="m-0" />
        </div>
    )

}


