//Login's reducer

import {
  COMPANYTYPE
  } from "../actions/actionTypes";
  
  const initialState = {
    data:[]
  };
  export const companyTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case COMPANYTYPE:
        return {
          data: action.payload
        };
          default:
        return state;
      
      
    }
  };
  
