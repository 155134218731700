/* eslint-disable react-hooks/exhaustive-deps */
// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState, useEffect } from 'react';
import GenericTableNew from '../Layouts/GenericTableNew';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import paginationStyles from '../Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import LupaClear from '../../assets/img/icons/lupaClear.svg';
import Excel from '../../assets/img/icons/excel.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg'

//-------------- scss styles 😄   --------------
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';

//-------------- actions 😄   --------------
import { useHistory } from 'react-router-dom';

import {
  getVaucherHistory,
  getDetailOneVaucher,
  getJournalType,
  listThirdParties,
} from '../../actions/accountingConsultsActions';

import { getNiifAccounts } from '../../actions/pucActions';

import { getListCompanies } from '../../actions/billingActions';

import { getProviders } from '../../actions/providersActions';
import { genericDownloadExcel } from '../../helpers/helpers';
import { Title } from '../../shared';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import { usePostMethod } from '../../Hooks';
import { customSwaltAlert } from '../../helpers';
import { SupportVisualizer } from '../AreaManagement/SupportVisualizer';

function VoucherHistory() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [modalAlertcontrol, setModalAlertcontrol] = useState({
    show: false,
    journal: '',
  });
  const history = useHistory();
  const [triggerModal, setTriggerModal] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const { trigger: postDocExcel, load: docExcelLoader } = usePostMethod();
  const { trigger: deleteJournalVoucher, load: loadJournal } = usePostMethod();


  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    dateFrom: '',
    dateUntil: '',
    amountFrom: '',
    amountUntil: '',
    search: '',
    third: '',
    niifAccount: '',
    type: '',
    typeThird: '',
  });

  const [info, setInfo] = useState([]);

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'voucherHis',
    );

  //-------------- first actions 😄   --------------

  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
  }, []);

  useEffect(() => {
    dispatch(getVaucherHistory(filters));
  }, [trigger, filters.page]);

  useEffect(() => {
    dispatch(getNiifAccounts({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getJournalType({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  useEffect(() => {
    if (modalAlertcontrol.journal) {
      dispatch(
        getDetailOneVaucher(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: modalAlertcontrol.journal,
          },
          setInfo,
        ),
      );
    }
  }, [triggerModal]);

  let optionsJournalType = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.accountingConsultsReducer.listJournalType)) {
    storage.accountingConsultsReducer.listJournalType.forEach(item => {
      optionsJournalType.push({
        value: item.id,
        label: item.name,
        key: item.id + 'Journaltype',
      });
    });
  }

  let optionsNiif = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.pucReducer.niifAccounts)) {
    storage.pucReducer.niifAccounts.forEach(item => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }

  let optionsTypeThirds = [
    { key: 'default', value: '', label: 'Seleccionar...' },
    { key: '1', value: 1, label: 'Cliente empresa' },
    { key: '1', value: 2, label: 'Proveedor' },
    { key: '1', value: 3, label: 'Terceros' },
  ];

  let optionsThirds = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (filters.typeThird === 1) {
    if (Array.isArray(storage.billingReducer.companies)) {
      storage.billingReducer.companies.forEach(item => {
        optionsThirds.push({
          value: item.Nit,
          label: item.copyName,
          key: item.id + 'companies',
        });
      });
    }
  }
  if (filters.typeThird === 2) {
    if (Array.isArray(storage.providerReducer.providers)) {
      storage.providerReducer.providers.forEach(item => {
        optionsThirds.push({
          value: item.nit,
          label: item.name,
          key: item.id + 'providers',
        });
      });
    }
  }
  if (filters.typeThird === 3) {
    if (Array.isArray(storage.accountingConsultsReducer.thirdParties)) {
      storage.accountingConsultsReducer.thirdParties.forEach(item => {
        optionsThirds.push({
          value: item.nit,
          label: item.description,
          key: item.id + 'thirds',
        });
      });
    }
  }

  const downloadExcelDoc = (id, type) => {
    const body =
      info?.jrnlTypeName === 'Planilla de nómina'
        ? { eaccount: storage.loginReducer.currentAccount.id, id, type }
        : info?.jrnlTypeName === 'Provisión de nómina'
          ? { eaccount: storage.loginReducer.currentAccount.id, id }
          : { eaccount: storage.loginReducer.currentAccount.id, jv_id: id };

    const url =
      info?.jrnlTypeName === 'Planilla de nómina'
        ? '/payroll/download_payroll_accounting/'
        : info?.jrnlTypeName === 'Provisión de nómina'
          ? '/payroll/download_provision_accounting/'
          : '/payroll/download_payment_accounting';
    postDocExcel({
      url: url,
      method: 'POST',
      body: body,
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };
  // --------------- Table 1 💥  ----------------//

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const handleDeleteJournalVoucher = async (journal_id, eaccount) => {
    const result = await customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: 'Se va a eliminar el comprobante contable',
    })

    if (!result.isConfirmed) {
      return
    }

    deleteJournalVoucher({
      token: storage.loginReducer.Authorization,
      method: 'DELETE',
      url: '/accounting/journalVoucher/',
      body: {
        eaccount,
        id: journal_id
      },
      doAfterSuccess: () =>
        setTrigger(trigger + 1)
    })
  }

  const header = [
    <th key={`reqTH2`} className='text-start px-2'>
      No. Comprobante
    </th>,
    <th key={`reqTH3`} className='text-start'>
      Tipo de comprobante
    </th>,
    <th key={`reqTH4`} className='text-start'>
      Concepto
    </th>,
    <th key={`reqTH5`} className='text-center'>
      Fecha de creación
    </th>,
    <th key={`reqTH6`} className='text-'>
      &nbsp;
    </th>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingConsultsReducer.listVaucherHistory)) {
      storage.accountingConsultsReducer.listVaucherHistory.forEach((item, index) => {
        tempList.push(
          <tr key={index} className='hover-table-row'>
            <td className='text-uppercase text-start px-2'>{item.docNbr}</td>
            <td className='text-start'>{item.jrnlTypeName}</td>
            <td className='text-start'>{item.jvDetail}</td>
            <td className='text-center'>
              {item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-center'>
              <CustomPopupExtend
                triggerSrc={ThreeDots}
                showDetails
                showDetailsEvent={() => {
                  setModalAlertcontrol({
                    ...modalAlertcontrol,
                    show: true,
                    journal: item.jrnlId,
                  });
                  setTriggerModal(triggerModal + 1);
                }}
                showDelete
                deleteEvent={ () => handleDeleteJournalVoucher(item.jrnlId, item.eaccount) }
              >
              </CustomPopupExtend>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  // --------------- Table 2 💥  ----------------//

  const header2 = [
    <div key={1} className={`col-2`}>
      No. Cuenta
    </div>,
    <div key={2} className={`col-3 text-start`}>
      Tercero
    </div>,
    <div key={3} className={`col-3`}>
      Centro de costo
    </div>,
    <div key={4} className={`col-2 text-end`}>
      Debe
    </div>,
    <div key={5} className={`col-2 text-end px-2`}>
      Haber
    </div>,
  ];

  const renderList2 = () => {
    let tempList = [];
    if (Array.isArray(info.lines)) {
      info.lines.forEach((item, index) => {
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-center col-2'>{item.niifAccountNbr}</div>
            <div className='text-start col-3'>{item.thirdName ? item.thirdName : '-'}</div>
            <div className='text-center col-3'>
              {item.costCenterName ? item.costCenterName : '-'}
            </div>
            <div className='text-end col-2'>
              {item.debit ? '$' + numberWithCommas(Number(item.debit)) : '$0'}
            </div>
            <div className='text-end col-2 px-2'>
              {item.credit ? '$' + numberWithCommas(Number(item.credit)) : '$0'}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  // --------------- Functions 💥  ----------------//

  const [showOpt, setShowOpt] = useState({
    show: false,
    arrow: false,
  });

  const ShowOptions = () => {
    if (showOpt?.show && showOpt?.arrow) {
      setShowOpt({
        ...showOpt,
        show: false,
        arrow: false,
      });
    } else {
      setShowOpt({
        ...showOpt,
        show: true,
        arrow: true,
      });
      setFilters({
        ...filters,
        client: '',
        contract: '',
        status: '',
        sequence: '',
        date_from: '',
        date_until: '',
        resolution: '',
        range_from: '',
        range_until: '',
      });
    }
  };
  const applyFilters = () => {
    setFilters({ ...filters, page: 1, perpage: 10 });
    setTrigger(trigger + 1);
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {(storage.accountingConsultsReducer.voucherH_loading || docExcelLoader || loadJournal) && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Historial de comprobantes'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        <div className={IndividualStyles.container2}>
          <div className={`${IndividualStyles.Aling} ${IndividualStyles.inputMargin} `}>
            <div className={`${IndividualStyles.Boton} `} onClick={() => ShowOptions()}>
              <img
                src={LupaClear}
                alt='añadir'
                className={`mx-3  ${IndividualStyles.pointerNew} ${IndividualStyles.sizeNew2} ${tableStyles.cursorPointer}`}
              />
              <span className={`${IndividualStyles.proArrowWrapper}`}>
                {showOpt.arrow ? (
                  <span className={`${IndividualStyles.proArrow2}`}> </span>
                ) : (
                  <span className={`${IndividualStyles.proArrow}`}> </span>
                )}
              </span>
            </div>
          </div>
        </div>
        {showOpt.show ? (
          <div>
            <Row className='d-flex'>
              <Col xs={3}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Tipo de comprobante</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsJournalType}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        type: e.value,
                        page: 1,
                        perpage: 10,
                      });
                      setTrigger(trigger + 1);
                    }}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                  />
                </form>
              </Col>
              <Col xs={3}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Tipo de tercero</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsTypeThirds}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        typeThird: e.value,
                        page: 1,
                        perpage: 10,
                      });
                      if (e.value === 1) {
                        dispatch(
                          getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }),
                        );
                      }
                      if (e.value === 2) {
                        dispatch(
                          getProviders({
                            id_account: storage.loginReducer.currentAccount.id,
                            slim: 1,
                          }),
                        );
                      }
                      if (e.value === 3) {
                        dispatch(
                          listThirdParties({ eaccount: storage.loginReducer.currentAccount.id }),
                        );
                      }
                      setTrigger(trigger + 1);
                    }}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                  />
                </form>
              </Col>
              <Col xs={3}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Tercero</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsThirds}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        third: e.value,
                        page: 1,
                        perpage: 10,
                      });
                      setTrigger(trigger + 1);
                    }}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                  />
                </form>
              </Col>
              <Col xs={3}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Cuenta</label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsNiif}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        niifAccount: e.value,
                        page: 1,
                        perpage: 10,
                      });
                      setTrigger(trigger + 1);
                    }}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                  />
                </form>
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={2}>
                <label className={tableStyles.crudModalLabel}>Fecha desde</label>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={filters.dateFrom}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      dateFrom: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      applyFilters();
                    }
                  }}
                ></input>
              </Col>

              <Col xs={2}>
                <label className={tableStyles.crudModalLabel}>Hasta</label>
                <input
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={filters.dateUntil}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      dateUntil: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      applyFilters();
                    }
                  }}
                ></input>
              </Col>

              <Col xs={2}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Valor desde</label>
                  <NumberFormat
                    allowNegative={false}
                    style={{ padding: 0, color: '#58595B' }}
                    className={IndividualStyles.registerInputs}
                    name='valueNote'
                    thousandsGroupStyle='thousand'
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'$'}
                    value={filters.amountFrom}
                    onValueChange={values => {
                      const { value } = values;
                      setFilters({
                        ...filters,
                        amountFrom: value,
                      });
                    }}
                    placeholder='Escribir...'
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        applyFilters();
                      }
                    }}
                  ></NumberFormat>
                </form>
              </Col>

              <Col xs={2}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={tableStyles.crudModalLabel}>Valor hasta</label>
                  <NumberFormat
                    allowNegative={false}
                    style={{ padding: 0, color: '#58595B' }}
                    className={IndividualStyles.registerInputs}
                    name='valueNote'
                    thousandsGroupStyle='thousand'
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    prefix={'$'}
                    value={filters.amountUntil}
                    onValueChange={values => {
                      const { value } = values;
                      setFilters({
                        ...filters,
                        amountUntil: value,
                      });
                    }}
                    placeholder='Escribir...'
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        applyFilters();
                      }
                    }}
                  ></NumberFormat>
                </form>
              </Col>

              <Col xs={2} style={{ alignSelf: 'end' }}>
                <form onSubmit={e => handleSearch(e)}>
                  <label className={`${tableStyles.crudModalLabel}`}></label>
                  <input
                    className={tableStyles.SearchNew}
                    placeholder='Buscar...'
                    type='text'
                    onChange={e =>
                      setFilters({
                        ...filters,
                        search: e.target.value,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        applyFilters();
                      }
                    }}
                  ></input>
                </form>
              </Col>

              <div className=' col display-grid  mr-2' style={{ alignContent: 'end' }}>
                <label className=''>
                  {' '}
                  <img
                    className={`${tableStyles.cursorPointer}`}
                    src={Lupa}
                    onClick={() => applyFilters()}
                    alt='icon'
                    height='24px'
                  />
                </label>
              </div>
            </Row>
          </div>
        ) : (
          <span></span>
        )}

        <GenericTableNew headers={header} dark={true}>
          {renderList()}
        </GenericTableNew>

        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.accountingConsultsReducer.listVaucherHistory ? filters.page : ''}
              {' de '}
              {Math.ceil(
                storage.accountingConsultsReducer?.listVaucherHistory_total / filters.perpage,
              )
                ? Math.ceil(
                    storage.accountingConsultsReducer?.listVaucherHistory_total / filters.perpage,
                  )
                : ''}{' '}
              ({storage.accountingConsultsReducer?.listVaucherHistory_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={Number(storage.accountingConsultsReducer?.listVaucherHistory_total)}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>

        <ModalNew
          size='940'
          title={'Detalle de comprobante'}
          show={modalAlertcontrol.show}
          btnYesName={'Aceptar'}
          btnYesDisabled={false}
          btnYesEvent={() => {
            setModalAlertcontrol({
              ...modalAlertcontrol,
              show: false,
            });
          }}
          onHide={() => {
            setModalAlertcontrol({
              ...modalAlertcontrol,
              show: false,
            });
          }}
          btnNoEvent={() => setShowPdf(true)}
          btnNoName={'Vista previa'}
        >
          {/* /* ---------------------------------- ROW 1 --------------------------------- */}

          <Row className='d-flex '>
            <Col xs={3}>
              <p className={`${tableStyles.crudModalLabel} `}>Tipo de comprobante</p>
              <input
                className={IndividualStyles.registerInputsClearBlue}
                name='position'
                type='text'
                style={{
                  color: '#58595B',
                  whiteSspace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                value={info.jrnlTypeName}
                disabled={!modalAlertcontrol.edit}
              />
            </Col>
            <Col xs={3}>
              <p className={`${tableStyles.crudModalLabel} `}>Fecha</p>
              <input
                className={IndividualStyles.registerInputsClearBlue}
                type='text'
                name='position'
                value={info.date ? info.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : ''}
                disabled={!modalAlertcontrol.edit}
              />
            </Col>
            <Col xs={5}>
              <p className={`${tableStyles.crudModalLabel} `}>Concepto</p>
              <input
                className={IndividualStyles.registerInputsClearBlue}
                type='text'
                name='position'
                style={{ color: '#58595B' }}
                value={info.jvDetail}
                disabled={!modalAlertcontrol.edit}
              />
            </Col>
            <Col xs={1} className='d-flex align-items-end mt-2'>
              {info?.jrnlTypeName === 'Planilla de nómina' && (
                <CustomPopupExtend
                  noHover
                  position='bottom'
                  triggerSrc={Excel}
                  extraButtons={[
                    {
                      text: 'Informe general',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        downloadExcelDoc(info.jrnlId, 'general');
                      },
                    },
                    {
                      text: 'Informe detallado',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        downloadExcelDoc(info.jrnlId, 'detail');
                      },
                    },
                  ]}
                />
              )}

              {(info?.jrnlTypeName === 'Pago de nómina' ||
                info?.jrnlTypeName === 'Provisión de nómina') && (
                <img
                  alt='Excel'
                  className='pointer'
                  width={20}
                  src={Excel}
                  onClick={() => downloadExcelDoc(info.jrnlId)}
                />
              )}
            </Col>
          </Row>

          <GenericTableScroll
            headers={header2}
            dark={false}
            body={renderList2()}
            typeHead={'3'}
          ></GenericTableScroll>
        </ModalNew>
      </div>
      <ModalNew
        title='Comprobante diario'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <SupportVisualizer file={info?.journal_file} />
      </ModalNew>
    </>
  );
}
export default VoucherHistory;
