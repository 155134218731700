import { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Agregar from '../../../assets/img/icons/add-check.svg';
import Search from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';

import { useGetMethod } from '../../../Hooks/useFetch';
import { getConCenter } from '../../../actions/consumptionCenterActions';
import {
  changePositionStatus,
  getAllJobPositions,
  list_status,
} from '../../../actions/positionsActions';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import {
  convertDateToLatinFormat,
  formatToRcSelect,
  isEmptyOrUndefined,
  message,
} from '../../../helpers/helpers';

import GenericTableNew from '../../Layouts/GenericTableNew';
import ModalNew from '../../Layouts/ModalNew';
import CustomPopupExtend from '../../Popup/customPopUpExtends';
import SelectComponent from '../../SelectComponent/SelectComponent';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import paginationStyles from '../../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';
import { useHasPermissionByTagModule } from "../../../Hooks";

export const PositionTable = props => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'position',
  );

  const [arrEmployees, setArrEmployees] = useState([]);
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [modalAlertControl, setModalAlertControl] = useState({
    showFirst: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
  });
  let i = 0;
  const arrJobsPosition = store.positionReducer?.jobsPositionsList;

  const dispatch = useDispatch();
  const {
    results: areaOptionsResults,
    load: areaOptionsLoader,
    trigger: getAreaOptions,
  } = useGetMethod();
  useEffect(() => {
    getAreaOptions({
      url: '/consumption_center/',
      objFilters: {
        eaccount: idEnterprise,
        active: 1,
        lite: 1,
        handleResponse: 1,
      },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formattedAreas = formatToRcSelect(areaOptionsResults?.results, 'id', 'description');

  const history = useHistory();
  const [trigger, setTrigger] = useState(0);

  const handleDetails = elem => {
    history.push({
      pathname: '/nomina/nuevoCargo',
      state: { detail: true, data: elem },
    });
  };

  //filtros para el functional_get
  const [filters, setFilters] = useState({
    area_id: undefined,
    search: '',
    status: undefined,
    slim: 0,
    page: 1,
    perpage: 10,
    entity_account: store.loginReducer.currentAccount.id,
    onlyCurrent: 'current',
    active: undefined,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getAllJobPositions(filters, () => setLoading(false)));
    dispatch(
      getConCenter({
        page: 1,
        perpage: 999,
        account: store.loginReducer.currentAccount.id,
      }),
    );
    dispatch(list_status({ prefix: 'contracts' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  const collaboratorsListelem = () => {
    let elemMap;
    if (arrEmployees !== undefined) {
      const elem2 = arrEmployees;
      elemMap = elem2.map(elem => {
        return collaboratorsRenderElement(elem);
      });
    }
    return elemMap;
  };
  const collaboratorsRenderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-center'>{elem.doc}</td>
        <td className='col-md-4 text-start'>{elem.name}</td>
        <td className='col-md-4 text-center'>{convertDateToLatinFormat(elem.date)}</td>
      </tr>
    );
  };

  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: '10px' }} key={i} className='px-2'>
      No.
    </th>,
    <th className='text-start' key={i + 1}>
      Nombre completo
    </th>,
    <th style={{ borderTopRightRadius: '10px' }} key={i + 1} className='text-center'>
      Fecha de inicio
    </th>,
  ];

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-3 text-start px-2'>
          {isEmptyOrUndefined(elem.area_name) ? '-' : elem.area_name}
        </td>
        <td className='col-md-3 text-start'>
          {isEmptyOrUndefined(elem.job_title) ? '-' : elem.job_title}
        </td>
        <td
          className='col-md-2 hoverPointer text-center'
          onClick={elem.employees_count > 0 ? () => handleEmployees(elem) : null}
        >
          <u className={elem.employees_count > 0 ? 'text-primary' : 'text-warning'}>
            <b>{elem.employees_count}</b>
          </u>
        </td>
        <td className='col-md-1 text-center cursorPointer'>
          <OverlayTrigger
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip placement='top-end' id='button-tooltip' {...props}>
                {elem.active === 1 ? 'Habilitado' : 'Inhabilitado'}
              </Tooltip>
            }
          >
            <div
              className='rounded-pill p-1'
              style={{
                backgroundColor: elem?.active === 1 ? '#E6F6EE' : '#FEE9EA',
              }}
            >
              <b style={{ color: elem?.active === 1 ? '#00A551' : '#EC1C24' }}>
                {elem?.active === 1 ? 'Habilitado' : 'Deshabilitado'}
              </b>
            </div>
          </OverlayTrigger>
        </td>

        <td className='col-md-1 '>
          {!!myPermission?.edit || !!myPermission?.delete ? (
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              showDetails={!!myPermission?.read && true}
              textDetails='Ver Detalle'
              showDetailsEvent={() => handleDetails(elem)}
              showEnable={!!myPermission?.edit && true}
              isEnabled={!!myPermission?.edit && elem.active}
              enableClickEvent={() =>
                customSwaltAlert({
                  icon: 'warning',
                  title: elem.active ? '¿Esta seguro?' : '¿Esta seguro?',
                  text: elem.active
                    ? `Se deshabilitará el cargo: ${elem.job_title}`
                    : `Se habilitará el cargo: ${elem.job_title}`,
                  confirmButtonText: elem.active ? 'Si, continuar' : 'Si, continuar',
                  cancelButtonText: 'Cancelar',
                  showCancelButton: true,
                }).then(result => {
                  if (result.isConfirmed) {
                    const send = async () => {
                      try {
                        let result = await changePositionStatus(
                          elem.id,
                          elem.active === 0 ? 1 : 0,
                          token,
                        );
                        setTrigger(trigger + 1);
                        if (result.success) {
                          message(
                            'success',
                            'Actualizado exitosamente',
                            `Se ha actualizado el cargo: ${elem.job_title}`,
                            undefined,
                            true,
                          );
                        } else {
                          return message(
                            'error',
                            'Ha ocurrido un error',
                            result.message,
                            undefined,
                            true,
                          );
                        }
                      } catch (error) {
                        message('error', 'Error', error.message, undefined, true);
                      }
                    };
                    send();
                  }
                })
              }
            />
          ) : (
            ''
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (arrJobsPosition !== undefined) {
      const elem2 = arrJobsPosition;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={i + 1} className='text-start px-2'>
      Área
    </th>,
    <th key={i + 1} className='text-start'>
      Nombre del cargo
    </th>,
    <th key={i + 1} className='text-center'>
      Colaboradores
    </th>,
    <th key={i + 1} className='text-center'>
      Estado
    </th>,
    <th key={i + 1}>&nbsp;</th>,
  ];

  const handleEmployees = elem => {
    setArrEmployees(elem.employees);
    setModalAlertControl({
      ...modalAlertControl,
      showFirst: true,
    });
  };
  const handleClickNew = () => {
    history.push({
      pathname: '/nomina/nuevoCargo',
      state: { detail: false },
    });
  };

  const handleSearch = e => {
    setLoading(true);
    e?.preventDefault();
    setFilters({ ...filters, page: 1 });
    dispatch(getAllJobPositions(filters, () => setLoading(false)));
  };

  const handleChangeArea = area_id => {
    setLoading(true);
    dispatch(getAllJobPositions({ ...filters, ...area_id }, () => setLoading(false)));
  };

  const handleChangeStatus = status => {
    setLoading(true);
    dispatch(getAllJobPositions({ ...filters, ...status }, () => setLoading(false)));
  };

  const statusOptions = [
    { value: '', label: 'Seleccionar...' },
    { value: 0, label: 'Inhabilitado' },
    { value: 1, label: 'Habilitado' },
  ];
  return (
    <>
      {(loading || areaOptionsLoader) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {/* /* ------------------------------ First modal ------------------------------ */}
      <ModalNew
        size='600'
        title='Colaboradores'
        show={modalAlertControl.showFirst}
        hideCancelButton={true}
        onHide={() =>
          setModalAlertControl({
            ...modalAlertControl,
            showFirst: false,
          })
        }
      >
        <div className={tableStyles.tableScroll}>
          <GenericTableNew headers={renderHeadersFirst}>{collaboratorsListelem()}</GenericTableNew>
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Cargos</h1>

          {!!myPermission?.read && (
            <>
              <Row>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Área</p>
                  <SelectComponent
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    key={'area' + trigger}
                    onChange={e => {
                      setFilters({ ...filters, area_id: e.value, page: 1 });
                      handleChangeArea({ area_id: e.value });
                    }}
                    value={formattedAreas.find(x => x.value === filters.area_id)}
                    options={formattedAreas}
                  ></SelectComponent>
                </Col>
                <Col xs={2}>
                  <p className={tableStyles.crudModalLabel}>Estado</p>
                  <SelectComponent
                    value={statusOptions.find(x => x.value === filters.active)}
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    key={'state' + trigger}
                    onChange={e => {
                      setFilters({ ...filters, active: e.value, page: 1 });
                      handleChangeStatus({ active: e.value });
                    }}
                    options={statusOptions}
                  ></SelectComponent>{' '}
                </Col>
                <Col xs={4} className='d-flex align-self-end gap-1'>
                  <input
                    className={tableStyles.SearchNew}
                    value={filters.search}
                    placeholder='Buscar...'
                    type='text'
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch(e);
                      }
                    }}
                  ></input>
                  <img
                    className='hoverPointer'
                    alt='iconSearch'
                    style={{}}
                    src={Search}
                    height='24px'
                    onClick={e => handleSearch(e)}
                  />
                </Col>
                {myPermission?.create ? (
                  <Col xs={4} className='align-self-end'>
                    <div className='d-flex justify-content-end'>
                      <div onClick={() => handleClickNew()} className={tableStyles.createNomModule}>
                        <b className={`mr-2`}>Crear cargo</b>
                        <img src={Agregar} alt='User' />
                        <div></div>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
              <GenericTableNew className='mt-0 ' headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>
              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {filters.page}
                  {' de '}
                  {Math.ceil(store.positionReducer.row_positions_total / filters.perpage)
                    ? Math.ceil(store.positionReducer.row_positions_total / filters.perpage)
                    : '1'}{' '}
                  ({store.positionReducer.row_positions_total} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.positionReducer.row_positions_total}
                  pageRangeDisplayed={5}
                  onChange={e => {
                    setFilters({ ...filters, page: e });
                    if (e !== filters.page) {
                      setLoading(true);
                    }
                  }}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
