import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';

import yellowExclude from '../../../assets/img/icons/yellowExclude.svg';
import greenDownArrow from '../../../assets/img/icons/greenDownArrow.svg';
import redArrow from '../../../assets/img/icons/redArrow.svg';
import { onCapitalLetter } from '../../../helpers/helpers';

import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';

import '../../../OrderingModule/orderingStyles.css';

export const OrderingHomeContent = () => {
  let history = useHistory();
  const storage = useSelector(state => state);

  const accountingModules = storage.loginReducer.all_categories?.find(
    cat => cat?.name === 'Ordenamientos',
  );
  const { modules } = accountingModules;
  const contentDynamic = modules?.map(module => {
    const textJoin = onCapitalLetter({ text: module?.name });
    return {
      title: textJoin,
      content: module?.functionalities?.map(subModule => {
        let subModuleName = onCapitalLetter({ text: subModule?.name });
        let returnValue = <></>;
        let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
          x => x.functionality?.prefix === subModule?.prefix,
        )?.read;
        if (permissionValidation) {
          returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
        }
        return returnValue;
      }),
    };
  });
  return (
    <div>
      {/* /* ---------------------------------- ICONS --------------------------------- */}
      <Row>
        <Col xs={3} className='cursorPointer mb-3'>
          <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
            <div className={`d-flex text-start justify-content-between`}>
              <div>
                <p className='text-secondary'>Solicitudes pendientes</p>
                <p className={tableStyles.cardBoxBottomText}>0</p>
              </div>
              <img
                src={yellowExclude}
                alt={'yellow'}
                className={tableStyles.cardBoxBottomText}
              ></img>
            </div>
          </div>
        </Col>
        <Col xs={3} className='cursorPointer'>
          <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
            <div className={`d-flex text-start justify-content-between`}>
              <div>
                <p className='text-secondary'>Formulas sin MIPRES</p>
                <p className={tableStyles.cardBoxBottomText}>0</p>
              </div>
              <img
                src={greenDownArrow}
                alt={'green'}
                className={tableStyles.cardBoxBottomText}
              ></img>
            </div>
          </div>
        </Col>
        <Col xs={3} className='cursorPointer'>
          <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
            <div className={`d-flex text-start justify-content-between`}>
              <div>
                <p className='text-secondary'>Entregas realizadas</p>
                <p className={tableStyles.cardBoxBottomText}>0</p>
              </div>
              <img src={redArrow} alt={'red'} className={tableStyles.cardBoxBottomText}></img>
            </div>
          </div>
        </Col>
      </Row>
      {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
    </div>
  );
};
