import { Grid } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import threeDots from '../../../assets/img/icons/auctionDetailLupa2.svg';
import Search from '../../../assets/img/icons/lupa.svg';
import planilla from '../../../assets/img/icons/planilla.svg';

import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import GenericTableNew from '../../Layouts/GenericTableNew';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import SelectComponent from '../../SelectComponent/SelectComponent';
import { SocialSecurityScheduleDetail } from './SocialSecurityScheduleDetail';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'reactjs-popup/dist/index.css';
import tableStyles2 from '../../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';

export const SocialSecuritySchedule = () => {
  const store = useSelector(state => state);
  const history = useHistory();

  const idAccount = store.loginReducer.currentAccount.id;

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );

  const [data, setData] = useState({
    trigger: 0,
    idDetail: null,
    backPage: null,
    filters: {
      risk: '',
      employed: '',
      search: '',
      area_id: 0,
      page: 1,
      perpage: 10,
      entity_account: idAccount,
    },
    modal: {
      show: false,
      isEditing: false,
      dateStart: moment(new Date()).format('YYYY-MM-DD'),
      dateEnd: moment(new Date()).format('YYYY-MM-DD'),
      datePayment: moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { filters, trigger, modal, backPage } = data;

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className={`col-2 px-2 text-start`}>{elem?.nameEntity ? elem.nameEntity : '-'}</td>

        <td className='col-1 text-center'>{elem.risk ? elem.risk : '-'}</td>

        <td className='col-1 text-center'>{elem.code ? elem.code : '-'}</td>

        <td className='col-1 text-center'>{elem.affiliates ? elem.affiliates : '-'}</td>

        <td className='col-2 text-end px-2'>{elem.liquidatedValue ? elem.liquidatedValue : '-'}</td>

        <td className='col-1 text-end px-2'>{elem.valueMora ? elem.valueMora : '-'}</td>

        <td className='col-1 text-end px-2'>{elem.disabilities ? elem.disabilities : '-'}</td>

        <td className='col-2 text-end px-2'>{elem.valueToPay ? elem.valueToPay : '-'}</td>

        <td className='col-1 '>
          {!!myPermission.read && (
            <img
              src={threeDots}
              alt=''
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setData(state => ({ ...state, backPage: true, idDetail: elem.id }));
              }}
            />
          )}
        </td>
      </tr>
    );
  };

  const listPremiumSchedule = [
    {
      id: 1,
      nameEntity: 'Sanitas',
      risk: 'EPS',
      code: 'EPS005',
      affiliates: '135',
      liquidatedValue: '$25.000.000',
      valueMora: '$25.000',
      disabilities: '$500.000',
      valueToPay: '$24.525.000',
    },
    {
      id: 2,
      nameEntity: 'Sanitas',
      risk: 'EPS',
      code: 'EPS005',
      affiliates: '135',
      liquidatedValue: '$25.000.000',
      valueMora: '$25.000',
      disabilities: '$500.000',
      valueToPay: '$24.525.000',
    },
    {
      id: 3,
      nameEntity: 'Sanitas',
      risk: 'EPS',
      code: 'EPS005',
      affiliates: '135',
      liquidatedValue: '$25.000.000',
      valueMora: '$25.000',
      disabilities: '$500.000',
      valueToPay: '$24.525.000',
    },
    {
      id: 4,
      nameEntity: 'Sanitas',
      risk: 'EPS',
      code: 'EPS005',
      affiliates: '135',
      liquidatedValue: '$25.000.000',
      valueMora: '$25.000',
      disabilities: '$500.000',
      valueToPay: '$24.525.000',
    },
    {
      id: 5,
      nameEntity: 'Sanitas',
      risk: 'EPS',
      code: 'EPS005',
      affiliates: '135',
      liquidatedValue: '$25.000.000',
      valueMora: '$25.000',
      disabilities: '$500.000',
      valueToPay: '$24.525.000',
    },
  ];

  const renderHeaders = [
    <th key={1} className='text-start col-2 px-2'>
      Nombre entidad
    </th>,

    <th key={2} className='text-center col-1'>
      Riesgo
    </th>,

    <th key={3} className='text-center col-1'>
      Código
    </th>,

    <th key={4} className='text-center col-1'>
      Afiliados
    </th>,

    <th key={5} className='text-end px-2 col-2'>
      Valor liquidado
    </th>,

    <th key={6} className='text-end px-2 col-1'>
      Mora
    </th>,

    <th key={7} className='text-end px-2 col-1'>
      Incapacidades
    </th>,

    <th key={8} className='text-end px-2 col-2'>
      Valor a pagar
    </th>,

    <th key={9} className='text-start col-1'>
      &nbsp;
    </th>,
  ];

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        show: false,
        isEditing: false,
        dateStart: moment(new Date()).format('YYYY-MM-DD'),
        dateEnd: moment(new Date()).format('YYYY-MM-DD'),
        datePayment: moment(new Date()).format('YYYY-MM-DD'),
      },
    }));
  };

  const validationRequest = () => {
    closeModal();
    setData(state => ({ ...state, generatePremiumSchedule: true }));
  };

  return (
    <>
      {!backPage ? (
        <>
          <OrdModal
            title={modal.isEditing ? '' : 'Seguridad social'}
            show={modal.show}
            btnYesName={modal.isEditing ? 'Subir' : 'Subir'}
            btnNoName={'Cancelar'}
            size={550}
            onHide={() => closeModal()}
            btnYesEvent={myPermission.create ? () => validationRequest() : null}
            btnNoEvent={() => closeModal()}
            sizeBody={`${tableStyles2.modalHeight}`}
            titleClassName={tableStyles2.darkBlueText}
            btnYesStyle={tableStyles2.btnYesStyle}
            btnCancelStyle={tableStyles2.btnCancelStyle}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <textarea
                  placeholder={'Escribir...'}
                  rows='25'
                  cols='55'
                  style={{ height: '4rem' }}
                  value={modal.denial}
                  className={`text-secondary ord-roundInput w-100 focusBlueVisible`}
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      modal: { ...state.modal, denial: e.target.value },
                    }))
                  }
                ></textarea>
              </Grid>
            </Grid>
          </OrdModal>
          <div className='w-80 mx-auto'>
            <h1 className={`${tableStyles.title}`}>Planilla seguridad social</h1>

            {!!myPermission.read && (
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <label className={tableStyles.crudModalLabel}>Riesgo</label>

                  <SelectComponent
                    styles={customSelectNewDark}
                    placeholder={'Seleccionar...'}
                    key={'cargo' + trigger}
                    onChange={e =>
                      setData(state => ({ ...state, filters: { ...state.filters, risk: e.value } }))
                    }
                    options={[{ label: 'Seleccionar...', value: '' }]}
                  ></SelectComponent>
                </Grid>

                <Grid item xs={4} className='d-flex align-items-end'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch}
                    style={{ flex: 1, color: `#73a6dc` }}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                    value={filters.search}
                    onChange={e =>
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, search: e.target.value },
                      }))
                    }
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setData(state => ({ ...state, trigger: state.trigger + 1 }));
                      }
                    }}
                  />

                  <img
                    src={Search}
                    style={{ cursor: 'pointer' }}
                    alt='Search icon'
                    height='24px'
                    onClick={() => {
                      setData(state => ({ ...state, trigger: state.trigger + 1 }));
                    }}
                  />
                </Grid>

                <Grid item xs={5} className='d-flex align-items-end justify-content-end'>
                  {!!myPermission.create && (
                    <div className={`d-flex gap-1 ${tableStyles.createNomModule} CursorPointer `}>
                      <label className='fw-bold mr-2 CursorPointer'>Cargar planilla</label>
                      <span>
                        <img
                          src={planilla}
                          alt='planilla'
                          className='pointer'
                          height='16px'
                          onClick={() => {
                            setData(state => ({ ...state, modal: { ...state.modal, show: true } }));
                          }}
                        />
                      </span>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            <Grid container>
              {!!myPermission.read && (
                <>
                  <Grid item xs={12}>
                    <GenericTableNew headers={renderHeaders} dark={true}>
                      {listPremiumSchedule.map(el => {
                        return renderElement(el);
                      })}
                    </GenericTableNew>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={paginationStyles.wrapper}>
                      <p className={`${paginationStyles.paginationText} text-secondary`}>
                        Pag. {store.partnersReducer.personal ? filters.page : ''}
                        {' de '}
                        {Math.ceil(store.partnersReducer.totalPersonal / filters.perpage)
                          ? Math.ceil(store.partnersReducer.totalPersonal / filters.perpage)
                          : ''}{' '}
                        ({store.partnersReducer.totalPersonal} encontrados)
                      </p>
                      <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={store.partnersReducer.totalPersonal}
                        pageRangeDisplayed={5}
                        onChange={e =>
                          setData(state => ({ ...state, filters: { ...filters, page: e } }))
                        }
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      ) : (
        <SocialSecurityScheduleDetail backPage={{ setData }} />
      )}
    </>
  );
};
