import { useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import '../Layouts/customTabs.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';

function ModalBudget(props) {
  const counter = useSelector(state => state);

  const [filters, setFilter] = useState({
    search: '',
    page: 1,
    perpage: 10,
    account: counter.loginReducer.currentAccount.id,
  });

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
  };
  const renderHeaders = [
    <th key={`ReqModalBudget1`} className='px-2'>
      Descripción
    </th>,
    <th key={`ReqModalBudget2`} className='px-2 text-center'>
      Cantidad
    </th>,
    <th key={`ReqModalBudget3`} className='px-2 text-end'>
      Valor unitario
    </th>,
  ];
  const totalArticles = () => {
    let ammountTotal = 0;
    let inversion = 0;
    const articles = props.reqState.articulos;
    if (Array.isArray(articles)) {
      articles.forEach(x => {
        if (!isNaN(x.ammount)) {
          ammountTotal = x.ammount + ammountTotal;
          if (!isNaN(x.cost_price)) {
            inversion = x.ammount * x.cost_price + inversion;
          }
        }
      });
    }
    return {
      ammountTotal: ammountTotal,
      inversion: inversion,
    };
  };

  const renderList = () => {
    let table = [];
    const articles = props.reqState.articulos;
    if (Array.isArray(articles)) {
      table = articles.map(x => {
        return (
          <tr key={'renderList' + x.num_req} className='hover-table-row'>
            <td className={`col-md-6 text-start px-2`}>{x.description}</td>
            <td className={`col-md-3`}>{x.ammount}</td>
            <td className={`col-md-3 text-end px-2`}>
              ${' '}
              {isNaN(x.cost_price)
                ? '-'
                : String(x.cost_price)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
            </td>
          </tr>
        );
      });
    }

    return table;
  };
  const buttonOK = () => {
    if (budgetOverRun()) {
      if (props.reqState.over_run_justification === '') {
        customSwaltAlert({
          icon: 'error',
          title: 'Falta justificación de sobrepaso',
          text: 'Debe digitar la justificación de sobrepaso del presupuesto asignado',
          showCancelButton: false,
        });
        return null;
      }
      customSwaltAlert({
        title: `¿Está seguro?`,
        text: 'Esta requisición sobrepasa el presupuesto asignado.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: `${reqStyles.btnPrimary}`,
          cancelButton: `${reqStyles.btnSecondary}`,
        },
        confirmButtonText: 'Si, Enviar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          props.sendrequisition();
          props.onHide();
        } else {
          props.onHide();
        }
      });
    } else {
      props.sendrequisition();
      props.onHide();
    }
  };

  const budgetOverRun = () => {
    let sw = false;
    if ((totalArticles().inversion / props.reqState.annual_budget) * 100 > 100) {
      sw = true;
    } else {
      sw = false;
    }
    return sw;
  };
  const paginateList = () => {
    let fullList = renderList();
    fullList = fullList.slice(filters.perpage * (filters.page - 1), filters.perpage * filters.page);
    return fullList;
  };

  return (
    <ModalNew
      title={props.title}
      show={props.show}
      size='lg'
      btnYesEvent={() => buttonOK()}
      onHide={props.onHide}
      btnYesName='Enviar'
    >
      <div className='d-flex flex-direction-row align-middle '>
        <div className='col-md-6' id='right'>
          <label className={`${reqStyles.subtitle}`}>Detalle de inversión</label>
          <div className='d-flex flex-direction-row align-middle '>
            <div className={`${reqStyles.inputMargin} ${reqStyles.ItemTiny}   `}>
              <p className={tableStyles.crudModalLabel}>No. de articulos</p>
              <input
                readOnly
                className={`register-inputs-readonly ${reqStyles.budgetModalText}`}
                name='ammountTotal'
                type='text'
                value={totalArticles().ammountTotal}
              />
            </div>
            <div className={`${reqStyles.inputMargin} ${reqStyles.ItemAlmostMedium} `}>
              <p className={tableStyles.crudModalLabel}>Inversión estimada</p>
              <input
                readOnly
                className={`register-inputs-readonly ${reqStyles.budgetModalText}`}
                name='inversion'
                type='text'
                value={
                  '$ ' +
                  totalArticles()
                    .inversion?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                }
              />
            </div>
          </div>
        </div>
        <div className='col-md-6' id='left'>
          <label className={`${reqStyles.subtitle}`}>Relación de presupuesto</label>
          <div className='d-flex flex-direction-row align-middle '>
            <div className={`${reqStyles.inputMargin} ${reqStyles.ItemAlmostMedium}  `}>
              <p className={tableStyles.crudModalLabel}>Presupuesto asignado</p>
              <input
                readOnly
                className={`register-inputs-readonly ${reqStyles.budgetModalText}`}
                name='annual_budget'
                type='text'
                value={`$  ${
                  props.reqState.annual_budget?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') ??
                  '-'
                }`}
              />
            </div>
            <div className={` ${reqStyles.ItemTiny} `}>
              <p className={tableStyles.crudModalLabel}>% utilizado</p>
              <input
                readOnly
                className={`register-inputs-readonly    ${
                  budgetOverRun()
                    ? `${reqStyles.orangeText} ${reqStyles.orangeText2}`
                    : reqStyles.budgetModalText
                } `}
                name='usedPercent'
                type='text'
                value={`${
                  props?.reqState?.annual_budget === undefined
                    ? '-'
                    : parseInt(
                        (totalArticles().inversion / Number(props?.reqState?.annual_budget ?? 0)) *
                          100,
                      )
                }%`}
              />
            </div>
          </div>
        </div>
      </div>
      <GenericTableNew headers={renderHeaders}>{paginateList()}</GenericTableNew>
      <div className={paginationStyles.wrapper}>
        <p className={paginationStyles.paginationText}>
          Pag. {props.reqState.articulos.length ? filters.page : ''}
          {' de '}
          {Math.ceil(props.reqState.articulos.length / filters.perpage)
            ? Math.ceil(props.reqState.articulos.length / filters.perpage)
            : ''}{' '}
          ({props.reqState.articulos.length} encontrados)
        </p>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={props.reqState.articulos.length}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
          itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
          itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
          itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
          itemClass={paginationStyles.itemClass}
        />
      </div>
      {budgetOverRun() ? (
        <div>
          <div className={` ${reqStyles.textToTheEnd} `} style={{ 'align-items': 'center' }}>
            <img src={AlertTriangle} alt='Alert' className={`${reqStyles.adjustIcon4}   `} />

            <label className={`${reqStyles.orangeText} ${reqStyles.orangeText3}`}>
              Esta requisición sobrepasa el presupuesto asignado
            </label>
          </div>
          <div className={``}>
            <p className={tableStyles.crudModalLabel}>Justificación de sobrepaso del presupuesto</p>

            <textarea
              className={`${reqStyles.inputsTextArea}`}
              name='justification'
              type='text'
              onChange={e =>
                props.setreqState({
                  ...props.reqState,
                  over_run_justification: e.target.value,
                })
              }
              value={props.reqState.over_run_justification}
            ></textarea>
          </div>
        </div>
      ) : (
        ''
      )}
    </ModalNew>
  );
}
export default ModalBudget;
