//costcenter' reducer

import {
  COLLECTION_GET,
  COLLECTION_GETONE,
  COLLECTION_EDIT,
  COLLECTION_POST,
  GET_ALL_COLLECTION,
  COLLECTION_LOAD,
} from "../actions/actionTypes";

const initialState = {
  cc: [{ id: 1 }],
  fu: [],
  cost_center: [],
  chosen_cc: {},
  total: 0,
  error: null,
  Authorization: "",
  loading: false,
};
export const collectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTION_LOAD:
      return {
        ...state,
        loading: action.loading,
      };
    case COLLECTION_GET:
      return {
        ...state,
        cc: action.results,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };

    case GET_ALL_COLLECTION:
      return {
        ...state,
        cost_center: action.payload,
        error: null,
        Authorization: action.Authorization,
      };
    case COLLECTION_GETONE:
      return {
        ...state,
        chosen_cc: action.payload,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };

    case COLLECTION_EDIT:
      return {
        ...state,
        error: null,
        Authorization: action.Authorization,
      };

    case COLLECTION_POST:
      return {
        ...state,
        error: null,
        Authorization: action.Authorization,
      };

    default:
      return state;
  }
};
