import { Drawer } from '@material-ui/core';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import arrow from '../../assets/img/icons/arrowTable.svg';
import closeIcon from '../../assets/img/icons/close.svg';
import countNotes from '../../assets/img/icons/countNotes.svg';
import iconMessage from '../../assets/img/icons/icon_message.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import successIcon from '../../assets/img/icons/riskIcon.gif';
import succesGray from '../../assets/img/icons/succesGray.svg';
import iconTrans from '../../assets/img/icons/trashNotes.svg';
import warningGray from '../../assets/img/icons/warningGray.svg';
import warningIcon from '../../assets/img/icons/warningIcon.gif';
import styles from '../../components/Layouts/tableStyle.module.scss';
import { Risk } from '../../components/Risk/Risk';
import {  getAge, loader, message, swalConfirm, formatteDocument } from '../../helpers';

export const ListPatient = () => {
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;

  const fullNameUser =
    store.loginReducer.user_data.first_name + ' ' + store.loginReducer.user_data.first_surname;
  const [trigger, setTrigger] = useState(1);
  const [comments, setComments] = useState({
    userId: idUser,
    entity: 'userClient',
    comment: '',
    id: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    filterPatient: '',
  });

  const [patient, setPatient] = useState({
    status: 'enabled',
    eaccount: idAccount,
  });

  const [modalRisk, setModalRisk] = useState({
    show: false,
    title: 'Importancias',
    listRisk: [],
    patient: undefined,
    type: 'administrative',
  });

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showImportance: false,
    header: [
      {
        title: 'Paciente',
        className: 'col-3 text-start px-3',
      },
      {
        title: 'No. Identificación',
        className: 'col-2 text-center px-3',
      },
      {
        title: 'Edad',
        className: 'col-1 text-center',
      },
      {
        title: 'EPS',
        className: 'col-3 px-2 text-start',
      },
      {
        title: 'Última atención',
        className: 'col-2 text-center',
      },
      {
        title: 'Notas',
        className: 'col-1 text-center',
      },
      {
        title: 'Alerta',
        className: 'col-2 text-center',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-2 text-end px-3',
      },
    ],
  });

  const { results: listPatient, load: loadListPatient, trigger: getListPatient } = useGetMethod();

  const {
    results: generalComments,
    load: loadGeneralComments,
    trigger: getGeneralComments,
  } = useGetMethod();

  const { trigger: getImportancies } = useGetMethod();

  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();
  const { load: loadDeleteComments, trigger: deleteComment } = usePostMethod();
  usePostMethod();

  useEffect(() => {
    getListPatient({
      url: '/medical/patient/listPatient/',
      objFilters: { ...filters, page: 1 },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getImportancies({
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
      token: token,
    });
  }, [getImportancies, token]);

  const getIdComment = id => {
    const filterComments = {
      id: id,
      entity: 'userClient',
    };
    getGeneralComments({
      url: '/medical/comments/',
      objFilters: filterComments,
      token: token,
    });
    setComments({ ...comments, id: id });
    setServices({ ...services, showDrawer: true });
  };

  const getIdPatient = id => {
    history.push(`/asistencial/DetallePacientes/${id}`);
  };

  const getIdNotes = id => {
    deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        getListPatient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        setServices({ ...services, showDrawer: false });
      },
    });
  };

  const onSubmitComment = e => {
    e.preventDefault();
    sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: comments,
      doAfterSuccess: () => {
        const filterComments = {
          id: comments.id,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        setComments({ ...comments, comment: '' });
        getListPatient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        message('success', 'Nota añadida');
      },
    });
  };

  const renderToolImportance = text => (
    <Tooltip>
      <span className={`${styles.darkerGrayText} ${styles.accordinElipsis} `}>{text}</span>
    </Tooltip>
  );

  const renderTooltipNotes = notes_num => (
    <Tooltip>
      <div className={`${styles.darkerGrayText}`}>
        ({notes_num}) {notes_num > 1 ? 'Notas' : 'Nota'}
      </div>
    </Tooltip>
  );

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`pointer ${styles.tableAdmissionOptional}`}>
            <td className='text-start px-3'>
              {item.first_name} {item?.other_names} {item.last_name} {item?.second_surname}
            </td>
            <td className='text-center px-2'>
              {formatteDocument(item.document).format || '-'}
            </td>
            <td className='text-center px-2'>{getAge(item.birth_date) || '-'}</td>
            <td className='text-start px-2'>{item.copyName || '-'}</td>
            <td className='text-center px-2'>{item.last_attention || '-'}</td>
            <td className='text-center px-2'>
              <div>
                {item?.notes_num ? (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    overlay={renderTooltipNotes(item?.notes_num)}
                  >
                    <div
                      className='pointer d-flex position-relative justify-content-center'
                      onClick={() => {
                        getIdComment(item.id);
                      }}
                    >
                      <img
                        alt='notes'
                        height={22}
                        src={countNotes}
                        width={22}
                        className={`${styles.filterOrdAquaMarine}`}
                      />
                      <span
                        className={`${styles.textCountNotes2} ${styles.filterWhite}`}
                        style={{ marginLeft: 'auto', marginTop: '-1px' }}
                      >
                        {item.notes_num || 0}
                      </span>
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div
                    className='pointer d-flex position-relative justify-content-center'
                    onClick={() => {
                      getIdComment(item.id);
                    }}
                  >
                    <img
                      alt='notes'
                      height={22}
                      src={countNotes}
                      width={22}
                      className={`${styles.filterGrayLight}`}
                    />
                  </div>
                )}
              </div>
            </td>
            <td className='text-center'>
              <div className='d-flex gap-2'>
                {item.risk.administrative.length === 0 ? (
                  <span
                    className='d-flex align-items-center justify-content-center'
                    style={{ width: '28px', height: '28px' }}
                  >
                    <img
                      alt={'icon'}
                      width={'18px'}
                      src={warningGray}
                      className={`cursorPointer ${styles.blendMode}`}
                      onClick={() => {
                        setModalRisk({
                          ...modalRisk,
                          type: 'administrative',
                          title: 'Importancias administrativas',
                          listRisk: item?.risk,
                          show: true,
                          patient: item,
                          defaultValueSelect: { label: item.alert, value: item.alertId },
                        });

                        setServices({ ...services, showImportance: true });
                        setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                      }}
                    />
                  </span>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    className={`${styles.app_container_tool_websc}`}
                    overlay={renderToolImportance(
                      item?.risk['administrative']?.map(risk => (
                        <ul
                          key={risk.id}
                          style={{ textAlign: 'start', margin: 0, padding: 0, listStyle: 'none' }}
                        >
                          {/* <li>{risk?.description}</li> */}
                          <li>
                            <b>-</b>&nbsp;{risk?.description}
                          </li>
                        </ul>
                      )),
                    )}
                  >
                    <span>
                      <img
                        alt={'icon'}
                        width={'28px'}
                        src={warningIcon}
                        className={`cursorPointer ${styles.blendMode}`}
                        onClick={() => {
                          // getIdPatientList(item.id);
                          setModalRisk({
                            ...modalRisk,
                            listRisk: item?.risk,
                            show: true,
                            type: 'administrative',
                            title: 'Importancias administrativas',
                            patient: item,
                            defaultValueSelect: { label: item.alert, value: item.alertId },
                          });

                          setServices({ ...services, showImportance: true });
                          setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                )}
                {item.risk.assistance.length === 0 ? (
                  <span
                    className='d-flex align-items-center justify-content-center'
                    style={{ width: '27px', height: '27px' }}
                  >
                    <img
                      alt={'icon'}
                      width={'18px'}
                      src={succesGray}
                      className={`cursorPointer ${styles.blendMode}`}
                      onClick={() => {
                        setModalRisk({
                          ...modalRisk,
                          type: 'assistance',
                          title: 'Importancias asistenciales',
                          listRisk: item?.risk,
                          show: true,
                          patient: item,
                          defaultValueSelect: { label: item.alert, value: item.alertId },
                        });

                        setServices({ ...services, showImportance: true });
                        setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                      }}
                    />
                  </span>
                ) : (
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 250 }}
                    className={`${styles.app_container_tool_websc}`}
                    overlay={renderToolImportance(
                      item?.risk['assistance']?.map(risk => (
                        <ul
                          key={risk.id}
                          style={{ textAlign: 'start', margin: 0, padding: 0, listStyle: 'none' }}
                        >
                          <li>
                            <b>-</b>&nbsp;{risk?.name || risk?.clinicalDiseaseName}
                          </li>
                        </ul>
                      )),
                    )}
                  >
                    <span>
                      <img
                        alt={'icon'}
                        width={'27px'}
                        src={successIcon}
                        className={`cursorPointer ${styles.blendMode}`}
                        onClick={() => {
                          // getIdPatientList(item.id);
                          setModalRisk({
                            ...modalRisk,
                            listRisk: item?.risk,
                            show: true,
                            title: 'Importancias asistenciales',
                            type: 'assistance',
                            patient: item,
                            defaultValueSelect: { label: item.alert, value: item.alertId },
                          });

                          setServices({ ...services, showImportance: true });
                          setPatient({ ...patient, cum_id: item?.alertId, id: item?.id });
                        }}
                      />
                    </span>
                  </OverlayTrigger>
                )}
              </div>
            </td>
            <td className='text-center px-3'>
              <img
                className={`cursorPointer ${styles.filterOrdDarkGrayText}`}
                alt={'arrow'}
                src={arrow}
                width={8}
                onClick={() => {
                  getIdPatient(item.id);
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '16px' }}>
              <div className={styles.app_container_tras_notes}>
                <span className={styles.app_name_drawer}>{item.userName}</span>
                <img
                  src={iconTrans}
                  width={15}
                  alt='tras-notes'
                  className='cursorPointer'
                  onClick={() => {
                    setServices({ ...services, showDrawer: true });
                    swalConfirm({
                      title: `<span class=${styles.ordClearBlueText} >¿Desea eliminar la nota?</span>`,
                      text: ` `,
                      confirmButtonText: 'Si, continuar',
                      doAfterConfirm: () => {
                        getIdNotes(item.id);
                        message('success', 'Nota eliminada');
                      },
                    });
                  }}
                />
              </div>
              <p className={styles.app_description_drawer}>{item.comment}</p>
              <div className={styles.app_container_date_drawer}>
                <span className={styles.app_date_drawer}>{item.date}</span>
              </div>
            </div>
            <div className={styles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  const handleCloseModalRisk = () => {
    setModalRisk({
      ...modalRisk,
      show: false,
      type: 'administrative',
      defaultValueSelect: undefined,
      listRisk: [],
      patient: undefined,
    });
  };

  return (
    <>
      {/* /* ------------------------------ load sections ----------------------------- */}
      {(loadListPatient || loadDeleteComments) && loader}

      <Risk
        {...modalRisk}
        key={1}
        idAccount={idAccount}
        listRisk={modalRisk?.listRisk}
        onHide={handleCloseModalRisk}
        btnNoEvent={handleCloseModalRisk}
        defaultValueSelect={modalRisk?.defaultValueSelect}
        onAdd={res => setModalRisk({ ...modalRisk, listRisk: { [modalRisk.type]: res } })}
        onRemove={res => setModalRisk({ ...modalRisk, listRisk: { [modalRisk.type]: res } })}
        doAfterSuccess={() => {
          setTrigger(state => state + 1);
          handleCloseModalRisk();
        }}
      />

      <Drawer
        anchor={'right'}
        open={services.showDrawer}
        style={{ zIndex: 900 }}
        onClose={() => setServices({ ...services, showDrawer: false })}
      >
        <div className={styles.app_drawer}>
          <div className={styles.app_container_close}>
            <img
              src={closeIcon}
              width={25}
              className={'cursorPointer'}
              alt='closeIcon'
              onClick={() => {
                setComments({ ...comments, id: '' });
                setServices({ ...services, showDrawer: false });
              }}
            />
          </div>
          <div className={styles.app_container_drawer}>
            <span className={styles.app_title_drawer}>Notas</span>
            {loadGeneralComments && loader}
            {generalComments.rowTotal === 0 ? (
              <div className={styles.app_container_not_notes}>
                <span className={styles.app_title_not_notes}>No hay notas aún</span>
              </div>
            ) : (
              <div className={styles.app_container_content_drawer}>
                {formatDataComments(generalComments.results)}
              </div>
            )}

            <div className={styles.app_container_form_drawer}>
              {loadCreateComments && loader}
              <Form.Group style={{ marginBottom: '10px' }}>
                <Form.Label className={styles.app_title_input}>
                  <span>{fullNameUser}</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  maxLength='170'
                  value={comments.comment}
                  onChange={e => setComments({ ...comments, comment: e.target.value })}
                  placeholder='Escribir...'
                />
              </Form.Group>
              <div className={styles.app_container_date_drawer}>
                <img
                  src={iconMessage}
                  className={'cursorPointer'}
                  alt='iconMessage'
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <div className='container-fluid'>
        <OrdGenericTemplate title='Listado de pacientes'>
          <div className={styles.app_mr_100}>
            <span className={`m-0 ${styles.ordDarkBlueText}`}>
              <h5 className={'text-start'}>Filtro última atención:</h5>
            </span>

            <Box display={'grid'} gridTemplateColumns={'170px 170px 1fr'} gap={2}>
              <Box>
                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                  <b className='text-start'>&nbsp;Desde</b>
                </span>
                <input
                  className={`w-100 ord-roundInput pointer ${styles.inputDate} ${styles.boxShadowNone}`}
                  type='date'
                  value={filters.startDate}
                  onChange={e => {
                    setFilters({ ...filters, startDate: e.target.value });
                  }}
                />
              </Box>

              <Box>
                <span className={`m-0 ${styles.ordDarkBlueText}`}>
                  <b className='text-start'>&nbsp;Hasta</b>
                </span>
                <input
                  disabled={!filters?.startDate}
                  className={`w-100 ord-roundInput pointer  ${styles.inputDate} ${styles.boxShadowNone}`}
                  type='date'
                  value={filters.endDate}
                  onChange={e => {
                    setFilters({ ...filters, endDate: e.target.value });
                  }}
                />
              </Box>

              <Box className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                <input
                  className={`ord-roundInput ${styles.appInputDate} w-100`}
                  type='text'
                  placeholder='Escribe aquí para buscar'
                  autoComplete='off'
                  onChange={e => {
                    setFilters({ ...filters, filterPatient: e.target.value });
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                />
                <img
                  src={ordBlueSearch}
                  className='pointer'
                  alt='buscar'
                  onClick={() => setTrigger(trigger + 1)}
                />
              </Box>
            </Box>

            <OrdTable
              shadow
              hasChildren={true}
              headers={services.header}
              paginate={{
                activePage: filters.page,
                totalPages: listPatient.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  const result = await getListPatient({
                    url: '/medical/patient/listPatient/',
                    objFilters: { ...filters, page: e },
                    token: token,
                  });
                  setFilters({ ...filters, page: e });
                  formatData(result?.results);
                },
                showTextDetails: true,
              }}
            >
              {formatData(listPatient.results)}
            </OrdTable>
          </div>
        </OrdGenericTemplate>
      </div>
    </>
  );
};
