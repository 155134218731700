import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bankMoveStyle from './bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { get_niifs, get_providers } from '../../actions/billPaymentActions';
import { banking_notes_insert } from '../../actions/bankNoteActions';
import { getAllConCenter } from '../../actions/consumptionCenterActions';
import { functional_get, functional_get_niif } from '../../actions/functionalActions';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { getTypesNotes } from '../../actions/billingActions';
import { listThirdParties } from '../../actions/accountingConsultsActions.js';
import { customSwaltAlert } from '../../helpers';
import { Title } from '../../shared';

function BankNotes() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(0);
  let history = useHistory();

  const [filters, setFilters] = useState({
    id_bank_nt_type: 0,
    desc_bank_nt_type: '',
    id_account: counter.loginReducer.currentAccount.id,
    eaccount: counter.loginReducer.currentAccount.id,
    created_by: counter.loginReducer.user_data.id,
    date: '',
    bknt_niif_account: 0,
    details: '',
    amount: 0,
  });

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'BnkNoteReg',
  );

  useEffect(() => {
    if (!myPermission.read || !myPermission.create) {
      history.push('/tesoreria/inicio');
    }

    dispatch(getAllConCenter({ eaccount: counter.loginReducer.currentAccount.id }));
    dispatch(functional_get(filters));
    dispatch(functional_get_niif(counter.loginReducer.currentAccount.id));
    dispatch(getTypesNotes({ category: 3, eaccount: counter.loginReducer.currentAccount.id }));
    dispatch(
      get_providers({
        lite: 1,
        id_account: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      get_niifs({
        lite: 1,
        bank: 1,
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );

    dispatch(listThirdParties());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilter = () => {
    setFilters({
      id_account: counter.loginReducer.currentAccount.id,
      eaccount: counter.loginReducer.currentAccount.id,
      created_by: counter.loginReducer.user_data.id,
      date: '',
      bknt_niif_account: 0,
      details: '',
      id_bank_nt_type: 0,
      cc_id: 0,
      amount: 0,
      fu_id: 0,
      site: 0,
    });
  };

  let optionAccountTypes = counter.billingReducer.note_types?.length
    ? [{ label: 'Seleccionar...', value: '' }]
    : [];
  if (Array.isArray(counter.billingReducer.note_types)) {
    counter.billingReducer.note_types.forEach(item => {
      optionAccountTypes.push({
        value: item.id,
        label: item.name,
        key: item.id + 'notes',
        niif_account: item.niif_account,
      });
    });
  }

  let optionNiifAccounts = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.billPaymentReducer.niif)) {
    counter.billPaymentReducer.niif.forEach(item => {
      optionNiifAccounts.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        complete_account: item.complete_account,
        description: item.description,
        num_count: item.num_count,
        key: item.id,
      });
    });
  }

  let optionProviders = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.accountingReducer.listThirdParties)) {
    counter.accountingReducer.listThirdParties.forEach(item => {
      optionProviders.push({
        value: item.id,
        label: item.description,
        key: item.id,
      });
    });
  }

  // const optionConCenter = () => {
  let optionConCenter = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.consumptionCenterReducer.consumption_center)) {
    counter.consumptionCenterReducer.consumption_center.forEach(elem => {
      let label = elem.description;
      if (Array.isArray(elem.consumption_combination)) {
        if (elem.consumption_combination.length < 1) {
          label += '*';
        }
      } else {
        label += '*';
      }
      optionConCenter.push({
        value: elem.id,
        label: label,
      });
    });
  }
  //     return optionConCenter;
  // }

  let optionsSites = [{ label: 'Seleccionar...', value: '' }];
  //const sitesarray = counter.siteReducer.sites;
  const sitesarray = counter.functionalReducer.sites.filter(item => item.active === 1);
  if (Array.isArray(sitesarray)) {
    sitesarray.forEach(elem => {
      optionsSites.push({
        ...elem,
        isDisabled: elem.id_account !== counter.loginReducer.currentAccount.id || !elem.active,
      });
    });
  }

  let optionsFunctionalUnits = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.functionalReducer.units)) {
    counter.functionalReducer.units.forEach(elem => {
      optionsFunctionalUnits.push({
        ...elem,
        key: elem.id,
        value: elem.id,
        label: elem.description,
      });
    });
  }

  const checkMandatoryInputs = type => {
    if (type === 'AND') {
      return (
        !!filters.date &&
        !!filters.bknt_niif_account &&
        !!filters.details &&
        !!filters.id_bank_nt_type &&
        !!filters.amount
      );
    }

    if (type === 'OR') {
      return (
        !!filters.date ||
        !!filters.bknt_niif_account ||
        !!filters.details ||
        !!filters.id_bank_nt_type ||
        !!filters.amount
      );
    }
  };

  const postBankNote = () => {
    let error = '';

    if (!!filters.date === false) {
      error = 'La fecha es obligatoria';
    }
    if (!!filters.bknt_niif_account === false) {
      error = 'La cuenta bancaria es obligatoria';
    }
    if (!!filters.details === false) {
      error = 'La descripción es obligatoria';
    }
    if (!!filters.id_bank_nt_type === false) {
      error = 'El tipo de nota es obligatorio';
    }
    if (isNaN(filters.amount) || (!isNaN(filters.amount) && filters.amount <= 0)) {
      error = 'Se debe agregar una cantidad de transferencia mayor a cero';
    }

    if (error) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
        showCancelButton: false,
      });
      return null;
    }

    dispatch(
      banking_notes_insert(filters, () => {
        resetFilter();
        setTrigger(trigger + 1);
      }),
    );
  };

  return (
    <>
      {!!counter.bankNoteReducer.post_bank_note_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003F80' height={100} width={100} />
        </div>
      )}
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall} style={{ marginTop: '30px' }}>
              <Title
                title='Registro de notas de banco'
                className={'mb-4'}
                onClickIcon={() => {
                  history.push('/tesoreria/inicio');
                }}
              />
            </div>
          </div>
          <div
            className={`pt-3 pb-3`}
            style={{
              backgroundColor: '#F2F7FC',
              borderRadius: '10px',
              paddingLeft: '24px',
            }}
          >
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%' }}>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  className='register-inputs'
                  style={{ color: '#01A0F6', fontWeight: 'bold' }}
                  name='requisition_date'
                  type='date'
                  min={new Date().toISOString().split('T')[0]}
                  value={filters.date}
                  onChange={e => setFilters({ ...filters, date: e.target.value })}
                />
              </Col>

              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Tipo de nota</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionAccountTypes.filter(
                    option => option.value === filters.id_bank_nt_type,
                  )}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      id_bank_nt_type: e.value,
                      niif_account: e.niif_account,
                      desc_bank_nt_type: e.label,
                    })
                  }
                  options={optionAccountTypes}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </Col>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Cuenta bancaria</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionNiifAccounts.filter(
                    option => option.value === filters.bknt_niif_account,
                  )}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      bknt_niif_account: e.value,
                      bank_account_nbr: e.num_count,
                      niif_complete_account: e.complete_account,
                      niif_description: e.description,
                    })
                  }
                  options={optionNiifAccounts}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </Col>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionConCenter.find(x => x.value === filters.cc_id)}
                  onChange={e => setFilters({ ...filters, cc_id: e.value })}
                  options={optionConCenter}
                  placeholder='Seleccionar...'
                  key={'ConCenter' + trigger}
                  styles={customSelectNewDark}
                  maxHeight={22}
                />
              </Col>
            </Row>

            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <Row className='d-flex' style={{ width: '100%' }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Detalle</p>
                <textarea
                  style={{ height: '3rem', maxHeight: 'initial', padding: '5px' }}
                  placeholder='Escribir...'
                  rows='5'
                  cols='40'
                  className='register-inputs'
                  name='requisition_date'
                  value={filters.details}
                  onChange={e => setFilters({ ...filters, details: e.target.value })}
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 3 --------------------------------- */}
            <Row className='d-flex ' style={{ width: '100%' }}>
              <Col xs={8}>
                <p className={tableStyles.crudModalLabel}>NIT</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionProviders.filter(option => option.value === filters.id_provider)}
                  onChange={e => setFilters({ ...filters, id_provider: e.value })}
                  options={optionProviders}
                  placeholder='Seleccionar...'
                  styles={customSelectNewDark}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Valor nota</p>
                <NumberFormat
                  allowNegative={false}
                  id='valornota'
                  className={`register-inputs`}
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  value={filters.amount}
                  onValueChange={values => {
                    const { floatValue } = values;
                    setFilters({ ...filters, amount: floatValue });
                  }}
                />
              </Col>
            </Row>
          </div>
          {/*--------------------------------- ROW 4 --------------------------------- */}
          <Row className='d-flex'>
            <Col xs={6} style={{ paddingLeft: '29px' }}></Col>
            <Col
              xs={6}
              className='d-flex'
              style={{ justifyContent: 'flex-end', paddingRight: '2.5rem' }}
            >
              <button
                className={`${tableStyles.btnSecondary} mt-4`}
                onClick={() => resetFilter()}
                disabled={!checkMandatoryInputs('OR')}
              >
                Cancelar
              </button>
              &nbsp;
              <button
                className={`${tableStyles.btnPrimaryAlt} mt-4`}
                onClick={() => postBankNote()}
              >
                Guardar
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default BankNotes;
