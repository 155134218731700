import styled from "styled-components";

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
    border: ${({ border, theme }) => border ? border : `1px solid ${theme.colors.ordDarkBlueText}`};
    box-shadow: ${({ boxShadow, theme }) => boxShadow ? boxShadow : `0px 0px 4px ${theme.colors.ordDarkBlueText}`};
    color: ${({ color, theme }) => color ? color : theme.colors.ordDarkBlueText};
    background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.colors.ordDarkBlueText};
    font-size: ${({ fontSize }) => fontSize ? fontSize : '40px'};
    padding: ${({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '0px'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'bold'};
    height: 16px;
    width: 16px;

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
    color: #fff;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    background-position:-2px -17rem;
    background-size: 18px;
    transition: all 0.3s ease-in-out;
    border-radius:3px;
    appearance: none;

    :checked{
        background-color: ${({ backgroundColor, theme }) => backgroundColor ? backgroundColor : theme.colors.ordDarkBlueText};
        border-radius:3px;
    }
`

export const Checkbox = ({ name, checked, handleChangeCheck, color, fontSize, padding, margin, fontWeight }) => {

    return (
        <StyledCheckbox
            color={color}
            fontSize={fontSize}
            padding={padding}
            margin={margin}
            fontWeight={fontWeight}
            onChange={handleChangeCheck}
            name={name}
            checked={checked}
        />
    )
}