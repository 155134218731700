import styled from 'styled-components';

export const Input = styled.input`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'lighter')};
  width: ${({ width }) => (width ? width : '296px')};
  border: ${({ border, theme }) => (border ? border : `1px solid ${theme.colors.darkBlueInput}`)};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '6px')};
  height: ${({ height }) => (height ? height : '32px')};
  padding: ${({ padding }) => (padding ? padding : '0px 10px')};
  color: ${({ color, theme }) => (color ? color : theme.colors.ordDarkGray)};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) =>
    borderBottomRightRadius ? borderBottomRightRadius : 'auto'};
  border-top-right-radius: ${({ borderTopRightRadius }) =>
    borderTopRightRadius ? borderTopRightRadius : 'auto'};
  border-right: ${({ borderRight }) => (borderRight ? borderRight : 'auto')};
  outline: 0;

  :focus {
    box-shadow: ${({ boxShadow, theme }) =>
      boxShadow ? boxShadow : `0px 0px 1px ${theme.colors.ordDarkGray}`};
  }

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

// export const Input = ({
//     title,
//     color,
//     fontSize,
//     padding,
//     margin,
//     fontWeight
// }) => {

//     return (
//         <StyledInput
//             // color={color}
//             fontSize={fontSize}
//             padding={padding}
//             margin={margin}
//             fontWeight={fontWeight}
//             type=""
//         >
//             {title}
//         </StyledInput>
//     )
// }
