import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import { upImage, updateUserInfo } from '../../actions/UserProfileActions';
import userLogo from '../../assets/img/header/user-img.png';
import RoundCheck from '../../assets/img/icons/RoundCheck.svg';
import watch from '../../assets/img/icons/Ver.svg';
import BackIcon from '../../assets/img/icons/back.svg';
import iconCamera from '../../assets/img/icons/camera.svg';
import dontWatch from '../../assets/img/icons/eye-close.svg';
import tableStyles from '../Layouts/tableStyle.module.scss';
import userStyles from './UserProfile.module.scss';

function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
}

function UserProfile() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [user, setuser] = useState({
    id: counter.loginReducer.user_data.id,
    first_name: counter.loginReducer.user_data.first_name,
    first_surname: counter.loginReducer.user_data.first_surname,
    second_surname: counter.loginReducer.user_data.second_surname,
    email: counter.loginReducer.user_data.email,
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [image, setimage] = useState({
    file: '',
    file64: '',
    fileUrl: counter?.loginReducer?.user_data?.image,
    id: counter.loginReducer.user_data.id,
    email: counter.loginReducer.user_data.email,
  });

  const [showText, setShowText] = useState({
    beforeText: false,
    afterText: false,
    afterTextValidation: false,
  });

  const resetUser = () => {
    setuser({
      ...user,
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  };

  const buttonSaveChanges = () => {
    if (user.password) {
      dispatch(
        updateUserInfo({
          ...user,
          password: user.password,
          newPassword: user.newPassword,
          newPasswordConfirm: user.newPasswordConfirm,
        }),
        resetUser(),
      );
    }
    if (image.file64) {
      dispatch(upImage(image));
    }
  };

  const handleImagePick = async e => {
    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1]; // Delete base64 prefix (data:application...

    setimage({
      ...image,
      file: e.target.files[0],
      file64: file64,
      fileUrl: URL.createObjectURL(e.target.files[0]),
    });
  };
  const buttonDisabled = () => {
    let bool;
    if (user.password !== '' || user.newPassword !== '' || user.newPasswordConfirm !== '') {
      bool = true;
    }
    if (user.password !== '' && user.newPassword !== '' && user.newPasswordConfirm !== '') {
      bool = false;
    }
    return bool;
  };

  return (
    <div className={tableStyles.container}>
      <div className={tableStyles.tableArea}>
        <h1 className={tableStyles.title}>Mi perfil</h1>

        <div className={`${userStyles.marginButton}`}>
          <Link to='/login' className={`text-decoration-none ${userStyles.buttonBack}`}>
            <p className={userStyles.label}>Regresar</p>
            <img
              src={BackIcon}
              alt='buscar'
              className={`${userStyles.sizeLink} ${userStyles.btnNewHover}`}
            />
          </Link>
        </div>

        {/* line */}
        <div className={`${userStyles.line}`}> </div>

        {/* general information */}
        <p className={`${userStyles.sectionTitle} ${userStyles.marginTopLabel}`}>
          Información General
        </p>

        <div className={`${userStyles.container0} `}>
          {/* first Name and Last name */}
          <div className={`${userStyles.Item1} ${userStyles.container1}`}>
            <div className='d-flex'>
              <div className={`${userStyles.inputMarginR} ${userStyles.Item1}`}>
                <p className={userStyles.crudModalLabel}>Primer Nombre</p>
                <input
                  className={`${userStyles.registerInputs}`}
                  name='FirstName'
                  type='text'
                  value={counter.loginReducer.user_data.first_name}
                  disabled={true}
                />
              </div>

              <div className={`${userStyles.inputMarginR} ${userStyles.Item1}`}>
                <p className={userStyles.crudModalLabel}>Primer Apellido</p>
                <input
                  className={`${userStyles.registerInputs}`}
                  name='FirstLastName'
                  type='text'
                  value={counter.loginReducer.user_data.first_surname}
                  disabled={true}
                />
              </div>
            </div>
            <div className={`${userStyles.Item1}`}>
              <p className={userStyles.crudModalLabel}>Cargo</p>
              <input
                className={`${userStyles.registerInputs}`}
                name='Cargo'
                type='text'
                value={counter.loginReducer.user_data?.payrollInfo?.job_title}
                disabled={true}
              />
            </div>
            <div className={`${userStyles.Item1}`}>
              <p className={userStyles.crudModalLabel}>Correo Electrónico</p>
              <input
                className={`${userStyles.registerInputs}`}
                name='emailAddress'
                type='text'
                value={counter.loginReducer.user_data.email}
                disabled={true}
              />
            </div>
          </div>
          <div className={`${userStyles.imageMarginTop} d-flex flex-column mx-auto`}>
            <div className={`${userStyles.profilepicWrapper}`}>
              {image?.fileUrl ? (
                <img
                  className={`${userStyles.profilepic} ${userStyles.hoverPointer} `}
                  src={image?.fileUrl}
                  alt='imagen usuario header'
                />
              ) : (
                <img
                  className={`${userStyles.profilepic} ${userStyles.hoverPointer} `}
                  src={userLogo}
                  alt='imagen usuario header'
                />
              )}
              <label htmlFor='imageUpload' className={`${userStyles.imageInputLabel} `}>
                <img
                  className={`${userStyles.changepfp} ${userStyles.hoverPointer}  `}
                  src={iconCamera}
                  alt='cambiar imagen'
                />
              </label>
              <input
                accept='image/png, image/jpeg'
                id='imageUpload'
                name='imageUpload'
                type='file'
                onChange={handleImagePick}
                className={userStyles.imageInputBtn}
              />
            </div>
          </div>
        </div>

        {/* password */}
        <p className={`${userStyles.sectionTitle} ${userStyles.marginTopSection}`}>Contraseña</p>
        <div className={`${userStyles.container1} `}>
          <div className={`${userStyles.Item1}`}>
            <p className={userStyles.crudModalLabel}>Contraseña Anterior</p>
            <div className='d-flex'>
              <input
                className={`${userStyles.registerInputs}`}
                name='oldpassword'
                type={showText.beforeText ? 'text' : 'password'}
                value={user.password}
                onChange={e =>
                  setuser({
                    ...user,
                    password: e.target.value,
                  })
                }
                minLength={8}
                disabled={false}
              />
            </div>
          </div>
          <div className={`${userStyles.Item1}`}>
            <p className={userStyles.crudModalLabel}>Nueva Contraseña</p>
            <div className='d-flex'>
              <input
                className={`${userStyles.registerInputs}`}
                name='newpassword'
                type={showText.afterText ? 'text' : 'password'}
                value={user.newPassword}
                onChange={e =>
                  setuser({
                    ...user,
                    newPassword: e.target.value,
                  })
                }
                minLength={8}
                disabled={false}
              />
              {user.newPassword !== '' && (
                <button
                  className='mx-2'
                  onClick={() => {
                    setShowText({ ...showText, afterText: !showText.afterText });
                  }}
                >
                  {showText.afterText ? (
                    <img alt='icon' src={dontWatch} />
                  ) : (
                    <img alt='icon' src={watch} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className={`${userStyles.Item1}`}>
            <p className={userStyles.crudModalLabel}>Confirmar Contraseña</p>
            <div className='d-flex'>
              <input
                className={`${userStyles.registerInputs}`}
                name='confirmpassword'
                type={showText.afterTextValidation ? 'text' : 'password'}
                value={user.newPasswordConfirm}
                onChange={e =>
                  setuser({
                    ...user,
                    newPasswordConfirm: e.target.value,
                  })
                }
                minLength={8}
                disabled={false}
              />
              {user.newPasswordConfirm !== '' && (
                <button
                  className='mx-2'
                  onClick={() => {
                    setShowText({
                      ...showText,
                      afterTextValidation: !showText.afterTextValidation,
                    });
                  }}
                >
                  {showText.afterTextValidation ? (
                    <img alt='icon' src={dontWatch} />
                  ) : (
                    <img alt='icon' src={watch} />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className='d-flex'>
            <button
              onClick={() => buttonSaveChanges()}
              className={`${userStyles.btnPrimary} ${userStyles.marginTopButton} `}
              disabled={buttonDisabled()}
            >
              {' '}
              Guardar Cambios
            </button>
            <img
              src={RoundCheck}
              alt='check'
              className={`${userStyles.imageMarginL} ${userStyles.imageMarginTop}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
