import {
  GET_ENTRY_WAREHOUSE,
  GET_LIST_INVOICES,
  GET_SELECT_PAY_CONDITIONS,
  GET_SELECT_TYPE_INVOICE,
  LOADING,
  GET_BALANCE_TOTAL,
  LOADING_RECEPIT_OF_INVOICES,
} from "../actions/actionTypes";

const initialState = {
  results: [],
  total: 0,
  resultsSelectTypeInvoice: [],
  resultsSelectPayConditions: [],
  resultsEntryWareHouse: [],
  loading: true,
  balanceT_loading: false,
  loadingInvoicesReceipt: false,
};
export const InvoiceReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_INVOICES:
      return {
        ...state,
        results: action.payload,
        total: action.total,
      };
    case GET_SELECT_TYPE_INVOICE:
      return {
        ...state,
        resultsSelectTypeInvoice: action.payload,
      };
    case GET_SELECT_PAY_CONDITIONS:
      return {
        ...state,
        resultsSelectPayConditions: action.payload,
      };
    case GET_ENTRY_WAREHOUSE:
      return {
        ...state,
        resultsEntryWareHouse: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_BALANCE_TOTAL:
      return {
        ...state,
        balanceT_loading: action.loading,
      };
    case LOADING_RECEPIT_OF_INVOICES:
      return {
        ...state,
        loadingInvoicesReceipt: action.loading,
      };

    default:
      return state;
  }
};
