import moment from 'moment';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../Hooks';
import { customSwaltAlertAsistencial, loader } from '../../helpers';
import { formatNumberWithoutDecimals } from "../../helpers/numberFormatting";

import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import downloadIcon from '../../assets/img/icons/downloadArrow2.svg';
import cancelIcon from '../../assets/img/icons/cancelPng.png';

import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';

import { selectMultiple } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

const header = [
  { title: 'Médico', className: 'px-2 text-start' },
  { title: 'Total servicios', className: 'px-2 text-center' },
  { title: 'Total', className: 'px-2 text-end' },
  { title: 'Fecha generación', className: 'px-2 text-center' },
  { title: 'Estado', className: 'px-2 text-center' },
  { title: '', className: 'px-2' },
];

export default function SettlementHistory() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);
  const userId = useSelector(store => store.loginReducer.user_data.id);

  const [params, setParams] = useState({
    doctors: [],
    page: 1,
    perpage: 10,
  });

  const {
    trigger: getRemunerationsHistory,
    results: remunerationsHistoryResult,
    load: remunerationsHistoryLoader,
  } = useGetMethod();

  const { trigger: getRemunerationPdf, load: pdfLoader } = useGetMethod();

  const { trigger: getDoctors, results: doctorsResult, load: doctorsLoader } = useGetMethod();

  useEffect(() => {
    void getDoctors({
      url: '/medical/generals/doctors/',
      token: token,
      objFilters: { idAccount: accountId },
    });
  }, [getDoctors, token, accountId]);

  useEffect(() => {
    void getRemunerationsHistory({
      token: token,
      url: '/medical/doctors/remunerations-history',
      objFilters: {
        initialDate: '',
        finalDate: '',
        page: params.page,
        perpage: params.perpage,
      },
    });
  }, [getRemunerationsHistory, params.page, params.perpage, token]);

  const doctorsList = useMemo(() => {
    if (doctorsResult?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...doctorsResult.results.map(doc => ({
          label: doc.nameDoctor,
          value: doc.idDoctor,
        })),
      ];
    }
    return [];
  }, [doctorsResult?.results]);

  const onSubmitForm = event => {
    event.preventDefault();

    const {
      dateFrom: { value: dateFromValue },
      dateTo: { value: dateToValue },
    } = event.target;

    if (!dateFromValue || !dateToValue || !params.doctors.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar los filtros obligatorios.',
        showCancelButton: false,
      });
      return;
    }

    void getRemunerationsHistory({
      token: token,
      url: '/medical/doctors/remunerations-history',
      objFilters: {
        doctors: params.doctors.map(state => state.value).join(','),
        initialDate: dateFromValue,
        finalDate: dateToValue,
        page: params.page,
        perpage: params.perpage,
      },
    });
  };

  const onGeneratePdf = async item => {
    void getRemunerationPdf({
      token: token,
      url: `/medical/doctors/remunerations-history/${item.id}`,
      objFilters: {
        eaccount: accountId,
        userId: userId,
      },
      succesAction: result => {
        if (result?.results?.file) {
          let treatPdf = result.results.file?.replace(/^b'/gm, '').replace(/=*'$/gm, '');
          let linkSource = `data:application/pdf;base64,${treatPdf}`;
          let downloadLink = document.createElement('a');
          let fileName = `liquidacion_${item.id}_${item.doctor.firstName}_${item.doctor.lastName}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      },
    });
  };

  const renderComponent = () => {
    return (
      <>
        {(doctorsLoader || remunerationsHistoryLoader || pdfLoader) && loader}
        <OrdGenericTemplate
          showBottomBorder={false}
          title={'Historial de liquidaciones'}
          titleSize={12}
        >
          <form onSubmit={onSubmitForm} className='row d-flex align-items-end'>
            <div className='col-lg-3'>
              <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Médico</div>
              <ReactSelect
                isMulti
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                styles={selectMultiple}
                options={doctorsList}
                name='medic'
                placeholder={'Seleccionar...'}
                onChange={option => setParams({ ...params, doctors: option })}
              />
            </div>

            <div className='col-lg-3'>
              <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Fecha desde</div>
              <Form.Control
                type='date'
                className='ord-roundInput'
                style={{ width: '100%' }}
                name='dateFrom'
                placeholder='Escribe...'
              />
            </div>
            <div className='col-lg-3'>
              <div className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Fecha hasta</div>
              <Form.Control
                type='date'
                className='ord-roundInput'
                style={{ width: '100%' }}
                name='dateTo'
                max={new Date().toISOString('en-US').split('T')[0]}
                placeholder='Escribe...'
              />
            </div>

            <div className='col-lg-1'>
              <button type='submit' className='mb-2'>
                <img src={ordBlueSearch} width={16} alt='' />
              </button>
            </div>
          </form>

          <OrdTable
            shadow
            headers={header}
            hasChildren={true}
            oneHeadDarkBlue={false}
            lowerCase={false}
          >
            {remunerationsHistoryResult?.results ? (
              remunerationsHistoryResult.results.map(item => (
                <tr key={item.id}>
                  <td align='left' className='px-2'>
                    {item.doctor.firstName} {item.doctor.lastName}
                  </td>
                  <td className='text-center px-2'>{item.totalServices}</td>
                  <td className='text-end px-2'>{formatNumberWithoutDecimals(item.total)}</td>
                  <td className='text-center px-2'>
                    {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
                  </td>
                  <td align='center' className='px-2'>
                    <div
                      style={{
                        color: item.status.color,
                        background: item.status.background,
                        borderRadius: 10,
                        padding: 5,
                        width: 120,
                      }}
                    >
                      {item.status.name}
                    </div>
                  </td>
                  <td width={40} align='center'>
                    <CustomPopupExtend
                      position='left top'
                      triggerSrc={ThreeDots}
                      extraButtons={[
                        {
                          event: () => onGeneratePdf(item),
                          icon: downloadIcon,
                          text: 'Descargar',
                        },
                        {
                          event: () => null,
                          icon: cancelIcon,
                          text: 'Anular',
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} align='center'>
                  No hay información por mostrar
                </td>
              </tr>
            )}
          </OrdTable>
        </OrdGenericTemplate>
      </>
    );
  };

  return renderComponent();
}
