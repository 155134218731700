//-------------- components 🍕   --------------------------------------------
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../Hooks/useFetch';
import { useLocation } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

//-------------- styles 🍕   --------------------------------------------
import tableStyles from '../../../src/components/Layouts/tableStyle.module.scss';

//-------------- icons 🍕   --------------------------------------------
import backIcon from '../../assets/img/icons/atras-icon.svg';
import downlA from '../../assets/img/icons/downloadArrow2.svg';
import { CardContent, Typography } from '@mui/material';
import userLogo from '../../assets/img/header/user-img.png';
import Calendar from '../../assets/img/icons/calendarWhite.svg';
import FileDown from '../../assets/img/icons/download.svg';
import { convertFilterToString } from '../../helpers/convertToFilter';
import { API_VERSION, URL_GATEWAY } from '../../helpers/constants';
import { downloaFile } from '../../helpers/helpers';

function DetailAppointment() {
  //-------------- const 🍕   --------------------------
  const MySwal = withReactContent(Swal);
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loadingBase64, setLoadingBase64] = useState(false);
  const { results: ListInfo, load: ListInfoLoader, trigger: getListInfo } = useGetMethod();

  const { results: orderFiles, load: loadListOrderFiles, trigger: getOrderFiles } = useGetMethod();

  const information = ListInfo?.results?.infoPatient;
  const careHistory = ListInfo?.results?.careHistory;
  const files = ListInfo?.results?.files;
  const events = ListInfo?.results?.events;
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    if (location?.state?.idApp) {
      getListInfo({
        url: '/medical/generals/detailAppointment/',
        objFilters: { idApp: location?.state?.idApp },
        token: token,
        doAfterSuccess: result => {
          formatData(result?.results?.careHistory);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);

  useEffect(() => {
    getOrderFiles({
      url: '/medical/appointment/OrderFilesAppointment/',
      objFilters: { appointment: location?.state?.idApp },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //-------------- actions 🍕   --------------------------

  const findOneHistory = idApp => {
    let found = careHistory?.filter(c => c.app_id === idApp);
    if (found?.length > 0) {
      return found[0].fileUrl;
    } else {
      return '-';
    }
  };

  //-------------- format data to table 🍕   --------------------------
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array?.forEach((e, index) => {
        tempList.push(
          <>
            <tr
              key={index}
              onClick={() =>
                e?.fileUrl !== '-'
                  ? window.open(e?.fileUrl)
                  : MySwal.fire({
                      icon: 'warnig',
                      title: 'Señor usuario,',
                      text: 'no fue posible encontrar el documento',
                      confirmButtonText: 'Aceptar',
                    })
              }
            >
              <td className='col text-start px-2'>{e?.dateApp ? e.dateApp : '-'}</td>
              <td className='col text-start px-2'>{e?.name ? e.name : '-'}</td>
              <td className='col text-start px-2'>{e?.fullNameDoctor ? e.fullNameDoctor : '-'}</td>
            </tr>
          </>,
        );
      });
    }
    if (array?.length === 0) {
      tempList.push(
        <tr>
          <td></td>
          <td> No hay registros</td>
          <td></td>
        </tr>,
      );
    }
    setData(tempList);
  };

  const headers = [
    {
      title: 'Fecha',
      className: 'text-start px-2',
    },
    {
      title: 'Servicio',
      className: 'text-start px-2',
    },
    {
      title: 'Médico',
      className: 'text-start px-2',
    },
  ];

  const getBase64 = (objFilters, type) => {
    let url = '';
    let name = '';
    if (type === 'assistCertification') {
      url = 'assistCertificationOrderPDF';
      name = 'Certificado de asistencia';
    } else if (type === 'inhability') {
      url = 'pdfMedicalinhabilities';
      name = 'Certificado de incapacidad';
    } else if (type === 'medicationOrder') {
      url = 'pdfMedicationOrder';
      name = 'Orden de medicamentos';
    } else if (type === 'reference') {
      url = 'pdfReference';
      name = 'Certificado de referencia';
    } else if (type === 'serviceOrder') {
      url = 'pdfserviceOrder';
      name = 'Orden de servicio';
    }

    const filters = convertFilterToString(objFilters);

    setLoadingBase64(true);

    fetch(`${URL_GATEWAY}${API_VERSION}/medical/${url}/?${filters}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          for (let i of res.results) {
            downloaFile(i?.base64, name);
          }
        }
        setLoadingBase64(false);
      })
      .catch(error => {
        setLoadingBase64(false);
        console.error(error);
      });
  };

  const orderButton = type => {
    let name = '';
    switch (type) {
      case 'assistCertification':
        name = 'Asistencia';
        break;
      case 'inhability':
        name = 'Incapacidad';
        break;
      case 'medicationOrder':
        name = 'Medicamentos';
        break;
      case 'reference':
        name = 'Referencia';
        break;
      default:
        name = 'Servicios';
        break;
    }
    return (
      <Col
        onClick={() => {
          getBase64({ appointment: location.state.idApp }, type);
        }}
        xs={5}
        className={`primary-text cursorPointer ${tableStyles.hoverOpacity7}`}
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          maxWidth: '420px',
          overflow: 'hidden',
          background: '#F5FCFD',
          borderRadius: '10px',
          padding: '8px',
          gap: '8px',
          marginBottom: '8px',
        }}
      >
        <img src={downlA} alt='donwloadArrow'></img>
        <span style={{ fontSize: '12px' }} className={`text-start px-2 cursorPointer`}>
          {name}
        </span>
      </Col>
    );
  };

  //-------------- Principal return 🍕   --------------------------
  return (
    <div className='w-100' style={{ height: 'max-content' }}>
      {(ListInfoLoader || loadListOrderFiles || loadingBase64) && (
        <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
      )}
      <div className='d-flex h-100'>
        <div className='col-4 py-4 px-4' style={{ borderRight: '5px solid #00b4cc' }}>
          <div className='d-flex mb-3'>
            <div>
              <img
                src={backIcon}
                alt='atras/back'
                className='cursorPointer'
                style={{
                  width: '20px',
                  marginRight: '10px',
                  marginTop: '18px',
                  filter:
                    'brightness(0) saturate(100%) invert(58%) sepia(19%) saturate(7304%) hue-rotate(149deg) brightness(94%) contrast(101%)',
                }}
                onClick={
                  location?.state?.location === 'calendar'
                    ? () =>
                        history.push({
                          pathname: '/telemedicina/detalleHorario/',
                          state: {
                            idDoctor: location?.state?.idDoctor,
                            nameDoctor: location?.state?.nameDoctor,
                            imageDoctor: location?.state?.imageDoctor,
                          },
                        })
                    : () =>
                        history.push({
                          pathname: '/telemedicina/ConsultarCitas/',
                        })
                }
              />
            </div>
            <div>
              <h1
                className={tableStyles.ordDarkBlueText}
                style={{ fontSize: '32px', margin: '0', marginTop: '15px' }}
              >
                {information?.serviceApp ? information?.serviceApp : '-'}
              </h1>
            </div>
          </div>
          <Row className={`mb-1 px-2`}>
            <Col xs={12} className={`mb-2`}>
              <span className={`${tableStyles.ordDarkBlueText} fw-bold`}>Detalle</span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold text-start ${tableStyles.ordDarkGrayText}`}>Paciente</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {information?.fullNamePatient ? information?.fullNamePatient : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>No. ID</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {information?.documentPatient ? information?.documentPatient : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Tipo</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {' '}
                {information?.entity ? information?.entity : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Estado</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {information?.statusApp ? information?.statusApp : '-'}
              </span>
            </Col>
            {!!(information?.statusApp === 'Fallida') && (
              <>
                <Col xs={6}>
                  <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Motivo</span>
                </Col>
                <Col xs={6}>
                  <span className={`${tableStyles.ordDarkGrayText}`}>
                    {information?.reason ? information?.reason : '-'}
                  </span>
                </Col>
              </>
            )}
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Fecha</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {information?.dateApp ? information?.dateApp : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Servicio</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`}>
                {information?.serviceApp ? information?.serviceApp : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Correo</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`} style={{ fontSize: '12px' }}>
                {information?.emailPatient ? information?.emailPatient : '-'}
              </span>
            </Col>
            <Col xs={6}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Telefono</span>
            </Col>
            <Col xs={6}>
              <span className={`${tableStyles.ordDarkGrayText}`} style={{ fontSize: '12px' }}>
                {information?.cellphonePatient ? information?.cellphonePatient : '-'}
              </span>
            </Col>
            <Col xs={8}>
              <span className={`fw-bold ${tableStyles.ordDarkGrayText}`}>Médico que atiende:</span>
            </Col>
          </Row>
          <div className='d-flex mb-4 mt-4 col-12'>
            <div className='col-4 px-3 align-self-center'>
              <img
                src={userLogo}
                alt='imageUser'
                width='85'
                className='cursorPointer'
                style={{
                  borderRadius: '100%',
                }}
              ></img>
            </div>
            <div className='d-grid'>
              <span
                className={`${tableStyles.ordDarkGrayText} fw-bold`}
                style={{ fontSize: '24px' }}
              >
                {information?.fullNameDoctor ? information?.fullNameDoctor : '-'}
              </span>
              <span className={`${tableStyles.ordDarkGrayText}`} style={{ fontSize: '14px' }}>
                {information?.emailDoctor ? information?.emailDoctor : '-'}
              </span>
              <span className={`${tableStyles.ordDarkGrayText}`} style={{ fontSize: '14px' }}>
                {information?.cellphoneDoctor ? information?.cellphoneDoctor : '-'}
              </span>
            </div>
          </div>
          <Row className='mb-3' style={{ paddingLeft: '2rem', paddingRight: '1rem' }}>
            {information?.statusApp === 'Pendiente de pago' ||
            information?.statusApp === 'Anulada' ||
            information?.statusApp === 'Pendiente por validación' ? (
              ''
            ) : (
              <Col xs={12} className={'text-end mb-4'}>
                <button
                  className={`${tableStyles.ordBtnAquaMarine} w-100`}
                  onClick={() =>
                    findOneHistory(location.state.idApp) !== '-'
                      ? window.open(findOneHistory(location.state.idApp))
                      : null
                  }
                  disabled={
                    information?.statusApp === 'Atención pendiente' ||
                    information?.statusApp === 'Fallida'
                      ? 'disabled'
                      : information?.statusApp === 'Atención parcial' ||
                          information?.statusApp === 'Atención exitosa'
                        ? findOneHistory(location.state.idApp) !== '-'
                          ? ''
                          : 'disabled'
                        : ''
                  }
                >
                  Descargar atención <span className='px-1'></span>{' '}
                  <img
                    alt={'FileDown'}
                    src={FileDown}
                    width='20'
                    style={{
                      filter:
                        ' brightness(0) saturate(100%) invert(96%) sepia(96%) saturate(0%) hue-rotate(117deg) brightness(105%) contrast(106%)',
                    }}
                  ></img>
                </button>
              </Col>
            )}
            {information?.statusApp === 'Atención exitosa' ||
            information?.statusApp === 'Pendiente de pago' ||
            information?.statusApp === 'Fallida' ||
            information?.statusApp === 'Anulada' ||
            information?.statusApp === 'Pendiente por validación' ? (
              ''
            ) : (
              <Col xs={12} className={'text-end mb-4'}>
                <button
                  className={`${tableStyles.ordBtnDarkBlue} w-100`}
                  onClick={() => alert('Funcionalidad en desarrollo')}
                  disabled={false}
                >
                  Reprogramar <span className='px-1'></span>{' '}
                  <img alt={'calendar'} src={Calendar}></img>
                </button>
              </Col>
            )}
          </Row>
        </div>
        <div className='col p-4 h-100' style={{ backgroundColor: '#F6FEFF' }}>
          <h3 className={`${tableStyles.ordDarkGrayText} fw-bold`}>Historial de atenciones</h3>
          <hr style={{ border: '1px solid #CBCBD0', marginBottom: '0px' }}></hr>
          <div className='d-flex'>
            <div className='col-12 p-2'>
              <OrdTable
                hasChildren={true}
                headers={headers}
                paginate={{
                  activePage: filters.page,
                  totalPages: ListInfo?.results?.rowTotal,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async e => {
                    setFilters({ ...filters, page: e });
                  },
                  showTextDetails: true,
                }}
              >
                {data}
              </OrdTable>
            </div>
          </div>
          <div className='d-flex'>
            <div className='col-6 p-2'>
              <Card className={`${tableStyles.ordCardBoxContainer}`}>
                <Typography
                  variant='p'
                  component='h6'
                  style={{ borderBottom: '1px solid #CBCBD0' }}
                  className={`${tableStyles.ordDarkGrayText} fw-bold p-2`}
                >
                  Adjuntos
                </Typography>
                {files?.length !== 0 ? (
                  <CardContent
                    className='px-2'
                    style={{
                      maxHeight: '100px',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                    }}
                  >
                    {files?.map(elem => {
                      return (
                        <>
                          <Row>
                            <Col xs={12} className={'cursorPointer'}>
                              <div>
                                <u
                                  className={`${tableStyles.ordAquaMarineText}`}
                                  style={{ fontSize: '12px' }}
                                  onClick={() =>
                                    elem.fileUrl !== '-' ? window.open(elem.fileUrl) : null
                                  }
                                >
                                  {elem.fileName}
                                </u>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography
                      variant='body2'
                      className={`${tableStyles.ordDarkGrayText} text-center`}
                    >
                      No has subido ningún documento, exámenes prequirúrgicos o imágenes.
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
            <div className='col-6 p-2'>
              <Card className={`${tableStyles.ordCardBoxContainer}`}>
                <Typography
                  variant='p'
                  component='h6'
                  style={{ borderBottom: '1px solid #CBCBD0' }}
                  className={`${tableStyles.ordDarkGrayText} fw-bold p-2`}
                >
                  Fórmulas e incapacidad
                </Typography>

                <CardContent>
                  {orderFiles?.results?.assistCertification !== 0 ||
                  orderFiles?.results?.inhability !== 0 ||
                  orderFiles?.results?.medicationOrder !== 0 ||
                  orderFiles?.results?.reference !== 0 ||
                  orderFiles?.results?.serviceOrder !== 0 ? (
                    <Row className={`gap-2 p-2 ${tableStyles.ordAquaMarineText}`}>
                      {orderFiles?.results?.assistCertification !== 0
                        ? orderButton('assistCertification')
                        : null}
                      {orderFiles?.results?.inhability !== 0 ? orderButton('inhability') : null}
                      {orderFiles?.results?.medicationOrder !== 0
                        ? orderButton('medicationOrder')
                        : null}
                      {orderFiles?.results?.reference !== 0 ? orderButton('reference') : null}
                      {orderFiles?.results?.serviceOrder !== 0 ? orderButton('serviceOrder') : null}
                    </Row>
                  ) : (
                    <Typography
                      variant='body2'
                      className={`text-center ${tableStyles.ordDarkGrayText}`}
                    >
                      No hay fórmulas o incapacidades asociadas.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
          </div>
          <div className='d-flex'>
            <div className='col p-2'>
              <Card className={`${tableStyles.ordCardBoxContainer}`}>
                <Typography
                  variant='p'
                  component='h6'
                  style={{ borderBottom: '1px solid #CBCBD0' }}
                  className={`${tableStyles.ordDarkGrayText} fw-bold p-2`}
                >
                  Registro de actividad
                </Typography>

                {events?.length !== 0 ? (
                  <CardContent className='px-2'>
                    <Row>
                      <Col xs={4}>
                        <span
                          className={`fw-bold ${tableStyles.ordDarkGrayText}`}
                          style={{ fontSize: '16px' }}
                        >
                          Fecha
                        </span>
                      </Col>
                      <Col xs={8}>
                        <span
                          className={`fw-bold ${tableStyles.ordDarkGrayText}`}
                          style={{ fontSize: '16px' }}
                        >
                          Actividad
                        </span>
                      </Col>
                      {events?.map(elem => {
                        return (
                          <>
                            <Col xs={4}>
                              <span
                                className={`${tableStyles.ordDarkGrayText}`}
                                style={{ fontSize: '12px' }}
                              >
                                {elem.date}
                              </span>
                            </Col>
                            <Col xs={8}>
                              <span
                                className={`${tableStyles.ordDarkGrayText}`}
                                style={{ fontSize: '12px' }}
                              >
                                {elem.descriptionEvent}
                              </span>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </CardContent>
                ) : (
                  <CardContent>
                    <Typography
                      variant='body2'
                      className={`${tableStyles.ordDarkGrayText} text-center`}
                    >
                      No hay registros
                    </Typography>
                  </CardContent>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DetailAppointment;
