import { GET_LIST_WORK_ENV_POLL } from "../actions/actionTypes";

const initialState = {
  listWorkEnvPoll: [],
  listWorkEnvPoll_loading: false,
};
export const workEnvReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_WORK_ENV_POLL:
      return {
        ...state,
        listWorkEnvPoll: action.payload,
        rowTotal: action.rowTotal,
        listWorkEnvPoll_loading: action.loading,
      };
    default:
      return state;
  }
};
