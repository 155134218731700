
import {
  GET_RETEICA,
  GET_RETEICA_RATE,
  GET_TYPE_EXPENSE,
  GET_PETTY_CASH,
  GET_EMPLOYEES,
  GET_JOURNAL_TYPE,

  GET_REPAYMENT,
  GET_DETAIL_EXPENSE,
  GET_LIST_PETTY_CASH_EXPENSES,
  GET_EXPENSE_FOR_REPAYMENT,
  GET_DETAIL_REPAYMENT,
  GET_THIRD_PARTIES,
  GET_ONE_DETAIL_EXPENSE,
  GET_PETTYCASH_USER_PERMISSION,
  GET_ONE_PETTYCASH_USER_PERMISSION,
  GET_LIST_USERS,
  GET_CONFIGURATION_PARAMS,
  REPAYMENT_LOADING,
  GET_PAYROLL_PARAMS,
} from "../actions/actionTypes";

const initialState = {
  listReteica: [],
  listTypesExpense: [],
  listReteicaRate: [],
  listReteica_total: 0,
  listPettyCash: [],
  listEmployees: [],
  listJournalType: [],
  listOnePettyCah: [],
  listRepayment: [],
  listRepayment_loading: false,
  detailExpense: [],
  listPettyCashExpenses: [],
  listExpenseForRepayment: [],
  detailRepayment: [],
  listThirdParties: [],
  detailOneExpense: [],
  pettyCashPermission: [],
  onePettyCashPermission: [],
  listUsers: [],
  paramConfigList: [],
  listUser_total: 0,
  listExpenseForRepayment_loading: false,
  reteica_loading: false,
  pettyC_loading: false,
  detailExpense_loading: false,
  expense_loading: false,
  permission_loading: false,
  loading: false,
  listUsers_loading: false,
  repayment_loading: false,
  payrollParamList: [],
  ppLoading: false,
  rowTotal: 0,
};

export const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RETEICA:
      return {
        ...state,
        listReteica: action.payload,
        listReteica_total: action.total,
        reteica_loading: action.loading,
      };
    case GET_RETEICA_RATE:
      return {
        ...state,
        listReteicaRate: action.payload,
      };
    case GET_TYPE_EXPENSE:
      return {
        ...state,
        listTypesExpense: action.payload,
        expense_loading: action.loading,
      };
    case GET_PETTY_CASH:
      return {
        ...state,
        listPettyCash: action.payload,
        pettyC_loading: action.loading,
      };
    case GET_EMPLOYEES:
      return {
        ...state,
        listEmployees: action.payload,
      };
    case GET_JOURNAL_TYPE:
      return {
        ...state,
        listJournalType: action.payload,
      };
    case GET_REPAYMENT:
      return {
        ...state,
        listRepayment: action.payload,
        listRepayment_loading: action.loading,
      };
    case GET_DETAIL_EXPENSE:
      return {
        ...state,
        detailExpense: action.payload,
        detailExpense_loading: action.loading,
      };
    case GET_LIST_PETTY_CASH_EXPENSES:
      return {
        ...state,
        listPettyCashExpenses: action.payload,
      };
    case GET_EXPENSE_FOR_REPAYMENT:
      return {
        ...state,
        listExpenseForRepayment: action.payload,
        listExpenseForRepayment_loading: action.loading,
      };
    case GET_DETAIL_REPAYMENT:
      return {
        ...state,
        detailRepayment: action.payload,
      };
    case GET_THIRD_PARTIES:
      return {
        ...state,
        listThirdParties: action.payload,
      };
    case GET_ONE_DETAIL_EXPENSE:
      return {
        ...state,
        detailOneExpense: action.payload,
      };
    case GET_PETTYCASH_USER_PERMISSION:
      return {
        ...state,
        pettyCashPermission: action.payload,
        permission_loading: action.loading,
      };
    case GET_ONE_PETTYCASH_USER_PERMISSION:
      return {
        ...state,
        onePettyCashPermission: action.payload,
      };
    case GET_LIST_USERS:
      return {
        ...state,
        listUsers: action.payload,
        listUser_total: action.total,
        listUsers_loading: action.loading,
      };
    case GET_CONFIGURATION_PARAMS:
      return {
        ...state,
        paramConfigList: action.payload,
        loading: action.loading,
      };
    case REPAYMENT_LOADING:
      return {
        ...state,
        repayment_loading: action.loading,
      };
    case GET_PAYROLL_PARAMS:
      return {
        ...state,
        payrollParamList: action.payload,
        ppLoading: action.loading,
        rowTotal: action.rowTotal,
      };
    default:
      return state;
  }
};
