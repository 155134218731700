import deepcopy from 'deepcopy';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import userLogo from '../../assets/img/header/user-img.png';
import Back from '../../assets/img/icons/atras-icon.svg';

import {
  listUsers,
  oneUserPermission,
  updateUserPermissions,
  userPermission,
} from '../../actions/accountingActions';

import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Styles from './pettyCashStyles.module.scss';

function EditUserPermissions(props) {
  const dispatch = useDispatch();
  const storage = useSelector(state => state);

  const [info, setInfo] = useState({
    user: '',
    pettyCash: '',
    userFullName: '',
    permissionCount: '',
    totalPermission: '',
    permissionLabel: '',
    jobPosition: '',
    permissions: [],
  });

  useEffect(() => {
    if (props.selectedUser) {
      dispatch(
        oneUserPermission(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            user: props.selectedUser,
            mode: 'new',
          },
          setInfo,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUser, storage.loginReducer.currentAccount.id]);

  const handleView = (e, index) => {
    let check = 0;
    const value = e.target.checked;
    if (value) {
      check = 1;
    } else {
      check = 0;
    }
    let tempObject = deepcopy(info);
    tempObject.permissions[index].a_view = check;
    setInfo(tempObject);
  };

  const handleCreate = (e, index) => {
    let check = 0;
    const value = e.target.checked;
    if (value) {
      check = 1;
    } else {
      check = 0;
    }
    let tempObject = deepcopy(info);
    tempObject.permissions[index].a_create = check;
    setInfo(tempObject);
  };

  const handleEdit = (e, index) => {
    let check = 0;
    const value = e.target.checked;
    if (value) {
      check = 1;
    } else {
      check = 0;
    }
    let tempObject = deepcopy(info);
    tempObject.permissions[index].a_edit = check;
    setInfo(tempObject);
  };

  const isBoxAllSelected = () => {
    let res = false;
    if (Array.isArray(info.permissions)) {
      info.permissions.forEach(item => {
        if (Number(item.a_edit) && Number(item.a_create) && Number(item.a_view)) {
          res = true;
        } else {
          res = false;
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = checkEmAll => {
    if (checkEmAll) {
      let tempObject = deepcopy(info);
      tempObject.permissions?.forEach(element => {
        element.a_edit = checkEmAll;
        element.a_create = checkEmAll;
        element.a_view = checkEmAll;
      });
      setInfo(tempObject);
    } else {
      let tempObject = deepcopy(info);
      tempObject.permissions?.forEach(element => {
        element.a_edit = checkEmAll;
        element.a_create = checkEmAll;
        element.a_view = checkEmAll;
      });
      setInfo(tempObject);
    }
  };

  const buttonSave = () => {
    dispatch(
      updateUserPermissions(info, () => {
        props.setDetails({ show: false });
        dispatch(userPermission({ eaccount: storage.loginReducer.currentAccount.id }));
        dispatch(
          listUsers({ eaccount: storage.loginReducer.currentAccount.id, module: 'pettyCash' }),
        );
      }),
    );
  };

  return (
    <div className='d-flex flex-column mx-2 p-2 container-fluid mx-auto'>
      <div className='d-flex'>
        <div className='align-self-top pt-1 mx-4'>
          <img
            src={Back}
            alt='volver'
            width={24}
            className='hoverPointer '
            style={{ marginTop: '56px' }}
            onClick={() => {
              props.setDetails({ show: false });
            }}
          />
        </div>
        <div className='d-flex flex-column w-90'>
          <h1 className={tableStyles.title}>Permisos de usuarios</h1>
          <p className={`${Styles.title7} m-0`}>Editar los permisos de usuario</p>
          <Row style={{ marginTop: '40px' }}>
            <Col xs={3}>
              <div className={`d-flex`}>
                <div
                  className={`${Styles.profilepicWrapper} col-3`}
                  style={{ alignSelf: 'center', textAlign: 'center' }}
                >
                  <img
                    className={`${Styles.profilepic} ${Styles.imageFormat}`}
                    src={info.imageUrl ? info.imageUrl : userLogo}
                    alt=''
                    srcSet=''
                  />
                </div>
                <div className='col-9'>
                  <p className={`${Styles.title1} m-0`}>{info.userFullName}</p>
                  <p className={`${Styles.title2} m-0`}>
                    {info.jobPosition ? info.jobPosition : 'Cargo'}
                  </p>
                </div>
              </div>
              <div className={`d-flex`} style={{ marginTop: '15px' }}>
                <div
                  className='col-3 text-end'
                  style={{ marginRight: '2px', marginBottom: '0px', marginTop: '-2px' }}
                >
                  <input
                    className='border border-dark-blue form-check-input p1 check-dark-blue'
                    type='checkbox'
                    name='edit'
                    value='selectedAll'
                    checked={isBoxAllSelected()}
                    onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
                  />
                </div>
                <div className='col-9'>
                  <p className={`${Styles.title4} m-0`}>Todos los permisos</p>
                </div>
              </div>
            </Col>
            <Col xs={9} className={`${Styles.borderLeft}`}>
              <div className={`d-flex`}>
                <div className='col-6'>
                  <p className={`${Styles.title5} m-0`}> Cuenta caja menor</p>
                </div>
                <div className='col-3'>
                  <p className={`${Styles.title5} m-0`} style={{ textAlign: 'center' }}>
                    {' '}
                    No. de cuenta
                  </p>
                </div>
                <div className='col-1'>
                  <p className={`${Styles.title5} m-0`} style={{ textAlign: 'center' }}>
                    {' '}
                    Ver
                  </p>
                </div>
                <div className='col-1'>
                  <p className={`${Styles.title5} m-0`} style={{ textAlign: 'center' }}>
                    {' '}
                    Crear
                  </p>
                </div>
                <div className='col-1'>
                  <p className={`${Styles.title5} m-0`} style={{ textAlign: 'center' }}>
                    {' '}
                    Editar
                  </p>
                </div>
              </div>
              {info.permissions?.map((x, realindex) => (
                <div className={`d-flex`} key={'onePettyCash' + realindex}>
                  <div className='col-6'>
                    <p className={`${Styles.title6} m-0`}> {x.pettyCashName} </p>
                  </div>
                  <div className='col-3'>
                    <p className={`${Styles.title6} m-0`} style={{ textAlign: 'center' }}>
                      {' '}
                      {x.niifAccountNbr}{' '}
                    </p>
                  </div>
                  <div className='col-1' style={{ textAlign: 'center' }}>
                    <input
                      className='border border-dark-blue form-check-input p1 check-dark-blue'
                      type='checkbox'
                      name='see'
                      checked={Number(x.a_view)}
                      onChange={e => handleView(e, realindex)}
                    />
                  </div>
                  <div className='col-1' style={{ textAlign: 'center' }}>
                    <input
                      className='border border-dark-blue form-check-input p1 check-dark-blue'
                      type='checkbox'
                      name='create'
                      checked={Number(x.a_create)}
                      onChange={e => handleCreate(e, realindex)}
                    />
                  </div>
                  <div className='col-1' style={{ textAlign: 'center' }}>
                    <input
                      className='border border-dark-blue form-check-input p1 check-dark-blue'
                      type='checkbox'
                      name='edit'
                      checked={Number(x.a_edit)}
                      onChange={e => handleEdit(e, realindex)}
                    />
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          <div className={IndividualStyles.bottomRow} style={{ marginTop: '40px' }}>
            <Button
              className={IndividualStyles.btnPrimaryDark}
              onClick={() => buttonSave()}
              disabled={false}
            >
              Guardar
            </Button>
            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => props.setDetails({ show: false })}
              disabled={false}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditUserPermissions;
