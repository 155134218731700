import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
//
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import PopUp from '../../components/Popup/customPopUpExtends';
//
import { customSwaltAlertAsistencial, loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
//
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function GeneralClassification() {
  const store = useSelector(state => state);

  const [generalClass, setGeneralClass] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Clasificación general', className: 'col-8 text-start px-2' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
  });
  const [data, setData] = useState({
    nameClassification: '',
    stcId: null,
    createdBy: store.loginReducer.currentAccount.id,
  });

  const {
    load: generalClassificationLoader,
    results: generalClassificationResults,
    trigger: getGeneralClassification,
  } = useGetMethod();

  const { load: createDilatationServiceLoader, trigger: createGeneralClassificationMethod } =
    usePostMethod();

  const { load: updateDilatationServiceLoader, trigger: updateGeneralClassificationMethod } =
    usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getGeneralClassification({
        url: '/medical/clinicHistory/clasificationLevels/',
        objFilters: { eaccount: store.loginReducer.currentAccount.id, ...filters },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
  }, [
    getGeneralClassification,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    createDilatationServiceLoader,
    updateDilatationServiceLoader,
    filters,
  ]);

  const handleOpenEditModal = item => {
    setGeneralClass({
      ...generalClass,
      showModal: true,
      isEditing: true,
    });
    setData({
      ...data,
      nameClassification: item?.nameClassification,
      createdBy: store.loginReducer.currentAccount.id,
      stcId: item?.stcId,
      active: item?.active,
    });
  };

  const handleCloseModal = () => {
    setGeneralClass({ ...generalClass, showModal: false, isEditing: false });
    setData({
      stcId: null,
      nameClassification: null,
      createdBy: store.loginReducer.currentAccount.id,
    });
  };

  const handleCreateGeneralClassification = async () => {
    await createGeneralClassificationMethod({
      url: '/medical/clinicHistory/clasificationLevels/',
      method: 'POST',
      token: store.loginReducer.Authorization,
      body: data,
      succesAction: results => {
        handleCloseModal();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: results.title,
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleUpdateGeneralClassification = async dataComing => {
    await updateGeneralClassificationMethod({
      url: '/medical/clinicHistory/clasificationLevels/',
      method: 'PUT',
      token: store.loginReducer.Authorization,
      body: dataComing ? dataComing : data,
      succesAction: results => {
        handleCloseModal();
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          title: 'Actualizado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          titleColor: '#1ABCD2',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleNameChange = event => {
    const rangeValue = event.target.value;
    setData({
      ...data,
      nameClassification: rangeValue,
    });
  };

  const handleSubmit = async () => {
    if (data?.nameClassification) {
      if (generalClass.isEditing) {
        await handleUpdateGeneralClassification();
      } else {
        await handleCreateGeneralClassification();
      }
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        titleColor: '#1ABCD2',
        title: 'Intenta de nuevo',
        text: 'El nombre de la clasificación general es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

  const renderRow = row => {
    return (
      <tr key={row.id} className={`hover-table-row`}>
        <td className='text-start px-2'>{row?.nameClassification}</td>
        <td className='px-4'>
          {row?.active === 1 ? (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_true}>Habilitado</span>
            </div>
          ) : (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_false}>Deshabilitado</span>
            </div>
          )}
        </td>
        <td className='text-end'>
          <PopUp
            showEdit={true}
            showEnable={true}
            position='right'
            triggerSrc={ThreeDots}
            editClickEvent={() => handleOpenEditModal(row)}
            isEnabled={row.active === 1}
            enableText={row.active === 1 ? 'Deshabilitar' : 'Habilitar'}
            enableClickEvent={() => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: '¿Está seguro?',
                text: `Se ${row.active === 1 ? 'deshabilitará' : 'habilitará'} la clasificación general: ${row.nameClassification}`,
                confirmButtonText: 'Si, continuar',
              }).then(async rs => {
                if (rs.isConfirmed) {
                  await handleUpdateGeneralClassification({
                    nameClassification: row?.nameClassification,
                    createdBy: store.loginReducer.currentAccount.id,
                    stcId: row?.stcId,
                    active: row.active === 1 ? 0 : 1,
                  });
                }
              });
            }}
          />
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={generalClass.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: generalClassificationResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getGeneralClassification({
              url: '/medical/clinicHistory/clasificationLevels/',
              objFilters: { eaccount: store.loginReducer.currentAccount.id, page: e },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {generalClassificationResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    return (
      <OrdModal
        title={`${generalClass.isEditing ? 'Editar' : 'Nueva'} clasificación general`}
        titleClassName='fs-5'
        show={generalClass.showModal}
        btnYesName='Guardar'
        btnNoName='Cancelar'
        size='md'
        onHide={handleCloseModal}
        btnYesEvent={() => handleSubmit()}
        btnNoEvent={handleCloseModal}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Form.Group className='mb-4'>
            <Form.Label className={tableStyles.app_title_input}>
              <div>
                <span className={tableStyles.app_title_new_item}>Nombre</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>
            <Form.Control
              type='text'
              placeholder='Escribir...'
              className='ord-roundInput'
              defaultValue={data.nameClassification}
              onChange={handleNameChange}
              disabled={data.alwaysApplies === 1}
              onKeyDown={event => {
                if (event.key === '-') {
                  event.preventDefault();
                }
              }}
            />
          </Form.Group>
        </form>
      </OrdModal>
    );
  };

  const render = () => {
    return (
      <>
        {generalClassificationLoader && loader}
        {createDilatationServiceLoader && loader}
        {updateDilatationServiceLoader && loader}
        {renderModal()}
        <div className=''>
          <OrdGenericTemplate
            buttonNewLabel={'Nueva clasificación general'}
            buttonNewAction={() => {
              setGeneralClass({ ...generalClass, showModal: true });
            }}
            searchWithNew={{
              colSize: '12',
              WSize: '100',
              searchWith: '50',
              onChange: e => {
                e.preventDefault();
                setFilters({ ...filters, search: e.target.value });
              },
              buttonNewLabel: 'Nueva clasificación general',
              buttonNewAction: () => {
                setGeneralClass({ ...generalClass, showModal: true });
              },
            }}
          >
            {renderTable()}
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
