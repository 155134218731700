import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import canecaGris from '../../assets/img/icons/canecaGris.svg';
import minModal from './minModal.module.scss';
import Agregar from '../../assets/img/icons/circleAdd.svg';
import atrasIcon from '../../assets/img/icons/atras-icon.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import Search from '../../assets/img/icons/lupa.svg';
import { useHistory } from 'react-router-dom';
import ModalNew from '../Layouts/ModalNew';
import moment from 'moment';
import Select from 'react-select';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import Pagination from 'react-js-pagination';
import paginationStyles from '../Layouts/pagination.module.scss';
import { formatToRcSelect, isEmptyOrUndefined, message } from '../../helpers/helpers';

import { SkeletonTemplate } from '../Layouts/SkeletonTemplate';
import grayOutPut from '../../assets/img/icons/grayTransfer.svg';
import {
  getWareHouseList,
  getArticlesInWarehouseTransfer,
  getOutputArticles,
  creatMovement,
} from '../../actions/inventoryActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import deepcopy from 'deepcopy';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';

export const TransferWarehouse = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();

  const arrWarehouse = store.inventoryReducer.wareHouseList;
  const arrArticles = store.inventoryReducer.articles_transfer;
  const arrOutputArticles = store.inventoryReducer.outPutLotsArticles;

  const [details, setDetails] = useState('');
  const [search, setSearch] = useState({ search: '' });
  const [saveList, setSaveList] = useState([]);
  const [selectedList, setselectedList] = useState([]);
  const [selectedList2, setSelectedList2] = useState([]);
  const [justification, setJustification] = useState('');
  const [itemCheck, setItemCheck] = useState([]);
  const [lotsFilters, setLotsFilters] = useState({
    id_warehouse_articles: 178,
    page: 1,
    perpage: 10,
    trigger: false,
  });
  const [copy, setCopy] = useState();
  const [modalLotsControl, setmodalLotsControl] = useState({
    show: false,
  });
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    id_warehouse1: '',
    id_warehouse2: '',
    page: 1,
    perpage: 10,
  });
  const [fillpTable, setFillpTable] = useState(false);

  useEffect(() => {
    dispatch(getWareHouseList(store.loginReducer.currentAccount.id));
  }, [dispatch, store.loginReducer.currentAccount.id]);

  useEffect(() => {
    if (filters.id_warehouse1 && filters.id_warehouse2 !== '') {
      dispatch(getArticlesInWarehouseTransfer(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  useEffect(() => {
    dispatch(
      getOutputArticles({
        id_warehouse_articles: '',
        page: 1,
        perpage: 10,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (selectedList.length > 0) {
      dispatch(getOutputArticles(lotsFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotsFilters.trigger, lotsFilters.page]);

  let curr = new Date();
  curr.setDate(curr.getDate());
  let date = curr.toISOString().substr(0, 10);
  let arrWh = formatToRcSelect(arrWarehouse, 'id_warehouse', 'description_warehouse');
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    showJustification: false,
  });
  const localStyles = {
    blueInputText: {
      color: '#005dbf',
    },
    footerStyles: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '310%',
    },
    noBorder: { border: 'none', padding: '8px' },
  };

  const handleLots = elem => {
    setLotsFilters({
      ...lotsFilters,
      trigger: !lotsFilters.trigger,
      id_warehouse_articles: elem.id_warehouse_articles,
    });
    setmodalLotsControl({
      show: true,
      product: elem.description_article,
      id: elem.id_article,
    });
    setmodalArtControl({
      ...modalArtControl,
      showLots: true,
    });
  };

  const renderElement = elem => {
    return (
      <tr key={elem.code}>
        <td className='col-md-1 text-center'>{elem.id_article}</td>
        <td className='col-md-6 text-start'>{elem.description_article}</td>
        <td className='col-md-1 text-center'>
          <input
            type='checkbox'
            name=''
            id=''
            checked={isBoxSelected(elem)}
            onChange={() => changeBoxSelected(elem)}
          />
        </td>
      </tr>
    );
  };

  const pTableListElem = () => {
    let elemMap;
    if (saveList !== undefined) {
      const elem2 = saveList;
      elemMap = elem2.map((elem, i) => {
        let amount = '-';
        if (!isEmptyOrUndefined(copy)) {
          amount = copy.length > 0 ? copy[i]?.amount : '-';
        }
        return pTableRenderElem(elem, i, amount);
      });
    }
    return elemMap;
  };
  const listElem = () => {
    let elemMap;
    if (arrArticles !== undefined) {
      const elem2 = arrArticles;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const isBoxSelected = elem => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id_article === elem.id_article);
      return !!target;
    }
  };
  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id_article === item.id_article);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id_article !== item.id_article);
      } else {
        let newChange = {
          ...item,
          amount_received: 0,
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const btnCreatMovement = () => {
    setmodalArtControl({ ...modalArtControl, showJustification: false });
    dispatch(
      creatMovement({
        wm_account: store.loginReducer.currentAccount.id,
        wm_justification: justification,
        wm_observations: details,
        created_by: store.loginReducer.user_data.id,
        wm_movement_type: 'warehouseTransfer',
        wm_origin: filters.id_warehouse1,
        wm_destination: filters.id_warehouse2,
        wm_status: 'legalized',
        articles: selectedList,
      }),
    );
  };
  const deleteItems = () => {
    let templist = [...selectedList];
    templist = templist.filter(fullelement => itemCheck.indexOf(fullelement.id_article) === -1);
    setselectedList(templist);
    setSaveList(templist);
  };
  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(arrArticles) && Array.isArray(selectedList)) {
      arrArticles.forEach(item => {
        if (!selectedList.find(itemb => item.id_article === itemb.id_article)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = selectedList;
      if (Array.isArray(arrArticles) && Array.isArray(tempSelected)) {
        arrArticles.forEach(x => {
          let found = selectedList.find(obj => obj.id_article === x.id_article);
          if (found?.id_article !== x.id_article) {
            let newChange = {
              ...x,
              amount_received: 0,
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      setselectedList([]);
    }
  };
  const handleItemsToAdd = id_article => {
    let temparray = deepcopy(selectedList);
    let target = temparray.findIndex(x => x.id_article === id_article);
    if (target >= 0) {
      temparray[target].lots = selectedList2;
      let newammount =
        selectedList2.length > 0 &&
        selectedList2.reduce((prev, current) => {
          return { qty_received: prev.qty_received + current.qty_received };
        });
      temparray[target].amount_received = newammount.qty_received;
      setselectedList(temparray);
      setCopy(temparray);
    }

    setSelectedList2([]);
    setmodalArtControl({
      ...modalArtControl,
      showLots: false,
    });
  };

  const renderTableLots = () => {
    let table = [];
    let amount_received = 0;
    let num_lots = 0;
    if (!isEmptyOrUndefined(arrOutputArticles)) {
      if (arrOutputArticles.length > 0) {
        table = arrOutputArticles.map((x, index) => {
          amount_received = amount_received + x.qty;
          num_lots = num_lots + 1;
          let foundlotvalue = selectedList2.find(item => item.id === x.id);
          return (
            <tr key={'lots' + index}>
              <td>{moment(x.date_expiration).format('DD/MM/YYYY')}</td>
              <td style={{ textTransform: 'uppercase' }}>{x.name}</td>
              <td>{foundlotvalue ? foundlotvalue.qty_received : x.qty_received}</td>
            </tr>
          );
        });
      }
    }
    return table;
  };

  const pTableRenderElem = (elem, i) => {
    return (
      <tr key={elem.code}>
        <td className='col-md-1 text-center'>
          <input
            key={'checkbox' + i + '-' + itemCheck.length}
            type='checkbox'
            checked={!!itemCheck.find(myitem => myitem === elem.id_article)}
            name=''
            id=''
            onChange={e => checkToDelete(e, elem, i)}
          />
        </td>
        <td className='col-md-1 text-center'>{i + 1}</td>
        <td className='col-md-2 text-center'>{elem.id_article}</td>
        <td className='col-md-2 text-start'>{elem.description_article}</td>
        <td className='cursorPointer'>
          <img src={Agregar} alt='btnAgregar' onClick={() => handleLots(elem)}></img>
        </td>
        <td className='col-md-2 text-center'>
          {elem.amount <= 0 ? (
            <input
              value='0'
              className='text-center text-secondary'
              disabled={true}
              style={{
                backgroundColor: 'none',
                borderWidth: '0px',
                background: 'none',
                border: '1px solid #01a0f6',
                borderRadius: '5px',
                width: '80%',
              }}
              name={'amount_received'}
            ></input>
          ) : (
            <input
              className='text-center text-secondary'
              style={{
                backgroundColor: 'none',
                borderWidth: '0px',
                background: 'none',
                border: '1px solid #01a0f6',
                borderRadius: '5px',
                width: '80%',
              }}
              onChange={e => handleChangeAmount(elem, e, i)}
              name={'amount_received'}
            ></input>
          )}
        </td>
        <td className='col-md-2 text-info text-center'>{elem.amount}</td>
      </tr>
    );
  };
  const checkToDelete = (e, elem) => {
    let checked = e.target.checked;

    let nueva = [...itemCheck];
    if (checked) {
      nueva.push(elem.id_article);
    } else {
      nueva = nueva.filter(x => x !== elem.id_article);
    }
    setItemCheck(nueva);
  };
  const handleChangeAmount = (elem, e) => {
    if (e.target.value > elem.amount) {
      e.target.value = elem.amount;
    }
    if (e.target.value === 0) {
      e.target.value = 1;
    }

    const re = /^[0-9\b]+$/;
    if (!(e.target.value === '' || re.test(e.target.value))) {
      e.target.value = 1;
    }
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.findIndex(x => x.id_article === elem.id_article);

      if (target >= 0) {
        tempSelected[target].amount_received = Math.max(1, e.target.value);
      } else {
        let newChange = {
          ...elem,
          amount_received: Math.max(1, e.target.value),
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const confirmation = () => {
    if (selectedList.length > 0) {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: 'Revise la información',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          if (selectedList.length > 0) {
            selectedList.forEach(e => {
              if (isEmptyOrUndefined(e.amount_received) || e.amount_received === 0) {
                setmodalArtControl({
                  ...modalArtControl,
                  showJustification: false,
                });
                return message(
                  'warning',
                  'Intenta de nuevo',
                  'Digite la cantidad a extraer para cada artículo y/o elimine los artículos no modificados',
                  undefined,
                  true,
                );
              }
              setmodalArtControl({
                ...modalArtControl,
                showJustification: true,
              });
            });
          }
        }
      });
    } else {
      return message(
        'warning',
        'Intenta de nuevo',
        'Debe seleccionar al menos un artículo',
        undefined,
        true,
      );
    }
  };
  const pTableIsBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(selectedList) && Array.isArray(itemCheck)) {
      selectedList.forEach(item => {
        if (!itemCheck.find(itemb => item.id_article === itemb)) {
          res = false;
        }
      });
    }
    return res;
  };

  const pTableCheckThemAll = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = [];
      if (Array.isArray(selectedList) && Array.isArray(tempSelected)) {
        selectedList.forEach(x => {
          let id = x.id_article;
          let newChange = id;
          tempSelected.push(newChange);
        });
        setItemCheck(tempSelected);
      }
    } else {
      setItemCheck([]);
    }
  };
  const renderHeadersPTable = [
    <th className='col-md-1' style={{ borderTopLeftRadius: '10px' }} key={1}>
      <input
        type='checkbox'
        name=''
        id=''
        checked={pTableIsBoxAllSelected()}
        onChange={() => pTableCheckThemAll(!pTableIsBoxAllSelected())}
      />
    </th>,
    <th className='col-md-1' key={2}>
      Item
    </th>,
    <th className='col-md-1' key={3}>
      Código
    </th>,
    <th className='col-md-6' key={4}>
      Descripción de articulo
    </th>,
    <th className='col-md-1' key={5}>
      Lote
    </th>,
    <th className='col-md-1' key={6}>
      Cantidad
    </th>,
    <th style={{ borderTopRightRadius: '10px' }} key={7}>
      Disponible
    </th>,
  ];
  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: '10px' }} className='px-2' key={1}>
      Código
    </th>,
    <th className='text-start' key={2}>
      Nombre
    </th>,

    <th style={{ borderTopRightRadius: '10px' }} key={3}>
      <input
        type='checkbox'
        name=''
        id=''
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
  ];
  const renderHeadersLots = [
    <th key={`Lots1`}>Vencimiento</th>,
    <th key={`Lots2`}>Lote</th>,
    <th key={`Lots3`}>Cantidad</th>,
  ];

  /* -------------------------- HANDLE CHANGE SECTION ------------------------- */
  const handleBack = () => {
    history.push('/compras/registrarMovimiento');
  };
  const handleClickEntrybyStorage = () => {
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
    dispatch(getArticlesInWarehouseTransfer(filters));
  };
  const handleBring = () => {
    let selListCopy = deepcopy(selectedList);
    setSaveList(selListCopy);
    setFillpTable(true);
    setmodalArtControl({
      ...modalArtControl,
      showSecond: false,
      show: false,
    });
  };
  const handleSearch = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
    });
  };
  return (
    <>
      {/* MODAL JUSTIFICACIÓN DE JUSTIFICACIÓN*/}
      <ModalNew
        title='Justificación'
        show={modalArtControl.showJustification}
        btnYesName={justification !== '' && 'Guardar'}
        btnNoName={'Cancelar'}
        size='400'
        btnYesEvent={justification !== '' ? () => btnCreatMovement() : null}
        btnYesDisabled={false}
        onHide={() => setmodalArtControl({ ...modalArtControl, showJustification: false })}
        btnNoEvent={() => setmodalArtControl({ ...modalArtControl, showJustification: false })}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>¿Porqué es necesario hacer este ajuste?</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          onChange={e => setJustification(e.target.value)}
          value={justification}
          style={{ height: '4rem' }}
          className={`register-inputs text-secondary`}
        ></textarea>
      </ModalNew>

      {/* /* ------------------------------- First modal articulos ------------------------------ */}
      <ModalNew
        size='700'
        btnYesEvent={selectedList.length > 0 ? () => handleBring() : null}
        title='Artículos'
        btnYesName={'Agregar'}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
      >
        <Row className='d-flex'>
          <Col xs={5}>
            <form onSubmit={e => handleSearch(e)}>
              <p className={tableStyles.crudModalLabel}>&nbsp;</p>
              <input
                placeholder='Escribir...'
                className='register-inputs text-secondary'
                name='id_consumption_center'
                type='text'
                value={search.search}
                onChange={e => setSearch({ ...search, search: e.target.value })}
              />
            </form>
          </Col>

          <Col xs={1} className={`pt-3`} style={{ 'align-self': 'end' }}>
            <div>
              <img
                src={Search}
                alt='Search icon'
                className={'cursorPointer'}
                height={24}
                onClick={e => handleSearch(e)}
              />
            </div>
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersFirst}>
          {listElem()}
        </GenericTableNew>

        <div className={`${paginationStyles.wrapper}`} style={localStyles.endPagination}>
          <p className={paginationStyles.paginationText}>
            Pag. {store.inventoryReducer.articles_transfer ? filters.page : ''}
            {' de '}
            {Math.ceil(store.inventoryReducer.articles_transfer_total / filters.perpage)
              ? Math.ceil(store.inventoryReducer.articles_transfer_total / filters.perpage)
              : ''}{' '}
            ({store.inventoryReducer.articles_transfer_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={store.inventoryReducer.articles_transfer_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </ModalNew>
      <ModalNew
        title={'Lotes'}
        show={modalArtControl.showLots}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        btnYesEvent={() => handleItemsToAdd(modalLotsControl.id)}
        btnNoEvent={() =>
          setmodalArtControl({
            ...modalArtControl,
            showLots: false,
          })
        }
        size='sz'
        onHide={() => {
          setmodalArtControl({
            ...modalArtControl,
            showLots: false,
          });
          setSelectedList2([]);
        }}
      >
        <div id='arriba' className={`${minModal.backgroundModal} ${minModal.paddingButton}`}>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <label className={`${tableStyles.subtitle} `}> Información del producto  </label>
          <div className='d-flex'>
            <div className={` ${tableStyles.ItemSmall}`}>
              <p className={'text-secondary'}>Producto</p>
              <input
                className='register-inputs text-secondary'
                name='auction_title'
                type='text'
                value={modalLotsControl.product}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* ============================= ROW 2 =================================== */}
        <div id='arriba' className={`${tableStyles.paddingButton}`}>
          <GenericTableNew headers={renderHeadersLots}>{renderTableLots()}</GenericTableNew>
          <div className={`${paginationStyles.wrapper}`} style={localStyles.endPagination}>
            <p className={paginationStyles.paginationText}>
              Pag. {store.inventoryReducer.outPutLotsArticles ? lotsFilters.page : ''}
              {' de '}
              {Math.ceil(store.inventoryReducer.lotsTotal / lotsFilters.perpage)
                ? Math.ceil(store.inventoryReducer.lotsTotal / lotsFilters.perpage)
                : ''}{' '}
              ({store.inventoryReducer.lotsTotal} encontrados)
            </p>
            <Pagination
              activePage={lotsFilters.page}
              itemsCountPerPage={lotsFilters.perpage}
              totalItemsCount={store.inventoryReducer.lotsTotal}
              pageRangeDisplayed={2}
              onChange={e => setLotsFilters({ ...lotsFilters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </ModalNew>

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={atrasIcon}
                className={`mr-2 mb-2 cursorPointer`}
                alt='icon'
              ></img>
              Traslado de almacén
              <img width='3.5%' src={grayOutPut} className={`ml-3`} alt='icon'></img>
            </h1>
          </Col>
        </Row>
        <Row className=' d-flex'>
          <Col xs={3} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>Almacén de origen</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={arrWh}
              onChange={e => {
                setFilters({ ...filters, id_warehouse1: e.value });
                setselectedList([]);
                setSaveList([]);
              }}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>Almacén de destino</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={arrWh}
              onChange={e => setFilters({ ...filters, id_warehouse2: e.value })}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Traer artículos</p>
            <input
              placeholder={'Buscar...'}
              onClick={() => handleClickEntrybyStorage()}
              className={` ${tableStyles.articleSearch} register-inputs cursorPointer`}
              name='requisition_date'
              type='text'
              disabled={filters.id_warehouse1 !== '' && filters.id_warehouse2 !== '' ? false : true}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>Fecha</p>
            <input
              disabled={true}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='date'
              defaultValue={date}
            />
          </Col>
          {fillpTable && (
            <Col xs={12} className='d-flex mt-2' style={{ justifyContent: 'flex-end' }}>
              <img
                width='1.5%'
                src={canecaGris}
                className='cursorPointer'
                alt='btnAgregar'
                onClick={e => deleteItems(e)}
              ></img>
            </Col>
          )}
          <Col xs={12}>
            {fillpTable ? (
              <>
                <GenericTableNew dark={true} headers={renderHeadersPTable}>
                  {pTableListElem()}
                </GenericTableNew>
                <Row className={`w-100 m-0`}>
                  <Col xs={9} className={`${tableStyles.clearBlueBg} px-3`}>
                    <p className={`${tableStyles.crudModalLabel} pt-2`}>Observaciones</p>
                    <textarea
                      onChange={e => setDetails(e.target.value)}
                      style={{ height: '3rem' }}
                      rows='10'
                      cols='10'
                      className={`register-inputs text-secondary`}
                    ></textarea>
                  </Col>
                </Row>

                <div className={`customTabs__Footer mt-5`}>
                  <Button onClick={() => confirmation()} className={tableStyles.btnPrimary}>
                    Guardar
                  </Button>
                  <Button variant='outline-primary' className={tableStyles.btnSecondary}>
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <SkeletonTemplate></SkeletonTemplate>
                <div className={`customTabs__Footer mt-5`}>
                  <Button className={tableStyles.btnPrimary} disabled={true}>
                    Guardar
                  </Button>
                  <Button
                    variant='outline-primary'
                    onClick={() => handleBack()}
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
