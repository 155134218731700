import { useEffect, useState } from 'react';
import Agregar from '../../assets/img/icons/agregarEspecialidad.svg';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import valeStyles from '../TableAccAccounts/AccAccounts_CSS.module.scss';
import Styles from '../../components/PayRollModule/Hiring/Contract.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { convertDateToLatinFormat } from '../../helpers/helpers';
import Loader from 'react-loader-spinner';
import { getProvision } from '../../actions/receiptOfInvoicesActions';

export const ReceivableAges = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'reFac',
  );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const recAgesWallet = store.invoiceReducer?.provision;

  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
    description: '',
    active: '',
    search: '',
    status: '',
  });

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/administracion/inicio');
    }

    dispatch(getProvision({ page: 1, perpage: 10, eaccount: idEnterprise }));
  }, [dispatch, filters.page, history, idEnterprise, myPermission?.read]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start' style={{ paddingLeft: '0.5rem' }}>
          {elem.name}
        </td>

        <td className='col-md-2 text-center'>{convertDateToLatinFormat(elem.start_days)}</td>
        <td className='col-md-2 text-center'>{convertDateToLatinFormat(elem.end_days)}</td>
        <td className='col-md-2 text-center' style={{ paddingLeft: '0.5rem' }}>
          {elem.deteriorationValue}%
        </td>
        <td className='col-md-1 text-center cursorPointer'>
          <div>
            <img
              src={Filtrar}
              alt='lupa'
              onClick={() => {
                history.push({
                  pathname: '/administracion/TMDeCartera/EdadesPorCobrar/nueva',
                  state: {
                    paramInfo: elem,
                    isEditing: true,
                  },
                });
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (recAgesWallet !== undefined) {
      const elem2 = recAgesWallet;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Nombre
    </th>,
    <th key={2} className='text-center'>
      &nbsp; Día de inicio
    </th>,
    <th key={3} className='text-center'>
      Día de fin
    </th>,
    <th key={4} className='text-center'>
      Porcentaje de deterioro
    </th>,
    <th key={8}>&nbsp;</th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);

    dispatch(getProvision({ ...filters, page: 1 }));
  };

  return (
    <>
      {store.invoiceReducer?.loadingProvision && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Edades de cartera por cobrar</h1>

          <Row>
            <Col className='d-block' xs={6}>
              <div className='pt-4'>
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={tableStyles.searchUsersAlt}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
            <Col
              xs={6}
              onClick={() => history.push('/administracion/TMDeCartera/EdadesPorCobrar/nueva')}
              className={`text-end `}
              style={{
                'align-self': 'end',
              }}
            >
              <span
                className={`text-decoration-none hoverPointer
                                ${valeStyles.buttonCreatCount}  ${Styles.hoverBtnAdd}`}
                style={{
                  width: 'max-content',
                }}
              >
                <label
                  style={{
                    'align-self': 'center',
                    paddingRight: '8px',
                    fontSize: '16px',
                  }}
                  className={`${Styles.labelNew} hoverPointer`}
                >
                  Nuevo parámetro
                </label>
                <img
                  src={Agregar}
                  alt='User'
                  className={``}
                  style={{
                    'align-self': 'center',
                  }}
                />
              </span>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.invoiceReducer?.recAgesWallet ? filters.page : ''}
              {' de '}
              {Math.ceil(store.invoiceReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.invoiceReducer.rowTotal / filters.perpage)
                : ''}{' '}
              ({store.invoiceReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.invoiceReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => {
                setFilters({ ...filters, page: e });
                dispatch(getProvision({ ...filters, page: e }));
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
