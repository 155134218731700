import { Component } from 'react';
import { connect } from 'react-redux';
import GoToIcon from '../../assets/img/icons/link.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';

import Pagination from 'react-js-pagination';
import { Link } from 'react-router-dom';
import 'reactjs-popup/dist/index.css';
import {
  cleanNiifAccountLinked,
  getAllPUCAccounts,
  getLocalAccounts,
  getNiifAccounts,
  getNiifByNumber,
} from '../../actions/AccountsActions';
import { PAGE, PERPAGE } from '../../helpers/constants';
import GenericTableNew from '../Layouts/GenericTableNew';
import valeStyles from './AccAccounts_CSS.module.scss';

class TableAccountingAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: 'Cuentas contables actuales',
      modal: false,
      editRecord: false,
      totalIndex: 0,
      addPuc: {
        id: '',
        code: '',
        newCode: '',
        description: '',
        niifLinked: '',
        niifDescription: '',
        uses_third: false,
        uses_cost_center: false,
        type: '',
        search: '',
        check_format: '',
        account_type: '',
        name_bank: '',
        num_count: '',
        entity_account: this.props.currentAccount.id,
      },
      filters: {
        name: '',
        account_type: '',
        search: '',
        active: false,
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        'Cuenta',
        'Nombre',
        'Tipo de Cta.',
        'Número de Cta.',
        'Formato de Cheque',
        'Último Cheque',
      ],
    };
  }

  componentDidMount() {
    setTimeout(async () => {
      await this.props.getAllPUCAccounts(this.state.filters);
    }, 1000);
    this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    if (this.props.allAccounts !== prevProps.allAccounts) {
      this.setState({
        allAccounts: this.props.allAccounts,
      });
    }
  }

  getInitialState = () => {
    this.setState({
      titleModal: 'Cuentas contables actuales',
      modal: false,
      editRecord: false,
      addPuc: {
        id: '',
        code: '',
        newCode: '',
        description: '',
        niifLinked: '',
        niifDescription: '',
        uses_third: false,
        uses_cost_center: false,
        type: '',
        search: '',
        check_format: '',
        account_type: '',
        name_bank: '',
        num_count: '',
        entity_account: this.props.currentAccount.id,
      },
      totalIndex: 0,
      filters: {
        search: '',
        name: '',
        account_type: '',
        active: '',
        perpage: PERPAGE,
        page: PAGE,
        eaccount: this.props.currentAccount.id,
      },
      headerState: [
        'Cuenta',
        'Nombre',
        'Tipo de Cta.',
        'Número de Cta.',
        'Formato de Cheque',
        'Último Cheque',
      ],
    });
  };

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getAllPUCAccounts(this.state.filters);
  }

  toggleStatus = account => {
    this.props.changeStatus(account);
  };

  onChangeForm = e => {
    var { name, value } = e.target;
    let { addPuc } = this.state;
    addPuc[name] = value;
    let { filters } = this.state;
    filters.account_type = value;
    this.setState({ addPuc });
  };

  // Search puc when user was typing
  onKeyUpSearch_name = e => {
    const value = e.target.value;
    let { filters } = this.state;
    filters.name = value;
    if (e.keyCode === 13) {
      filters.page = 1;
      //this.setState({ filters });
      this.props.getAllPUCAccounts(this.state.filters);
    } else {
      e.preventDefault();
    }
    this.setState({ filters });
  };

  toggleModal = () => {
    let { modal } = this.state;
    modal = !modal;
    this.props.cleanNiifAccountLinked();
    if (this.state.editRecord) {
      this.setState({ modal });
    }
    this.getInitialState();
    this.setState({ modal });
  };

  //Method to search when click in magnify lens
  handleSearch = () => {
    let { filters } = this.state;
    filters.page = 1;
    this.props.getAllPUCAccounts(this.state.filters);
  };

  irapuc = () => {
    const { history } = this.props;
    history.push('/administration/puc');
  };

  renderAccounts(account) {
    return (
      <tr key={`${account.id}${account.type}`} className='hover-table-row text-center align-middle'>
        <td className='col-md-1'>{account.complete_account} </td>
        <td className='col-md-3'>{account.description}</td>
        <td className='col-md-2'>
          {!!account.account_type && account.account_type !== 'None'
            ? account.account_type.replace(/_/g, ' ')
            : '-'}
        </td>
        <td className='col-md-2'>
          {!!account.num_count && account.num_count !== 'None' ? account.num_count : '-'}
        </td>
        <td className='col-md-2'>
          {!!account.check_format && account.num_count !== 'None'
            ? account.check_format.replace('_', ' ')
            : '-'}
        </td>
        <td className='col-md-2'>{'-'}</td>
      </tr>
    );
  }

  render() {
    let renderHeaders = this.state.headerState.map((header, ind) => {
      return (
        <th key={ind}>
          <div className={header === 'est.' || header === '' ? tableStyles.groupElems : ''}>
            {header}
          </div>
        </th>
      );
    });
    let renderAccounts = this.props.allAccounts
      ? this.props.allAccounts.map(account => {
          return this.renderAccounts(account);
        })
      : '';

    return (
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Cuentas contables actuales</h1>
          <div className=''>
            <div className={`flex-direction-row align-middle ${valeStyles.widtDiv}`}>
              <p className={`${valeStyles.titulo}`}>Tipo de cuenta</p>
              <select
                name='account_type'
                className={`${valeStyles.register_inputs_new}`}
                onChange={this.onChangeForm}
                value={this.state.addPuc.account_type}
              >
                <option value=''></option>
                <option value='Corriente'>Corriente</option>
                <option value='Fondos_de_inversión'>Fondos de inversión</option>
                <option value='Ahorros'>Ahorros</option>
                <option value='Fiduciarias'>Fiduciarias</option>
              </select>

              <input
                className={`${valeStyles.Search} ml-3`}
                type='text'
                onKeyUp={this.onKeyUpSearch_name}
              />
              <img
                src={SearchIcon}
                alt='buscar'
                className='icons-svg-top-table float-left'
                onClick={this.handleSearch}
              />

              <Link
                to='/administracion/puc'
                className={`text-decoration-none ${valeStyles.buttonCreatCount}`}
              >
                <p className={valeStyles.labelNew}>Ir a PUC</p>
                <img
                  src={GoToIcon}
                  alt='buscar'
                  className={`${valeStyles.sizeLink} ${valeStyles.btnNewHover}`}
                />
              </Link>
            </div>
          </div>

          <GenericTableNew dark={true} headers={renderHeaders}>
            {renderAccounts}
          </GenericTableNew>

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalIndexAccount ? this.state.filters.page : ''}
              {' de '}
              {Math.ceil(this.props.totalIndexAccount / this.state.filters.perpage)
                ? Math.ceil(this.props.totalIndexAccount / this.state.filters.perpage)
                : ''}{' '}
              ({this.props.totalIndexAccount} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalIndexAccount}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  currentAccount: state.loginReducer.currentAccount,
  allAccounts: state.pucReducer.allAccounts,
  niifAccounts: state.pucReducer.niifAccounts,
  localAccounts: state.pucReducer.localAccounts,
  totalIndexAccount: state.pucReducer.totalIndexAccount,
  totalIndexPuc: state.pucReducer.totalIndexPuc,
});

const mapDispatchToProps = dispatch => ({
  getAllPUCAccounts: filters => {
    dispatch(getAllPUCAccounts(filters));
  },
  getNiifAccounts: async filters => {
    await dispatch(getNiifAccounts(filters));
  },
  getLocalAccounts: async filters => {
    await dispatch(getLocalAccounts(filters));
  },
  getNiifByNumber: number => {
    dispatch(getNiifByNumber(number));
  },
  cleanNiifAccountLinked: () => {
    dispatch(cleanNiifAccountLinked());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableAccountingAccounts);
