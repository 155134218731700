import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import backArrow from '../assets/img/icons/ordBackArrow.svg';
import ordLeftTriangle from '../assets/img/icons/ordLeftTriangle.svg';
import iconPrint from '../assets/img/icons/iconPrint.svg';
import ordRightTriangle from '../assets/img/icons/ordRightTriangle.svg';
import ordWhiteBackArrow from '../assets/img/icons/ordWhiteBackArrow.svg';
import { useGetMethod } from '../Hooks/useFetch';
import { isEmptyOrUndefined, message } from '../helpers/helpers';
import { useSelector } from 'react-redux';
import { PdfViewer } from '../components/Layouts/PdfViewer';
export const RenderMedicalFormulasPdf = props => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  let { backAction, finalize, withPagination, title, pdfParams, doAfterException, goBack, cancel } =
    props;
  const formulaInfo = props;
  const [pdfFilters, setPdfFilters] = useState({
    activePage: 0,
  });
  const [back, setBack] = useState(backArrow);
  /* ----------------------------- FETCHS SECTION ----------------------------- */
  /* ----------------------------- GET ----------------------------- */
  const { results: medicationOrder, trigger: getPdfOrder } = useGetMethod();
  /* -----------------------------  ----------------------------- */
  useEffect(() => {
    if (
      (!isEmptyOrUndefined(pdfParams?.url) && !isEmptyOrUndefined(pdfParams?.objFilters)) ||
      (!isEmptyOrUndefined(pdfParams?.url) && !isEmptyOrUndefined(pdfParams?.id))
    ) {
      getPdfOrder({
        url: pdfParams.url,
        objFilters: pdfParams?.objFilters
          ? pdfParams?.objFilters
          : { id: Number(pdfParams?.id) || '', orderType: pdfParams?.ordTag || '' },
        // objFilters: { id: Number(pdfParams?.id) || "", orderType: pdfParams?.ordTag || "" } || props?.objFilters,
        token: token,
        doAfterSuccess: response => {
          if (response.status !== 200) {
            return message('warning', ' Advertencia', response.message);
          }
        },
        doAfterException: error => {
          doAfterException ? doAfterException(error) : backAction();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulaInfo.id, formulaInfo.url, getPdfOrder, pdfParams?.id, pdfParams.url, token]);

  let base64 =
    medicationOrder?.results?.length > 0
      ? medicationOrder?.results[pdfFilters.activePage]?.base64?.split("'")[1]
      : [];

  const paginate = op => {
    let limit = medicationOrder?.results?.length - 1;

    if (op === 'next') {
      pdfFilters.activePage !== limit &&
        setPdfFilters({ ...pdfFilters, activePage: pdfFilters.activePage + 1 });
    } else {
      pdfFilters.activePage >= 1 &&
        setPdfFilters({ ...pdfFilters, activePage: pdfFilters.activePage - 1 });
    }
  };
  const download = file => {
    // window.open(file);
    // window.open(file, "popup", "fullscreen=yes");

    //PARA EL BASE64 -- NO BORRAR
    let win = window.open();
    win.document.write(
      '<iframe src="' +
        file +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
    );
  };
  return (
    <>
      {/* {medicationOrderLoader && loader} */}

      <div className={`container mb-5`}>
        <Row className='justify-content-center mb-4 ml-5'>
          {title && (
            <Col xs={12}>
              <h1 className={tableStyles.ordDarkBlueText}>
                <img
                  onClick={() => backAction()}
                  src={backArrow}
                  className={`mr-2 mb-2 cursorPointer`}
                  alt='Atrás'
                ></img>
                {title || 'Información de la fórmula'}
              </h1>
            </Col>
          )}
        </Row>
        <div className=''>
          <div className='d-flex justify-content-center '>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              {goBack && (
                <div
                  className='d-flex justify-items-start align-items-start '
                  style={{
                    width: '49.6rem',
                    marginLeft: '5rem',
                  }}
                >
                  <img
                    onClick={() => backAction()}
                    src={backArrow}
                    className={`mr-2 mb-2 cursorPointer`}
                    alt='Atrás'
                    style={{
                      width: '15px',
                    }}
                  ></img>
                </div>
              )}

              <div
                className='mb-4 d-flex'
                style={{
                  width: '49.6rem',
                  marginLeft: '5rem',
                }}
              >
                <Col xs={2} className={`mb-2`}>
                  {!!withPagination && (
                    <Row>
                      <Col onClick={() => paginate('back')} xs={2}>
                        <img src={ordLeftTriangle} alt='Atrás' className={`cursorPointer`}></img>
                      </Col>
                      <Col
                        className={`
                                d-flex justify-content-center align-items-center
                                 ${tableStyles.selectedPdfPage} ${tableStyles.ordClearBlueText}`}
                        xs={1}
                      >
                        {pdfFilters.activePage + 1}
                      </Col>
                      <Col className={`text-secondary`} xs={1}>
                        /
                      </Col>
                      <Col className={`text-secondary`} xs={1}>
                        {medicationOrder?.results?.length || ''}
                      </Col>
                      <Col onClick={() => paginate('next')} xs={3}>
                        <img
                          src={ordRightTriangle}
                          alt='Adelante'
                          className={`cursorPointer`}
                        ></img>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col className={`d-flex justify-content-end`} xs={10}>
                  <img
                    src={iconPrint}
                    alt='imprimir'
                    onClick={() => download(`data:application/pdf;base64,${base64}`)}
                    width='25'
                    className={`cursorPointer`}
                  ></img>
                </Col>
              </div>

              {!isEmptyOrUndefined(base64) ? (
                <Col
                  className={` ${tableStyles.shade}`}
                  style={{
                    width: '49.6rem',
                    marginLeft: '5rem',
                  }}
                  xs={9}
                >
                  <PdfViewer
                    pdfWidth={790}
                    containerClass={`${tableStyles.w54}`}
                    file={`data:application/pdf;base64,${base64}`}
                  ></PdfViewer>
                </Col>
              ) : (
                'Sin información'
              )}
            </div>
            <Col className='justify-content-end ml-5'>
              &nbsp;
              {!goBack && (
                <button
                  onClick={() => backAction()}
                  className={`btn d-flex  px-5 ${tableStyles.ordBtnSecondary}`}
                  style={{ minWidth: '165px', maxWidth: '165px', height: '35px' }}
                  onMouseEnter={() => setBack(ordWhiteBackArrow)}
                  onMouseLeave={() => setBack(backArrow)}
                >
                  <img
                    className={`pt-1 ${back !== backArrow && 'p-0'} `}
                    src={back}
                    alt='Atrás'
                    width={back === backArrow ? '7px' : '15px'}
                  ></img>
                  &nbsp;
                  <span>Regresar</span>
                </button>
              )}
              &nbsp;
              {finalize && (
                <button
                  onClick={() => finalize?.finalizeAction()}
                  className={`btn d-flex  px-5 btn ${tableStyles.ordDarkBlueBtn}`}
                  style={{ minWidth: '165px', height: '35px' }}
                >
                  <span>
                    &nbsp;
                    {finalize?.title || 'Finalizar'}
                    &nbsp;
                  </span>
                </button>
              )}
              {cancel && (
                <button
                  onClick={() => cancel?.cancelAction()}
                  className={`btn d-flex btn ordBtnBar`}
                  style={{ minWidth: '165px', height: '35px', fontSize: '13.5px', width: '87%' }}
                >
                  <span>
                    &nbsp;
                    {cancel?.title || 'Cancelar ordenamiento'}
                    &nbsp;
                  </span>
                </button>
              )}
            </Col>
          </div>
        </div>
      </div>
    </>
  );
};
