import Pagination from 'react-js-pagination';
import Search from '../../../../assets/img/icons/lupa.svg';
import check from '../../../../assets/img/icons/check2.svg';
import GenericTableNew from '../../../Layouts/GenericTableNew';
import 'reactjs-popup/dist/index.css';
import '../../../TableUsers/tableUserStyle.scss';
import tableStyles from '../../../../components/Layouts//tableStyle.module.scss';
import paginationStyles from '../../../../components/Layouts//pagination.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { Grid } from '@mui/material';
import tableStyles2 from '../../../../components/Layouts/tableStyle.module.scss';
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import { convertMoneyFormat, optionsSelect, swalConfirm, valueSelect, formatteDocument } from '../../../../helpers';
import SelectComponent from '../../../SelectComponent/SelectComponent';
import { customSelectNewDark } from '../../../Layouts/react-select-custom';


export const Severances = ({
  dataPage,
  data,
  setData,
  severancePayrollResults,
  setEmployeeDetail,
  EmployeeDetail,
  jobPositionsOptions
}) => {
  const { filters, modal } = data;
  const renderElement = elem => {
    return (
      <tr key={elem?.severanceId}>
        <td className={`col-1 px-3 text-start`}>{formatteDocument(elem?.docNumber).format ?? '-'}</td>
        <td className='col-2 text-start'>
          <u
            className='cursorPointer'
            onClick={() => {
              setEmployeeDetail({
                ...EmployeeDetail,
                show: true,
                dataEmployee: elem,
                dataPayroll: dataPage,
                page: 'severance',
              });
            }}
          >
            {elem?.candidateFullName ?? '-'}
          </u>
        </td>
        <td className='col-2 text-center' style={{textWrap: 'balance'}}>{elem?.titleJob ?? '-'}</td>
        <td className='col-1 text-end'>{convertMoneyFormat(elem?.averageSalary ?? '-')}</td>
        <td className='col-2 text-center'>{elem?.totalDaysWork ?? '-'}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.severancePayment ?? '-')}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.severancePaymentInsc ?? '-')}</td>
        <td className='col-2 text-end px-3'>
          {convertMoneyFormat(
            (Number(elem?.severancePayment) + Number(elem?.severancePaymentInsc)).toFixed(2) ?? '-',
          )}
        </td>
      </tr>
    );
  };
  const renderHeaders = [
    <th key={1} className='text-start col-1 px-3'>
      ID
    </th>,

    <th key={2} className='text-start col-2'>
      Empleado
    </th>,

    <th key={8} className='text-center col-1'>
      Cargo
    </th>,

    <th key={3} className='text-end col-1'>
      Salario
    </th>,

    <th key={4} className='text-center col-2'>
      Días laborados
    </th>,

    <th key={5} className='text-end col-1'>
      Cesantías
    </th>,

    <th key={6} className='text-end col-2'>
      Cesantías INCS
    </th>,

    <th key={7} className='text-end col-2 px-3'>
      Total a pagar
    </th>,
  ];
  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        show: false,
        isEditing: false,
        denial: '',
      },
    }));
  };
  const validationRequest = () => {
    if (modal.denial) {
      setData(e => ({ ...e, modal: { ...modal, show: false } }));
      swalConfirm({
        icon: 'success',
        title: `Planilla denegada exitosamente`,
        text: `Se ha denegado la planilla: ${`${dataPage.detailPage.month} ${dataPage.detailPage.range} / ${dataPage.detailPage.year}`}`,
        confirmButtonText: `Aceptar`,

        doAfterConfirm: () => {
          dataPage.setDetailPage(null);

        },
      });
    }
  };

  return (
    <>
      <OrdModal
        title={modal.isEditing ? '' : 'Denegar planilla'}
        show={modal.show}
        btnYesName={modal.isEditing ? '' : 'Enviar'}
        btnNoName={'Cancelar'}
        size={550}
        onHide={() => closeModal()}
        btnYesEvent={() => validationRequest()}
        btnNoEvent={() => closeModal()}
        btnYesDisabled={!modal.denial}
        sizeBody={`${tableStyles2.modalHeight}`}
        titleClassName={tableStyles2.darkBlueText}
        btnYesStyle={modal.denial ? tableStyles2.btnYesStyle : tableStyles2.ordBtnPrimaryDisabled}
        btnCancelStyle={tableStyles2.btnCancelStyle}
        iconBtnYes
        iconBtnYesColor={tableStyles2.svgWhiteFilter}
        iconSrc={check}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <label className={tableStyles.labelTex}>Devengados</label>
            <textarea
              placeholder={'Escribir...'}
              rows='25'
              cols='55'
              style={{ height: '8rem' }}
              value={modal.denial}
              className={`text-secondary ord-roundInput w-100 focusBlueVisible`}
              onChange={e =>
                setData(state => ({ ...state, modal: { ...state.modal, denial: e.target.value } }))
              }
            ></textarea>
            <label className={tableStyles.labelText2}>
              *El mensaje será enviado al autor de la planilla
            </label>
          </Grid>
        </Grid>
      </OrdModal>
      <div>

        <Grid container spacing={3}>
          <Grid item xs={2}>
            <p className={tableStyles.crudModalLabel}>Días menores a</p>
            <input
              onChange={e =>
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, daysToPay: e.target.value },
                }))
              }
              type='number'
              value={filters.daysToPay}
              placeholder='Escribir...'
              className={`register-inputs`}
              name='position'
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <div className='d-flex flex-column'>
              <p className={tableStyles.crudModalLabel}>Cargo</p>
              <SelectComponent
                styles={customSelectNewDark}
                placeholder={'Seleccionar'}
                value={valueSelect({
                  list: jobPositionsOptions.results || [],
                  findId: 'id',
                  findLabel: 'job_title',
                  value: data.filters.idJob,
                })}
                onChange={e =>
                  setData(state => ({
                    ...state,
                    trigger: state.trigger + 1,
                    filters: {
                      ...state.filters,
                      idJob: e.value,
                    }
                  }))
                }
                options={optionsSelect({
                  list: jobPositionsOptions.results,
                  label: 'job_title',
                  value: 'id',
                })}
              />
            </div>
          </Grid>

          <Grid item xs={6} className='d-flex align-items-end justify-content-end mt-2'>
            <input
              className={`${tableStyles.SearchNew} mr-2`}
              style={{ flex: 1, color: `#73a6dc` }}
              name='search'
              type='text'
              placeholder='Buscar por ID, Empleado, Días laborados...'
              value={filters.search}
              onChange={e =>
                setData(state => ({
                  ...state,
                  filters: { ...state.filters, search: e.target.value },
                }))
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setData(state => ({ ...state, trigger: state.trigger + 1 }));
                }
              }}
            />

            <img
              src={Search}
              style={{ cursor: 'pointer' }}
              alt='Search icon'
              onClick={() => {
                setData(state => ({ ...state, trigger: state.trigger + 1 }));
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <GenericTableNew headers={renderHeaders} dark={true}>
              {severancePayrollResults?.results?.length > 0 &&
                severancePayrollResults?.results?.map(el => {
                  return renderElement(el);
                })}
            </GenericTableNew>
          </Grid>
          <Grid item xs={12}>
            <div className={paginationStyles.wrapper}>
              <p className={`${paginationStyles.paginationText} text-secondary`}>
                Pag. {severancePayrollResults?.results ? filters.page : ''}
                {' de '}
                {Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                  ? Math.ceil(severancePayrollResults?.rowTotal / filters.perpage)
                  : ''}{' '}
                ({severancePayrollResults?.rowTotal} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={severancePayrollResults?.rowTotal}
                pageRangeDisplayed={5}
                onChange={e =>
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, page: e },
                    trigger: state.trigger + 1,
                  }))
                }
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
