import { useState } from 'react';
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import backIcon from '../../../../assets/img/icons/atras-icon.svg';

import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import SurveyAnswers from './SurveyAnswers';
import SurveyPerf from './SurveyPerf';

import 'reactjs-popup/dist/index.css';
import tableStyles from '../../../Layouts/tableStyle.module.scss';

const DetailSurveyPerf = () => {
  const history = useHistory();
  const location = useLocation();

  const isEditing = location?.state?.action === 'edit' ? true : false;
  const detail = location?.state?.action === 'detail' ? true : false;
  const data = location?.state?.data;

  const [profile, setProfile] = useState(1);

  const goBack = () => {
    history.push('/nomina/EvaluacionDeDesempeño');
  };
  return (
    <>
      <OrdGenericTemplate
        showBackArrow={true}
        backArrowAction={() => {
          goBack();
        }}
        classIcon={`${tableStyles.svgFilterColorBlue}`}
        backIcon={backIcon}
        title={'Evaluación'}
        className='px-4 my-2 w-80'
        titleSize={10}
        titleStyle={{ fontSize: '32px' }}
      >
        <div style={{ marginLeft: '2rem' }}>
          <div className='simpleTabs__Container'>
            <TabContainer defaultActiveKey={profile}>
              <Nav className='flex-row simpleTabs'>
                <Nav.Item key={1}>
                  <Nav.Link eventKey={1} onClick={() => setProfile(1)} style={{ width: '200px' }}>
                    Preguntas
                  </Nav.Link>
                </Nav.Item>
                {detail && (data.status === 'in_progress' || data.status === 'completed') && (
                  <Nav.Item key={2}>
                    <Nav.Link eventKey={2} onClick={() => setProfile(2)} style={{ width: '200px' }}>
                      Respuestas
                    </Nav.Link>
                  </Nav.Item>
                )}
                <div style={{ flex: 1 }} className='simpleTabs__filler'></div>
              </Nav>
              <TabContent className='simpleTabs__relative' style={{ overflowY: 'unset' }}>
                <TabPane key={'panelTab_1'} eventKey={1} className='simpleTabs__Area'>
                  <SurveyPerf profile={profile} edit={isEditing} detailD={detail} />
                </TabPane>
                <TabPane key={'panelTab_2'} eventKey={2} className='simpleTabs__Area'>
                  <SurveyAnswers profile={profile} edit={isEditing} detailD={detail} data={data} />
                </TabPane>
              </TabContent>
            </TabContainer>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default DetailSurveyPerf;
