import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {
  let {
    leftTitle,
    rightTitle,
    setData,
    leftItems,
    rightItems,
    trigger,
    setLeftItems,
    setRightItems,
  } = props;
  const [checked, setChecked] = React.useState([]);
  const leftChecked = intersection(checked, leftItems);
  const rightChecked = intersection(checked, rightItems);

  React.useEffect(() => {
    setData({
      left: leftItems,
      right: rightItems,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftItems, rightItems]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRightItems(rightItems.concat(leftChecked));
    setLeftItems(not(leftItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftItems(leftItems.concat(rightChecked));
    setRightItems(not(rightItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  //HEY! FUNCTION RETURNS  THE RENDER OF EACH BOX IF YOU WANT ANOTHER CHECK-BOX BOX U HAVE TO MAKE ONE MORE GRID CALLING customList
  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items?.length && items?.length !== 0}
            indeterminate={numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0}
            disabled={items?.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} Seleccionados`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items?.map((e, index) => {
          const labelId = `transfer-list-all-item-${index}-label`;
          return (
            <ListItem
              key={`key${index}${trigger}`}
              role='listitem'
              button
              onClick={handleToggle(e)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(e) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${e.title}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    /* ---------------------------- Father container ---------------------------- */
    <Grid container spacing={2} justifyContent='center' alignItems='center' className='m-0 p-0'>
      {/* ---------------------------- Left container ---------------------------- */}
      <Grid
        style={{
          border: '1px solid #1a538d6e',
          borderRadius: '10px',
          padding: '0.1rem',
        }}
        item
      >
        {customList(leftTitle || 'Choices', leftItems)}
      </Grid>
      {/* /* ----------------- Here are the buttons to move every item ---------------- */}
      <Grid style={{ padding: '0.5rem' }} item>
        <Grid container direction='column' alignItems='center'>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label='move selected right'
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant='outlined'
            size='small'
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label='move selected left'
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      {/* ---------------------------- Right container ---------------------------- */}
      <Grid
        style={{
          border: '1px solid #1a538d6e',
          borderRadius: '10px',
          padding: '0.1rem',
        }}
        item
      >
        {/* /* ----------- here's a example of how to use customList function ----------- */}
        {customList(rightTitle || 'Chosen', rightItems)}
      </Grid>
    </Grid>
  );
}
