import tableStyles from '../../../../../components/Layouts/tableStyle.module.scss';
import iconClose from '../../../../../../src/assets/img/icons/modalClose.svg';
import countNotes from '../../../../../../src/assets/img/icons/countNotes.svg';

import { formatteDocument } from '../../../../../helpers';

import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../../../../helpers/numberFormatting';

import { isNullOrUndefined } from 'util';

import { Box, Drawer } from '@mui/material';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import OrdTable from '../../../../../OrderingModule/OrderingComponents/OrdTable';
import { Text } from '../../../../UI/atoms';

const localeSeparators = getLocaleSeparators()

const CashDetailDrawer = ({ isOpened, ccDetailData, onClose, onNotesDetail }) => {

  const headersWalletMovements = [
    {
      title: 'Paciente',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Identificación',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total vuelto',
      className: 'px-2 text-end col-4',
    },
  ];

  const headersCashClosing = [
    {
      title: 'Moneda',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Cantidad',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total',
      className: 'px-3 text-end col-4',
    },
  ];

  const formatWalletMovements = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-2`}>{item?.patientName || '-'}</td>

            <td className={`text-center px-2`}>
              <span>{item?.patientDocType || '-'}</span>&nbsp;
              <span>
                {formatteDocument(item?.patientDoc).format || '-'}
              </span>
            </td>

            <td className={`text-end px-2`}>
              {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataCashClosingDate = array => {
    const validateArray = array[0] === null ? [] : array;

    let tempList = [];
    if (Array.isArray(validateArray)) {
      const listCollections = [];

      ccDetailData?.results?.depositsData?.forEach(el => {
        if (el?.depositTypeLabel !== 'cash' && el?.depositType !== 'replacedDeposits') {
          listCollections?.push(el);
        }
      });
      let resFull = [];

      if (listCollections?.length && validateArray?.length) {
        resFull = [...listCollections, ...validateArray];
      } else if (listCollections?.length) {
        resFull = [...listCollections];
      } else if (validateArray?.length) {
        resFull = [...validateArray];
      }

      resFull?.forEach((item, index) => {
        const type = !isNullOrUndefined(item?.appsCount)
          ? item?.depositTypeName
          : item?.depositTypeName === 'transfer'
            ? 'Transferencia'
            : item?.depositTypeName === 'card'
              ? 'Tarjeta de crédito'
              : 'Efectivo';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              {item?.depositTypeName ? (
                type
              ) : (
                <>
                  {item?.cdType === 'cash' ? 'Billete de ' : 'Moneda de '}
                  {formatNumberWithoutDecimals(item?.cdValue)}
                </>
              )}
            </td>

            <td className='text-center px-2'>
              <span
                className={`${!isNullOrUndefined(item?.appsCount) ? `pointer text-decoration-underline ${tableStyles.ordAquaMarineText}` : ''}`}
                onClick={() => { onClose() }}
              >
                {item?.cdQuantity || item?.appsCount || '-'}
              </span>
            </td>

            <td className='text-end px-3'>
              {item?.cdValue
                ? formatNumberWithoutDecimals(Number(item?.cdValue) * Number(item?.cdQuantity))
                : item?.depositTypeAmount ? formatNumberWithoutDecimals(item?.depositTypeAmount) : '-'
              }
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  return (
    <Drawer
      anchor={'right'}
      open={isOpened}
      onClose={() => onClose() }
    >
      <Box padding={3}>
        <Box mb={2}>
          <img
            className={`${tableStyles.closeDrawerAsistencial} pointer`}
            src={iconClose}
            alt='close'
            onClick={() => onClose()}
            height={'28px'}
            width={'28px'}
          />
        </Box>

        <Box paddingX={4}>
          <Box>
            <Box className='d-flex' justifyContent={'space-between'} alignItems={'center'}>
              <Box
                fontSize={'36px'}
                className={`${tableStyles.ordDarkBlueText} fw-bold`}
                mb={0}
              >
                Conteo general
              </Box>

              <OverlayTrigger
                flip
                shouldUpdatePosition={true}
                placement='top'
                delay={{ show: 150, hide: 150 }}
                overlay={
                  <Tooltip
                    id='button-tooltip-2'
                    style={{ zIndex: '9999999' }}
                    className={`d-inline-flex ${tableStyles.darkerGrayText}`}
                  >
                    <span>({ccDetailData?.results?.dailyNotes?.length}) </span>
                    <span>
                      {ccDetailData?.results?.dailyNotes?.length > 1
                        ? 'Notas'
                        : 'Nota'}
                    </span>
                  </Tooltip>
                }
              >
                <div
                  className='pointer d-flex position-relative'
                  onClick={() => onNotesDetail()}
                >
                  <img
                    alt='notes'
                    height={22}
                    src={countNotes}
                    width={22}
                    className={`${tableStyles.filterOrdAquaMarine}`}
                  />
                  <span className={`${tableStyles.textCountNotes2} ${tableStyles.filterWhite}`}>
                    {ccDetailData?.results?.dailyNotes?.length || 0}
                  </span>
                </div>
              </OverlayTrigger>
            </Box>

            <Text title='Recaudos en efectivo' classNameBox='mb-1' />
          </Box>

          <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
            <Box className='tableScroll'>
              <OrdTable
                className='mt-0'
                headers={headersCashClosing}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {formatDataCashClosingDate(
                  ccDetailData?.results?.depositsDetail
                    ?.filter(el => el?.depositTypeLabel !== 'cash')
                    .concat(ccDetailData?.results?.cashDetail) || [],
                )}
              </OrdTable>
            </Box>

            <Box className='tableScroll my-4'>
              <Text title='Transferencias a bolsillo' classNameBox='mb-1' />

              <OrdTable
                className='mt-0'
                headers={headersWalletMovements}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
              >
                {formatWalletMovements(ccDetailData?.results?.walletMovements) ||
                  []}
              </OrdTable>
            </Box>

            <Box className='ms-1 animate__animated animate__fadeIn mt-auto'>
              <Box
                borderRadius={3}
                paddingX={3}
                paddingY={2}
                bgcolor={'#F5FCFD'}
                className='d-flex gap-3 mt-4'
              >
                <Box>
                  <Text title='Total contado' fontSize={'12px'} classNameBox={'ms-1'} />
                  <NumberFormat
                    disabled
                    allowNegative={false}
                    className={`register-inputs ${tableStyles.inputPlaceholder}`}
                    placeholder='$...'
                    style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                    isNumericString={true}
                    value={ccDetailData?.results?.userTotalAmount || 0}
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    format={(valueStr) => {
                      if (!valueStr) return ''

                      return formatNumberWithoutDecimals(valueStr)
                    }}
                    allowLeadingZeros={false}
                  />
                </Box>

                <Box>
                  <Text title='Total en el sistema' fontSize={'12px'} classNameBox={'ms-1'} />
                  <NumberFormat
                    disabled
                    allowNegative={false}
                    className={`register-inputs ${tableStyles.inputPlaceholder}`}
                    placeholder='$...'
                    style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                    isNumericString={true}
                    value={ccDetailData?.results?.systemTotalAmount || 0}
                    thousandSeparator={localeSeparators.groupSeparator}
                    decimalSeparator={localeSeparators.decimalSeparator}
                    format={(valueStr) => {
                      if (!valueStr) return ''

                      return formatNumberWithoutDecimals(valueStr)
                    }}
                    allowLeadingZeros={false}
                  />
                </Box>

                <Box>
                  <Text title='Diferencia' fontSize={'12px'} classNameBox={'ms-1'} />
                  <input
                    disabled
                    type='text'
                    className={`register-inputs ${tableStyles.inputPlaceholder}`}
                    style={{
                      maxHeight: '32px',
                      height: '32px',
                      borderColor: '#A3BAD1',
                      color:
                        Number(ccDetailData?.results?.userTotalAmount) -
                          Number(ccDetailData?.results?.systemTotalAmount) <
                        0
                          ? '#F39682'
                          : '#58595b',
                    }}
                    value={`${
                      formatNumberWithoutDecimals(
                        Number(ccDetailData?.results?.userTotalAmount) -
                        Number(ccDetailData?.results?.systemTotalAmount)
                        > 0
                        ? 0
                        : Number(ccDetailData?.results?.userTotalAmount) -
                        Number(ccDetailData?.results?.systemTotalAmount)
                      )}`
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default CashDetailDrawer
