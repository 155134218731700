import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

import Question from '../../assets/img/icons/question.svg';
import topArrow from '../../assets/img/icons/topArrow.svg';

import { getCorporateClients } from '../../actions/corporateClientsActions';
import {
  getAccToPayByProv,
  getBalanceInAcc,
  getCPXRotation,
  getDistributionOfBankBalances,
  getProfitBalance,
  getTotalAccToPay,
} from '../../actions/managementActions';
import { getNiifAccounts } from '../../actions/pucActions';
import {
  donutOptions,
  doubleLineAreaOptions,
  highChartVerticalLines,
  singleLineOptions,
} from '../../helpers/constants';
import { day, daysOfTheMonth, formatToRcSelect, month, year } from '../../helpers/helpers';
import { customSelectNew } from '../Layouts/react-select-custom';

import 'reactjs-popup/dist/index.css';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

export const TreasuryReport = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'billng',
  );
  const consum = store?.managementReducer?.consum;
  const idEnterprise = store.loginReducer?.currentAccount.id;
  let balanceInAccount = store?.managementReducer?.balanceInAccount;
  let totalAccToPay = store?.managementReducer?.totalAccToPay;
  let profitBalance = store?.managementReducer?.profitBalance;
  let profitBalanceSeries = profitBalance?.series;
  let profitBalanceLabels = profitBalance?.labels;

  let distributionOfBankBalances = store?.managementReducer?.distributionBankBalance;
  let accToPayByProv = store?.managementReducer?.accToPayByProv;

  let corporateClientList = store?.corporateclientsReducer?.corporateClients;
  let formattedClients = formatToRcSelect(corporateClientList, 'id', 'copyName');
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 15000);
  }, [showButton]);
  let CPXRotation = store?.managementReducer?.cpxRotationList;
  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
    day: day(),
  });
  const [filtersAccToPay, setFiltersAccToPay] = useState({
    period: '',
    provider: '',
    month: month(),
    year: year(),
    day: day(),
  });
  const [filtersCashInAcc, setFiltersCashInAcc] = useState({
    month: month(),
    year: year(),
    day: day(),
  });
  const [filtersBalance, setFiltersBalance] = useState({
    month: month(),
    year: year(),
    day: day(),
    niifAccount: '',
  });

  const [filtersRotation, setFiltersRotation] = useState({
    year: year(),
    eaccount: idEnterprise,
  });

  useEffect(() => {
    dispatch(
      getCorporateClients({
        id_account: store.loginReducer.currentAccount.id,
      }),
    );
  }, [dispatch, store.loginReducer.currentAccount.id]);

  let formatter = new Intl.NumberFormat(APP_LANGUAGE, {
    style: 'currency',
    currency: CURRENCY,
  });

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/gerencia/inicio');
    }
    dispatch(
      getAccToPayByProv({
        account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
        day: filters.day,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    dispatch(
      getNiifAccounts({
        eaccount: store.loginReducer.currentAccount.id,
        check_info_bank: 1,
      }),
    );
  }, [dispatch, store.loginReducer.currentAccount.id]);

  useEffect(() => {
    dispatch(
      getTotalAccToPay({
        period: filtersAccToPay.period,
        provider: filtersAccToPay.provider,
        year: filtersAccToPay.year,
        month: filtersAccToPay.month,
        day: filtersAccToPay.day,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersAccToPay, filters]);

  useEffect(() => {
    dispatch(
      getBalanceInAcc({
        account: store.loginReducer.currentAccount.id,
        month: filtersCashInAcc.month,
        year: filtersCashInAcc.year,
        day: filtersCashInAcc.day,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCashInAcc]);

  useEffect(() => {
    dispatch(
      getProfitBalance({
        account: store.loginReducer.currentAccount.id,
        month: filtersBalance.month,
        year: filtersBalance.year,
        day: filtersBalance.day,
        niifAccount: filtersBalance.niifAccount,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersBalance]);

  useEffect(() => {
    dispatch(
      getDistributionOfBankBalances({
        month: month(),
        year: year(),
        day: day(),
        entity_account: idEnterprise,
      }),
    );
  }, [dispatch, idEnterprise]);

  useEffect(() => {
    dispatch(
      getCPXRotation({
        eaccount: store.loginReducer.currentAccount.id,
        year: filtersRotation.year,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersRotation.year]);

  let optionsNiif = [];
  if (Array.isArray(store.pucReducer.niifAccounts)) {
    store.pucReducer.niifAccounts.forEach(item => {
      optionsNiif.push({
        value: item.id,
        label: item.complete_account + ' - ' + item.description,
        key: item.id + 'niff',
      });
    });
  }
  let seriesConsum = [];
  let categoriesConsum = [];
  let seriesOutput = { data: [], labels: [] };

  consum?.results?.length > 0 &&
    consum?.results?.forEach(e => {
      seriesConsum.push({
        name: e.description,
        data: [e.current_balance, e.annual_budget],
      });
      seriesOutput.data.push(e.total_spent);
      seriesOutput.labels.push(e.description);

      categoriesConsum.push(e.description);
    });
  const getFivePreviousYear = () => {
    let array = [];
    let current_year = year();
    for (let step = 0; step <= 5; step++) {
      array.push(current_year - step);
    }
    return array;
  };
  return (
    <>
      {showButton && (
        <a
          width='25px'
          className={`${tableStyles.noTextDecoration} ${tableStyles.backToTop} p-3 center`}
          href='#home'
        >
          <img className={`center`} src={topArrow} alt=''></img>
        </a>
      )}
      <div
        className={`${tableStyles.container}`}
        style={{ overflowX: 'hidden' }}
        onScroll={() => setShowButton(true)}
      >
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={7} className={``} id='home'>
              <h1 className={tableStyles.title}>Indicadores de tesorería</h1>
            </Col>
            <Col
              xs={5}
              className={`text-end d-flex justify-content-between`}
              style={{ paddingTop: '5rem' }}
            >
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#AccToPay'>
                  <b>Cuentas por pagar</b>
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#CashInAcc'>
                  <b>Saldos en cuentas</b>{' '}
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Balance'>
                  <b>Balance</b>{' '}
                </a>
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row className='justify-content-between mb-2' id='AccToPay'>
            <Col xs={4} className=' text-start'>
              <h3 className={tableStyles.darkBlueText}>
                <b>Cuentas por pagar</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filters.month}
                onChange={e => setFilters({ ...filters, month: Number(e.target.value) })}
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>
              <select
                value={filters.year}
                onChange={e => setFilters({ ...filters, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map((e, i) => {
                  return (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={5}>
              <Row className='mb-4'>
                <Col xs={12}>
                  <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                    <div className={` text-start`}>
                      <div>
                        <p className={`${tableStyles.darkGrayText}`}>
                          <span>Total de cuentas por pagar</span>
                          <br></br>
                          <b>{formatter.format(totalAccToPay?.total_por_pagar)}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                    <div className={` text-start`}>
                      <div>
                        <p className={`${tableStyles.darkGrayText}`}>
                          <b>Cuentas por pagar por proveedor</b>
                        </p>

                        {accToPayByProv?.data?.length > 0 && accToPayByProv?.labels?.length > 0 ? (
                          <Chart
                            options={donutOptions({
                              labels:
                                accToPayByProv?.labels?.length > 0 ? accToPayByProv.labels : [],
                            })}
                            series={accToPayByProv?.data?.length > 0 ? accToPayByProv.data : []}
                            type='donut'
                            // width={400}
                            height={1100}
                          />
                        ) : (
                          <Chart
                            options={donutOptions({
                              labels: ['Cargando...'],
                              colors: ['gray'],
                            })}
                            series={[50]}
                            type='donut'
                            height={900}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {/* /* ---------------------------- ---------------------------- */}
            <Col xs={7}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={5}>
                    <label className={tableStyles.crudModalLabel}>Proveedor</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      options={formattedClients}
                      onChange={e =>
                        setFiltersAccToPay({
                          ...filtersAccToPay,
                          client: e.value,
                        })
                      }
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                  <Col xs={5}>
                    <label className={tableStyles.crudModalLabel}>Periodo</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      onChange={e =>
                        setFiltersAccToPay({
                          ...filtersAccToPay,
                          period: e.value,
                        })
                      }
                      options={[
                        { value: 3, label: 'Ultimos 3 meses' },
                        { value: 6, label: 'Ultimos 6 meses' },
                        { value: 12, label: 'Ultimos 12 meses' },
                      ]}
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>
                        Cuentas por pagar vs cuentas pagadas
                        <img src={Question} style={{ width: '13px' }} alt=''></img>
                      </b>
                    </p>
                    <Chart
                      options={doubleLineAreaOptions({
                        colors: ['#005DBF', '#FF8B00'],
                        leftTitle: '\n',
                        bottomTitle: 'Meses',
                        categories:
                          totalAccToPay?.categories?.length > 0 ? totalAccToPay?.categories : [],
                      })}
                      series={totalAccToPay?.series?.length > 0 ? totalAccToPay?.series : []}
                      type='area'
                      height={460}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={12}>
                    <label className={tableStyles.crudModalLabel}>Periodo</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      onChange={e =>
                        setFiltersRotation({
                          ...filtersRotation,
                          year: e.value,
                        })
                      }
                      options={getFivePreviousYear().map(e => {
                        return { value: e, label: e };
                      })}
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Rotación de cuentas por pagar proveedores</b>
                    </p>
                    <Chart
                      options={singleLineOptions({
                        leftTitle: ' Días',
                        bottomTitle: 'Meses',
                        categories:
                          CPXRotation?.results?.labels?.length > 0
                            ? CPXRotation?.results?.labels
                            : [],
                      })}
                      series={
                        CPXRotation?.results?.series?.length > 0 ? CPXRotation?.results?.series : []
                      }
                      type='line'
                      height={350}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4' id='CashInAcc'>
            <hr></hr>
            <h3 className={`${tableStyles.darkBlueText} ml-2`}>
              <b>Saldos en cuentas</b>
            </h3>
            <Row className='justify-content-end mb-2'>
              <Col xs={2} className=' text-end'>
                <select
                  value={filtersCashInAcc.month}
                  onChange={e =>
                    setFiltersCashInAcc({
                      ...filtersCashInAcc,
                      month: Number(e.target.value),
                    })
                  }
                  placeholder={'Mes'}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>
              </Col>
              <Col xs={1} className=' text-end'>
                <select
                  value={filtersCashInAcc.year}
                  onChange={e =>
                    setFiltersCashInAcc({
                      ...filtersCashInAcc,
                      year: Number(e.target.value),
                    })
                  }
                  placeholder={'Año'}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
              </Col>

              <Col xs={1} className=''>
                <select
                  value={filtersCashInAcc.day}
                  onChange={e =>
                    setFiltersCashInAcc({
                      ...filtersCashInAcc,
                      day: Number(e.target.value),
                    })
                  }
                  style={{ width: '70%' }}
                  placeholder={'Día'}
                  className={tableStyles.simpleDropDown}
                >
                  {daysOfTheMonth.map((e, i) => {
                    return (
                      <option key={i} value={e.value}>
                        {e.label}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col xs={5}>
                <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                  <div className={` text-start`}>
                    <div>
                      <p className={`${tableStyles.darkGrayText}`}>
                        <span>Total actual de saldo en cuentas</span>
                        <br></br>
                        <b>{formatter.format(totalAccToPay?.total_por_pagar)}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                  <div className={` text-start`}>
                    <div>
                      <p className={`${tableStyles.darkGrayText}`}>
                        <b>Distribución de saldos en bancos</b>
                      </p>

                      {distributionOfBankBalances?.labels?.length > 0 &&
                      distributionOfBankBalances?.data?.length > 0 ? (
                        <Chart
                          options={donutOptions({
                            labels:
                              distributionOfBankBalances?.labels?.length > 0
                                ? distributionOfBankBalances.labels
                                : [],
                          })}
                          series={
                            distributionOfBankBalances?.data?.length > 0
                              ? distributionOfBankBalances.data
                              : []
                          }
                          type='donut'
                          height={380}
                        />
                      ) : (
                        <Chart
                          options={donutOptions({
                            labels: ['Cargando...'],
                            colors: ['gray'],
                          })}
                          series={[50]}
                          type='donut'
                          height={900}
                        />
                      )}

                      <Col xs={12}>
                        <div
                          className={`d-flex`}
                          style={{
                            borderBottom: '1px solid  #CECECE',
                            paddingBottom: '8px',
                            marginTop: '8px',
                          }}
                        >
                          <div className='col-8'>
                            <p
                              className={`m-0`}
                              style={{
                                textAlign: 'start',
                                color: '#005DBF',
                                fontWeight: '700',
                                fontSize: '14px',
                              }}
                            >
                              Banco
                            </p>
                          </div>
                          <div className='col-4'>
                            <p
                              className={`m-0`}
                              style={{
                                textAlign: 'center',
                                color: '#005DBF',
                                fontWeight: '700',
                                fontSize: '14px',
                              }}
                            >
                              {' '}
                              Saldo
                            </p>
                          </div>
                        </div>
                        {distributionOfBankBalances?.table?.map((x, realindex) => (
                          <div
                            className={`d-flex`}
                            key={'table' + realindex}
                            style={{
                              borderBottom: '1px solid  #CECECE',
                              paddingBottom: '8px',
                              paddingTop: '8px',
                            }}
                          >
                            <div className='col-8'>
                              <p
                                className={`m-0`}
                                style={{
                                  textAlign: 'start',
                                  color: '#58595B',
                                  fontSize: '12px',
                                }}
                              >
                                {' '}
                                {x.label}{' '}
                              </p>
                            </div>
                            <div className='col-4'>
                              <p
                                className={`m-0`}
                                style={
                                  x.amount > 0
                                    ? {
                                        textAlign: 'end',
                                        color: '#58595B',
                                        fontSize: '12px',
                                      }
                                    : {
                                        textAlign: 'end',
                                        color: '#EC1C24',
                                        fontSize: '12px',
                                      }
                                }
                              >
                                {' '}
                                {formatter.format(x.amount)}{' '}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>{' '}
              {/* /* ---------------------------- VS ---------------------------- */}
              <Col xs={7}>
                <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                  <div className={`text-start justify-content-between `}>
                    <div>
                      <p className={`${tableStyles.darkGrayText}`}>
                        <b>Saldo por cuentas</b>
                      </p>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartVerticalLines({
                          seriesName: 'Saldo en cuenta',
                          series:
                            balanceInAccount?.series?.length > 0 ? balanceInAccount.series : [],
                          inverted: true,
                          xAxisVisible: true,
                          yAxisVisible: true,
                          height: 560,
                        })}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
          {/* /* ------------------------------------ - ----------------------------------- */}
          <Row className='justify-content-between mb-2' id='Balance'>
            <Col xs={9} className=' text-start'>
              <h3 className={tableStyles.darkBlueText}>
                <b>Balance de ingresos y gastos</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filtersBalance.year}
                onChange={e =>
                  setFiltersBalance({
                    ...filtersBalance,
                    year: Number(e.target.value),
                  })
                }
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map((e, i) => {
                  return (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={3}>
                    <label className={tableStyles.crudModalLabel}>Cuenta bancaria</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      onChange={e =>
                        setFiltersBalance({
                          ...filtersBalance,
                          niifAccount: e.value,
                        })
                      }
                      options={optionsNiif}
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>

                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>Balance de ingresos y gastos</b>
                  </p>
                  <Chart
                    options={doubleLineAreaOptions({
                      colors: ['#00A551', '#FF8B00'],
                      leftTitle: '\n',
                      bottomTitle: 'Meses',
                      categories: profitBalanceLabels?.length > 0 ? profitBalanceLabels : [],
                      legendPosition: 'top',
                    })}
                    series={profitBalanceSeries?.length > 0 ? profitBalanceSeries : []}
                    type='area'
                    height={350}
                  />
                </div>
              </div>
            </Col>
          </Row>{' '}
        </div>
      </div>
    </>
  );
};
