import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import { RenderMedicalFormulasPdf } from '../../AsistanceModule/RenderMedicalFormulasPdf';
import { useGetMethod, useMultiGetMethod } from '../../Hooks/useFetch';
import CloneIconClearBlue from '../../assets/img/icons/CloneIconClearBlue.svg';
import Menos from '../../assets/img/icons/Menos.svg';
import Lupa2 from '../../assets/img/icons/NewLupa.svg';
import Lupa from '../../assets/img/icons/OrdLupa.svg';
import alertIcon from '../../assets/img/icons/alert-circle-orange.svg';
import check2 from '../../assets/img/icons/check2.svg';
import flecha from '../../assets/img/icons/desplegarGris.svg';
import justificationIcon from '../../assets/img/icons/justification.svg';
import pdfIcon from '../../assets/img/icons/pdfBlue.svg';
import redCloseX from '../../assets/img/icons/redcloseX.svg';
import searchBlueIcon from '../../assets/img/icons/searchBlueIcon.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { downloaFileAddName, formatToRcSelect, loader, formatteDocument} from '../../helpers';
import FullScreenDialog from '../OrderingComponents/FullScreenDialog';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingComponents/OrdModal';
import { OrdSlideDownButton } from '../OrderingComponents/OrdSlideDownButton';
import OrdTable from '../OrderingComponents/OrdTable';
import ordComponentStyles from '../OrderingComponents/ordComponentStyles.module.scss';

export const ServiceHistory = () => {
  let history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;
  const idCurrentAccount = store.loginReducer.currentAccount.id;
  const {
    results: servicesHistory,
    load: servicesHistoryLoader,
    trigger: getServicesHistory,
  } = useGetMethod();
  const { results: unities, load: unitiesLoader, trigger: getUnities } = useMultiGetMethod();
  const { load: servicesOrderLoader, trigger: getPdfOrder } = useGetMethod();
  const [trigger, setTrigger] = useState(0);
  const [rotate, setRotate] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: idCurrentAccount,
  });
  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    id: '',
    ordTag: '',
  });
  const [justification, setJustification] = useState({
    showModal: false,
    justificationText: '',
  });
  const doctorOptions = formatToRcSelect(
    unities?.doctors?.results,
    'idDoctor',
    'nameDoctor',
    '',
    '',
    '',
  );
  const contractsOptions = formatToRcSelect(
    unities?.contracts?.results,
    'id',
    'contractName',
    '',
    '',
    '',
  );
  const companiesOptions = formatToRcSelect(
    unities?.company?.results,
    'idCorporateClient',
    'nameCorporateClient',
    '',
    '',
    '',
  );
  const statusOptions = formatToRcSelect(unities?.status?.results, 'id', 'description', '', '', '');
  const sitesOptions = formatToRcSelect(unities?.sites?.data, 'id', 'description', '', '', '');
  const buttons = [
    {
      button: <img alt='btn' src={Lupa}></img>,
    },
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={flecha}
        ></img>
      ),
    },
  ];
  const renderTooltipStatusIcon = statusV => <Tooltip>{statusV}</Tooltip>;
  const renderTooltipDoctor = ({ item }) => (
    <Tooltip>
      <span>
        CC {item.doctor.document} <br></br>RM {item.doctor.medicalRecord}
      </span>
    </Tooltip>
  );

  const renderTooltipSite = ({ item }) => (
    <Tooltip>
      <span>{item?.site?.name}</span>
    </Tooltip>
  );

  const downloadPdf = (id, ortTag, patientName) => {
    getPdfOrder({
      url: '/medical/pdfserviceOrder/',
      objFilters: { id: id, orderType: ortTag },
      token: token,
      doAfterSuccess: res => {
        if (res.success) {
          for (let i of res.results) {
            let name =
              i?.orderType === 'presurgicalExams'
                ? `Exámenes prequirurgicos - ${patientName}`
                : i.orderType === 'preanesthetic '
                  ? `Valoración preanestésica`
                  : `Formula de servicios - ${patientName} `;
            downloaFileAddName(i?.base64, name);
          }
        }
      },
    });
  };
  const searchButton = () => {
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  const headers = [
    {
      title: 'Identificación',
      className: 'px-2 text-start',
    },
    {
      title: 'Paciente',
      className: 'px-2 text-start',
    },
    {
      title: 'Contrato',
      className: 'px-2 text-start',
    },
    {
      title: 'Médico',
      className: 'px-2 text-start',
    },
    {
      title: 'Sede',
      className: 'px-2 text-center',
    },
    {
      title: 'Fecha de creación',
      className: 'px-2 text-center',
    },
    {
      title: 'Paciente recibe',
      className: 'px-2 text-center',
    },
    {
      title: 'Estado',
      className: 'px-2 text-center',
    },
    {
      title: <>ㅤ</>,
    },
  ];

  // --- GET SERVICES HISTORY-----
  useEffect(() => {
    getServicesHistory({
      url: '/medical/serviceOrder/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // --- MULTI GET FILTERS VALUE-----
  useEffect(() => {
    getUnities({
      multipleGet: [
        {
          url: '/medical/generals/doctors/',
          requestName: 'doctors',
        },
        {
          url: '/site/',
          objFilters: { id_account: idEnterprise },
          requestName: 'sites',
        },
        {
          url: '/medical/generals/company/',
          requestName: 'company',
        },
        {
          url: '/medical/medicine/getContract/',
          objFilters: { slim: 1 },
          requestName: 'contracts',
        },
        {
          url: '/medical/status/service_order/',
          requestName: 'status',
        },
      ],
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        let statusV = item?.status;
        let conditionStatus =
          statusV === 'Aprobado'
            ? check2
            : statusV === 'No requiere autorización'
              ? Menos
              : statusV === 'Pendiente'
                ? alertIcon
                : redCloseX;
        let statusIcon = (
          <img
            alt='type'
            src={conditionStatus}
            width={conditionStatus === redCloseX ? '30px' : '18px'}
          />
        );
        let createdDate = item?.createdAtMedicalOrders.split('|');
        let date = createdDate[0];
        let time = createdDate[1];

        let patientRecibe =
          item?.patientReceived === 0 ? 'NO' : item?.patientReceived === 1 ? 'SI' : 'N/A';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-2'>{formatteDocument(item?.patient?.document).format}</td>
            <td className='text-start px-2'>{item?.patient?.name}</td>
            <td className='text-start px-2'>{item?.corporateClient?.contractName}</td>
            <td className={` ${tableStyles.ordHoverBlueText} text-start px-2`}>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipDoctor({ item })}
              >
                <span>{item?.doctor?.name}</span>
              </OverlayTrigger>
            </td>
            <td className='text-Center '>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipSite({ item })}
              >
                <span>{item?.site?.prefix}</span>
              </OverlayTrigger>
            </td>
            <td className='text-center'>
              {date} | <b>{time}</b>
            </td>
            <td className='text-Center '>{patientRecibe}</td>
            <td className='text-center'>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipStatusIcon(statusV)}
              >
                <span>{statusIcon}</span>
              </OverlayTrigger>
            </td>
            <td>
              <CustomPopupExtend
                triggerSrc={threeDots}
                extraButtons={
                  statusV === 'No requiere autorización' || statusV === 'Aprobado'
                    ? [
                        {
                          type: 1,
                          text: 'Detalle',
                          icon: searchBlueIcon,
                          event: () => {
                            setFullDialogScreen({
                              show: true,
                              id: item.idSer,
                              ordTag: item.ortTag,
                            });
                          },
                        },
                        {
                          type: 1,
                          text: 'Clonar orden',
                          icon: CloneIconClearBlue,
                          event: () => {
                            history.push({
                              pathname: '/ordenamientos/ordenes',
                              state: { isCloningServices: true, cloneData: item },
                            });
                          },
                        },
                        {
                          type: 1,
                          text: 'Descargar PDF',
                          icon: pdfIcon,
                          event: () => {
                            downloadPdf(item.idSer, item.ortTag, item?.patient?.name);
                          },
                        },
                      ]
                    : statusV === 'Pendiente'
                      ? [
                          {
                            type: 1,
                            text: 'Clonar orden',
                            icon: CloneIconClearBlue,
                            event: () => {
                              history.push({
                                pathname: '/ordenamientos/ordenes',
                                state: { isCloningServices: true, cloneData: item },
                              });
                            },
                          },
                        ]
                      : statusV === 'Rechazado'
                        ? [
                            {
                              type: 1,
                              text: 'Clonar orden',
                              icon: CloneIconClearBlue,
                              event: () => {
                                history.push({
                                  pathname: '/ordenamientos/ordenes',
                                  state: { isCloningServices: true, cloneData: item },
                                });
                              },
                            },
                            {
                              type: 1,
                              text: 'Justificación',
                              icon: justificationIcon,
                              event: () => {
                                // let string = ""
                                // for (let i of item.medicine)
                                // string = string +`\n• Medicamento ${(i?.name)}, fue rechazado por: \n${i?.auth?.authComment} \n \n`
                                setJustification({
                                  ...justification,
                                  showModal: true,
                                  justificationText: item?.observations,
                                });
                              },
                            },
                          ]
                        : []
                }
              ></CustomPopupExtend>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  return (
    <div className='d-flex w-100 mx-auto container pt-3'>
      {servicesHistoryLoader && loader}
      {unitiesLoader && loader}
      {servicesOrderLoader && loader}
      {/* ------------ Details ---------------- */}
      <FullScreenDialog onHide={() => setFullDialogScreen(false)} isOpen={fullDialogScreen.show}>
        <RenderMedicalFormulasPdf
          pdfParams={{
            url: '/medical/pdfserviceOrder/', //ESPERAR URL
            id: fullDialogScreen.id,
            ordTag: fullDialogScreen.ordTag,
          }}
          title={'Ordenamiento de servicios'}
          backAction={() => {
            setFullDialogScreen(false);
          }}
          withPagination={true}
        />
      </FullScreenDialog>
      {/* ------------ Justification ---------------- */}
      <OrdModal
        title={'Justificación'}
        show={justification.showModal}
        btnYesName='Aceptar'
        hideCancelButton
        size='500'
        btnYesEvent={() => {
          setJustification({ ...justification, showModal: false });
        }}
        onHide={() => {
          setJustification({ ...justification, showModal: false });
        }}
      >
        <Col xs={12}>
          <Form.Group className='mb-3' controlId='fName'>
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
              <b className='text-start'>&nbsp;Razón por la que su solicitud es rechazada</b>
            </Form.Label>
            <textarea
              disabled
              value={justification.justificationText}
              rows='25'
              cols='55'
              style={{ height: '7rem' }}
              className={`text-secondary ord-roundInput w-100 p-2`}
            ></textarea>
          </Form.Group>
        </Col>
      </OrdModal>
      {/* ------------- Page ---------------- */}
      <OrdGenericTemplate
        title={'Historial de ordenes de servicios '}
        titleSize={9}
        showBackArrow={true}
        backArrowAction={() => history.push('/ordenamientos/Consultas')}
      >
        {/* ------------- Filters and inputs ---------------- */}
        <OrdSlideDownButton
          onToggleClick={() => setRotate(!rotate)}
          buttons={buttons}
          accordionClassName={`${ordComponentStyles.OrdSlideButton} mr-1`}
        >
          <>
            <Row>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Fecha desde</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type='date'
                    placeholder=''
                    value={filters.startDate}
                    onChange={e => {
                      setFilters({ ...filters, startDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Fecha hasta</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.inputDateServiceReports}`}
                    type='date'
                    placeholder=''
                    value={filters.endDate}
                    onChange={e => {
                      setFilters({ ...filters, endDate: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Identificación del paciente</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordGrayInput}`}
                    autoComplete='off'
                    type='number'
                    style={{ fontSize: '13.5px' }}
                    placeholder='Escribe...'
                    value={formatteDocument(filters.documentPatient).format}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        searchButton();
                      }
                    }}
                    onChange={e => {
                      setFilters({
                        ...filters,
                        documentPatient:formatteDocument(e.target.value),
                      });
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Médico</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={doctorOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setFilters({ ...filters, doctor: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='pt-2'>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Sede</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={sitesOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setFilters({ ...filters, site: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Empresa</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={companiesOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setFilters({ ...filters, company: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={3} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Contrato</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={contractsOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setFilters({ ...filters, contract: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2} className=''>
                <Form.Group className='' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b className='text-start'>&nbsp;Estado</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={statusOptions}
                    className='text-secondary '
                    placeholder={'Seleccione'}
                    styles={ordCustomSelect}
                    onChange={e => {
                      setFilters({ ...filters, status: e.value });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={1} className='' style={{ alignSelf: 'center' }}>
                <button
                  onClick={() => {
                    searchButton();
                  }}
                >
                  <img
                    src={Lupa2}
                    className='hoverPointer mt-4'
                    alt='lupa'
                    style={{ width: '25px', height: '25px' }}
                  />
                </button>
              </Col>
            </Row>
          </>
        </OrdSlideDownButton>
        {/* ------------- Table ---------------- */}
        <OrdTable
          headers={headers}
          hasChildren={true}
          shadow={true}
          paginate={{
            activePage: filters?.page,
            totalPages: servicesHistory?.rowTotal,
            perPage: filters?.perpage,
            pageRangeDisplayed: 3,
            onChangePage: async e => {
              const result = await getServicesHistory({
                url: '/medical/serviceOrder/',
                objFilters: { ...filters, page: e },
                token: token,
              });
              setFilters({ ...filters, page: e });
              formatData(result?.results);
            },
            showTextDetails: true,
          }}
        >
          {formatData(servicesHistory.results)}
        </OrdTable>
      </OrdGenericTemplate>
    </div>
  );
};
