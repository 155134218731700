import { Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import { useGetMethod } from '../../../../Hooks/useFetch';
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import PENDING from '../../../../assets/img/icons/Alert.svg';
import APPROVED from '../../../../assets/img/icons/Check Mark.svg';
import REJECTED from '../../../../assets/img/icons/extraBtnRedX.svg';
import PROCESSED from '../../../../assets/img/icons/grayCheckNew.svg';
import Search from '../../../../assets/img/icons/lupa.svg';
import ordBlueSearch from '../../../../assets/img/icons/ordBlueSearch.svg';
import tableStyles2 from '../../../../components/Layouts/tableStyle.module.scss';
import Styles from '../../../../components/PayRollModule/Hiring/Contract.module.scss';
import { convertMoneyFormat, formatToRcSelect, loader } from '../../../../helpers/helpers';
import GenericTableNew from '../../../Layouts/GenericTableNew';
import paginationStyles from '../../../Layouts/pagination.module.scss';
import { customSelectNew } from '../../../Layouts/react-select-custom';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import SelectComponent from '../../../SelectComponent/SelectComponent';
import '../../../TableUsers/tableUserStyle.scss';
import { BonusSheetAuthDetail } from './BonusSheetAuthDetail';

export const BonusSheetAuth = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const [detailPage, setDetailPage] = useState(null);
  const history = useHistory();
  const [data, setData] = useState({
    trigger: 0,
    generatePremiumSchedule: null,
    filters: {
      date: '',
      search: '',
      page: 1,
      perpage: 10,
      entity_account: idAccount,
      module: 'bonusAuth',
    },
    modal: {
      show: false,
      isEditing: false,
      dateStart: moment(new Date()).format('YYYY-MM-DD'),
      dateEnd: moment(new Date()).format('YYYY-MM-DD'),
      datePayment: moment(new Date()).format('YYYY-MM-DD'),
    },
  });
  const { filters, trigger, modal } = data;
  const {
    results: premiumPayrollResults,
    load: premiumPayrollLoader,
    trigger: getPremiumPayroll,
  } = useGetMethod();
  const {
    results: statusMethodResults,
    load: statusLoader,
    trigger: getStatusMethod,
  } = useGetMethod();
  const statusOptions = formatToRcSelect(statusMethodResults?.results, 'name', 'label', '', '', '');

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }

    getPremiumPayroll({
      url: '/payroll/law_bonus/',
      objFilters: filters,
      token: token,
    });

    getStatusMethod({
      url: '/payroll/status-rd/law_bonus/',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className={`col-3 px-3 text-start fw-bold ${tableStyles2.darkBlueText}`}>
          {elem?.period ?? '-'}
        </td>
        <td className='col-2 text-center'>{elem?.payment_year ?? '-'}</td>
        <td className='col-2 text-center'>{elem?.total_employees ?? '-'}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.total ?? '-')}</td>
        <td className='col-2 text-end'>{convertMoneyFormat(elem?.total_amount_bonus ?? '-')}</td>
        <td className='col-1 text-center px-2'>
          <Tooltip title={elem?.status?.value} arrow>
            <img
              alt={'status'}
              src={
                elem?.status?.name === 'review'
                  ? PENDING
                  : elem?.status?.name === 'approved'
                    ? APPROVED
                    : elem?.status?.name === 'rejected'
                      ? REJECTED
                      : elem?.status?.name === 'processed' && PROCESSED
              }
            ></img>
          </Tooltip>
        </td>
        <td className='col-1 text-center px-2'>
          <div
            onClick={() => setDetailPage(elem)}
            style={{ padding: '3px 5px' }}
            className='pointer hoverImg d-flex justify-content-center'
          >
            <img src={ordBlueSearch} alt='buscador' />
          </div>
        </td>
      </tr>
    );
  };

  const renderHeaders = [
    <th key={1} className='text-start col-3 px-3'>
      Periodo
    </th>,

    <th key={2} className='text-center col-2'>
      Año
    </th>,

    <th key={3} className='text-center col-2'>
      No. de empleados
    </th>,

    <th key={4} className='text-end col-2'>
      Total
    </th>,

    <th key={5} className='text-end col-2'>
      Total bonificación
    </th>,

    <th key={6} className='text-center col-1'>
      Estado
    </th>,
    <th key={6} className='text-start col-1'>
      &nbsp;
    </th>,
  ];

  const closeModal = () => {
    setData(state => ({
      ...state,
      modal: {
        show: false,
        isEditing: false,
        dateStart: moment(new Date()).format('YYYY-MM-DD'),
        dateEnd: moment(new Date()).format('YYYY-MM-DD'),
        datePayment: moment(new Date()).format('YYYY-MM-DD'),
      },
    }));
  };

  const validationRequest = () => {
    closeModal();
    setData(state => ({ ...state, generatePremiumSchedule: true }));
  };

  return (
    <>
      {(premiumPayrollLoader || statusLoader) && loader}
      {detailPage === null ? (
        <>
          <OrdModal
            title={modal.isEditing ? '' : 'Generar planilla de primas'}
            show={modal.show}
            btnYesName={modal.isEditing ? 'Guardar' : 'Guardar'}
            btnNoName={'Cancelar'}
            size={550}
            onHide={() => closeModal()}
            btnYesEvent={() => validationRequest()}
            btnNoEvent={() => closeModal()}
            sizeBody={`${tableStyles2.modalHeight}`}
            titleClassName={tableStyles2.darkBlueText}
            btnYesStyle={tableStyles2.btnYesStyle}
            btnCancelStyle={tableStyles2.btnCancelStyle}
          >
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <label className={tableStyles.crudModalLabel}>Fecha de inicio</label>

                <input
                  className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                  type='date'
                  value={modal.dateStart}
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, dateStart: e.target.value },
                    }))
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <label className={tableStyles.crudModalLabel}>Fecha de fin</label>

                <input
                  className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                  type='date'
                  value={modal.dateEnd}
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, dateEnd: e.target.value },
                    }))
                  }
                />
              </Grid>
            </Grid>

            <Grid container className='my-3'>
              <Grid item xs={12}>
                <label className={tableStyles.crudModalLabel}>Fecha de pago</label>

                <input
                  className={`${Styles.register_inputs_new} ${Styles.register_inputs_blue2}`}
                  type='date'
                  value={modal.datePayment}
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, datePayment: e.target.value },
                    }))
                  }
                />
              </Grid>
            </Grid>
          </OrdModal>
          <div className='w-80 mx-auto'>
            <h1 className={tableStyles.title}>Autorización de bonificación</h1>

            <Row className='d-flex'>
              <Col xs={2}>
                <label className={tableStyles.crudModalLabel}>Año</label>
                <input
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, year: e.target.value },
                    }))
                  }
                  type='number'
                  placeholder='Escribir...'
                  className={`register-inputs `}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setData(state => ({ ...state, trigger: state.trigger + 1 }));
                    }
                  }}
                />
              </Col>
              <Col xs={2} className='align-self-end'>
                <label className={tableStyles.crudModalLabel}>Estado</label>
                <SelectComponent
                  key={`select`}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew}
                  options={statusOptions}
                  value={statusOptions.find(e => e.value === filters?.status)}
                  onChange={e => {
                    setData(state => ({ ...state }));
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                      filters: { ...state.filters, status: e.value },
                    }));
                  }}
                />
              </Col>
              <Col xs={5} className='d-flex align-items-end'>
                <input
                  className={` ${tableStyles.SearchNew} mr-2`} //${tableStyles.clearBlueSearch}
                  name='search'
                  type='text'
                  placeholder='Buscar...'
                  value={filters.search}
                  onChange={e =>
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, search: e.target.value },
                    }))
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setData(state => ({ ...state, trigger: state.trigger + 1 }));
                    }
                  }}
                />

                <img
                  src={Search}
                  style={{ cursor: 'pointer' }}
                  alt='Search icon'
                  height='24px'
                  onClick={() => {
                    setData(state => ({ ...state, trigger: state.trigger + 1 }));
                  }}
                />
              </Col>
            </Row>

            <Grid container>
              <Grid item xs={12}>
                <GenericTableNew headers={renderHeaders} dark={true}>
                  {premiumPayrollResults?.results?.length > 0 &&
                    premiumPayrollResults?.results?.map(el => {
                      return renderElement(el);
                    })}
                </GenericTableNew>
              </Grid>
              <Grid item xs={12}>
                <div className={paginationStyles.wrapper}>
                  <p className={`${paginationStyles.paginationText} text-secondary`}>
                    Pag. {premiumPayrollResults?.results ? filters.page : ''}
                    {' de '}
                    {Math.ceil(premiumPayrollResults?.rowTotal / filters.perpage)
                      ? Math.ceil(premiumPayrollResults?.rowTotal / filters.perpage)
                      : ''}{' '}
                    ({premiumPayrollResults?.rowTotal} encontrados)
                  </p>
                  <Pagination
                    activePage={filters.page}
                    itemsCountPerPage={filters.perpage}
                    totalItemsCount={premiumPayrollResults?.rowTotal}
                    pageRangeDisplayed={5}
                    onChange={e =>
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, page: e },
                        trigger: state.trigger + 1,
                      }))
                    }
                    itemClassPrev={paginationStyles.itemClassPrev}
                    itemClassNext={paginationStyles.itemClassNext}
                    itemClassFirst={paginationStyles.itemClassFirst}
                    itemClassLast={paginationStyles.itemClassLast}
                    itemClass={paginationStyles.itemClass}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <BonusSheetAuthDetail backPage={{ setDetailPage, detailPage }} setTrigger={setData} />
      )}
    </>
  );
};
