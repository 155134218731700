import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';

import Excel from '../../assets/img/icons/excel.svg';
import LupaDetail from '../../assets/img/icons/Filtrar.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

import { convertMoneyFormat, customSwaltAlert, genericDownloadExcel, loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';

import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import Apply from './Apply/Apply';
import Details from './Details/Details';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import GenericTable from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

function ImpairmentPurse() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const [see, setSee] = useState(false);
  const [trigger, setTrigger] = useState(1);

  const [filters, setFilters] = useState({
    page: 1,
    eaccount: storage.loginReducer.currentAccount.id,
    perpage: 10,
    search: '',
  });
  const [visualizeConfig, setVisualizeConfig] = useState({
    isOpen: false,
    current: '',
  });
  const [currentVisualize, setCurrentVisualize] = useState('provision');
  const [currentDetail, setCurrentDetail] = useState({
    isOpen: false,
    current: '',
    client: '',
  });

  const { load: balanceLoader, trigger: getBalance, results: balanceResults } = useGetMethod();
  const {
    load: visualizeLoader,
    trigger: getVisualizeData,
    results: visualizeResults,
  } = useGetMethod();
  const { load: totalLoader, trigger: getTotal, results: totalResults } = useGetMethod();

  const { trigger: postDocExcel } = usePostMethod();
  const { trigger: applyArrears, load: applyArrearsLoader } = usePostMethod();

  const myPermission = useCallback(
    () =>
      storage.loginReducer.currentAccount?.profile?.permission?.find(
        x => x.functionality?.prefix === 'portfolioI',
      ),
    [storage.loginReducer.currentAccount?.profile?.permission],
  );

  useEffect(() => {
    (async function () {
      await getBalance({
        url: '/receivable/balance/getPortfolioByCompany/',
        objFilters: filters,
        token: storage.loginReducer.Authorization,
      });
    })();
  }, [
    filters,
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getBalance,
  ]);

  useEffect(() => {
    (async function () {
      if (currentVisualize) {
        await getVisualizeData({
          url: '/receivable/balance/viewDeterioration/',
          objFilters: {
            eaccount: storage.loginReducer.currentAccount.id,
            process: currentVisualize,
          },
          token: storage.loginReducer.Authorization,
        });
        await getTotal({
          url: '/receivable/balance/total/',
          objFilters: {
            eaccount: storage.loginReducer.currentAccount.id,
          },
          token: storage.loginReducer.Authorization,
        });
      }
    })();
  }, [
    dispatch,
    currentVisualize,
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getVisualizeData,
    getTotal,
  ]);

  useEffect(() => {
    (async function () {
      await getTotal({
        url: '/receivable/balance/total/',
        objFilters: {
          eaccount: storage.loginReducer.currentAccount.id,
        },
        token: storage.loginReducer.Authorization,
      });
    })();
  }, [
    dispatch,
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getTotal,
  ]);

  useEffect(() => {
    if (!myPermission()?.read) {
      history.push('/cartera/inicio');
    }
  }, [history, myPermission]);

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/balance/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        consultType: 'deterioration',
        all: '1',
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        if (results.success) {
          genericDownloadExcel(results?.results?.base64, results?.results?.filename);
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Excel descargado',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        }
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onSuccessApply = async () => {
    await getBalance({
      url: '/receivable/balance/getPortfolioByCompany/',
      objFilters: {
        ...filters,
        page: 1,
        search: '',
      },
      token: storage.loginReducer.Authorization,
    });
    await getTotal({
      url: '/receivable/balance/total/',
      objFilters: {
        eaccount: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
    });
  };

  const onApplyArrears = () => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
      confirmButtonText: 'Aceptar',
    }).then(response => {
      if (response.isConfirmed) {
        applyArrears({
          token: storage.loginReducer.Authorization,
          body: {
            process: 'arrears',
            eaccount: storage.loginReducer.currentAccount.id,
            created_by: storage.loginReducer.user_data.id,
          },
          method: 'POST',
          url: '/receivable/balance/applyDeterioration/',
          succesAction: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Mora exitosa',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                await onSuccessApply();
              }
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const balanceTotal = useMemo(() => totalResults.results?.[0], [totalResults.results]);

  const changeSee = () => {
    if (see) {
      setSee(false);
    } else {
      setSee(true);
    }
  };

  const handleSearch = e => {
    e.preventDefault();
    const { search } = e.target;

    setFilters({ ...filters, search: search.value });
  };

  const header = [
    <th key={1} className='px-2 text-center'>
      NIT
    </th>,
    <th key={2} className='px-2 text-start'>
      Empresa
    </th>,
    <th key={3} className='px-2 text-end'>
      Saldo
    </th>,
    <th key={4} className='px-2 text-end'>
      Por radicar
    </th>,
    <th key={5} className='px-2 text-end'>
      Corriente
    </th>,
    <th key={6} className='px-2 text-end'>
      Deterioro
    </th>,
    <th key={7} className='px-2 text-end'>
      Provisión
    </th>,
    <th key={8} className='px-2 text-end'>
      Mora
    </th>,
    <th key={9} className='px-2 text-start' style={{ width: 30 }}></th>,
  ];
  const renderList = () => {
    return balanceResults.results?.map(item => {
      return (
        <tr key={'renderList' + item.id} className='hover-table-row'>
          <td className='px-2'>{item.nit}</td>
          <td className='px-2 text-start'>{item.client}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.pending)}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.unfiled)}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.not_due)}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.deterioration)}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.provision)}</td>
          <td className='px-2 text-end'>{convertMoneyFormat(item.arrears)}</td>
          <td className='px-2 text-center'>
            <CustomPopupExtend
              position='left'
              triggerSrc={threeDots}
              extraButtons={[
                {
                  text: 'Detalle mora',
                  icon: LupaDetail,
                  class: CustomPopupStyles.popUpGeneric,
                  event: () =>
                    setCurrentDetail({
                      isOpen: true,
                      current: 'arrears',
                      client: item.id,
                    }),
                },
                {
                  text: 'Detalle provisión',
                  icon: LupaDetail,
                  class: CustomPopupStyles.popUpGeneric,
                  event: () =>
                    setCurrentDetail({
                      isOpen: true,
                      current: 'provision',
                      client: item.id,
                    }),
                },
                {
                  text: 'Detalle deterioro',
                  icon: LupaDetail,
                  class: CustomPopupStyles.popUpGeneric,
                  event: () =>
                    setCurrentDetail({
                      isOpen: true,
                      current: 'deterioration',
                      client: item.id,
                    }),
                },
              ]}
            />
          </td>
        </tr>
      );
    });
  };

  const renderMenuOptions = props => {
    return (
      <Popup
        closeOnDocumentClick
        position='bottom center'
        trigger={
          <button
            className={`${tableStyles.buttonTextPrimary} small`}
            style={{ borderRadius: 30 }}
            onClick={() => changeSee()}
            type='button'
          >
            {props.label}
          </button>
        }
      >
        {close => (
          <div className='small text-muted'>
            <div className='fw-bold py-1 px-2'>{props.sublabel}</div>
            <hr className='m-0' />
            <div
              className={`${tableStyles.hoverable} px-2`}
              onClick={() => {
                props.onApply();
                close();
              }}
            >
              Aplicar
            </div>
            <div
              className={`${tableStyles.hoverable} px-2`}
              onClick={() => {
                props.onVisualize();
                close();
              }}
            >
              Visualizar
            </div>
          </div>
        )}
      </Popup>
    );
  };

  const renderFilters = () => {
    return (
      <form onSubmit={e => handleSearch(e)} className='row my-4'>
        <Col className='d-flex align-items-center' lg={6}>
          <input
            className={tableStyles.SearchNew}
            type='text'
            placeholder='Buscar..'
            name='search'
          />
          <button type='submit' className='ms-3'>
            <img src={Lupa} alt='' onClick={() => setTrigger(trigger + 1)} />
          </button>
        </Col>
        <Col className='d-flex justify-content-end align-items-center' lg={6}>
          {renderMenuOptions({
            label: 'Provisión',
            sublabel: 'Fiscal',
            key: 'provision',
            onApply: () => {
              setVisualizeConfig({
                current: 'provision',
                isOpen: true,
              });
            },
            onVisualize: () => {
              setCurrentVisualize('provision');
            },
          })}
          {renderMenuOptions({
            label: 'Mora',
            sublabel: 'Mora',
            key: 'arrears',
            onApply: () => onApplyArrears(),
            onVisualize: () => {
              setCurrentVisualize('arrears');
            },
          })}
          {renderMenuOptions({
            label: 'Deterioro',
            sublabel: 'NIIF',
            key: 'deterioration',
            onApply: () => {
              setVisualizeConfig({
                current: 'deterioration',
                isOpen: true,
              });
            },
            onVisualize: () => {
              setCurrentVisualize('deterioration');
            },
          })}

          <button className='ms-3' onClick={downloadExcelDoc}>
            <img src={Excel} alt='' width={23} />
          </button>
        </Col>
      </form>
    );
  };

  const render = () => {
    return (
      <>
        {(visualizeLoader || balanceLoader || applyArrearsLoader || totalLoader) && loader}

        <div className='container-fluid px-5'>
          <h1>Deterioro de cartera</h1>

          {renderFilters()}

          <div className='row card-container-principal overflow-hidden'>
            <div className='col-5'>
              <div className='row h-100 card-container'>
                <div className='col-4 card-principal border-muted my-3'>
                  <label>Saldo</label>
                  <p>{balanceTotal?.pending ? convertMoneyFormat(balanceTotal.pending) : '$0'}</p>
                </div>

                <div className='col-4 card-principal border-muted my-3'>
                  <label>Pendiente por radicar</label>
                  <p>{balanceTotal?.unfiled ? convertMoneyFormat(balanceTotal.unfiled) : '$0'}</p>
                </div>

                <div className='col-4 card-principal'>
                  <label>Corriente</label>
                  <p>{balanceTotal?.not_due ? convertMoneyFormat(balanceTotal.not_due) : '$0'}</p>
                </div>
              </div>
            </div>

            <div className='col d-flex' style={{ overflowX: 'auto' }}>
              {visualizeResults.results?.map(item => (
                <div
                  key={item.name.replace(' ', '')}
                  className='d-flex justify-content-center align-items-center flex-column h-100 border-end text-muted p-3'
                  style={{ minWidth: 220 }}
                >
                  <small className='fw-bold'>
                    {currentVisualize === 'provision' && 'Provisión'}
                    {currentVisualize === 'deterioration' && 'Deterioro'}
                    {currentVisualize === 'arrears' && 'Mora de'}
                  </small>
                  <h5 className='fw-bold m-0'>{item.name} días</h5>
                  <div>{convertMoneyFormat(item.value)}</div>
                </div>
              ))}
            </div>
          </div>

          <GenericTable headers={header} dark={true}>
            {renderList()}
          </GenericTable>
          <div className={IndividualStyles.bottom}>
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {balanceResults.results ? filters.page : ''}
                {' de '}
                {Math.ceil(balanceResults.row_count / filters.perpage)
                  ? Math.ceil(balanceResults.row_count / filters.perpage)
                  : ''}{' '}
                ({balanceResults.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={balanceResults.row_count}
                pageRangeDisplayed={5}
                onChange={e => setFilters({ ...filters, page: e })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>

        <Apply
          visualizer={visualizeConfig.current}
          isOpen={visualizeConfig.isOpen}
          onClose={() => setVisualizeConfig({ ...visualizeConfig, isOpen: false })}
          onSuccess={onSuccessApply}
        />

        <Details
          currentDetail={currentDetail.current}
          client={currentDetail.client}
          isOpen={currentDetail.isOpen}
          onClose={() => setCurrentDetail({ ...currentDetail, isOpen: false })}
        />
      </>
    );
  };

  return render();
}
export default ImpairmentPurse;
