import { 
    GO_TO_SALIDA_CONSUMO,
    LOTS_FROM_WAREHOUSE,
    LOTS_FROM_WAREHOUSE_LOADING
    } from "../actions/actionTypes";
  
  const initialState = {
      reqIds:[], //160
      mainWarehouse:0, //40
      centroConsumo:0, //79
      date:"",

      lots:[],
      lots_loading: false,
  };

  export const salidaConsumoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GO_TO_SALIDA_CONSUMO:
            return {
                ...state,
                reqIds: action.reqIds,
                mainWarehouse: action.mainWarehouse,
                centroConsumo: action.centroConsumo,
            };
        case LOTS_FROM_WAREHOUSE:
            return {
                ...state,
                lots: action.lots,
                lots_loading: action.loading,
            };
        
        case LOTS_FROM_WAREHOUSE_LOADING:
            return {
                ...state,
                lots_loading: action.loading,
            };
        default:
            return state;
    }
  };
  