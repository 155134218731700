import { useEffect, useState } from 'react';
import GenericTableNew from '../Layouts/GenericTableNew';

import { REQUISITION_GET } from '../../actions/actionTypes';
import { requisition_get_one } from '../../actions/requisitionActions';

import moment from 'moment';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import ModalNew from '../Layouts/ModalNew';
import '../Layouts/customTabs.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';

function ReqModalReq(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const myReq = counter.requisitionReducer.req_chosen;

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });

  useEffect(() => {
    setLoading(true);
    dispatch(requisition_get_one(props.reqId, () => setLoading(false)));
    return () => {
      dispatch({
        type: REQUISITION_GET,
        payload: {},
      });
    };
  }, [props.reqId, dispatch]);

  const renderHeaders = [
    <th key={`ReqModalReq1`} className='text-start px-2'>
      Código
    </th>,
    <th key={`ReqModalReq2`}>Artículo</th>,
    <th key={`ReqModalReq3`} className='text-center'>
      {' '}
      Cantidad
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(myReq?.article_list)) {
      table = myReq?.article_list.map(x => {
        return (
          <tr key={'reqModalReqList' + x.id_article} className={`hover-table-row`}>
            <td className={`col-md-3 text-start px-2`}>{x.id_article}</td>
            <td className={`col-md-6 text-start`}>{x.article.description}</td>
            <td className={`col-md-3`}>{x.qty_article}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const paginateList = () => {
    const fullList = renderList();
    return fullList.slice(filters.perpage * (filters.page - 1), filters.perpage * filters.page);
  };

  // const presentableDate = new Date(props.reqState?.requisition_date);
  const presentableDate = moment(props.reqState?.requisition_date).format('DD/MM/YYYY');

  return (
    <ModalNew
      title={'Detalle de Requisición'}
      show={props.show}
      btnYesName='Clonar'
      size='xl'
      btnYesEvent={() => props.btnClonar()}
      btnYesDisabled={counter.requisitionReducer.loading}
      onHide={props.onHide}
    >
      <div className={reqStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className='d-flex'>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Fecha de Requisición</p>
            <input
              className='register-inputs'
              name='requisition_date'
              type='text'
              // value={presentableDate.toLocaleDateString("es-CO")}
              value={presentableDate}
              disabled={true}
            />
          </div>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
            <input
              className='register-inputs'
              name='requisition_date'
              type='text'
              value={
                /*props.reqState?.id_consumption_center +" "+*/ props.reqState?.cc_ref?.description
              }
              disabled={true}
            />
          </div>
          <div className={reqStyles.ItemMedium}>
            <div className={`${reqStyles.ItemSmall}`}>
              <p className={tableStyles.crudModalLabel}>Concepto</p>
              <input
                className='register-inputs'
                name='concept'
                type='text'
                value={props.reqState?.concept}
                disabled={true}
              />
            </div>
          </div>
        </div>

        {/* ============================= ROW 2 =================================== */}
        <div className='d-flex'>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Número de Requisición</p>
            <input
              className='register-inputs'
              name='num_req'
              type='text'
              value={props.reqState?.num_req}
              disabled={true}
            />
          </div>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Requerido por</p>
            <input
              className='register-inputs'
              name='id_user'
              type='text'
              value={/*props.reqState.id_user*/ props.reqState?.name_user}
              disabled={true}
            />
          </div>

          <div className={reqStyles.ItemMedium}>
            <div className={`${reqStyles.ItemSmall} ${reqStyles.ItemCheckbox}`}>
              <div className={tableStyles.myCheckbox}>
                <input
                  key={'checkBoxRMR' + Math.random()}
                  className={tableStyles.myCheckboxInput}
                  type='checkbox'
                  name='urgent'
                  value='urgent'
                  defaultChecked={props.reqState?.urgent}
                  disabled={true}
                />
                <label
                  className={`${tableStyles.myCheckboxLabel} ${reqStyles.urgente}`}
                  htmlFor='urgent'
                >
                  Urgente
                </label>
              </div>
            </div>
          </div>
        </div>
        <GenericTableNew headers={renderHeaders}>
          {loading && (
            <tr>
              <td />
              <td>
                <Loader type='Oval' color='#003f80' height={100} width={100} />
              </td>
              <td />
            </tr>
          )}
          {paginateList()}
        </GenericTableNew>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {myReq?.article_list?.length ? filters.page : ''}
            {' de '}
            {Math.ceil(myReq?.article_list?.length / filters.perpage)
              ? Math.ceil(myReq?.article_list?.length / filters.perpage)
              : ''}{' '}
            ({myReq?.article_list?.length} encontrados)
          </p>

          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={myReq?.article_list?.length}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
            itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
            itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
            itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Observaciones</p>
          <textarea
            id='txtArea'
            rows='10'
            cols='10'
            value={props.reqState?.observations}
            style={{ border: '1px solid #7FADDE' }}
            className={`register-inputs ${reqStyles.textArea}`}
            disabled={true}
          ></textarea>
        </div>
      </div>
    </ModalNew>
  );
}

export default ReqModalReq;
