import { useEffect, useMemo, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
//
import { useGetMethod } from '../../Hooks';
import { usePostMethod } from '../../Hooks/useFetch';
import { getClient, getReceiptInvoices, getStatus } from '../../actions/receiptOfInvoicesActions';
import Lupa2 from '../../assets/img/icons/auctionDetailLupa2.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert, genericDownloadExcel, loader } from '../../helpers';
//
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

function InvoiceReceipt() {
  const storage = useSelector(state => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const filtersInitialState = {
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    contractType: '',
    minuteNumber: '',
    status: '',
    date: '',
    client: '',
  };

  const [filters, setFilters] = useState(filtersInitialState);
  const { trigger: getContractTypes, results: contractTypes } = useGetMethod();
  const { trigger: postDocExcel } = usePostMethod();
  useEffect(() => {
    (async function () {
      await getContractTypes({
        url: '/invoicing/contract/type/',
        objFilters: {
          eaccount: storage.loginReducer.currentAccount.id,
        },
        token: storage.loginReducer.Authorization,
      });
    })();
  }, [
    storage.loginReducer.currentAccount.id,
    storage.loginReducer.Authorization,
    getContractTypes,
  ]);

  useEffect(() => {
    dispatch(getReceiptInvoices(filters));
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch(getStatus({ type: 'receivRec' }));
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/receiptRecord/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        if (results.success) {
          genericDownloadExcel(results?.results?.base64, results?.results?.filename);
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Excel descargado',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        }
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  let optionsStatus = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.status)) {
    storage.invoiceReducer.status.forEach(item => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + 'status',
      });
    });
  }

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  const optionsContracts = useMemo(
    () =>
      contractTypes.results?.map(item => ({
        label: item.value,
        value: item.id,
      })) || [],
    [contractTypes.results],
  );

  const handleSearch = e => {
    e.preventDefault();
    const { minuteNumber } = e.target;
    setFilters({ ...filters, minuteNumber: minuteNumber.value, page: 1 });
  };

  const header = [
    <th key={1} className='text-start px-2' style={{ whiteSpace: 'nowrap' }}>
      No. Acta{' '}
    </th>,
    <th key={2} className='text-start'>
      NIT
    </th>,
    <th key={3} className='text-start'>
      Empresa
    </th>,
    <th key={4} className='text-start'>
      Contrato
    </th>,
    <th key={5} className='text-center'>
      Regimen
    </th>,
    <th key={6} className='text-start'>
      Sede
    </th>,
    <th key={7} className='text-center'>
      F. de envío
    </th>,
    <th key={8} className='text-center'>
      Estado
    </th>,
    <th key={9} className='text-center'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.invoices)) {
      table = storage.invoiceReducer.invoices.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-start px-2 text-uppercase'>{x.sequence}</td>
            <td className='text-start'>{x.clientNit}</td>
            <td className='text-start'>{x.clientName}</td>
            <td className='text-start' width={100}>
              {x.contract}
            </td>
            <td className='text-center' width={100}>
              {x.healthCarePlan}
            </td>
            <td className='text-start'>{x.site}</td>
            <td className='text-center' width={130}>
              {x.exp_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-center'>
              <div
                className='rounded-pill p-1'
                style={{ backgroundColor: `${x.status_background}` }}
              >
                <b style={{ color: `${x.status_fontcolor}` }}>{x.status}</b>
              </div>
            </td>
            <td className='text-center' width={50}>
              <img
                alt='img'
                className={`text-start w-40 ${IndividualStyles.pointer}`}
                src={Lupa2}
                onClick={() => {
                  history.push(`/cartera/Factura/RecepcionDeFacturas/${x.id}`);
                  setFilters(filtersInitialState);
                }}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const principalRender = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {storage.invoiceReducer.loading_receipt_invoice && loader}

      <h1 className={tableStyles.title}>Recepción de acta</h1>

      <div className='row align-items-end'>
        <div className='col'>
          &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={optionsClient}
            placeholder='Seleccionar...'
            onChange={e =>
              setFilters({
                ...filters,
                client: e.value,
                page: 1,
              })
            }
            styles={customSelectNewDark}
            name='id_consumption_center'
          />
        </div>

        <div className='col'>
          &nbsp;<label className={tableStyles.stylesLabel}>Contrato</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={[{ label: 'Seleccionar...', value: '' }, ...optionsContracts]}
            placeholder='Seleccionar...'
            onChange={e =>
              setFilters({
                ...filters,
                contractType: e.value === '' ? '' : e.label,
                page: 1,
              })
            }
            styles={customSelectNewDark}
          />
        </div>

        <div className='col'>
          &nbsp;<label className={tableStyles.stylesLabel}>Fecha de envío</label>
          <input
            className={`${IndividualStyles.registerInputs} register-inputs`}
            type='date'
            style={{ color: '#005DBF', fontWeight: 'bold' }}
            value={filters.date}
            onChange={e => setFilters({ ...filters, date: e.target.value })}
          />
        </div>

        <div className='col'>
          &nbsp;<label className={tableStyles.stylesLabel}>Estado</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={optionsStatus}
            placeholder='Seleccionar...'
            onChange={e =>
              setFilters({
                ...filters,
                status: e.value,
                page: 1,
              })
            }
            styles={customSelectNewDark}
            name='id_consumption_center'
          />
        </div>

        <div className='col-3 d-flex'>
          <form className='w-100' onSubmit={handleSearch}>
            <input
              className={tableStyles.SearchNew}
              type='text'
              placeholder='Escribir...'
              name='minuteNumber'
            />
          </form>
          <img
            style={{ width: '25px' }}
            className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
            src={Lupa}
            alt=''
            onClick={() => setFilters({ ...filters, page: 1 })}
          />
        </div>

        <div className='col-1 text-end'>
          <img
            alt='Excel'
            className='pointer'
            width={25}
            src={Excel}
            onClick={() => downloadExcelDoc()}
          />
        </div>
      </div>

      <GenericTable headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.invoices ? filters.page : ''}
            {' de '}
            {Math.ceil(storage.invoiceReducer?.invoices_total / filters.perpage)
              ? Math.ceil(storage.invoiceReducer.invoices_total / filters.perpage)
              : ''}{' '}
            ({storage.invoiceReducer.invoices_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={parseInt(storage.invoiceReducer.invoices_total)}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );

  return principalRender;
}
export default InvoiceReceipt;
