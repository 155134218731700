import tableStylesScroll from './TableScrollStyles.module.scss';

function TableScroll(props) {
  return (
    <main className={tableStylesScroll.mainTable}>
      <div
        style={{
          overflow: 'auto',
          borderRadius: 10,
          scrollbarWidth: 'none',
          scrollbarColor: '#ccc transparent',
        }}
      >
        <table className={tableStylesScroll.tableScrollK}>
          <thead>
            <tr>
              {props?.headers?.length > 0 &&
                props?.headers?.map(header => {
                  return header;
                })}
            </tr>
          </thead>

          <tbody>
            {props?.body?.length > 0 &&
              props?.body?.map(body => {
                return body;
              })}
          </tbody>
        </table>
      </div>
    </main>
  );
}

export default TableScroll;
