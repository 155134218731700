//-------------- components 🍕   --------------------------------------------
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Carousel, { consts } from 'react-elastic-carousel';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import es from 'date-fns/locale/es';

import add from '../assets/img/icons/AdjuntarRecto.svg';
import arrowDerechaCalendario from '../assets/img/icons/arrowDerechaCalendario.svg';
import arrowIzquierdaCalendario from '../assets/img/icons/arrowIzquierdaCalendario.svg';
import arrow from '../assets/img/icons/ArrowRight.svg';
import downGrey from '../assets/img/icons/desplegarGris.svg';
import down from '../assets/img/icons/downArrowIcon.svg';
import excelClearGrey from '../assets/img/icons/excelClearGray.svg';
import ordBlueSearch from '../assets/img/icons/ordBlueSearch.svg';
import OrdLupa from '../assets/img/icons/OrdLupa.svg';
import search from '../assets/img/icons/searchCalendar.svg';
import threeDots from '../assets/img/icons/threeDots.svg';

import {
  ordCustomSelect,
  customSelectMultiHorizontal,
} from '../../src/components/Layouts/react-select-custom';
import CustomPopupExtend from '../../src/components/Popup/customPopUpExtends';
import {
  convertBase64,
  customSwaltAlertAsistencial,
  downloadExcel,
  formatteDocument,
  formatToRcSelect,
  generateId,
  loader,
  message,
  validateEmail,
  validateEmptyString,
} from '../helpers';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';

import { RenderMedicalFormulasPdf } from '../AsistanceModule/RenderMedicalFormulasPdf';
import { OrdBiTable } from '../OrderingModule/OrderingComponents/OrdBiTable';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../OrderingModule/OrderingComponents/OrdModal';
import { OrdSlideDownButton } from '../OrderingModule/OrderingComponents/OrdSlideDownButton';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';

import tableStyles from '../../src/components/Layouts/tableStyle.module.scss';
import CustomPopupStyles from '../../src/components/Popup/customPopup.module.scss';
import stylesApp from '../AppointmentModule/RegisterAppointment/DetailPatient/styles.module.scss';
import { formatNumberWithoutDecimals } from '../helpers/numberFormatting';
import ordComponentStyles from '../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import styles from '../TelemedicineModule/VirtualDating/cardsStyles.module.scss';
import { SearchCardDoctor } from './RegisterAppointment/DetailPatient/components';
import ReactDatePicker from 'react-datepicker';

moment.locale('en-US');

const TODAY = moment().format('YYYY-MM-DD');

function ConsultApp() {
  const store = useSelector(state => state);
  const MySwal = withReactContent(Swal);

  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  const userId = store.loginReducer.user_data.id;

  const [ticketValue, setTicketValue] = useState();
  const [data, setData] = useState([]);
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    modality: '',
    doctor: '',
    company: '',
    status: '',
    startDate: TODAY,
    endDate: TODAY,
    search: '',
    siteIds: '',
    serviceTypeIds: '',
    module: 'appointments',
    page: 1,
    perpage: 10,
    idAccount: idAccount,
  });
  const [rotate, setRotate] = useState(false);

  const [fullDialogScreen, setFullDialogScreen] = useState({
    show: false,
    appId: '',
    ordTag: '',
  });

  const [showModal, setShowModal] = useState({
    show: false,
  });

  const [services, setServices] = useState({
    currentItem: {},
    status: '',
    header: [
      {
        title: 'Fecha',
        className: 'col-2 text-center',
      },
      {
        title: 'Servicio',
        className: 'col-3 text-start',
      },
      {
        title: 'Estado',
        className: 'col-2 text-center',
      },
      {
        title: <>&nbsp;</>,
        className: 'col-1 text-end px-1',
      },
    ],
  });
  const [modalProgramming, setModalProgramming] = useState({
    show: false,
  });
  const [locationOptions, setLocationOptions] = useState([{ value: '', label: '-Seleccione-' }]);
  const [siteOptions, setSiteOptions] = useState([{ value: '', label: '-Seleccione-' }]);
  const [showJustification, setShowJustification] = useState({
    show: false,
  });
  const [dataProgramming, setDataProgramming] = useState({
    oldDate: '',
    idService: '',
    servicesName: '',
    location: '',
    doctor: '',
    site: '',
    date: new Date(),
    proximateDates: [],
    patientName: '',
    idDoctor: '',
    insurance: '',
    selectedInsurance: {
      company: '',
      companyId: '',
      contractCorporateClient: '',
      idContractCorporateClient: '',
      popName: '',
      crtName: '',
      idPopulation: '',
      attGroupId: '',
      contractId: '',
    },
    showInsurances: false,
    showSchedule: false,
    idDoctorSch: '',
    idPatient: '',
  });
  const [modalEdit, setModalEdit] = useState({
    show: false,
    code: '',
    status: '',
  });
  const [fields, setFields] = useState({
    idAPP: '',
    transacCode: '',
    bank: '',
    file: '',
    typeFile: '',
    userId: '',
    fileName: '',
  });
  const [dateInfo, setDateInfo] = useState({
    date: '',
  });
  const [triggerPicker, setTriggerPicker] = useState(0);
  const [scheduleDoctorTime, setScheduleDoctorTime] = useState();

  const [sendReminderModal, setSendReminderModal] = useState({
    show: false,
    email: '',
    fileBase64: '',
    idAPP: '',
    detailReminder: {
      namePatient: '',
      specialist: '',
      date: '',
      hour: '',
      serviceName: '',
      price: '',
    },
  });

  const { results: ListApp, load: ListAppLoader, trigger: getListApp } = useGetMethod();
  const { trigger: getTicket } = useGetMethod();
  const { results: ListStateApp, trigger: getListStateApp } = useGetMethod();
  const { results: ListCompany, trigger: getListCompany } = useGetMethod();
  const { results: ListDoctors, trigger: getListDoctors } = useGetMethod();
  const { load: editAppointmentLoader, trigger: editAppointmentMethod } = usePostMethod();
  const { load: docPdfLoader } = useGetMethod();
  const { load: docExcelLoader, trigger: getDocExcel } = useGetMethod();
  const {
    results: scheduleDoctor,
    load: scheduleDoctorLoader,
    trigger: getScheduleDoctor,
  } = useGetMethod();
  const { load: createReminderLoader, trigger: createReminderMethod } = usePostMethod();
  const { trigger: getPdfMethod } = useGetMethod();
  const { trigger: postTicket } = usePostMethod();
  const { load: createMedicineLoader, trigger: updateApp } = usePostMethod();
  const {
    results: cancelMotiveResults,
    load: cancelMotiveLoader,
    trigger: getCancelMotive,
  } = useGetMethod();

  const {
    load: loadAppointmentHistoryPatient,
    trigger: getAppointmentHistoryPatient,
    results: collectAppointment,
  } = useGetMethod();

  const {
    results: detailPatient,
    loader: detailLoaderPatient,
    trigger: getDetailPatient,
  } = useGetMethod();
  const { load: locationLoader, trigger: getLocationOptions } = useGetMethod();
  const {
    load: siteLoader,
    trigger: getSiteOptions,
    results: getSiteOptionsResult,
  } = useGetMethod();
  const {
    load: serviceTypesLoader,
    trigger: getServiceTypes,
    results: serviceTypesResults,
  } = useGetMethod();
  const {
    results: doctorOptionsResults,
    load: doctorsLoader,
    trigger: getDoctorOptions,
  } = useGetMethod();
  const { results: statusResults, trigger: getStatusOptions } = useGetMethod();

  useEffect(() => {
    getListApp({
      url: '/medical/generals/appointments/',
      objFilters: filters,
      token: token,
      doAfterSuccess: result => {
        formatData(result.results);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.modality,
    filters.company,
    filters.doctor,
    filters.status,
    filters.startDate,
    filters.endDate,
    filters.page,
    filters.siteIds,
    filters.serviceTypeIds,
    trigger,
  ]);

  useEffect(() => {
    getListStateApp({
      url: '/medical/generals/stateAppointments/',
      token: token,
    });
    getListCompany({
      url: '/medical/client/',
      objFilters: { eaccount: idAccount },
      token: token,
    });
    getListDoctors({
      url: '/medical/generals/doctors/',
      objFilters: { idAccount },
      token: token,
    });
    getStatusOptions({
      url: '/medical/patient/',
      token: token,
    });
  }, [getListCompany, getListDoctors, getListStateApp, getStatusOptions, token, idAccount]);

  useEffect(() => {
    getCancelMotive({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });
  }, [getCancelMotive, token, trigger]);

  useEffect(() => {
    void getSiteOptions({
      url: '/medical/generals/site/',
      objFilters: { idAccount: idAccount },
      token: token,
    });
    void getServiceTypes({
      url: '/medical/generals/service-types/',
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });
  }, [getSiteOptions, getServiceTypes, idAccount, token]);

  const statusMessages = useMemo(
    () => ({
      'Pendiente de pago': 'Cita agendada, y pendiente de pago',
      Pagada: 'Cita agendadas y pagadas en espera de la fecha acordada',
      'Atención pendiente':
        filters.status === 'in_progress'
          ? 'Citas admitidas y en proceso de atención'
          : 'Citas agendadas, pagadas y admitidas en espera de atención',
      Anulada: 'Anulada manualmente o que no se pagaron en el tiempo estipulado',
      'Anulada pagada': 'Agendada y pagada pero anulada manualmente',
      'Atención exitosa': 'Proceso terminado exitosamente',
      Ausente: 'Agendada pero el paciente faltó al a consulta',
      'Ausente pagada': 'Agendada y pagada pero el paciente faltó al a consulta',
      'Por confirmar':
        'Requiere confirmación por falla del web scraping. De otra forma, en 48 será borrada',
      'Por autorizar': 'Pendiente autorización de cupo adicional',
      'Atención parcial':
        'Durante la cita se presentó un inconveniente y no se pudo tener una atención exitosa',
      Fallida: 'Atención fallida',
      'Cita confirmada': 'Cita confirmada manualmente o por medio virtual',
      Admitida: 'En espera de atención',
      Consulta: 'Paciente en atención médica',
      Preconsulta: 'Paciente en evaluación inicial por enfermería',
      Reprogramada: 'Consulta pospuesta para nueva fecha',
    }),
    [filters.status],
  );

  const formattedSiteOptions = useMemo(() => {
    if (getSiteOptionsResult?.results) {
      return getSiteOptionsResult.results.map(site => ({
        label: site.site,
        value: site.siteId,
      }));
    }
    return [];
  }, [getSiteOptionsResult?.results]);

  const formattedServiceTypesOptions = useMemo(() => {
    if (serviceTypesResults?.results) {
      return serviceTypesResults.results.map(type => ({
        label: type.name,
        value: type.id,
      }));
    }
    return [];
  }, [serviceTypesResults?.results]);

  const renderTooltipHourIcon = e => <Tooltip>{e}</Tooltip>;

  const headers = [
    {
      title: 'Paciente',
      className: 'text-start px-2',
    },
    {
      title: 'Médico',
      className: 'text-start px-2',
    },
    {
      title: 'Sede',
      className: 'text-start px-2',
    },
    {
      title: 'Fecha Registro',
      className: 'text-center px-2',
    },
    {
      title: 'Fecha Atención',
      className: 'text-center px-2',
    },
    {
      title: 'Aseguradora',
      className: 'text-start px-2',
    },
    {
      title: 'Tipo de servicio',
      className: 'text-start px-2',
    },
    {
      title: 'Servicio',
      className: 'text-start px-2',
    },
    {
      title: 'Valor Servicio',
      className: 'text-center px-2',
    },
    {
      title: 'Valor Paciente',
      className: 'text-center px-2',
    },
    {
      title: 'Estado',
      className: 'text-center px-2',
    },
    {
      title: <>&nbsp;</>,
    },
  ];

  //-------------- options Selects 🍕   --------------------------
  let optionsDoctors = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListDoctors?.results)) {
    ListDoctors.results.forEach(item => {
      optionsDoctors.push({
        value: item.idDoctor,
        label: item.nameDoctor + ' - ' + formatteDocument(item.document).format,
        key: item.idDoctor + 'doctor',
      });
    });
  }

  let optionsCompany = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListCompany?.results)) {
    ListCompany.results.forEach(item => {
      optionsCompany.push({
        value: item.id,
        label: item.name,
        key: item.id + 'company',
      });
    });
  }

  let optionsState = [{ key: 'default', value: '', label: 'Todos', id: '' }];
  if (Array.isArray(ListStateApp?.results)) {
    ListStateApp.results.forEach(item => {
      optionsState.push({
        value: item.statusTag,
        label: item.description,
        key: item.statusTag + 'status',
      });
    });
  }

  const downloadExcelDoc = () => {
    let name = 'report.xlsx';
    getDocExcel({
      url: '/medical/generals/exportAppointmentReportExcel/',
      objFilters: {
        modality: filters.modality,
        doctor: filters.doctor,
        company: filters.company,
        status: filters.status,
        startDate: filters.startDate,
        endDate: filters.endDate,
        search: filters.search,
        module: 'appointments',
        idAccount: idAccount,
      },
      token: token,
      doAfterSuccess: results => {
        if (results.success) {
          downloadExcel(results.results, name);
        }
      },
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  const getDoctorReprogramming = e => {
    getDoctorOptions({
      url: '/medical/appointment/medical-by-contract-and-location/',
      objFilters: {
        idAccount: idAccount,
        popId: dataProgramming.selectedInsurance.idPopulation,
        contractId: dataProgramming?.selectedInsurance.idContractCorporateClient,
        srvId: dataProgramming.idService,
        city: e.value,
      },
      token: token,
    });
  };

  const openProgrammingModal = e => {
    getTicket({
      url: '/medical/appointment/concecutive/',
      objFilters: {
        idConsec: 24,
      },
      token: token,
      doAfterSuccess: res => {
        setTicketValue(res.results);
      },
    });

    getLocationOptions({
      url: '/medical/appointment/locationService/',
      objFilters: { idAccount: idAccount, srvId: e.idService, modality: e.modality },
      token: token,
      doAfterSuccess: res => {
        setLocationOptions(formatToRcSelect(res.results, 'id', 'name', 'sites'));
      },
    });
    getDetailPatient({
      url: '/medical/patient/',
      objFilters: { id: e.idPatient, eaccount: idAccount },
      token: token,
      doAfterSuccess: res => {
        setDataProgramming({
          ...dataProgramming,
          affiliationTypeId: res?.results?.affiliationTypeId,
          modality: e.modality,
          oldDate: e.dateApp,
          patientName: e.namePatient,
          idService: e.idService,
          serviceName: e.serviceName,
          insurance: res?.results?.contracts,
          idApp: e.idApp,
          selectedInsurance: {
            contractId: e.crtId,
            company: e.company,
            companyId: e.companyId,
            contractCorporateClient: e.contractCorporateClient,
            idContractCorporateClient: e.idContractCorporateClient,
            idPopulation: e.idPopulation,
            popName: e.population,
            crtName: e.crtName,
            attGroupId: e.attGroupId,
            idCorporateClient: e.companyId,
          },
          idPatient: e.idPatient,
        });
      },
    });
    setModalProgramming({ ...modalProgramming, show: true });
  };

  const closeProgrammingModal = () => {
    setTicketValue('');
    setDataProgramming({
      oldDate: '',
      idService: '',
      servicesName: '',
      location: '',
      doctor: '',
      site: '',
      date: '',
      idDoctor: '',
      insurance: '',
      selectedInsurance: {
        company: '',
        companyId: '',
        contractCorporateClient: '',
        idContractCorporateClient: '',
        popName: '',
        crtName: '',
        idPopulation: '',
        attGroupId: '',
        contractId: '',
      },
      showInsurances: false,
      showSchedule: false,
      idDoctorContract: '',
      idDoctorSch: '',
      hourStart: '',
      hourEnd: '',
      appointment: '',
    });
    setModalProgramming({ ...modalProgramming, show: false });
    setDateInfo({ date: '' });
  };

  const getAppointmentDetail = (id, appId, status, e) => {
    getAppointmentHistoryPatient({
      url: `/medical/appointment/`,
      token: token,
      objFilters: {
        appId: appId,
        modality: 'on_site',
      },
      doAfterSuccess: res => {
        setServices({ ...services, currentItem: res?.results, show: true, status: status });
      },
    });
    getDetailPatient({
      url: '/medical/patient/',
      objFilters: { id: id, eaccount: idAccount },
      token: token,
    });
    setServices({ ...services, fontColorStatus: e.fontColorStatus });
  };

  const detailAppReprogrammed = (appId, result) => {
    setSendReminderModal({ ...sendReminderModal, idAPP: appId });
    getAppointmentHistoryPatient({
      url: `/medical/appointment/`,
      token: token,
      objFilters: { appId: appId, modality: 'on_site' },
      doAfterSuccess: res => {
        openReminderModal(result?.results[0].base64, appId, res);
      },
    });
  };

  const methodUpdate = idApp => {
    setShowJustification({ ...showJustification, show: true, idApp: idApp });
  };

  const cancelAppointment = idApp => {
    let validations = validateEmptyString([
      { field: showJustification.justification, whithOutAlert: true },
    ]);
    if (validations.valid === true) {
      MySwal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: 'Esta cita será anulada',
        confirmButtonText: 'Si, continuar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          updateApp({
            url: '/medical/appointment/',
            token: token,
            noAlert: true,
            method: 'PUT',
            body: {
              id: idApp,
              status: 'cancelled',
              canId: showJustification.justification,
            },
            doAfterSuccess: () => {
              MySwal.fire({
                icon: 'success',
                title: 'Cita anulada',
                confirmButtonText: 'Aceptar',
              });
              setShowJustification({ ...showJustification, show: false, justification: '' });
              setTrigger(trigger + 1);
            },
          });
        }
      });
    } else {
      message('info', 'Debe seleccionar el motivo de anulación');
    }
  };
  const confirmedAppointment = idApp => {
    MySwal.fire({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Esta cita será confirmada',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        updateApp({
          url: '/medical/appointment/',
          token: token,
          noAlert: true,
          method: 'PUT',
          body: {
            id: idApp,
            status: 'confirmed',
            userId,
          },
          doAfterSuccess: () => {
            MySwal.fire({
              icon: 'success',
              title: 'Cita confirmada',
              confirmButtonText: 'Aceptar',
            });
            setTrigger(trigger + 1);
          },
        });
      }
    });
  };

  const handleSubmitReprogramming = () => {
    editAppointment();
  };

  const openEditModal = () => {
    setModalEdit({ ...modalEdit, show: true });
  };
  const closeEditModal = () => {
    setModalEdit({ ...modalEdit, show: false, code: '', status: '' });
    setFields({ ...fields, fileName: '', file: '', typeFile: '' });
  };

  const handleSubmitEdit = () => {
    alert('yo edito algo');
  };

  const onChangeDoc = async e => {
    var f = e.target.files[0];
    if (f) {
      var name_doc = f.name;
      var ext = f.name.split('.')[1];
      var type_doc = f.type;
      if (ext !== 'pdf' && ext !== 'zip' && ext !== 'rar') {
        return message(
          'error',
          'El formato del archivo debe ser PDF o ZIP',
          'El tamaño máximo permitido es de 5MB',
        );
      }
      let encode = await convertBase64(e.target.files[0]);
      let file64 = encode.split(',')[1];
      setFields({
        ...fields,
        fileName: name_doc,
        file: file64,
        typeFile: type_doc.split('/')[1],
      });
    }
  };

  const showMoreInsurances = () => {
    setDataProgramming({ ...dataProgramming, showInsurances: !dataProgramming.showInsurances });
  };

  const changeInsuranceSelected = e => {
    setDataProgramming({
      ...dataProgramming,
      selectedInsurance: {
        company: e.companyTypeName,
        companyId: e.companyTypeId,
        contractCorporateClient: e.contractDescription,
        idContractCorporateClient: e.corporateClientId,
        contractId: e.contractId,
        crtId: e.crtId,
        popName: e.population,
        crtName: e.crtName,
        idPopulation: e.populationId,
        attGroupId: e.attGroupId,
        idCorporateClient: e.corporateClientId,
      },
      showInsurances: false,
    });
  };

  const searchDoctorSchedule = () => {
    let validations = validateEmptyString([
      {
        field: dataProgramming.idService,
        message: "El campo 'Servicio' es obligatorio para la búsqueda",
      },
      {
        field: dataProgramming.location,
        message: "El campo 'Ubicación' es obligatorio para la búsqueda",
      },
    ]);

    if (validations.valid) {
      getScheduleDoctor({
        url: '/medical/appointment/availability/',
        objFilters: {
          modality: dataProgramming.modality,
          idDoctor: dataProgramming?.doctor,
          siteId: dataProgramming?.site,
          attentionGroup: dataProgramming?.selectedInsurance?.attGroupId,
          eaccount: idAccount,
          idService: dataProgramming.idService,
          city: dataProgramming.location,
          idContractcorporateClient: dataProgramming?.selectedInsurance.idContractCorporateClient,
          idCorporateClient: dataProgramming?.selectedInsurance?.idCorporateClient,
          idPopulation: dataProgramming?.selectedInsurance.idPopulation,
        },
        token: token,
        doAfterSuccess: data => {
          postTicketSearchSchedule();
          setDataProgramming({
            ...dataProgramming,
            showSchedule: true,
            proximateDates: data?.proximateDates?.map(el => moment(el)?.toDate()) || [],
          });
          if (data?.date) {
            const dateV = data.date?.split('/');
            const date = dateV.length ? `${dateV[2]}-${dateV[1]}-${dateV[0]}` : undefined;
            setDateInfo({ date: date ? new Date(date) : new Date() });
          }
          setTriggerPicker(triggerPicker + 1);
          setScheduleDoctorTime();
        },
        doAfterException: data => {
          message('info', data.message);
        },
      });
    }
  };

  const changeDatePicker = newDate => {
    setDateInfo({ date: newDate });

    getScheduleDoctor({
      url: '/medical/appointment/availability/',
      objFilters: {
        modality: dataProgramming.modality,
        doctorId: dataProgramming?.idDoctorSch,
        siteId: dataProgramming?.site,
        attentionGroup: dataProgramming?.selectedInsurance?.attGroupId,
        eaccount: idAccount,
        idService: dataProgramming.idService,
        city: dataProgramming.location,
        idContractcorporateClient: dataProgramming?.selectedInsurance.idContractCorporateClient,
        idCorporateClient: dataProgramming?.selectedInsurance?.idCorporateClient,
        idPopulation: dataProgramming?.selectedInsurance.idPopulation,
        dateSearch: moment(newDate).format('YYYY-MM-DD'),
      },
      token: token,
      doAfterSuccess: () => {
        postTicketSearchSchedule();
        setDataProgramming({ ...dataProgramming, showSchedule: true, idDoctorSch: '' });
      },
    });
  };

  const postTicketReprogramming = appId => {
    let arrTemp = [];
    arrTemp.push(appId);
    postTicket({
      url: '/medical/appointment/saveStepProcess/',
      method: 'POST',
      body: {
        consecutiveTicket: ticketValue,
        userId: userId,
        aleEvent: 'reschedule_appointment',
        appId: arrTemp,
      },
      token: token,
      noAlert: true,
    });
  };
  const postTicketSearchSchedule = () => {
    postTicket({
      url: '/medical/appointment/saveStepProcess/',
      method: 'POST',
      body: {
        consecutiveTicket: ticketValue,
        userId: userId,
        aleEvent: 'agenda_availability',
        appId: [],
      },
      token: token,
      noAlert: true,
    });
  };

  const editAppointment = () => {
    let appDateTime = `${moment(dateInfo.date).format('YYYY-MM-DD')} ${dataProgramming.hourStart}`;
    let appEndDatetime = `${moment(dateInfo.date).format('YYYY-MM-DD')} ${dataProgramming.hourEnd}`;

    let validations = validateEmptyString([
      { field: dateInfo.date, message: 'La fecha es obligatoria' },
      { field: dataProgramming.hourStart, message: 'La hora de la cita es obligatoria' },
      { field: dataProgramming.idDoctorSch, message: 'El doctor es obligatorio' },
    ]);

    if (validations.valid) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Se reprogramará una cita al paciente ${dataProgramming.patientName} el día ${moment(dateInfo.date).format('DD/MM/YYYY')} a las ${moment(dataProgramming.hourStart, 'HH:mm').format('HH:mm')}`,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          editAppointmentMethod({
            url: '/medical/appointment/',
            method: 'PUT',
            body: {
              userId: userId,
              affiliationTypeId: dataProgramming.affiliationTypeId,
              id: dataProgramming.idApp,
              status: 'reprogrammed',
              idPatient: dataProgramming.idPatient,
              appDatetime: appDateTime,
              appEndDatetime: appEndDatetime,
              modality: dataProgramming.modality,
              popId: dataProgramming.selectedInsurance.idPopulation,
              contractId: dataProgramming.selectedInsurance.idContractCorporateClient,
              category: dataProgramming.selectedInsurance.crtName,
              eaccount: idAccount,
            },
            token: token,
            succesAction: info => {
              customSwaltAlertAsistencial({
                icon: 'success',
                titleColor: '#1ABCD2',
                title: 'Reprogramación exitosa',
                text: info.message,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            },
            doAfterSuccess: info => {
              getListApp({
                url: '/medical/generals/appointments/',
                objFilters: filters,
                token: token,
                doAfterSuccess: result => {
                  formatData(result.results);
                },
              });
              postTicketReprogramming(info.results);
              closeProgrammingModal(info);
              getPdfMethod({
                url: '/medical/appointment/pdf/',
                method: 'GET',
                objFilters: {
                  appIds: String(info.results),
                },
                token: token,
                succesAction: res => {
                  detailAppReprogrammed(info?.results, res);
                },
              });
            },
          });
        }
      });
    }
  };

  const openReminderModal = (file, id, res) => {
    setSendReminderModal({
      ...sendReminderModal,
      show: true,
      fileBase64: file,
      appId: id,
      detailReminder: {
        namePatient: `${res.results.cluFirstName} ${res.results.cluLastName} `,
        specialist: `${res.results.fullNameDoctor}`,
        date: `${res.results.dateApp}`,
        hour: `${res.results.timeApp}`,
        serviceName: `${res.results.serviceName}`,
        price: `${res.results.serviceValue}`,
      },
    });
  };
  const closeReminderModal = () => {
    setSendReminderModal({
      ...sendReminderModal,
      show: false,
      email: '',
      fileBase64: '',
      idAPP: '',
    });
    setTrigger(trigger + 1);
  };

  const handleSubmitReminder = () => {
    let arrEmail = [];
    arrEmail.push(sendReminderModal.email);
    let emailValidation;
    if (arrEmail.length > 0) {
      if (validateEmail(arrEmail[0]) === true) {
        emailValidation = true;
      } else {
        message('info', 'Validar correo', 'Valide su correo');
      }
    } else {
      message('info', 'Campos vacíos', "El campo 'Para' es obligatorio");
    }

    let appId = [];
    appId.push(sendReminderModal.appId);
    if (emailValidation) {
      Swal.fire({
        icon: 'warning',
        title: '¿Está seguro?',
        text: `Recordatorio de cita será enviado al correo: ${sendReminderModal.email} `,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Si, Continuar',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          createReminderMethod({
            url: '/medical/appointment/paramsEmail/',
            method: 'POST',
            body: {
              appIds: appId,
              cluEmail: arrEmail,
              fileBase64: sendReminderModal.fileBase64,
            },
            token: token,
            doAfterSuccess: info => {
              closeReminderModal();
              setTrigger(trigger + 1);
              message('info', info.message);
            },
            doAfterException: error => {
              message('error', 'Error', error.message);
            },
          });
        }
      });
    }
  };

  const renderStatusType = text => (
    <Tooltip>
      <span>{text}</span>
    </Tooltip>
  );

  //-------------- format data to table 🍕   --------------------------
  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array?.forEach(e => {
        let container = (
          <div className='rounded-pill p-1' style={{ backgroundColor: `${e?.bgColorStatus}` }}>
            <b style={{ color: `${e?.fontColorStatus}` }}>{e?.statusApp}</b>
          </div>
        );
        let text = statusMessages[e.statusApp];

        const patientAmount = +e.price;
        const totalServiceAmount = +e.serviceAmount;

        tempList.push([
          {
            text: (
              <>
                {' '}
                <div>
                  {' '}
                  <div>{e?.namePatient}</div>
                  <div>{formatteDocument(e?.documentPatient).format}</div>
                </div>
              </>
            ),
            className: 'text-start px-2',
          },
          {
            text: (
              <>
                {' '}
                <div>
                  {' '}
                  <div>{e?.nameDoctor}</div>
                  <div>{formatteDocument(e?.documentDoctor).format}</div>
                </div>
              </>
            ),
            className: 'text-start px-2',
          },
          <OverlayTrigger
            key={generateId()}
            placement='top'
            delay={{ show: 250, hide: 250 }}
            overlay={renderStatusType(e?.siteName)}
          >
            <span>{e?.sitePrefix}</span>
          </OverlayTrigger>,
          {
            text: e?.applicationDateApp,
            className: 'px-2 text-center',
          },
          {
            text: e?.dateAttentionApp,
            className: 'px-2 text-center',
          },
          {
            text: e?.company,
            className: 'text-start px-2',
          },
          {
            text: e?.serviceTypeName || '-',
            className: 'px-2 text-start',
          },
          {
            text: e?.serviceName,
            className: 'text-start px-2',
          },
          {
            text: `${formatNumberWithoutDecimals(totalServiceAmount ?? 0)}`,
            className: 'text-end px-2',
          },
          {
            text: `${formatNumberWithoutDecimals(patientAmount ?? 0)}`,
            className: 'text-end px-2',
          },
          <OverlayTrigger
            key={generateId()}
            placement='top'
            delay={{ show: 250, hide: 400 }}
            overlay={renderStatusType(text)}
          >
            <span>{container}</span>
          </OverlayTrigger>,
          <CustomPopupExtend
            key={generateId()}
            triggerSrc={threeDots}
            extraButtons={
              e.statusApp === 'Agendada'
                ? [
                    {
                      text: 'Ver detalle',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        setShowModal({ ...showModal, appId: e.appId, show: true });
                        getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                      },
                    },
                    {
                      text: 'Reprogramar',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        openProgrammingModal(e);
                      },
                    },
                    {
                      text: 'Anular',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => methodUpdate(e?.idApp),
                    },
                    {
                      text: 'Editar',
                      class: CustomPopupStyles.popUpGeneric,
                      event: () => {
                        openEditModal();
                      },
                    },
                  ]
                : e.statusApp === 'Pagada' || e.statusApp === 'Admitida'
                  ? [
                      {
                        text: 'Ver detalle',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          setShowModal({ ...showModal, appId: e.appId, show: true });
                          getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                        },
                      },
                      {
                        text: 'Anular',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => methodUpdate(e?.idApp),
                      },
                      {
                        text: 'Reprogramar',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          openProgrammingModal(e);
                        },
                      },
                    ]
                  : e.statusApp === 'En progreso' ||
                      e.statusApp === 'Atención exitosa' ||
                      e.statusApp === 'Ausente'
                    ? [
                        {
                          text: 'Ver detalle',
                          class: CustomPopupStyles.popUpGeneric,
                          event: () => {
                            setShowModal({ ...showModal, appId: e.appId, show: true });
                            getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                          },
                        },
                      ]
                    : e.statusApp === 'Anulada pagada' || e.statusApp === 'Atención parcial'
                      ? [
                          {
                            text: 'Ver detalle',
                            class: CustomPopupStyles.popUpGeneric,
                            event: () => {
                              setShowModal({ ...showModal, appId: e.appId, show: true });
                              getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                            },
                          },
                          {
                            text: 'Reprogramar',
                            class: CustomPopupStyles.popUpGeneric,
                            event: () => {
                              openProgrammingModal(e);
                            },
                          },
                        ]
                      : e.statusApp === 'Anulada'
                        ? [
                            {
                              text: 'Ver detalle',
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => {
                                setShowModal({ ...showModal, appId: e.appId, show: true });
                                getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                              },
                            },
                            {
                              text: 'Editar',
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => {
                                openEditModal();
                              },
                            },
                            {
                              text: 'Reprogramar',
                              class: CustomPopupStyles.popUpGeneric,
                              event: () => {
                                openProgrammingModal(e);
                              },
                            },
                          ]
                        : e.statusApp === 'Atención pendiente' && filters.status === 'in_progress'
                          ? [
                              {
                                text: 'Ver detalle',
                                class: CustomPopupStyles.popUpGeneric,
                                event: () => {
                                  setShowModal({ ...showModal, appId: e.appId, show: true });
                                  getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                                },
                              },
                            ]
                          : e.statusApp === 'Pendiente por validación' ||
                              e.statusApp === 'Por confirmar'
                            ? [
                                {
                                  text: 'Ver detalle',
                                  class: CustomPopupStyles.popUpGeneric,
                                  event: () => {
                                    setShowModal({ ...showModal, appId: e.appId, show: true });
                                    getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                                  },
                                },
                                {
                                  text: 'Anular',
                                  class: CustomPopupStyles.popUpGeneric,
                                  event: () => methodUpdate(e?.idApp),
                                },
                              ]
                            : e.statusApp === 'Pendiente de pago'
                              ? [
                                  {
                                    text: 'Ver detalle',
                                    class: CustomPopupStyles.popUpGeneric,
                                    event: () => {
                                      setShowModal({ ...showModal, appId: e.appId, show: true });
                                      getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                                    },
                                  },
                                  {
                                    text: 'Anular',
                                    class: CustomPopupStyles.popUpGeneric,
                                    event: () => methodUpdate(e?.idApp),
                                  },
                                  {
                                    text: 'Reprogramar',
                                    class: CustomPopupStyles.popUpGeneric,
                                    event: () => {
                                      openProgrammingModal(e);
                                    },
                                  },
                                  {
                                    text: 'Confirmar',
                                    class: CustomPopupStyles.popUpGeneric,
                                    event: () => {
                                      confirmedAppointment(e.idApp);
                                    },
                                  },
                                ]
                              : e.statusApp === 'Ausente pagada' ||
                                  e?.statusApp === 'Atención pendiente' ||
                                  e.statusApp === 'Fallida'
                                ? [
                                    {
                                      text: 'Ver detalle',
                                      class: CustomPopupStyles.popUpGeneric,
                                      event: () => {
                                        setShowModal({ ...showModal, appId: e.appId, show: true });
                                        getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                                      },
                                    },
                                    {
                                      text: 'Anular',
                                      class: CustomPopupStyles.popUpGeneric,
                                      event: () => methodUpdate(e?.idApp),
                                    },
                                    {
                                      text: 'Reprogramar',
                                      class: CustomPopupStyles.popUpGeneric,
                                      event: () => {
                                        openProgrammingModal(e);
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      text: 'Ver detalle',
                                      class: CustomPopupStyles.popUpGeneric,
                                      event: () => {
                                        setShowModal({ ...showModal, appId: e.appId, show: true });
                                        getAppointmentDetail(e.idPatient, e.idApp, e.statusApp, e);
                                      },
                                    },
                                  ]
            }
          />,
        ]);
      });
    }
    setData(tempList);
  };

  const statusOptions = formatToRcSelect(
    statusResults?.results?.contracts,
    'corporateClientId',
    'corporateClient',
    '',
    '',
    '',
  );

  let buttonsSlideDown = [
    {
      button: <img alt='btn' src={OrdLupa}></img>,
    },
    {
      button: (
        <img
          alt='btn'
          style={{
            transform: !rotate && 'rotate(180deg)',
          }}
          width={10}
          src={downGrey}
        ></img>
      ),
    },
  ];

  const insurenceAppointmentInfo = [
    {
      title: 'Aseguradora',
      subTitle: services?.currentItem?.ccName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Contrato',
      subTitle: services?.currentItem?.contractName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Población',
      subTitle: services?.currentItem?.popName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Grupo de ingreso',
      subTitle: services?.currentItem?.crtName,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Observaciones',
      subTitle: services?.currentItem?.appObservations,
      titleSize: 5,
      subTitleSize: 7,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
  ];

  const appointmentInfo = [
    {
      title: 'Sede',
      subTitle: services?.currentItem?.siteName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Médico',
      subTitle: services?.currentItem?.fullNameDoctor,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Servicio',
      subTitle: services?.currentItem?.serviceName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Fecha',
      subTitle: services?.currentItem?.dateApp
        ? services?.currentItem?.dateApp + ' ' + services?.currentItem?.timeApp
        : '-',
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'No. Historia clínica',
      subTitle: services?.currentItem?.clinicalHistoryID,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
    {
      title: 'Diagnóstico',
      subTitle: services?.currentItem?.dxName,
      titleSize: 6,
      subTitleSize: 6,
      titleClassName: `${tableStyles.app_title_info}`,
      subTitleClassName: `${tableStyles.app_subtitle_info}`,
    },
  ];

  const cancelOptions = formatToRcSelect(
    cancelMotiveResults?.results,
    'canId',
    'canDescription',
    '',
    '',
    '',
  );

  const doctorOptions = formatToRcSelect(
    doctorOptionsResults?.results,
    'mdId',
    'mdName',
    '',
    '',
    '',
  );

  const renderArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <img src={arrowIzquierdaCalendario} alt='arrow' width={10} />
      ) : (
        <img src={arrowDerechaCalendario} alt='arrow' width={10} />
      );
    return (
      <button onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  return (
    <>
      {(loadAppointmentHistoryPatient ||
        docPdfLoader ||
        docExcelLoader ||
        createMedicineLoader ||
        cancelMotiveLoader ||
        siteLoader ||
        serviceTypesLoader ||
        doctorsLoader ||
        editAppointmentLoader ||
        createReminderLoader ||
        scheduleDoctorLoader ||
        locationLoader) &&
        loader}

      {/* --------------------------------REMINDER MODAL --------------------------*/}
      <OrdModal
        show={sendReminderModal.show}
        title='Enviar recordatorio'
        size={'n'}
        btnYesName={'Enviar'}
        btnYesEvent={() => handleSubmitReminder()}
        btnNoEvent={() => {
          closeReminderModal();
        }}
        onHide={() => {
          closeReminderModal();
        }}
      >
        <Row className='mb-3'>
          <Col xs={12} className='d-flex mb-3 '>
            <b className={`${tableStyles.tlnTextDark} ${tableStyles.f16}`}>Detalle de la cita</b>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Paciente</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {sendReminderModal.detailReminder.namePatient}
            </span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Especialista</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {sendReminderModal.detailReminder.specialist}
            </span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Fecha</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {sendReminderModal.detailReminder.date} - {sendReminderModal.detailReminder.hour}
            </span>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Servicio</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {sendReminderModal.detailReminder.serviceName}
            </span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Valor</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {sendReminderModal.detailReminder.price}{' '}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} `}>
                <span>&nbsp;Correo electrónico</span>
              </Form.Label>
              <textarea
                className={`form-control ${tableStyles.f16}`}
                id='exampleFormControlTextarea1 '
                rows='2'
                style={{ color: '#6e6f7c' }}
                type='text'
                placeholder='Escribe...'
                onChange={e =>
                  setSendReminderModal({ ...sendReminderModal, email: e.target.value })
                }
              />
            </Form.Group>
          </Col>
        </Row>
      </OrdModal>

      {/* ------------------------ REPROGRAMMING MODAL ---------------------------*/}
      <OrdModal
        show={modalProgramming.show}
        title='Cambio de fecha'
        size={940}
        btnYesName={'Aceptar'}
        btnYesEvent={() => handleSubmitReprogramming()}
        btnNoEvent={() => closeProgrammingModal()}
        onHide={() => {
          closeProgrammingModal();
        }}
      >
        <div className={`${tableStyles.app_back_search} d-flex`}>
          <Row className={``}>
            <Col xs={11}>
              <Row className={`d-flex`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fService'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <b> &nbsp;Aseguradora </b>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${dataProgramming?.selectedInsurance?.company} `}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Contrato</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${dataProgramming?.selectedInsurance?.contractCorporateClient} `}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Población</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${dataProgramming?.selectedInsurance?.popName} `}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp; Grupo de ingreso</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${dataProgramming?.selectedInsurance?.crtName ? dataProgramming?.selectedInsurance?.crtName : '-'} `}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col
              xs={1}
              className='d-flex align-self-center justify-content-center'
              style={{ marginTop: '1rem' }}
            >
              <button onClick={() => showMoreInsurances()} className='d-flex align-self-center'>
                <img src={down} alt='agregar archivo' width='30' />
              </button>
            </Col>
          </Row>
        </div>
        {dataProgramming.showInsurances &&
          dataProgramming.insurance.length > 0 &&
          dataProgramming.insurance.map((e, ind) => {
            return (
              <div
                key={ind}
                className={`${tableStyles.app_back_search} cursorPointer d-flex`}
                onClick={() => {
                  changeInsuranceSelected(e);
                }}
              >
                <Row className={``}>
                  <Col xs={12}>
                    <Row className={`d-flex`}>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fService'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <b> &nbsp;Aseguradora</b>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.companyTypeName} `}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp;Contrato</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.contractDescription} `}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fSite'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp;Población</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.population} `}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={3}>
                        <Form.Group className='mb-3 text-start ' controlId='fSite'>
                          <Form.Label
                            className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                          >
                            <span> &nbsp; Grupo de ingreso</span>
                          </Form.Label>
                          <Form.Control
                            className={`ord-roundInput `}
                            disabled
                            type='text'
                            placeholder=''
                            value={`${e.crtName} `}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        <div className={`${tableStyles.app_back_search} d-flex`}>
          <Row>
            <Col xs={11}>
              <Row className={` d-flex`}>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fService'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Servicio</span>
                    </Form.Label>
                    <Form.Control
                      className={`ord-roundInput `}
                      disabled
                      type='text'
                      placeholder=''
                      value={`${dataProgramming.idService} - ${dataProgramming.serviceName}`}
                    />
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fLocation'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Ubicación</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={locationOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={locationOptions.find(x => x.value === dataProgramming.location)}
                      onChange={e => {
                        setDataProgramming({ ...dataProgramming, location: e.value, site: '' });
                        setSiteOptions(formatToRcSelect(e.extra, 'id', 'siteName', ''));
                        getDoctorReprogramming(e);
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Sede</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={siteOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={siteOptions.find(x => x.value === dataProgramming.site)}
                      onChange={e => {
                        setDataProgramming({ ...dataProgramming, site: e.value, srvId: '' });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col xs={3}>
                  <Form.Group className='mb-3 text-start ' controlId='fSite'>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp; Médico</span>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      options={doctorOptions}
                      isDisabled={dataProgramming.isDisabled}
                      className='text-secondary '
                      placeholder={'Seleccione'}
                      styles={ordCustomSelect}
                      value={doctorOptions.find(x => x.value === dataProgramming.options)}
                      onChange={e => {
                        setDataProgramming({ ...dataProgramming, doctor: e.value });
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col
              xs={1}
              className='d-flex align-self-center justify-content-center'
              style={{ marginTop: '1rem' }}
            >
              <button onClick={() => searchDoctorSchedule()} className='d-flex align-self-center'>
                <img src={search} alt='searchIcon' width='30' />
              </button>
            </Col>
          </Row>
        </div>
        <Row className='mb-2'>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <span>Fecha actual</span>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type='date'
                placeholder=''
                value={dataProgramming.oldDate}
                readOnly
              />
            </Form.Group>
          </Col>

          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText}`}
                style={{ paddingLeft: '3px' }}
              >
                <span> Nueva fecha</span>
              </Form.Label>
              <Form.Control
                className={`ord-roundInput`}
                type='date'
                placeholder=''
                value={moment(dateInfo.date).format('YYYY-MM-DD')}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row>
        {dataProgramming.showSchedule && (
          <Row>
            <Col id='new-style-react-date'>
              <ReactDatePicker
                inline
                key={`datepicker + ${triggerPicker}`}
                locale={es}
                minDate={new Date()}
                highlightDates={dataProgramming?.proximateDates}
                selected={dateInfo?.date ? dateInfo.date : new Date()}
                onChange={newDate => {
                  changeDatePicker(newDate);
                }}
                style={{ border: '1px solid #00B4CC' }}
              />
            </Col>

            {scheduleDoctor?.results?.length > 0 ? (
              <>
                {dataProgramming.idDoctorSch !== '' ? (
                  <Col xs={4} className={` `}>
                    {dataProgramming.idDoctorSch !== '' && (
                      <div
                        key={generateId()}
                        className='py-2'
                        style={{ height: '250px', overflowY: 'auto' }}
                      >
                        {scheduleDoctorTime
                          ?.filter(schedule =>
                            moment(dateInfo?.date).isSame(moment(), 'day')
                              ? moment(schedule.time, 'HH:mm:ss').isSameOrAfter(
                                  moment().subtract(1, 'hour'),
                                )
                              : schedule,
                          )
                          ?.filter(schedule => schedule.hours.length > 0)
                          ?.map((schedule, index) => {
                            let hourSch = schedule?.time?.split(':')[0];
                            return (
                              <div
                                key={index}
                                className={`${stylesApp.app_container_map} d-flex`}
                                style={{ height: 30 }}
                              >
                                <div className={`${stylesApp.line} align-self-center`}>
                                  <div className={stylesApp.app_container_left_hours}>
                                    <span className={stylesApp.app_title}>{hourSch}</span>
                                  </div>
                                </div>
                                <Carousel
                                  itemsToShow={3}
                                  itemsToScroll={3}
                                  showArrows={true}
                                  pagination={false}
                                  renderArrow={renderArrow}
                                >
                                  {schedule.hours.map((hour, index) => {
                                    let hourCard = `${hour?.hourStart.slice(0, hour?.hourStart.length - 3)}`;
                                    return (
                                      <OverlayTrigger
                                        key={generateId()}
                                        placement='top'
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltipHourIcon(hour.nameSite)}
                                      >
                                        <div key={index} className={stylesApp.app_container_map}>
                                          <div
                                            className={
                                              hour.appointment
                                                ? stylesApp.app_container_hours_inactive
                                                : hour.hourStart === dataProgramming.hourStart
                                                  ? stylesApp.app_container_hours_active
                                                  : stylesApp.app_container_hours
                                            }
                                            onClick={() => {
                                              if (!hour.appointment) {
                                                if (dataProgramming.hourStart === hour.hourStart) {
                                                  setDataProgramming({
                                                    ...dataProgramming,
                                                    idSchedule: '',
                                                    hourStart: '',
                                                    hourEnd: '',
                                                    appointment: '',
                                                    idTime: '',
                                                  });
                                                } else {
                                                  setDataProgramming({
                                                    ...dataProgramming,
                                                    idSchedule: hour.idSchedule,
                                                    hourStart: hour.hourStart,
                                                    hourEnd: hour.hourEnd,
                                                    appointment: hour.appointment,
                                                    idTime: '',
                                                  });
                                                }
                                              }
                                            }}
                                          >
                                            <span
                                              style={{
                                                color:
                                                  hour.hourStart === dataProgramming.hourStart
                                                    ? '#FFFFFF'
                                                    : '#6e6f7c',
                                              }}
                                              className={stylesApp.hours}
                                            >
                                              {hourCard}
                                            </span>
                                          </div>
                                        </div>
                                      </OverlayTrigger>
                                    );
                                  })}
                                </Carousel>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </Col>
                ) : (
                  <Col
                    xs={4}
                    className='d-flex justify-content-center justify-align-center align-middle align-self-center'
                  >
                    <div className='d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center'>
                      <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
                        Seleccione especialista
                      </span>
                    </div>
                  </Col>
                )}
                <Col xs={4}>
                  <div style={{ height: '250px', overflowY: 'auto' }}>
                    {scheduleDoctor?.results?.map(schedule => (
                      <SearchCardDoctor
                        active={
                          `${schedule.idDoctor}_${schedule.idSchedule}` === dataProgramming?.unique
                        }
                        key={schedule?.idSchedule}
                        scheduleName={schedule?.scheduleName}
                        doctorName={schedule?.doctor}
                        doctorImage={schedule?.image}
                        onToggleDoctor={() => {
                          if (
                            `${schedule.idDoctor}_${schedule.idSchedule}` ===
                            dataProgramming?.unique
                          ) {
                            setDataProgramming({ ...dataProgramming, idDoctorSch: '', unique: '' });
                          } else {
                            setDataProgramming({
                              ...dataProgramming,
                              unique: `${schedule.idDoctor}_${schedule.idSchedule}`,
                              idDoctorSch: schedule.idDoctor,
                              idDoctorContract: schedule.idDoctorContract,
                            });
                            setScheduleDoctorTime(schedule.schedule);
                          }
                        }}
                      />
                    ))}
                  </div>
                </Col>
              </>
            ) : (
              <Col
                xs={8}
                className='d-flex justify-content-center justify-align-center align-middle align-self-center'
              >
                <div className='d-flex w-100 h-100 justify-content-center justify-align-center align-middle align-self-center'>
                  <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
                    No hay agendas disponibles
                  </span>
                </div>
              </Col>
            )}
          </Row>
        )}
      </OrdModal>
      <OrdModal
        show={modalEdit.show}
        title='Editar'
        size={'n'}
        btnYesName={'Guardar'}
        btnYesEvent={() => handleSubmitEdit()}
        btnNoEvent={() => {
          closeEditModal();
        }}
        onHide={() => {
          closeEditModal();
        }}
      >
        <Row className='mb-3'>
          <Col xs={12} className='d-flex mb-3 '>
            <b className={`${tableStyles.tlnTextDark} ${tableStyles.f16}`}>Detalle</b>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Paciente</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              Amelia Fernandez
            </span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Tipo de atención</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Particular</span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Especialista</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Rafael Estevez</span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Fecha</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              jueves 06 de enero 07:00 am
            </span>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Servicio</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              12326 - Revisión
            </span>
          </Col>
          <Col xs={5} className='d-flex'>
            <b className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>Valor</b>
          </Col>
          <Col xs={7} className='d-flex'>
            <span className={`${tableStyles.tlnTextGray} ${tableStyles.f14}`}>
              {formatNumberWithoutDecimals(3500)}
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <textarea
                className={`form-control ${tableStyles.f16}`}
                id='exampleFormControlTextarea1 '
                rows='2'
                style={{ color: '#6e6f7c' }}
                type='text'
                placeholder='Código del comprobante'
                onChange={e => setModalEdit({ ...modalEdit, code: e.target.value })}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} `}>
                <span>Anexar evidencia</span>
              </Form.Label>
              <label
                htmlFor='furatFile'
                className={`bg-transparent w-100 ${tableStyles.tlnTextGray} ${tableStyles.cursorPointer} ${tableStyles.f14} `}
                style={{
                  borderRadius: '5px',
                  border: '1px solid #a3bad1',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingRight: '10px',
                  padding: '0.25rem 0.4rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {fields?.fileName !== '' ? fields?.fileName : 'Cargar documento'}
                <input
                  id='furatFile'
                  type='file'
                  name='furat'
                  accept='application/pdf'
                  className='d-none w-100'
                  placeholder='Cargar documento'
                  onChange={e => onChangeDoc(e)}
                />

                <img src={add} alt='agregar archivo' width='14' />
              </label>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label
                className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} fs-14px`}
              >
                <span>Estado</span>{' '}
              </Form.Label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={statusOptions}
                isDisabled={dataProgramming.isDisabled}
                className='text-secondary '
                placeholder={'Seleccione'}
                styles={ordCustomSelect}
                value={statusOptions.find(x => x.value === modalEdit.status)}
              />
            </Form.Group>
          </Col>
        </Row>
      </OrdModal>

      <OrdModal
        title='Motivo de anulación'
        show={showJustification.show}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={() =>
          setShowJustification({
            ...showJustification,
            show: false,
            justification: cancelOptions.filter(x => x.value === ''),
          })
        }
        btnYesEvent={() => cancelAppointment(showJustification.idApp)}
        btnNoEvent={() =>
          setShowJustification({
            ...showJustification,
            show: false,
            justification: cancelOptions.filter(x => x.value === ''),
          })
        }
      >
        <div className='m-5'>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={cancelOptions}
            className='text-secondary'
            value={cancelOptions.filter(x => x.value === showJustification?.justification)}
            placeholder={'Seleccionar...'}
            onChange={e => {
              setShowJustification({ ...showJustification, justification: e.value });
            }}
            styles={ordCustomSelect}
          />
        </div>
      </OrdModal>
      <OrdModal
        title={'Detalle de cita'}
        show={showModal.show}
        subtitle={`Estado - ${services?.status ? services?.status : '-'}`}
        colorSubtitle={`${services?.fontColorStatus}`}
        btnYesName={'Seleccionar'}
        btnNoName='Cerrar'
        size='1340'
        onHide={() => {
          setShowModal({ ...showModal, show: false });
          setFullDialogScreen({
            ...fullDialogScreen,
            show: false,
          });
        }}
        btnNoEvent={() => {
          setShowModal({ ...showModal, show: false });
          setFullDialogScreen({
            ...fullDialogScreen,
            show: false,
          });
        }}
      >
        <div className='row'>
          <Col xs={3} style={{ marginTop: '10px' }}>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>Identificación del paciente</span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='text'
                  disabled
                  value={formatteDocument(detailPatient?.results?.document).format}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>Primer Nombre </span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='text'
                  disabled
                  value={detailPatient?.results?.firstName}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>Primer Apellido</span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='text'
                  disabled
                  value={detailPatient?.results?.lastName}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>Fecha de nacimiento</span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='date'
                  disabled
                  value={detailPatient?.results?.birthDate}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>WhatsApp</span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='text'
                  disabled
                  value={detailPatient?.results?.whatsapp}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className={tableStyles.app_container_content_form}>
              <Form.Group controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                  <span className='px-1'>Correo electrónico</span>
                </Form.Label>
                <Form.Control
                  className={tableStyles.app_inputs_detail_patient}
                  type='email'
                  disabled
                  value={detailPatient?.results?.email}
                  placeholder='-'
                />
              </Form.Group>
            </div>
            <div className='pt-4'>
              {/* ANCHOR BOTÓN RECORDATORIO */}
              {(services.status === 'Atención pendiente' ||
                services.status === 'Pagada' ||
                services.status === 'Pendiente de pago') && (
                <button
                  className={`${tableStyles.button_assign_appointment} `}
                  onClick={() =>
                    setFullDialogScreen({
                      ...fullDialogScreen,
                      show: true,
                      appId: services?.currentItem.id,
                    })
                  }
                >
                  <div className='d-flex'>
                    <div>
                      <span>Recordatorio de cita</span>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <img className={'cursorPointer '} alt={'arrow'} src={arrow} width={25} />
                    </div>
                  </div>
                </button>
              )}
            </div>
          </Col>
          <Col xs={9} style={{ marginTop: '10px' }}>
            {fullDialogScreen.show === true ? (
              <RenderMedicalFormulasPdf
                pdfParams={{
                  url: '/medical/appointment/pdf/',
                  objFilters: {
                    appIds: String(fullDialogScreen.appId),
                  },
                }}
                goBack={true}
                backAction={() => {
                  setFullDialogScreen({ ...fullDialogScreen, show: false });
                }}
              />
            ) : (
              <div>
                <div className='d-flex justify-content-between align-items-center ml-5'>
                  <div>
                    <span className={tableStyles.app_title_order}>
                      Identificador de la Cita No. {services.currentItem?.id}
                    </span>
                  </div>
                </div>
                <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
                  <Col xs={6}>
                    <div>
                      <span className={tableStyles.app_container_biTable_title}>
                        Información de la cita
                      </span>
                      <OrdBiTable data={appointmentInfo} />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <span className={tableStyles.app_container_biTable_title}>Aseguradora</span>
                      <OrdBiTable data={insurenceAppointmentInfo} />
                    </div>
                  </Col>
                </Row>
                <div>
                  <span className={tableStyles.app_container_biTable_title}>Recaudo</span>
                  {collectDataTable(collectAppointment.results?.paymentApp)}
                </div>
              </div>
            )}
            <div>
              <Row>
                <Col
                  className={`${tableStyles.ordBitableTitle} ${tableStyles.app_title_info}`}
                  lg={3}
                >
                  Asesor
                </Col>
                <Col
                  className={`${tableStyles.ordBitableSubTitle} ${tableStyles.app_subtitle_info}`}
                  lg={6}
                >
                  {collectAppointment.results?.admittedByName}
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col
                  className={`${tableStyles.ordBitableTitle} ${tableStyles.app_title_info}`}
                  lg={3}
                >
                  Fecha de admisión
                </Col>
                <Col
                  className={`${tableStyles.ordBitableSubTitle} ${tableStyles.app_subtitle_info}`}
                  lg={6}
                >
                  {collectAppointment.results?.admittedAt &&
                    moment(collectAppointment.results?.admittedAt).format('DD/MM/YYYY hh:mm A')}
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </OrdModal>
      <div className={`${tableStyles.tlnContainer} mt-4`}>
        {(ListAppLoader || docPdfLoader || detailLoaderPatient) && (
          <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
        )}
        <OrdGenericTemplate showBottomBorder={false} title={'Consulta de citas'}>
          <div className='d-flex justify-content-end p-0 cursorPointer'>
            <img alt={'excel'} src={excelClearGrey} onClick={() => downloadExcelDoc()} />
          </div>
          <OrdSlideDownButton
            onToggleClick={() => setRotate(!rotate)}
            buttons={buttonsSlideDown}
            accordionClassName={ordComponentStyles.OrdSlideButton2}
          >
            <Row className={`px-2`}>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Médico</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsDoctors}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    tableStyles={ordCustomSelect}
                    onChange={e => setFilters({ ...filters, doctor: e.value, page: 1 })}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Aseguradora</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsCompany}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    tableStyles={ordCustomSelect}
                    onChange={e => setFilters({ ...filters, company: e.value, page: 1 })}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Estado</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={optionsState}
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    tableStyles={ordCustomSelect}
                    onChange={e => setFilters({ ...filters, status: e.value, page: 1 })}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Fecha de atención desde</b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='date'
                    placeholder=''
                    onChange={e => setFilters({ ...filters, startDate: e.target.value, page: 1 })}
                    value={filters.startDate}
                  />
                </Form.Group>
              </Col>
              <Col xs={3}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Fecha de atención hasta</b>
                  </Form.Label>
                  <Form.Control
                    className={styles.app_inputs_detail_patient}
                    type='date'
                    placeholder=''
                    onChange={e => setFilters({ ...filters, endDate: e.target.value, page: 1 })}
                    value={filters.endDate}
                  />
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Sede</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={formattedSiteOptions}
                    isMulti
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    styles={customSelectMultiHorizontal}
                    onChange={options => {
                      setFilters({
                        ...filters,
                        siteIds: options.map(site => site.value).join(','),
                        page: 1,
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={2}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label
                    className={`m-0 ${tableStyles.ordDarkBlueText}`}
                    style={{ paddingLeft: '3px' }}
                  >
                    <b>Tipo de servicio</b>
                  </Form.Label>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    options={formattedServiceTypesOptions}
                    isMulti
                    className='text-secondary'
                    placeholder={'Seleccione'}
                    styles={customSelectMultiHorizontal}
                    onChange={options => {
                      setFilters({
                        ...filters,
                        serviceTypeIds: options.map(type => type.value).join(','),
                        page: 1,
                      });
                    }}
                  ></Select>
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group className='mb-3 text-start' controlId='fName'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
                  <form onSubmit={e => handleSearch(e)}>
                    <div className={`d-flex ${tableStyles.ordBlueInputSearch}`}>
                      <Form.Control
                        className={`ord-roundInput-search`}
                        type='text'
                        placeholder='Paciente o No. de identidad'
                        onChange={e => setFilters({ ...filters, search: e.target.value, page: 1 })}
                      />
                      <img
                        className='cursorPointer'
                        src={ordBlueSearch}
                        onClick={() => {
                          setFilters({ ...filters, page: 1 });
                          setTrigger(trigger + 1);
                        }}
                        alt={'search'}
                      ></img>
                    </div>
                  </form>
                </Form.Group>
              </Col>
              <Col xs={3}></Col>
            </Row>
          </OrdSlideDownButton>
          <OrdTable
            headers={headers}
            body={data}
            className={tableStyles.ordTableShadow}
            paginate={{
              activePage: filters.page,
              totalPages: ListApp?.rowTotal,
              perPage: filters.perpage,
              pageRangeDisplayed: 3,
              onChangePage: async e => {
                setFilters({ ...filters, page: e });
              },
              showTextDetails: true,
            }}
          ></OrdTable>
        </OrdGenericTemplate>
      </div>
    </>
  );
}
export default ConsultApp;

const collectDataTable = collectInfo => {
  if (!collectInfo)
    return (
      <Row>
        <Col className={`${tableStyles.ordBitableTitle} ${tableStyles.app_title_info}`} xs={3}>
          No hay datos.
        </Col>
      </Row>
    );

  const totalCollect = collectInfo.reduce((accum, current) => {
    const { amount } = current;

    return amount + accum;
  }, 0);

  return (
    <>
      {collectInfo.map(collectItem => {
        const { description, amount, id } = collectItem;

        return (
          <Row key={id}>
            <Col className={`${tableStyles.ordBitableTitle} ${tableStyles.app_title_info}`} xs={3}>
              {description}
            </Col>
            <Col
              className={`${tableStyles.ordBitableSubTitle} ${tableStyles.app_subtitle_info}`}
              xs={6}
            >
              {formatNumberWithoutDecimals(amount)}
            </Col>
          </Row>
        );
      })}

      <Row>
        <Col className={`${tableStyles.ordBitableTitle} ${tableStyles.app_title_info}`} xs={3}>
          Total
        </Col>
        <Col
          className={`${tableStyles.ordBitableSubTitle} ${tableStyles.app_subtitle_info}`}
          xs={6}
        >
          {formatNumberWithoutDecimals(totalCollect)}
        </Col>
      </Row>
    </>
  );
};
