//Login's reducer

import {
  COMPLEMENTARY_DOCS_SECTIONS,
  GET_ALL_POSITIONS,
  GET_WORK_TIMES,
  JOB_POS_AREAS,
  PAYROLL_STATUS,
} from "../actions/actionTypes";

const initialState = {
  jobsPositionsList: [],
  row_positions_total: 0,
  work_times_total: 0,
  workTimes: [],
  comp_docs: [],
  comp_docs_sections: [],
  job_pos_areas: [],
  statuses: [],
};

export const positionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_POSITIONS:
      return {
        ...state,
        jobsPositionsList: action.payload,
        row_positions_total: action.total,
      };
    case GET_WORK_TIMES:
      return {
        ...state,
        workTimes: action.payload,
        work_times_total: action.total,
      };
    case COMPLEMENTARY_DOCS_SECTIONS:
      return {
        ...state,
        comp_docs: action.payload,
        comp_docs_sections: action.sections,
      };
    case JOB_POS_AREAS:
      return {
        ...state,
        job_pos_areas: action.payload,
      };
    case PAYROLL_STATUS:
      return {
        ...state,
        statuses: action.payload,
      };
    default:
      return state;
  }
};
