import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import doc from '../../assets/img/icons/documentos.svg';

import { Title } from '../../shared';
import ClosingVoucher from './ClosingVoucher';
import CodeReplace from './CodeReplace';
import VoucherRegistration from './VoucherRegistration';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';

function Vouchers() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const [show, setShow] = useState({
    showReplace: false,
    showClose: false,
    showRegistration: false,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'vouchers',
    );

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const principalRender = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      <div className='d-flex flex-column'>
        <div className={tableStyles.title}>
          <Title
            title='Comprobantes'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <p style={{ color: '#58595B', fontSize: '24px', lineHeight: '31px', fontWeight: '400' }}>
          Selecciona el tipo de comprobante a registrar
        </p>
      </div>
      <Row className='p-5 d-flex'>
        {myPermission()?.create ? (
          <Col className='p-5'>
            <button className='botn' onClick={() => setShow({ ...show, showRegistration: true })}>
              <div className='col-auto p-4 text-center'>
                <img src={doc} alt='' srcSet='' />
                <p className='text-secondary'>Registro de comprobantes</p>
              </div>
            </button>
          </Col>
        ) : (
          <span></span>
        )}
        {myPermission()?.create ? (
          <Col className='p-5'>
            <button className='botn' onClick={() => setShow({ ...show, showClose: true })}>
              <div className='col-auto p-4 text-center'>
                <img src={doc} alt='' srcSet='' />
                <p className='text-secondary'>Comprobante de cierre de ejercicio</p>
              </div>
            </button>
          </Col>
        ) : (
          <span></span>
        )}
        {myPermission()?.create ? (
          <Col className='p-5'>
            <button className='botn' onClick={() => setShow({ ...show, showReplace: true })}>
              <div className='col-auto p-4 text-center'>
                <img src={doc} alt='' srcSet='' />
                <p className='text-secondary'>Reemplazo de terceros</p>
              </div>
            </button>
          </Col>
        ) : (
          <span></span>
        )}
      </Row>
      <div></div>
    </div>
  );
  return show.showRegistration ? (
    <VoucherRegistration setShow={setShow} />
  ) : show.showClose ? (
    <ClosingVoucher setShow={setShow} />
  ) : show.showReplace ? (
    <CodeReplace setShow={setShow} />
  ) : (
    principalRender
  );
}
export default Vouchers;
