import {
  GET_ENTRY_BY_CONSIGN,
  GET_ENTRY_BY_DONATION,
  GET_ENTRY_BY_PURCHASE,
  GET_WAREHOUSE_LIST,
  GET_MOVEMENTS_INVENTORY,
  GET_MOVEMENTS_TYPES,
  GET_ARTICLES_IN_WAREHOUSE,
  GET_ARTICLES_IN_TRANSFER_WAREHOUSE,
  GET_ARTICLES_FOR_EACH_WAREHOUSE,
  GET_MOVEMENT_FOR_EACH_ARTICLE,
  GET_WAREHOUSE_DETAILS,
  GET_REPORT_DETAILS,
  GET_OUTPUT_ARTICLES,
  LOADING,
  INVENTORY_ADJUSTMENT_TYPE,
  OUTPUT_ADJS_LOADING,
} from "../actions/actionTypes";

const initialState = {
  entryByPurchaseList: [],
  entryByPurchaseList_total: 0,
  entryByConsign: [],
  entryByConsignTotal: 0,
  entryByDonation: [],
  entryByDonationTotal: 0,
  wareHouseList: [],
  movementsList: [],
  movementsTypes: [],
  movementsList_total: 0,
  articles: [],
  articles_total: 0,
  articles_transfer: [],
  articles_transfer_total: 0,
  articles_for_each_wh: [],
  articles_for_each_wh_total: 0,
  movements: [],
  movements_total: 0,
  wareHouseDetails: [],
  reportList: [],
  reportDetail: {},
  outPutLotsArticles: [],
  lotsTotal: 0,
  loading: false,
  entryP_loading: false,
  inventoryS_loading: false,
  movements_loading: false,
  entryByDonation_loading: false,
  entryByConsign_loading: false,
  movementsA_loading: false,
  articlesWhLoading: false,
  inventoryAdjustmentTypes: [],
  outputAdjsLoading: false,
};
export const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENTRY_BY_PURCHASE:
      return {
        ...state,
        entryByPurchaseList: action.payload,
        entryByPurchaseList_total: action.total,
        entryP_loading: action.loading,
      };
    case GET_REPORT_DETAILS:
      return {
        ...state,
        reportDetail: action.payload,
      };
    case GET_ENTRY_BY_CONSIGN:
      return {
        ...state,
        entryByConsignTotal: action.total,
        entryByConsign: action.payload,
        entryByConsign_loading: action.loading,
      };
    case GET_ENTRY_BY_DONATION:
      return {
        ...state,
        entryByDonation: action.payload,
        entryByDonationTotal: action.total,
        entryByDonation_loading: action.loading,
      };
    case GET_WAREHOUSE_LIST:
      return {
        ...state,
        wareHouseList: action.payload,
        inventoryS_loading: action.loading,
      };
    case GET_MOVEMENTS_INVENTORY:
      return {
        ...state,
        movementsList: action.payload,
        movementsList_total: action.total,
        movements_loading: action.loading,
      };
    case GET_MOVEMENTS_TYPES:
      return {
        ...state,
        movementsTypes: action.payload,
      };
    case GET_WAREHOUSE_DETAILS:
      return {
        ...state,
        wareHouseDetails: action.payload,
      };
    case GET_ARTICLES_IN_WAREHOUSE:
      return {
        ...state,
        articles: action.payload,
        articles_total: action.total,
        articlesWhLoading: action.loading,
      };
    case GET_ARTICLES_IN_TRANSFER_WAREHOUSE:
      return {
        ...state,
        articles_transfer: action.payload,
        articles_transfer_total: action.total,
      };
    case GET_ARTICLES_FOR_EACH_WAREHOUSE:
      return {
        ...state,
        articles_for_each_wh: action.payload,
        articles_for_each_wh_total: action.total,
      };
    case GET_MOVEMENT_FOR_EACH_ARTICLE:
      return {
        ...state,
        movements: action.payload,
        movements_total: action.total,
        movementsA_loading: action.loading,
      };
    case GET_OUTPUT_ARTICLES:
      return {
        ...state,
        lotsTotal: action.lotsTotal,
        outPutLotsArticles: action.payload,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case OUTPUT_ADJS_LOADING:
      return {
        ...state,
        outputAdjsLoading: action.loading,
      };
    case INVENTORY_ADJUSTMENT_TYPE:
      return {
        ...state,
        inventoryAdjustmentType: action.payload,
      };
    default:
      return state;
  }
};
