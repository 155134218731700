import { GET_INVOICES } from "../actions/actionTypes";

const initialState = {
    results : [],
    amountTotal : 0,
    row_total :0,

};
export const cashReceipttReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INVOICES:
            return {
                ...state,
                results: action.payload,
                amountTotal: action.total,
                row_total: action.rows
            };
        default:
            return state;
    }
};
