import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../Hooks/useFetch';

import { MultiTabComponent } from '../../components/Tabs/MultiTabComponent';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Post } from './Post';

export const ReportMenuTabs = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const { results: attentionGroup, trigger: getAttentionGroup } = useGetMethod();

  const [tab, setTab] = useState({
    tabName: '',
  });

  const [tabs, setTabs] = useState([]);
  const [attentionGroupId, setAttentionGroupId] = useState(attentionGroup?.results?.[0]?.id);

  useEffect(() => {
    getAttentionGroup({
      url: '/medical/attentionGroup/',
      objFilters: { eaccount: eaccount, status: 'enabled' },
      token: token,
    });
  }, [getAttentionGroup, eaccount, token]);

  useEffect(() => {
    setTabs(
      attentionGroup?.results?.map((item, index) => {
        return {
          id: index + 1,
          tabName: item.name,
          tabContent: <Post tab={tab} attentionGroupId={attentionGroupId} />,
          onFunction: () => {
            setAttentionGroupId(item.id);
            setTab({ tabName: item.name });
          },
        };
      }),
    );
  }, [attentionGroup, tab, attentionGroupId]);

  useEffect(() => {
    if (attentionGroup?.results?.length > 0) {
      setAttentionGroupId(attentionGroup?.results?.[0]?.id);
    }
  }, [attentionGroup]);

  return (
    <>
      <OrdGenericTemplate
        title={'Informes de rendimiento'}
        titleSize={12}
        titleClass='my-2 mb-4'
        className='mx-2 pb-2 my-2'
      >
        <MultiTabComponent tabs={tabs} />
      </OrdGenericTemplate>
    </>
  );
};
