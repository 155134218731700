import moment from 'moment';
import 'moment/locale/es';


export const getMessagesES = () => {
    moment.locale('es')
    return {
        allDay: 'Todo el día',
        previous: '<',
        next: '>',
        today: moment(new Date()).format('MMMM YYYY').toUpperCase(),
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        agenda: 'Agenda',
        date: 'Fecha',
        time: 'Hora',
        event: 'Evento',
        noEventsInRange: 'No hay eventos en este rango',
        showMore: total => <div className="text-end" style={{ fontSize: 10 }}>+ Ver más ({total})</div>
    }
}