import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Close from '../../assets/img/icons/checkXblanco.svg';
import '../Layouts/modal.scss';
import { connect } from 'react-redux';
class ModalCreateRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onChange = e => {
    let name = e.target.name;
    const value = e.target.value;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state[name] = value;
  };
  render() {
    return (
      <Modal {...this.props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header>
          <div className='div-header-modal'>
            <h2>{this.props.title}</h2>
            <img
              src={Close}
              alt='Cerrar'
              className='icons-svg close-icon-modal hoverPointer'
              onClick={this.props.onHide}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>{this.props.children}</div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end border-0'>
          <Button onClick={this.props.onHide} className='btn-primary px-5 fw-bold'>
            Cancelar
          </Button>
          <Button onClick={this.props.submit} className='btn-primary px-5 fw-bold mx-5'>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateRecord);
