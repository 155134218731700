import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';

import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import InCash from './Tabs/InCash';
import OnCredit from './Tabs/OnCredit';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function Settlements() {
  const [tabActive, setTabActive] = useState('in-cash');

  const renderComponent = () => {
    return (
      <>
        <OrdGenericTemplate
          showBottomBorder={false}
          title={'Liquidaciones'}
          titleSize={12}
        >
          <div className='asistencialDateColorIcon' style={{ width: '95%' }}>
            <Row className={tableStyles.rowTabStyle}>
              <Col
                xs={2}
                className={`${tabActive === 'in-cash' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
                onClick={() => setTabActive('in-cash')}
              >
                <span>De contado</span>
              </Col>

              <Col
                xs={2}
                className={`${tabActive === 'on-credit' ? tableStyles.tabActiveAdmition : tableStyles.tabInactiveAdmition}`}
                onClick={() => setTabActive('on-credit')}
              >
                <span>A crédito</span>
              </Col>

              <Col xs={8} style={{ borderBottom: '1px solid #00B4CC' }}></Col>
            </Row>
            
            {tabActive === 'in-cash' && <InCash />}
            {tabActive === 'on-credit' && <OnCredit />}
          </div>
        </OrdGenericTemplate>
      </>
    );
  };

  return renderComponent();
}
