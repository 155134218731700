import styled from "styled-components";


const StyledTitle = styled.p`
    color: ${({ color, theme }) => color ? color : theme.colors.ordDarkBlueText};
    font-size: ${({ fontSize }) => fontSize ? fontSize : '40px'};
    padding: ${({ padding }) => padding ? padding : '0px'};
    margin: ${({ margin }) => margin ? margin : '0px'};
    text-align: ${({ textAlign }) => textAlign ? textAlign : 'auto'};
    font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'bold'};
    opacity: ${({ opacity }) => opacity ? opacity : '1'};
    `


export const Title = ({ textAlign, title, color, fontSize, padding, margin, fontWeight,opacity }) => {


    return (
        <StyledTitle  opacity={opacity} textAlign={textAlign} color={color} fontSize={fontSize} padding={padding} margin={margin} fontWeight={fontWeight}>
            {title}
        </StyledTitle>
    )
}
