import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import {
  GET_AUTH_STATUS,
  GET_WAREHOUSE_ENTRIES,
  GET_WE_PROBLEMS,
  LOADING,
  GET_PURCHASEORDER_INWAREHOUSE,
  GET_PROGRAMMING_INWAREHOUSE,
  GET_PROVIDRES_WITHPO,
  GET_WAREHOUSE_ENTRY_DETAIL,
  GET_ARTICLES_INWAREHOUSE,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getWarehouseEntries =
  (params, doafter = null) =>
  (dispatch, getState) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(params);
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-entry?${filters}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        if (doafter) {
          doafter();
        }
        dispatch({
          type: GET_WAREHOUSE_ENTRIES,
          payload: res.results,
          row_total: res.row_total,
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
      })
      .catch(() => {
        if (doafter) {
          doafter();
        }
        dispatch({
          type: LOADING,
          payload: false,
        });
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al traer el historial de entradas',
          showCancelButton: false,
        });
      });
  };

export const getWEProblems = args => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  let filters = convertFilterToString(args) ? !!args : '';
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/we-problems?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_WE_PROBLEMS,
        payload: res.results,
      });
    })
    .catch(() => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer el listado de problemas de una entrada',
        showCancelButton: false,
      });
    });
};

export const getAuthStatus = params => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(params);
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/status?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_AUTH_STATUS,
        payload: res.results,
      });
    })
    .catch(() => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer el listado de estados',
      });
    });
};

export const updateWarehouseEntry = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const bodySend = {
    id: data.id,
    we_status: data.we_status,
    we_justification: data.we_justification,
  };
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-entry`, {
    method: 'PUT',
    body: JSON.stringify(bodySend),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: `${res.message}`,
          text: `${data.we_number}`,
          showCancelButton: false,
        });
        dispatch(
          getWarehouseEntries({
            page: 1,
            perpage: 10,
            eaccount: getState().loginReducer.currentAccount.id,
            we_req_auth: 1,
          }),
        );
        if (doAfter) doAfter();
      }
    })
    .catch(() => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al actualizar la entrada',
        showCancelButton: false,
      });
    });
};

export const saveArticleInWarehouse = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehosue/link-article`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Artículo agregado',
          showCancelButton: false,
        });
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al guardar el artículo',
          showCancelButton: false,
        });
      }
      if (doAfter) doAfter();
    })
    .catch(() => {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al guardar el artículo',
        showCancelButton: false,
      });
    });
};

// get detail from warehouse entry
export const getWarehouseEntryDetail = (id_warehouse_entry, doAfter) => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-entry/${id_warehouse_entry}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_WAREHOUSE_ENTRY_DETAIL,
        payload: res.results,
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
      if (doAfter) doAfter();
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer la información de la entrada',
        showCancelButton: false,
      });
    });
};

export const get_purchaseOrders_inWarehouse = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_PURCHASEORDER_INWAREHOUSE,
    loading: true,
  });

  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/inWarehouse/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: GET_PURCHASEORDER_INWAREHOUSE,
        results: results,
        row_total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_PURCHASEORDER_INWAREHOUSE,
        payload: [],
        row_total: 0,
        loading: false,
      });
      console.error(err);
    });
};

export const get_purchaseOrder_Articles_inWarehouse = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/articles_inWarehouse/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: GET_ARTICLES_INWAREHOUSE,
        results: results,
        row_total: row_total,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const get_purchaseOrder_Programming_inWarehouse = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/programming_inWarehouse/?${filters}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    },
  )
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: GET_PROGRAMMING_INWAREHOUSE,
        results: results,
        row_total: row_total,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const providersWithPO = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/purchaseOrder/providersWithPO/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(results => {
      dispatch({
        type: GET_PROVIDRES_WITHPO,
        results: results,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const creat_entryWarehouse =
  (data, executeThisAfter = null) =>
  (dispatch, getState) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-entry`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        //console.log(res)
        dispatch({
          type: LOADING,
          payload: false,
        });
        if (res.success) {
          if (executeThisAfter) {
            executeThisAfter();
          }
          customSwaltAlert({
            icon: 'success',
            title: '<span style=color:#01A0F6>Entrada registrada<span>',
            html: <p>{res.code_entry}</p>,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
            showCancelButton: false,
          });
        }
      })
      .catch(err => {
        dispatch({
          type: LOADING,
          payload: false,
        });
        console.error(err);
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'No se pudo registrar la entrada' + err,
          footer: 'Si el problema persiste comuníquese con un asesor.',
          showCancelButton: false,
        });
      });
  };

export const cleanWarehouseEntries = () => dispatch => {
  dispatch({
    type: GET_WAREHOUSE_ENTRIES,
    payload: [],
  });
};
