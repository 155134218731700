import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import specialTableStyles from '../Layouts/SpecialTable/SpecialTable.module.scss';
import '../Layouts/customTabs.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopUpExtends';
import '../TableUsers/tableUserStyle.scss';
import pendingStyles from './AuctionPending.module.scss';
import detailStyles from './CurrentAuctionDetails.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';

import { Tooltip } from '@material-ui/core';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import Back from '../../assets/img/icons/atras-icon.svg';
import auctionDetailExpand from '../../assets/img/icons/auctionDetailExpand.svg';
import iconHandshake from '../../assets/img/icons/iconHandshake.svg';
import iconPrint from '../../assets/img/icons/iconPrint.svg';
import iconShopCart from '../../assets/img/icons/iconShopCart.svg';
import specialTableArrow from '../../assets/img/icons/specialTableArrow.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import specialStyles from '../Layouts/SpecialTable/SpecialTable.module.scss';

import {
  getPdfTest,
  indauction_get_one,
  quotation_filter,
} from '../../actions/IndividualAuctionActions';
import { get_many_purchase_orders } from '../../actions/purchaseOrderActions';

import { message } from '../../helpers/helpers';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { PdfViewer } from '../Layouts/PdfViewer';
import SpecialTable from '../Layouts/SpecialTable/SpecialTable';
import DetailsModalConvenio from './DetailsModalConvenio';
import DetailsModalCotizacion from './DetailsModalCotizacion';
import DetailsModalPurchaseSummary from './DetailsModalPurchaseSummary';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

function priority(key) {
  switch (key) {
    case 'normal':
      return 'Normal';
    case 'priority':
      return 'Prioritaria';
    default:
      return '-';
  }
}

// function auctionType (key){
//     switch(key){
//         case "consolidationreq":
//             return "Consolidación de requisiciones ";
//         case "buyin":
//             return "Compra individual";
//         default:
//             return "-";
//     }
// }

function payConditions(key) {
  switch (key) {
    case 'prepayment':
      return 'Pago anticipado';
    case 'delivery':
      return 'Pago contraentrega';
    case 'pay20':
      return 'Pago a 20 días';
    case 'pay30':
      return 'Pago a 30 días';
    case 'pay60':
      return 'Pago a 60 días';
    case 'pay90':
      return 'Pago a 90 días';
    default:
      return '-';
  }
}

const article_headers = [
  <p key={'special1'}>Proveedor</p>,
  <p key={'special2'}>Marca</p>,
  //<p key={"special3"}>Registro&nbsp;Sanitario</p>,
  <p key={'special4'}>Precio&nbsp;Unit.</p>,
  <p key={'special5'} className={specialStyles.smallChild}>
    Cant.
  </p>,
  <p key={'special6'} className={specialStyles.smallChild}>
    IVA
  </p>,
  <p key={'special7'} className={specialStyles.smallChild}>
    Descuento
  </p>,
  <p key={'special8'}>Valor&nbsp;Total</p>,
  <p key={'special9'}>
    <img src={iconShopCart} className={detailStyles.tableGearIcon} alt='carrito' />
  </p>,
];

function HistorialSubastasDetails(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  // const MySwal = withReactContent(Swal);
  const [showPdf, setShowPdf] = useState(false);
  const store_indauc = counter.individualAuctionReducer.selected_indauction;
  // const today = new Date();
  const [triggerMemoProvider, settriggerMemoProvider] = useState(1);
  const [collapseAll, setCollapseAll] = useState(true);
  const [base64, setBase64] = useState('');
  const token = counter.loginReducer.Authorization;
  const getPdf = async () => {
    const result = await getPdfTest(
      {
        id: props?.selectedAuction?.id,
        doc: 9,
      },
      token,
    );

    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message('error', 'Ha ocurrido un error', 'No ha sido posible cargar el documento');
      return setShowPdf(false);
    }
  };
  useEffect(() => {
    getPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [modalDetails, setmodalDetails] = useState({
    show: false,
  });

  const [modalConvenioGeneral, setmodalConvenioGeneral] = useState({
    show: false,
  });

  const [modalConvenio, setmodalConvenio] = useState({
    show: false,
  });

  const [articleSearch, setarticleSearch] = useState('');

  useEffect(() => {
    if (props.selectedAuction?.id) {
      dispatch(indauction_get_one(props.selectedAuction.id));
      dispatch(quotation_filter({ indauc: props.selectedAuction.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const [listProviders, listProvidersLite] = useMemo(() => {
    let templist = [];
    let liteList = [];
    if (Array.isArray(counter.individualAuctionReducer.quotations)) {
      counter.individualAuctionReducer.quotations.forEach((x, index) => {
        const isOnHold = !!(x.status === 'requested');
        const validityformat = x.quote_validity ? new Date(x.quote_validity) : '';

        if (x.provider_ref) {
          templist.push(
            <div key={index} className={detailStyles.providerTable__Row}>
              <div
                className={`${detailStyles.providerTable__ProviderName} ${isOnHold ? detailStyles.clearGrayText : ''}`}
              >
                {x.provider_ref.name}
              </div>
              <div className={`${detailStyles.f1}`}>
                <b className={isOnHold ? detailStyles.clearGrayText : ''}>
                  {isOnHold ? '-' : formatter.format(x.total)}
                </b>
              </div>
              <div className={`${detailStyles.f1} ${isOnHold ? detailStyles.clearGrayText : ''}`}>
                {/* {isOnHold? "-" : x.quote_validity} */}
                {isOnHold
                  ? '-'
                  : x.quote_validity
                    ? validityformat.getDate() +
                      '/' +
                      validityformat.getMonth() +
                      '/' +
                      validityformat.getFullYear()
                    : '-'}
              </div>
              <div className={`${detailStyles.f1} ${isOnHold ? detailStyles.clearGrayText : ''}`}>
                {isOnHold ? '-' : payConditions(x.pay_conditions)}
              </div>
              <div className={detailStyles.providerTable__Icon}>
                {/* <img className={detailStyles.tableGearIcon} src={engraneGris} alt="engraneGris" /> */}
                <CustomPopup
                  triggerSrc={threeDots}
                  showDetails={true}
                  position='left'
                  textDetails='Ver cotización'
                  showDetailsEvent={() => setmodalDetails({ show: true, Qid: x.id })}
                  purchaseEvent={
                    () =>
                      dispatch(
                        get_many_purchase_orders({
                          //id_auction: props.selectedAuction.id,
                          //id_provider: ,
                          id_quotation: x.id,
                        }),
                      )
                    // dispatch({
                    //     type: PURCHASEORDER_MODAL3,
                    //     show:true,
                    //     codes: [x.id],
                    //     //codes should be multiple PO id's from backend given this provider's x.id and auction id
                    //     //after this onclick, get a quotation, then get all po
                    // })
                  }
                  dealEvent={() => setmodalConvenio({ show: true, quotation: x })}
                  triggerDisabled={isOnHold}
                  triggerClass={detailStyles.providerGearIcon}
                />
              </div>
            </div>,
          );

          //for the modal in the handshake button at the top
          if (!isOnHold) {
            liteList.push(
              <tr key={index} className='hover-table-row'>
                <td className={`col-md-2`}>
                  <input
                    name={'selectConvenio'}
                    type='radio'
                    checked={modalConvenio?.quotation?.id === x.id}
                    onClick={() => {
                      setmodalConvenio({ ...modalConvenio, quotation: x });
                      settriggerMemoProvider(triggerMemoProvider + 1);
                    }}
                  />
                </td>
                <td className={`col-md-3`}>
                  <p className={`m-0 text-uppercase`}>
                    {
                      //x.provider_ref.name
                      x.quotation_number
                    }
                  </p>
                </td>
                <td className={`col-md-7`}>
                  <p className={`m-0 text-start`}>{x.provider_ref.name}</p>
                </td>
              </tr>,
            );
          }
        }
      });
    }
    //return both lists, used for 2 different things but created form the same process
    return [templist, liteList];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.individualAuctionReducer.quotations, triggerMemoProvider]);

  const renderProviders = () => (
    <div className={detailStyles.sectionContainer}>
      <p className={detailStyles.sectionTitle}>Lista de proveedores</p>

      <div className={`${detailStyles.providerTable} ${detailStyles.tableMargin}`}>
        <div className={detailStyles.providerTable__Header}>
          <div className={`${detailStyles.f3}`}>Proveedores</div>
          <div className={`${detailStyles.f1}`}>Total cotizado</div>
          <div className={`${detailStyles.f1}`}>Validez de cotización</div>
          <div className={`${detailStyles.f1}`}>Condiciones de pago</div>
          <div className={`${detailStyles.providerTable__Icon}`}>&nbsp;</div>
        </div>

        <div className={detailStyles.providerTable__Body}>{listProviders}</div>
      </div>
    </div>
  );

  const specialTableFooter = () => {
    return (
      <div className={pendingStyles.f1}>
        <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerTitle}`}>
          Última Compra
        </p>
        <div className='d-flex mx-1'>
          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Proveedor
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Marca
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f3}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Precio Unitario
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>

          <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
            <p className={`${IndividualStyles.crudModalLabel} ${specialTableStyles.footerLabels}`}>
              Cantidad
            </p>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={'?'}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const stringAIncludesB = (A, B) => {
    let AA = String(A).toLowerCase();
    let BB = String(B).toLowerCase();

    return AA.includes(BB);
  };

  const [listArticles, listArticlesDenied] = useMemo(() => {
    let acceptedList = [];
    let deniedList = [];

    //for each article make a table
    if (
      Array.isArray(counter.individualAuctionReducer.selected_indauction?.article_list) &&
      Array.isArray(counter.individualAuctionReducer.quotations)
    ) {
      counter.individualAuctionReducer.selected_indauction.article_list.forEach((x, index) => {
        //filter by search
        if (
          stringAIncludesB(x.article?.description, articleSearch) ||
          stringAIncludesB(x.article?.id, articleSearch)
        ) {
          //theme
          let mytheme = '';
          if (x.status_articles === 'reject') {
            mytheme = 'gray';
          } else {
            if (x.article?.req_authorization) {
              mytheme = 'orange';
            } else {
              mytheme = index % 2 === 0 ? 'blue' : 'darkblue';
            }
          }

          //supers
          let superColumns = [
            <p key='super1'>
              {index + 1}
              <img src={specialTableArrow} className={specialTableStyles.arrow} alt='>' />
              {x.article?.description}
            </p>,
            <p key='super2'>{x.article?.id}</p>,
            <p key='super3'>{x.qty_article} und</p>,
            <p key='super4'>
              {mytheme === 'orange' ? (
                <img src={AlertTriangle} className={detailStyles.filterWhite} alt='alert' />
              ) : (
                <span>&nbsp;</span>
              )}
            </p>,
          ];

          //headers are equal for all

          //body
          let bodyRows = [];
          //find providers of the current article to fill table body. gotta look into every article of every quotation

          //for each quotation
          counter.individualAuctionReducer.quotations.forEach(y => {
            if (y.status === 'solved') {
              // filter by only solved quotations

              let gottenthing = null;

              //for each quotation_article
              if (Array.isArray(y.quotation_article_ref)) {
                const tempobj = y.quotation_article_ref.find(z => z.id_article === x.id_article);
                if (!!tempobj && !!gottenthing === false) {
                  gottenthing = {
                    ...tempobj,
                    provider_id: y.provider,
                    provider_name: y.provider_ref?.name,
                    quotation_total: y.total,
                  };
                }
              }

              //if there the current article of the auction is also inside the quotation, then...
              if (gottenthing) {
                bodyRows.push(
                  <div key={'article' + gottenthing.id_article + ' ' + gottenthing.provider_id}>
                    <p>{gottenthing.provider_name}</p>
                    <p>{gottenthing.brand}</p>
                    {/* <p>{gottenthing.health_register}</p> */}
                    <p>{formatter.format(gottenthing.unit_price)}</p>
                    <p className={specialStyles.smallChild}>{gottenthing.qty_article}</p>
                    <p className={specialStyles.smallChild}>
                      {gottenthing.iva ? gottenthing.iva + '%' : '-'}
                    </p>
                    <p className={specialStyles.smallChild}>
                      {gottenthing.discount ? gottenthing.discount + '%' : '-'}
                    </p>
                    <p>
                      {formatter.format(
                        gottenthing.unit_price * gottenthing.qty_article +
                          gottenthing.unit_price *
                            gottenthing.qty_article *
                            gottenthing.iva *
                            0.01 -
                          gottenthing.unit_price *
                            gottenthing.qty_article *
                            gottenthing.discount *
                            0.01,
                      )}
                    </p>
                    <p>&nbsp;</p>
                  </div>,
                );
              }
            } // filter by only solved quotations
          });

          if (x.status_articles === 'reject') {
            deniedList.push(
              <SpecialTable
                key={'specialtableDenied' + index}
                theme={mytheme}
                collapse={collapseAll}
                superheaders={superColumns}
                headers={article_headers}
                body={bodyRows}
                footer={specialTableFooter(x)}
              />,
            );
          } else {
            acceptedList.push(
              <SpecialTable
                key={'specialtable' + index}
                theme={mytheme}
                collapse={collapseAll}
                superheaders={superColumns}
                headers={article_headers}
                body={bodyRows}
                footer={specialTableFooter(x)}
              />,
            );
          }
        }
        //only add those in filter
      });
    }

    //return the entire thing
    return [acceptedList, deniedList];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    counter.individualAuctionReducer.selected_indauction,
    counter.individualAuctionReducer.quotations,
    collapseAll,
    articleSearch,
    triggerMemoProvider,
  ]);

  return (
    <div className={tableStyles.container}>
      {!!counter.individualAuctionReducer.selected_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={`d-flex ${detailStyles.titleBlock}`}>
        <img
          alt='x'
          src={Back}
          className={detailStyles.backIcon}
          onClick={() => props.setDetails({ show: false })}
        />
        <h1 className={detailStyles.mainTitle}>
          {store_indauc?.description ? store_indauc.description : 'Subasta'}
        </h1>
      </div>

      <div className={` ${detailStyles.bigContainer}`}>
        <div id='popup-root' />

        <div className={`${IndividualStyles.container2}`}>
          <div className={`d-flex`}>
            <div className={`d-flex ${detailStyles.underMainTitle} ${pendingStyles.f1}`}>
              <p>
                <span className={detailStyles.blueText}>#{store_indauc.auction_number}</span>
              </p>
            </div>

            <div className={`d-flex flex-row-reverse ${pendingStyles.f1}`}>
              <Tooltip title='Crear Convenio'>
                <img
                  className={detailStyles.titleIcons}
                  src={iconHandshake}
                  alt='handshake'
                  onClick={() => setmodalConvenioGeneral({ show: true })}
                />
              </Tooltip>
              {/* /* -------------------------------- PDF Viwer ------------------------------- */}
              <ModalNew
                title='Detalle de subasta'
                show={showPdf}
                btnNoName={'Cancelar'}
                size='700'
                btnYesDisabled={false}
                onHide={() => setShowPdf(false)}
                btnNoEvent={() => setShowPdf(false)}
                btnNoDisabled={false}
              >
                <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
              </ModalNew>

              <Tooltip title='Imprimir'>
                <img
                  className={`${detailStyles.titleIcons} cursorPointer`}
                  src={iconPrint}
                  alt='imprimir'
                  onClick={() => setShowPdf(true)}
                />
              </Tooltip>
            </div>
          </div>
        </div>

        <p className={detailStyles.sectionTitle}>Información general</p>
        <div className={IndividualStyles.container2}>
          {/* row 1 */}
          <div className={`d-flex`}>
            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Fecha de vencimiento</p>
              <input
                className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputs}`}
                type='datetime-local'
                value={store_indauc.date_expiry + 'T' + store_indauc.hour_expiry}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Forma de pago</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={payConditions(store_indauc.pay_conditions)}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin}  ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Autor de la subasta</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={
                  store_indauc.user
                    ? store_indauc.user.first_name + ' ' + store_indauc.user.first_surname
                    : store_indauc.id_user
                }
                disabled={true}
              />
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
              <p className={IndividualStyles.crudModalLabel}>Autor de ordenes de compra</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={
                  store_indauc.purchaseUser
                    ? store_indauc.purchaseUser.first_name +
                      ' ' +
                      store_indauc.purchaseUser.first_surname
                    : store_indauc.purchaseO_user
                }
                disabled={true}
              />
            </div>
          </div>

          {/* row 2 */}
          <div className={`d-flex`}>
            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Fecha de creación de OC`s</p>
              <input
                className={` ${IndividualStyles.crudModalInputDate} ${IndividualStyles.registerInputs}`}
                type='datetime-local'
                value={String(store_indauc.created_atPurchaseO).replace(' ', 'T')}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Tipo de subasta</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={priority(store_indauc.priority)}
                disabled={true}
              />
            </div>

            <div
              className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f1}`}
            >
              <p className={IndividualStyles.crudModalLabel}>Dirección de entrega</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={store_indauc.warehouse?.address}
                disabled={true}
              />
            </div>

            <div className={`${pendingStyles.Item} ${pendingStyles.f1}`}>
              <p className={IndividualStyles.crudModalLabel}>Terminos y condiciones</p>
              <input
                className={`${IndividualStyles.registerInputs}`}
                type='text'
                value={store_indauc.terms}
                disabled={true}
              />
            </div>
          </div>

          {/* row 3 */}
          <div className={`d-flex`}>
            <div className={`${pendingStyles.Item} ${pendingStyles.f3}`}>
              <p className={IndividualStyles.crudModalLabel}>Observaciones</p>
              <textarea
                id='txtArea'
                rows='10'
                cols='10'
                className={IndividualStyles.textArea}
                value={store_indauc.observations}
                disabled={true}
              ></textarea>
            </div>
          </div>
        </div>

        {renderProviders()}

        <div className={detailStyles.sectionContainer}>
          <p className={detailStyles.sectionTitle}>Lista de productos ({listArticles.length})</p>

          <div className={`d-flex flex-row-reverse`}>
            <div
              className={`${detailStyles.articleControlIcon}`}
              onClick={() => setCollapseAll(!collapseAll)}
            >
              <img
                className={` ${detailStyles.toggleAll} ${collapseAll ? detailStyles.rotate : ''}`}
                src={auctionDetailExpand}
                alt='Toggle All'
              />
            </div>

            <div
              className={`
                        ${detailStyles.articleControlIcon} 
                        ${articleSearch !== '' ? detailStyles.expand : ''} 
                    `}
            >
              <input
                type='text'
                className={`
                            ${detailStyles.articleSearch} 
                            ${articleSearch !== '' ? detailStyles.expand : ''} 
                        `}
                onChange={e => setarticleSearch(e.target.value)}
              ></input>
            </div>
          </div>

          <table className={`table mb-0`}>
            <tr className='d-flex'>
              <th className={detailStyles.f3}>
                <span>&nbsp;</span>{' '}
              </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Código </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Cantidad </th>
              <th className={detailStyles.f1}>
                <span>&nbsp;</span>
              </th>
            </tr>
          </table>

          {listArticles}

          {/* zone 2 */}
          <p className={detailStyles.sectionTitle}>
            Lista de productos denegados({listArticlesDenied.length})
          </p>
          <table className={`table mb-0`}>
            <tr className='d-flex'>
              <th className={detailStyles.f3}>
                <span>&nbsp;</span>{' '}
              </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Código </th>
              <th className={`${detailStyles.f1} ${detailStyles.namesColum}`}>Cantidad </th>
              <th className={detailStyles.f1}>
                <span>&nbsp;</span>
              </th>
            </tr>
          </table>
          {listArticlesDenied}
        </div>
      </div>

      {/*zona detalles */}
      {Array.isArray(counter.individualAuctionReducer.quotations) && modalDetails.show && (
        <DetailsModalCotizacion
          show={modalDetails.show}
          Qid={modalDetails.Qid}
          onHide={() => setmodalDetails({ show: false })}
          payConditions={payConditions}
          formatter={formatter}
          parentTerms={store_indauc.terms}
        ></DetailsModalCotizacion>
      )}

      {/*zona convenio */}
      {modalConvenioGeneral.show && (
        <ModalNew
          title='Nuevo Convenio'
          show={modalConvenioGeneral.show}
          size='500'
          btnYesName='Aceptar'
          btnYesEvent={() => setmodalConvenio({ ...modalConvenio, show: true })}
          onHide={() => setmodalConvenioGeneral({ show: false })}
        >
          <GenericTableNew
            headers={[
              <th key={`1`}>&nbsp;</th>,
              <th key={`2`}>Cotización</th>,
              <th key={`3`}>Seleccionar proveedor</th>,
            ]}
          >
            {listProvidersLite}
          </GenericTableNew>
        </ModalNew>
      )}

      {modalConvenio.show && (
        <DetailsModalConvenio
          show={modalConvenio.show}
          quotation={modalConvenio.quotation}
          onHide={() => setmodalConvenio({ show: false })}
          payConditions={payConditions}
          formatter={formatter}
          parentDesc={store_indauc.description}
        ></DetailsModalConvenio>
      )}

      <DetailsModalPurchaseSummary
        hideparent={() => {
          // console.log("DetailsModalPurchaseSummary hideparent")
        }}
      />
    </div>
  );
}

export default HistorialSubastasDetails;

// Ranma | Gantz | FMA
//  | jojo | Shiori Experience
// Hellsing | Berserk | Bleach
