import { Component } from 'react';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import { showAccountInfo } from '../actions/headerActions';
import { connect } from 'react-redux';
import { logOutUser } from '../actions/loginActions';
import styles from './fullLayout.module.css';
import { Redirect } from 'react-router';

class FullLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }
  onClickHideAccountInfo = () => {
    this.props.showAccountInfo(false);
  };
  render() {
    return this.props.isAuth ? (
      <div className={styles.fullLayout}>
        <Header isAuth={this.props.isAuth}></Header>
        <div className={styles.fullLayoutBody}>
          <Sidebar
            logOutUser={() => this.props.logOutUser()}
            loginReducer={this.props.loginReducer}
          ></Sidebar>
          <div
            className={`${this.props.classWorkArea && this.props.classWorkArea} ${styles.fullLayoutWorkArea}`}
            onClick={this.onClickHideAccountInfo}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    ) : (
      <Redirect to='/login' />
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  loginReducer: state.loginReducer,
  showAccount: state.headerReducer.showAccount,
});

const mapDispatchToProps = dispatch => ({
  showAccountInfo: status => {
    dispatch(showAccountInfo(status));
  },
  logOutUser: () => {
    dispatch(logOutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(FullLayout);
