import tableStyles from './genericTable.module.scss';

function GenericTableNew(props) {
  return (
    <table
      className={`table
          ${props.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ''}
          ${props.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ''}
          `}
      style={props.style}
    >
      <thead
        className={`${tableStyles.thead} ${props.clearHeader ? '' : props.dark ? tableStyles.theadDark : tableStyles.theadLight}`}
      >
        <tr className={`${props.fontFamilyTable ? props.fontFamilyTable : ''}`}>{props.headers}</tr>
      </thead>
      <tbody>{props?.children || props?.body}</tbody>
      {!!props.footer && <tfoot>{props.footer}</tfoot>}
    </table>
  );
}

export default GenericTableNew;
