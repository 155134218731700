import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Add from '../../assets/img/icons/crearCuenta.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import tableStyles from '../Layouts/tableStyle.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';

function ModalProgramming(props) {
  const MySwal = withReactContent(Swal);

  const renderHeaders = [
    <th key={`reqTH1`}>#</th>,
    <th key={`reqTH2`}>Fecha</th>,
    <th key={`reqTH3`}>Cantidad</th>,
  ];

  const saveprogramming = () => {
    if (props.articulo.id === props.id) {
      if (Array.isArray(currentProg)) {
        if (props.articulo?.ammount - rest === 0) {
          props.setreqState({
            ...props.reqState,
            programacion: currentProg,
          });
          props.onHide();
        } else {
          MySwal.fire({
            icon: 'error',
            title: 'Cantidades programadas erroneas',
            text: 'El valor restante no es igual a cero',
            confirmButtonText: `Ok`,
            customClass: {
              confirmButton: 'order-2',
            },
          });
        }
      }
    }
  };

  //MIS COSAS --->eESTADO INICIAL
  //la funcion en el segundo espacio es la unica forma de modificar la constante en el primer espacio
  //la funcion del segundo espacio completamente re asigna el valor de la constante
  //"...programing" es igual a decir "traeme todas las propiedades de programming por separado"
  const [programming, setProgramming] = useState({
    qty_article: ' ',
    delivery_date: ' ',
    article_id: props.id,
  });

  //const[rest,Setrest]=useState(0);
  let rest = 0;
  const [currentProg, setcurrentProg] = useState([]);

  useEffect(() => {
    //Esto se activará cuando se inicie el componente
    if (Array.isArray(props.reqState?.programacion)) {
      setcurrentProg([...props.reqState.programacion]);
    }

    return () => {
      //Esto se activará cuando se cierre el componente
      //setcurrentProg([])
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // SI no se le pasa parámetros -> Solo se ectiva una vez

  // ON CLICK DEL BOTON AGREGAR
  const AddProgramming = () => {
    if (
      programming.delivery_date !== ' ' &&
      programming.qty_article !== ' ' &&
      parseInt(programming.qty_article) <= props.articulo?.ammount &&
      parseInt(programming.qty_article) !== 0 &&
      Number.isInteger(parseFloat(programming.qty_article)) &&
      props.articulo?.ammount - rest >= programming.qty_article
    ) {
      //temCurrentProg es lo que tengo guardado en currentProg
      let temCurrentProg = currentProg;

      //agrega programing al array temCurrentProg
      temCurrentProg.push(programming);

      //sobre escribir mis cosas con temCurrentProg
      setcurrentProg(temCurrentProg);

      //resetear programming a default
      setProgramming({
        qty_article: ' ',
        delivery_date: ' ',
        article_id: props.id,
      });
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Error al programar entrega',
        text: 'Por favor tenga presente que la fecha de entrega y la cantidad son obligatorios, no debe ingresar valores superiores a la cantidad total solicitada.',
        confirmButtonText: `Ok`,
        customClass: {
          confirmButton: 'order-2',
        },
      });
      setProgramming({
        qty_article: ' ',
        delivery_date: ' ',
        article_id: props.id,
      });
    }
  };

  const renderTable = () => {
    let table = [];
    const data = currentProg;
    let num = 0;
    let ammount = 0;
    if (Array.isArray(data)) {
      table = data.forEach(x => {
        if (
          !!x.delivery_date &&
          !!x.qty_article &&
          x.qty_article > 0 &&
          x.article_id === props.id
        ) {
          if (props.articulo?.ammount >= parseInt(x?.qty_article) + parseInt(ammount)) {
            num++;
            ammount = parseInt(x?.qty_article) + parseInt(ammount);
            //Setrest(rest+ammount);
            rest = ammount;
            return (
              <tr key={'programming' + num}>
                <td>{num}</td>
                <td>{x.delivery_date}</td>
                <td>{x.qty_article}</td>
              </tr>
            );
          } //else{
        }
      });
    }
    return table;
  };

  return (
    <ModalNew
      title={props.title}
      show={props.show}
      size='lg'
      btnYesEvent={() => saveprogramming()}
      btnYesName='Grabar programación'
      onHide={props.onHide}
    >
      <div
        id='arriba'
        className={`${IndividualStyles.backgroundModal} ${IndividualStyles.paddingButton}`}
      >
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <label className={`${IndividualStyles.subtitle} `}>Información del producto</label>
        <div className='d-flex'>
          <div className={` ${IndividualStyles.ItemSmall}`}>
            <p className={IndividualStyles.crudModalLabelGris}>Producto</p>
            <input
              className={` ${IndividualStyles.registerInputsBlue}`}
              name='auction_title'
              type='text'
              value={props.articulo?.description}
              readOnly
            />
          </div>

          <div className={`${IndividualStyles.inputMarginleft} ${IndividualStyles.ItemTiny2}`}>
            <p className={` ${IndividualStyles.crudModalLabelGris}`}>Cantidad</p>
            <input
              className={` ${tableStyles.crudModalInputDate} ${IndividualStyles.registerInputsBlue}`}
              name='amount'
              type='text'
              value={props.articulo?.ammount}
              readOnly
            />
          </div>

          <div className={` ${IndividualStyles.ItemTiny2}`}>
            <p className={` ${IndividualStyles.crudModalLabelGris}`}>Código </p>
            <input
              className={`${IndividualStyles.styleSelect} ${IndividualStyles.registerInputsBlue} `}
              name='code'
              type='text'
              value={props.articulo?.id}
              readOnly
            />
          </div>
        </div>
      </div>
      {/* ============================= ROW 2 =================================== */}
      <div
        id='bajo'
        className={`${IndividualStyles.backgroundModal_new} ${IndividualStyles.paddingButton}`}
      >
        <label className={`${IndividualStyles.subtitle}`}>Información de entrega</label>
        <div className='d-flex'>
          <div className={`${IndividualStyles.ItemSmall1}`}>
            <p className={tableStyles.crudModalLabel}>Fecha de entrega</p>
            <input
              className={`${IndividualStyles.registerInputsBlue}`}
              name='delivery_date'
              type='date'
              value={programming.delivery_date}
              onChange={e =>
                setProgramming({
                  ...programming,
                  delivery_date: e.target.value,
                })
              }
              min={new Date().toISOString().slice(0, 10)}
            />
          </div>
          <div
            className={` ${IndividualStyles.ItemTiny_cantidad} ${IndividualStyles.inputMarginleft}`}
          >
            <p className={` ${IndividualStyles.crudModalLabel}`}>Cantidad</p>
            <input
              className={`${IndividualStyles.registerInputsGris}`}
              name='qty'
              type='number'
              value={programming.qty_article}
              onChange={e =>
                setProgramming({
                  ...programming,
                  qty_article: e.target.value,
                })
              }
              min={0}
            />
          </div>
          <div className={`${IndividualStyles.Aling} ${IndividualStyles.ItemTiny2}`}>
            <span> ‏‏‎</span>
            <img
              src={Add}
              onClick={() => AddProgramming()}
              alt='añadir'
              className={`${IndividualStyles.size} ${IndividualStyles.btn} ${IndividualStyles.imagePadding2}`}
            />
          </div>
        </div>
      </div>
      <div className={`${IndividualStyles.container3} ${IndividualStyles.inputMarginleft_new}`}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {renderTable()}
        </GenericTableNew>

        <div className={IndividualStyles.bottom}></div>
      </div>
      <div className='d-flex col-sm-2'>
        <div className={`${IndividualStyles.inputMarginleft} ${IndividualStyles.inputAlling}`}>
          <p className={tableStyles.crudModalLabel}>Restante</p>
          <input
            className={`${IndividualStyles.registerInputsBlue}`}
            name='auction_title'
            type='text'
            value={(props.articulo?.ammount - rest).toString() + ' und'}
            disabled={true}
          />
        </div>
      </div>
    </ModalNew>
  );
}

export default ModalProgramming;
