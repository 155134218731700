import { ACTIVE_ID} from "../actions/actionTypes";


const initialState = {
    id:''
};

export const patientActiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_ID:
            return {
                id: action.payload
            };
            
        default:
            return state;
    }
};