import { useState } from 'react';

//components
import { OrdGenericTemplate } from './../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { SubscriptionsPage } from './../TabsProviders/';

const SupplierOrders = () => {
  const [newOrdenActive, setNewOrdenActive] = useState(false);

  return (
    <OrdGenericTemplate className=' mt-4 mx-2' title={'Órdenes con saldo pendiente'} titleSize={12}>
      <div
        className='asistencialDateColorIcon'
        style={{ width: '94%' }}
        onClick={() => {
          newOrdenActive && setNewOrdenActive(false);
        }}
      >
        <SubscriptionsPage />
      </div>
    </OrdGenericTemplate>
  );
};

export default SupplierOrders;
