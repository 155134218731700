import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import {
  getClient,
  getContracts,
  getReceiptInvoices,
} from '../../actions/receiptOfInvoicesActions';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { Title } from '../../shared';
import DocSignature from './docSignature';

function FilingInvoices() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    show: false,
    type: '',
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    filing_status: 'unfiled',
    client: '',
    contract: '',
    invoice: '',
    eaccount: storage.loginReducer.currentAccount.id,
    status: 'received',
  });

  const [trigger, setTrigger] = useState(1);
  useEffect(() => {
    dispatch(getReceiptInvoices(filters));
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
    if (filters.client !== '') {
      dispatch(
        getContracts({ eaccount: storage.loginReducer.currentAccount.id, client: filters.client }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, trigger]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccione cliente', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsContracts = [{ key: 'default', value: '', label: 'Seleccione contrato', id: '' }];
  if (Array.isArray(storage.invoiceReducer.contracts)) {
    storage.invoiceReducer.contracts.forEach(item => {
      optionsContracts.push({
        value: item.id,
        label: item.contract_name,
        key: item.id + 'clients',
      });
    });
  }

  const header = [
    <th key={1} className='text-center'>
      No. Acta{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha facturación
    </th>,
    <th key={3} className='text-center'>
      NIT
    </th>,
    <th key={4} className='text-center'>
      Empresa
    </th>,
    <th key={5} className='text-center'>
      Valor
    </th>,
    <th key={6} className='text-center'>
      ㅤ
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.invoices)) {
      table = storage.invoiceReducer.invoices.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-uppercase'>{x.sequence}</td>
            <td>{x.exp_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
            <td>{x.clientNit}</td>
            <td>{x.clientName}</td>
            <td>{x?.total_amount ? '$' + numberWithCommas(Number(x.total_amount)) : ''}</td>
            <td className='px-2'>
              <CustomPopupExtend
                triggerClass={tableStyles.w15px}
                noHover
                triggerSrc={Filtrar}
                showRadFis={true}
                radFisEvent={() => setDetails({ show: true, type: 'P', receiptRecord: x.id })}
                showRadDig={true}
                radDigEvent={() => setDetails({ show: true, type: 'V', receiptRecord: x.id })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const principalRender = (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {storage.invoiceReducer.loading_receipt_invoice && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={tableStyles.title}>
        <Title
          title='Radicación de actas'
          onClickIcon={() => {
            history.push('/cartera/inicio');
          }}
        />
      </div>

      <div className='d-flex gap-3 align-items-center'>
        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsClient}
              onChange={e =>
                setFilters({
                  ...filters,
                  client: e.value,
                })
              }
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
            />
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>Contrato</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              options={optionsContracts}
              onChange={e =>
                setFilters({
                  ...filters,
                  contract: e.value,
                })
              }
              styles={customSelectNewDark}
              isDisabled={filters.client === ''}
            />
          </form>
        </div>

        <div className={tableStyles.containerSelect}>
          <form onSubmit={e => handleSearch(e)}>
            &nbsp;<label className={tableStyles.stylesLabel}>No. Factura</label>
            <input
              placeholder='Escribir...'
              className={IndividualStyles.registerInputsGris}
              type='text'
              value={filters.invoice}
              onChange={e =>
                setFilters({
                  ...filters,
                  invoice: e.target.value,
                })
              }
            ></input>
          </form>
        </div>

        <div>
          <form onSubmit={e => handleSearch(e)}>
            <i>
              <img
                style={{ width: '25px' }}
                className={`${tableStyles.cursorPointer} ${tableStyles.iconSvg}`}
                src={Lupa}
                alt=''
                onClick={() => setTrigger(trigger + 1)}
              />
            </i>
          </form>
        </div>
      </div>

      <GenericTable headers={header} dark={true}>
        {renderList()}
      </GenericTable>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.invoices ? filters.page : ''}
            {' de '}
            {Math.ceil(storage.invoiceReducer?.invoices_total / filters.perpage)
              ? Math.ceil(storage.invoiceReducer.invoices_total / filters.perpage)
              : ''}{' '}
            ({storage.invoiceReducer.invoices_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.invoices_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );
  return details.show ? (
    <DocSignature
      selectedRecord={details.receiptRecord}
      show={details.show}
      type={details.type}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}
export default FilingInvoices;
