import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Lupa from '../../assets/img/icons/lupa.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Pagination from 'react-js-pagination';
import threeDots from '../../assets/img/icons/threeDots.svg';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Tooltip } from '@material-ui/core';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { useHistory } from 'react-router-dom';
import ModalNew from '../../components/Layouts/ModalNew';
import {
  getListCompanies,
  getHistoryStatus,
  getHistory,
  getInvoiceTypes,
  getGenerateZip,
} from '../../actions/billingActions';
import { Title } from '../../shared';
import { SupportVisualizer } from '../../components/AreaManagement/SupportVisualizer';
import { customSwaltAlert, getEnvs } from "../../helpers";
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,
});

function BillingHistory() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [showPdf, setShowPdf] = useState(false);
  const [file, setFile] = useState('')


  const [filters, setFilters] = useState({
    eaccount: counter.loginReducer.currentAccount.id,
    //created_by: counter.loginReducer.user_data.id,
    page: 1,
    perpage: 10,
  });

  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'historyInv',
  );

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/categoryselect');
    }
    dispatch(
      getListCompanies({
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getInvoiceTypes({
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      getHistoryStatus({
        type: 'invoiceHis',
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getHistory(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  let optionCompanies = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(counter.billingReducer.companies)) {
    counter.billingReducer.companies.forEach(item => {
      optionCompanies.push({
        key: item.id,
        value: item.id,
        label: item.copyName,

        id: item.id,
        companyType: item.companyType,
        copyName: item.copyName,
        notification_email: item.notification_email,
        Nit: item.Nit,
        address: item.address,
        phone: item.phone,
        email: item.email,
        cityId: item.cityId,
        city: item.city,
      });
    });
  }

  let optionStatus = [{ key: 'default', value: '', label: 'Seleccionar...', name: '' }];
  if (Array.isArray(counter.billingReducer.billing_history_status)) {
    counter.billingReducer.billing_history_status.forEach(item => {
      optionStatus.push({
        key: item.id,
        value: item.id,
        label: item.value,
        id: item.id,
        name: item.name,
        prefix: item.prefix,
        color_id: item.color_id,
        background: item.background,
        fontcolor: item.fontcolor,
      });
    });
  }

  let optionTypes = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.billingReducer.invoice_types)) {
    counter.billingReducer.invoice_types.forEach(item => {
      optionTypes.push({
        key: item.id,
        value: item.id,
        label: item.type,
      });
    });
  }

  const handleViewSupport = (invoiceId) => {
    if (invoiceId) {
      dispatch(
        getGenerateZip({
          eaccount: counter.loginReducer.currentAccount.id,
          invoiceId: invoiceId,
        })
      ).then(res => {
        if (res && res.results) {
          const base64Data = res.results;
          if (base64Data) {
            const linkSource = `data:application/zip;base64,${base64Data}`;
            const downloadLink = document.createElement('a');
            const fileName = `soporte_${invoiceId}.zip`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'No se encontraron soportes',
              text: 'No se encontraron soportes asociados a la factura',
              showCancelButton: false,
            });
          }
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'No se encontraron soportes',
            text: 'No se encontraron soportes asociados a la factura',
            showCancelButton: false,
          });
        }
      }).catch(err => {
        console.error(err.message);
      });
    }
  };


  const renderList = () => {
    let tempList = [];
    if (Array.isArray(counter.billingReducer.billing_history)) {
      counter.billingReducer.billing_history.forEach((item, index) => {
        tempList.push(
          <tr key={index}>
            <td className='text-center'>{item.sequence}</td>
            <td className='text-center'>{convertDateToLatinFormat(item.exp_date)}</td>
            <td className='text-center'>{item.clientNit}</td>
            <td className='text-center'>{item.clientName}</td>
            <td className='text-center'>{item.type}</td>
            <td className='text-center'>
              <Tooltip title={item.value}>
                <p
                  style={{
                    margin: '0 auto',
                    padding: '2px 4px',
                    borderRadius: '10px',
                    backgroundColor: `${item.status_fontcolor}1A`,
                    color: `${item.status_fontcolor}`,
                    fontWeight: 'bold',
                  }}
                >
                  {item.value ? item.value : ' - '}
                </p>
              </Tooltip>
            </td>
            <td className='text-end' style={{ paddingRight: '8px' }}>
              {formatter.format(item.total_amount)}
            </td>
            <td className='text-center cursorPointer px-2'>
              <CustomPopupExtend
                position='right center'
                className='align-self-end'
                triggerClass={`${styles.hoverPopup}`}
                triggerSrc={threeDots}
                extraButtons={[
                  {
                    text: 'Ver detalle',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      setShowPdf(true);
                      setFile(item?.file_url);
                    },
                  },
                  {
                    text: 'Ver soporte',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      handleViewSupport(item?.id); 
                    },
                  }

                ]}
              ></CustomPopupExtend>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const header3 = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha factura
    </th>,
    <th key={3} className='text-center'>
      NIT
    </th>,
    <th key={4} className='text-center'>
      Empresa
    </th>,
    <th key={5} className='text-center'>
      Tipo
    </th>,
    <th key={6} className='text-center'>
      Estado
    </th>,
    <th key={7} className='text-end px-2'>
      Valor
    </th>,
    <th key={8} className='text-center'></th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <Title
          title='Historial de facturas'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/facturacion/inicio');
          }}
        />

        <div>
          <div className='div gap-3 align-items-end'>
            <div className={`display-grid ${tableStyles.containerSelect}`}>
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>Empresa</label>
                <Select
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionCompanies.find(option => option.value === filters.client)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      client: e.id,
                    })
                  }
                  options={optionCompanies}
                  styles={customSelectNew}
                />
              </form>
            </div>

            <div className={`display-grid ${tableStyles.containerSelect}`}>
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>No. Factura</label>
                <input
                  placeholder='Escribir...'
                  className={IndividualStyles.registerInputs}
                  type='text'
                  style={{ borderColor: '#01A0F6' }}
                  value={filters.sequence}
                  onChange={e => setFilters({ ...filters, sequence: e.target.value })}
                />
              </form>
            </div>

            <div className={`display-grid ${tableStyles.containerSelect}`}>
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>Tipo</label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionTypes.find(option => option.value === filters.type)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      type: e.value,
                    })
                  }
                  options={optionTypes}
                  placeholder='Seleccionar...'
                  styles={customSelectNew}
                />
              </form>
            </div>

            <div className={`display-grid ${tableStyles.containerSelect}`}>
              <form onSubmit={e => handleSearch(e)}>
                <label className={tableStyles.crudModalLabel}>Estado</label>
                <Select
                  placeholder='Seleccionar...'
                  noOptionsMessage={() => 'No hay datos'}
                  value={optionStatus.find(option => option.name === filters.status)}
                  onChange={e =>
                    setFilters({
                      ...filters,
                      status: e.name,
                    })
                  }
                  options={optionStatus}
                  styles={customSelectNew}
                />
              </form>
            </div>

            <div className={`display-grid `}>
              <label className={tableStyles.crudModalLabel}>Fecha desde</label>

              <div className={tableStyles.deleteicon}>
                {filters.date_from ? (
                  <span onClick={() => setFilters({ ...filters, date_from: '' })}>x</span>
                ) : (
                  ''
                )}
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    style={{ color: '#005DBF', fontWeight: 'bold' }}
                    type='date'
                    value={filters.date_from}
                    onChange={e => setFilters({ ...filters, date_from: e.target.value })}
                  />
                </form>
              </div>
            </div>

            <div className={`display-grid`}>
              <label className={tableStyles.crudModalLabel}>Fecha hasta</label>
              <div className={tableStyles.deleteicon}>
                {filters.date_until ? (
                  <span onClick={() => setFilters({ ...filters, date_until: '' })}>x</span>
                ) : (
                  ''
                )}
                <form onSubmit={e => handleSearch(e)}>
                  <input
                    className={`${IndividualStyles.registerInputs} register-inputs`}
                    style={{ color: '#005DBF', fontWeight: 'bold' }}
                    type='date'
                    value={filters.date_until}
                    onChange={e => setFilters({ ...filters, date_until: e.target.value })}
                  />
                </form>
              </div>
            </div>

            <div className={`display-grid`}>
              <label className={tableStyles.crudModalLabel}>&nbsp;</label>
              <form onSubmit={e => handleSearch(e)}>
                <i>
                  <img
                    style={{ width: '25px' }}
                    src={Lupa}
                    alt=''
                    className='cursorPointer'
                    srcSet=''
                    onClick={() => {
                      setTrigger(trigger + 1);
                      setFilters({ ...filters, page: 1 });
                    }}
                  />
                </i>
              </form>
            </div>

            <div className={`display-grid`}>
              <label className={tableStyles.crudModalLabel}>&nbsp;</label>
            </div>
          </div>
        </div>
        <div>
          <GenericTable headers={header3} dark={true}>
            {renderList()}
          </GenericTable>
        </div>
        {counter.billingReducer.billing_history_loading && (
          <div className='mx-auto'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.billingReducer.billing_history_total ? filters.page : ''}
            {' de '}
            {Math.ceil(counter.billingReducer.billing_history_total / filters.perpage)
              ? Math.ceil(counter.billingReducer.billing_history_total / filters.perpage)
              : ''}{' '}
            ({counter.billingReducer.billing_history_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={counter.billingReducer.billing_history_total}
            pageRangeDisplayed={5}
            onChange={val => {
              setTrigger(trigger + 1);
              setFilters({ ...filters, page: val });
            }}
            itemClassPrev={`${paginationStyles.itemClassPrev} `}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
      <ModalNew
        title='Detalle de factura'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <SupportVisualizer file={file} />
      </ModalNew>
    </>
  );
}
export default BillingHistory;
