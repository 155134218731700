import { useEffect, useState } from 'react';
import moment from 'moment';

//styles
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';

//helpers
import { isNullOrUndefined } from 'util';
import {
  formatteDocument,
  convertMoneyFormatNew,
  customSwaltAlertAsistencial,
  generateId,
  loader,
} from '../../../../helpers';

//hooks
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useGetMethod } from '../../../../Hooks';

//components
import { Box, Drawer } from '@mui/material';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import NumberFormat from 'react-number-format';
import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import { Button, Text } from '../../../UI/atoms';

//icons
import download from '../../../../../src/assets/img/icons/downloadDoc.svg';
import threeDots from '../../../../../src/assets/img/icons/threeDots.svg';
import countNotes from '../../../../../src/assets/img/icons/countNotes.svg';
import iconClose from '../../../../../src/assets/img/icons/modalClose.svg';

import {
  formatNumberWithoutDecimals,
  getLocaleSeparators,
} from '../../../../helpers/numberFormatting';

import CustomPopupExtend from '../../../Popup/customPopUpExtends';
import CustomPopupStyles from '../../../../components/Popup/customPopup.module.scss';

const localeSeparators = getLocaleSeparators();

export const HistoryClosing = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const siteId = store.userReducer.site;
  const eaccount = store.loginReducer.currentAccount.id;
  const [headers, setHeaders] = useState([]);
  const [dailyCashClosingDate, setDailyCashClosingDate] = useState(undefined);
  const [voucherDepositTypeName, setVoucherDepositTypeName] = useState(undefined);
  const [admisionistName, setAdmisionistName] = useState(undefined);

  const [options, setOptions] = useState({
    showNotes: false,
    showImportance: false,
  });

  const [filters, setFilters] = useState({
    eaccount,
    userId,
    siteId,
    getDetail: 0,
    dateMin: moment(new Date()).format('YYYY-MM-DD'),
    dateMax: undefined,
    page: 1,
    perpage: 10,
  });

  const [filtersListCashClosingCurrency, setFiltersListCashClosingCurrency] = useState({
    userId,
    siteId,
    eaccount,
    getDetail: 1,
    page: 1,
    perpage: 10,
    depositTypeId: undefined,
  });

  const [drawerDetail, setDrawerDetail] = useState(false);
  const [drawerVoucher, setDrawerVoucher] = useState(false);
  const [triggers, setTriggers] = useState(0);
  const { colors } = useTheme();

  const {
    load: loaderListHistoryClosing,
    results: listHistoryClosing,
    trigger: getListHistoryClosing,
  } = useGetMethod();

  const {
    results: listDepositType,
    load: loaderListDepositType,
    trigger: getListDepositType,
  } = useGetMethod();

  const {
    results: listGetDailyCashClosingDate,
    load: loaderListGetDailyCashClosingDate,
    trigger: getDailyCashClosingDate,
  } = useGetMethod();

  const {
    results: listCashClosingCurrency,
    trigger: getListCashClosingCurrency,
    load: loaderListCashClosingCurrency,
  } = useGetMethod();

  const { load: historyClosingDownloadLoader, trigger: getHistoryClosingDownload } = useGetMethod();

  const headersCashClosing = [
    {
      title: 'Moneda',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Cantidad',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total',
      className: 'px-3 text-end col-4',
    },
  ];

  const headersWalletMovements = [
    {
      title: 'Paciente',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Identificación',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total devuelta',
      className: 'px-2 text-end col-4',
    },
  ];

  const headersDetailCashClosing = [
    {
      title: 'Voucher',
      className: 'px-3 text-start',
    },
    {
      title: 'Entidad',
      className: 'px-2 text-center',
    },
    {
      title: 'Total',
      className: 'px-3 text-end',
    },
  ];

  const formatData = array => {
    let tempList = [];

    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        const deposits = item?.depositsSummary?.map(el => el?.depositTypeName?.toLocaleLowerCase());

        tempList.push(
          <tr key={index} className={`${tableStyles.tableAdmission}`}>
            <td className='text-start px-3'>{item?.dailyCashClosingDate || '-'}</td>

            {listDepositType?.results?.map(el => {
              if (deposits?.includes(el?.depoTypeName?.toLocaleLowerCase())) {
                return (
                  <td className='text-end px-3' key={generateId()}>
                    {formatNumberWithoutDecimals(
                      item?.depositsSummary?.find(
                        elem => elem?.depositTypeName === el?.depoTypeName,
                      )?.depositTypeAmount,
                    )}
                  </td>
                );
              }

              return (
                <td className='text-end px-3' key={generateId()}>
                  {'-'}
                </td>
              );
            })}

            <td className='text-end px-3'>{formatNumberWithoutDecimals(item?.difference)}</td>

            <td className='text-end px-3'>{formatNumberWithoutDecimals(item?.totalAmount)}</td>

            <td className='text-center px-2 cursorPointer'>
              <CustomPopupExtend
                position='bottom left'
                triggerSrc={threeDots}
                showDetails
                showDetailsEvent={() => {
                  getDailyCashClosingDate({
                    url: '/medical/admissions/dailyCashClosing/',
                    token: token,
                    objFilters: {
                      userId,
                      siteId,
                      getDetail: 1,
                      dailyCashClosingDate: item?.dailyCashClosingDate,
                    },
                  }).then(res => {
                    if (res?.success) {
                      setDrawerDetail(true);
                      setDailyCashClosingDate(item?.dailyCashClosingDate);
                      setAdmisionistName(item?.admisionistName);
                    } else {
                      customSwaltAlertAsistencial({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: res?.message,
                        showCancelButton: false,
                      });
                    }
                  });
                }}
                extraButtons={[
                  {
                    class: CustomPopupStyles.popUpGeneric,
                    text: 'Descargar',
                    event: async () => {
                      const response = await getHistoryClosingDownload({
                        url: `/medical/admissions/dailyCashClosing/${item?.dailyCashClosingId}`,
                        token,
                      });

                      if (response?.results) {
                        const a = document.createElement('a');
                        a.href =
                          'data:application/pdf;base64,' + response.results.base64.slice(2, -2);
                        a.download = `${response.message}.pdf`;
                        a.click();
                        a.target = '_blank';
                      }
                    },
                    icon: download,
                  },
                ]}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataCashClosingDate = array => {
    const validateArray = array[0] === null ? [] : array.filter(value => value !== null);

    let tempList = [];
    if (Array.isArray(validateArray)) {
      const listCollections = [];

      listGetDailyCashClosingDate?.results?.depositsData?.forEach(el => {
        if (el?.depositTypeLabel !== 'cash' && el?.depositType !== 'replacedDeposits') {
          listCollections?.push(el);
        }
      });
      let resFull = [];

      if (listCollections?.length && validateArray?.length) {
        resFull = [...listCollections, ...validateArray];
      } else if (listCollections?.length) {
        resFull = [...listCollections];
      } else if (validateArray?.length) {
        resFull = [...validateArray];
      }

      resFull?.forEach((item, index) => {
        const type = !isNullOrUndefined(item?.appsCount)
          ? item?.depositTypeName
          : item?.depositTypeName === 'transfer'
            ? 'Transferencia'
            : item?.depositTypeName === 'card'
              ? 'Tarjeta de crédito'
              : 'Efectivo';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              {item?.depositTypeName ? (
                type
              ) : (
                <>
                  {item?.cdType === 'cash' ? 'Billete de ' : 'Moneda de '}
                  {formatNumberWithoutDecimals(item?.cdValue)}
                </>
              )}
            </td>

            <td className='text-center px-2'>
              <span
                className={`${!isNullOrUndefined(item?.appsCount) ? `pointer text-decoration-underline ${tableStyles.ordAquaMarineText}` : ''}`}
                onClick={() => {
                  if (!isNullOrUndefined(item?.appsCount)) {
                    setFiltersListCashClosingCurrency(state => ({
                      ...state,
                      depositTypeId: item?.depositType,
                    }));

                    getListCashClosingCurrency({
                      url: '/medical/admissions/dailyCashClosing/',
                      token: token,
                      objFilters: {
                        ...filtersListCashClosingCurrency,
                        depositTypeId: item?.depositTypeId,
                        dailyCashClosingDate: dailyCashClosingDate,
                        vouchersDetail: 1,
                      },
                    }).then(res => {
                      if (res?.success) {
                        setDrawerVoucher(true);
                        setVoucherDepositTypeName(item?.depositTypeLabel);
                      }
                    });
                  }

                  setDrawerDetail(false);
                }}
              >
                {item?.cdQuantity || item?.appsCount || '-'}
              </span>
            </td>

            <td className='text-end px-3'>
              {item?.cdValue
                ? formatNumberWithoutDecimals(Number(item?.cdValue) * Number(item?.cdQuantity))
                : item?.depositTypeAmount
                  ? formatNumberWithoutDecimals(item?.depositTypeAmount)
                  : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatWalletMovements = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-2`}>{item?.patientName || '-'}</td>

            <td className={`text-center px-2`}>
              <span>{item?.patientDocType || '-'}</span>&nbsp;
              <span>{formatteDocument(item?.patientDoc).format || '-'}</span>
            </td>

            <td className={`text-end px-2`}>
              {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListHistoryClosing({
      url: '/medical/admissions/dailyCashClosing/',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggers]);

  useEffect(() => {
    getListDepositType({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount },
    }).then(res => {
      if (res?.success) {
        const listDepositType = res?.results?.map(el => ({
          title: el?.depoTypeName,
          className: 'text-end px-3',
        }));

        const firstHeader = {
          title: 'Fecha',
          className: 'text-start px-3',
        };

        const lastHeader = [
          {
            title: 'Diferencia',
            className: 'text-end px-3',
          },
          {
            title: 'Total',
            className: 'text-end px-3',
          },
          {
            title: '',
            className: 'text-center px-3',
          },
        ];

        const allData = listDepositType?.length
          ? [firstHeader, ...listDepositType, ...lastHeader]
          : [firstHeader, ...lastHeader];

        setHeaders(allData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDataVoucher = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`text-start px-3`} style={{ width: '150px' }}>
              <input
                value={item?.voucherNo || '-'}
                type='text'
                className='ord-roundInput2'
                style={{ color: '#6E6F7C' }}
              />
            </td>

            <td className='text-center px-2'>
              <span>{item?.bankName || '-'}</span>
            </td>

            <td className='text-end px-3'>
              {convertMoneyFormatNew({ textNumber: item?.totalAmount }) || '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '5px', marginTop: '10px' }}>
              <div className={tableStyles.app_container_tras_notes}>
                <span className={tableStyles.app_name_drawer}>{admisionistName || '-'}</span>
              </div>
              <p className={tableStyles.app_description_drawer}>{item?.ccNote || '-'}</p>
              <div className={tableStyles.app_container_date_drawer}>
                <span
                  className={tableStyles.app_date_drawer}
                >{`${item?.ccDate} ${item?.ccHour}`}</span>
              </div>
            </div>
            <div className={tableStyles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      {(loaderListHistoryClosing ||
        loaderListGetDailyCashClosingDate ||
        loaderListCashClosingCurrency ||
        loaderListDepositType ||
        historyClosingDownloadLoader) &&
        loader}

      <div style={{ marginRight: '100px' }}>
        <OrdGenericTemplate
          className='w-100 mx-2'
          titleSize={12}
          colBtnSize={6}
          colbtnClass={'p-0 m-0'}
          title={'Historial de cierres diarios'}
        >
          <Box style={{ width: '300px' }} className='d-flex gap-3'>
            <Box>
              <span className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                <b className='text-start'>&nbsp;Fecha desde</b>
              </span>
              <input
                className={`ord-roundInput2`}
                style={filters.dateMin ? { color: '#1a538d' } : {}}
                type='date'
                placeholder=''
                value={filters.dateMin}
                onChange={e => {
                  setFilters({ ...filters, dateMin: e.target.value, dateMax: '' });
                  setTriggers(state => state + 1);
                }}
              />
            </Box>

            <Box>
              <span className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                <b className='text-start'>&nbsp;Hasta</b>
              </span>
              <input
                disabled={!filters?.dateMin}
                className={`ord-roundInput2`}
                style={filters.dateMax ? { color: '#1a538d' } : {}}
                type='date'
                value={filters.dateMax}
                min={filters.dateMin}
                onChange={e => {
                  setFilters({ ...filters, dateMax: e.target.value });
                  setTriggers(state => state + 1);
                }}
              />
            </Box>
          </Box>

          <Box className={'mb-3 asistencialDateColorIcon'}>
            <OrdTable
              shadow
              hasChildren={true}
              headers={headers}
              paginate={{
                showTextDetails: true,
                activePage: filters.page,
                totalPages: listHistoryClosing?.rowTotal || 0,
                perPage: filters.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  setFilters({ ...filters, page: e });
                  setTriggers(state => state + 1);
                },
              }}
            >
              {formatData(listHistoryClosing?.results || [])}
            </OrdTable>
          </Box>
        </OrdGenericTemplate>

        <Drawer
          anchor={'right'}
          open={drawerDetail}
          onClose={() => {
            setDrawerVoucher(false);
            setDrawerDetail(false);
            setDailyCashClosingDate(undefined);
            setVoucherDepositTypeName(undefined);
            setAdmisionistName(undefined);
          }}
        >
          <Box padding={3}>
            <Box mb={2}>
              <img
                className={`${tableStyles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                onClick={() => {
                  setDrawerVoucher(false);
                  setDrawerDetail(false);
                  setDailyCashClosingDate(undefined);
                  setVoucherDepositTypeName(undefined);
                  setAdmisionistName(undefined);
                }}
                height={'28px'}
                width={'28px'}
              />
            </Box>

            <Box paddingX={4}>
              <Box>
                <Box className='d-flex' justifyContent={'space-between'} alignItems={'center'}>
                  <Box
                    fontSize={'36px'}
                    className={`${tableStyles.ordDarkBlueText} fw-bold`}
                    mb={0}
                  >
                    Conteo general
                  </Box>

                  <OverlayTrigger
                    flip
                    shouldUpdatePosition={true}
                    placement='top'
                    delay={{ show: 150, hide: 150 }}
                    overlay={
                      <Tooltip
                        id='button-tooltip-2'
                        style={{ zIndex: '9999999' }}
                        className={`d-inline-flex ${tableStyles.darkerGrayText}`}
                      >
                        <span>({listGetDailyCashClosingDate?.results?.dailyNotes?.length}) </span>
                        <span>
                          {listGetDailyCashClosingDate?.results?.dailyNotes?.length > 1
                            ? 'Notas'
                            : 'Nota'}
                        </span>
                      </Tooltip>
                    }
                  >
                    <div
                      className='pointer d-flex position-relative'
                      onClick={() => {
                        setOptions(state => ({
                          ...state,
                          showNotes: true,
                        }));

                        setDrawerDetail(false);
                      }}
                    >
                      <img
                        alt='notes'
                        height={22}
                        src={countNotes}
                        width={22}
                        className={`${tableStyles.filterOrdAquaMarine}`}
                      />
                      <span className={`${tableStyles.textCountNotes2} ${tableStyles.filterWhite}`}>
                        {listGetDailyCashClosingDate?.results?.dailyNotes?.length || 0}
                      </span>
                    </div>
                  </OverlayTrigger>
                </Box>

                <Text title='Recaudos en efectivo' classNameBox='mb-1' />
              </Box>

              <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
                <Box className='tableScroll'>
                  <OrdTable
                    className='mt-0'
                    headers={headersCashClosing}
                    hasChildren={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                  >
                    {formatDataCashClosingDate(
                      listGetDailyCashClosingDate?.results?.depositsDetail
                        ?.filter(el => el?.depositTypeLabel !== 'cash')
                        .concat(listGetDailyCashClosingDate?.results?.cashDetail) || [],
                    )}
                  </OrdTable>
                </Box>

                <Box className='tableScroll my-4'>
                  <Text title='Transferencias a bolsillo' classNameBox='mb-1' />

                  <OrdTable
                    className='mt-0'
                    headers={headersWalletMovements}
                    hasChildren={true}
                    oneHeadDarkBlue={false}
                    lowerCase={false}
                  >
                    {formatWalletMovements(listGetDailyCashClosingDate?.results?.walletMovements) ||
                      []}
                  </OrdTable>
                </Box>

                <Box className='ms-1 animate__animated animate__fadeIn mt-auto'>
                  <Box
                    borderRadius={3}
                    paddingX={3}
                    paddingY={2}
                    bgcolor={'#F5FCFD'}
                    className='d-flex gap-3 mt-4'
                  >
                    <Box>
                      <Text title='Total contado' fontSize={'12px'} classNameBox={'ms-1'} />
                      <NumberFormat
                        disabled
                        allowNegative={false}
                        className={`register-inputs ${tableStyles.inputPlaceholder}`}
                        placeholder='$...'
                        style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                        isNumericString={true}
                        value={listGetDailyCashClosingDate?.results?.userTotalAmount || 0}
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        format={valueStr => {
                          if (!valueStr) return '';

                          return formatNumberWithoutDecimals(valueStr);
                        }}
                        allowLeadingZeros={false}
                      />
                    </Box>

                    <Box>
                      <Text title='Total en el sistema' fontSize={'12px'} classNameBox={'ms-1'} />
                      <NumberFormat
                        disabled
                        allowNegative={false}
                        className={`register-inputs ${tableStyles.inputPlaceholder}`}
                        placeholder='$...'
                        style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                        isNumericString={true}
                        value={listGetDailyCashClosingDate?.results?.systemTotalAmount || 0}
                        thousandSeparator={localeSeparators.groupSeparator}
                        decimalSeparator={localeSeparators.decimalSeparator}
                        format={valueStr => {
                          if (!valueStr) return '';

                          return formatNumberWithoutDecimals(valueStr);
                        }}
                        allowLeadingZeros={false}
                      />
                    </Box>

                    <Box>
                      <Text title='Diferencia' fontSize={'12px'} classNameBox={'ms-1'} />
                      <input
                        disabled
                        type='text'
                        className={`register-inputs ${tableStyles.inputPlaceholder}`}
                        style={{
                          maxHeight: '32px',
                          height: '32px',
                          borderColor: '#A3BAD1',
                          color:
                            Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                              Number(listGetDailyCashClosingDate?.results?.systemTotalAmount) <
                            0
                              ? '#F39682'
                              : '#58595b',
                        }}
                        value={`${formatNumberWithoutDecimals(
                          Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                            Number(listGetDailyCashClosingDate?.results?.systemTotalAmount) >
                            0
                            ? 0
                            : Number(listGetDailyCashClosingDate?.results?.userTotalAmount) -
                                Number(listGetDailyCashClosingDate?.results?.systemTotalAmount),
                        )}`}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor={'right'}
          open={drawerVoucher}
          onClose={() => {
            setDrawerVoucher(false);
            setDrawerDetail(true);
          }}
        >
          <Box padding={3}>
            <Box mb={2}>
              <img
                className={`${tableStyles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                onClick={() => {
                  setDrawerVoucher(false);
                  setDrawerDetail(true);
                  // setFiltersListCashClosingCurrency(state => ({
                  //     ...state,
                  //     depositTypeId: undefined
                  // }))
                }}
                height={'28px'}
                width={'28px'}
              />
            </Box>

            <Box paddingX={4}>
              <Box>
                <Box fontSize={'36px'} className={`${tableStyles.ordDarkBlueText} fw-bold`} mb={0}>
                  Listado de{' '}
                  {voucherDepositTypeName?.toLowerCase() === 'transfer'
                    ? 'transferencias'
                    : 'vouchers'}
                </Box>
              </Box>

              <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
                <OrdTable
                  shadow
                  headers={headersDetailCashClosing}
                  hasChildren={true}
                  oneHeadDarkBlue={false}
                  lowerCase={false}
                  style={{ minWidth: '500px' }}
                >
                  {formatDataVoucher(listCashClosingCurrency?.results || [])}
                </OrdTable>

                <Box display='flex' columnGap='10px' justifyContent={'start'}>
                  <Button
                    backgroundHover={colors.ordAquaMarine}
                    backgroundColorHover={colors.ordAquaMarine}
                    backgroundColor={'white'}
                    color={colors.ordAquaMarine}
                    colorHover='white'
                    width='100px'
                    padding='8px'
                    fontSize='14px'
                    onClick={() => {
                      setDrawerVoucher(false);
                      setDrawerDetail(true);
                    }}
                  >
                    Regresar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Drawer>

        <Drawer
          anchor={'right'}
          open={options.showNotes}
          style={{ zIndex: 900 }}
          onClose={() => {
            setOptions({ ...options, showNotes: false });
            setDrawerDetail(true);
          }}
        >
          <div className={tableStyles.app_drawer} style={{ width: '500px', maxWidth: '500px' }}>
            <div className={tableStyles.app_container_close}>
              <img
                className={`${tableStyles.closeDrawerAsistencial} pointer`}
                src={iconClose}
                alt='close'
                width={25}
                onClick={() => {
                  setDrawerDetail(true);
                  setOptions({ ...options, showNotes: false });
                }}
              />
            </div>

            <div
              className={`${tableStyles.app_container_drawer} pt-0 px-5 mt-0`}
              style={{ width: '500px', maxWidth: '500px' }}
            >
              <span className={`${tableStyles.app_title_drawer} mb-2`}>Notas</span>

              {listGetDailyCashClosingDate?.results?.dailyNotes?.length === 0 ? (
                <div className={tableStyles.app_container_not_notes}>
                  <span className={tableStyles.app_title_not_notes}>No hay notas aún</span>
                </div>
              ) : (
                <div className={tableStyles.app_container_content_drawer}>
                  {formatDataComments(listGetDailyCashClosingDate?.results?.dailyNotes || [])}
                </div>
              )}
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
