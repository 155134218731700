import { Fragment, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Box, Drawer } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
//
import treeDots from '../../assets/img/icons/threeDots.svg';
import templateIcon from '../../assets/img/icons/planilla.svg';
import edit from '../../assets/img/icons/app_editar.svg';
import cancel from '../../assets/img/icons/closeOrange.svg';
import add from '../../assets/img/icons/orangeNoBGArrow.svg';
//
import { useGetMethod, usePostMethod } from '../../Hooks';
import { customSwaltAlertAsistencial, formatToRcSelect, generateId, loader } from '../../helpers';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
//
import styles from '../../components/Layouts/tableStyle.module.scss';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import { useHistory } from 'react-router';
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';

export default function DiagnosticAids() {
  const store = useSelector(state => state);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState({
    perpage: 10,
    page: 1,
    enabled: 1,
  });
  const [triggerDetail, setTriggerDetail] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [services, setServices] = useState({
    showDrawer: false,
    isEditing: false,
    headers: [
      { title: 'CUP', className: 'col-1 text-center' },
      { title: 'Servicio', className: 'col-5 text-start' },
      { title: 'Número de variables', className: 'col-2 text-center' },
      { title: 'Requiere imágenes', className: 'col-2 text-center' },
      { title: 'Requiere informe', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });

  const headersDetail = [
    { title: 'Variable', className: 'col-4 text-start px-2 f12' },
    { title: 'Tipo', className: 'col-3 text-start f12' },
    { title: 'Unidades', className: 'col-2 text-center f12' },
    { title: 'OD', className: 'col-1 text-center f12' },
    { title: 'OI', className: 'col-1 text-center f12' },
    { title: <>&nbsp;</>, className: 'col-1' },
  ];
  const [tempVariable, setTempVariable] = useState({
    enabled: 1,
    name: '',
    organs: [],
    type: '',
    units: '',
    options: [],
    isOpen: false,
    index: generateId(3),
  });
  const [selectedService, setSelectedService] = useState({});

  const { trigger: getDXServices, results: dxServices, load: dxLoader } = useGetMethod();
  const { trigger: getMeasurementUnits, results: measurementUnits } = useGetMethod();
  const { trigger: getVariableTypes, results: variableTypes } = useGetMethod();
  const { load: updateServiceLoader, trigger: updateService } = usePostMethod();
  const formattedUnits = formatToRcSelect(measurementUnits?.data, 'id', 'prefix');
  const formattedVariableTypes = formatToRcSelect(
    variableTypes?.results,
    'type',
    'TypeName',
    'prefix',
  );
  useEffect(() => {
    async function fetchAsyncData() {
      await getVariableTypes({
        url: '/medical/variableTypes/',
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getVariableTypes, store.loginReducer.Authorization]);

  useEffect(() => {
    void getDXServices({
      url: '/medical/clinicHistoryServices/',
      objFilters: { ...filters, search: searchValue, prefix: 'diagnostic_aids' },
      token: store.loginReducer.Authorization,
    });
  }, [store.loginReducer.Authorization, filters, searchValue, getDXServices, trigger]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getMeasurementUnits({
        url: '/inventory/measurements/',
        objFilters: { id_account: store.loginReducer.currentAccount.id },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
    filters,
    searchValue,
    getMeasurementUnits,
  ]);

  const debounceSearch = useMemo(() => {
    return _.debounce(event => {
      const value = event.target.value;
      setFilters(state => ({ ...state, page: 1 }));
      setSearchValue(value);
    }, 500);
  }, []);

  const onCloseDrawer = () => {
    setTrigger(trigger + 1);
    setServices({ ...services, showDrawer: false });
    setSelectedService({});
    setTempVariable({
      enabled: 1,
      name: '',
      organs: [],
      type: '',
      units: '',
      options: [],
      isOpen: false,
      index: generateId(3),
    });
    setTrigger(trigger + 1);
  };

  const onOpenDrawer = service => {
    setServices({
      ...services,
      showDrawer: true,
    });
    const option = service.variables.map(vr => ({
      ...vr,
      options: vr.options ?? [],
      isOpen: false,
      index: generateId(3),
    }));
    setSelectedService({
      ...service,
      variables: option,
    });
  };

  const onChangeRequeriment = (req, value) => {
    let option;
    if (req === 'requiresImage' && !value) {
      option = { ...selectedService, [req]: value, reportRequired: false };
    } else {
      option = { ...selectedService, [req]: value };
    }
    setSelectedService(option);
  };

  const onAddVariable = () => {
    function isDuplicated(array, key) {
      const recount = array.reduce((counter, elem) => {
        const value = String(elem[key]).toLowerCase();
        counter[value] = (counter[value] || 0) + 1;
        return counter;
      }, {});

      return Object.values(recount).some(count => count > 1);
    }

    const findVariable = selectedService.variables.find(
      variable => variable.index === tempVariable.index,
    );
    const filterVariables = selectedService.variables.filter(
      variable => variable.index !== tempVariable.index,
    );
    if (tempVariable.name !== '' && tempVariable.type !== '') {
      if (
        !filterVariables.some(
          variable => variable.name.toLowerCase() === tempVariable.name.toLowerCase(),
        )
      ) {
        if (
          formattedVariableTypes?.find(type => type.value === tempVariable.type)?.extra ===
          'dropdownList'
        ) {
          if (
            tempVariable.options.length > 0 &&
            tempVariable.options.some(option => option.active === 1) &&
            !tempVariable.options.some(option => option.active === 1 && option.label === '')
          ) {
            if (!isDuplicated(tempVariable.options, 'label')) {
              if (findVariable) {
                const newVariables = selectedService.variables.map(variable =>
                  variable.index !== tempVariable.index ? variable : tempVariable,
                );
                setSelectedService({
                  ...selectedService,
                  variables: newVariables,
                });
              } else {
                setSelectedService({
                  ...selectedService,
                  variables: [...selectedService.variables, tempVariable],
                });
              }

              setTempVariable({
                enabled: 1,
                name: '',
                organs: [],
                type: '',
                units: '',
                options: [],
                isOpen: false,
                index: generateId(3),
              });
            } else {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'Las opciones no pueden estar repetidas',
                showCancelButton: false,
              });
            }
          } else {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'Las opciones son obligatorias en una lista plegable',
              showCancelButton: false,
            });
          }
        } else {
          if (findVariable) {
            const newVariables = selectedService.variables.map(variable =>
              variable.index !== tempVariable.index ? variable : tempVariable,
            );
            setSelectedService({
              ...selectedService,
              variables: newVariables,
            });
          } else {
            setSelectedService({
              ...selectedService,
              variables: [...selectedService.variables, tempVariable],
            });
            setTempVariable({
              enabled: 1,
              name: '',
              organs: [],
              type: '',
              units: '',
              options: [],
              isOpen: false,
              index: generateId(3),
            });
          }
        }
      } else {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El nombre de la variable ya existe',
          showCancelButton: false,
        });
      }
    } else {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar todos los campos',
        showCancelButton: false,
      });
    }
  };

  const onRemoveVariable = (index, v) => {
    if (v?.id) {
      const variablesTemp = [...selectedService.variables];
      const findVariable = variablesTemp.find(vari => vari.id === v.id);
      findVariable['enabled'] = 0;
      setSelectedService(prev => ({
        ...prev,
        variables: variablesTemp,
      }));
    } else {
      const newVar = selectedService.variables.filter(item => item.index !== index);
      setSelectedService({
        ...selectedService,
        variables: newVar,
      });
    }
    setTriggerDetail(triggerDetail + 1);
  };

  const onChangeOrgans = organ => {
    setTempVariable({
      ...tempVariable,
      organs: tempVariable.organs
        ? tempVariable.organs.includes(organ)
          ? tempVariable.organs.filter(e => e !== organ)
          : [...tempVariable.organs, organ]
        : [organ],
    });
  };

  const onSubmit = () => {
    const error = selectedService.variables.some(item => !(item.name.length && item.type));
    const errorRequired = !!selectedService.reportRequired && !selectedService.requiresImage;
    const errorVarOptions = selectedService.variables.some(
      item => item.type === 2 && item.options.length === 0,
    );
    const errorVarOptionsEmpty = selectedService.variables.some(
      item => item.type === 2 && item.options.length > 0 && item.options.some(opt => !opt.label),
    );
    if (error) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Asegúrese de llenar todos los campos',
        showCancelButton: false,
      });
      return;
    }
    if (errorVarOptions) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "Debe añadir como mínimo una opción en la variable de tipo 'lista plegable'",
        showCancelButton: false,
      });
      return;
    }
    if (errorVarOptionsEmpty) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: "Debe de llenar todas las opciones de la variable de tipo 'lista plegable' ",
        showCancelButton: false,
      });
      return;
    }
    if (errorRequired) {
      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Para que el servicio requiera informe es necesario que requiera imágenes',
        showCancelButton: false,
      });
      return;
    }
    updateService({
      url: '/medical/clinicHistoryServices/',
      token: store.loginReducer.Authorization,
      method: 'PUT',
      noAlert: true,
      body: {
        ...selectedService,
        srvId: selectedService.id,
        userId: store.loginReducer?.user_data?.id,
      },
      doAfterSuccess: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Servicio actualizado',
          text: 'El servicio ha sido actualizado satisfactoriamente.',
          showCancelButton: false,
        });
        onCloseDrawer();
        setTrigger(trigger + 1);
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  const renderRow = item => {
    return (
      <tr key={item.id} className={`hover-table-row`}>
        <td>{item.cup}</td>
        <td className='text-start'>{item.name}</td>
        <td className='text-center'>{item.numVariables}</td>
        <td className='text-center'>
          <input
            type='checkbox'
            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
            checked={item.requiresImage === 1 ? true : false}
            disabled
          />
        </td>
        <td className='text-center'>
          <input
            type='checkbox'
            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
            checked={item.reportRequired === 1 ? true : false}
            disabled
          />
        </td>
        <td className='text-center pe-3'>
          <CustomPopupExtend
            showEdit={true}
            position='bottom right'
            triggerSrc={treeDots}
            editText='Ver detalle'
            editClickEvent={() => {
              onOpenDrawer(item);
            }}
            extraButtons={
              (item.reportRequired && item.requiresImage) ||
              (!item.reportRequired && !item.requiresImage)
                ? [
                    {
                      event: () => {
                        history.push({
                          pathname:
                            '/asistencial/tmHistoriaClinica/ayudasDiagnosticas/plantillaInforme',
                          state: {
                            srvId: item.id,
                            reportRequired: item.reportRequired,
                            requiresImage: item.requiresImage,
                          },
                        });
                      },
                      text: 'Configurar plantilla',
                      icon: templateIcon,
                    },
                  ]
                : undefined
            }
          />
        </td>
      </tr>
    );
  };
  const renderRowDetail = item => {
    return (
      !!item.enabled && (
        <tr key={item.id + triggerDetail} className={`hover-table-row`}>
          <td className='text-start px-2'>{item.name}</td>
          <td className='text-start'>
            {formattedVariableTypes.length > 0 &&
              formattedVariableTypes?.find(type => type.value === item.type)?.label}
          </td>
          <td className='text-center'>
            {' '}
            {(formattedUnits.length > 0 && formattedUnits?.find(unit => unit.value === item.units))
              ?.value === ''
              ? ''
              : (
                  formattedUnits.length > 0 &&
                  formattedUnits?.find(unit => unit.value === item.units)
                )?.label}
          </td>
          <td className='text-center'>
            <input
              type='checkbox'
              className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
              checked={item?.organs?.some(x => x === 'od')}
              disabled
            />
          </td>
          <td className='text-center'>
            <input
              type='checkbox'
              className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
              checked={item?.organs?.some(x => x === 'oi')}
              disabled
            />
          </td>
          <td className='text-center pe-3 '>
            <div className='d-flex'>
              <img
                className={`mx-2 ${styles.colorNewOrderHover}`}
                src={edit}
                width={10}
                alt=''
                onClick={() => {
                  setTempVariable(item);
                }}
              />
              <img
                className={styles.colorNewOrderHover}
                src={cancel}
                width={10}
                alt=''
                onClick={() => onRemoveVariable(item.index, item)}
              />
            </div>
          </td>
        </tr>
      )
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${styles.shade}`}
        headers={services.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: dxServices?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDXServices({
              url: '/medical/clinicHistoryServices/',
              objFilters: {
                ...filters,
                search: searchValue,
                page: e,
                prefix: 'diagnostic_aids',
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {dxServices?.results?.map(service => renderRow(service))}
      </OrdTable>
    );
  };
  const renderTableDetails = () => {
    return (
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: 200 }}>
        <OrdTable
          key={triggerDetail}
          className={`${styles.shade}`}
          headers={headersDetail}
          hasChildren={true}
          style={{ borderRadius: '10px', overflow: 'hidden', maxHeight: 280 }}
        >
          {selectedService?.variables?.length > 0 &&
            selectedService?.variables?.map(variable => renderRowDetail(variable))}
        </OrdTable>
      </div>
    );
  };

  const renderModal = () => {
    if (selectedService) {
      return (
        <Drawer
          anchor={'right'}
          open={services.showDrawer}
          onClose={() => {
            onCloseDrawer();
          }}
          PaperProps={{ style: { width: '40%' } }}
          // hideBackdrop
        >
          <Box padding={3} className='d-flex align-items-center'>
            <div
              className={`text-muted  me-4 ms-0 cursorPointer`}
              onClick={() => {
                onCloseDrawer();
              }}
            >
              <b style={{ fontSize: '25px' }}>✖</b>
            </div>
            <h2 className='text-muted m-0 fw-bold'>Detalle del servicio</h2>
          </Box>
          <hr className='m-0' />
          <Box className='d-flex flex-column justify-content-between h-100 f12 px-4 py-2'>
            <div className='d-flex flex-column jus'>
              <Form.Group className='mb-3 text-start' controlId='2'>
                <Form.Label className={`m-0 ${styles.text_blueHC}`}>
                  <span className='px-1 f12 fw-bold'>{selectedService.name}</span>
                </Form.Label>
              </Form.Group>
              <div className='row'>
                <div className='col-4'>
                  <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <span className='px-1 f12'>
                        {' '}
                        <b>CUP:</b> {selectedService.cup}
                      </span>
                    </Form.Label>
                    <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                      <span className='px-1 f12'>
                        <b>Tipo:</b> {selectedService.serviceType}
                      </span>
                    </Form.Label>
                  </Form.Group>
                </div>
              </div>
              <div className='d-flex pt-2'>
                <div className='d-flex align-items-center my-2'>
                  <input
                    type='checkbox'
                    className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                    checked={selectedService.requiresImage ? true : false}
                    onChange={({ target }) => onChangeRequeriment('requiresImage', target.checked)}
                  />
                  <span className='ms-3  f12 text-muted'>Requiere imágenes</span>
                </div>
                <div className='d-flex align-items-center mx-5'>
                  <input
                    type='checkbox'
                    disabled={!selectedService.requiresImage}
                    className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                    checked={selectedService.reportRequired ? true : false}
                    onChange={({ target }) => onChangeRequeriment('reportRequired', target.checked)}
                  />
                  <span className='ms-3 f12 text-muted'>Requiere informe</span>
                </div>
              </div>
              <div className={`${styles.app_back_search}`}>
                <Row className='align-items-center'>
                  <Col xs={3} className={`f12 ${styles.ordDarkBlueText}`}>
                    Variable
                  </Col>
                  <Col xs={3} className={`f12 ${styles.ordDarkBlueText}`}>
                    Tipo
                  </Col>
                  <Col xs={3} className={`f12 ${styles.ordDarkBlueText}`}>
                    Unidades
                  </Col>
                  <Col xs={1} className={`f12 pl-1 text-center ${styles.ordDarkBlueText}`}>
                    OD
                  </Col>
                  <Col
                    xs={1}
                    className={`f12 pl-1 text-center ${styles.ordDarkBlueText}`}
                    style={{ paddingLeft: 1 }}
                  >
                    OI
                  </Col>
                  <Col xs={1}></Col>
                </Row>
                <div className={`${styles.app_back_body}`} id='ad'>
                  <Fragment key={'a'}>
                    <Row className=' align-items-center flex-nowrap border-top pt-2'>
                      <Col xs={3} className=''>
                        <Form.Control
                          className={`form-control text-muted ord-roundInput`}
                          type='text'
                          placeholder='Escribir...'
                          value={tempVariable.name}
                          style={{ fontSize: 12 }}
                          onChange={({ target }) =>
                            setTempVariable({ ...tempVariable, name: target.value })
                          }
                        />
                      </Col>
                      <Col xs={3} className=''>
                        <Select
                          classNamePrefix={styles.selectMenu}
                          options={formattedVariableTypes}
                          className='text-secondary'
                          placeholder={'Seleccionar...'}
                          styles={ordCustomSelect}
                          value={formattedVariableTypes?.find(
                            item => item.value === tempVariable.type,
                          )}
                          onChange={e => {
                            setTempVariable({
                              ...tempVariable,
                              type: e.value,
                              extra: e.extra,
                              options: e.extra !== 'fieldtext' ? [] : tempVariable.options,
                            });
                          }}
                        />
                      </Col>
                      <Col xs={3} className=''>
                        <Select
                          options={formattedUnits}
                          className='text-secondary'
                          placeholder={'Seleccionar...'}
                          styles={ordCustomSelect}
                          value={formattedUnits?.find(item => item.value === tempVariable.units)}
                          onChange={e => {
                            setTempVariable({ ...tempVariable, units: e.value });
                          }}
                        />
                      </Col>
                      <Col xs={1} className=' pt-1  text-center'>
                        <input
                          type='checkbox'
                          className={`${styles.checkClearBlue} ${styles.appCheckInput} ${styles.appCheckClear}  form-check-input p1`}
                          checked={tempVariable.organs && tempVariable.organs.includes('od')}
                          onChange={() => onChangeOrgans('od')}
                        />
                      </Col>
                      <Col xs={1} className='col-1 pt-1  text-center'>
                        <input
                          type='checkbox'
                          className={`${styles.checkClearBlue} ${styles.appCheckInput} ${styles.appCheckClear}  form-check-input p1`}
                          checked={tempVariable.organs && tempVariable.organs.includes('oi')}
                          onChange={() => onChangeOrgans('oi')}
                        />
                      </Col>
                      <Col xs={1}></Col>
                    </Row>
                    {formattedVariableTypes?.find(type => type.value === tempVariable.type)
                      ?.extra === 'dropdownList' && (
                      <div
                        className=''
                        style={{ maxHeight: 100, overflowY: 'auto', overflowX: 'hidden' }}
                      >
                        {tempVariable.options.map(
                          (item, index) =>
                            !!item.active && (
                              <Row key={index}>
                                <Col xs={3}></Col>
                                <Col
                                  xs={8}
                                  key={index}
                                  className='align-items-center justify-content-end align-self-end pt-2'
                                >
                                  <Form.Control
                                    className={`form-control text-muted flex-grow-1 ord-roundInput
                                                        `}
                                    type='text'
                                    value={item.label}
                                    style={{ fontSize: 12 }}
                                    onChange={({ target }) => {
                                      // const findVariable = {...tempVariable};
                                      const newVariables = tempVariable.options.map(variable =>
                                        (variable.id ?? variable.value) !== (item.id ?? item.value)
                                          ? variable
                                          : { ...variable, label: target.value },
                                      );

                                      // const findOption = findVariable.options.find(option => (option.id ?? option.value) === (item.value ?? item.id));
                                      // findOption["label"] = target.value;
                                      setTempVariable({ ...tempVariable, options: newVariables });
                                    }}
                                    placeholder='Escribir...'
                                  />
                                </Col>
                                <Col xs={1} className='align-self-center px-0'>
                                  <div
                                    style={{ marginLeft: '8px' }}
                                    className={`align-self-center cursorPointer pt-1`}
                                    onClick={() => {
                                      const newVariables = tempVariable.options.map(variable =>
                                        (variable.id ?? variable.value) !== (item.id ?? item.value)
                                          ? variable
                                          : { ...variable, active: 0 },
                                      );
                                      setTempVariable({ ...tempVariable, options: newVariables });
                                    }}
                                  >
                                    <img
                                      className={`px-0 ${styles.colorNewOrderHover}`}
                                      src={cancel}
                                      width={15}
                                      alt=''
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ),
                        )}
                        <Row className='d-flex justify-content-center'>
                          <Col xs={3}></Col>

                          <Col xs={9} className='d-flex justify-content-start'>
                            <div
                              className='d-flex align-items-center justify-content-center my-2 ordGroupAddButton'
                              onClick={() => {
                                const findVariable = tempVariable;
                                findVariable.options.push({
                                  label: '',
                                  value: generateId(3),
                                  active: 1,
                                });
                                setTempVariable(tempVariable);
                              }}
                            >
                              <label className='ordGrayText fw-bold f12'>Agregar opción</label>
                              <button className='addCheckButton ms-2' />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div
                  className={
                    'd-flex justify-content-end align-items-center CursorPointer svgIconDark mt-3'
                  }
                  onClick={() => onAddVariable()}
                  style={{ marginLeft: '3%' }}
                >
                  <label className={` f12 fw-bold ${styles.orangeLightText} `}>
                    Agregar variable
                    <img alt='icon' src={add} className='pl-2' />
                  </label>
                </div>
              </div>
              {renderTableDetails()}
            </div>
            <div className='d-flex flex-column'>
              <div className='align-self-end'>
                <button
                  className={`mr-2 btn ${styles.tlnBtnPrimaryligth}`}
                  onClick={() => onCloseDrawer()}
                >
                  Cancelar
                </button>
                <button className={`${styles.tlnBtnPrimary} btn`} onClick={() => onSubmit()}>
                  Guardar
                </button>
              </div>
            </div>
          </Box>
        </Drawer>
      );
    }
  };

  const render = () => {
    return (
      <>
        {(updateServiceLoader || dxLoader) && loader}

        <div className={`${styles.tlnContainer} `}>
          <OrdGenericTemplate className='' titleSize={12} title={'Servicios ayudas diagnósticas'}>
            <>
              <div className='d-flex align-items-center mt-3'>
                <div className='flex-grow-1'>
                  <input
                    placeholder='Escribe aquí para buscar'
                    className={`${ordComponentStyles.backgroundImage} ${styles.appInputDate} pl-2 w-50`}
                    onChange={debounceSearch}
                  />
                </div>
              </div>
              {renderTable()}
            </>
          </OrdGenericTemplate>
        </div>
        {renderModal()}
      </>
    );
  };

  return render();
}
