//react
import { useEffect, useState } from 'react';
import Select from 'react-select';

//components
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../../../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import { appCustomSelectNew } from '../../../Layouts/react-select-custom';
import { QueriesOrderDetail } from './QueriesOrderDetail';

//hooks
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../../Hooks/useFetch';

//helpers
import { loader } from '../../../../helpers/helpers';
import { formatNumberWithoutDecimals } from '../../../../helpers/numberFormatting';

//icons
import arrowDarkGrey from '../../../../../src/assets/img/icons/arrowDarkGrey.svg';
import ordBlueSearch from '../../../../../src/assets/img/icons/ordBlueSearch.svg';

//css
import { Box } from '@mui/material';
import styles from '../../../../components/Layouts/tableStyle.module.scss';

export const QueriesOrder = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const [orderDetailSelected, setOrderDetailSelected] = useState(undefined);

  const [trigger, setTrigger] = useState(0);

  const [filters, setFilters] = useState({
    dateMax: '',
    dateMin: '',
    search: '',
    generalResponse: 1,
    partialPayments: 0,
    page: 1,
    perpage: 10,
    eaccount,
  });

  const [services, setServices] = useState({
    data: {},
    showDrawer: false,
    showImportance: false,
    header: [
      {
        title: 'Tipo',
        className: 'px-2 text-center',
      },
      {
        title: 'Identificación',
        className: 'px-2 text-start',
      },
      {
        title: 'Proveedor',
        className: 'px-2 text-start',
      },
      {
        title: 'Ordenes',
        className: 'px-2 text-center',
      },
      {
        title: 'Total copago',
        className: 'px-2 text-end',
      },
      {
        title: '',
        className: 'px-3 text-end',
      },
    ],
  });

  const {
    results: listProviderOrders,
    load: loaderListProviderOrders,
    trigger: getListProviderOrders,
  } = useGetMethod();

  const renderToolImportance = text => (
    <Tooltip>
      <span className={styles.darkerGrayText}>{text}</span>
    </Tooltip>
  );

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        const typeOrder = item?.iconUrl?.split('/')?.at(-1)?.split('.')?.at(0);

        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-center px-3'>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 250 }}
                className={styles.app_container_tool_websc}
                overlay={renderToolImportance(
                  typeOrder === 'lens'
                    ? 'Lente de contacto'
                    : typeOrder === 'eye'
                      ? 'Servicio'
                      : 'Laboratorio'
                )}
              >
                <img height={22} width={22} src={item?.iconUrl} alt='icono' />
              </OverlayTrigger>
            </td>

            <td className='text-start px-2'>{item?.nit || '-'}</td>

            <td className='text-start px-2'>{item?.name || '-'}</td>

            <td className='text-center px-2'>{item?.totalOrders}</td>

            <td className='text-end px-2'>
              {formatNumberWithoutDecimals(item?.totalPaidAmount)}
            </td>

            <td className='text-center px-3'>
              <img
                className={`${styles.pointer} ${styles.filterOrdAquaMarine}`}
                src={arrowDarkGrey}
                alt='icono'
                onClick={() => setOrderDetailSelected(item)}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const onBackState = () => setOrderDetailSelected(undefined);

  useEffect(() => {
    getListProviderOrders({
      token: token,
      objFilters: { ...filters },
      url: '/medical/admissions/provider-orders/',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {orderDetailSelected ? (
        <QueriesOrderDetail provider={orderDetailSelected} onBackState={onBackState} />
      ) : (
        <>
          {loaderListProviderOrders && loader}

          <OrdModal
            title='Tipo de importancia'
            show={services.showImportance}
            btnYesName={'Aceptar'}
            btnNoName={'Cancelar'}
            size={'200'}
            onHide={() => setServices({ ...services, showImportance: false })}
            // btnYesEvent={() => OnSubmitMarkPatient()}
            btnNoEvent={() => setServices({ ...services, showImportance: false })}
          >
            <div>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={appCustomSelectNew}
              />
            </div>
          </OrdModal>

          <div className='container-fluid pt-3'>
            <OrdGenericTemplate titleSize='12' title='Consulta órdenes a proveedores'>
              <div className={`${styles.app_mr_100} asistencialDateColorIcon`}>
                <Box gap={2} marginBottom={2} display={'grid'} gridTemplateColumns={'430px'}>
                  <Box
                    className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}
                  >
                    <input
                      className={`ord-roundInput ${styles.appInputDate} w-100`}
                      type='text'
                      placeholder='Escribe aquí para buscar'
                      autoComplete='off'
                      onChange={e => {
                        setFilters({ ...filters, search: e.target.value });
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          setTrigger(trigger + 1);
                        }
                      }}
                    />
                    <img
                      src={ordBlueSearch}
                      className='pointer'
                      alt='buscar'
                      onClick={() => setTrigger(trigger + 1)}
                    />
                  </Box>
                </Box>

                <OrdTable
                  shadow
                  className={'mt-0'}
                  hasChildren={true}
                  headers={services.header}
                  paginate={{
                    activePage: filters.page,
                    totalPages: listProviderOrders?.rowTotal,
                    perPage: filters.perpage,
                    pageRangeDisplayed: 3,
                    showTextDetails: true,
                    onChangePage: async e => {
                      setFilters(state => ({
                        ...state,
                        page: e,
                      }));

                      setTrigger(trigger + 1);
                    },
                  }}
                >
                  {formatData(listProviderOrders?.results)}
                </OrdTable>
              </div>
            </OrdGenericTemplate>
          </div>
        </>
      )}
    </>
  );
};
