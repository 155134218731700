import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import Atras from '../../assets/img/icons/atras-icon.svg';
import { useHistory } from 'react-router-dom';
import myStyles from './InvoiceBilling.module.scss';
import { convertDateToLatinFormat, monthArray } from '../../helpers/convertDateFormat';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { getReceiptRecordOne } from '../../actions/billingActions';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,
});

function BillingCertificateDetails(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  let history = useHistory();
  const today = new Date();

  function handleClick() {
    if (props.goBack) {
      props.goBack();
    } else {
      history.push('/cartera/Factura/RecepcionDeFacturas');
    }
  }

  useEffect(() => {
    if (props.detail?.id) {
      dispatch(
        getReceiptRecordOne({
          id: props.detail.id,
          eaccount: counter.loginReducer.currentAccount.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.detail?.show]);

  const header = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha factura
    </th>,
    <th key={3} className='text-center'>
      Empresa
    </th>,
    <th key={4} className='text-center'>
      Valor
    </th>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(counter.billingReducer.receipt_chosen?.invoices)) {
      counter.billingReducer.receipt_chosen?.invoices.forEach((item, index) => {
        tempList.push(
          <tr key={index}>
            <td className='text-center'>{item.invoice}</td>
            <td className='text-center'>{convertDateToLatinFormat(item.date)}</td>
            <td className='text-center'>{item.company}</td>
            <td className='text-center'>{item.amount ? formatter.format(item.amount) : '-'}</td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      <div className={` ml-5 mr-3 ${tableStyles.container}`}>
        <div className='d-flex'>
          <img
            alt=''
            src={Atras}
            onClick={handleClick}
            className={`${tableStyles.title} mr-3 ${tableStyles.cursorPointer}`}
          />
          <h1 className={tableStyles.title}>Acta de facturación</h1>
        </div>

        <div className={`d-flex`} style={{ justifyContent: 'space-between' }}>
          <p className={`text-start`} style={{ color: props.detail?.status_fontcolor }}>
            {' '}
            {props.detail?.status === 'Enviada'
              ? 'Enviado por ' + props.detail?.generatorFullName
              : props.detail?.status === 'Recibido'
                ? props.detail?.receiver_name
                  ? 'Recibido por ' + props.detail?.receiver_name
                  : 'Recibido por -'
                : 'Actualizada por ' + props.detail?.generatorFullName}
          </p>
          <p className={`${myStyles.codeText} text-end`}>{props.detail?.sequence}</p>
        </div>

        <Row className='d-flex'>
          <Col>
            <p>
              Por medio del presente documento se da constancia del envío de las facturas anexadas a
              departamento de cartera, el día {today.getDate()}, del mes{' '}
              {monthArray[today.getMonth()]} del año {today.getFullYear()}.
            </p>
          </Col>
        </Row>

        <GenericTable className='mb-6' headers={header} dark={true}>
          {renderList()}
        </GenericTable>
        {counter.billingReducer.receipt_chosen_loading && (
          <div className='mx-auto'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className='d-flex'>
          <p className={`${myStyles.greyText}`} style={{ flex: 3 }}>
            {props.detail?.generatorFullName} <br />
            Asistente de facturación <br />
            C.C: {props.detail?.generatorDocNum}
          </p>

          <div className='d-flex flex-column' style={{ flex: 9, marginLeft: 20 }}>
            <label className={`${tableStyles.crudModalLabel} m-0`}>Descripción</label>
            <textarea
              name='textarea'
              className={myStyles.detailZone}
              rows='3'
              cols='50'
              value={props.detail?.summary}
              disabled
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
}
export default BillingCertificateDetails;
