import { Box } from '@mui/material';
import moment from 'moment';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod, usePostMethod } from '../../../Hooks';

import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';
import { customSwaltAlertAsistencial, loader } from '../../../helpers';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';

import { ColorButton } from '../../../OrderingModule/OrderingComponents/ColorButton';
import { OrdCard } from '../../../OrderingModule/OrderingComponents/OrdCard';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';

import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import ReactSelect from 'react-select';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';

const REJECT_INITIAL_STATE = {
  isOpen: false,
  canId: null,
  patientName: '',
  comments: '',
  requestId: null,
};

export default function Refund() {
  const token = useSelector(store => store.loginReducer.Authorization);
  const eaccount = useSelector(store => store.loginReducer.currentAccount.id);
  const currentUserId = useSelector(store => store.loginReducer.user_data.id);

  const [rejectModal, setRejectModal] = useState(REJECT_INITIAL_STATE);
  const [datesFilter, setDatesFilter] = useState({
    userId: null,
    fromDate: '',
    toDate: '',
  });

  const { trigger: getRefunds, load: refundsLoader, results: refundsResult } = useGetMethod();
  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();
  const { results: listUsers, trigger: getListUsers } = useGetMethod();

  const { trigger: submitRefund, load: loadSubmitRefund } = usePostMethod();

  const listUsersSelect = useMemo(() => {
    if (listUsers?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...listUsers.results.map(el => ({
          label: `${el?.first_name} ${el?.first_surname}`,
          value: el?.id,
        })),
      ];
    }
    return [];
  }, [listUsers?.results]);

  useEffect(() => {
    void getRefunds({
      token: token,
      url: '/medical/admissions/refund-requests',
      objFilters: {
        eaccount: eaccount,
        startDate: datesFilter.fromDate,
        endDate: datesFilter.toDate,
        userId: datesFilter.userId,
      },
    });
  }, [getRefunds, token, datesFilter, eaccount]);

  useEffect(() => {
    getListUsers({
      token,
      url: '/users/select',
      objFilters: { active: 1 },
    });
  }, [getListUsers, token]);

  useEffect(() => {
    if (rejectModal.isOpen)
      void getListCancellationReason({
        url: '/medical/generals/cancellationReason/',
        token: token,
      });
  }, [getListCancellationReason, token, rejectModal.isOpen]);

  const onChangeDates = (key = '', value) => {
    setDatesFilter(state => ({
      ...state,
      [key]: value,
    }));
  };

  const onSubmitSearch = event => {
    event.preventDefault();

    const { search } = event.target;

    void getRefunds({
      token: token,
      url: '/medical/admissions/refund-requests',
      objFilters: {
        eaccount: eaccount,
        userId: datesFilter.userId,
        startDate: datesFilter.fromDate,
        endDate: datesFilter.toDate,
        search: search.value,
      },
    });
  };

  const onSubmitRefund = ({ status, patientName, requestId, canId, comments }) => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${status === 'approved' ? 'aprobará' : 'rechazará'} la solicitud de devolución para el paciente ${patientName}`,
      showCancelButton: true,
    }).then(response => {
      if (response.isConfirmed) {
        submitRefund({
          token,
          body: {
            eaccount,
            status: status,
            userId: currentUserId,
            comments,
            canId,
          },
          method: 'PUT',
          url: `/medical/admissions/refund-requests/${requestId}`,
          succesAction: response => {
            setRejectModal(REJECT_INITIAL_STATE);
            customSwaltAlertAsistencial({
              icon: 'success',
              title: `Solicitud de devolución ${status === 'approved' ? 'aprobada' : 'rechazada'}`,
              text: response.message,
              showCancelButton: false,
            }).finally(() => {
              void getRefunds({
                token: token,
                url: '/medical/admissions/refund-requests',
                objFilters: {
                  eaccount: eaccount,
                },
              });
              setDatesFilter({ fromDate: '', toDate: '', userId: null });
            });
          },
          doAfterException: response => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: response.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const titleCard = item => {
    return (
      <Row className={`pb-3 ${tableStyles.ordGrayBorderBottom} align-items-center`} gap={2}>
        <Col className={`d-flex ${tableStyles.ellipsisAuto}`}>
          <div>
            <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              Solicitante: &nbsp;
            </span>
          </div>
          <div className={tableStyles.ellipsisAuto}>
            <span className={`text-secondary fw-normal`}>
              {item.userInfo.firstName} {item.userInfo.lastName}
            </span>
          </div>
        </Col>
        <Col className={`d-flex ${tableStyles.ellipsisAuto}`}>
          <div>
            <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              F. de solicitud: &nbsp;
            </span>
          </div>
          <div>
            <span className={`text-secondary fw-normal`}>
              {moment(item.createdAt).format('DD/MM/YYYY')}
            </span>
          </div>
        </Col>
        <Col lg={1} className='d-flex justify-content-end'>
          <ColorButton
            style={{
              width: 'auto',
              fontSize: '15px',
              wordWrap: 'normal',
              color: item.status.fontColor,
              paddingInline: 5,
              background: item.status.bgColor,
            }}
            text={item.status.description}
          />
        </Col>
        {item.status.value === 'pending_request' && (
          <Col lg={2} className={`d-flex justify-content-end gap-2`}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={<Tooltip>Rechazar</Tooltip>}
            >
              <div
                className={tableStyles.denyCardRed}
                onClick={() =>
                  setRejectModal({
                    isOpen: true,
                    canId: null,
                    patientName: `${item.patient.firstName} ${item.patient.lastName}`,
                    comments: '',
                    requestId: item.requestId,
                  })
                }
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>Aprobar</Tooltip>}
            >
              <div
                className={tableStyles.checkCardGreen}
                onClick={() =>
                  onSubmitRefund({
                    status: 'approved',
                    patientName: `${item.patient.firstName} ${item.patient.lastName}`,
                    requestId: item.requestId,
                  })
                }
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
          </Col>
        )}
      </Row>
    );
  };
  const bodyCard = item => {
    return (
      <>
        <Box display={'grid'} gridTemplateColumns={'100px 3fr 2fr 3fr 3fr 2fr'} gap={1} mb={1}>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>No. Cirugía</div>
            <div>{item.surgeryId}</div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Paciente</div>
            <div>
              {item.patient.firstName} {item.patient.lastName}
            </div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Identificación</div>
            <div>{item.patient.docNumber}</div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Aseguradora</div>
            <div>{item.corporateClient}</div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Valor devuelta</div>
            <div>{formatNumberWithoutDecimals(item.pendingAmount)}</div>
          </Box>
        </Box>
        {item.status.value === 'rejected' && (
          <Box display={'grid'} gridTemplateColumns={'5fr 100px 3fr 3fr 2fr'} gap={1}>
            <Box className={tableStyles.ellipsisAuto}>
              <div
                className={`fw-bold ${tableStyles.ordAquaMarineText} ${tableStyles.ellipsisAuto}`}
              >
                Comentarios
              </div>
              <div>{item?.comments ?? '-'}</div>
            </Box>
          </Box>
        )}
      </>
    );
  };

  const renderRejectModal = () => {
    return (
      <OrdModal
        show={rejectModal.isOpen}
        onHide={() => setRejectModal(REJECT_INITIAL_STATE)}
        title='Rechazar solicitud'
        centered
        className={`${modalStyles.container}`}
        btnYesName={'Aceptar'}
        btnNoEvent={() => setRejectModal(REJECT_INITIAL_STATE)}
        btnYesEvent={() =>
          onSubmitRefund({
            status: 'rejected',
            patientName: rejectModal.patientName,
            canId: rejectModal.canId,
            requestId: rejectModal.requestId,
            comments: rejectModal.comments,
          })
        }
        styleBody={'p-2'}
        size={600}
      >
        <Box>
          <Box>
            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
              &nbsp;Motivo
              <span className={`${tableStyles.ordOrangeText}`}>*</span>
            </Box>

            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={listCancellationReason?.results?.map(el => ({
                label: el?.canDescription,
                value: el?.canId,
              }))}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              onChange={({ value }) => {
                setRejectModal(state => ({
                  ...state,
                  canId: value,
                }));
              }}
            />
          </Box>

          <Box my={2}>
            <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
              &nbsp;Observaciones
              <span className={`${tableStyles.ordOrangeText}`}>*</span>
            </Box>

            <textarea
              placeholder='Escribir...'
              style={{ minHeight: '165px', maxHeight: '265px' }}
              className={`text-secondary ordInputAdmission w-100 p-2`}
              onChange={({ target }) => {
                setRejectModal(state => ({
                  ...state,
                  comments: target?.value,
                }));
              }}
            />
          </Box>
        </Box>
      </OrdModal>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {(refundsLoader || loadSubmitRefund || loaderListCancellationReason) && loader}
        {renderRejectModal()}
        <Box display={'grid'} gridTemplateColumns={'200px 200px 200px 1fr'} gap={2}>
          <Box>
            <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
              &nbsp;Solicitante
            </span>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              options={listUsersSelect}
              value={listUsersSelect.find(user => user.value === datesFilter.userId)}
              onChange={({ value }) => {
                setDatesFilter(state => ({
                  ...state,
                  userId: value,
                }));
              }}
            />
          </Box>
          <Box>
            <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
              &nbsp;Fecha desde
            </span>
            <input
              className={`ord-roundInput2`}
              type='date'
              value={datesFilter.fromDate}
              onChange={({ target }) => onChangeDates('fromDate', target.value)}
            />
          </Box>

          <Box>
            <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
              &nbsp;Hasta
            </span>
            <input
              className={`ord-roundInput2`}
              type='date'
              value={datesFilter.toDate}
              onChange={({ target }) => onChangeDates('toDate', target.value)}
            />
          </Box>

          <form
            onSubmit={onSubmitSearch}
            className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12} align-self-end`}
          >
            <input
              className={`ord-roundInput ${tableStyles.appInputDate} w-100`}
              type='text'
              name='search'
              placeholder='Escribe aquí para buscar'
              autoComplete='off'
            />
            <img src={ordBlueSearch} className='pointer' alt='buscar' />
          </form>
        </Box>
        {refundsResult?.results &&
          refundsResult.results.map(item => (
            <OrdCard
              key={item.requestId}
              className={`my-4 ${tableStyles.f14}`}
              title={titleCard(item)}
              body={bodyCard(item)}
            />
          ))}
      </>
    );
  };
  return renderComponent();
}
