import {
  GET_GENERAL_JOURNAL_REPORT,
  GET_GENERAL_BALANCE,
  GET_MAJOR_BALANCE_REPORT,
} from "../actions/actionTypes";

const initialState = {
  getJournalReport: [],
  getJournalReport_total: 0,
  getGeneralBalance: [],
  getGeneralBalance_total: 0,
  getMajorBalance: [],
  getMajorBalance_total: 0,
  loadingMB: false,
  loadingGR: false,
  loadingJR: false,
  dateReport: false,
  pagination: false,
  pagination2: false,
  dateReport2: false,
  dateReport3: false,
  pagination3: false,
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_JOURNAL_REPORT:
      return {
        ...state,
        getJournalReport: action.payload,
        getJournalReport_total: action.total,
        loadingJR: action.loadingJR,
        pagination: action.pagination,
        dateReport: action.dateReport,
      };
    case GET_GENERAL_BALANCE:
      return {
        ...state,
        getGeneralBalance: action.payload,
        getGeneralBalance_total: action.total,
        loadingGR: action.loadingGR,
        pagination2: action.pagination2,
        dateReport2: action.dateReport2,
      };
    case GET_MAJOR_BALANCE_REPORT:
      return {
        ...state,
        getMajorBalance: action.payload,
        getMajorBalance_total: action.total,
        loadingMB: action.loadingMB,
        pagination3: action.pagination3,
        dateReport3: action.dateReport3,
      };
    default:
      return state;
  }
};
