import {CUPS_FILTER} from "../actions/actionTypes.js";

import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { convertFilterToString } from "../helpers/convertToFilter";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal);


export const cups_filter = (objFilters) => (dispatch, getState) => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;

    fetch(`${URL_GATEWAY}${API_VERSION}/admin/cups/?${filters}`,{
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
    }})
    .then(response => response.json())
    .then(res => {
        const {results, row_search} = res
        dispatch({
            type: CUPS_FILTER,
            results: results,
            row_search: row_search
        })
    })
    .catch((err)=>{
        console.error(err)
    })
}


export const cups_edit_active = (id, data, description, executeThisAfter) => (dispatch, getState)  => {
    const token = getState().loginReducer.Authorization; // SIEMPRE VA

    let status = data.active ? "habilitar" : "deshabilitar"
        
    Swal.fire({
        title: `¿Está seguro de ${status} la cuenta, '${description}'?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#003f80',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, estoy seguro',
        cancelButtonText: 'Cancelar'
        }).then((result) => {
        if (result.isConfirmed) {
            fetch(`${URL_GATEWAY}${API_VERSION}/admin/cups/${id}`,{
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: token,
                }
            })
            .then(response => response.json())
            .then(res => {
                if(res.success){
                    MySwal.fire({
                        icon:'success',
                        title:'¡Cups actualizado!',
                        text:'El registro ha cambiado su estado correctamente'
                    })
                    executeThisAfter();
                }else{
                    MySwal.fire({
                        icon:'error',
                        title:'Error',
                        text:`${res.message}`
                    })
                }
            })
            .catch((err)=>{
                console.error(err);
                MySwal.fire({
                    icon:'error',
                    title:'Error al guardar',
                    text:'No se pudo cambiar el estado',
                    footer:'Si el problema persiste comuníquese con un asesor.'
                })
            })
        }
        })
      
  }
