import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import tableStyles from "../../components/Layouts/tableStyle.module.scss";
import DetailedClassification from "./DetailedClassification";
import GeneralClassification from "./GeneralClassification";
import { OrdGenericTemplate } from "../../OrderingModule/OrderingComponents/OrdGenericTemplate";

export default function UnexpectedSituations() {
    const [tabLabel, setTabLabel] = useState("generalClassification");

    const render = () => {
        return (
            <>

                <div className={`w-80`}>

                    <OrdGenericTemplate
                        className="w-100"
                        titleSize={12}
                        title={"Situaciones Inesperadas"}
                        titleClass={"mx-3"}
                    >
                        <div className="w-100">
                            <Row className="mx-2 pr-8 mt-2"
                            >
                                 <Col xs={3}> <button
                                    onClick={() => setTabLabel("generalClassification")}
                                    className={` ${tabLabel === "generalClassification" ? tableStyles.buttonSituationActive : tableStyles.buttonSituationDisabled}`}
                                >
                                    Clasificación general
                                </button></Col>
                                <Col xs={3}> <button
                                    onClick={() => setTabLabel("detailedClassification")}
                                    className={` ${tabLabel === "detailedClassification" ? tableStyles.buttonSituationActive : tableStyles.buttonSituationDisabled}`}>
                                    Clasificación detallada
                                </button></Col> 
                            </Row>
                            {tabLabel === "detailedClassification" ?
                                <DetailedClassification />
                                :
                                <GeneralClassification />
                            }
                        </div>

                    </OrdGenericTemplate>

                </div>
            </>
        );
    };

    return render();
}