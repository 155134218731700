import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import Engra from '../../assets/img/icons/engraneGris.svg';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { useHistory } from 'react-router-dom';
import ModalNew from '../../components/Layouts/ModalNew';
import { edit_Invoice, getListInvoices } from '../../actions/InvoiceReceiptActions';
import { useDispatch, useSelector } from 'react-redux';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import StyleInvoice from './InvoiceReceipt.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import CreateInvoice from './CreateInvoice';
import Swal from 'sweetalert2';
import { LOADING } from '../../actions/actionTypes';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import { Title } from '../../shared';

function ListInvoices() {
  // funcion para formatear valores de respuesta
  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const [contador] = useState(0);
  const [details, setDetails] = useState({ show: false });
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [loading, setLoading] = useState(true);
  const [trigger, setTrigger] = useState(1);
  const [justifi, setJustifi] = useState([]);
  const [filters, setFilter] = useState({
    search: '',
    page: 1,
    perpage: 10,
    status: '',
    entity_account: counter.loginReducer.currentAccount.id,
  });
  useEffect(() => {
    setLoading(true);
    dispatch(getListInvoices(filters, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contador, filters.page, trigger, filters.status]);

  let history = useHistory();
  function handleClick() {
    history.push('/tesoreria/RecepcionDeFacturas/NuevaFactura');
  }
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'listpinvoi',
    );
  useEffect(() => {
    if (!myPermission()?.read) {
      history.push('/tesoreria/inicio');
    }
  });
  const handleDeleteRecord = (id, number) => {
    Swal.fire({
      title: `<h2 class="swal2-title" id="swal2-title" style="display: flex; color:#01a0f6 ; font-size:2rem">¿Está seguro?</h2>`,
      text: `Se anulará la factura ${number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        setShowModal(true);
      }
    });
    setJustifi({
      ...justifi,
      id: id,
      status: 6,
    });
  };

  const handleRegisterRecord = (id, number) => {
    Swal.fire({
      title: `<h2 class="swal2-title" id="swal2-title" style="display: flex; color:#01a0f6 ; font-size:2rem">¿Está seguro?</h2>`,
      text: `Se registrar la factura ${number}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch({
          type: LOADING,
          payload: true,
        });
        dispatch(edit_Invoice({ id: id, status: 2 }, () => setTrigger(trigger + 1)));
      }
    });
  };

  const resetSearch = () => {
    setLoading(true);
    setFilter({
      ...filters,
      page: 1,
      perpage: 10,
    });
    dispatch(getListInvoices(filters, () => setLoading(false)));
  };
  const handleSumit = e => {
    e.preventDefault();
    setLoading(true);
    setFilter({
      ...filters,
      page: 1,
      perpage: 10,
    });
    dispatch(getListInvoices(filters, () => setLoading(false)));
  };

  const handlePageChange = val => {
    setFilter({
      ...filters,
      page: val,
    });
    getListInvoices(filters, () => setLoading(false));
  };

  const [showModal, setShowModal] = useState(false);

  const header = [
    <th key={1} className='col-1 text-center'>
      Número
    </th>,
    <th key={2} className='text-start' style={{ paddingLeft: '1rem' }}>
      Proveedor
    </th>,
    <th key={3} className='col-1 text-center'>
      Fecha
    </th>,
    <th key={4} className='col-1 text-center'>
      Vence
    </th>,
    <th key={5} className='col-3 text-center'>
      No. Entrada
    </th>,
    <th key={6} className='col-1 text-center'>
      Valor total
    </th>,
    <th key={7} className='col-1 text-center'>
      Estado
    </th>,
    <th key={8} className='col-1 text-center'></th>,
  ];

  const body = [];
  var color = '';
  var name1 = '';

  if (Array.isArray(counter.InvoiceReceiptReducer.results)) {
    counter?.InvoiceReceiptReducer?.results.forEach(item => {
      if (item.status === 1) {
        color = '#58595B';
        name1 = 'Precargada';
      } else if (item.status === 2) {
        color = '#00A551';
        name1 = 'Registrada';
      } else if (item.status === 3) {
        color = '#5942CD';
        name1 = 'Causada';
      } else if (item.status === 4) {
        color = '#005DBF';
        name1 = 'Parcialmente Pagada';
      } else if (item.status === 5) {
        color = '#E9B419';
        name1 = 'Pagada';
      } else if (item.status === 6) {
        color = '#EC1C24';
        name1 = 'Anulada';
      }

      body.push(
        <tr>
          <td className='text-start' style={{ paddingLeft: '1rem' }}>
            {item.invoice_nbr}
          </td>
          <td className='text-start' style={{ paddingLeft: '1rem' }}>
            {item.provider_name}
          </td>
          <td className='text-center'>{convertDateToLatinFormat(item.exp_date)}</td>
          <td className='text-center'>{convertDateToLatinFormat(item.due_date)}</td>
          <td className='text-center'>
            {item.warehouse_entry_id == null
              ? '-'
              : item.warehouse_entry_id.map(x => {
                  if (item.warehouse_entry_id.length > 1) {
                    return x.id + ' | ';
                  } else {
                    return x.id;
                  }
                })}
          </td>
          <td className='text-end' style={{ paddingRight: '1rem' }}>
            {'$' + numberWithCommas(item.total_amount)}
          </td>
          <td className='text-center'>
            <div
              className='rounded-pill '
              style={{ backgroundColor: `${color}20`, padding: '2px 10px' }}
            >
              <b style={{ verticalAlign: 'middle', color: `${color}` }}>{name1}</b>
            </div>
          </td>
          <td className='text-center'>
            {item.status ? (
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showDelete={item.status === 1 ? true : false}
                showDetails={item.status !== 1 ? true : false}
                showRegister={item.status === 1 ? true : false}
                deleteText='Anular'
                textDetails='Ver detalle'
                showDetailsEvent={() =>
                  setDetails({ ...details, show: true, details: true, edit: false, info: item })
                }
                deleteEvent={() => handleDeleteRecord(item.id, item.invoice_nbr)}
                registerEvent={() => handleRegisterRecord(item.id, item.invoice_nbr)}
              />
            ) : (
              <img alt='' className='text-start w-15' style={{ width: '20px' }} src={Engra} />
            )}
          </td>
        </tr>,
      );
    });
  }
  const renderListPinvoices = (
    <>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <div className='d-flex'>
          <Title
            className={'mb-2'}
            title='Listado de facturas'
            onClickIcon={() => {
              history.push('/tesoreria/inicio');
            }}
          />
        </div>

        <div>
          &nbsp;<label className={tableStyles.stylesLabel}>Estado</label>
        </div>

        <div className='d-flex gap-3 align-items-center'>
          <div className={tableStyles.containerSelect}>
            <Select
              placeholder='Seleccionar...'
              options={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Precargada', value: '1' },
                { label: 'Registrada', value: '2' },
                { label: 'Causada', value: '3' },
                { label: 'Parcialmente Pagada', value: '4' },
                { label: 'Pagada', value: '5' },
                { label: 'Anulada', value: '6' },
              ]}
              styles={customSelectNew}
              onChange={({ value }) =>
                setFilter({
                  ...filters,
                  status: value,
                })
              }
            />
          </div>

          <div className={tableStyles.containerSearchAdmin}>
            <form onSubmit={handleSumit}>
              <input
                className={StyleInvoice.inputSearch}
                placeholder='Buscar...'
                type='text'
                onChange={e =>
                  setFilter({
                    ...filters,
                    search: e.target.value,
                  })
                }
              ></input>
            </form>
          </div>

          <div className={tableStyles.iconSvg}>
            <img alt='' className='cursorPointer' src={Lupa} onClick={() => resetSearch()} />
          </div>
          {myPermission()?.create ? (
            <div
              className='text-end groupAddButton d-flex gap-2 align-items-center ms-auto'
              onClick={handleClick}
            >
              <label style={{ color: '#58595B', fontWeight: 'bold' }}>Nueva Factura</label>
              <button className='addCheckButton' />
            </div>
          ) : (
            ''
          )}
        </div>
        <div>
          <ModalNew
            show={showModal}
            onHide={() => setShowModal(false)}
            title={'Anulación'}
            btnYesEvent={() =>
              dispatch(
                edit_Invoice(
                  {
                    id: justifi.id,
                    status: justifi.status,
                    anullment_justif: justifi.anullment_justif,
                  },
                  () => {
                    setTrigger(trigger + 1);
                    setJustifi([]);
                    setShowModal(false);
                  },
                ),
              )
            }
            btnYesName={'Continuar'}
            size={'500'}
            btnYesDisabled={justifi.anullment_justif ? false : true}
          >
            <Row className='d-flex'>
              <Col className='d-grid' xs={12}>
                <label className={tableStyles.crudModalLabel}>Justificación</label>
                <textarea
                  className={IndividualStyles.textAreaClear}
                  onChange={e =>
                    setJustifi({
                      ...justifi,
                      anullment_justif: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </ModalNew>
        </div>
        <GenericTable headers={header} dark={true}>
          {body}
        </GenericTable>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {counter.InvoiceReceiptReducer.total ? filters.page : ''}
            {' de '}
            {Math.ceil(counter.InvoiceReceiptReducer.total / filters.perpage)
              ? Math.ceil(counter.InvoiceReceiptReducer.total / filters.perpage)
              : ''}{' '}
            ({counter.InvoiceReceiptReducer.total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={counter.InvoiceReceiptReducer.total}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClassPrev={`${paginationStyles.itemClassPrev}`}
            itemClassNext={`${paginationStyles.itemClassNext} `}
            itemClassFirst={`${paginationStyles.itemClassFirst} `}
            itemClassLast={`${paginationStyles.itemClassLast} `}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </>
  );
  return details.show ? (
    <CreateInvoice
      setDetails={setDetails}
      info={details.info}
      edit={details.edit}
      details={details.details ? null : details.details}
      show={details.show}
    />
  ) : (
    renderListPinvoices
  );
}
export default ListInvoices;
