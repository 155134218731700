//react
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//icons
import closeIcon from '../../../assets/img/icons/close.svg';
import iconMessage from '../../../assets/img/icons/icon_message.svg';
import iconTrans from '../../../assets/img/icons/trashNotes.svg';
import countNotes from '../../../assets/img/icons/countNotes.svg';
import warningIcon from '../../../assets/img/icons/warningIcon.gif';

//styles
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { appCustomSelectNew } from '../../../components/Layouts/react-select-custom';

//HOOKS
import { useGetMethod, usePostMethod } from '../../../Hooks';

//HELPERS
import { formatToRcSelect, loader } from '../../../helpers/helpers';

//components
import { Drawer } from '@material-ui/core';
import { Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import Select from 'react-select';
import { customSwaltAlertAsistencial } from '../../../helpers';

export const DrawerAndAlert = ({ patientDocument, patientId }) => {
  //states
  const store = useSelector(state => state);

  const token = store.loginReducer.Authorization;

  const idUser = store.loginReducer.user_data.id;

  const idAccount = store.loginReducer.currentAccount.id;

  const fullNameUser = `${store.loginReducer.user_data.first_name} ${store.loginReducer.user_data.first_surname}`;

  const [options, setOptions] = useState({
    showNotes: false,
    showImportance: false,
  });

  const [patient, setPatient] = useState({ eaccount: idAccount, id: patientId, patientId });

  const [comments, setComments] = useState({
    userId: idUser,
    entity: 'userClient',
    comment: '',
    id: '',
  });

  const [triggerComment, setTriggerComment] = useState(0);

  const [filters] = useState({
    filterPatient: patientDocument,
    page: 1,
    perpage: 100,
    orderByType: 'creation',
  });

  //HTTPS
  const {
    results: generalComments,
    load: loadGeneralComments,
    trigger: getGeneralComments,
  } = useGetMethod();

  const { load: loadCreateComments, trigger: sendComment } = usePostMethod();

  const { load: loadDeleteComments, trigger: deleteComment } = usePostMethod();

  const { loader: detailLoaderPatient, trigger: getDetailPatient } = useGetMethod();

  const {
    results: detailPatientId,
    loader: detailLoaderPatientId,
    trigger: getDetailPatientId,
  } = useGetMethod();

  const {
    results: importancies,
    load: loadImportancies,
    trigger: getImportancies,
  } = useGetMethod();

  const { load: loadCreateMarkPatient, trigger: sendMarkPatient } = usePostMethod();

  const { load: ListPacientLoader, trigger: getListPacient } = useGetMethod();

  //ACTIONS
  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '16px' }}>
              <div className={tableStyles.app_container_tras_notes}>
                <span className={tableStyles.app_name_drawer}>{item.userName}</span>
                <img
                  src={iconTrans}
                  width={15}
                  alt='tras-notes'
                  className='cursorPointer'
                  onClick={() => {
                    setOptions({ ...options, showNotes: true });
                    customSwaltAlertAsistencial({
                      icon: 'warning',
                      title: `¿Está seguro?`,
                      text: `Se eliminará la nota: ${item?.comment}`,
                      confirmButtonText: 'Si, continuar',
                      showCancelButton: true,
                    }).then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        deleteNoteId(item.id, item?.comment);
                      }
                    });
                  }}
                />
              </div>
              <p className={tableStyles.app_description_drawer}>{item.comment}</p>
              <div className={tableStyles.app_container_date_drawer}>
                <span className={tableStyles.app_date_drawer}>{item.date}</span>
              </div>
            </div>
            <div className={tableStyles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  const onSubmitComment = e => {
    e.preventDefault();
    sendComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'POST',
      body: { ...comments, id: patientId },
      succesAction: () => {
        const filterComments = {
          id: patientId,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });

        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });

        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha añadido la nota: ${comments?.comment}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        setTriggerComment(state => state + 1);

        setOptions({ ...options, showNotes: false, showImportance: false });

        setComments({ ...comments, comment: '' });
      },
      doAfterException: err => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const deleteNoteId = (id, comment) => {
    deleteComment({
      url: '/medical/comments/',
      token: token,
      noAlert: true,
      method: 'DELETE',
      body: { id: id },
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha eliminado la nota: ${comment}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        const filterComments = {
          id: patientId,
          entity: 'userClient',
        };
        getGeneralComments({
          url: '/medical/comments/',
          objFilters: filterComments,
          token: token,
        });
        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        setOptions({ ...options, showNotes: false, showImportance: false });
        setTriggerComment(state => state + 1);
      },
      doAfterException: err => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const OnSubmitMarkPatient = () => {
    sendMarkPatient({
      url: '/medical/patient/',
      token: token,
      method: 'PUT',
      body: patient,
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Proceso completado',
          text: `Usuario actualizado correctamente`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        getDetailPatient({
          url: '/medical/patient/',
          objFilters: patient,
          token: token,
        });
        getListPacient({
          url: '/medical/patient/listPatient/',
          objFilters: filters,
          token: token,
        });
        setOptions({
          ...options,
          showInfoPatient: false,
          showCards: true,
          showImportance: false,
        });

        setPatient(state => ({ ...state, cum_id: '' }));
      },
      doAfterException: err => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const renderTooltip = props => (
    <Tooltip {...props}>
      <div className={`${tableStyles.darkerGrayText}`}>Marcar importante</div>
    </Tooltip>
  );

  const renderTooltip2 = props => (
    <Tooltip {...props} className='mb-4'>
      <div className={`${tableStyles.darkerGrayText}`}>
        ({detailPatientId?.results?.notes_num}){' '}
        {detailPatientId?.results?.notes_num > 1 ? 'Notas' : 'Nota'}
      </div>
    </Tooltip>
  );

  const formatedImportances = formatToRcSelect(importancies.results, 'id', 'description');

  useEffect(() => {
    getImportancies({
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterComments = {
      id: patientId,
      entity: 'userClient',
    };

    getDetailPatientId({
      url: '/medical/patient/',
      objFilters: { id: patientId, eaccount: idAccount },
      token: token,
    });

    getGeneralComments({
      url: '/medical/comments/',
      objFilters: filterComments,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerComment]);

  return (
    <>
      {(loadGeneralComments ||
        loadCreateComments ||
        loadDeleteComments ||
        loadImportancies ||
        detailLoaderPatient ||
        loadCreateMarkPatient ||
        detailLoaderPatientId ||
        ListPacientLoader) &&
        loader}

      <div className='d-flex align-items-center mx-2'>
        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
          <span>
            <img
              alt={'icon'}
              onClick={() => {
                setOptions(state => ({ ...state, showImportance: true }));
              }}
              width={'40px'}
              src={warningIcon}
              className={`mr-2 mt-2 mb-2 cursorPointer`}
            ></img>
          </span>
        </OverlayTrigger>

        <OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip2}>
          <div
            className='pointer d-flex position-relative'
            onClick={() => {
              setOptions(state => ({ ...state, showNotes: true }));
            }}
          >
            <img
              alt='notes'
              height={22}
              src={countNotes}
              width={22}
              className={`${tableStyles.filterOrdAquaMarine}`}
            />
            <span className={`${tableStyles.textCountNotes2} ${tableStyles.filterWhite}`}>
              {detailPatientId?.results?.notes_num || 0}
            </span>
          </div>
        </OverlayTrigger>
      </div>

      <OrdModal
        title='Tipo de importancia'
        show={options.showImportance}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={() => {
          setOptions({ ...options, showImportance: false });
          setPatient(state => ({
            ...state,
            cum_id: '',
            risk: {},
          }));
        }}
        btnYesEvent={() => OnSubmitMarkPatient()}
        btnNoEvent={() => {
          setOptions({ ...options, showImportance: false });
          setPatient(state => ({
            ...state,
            cum_id: '',
            risk: {},
          }));
        }}
      >
        <div>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={formatedImportances}
            className='text-secondary'
            value={formatedImportances.filter(x => x.value === patient?.cum_id)}
            placeholder={'Seleccionar...'}
            onChange={e => {
              setPatient({
                ...patient,
                cum_id: e.value,
                risk: {
                  administrative: [{ id: e.value }],
                },
              });
            }}
            styles={appCustomSelectNew}
          />
        </div>
      </OrdModal>

      <Drawer
        anchor={'right'}
        open={options.showNotes}
        style={{ zIndex: 900 }}
        onClose={() => setOptions({ ...options, showNotes: false })}
      >
        <div className={tableStyles.app_drawer}>
          <div className={tableStyles.app_container_close}>
            <img
              src={closeIcon}
              width={25}
              className={'cursorPointer'}
              alt='closeIcon'
              onClick={() => {
                setComments({ ...comments, id: '' });
                setOptions({ ...options, showNotes: false });
              }}
            />
          </div>
          <div className={tableStyles.app_container_drawer}>
            <span className={tableStyles.app_title_drawer}>Notas</span>

            {generalComments.rowTotal === 0 ? (
              <div className={tableStyles.app_container_not_notes}>
                <span className={tableStyles.app_title_not_notes}>No hay notas aún</span>
              </div>
            ) : (
              <div className={tableStyles.app_container_content_drawer}>
                {formatDataComments(generalComments.results)}
              </div>
            )}

            <div className={tableStyles.app_container_form_drawer}>
              <Form.Group style={{ marginBottom: '10px' }}>
                <Form.Label className={tableStyles.app_title_input}>
                  <span>{fullNameUser}</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={4}
                  maxLength='170'
                  value={comments.comment}
                  onChange={e => setComments({ ...comments, comment: e.target.value })}
                  placeholder='Escribir...'
                />
              </Form.Group>
              <div className={tableStyles.app_container_date_drawer}>
                <img
                  src={iconMessage}
                  className={'cursorPointer'}
                  alt='iconMessage'
                  onClick={onSubmitComment}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};
