import { GET_STATUS_PAYMENT } from "../actions/actionTypes";

const initialState = {
  listStatusPayment: [],
  listStatusPayment_loading: false,
};
export const payHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_PAYMENT:
      return {
        ...state,
        listStatusPayment: action.payload,
        rowTotal: action.rowTotal,
        listStatusPayment_loading: action.loading,
      };
    default:
      return state;
  }
};
