import { useState } from "react";

const useForm = (initialState = {}) => {
  const [valuesForm, setValuesForm] = useState(initialState);

  const handleChange = (e) => {
    setValuesForm({ ...valuesForm, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (e, name) => {
    setValuesForm({ ...valuesForm, [name]: e.value });
  };

  const handleChangeCheck = (e) => {
    setValuesForm({ ...valuesForm, [e.target.name]: e.target.checked });
  };

  const reset = () => setValuesForm(initialState);

  return { handleChange, reset, valuesForm, setValuesForm, handleChangeCheck, handleChangeSelect };

};

export default useForm;
