import { 
    GET_CALENDAR_MEDICAL_EXAMS,
    SELECT_CALENDAR_MEDICAL_EXAMS,
} from "../actions/actionTypes";

const initialState = {
    results: [],
    chosen_id:null,
    chosen_area:null,
};
export const PlanningMedicalExamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CALENDAR_MEDICAL_EXAMS:
            return {
                ...state,
                results: action.payload,

            };
        case SELECT_CALENDAR_MEDICAL_EXAMS:
            return {
                ...state,
                chosen_id: action.id,
                chosen_area: action.area_name,
            }
        default:
            return state;
    }
};
