import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Back from '../../assets/img/icons/atras-icon.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

import { getReplaces } from '../../actions/movementsActions';

import GenericTableScroll from '../Layouts/GenericTableScroll';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from './CodeReplace.module.scss';

function ReplacementHistory(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);

  const [info, setInfo] = useState({
    dateUntil: '',
    dateFrom: '',
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'vouchers',
    );

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getReplaces(info));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const header = [
    <div key={1} className={`col-3 text-start`} style={{ paddingLeft: '10px' }}>
      Tercero original
    </div>,
    <div key={2} className={`col-3 text-start`} style={{ paddingLeft: '10px' }}>
      Tercero de reemplazo
    </div>,
    <div key={3} className={`col-3`}>
      Justificación
    </div>,
    <div key={4} className={`col-1`}>
      Fecha
    </div>,
    <div key={5} className={`col-1`}>
      Desde
    </div>,
    <div key={6} className={`col-1`}>
      Hasta
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.movementsReducer.replaces)) {
      storage.movementsReducer.replaces.forEach((item, index) => {
        tempList.push(
          <section2 className={`d-flex`} key={index}>
            <div className='text-center col-3'>{item.thirdNit + '-' + item.thirdName}</div>
            <div className='text-center col-3'>{item.thirdNitTo + '-' + item.thirdNameTo}</div>
            <div className='text-center col-3'>{item.justify}</div>
            <div className='text-center col-1'>
              {item.date ? item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : ''}
            </div>
            <div className='text-center col-1'>
              {item.startDate
                ? item.startDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                : ''}
            </div>
            <div className='text-center col-1'>
              {item.endDate ? item.endDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : ''}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  const handleSearch = e => {
    e.preventDefault();
    setInfo({ ...info, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {storage.movementsReducer.replaces_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className='d-flex'>
        <div className='align-self-top pt-1 mx-1'>
          <img
            src={Back}
            alt='volver'
            width={24}
            className='hoverPointer '
            style={{ marginTop: '56px' }}
            onClick={() => props.setShow(false)}
          />
        </div>
        <div className='d-flex flex-column' style={{ width: '100%' }}>
          <h1 className={tableStyles.title} style={{ paddingLeft: '0px', marginRight: '0px' }}>
            Historial de reemplazos
          </h1>
          <Row className='mx-1'>
            <Col xs={3} style={{ paddingLeft: '0' }}>
              <label className={`${tableStyles.crudModalLabel}`}>Fecha desde</label>
              <input
                type='date'
                name='Fdate'
                className={IndividualStyles.registerInputsBlue}
                onChange={e =>
                  setInfo({
                    ...info,
                    dateFrom: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col xs={3} style={{ paddingLeft: '0' }}>
              <label className={`${tableStyles.crudModalLabel}`}>Fecha hasta</label>
              <input
                type='date'
                name='Udate'
                className={IndividualStyles.registerInputsBlue}
                onChange={e =>
                  setInfo({
                    ...info,
                    dateUntil: e.target.value,
                  })
                }
              ></input>
            </Col>
            <Col
              xs={4}
              style={{ paddingTop: '4px', paddingLeft: '0px' }}
              className='align-self-end'
            >
              <form onSubmit={e => handleSearch(e)}>
                <label className={`${tableStyles.crudModalLabel}`}></label>
                <input
                  className={Styles.inputSearch}
                  placeholder='Buscar...'
                  //style={{maxHeight: "29px"}}
                  type='text'
                  onChange={e =>
                    setInfo({
                      ...info,
                      search: e.target.value,
                    })
                  }
                ></input>
              </form>
            </Col>
            <div
              className=' col display-grid  mr-2'
              style={{ alignContent: 'end', marginTop: '-4px' }}
            >
              <label className=''>
                {' '}
                <img
                  className={`${tableStyles.cursorPointer} hoverPointer`}
                  src={Lupa}
                  alt=''
                  onClick={() => setTrigger(trigger + 1)}
                />
              </label>
            </div>
          </Row>

          <GenericTableScroll
            headers={header}
            body={renderList()}
            typeHead={'2'}
          ></GenericTableScroll>
        </div>
      </div>
    </div>
  );
}
export default ReplacementHistory;
