import "./Button.scss";

export function Button({
    type = 'button',
    heightIcon,
    widthIcon,
    children,
    className,
    variant,
    startIcon,
    endIcon,
    size = "small",
    ...rest
}) {

    return (
        <button
            type={type}
            className={`button ${size && `button-${size}`} ${variant && `button-${variant}`} ${className}`}
            {...rest}
        >
            {startIcon && (
                <span className="px-2">
                    <img src={startIcon} alt="icon" height={heightIcon} width={widthIcon} />
                </span>
            )}
            <span>{children}</span>
            {endIcon && (
                <span className="ms-2">
                    <img src={endIcon} alt="icon" height={heightIcon} width={widthIcon} />
                </span>
            )}
        </button>
    );
}