import {
    ON_SET_ACTIVE_EVENT, ON_SET_EVENTS
} from "../actions/actionTypes";


const initialState = {
    events: [],
    activeEvent: null
};


export const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_SET_ACTIVE_EVENT:
            return {
                ...state,
                activeEvent: action.payload
            };
        case ON_SET_EVENTS:
            return {
                events: action.payload,
                activeEvent: null
            };

        default:
            return state;
    }

}
