import { useEffect, useRef } from "react";
import tableStyles from "./genericTable.module.scss";
import { Box } from '@mui/material';

export const GenericTableScrollDynamic = (props) =>{
  const leftTableRef = useRef(null);
  const rightTableRef = useRef(null);

  useEffect(() => {
    const leftTable = leftTableRef.current;
    const rightTable = rightTableRef.current;

    if (leftTable && rightTable) {
      const handleScroll = (e) => {
        if (e.target === leftTable) {
          rightTable.scrollTop = e.target.scrollTop;
        } else {
          leftTable.scrollTop = e.target.scrollTop;
        }
      };

      leftTable.addEventListener('scroll', handleScroll);
      rightTable.addEventListener('scroll', handleScroll);

      return () => {
        leftTable.removeEventListener('scroll', handleScroll);
        rightTable.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const columns = props.headerDynamic ? (props.columns || '50% 50%') : '100% 0%';

  return (

    <Box
      className={`${tableStyles.edgeOutside}`}
      display={'grid'}
      gridTemplateColumns={columns}
    >
      <div ref={leftTableRef}
       className={`d-flex ${props?.scroll && `${tableStyles.scrollSize} mt-2`}`}>
        <table
          className={
            `table
            ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
            ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
            ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
            ${tableStyles.edgeOutside}
            `
          }
          style={props?.style}
        >
          <thead className={`${tableStyles.thead} ${props?.clearHeader ? "" : props?.dark ? tableStyles.theadDark : tableStyles.theadLight}`}
          style={props?.scroll && {position: 'sticky', top: 0}}>
            <tr className={`${props?.fontFamilyTable ? props?.fontFamilyTable : ""}`}>{props?.headerStatic}</tr>
          </thead>
          <tbody>
            {props?.bodyStatic()}
          </tbody>
          {props?.footerStatic &&
            <tfoot style={{position: 'sticky', bottom: 0, backgroundColor: '#fff'}}>
              {props?.footerStatic()}
            </tfoot>
          }
        </table>
      </div>

      <div
        className={`d-flex ${props?.scroll && `${tableStyles.scrollSize} mt-2`}`}
        style={{ overflow: 'overlay' }}
        ref={rightTableRef}
      >
        <table
          className={
            `table
            ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
            ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ""}
            ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ""}
            ${tableStyles.edgeOutside2}
            `
          }
          style={props?.style}
        >
          <thead className={`${tableStyles.thead} ${props?.clearHeader ? "" : props?.dark ? tableStyles.theadDark : tableStyles.theadLight}`}
          style={props?.scroll && {position: 'sticky', top: 0}}>
            <tr className={`${props?.fontFamilyTable ? props?.fontFamilyTable : ""}`}>{props?.headerDynamic}</tr>
          </thead>
          <tbody>
            {props?.bodyDynamic()}
          </tbody>
          {props?.footerDynamic &&
            <tfoot style={{position: 'sticky', bottom: 0}}>
              {props?.footerDynamic()}
            </tfoot>
          }
        </table>
      </div>
    </Box>
  )
}