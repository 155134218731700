// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../Layouts/ModalNew';
import { useSelector } from 'react-redux';
import GenericTableNew from '../Layouts/GenericTableNew';
import { SkeletonTemplate } from '../Layouts/SkeletonTemplate';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Imprimir from '../../assets/img/icons/imprimir.svg';
//-------------- scss styles 😄   --------------
import Select from 'react-select';
import {
  customSelectNewDark,
  customSelectNewGrayNew,
} from '../../components/Layouts/react-select-custom';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { getPdfTest } from '../../actions/consultAction';
import { PdfViewer } from '../../components/Layouts/PdfViewer';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { Title } from '../../shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// ------------------ Functions ----------------------

function BalanceReport() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const [info, setInfo] = useState({
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
    balance: '',
  });
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState('');
  const getPdf = async () => {
    const result = await getPdfTest(
      '',
      storage.loginReducer.Authorization,
      'treasury/consultation_of_bank_notes',
    );
    if (result?.success) {
      return setBase64(result?.result?.base64);
    } else {
      setShowPdf(false);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `${result?.message}`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };
  useEffect(() => {
    if (showPdf) {
      getPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPdf]);

  const [body] = useState([]);

  // --------------- Table 1 💥  ----------------//

  const header = [
    <th key={`reqTH2`} className='px-2'>
      Subcuenta
    </th>,
    <th key={`reqTH3`} className='px-2 text-center'>
      NIT
    </th>,
    <th key={`reqTH4`} className='px-2 text-start'>
      Tercero
    </th>,
    <th key={`reqTH5`} className='px-2 text-end'>
      Saldo anterior
    </th>,
    <th key={`reqTH6`} className='px-2 text-end'>
      Debe
    </th>,
    <th key={`reqTH7`} className='px-2 text-end'>
      Haber
    </th>,
    <th key={`reqTH8`} className='px-2 text-end'>
      Saldo final
    </th>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(body)) {
      body.forEach((item, index) => {
        tempList.push(
          <tr key={index} className='hover-table-row'>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //-------------- select options 🍕   --------------

  let optionsBalances = [
    { key: 'default', value: '', label: 'Seleccionar...' },
    { key: '2i9', value: 1, label: 'Cuenta' },
    { key: '328938', value: 2, label: 'Tercero' },
  ];

  // let optionsAccount = [{ key: "", value: "", label: "Seleccione cuenta" }];
  //   if (Array.isArray(storage.pucReducer.niifAccounts)) {
  //     storage.pucReducer.niifAccounts.map((item, index) => {
  //         optionsAccount.push({
  //         value: item.id,
  //         label: item.complete_account +" - " + item.description ,
  //         key: index +"account",
  //       });
  //     });
  // }

  //--------------  Actions  🍕   --------------

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className={tableStyles.title}>
          <Title
            title='Informe de saldos'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        {/*----------------------------FIRST ROW---------------------------------- */}
        <Row className='d-flex'>
          <Col xs={2}>
            <label className={tableStyles.crudModalLabel}>Saldos por</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsBalances}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              onChange={e =>
                setInfo({
                  ...info,
                  balance: e.value,
                })
              }
            />
          </Col>
          <Col xs={4}>
            <label className={tableStyles.crudModalLabel}>
              {info.balance ? (info.balance === 1 ? 'Cuenta' : 'Tercero') : 'ㅤ'}
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              styles={info.balance ? customSelectNewDark : customSelectNewGrayNew}
              placeholder='Seleccionar...'
              isDisabled={info.balance ? false : true}
            />
          </Col>
          <Col xs={3}>
            <label className={tableStyles.crudModalLabel}>
              {info.balance ? 'Fecha desde' : 'ㅤ'}
            </label>
            <input
              type='date'
              name='name'
              placeholder='Escribir...'
              style={
                info.balance
                  ? {}
                  : { border: '0.7px solid #CECECE', background: '#FFFFFF', color: '#CECECE' }
              }
              className={IndividualStyles.registerInputsBlue}
              disabled={info.balance ? false : true}
            />
          </Col>

          <Col xs={3}>
            <label className={tableStyles.crudModalLabel}>{info.balance ? 'Hasta' : 'ㅤ'}</label>
            <input
              type='date'
              name='name'
              placeholder='Escribir...'
              style={
                info.balance
                  ? {}
                  : { border: '0.7px solid #CECECE', background: '#FFFFFF', color: '#CECECE' }
              }
              className={IndividualStyles.registerInputsBlue}
              disabled={info.balance ? false : true}
            />
          </Col>
        </Row>

        {/*----------------------------SECOND ROW---------------------------------- */}
        <Row className='d-flex'>
          <Col xs={11}>
            <label
              className={`${tableStyles.crudModalLabel}`}
              style={{ marginTop: '22px' }}
            ></label>
            <input
              className={IndividualStyles.Search}
              style={info.balance ? {} : { border: '0.7px solid #CECECE', background: '#FFFFFF' }}
              placeholder={info.balance ? 'Buscar...' : ''}
              type='text'
              disabled={info.balance ? false : true}
            ></input>
          </Col>
          <div className=' display-grid col-1 p-0' style={{ alignContent: 'end' }}>
            <label className=''>ㅤ </label>
            {info.balance ? (
              <div className='d-flex'>
                <img alt='' className={`${tableStyles.cursorPointer}`} src={Lupa} />
                <img
                  alt=''
                  className={`${tableStyles.cursorPointer} `}
                  style={{ paddingLeft: '5px', paddingRight: '5px' }}
                  src={Imprimir}
                  onClick={() => setShowPdf(true)}
                />
                <img alt='' src={Excel} />
              </div>
            ) : (
              <div className='d-flex'>
                <img
                  alt=''
                  className={`${tableStyles.cursorPointer}`}
                  style={{ filter: 'grayscale(100%)' }}
                  src={Lupa}
                />
              </div>
            )}
          </div>
        </Row>

        {/*----------------------------TABLE---------------------------------- */}
        {info.balance ? (
          <GenericTableNew headers={header} dark={true}>
            {renderList()}
          </GenericTableNew>
        ) : (
          <SkeletonTemplate footerDisabled={true}></SkeletonTemplate>
        )}

        <div className={IndividualStyles.bottom}>
          {/* <div className={paginationStyles.wrapper}>
                        <p className={paginationStyles.paginationText}>
                        Pag. {storage.accountingConsultsReducer.listVaucherHistory? filters.page : ""}
                        {" de "}
                        {Math.ceil(storage.accountingConsultsReducer?.listVaucherHistory_total / filters.perpage)
                            ? Math.ceil(
                                storage.accountingConsultsReducer?.listVaucherHistory_total / filters.perpage
                            )
                            : ""}{" "}
                        ({storage.accountingConsultsReducer?.listVaucherHistory_total} encontrados)
                        </p>
                        <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={10}
                        totalItemsCount={Number(storage.accountingConsultsReducer?.listVaucherHistory_total)}
                        pageRangeDisplayed={5}
                        onChange={(e) => setFilters({ ...filters, page: e })}
                        itemClassPrev={paginationStyles.itemClassPrev}
                        itemClassNext={paginationStyles.itemClassNext}
                        itemClassFirst={paginationStyles.itemClassFirst}
                        itemClassLast={paginationStyles.itemClassLast}
                        itemClass={paginationStyles.itemClass}
                        />
                    </div> */}
        </div>
      </div>
      <ModalNew
        title='Preview'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
    </>
  );
}
export default BalanceReport;
