//costcenter' reducer

import {
  COLLECTION_GET_ONE,
  COLLECTION_LOAD_ONE,

} from "../actions/actionTypes";

const initialState = {
  total: 0,
  error: null,
  Authorization: "",
  loading: false,
};

export const collectionGetOneReducer = (state = initialState, action) => {
  switch (action.type) {

    case COLLECTION_LOAD_ONE:
      return {
        ...state,
        loading: action.loading,
      };
    case COLLECTION_GET_ONE:
      return {
        ...state,
        cc: action.results,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };


    default:
      return state;
  }
};
