import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { onCapitalLetter } from '../../../helpers/helpers';

import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';

import '../../../OrderingModule/orderingStyles.css';

export const TelemedicineHomeContent = () => {
  let history = useHistory();
  const storage = useSelector(state => state);

  const accountingModules = storage.loginReducer.all_categories?.find(
    cat => cat?.name === 'Telemedicina',
  );
  const { modules } = accountingModules;

  const contentDynamic = modules?.map(module => {
    const textJoin = onCapitalLetter({ text: module?.name });
    return {
      title: textJoin,
      content: module?.functionalities?.map(subModule => {
        let subModuleName = onCapitalLetter({ text: subModule?.name });
        let returnValue = <></>;
        let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
          x => x.functionality?.prefix === subModule?.prefix,
        )?.read;
        if (permissionValidation) {
          returnValue = { subtitle: subModuleName, action: () => history.push(subModule?.url) };
        }
        return returnValue;
      }),
    };
  });
  return (
    <div>
      {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
      {<PseudoSideBarAccordion accordionItems={contentDynamic} />}
    </div>
  );
};
