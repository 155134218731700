import {
  GET_BILLS_AND_VALUES,
  GET_NOTE_TYPES_LIST,
  GET_PROVIDERS_LIST,
  GET_PROVIDERS_NOTES,
  GET_RECORD_NOTES_LIST,
  LOADING,
} from "../actions/actionTypes";

const initialState = {
  noteTypesList: [],
  providersList: [],
  billsAndValues: [],
  noteTypeList: [],
  noteRecords: [],
  row_total_query_notes: 0,
  loading: false,
};
export const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROVIDERS_NOTES:
      return {
        ...state,
        noteTypesList: action.payload,
        row_total: action.row_total,
        loading: action.loading,
      };
    case GET_PROVIDERS_LIST:
      return {
        ...state,
        providersList: action.payload,
        // row_total: action.row_total,
      };
    case GET_BILLS_AND_VALUES:
      return {
        ...state,
        billsAndValues: action.payload,
        row_total_query_notes: action.row_total,
      };
    case GET_NOTE_TYPES_LIST:
      return {
        ...state,
        noteTypeList: action.payload,
        row_total: action.row_total,
        // row_total: action.row_total,
      };
    case GET_RECORD_NOTES_LIST:
      return {
        ...state,
        noteRecords: action.payload,
        row_total: action.row_total,
        loading: action.loading,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
