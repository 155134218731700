import { Checkbox } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import downloadDoc from '../../../assets/img/icons/downloadDoc.svg';
import paperplane from '../../../assets/img/icons/send.svg';

import {
  get_endownment_deliveries,
  get_endownment_deliveries_pdf,
  get_endownment_groups,
  insert_endowment_deliveries,
} from '../../../actions/payrollActions';
import { convertDateToLatinFormat } from '../../../helpers/convertDateFormat';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';

import FileInput from '../../Layouts/fileInput';

import tableStyles from '../../Layouts/tableStyle.module.scss';
import payrollStyles from '../../Payroll/payrollStyles.module.scss';

function CurriculumEndowments(props) {
  const store = useSelector(state => state);
  const dispatch = useDispatch();

  const userdata = store.loginReducer.user_data;
  console.log('userdata: ', userdata);

  const [trigger, setTrigger] = useState(1);
  const [saveFile, setsaveFile] = useState({
    candidate: userdata?.payrollInfo?.id_candidate,
    delivered_by: userdata?.first_name + ' ' + userdata?.first_surname,
  });

  const [loading, setLoading] = useState(false);

  const [checkedArticles, setCheckedArticles] = useState([]);

  useEffect(() => {
    dispatch(
      get_endownment_groups({
        candidate: userdata?.payrollInfo?.id_candidate,
        eaccount: store.loginReducer.currentAccount.id,
      }),
    );
    dispatch(
      get_endownment_deliveries({
        candidate: userdata?.payrollInfo?.id_candidate,
      }),
    );
  }, [
    dispatch,
    store.loginReducer.currentAccount.id,
    trigger,
    userdata?.payrollInfo?.id_candidate,
  ]);

  const isBoxSelected = id => {
    let target = checkedArticles.findIndex(x => x.id === id);
    return target >= 0;
  };

  const changeBoxSelected = subitem => {
    let tempSelected = checkedArticles;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === subitem.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== subitem.id);
      } else {
        let newChange = {
          ...subitem,
        };
        tempSelected.push(newChange);
      }
      setCheckedArticles(tempSelected);
    }
  };

  const isBoxAllSelected = (id_end_group, sublistlen) => {
    const subarray = checkedArticles.filter(subitem => subitem.id_end_group === id_end_group);
    return subarray.length === sublistlen;
  };

  const changeBoxAllSelected = (id_end_group, checkEmAll) => {
    if (checkEmAll) {
      if (Array.isArray(store.payrollReducer.endownment_groups)) {
        let tempSelected = [...checkedArticles];
        const chosengroup = store.payrollReducer.endownment_groups.find(
          group => group.id === id_end_group,
        );
        if (Array.isArray(chosengroup.articles)) {
          chosengroup.articles.forEach(item => {
            let target = tempSelected.findIndex(x => x.id === item.id);
            if (target < 0) {
              tempSelected.push(item);
            }
          });
          setCheckedArticles(tempSelected);
        }
      }
    } else {
      const subarray = checkedArticles.filter(subitem => subitem.id_end_group !== id_end_group);
      setCheckedArticles(subarray);
    }
  };

  const renderArticles = () => {
    let temp = [];
    if (Array.isArray(store.payrollReducer.endownment_groups)) {
      store.payrollReducer.endownment_groups.forEach((item, index) => {
        let subtemp = [];
        item.articles.forEach((subitem, subindex) => {
          subtemp.push(
            <div
              key={subindex}
              className={`d-flex align-items-center`}
              style={{ marginLeft: '32px', marginBottom: 8 }}
            >
              <Checkbox
                checked={isBoxSelected(subitem.id, subitem.id_end_group)}
                onChange={() => changeBoxSelected(subitem)}
                color='primary'
                style={{ margin: 0, padding: 0, paddingRight: 16 }}
              />
              <p
                className={`m-0 ${payrollStyles.checklistText}`}
                style={{ flex: 1, fontWeight: 'bold' }}
              >
                {subitem.description}
              </p>
              <p className={`m-0 ${payrollStyles.checklistText}`}>{subitem.min_quantity}</p>
            </div>,
          );
        });

        temp.push(
          <div key={index} style={{ marginTop: '8px' }}>
            <div className={`d-flex align-items-center`}>
              <Checkbox
                checked={isBoxAllSelected(item.id, item.articles.length)}
                onChange={() =>
                  changeBoxAllSelected(item.id, !isBoxAllSelected(item.id, item.articles.length))
                }
                color='primary'
              />
              <p className={`m-0 ${payrollStyles.sectionSubTitle}`}>{item.description}</p>
            </div>
            {subtemp}
          </div>,
        );
      });
    }

    return temp;
  };

  const renderHistory = () => {
    let temp = [];
    if (Array.isArray(store.payrollReducer.endowment_deliveries)) {
      store.payrollReducer.endowment_deliveries.forEach((item, index) => {
        temp.push(
          <li key={index} className={`d-flex align-items-center ${payrollStyles.historyListItem}`}>
            <p className={payrollStyles.historyTextBlue}>
              {convertDateToLatinFormat(item.delivery_date)}
            </p>
            <div>
              <a target='_blank' href={item.delivery_signed} rel='noreferrer'>
                <p className={payrollStyles.historyTextGray}>
                  {Array.isArray(item.articles)
                    ? item.articles.map(x => x.description).join(', ')
                    : 'Document'}
                </p>
              </a>
              <p className={payrollStyles.historyText}>{item.delivered_by}</p>
            </div>
          </li>,
        );
      });
    }
    return temp;
  };

  const sendPost = () => {
    let error = '';
    if (!saveFile.delivery_date) {
      error = 'el campo fecha es obligatorio';
    }
    if (!saveFile.filename) {
      error = 'se necesita subir un archivo pdf';
    }

    if (error !== '') {
      return customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: error,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
    setLoading(true);
    dispatch(
      insert_endowment_deliveries(
        {
          ...saveFile,
          articles: checkedArticles,
        },
        () => {
          setLoading(false);
          setsaveFile({
            candidate: userdata?.payrollInfo?.id_candidate,
            delivered_by: userdata?.first_name + ' ' + userdata?.first_surname,
          });
          setTrigger(trigger + 1);
        },
      ),
    );
  };

  const getNextDate = () => {
    if (Array.isArray(store.payrollReducer.endowment_deliveries)) {
      let lastHistory = store.payrollReducer.endowment_deliveries.at(-1);
      let lastdate = new Date();
      if (lastHistory?.delivery_date) {
        lastdate = new Date(lastHistory?.delivery_date);
      }
      let lowestRenewal = 0;
      if (Array.isArray(lastHistory?.articles)) {
        lowestRenewal = lastHistory.articles
          .map(x => x.renewal_days)
          .reduce(
            (prev, current) => {
              if (prev < current) {
                return prev;
              } else {
                return current;
              }
            },
            [Infinity],
          );
      }
      lastdate.setDate(lastdate.getDate() + lowestRenewal);
      return lastdate.toISOString().substring(0, 10);
    } else {
      return '';
    }
  };

  return (
    <div className={`d-flex`}>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div style={{ flex: 3, padding: '32px', height: '70vh', overflowY: 'auto' }}>
        <p className={`${payrollStyles.sectionTitle}`}>Dotación Asignada</p>

        {renderArticles()}
      </div>

      <div style={{ flex: 4, padding: '32px', height: '70vh', overflowY: 'auto' }}>
        <p className={`${payrollStyles.sectionTitle}`}>Nueva Entrega</p>

        <div className={`d-flex flex-column`}>
          <label className={tableStyles.crudModalLabel}>Persona que realiza la entrega*</label>
          <input
            type='text'
            className={`register-inputs`}
            disabled={true}
            value={saveFile.delivered_by}
          />
        </div>

        <div className={`d-flex flex-column`}>
          <label className={tableStyles.crudModalLabel}>Fecha de entrega*</label>
          <input
            key={'fecha' + trigger}
            type='date'
            className={`register-inputs`}
            value={saveFile.delivery_date}
            onChange={e => setsaveFile({ ...saveFile, delivery_date: e.target.value })}
          />
        </div>

        <div
          className={payrollStyles.downloadBtn}
          style={{ marginTop: '15px', cursor: 'pointer' }}
          onClick={() =>
            dispatch(
              get_endownment_deliveries_pdf({ candidate: userdata?.payrollInfo?.id_candidate }),
            )
          }
        >
          <img
            src={downloadDoc}
            className={`mr-3`}
            style={{ height: '16px', width: '16px' }}
            alt=''
          />
          <p className={`m-0 align-items-center ${payrollStyles.sectionSubTitle}`}>
            Generar acta de entrega
          </p>
        </div>

        <div className={`d-flex flex-column`}>
          <label className={tableStyles.crudModalLabel}>Subir acta firmada</label>
          <FileInput
            key={'fileinput' + trigger}
            onChange={file =>
              setsaveFile({
                ...saveFile,
                signed_doc: file.base64,
                filename: file.full_name,

                name_doc: file.name_doc,
                type_doc: file.type_doc,
              })
            }
            name_doc={saveFile.name_doc}
            type_doc={saveFile.type_doc}
          />
        </div>

        <div className={`d-flex flex-row-reverse`}>
          <button
            className={`col-md-4 ${tableStyles.buttonExtra3} justify-content-center`}
            style={{ marginTop: '23px' }}
            onClick={() => sendPost()}
          >
            <img src={paperplane} alt='enviar' /> <span></span>Enviar
          </button>
        </div>
      </div>

      <div style={{ flex: 5, padding: '32px', height: '70vh', overflowY: 'auto' }}>
        <p className={`${payrollStyles.sectionTitle}`}>Información de dotaciones</p>

        <div className={`d-flex flex-column`}>
          <label className={tableStyles.crudModalLabel}>Fecha de próxima entrega</label>
          <input type='date' className={`register-inputs`} value={getNextDate()} disabled={true} />
        </div>

        <p
          className={tableStyles.blackGrayBoldFont}
          style={{ marginTop: '16px', marginBottom: '4px' }}
        >
          Tallas
        </p>

        <div className={`d-flex`}>
          <div className={`d-flex flex-column mr-3`}>
            <label className={tableStyles.crudModalLabel}>Camisa</label>
            <input
              type='text'
              className={`register-inputs`}
              value={props.employeeInfo?.shirt_size}
              disabled={true}
            />
          </div>
          <div className={`d-flex flex-column mr-3`}>
            <label className={tableStyles.crudModalLabel}>Pantalón</label>
            <input
              type='text'
              className={`register-inputs`}
              value={props.employeeInfo?.pant_size}
              disabled={true}
            />
          </div>
          <div className={`d-flex flex-column`}>
            <label className={tableStyles.crudModalLabel}>Calzado</label>
            <input
              type='text'
              className={`register-inputs`}
              value={props.employeeInfo?.shoe_size}
              disabled={true}
            />
          </div>
        </div>
        <p className={`${payrollStyles.sectionTitle}`} style={{ marginTop: 30 }}>
          Historial
        </p>

        <ul>{renderHistory()}</ul>
      </div>
    </div>
  );
}

export default CurriculumEndowments;
