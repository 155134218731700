import { Fragment, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export const Variables = ({ getInitialList, initialList = {} }) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idEnterprise = store.loginReducer.currentAccount.id;

  useEffect(() => {
    const filters = {
      page: 1,
      search: '',
      perpage: 10,
      idAccount: idEnterprise,
    };
    getInitialList({
      multipleGet: [
        {
          url: '/medical/protocolConditions',
          objFilters: filters,
          token: token,
          requestName: 'variables',
        },
      ],
      token: token,
    });
  }, [idEnterprise, getInitialList, token]);

  return (
    <div>
      {initialList?.variables?.results?.length > 0 &&
        initialList?.variables?.results?.map((e, index) => {
          return (
            <Fragment key={index}>
              <Row className='ml-3 pt-3'>
                <Col
                  xs={11}
                  className={`${tableStyles.ordSearchSections} my-2 ml-2`}
                  style={{ borderRadius: '10px' }}
                >
                  <Row className={`my-2`}>
                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Nombre</b>
                        </Form.Label>
                        <Form.Control
                          value={e.name}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Campo de historia clínica</b>
                        </Form.Label>
                        <Form.Control
                          value={e.fieldName}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={3}>
                      <Form.Group className='mb-3 text-start' controlId='lName'>
                        <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} text-start`}>
                          <b> &nbsp; Condición</b>
                        </Form.Label>
                        <Form.Control
                          value={e.value}
                          disabled
                          className={`ord-roundInput`}
                          type='text'
                          placeholder=''
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          );
        })}
    </div>
  );
};
