import { Col, Form, Row } from 'react-bootstrap';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { useGetMethod, usePostMethod } from '../../Hooks';

import { convertBase64, customSwaltAlertAsistencial, generateId, loader } from '../../helpers';

import adding from '../../assets/img/icons/add-check-blue.svg';
import addFile from '../../assets/img/icons/addFile.svg';
import deleteImage from '../../assets/img/icons/extraBtnRedX.svg';

import LetterEditor from '../../components/LetterEditor/LetterEditor';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';

import styles from '../../components/Layouts/tableStyle.module.scss';

const VARIABLE_STYLES = {
  '@': `${styles.inputVariableCareTheme}`,
  '@Focused': `${styles.inputVariableFocus}`,
};

const ServiceReport = () => {
  const store = useSelector(state => state) ?? null;
  const sessionAccount = store.loginReducer.currentAccount;
  const token = store?.loginReducer.Authorization;
  const location = useLocation();
  const srvId = location?.state?.srvId;
  const requiresImage = location?.state?.requiresImage;
  const reportRequired = location?.state?.reportRequired;
  const history = useHistory();
  const userId = store.loginReducer.user_data.id;
  const [modal, setModal] = useState(false);
  const [reportPayload, setReportPayload] = useState({});
  const [images, setImages] = useState([]);
  const [showSectionForm, setShowSectionForm] = useState(false); //POONERLO EN FALSE
  const { load: updateServiceLoader, trigger: updateService } = usePostMethod();
  const { trigger: getDXServices, load: dxLoader, results: getDXservicesResults } = useGetMethod();
  const {
    trigger: getVariables,
    results: variableServicesRes,
    load: variablesLoader,
  } = useGetMethod();

  const templateVariables = useMemo(() => {
    if (variableServicesRes?.results) {
      return variableServicesRes.results.map(elem => `[${elem.name}]`);
    }
    return [];
  }, [variableServicesRes?.results]);

  const variableLabels = useMemo(() => {
    if (variableServicesRes?.results) {
      return variableServicesRes.results.reduce((acc, elem) => {
        acc[`[${elem.name}]`] = elem.name.replace('_', ' ');
        return acc;
      }, {});
    }
    return {};
  }, [variableServicesRes?.results]);

  useEffect(() => {
    if (srvId) {
      getDXServices({
        url: '/medical/clinicHistory/templatesConfigurationDx/',
        objFilters: { srvId },
        token,
        succesAction: res => {
          if (res.results.requeridImage && res.results.requeridReport) {
            setShowSectionForm(true);
          }
          if (res.results.images && res.results.images.length > 0) {
            setImages(res.results.images);
          }
        },
      });
      getVariables({
        url: '/medical/generals/fixedData/',
        token,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  useEffect(() => {
    if (Object.keys(reportPayload).length < 10) {
      if (getDXservicesResults?.results) {
        if (Object.keys(getDXservicesResults?.results).length > 10) {
          setReportPayload(getDXservicesResults?.results);
        }
      }
    }
  }, [getDXservicesResults, reportPayload]);

  const addImage = async e => {
    var f = e.target.files[0];
    if (f) {
      var name_doc = f.name;
      var ext = f.name.split('.')[1];
      if (ext !== 'png' && ext !== 'jpg') {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'El formato del archivo debe ser PNG o JPG',
          showCancelButton: false,
        });
      }
      let encode = await convertBase64(e.target.files[0]);
      let file64 = encode.split(',')[1];
      setImages([
        ...images,
        {
          id: null,
          identifier: generateId(),
          filename: name_doc,
          base64: file64,
          ext: ext,
          url: null,
        },
      ]);
    }
  };

  const deleteImageFunction = image => {
    const imagesFiltered = images.filter(
      img => (img.id ?? img.identifier) !== (image.id ?? image.identifier),
    );
    setImages(imagesFiltered);
  };
  const renderReport = () => {
    return (
      <div
        className={`${styles.renderReport} ${styles.f12} `}
        style={{ height: 'calc( 86vh - 100px)', overflow: 'auto' }}
      >
        <section className='bg-white f10 h-100' style={{ padding: '1rem' }}>
          <div className='h-100 d-flex flex-column justify-content-between'>
            <div>
              <Row className='pb-2'>
                <Col xs={4} className={`${styles.ordDarkBlueText} text-start`}>
                  Logo cuenta
                </Col>
                <Col xs={4} className={`${styles.ordDarkBlueText} text-center`}>
                  {' '}
                  Nombre Cuenta
                </Col>
                <Col xs={4} className={`text-end`}>
                  {' '}
                  Página: 1/1{' '}
                </Col>
              </Row>
              <Row className={`${styles.bgClearGray} p-3 mx-0 mb-2`}> </Row>
              <Row className={`${styles.ordAquaMarineBg20} p-2 mx-0 mb-2`}> </Row>
              <Row
                className={` ${styles.ordDarkBlueText} px-2 mx-0 mb-2 text-center justify-content-center`}
              >
                {reportPayload?.title ?? 'Título'}
              </Row>

              <div className='mt-3'>
                {reportPayload?.introduction?.enabled && reportPayload?.introduction?.showTitle && (
                  <Row className={`${styles.tlnTextPrimary} mx-0`}> Introducción </Row>
                )}
                {reportPayload?.introduction?.enabled && (
                  <Row className={`mx-0`}>
                    <LetterEditor
                      readOnly
                      defaultValue={
                        reportPayload?.introduction?.textLabel ?? reportPayload?.introduction?.text
                      }
                      variableClasses={VARIABLE_STYLES}
                    />
                  </Row>
                )}
              </div>
              <div>
                {!showSectionForm && (
                  <div className='mt-3'>
                    <Row className={`${styles.tlnTextPrimary} mx-0`}> Realización del examen </Row>
                    <Row className={`mx-0 d-flex flex-column`}>
                      {reportPayload?.variables?.length > 0 &&
                        reportPayload.variables.map(variable => {
                          return (
                            <div key={variable.id} className='d-flex flex-column'>
                              <div>
                                {!!variable.od && (
                                  <span>
                                    {' '}
                                    <b> {variable.name} OD:</b> XXXXXX
                                  </span>
                                )}
                              </div>
                              <div>
                                {!!variable.oi && (
                                  <span>
                                    {' '}
                                    <b> {variable.name} OI:</b> XXXXXX
                                  </span>
                                )}
                              </div>
                              <div>
                                {!variable.od && !variable.oi && (
                                  <span>
                                    {' '}
                                    <b>{variable.name}:</b> XXXXXX
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </Row>
                    <br />
                    <Row className={`mx-0`}>
                      {' '}
                      <span className=''>
                        {' '}
                        <b>Notas:</b> xxxxxxxxx
                      </span>{' '}
                    </Row>
                  </div>
                )}
              </div>

              <div className='d-flex flex-column'>
                {images.length > 0 &&
                  images.map(image => {
                    return (
                      <div
                        className='align-self-center pb-2 text-center'
                        key={image.id ?? image.identifier}
                      >
                        <img
                          src={image.url ?? `data:application/pdf;base64,${image.base64}`}
                          alt='referenceValue'
                          width={'75%'}
                          height={'auto'}
                        />
                      </div>
                    );
                  })}
              </div>

              {showSectionForm && (
                <>
                  {}
                  <div className='mt-3'>
                    {reportPayload?.findings?.enabled && reportPayload?.findings?.showTitle && (
                      <Row className={`${styles.tlnTextPrimary} mx-0`}> Hallazgos </Row>
                    )}
                    {reportPayload?.findings?.enabled && (
                      <div>
                        <Row className={`mx-0 d-flex flex-column`}>
                          {reportPayload?.variables?.length > 0 &&
                            reportPayload.variables.map(variable => {
                              return (
                                <div key={variable.id} className='d-flex flex-column'>
                                  <div>
                                    {!!variable.od && (
                                      <span>
                                        {' '}
                                        <b> {variable.name} OD:</b> XXXXXX
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!!variable.oi && (
                                      <span>
                                        {' '}
                                        <b> {variable.name} OI:</b> XXXXXX
                                      </span>
                                    )}
                                  </div>
                                  <div>
                                    {!variable.od && !variable.oi && (
                                      <span>
                                        {' '}
                                        <b>{variable.name}:</b> XXXXXX
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </Row>
                        <Row className={`mx-0`}>Descripción de hallazgos</Row>
                      </div>
                    )}
                  </div>
                  <div className='mt-3'>
                    {reportPayload?.medicalConcept?.enabled &&
                      reportPayload?.medicalConcept?.showTitle && (
                        <Row className={`${styles.tlnTextPrimary} mx-0`}> Concepto médico </Row>
                      )}
                    {reportPayload?.medicalConcept?.enabled && (
                      <Row className={`d-flex flex-column mx-0`}>
                        <div className='d-flex flex-column justify-content-between'>
                          <div className='d-flex justify-content-between mt-3'>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-start`}>
                              {' '}
                              Concepto
                            </Col>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-end`}>
                              {' '}
                              Órgano
                            </Col>
                          </div>
                          <hr className='mb-2 mt-0' />

                          <div className='d-flex justify-content-between'>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-start`}>
                              {' '}
                              Concepto 1
                            </Col>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-end`}>
                              {' '}
                              OI
                            </Col>
                          </div>
                          <hr className='mb-2 mt-0' />

                          <div className='d-flex justify-content-between'>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-start`}>
                              {' '}
                              Concepto 2
                            </Col>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-end`}>
                              {' '}
                              OD
                            </Col>
                          </div>
                          <hr className='mb-2 mt-0' />

                          <div className='d-flex justify-content-between'>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-start`}>
                              {' '}
                              Concepto 3
                            </Col>
                            <Col xs={6} className={`${styles.tlnTextPrimary} text-end`}>
                              {' '}
                              AO
                            </Col>
                          </div>
                        </div>
                      </Row>
                    )}
                  </div>
                  <div className='mt-3'>
                    {reportPayload?.conclusion?.enabled && reportPayload?.conclusion?.showTitle && (
                      <Row className={`${styles.tlnTextPrimary} mx-0`}> Conclusión </Row>
                    )}
                    {reportPayload?.conclusion?.enabled && <Row className={`mx-0`}> Texto </Row>}
                  </div>
                </>
              )}
            </div>
            <div className='d-flex flex-column pb-2'>
              <div className='mt-3 d-flex flex-column'>
                <span className='fw-bold'> Nombre médico</span>
                <span className=''> Especialidad del médico</span>
                <span className=''> RM #00000</span>
              </div>
              <div className='d-flex justify-content-end mt-2'>
                <span className='fw-bold'> Teléfono: &nbsp;</span>
                {sessionAccount.phone}
                <span className='fw-bold ms-2'> | Dirección &nbsp;</span>
                {sessionAccount.address}
                <span className='fw-bold ms-2'> | Sitio web: &nbsp;</span>www.centrolaser.net
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <OrdModal
        title='Visualizador de informe'
        titleClassName='fs-5'
        show={modal}
        btnNoName='Cerrar'
        size='700'
        onHide={() => setModal(!modal)}
        btnNoEvent={() => setModal(!modal)}
      >
        {renderReport()}
      </OrdModal>
    );
  };

  const onSubmit = () => {
    updateService({
      url: '/medical/clinicHistory/templatesConfigurationDx/',
      token: store.loginReducer.Authorization,
      method: 'PUT',
      noAlert: true,
      body: {
        ...reportPayload,
        reportRequired,
        requiresImage,
        images,
        userId,
      },
      doAfterSuccess: res => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Plantilla de informe guardada con éxito',
          text: res.message,
          showCancelButton: false,
        });
        history.push('/asistencial/tmHistoriaClinica/ayudasDiagnosticas');
      },
      doAfterException: res => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      },
    });
  };

  const render = () => {
    const handleChangeForm = (key, name, value) => {
      setReportPayload({ ...reportPayload, [key]: { ...reportPayload[key], [name]: value } });
    };
    return (
      <>
        {(dxLoader || updateServiceLoader || variablesLoader) && loader}
        {renderModal()}
        <Row className={`w-100  f12`}>
          <Col xs={6} className=' h-100 overflow-auto'>
            <div className={`pl-4 pt-4`}>
              <h1
                style={{ fontSize: '40px' }}
                className={`my-0 mb-2 ${styles.ordDarkBlueText} ${styles.fontFamiTitle}`}
              >
                Plantilla de informe
              </h1>
              <span>
                <span className='fw-bold mb-4'> Servicio: &nbsp;</span> {reportPayload?.srvName}
              </span>
              <Form.Group className='mb-4 text-start' controlId='2'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                  <span className='text-center'>Título</span>
                </Form.Label>
                <Form.Control
                  className={`ord-roundInput`}
                  type='text'
                  placeholder='Escribe...'
                  defaultValue={
                    getDXservicesResults?.results?.title && getDXservicesResults?.results?.title
                  }
                  value={reportPayload.title}
                  name='title'
                  style={{ fontSize: 12 }}
                  onChange={e => setReportPayload({ ...reportPayload, title: e.target.value })}
                />
              </Form.Group>
              <Form.Group className='mb-4 text-start' controlId='2'>
                <div className='d-flex justify-content-between'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                    <span className='px-1'>Introducción</span>
                  </Form.Label>
                  <div className='d-flex'>
                    <div>
                      <input
                        defaultChecked={
                          getDXservicesResults?.introduction?.enabled &&
                          getDXservicesResults?.introduction?.enabled
                        }
                        type='checkbox'
                        className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                        checked={reportPayload?.introduction?.enabled}
                        onChange={({ target }) => {
                          handleChangeForm('introduction', 'enabled', target.checked);
                        }}
                      />
                      <span className='px-2'>Visualizar</span>
                    </div>
                    <div>
                      <input
                        type='checkbox'
                        defaultChecked={
                          getDXservicesResults?.introduction?.showTitle &&
                          getDXservicesResults?.introduction?.showTitle
                        }
                        className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                        checked={reportPayload?.introduction?.showTitle}
                        onChange={({ target }) => {
                          handleChangeForm('introduction', 'showTitle', target.checked);
                        }}
                      />
                      <span className='px-2'>Mostrar título</span>
                    </div>
                  </div>
                </div>
                <hr className='mt-2' />
                <LetterEditor
                  onGetResult={result => {
                    setReportPayload({
                      ...reportPayload,
                      introduction: {
                        ...reportPayload.introduction,
                        text: result.template,
                        textLabel: result.template,
                      },
                    });
                  }}
                  name='template'
                  variables={templateVariables}
                  labels={variableLabels}
                  variableClasses={VARIABLE_STYLES}
                  defaultValue={getDXservicesResults?.results?.introduction?.text ?? ''}
                />
              </Form.Group>
              <Form.Group className='mb-4 text-start ' controlId='2'>
                <div className='d-flex justify-content-between align-self-end align-items-end'>
                  <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                    <span className='text-center'>Valores de referencia</span>
                  </Form.Label>
                  <div>
                    <label className={`${styles.ordBtnPrimary} ${styles.ml16} px-2`}>
                      <input
                        type='file'
                        hidden
                        onChange={e => {
                          addImage(e);
                        }}
                      />
                      <span className='mx-2 f12'>Añadir imagen</span>
                      <img src={adding} alt='add' className={` ${styles.filterWhite} `} />
                    </label>
                  </div>
                </div>

                <hr className='mt-2' />

                <div className='d-flex flex-wrap gap-2'>
                  {images.length > 0 &&
                    images.map(image => {
                      return (
                        <div
                          className={`${styles.variablesStyles} my-1 text-center px-2 `}
                          style={{ width: 'fit-content' }}
                          key={image.id ?? image.identifier}
                        >
                          <span className='px-2'>{image.filename}</span>
                          <button
                            className='px-2'
                            onClick={() => {
                              deleteImageFunction(image);
                            }}
                          >
                            <img alt='delete' src={deleteImage} height={15} />
                          </button>
                        </div>
                      );
                    })}
                </div>
              </Form.Group>

              {showSectionForm && (
                <>
                  <Form.Group className='mb-4 text-start' controlId='2'>
                    <div className='d-flex justify-content-between'>
                      <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                        <span className='px-1'>Hallazgos</span>
                      </Form.Label>
                      <div className='d-flex'>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.findings?.enabled}
                            onChange={({ target }) => {
                              handleChangeForm('findings', 'enabled', target.checked);
                            }}
                          />
                          <span className='px-2'>Visualizar</span>
                        </div>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.findings?.showTitle}
                            onChange={({ target }) => {
                              handleChangeForm('findings', 'showTitle', target.checked);
                            }}
                          />
                          <span className='px-2'>Mostrar título</span>
                        </div>
                      </div>
                    </div>
                    <hr className='mt-2' />
                  </Form.Group>
                  <Form.Group className='mb-4 text-start' controlId='2'>
                    <div className='d-flex justify-content-between'>
                      <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                        <span className='px-1'>Concepto médico</span>
                      </Form.Label>
                      <div className='d-flex'>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.medicalConcept?.enabled}
                            onChange={({ target }) => {
                              handleChangeForm('medicalConcept', 'enabled', target.checked);
                            }}
                          />
                          <span className='px-2'>Visualizar</span>
                        </div>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.medicalConcept?.showTitle}
                            onChange={({ target }) => {
                              handleChangeForm('medicalConcept', 'showTitle', target.checked);
                            }}
                          />
                          <span className='px-2'>Mostrar título</span>
                        </div>
                      </div>
                    </div>
                    <hr className='mt-2' />
                  </Form.Group>
                  <Form.Group className='mb-4 text-start' controlId='2'>
                    <div className='d-flex justify-content-between'>
                      <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                        <span className='px-1'>Conclusión</span>
                      </Form.Label>
                      <div className='d-flex'>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.conclusion?.enabled}
                            onChange={({ target }) => {
                              handleChangeForm('conclusion', 'enabled', target.checked);
                            }}
                          />
                          <span className='px-2'>Visualizar</span>
                        </div>
                        <div>
                          <input
                            type='checkbox'
                            className={`${styles.checkClearBlue} ${styles.appCheckInput}  ${styles.appCheckClear}  form-check-input p1 `}
                            checked={reportPayload?.conclusion?.showTitle}
                            onChange={({ target }) => {
                              handleChangeForm('conclusion', 'showTitle', target.checked);
                            }}
                          />
                          <span className='px-2'>Mostrar título</span>
                        </div>
                      </div>
                    </div>
                    <hr className='mt-2' />
                  </Form.Group>
                </>
              )}
            </div>
          </Col>

          <Col xs={5} className=' h-80 overflow-auto' style={{}}>
            <div className='py-4'>
              <div className='align-self-end text-end'>
                <button
                  className={`${styles.ordBtnPrimary} ${styles.ml16} px-2 f12`}
                  onClick={() => setModal(!modal)}
                >
                  <span className='f12'>Previsualizar ajuste</span>

                  <img alt='icon' src={addFile} className={`${styles.svgWhiteFilter} pl-2`} />
                </button>
              </div>
              <div className='my-2'>{renderReport()}</div>
              <div
                className='d-flex justify-content-end align-self-end text-end 
                '
              >
                <button
                  className={`mr-2 btn ${styles.tlnBtnPrimaryligth}`}
                  style={{ fontSize: 12, height: 32, paddingTop: 5 }}
                  onClick={() => history.push('/asistencial/tmHistoriaClinica/ayudasDiagnosticas')}
                >
                  <span>Cancelar</span>
                </button>
                <button
                  className={`${styles.tlnBtnPrimary} btn`}
                  onClick={() => onSubmit()}
                  style={{ fontSize: 12, height: 32, paddingTop: 5 }}
                >
                  <span>Guardar</span>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return render();
};

export default ServiceReport;
