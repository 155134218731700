import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import Excel from '../../assets/img/icons/excel.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

import { usePostMethod } from '../../Hooks/useFetch';
import { getBalanceSum, getBalanceTotal, getClient } from '../../actions/receiptOfInvoicesActions';
import { convertMoneyFormat, customSwaltAlert, genericDownloadExcel, loader } from '../../helpers';

import GenericTable from '../../components/Layouts/GenericTableNew';
import CompanyBalance from './CompanyBalance';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import '../management/Management.scss';

function ReportPurse() {
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    client: '',
    consolidated: true,
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const { trigger: postDocExcel } = usePostMethod();

  const [details, setDetails] = useState({
    show: false,
    client_id: '',
  });

  const [filters1, setFilters1] = useState({
    page: 1,
    perpage: 10,
    client: '',
    consolidated: false,
    orderType: '',
    reportType: true,
    eaccount: storage.loginReducer.currentAccount.id,
  });

  useEffect(() => {
    dispatch(
      getBalanceSum({
        page: 1,
        perpage: 10,
        client: '',
        consolidated: false,
        orderType: '',
        reportType: true,
        eaccount: storage.loginReducer.currentAccount.id,
      }),
    );
  }, [storage.loginReducer.currentAccount.id, dispatch]);

  useEffect(() => {
    dispatch(getBalanceTotal(filters));
  }, [dispatch, storage.loginReducer.currentAccount.id, filters]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  const downloadExcelDoc = consultType => {
    postDocExcel({
      url: '/receivable/balance/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        consultType: consultType,
        consolidated: 'false',
        reportType: 'true',
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    const { id_client } = e.target;
    setFilters({
      ...filters,
      page: 1,
      client: id_client.value,
    });
    dispatch(
      getBalanceSum({
        ...filters1,
        page: 1,
      }),
    );
  };

  let optionsOrders = [
    { key: 'default', value: '', label: 'Seleccionar...', id: '' },
    { key: 'default1', value: 'DESC', label: 'Mayor a menor', id: '1' },
    { key: 'default2', value: 'ASC', label: 'Menor a mayor', id: '2' },
  ];

  const header = [
    <th key={1} className='text-start px-2'>
      NIT
    </th>,
    <th key={2} className='text-start px-2'>
      Empresa
    </th>,
    <th key={3} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      Saldo
    </th>,
    <th key={4} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      30 días
    </th>,
    <th key={5} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      60 días
    </th>,
    <th key={6} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      90 días
    </th>,
    <th key={7} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      180 días
    </th>,
    <th key={8} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      360 días
    </th>,
    <th key={9} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      Mayor a 360 días
    </th>,
    <th key={10} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      Corriente
    </th>,
    <th key={11} style={{ whiteSpace: 'nowrap' }} className='text-end px-2'>
      Valor sin legalizar
    </th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.balance_sum)) {
      table = storage.invoiceReducer.balance_sum.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-start px-2'>{x.nit}</td>
            <td className='text-start px-2'>{x.client}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.pending)}</td>
            <td className='text-end px-2'>
              {convertMoneyFormat(x?.period_1_30 ? x?.period_1_30 : 0)}
            </td>
            <td className='text-end px-2'>
              {convertMoneyFormat(x?.period_31_60 ? x?.period_31_60 : 0)}
            </td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.period_61_90)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.period_91_180)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.period_181_360)}</td>
            <td className='text-end px-2'>
              {convertMoneyFormat(x?.period_M360 ? x?.period_M360 : 0)}
            </td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.not_due)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x?.amountNotLegalized ?? '0')}</td>
          </tr>
        );
      });
    }
    return table;
  };

  const render = () => {
    return (
      <div className='container-fluid px-5'>
        {storage.invoiceReducer.balance_sum_loading && loader}

        <h1 className='mb-4'>Informe de cartera</h1>

        <div className='row card-container-principal'>
          <div className='col-5'>
            <div className='row h-100 card-container'>
              <div className='col-4 card-principal border-muted my-3'>
                <label>Saldo</label>
                <p>
                  {storage.invoiceReducer.balance_total?.pending
                    ? convertMoneyFormat(storage.invoiceReducer.balance_total?.pending)
                    : '$0'}
                </p>
              </div>

              <div className='col-4 card-principal border-muted my-3'>
                <label>Corriente</label>
                <p>
                  {storage.invoiceReducer.balance_total?.not_due
                    ? convertMoneyFormat(storage.invoiceReducer.balance_total?.not_due)
                    : '$0'}
                </p>
              </div>

              <div className='col-4 card-principal'>
                <label>Valor sin legalizar</label>
                <p>
                  {storage.invoiceReducer.balance_total?.not_due
                    ? convertMoneyFormat(storage.invoiceReducer.balance_total?.amountNotLegalized)
                    : '$0'}
                </p>
              </div>
            </div>
          </div>

          <div className='col-7'>
            <div className='row w-100'>
              <div className='col-4 my-3 border-end card-segundario'>
                <article className='d-flex flex-column align-items-center pb-2 w-100 border-bottom text-center'>
                  <p>30 días</p>
                  <p>
                    {storage?.invoiceReducer.balance_total?.period_1_30
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_1_30)
                      : '$0'}
                  </p>
                </article>
                <article className='d-flex flex-column align-items-center pt-2 w-100 text-center'>
                  <p>180 días</p>
                  <p>
                    {storage?.invoiceReducer?.balance_total?.period_91_180
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_91_180)
                      : '$0'}
                  </p>
                </article>
              </div>

              <div className='col-4 my-3 border-end card-segundario'>
                <article className='d-flex flex-column align-items-center pb-2 w-100 border-bottom text-center'>
                  <p>60 días</p>
                  <p>
                    {storage.invoiceReducer.balance_total?.period_31_60
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_31_60)
                      : '$0'}
                  </p>
                </article>

                <article className='d-flex flex-column align-items-center pt-2 w-100 text-center'>
                  <p>360 días</p>
                  <p>
                    {storage?.invoiceReducer?.balance_total?.period_181_360
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_181_360)
                      : '$0'}
                  </p>
                </article>
              </div>

              <div className='col-4 card-segundario'>
                <article className='d-flex flex-column align-items-center pb-2 w-100 border-bottom text-center'>
                  <p>90 días</p>
                  <p>
                    {storage.invoiceReducer.balance_total?.period_61_90
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_61_90)
                      : '$0'}
                  </p>
                </article>

                <article className='d-flex flex-column align-items-center pt-2 w-100 text-center'>
                  <p>Mayor a 360 días</p>
                  <p>
                    {storage.invoiceReducer?.balance_total?.period_M360
                      ? convertMoneyFormat(storage?.invoiceReducer?.balance_total?.period_M360)
                      : '$0'}
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={e => handleSearch(e)} className='row mt-3'>
          <div className='col-lg-4'>
            <label className={tableStyles.stylesLabel}>Empresa</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsClient}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters1({
                  ...filters1,
                  client: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_client'
            />
          </div>

          <div className='col-lg-3'>
            <label className={tableStyles.stylesLabel}>Ordenar saldo</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsOrders}
              placeholder='Seleccionar...'
              onChange={e =>
                setFilters1({
                  ...filters1,
                  orderType: e.value,
                })
              }
              styles={customSelectNewDark}
              name='id_order'
            />
          </div>

          <div className='col-lg-1 d-flex align-items-end'>
            <button type='submit'>
              <img
                src={Lupa}
                alt=''
                onClick={() => {
                  setFilters1({ ...filters1, page: 1 });
                }}
              />
            </button>
          </div>

          <div className='col d-flex align-items-end justify-content-end'>
            <CustomPopupExtend
              noHover
              position='bottom'
              triggerSrc={Excel}
              extraButtons={[
                {
                  text: 'Informe general',
                  class: CustomPopupStyles.popUpGeneric,
                  event: () => {
                    downloadExcelDoc('general_collection_report');
                  },
                },
                {
                  text: 'Informe detallado',
                  class: CustomPopupStyles.popUpGeneric,
                  event: () => {
                    downloadExcelDoc('detailed_collection_report');
                  },
                },
              ]}
            />
            {/* <button>
              <img src={Excel} alt="" srcset="" onClick={() => downloadExcelDoc()} />
            </button> */}
          </div>
        </form>

        <div className='table-responsive'>
          <GenericTable headers={header} dark={true}>
            {renderList()}
          </GenericTable>
        </div>

        <div className='mt-3'>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.invoiceReducer.balance_sum ? filters1.page : ''}
              {' de '}
              {Math.ceil(storage.invoiceReducer?.balance_sum_total / filters1.perpage)
                ? Math.ceil(storage.invoiceReducer.balance_sum_total / filters1.perpage)
                : ''}{' '}
              ({storage.invoiceReducer.balance_sum_total} encontrados)
            </p>
            <Pagination
              activePage={filters1.page}
              itemsCountPerPage={10}
              totalItemsCount={storage.invoiceReducer.balance_sum_total}
              pageRangeDisplayed={5}
              onChange={e => {
                setFilters1({ ...filters1, page: e });
                dispatch(getBalanceSum({ ...filters1, page: e }));
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    );
  };

  return details.show ? (
    <CompanyBalance client_id={details.client_id} show={details.show} setDetails={setDetails} />
  ) : (
    render()
  );
}
export default ReportPurse;
