import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { API_VERSION, URL_GATEWAY } from '../../../helpers/constants';
import { convertFilterToString } from '../../../helpers/convertToFilter';

import Back from '../../../assets/img/icons/atras-icon.svg';
import Lupa from '../../../assets/img/icons/lupa.svg';

import { SELECT_CALENDAR_MEDICAL_EXAMS } from '../../../actions/actionTypes';
import { convertDateToLatinFormat } from '../../../helpers/convertDateFormat';

import GenericTableNew from '../../Layouts/GenericTableNew';
import SelectComponent from '../../SelectComponent/SelectComponent';
import MedicalExamDetail from './MedicalExamDetail';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../Layouts/react-select-custom';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import SalidaConStyles from '../../SalidaConsumo/SalidaConsumo.module.scss';
import payrollStyles from '../payrollStyles.module.scss';

function MedicalExamHistory(props) {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = store?.loginReducer?.Authorization;

  const [isLoading, setIsLoading] = useState(false);
  const [screenControl, setScreenControl] = useState(0);

  const [trigger, setTrigger] = useState(0);

  const [filters, setFilters] = useState({
    area_id: undefined,
    page: 1,
    perpage: 10,
    status: 'done',
    eaccount: store?.loginReducer?.currentAccount?.id
  });

  const [medTable, setMedTable] = useState([]);
  const [medTableTotal, setMedTableTotal] = useState(0);

  const fetchData = async () => {
    setIsLoading(true);
    await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/medical_exam/?${convertFilterToString(filters)}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    )
      .then(response => response.json())
      .then(res => {
        setMedTable(res.results);
        setMedTableTotal(res.total);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const optionArea = () => {
    let temp = [{ value: '', label: 'Seleccionar...' }];
    if (Array.isArray(props.medExam)) {
      props.medExam.forEach(elem => {
        temp.push({
          value: elem.CC_ID,
          label: elem.CC_ID + ': ' + elem.CC_DESC,
        });
      });
    }
    return temp;
  };

  const meHeaders = [
    <th key={1} className='text-start px-2'>
      Área
    </th>,
    <th key={2} className='text-center'>
      Fecha Programada
    </th>,
    <th key={3} className='text-center'>
      No. Examinados
      <br />
      <span style={{ fontSize: 10 }}>(Realizados/Total)</span>
    </th>,
    <th key={4}>&nbsp;</th>,
  ];

  const meBody = () => {
    if (!Array.isArray(medTable)) {
      return [];
    }
    let filteredArray = medTable;

    return filteredArray.map(item => {
      return (
        <tr key={item.id} className='hover-table-row'>
          <td className={`col-md-3`}>
            <p className={`m-0 text-start`} style={{ paddingLeft: 10 }}>
              {item.area_name}
            </p>
          </td>
          <td className={`col-md-3`}>
            <div className={`d-flex justify-content-center`}>
              {item.prog_date ? convertDateToLatinFormat(item.prog_date) : '00/00/0000'}
            </div>
          </td>
          <td className={`col-md-2`}>
            {Array.isArray(item.details)
              ? `
                            ${item.details?.reduce((acum, loop) => {
                              return acum + (loop.status === 'done' ? 1 : 0);
                            }, 0)}
                            /
                            ${item.details?.length}
                            `
              : '-'}
          </td>
          <td className={`col-md-3`}>
            <div className={`d-flex justify-content-center`}>
              <p
                className={`m-0 ${payrollStyles.statusPending}`}
                style={{ textTransform: 'capitalize', cursor: 'pointer' }}
                onClick={() => {
                  dispatch({
                    type: SELECT_CALENDAR_MEDICAL_EXAMS,
                    //...x,
                    id: item.id,
                    area: item.area_name,
                  });
                  history.push('/nomina/examenesMedicosDetalles/');
                }}
              >
                Ver Resultados
              </p>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderHistory = (
    <div className={tableStyles.container} style={{ padding: 0 }}>
      <div
        className={`d-flex ${SalidaConStyles.titleBlock} align-items-center gap-2`}
        style={{ marginLeft: '22px' }}
      >
        <img
          src={Back}
          alt='backIcon'
          className='cursorPointer'
          height='30px'
          onClick={() => props.setScreenControl(0)}
        />
        <span className={`fw-bold text-primary ${tableStyles.f40} ${tableStyles.darkBlueText} `}>
          Historial de Exámenes Médicos
        </span>
      </div>

      <div
        className={tableStyles.container}
        style={{ paddingBottom: 40, paddingLeft: 50, paddingRight: 50, flex: 1 }}
      >
        {isLoading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={`d-flex`} style={{ marginTop: 16 }}>
          <div className='d-flex flex-column  mr-2' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>Área</p>
            <SelectComponent
              value={optionArea().find(x => x.value === filters.area_id)}
              onChange={e => {
                setFilters({ ...filters, area_id: e.value });
                setTrigger(trigger + 1);
              }}
              options={optionArea()}
              placeholder={'Seleccionar...'}
              styles={customSelectNewDark}
              maxHeight={22}
            />
          </div>

          <div className='d-flex flex-column mr-2' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>Fecha desde</p>
            <input
              type='date'
              className='register-inputs'
              value={filters.from_date}
              onChange={e => {
                setFilters({ ...filters, from_date: e.target.value });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </div>

          <div className='d-flex flex-column mr-2' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>Hasta</p>
            <input
              type='date'
              className='register-inputs'
              value={filters.to_date}
              onChange={e => {
                setFilters({ ...filters, to_date: e.target.value });
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </div>

          <div className='d-flex flex-column' style={{ flex: 1 }}>
            <p className={tableStyles.crudModalLabel}>&nbsp;</p>
            <img
              className={tableStyles.cursorPointer}
              style={{ height: '24px', width: '24px' }}
              alt='icon'
              onClick={() => setTrigger(trigger + 1)}
              src={Lupa}
            />
          </div>

          <div style={{ flex: 1 }}></div>
        </div>

        <GenericTableNew headers={meHeaders} dark={true}>
          {meBody()}
        </GenericTableNew>

        {medTableTotal > filters.perpage && (
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {medTableTotal ? filters.page : ''}
              {' de '}
              {Math.ceil(medTableTotal / filters.perpage)
                ? Math.ceil(medTableTotal / filters.perpage)
                : ''}{' '}
              ({medTableTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={medTableTotal}
              pageRangeDisplayed={5}
              onChange={val => {
                setTrigger(trigger + 1);
                setFilters({ ...filters, page: val });
              }}
              itemClassPrev={`${paginationStyles.itemClassPrev} `}
              itemClassNext={`${paginationStyles.itemClassNext} `}
              itemClassFirst={`${paginationStyles.itemClassFirst} `}
              itemClassLast={`${paginationStyles.itemClassLast} `}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        )}
      </div>
    </div>
  );

  switch (screenControl) {
    case 1:
      return (
        <MedicalExamDetail screenControl={screenControl} setScreenControl={setScreenControl} />
      );

    default:
      return renderHistory;
  }
}

export default MedicalExamHistory;
