//
import { Component } from 'react';

//
import style from './providers.module.scss';

//
import Back from '../../assets/img/icons/iconBack.svg';

//
import { Link } from 'react-router-dom';

class ProvidersSelectorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='d-flex flex-column container'>
        <div className='d-flex mx-5 my-5'>
          <Link to='/administracion/proveedores' className='mt-2'>
            <img alt='regresar' src={Back} height={32} width={32} className='hoverPointer' />
          </Link>
          <h1 className='text-center align-middle m-0 mx-5'>Nueva vinculación</h1>
        </div>
        <div className={style.containerSelectorPersons}>
          <div className={style.containerItemSelector}>
            <div className={style.legalPersonBackground}></div>
            <Link
              to='/administracion/proveedores/selector/persona-juridica'
              className={style.btnBigPrimary}
            >
              Persona Jurídica
            </Link>
          </div>
          <div className={style.verticalDivisor}></div>
          <div className={style.containerItemNaturalSelector}>
            <div className={style.naturalPersonBackground}></div>
            <Link
              to='/administracion/proveedores/selector/persona-natural'
              className={style.btnBigPrimary}
            >
              Persona Natural
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default ProvidersSelectorForm;
