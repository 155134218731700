// by jhan ortegon

import { forwardRef, useEffect, useRef, useState } from 'react';

// css
import tableStyles from '../../../../../components/Layouts/tableStyle.module.scss';

// hooks
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../../../../Hooks';

// helpers
import _ from 'lodash';
import { generateId } from '../../../../../helpers';

// Components
import {
  AddCircle,
  ArrowDropDown,
  CancelOutlined,
  CreateNewFolderOutlined,
  DeleteOutline,
  NoteAddOutlined,
} from '@material-ui/icons';
import { Dropdown, Form } from 'react-bootstrap';
import AutosizeInput from 'react-input-autosize';
import ReactSelect from 'react-select';
import { ordCustomSelect } from '../../../../../components/Layouts/react-select-custom';

export default function FolderParams({
  companyParamsPayload,
  setCompanyParamsPayload,
  isCurrentTab,
}) {
  const store = useSelector(state => state);

  const [currentDocuments, setCurrentDocuments] = useState([]);

  const { trigger: getVariables, results: variables } = useGetMethod();

  useEffect(() => {
    if (companyParamsPayload.contractServices) {
      const docs = companyParamsPayload.contractServices.flatMap(cs => cs.documents);
      const format = _.uniqBy(docs, e => e?.value);
      setCurrentDocuments(format);
    }
  }, [companyParamsPayload.contractServices]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getVariables({
        url: '/receivable/ripData/getEquivalent/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getVariables, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  const onAddNewFolder = () => {
    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: [
          ...(prev.folderParams?.childrenFiles ?? []),
          {
            index: generateId(5),
            name: '',
            type: 'folder',
            documents: [{ index: generateId(5), file: null, name: '' }],
          },
        ],
      },
    }));
  };

  const onAddNewFile = () => {
    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: [
          ...prev.folderParams.childrenFiles,
          {
            index: generateId(5),
            name: '',
            type: 'file',
            documents: [{ index: generateId(5), file: null, name: '' }],
          },
        ],
      },
    }));
  };

  const onChangeFileName = (value, index) => {
    const opt = companyParamsPayload.folderParams.childrenFiles?.map(file => {
      if (file.index === index) {
        return { ...file, name: value };
      }
      return file;
    });
    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: opt,
      },
    }));
  };

  const onDeleteFile = index => {
    const opt = companyParamsPayload.folderParams.childrenFiles?.filter(
      item => item.index !== index,
    );
    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: opt,
      },
    }));
  };

  const onAddDocument = index => {
    const files = companyParamsPayload.folderParams.childrenFiles?.map(item => {
      if (item.index === index) {
        return {
          ...item,
          documents: [...item.documents, { index: generateId(5), file: null, name: '' }],
        };
      }
      return item;
    });

    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: files,
      },
    }));
  };

  const onDeleteDocument = (folderIndex, docIndex) => {
    const files = companyParamsPayload.folderParams.childrenFiles?.map(item => {
      if (item.index === folderIndex) {
        return {
          ...item,
          documents: item.documents?.filter(e => e.index !== docIndex),
        };
      }
      return item;
    });
    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: files,
      },
    }));
  };

  const onChangeDocumentName = (value, folderIndex, docIndex) => {
    const files = companyParamsPayload.folderParams.childrenFiles?.map(item => {
      if (item.index === folderIndex) {
        return {
          ...item,
          documents: item.documents?.map(doc => {
            if (doc.index === docIndex) {
              return { ...doc, name: value };
            }
            return doc;
          }),
        };
      }
      return item;
    });

    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: files,
      },
    }));
  };

  const onSelectDocument = (value, folderIndex, docIndex) => {
    const files = companyParamsPayload.folderParams.childrenFiles?.map(item => {
      if (item.index === folderIndex) {
        return {
          ...item,
          documents: item.documents?.map(doc => {
            if (doc.index === docIndex) {
              return { ...doc, file: value };
            }
            return doc;
          }),
        };
      }
      return item;
    });

    setCompanyParamsPayload(prev => ({
      ...prev,
      folderParams: {
        ...prev.folderParams,
        childrenFiles: files,
      },
    }));
  };

  const render = () => {
    return (
      <div style={{ display: isCurrentTab ? 'block' : 'none' }}>
        <div
          className='card p-4 my-4 border-0 shadow overflow-hidden'
          style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
        >
          <h5 className='text-primary fw-bold'>&#x2022; Carpeta contenedora</h5>
          <Form.Group>
            <span className='text-primary ms-1'>
              <span>Nombre</span>
              <span className='text-danger'>*</span>
            </span>
            <div className='d-flex align-items-center w-100'>
              <VariableInput
                onChange={value =>
                  setCompanyParamsPayload(prev => ({
                    ...prev,
                    folderParams: {
                      ...prev?.folderParams,
                      mainFolder: value,
                    },
                  }))
                }
                variables={variables}
              />
              <CreateNewFolderOutlined
                className={`${tableStyles.text_primary_alt} mx-2 pointer fs-2`}
                onClick={() => onAddNewFolder()}
              />
              <NoteAddOutlined
                className={`${tableStyles.text_primary_alt} me-2 pointer fs-2`}
                onClick={() => onAddNewFile()}
              />
            </div>
          </Form.Group>
        </div>
        {companyParamsPayload.folderParams.childrenFiles?.map(element => (
          <ParamElement
            key={element.index}
            element={element}
            variables={variables}
            documentTypes={currentDocuments}
            onChangeDocumentName={onChangeDocumentName}
            onAddDocument={onAddDocument}
            onDeleteDocument={onDeleteDocument}
            onSelectDocument={onSelectDocument}
            onDeleteFile={onDeleteFile}
            onChangeFileName={onChangeFileName}
          />
        ))}
      </div>
    );
  };

  return render();
}

const ParamElement = ({
  element,
  documentTypes,
  variables,
  onChangeDocumentName,
  onAddDocument,
  onDeleteDocument,
  onSelectDocument,
  onDeleteFile,
  onChangeFileName,
}) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (documentTypes) {
      setDocuments(documentTypes);
    }
  }, [documentTypes]);

  const onChangeDocument = (value, parentIndex, docIndex) => {
    onSelectDocument(value, parentIndex, docIndex);
  };

  return (
    <div key={element.index} className='card p-4 my-4 border-0 shadow' style={{ borderRadius: 10 }}>
      <div className='d-flex justify-content-between align-items-center'>
        <h5 className='text-primary fw-bold'>
          &#x2022; {element.type === 'folder' && 'Carpeta'}
          {element.type === 'file' && 'Documento'}
        </h5>
        <DeleteOutline
          className={`${tableStyles.text_primary_alt} pointer fs-2`}
          onClick={() => onDeleteFile(element.index)}
        />
      </div>
      {element.type === 'folder' && (
        <>
          <Form.Group>
            <span className='text-primary ms-1'>
              <span>Nombre</span>
              <span className='text-danger'>*</span>
            </span>
            <VariableInput
              variables={variables}
              onChange={fileName => onChangeFileName(fileName, element.index)}
            />
          </Form.Group>
          <hr />
        </>
      )}

      {element.documents?.map((doc, index) => (
        <div key={index} className='row align-items-end mb-2'>
          <div className='col-3'>
            <Form.Group className='me-3'>
              <span className='text-primary ms-1'>
                <span>Documentos</span>
                <span className='text-danger'>*</span>
              </span>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={ordCustomSelect}
                options={[{ label: 'Seleccionar...', value: '' }, ...documents]}
                placeholder='Seleccionar...'
                onChange={option => onChangeDocument(option.value, element.index, doc.index)}
              />
            </Form.Group>
          </div>
          <div className='col'>
            <Form.Group key={doc.index}>
              <span className='text-primary ms-1'>
                <span>Nombre</span>
                <span className='text-danger'>*</span>
              </span>
              <VariableInput
                onChange={value => onChangeDocumentName(value, element.index, doc.index)}
                variables={variables}
              />
            </Form.Group>
          </div>
          {element.type === 'folder' && (
            <div className='col-1 d-flex justify-content-start'>
              {doc.index === element.documents[element.documents.length - 1].index && (
                <AddCircle
                  className={`${tableStyles.text_primary_alt} me-2 pointer fs-3`}
                  onClick={() => onAddDocument(element.index)}
                />
              )}
              {element.documents?.length > 1 && (
                <CancelOutlined
                  className={`${tableStyles.text_primary_alt} pointer fs-3`}
                  onClick={() => {
                    onDeleteDocument(element.index, doc.index);
                    setDocuments(prev => [
                      ...prev,
                      documentTypes.find(item => item.value === doc.file),
                    ]);
                  }}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const VariableDropdown = forwardRef(function VariableDropdown({ children, onClick }, ref) {
  return (
    <div
      ref={ref}
      className={`${tableStyles.variableSelect}`}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  );
});

const VariableInput = props => {
  const inputTextRef = useRef(null);

  const [inputs, setInputs] = useState([]);
  const [nameValue, setNameValue] = useState('');

  useEffect(() => {
    if (inputs.length || nameValue.length) {
      const values = inputs?.map(item => (item.value ? item.value : item.label));
      props?.onChange(values.join('') + nameValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs, inputs.length, nameValue.length]);

  const onChangeNameInput = value => {
    setNameValue(value);
  };

  const onKeyDownNameInput = event => {
    if (event.key === ' ') {
      event.preventDefault();
    }
    if (event.key === '{' || event.key === '}') {
      event.preventDefault();
    }
    if (event.key === 'Backspace' && (!nameValue || event.target.selectionStart === 0)) {
      let options = inputs;
      let newOpt = options.slice(0, -1);

      if (newOpt.length && newOpt[newOpt.length - 1].value === '') {
        setNameValue(prev => newOpt[newOpt.length - 1].label + prev);
        newOpt = newOpt.filter(el => el.index !== newOpt[newOpt.length - 1].index);
      }
      setInputs(newOpt);
    }
  };

  const onAddVariable = item => {
    if (nameValue) {
      setInputs(prev => [
        ...prev,
        { index: generateId(5), label: nameValue, value: '' },
        { index: generateId(5), label: item.label, value: `{${item.var_name}}` },
      ]);
      setNameValue('');
    } else {
      setInputs(prev => [
        ...prev,
        { index: generateId(5), label: item.label, value: `{${item.var_name}}` },
      ]);
    }
  };

  const onDeleteVariable = (event, index) => {
    const currentVaraible = event.target;
    const isSameVariable = currentVaraible === document.activeElement;

    if (event.key === 'Backspace' && isSameVariable) {
      let options = inputs;

      const leftVarIndex = options.findIndex(el => el.index === index) - 1;
      const rightVarIndex = options.findIndex(el => el.index === index) + 1;

      if (options[leftVarIndex]?.value === '' && options[rightVarIndex]?.value === '') {
        options[leftVarIndex] = {
          index: options[leftVarIndex].index,
          value: '',
          label: options[leftVarIndex]?.label + options[rightVarIndex]?.label,
        };
        options = options.filter(el => el.index !== options[rightVarIndex]?.index);
      }

      let newOpt = options.filter(el => el.index !== index);

      if (newOpt.length && newOpt[newOpt.length - 1].value === '') {
        setNameValue(prev => newOpt[newOpt.length - 1].label + prev);
        newOpt = newOpt.slice(0, -1);
      }
      setInputs(newOpt);
    }
  };

  const onChangeText = (index, value) => {
    let options = inputs.map(el => {
      if (el.index === index) return { ...el, label: value };
      return el;
    });
    const leftVarIndex = options.findIndex(el => el.index === index) - 1;

    if (value === '' && options[leftVarIndex]?.value !== '') {
      options = options.filter(el => el.index !== options[leftVarIndex]?.index);
    }
    setInputs(options);
  };

  const onKeyDownText = (event, index) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
    if (event.key === '{' || event.key === '}') {
      event.preventDefault();
    }

    if (event.target.selectionStart === 0 && event.key === 'Backspace') {
      let options = inputs;
      const currentIndex = options.findIndex(el => el.index === index);
      const leftVarIndex = currentIndex - 1;

      if (options[leftVarIndex]?.value !== '') {
        options = options.filter(el => el.index !== options[leftVarIndex]?.index);
        options[leftVarIndex - 1] = {
          index: options[leftVarIndex - 1].index,
          value: '',
          label: options[leftVarIndex - 1].label + options[leftVarIndex].label,
        };
        options = options.filter(el => el.index !== options[leftVarIndex].index);
      }
      setInputs(options);
    }
  };

  return (
    <div className='d-flex align-items-center overflow-hidden w-100'>
      <div className={`${tableStyles.variableInput}`}>
        {inputs?.map((item, index) => {
          if (item.value !== '') {
            return (
              <label
                key={'variable-' + index}
                htmlFor={'variable-' + index}
                className={tableStyles.inputVariable}
                tabIndex={index}
                onKeyDown={event => onDeleteVariable(event, item.index)}
              >
                {item.label}
                <input id={'variable-' + index} type='hidden' value={`{${item.value}}`} readOnly />
              </label>
            );
          } else {
            return (
              <AutosizeInput
                key={'input-' + index}
                tabIndex={index}
                value={item.label}
                onChange={event => onChangeText(item.index, event.target.value)}
                onKeyDown={event => onKeyDownText(event, item.index)}
                inputClassName={tableStyles.plainInput}
              />
            );
          }
        })}
        <input
          ref={inputTextRef}
          type='text'
          className={tableStyles.plainInput}
          value={nameValue}
          style={{ width: `calc(${(nameValue.length + 1) * 12}px + 4% - 12px)`, margin: 0 }}
          onChange={({ target }) => onChangeNameInput(target.value)}
          onKeyDown={onKeyDownNameInput}
        />
        <div className={tableStyles.ghostInput} onClick={() => inputTextRef.current?.focus()}></div>
      </div>

      <Dropdown style={{ minWidth: '20%' }}>
        <Dropdown.Toggle as={VariableDropdown}>
          <span style={{ whiteSpace: 'nowrap' }}>Insertar variable...</span>
          <ArrowDropDown />
        </Dropdown.Toggle>
        <Dropdown.Menu className='overflow-hidden' popperConfig={{ strategy: 'fixed' }}>
          {props.variables?.results?.map(item => (
            <Dropdown.Item
              key={item.id}
              className={`${tableStyles.variableOption} ${tableStyles.elipsis}`}
              onClick={() => onAddVariable(item)}
            >
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
