import { Drawer } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
//
import { usePostMethod } from '../../../Hooks';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlert, loader } from '../../../helpers';

export default function FilingAction({ isOpen, selectedItems = [], onClose }) {
  const storage = useSelector(state => state);

  const firstItem = useMemo(() => selectedItems?.[0], [selectedItems]);

  const { trigger: filingAction, load: filingActionLoader } = usePostMethod();
  const { trigger: downloadFiling, load: downloadFilingLoader } = usePostMethod();

  const [filingPayload, setFilingPayload] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    type: '',
    date: moment().format('YYYY-MM-DD'),
    invoices: undefined,
    minutes: undefined,
  });

  useEffect(() => {
    (async function () {
      if (firstItem) {
        setFilingPayload(prev => ({
          ...prev,
          invoices: !firstItem?.filing_status ? selectedItems.map(item => item.id) : undefined,
          minutes: firstItem?.filing_status ? selectedItems.map(item => item.id) : undefined,
          type: firstItem.type_filing,
        }));
      }
    })();
  }, [
    firstItem,
    storage.loginReducer.Authorization,
    storage.loginReducer.currentAccount.id,
    selectedItems,
  ]);

  const onCloseDrawer = () => {
    setFilingPayload(prev => ({
      ...prev,
      type: '',
      date: moment().format('YYYY-MM-DD'),
    }));
    onClose();
  };

  const onDownloadFile = () => {
    const anchorElement = document.createElement('a');
    anchorElement.style.display = 'none';

    downloadFiling({
      token: storage.loginReducer.Authorization,
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        invoices: filingPayload.invoices,
        minutes: filingPayload.minutes,
      },
      method: 'POST',
      url: '/receivable/receiptRecord/download/',
      succesAction: response => {
        anchorElement.href = 'data:application/zip;base64,' + response.results;
        anchorElement.download = 'factura.zip';
        anchorElement.click();
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: response.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onFilingAction = () => {
    if (!filingPayload.date) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, ingrese una fecha de radicación válida.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }

    filingAction({
      token: storage.loginReducer.Authorization,
      body: filingPayload,
      method: 'POST',
      url: '/receivable/receiptRecord/file/',
      succesAction: () => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Información guardada',
          text: 'Se descargará la radicación de la(s) factura(s).',
          confirmButtonText: 'Aceptar',
        }).then(response => {
          if (response.isConfirmed) {
            onDownloadFile();
            onCloseDrawer();
            // onSuccess();
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const render = () => {
    return (
      <>
        {filingActionLoader && loader}
        {downloadFilingLoader && loader}
        <Drawer
          open={isOpen}
          anchor='right'
          onClose={() => onCloseDrawer()}
          PaperProps={{ style: { width: 360 } }}
        >
          <div className='d-flex flex-column p-4 h-100'>
            <div
              className={`${styles.tlndrawer__close} align-self-end`}
              onClick={() => onCloseDrawer()}
            ></div>
            <h4 className='text-primary m-0 my-4 fw-bold'>Radicación de factura</h4>

            <div className='d-flex flex-column flex-grow-1'>
              <div className='row gy-3'>
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>Fecha de radicación</span>
                  </small>
                  <input
                    type='date'
                    className='register-inputs'
                    value={filingPayload.date}
                    onChange={({ target }) =>
                      setFilingPayload(prev => ({ ...prev, date: target.value }))
                    }
                    max={moment().format('YYYY-MM-DD')}
                  />
                </div>
                <div className='col-12'>
                  <small className='text-primary ms-1'>
                    <span>Tipo de radicación</span>
                  </small>
                  <input
                    type='text'
                    className='register-inputs'
                    defaultValue={filingPayload.type === 'on_site' ? 'Física' : 'Virtual'}
                    disabled
                  />
                </div>
              </div>
              <div className='flex-grow-1'></div>
              <button
                onClick={() => onFilingAction()}
                className={`align-self-center ${styles.btnPrimaryAlt} mt-4 py-2`}
              >
                Guardar
              </button>
            </div>
          </div>
        </Drawer>
      </>
    );
  };

  return render();
}
