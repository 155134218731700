import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import { useGetMethod } from '../Hooks/useFetch';
import { formatToRcSelect, loader } from '../helpers/helpers';
import { ordCustomSelect } from '../components/Layouts/react-select-custom';
import config from '../assets/img/icons/iconGearGray.svg';
import OrdTable from '../OrderingModule/OrderingComponents/OrdTable';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SettingComments = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idAccount = store.loginReducer.currentAccount.id;
  let history = useHistory();
  const [filters, setFilters] = useState({
    srvId: '',
    srvTypeId: '',
    page: 1,
    perpage: 10,
    eaccount: idAccount,
  });
  const [trigger, setTrigger] = useState(0);
  const {
    results: servicesOptionsResults,
    load: servicesOptionsLoader,
    trigger: getServicesOptions,
  } = useGetMethod();
  const {
    results: typeOptionsResults,
    load: typeOptionsLoader,
    trigger: getTypeOptions,
  } = useGetMethod();
  const {
    results: observationsResults,
    load: observationLoader,
    trigger: getObservationsList,
  } = useGetMethod();

  const servicesOptions = formatToRcSelect(
    servicesOptionsResults?.results,
    'id',
    'name',
    '',
    '',
    '',
  );
  const typeOptions = formatToRcSelect(
    typeOptionsResults?.results,
    'serviceTypeId',
    'serviceType',
    '',
    '',
    '',
  );

  useEffect(() => {
    getServicesOptions({
      url: '/medical/generals/services/',
      token: token,
    });
    getTypeOptions({
      url: '/medical/generals/serviceType/',
      objFilters: { listAll: 1 },
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getObservationsList({
      url: '/medical/offeredService/observations/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleObservationList = async () => {
    const result = await getObservationsList({
      url: '/medical/offeredService/observations/',
      objFilters: { ...filters, page: filters.page },
      token: token,
    });

    formatData(result?.results);
  };

  useEffect(() => {
    if (filters.eaccount) {
      handleObservationList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //----------  SECTION TABLE -----------------

  const headers = [
    {
      title: 'Nombre del servicio',
      className: 'px-2 text-start col-6',
    },
    {
      title: 'Tipo',
      className: 'px-2 text-start col-2',
    },
    {
      title: 'Observaciones',
      className: 'px-2 text-start col-2',
    },
    {
      title: 'Contratos',
      className: 'px-2 text-start col-2',
    },
    {},
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-2'>{item?.srvName}</td>
            <td className='text-start px-2  '>{item.srvTypeName}</td>
            <td className='text-start px-2 '>{item?.obsQty ? item?.obsQty : '0'} </td>
            <td className='text-start px-2 '>{item?.contractsQty ? item?.contractsQty : '0'}</td>
            <td className='text-start px-2 '>
              <button
                onClick={() =>
                  history.push({
                    pathname: '/asistencial/parametrizacionObservaciones',
                    state: { observationData: item },
                  })
                }
              >
                <img alt='config' src={config} height='20px' />
              </button>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //----------  !SECTION TABLE -----------------

  return (
    <>
      {(typeOptionsLoader || servicesOptionsLoader || observationLoader) && loader}
      <OrdGenericTemplate
        title={'Configuración de observaciones'}
        titleSize={12}
        className='mx-2 py-2 w-90'
        titleStyle={{ marginLeft: '0.5rem' }}
      >
        <div>
          {' '}
          <Row className='px-2'>
            <Col xs={3}>
              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                  <span> &nbsp;Servicio</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={servicesOptions}
                  className='text-secondary '
                  placeholder={'Seleccione'}
                  value={servicesOptions.find(x => x.value === filters.srvId)}
                  styles={ordCustomSelect}
                  onChange={e => {
                    setFilters({ ...filters, srvId: e.value });
                    setTrigger(trigger + 1);
                  }}
                ></Select>
              </Form.Group>
            </Col>
            <Col xs={3}>
              <Form.Group className='mb-3 text-start' controlId='fName'>
                <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                  <span> &nbsp;Tipo</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={typeOptions}
                  className='text-secondary '
                  placeholder={'Seleccione'}
                  value={typeOptions.find(x => x.value === filters.srvTypeId)}
                  styles={ordCustomSelect}
                  onChange={e => {
                    setFilters({ ...filters, srvTypeId: e.value });
                    setTrigger(trigger + 1);
                  }}
                ></Select>
              </Form.Group>
            </Col>
          </Row>
          <div className='px-2'>
            <OrdTable
              headers={headers}
              hasChildren={true}
              shadow={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              paginate={{
                activePage: filters?.page,
                totalPages: observationsResults?.rowTotal,
                perPage: filters?.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  setFilters({ ...filters, page: e });
                },
                showTextDetails: true,
              }}
            >
              {formatData(observationsResults.results)}
            </OrdTable>
          </div>
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default SettingComments;
