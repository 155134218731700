/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router";
//
import { CreateOrUpdateConsent } from "./CreateOrUpdateConsent";
import { DetailConsent } from "./DetailConsent";

export const ConsentsActions = () => {

    const history = useHistory()
    const { state } = useLocation();

    useEffect(() => {
        if (!state?.action) history.replace('/asistencial/tmAdmisiones/consentimientos')
    }, [state])

    const render = () => {

        if (['create', 'update', 'duplicate'].includes(state?.action)) return <CreateOrUpdateConsent  {...state.data} />

        if (['detail', 'save', 'newUpdate'].includes(state?.action)) return <DetailConsent {...state.data} />

        return <></>
    }

    return render()
}