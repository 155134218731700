import { useState } from 'react';

import { MultiTabComponent } from '../../components/Tabs/MultiTabComponent';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import Offices from './Offices';

const ResourceAssignment = () => {
  const [tab, setTab] = useState({
    tabName: '',
  });

  const tabs = [
    {
      id: 1,
      tabName: 'Consultorios',
      tabContent: <Offices tab={tab} setTab={setTab} />,
      onFunction: () => setTab({ ...tab, tabName: 'Consultorios' }),
    },
  ];

  return (
    <div className={` d-flex w-90`}>
      <>
        <OrdGenericTemplate
          title={'Asignación de recursos'}
          titleSize={12}
          className='mx-2 pb-2 my-2'
          titleClass='my-2 mb-4'
        >
          <MultiTabComponent tabs={tabs}></MultiTabComponent>
        </OrdGenericTemplate>
      </>
    </div>
  );
};

export default ResourceAssignment;
