import { STORAGE_GET, ARTICLE_GET, STORAGE_SUPPLIER } from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const warehouse_supplier_active = eaccount => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/supplier/${eaccount}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: STORAGE_SUPPLIER,
        payload: res,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const article_get = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/storage/articles/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: ARTICLE_GET,
        articles: res,
      });
    })
    .catch(err => {
      console.error(err);
    });
};

export const storage_get =
  (objFilters, doAfter = null) =>
  (dispatch, getState) => {
    dispatch({
      type: STORAGE_GET,
      loading: true,
    });
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/?${filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { results, row_total, total_index } = res;
        dispatch({
          type: STORAGE_GET,
          results: results,
          row_total: row_total,
          total_index: total_index,
          loading: false,
        });
      })
      .catch(() => {
        dispatch({
          type: STORAGE_GET,
          loading: false,
          results: [],
          row_total: 0,
          total_index: 0,
        });
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al traer el listado de almacenes',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      })
      .then(() => {
        if (doAfter) doAfter();
      });
  };

export const storage_edit_active =
  (codigo, data, description, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let status = !data.active ? 'habilitará' : 'deshabilitará';

    customSwaltAlert({
      icon: 'warning',
      title: `¿Está seguro?`,
      text: `Se ${status} el almacén: ${description}`,
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        dispatch({
          type: STORAGE_GET,
          loading: true,
        });
        data.active = !data.active;
        fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
          .then(response => response.json())
          .then(res => {
            if (res.success) {
              dispatch({
                type: STORAGE_GET,
                loading: false,
              });

              customSwaltAlert({
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha ${data.active ? 'habilitado' : 'deshabilitado'} el almacén: ${description}`,
              });
            } else {
              dispatch({
                type: STORAGE_GET,
                loading: false,
              });

              customSwaltAlert({
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: `${res.message}`,
              });
            }
          })
          .then(() => {
            executeThisAfter();
          })
          .catch(err => {
            console.error(err);
            dispatch({
              type: STORAGE_GET,
              loading: false,
            });

            customSwaltAlert({
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'No se pudo cambiar el estado',
              footer: 'Si el problema persiste comuníquese con un asesor.',
            });
          });
      }
    });
  };

export const storage_edit = (codigo, data, executeThisAfter) => (dispatch, getState) => {
  const id_account = getState().loginReducer.currentAccount.id;
  if (data.description.trim()) {
    if (!data.description || data.description === '') {
      return customSwaltAlert({
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar una descripción',
      });
    }
    if (!data.articles || data.articles.length <= 0) {
      return customSwaltAlert({
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar por lo menos un artículo',
      });
    }
    const token = getState().loginReducer.Authorization;
    delete data['active'];
    dispatch({
      type: STORAGE_GET,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          dispatch({
            type: STORAGE_GET,
            loading: false,
          });

          customSwaltAlert({
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: `Se ha actualizado el almacén: ${data.description}`,
          });
        } else {
          dispatch({
            type: STORAGE_GET,
            loading: false,
          });

          customSwaltAlert({
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
          });
        }
      })
      .then(() => {
        setTimeout(() => {
          executeThisAfter();
          dispatch(storage_get({ page: 1, perpage: 10, id_account: id_account }));
        }, 500);
      })
      .catch(err => {
        dispatch({
          type: STORAGE_GET,
          loading: false,
        });
        console.error(err);

        customSwaltAlert({
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'No se pudo actualizar el almacén',
          footer: 'Si el problema persiste comuníquese con un asesor.',
        });
      });
  } else {
    customSwaltAlert({
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La descripción no puede estar vacía',
    });
  }
};

export const storage_insert = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  if (data) {
    if (!data.address || data.address === '') {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo dirección es obligatorio',
        showCancelButton: false,
      });
    }
    if (!data.articles || data.articles.length <= 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe agregar por lo menos un artículo',
        showCancelButton: false,
      });
    }
    for (const key in data.articles) {
      if (Object.hasOwnProperty.call(data.articles, key)) {
        const element = data.articles[key];
        if (element.maxStock < element.minStock) {
          return customSwaltAlert({
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: 'La cantidad mínima de artículos debe ser menor a la cantidad máxima',
          });
        }
      }
    }
    // Delete all no need props to send for create a warehouse / storage
    delete data['active'];
    delete data['addArticle'];
    delete data['id'];
    delete data['maxStock'];
    delete data['minStock'];
    delete data['modalShow'];
    delete data['stocks'];
    delete data['usage'];
    data.id_account = id_account;
    dispatch({
      type: STORAGE_GET,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          dispatch({
            type: STORAGE_GET,
            loading: false,
          });

          customSwaltAlert({
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha creado el almacén: ${data.description}`,
          });
        } else {
          dispatch({
            type: STORAGE_GET,
            loading: false,
          });

          customSwaltAlert({
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
          });
        }
      })
      .then(() => {
        dispatch(storage_get({ page: 1, perpage: 10, id_account: id_account }));
        executeThisAfter();
      })
      .catch(err => {
        dispatch({
          type: STORAGE_GET,
          loading: false,
        });
        console.error(err);

        customSwaltAlert({
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'No se pudo crear el registro',
        });
      });
  }
};

export const deleteWarehouseArticle = (objArticle, objWarehouse) => () => {
  var warehouse = objWarehouse;
  if (objArticle.id_article) {
    if (Array.isArray(warehouse.articles) && warehouse.articles.length > 0) {
      warehouse.articles.forEach(element => {
        if (element.id_article === objArticle.id_article) {
          let index = warehouse.articles.indexOf(element);
          if (index > -1) {
            warehouse.articles.splice(index, 1);
          }
        }
      });
    }
    storage_edit(warehouse.id, warehouse, storage_get({ page: 1, perpage: 10 }));
  }
};
