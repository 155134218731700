import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { ColorButton } from '../../../OrderingModule/OrderingComponents/ColorButton';
import { OrdCard } from '../../../OrderingModule/OrderingComponents/OrdCard';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import ordBlueSearch from '../../../assets/img/icons/ordBlueSearch.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { isEmptyOrUndefined, loader, formatteDocument } from '../../../helpers';
import { formatNumberWithoutDecimals } from '../../../helpers/numberFormatting';
import { customSwaltAlertAsistencial } from '../../../helpers/customSwaltAlertAsistencial';

export const Providers = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const eaccount = store.loginReducer.currentAccount.id;

  const [filters, setFilters] = useState({
    eaccount,
    status: '',
    dateMin: '',
    dateMax: '',
    search: '',
  });

  const [modal, setModal] = useState({
    isOpen: false,
    title: '',
  });

  const [trigger, setTrigger] = useState(0);

  const {
    results: listProvidersOrders,
    load: loaderProvidersOrders,
    trigger: getListProvidersOrders,
  } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  const { trigger: postProviderOrder, load: loaderPostProviderOrder } = usePostMethod();

  const onPostProvidersOrders = ({
    discountId,
    approveDiscount,
    patientName,
    finalAmount,
    cancelId,
    cancelObservation,
  }) => {
    const secondMessage = `<span>para el paciente: ${patientName}</span>`;
    const messageOne = `Se ${
      approveDiscount ? 'aprobará' : 'denegará'
    } un descuento de ${formatNumberWithoutDecimals(finalAmount)}`;

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `${messageOne} <br/> ${secondMessage}`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result?.isConfirmed) {
        postProviderOrder({
          token: token,
          method: 'POST',
          url: '/medical/admissions/provider-orders/discounts/',
          body: {
            userId,
            discountId,
            approveDiscount,
            eaccount,
            cancelId,
            cancelObservation,
          },
          doAfterSuccess: res => {
            if (res?.success) {
              const firstMessage = `<span>Se ha ${
                approveDiscount ? 'aprobado' : 'denegado'
              } un descuento de ${formatNumberWithoutDecimals(finalAmount)}</span>`;

              customSwaltAlertAsistencial({
                icon: 'success',
                title: `Admisión ${approveDiscount ? 'aprobada' : 'rechazada'}`,
                text: `${firstMessage} <br/> ${secondMessage}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              }).then(() => {
                setTrigger(state => state + 1);
                setFilters({
                  eaccount,
                  dateMin: '',
                  dateMax: '',
                  search: '',
                });

                setModal({
                  isOpen: false,
                  title: '',
                });
              });
            }
          },
          doAfterException: res => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: res.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderTooltipAccept = props => <Tooltip {...props}>Autorizar</Tooltip>;
  const renderTooltipDeny = props => <Tooltip {...props}>Denegar</Tooltip>;

  const titleCard = (e, i) => {
    return (
      <Box
        className={`pb-3 ${tableStyles.ordGrayBorderBottom}`}
        key={i}
        display={'grid'}
        gridTemplateColumns={`4fr 3fr 290px 1fr ${e?.status?.value === 'pending' ? '1fr' : ''}`}
        alignItems={'center'}
        gap={2}
      >
        <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
          <div>
            <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              Solicitud: &nbsp;
            </span>
          </div>

          <div className={tableStyles.ellipsisAuto}>
            <span className={`text-secondary fw-normal`}>Autorizar descuento</span>
          </div>
        </Box>

        <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
          <div>
            <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              Solicitante: &nbsp;
            </span>
          </div>

          <div className={tableStyles.ellipsisAuto}>
            <span className={`text-secondary fw-normal`}>{e?.admisionistName || '-'}</span>
          </div>
        </Box>

        <Box className={`d-flex ${tableStyles.ellipsisAuto}`}>
          <div>
            <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              F. de solicitud: &nbsp;
            </span>
          </div>

          <div>
            <span className={`text-secondary fw-normal`}>{e?.creationDate || '-'}</span>
          </div>
        </Box>

        <Box className='w-auto'>
          <ColorButton
            style={{ width: '100%', fontSize: '15px' }}
            text={e?.status?.description}
            variant={
              e?.status?.value === 'pending'
                ? 'danger'
                : e?.status?.value === 'approved'
                  ? 'success'
                  : 'rejected'
            }
          />
        </Box>

        {e?.status?.value === 'pending' && (
          <Box className={`w-auto d-flex gap-2`} key={i}>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 250 }}
              overlay={renderTooltipDeny}
            >
              <div
                className={tableStyles.denyCardRed}
                onClick={() =>
                  setModal({
                    ...e,
                    approveDiscount: 0,
                    isOpen: true,
                    title: 'Rechazar solicitud',
                  })
                }
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipAccept}
            >
              <div
                className={tableStyles.checkCardGreen}
                onClick={() =>
                  setModal({
                    ...e,
                    approveDiscount: 1,
                    isOpen: true,
                    title: 'Autorizar solicitud',
                  })
                }
              >
                {' '}
                &nbsp; &nbsp;
              </div>
            </OverlayTrigger>
          </Box>
        )}
      </Box>
    );
  };

  const bodyCard = (item, i) => {
    return (
      <>
        <Box
          key={i}
          display={'grid'}
          gridTemplateColumns={'100px 3fr 2fr 3fr 3fr 2fr'}
          gap={1}
          mb={1}
        >
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>No. Orden</div>
            <div>{item?.orderNo || '-'}</div>
          </Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Paciente</div>
            <div>{item?.patientName || '-'}</div>
          </Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Identificación</div>
            <div>{formatteDocument(item?.document).format || '-'}</div>
          </Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Aseguradora</div>
            <div>{item?.insuranceName || '-'}</div>
          </Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Tipo de orden</div>
            <div>{item?.orderType || '-'}</div>
          </Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Descuento</div>
            <div>{item?.discount || '-'}</div>
          </Box>
        </Box>

        <Box key={i} display={'grid'} gridTemplateColumns={'5fr 100px 3fr 3fr 2fr'} gap={1}>
          <Box className={tableStyles.ellipsisAuto}>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText} ${tableStyles.ellipsisAuto}`}>
              Comentarios
            </div>
            <div>{item?.comments || '-'}</div>
          </Box>

          <Box></Box>

          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Servicio/producto</div>
            <div>{item?.orderServices || '-'}</div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Valor original</div>
            <div>{item?.initialAmount ? formatNumberWithoutDecimals(item?.initialAmount) : '-'}</div>
          </Box>
          <Box>
            <div className={`fw-bold ${tableStyles.ordAquaMarineText}`}>Nuevo valor</div>
            <div>{item?.finalAmount ? formatNumberWithoutDecimals(item?.finalAmount) : '-'}</div>
          </Box>
        </Box>
      </>
    );
  };

  const onClearStateModal = () => setModal({ isOpen: false, title: '' });

  const handlePostModal = () => {
    if (isEmptyOrUndefined(modal?.data?.cancelId)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo motivo es obligatorio',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(modal?.data?.cancelObservation)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo observación es obligatorio',
        showCancelButton: false,
      });
    }

    onPostProvidersOrders({
      patientName: modal?.patientName,
      cancelObservation: modal?.data?.cancelObservation,
      cancelId: modal?.data?.cancelId,
      discountId: modal?.discountId,
      approveDiscount: modal?.approveDiscount,
      finalAmount: modal?.finalAmount,
    });
  };

  useEffect(() => {
    getListProvidersOrders({
      url: '/medical/admissions/provider-orders/discounts/',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    getListCancellationReason({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });
  }, [getListCancellationReason, token]);

  return (
    <>
      {(loaderProvidersOrders || loaderPostProviderOrder || loaderListCancellationReason) && loader}

      <div style={{ marginRight: '100px', width: '100%' }}>
        <Box className={'mb-3 asistencialDateColorIcon'}>
          <Box display={'grid'} gridTemplateColumns={'150px 150px 150px 1fr'} gap={2}>
            <Box>
              <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
                &nbsp;Estado
              </span>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                options={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Aprobada', value: 'approved' },
                  { label: 'Pendiente', value: 'pending' },
                  { label: 'Rechazada', value: 'rejected' },
                ]}
                onChange={({ value }) => {
                  setFilters({ ...filters, status: value });
                  setTrigger(state => state + 1);
                }}
              />
            </Box>

            <Box>
              <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
                &nbsp;Fecha desde
              </span>
              <input
                value={filters?.dateMin}
                className={`ord-roundInput2`}
                type='date'
                style={filters.dateMin ? { color: '#1a538d' } : {}}
                onChange={e => {
                    setFilters({
                      ...filters,
                      dateMin: e.target.value,
                      dateMax: '',
                    });
                    setTrigger(state => state + 1);
                  }
                }
              />
            </Box>

            <Box>
              <span className={`m-0 d-block ${tableStyles.ordDarkBlueText} fw-bold`}>
                &nbsp;Hasta
              </span>
              <input
                min={filters?.dateMin || ''}
                className={`ord-roundInput2`}
                type='date'
                value={filters?.dateMax}
                style={filters.dateMax ? { color: '#1a538d' } : {}}
                onChange={e => {
                    setFilters({ ...filters, dateMax: e.target.value });
                    setTrigger(state => state + 1);
                  }
                }

              />
            </Box>

            <Box
              className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12} align-self-end`}
            >
              <input
                className={`ord-roundInput ${tableStyles.appInputDate} w-100`}
                type='text'
                placeholder='Escribe aquí para buscar'
                autoComplete='off'
                onChange={e => {
                  setFilters({ ...filters, search: e.target.value });
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              />
              <img
                src={ordBlueSearch}
                className='pointer'
                alt='buscar'
                onClick={() => setTrigger(trigger + 1)}
              ></img>
            </Box>
          </Box>

          {listProvidersOrders?.results?.map((e, i) => {
            return (
              <OrdCard
                key={i}
                className={`my-4 ${tableStyles.f14}`}
                title={titleCard(e, i)}
                body={bodyCard(e, i)}
              />
            );
          })}
        </Box>

        <OrdModal
          styleBody='p-2'
          title={modal.title}
          show={modal.isOpen}
          btnYesName={'Aceptar'}
          onHide={onClearStateModal}
          btnNoEvent={onClearStateModal}
          btnYesEvent={handlePostModal}
        >
          <Box>
            <Box>
              <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${tableStyles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={listCancellationReason?.results?.map(el => ({
                  label: el?.canDescription,
                  value: el?.canId,
                }))}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  setModal(state => ({
                    ...state,
                    data: {
                      ...state.data,
                      cancelId: value,
                    },
                  }));
                }}
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${tableStyles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${tableStyles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                placeholder='Escribir...'
                style={{ minHeight: '165px', maxHeight: '265px' }}
                className={`text-secondary ordInputAdmission w-100 p-2`}
                onChange={({ target }) => {
                  setModal(state => ({
                    ...state,
                    data: {
                      ...state.data,
                      cancelObservation: target?.value,
                    },
                  }));
                }}
              />
            </Box>
          </Box>
        </OrdModal>
      </div>
    </>
  );
};
