import { useEffect, useState } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import lupaTransparent from '../../assets/img/icons/lupaTransparent.svg';
import GenericTableNew from '../Layouts/GenericTableNew';

import { aprove_purchase_order_filter } from '../../actions/IndividualAuctionActions';

import { Tooltip } from '@material-ui/core';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import '../Layouts/customTabs.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import AuthorizationsStyles from './Authorizations.module.scss';
import DetailPurchaseOrder from './DetailPurchaseOrder';

function ApprovePurchaseOrder(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'APO',
  );
  const [trigger, setTrigger] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    id: '',
    eaccount: counter.loginReducer.currentAccount.id,
    auction_type: '',
    auction_title: '',
    status: '',
    date_expiry: '',
  });
  const [details, setDetails] = useState({
    show: false,
  });

  useEffect(() => {
    const { history } = props;
    if (!myPermission?.read) {
      history?.push('/purchases/inicio');
    }
    setLoading(true);
    dispatch(aprove_purchase_order_filter(filters, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, filters.page, filters.auction_type, filters.status]);

  const typesAuction = type => {
    switch (type) {
      case 'normal':
        return 'Normal';
      case 'priority':
        return 'Prioritaria';
      default:
    }
  };

  const renderHeaders = [
    <th key={`reqTH2`} className='px-4'>
      ID
    </th>,
    <th key={`reqTH3`} className='text-center px-2'>
      Artículos
    </th>,
    <th key={`reqTH4`}>Tipo de subasta</th>,
    <th key={`reqTH5`} className='px-2'>
      Título de cotización
    </th>,
    <th key={`reqTH6`} className='text-center px-4'>
      Inicio
    </th>,
    <th key={`reqTH7`} className='text-center px-4'>
      Vence
    </th>,
    <th key={`reqTH8`} className='text-center px-4'>
      Estado
    </th>,
    <th key={`reqTH99`} className='px-4'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.individualAuctionReducer.apo)) {
      table = counter.individualAuctionReducer.apo.map(x => {
        let justincase = x.date_expiry ? '' + x.date_expiry : '-';
        return (
          <tr key={'renderList' + x.id} className='hover-table-row'>
            <td className='text-uppercase px-4 text-start'>{x.auction_number}</td>
            <td className='text-center'>{x.articles}</td>
            <td className='text-start'>{typesAuction(x.priority)}</td>
            <td className='px-2'>
              {' '}
              <p className={`${AuthorizationsStyles.textLeft}`}> {x.description} </p>
            </td>
            <td className='text-center'>
              {x.created_at?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-center'>
              {justincase.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-center'>
              <Tooltip
                title={
                  x.status_articles.includes('req_authorization')
                    ? 'Requiere Autorización'
                    : 'Atendida'
                }
                arrow
              >
                <div
                  className={`
                    ${AuthorizationsStyles.popupReqActive} 
                    ${
                      x.status_articles.includes('req_authorization')
                        ? tableStyles.alertTriangle
                        : tableStyles.greenCheck
                    }
                  `}
                ></div>
              </Tooltip>
            </td>

            <td>
              {/* {!!myPermission?.read? */}
              <img
                src={lupaTransparent}
                alt='detalles'
                className={`icons-popUp hoverPointer ${AuthorizationsStyles.adjustIcon3}`}
                onClick={() => setDetails({ show: true, selectedAuction: x })}
              />
              {/* :
                    <span></span>
                    } */}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const principalRender = (
    <div className={tableStyles.container} style={{ padding: '0 6rem' }}>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={AuthorizationsStyles.container1}>
        <div className={AuthorizationsStyles.ItemSmall}>
          <h1 className={tableStyles.title}>Autorizar orden de compra</h1>
        </div>
      </div>
      <div className={AuthorizationsStyles.container2}>
        {/* ---------------------------- ROW 1 ---------------------------------- */}
        <div className='d-flex'>
          <div className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}>
            <p className={AuthorizationsStyles.crudModalLabel}>Tipo de Subasta</p>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              name='auction_type'
              styles={customSelectNewDark}
              onChange={e =>
                setFilters({
                  ...filters,
                  auction_type: e.value,
                })
              }
              value={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Normal', value: 'normal' },
                { label: 'Prioritaria', value: 'priority' },
              ].find(x => x.value === filters.auction_type)}
              options={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Normal', value: 'normal' },
                { label: 'Prioritaria', value: 'priority' },
              ]}
            />
          </div>
          <div className={` ${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}>
            <p className={AuthorizationsStyles.crudModalLabel}>Estado</p>

            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              name='status'
              styles={customSelectNewDark}
              onChange={e =>
                setFilters({
                  ...filters,
                  status: e.value,
                })
              }
              value={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Requiere Autorización', value: 'req_authorization' },
                { label: 'Atendido', value: 'solved,reject' },
              ].find(x => x.value === filters.status)}
              options={[
                { label: 'Seleccionar...', value: '' },
                { label: 'Requiere Autorización', value: 'req_authorization' },
                { label: 'Atendido', value: 'solved,reject' },
              ]}
            />
          </div>
          <div className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item2}`}>
            <p className={AuthorizationsStyles.crudModalLabel}>Fecha de vencimiento</p>
            <div className={tableStyles.deleteicon}>
              {filters.date_expiry ? (
                <span onClick={() => setFilters({ ...filters, date_expiry: '' })}>x</span>
              ) : (
                ''
              )}
              <input
                className={`register-inputs`}
                style={{ cursor: 'pointer' }}
                name='fromDate'
                type='date'
                value={filters.date_expiry}
                onChange={e =>
                  setFilters({
                    ...filters,
                    date_expiry: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className={`${AuthorizationsStyles.inputMarginNew} ${AuthorizationsStyles.Item3}`}>
            <p className={AuthorizationsStyles.crudModalLabel}>Título de cotización</p>
            <input
              className={`${tableStyles.SearchNew}`}
              type='text'
              placeholder='Buscar...'
              value={filters.auction_title}
              onChange={e =>
                setFilters({
                  ...filters,
                  auction_title: e.target.value,
                })
              }
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setTrigger(trigger + 1);
                }
              }}
            />
          </div>
          <div className={`${AuthorizationsStyles.Aling}`}>
            <img
              src={Lupa}
              alt='filtrar'
              className={`${AuthorizationsStyles.imagePaddingNew} ${AuthorizationsStyles.sizeNew} ${AuthorizationsStyles.pointer}`}
              onClick={() => setTrigger(trigger + 1)}
            />
          </div>
        </div>
      </div>

      <div className={AuthorizationsStyles.container3}>
        <GenericTableNew dark={true} headers={renderHeaders}>
          {renderList()}
        </GenericTableNew>
      </div>

      {/* <div className={AuthorizationsStyles.bottom}> */}
      <div className={paginationStyles.wrapper}>
        <p className={paginationStyles.paginationText}>
          Pag. {counter.individualAuctionReducer.apo_total ? filters.page : ''}
          {' de '}
          {Math.ceil(counter.individualAuctionReducer.apo_total / filters.perpage)
            ? Math.ceil(counter.individualAuctionReducer.apo_total / filters.perpage)
            : ''}{' '}
          ({counter.individualAuctionReducer.apo_total} encontrados)
        </p>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={10}
          totalItemsCount={counter.individualAuctionReducer.apo_total}
          pageRangeDisplayed={5}
          onChange={e => setFilters({ ...filters, page: e })}
          itemClassPrev={paginationStyles.itemClassPrev}
          itemClassNext={paginationStyles.itemClassNext}
          itemClassFirst={paginationStyles.itemClassFirst}
          itemClassLast={paginationStyles.itemClassLast}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );

  return details.show ? (
    <DetailPurchaseOrder
      selectedAuction={details.selectedAuction}
      show={details.show}
      setDetails={setDetails}
    />
  ) : (
    principalRender
  );
}

export default ApprovePurchaseOrder;
