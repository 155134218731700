import {
  GET_ARL_LIST,
  GET_CONTRACT_BY_ID,
  GET_DEDUCTIONS,
  GET_DISCIPLINARY_PROCESSES,
  GET_DISCOUNTS_ANDPAYOUT,
  GET_EDUCATION_LEVEL,
  GET_EMPLOYEE,
  GET_EMPLOYEEE_EDUCATION,
  GET_EMPLOYEE_EXPERIENCE,
  GET_EMPLOYEE_INFO,
  GET_ENDOWMENT_GROUPS,
  GET_EPS_LIST,
  GET_MEDICAL_INFO,
  GET_PENSION_FUND,
  GET_PERSONAL,
} from "../actions/actionTypes";

const initialState = {
  employee_list: [],
  employee_info: {},
  eps_list: [],
  pension_fund_list: [],
  arl_list: [],
  endowment_groups: [],
  education: [],
  experience: [],
  educationLevel: [],
  personal: [],
  totalPersonal: 0,
  deductionList: [],
  discountAndpayOuts: [],
  medicalInfo: [],
  disciplinaryProcesses: [],
  urlContract: "",
  personal_loading:false
};
export const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE:
      return {
        ...state,
        employee_list: action.payload,
      };
    case GET_EMPLOYEE_INFO:
      return {
        ...state,
        employee_info: action.payload,
      };
    case GET_EPS_LIST:
      return {
        ...state,
        eps_list: action.payload,
      };
    case GET_PENSION_FUND:
      return {
        ...state,
        pension_fund_list: action.payload,
      };
    case GET_ARL_LIST:
      return {
        ...state,
        arl_list: action.payload,
      };
    case GET_ENDOWMENT_GROUPS:
      return {
        ...state,
        endowment_groups: action.payload,
      };
    case GET_EMPLOYEEE_EDUCATION:
      return {
        ...state,
        education: action.payload,
      };
    case GET_EMPLOYEE_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
      };
    case GET_EDUCATION_LEVEL:
      return {
        ...state,
        educationLevel: action.payload,
      };
    case GET_PERSONAL:
      return {
        ...state,
        personal: action.payload,
        totalPersonal: action.total,
        personal_loading: action.loading
      };
    case GET_DEDUCTIONS:
      return {
        ...state,
        deductionList: action.payload,
      };
    case GET_DISCOUNTS_ANDPAYOUT:
      return {
        ...state,
        discountAndpayOuts: action.payload,
      };
    case GET_MEDICAL_INFO:
      return {
        ...state,
        medicalInfo: action.payload,
      };
    case GET_DISCIPLINARY_PROCESSES:
      return {
        ...state,
        disciplinaryProcesses: action.payload,
      };
    case GET_CONTRACT_BY_ID:
      return {
        ...state,
        urlContract: action.payload,
      };

    default:
      return state;
  }
};
