// react && react select && react router dom
import Select from 'react-select';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// material ui, styles
import { Box } from '@mui/material';
import styles from '../../components/Layouts/tableStyle.module.scss';
import paginationStyles from '../../components/Layouts/pagination.module.scss';

// hooks
import { useGetMethod } from '../../Hooks';

// Helpers
import { loader } from '../../helpers';

// icons
import Search from '../../assets/img/icons/lupa.svg';
import add from '../../assets/img/icons/add-check.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import cloneIcon from '../../assets/img/icons/cloneIcon.svg';

// Componentes
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import { customSelectAdmin } from './../../components/Layouts/react-select-custom';
import Pagination from 'react-js-pagination';

export const SettingSupport = () => {
  // states
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  // const eaccount = 13

  const [data, setData] = useState({
    trigger: 0,
    corporateClientLabel: '',
    contractLabel: '',
    filters: {
      page: 1,
      perpage: 10,
      search: '',
      corporateClient: '',
      contract: '',
    },
  });

  const { filters } = data;

  const {
    load: loadListSettingSupport,
    results: listSettingSupport,
    trigger: getListSettingSupport,
  } = useGetMethod();

  const { results: listCompany, load: loadListCompany, trigger: getListCompany } = useGetMethod();

  const {
    results: listContract,
    load: loadListContract,
    trigger: getListContract,
  } = useGetMethod();

  const tableHeader = [
    <th key={1} className='px-2 text-start col-2'>
      Empresa cliente
    </th>,
    <th key={2} className='px-2 text-start col-2'>
      Contrato
    </th>,
    <th key={3} className='px-2 text-start col-2'>
      Servicios
    </th>,
    <th key={4} className='px-2 text-start col-6'>
      Documentos
    </th>,
    <th key={5} className='px-2 text-start'>
      Facturación
    </th>,
    <th key={6} className='px-2 text-start'>
      Modalidad
    </th>,
    <th key={7} className='px-2 text-start'></th>,
  ];

  const tableBody = listData => {
    const body = [];
    if (Array.isArray(listData)) {
      listData?.forEach((item, i) => {
        body.push(
          <tr key={i}>
            <td className='px-2 text-start'>{item?.company?.name ? item?.company?.name : '-'}</td>
            <td className='px-2 text-start'>{item?.contract?.name ? item?.contract?.name : '-'}</td>
            <td className='px-2 text-start'>
              <div className='d-flex gap-1 flex-wrap'>
                {!item?.services?.length
                  ? '-'
                  : item?.services?.map((el, indice) =>
                      indice <= 2 ? (
                        <Box key={el?.id} className={styles.cardMessageBlue}>
                          {' '}
                          {el?.name}{' '}
                        </Box>
                      ) : indice === 3 ? (
                        <Box key={el?.id} className={styles.cardMessageBlue}>
                          ...
                        </Box>
                      ) : (
                        ''
                      ),
                    )}
              </div>
            </td>
            <td className='px-2 text-start'>
              <div className='d-flex gap-1 flex-wrap'>
                {!item?.documents?.length
                  ? '-'
                  : item?.documents?.map((el, indice) =>
                      indice <= 2 ? (
                        <Box key={el?.id} className={`${styles.cardMessageBlue}`}>
                          {' '}
                          {el?.name}{' '}
                        </Box>
                      ) : indice === 3 ? (
                        <Box key={el?.id} className={styles.cardMessageBlue}>
                          ...
                        </Box>
                      ) : (
                        ''
                      ),
                    )}
              </div>
            </td>
            <td className='px-2 text-start'>
              {' '}
              {item?.invoicing?.label ? item?.invoicing?.label : '-'}{' '}
            </td>
            <td className='px-2 text-start'>
              {' '}
              {item?.modality?.label ? item?.modality?.label : '-'}{' '}
            </td>
            <td className='px-2 text-start'>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                position='right top'
                extraButtons={[
                  {
                    text: <span>Editar</span>,
                    class: CustomPopupStyles.popUpLiEdit,
                    event: () => {
                      history.push({
                        state: { action: 'edit', data: { ...item } },
                        pathname: `parametrizacionSoporte/${item?.id}`,
                      });
                    },
                  },

                  {
                    text: <span>Duplicar</span>,
                    class: CustomPopupStyles.popUpGeneric,
                    icon: cloneIcon,
                    event: () => {
                      history.push({
                        state: { action: 'duplicate', data: { ...item } },
                        pathname: `parametrizacionSoporte/${item?.id}`,
                      });
                    },
                  },

                  {
                    text: <span>Ver detalle</span>,
                    class: CustomPopupStyles.popUpShowDetails,
                    event: () => {
                      history.push({
                        state: { action: 'detail', data: { ...item } },
                        pathname: `parametrizacionSoporte/${item?.id}`,
                      });
                    },
                  },
                ]}
              />
            </td>
          </tr>,
        );
      });
    }

    return body;
  };

  useEffect(() => {
    if (filters?.corporateClient) {
      getListContract({
        url: '/medical/client/contract/',
        token: token,
        objFilters: {
          client: filters.corporateClient,
          status: 'enabled',
          eaccount,
        },
      });
    }

    if (filters?.corporateClient !== '') {
      setData(state => ({
        ...state,
        contractLabel: '',
        filters: { ...state.filters, contract: '' },
      }));
    }

    if (filters?.corporateClient === '') {
      setData(state => ({
        ...state,
        contractLabel: '',
        filters: { ...state.filters, contract: '' },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.corporateClient]);

  useEffect(() => {
    getListSettingSupport({
      token: token,
      url: `/medical/configSupport`,
      objFilters: {
        page: 1,
        perpage: 10,
        search: '',
        corporateClient: '',
        contract: '',
        eaccount,
      },
    });

    getListCompany({
      url: '/coporateClients/',
      objFilters: { id_account: eaccount },
      token: token,
    });
  }, [eaccount, getListCompany, getListSettingSupport, token]);

  return (
    <>
      {(loadListSettingSupport || loadListCompany || loadListContract) && loader}

      <Box className={`${styles.layoutBase}`}>
        <Box className={`${styles.f32} ${styles.darkBlueText} mb-2 fw-bold`}>
          Parametrización de soportes
        </Box>

        <Box display={'Grid'} gridTemplateColumns={'192px 192px 1fr 192px'} gap={2} mb={2}>
          <Box>
            <Box className={`${styles.darkBlueText} fw-light`}>&nbsp;Empresa cliente</Box>

            <Select
              value={
                data?.corporateClientLabel
                  ? { label: data?.corporateClientLabel, value: filters?.corporateClient }
                  : ''
              }
              placeholder={'Seleccionar...'}
              styles={customSelectAdmin}
              noOptionsMessage={() => 'No hay datos'}
              options={
                listCompany?.results?.length
                  ? [
                      { label: 'Seleccionar...', value: '' },
                      ...listCompany.results.map(el => ({ label: el?.copyName, value: el?.id })),
                    ]
                  : []
              }
              onChange={({ value, label }) => {
                setData(state => ({
                  ...state,
                  corporateClientLabel: label,
                  filters: { ...state.filters, corporateClient: value },
                }));

                getListSettingSupport({
                  token: token,
                  url: `/medical/configSupport`,
                  objFilters: { ...filters, corporateClient: value, eaccount },
                });
              }}
            />
          </Box>

          <Box>
            <Box className={`${styles.darkBlueText} fw-light`}>&nbsp;Contrato</Box>

            <Select
              isDisabled={filters?.corporateClient === ''}
              value={
                data?.contractLabel ? { label: data?.contractLabel, value: filters?.contract } : ''
              }
              placeholder={'Seleccionar...'}
              styles={customSelectAdmin}
              noOptionsMessage={() => 'No hay datos'}
              options={
                listContract?.results?.length
                  ? [
                      { label: 'Seleccionar...', value: '' },
                      ...listContract.results.map(el => ({ label: el?.name, value: el?.id })),
                    ]
                  : []
              }
              onChange={({ value, label }) => {
                setData(state => ({
                  ...state,
                  contractLabel: label,
                  filters: { ...state.filters, contract: value },
                }));

                getListSettingSupport({
                  token: token,
                  url: `/medical/configSupport`,
                  objFilters: { ...filters, contract: value },
                });
              }}
            />
          </Box>

          <Box className='d-flex align-items-end'>
            <div className='d-flex align-items-center w-100'>
              <input
                className={`${styles.SearchNewBig}`}
                style={{ maxWidth: '400px' }}
                name='search'
                type='text'
                placeholder='Escribe aquí para buscar...'
                value={filters?.search}
                onChange={({ target }) => {
                  if (
                    !['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(
                      target.value[target.value?.length - 1],
                    )
                  ) {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, search: target?.value },
                    }));
                  }
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                    }));

                    getListSettingSupport({
                      url: `/medical/configSupport`,
                      objFilters: { ...filters, eaccount },
                      token: token,
                    });
                  }
                }}
              />

              <img
                src={Search}
                alt='Search icon'
                height='24px'
                className={`${styles.iconSvg} ${styles.iconSvgMargin}`}
                onClick={() => {
                  setData(state => ({
                    ...state,
                    trigger: state.trigger + 1,
                  }));

                  getListSettingSupport({
                    url: `/medical/configSupport`,
                    objFilters: { ...filters, eaccount },
                    token: token,
                  });
                }}
              />
            </div>
          </Box>

          <Box
            className={`${styles.filterHoverDarkerBlue} ${styles.f14} pointer`}
            display={'flex'}
            alignItems={'end'}
            justifyContent={'end'}
          >
            <Box
              className='w-100'
              display={'flex'}
              alignItems='center'
              gap={2}
              style={{ height: '32px' }}
              onClick={() => {
                history.push({
                  state: { action: 'create' },
                  pathname: `parametrizacionSoporte/nueva-parametrizacion`,
                });
              }}
            >
              <span className={`${styles.ordDarkGrayText} fw-bold`}>Crear parametrización</span>
              <img src={add} alt='agregar' />
            </Box>
          </Box>
        </Box>

        <GenericTable headers={tableHeader} dark={true}>
          {tableBody(listSettingSupport?.results)}
        </GenericTable>

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {filters.page ? filters.page : ''}
            {' de '}
            {Math.ceil(listSettingSupport?.rowTotal / filters.perpage)
              ? Math.ceil(listSettingSupport?.rowTotal / filters.perpage)
              : ''}{' '}
            ({listSettingSupport?.rowTotal} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={listSettingSupport?.rowTotal}
            pageRangeDisplayed={5}
            onChange={e => {
              setData(state => ({
                ...state,
                filters: { ...state.filters, page: e },
                trigger: state.trigger + 1,
              }));

              getListSettingSupport({
                url: `/medical/configSupport`,
                objFilters: { ...filters, eaccount, page: e },
                token: token,
              });
            }}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </Box>
    </>
  );
};
