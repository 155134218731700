import {
  GET_APPROVED_OVERTIME,
  GET_INHABILITIES,
  GET_PERMISSIONS_LIST,
  GET_VACATIONS_LIST,
} from "../actions/actionTypes";

const initialState = {
  overTimeList: [],
  permissionsList: [],
  vacationList: [],
  inhabilitiesList: [],
  rowTotal: 0,
  loadingPerm: false,
  loadingVct: false,
};
export const approvedNewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPROVED_OVERTIME:
      return {
        ...state,
        overTimeList: action.payload,
        rowTotal: action.rowTotal,
      };
    case GET_PERMISSIONS_LIST:
      return {
        ...state,
        permissionsList: action.payload,
        rowTotal: action.rowTotal,
        loadingPerm: action.loading,
      };

    case GET_VACATIONS_LIST:
      return {
        ...state,
        vacationList: action.payload,
        rowTotal: action.rowTotal,
        loadingVct: action.loading,
      };
    case GET_INHABILITIES:
      return {
        ...state,
        inhabilitiesList: action.payload,
        rowTotal: action.rowTotal,
      };

    default:
      return state;
  }
};
