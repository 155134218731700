// react && react select && react router dom
import Select from 'react-select';
import { useState, useEffect } from 'react';

// material ui, styles
import { Box } from '@mui/material';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import paginationStyles from './../../../components/Layouts/pagination.module.scss';

// Hook
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { useSelector } from 'react-redux';

// icons
import Search from '../../../assets/img/icons/lupa.svg';
import add from '../../../assets/img/icons/add-check.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';

// Components
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../../components/Popup/customPopup.module.scss';

import GenericTable from '../../../components/Layouts/GenericTableNew';
import { customSelectMultiBig } from '../../../components/Layouts/react-select-custom';
import ModalNew from './../../../components/Layouts/ModalNew';
import { customSwaltAlert } from './../../../helpers/customSwaltAlert';
import Pagination from 'react-js-pagination';
import { loader } from '../../../helpers';

export const Documents = () => {
  // states
  const store = useSelector(state => state);
  const idUser = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;

  const [data, setData] = useState({
    actions: {
      POST: 'POST',
      PUT: 'PUT',
    },
    modal: {
      show: false,
      title: '',
      action: '',
    },
    filter: {
      page: 1,
      perpage: 10,
      search: '',
      tag: '',
    },
    dataHttp: {
      id: '',
      descripción: '',
      typeDocument: [],
    },
    trigger: 0,
  });

  const { filter, modal, actions, dataHttp } = data;

  // queries
  const { load: loadListDocuments, results: listDocuments, trigger: getDocuments } = useGetMethod();

  const {
    load: loadListExtensions,
    results: listExtensions,
    trigger: getExtensions,
  } = useGetMethod();

  const { load: loadPostDocuments, trigger: postDocuments } = usePostMethod();

  const { load: loadPutDocuments, trigger: putDocuments } = usePostMethod();

  // table
  const tableHeader = [
    <th key={1} className='px-2 text-start'>
      Descripción
    </th>,
    <th key={2} className='px-2 text-start'>
      Tipo de documento
    </th>,
    <th key={3} className='px-2 text-center'>
      Veces asociado
    </th>,
    <th key={4} className='px-2 text-center'>
      Estado
    </th>,
    <th key={5} className='px-2 text-start'></th>,
  ];

  const tableBody = documents => {
    const body = [];
    if (Array.isArray(documents)) {
      documents?.forEach((item, i) => {
        body.push(
          <tr key={i}>
            <td className='px-2 text-start'>{item?.name ? item?.name : '-'}</td>

            <td className='px-2 text-start'>
              <Box display={'flex'} gap={1}>
                {item?.extensions?.map(ext => (
                  <Box key={ext?.id} className={styles.cardMessageBlue}>
                    {ext?.name}
                  </Box>
                ))}
              </Box>
            </td>

            <td className='px-2 text-center'>
              {typeof item?.linkedCount !== 'undefined' ? item?.linkedCount : '-'}
            </td>

            <td className='px-2 text-center'>
              <Box className={item.enabled ? styles.greenState2 : styles.redState2}>
                {item?.enabled ? 'Habilitado' : 'Inhabilitado'}
              </Box>
            </td>

            <td className='px-2 text-start'>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                position='right top'
                extraButtons={extraButtonsConditional(item)}
              />
            </td>
          </tr>,
        );
      });
    }

    return body;
  };

  // helpers
  const onIncrementTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const onChangeStateModal = ({ show, action, title }) => {
    setData(state => ({ ...state, modal: { ...state.modal, show, action, title } }));
  };

  const extraButtonsConditional = data => {
    if (!data?.isEditable) {
      return [
        {
          text: <span>{data?.enabled ? 'Deshabilitar' : 'Habilitar'}</span>,
          class: data?.enabled
            ? CustomPopupStyles.popUpLiActiveRed
            : CustomPopupStyles.popUpLiActive,
          event: () => onChangeUpdateStatus(data),
        },
      ];
    }

    return [
      {
        text: <span>Editar</span>,
        class: CustomPopupStyles.popUpLiEdit,
        event: () => {
          setData(state => ({
            ...state,
            modal: { ...state.modal, action: actions.PUT, show: true, title: 'Editar Documento' },
            dataHttp: {
              ...state.dataHttp,
              id: data?.id,
              descripción: data?.name,
              typeDocument: data?.extensions?.map(el => ({ label: el?.name, value: el?.id })),
            },
          }));
        },
      },

      {
        text: <span>{data?.enabled ? 'Deshabilitar' : 'Habilitar'}</span>,
        class: data?.enabled ? CustomPopupStyles.popUpLiActiveRed : CustomPopupStyles.popUpLiActive,
        event: () => onChangeUpdateStatus(data),
      },
    ];
  };

  const onClearState = () => {
    setData(state => ({
      ...state,
      actions: {
        POST: 'POST',
        PUT: 'PUT',
      },
      modal: {
        show: false,
        title: '',
        action: '',
      },
      filter: {
        page: 1,
        perpage: 10,
        search: '',
      },
      dataHttp: {
        id: '',
        descripción: '',
        typeDocument: [],
      },
      trigger: state.trigger,
    }));
  };

  // validations
  const validateSubmit = () => {
    if (!dataHttp?.descripción?.trim()?.length) {
      return { success: false, message: 'El campo descripción es obligatorio' };
    }

    if (!dataHttp?.typeDocument?.length) {
      return { success: false, message: 'El campo tipo de documento es obligatorio' };
    }

    return { success: true, message: 'validación exitosa' };
  };

  const validateSubmitAlert = ({ message }) => {
    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: message,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  };

  // https
  const onCreateDocument = () => {
    postDocuments({
      url: `/medical/supportFiles`,
      token: token,
      noAlert: true,
      method: 'POST',
      body: {
        usrId: idUser,
        name: dataHttp?.descripción,
        extensions: dataHttp?.typeDocument?.map(el => Number(el?.value)),
      },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado el documento: ${dataHttp?.descripción}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        onClearState();
        onIncrementTrigger();

        getDocuments({
          url: `/medical/supportFiles`,
          objFilters: {
            page: 1,
            perpage: 10,
            search: '',
            tag: '',
          },
          token,
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${error?.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const onUpdateDocument = () => {
    putDocuments({
      url: `/medical/supportFiles`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: {
        id: dataHttp?.id,
        usrId: idUser,
        name: dataHttp?.descripción,
        extensions: dataHttp?.typeDocument?.map(el => Number(el?.value)),
      },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha actualizado el documento: ${dataHttp?.descripción}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        onClearState();
        onIncrementTrigger();

        getDocuments({
          url: `/medical/supportFiles`,
          objFilters: {
            page: 1,
            perpage: 10,
            search: '',
            tag: '',
          },
          token,
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${error?.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const onChangeUpdateStatus = item => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${item?.enabled ? 'deshabilitará' : 'habilitará'} el documento: ${item?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        putDocuments({
          url: `/medical/supportFiles`,
          token: token,
          noAlert: true,
          method: 'PUT',
          body: {
            id: item?.id,
            enabled: !item?.enabled,
          },
          succesAction: () => {
            customSwaltAlert({
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: `Se ha ${item?.enabled ? 'deshabilitado' : 'habilitado'} el documento: ${item?.name}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            onClearState();
            onIncrementTrigger();

            getDocuments({
              url: `/medical/supportFiles`,
              objFilters: {
                page: 1,
                perpage: 10,
                search: '',
                tag: '',
              },
              token,
            });
          },
          doAfterException: error => {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `${error?.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  // submit
  const onSubmit = () => {
    const { message, success } = validateSubmit();

    if (!success) return validateSubmitAlert({ message });

    modal?.action === actions.POST ? onCreateDocument() : onUpdateDocument();
  };

  // Hooks
  useEffect(() => {
    const currentFilters = {
      page: 1,
      perpage: 10,
      search: '',
      tag: '',
    };

    getDocuments({
      url: `/medical/supportFiles`,
      objFilters: currentFilters,
      token,
    });

    getExtensions({
      url: `/medical/fileExtension`,
      objFilters: currentFilters,
      token,
    });
  }, [getDocuments, getExtensions, token]);

  return (
    <>
      {(loadListDocuments || loadListExtensions || loadPostDocuments || loadPutDocuments) && loader}
      <Box className={`${styles.layoutBaseNew}`}>
        <Box className={`${styles.f32} ${styles.darkBlueText} mb-2 fw-bold`}>
          Tabla maestra documentos
        </Box>

        <Box display={'Grid'} gridTemplateColumns={'1fr 192px'} gap={6} mb={2}>
          <Box style={{ width: '550px' }} className='d-flex align-items-end'>
            <div className='d-flex align-items-center w-100'>
              <input
                className={`${styles.SearchNewBig}`}
                name='search'
                type='text'
                placeholder='Escribe aquí para buscar...'
                onChange={({ target }) => {
                  setData(state => ({
                    ...state,
                    filter: { ...state.filter, search: target.value },
                  }));
                }}
                value={filter?.search}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    onIncrementTrigger();

                    getDocuments({
                      token,
                      url: `/medical/supportFiles`,
                      objFilters: { ...filter },
                    });
                  }
                }}
              />

              <img
                src={Search}
                alt='Search icon'
                height='24px'
                className={`${styles.iconSvg} ${styles.iconSvgMargin}`}
                onClick={() => {
                  onIncrementTrigger();
                  getDocuments({
                    token,
                    url: `/medical/supportFiles`,
                    objFilters: { ...filter },
                  });
                }}
              />
            </div>
          </Box>

          <Box
            className={`${styles.filterHoverDarkerBlue} ${styles.f14} pointer`}
            display={'flex'}
            alignItems={'end'}
            justifyContent={'end'}
          >
            <Box
              className='w-100'
              display={'flex'}
              alignItems='center'
              justifyContent={'end'}
              gap={2}
              style={{ height: '32px' }}
              onClick={() =>
                onChangeStateModal({ show: true, title: 'Nuevo Documento', action: actions.POST })
              }
            >
              <span className={`${styles.ordDarkGrayText} fw-bold`}>Nuevo documento</span>
              <img src={add} alt='agregar' />
            </Box>
          </Box>
        </Box>

        <GenericTable headers={tableHeader} dark={true}>
          {tableBody(listDocuments?.results)}
        </GenericTable>

        <div className={paginationStyles.wrapper}>
          <p className={`${paginationStyles.paginationText} text-secondary`}>
            Pag. {filter.page ? filter.page : ''}
            {' de '}
            {Math.ceil(listDocuments?.rowTotal / filter.perpage)
              ? Math.ceil(listDocuments?.rowTotal / filter.perpage)
              : ''}{' '}
            ({listDocuments?.rowTotal} encontrados)
          </p>
          <Pagination
            activePage={filter.page}
            itemsCountPerPage={filter.perpage}
            totalItemsCount={listDocuments?.rowTotal}
            pageRangeDisplayed={5}
            onChange={e => {
              setData(state => ({
                ...state,
                filter: { ...state.filter, page: e },
                trigger: state?.trigger + 1,
              }));

              getDocuments({
                url: `/medical/supportFiles`,
                objFilters: { ...filter, page: e },
                token,
              });
            }}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <ModalNew
          title={modal?.title}
          show={modal?.show}
          onHide={() => onClearState()}
          btnYesEvent={() => onSubmit()}
          btnNoEvent={() => onClearState()}
        >
          <Box>
            <Box mb={1}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Descripción
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>

              <input
                type='text'
                className={`register-inputs ${styles.h32}`}
                placeholder={'Escribir...'}
                value={dataHttp?.descripción}
                onChange={({ target: { value } }) => {
                  setData(state => ({
                    ...state,
                    dataHttp: { ...state.dataHttp, descripción: value },
                  }));
                }}
              />
            </Box>

            <Box mb={3}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Tipo de documento
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>

              <Select
                isMulti
                placeholder={'Seleccionar...'}
                styles={customSelectMultiBig}
                value={dataHttp?.typeDocument}
                options={listExtensions?.results?.map(el => ({ label: el?.name, value: el?.id }))}
                noOptionsMessage={() => 'No hay datos'}
                onChange={typeDocument => {
                  setData(state => ({
                    ...state,
                    dataHttp: { ...state.dataHttp, typeDocument },
                  }));
                }}
              />
            </Box>
          </Box>
        </ModalNew>
      </Box>
    </>
  );
};
