import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export const TextGrayInBrackets = ({ typeSignature, typeId, active }) => {
    const color = '#CBCBD0'
    const Line = () => <div style={{ height: '1px', width: '100%', background: color }} />
    return (
        <div className={tableStyles.f13} style={active ? { color: '#6e6f7c' } : { color }}>
            <div className="mb-2">
                <Line />
                <div>{typeSignature}</div>
            </div>

            <div className="d-flex gap-2 align-items-baseline">
                <div>{typeId}</div>
                <Line />
            </div>
        </div>
    )

}


