import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import file from '../../assets/img/icons/AdjuntarRecto.svg';
import Down from '../../assets/img/icons/detailsClosed.svg';
import Atras from '../../assets/img/icons/iconBack.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import Document from '../../assets/img/icons/traerReq.svg';

import {
  getDayCalendar,
  getDetailCalendar,
  getOneBalance,
  getTypeActivity,
  newObservation,
} from '../../actions/receiptOfInvoicesActions';
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import ModalNew from '../../components/Layouts/ModalNew';
import { SlideDownButton } from '../../components/Layouts/SlideDownButton';

import moment from 'moment';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { customSwaltAlert, loader, onChangeFile } from '../../helpers';

function SituationPurseDetail(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(1);
  const [selectedList, setselectedList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState({});
  const [reqState, setreqState] = useState({
    auction_title: '',
    date_expiry: '',
    hour_expiry: '',
    terms: '',
    priority: '',
    id_warehouse: '',
    id_user: counter.loginReducer.user_data.id,
    account: counter.loginReducer.currentAccount.id,
    account_session: counter.loginReducer.currentAccount.name,
    id_consumption_center: 0,
    articulos: [],
    pay_conditions: '',
    coin: '',
    document: [],
    file: false,
    observations: ' ',
    qty_total: 0,
  });
  const [filterModal, setFilterModal] = useState({
    client: props.filter.client,
    user: counter.loginReducer.user_data.id,
    date: moment().format('YYYY-MM-DD'),
    invoices: [],
    file64: '',
    docType: '',
    type: '',
    summary: '',
    fileName: null,
  });

  useEffect(() => {
    dispatch(getTypeActivity());
    dispatch(
      getOneBalance({
        eaccount: counter.loginReducer.currentAccount.id,
        id: props.filter.client,
        invoiceSeq: filters.invoiceSeq ? filters.invoiceSeq : '',
        receivableDetail: true,
      }),
    );
    dispatch(
      getDetailCalendar({
        date: props.info.date,
        year: props.filter.year,
        month: props.filter.month,
        client: props.filter.client,
      }),
    );
  }, [
    trigger,
    counter.loginReducer.currentAccount.id,
    filters.invoiceSeq,
    props.filter,
    props.info.date,
    dispatch,
  ]);

  const resetStatusFile = event => {
    if (event) {
      event.stopPropagation();
    }
    setreqState({
      auction_title: '',
      date_expiry: '',
      hour_expiry: '',
      terms: '',
      priority: '',
      id_warehouse: '',
      id_user: counter.loginReducer.user_data.id,
      account: counter.loginReducer.currentAccount.id,
      account_session: counter.loginReducer.currentAccount.name,
      id_consumption_center: 0,
      articulos: [],
      pay_conditions: '',
      coin: '',
      document: [],
      file: false,
      observations: ' ',
      qty_total: 0,
    });
  };

  let optionsActivity = [{ key: '', value: '', label: 'Seleccione tipo de gestión' }];
  if (Array.isArray(counter.invoiceReducer.typeActivity)) {
    counter.invoiceReducer.typeActivity.forEach((item, index) => {
      optionsActivity.push({
        value: item.id,
        label: item.name,
        key: index + 'eventType',
      });
    });
  }

  function onGoBack() {
    props.setDetails(prev => ({ ...prev, show: false }));
    dispatch(
      getDayCalendar({
        year: props.filter.year,
        month: props.filter.month,
        client: props.filter.client,
      }),
    );
  }

  const isBoxSelected = invoice_id => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x === invoice_id);
      return !!target;
    }
  };

  const changeBoxSelected = invoice => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x === invoice);
      if (target) {
        tempSelected = tempSelected.filter(x => x !== invoice);
      } else {
        let newChange = invoice;
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(counter.invoiceReducer.one_balance) && Array.isArray(selectedList)) {
      counter.invoiceReducer.one_balance.forEach(item => {
        if (item.filing_date !== null) {
          if (selectedList.find(itemb => item.id === itemb)) {
            //f
          } else {
            res = false;
          }
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = [...selectedList];
      if (Array.isArray(counter.invoiceReducer.one_balance) && Array.isArray(tempSelected)) {
        counter.invoiceReducer.one_balance.forEach(x => {
          if (x.filing_date !== null) {
            if (selectedList.find(itemb => x.id === itemb)) {
              //f
            } else {
              let newChange = x.id;
              tempSelected.push(newChange);
            }
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      setselectedList([]);
    }
  };

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const resetForm = () => {
    setselectedList([]);
    setShowModal(false);
    setTrigger(trigger + 1);
    resetStatusFile();
    setFilterModal(prev => ({
      ...prev,
      date: moment().format('YYYY-MM-DD'),
      docType: '',
      file64: '',
      fileName: null,
      invoices: [],
      summary: '',
      type: '',
    }));
  };
  const newObj = () => {
    const options = filterModal;
    options.invoices = selectedList;
    options.file64 = reqState?.file?.base64;
    options.docType = reqState?.file?.docType;
    dispatch(newObservation(options, () => resetForm()));
  };

  const onSubmitFilters = e => {
    e.preventDefault();
    const { invoice } = e.target;
    setFilters({
      ...filters,
      invoiceSeq: invoice.value,
    });
  };

  const onChangeSignature = async e => {
    const file = await onChangeFile(e);

    if (file.fileSize > 3 * 1024 * 1024) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El tamaño máximo permitido para el archivo es 3MB.',
        confirmButtonText: 'Aceptar',
      });
      setFilterModal(prev => ({
        ...prev,
        base64: '',
        docType: '',
        fileName: null,
      }));
      e.target.value = null;
      return;
    }

    setFilterModal(prev => ({
      ...prev,
      base64: file.file,
      docType: file.typeFile,
      fileName: file.fileName,
    }));
  };

  let optionsStatus = [{ key: 'default', value: '', label: 'Seleccione estado', id: '' }];

  if (Array.isArray(counter.invoiceReducer.status)) {
    counter.invoiceReducer.status.forEach(item => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + 'status',
      });
    });
  }

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccione cliente', id: '' }];
  if (Array.isArray(counter.invoiceReducer.clients)) {
    counter.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  const headerCard = [
    <div key={1} className='col text-start ps-2'>
      No. Factura{' '}
    </div>,
    <div key={2} className='col text-center'>
      Fecha Factura
    </div>,
    <div key={3} className='col text-end pe-2'>
      Valor
    </div>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(counter.invoiceReducer.one_balance)) {
      table = counter.invoiceReducer.one_balance.map((x, index) => {
        return (
          <div key={'renderList' + index} className='row g-0 align-items-center py-2'>
            {props.info.date === moment().format('YYYY-MM-DD') && (
              <div className='col-1 px-2 text-center'>
                <input
                  type='checkbox'
                  className={
                    x.filing_date
                      ? 'border border-dark-blue form-check-input check-dark-blue'
                      : 'border border-clear-grey form-check-input'
                  }
                  style={{ width: '14px', height: '14px' }}
                  checked={isBoxSelected(x.id)}
                  onChange={() => changeBoxSelected(x.id)}
                  disabled={x.filing_date ? false : true}
                />
              </div>
            )}
            <div className='col text-start px-2 text-uppercase'>{x.sequence}</div>
            <div className='col text-center px-2'>
              {x?.date ? x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : ''}
            </div>
            <div className='col px-2 text-end'>{'$' + numberWithCommas(x.amount)}</div>
            <div className='col text-center px-2'>
              {x?.filing_date
                ? x.filing_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                : '-'}
            </div>
            <div className='col text-center px-2'>
              {x.expiration_period ? `De ${x.expiration_period}` : '-'}
            </div>
            <div
              className='col text-center px-2'
              style={{ paddingRight: '1rem', paddingLeft: '1rem' }}
            >
              <div className='rounded-pill p-1' style={{ backgroundColor: `${x.background}` }}>
                <b style={{ color: `${x.fontcolor}` }}>{x.status}</b>
              </div>
            </div>
          </div>
        );
      });
    }
    return table;
  };
  const renderListCard = invoice => {
    let tableCard = [];

    if (Array.isArray(invoice)) {
      tableCard = invoice.map((x, index) => {
        return (
          <section2 key={'renderList' + index} className='hover-table-row'>
            <div className='col text-start text-uppercase ps-2'>{x.sequence}</div>
            <div className='col text-center'>
              {x?.invoice_date
                ? x.invoice_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                : ''}
            </div>
            <div className='col text-end pe-2'>{'$' + numberWithCommas(x.amount)}</div>
          </section2>
        );
      });
    }
    return tableCard;
  };

  return (
    <div className='container mt-5'>
      {!!counter.invoiceReducer.loading_PayLegalCollection && loader}
      {counter.invoiceReducer.loading_calendar_detail && loader}

      <div className='d-flex align-items-center'>
        <img
          className='mr-3'
          src={Atras}
          onClick={onGoBack}
          style={{ cursor: 'pointer' }}
          alt='Atras'
        />
        <h1 className='m-0'>Detalle cartera</h1>
      </div>

      <div className='row mt-4'>
        <div className='col-lg-8'>
          <Row className='d-flex align-items-end'>
            <Col xs={3}>
              <small className='text-primary ms-1'>Empresa</small>
              <input
                className='register-inputs'
                type='text'
                defaultValue={props.filter.clientName}
                disabled
              />
            </Col>
            <Col as='form' onSubmit={onSubmitFilters} xs={3}>
              <small className='text-primary ms-1'>No. Factura</small>
              <input className='register-inputs' type='text' name='invoice' />
            </Col>
            <Col xs={3}>
              <i>
                <img
                  className={`${tableStyles.cursorPointer}`}
                  src={Lupa}
                  onClick={() => {
                    setTrigger(trigger + 1);
                    onSubmitFilters();
                  }}
                  alt='Lupa'
                />
              </i>
            </Col>
          </Row>

          <div
            className='row align-items-center g-0 mt-4 py-2 text-white fw-bold small'
            style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            {props.info.date === moment().format('YYYY-MM-DD') && (
              <div className='col-1 text-center px-2'>
                <input
                  type='checkbox'
                  className='border border-dark-blue form-check-input check-dark-blue'
                  style={{ width: '14px', height: '14px' }}
                  name={'selectedAll'}
                  value='selectedAll'
                  checked={isBoxAllSelected()}
                  onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </div>
            )}
            <div className='col text-start px-2' style={{ whiteSpace: 'nowrap' }}>
              No. factura
            </div>
            <div className='col text-center px-2' style={{ whiteSpace: 'nowrap' }}>
              Fecha factura
            </div>
            <div className='col text-end px-2'>Valor</div>
            <div className='col text-center px-2' style={{ whiteSpace: 'nowrap' }}>
              Fecha radicación
            </div>
            <div className='col text-center px-2'>Vencimiento</div>
            <div className='col text-center px-2'>Estado</div>
          </div>

          <div
            className={`${tableStyles.odd_rows} overflow-auto small`}
            style={{ maxHeight: '50vh' }}
          >
            {renderList()}
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='d-flex align-items-end' style={{ height: 55 }}>
            <small className='text-primary pb-1 fw-bold'>Observaciones</small>

            <div style={{ flex: 1 }}></div>

            {props.info.date === moment().format('YYYY-MM-DD') && (
              <div
                className={tableStyles.buttonTextPrimary}
                onClick={() =>
                  selectedList.length < 1
                    ? customSwaltAlert({
                        icon: 'warning',
                        title: 'Intenta de nuevo',
                        text: 'No hay facturas relacionadas',
                        confirmButtonText: 'Aceptar',
                        showCancelButton: false,
                      })
                    : setShowModal(true)
                }
              >
                <small className='mr-2' style={{ whiteSpace: 'nowrap' }}>
                  Nueva observación
                </small>
                <img width='15px' height='20px' src={Document} alt='Form' />
              </div>
            )}
          </div>

          <div className='mt-4 px-3' style={{ overflow: 'scroll', maxHeight: '50vh' }}>
            {counter?.invoiceReducer?.DetailCalendar?.map((item, ind) => {
              return (
                <div
                  key={ind}
                  style={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <Row className='d-flex'>
                    <Col xs={6}>
                      <label className={IndividualStyles.crudModalLabelGris}>
                        <b style={{ fontSize: '14px' }}>{item.type}</b>
                      </label>
                    </Col>
                    <Col xs={6} className='text-end'>
                      <label className={IndividualStyles.crudModalLabelGris}>
                        <b style={{ fontSize: '14px' }}>{item.date}</b>
                      </label>
                    </Col>
                  </Row>
                  <label
                    className={IndividualStyles.crudModalLabelGris}
                    style={{ whiteSpace: 'inherit' }}
                  >
                    <p>{item.summary}</p>
                  </label>

                  {item.invoices.length > 0 ? (
                    <SlideDownButton
                      iconAfter={Down}
                      widthText={true}
                      bgBody='white'
                      width={'100%'}
                      colorBg={'white'}
                      textAlign='start'
                      title='Ver facturas relacionadas'
                      bodyWidth={'100%'}
                      body={
                        <>
                          <GenericTableScroll
                            headers={headerCard}
                            body={renderListCard(item.invoices)}
                            typeHead={'2'}
                          ></GenericTableScroll>
                        </>
                      }
                    ></SlideDownButton>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Observación'}
        btnYesEvent={() => newObj()}
        btnNoEvent={() => {
          setShowModal(false);
          setFilterModal({
            client: props.filter.client,
            user: counter.loginReducer.user_data.id,
          });
          resetStatusFile();
        }}
        btnYesName={'Guardar'}
        size={'500'}
      >
        <div className='row gx-3 gy-2'>
          <div className='col-6'>
            <small className='text-primary ms-1'>Responsable</small>
            <input
              className='register-inputs'
              type='text'
              value={
                counter.loginReducer.user_data.first_name +
                ' ' +
                counter.loginReducer.user_data.first_surname
              }
              disabled
            />
          </div>

          <div className='col-6'>
            <small className='text-primary ms-1'>Gestión</small>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar'
              options={optionsActivity}
              styles={customSelectNewDark}
              onChange={e =>
                setFilterModal({
                  ...filterModal,
                  type: e.value,
                })
              }
            />
          </div>

          <div className='col-6'>
            <small className='text-primary ms-1'>Fecha</small>
            <input
              className='register-inputs'
              type='date'
              defaultValue={moment().format('YYYY-MM-DD')}
              disabled
            />
          </div>
          <div className='col-6'>
            <small className='text-primary ms-1'>Cargar archivo</small>
            <label
              htmlFor='signature'
              className='register-inputs d-flex align-items-center pointer overflow-hidden'
            >
              <span className={`flex-grow-1 ${tableStyles.elipsis}`} style={{ maxWidth: '100%' }}>
                {filterModal.fileName ?? 'Cargar...'}
              </span>
              <input
                id='signature'
                type='file'
                accept='image/png, image/jpeg, application/pdf'
                className='d-none'
                onChange={onChangeSignature}
              />
              <img
                src={file}
                alt='agregar archivo'
                width={15}
                className={`${tableStyles.iconFileStyle}`}
              />
            </label>
          </div>
          <div className='col-12'>
            <small className='text-primary ms-1'>Descripción</small>
            <textarea
              className='register-inputs w-100'
              onChange={e =>
                setFilterModal({
                  ...filterModal,
                  summary: e.target.value,
                })
              }
              style={{ minHeight: 100 }}
            />
          </div>
        </div>
      </ModalNew>
    </div>
  );
}
export default SituationPurseDetail;
