import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { isEmptyOrUndefined, message } from '../../helpers/helpers';

export function OrdDropZone({ getFile, validateExt, whiteExtList = ['zip', 'pdf', 'rar', 'xls'] }) {
  const [file, setFile] = useState({
    base64: '',
    filename: '',
  });
  let isValid = false;
  const validateFile = async e => {
    const file = e[0];
    if (!isEmptyOrUndefined(file)) {
      let actFileExt = file.name?.split('.');
      let extension = whiteExtList.find(obj => obj === actFileExt[actFileExt.length - 1]);
      if (isEmptyOrUndefined(extension)) {
        e = '';
        isValid = false;
        setFile({
          base64: '',
          filename: '',
        });
        return message(
          'warning',
          'Advertencia!',
          `No se permiten documentos con extensión .${actFileExt[
            actFileExt.length - 1
          ].toUpperCase()}`,
        );
      }

      if (file.size > 50000000) {
        e = '';
        isValid = false;
        setFile({
          base64: '',
          filename: '',
        });
        return message(
          'warning',
          'Advertencia',
          'Su archivo no puede tener un tamaño superior a 5MB',
        );
      } else {
        if (file.size <= 0) {
          e = '';
          setFile({
            base64: '',
            filename: '',
          });
          isValid = false;
          return message('warning', 'Advertencia', 'Por favor, adjunte un archivo valido.');
        }
      }
    } else {
      return console.error('Archivo vacio: ', file);
    }
    isValid = true;
  };
  const onDrop = useCallback(acceptedFiles => {
    validateFile(acceptedFiles, whiteExtList);
    if (isValid) {
      !!validateExt && validateExt(acceptedFiles);
      getBase64(acceptedFiles[0]);
      files(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const files = acceptedFiles =>
    acceptedFiles.map(file => {
      getBase64(file);
      return (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      );
    });

  async function getBase64(file) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile({ base64: reader.result, filename: file?.name });
      !!getFile && getFile({ base64: reader.result, filename: file?.name });
    };
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className='cursorPointer' {...getRootProps()}>
      <input {...getInputProps()} />
      {file?.filename === '' ? (
        isDragActive ? (
          <p>Sueltalo...</p>
        ) : (
          <p>Soltar documento aquí </p>
        )
      ) : (
        <aside className='text-center'>
          <ul>{file?.filename}</ul>
        </aside>
      )}
    </div>
  );
}
