// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState, useEffect, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector, useDispatch } from 'react-redux';
import GenericTableNew from '../../components/Layouts/GenericTableNew';
import ModalNew from '../../components/Layouts/ModalNew';
import Pagination from 'react-js-pagination';
import {
  getListCompanies,
  getRegion,
  getTypesContracts,
  getCIIU,
  getPayConditions,
  getPayMethods,
  getServicesInADM,
  getDivisa,
  creatContract,
  getTypesServices,
  getOneContract,
} from '../../actions/billingActions';
import * as deepcopy from 'deepcopy';

import Select from 'react-select';
import {
  customSelectNewDark,
  customSelectMulti,
  ordCustomSelect,
} from '../../components/Layouts/react-select-custom';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { Button } from 'react-bootstrap';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import makeAnimated from 'react-select/animated';

// --------------- icons 💥  ----------------//
import backIcon from '../../assets/img/icons/iconBack.svg';
import question from '../../assets/img/icons/question.svg';
import AddBlue from '../../assets/img/icons/AddBlue.svg';
import lupaClear from '../../assets/img/icons/lupaClear.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Word from '../../assets/img/icons/word.svg';
import PDF from '../../assets/img/icons/pdf.svg';
import Closed from '../../assets/img/icons/Cerrar.svg';

//-------------- scss styles 😄   --------------
import styles from './contractClientsStyles.module.scss';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { useGetMethod } from './../../Hooks/useFetch';
import { loader, optionsSelect } from '../../helpers/helpers';

export function NewContract(props) {
  const animatedComponents = makeAnimated();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const dispatch = useDispatch();
  const [modalAdd, setModalAdd] = useState(false);
  const [selectedList, setselectedList] = useState([]);
  const [ServicesSelected, setServicesSelected] = useState([]);

  const componentSelect = !props.edit
    ? {
        MultiValueRemove: () => null, // Elimina el botón de eliminación
      }
    : {};

  const [filters, setFilters] = useState({
    eaccount: storage.loginReducer.currentAccount.id,
    sequence: '',
    start_date: '',
    end_date: '',
    client: undefined,
    contract_name: '',
    contractType: undefined,
    handle_copayment: false,
    is_copayment_editable: false,
    includes_frame: false,
    ciuu: undefined,
    description: '',
    attention_conditions: '',
    paymentCond: '',
    invoiceType: '',
    filing_date: undefined,
    notification_email: '',
    trm: '',
    currency: undefined,
    depositType: undefined,
    population: [],
    services: [],
    conectivityPrice: '',
  });

  const [filters2, setFilters2] = useState({
    perpage: 10,
    page: 1,
    type: '',
    search: '',
    status: 'enabled',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  //--------------first functions 🍕   --------------
  const [loading, setLoading] = useState(false);
  const token = storage.loginReducer.Authorization;
  const eaccount = storage.loginReducer.currentAccount.id;

  const [listServices, setListServices] = useState([]);

  const {
    results: listTypeCompany,
    trigger: getListTypeCompany,
    load: loadListTypeCompany,
  } = useGetMethod();

  const { trigger: getListServices, load: loaderListServices } = useGetMethod();

  useEffect(() => {
    if (props.selectedContract) {
      setLoading(true);
      dispatch(
        getOneContract(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: props.selectedContract,
            ungroup: true
          },
          setFilters,
          () => setLoading(false),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedContract]);

  useEffect(() => {
    getListTypeCompany({
      url: `/medical/client/companytype/`,
      objFilters: { eaccount },
      token: token,
    });

    getListServices({
      url: `/medical/generals/serviceType/`,
      objFilters: { eaccount, listAll: 1 },
      token: token,
    }).then(res => {
      if (res.success) {
        const initialValues = { label: 'Seleccionar...', value: '' };
        const services = res?.results?.map(service => ({
          value: service.serviceTypeId,
          label: service.serviceType,
          key: service.serviceTypeId + 'services',
        }));

        const formatData = services?.length ? [initialValues, ...services] : [initialValues];

        setListServices(formatData);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //--------------initial functions 🍕   --------------
  useEffect(() => {
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getTypesContracts({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getCIIU({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getPayConditions({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getPayMethods({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getDivisa({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getTypesServices({ eaccount: storage.loginReducer.currentAccount.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getRegion({
        eaccount: storage.loginReducer.currentAccount.id,
        client: filters.client,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  useEffect(() => {
    dispatch(
      getServicesInADM({ ...filters2, typeId: filters2.type }, () => {
        setLoading(false);
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters2.page, trigger]);

  //-------------- select options 🍕   --------------
  let optionsClients = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsClients.push({
        value: item.id,
        label: item.copyName,
        key: item.Nit,
      });
    });
  }

  let optionsRegion = [];
  if (Array.isArray(storage.billingReducer.regions)) {
    storage.billingReducer.regions.forEach(item => {
      optionsRegion.push({
        value: item.id,
        label: item.name,
        key: item.Nit,
      });
    });
  }

  let optionsContracts = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.types_contracts)) {
    storage.billingReducer.types_contracts.forEach(item => {
      optionsContracts.push({
        value: item.id,
        label: item.value,
        key: item.id + 'contracts',
      });
    });
  }

  let optionsCIIU = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.ciuu)) {
    storage.billingReducer.ciuu.forEach(item => {
      optionsCIIU.push({
        value: item.id,
        label: item.value,
        key: item.id + 'ciuu',
      });
    });
  }

  let optionsPayC = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.pay_conditions)) {
    storage.billingReducer.pay_conditions.forEach(item => {
      optionsPayC.push({
        value: item.id,
        label: item.value,
        key: item.id + 'conditions',
      });
    });
  }

  let optionsServices = [{ key: 'default', value: '', label: 'Seleccionar...' }];

  if (Array.isArray(storage.billingReducer.get_types_services)) {
    storage.billingReducer.get_types_services.forEach(item => {
      optionsServices.push({
        value: item.serviceTypeId,
        label: item.serviceType,
        key: item.serviceTypeId + 'services',
      });
    });
  }

  let optionsPayM = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.pay_methods)) {
    storage.billingReducer.pay_methods.forEach(item => {
      optionsPayM.push({
        value: item.id,
        label: item.value,
        key: item.id + 'methods',
      });
    });
  }

  let optionsDays = [
    { key: 'default', value: '', label: 'Seleccionar...' },
    { key: 'default', value: 1, label: '1' },
    { key: 'default', value: 2, label: '2' },
    { key: 'default', value: 3, label: '3' },
    { key: 'default', value: 4, label: '4' },
    { key: 'default', value: 5, label: '5' },
    { key: 'default', value: 6, label: '6' },
    { key: 'default', value: 7, label: '7' },
    { key: 'default', value: 8, label: '8' },
    { key: 'default', value: 9, label: '9' },
    { key: 'default', value: 10, label: '10' },
    { key: 'default', value: 11, label: '11' },
    { key: 'default', value: 12, label: '12' },
    { key: 'default', value: 13, label: '13' },
    { key: 'default', value: 14, label: '14' },
    { key: 'default', value: 15, label: '15' },
    { key: 'default', value: 16, label: '16' },
    { key: 'default', value: 17, label: '17' },
    { key: 'default', value: 18, label: '18' },
    { key: 'default', value: 19, label: '19' },
    { key: 'default', value: 20, label: '20' },
    { key: 'default', value: 21, label: '21' },
    { key: 'default', value: 22, label: '22' },
    { key: 'default', value: 23, label: '23' },
    { key: 'default', value: 24, label: '24' },
    { key: 'default', value: 25, label: '25' },
    { key: 'default', value: 26, label: '26' },
    { key: 'default', value: 27, label: '27' },
    { key: 'default', value: 28, label: '28' },
    { key: 'default', value: 29, label: '29' },
    { key: 'default', value: 30, label: '30' },
  ];

  let optionsDivisa = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.divisa)) {
    storage.billingReducer.divisa.forEach(item => {
      optionsDivisa.push({
        value: item.short_name,
        label: item.value,
        key: item.id + 'divisa',
      });
    });
  }

  const renderTooltip = message => (
    <Tooltip placement='top-end' id='button-tooltip'>
      {message}
    </Tooltip>
  );

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  //-------------- logic of select all  🍕   --------------

  const isBoxAllSelected = () => {
    let res = true;

    if (Array.isArray(storage.billingReducer.services_ADM) && Array.isArray(selectedList)) {
      storage.billingReducer.services_ADM.forEach(item => {
        if (selectedList.find(itemb => item.srvId !== itemb.srvId)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(storage.billingReducer.services_ADM) && Array.isArray(tempSelected)) {
        storage.billingReducer.services_ADM.forEach(x => {
          let newChange = {
            ...x,
            qty: '',
            min: '',
          };
          tempSelected.push(newChange);
        });

        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  //-------------- logic of checkbox 🍕   --------------

  const isBoxSelected = item => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.srvId === item.srvId);
      return !!target;
    }
  };

  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.srvId === item.srvId);
      if (target) {
        tempSelected = tempSelected.filter(x => x.srvId !== item.srvId);
      } else {
        let newChange = {
          ...item,
          qty: '',
          min: '',
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  //-------------- Render Table of Services 🍕   --------------
  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.billingReducer.services_ADM)) {
      table = storage.billingReducer.services_ADM.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='px-2'>{x.cupCode}</td>
            <td className='text-center px-2'>{x.name}</td>
            <td className='text-center px-2'>{x.serviceType}</td>
            <td>
              <input
                type='checkbox'
                name=''
                id=''
                checked={isBoxSelected(x)}
                onChange={() => changeBoxSelected(x)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  //-------------- HandleChange in inputs 🍕   --------------
  const handleChangeQty = (elem, value) => {
    // let { value } = e.target;
    let tempObj = [...ServicesSelected];
    let index = ServicesSelected.findIndex(x => x.srvId === elem.srvId);
    if (index >= 0) {
      tempObj[index].qty = value;
    }
    setServicesSelected(tempObj);
  };

  const handleChangeMin = (elem, value) => {
    // let { value } = e.target;
    let tempObj = [...ServicesSelected];
    let index = ServicesSelected.findIndex(x => x.srvId === elem.srvId);
    if (index >= 0) {
      tempObj[index].min = value;
    }
    setServicesSelected(tempObj);
  };

  const handleChangeInterpretationCheck = (elem, value) => {
    // let { value } = e.target;
    let tempObj = [...ServicesSelected];
    let index = ServicesSelected.findIndex(x => x.srvId === elem.srvId);
    if (index >= 0) {
      tempObj[index].serviceInterpretation = value;
    }
    setServicesSelected(tempObj);
  };

  const handleChangePrice = (elem, value) => {
    // let { value } = e.target;
    let tempObj = [...ServicesSelected];
    let index = ServicesSelected.findIndex(x => x.srvId === elem.srvId);
    if (index >= 0) {
      tempObj[index].price = value;
    }
    setServicesSelected(tempObj);
  };

  //-------------- delete service 🍕   --------------
  const deleteService = id => {
    let tempServices = deepcopy(ServicesSelected);
    let found = tempServices.filter(x => x.srvId !== id);
    setServicesSelected(found);
    setselectedList(found);
  };
  //-------------- Render Table of Services in second table 🍕   --------------

  const [renderListServices, valorTotal] = useMemo(() => {
    let table = [];
    let valor = 0;

    if (ServicesSelected.length > 0) {
      table = ServicesSelected.map((x, index) => {
        if (!!x?.price && !!x?.qty) {
          valor = Number(x.price) * Number(x.qty) + valor;
        }

        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='px-2'>{x.cupCode}</td>
            <td className='px-2'>
              <input
                style={{ borderColor: '#7FADDE' }}
                type='checkbox'
                name='handleCopay'
                className='border form-check-input p1 check-dark-blue'
                onChange={e => {
                  handleChangeInterpretationCheck(x, e.target.checked);
                }}
                checked={filters.serviceInterpretation}
                disabled={!x.srvReportRequired}
              />
            </td>
            <td className='px-2'>{x.name}</td>
            <td className='col-md-2 text-center px-2'>
              <NumberFormat
                allowNegative={false}
                style={{
                  backgroundColor: 'none',
                  borderWidth: '0px',
                  border: 'none',
                  background: 'none',
                }}
                className={`${IndividualStyles.registerInputs} text-center text-secondary `}
                name='value'
                thousandSeparator={','}
                decimalSeparator={'.'}
                isNumericString={true}
                onValueChange={values => {
                  const { value } = values;
                  handleChangeQty(x, value);
                }}
              ></NumberFormat>
            </td>
            <td className='px-2'>
              <NumberFormat
                allowNegative={false}
                style={{
                  backgroundColor: 'none',
                  borderWidth: '0px',
                  border: 'none',
                  background: 'none',
                }}
                className={`${IndividualStyles.registerInputs} text-center text-secondary `}
                name='value'
                thousandSeparator={','}
                decimalSeparator={'.'}
                isNumericString={true}
                onValueChange={values => {
                  const { value } = values;
                  handleChangeMin(x, value);
                }}
                isAllowed={values => {
                  const { floatValue } = values;
                  if (floatValue > x.qty) {
                    return false;
                  } else {
                    if (Number(floatValue) !== '') {
                      if (floatValue <= 0) {
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }
                }}
              ></NumberFormat>
            </td>
            <td className='px-2'>
              <NumberFormat
                allowNegative={false}
                style={{
                  backgroundColor: 'none',
                  borderWidth: '0px',
                  border: 'none',
                  background: 'none',
                }}
                className={`${IndividualStyles.registerInputs} text-center text-secondary `}
                name='value'
                prefix={'$'}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString={true}
                defaultValue={x.price}
                onValueChange={values => {
                  const { value } = values;
                  handleChangePrice(x, value === '' ? 0 : value);
                }}
                isAllowed={values => {
                  const { floatValue } = values;
                  if (Number(floatValue) !== '') {
                    if (floatValue < 0) {
                      return false;
                    } else {
                      return true;
                    }
                  } else {
                    return true;
                  }
                }}
              ></NumberFormat>
            </td>
            <td>
              <img
                src={Closed}
                alt='closed'
                width={18}
                className={IndividualStyles.pointer}
                onClick={() => deleteService(x.srvId)}
              />
            </td>
          </tr>
        );
      });
    } else {
      table = filters.services.map((x, index) => {
        if (x?.price) {
          valor = Number(x.price) * Number(x.qty) + valor;
        }
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td>{x.cupCode}</td>
            <td className=''>
              <input
                style={{ borderColor: '#7FADDE' }}
                type='checkbox'
                name='handleCopay'
                className='border form-check-input p1 check-dark-blue'
                checked={x.serviceInterpretation}
                disabled={true}
              />
            </td>{' '}
            <td>{x.name}</td>
            <td className='col-md-2 text-center'>{x?.qty ? numberWithCommas(x.qty) : ''}</td>
            <td>{x?.min ? numberWithCommas(x.min) : ''}</td>
            <td>{x?.price ? '$' + numberWithCommas(Number(x.price)) : ''}</td>
          </tr>
        );
      });
    }

    return [table, valor];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ServicesSelected.length,
    filters.services,
    handleChangeMin,
    handleChangeQty,
    handleChangePrice,
  ]);

  //--------------  save services 🍕  --------------

  const saveServices = () => {
    setModalAdd(false);
    let newServices = deepcopy(selectedList);
    setServicesSelected(newServices);
    setFilters2({
      perpage: 10,
      page: 1,
      type: '',
      search: '',
      eaccount: storage.loginReducer.currentAccount.id,
    });
    setTrigger(trigger + 1);
  };

  //--------------   🍕   --------------

  const creatNewContract = () => {
    setLoading(true);
    if (props.clone) {
      dispatch(
        creatContract(
          {
            is_copayment_editable: filters?.is_copayment_editable ? 1 : 0,
            conectivityPrice: filters.conectivityPrice,
            eaccount: storage.loginReducer.currentAccount.id,
            start_date: filters.start_date,
            end_date: filters.end_date,
            client: filters.client,
            contract_name: filters.contract_name,
            contractType: filters.contractType,
            handle_copayment: filters.handle_copayment,
            includes_frame: filters.includes_frame,
            ciiu: filters.ciuu,
            description: filters.description,
            attention_conditions: filters.attention_conditions,
            paymentCond: filters.paymentCond,
            invoiceType: filters.invoiceType,
            filing_date: filters.filing_date,
            notification_email: filters.notification_email,
            trm: filters.trm,
            currency: filters.currency,
            population:
              typeof filters.population === 'string'
                ? JSON.parse(filters.population)
                : filters.population,
            depositType: filters.depositType,
            companyType: filters.companyType,
            services: ServicesSelected.length > 0 ? ServicesSelected : filters.services,
          },
          () => setLoading(false),
          () => props.setDetails({ show: false }),
        ),
      );
    } else {
      dispatch(
        creatContract(
          filters,
          () => setLoading(false),
          () => props.setDetails({ show: false }),
        ),
      );
    }
  };

  //-------------- documents 🍕   --------------
  const docType = data => {
    let iconDoc;
    if (data) {
      let type = data.split('/').pop().split('.')[1];
      switch (type) {
        case 'pdf':
          iconDoc = PDF;
          break;
        case 'document' || 'word' || 'doc':
          iconDoc = Word;
          break;
        case 'sheet' || 'csv' || 'xls':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  const nameDoc = url => {
    let name = url.split('/').pop();
    return name;
  };

  const download = url => {
    window.location.href = url;
  };

  return (
    <>
      {(loaderListServices || loadListTypeCompany || loading) && loader}

      <div className='d-flex flex-column mx-2 p-2 container-fluid mx-auto'>
        <div className='d-flex'>
          <div className='align-self-top mt-5 pt-2 mx-4'>
            <img
              src={backIcon}
              alt='volver'
              width={32}
              height={32}
              className='hoverPointer '
              style={{ marginTop: '1px' }}
              onClick={() => props.setDetails({ show: false })}
            />
          </div>
          <div className='d-flex flex-column w-90'>
            <h1 style={{ paddingLeft: '12px' }} className='mt-5'>
              {props.edit ? 'Nuevo contrato' : filters?.clientName}
            </h1>
            <p className='text-primary'>{props.edit ? '' : 'Contrato ' + filters.sequence}</p>
            <form action={() => alert('Guardando...')}>
              <ul style={{ margin: '0', padding: '0 1rem' }}>
                <li className='text-primary fw-bold'>Cliente Empresa</li>
                <li
                  className='list-unstyled mt-3 p-3'
                  style={{
                    backgroundColor: 'rgba(0, 93, 191, 0.05)',
                    borderRadius: '6px',
                  }}
                >
                  <div className='d-flex gap-2'>
                    <div className='d-flex flex-column col-4'>
                      <label htmlFor='bussinesName' className={styles.labelFont}>
                        Razón social <span className={'text-warning'}>*</span>
                      </label>
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        Name='optionsClients'
                        className={'w-100'}
                        placeholder='Seleccionar...'
                        value={optionsClients.find(item => item.value === filters.client)}
                        options={optionsClients}
                        styles={customSelectNewDark}
                        onChange={e =>
                          setFilters({
                            ...filters,
                            client: e.value,
                          })
                        }
                        isDisabled={!props.edit}
                      />
                    </div>

                    <div className='d-flex flex-column col-4'>
                      <label htmlFor='region' className={styles.labelFont}>
                        Tipo de empresa<span className={'text-warning'}>*</span>
                      </label>
                      <Select
                        placeholder='Seleccionar...'
                        noOptionsMessage={() => 'No hay datos'}
                        components={animatedComponents}
                        className={'w-100'}
                        options={optionsSelect({
                          list: listTypeCompany?.results,
                          label: 'name',
                          value: 'id',
                        })}
                        value={
                          filters.companyType
                            ? {
                                label: filters?.companyTypeName,
                                value: filters?.companyType,
                              }
                            : ''
                        }
                        styles={customSelectNewDark}
                        onChange={e => {
                          if (e?.label?.toLocaleLowerCase() === 'Prepagada'.toLocaleLowerCase()) {
                            setFilters({
                              ...filters,
                              population: [],
                              companyTypeName: e.label,
                              companyType: e.value,
                              handle_copayment: false,
                              is_copayment_editable: false,
                            });
                          } else {
                            setFilters({
                              ...filters,
                              companyTypeName: e.label,
                              companyType: e.value,
                              handle_copayment: false,
                              is_copayment_editable: false,
                            });
                          }

                          if (e?.label?.toLocaleLowerCase() === 'EPS'.toLocaleLowerCase()) {
                            setFilters({
                              ...filters,
                              companyTypeName: e.label,
                              companyType: e.value,
                              handle_copayment: true,
                            });
                          }
                        }}
                        isDisabled={!props.edit}
                      />
                    </div>

                    <div
                      className={`${styles.selectGroupWithOutMargin} col-4`}
                      style={{ paddingRight: '15px' }}
                    >
                      <div className='d-flex flex-column'>
                        <label htmlFor='nit' className={`${styles.labelFont} w-100`}>
                          NIT<span className={'text-warning'}>*</span>
                        </label>
                        <div className={`d-flex align-items-center`}>
                          <input
                            // placeholder="Escribe…"
                            className={`${IndividualStyles.registerInputs} ${styles.outlineNone} textGrayPlaceholder2 pl-1`}
                            style={{ color: '#58595B', height: '24px' }}
                            type='text'
                            onChange={e => {
                              setFilters(state => ({
                                ...state,
                                client: e.target.value,
                              }));
                            }}
                            value={
                              optionsClients?.length > 1
                                ? optionsClients?.find(x => x?.value === filters?.client)?.key
                                : ''
                            }
                            disabled={!props.edit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex' style={{ marginTop: '8px' }}>
                    <div className='d-flex flex-column col-4 flex-6'>
                      <label htmlFor='region' className={styles.labelFont}>
                        Población
                      </label>

                      {filters?.companyTypeName?.toLocaleLowerCase() ===
                      'Prepagada'.toLocaleLowerCase() ? (
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          placeholder='Seleccionar...'
                          Name='optionsRegion'
                          className={'w-100'}
                          options={optionsRegion}
                          value={
                            filters?.population.length > 0 &&
                            typeof JSON.parse(filters?.population)[0] === 'number'
                              ? {
                                  label: optionsRegion?.find(
                                    e => Number(e.value) === JSON.parse(filters?.population)[0],
                                  )?.label,
                                  value: optionsRegion?.find(
                                    e => Number(e.value) === JSON.parse(filters?.population)[0],
                                  )?.value,
                                }
                              : filters?.population.length > 0 &&
                                  typeof JSON.parse(filters?.population)[0] !== 'number'
                                ? {
                                    label: optionsRegion?.find(
                                      e => e.value === filters?.population[0],
                                    )?.label,
                                    value: optionsRegion?.find(
                                      e => e.value === filters?.population[0],
                                    )?.value,
                                  }
                                : ''
                          }
                          styles={customSelectNewDark}
                          onChange={e => {
                            setFilters({
                              ...filters,
                              population: [e.value],
                            });
                          }}
                          components={componentSelect}
                          isClearable={props.edit}
                          openMenuOnClick={props.edit}
                          menuIsOpen={props.edit ? undefined : false}

                          // isDisabled={!props.edit}
                        />
                      ) : (
                        <Select
                          noOptionsMessage={() => 'No hay datos'}
                          isMulti
                          placeholder='Seleccionar...'
                          Name='optionsRegion'
                          // components={animatedComponents}
                          closeMenuOnSelect={false}
                          options={optionsRegion}
                          className={`w-100 text-secondary ${tableStyles.heightScrollSelectApp}`}
                          value={optionsRegion.filter(item =>
                            filters?.population?.includes(item.value),
                          )}
                          styles={customSelectMulti}
                          components={componentSelect}
                          onChange={e => {
                            let arrIdEnd = [];
                            e.forEach(elem => {
                              arrIdEnd?.push(elem?.value);
                            });

                            setFilters({
                              ...filters,
                              population: arrIdEnd,
                            });
                          }}
                          // isDisabled={!props.edit}
                          isClearable={props.edit}
                          openMenuOnClick={props.edit}
                          menuIsOpen={props.edit ? undefined : false}
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className='text-primary fw-bold'>Información general</li>

                <div className='d-flex mt-3'>
                  <div className='d-flex flex-column col'>
                    <label htmlFor='conctactName' className={styles.labelFont}>
                      Nombre del contrato <span className={'text-warning'}>*</span>
                    </label>
                    <input
                      type='text'
                      name='conctactName'
                      className={`${IndividualStyles.registerInputs} ${styles.outlineNone} pl-1`}
                      placeholder='Escribe...'
                      style={{ color: '#58595B' }}
                      value={filters.contract_name}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          contract_name: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col mx-3'>
                    <label htmlFor='contractType' className={styles.labelFont}>
                      Tipo de contrato <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      Name='optionsContracts'
                      className={'w-100'}
                      placeholder='Seleccionar...'
                      value={optionsContracts.find(item => item.value === filters.contractType)}
                      options={optionsContracts}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          contractType: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col'>
                    <div className='d-flex mt-3 pt-2'>
                      <input
                        style={{ borderColor: '#7FADDE' }}
                        type='checkbox'
                        name='handleCopay'
                        className='border form-check-input p1 check-dark-blue'
                        onChange={() =>
                          setFilters({
                            ...filters,
                            handle_copayment: !filters.handle_copayment,
                            is_copayment_editable: false,
                          })
                        }
                        checked={filters.handle_copayment}
                        disabled={!props.edit || filters.companyTypeName === 'EPS'}
                      />
                      <label htmlFor='handleCopay' className={`mx-2 ${styles.parragraphColor}`}>
                        Cuota moderadora/Copago
                        <span className={'text-warning'}>*</span>
                      </label>
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip('Manejará copago')}
                      >
                        <img
                          style={{ 'vertical-align': 'top' }}
                          src={question}
                          alt="'question"
                          width={12}
                        ></img>
                      </OverlayTrigger>
                    </div>
                  </div>

                  {filters.handle_copayment ? (
                    <div className='d-flex flex-column col-2'>
                      <div className='d-flex mt-3 pt-2'>
                        <input
                          style={{ borderColor: '#7FADDE' }}
                          type='checkbox'
                          name='is_copayment_editable'
                          className='border form-check-input p1 check-dark-blue'
                          onChange={() =>
                            setFilters({
                              ...filters,
                              is_copayment_editable: !filters.is_copayment_editable,
                            })
                          }
                          checked={filters.is_copayment_editable}
                          disabled={!props.edit}
                        />
                        <label
                          htmlFor='is_copayment_editable'
                          className={`mx-2 ${styles.parragraphColor}`}
                        >
                          Copago editable
                        </label>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Manejará copago')}
                        >
                          <img
                            style={{ 'vertical-align': 'top' }}
                            src={question}
                            alt="'question"
                            width={12}
                          ></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex flex-column col-2'>
                      <div className='d-flex mt-3 pt-2'>
                        <input
                          checked={filters.includes_frame}
                          className='border form-check-input p1 check-dark-blue'
                          disabled={!props.edit}
                          name='includes_frame'
                          style={{ borderColor: '#7FADDE' }}
                          type='checkbox'
                          onChange={() =>
                            setFilters({
                              ...filters,
                              includes_frame: !filters.includes_frame,
                            })
                          }
                        />
                        <label
                          htmlFor='includes_frame'
                          className={`mx-2 ${styles.parragraphColor}`}
                        >
                          Incluye montura
                        </label>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Incluye montura')}
                          style={{ 'vertical-align': 'top' }}
                        >
                          <img alt='question' src={question} width={12} />
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-flex mt-3'>
                  {filters.handle_copayment ? (
                    <div className='d-flex flex-column col-2 me-3'>
                      <div className='d-flex mt-3 pt-2'>
                        <input
                          checked={filters.includes_frame}
                          className='border form-check-input p1 check-dark-blue'
                          disabled={!props.edit}
                          name='includes_frame'
                          style={{ borderColor: '#7FADDE' }}
                          type='checkbox'
                          onChange={() =>
                            setFilters({
                              ...filters,
                              includes_frame: !filters.includes_frame,
                            })
                          }
                        />
                        <label
                          htmlFor='includes_frame'
                          className={`mx-2 ${styles.parragraphColor}`}
                        >
                          Incluye montura
                        </label>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Incluye montura')}
                          style={{ 'vertical-align': 'top' }}
                        >
                          <img alt='question' src={question} width={12} />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='d-flex flex-column col-6'>
                    <label htmlFor='ciiu' className={styles.labelFont}>
                      Código CIIU <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      placeholder='Seleccionar...'
                      Name='optionsCiiu'
                      className={`w-100 ${styles.labelFont2}`}
                      value={optionsCIIU.find(item => Number(item.value) === Number(filters.ciuu))}
                      options={optionsCIIU}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          ciuu: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col mx-3'>
                    <label htmlFor='startDate' className={styles.labelFont}>
                      Fecha de inicio <span className={'text-warning'}>*</span>
                    </label>
                    <input
                      type='date'
                      name='startDate'
                      className={IndividualStyles.registerInputsBlue}
                      value={filters.start_date}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          start_date: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col'>
                    <label htmlFor='dueDate' className={styles.labelFont}>
                      Fecha de vencimiento <span className={'text-warning'}>*</span>
                    </label>
                    <input
                      type='date'
                      name='dueDate'
                      className={IndividualStyles.registerInputsBlue}
                      value={filters.end_date}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          end_date: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                      min={filters?.start_date ? filters.start_date : ''}
                    />
                  </div>
                </div>

                <div className='d-flex mt-3'>
                  <div className='d-flex flex-column col-6 mr-3'>
                    <label htmlFor='contractDescription' className={styles.labelFont}>
                      Descripción del contrato <span className={'text-warning'}>*</span>
                    </label>
                    <textarea
                      style={{ borderColor: '#7FADDE', fontSize: '12px' }}
                      name='contractDescription'
                      className={`${styles.textArea} ${styles.labelFont2} ${styles.outlineNone} pl-1`}
                      value={filters.description}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          description: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                    />
                  </div>
                  <div className='d-flex flex-column col'>
                    <label htmlFor='clientConditions' className={styles.labelFont}>
                      Condiciones de atención a clientes <span className={'text-warning'}>*</span>
                    </label>
                    <textarea
                      style={{ borderColor: '#7FADDE', fontSize: '12px' }}
                      name='clientConditions'
                      className={`${styles.textArea} ${styles.labelFont2} ${styles.outlineNone} pl-1`}
                      value={filters.attention_conditions}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          attention_conditions: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                    />
                  </div>
                </div>

                <li className='text-primary fw-bold mt-3'>Facturación de contrato</li>
                <div className='d-flex mt-3'>
                  <div className='d-flex flex-column col-6'>
                    <label htmlFor='payConditions' className={styles.labelFont}>
                      Condición de pago <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      Name='optionsConditions'
                      className={'w-100'}
                      value={optionsPayC.find(item => item.value === filters.paymentCond)}
                      options={optionsPayC}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          paymentCond: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col mx-3'>
                    <label htmlFor='payMethod' className={styles.labelFont}>
                      Método de pago <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      placeholder='Seleccionar...'
                      Name='optionsConditions'
                      className={'w-100'}
                      value={optionsPayM.find(item => item.value === filters.depositType)}
                      options={optionsPayM}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          depositType: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col'>
                    <label htmlFor='filingDate' className={styles.labelFont}>
                      Día límite de radicación <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      placeholder='Seleccionar...'
                      Name='optionsDays'
                      className={'w-100'}
                      value={optionsDays.find(item => item.value === Number(filters.filing_date))}
                      options={optionsDays}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          filing_date: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>
                </div>

                <div className='d-flex mt-3 gap-2'>
                  <div className='d-flex flex-column col-3'>
                    <label htmlFor='email' className={styles.labelFont}>
                      Correo de notificación <span className={'text-warning'}>*</span>
                    </label>
                    <input
                      type='text'
                      name='email'
                      placeholder='correo@correo.com'
                      className={`${IndividualStyles.registerInputs} ${styles.outlineNone} pl-1 textGrayPlaceholder2`}
                      style={{ color: '#58595b' }}
                      value={filters.notification_email}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          notification_email: e.target.value,
                        })
                      }
                      disabled={!props.edit}
                    />
                  </div>

                  <div className='d-flex flex-column col-3' style={{ paddingRight: '8px' }}>
                    <label htmlFor='exchangeRate' className={styles.labelFont}>
                      Tasa de cambio
                    </label>
                    <NumberFormat
                      allowNegative={false}
                      style={{ padding: 0, color: '#58595B' }}
                      className={`${IndividualStyles.registerInputs} textGrayPlaceholder2`}
                      name='valueNote'
                      placeholder='$5.000'
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      isNumericString={true}
                      prefix={'$'}
                      value={filters.trm}
                      onValueChange={values => {
                        const { value } = values;
                        setFilters({
                          ...filters,
                          trm: value,
                        });
                      }}
                      min={1}
                      disabled={!props.edit}
                    ></NumberFormat>
                  </div>

                  <div className='d-flex flex-column col-3' style={{ paddingRight: '14px' }}>
                    <label htmlFor='currency' className={styles.labelFont}>
                      Divisa <span className={'text-warning'}>*</span>
                    </label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      Name='optionsConditions'
                      placeholder='Seleccionar...'
                      className={'w-100'}
                      value={optionsDivisa.find(item => item.value === filters.currency)}
                      options={optionsDivisa}
                      styles={customSelectNewDark}
                      onChange={e =>
                        setFilters({
                          ...filters,
                          currency: e.value,
                        })
                      }
                      isDisabled={!props.edit}
                    />
                  </div>

                  <div className={`col-3`} style={{ paddingRight: '16px', marginLeft: '-7px' }}>
                    <div className='d-flex flex-column'>
                      <label htmlFor='conectivityPrice' className={styles.labelFont}>
                        Costo de conectividad <span className={'text-warning'}>*</span>
                      </label>

                      <NumberFormat
                        allowNegative={false}
                        style={{ padding: 0, color: '#58595B' }}
                        className={`${IndividualStyles.registerInputs} textGrayPlaceholder2`}
                        name='conectivityPrice'
                        placeholder='$5.000'
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        isNumericString={true}
                        prefix={'$'}
                        value={filters.conectivityPrice}
                        onValueChange={values => {
                          const { value } = values;

                          setFilters({
                            ...filters,
                            conectivityPrice: value,
                          });
                        }}
                        min={1}
                        disabled={!props.edit}
                      ></NumberFormat>
                    </div>
                  </div>
                </div>
                <li className='text-primary fw-bold mt-3'>Servicios relacionados</li>
                <GenericTableNew
                  dark={true}
                  footer={
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td className='text-primary fw-bold text-uppercase'>TOTALES</td>
                      <td className='text-primary fw-bold text-uppercase'>
                        {'$' + numberWithCommas(valorTotal)}
                      </td>
                    </tr>
                  }
                  headers={[
                    <th key={1} className='text-center'>
                      Código
                    </th>,
                    <th key={2} className='text-center'>
                      Interpretación de ADX
                    </th>,
                    <th key={3} className='text-center'>
                      Nombre del servicio
                    </th>,
                    <th key={4} className='text-center'>
                      Cant. acordada
                    </th>,
                    <th key={5} className='text-center'>
                      Acuerdo mínimo
                    </th>,
                    <th key={6} className='text-center'>
                      Valor
                    </th>,
                    <th key={7}> &nbsp; </th>,
                  ]}
                >
                  {renderListServices}
                </GenericTableNew>
                {props.sign ? (
                  <div className={styles.containerRow}>
                    <div>
                      <div
                        className='col-auto text-center'
                        onClick={() =>
                          filters.signature_url ? download(filters.signature_url) : null
                        }
                      >
                        <div
                          style={{
                            padding: '3%',
                            border: 'solid 1px #005DBF',
                            borderRadius: '10px',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div>
                            {filters.signature_url !== null && filters.signature_url !== '' && (
                              <img alt='icon' className='' src={docType(filters.signature_url)} />
                            )}
                          </div>

                          <div title={'Contrato EPS SURA Serv'}>
                            <p className={`pt-1 ${IndividualStyles.ellipsisNew}`}>
                              {filters.signature_url ? nameDoc(filters.signature_url) : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                      <h6
                        style={{
                          color: 'rgba(88, 89, 91, 1)',
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          paddingTop: '0.3rem',
                          marginTop: '0.5rem',
                          borderTop: '1.5px solid rgba(88, 89, 91, 1)',
                        }}
                      >
                        Contrato firmado
                      </h6>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {props.clone && props.edit ? (
                  <img
                    src={AddBlue}
                    width={24}
                    alt='Agregar'
                    className='hoverPointer'
                    onClick={() => setModalAdd(true)}
                  />
                ) : (
                  ''
                )}
              </ul>
              <div className={IndividualStyles.bottomRow}>
                {props.edit ? (
                  <Button
                    className={`${tableStyles.buttonExtra3} d-block`}
                    onClick={() => creatNewContract()}
                  >
                    Guardar
                  </Button>
                ) : (
                  ''
                )}
                <Button
                  className={tableStyles.buttonExtra1}
                  onClick={() => props.setDetails({ show: false })}
                >
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ModalNew
        show={modalAdd}
        title='Servicios'
        subtitle='Selecciona los servicios asociados al contrato'
        size='620'
        btnYesEvent={() => saveServices()}
        btnNoEvent={() => setModalAdd(false)}
        onHide={() => {
          setModalAdd(false);
          setFilters2({
            perpage: 10,
            page: 1,
            type: '',
            search: '',
            eaccount: storage.loginReducer.currentAccount.id,
          });
          setTrigger(trigger + 1);
        }}
      >
        <div className='d-flex align-items-end gap-2'>
          <div className='d-flex flex-column' style={{ flex: '0.5' }}>
            <label htmlFor='serviceType' className={styles.labelFont}>
              Tipo
            </label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              Name='optionsRegion'
              className={'w-100'}
              placeholder='Seleccionar...'
              options={listServices}
              styles={ordCustomSelect}
              onChange={e => {
                setFilters2({
                  ...filters2,
                  type: e.value,
                });

                setLoading(true);
                setTrigger(trigger + 1);
              }}
              isDisabled={!props.edit}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  setLoading(true);
                  setTrigger(trigger + 1);
                }
              }}
            />
          </div>

          <input
            type='text'
            name='search'
            placeholder='Filtrar'
            className={`${styles.inputTextClear} ${styles.outlineNone} px-2`}
            style={{ color: '#58595B', borderColor: '#A3BAD1', height: '32px' }}
            value={filters2.search}
            onChange={e => {
              setFilters2({
                ...filters2,
                search: e.target.value,
                page: 1,
              });
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setLoading(true);
                setTrigger(trigger + 1);
              }
            }}
          />

          <img
            src={lupaClear}
            width={25}
            height={32}
            className='pointer'
            alt='Filtrar'
            onClick={() => {
              setLoading(true);
              setTrigger(trigger + 1);
            }}
          />
        </div>

        <GenericTableNew
          headers={[
            <th key={1} className='text-center px-2'>
              Código
            </th>,
            <th key={2} className='text-center px-2'>
              Nombre
            </th>,
            <th key={3} className='px-2 text-center'>
              Tipo
            </th>,
            <th key={4} className='px-2'>
              <div className='d-flex align-items-center justify-content-center'>
                <input
                  type='checkbox'
                  name='selectdService'
                  id=''
                  checked={isBoxAllSelected()}
                  onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
                />
              </div>
            </th>,
          ]}
        >
          {renderList()}
        </GenericTableNew>
        {/* ----- pagination #️⃣ ----- */}
        <div className={IndividualStyles.bottom}>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {storage.billingReducer.services_ADM ? filters2.page : ''}
              {' de '}
              {Math.ceil(storage.billingReducer?.services_ADM_total / filters2.perpage)
                ? Math.ceil(storage.billingReducer.services_ADM_total / filters2.perpage)
                : ''}{' '}
              ({storage.billingReducer.services_ADM_total} encontrados)
            </p>
            <Pagination
              activePage={filters2.page}
              itemsCountPerPage={10}
              totalItemsCount={Number(storage.billingReducer.services_ADM_total)}
              pageRangeDisplayed={5}
              onChange={e => setFilters2({ ...filters2, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </ModalNew>
    </>
  );
}

export default NewContract;
