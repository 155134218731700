/* eslint-disable react-hooks/exhaustive-deps */
// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useEffect, useState } from 'react';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../Layouts/ModalNew';
import { useDispatch, useSelector } from 'react-redux';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import { Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';
import Agregar from '../../assets/img/icons/add-check.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

//-------------- scss styles 😄   --------------
import Select from 'react-select';
import { customSelectNew, customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

// ------------------ Functions ----------------------
import {
  getListReteICA,
  getListReteICARate,
  creatReteica,
  updateReteica,
  changeStatusActivity,
  getOneReteICA,
} from '../../actions/accountingActions';
import { city_get, province_get_all } from '../../actions/locationActions';

import { getCIIU } from '../../actions/corporateClientsActions';
import { Title } from '../../shared';

function ParameterRETEICA() {
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [triggerSelect, setTriggerSelect] = useState(1);
  const [triggerModal, setTriggerModal] = useState(1);
  const [showModal, setShowModal] = useState({
    show: false,
  });
  const [creatActivity, setCreatActivity] = useState({
    province: '',
    city: '',
    ciiu: '',
    rate: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const [info, setInfo] = useState({
    province: '',
    city: '',
    search: '',
    eaccount: storage.loginReducer.currentAccount.id,
  });

  const myPermission = () =>
    storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'ReteICAPar',
    );

  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (!myPermission()?.create || !myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
  }, []);

  useEffect(() => {
    dispatch(province_get_all());
    dispatch(getCIIU());
    dispatch(getListReteICARate({ eaccount: storage.loginReducer.currentAccount.id }));
  }, []);

  useEffect(() => {
    if (creatActivity.province !== '') {
      dispatch(
        city_get({ province: Number(creatActivity.province) }, () => {
          setTriggerSelect(triggerSelect + 1);
        }),
      );
    }
  }, [creatActivity.province]);

  useEffect(() => {
    if (info.province !== '') {
      dispatch(
        city_get({ province: Number(info.province) }, () => {
          setTriggerSelect(triggerSelect + 1);
        }),
      );
    }
  }, [info.province]);

  useEffect(() => {
    dispatch(getListReteICA(info));
  }, [trigger, info.province, info.city]);

  useEffect(() => {
    if (showModal.selectedReteICA) {
      dispatch(
        getOneReteICA(
          {
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedReteICA,
          },
          setCreatActivity,
        ),
      );
    }
  }, [triggerModal]);

  //-------------- Table 🍕   --------------

  const header = [
    <div key={1} className={`col-2 text-start px-2`}>
      Código
    </div>,
    <div key={2} className={`col-2 text-start`}>
      Actividad económica
    </div>,
    <div key={3} className={`col-2 text-start`}>
      Departamento
    </div>,
    <div key={4} className={`col-2 text-start`}>
      Ciudad
    </div>,
    <div key={5} className={`col-2`}>
      Tarifa por mil
    </div>,
    <div key={6} className={`col-1`}>
      Estado
    </div>,
    <div key={7} className={`col-1`}>
      &nbsp;
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.listReteica)) {
      storage.accountingReducer.listReteica.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className='col-2 text-start px-2'>{item.code}</div>
            <div className='col-2 text-start'>{item.name}</div>
            <div className='col-2 text-start'>{item.provinceName}</div>
            <div className='col-2 text-start'>{item.cityName}</div>
            <div className='col-2'>{item.rateName}</div>
            <div className='col-1'>
              <Tooltip title={item.statusName} arrow>
                <div
                  className='rounded-pill p-1'
                  style={{
                    backgroundColor: item.statusName !== 'Deshabilitado' ? '#FAFDF6' : '#FEF7F5',
                  }}
                >
                  <b style={{ color: item.statusName !== 'Deshabilitado' ? '#83C036' : '#F39682' }}>
                    {item.statusName !== 'Deshabilitado' ? 'Habilitado' : 'Inhabilitado'}
                  </b>
                </div>
              </Tooltip>
            </div>
            <div className='col-1'>
              <CustomPopupExtend
                noHover
                triggerSrc={threeDots}
                showEnable={myPermission()?.delete}
                isEnabled={item.statusName === 'Deshabilitado' ? false : true}
                enableClickEvent={() => changeStatus(item.id, item)}
                enableText={item.statusName === 'Deshabilitado' ? 'Habilitar' : 'Deshabilitar'}
                showEdit={myPermission()?.edit}
                editClickEvent={() => {
                  setShowModal({ show: true, edit: true, selectedReteICA: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                showDetails={true}
                showDetailsEvent={() => {
                  setShowModal({ show: true, creat: false, selectedReteICA: item.id });
                  setTriggerModal(triggerModal + 1);
                }}
                textDetails={'Ver detalle'}
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  //-------------- select options 🍕   --------------

  let optionsCity = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.locationReducer.cities)) {
    storage.locationReducer.cities.forEach((item, index) => {
      optionsCity.push({
        value: item.id,
        label: item.name,
        key: index + 'city',
      });
    });
  }

  let provincesOptions = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.locationReducer.allProvinces)) {
    storage.locationReducer.allProvinces.forEach(item => {
      provincesOptions.push({
        value: item.id,
        label: item.name,
        name: 'id_province',
      });
    });
  }

  let optionsCiiu = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.corporateclientsReducer.ciiu)) {
    storage.corporateclientsReducer.ciiu.forEach((item, index) => {
      optionsCiiu.push({
        value: item.id,
        label: item.description,
        key: index + 'ciiu',
      });
    });
  }

  let optionsReteICA = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.accountingReducer.listReteicaRate)) {
    storage.accountingReducer.listReteicaRate.forEach((item, index) => {
      optionsReteICA.push({
        value: item.id,
        label: item.name,
        key: index + 'rate',
      });
    });
  }

  //--------------  Actions  🍕   --------------

  const creatNewActivity = () => {
    if (showModal.creat) {
      dispatch(
        creatReteica(
          {
            province: creatActivity.province,
            city: creatActivity.city,
            ciiu: creatActivity.ciiu,
            rate: creatActivity.rate,
            eaccount: storage.loginReducer.currentAccount.id,
          },
          () => {
            setShowModal({ show: false });
            dispatch(getListReteICA({ eaccount: storage.loginReducer.currentAccount.id }));
            setCreatActivity({
              city: '',
              ciiu: '',
              rate: '',
              eaccount: storage.loginReducer.currentAccount.id,
              province: '',
            });
          },
        ),
      );
    } else {
      dispatch(
        updateReteica(
          {
            city: creatActivity.city,
            ciiu: creatActivity.ciiu,
            rate: creatActivity.rate,
            eaccount: storage.loginReducer.currentAccount.id,
            id: showModal.selectedReteICA,
          },
          () => {
            setShowModal({ show: false });
            dispatch(getListReteICA({ eaccount: storage.loginReducer.currentAccount.id }));
            setCreatActivity({
              city: '',
              ciiu: '',
              rate: '',
              eaccount: storage.loginReducer.currentAccount.id,
            });
          },
        ),
      );
    }
  };

  const changeStatus = (id_activity, elem) => {
    dispatch(
      changeStatusActivity(
        { eaccount: storage.loginReducer.currentAccount.id, id: id_activity, elem },
        () => {
          dispatch(getListReteICA({ eaccount: storage.loginReducer.currentAccount.id }));
        },
      ),
    );
  };
  const handleSumit = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {storage.accountingReducer.reteica_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={tableStyles.title}>
          <Title
            title='Parametrización ReteICA'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        <Row className='d-flex'>
          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Departamento</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                options={provincesOptions}
                styles={customSelectNewDark}
                onChange={e => {
                  setInfo({
                    ...info,
                    province: e.value,
                  });
                  setTrigger(trigger + 1);
                }}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div className='display-grid mt-1'>
              <label className={tableStyles.crudModalLabel}>Ciudad</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'city' + triggerSelect}
                placeholder='Seleccionar...'
                options={optionsCity}
                styles={customSelectNewDark}
                onChange={e => {
                  setInfo({
                    ...info,
                    city: e.value,
                  });
                  setTrigger(trigger + 1);
                }}
              />
            </div>
          </Col>
          <Col xs={4}>
            <div className='display-grid mt-1'>
              <label className={`${tableStyles.crudModalLabel}`}>ㅤ</label>
              <form onSubmit={handleSumit}>
                <input
                  className={tableStyles.SearchNew}
                  placeholder='Buscar...'
                  type='text'
                  onChange={e =>
                    setInfo({
                      ...info,
                      search: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                ></input>
              </form>
            </div>
          </Col>
          <Col xs={1}>
            <div className='display-grid mt-1'>
              <label className={`${tableStyles.crudModalLabel}`}>ㅤ</label>
              <i>
                <img
                  style={{ cursor: 'pointer' }}
                  src={Lupa}
                  alt='icon'
                  height='24px'
                  onClick={() => setTrigger(trigger + 1)}
                />
              </i>
            </div>
          </Col>
          {myPermission()?.create ? (
            <Col
              xs={3}
              className='text-end d-flex justify-content-end align-items-end'
              // onClick={() => setShowModal({ show: true, creat: true })}
            >
              <div
                onClick={() => setShowModal({ show: true, creat: true })}
                className={tableStyles.createNomModule}
              >
                <b className={`mr-2`}>Crear tarifa</b>
                <img src={Agregar} alt='User' />
                <div></div>
              </div>
              {/* <label className={Styles.bgBtn} style={{ marginTop: "18px", cursor: "pointer", padding: "5px" }}>Crear Actividad
                                <img
                                    className="ml-3 "
                                    style={{ width: "18px", marginTop: "-4px" }}
                                    alt="icon"
                                    src={Agregar} />
                            </label> */}
            </Col>
          ) : (
            ''
          )}
        </Row>
        <div>
          <ModalNew
            show={showModal.show}
            onHide={() => {
              setShowModal({ show: false });
              setCreatActivity({
                province: '',
                city: '',
                ciiu: '',
                rate: '',
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
            title={showModal.creat ? 'Crear tarifa' : 'Editar tarifa'}
            btnYesEvent={showModal.creat || showModal.edit ? () => creatNewActivity() : null}
            btnYesName={showModal.creat ? 'Guardar' : 'Editar'}
            btnYesDisabled={
              !!(creatActivity.province === '') ||
              !!(creatActivity.city === '') ||
              !!(creatActivity.ciiu === '') ||
              !!(creatActivity.rate === '')
            }
            size={'500'}
            btnNoEvent={() => {
              setShowModal({ show: false });
              setCreatActivity({
                province: '',
                city: '',
                ciiu: '',
                rate: '',
                eaccount: storage.loginReducer.currentAccount.id,
              });
            }}
          >
            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Actividad económica<span className={'text-danger'}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'code' + trigger}
                  options={optionsCiiu}
                  value={optionsCiiu.find(item => item.value === Number(creatActivity.ciiu))}
                  styles={customSelectNew}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      ciiu: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Departamento<span className={'text-danger'}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'dept2' + trigger}
                  options={provincesOptions}
                  styles={customSelectNew}
                  value={provincesOptions.find(
                    item => item.value === Number(creatActivity.province),
                  )}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      province: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Ciudad<span className={'text-danger'}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'city2' + triggerSelect}
                  options={optionsCity}
                  styles={customSelectNew}
                  value={optionsCity.find(item => item.value === Number(creatActivity.city))}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      city: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>

            <Row className='d-flex'>
              <Col xs={12}>
                <p className={tableStyles.crudModalLabel}>
                  Tarifa por mil del ReteICA<span className={'text-danger'}>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  placeholder='Seleccionar...'
                  key={'rate' + trigger}
                  options={optionsReteICA}
                  value={optionsReteICA.find(item => item.value === creatActivity.rate)}
                  styles={customSelectNew}
                  onChange={e =>
                    setCreatActivity({
                      ...creatActivity,
                      rate: e.value,
                    })
                  }
                  isDisabled={!(showModal.edit || showModal.creat)}
                />
              </Col>
            </Row>
          </ModalNew>
        </div>
        <GenericTableScroll headers={header} dark={true} body={renderList()} typeHead={'2'}>
          {/* {renderList()} */}
        </GenericTableScroll>
        {/* <div className={paginationStyles.wrapper}>
                    <p className={paginationStyles.paginationText}>
                        Pag. {counter.InvoiceReceiptReducer.total ? filters.page : ""}
                        {" de "}
                        {Math.ceil(counter.InvoiceReceiptReducer.total / filters.perpage) ?
                            Math.ceil(counter.InvoiceReceiptReducer.total / filters.perpage)
                            :
                            ""
                        }
                        {" "}
                        ({counter.InvoiceReceiptReducer.total} encontrados)
                    </p>
                    <Pagination
                        activePage={filters.page}
                        itemsCountPerPage={filters.perpage}
                        totalItemsCount={counter.InvoiceReceiptReducer.total}
                        pageRangeDisplayed={5}
                        onChange={ (e) => setFilters({...filters, page: e}) }
                        itemClassPrev={`${paginationStyles.itemClassPrev}`}
                        itemClassNext={`${paginationStyles.itemClassNext} `}
                        itemClassFirst={`${paginationStyles.itemClassFirst} `}
                        itemClassLast={`${paginationStyles.itemClassLast} `}
                        itemClass={paginationStyles.itemClass}
                    />
                </div> */}
      </div>
    </>
  );
}
export default ParameterRETEICA;
