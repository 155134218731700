import {
  GET_SELECT_STATUS,
  GET_WORK_ACCIDENTS,
  LOADING,
} from "../actions/actionTypes";

import {
  GET_INFO_PAYROLL,
  GET_PERIODS_PAYROLL,
  GET_ENDOWNMENT_GROUPS,
  CREATE_ENDOWNMENT_GROUPS,
  GET_ENDOWNMENT_ARTICLES,
  GET_ENDOWNMENT_DELIVERIES,
  POST_ENDOWNMENT_DELIVERIES,
  GET_ENDOWNMENT_DELIVERIES_PDF,
  GET_WORK_ACCIDENT_DETAILS
} from "../actions/actionTypes";

const initialState = {
  infoPayroll: [],
  listPeriodsPayroll: [],
  endownment_groups: [],
  endownment_total: 0,
  endownment_loading: false,
  endownment_articles: [],
  endowment_deliveries: [],
  endowment_deliveries_total: 0,
  endowment_deliveries_pdf: {},
  loading: false,
  status: [],
  accidents:[],
  rowTotalAccidents:0,
  accidentDetails:{}

};

export const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFO_PAYROLL:
      return {
        ...state,
        infoPayroll: action.payload,
      };
    case GET_PERIODS_PAYROLL:
      return {
        ...state,
        listPeriodsPayroll: action.payload,
      };

    case GET_ENDOWNMENT_GROUPS:
      return {
        ...state,
        endownment_groups: action.payload,
        endownment_total: action.total,
        endownment_loading: action.loading,
      };
    case CREATE_ENDOWNMENT_GROUPS:
      return {
        ...state,
        endownment_loading: action.loading,
      };

    case GET_ENDOWNMENT_ARTICLES:
      return {
        ...state,
        endownment_articles: action.payload,
      };
    case GET_ENDOWNMENT_DELIVERIES:
      return {
        ...state,
        endowment_deliveries: action.payload,
        endowment_deliveries_total: action.total,
        endownment_loading: action.loading,
      };
    case POST_ENDOWNMENT_DELIVERIES:
      return {
        ...state,
        endownment_loading: action.loading,
      };

    case GET_ENDOWNMENT_DELIVERIES_PDF:
      return {
        ...state,
        endowment_deliveries_pdf: action.payload,
      };

    case LOADING:
      return { ...state, loading: action.payload };

    case GET_SELECT_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case GET_WORK_ACCIDENTS:
      return {
        ...state,
        accidents: action.payload,
        rowTotalAccidents: action.row_total,
      };
    case GET_WORK_ACCIDENT_DETAILS:
      return {
        ...state,
        accidentDetails:action.payload
      }
    default:
      return state;
  }
};
