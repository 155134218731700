//Login's reducer

import {
    COUNT_USERS,
    GET_USERS,
    SET_INDEX_USER, 
    CREATE_USER, 
    SET_INDEX_FILTER_USER, 
    SAVE_USER_INFO,
    LOGOUT_SUCCESS,
    SET_LOADING_USER, GET_ALL_USERS,
    GET_ALL_USERS_NEW,
    LOADING,
    SITE_SELECTED
  } from "../actions/actionTypes";
  
    
  const initialState = {
   users: [],
   totalUser:0,
   user_data: {},
   allUsers: [],
   totalUserFiltered: 0,
   displayError: false,
   error: null,
   Authorization:"",
   loading:false,
   countusers:{
     totalusers: 0, 
     total_active:0
    },
    site: null,
    siteName: "",
    newAllUsers: []

  };
  export const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_USER_INFO:
        return {
          ...state,
          user_data: action.payload
        };
      case GET_USERS:
        return {
          ...state,
          users: action.payload,
          totalUser: action.row_total,
          Authorization:action.Authorization
        };
        case GET_ALL_USERS:
          return {
            ...state,
            allUsers: action.payload,
            Authorization:action.Authorization
          };
        case GET_ALL_USERS_NEW:
          return {
            ...state,
            newAllUsers: action.payload,
            Authorization:action.Authorization
          };
      case SET_INDEX_USER:
        return {
          ...state,
          users: action.users,
          totalUser: action.payload,
          displayError: false,
          error: null,
          Authorization:action.Authorization
        };
        case SET_INDEX_FILTER_USER:
        return {
          ...state,
          totalUserFiltered: action.payload,
          displayError: false,
          error: null,
          Authorization:action.Authorization
        };

        case CREATE_USER:
        return {
          ...state,
          displayError: false,
          error: null,
          token:action.token
        };
      case LOGOUT_SUCCESS:
          return {
            user_data: {},
            users: [],
            totalUser:0,
            displayError: false,
            error: null,
            Authorization:""
          };

      case COUNT_USERS:
        return {
          ...state,
          countusers: action.payload,
        };
        
      case SET_LOADING_USER:
        return {
          ...state,
          loading: action.loading,
        };

      case LOADING:
        return {
          ...state,
          loading:action.payload
        }
      case SITE_SELECTED:
        return {
          ...state,
          site:action.payload.siteId , 
          siteName: action.payload.siteName,
          resolutions: action.payload.resolutions ?? []
        }

      default:
        return state;
    }
  };
  