import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import ReactSelectMulti from 'react-select/creatable';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
//
import IconBack from '../../../../assets/img/icons/iconBack.svg';
import { customSwaltAlert, generateId, loader } from '../../../../helpers';
import { useGetMethod, usePostMethod } from '../../../../Hooks';
//
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../../../components/Layouts/react-select-custom';

export default function CreateRips() {
  const history = useHistory();
  const store = useSelector(state => state);
  const location = useLocation();

  const { trigger: getCompanies, results: companiesSelect } = useGetMethod();
  const { trigger: getDetail } = useGetMethod();
  const { trigger: getContracts } = useGetMethod();
  const { trigger: getVariables, results: variables } = useGetMethod();

  const { trigger: createRips, load: createRipsLoader } = usePostMethod();

  const [ripsPayload, setRipsPayload] = useState({
    ripsType: '',
    name: '',
    validUntil: '',
    description: '',
    eaccount: store.loginReducer.currentAccount.id,
    created_by: store.loginReducer.user_data.id,
    companies: [
      {
        index: generateId(),
        company: '',
        contracts: [],
        contractsList: [],
      },
    ],
    ripsData: [
      {
        index: generateId(),
        dataId: '',
        description: '',
      },
    ],
  });

  useEffect(() => {
    async function fetchAsyncData() {
      if (location.state?.id) {
        const result = await getDetail({
          url: '/receivable/rips/getDetail/',
          objFilters: {
            id: location.state?.id,
          },
          token: store.loginReducer.Authorization,
        });
        if (result.results) {
          const rip = result.results[0];
          const companies = await Promise.all(
            rip.rip_companies_info?.map(async el => {
              const response = await getContracts({
                url: '/receivable/contract/',
                objFilters: {
                  client: el.company.id,
                  status: 'enabled',
                },
                token: store.loginReducer.Authorization,
              });
              const formattedContracts =
                response.results?.map(con => ({
                  value: con.id,
                  label: con.contract_name,
                })) || [];

              return {
                index: generateId(),
                company: el.company.id,
                contracts: el.contracts?.map(el => el.id),
                contractsList: formattedContracts,
              };
            }),
          );
          setRipsPayload(prev => ({
            ...prev,
            id: location.state?.id,
            description: rip.description,
            name: rip.name,
            ripsType: rip.rip_type,
            companies: companies,
            validUntil: rip.valid_until,
            ripsData: rip.rips_info?.map(el => ({
              index: generateId(),
              dataId: el.data_rip_id,
              description: el.data_rip_descriptions,
            })),
          }));
        }
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getDetail, getContracts, location.state?.id, store.loginReducer.Authorization]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getCompanies({
        url: '/receivable/client/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getCompanies, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getVariables({
        url: '/receivable/ripData/',
        objFilters: {
          page: 1,
          perpage: 99,
          eaccount: store.loginReducer.currentAccount.id,
          status: 1,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getVariables, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  const onAddNewCompany = () => {
    const options = [
      ...ripsPayload.companies,
      {
        index: generateId(),
        company: '',
        contracts: [],
      },
    ];
    setRipsPayload({ ...ripsPayload, companies: options });
  };

  const onDeleteCompany = index => {
    const options = ripsPayload.companies.filter(el => el.index !== index);
    setRipsPayload({ ...ripsPayload, companies: options });
  };

  const onAddNewRip = () => {
    const options = [
      ...ripsPayload.ripsData,
      {
        index: generateId(),
        dataId: '',
        description: [],
      },
    ];
    setRipsPayload({ ...ripsPayload, ripsData: options });
  };

  const onDeleteRip = index => {
    const options = ripsPayload.ripsData.filter(el => el.index !== index);
    setRipsPayload({ ...ripsPayload, ripsData: options });
  };

  const onChangeGeneralInfo = (key, value) => {
    const options = { ...ripsPayload, [key]: value };
    setRipsPayload(options);
  };

  const onChangeCompanyValue = async (index, value) => {
    const response = await getContracts({
      url: '/receivable/contract/',
      objFilters: {
        client: value,
        status: 'enabled',
      },
      token: store.loginReducer.Authorization,
    });
    const formattedContracts =
      response.results?.map(con => ({
        value: con.id,
        label: con.contract_name,
      })) || [];

    const options = ripsPayload.companies.map(el => {
      if (el.index === index) {
        return { ...el, company: value, contracts: [], contractsList: formattedContracts };
      }
      return el;
    });

    setRipsPayload({ ...ripsPayload, companies: options });
  };

  const onChangeContracts = (index, options) => {
    const opt = ripsPayload.companies.map(el => {
      if (el.index === index) {
        return { ...el, contracts: options };
      }
      return el;
    });
    setRipsPayload({ ...ripsPayload, companies: opt });
  };

  const onChangeRipData = (index, key, value) => {
    const opt = ripsPayload.ripsData.map(el => {
      if (el.index === index) {
        return { ...el, [key]: value };
      }
      return el;
    });
    setRipsPayload({ ...ripsPayload, ripsData: opt });
  };

  const onCreateRips = async () => {
    await createRips({
      token: store.loginReducer.Authorization,
      body: ripsPayload,
      method: ripsPayload.id ? 'PUT' : 'POST',
      url: '/receivable/rips/',
      succesAction: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: `${ripsPayload.id ? 'Actualizado' : 'Creado'} exitosamente`,
          text: result.message,
          confirmButtonText: 'Aceptar',
        }).then(response => {
          if (response.isConfirmed) {
            history.goBack();
          }
        });
      },
      doAfterException: result => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: result.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const formattedCompaniesSelect =
    companiesSelect?.results?.map(com => ({
      value: com.id,
      label: com.copyName,
    })) || [];

  const formattedVariables =
    variables?.results?.map(el => ({
      label: `${el.name} - ${el.label}`,
      value: el.id,
    })) || [];

  const renderGeneralInfo = () => {
    const ripsTypes = [
      { label: 'Seleccionar...', value: '', extraValue: '' },
      { label: 'CT', value: 'CT', extraValue: 'Archivo de control' },
      { label: 'AF', value: 'AF', extraValue: 'Archivo de transacciones' },
      { label: 'US', value: 'US', extraValue: 'Archivo de usuarios' },
      { label: 'AC', value: 'AC', extraValue: 'Archivo de consulta' },
      { label: 'AP', value: 'AP', extraValue: 'Archivo de procedimientos' },
      { label: 'AM', value: 'AM', extraValue: 'Archivo de medicamentos ' },
      { label: 'AT', value: 'AT', extraValue: 'Archivo de otros servicios ' },
      { label: 'MIA', value: 'MIA', extraValue: 'Archivo para COOSALUD' },
      { label: 'MEGAPLANO', value: 'MEGAPLANO', extraValue: 'Archivo para Positiva' },
    ];

    return (
      <div
        className='card p-4 mb-4 border-0 shadow mt-4'
        style={{ borderRadius: 10, backgroundColor: '#F5F7FA' }}
      >
        <h5 className='text-primary fw-bold'>Información general</h5>
        <div className='row'>
          <div className='col'>
            <Form.Group className='me-3'>
              <small className='text-primary ms-1'>
                <span>Vigente hasta</span>
                <span className='text-danger'>*</span>
              </small>
              <Form.Control
                type='date'
                className='register-inputs'
                value={ripsPayload.validUntil}
                onChange={({ target }) => onChangeGeneralInfo('validUntil', target.value)}
              />
            </Form.Group>
          </div>
          <div className='col'>
            <Form.Group className='me-3'>
              <small className='text-primary ms-1'>
                <span>Tipo de archivo</span>
                <span className='text-danger'>*</span>
              </small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                placeholder='Seleccionar...'
                options={ripsTypes}
                value={ripsTypes?.find(el => el.value === ripsPayload.ripsType)}
                onChange={option => {
                  setRipsPayload({
                    ...ripsPayload,
                    name: option.extraValue,
                    ripsType: option.value,
                  });
                }}
              />
            </Form.Group>
          </div>
          <div className='col'>
            <Form.Group className='me-3'>
              <small className='text-primary ms-1'>Nombre</small>
              <Form.Control
                type='text'
                className='register-inputs'
                value={ripsTypes.find(el => el.value === ripsPayload.ripsType)?.extraValue}
                disabled
              />
            </Form.Group>
          </div>
          <div className='col-5'>
            <Form.Group className='me-3'>
              <small className='text-primary ms-1'>
                <span>Descripción</span>
                <span className='text-danger'>*</span>
              </small>
              <Form.Control
                type='text'
                className='register-inputs'
                placeholder='Escribir...'
                value={ripsPayload.description}
                onChange={({ target }) => onChangeGeneralInfo('description', target.value)}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    );
  };

  const renderFormBody = () => {
    return (
      <div className='overflow-hidden'>
        <div className='p-3 overflow-auto' style={{ maxHeight: '50vh' }}>
          <h5 className='text-primary fw-bold'>Empresa cliente</h5>
          <div
            className='row g-0 py-1 px-2 text-white fw-bold'
            style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <div className='col me-3'>Empresa</div>
            <div className='col me-3'>Contrato</div>
            <div className='col-1 ps-3 text-center'>Acción</div>
          </div>
          {ripsPayload.companies?.map(com => (
            <div key={com.index} className='row g-0 align-items-center mt-2'>
              <div className='col mx-2'>
                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={option => onChangeCompanyValue(com.index, option.value)}
                  styles={{
                    ...customSelectNewDark,
                    control: provided => ({
                      ...provided,
                      border: '1px solid #7FADDE',
                      borderRadius: 5,
                      fontSize: 12,
                      minHeight: 30,
                    }),
                    menuList: base => ({
                      ...base,
                      maxHeight: 100,
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      height: '30px',
                      padding: '0 6px',
                    }),
                  }}
                  placeholder='Seleccionar...'
                  value={formattedCompaniesSelect?.find(el => Number(el.value) === com.company)}
                  options={[{ label: 'Seleccionar...', value: '' }, ...formattedCompaniesSelect]}
                />
              </div>
              <div className='col mx-2'>
                <ReactSelectMulti
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={options =>
                    onChangeContracts(
                      com.index,
                      options?.map(el => Number(el.value)),
                    )
                  }
                  styles={{
                    ...customSelectNewDark,
                    control: provided => ({
                      ...provided,
                      border: '1px solid #7FADDE',
                      borderRadius: 5,
                      padding: 0,
                      fontSize: 12,
                      minHeight: 30,
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      height: '30px',
                      padding: '0 6px',
                    }),
                  }}
                  placeholder='Seleccionar...'
                  isMulti
                  value={com.contractsList?.filter(el => com.contracts.includes(Number(el.value)))}
                  options={com.contractsList}
                />
              </div>
              <div className='col-1 d-flex justify-content-center align-items-center text-center'>
                {ripsPayload.companies[ripsPayload.companies.length - 1].index === com.index && (
                  <AddCircleOutline
                    className='text-primary pointer me-2'
                    onClick={onAddNewCompany}
                  />
                )}
                {ripsPayload.companies.length > 1 && (
                  <CancelOutlined
                    className='text-primary pointer'
                    onClick={() => onDeleteCompany(com.index)}
                  />
                )}
              </div>
            </div>
          ))}

          <h5 className='text-primary fw-bold mt-4'>Datos RIPS</h5>
          <div
            className='row g-0 py-1 px-2 text-white fw-bold'
            style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <div className='col me-3'>Dato</div>
            <div className='col me-3'>Descripción</div>
            <div className='col-1 text-center'>Acción</div>
          </div>
          {ripsPayload.ripsData?.map(rip => (
            <div key={rip.index} className='row g-0 align-items-center mt-2'>
              <div className='col mx-2'>
                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  onChange={option => onChangeRipData(rip.index, 'dataId', option.value)}
                  styles={{
                    ...customSelectNewDark,
                    control: provided => ({
                      ...provided,
                      border: '1px solid #7FADDE',
                      borderRadius: 5,
                      fontSize: 12,
                      minHeight: 30,
                    }),
                    valueContainer: provided => ({
                      ...provided,
                      height: '30px',
                      padding: '0 6px',
                    }),
                  }}
                  placeholder='Seleccionar...'
                  value={formattedVariables?.find(el => Number(el.value) === rip.dataId)}
                  options={[{ label: 'Seleccionar...', value: '' }, ...formattedVariables]}
                />
              </div>
              <div className='col mx-2'>
                <input
                  type='text'
                  style={{
                    minHeight: 32,
                  }}
                  onChange={({ target }) => onChangeRipData(rip.index, 'description', target.value)}
                  className='register-inputs'
                  value={rip.description}
                  placeholder='Escribir...'
                />
              </div>
              <div className='col-1 d-flex justify-content-center align-items-center text-center'>
                {ripsPayload.ripsData[ripsPayload.ripsData.length - 1].index === rip.index && (
                  <AddCircleOutline className='text-primary pointer me-2' onClick={onAddNewRip} />
                )}
                {ripsPayload.ripsData.length > 1 && (
                  <CancelOutlined
                    className='text-primary pointer'
                    onClick={() => onDeleteRip(rip.index)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        {createRipsLoader && loader}
        <div className={tableStyles.tlnContainer}>
          <div className={tableStyles.tableArea}>
            <div className='d-flex align-items-center mt-5 mb-3'>
              <img
                onClick={() => history.goBack()}
                src={IconBack}
                className='pointer me-3'
                alt=''
              />
              <h1 className='m-0'>{ripsPayload.id ? 'Editar' : 'Crear nuevo'} RIPS</h1>
            </div>
            {renderGeneralInfo()}
            {renderFormBody()}
            <div className='d-flex justify-content-end align-items-center my-4'>
              <button
                onClick={() => history.goBack()}
                className={`${tableStyles.btnSecondary} py-2 me-3`}
              >
                Cancelar
              </button>
              <button
                className={`${tableStyles.btnPrimaryAlt} py-2`}
                onClick={() => onCreateRips()}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return render();
}
