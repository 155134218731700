import {
  GET_MY_VACATIONS_LIST,
  GET_PERMISSIONS_LIST_MY_PAYROLL,
  GET_TICKETS_LIST,
} from "../actions/actionTypes";

const initialState = {
  permissionsList: [],
  vacationsList: [],
  ticketsList: [],
  loadingTickets: false,
};
export const myPayrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_LIST_MY_PAYROLL:
      return {
        ...state,
        permissionsList: action.payload,
      };
    case GET_MY_VACATIONS_LIST:
      return {
        ...state,
        vacationsList: action.payload,
      };
    case GET_TICKETS_LIST:
      return {
        ...state,
        ticketsList: action.payload,
        loadingTickets: action.loading,
      };

    default:
      return state;
  }
};
