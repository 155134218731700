/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import ModalNew from '../../components/Layouts/ModalNew';
import Style from '../Payroll/payrollStyles.module.scss';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import Lista from '../../assets/img/icons/Lista.svg';
import { Col, Row } from 'react-bootstrap';
import { changeStatusEvent, getEventTaxCalendar } from '../../actions/TaxCalendarAction';
import Loader from 'react-loader-spinner';
import {
  createEvent,
  finishEvent,
  getEventType,
  getTask,
} from '../../actions/accountingConsultsActions';
import AddCheck from '../../assets/img/icons/add-check.svg';

function ViewCalendar() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filter, setFilter] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState({});
  const [filters] = useState({
    dateFrom: '',
    dateUntil: '',
    search: '',
    eaccount: counter.loginReducer.currentAccount.id,
  });
  const [event, setEvent] = useState({
    dueDate: '',
    title: '',
    tt: '',
    eaccount: counter.loginReducer.currentAccount.id,
  });
  const [showModal2, setShowModal2] = useState({
    show: false,
    id: '',
    create: '',
    detail: '',
  });
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'taxCalenda',
    );
  //console.log(myPermission())
  useEffect(() => {
    if (!myPermission()?.read) {
      history?.push('/contabilidad/inicio');
    }
  }, []);
  const today = new Date();
  const currYear = today.getFullYear();
  const month = today.getMonth() + 1;
  useEffect(() => {
    dispatch(
      getEventTaxCalendar({
        year: filter.year ? filter.year : currYear,
        month: filter.month ? filter.month : month,
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    dispatch(getEventType({ eaccount: counter.loginReducer.currentAccount.id }));
  }, [trigger]);
  const optionMonth = [
    { value: '', label: 'Seleccionar...' },
    { value: '1', label: 'Enero' },
    { value: '2', label: 'Febrero' },
    { value: '3', label: 'Marzo' },
    { value: '4', label: 'Abril' },
    { value: '5', label: 'Mayo' },
    { value: '6', label: 'Junio' },
    { value: '7', label: 'Julio' },
    { value: '8', label: 'Agosto' },
    { value: '9', label: 'Septiembre' },
    { value: '10', label: 'Octubre' },
    { value: '11', label: 'Noviembre' },
    { value: '12', label: 'Diciembre' },
  ];
  const handlerClick = () => {
    history?.push('/contabilidad/Consultas/CalendarioTributario');
  };
  const optionYear = [{ label: 'Seleccionar...', value: '' }];
  for (let step = 2020; step < 2050; step++) {
    optionYear.push({ value: step, label: step });
  }

  const changesMonth = e => {
    setFilter({
      ...filter,
      month: e.value,
    });
    setTrigger(trigger + 1);
  };
  const changesYear = e => {
    setFilter({
      ...filter,
      year: e.value,
    });
    setTrigger(trigger + 1);
  };

  const infoEvents = info => {
    setInfo(info);
    setShowModal(true);
  };

  const resetCalendar = () => {
    setShowModal(false);
    setTrigger(trigger + 1);
  };

  const changesStatus = () => {
    dispatch(
      changeStatusEvent(
        {
          id: info.id,
          eaccount: counter.loginReducer.currentAccount.id,
          user: counter.loginReducer.user_data.id,
        },
        () => resetCalendar(),
      ),
    );
  };
  let optionsEvents = [{ key: '', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(counter.accountingConsultsReducer.listEventTypes)) {
    counter.accountingConsultsReducer.listEventTypes.forEach((item, index) => {
      optionsEvents.push({
        value: item.id,
        label: item.name,
        key: index + 'eventType',
      });
    });
  }
  const newEvent = () => {
    dispatch(
      createEvent(
        {
          eaccount: counter.loginReducer.currentAccount.id,
          dueDate: event.dueDate,
          title: event.title,
          type: event.tt,
        },
        () => {
          setShowModal2({ show: false });
          setEvent({
            eaccount: counter.loginReducer.currentAccount.id,
            dueDate: '',
            title: '',
            type: '',
          });
          dispatch(getTask(filters));
          setTrigger(trigger + 1);
        },
      ),
    );
  };

  const endEvent = () => {
    dispatch(
      finishEvent(
        {
          eaccount: counter.loginReducer.currentAccount.id,
          user: counter.loginReducer.user_data.id,
          id: showModal.id,
        },
        () => {
          setShowModal2({ show: false });
          setEvent({
            eaccount: counter.loginReducer.currentAccount.id,
            dueDate: '',
            title: '',
            type: '',
          });
          dispatch(getTask(filters));
          setTrigger(trigger + 1);
        },
      ),
    );
  };
  return (
    <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
      {!!counter.TaxCalendarReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <h1 className={tableStyles.title} style={{ marginBottom: 0 }}>
        Calendario tributario
      </h1>

      <div className={`d-flex align-items-end`}>
        <div className='col-2 display-grid'>
          <label className={tableStyles.crudModalLabel}>Mes</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            placeholder='Seleccionar...'
            value={
              filter.month
                ? optionMonth.find(option => option.value === filter.month)
                : optionMonth.find(option => option.value === month)
            }
            options={optionMonth}
            styles={customSelectNewDark}
            onChange={e => changesMonth(e)}
          />
        </div>
        <div className='col-2 display-grid' style={{ paddingLeft: '1rem' }}>
          <label className={tableStyles.crudModalLabel}>Año</label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            placeholder='Seleccionar...'
            value={
              filter.year
                ? optionYear.find(option => option.value === filter.year)
                : optionYear.find(option => option.value === currYear)
            }
            options={optionYear}
            styles={customSelectNewDark}
            onChange={e => changesYear(e)}
          />
        </div>
        <div style={{ flex: 1 }}></div>

        {myPermission().create ? (
          <div
            onClick={() => setShowModal2({ show: true, create: true, detail: false })}
            className={tableStyles.createNomModule}
          >
            <b className={`mr-2`}>Agregar Evento</b>
            <img src={AddCheck} alt='User' />
            <div></div>
          </div>
        ) : (
          ''
        )}
        <label
          onClick={() => handlerClick()}
          className={`${tableStyles.iconWrap} hoverPointer`}
          style={{ marginLeft: 10, alignSelf: 'end', height: '24px' }}
        >
          <span className={`${tableStyles.iconLabel}`}>Ver Listado</span>
          <img style={{ width: '20px' }} src={Lista} alt='Form' />
        </label>
      </div>
      <div className='container-month mt-5'>
        <div className='container-nameday d-flex flex-nowrap'>
          <div className={Style.name_day} style={{ borderRadius: '10px 0 0 0' }}>
            <p>Dom</p>
          </div>
          <div className={Style.name_day}>
            <p>Lun</p>
          </div>
          <div className={Style.name_day}>
            <p>Mar</p>
          </div>
          <div className={Style.name_day}>
            <p>Mié</p>
          </div>
          <div className={Style.name_day}>
            <p>Jue</p>
          </div>
          <div className={Style.name_day}>
            <p>Vie</p>
          </div>
          <div className={Style.name_day} style={{ borderRadius: '0 10px 0 0' }}>
            <p>Sáb</p>
          </div>
        </div>
        <div className={Style.containerDay}>
          {counter?.TaxCalendarReducer?.results?.map((item, key) => (
            <div key={key} className={`${Style.number_day}`} style={{ height: '200px' }}>
              <p className='text-day'>{item.day}</p>
              {item?.data.length >= 1
                ? item?.data?.map((x, keyItem) => (
                    <div
                      key={keyItem}
                      className={Style.day_icons}
                      style={{ backgroundColor: x.status_background, marginTop: '5px' }}
                    >
                      <div
                        style={{ color: x.status_fontcolor }}
                        title={x.title}
                        onClick={() => infoEvents(x)}
                      >
                        <b>{x.title}</b>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          ))}
        </div>
      </div>
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Evento'}
        btnYesEvent={info.status !== 'done' ? () => changesStatus() : false}
        btnYesName={'Marcar como realizado'}
        size={'600'}
      >
        <Col xs={12}>
          <label className={tableStyles.crudModalLabel}>Fecha límite</label>
          <input
            className={IndividualStyles.registerInputsClearBlue}
            type='date'
            value={info.dueDate}
            disabled
          ></input>
        </Col>
        <Col xs={12}>
          <label className={tableStyles.crudModalLabel}>Título</label>
          <input
            className={IndividualStyles.registerInputsClearBlue}
            type='text'
            value={info.title}
            disabled
          ></input>
        </Col>
        <Col xs={12}>
          <label className={tableStyles.crudModalLabel}>Tipo de evento</label>
          <input
            className={IndividualStyles.registerInputsClearBlue}
            type='text'
            value={info.ttName}
            disabled
          ></input>
        </Col>
      </ModalNew>
      <ModalNew
        show={showModal2.show}
        onHide={() => {
          setShowModal2(false);
          setEvent({
            dueDate: '',
            title: '',
            tt: '',
            eaccount: counter.loginReducer.currentAccount.id,
          });
        }}
        title={'Agregar evento'}
        btnYesEvent={() => {
          showModal2.create ? newEvent() : endEvent();
        }}
        btnYesName={'Agregar'}
        btnYesDisabled={
          !!(event.dueDate === '') ||
          !!(event.title === '') ||
          !!(event.tt === '') ||
          myPermission().edit !== 1
        }
        btnNoEvent={() => {
          setShowModal2(false);
          setEvent({
            dueDate: '',
            title: '',
            tt: '',
            eaccount: counter.loginReducer.currentAccount.id,
          });
          setTrigger(trigger + 1);
        }}
        btnNoName={'Cancelar'}
        size={'500'}
      >
        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Fecha limite</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              style={{ color: '#7FADDE', fontWeight: '700' }}
              type='date'
              value={event.dueDate}
              onChange={e =>
                setEvent({
                  ...event,
                  dueDate: e.target.value,
                })
              }
              disabled={showModal2.create ? false : true}
            ></input>
          </Col>
        </Row>

        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Título</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              type='text'
              value={event.title}
              style={{ color: '#58595B' }}
              onChange={e =>
                setEvent({
                  ...event,
                  title: e.target.value,
                })
              }
              disabled={showModal2.create ? false : true}
              placeholder='Escribir...'
            ></input>
          </Col>
        </Row>

        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Tipo de evento</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsEvents}
              onChange={e =>
                setEvent({
                  ...event,
                  tt: e.value,
                })
              }
              value={optionsEvents.find(item => item.value === event.tt)}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
              isDisabled={showModal2.create ? false : true}
            />
          </Col>
        </Row>
      </ModalNew>
    </div>
  );
}

export default ViewCalendar;
