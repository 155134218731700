import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import topArrow from '../../assets/img/icons/topArrow.svg';

import {
  getAbseteenism,
  getAccidentPercentage,
  getCurriculumAdvances,
  getEmployeeNumber,
  getTotalInvestment,
} from '../../actions/managementActions';
import { donutOptions, singleLineOptions } from '../../helpers/constants';
import { generateId, month, year } from '../../helpers/helpers';

import 'reactjs-popup/dist/index.css';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';

export const PayrollReport = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'payrll',
  );
  const token = store.loginReducer.Authorization;
  let curriculumAdvances = store?.managementReducer?.curriculumAdvances;
  let absenteeism = store?.managementReducer?.absenteeism;
  let totalInvestment = store?.managementReducer?.totalInvestment;

  const [numberOfEmplyees, setNumberOfEmplyees] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
  });
  const [filtersPayroll, setFiltersPayroll] = useState({
    month: month(),
    year: year(),
  });
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 15000);
  }, [showButton]);

  const employees = async () => {
    const result = await getEmployeeNumber(
      {
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year, //do this!!
      },
      token,
    );
    if (result?.success) {
      setNumberOfEmplyees(result.result);
    }
  };
  const accidentPercentage = async () => {
    const result = await getAccidentPercentage(
      {
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year, //do this!!
      },
      token,
    );
    if (result?.success) {
      setPercent(result.result);
    }
  };

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/gerencia/inicio');
    }

    dispatch(
      getCurriculumAdvances({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      }),
    );
    dispatch(
      getAbseteenism({
        entity_account: store.loginReducer.currentAccount.id,
        month: filters.month,
        year: filters.year,
      }),
    );
    employees();
    accidentPercentage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    dispatch(
      getTotalInvestment({
        entity_account: store.loginReducer.currentAccount.id,
        month: filtersPayroll.month,
        year: filtersPayroll.year,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersPayroll]);

  const getFivePreviousYear = () => {
    let array = [];
    let current_year = year();
    for (let step = 0; step <= 5; step++) {
      array.push(current_year - step);
    }
    return array;
  };
  return (
    <>
      {showButton && (
        <a
          width='25px'
          className={`${tableStyles.noTextDecoration} ${tableStyles.backToTop} p-3 center`}
          href='#home'
        >
          <img className={`center`} src={topArrow} alt='' />
        </a>
      )}
      <div
        className={`${tableStyles.container}`}
        style={{ overflowX: 'hidden' }}
        onScroll={() => setShowButton(true)}
      >
        <div className={tableStyles.tableArea}>
          <Row>
            <Col xs={10} className={``} id='home'>
              <h1 className={tableStyles.title}>Indicadores de nómina</h1>
            </Col>
            <Col
              xs={2}
              className={`text-end d-flex justify-content-between`}
              style={{ paddingTop: '5rem' }}
            >
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Employees'>
                  <b>Empleados</b>
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Payroll'>
                  <b>Nómina</b>{' '}
                </a>
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row className='justify-content-between mb-2' id='Employees'>
            <Col xs={3} className=' text-start'>
              <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                <b>Empleados</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filters.month}
                onChange={e => setFilters({ ...filters, month: Number(e.target.value) })}
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>

              <select
                value={filters.year}
                onChange={e => setFilters({ ...filters, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map(e => {
                  return (
                    <option key={generateId()} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={6}>
              <div className={`${tableStyles.cardBoxContainer}`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Número total de empleados</p>
                    <p className={tableStyles.cardBoxBottomText}>{numberOfEmplyees}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Porcentaje de accidentalismo anual</p>
                    <p className={tableStyles.cardBoxBottomText}>{percent + '%'}</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={6} className=''>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>Avance de hojas de vida</b>

                    {curriculumAdvances?.labels?.length > 0 &&
                    curriculumAdvances?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels:
                            curriculumAdvances?.labels?.length > 0
                              ? curriculumAdvances?.labels
                              : [],
                          legendPosition: 'bottom',
                        })}
                        series={
                          curriculumAdvances?.data?.length > 0 ? curriculumAdvances?.data : []
                        }
                        type='donut'
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                        height={900}
                      />
                    )}
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={6} className=''>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div>
                  <p className={`${tableStyles.darkGrayText}`}>
                    <b>Ausentismo</b>
                    {absenteeism?.labels?.length > 0 && absenteeism?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels: absenteeism?.labels?.length > 0 ? absenteeism?.labels : [],
                          legendPosition: 'bottom',
                        })}
                        series={absenteeism?.data?.length > 0 ? absenteeism?.data : []}
                        type='donut'
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                        height={900}
                      />
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4' id='Payroll'>
            <hr></hr>
            <Row className='justify-content-between mb-2'>
              <Col xs={3} className=' text-start'>
                <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                  <b>Valor de nómina</b>
                </h3>
              </Col>
              <Col xs={3} className=' text-end mb-2'>
                <select
                  value={filtersPayroll.month}
                  onChange={e =>
                    setFiltersPayroll({
                      ...filtersPayroll,
                      month: Number(e.target.value),
                    })
                  }
                  placeholder={'Mes'}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>

                <select
                  value={filtersPayroll.year}
                  onChange={e =>
                    setFiltersPayroll({
                      ...filtersPayroll,
                      year: Number(e.target.value),
                    })
                  }
                  placeholder={'Año'}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map(e => {
                    return (
                      <option key={generateId()} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Inversión total de nómina</b>
                    </p>
                    <Chart
                      options={singleLineOptions({
                        leftTitle: ' Días',
                        bottomTitle: 'Meses',
                        categories:
                          totalInvestment?.labels?.length > 0 ? totalInvestment?.labels : [],
                      })}
                      series={totalInvestment.data?.length > 0 ? totalInvestment.data : []}
                      type='line'
                      height={450}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
