import {
    GET_THIRD_PARTIES,
    GET_REPLACES,
    LOADING_REGISTER_VOUCHERS,
  } from "../actions/actionTypes";
  
  const initialState = {
    thirdParties:[],
    replaces:[],
    replaces_loading : false,
    loadingRegisterVoucher : false
  };
  export const movementsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_THIRD_PARTIES:
        return {
          ...state,
          thirdParties: action.payload,
        };
      case LOADING_REGISTER_VOUCHERS:
        return {
          ...state,
          loadingRegisterVoucher: action.loading,
        };
      case GET_REPLACES:
        return {
          ...state,
          replaces: action.payload,
          replaces_loading : action.loading
        };
      default:
        return state;
    }
  };
  
