import ModalNew from "../Layouts/ModalNew"
import GenericTableScroll from '../../components/Layouts/GenericTableScroll';
import { convertMoneyFormat } from "../../helpers";

export const ImbalanceWarningModal = ({data, show, onCloseModal}) => {

  const renderHeader = () =>{
    return [
      <div key={1} className='text-start' style={{flex: 3, paddingLeft: 14}}>
        Nombre
      </div>,
      <div key={2} className='text-center' style={{flex: 2}}>
        Tipo de descuadre
      </div>,
      <div key={3} className='text-center' style={{flex: 2}}>
        Total estimado
      </div>,
      <div key={4} className='text-center' style={{flex: 2}}>
        Total almacenado
      </div>,
      <div key={5} className='text-center' style={{flex: 2}}>
        Diferencia
      </div>,

    ]
  }

  const renderBody = () => {
    return data?.map((item, index) => {
      return (
        <section2 key={index}>
          <div className='text-start' style={{ flex: 3, paddingLeft: 14 }}>
            {item.full_name}
          </div>
          <div className='text-center' style={{ flex: 2 }}>
            {(item.difference_type)}
          </div>
          <div className='text-center' style={{ flex: 2 }}>
            {convertMoneyFormat(item.estimated_total)}
          </div>
          <div className='text-center' style={{ flex: 2 }}>
            {convertMoneyFormat(item.stored_total)}
          </div>
          <div className='text-center' style={{ flex: 2 }}>
            {convertMoneyFormat(item.difference)}
          </div>
        </section2>
      )
    })
  }

  return (
    <ModalNew
    dontClose
    hideCancelButton
    title={'Descuadre encontrado'}
    show={show}
    onHide={onCloseModal}
    btnYesName='Aceptar'
    btnYesEvent={onCloseModal}
    size={'xl'}
    >

      <GenericTableScroll
        dark
        headers={renderHeader()}
        body={renderBody()}
        typeHead="2"
        maxHeight={500}
      >
      </GenericTableScroll>

    </ModalNew>
  )
}
