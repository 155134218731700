import ReactSelect from 'react-select';
import _ from 'lodash';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../../Hooks';

import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function AddDay({
  open = false,
  busyDays = [],
  onClose = () => null,
  onSubmit = () => null,
}) {
  const token = useSelector(store => store.loginReducer.Authorization);

  const [payload, setPayload] = useState({
    day: null,
    clonedDay: null,
  });

  const { results: daysOptionsResults, trigger: getDaysOptions } = useGetMethod();

  const daysSelectOptions = useMemo(() => {
    if (daysOptionsResults?.results) {
      const daysOptions = daysOptionsResults.results.map(day => ({
        label: day.dowValue,
        value: day.dowId,
      }));
      return [{ label: 'Seleccionar...', value: null }, ..._.differenceBy(daysOptions, busyDays, 'value')];
    }
    return [];
  }, [busyDays, daysOptionsResults.results]);

  useEffect(() => {
    if (open) {
      void getDaysOptions({
        url: '/medical/schedules/dow/',
        token: token,
      });
    }
  }, [open, getDaysOptions, token]);

  const onSubmitModal = () => {
    onSubmit(payload);
    onClose();
  };

  const onCloseModal = () => {
    onClose();
  };

  const renderComponent = () => {
    return (
      <OrdModal
        title='Agregar día'
        show={open}
        onHide={() => onCloseModal()}
        btnYesName='Aceptar'
        btnNoName='Cancelar'
        size={500}
        btnYesEvent={() => onSubmitModal()}
        btnNoEvent={() => onCloseModal()}
      >
        <div>
          <label className={tableStyles.ordDarkBlueText}>
            Día de la semana<span className='text-danger'>*</span>
          </label>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={daysSelectOptions}
            className='text-secondary '
            placeholder='Seleccionar...'
            styles={ordCustomSelect}
            onChange={option => {
              setPayload(state => ({ ...state, day: option }));
            }}
          />
        </div>
        <div className='mt-3'>
          <label className={tableStyles.ordDarkBlueText}>Clonar del día</label>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={[{ label: 'Seleccionar...', value: null }, ...busyDays]}
            className='text-secondary '
            placeholder='Seleccionar... '
            onChange={option => {
              setPayload(state => ({ ...state, clonedDay: option.value }));
            }}
            styles={ordCustomSelect}
          />
        </div>
      </OrdModal>
    );
  };

  return renderComponent();
}
