//User's reducer

import {
   GET_DIAGNOSTICS, SET_INDEX_DIAGNOSTICS, CREATE_DIAGNOSTIC
  } from "../actions/actionTypes";
  
  const initialState = {
   diagnostics: [],
   totalDiagnostics:0,
   displayError: false,
   error: null,
   token:""

  };
  export const diagnosticReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DIAGNOSTICS:
        return {
          ...state,
          diagnostics: action.payload,
          displayError: false,
          error: null,
          token:action.token
        };
        case SET_INDEX_DIAGNOSTICS:
        return {
          ...state,
          totalDiagnostics: action.payload,
          displayError: false,
          error: null,
          token:action.token
        };
        case CREATE_DIAGNOSTIC:
        return {
          ...state,
          displayError: false,
          error: null,
          token:action.token
        };

      
     
     
      default:
        return state;
    }
  };
  