import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { message } from '../helpers/helpers';
import {
  GET_ENTRY_BY_CONSIGN,
  GET_ENTRY_BY_DONATION,
  GET_ENTRY_BY_PURCHASE,
  GET_WAREHOUSE_LIST,
  GET_MOVEMENTS_INVENTORY,
  GET_MOVEMENTS_TYPES,
  GET_ARTICLES_IN_WAREHOUSE,
  GET_ARTICLES_IN_TRANSFER_WAREHOUSE,
  GET_ARTICLES_FOR_EACH_WAREHOUSE,
  GET_MOVEMENT_FOR_EACH_ARTICLE,
  GET_WAREHOUSE_DETAILS,
  GET_REPORT_DETAILS,
  GET_LOTS,
  GET_OUTPUT_ARTICLES,
  INVENTORY_ADJUSTMENT_TYPE,
  OUTPUT_ADJS_LOADING,
} from './actionTypes';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getEntryByPurchaseRecords = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_ENTRY_BY_PURCHASE,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_ENTRY_BY_PURCHASE,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ENTRY_BY_PURCHASE,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getEntryByConsignRecords = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_ENTRY_BY_CONSIGN,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_ENTRY_BY_CONSIGN,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ENTRY_BY_CONSIGN,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};
export const getEntryByDonationRecords = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_ENTRY_BY_DONATION,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_ENTRY_BY_DONATION,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ENTRY_BY_DONATION,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getWareHouseList = (id, doAfter) => (dispatch, getState) => {
  dispatch({
    type: GET_WAREHOUSE_LIST,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/select/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_WAREHOUSE_LIST,
        payload: results,
        total: row_total,
        loading: false,
      });
      if (doAfter) {
        doAfter();
      }
    })
    .catch(err => {
      dispatch({
        type: GET_WAREHOUSE_LIST,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getMovementInventory = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_MOVEMENTS_INVENTORY,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/list/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_MOVEMENTS_INVENTORY,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_MOVEMENTS_INVENTORY,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getMovementType = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement-type/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_MOVEMENTS_TYPES,
        payload: results,
        total: row_total,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getArticlesInWarehouse =
  (objFilters, doafter = null) =>
  (dispatch, getState) => {
    let filters = convertFilterToString(objFilters);
    const token = getState().loginReducer.Authorization;
    dispatch({
      type: GET_ARTICLES_IN_WAREHOUSE,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/list_articles/?${filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { results, row_total } = res;
        if (results?.length === 0) {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intente de nuevo',
            text: 'No hay registros para mostrar',
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
        dispatch({
          type: GET_ARTICLES_IN_WAREHOUSE,
          payload: results,
          total: row_total,
          loading: false,
        });
        if (doafter) {
          doafter();
        }
      })
      .catch(err => {
        dispatch({
          type: GET_ARTICLES_IN_WAREHOUSE,
          loading: false,
        });
        console.error(err.message);
        if (doafter) {
          doafter();
        }
      });
  };

export const getArticlesInWarehouseTransfer = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/intersect_list_articles/?${filters}`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    },
  )
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      if (results?.length === 0) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intente de nuevo',
          text: 'No hay registros para mostrar',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: GET_ARTICLES_IN_TRANSFER_WAREHOUSE,
        payload: results,
        total: row_total,
      });
    })
    .catch(err => {
      console.error(err.message);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al traer los artículos del almacén',
        showCancelButton: false,
      });
    });
};

export const getArticlesForEachWarehouse = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse/inventory_in_warehouse/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_ARTICLES_FOR_EACH_WAREHOUSE,
        payload: results,
        total: row_total,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getMovementForArticle = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_MOVEMENT_FOR_EACH_ARTICLE,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/for_each_article/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_MOVEMENT_FOR_EACH_ARTICLE,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_MOVEMENT_FOR_EACH_ARTICLE,
        payload: [],
        total: 0,
        loading: false,
      });
      console.error(err.message);
    });
};

export const getwhEntryDetails =
  (id, doafter = null) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    // ${id}
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-entry/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        const { results, row_total } = res;
        dispatch({
          type: GET_WAREHOUSE_DETAILS,
          payload: results,
          total: row_total,
        });
        if (doafter) {
          doafter();
        }
      })
      .catch(err => {
        if (doafter) {
          doafter();
        }
        console.error(err.message);
      });
  };

export const getReportDetails = (id, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  // warehouse-movement/
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/${id}`, {
    //change url
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_REPORT_DETAILS,
        payload: results,
        total: row_total,
      });
      if (doAfter) {
        doAfter();
      }
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const saveWareHouseMovement = async (
  wm_account,
  wm_justification,
  wm_observations,
  created_by,
  wm_movement_type,
  wm_destination,
  wm_origin,
  wm_we_id,
  wm_status,
  we_billDocument,
  we_billNumber,
  filename,
  articles,
  token,
  wm_provider_id = null,
) => {
  try {
    let data = {
      wm_account,
      wm_justification,
      wm_observations,
      created_by,
      wm_movement_type,
      wm_destination,
      wm_origin,
      wm_we_id,
      wm_status,
      we_billDocument,
      we_billNumber,
      filename,
      articles,
      token,
      wm_provider_id,
    };

    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      console.error('Error === ', result.message);
    }
  } catch (error) {
    console.error(error);
    return message('warning', 'Intenta de nuevo', error.message, undefined, true);
  }
};

export const creatMovement = data => (dispatch, getState) => {
  dispatch({
    type: OUTPUT_ADJS_LOADING,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/internal/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        dispatch({
          type: OUTPUT_ADJS_LOADING,
          loading: false,
        });
        customSwaltAlert({
          icon: 'success',
          title: 'Movimiento guardado',
          text: res.results,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).then(response => {
          if (response.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        dispatch({
          type: OUTPUT_ADJS_LOADING,
          loading: false,
        });
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `Error al guardar movimiento: ${res.message}`,
          showCancelButton: false,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: OUTPUT_ADJS_LOADING,
        loading: false,
      }); //

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        footer: 'Error al guardar movimiento, si el problema persiste comuníquese con un asesor.',
        showCancelButton: false,
      });
    });
};

export const getReceiptInvoices = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/lots/${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_count } = res;
      dispatch({
        type: GET_LOTS,
        results: results,
        total: row_count,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const getOutputArticles = objFilters => (dispatch, getState) => {
  const filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/lots/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_OUTPUT_ARTICLES,
        payload: results,
        lotsTotal: row_total,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const applyLegalization = (data, doAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/warehouse-movement/legalize`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        if (doAfter) {
          doAfter();
        }
        return customSwaltAlert({
          icon: 'success',
          title: 'Legalización solicitada',
          text: res.results,
          showConfirmButton: false,
          showCancelButton: false,
          html: (
            <div className='my-4'>
              <a
                className='my-3 text-decoration-none btn-primary py-2'
                href='/compras/informeEntradaPorConsignacion'
              >
                Aceptar
              </a>
            </div>
          ),
        });
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          showCancelButton: false,
        });
      }
    })
    .catch(() => {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Ocurrió un error al enviar la solicitud',
        showCancelButton: false,
      });
    });
};

export const getInventoryAdjustmentType = objFilters => (dispatch, getState) => {
  const filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/accounting/inventoryAdjustmentType/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: INVENTORY_ADJUSTMENT_TYPE,
        payload: res.results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
