import { Card } from 'react-bootstrap';
import info from '../../assets/img/icons/Alert.svg';
import ordComponentStyles from './ordComponentStyles.module.scss';
export const OrdBox = props => {
  return (
    <>
      <Card
        className={`${ordComponentStyles.ordBox} hoverPointer`}
        onClick={() => props?.buttonAction()}
      >
        <span>
          <img
            alt={'backArrow'}
            src={props?.img ? props.img : info}
            className={`mr-2 mt-2 mb-2 cursorPointer ${ordComponentStyles.ordBoxImg}`}
          ></img>
        </span>
        <b>{props?.titleCardBox}</b>
      </Card>
    </>
  );
};
