import styles404 from "./styles404.module.scss";
import { useHistory } from 'react-router-dom';

export const Layout404 = () => {

    const history = useHistory();
    const onRedirectHome = () => history.push('/categoryselect')

        return (
        <div className={styles404.container404}>
            <button
                type="button"
                className={styles404.error404Btn}
                onClick={onRedirectHome}
            >
                INICIO
            </button>
        </div>
    )
}