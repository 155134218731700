import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import Check from '../../assets/img/icons/CheckMark.svg';
import Agregar from '../../assets/img/icons/add-check.svg';
import Search from '../../assets/img/icons/lupa.svg';
import SearchB from '../../assets/img/icons/lupaTransparent.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import GenericTableNew from '../Layouts/GenericTableNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';

import { Col, Form, Row } from 'react-bootstrap';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useGetMethod, usePostMethod } from '../../Hooks/useFetch';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { loader, validateEmptyString } from '../../helpers/helpers';
import ModalNew from '../Layouts/ModalNew';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import { useHasPermissionByTagModule } from "../../Hooks";

export const WorkEnv = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'jobWea',
  );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const history = useHistory();
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    search: '',
    startDate: '',
    endDate: '',
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
  });

  const {
    results: surveyListResults,
    load: surveyListLoader,
    trigger: getSurveyListMethod,
  } = useGetMethod();
  const { load: changeStatusSurveyLoader, trigger: changeStatusSurveyMethod } = usePostMethod();

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    getSurveyListMethod({
      url: '/payroll/workenviroment',
      objFilters: filters,
      token: token,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const renderElement = elem => {
    const optionsFinished = [
      {
        text: 'Ver detalle',
        icon: SearchB,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickDetail(elem);
        },
      },
      {
        text: 'Conclusiones',
        icon: Check,
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickConclusion(elem);
        },
      },
    ];

    const options = [
      {
        text: 'Ver detalle',
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickDetail(elem);
        },
      },
      {
        text: 'Cancelar',
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          setCancelModal({ ...cancelModal, show: true, surveyId: elem.id, title: elem.title });
        },
      },
      {
        text: 'Finalizar',
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          finishSurveys(elem.title, elem.id);
        },
      },
    ];
    const optionsCancelled = [
      {
        text: 'Ver detalle',
        class: CustomPopupStyles.popUpGeneric,
        event: () => {
          handleClickDetail(elem);
        },
      },
    ];

    return (
      <tr key={elem.id}>
        <td className='col-md-3 text-start ' style={{ paddingLeft: '2rem' }}>
          {elem.title ? elem.title : '-'}
        </td>
        <td className='col-md-2 text-center'>{elem.startDate ? elem.startDate : '-'}</td>
        <td className='col-md-2 text-center'>{elem.endDate ? elem.endDate : '-'}</td>
        <td className='col-md-2 text-center'>
          {elem.numberRespondents ? elem.numberRespondents : '-'}
        </td>
        <td className='col-md-2 text-center px-4'>
          <div
            className='rounded-pill p-1'
            style={{
              backgroundColor: elem?.background,
            }}
          >
            <b
              style={{
                color: elem?.fontColor,
                width: '150px',
              }}
            >
              {elem.statusName ? elem.statusName : '-'}
            </b>
          </div>
        </td>
        <td className='col-md-1 text-center'>
          {myPermission?.read && (
            <>
              <div className='cursorPointer'>
                <CustomPopupExtend
                  noHover
                  className={`align-self-end`}
                  // triggerClass={`${tableStyles.hoverPopup} `}
                  triggerSrc={threeDots}
                  extraButtons={
                    elem.status === 'completed'
                      ? optionsFinished
                      : elem.status === 'cancelled'
                        ? optionsCancelled
                        : options
                  }
                />
              </div>
            </>
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (surveyListResults?.results?.length > 0) {
      const elem2 = surveyListResults?.results;
      if (elem2?.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-start'>
      &nbsp; Título de la encuesta
    </th>,
    <th key={2} className='text-center'>
      Fecha inicio
    </th>,
    <th key={3} className='text-center'>
      Fecha fin
    </th>,
    <th key={4} className='text-center'>
      No. de encuestados
    </th>,
    <th key={4} className='text-center'>
      Estado
    </th>,
    <th key={5} className='text-center'>
      &nbsp;
    </th>,
  ];

  const handleClickNew = () => {
    history.push({
      pathname: '/nomina/seguridadYsalud/crearEncuesta',
      state: { detail: false },
    });
  };
  const handleClickDetail = elem => {
    history.push({
      pathname: '/nomina/seguridadYsalud/registrarEncuesta',
      state: { detail: true, data: elem },
    });
  };
  const handleClickConclusion = elem => {
    history.push({
      pathname: '/nomina/seguridadYsalud/registrarEncuesta',
      state: { detail: true, conclusion: true, data: elem },
    });
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  const [cancelModal, setCancelModal] = useState({
    show: false,
    cancelledReason: '',
    title: '',
    surveyId: '',
  });

  const closeCancelModal = () => {
    setCancelModal({
      ...cancelModal,
      show: false,
      cancelledReason: '',
    });
  };

  const sendCancelledReason = () => {
    let validations = validateEmptyString([
      {
        field: cancelModal.cancelledReason,
        message: 'Para cancelar una encuesta debe de mandar el motivo de cancelación',
      },
    ]);

    if (validations.valid) {
      customSwaltAlert({
        title: `¿Está seguro?`,
        text: `Se cancelará la encuesta: '${cancelModal.title}'`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Continuar',
        cancelButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          changeStatusSurvey('cancelled', '', cancelModal.title);
        }
      });
    }
  };

  const finishSurveys = (title, id) => {
    customSwaltAlert({
      title: `¿Está seguro?`,
      text: `Se finalizará la encuesta: '${title}'`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        changeStatusSurvey('completed', id, title);
      }
    });
  };

  const changeStatusSurvey = (method, id, title) => {
    let objFilters =
      method === 'cancelled'
        ? {
            id: cancelModal.surveyId,
            status: 'cancelled',
            cancellationReason: cancelModal.cancelledReason,
          }
        : {
            id: id,
            status: 'completed',
          };
    changeStatusSurveyMethod({
      url: '/payroll/workenviroment',
      method: 'PUT',
      body: objFilters,
      token: token,
      succesAction: () => {
        if (method === 'cancelled') {
          closeCancelModal();
        }

        customSwaltAlert({
          title: 'Actualizado exitosamente',
          text: `Se ha ${method === 'cancelled' ? 'cancelado' : 'finalizado'} la encuesta: ${title}`,
          icon: 'success',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        setTrigger(trigger + 1);
      },
      doAfterException: error => {
        customSwaltAlert({
          title: `${error.message}`,
          icon: 'success',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  return (
    <>
      {/* ANCHOR  CANCELLED MODAL */}
      {(surveyListLoader || changeStatusSurveyLoader) && loader}
      <ModalNew
        title={'Motivo de cancelación'}
        show={cancelModal.show}
        btnYesName={'Enviar'}
        btnNoName={'Cancelar'}
        size={500}
        onHide={() => closeCancelModal()}
        btnYesEvent={() => sendCancelledReason()}
        btnNoEvent={() => closeCancelModal()}
        // sizeBody={`${tableStyles.modalHeight}`}
      >
        <div className='mb-4'>
          <Form.Group className=' text-start' controlId='cancelled'>
            <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                &nbsp;Motivo <span className={`${tableStyles.ordOrangeText}`}></span>{' '}
              </span>
            </Form.Label>
            <textarea
              placeholder={'Escribir...'}
              rows='30'
              cols='55'
              style={{ height: '5rem' }}
              value={cancelModal.cancelledReason}
              className={`text-secondary ord-roundInput w-100 ${tableStyles.outlineNone}`}
              onChange={e => setCancelModal({ ...cancelModal, cancelledReason: e.target.value })}
            ></textarea>
          </Form.Group>
        </div>
      </ModalNew>

      <div className={tableStyles.container}>
        {store.workEnvReducer.listWorkEnvPoll_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Clima laboral</h1>
          <Row>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Fecha inicio</p>
              <input
                onChange={e => setFilters({ ...filters, startDate: e.target.value })}
                type='date'
                placeholder='Escribe...'
                className='register-inputs   fw-bold'
                name='position'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col className='d-block' xs={2}>
              <p className={`${tableStyles.crudModalLabel}`}>Fecha fin</p>
              <input
                onChange={e => setFilters({ ...filters, endDate: e.target.value })}
                type='date'
                placeholder='Escribe...'
                className='register-inputs   fw-bold'
                name='position'
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleSearch(e);
                  }
                }}
              />
            </Col>
            <Col xs={4} className='d-flex align-self-end gap-1'>
              {myPermission?.read && (
                <>
                  <input
                    className={tableStyles.SearchNew}
                    value={filters.search}
                    placeholder='Buscar...'
                    type='text'
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleSearch(e);
                      }
                    }}
                  ></input>
                  <img
                    className='hoverPointer'
                    alt='iconSearch'
                    style={{}}
                    src={Search}
                    height='24px'
                    onClick={e => handleSearch(e)}
                  />
                </>
              )}
            </Col>
            {myPermission?.create ? (
              <Col xs={4} className='align-self-end'>
                <div className='d-flex justify-content-end'>
                  <div onClick={() => handleClickNew()} className={tableStyles.createNomModule}>
                    <b className={`mr-2`}>Crear encuesta</b>
                    <img src={Agregar} alt='User' />
                    <div></div>
                  </div>
                </div>
              </Col>
            ):''}
          </Row>

          {myPermission?.read && (
            <>
              <GenericTableNew headers={renderHeaders} dark={true}>
                {listElem()}
              </GenericTableNew>

              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {surveyListResults?.results ? filters.page : ''}
                  {' de '}
                  {Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                    ? Math.ceil(surveyListResults?.rowTotal / filters.perpage)
                    : '1'}{' '}
                  ({surveyListResults?.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={surveyListResults?.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={e => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
