//User's reducer

import {
  GET_MU, SET_INDEX_MU, GET_ALL_MU, LOADING
} from "../actions/actionTypes";
const initialState = {
 measurements: [],
 allMeasurements: [],
 totalMeasures:0,
 displayError: false,
 error: null,
 Authorization:"",
 measurement_loading: false,
 loading: false

};
export const measurementUnitsReducer = (state = initialState, action) => {
  switch (action.type) {
      case GET_MU:
          return {
            ...state,
            measurements: action.payload,
            Authorization:action.Authorization,
            measurement_loading: action.loading
          };
        case SET_INDEX_MU:
          return {
            ...state,
            totalMeasures: action.payload,
            displayError: false,
            error: null,
            Authorization:action.Authorization
          };
          case GET_ALL_MU:
            return {
              ...state,
              allMeasurements: action.payload,
              displayError: false,
              error: null,
              token:action.token,
             
            };
          case LOADING:
            return {
              ...state,
              measurement_loading: action.loading
            };
    
    default:
      return state;
  }
};
