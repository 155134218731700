import { Checkbox } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import {
  default as Adding,
  default as agregarEspecialidad,
} from '../../assets/img/icons/agregarEspecialidad.svg';
import Trash from '../../assets/img/icons/canecaGris.svg';
import iconCamera from '../../assets/img/icons/iconCamera.svg';
import SearchIcon from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';

import { STORAGE_EDIT } from '../../actions/actionTypes';
import { getSites } from '../../actions/sitesActions';
import {
  deleteWarehouseArticle,
  storage_edit,
  storage_edit_active,
  storage_get,
  storage_insert,
} from '../../actions/storageActions';
import { getArticles } from '../../actions/articlesActions';
import { customSwaltAlert, loader } from '../../helpers';
import GenericTableNew from '../Layouts/GenericTableNew';

import ModalNew from '../Layouts/ModalNew';
import CustomPopup from '../Popup/customPopup';

import 'reactjs-popup/dist/index.css';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectMulti, customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import style from './Storage.module.scss';

function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

function TableStorage() {
  const history = useHistory();
  const store = useSelector(state => state);
  const dispatch = useDispatch();

  const [unconfirmedFilters, setUnconfirmedFilters] = useState({
    search: '',
    active: '',
  });

  const handleImagePick = async e => {
    const blobURL = window.URL.createObjectURL(e.target.files[0]);
    const img = new Image();
    img.src = blobURL;
    img.onload = function () {
      window.URL.revokeObjectURL(blobURL);
      const canvas = document.createElement('canvas');

      const newWidth = clamp(img.width, 640, 1280);
      const newHeight = (img.height * newWidth) / img.width;
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        function (blob) {
          let encode = canvas.toDataURL('image/png', 0.9);
          let file64 = encode.split(';base64,')[1];

          setPutState({
            ...putState,
            file: blob,
            file64: file64,
            fileUrl: URL.createObjectURL(blob),
          });
        },
        'image/jpeg',
        0.9,
      );
    };
  };

  const [filters, setFilter] = useState({
    search: '',
    active: '',
    page: 1,
    id_account: store.loginReducer?.currentAccount.id,
    perpage: 10,
    pageInModal: 1,
    perpageModal: 5,
  });

  const [putState, setPutState] = useState({
    usage: '',
    id: '',
    id_account: store.loginReducer?.currentAccount.id,
    description: '',
    address: '',
    supplier: false,
    active: '',
    sites: [],
    stocks: [],
    articles: [],
    minStock: 0,
    maxStock: 0,
    available: 0,
    addArticle: {},
    reorder: 1,
    modalShow: false,
  });

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'Store',
  );

  useEffect(() => {
    dispatch(storage_get(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    if (!!myPermission.read === false) {
      history.push('/login');
    }

    dispatch(
      getSites({ page: 1, perpage: 1000, id_account: store.loginReducer.currentAccount.id }),
    );
    dispatch(getArticles({ page: 1, perpage: 10000 }));
  }, [store.loginReducer.currentAccount.id, dispatch, history, myPermission.read]);

  const handleSearchButton = () => {
    const newFilters = {
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      id_account: store.loginReducer?.currentAccount.id,
      page: 1,
      pageInModal: 1,
      perpageModal: 5,
    };

    setFilter(newFilters);
    dispatch(storage_get(newFilters));
  };

  const handleResetPutState = () => {
    setPutState({
      usage: '',
      id: '',
      description: '',
      address: '',
      supplier: false,
      active: '',
      sites: [],
      stocks: [],
      articles: [],
      modalShow: false,
      reorder: 1,
    });
  };

  const handleChange = event => {
    if (event.target.value === '') {
      dispatch(storage_get(filters));
    }

    setUnconfirmedFilters({
      ...unconfirmedFilters,
      search: event.target.value,
    });
  };

  const handlePageChange = val => {
    dispatch(storage_get({ ...filters, page: val }));
  };

  const handleAddArticle = () => {
    if (!putState.addArticle) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un artículo para agregar',
        showCancelButton: false,
      });
    }
    if (putState.minStock <= 0 || putState.maxStock <= 0 || putState.available <= 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar un stock mayor a cero (0)',
        showCancelButton: false,
      });
    }
    if (putState.reorder <= 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar un punto de reorden mayor que cero (0)',
        showCancelButton: false,
      });
    }
    const newArticle = {
      description: putState.addArticle.label,
      id_article: putState.addArticle.value,
      min_stock: putState.minStock,
      max_stock: putState.maxStock,
      available: putState.available,
      reorder: putState.reorder,
    };

    if (parseInt(newArticle.min_stock) > parseInt(newArticle.max_stock)) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'La cantidad mínima debe ser menor que la cantidad máxima',
        showCancelButton: false,
      });
    }
    if (parseInt(newArticle.available) > parseInt(newArticle.max_stock)) {
      customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: 'La cantidad disponible supera el stock máximo de este almacén.',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          putInTable(newArticle);
        }
      });
    } else {
      putInTable(newArticle);
    }
  };

  function putInTable(newArticle) {
    let articles = putState?.articles ?? [];
    let articlesStock = stockTableData.stockArticles;

    if (articles.some(e => e.id_article === newArticle.id_article)) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El artículo ya está agregado',
        showCancelButton: false,
      });
      return;
    }
    articles.push(newArticle);

    articlesStock = articles;
    setPutState({
      ...putState,
      articles: articles,
    });
    setStockTableData({
      ...stockTableData,
      stockArticles: articlesStock,
    });
  }

  function handleSubmit() {
    if (putState.description === '') {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El campo descripción es obligatorio',
        showCancelButton: false,
      });
      return null;
    }
    if (!Array.isArray(putState.sites) || putState.sites.length < 1) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe escoger por lo menos una sede',
        showCancelButton: false,
      });
      return null;
    }
    if (putState.usage === 'PUT') {
      return dispatch(
        storage_edit(
          putState.id,
          {
            ...putState,
          },
          () => {
            handleResetPutState();
            dispatch(storage_get(filters));
          },
        ),
      );
    }
    if (putState.usage === 'POST') {
      return dispatch(
        storage_insert(
          {
            ...putState,
          },
          () => {
            handleResetPutState();
            dispatch(storage_get(filters));
          },
        ),
      );
    }
    return null;
  }

  const renderElement = elem => {
    return (
      <tr key={`${elem.id}-${elem.description}`} className='hover-table-row'>
        <td className='col-md-4 text-start px-2'>{elem.description}</td>
        <td className='col-md-6'>
          <p className='m-0 text-start'>{elem.info}</p>
        </td>
        <td className='col-md-1'>
          <div className={`${tableStyles.groupElems} gap-2`}>
            <div
              className={`mx-0
              ${elem.active ? tableStyles.greenState : tableStyles.redState}
              `}
            >
              {elem.active ? 'Habilitado' : 'Inhabilitado'}
            </div>
            {(!!myPermission?.edit || !!myPermission?.delete) && (
              <CustomPopup
                showEdit={!!myPermission?.edit}
                triggerSrc={threeDots}
                editClickEvent={() => {
                  setPutState({
                    ...putState,
                    id: elem.id,
                    description: elem.description,
                    address: elem.address,
                    supplier: elem.supplier,
                    sites: elem?.sites?.map(elem => elem),
                    stocks: elem.stocks,
                    articles: elem.articles,
                    usage: 'PUT',
                    modalShow: true,
                    fileUrl: elem.fileUrl,
                    info: elem.info,
                  });
                }}
                showEnable={!!myPermission?.delete}
                isEnabled={elem.active}
                enableClickEvent={() =>
                  dispatch(
                    storage_edit_active(elem.id, elem, elem.description, () =>
                      dispatch(storage_get(filters)),
                    ),
                  )
                }
              />
            )}
          </div>
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (Array.isArray(store.storageReducer.list)) {
      const elem2 = store.storageReducer.list;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={`2${Date.now()}`} className={`px-2`}>
      Descripción
    </th>,
    <th key={`3${Date.now()}`}>Información Adicional</th>,
    <th key={`4${Date.now()}`}>
      <div style={{ width: '110px' }} className={`${tableStyles.groupElems} text-center`}>
        Estado
      </div>
    </th>,
  ];

  //====================================================================================================
  //=============================== SECONDARY TABLE FOR STOCKS =========================================
  const [stockTableData, setStockTableData] = useState({
    page: 1,
    stockArticles: [],
  });

  const renderHeadersStock = [
    <th key={`1${Date.now()}`} className="ps-2">Artículo</th>,
    <th key={`x${Date.now()}`}>Reorden</th>,
    <th key={`2${Date.now()}`}>Min</th>,
    <th key={`3${Date.now()}`}>Max</th>,
    <th key={`4${Date.now()}`}>Disponible</th>,
    <th key={`5${Date.now()}`}> </th>,
  ];

  const optionsArticles = () => {
    let temp = [];
    if (store.articleReducer.articles !== undefined) {
      const elem2 = store.articleReducer.articles;
      temp = elem2?.map(elem => {
        return {
          value: elem.id,
          label: elem.description,
          isDisabled: !elem.active,
        };
      });
    }
    return temp;
  };

  const renderStock = storageStock => {
    if (storageStock) {
      let pageInit = filters.pageInModal * filters.perpageModal - filters.perpageModal;
      let pageEnd = filters.pageInModal * filters.perpageModal;
      let renderArticles = storageStock.slice(pageInit, pageEnd);
      return renderArticles?.map(article => {
        return (
          <tr key={article.article_id} className='hover-table-row'>
            <td className={`ps-2 text-start`} width={300}>{article.description}</td>
            <td className={`${tableStyles.textCenter}`}>{article.reorder}</td>
            <td className={`${tableStyles.textCenter}`}>{article.min_stock}</td>
            <td className={`${tableStyles.textCenter}`}>{article.max_stock}</td>
            <td className={`${tableStyles.textCenter}`}>{article.available}</td>
            <td className={`${tableStyles.textCenter}`}>
              <img
                src={Trash}
                alt='trash icon'
                onClick={() => {
                  dispatch({
                    type: STORAGE_EDIT,
                    payload: putState,
                  });
                  dispatch(deleteWarehouseArticle(article, putState));
                }}
                className={tableStyles.iconSvgSmall}
              />
            </td>
          </tr>
        );
      });
    }
  };

  //====================================================================================================
  //====================================================================================================

  //=============================================================================================
  //react select input library section ==========================================================
  const optionsSites = () => {
    let temp = [];
    if (store.siteReducer.sites !== undefined) {
      const elem2 = store.siteReducer.sites;
      temp = elem2?.map(elem => {
        return {
          value: elem.id,
          label: elem.description,
          isDisabled: !elem.active,
        };
      });
    }
    return temp;
  };

  const optionsSitesValue = () => {
    let selectedOptions = [];
    putState?.sites?.forEach(elem => {
      let foundOption = elem.id_site
        ? optionsSites().find(ss => ss.value === elem.id_site)
        : optionsSites().find(ss => ss.value === elem);
      if (foundOption) {
        selectedOptions.push(foundOption);
      }
    });
    return selectedOptions;
  };

  const optionsSitesChange = e => {
    const newSites = e?.map(elem => elem.value);
    setPutState({
      ...putState,
      sites: newSites,
    });
  };
  const handleSumit = e => {
    e.preventDefault();
    setFilter({
      ...filters,
      search: unconfirmedFilters.search,
      active: unconfirmedFilters.active,
      id_account: store.loginReducer?.currentAccount.id,
      page: 1,
      pageInModal: 1,
      perpageModal: 5,
    });
    dispatch(storage_get(filters));
  };

  return (
    <div className={tableStyles.container}>
      {(store.storageReducer.storage_loading || store.storageReducer.isLoading) && loader}

      <div className={tableStyles.tableArea}>
        <h1 className={tableStyles.title}>Almacenes</h1>

        <div className={tableStyles.mainRow}>
          <div>
            <form onSubmit={handleSumit}>
              <input
                className={`${tableStyles.searchUsersAlt}`}
                type='text'
                placeholder='Buscar...'
                onChange={handleChange}
              />

              <img
                src={SearchIcon}
                alt='Search icon'
                className={`${tableStyles.iconSvg} ${style.iconSvgMargin}`}
                onClick={() => handleSearchButton()}
              />
            </form>
          </div>

          {myPermission?.create ? (
            <div className={`${style.principalScreenBtns}`}>
              <div
                className={`text-decoration-none ${style.buttonCreatCount} groupAddButton align-items-center`}
                onClick={() => setPutState({ ...putState, usage: 'POST', modalShow: true })}
                style={{ width: 'max-content' }}
              >
                <label htmlFor='newAccident' className='darkGray fw-bold'>
                  Nuevo almacén
                </label>

                <img
                  src={Adding}
                  alt='User'
                  className={`${style.btnNewHover} ${tableStyles.iconSvg}`}
                />
              </div>
            </div>
          ) : null}
        </div>

        {putState.modalShow ? (
          <ModalNew
            size='700'
            title='Nuevo Almacén'
            show={putState.modalShow}
            onHide={() => handleResetPutState()}
            btnYesEvent={() => handleSubmit()}
          >
            <div className={style.upperzone}>
              {/*grey zone */}

              <div className={`d-flex`}>
                {/*top row */}
                <div className={`${style.Item} ${style.mr} `}>
                  {/*left column: most inputs */}
                  <p className={`${style.labelBig} ${style.f6}`}>Información General</p>

                  <div className='d-flex mb-1'>
                    <div className={`${style.Item}  ${style.mr}  ${style.f4}`}>
                      <p className={`${style.label}`}>Descripción</p>
                      <input
                        className='register-inputs input-big-height'
                        name='description'
                        placeholder='Escribir...'
                        type='text'
                        defaultValue={putState.description ? putState.description : ''}
                        onChange={event =>
                          setPutState({
                            ...putState,
                            description: event.target.value,
                          })
                        }
                      />
                    </div>

                    <div className={`${style.Item} ${style.f3}`}>
                      <p className={`${style.label}`}>Información Adicional</p>
                      <input
                        className='register-inputs input-big-height'
                        name='info'
                        type='text'
                        placeholder='1 o 2 palabras...'
                        value={putState.info}
                        onChange={event =>
                          setPutState({
                            ...putState,
                            info: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex mb-1'>
                    <div className={`${style.Item}  ${style.mr}  ${style.f4}`}>
                      <p className={`${style.label}`}>Dirección</p>
                      <input
                        className='register-inputs input-big-height'
                        name='address'
                        placeholder='Escribir...'
                        type='text'
                        defaultValue={putState.address || ''}
                        onChange={event =>
                          setPutState({
                            ...putState,
                            address: event.target.value,
                          })
                        }
                      />
                    </div>

                    <div className={`${style.Item} ${style.f3}`}>
                      <p className={`${style.label}`}>&nbsp;</p>
                      <div className={`d-flex`}>
                        <Checkbox
                          className={style.mycheckbox}
                          type='checkbox'
                          name='supplier'
                          onChange={() =>
                            setPutState({ ...putState, supplier: !putState.supplier })
                          }
                          checked={putState.supplier}
                        />
                        <label className={`${style.label}`} htmlFor='supplier'>
                          {' '}
                          Recibe proveedor{' '}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`${style.Item} ${style.f4} ${style.imageWrapper}`}>
                  {/*right column: image*/}
                  <img alt='input' src={putState.fileUrl} className={style.imageInput} />

                  <label htmlFor='imageUpload' className={style.imageInputLabel}>
                    <img src={iconCamera} alt='cambiar imagen' />
                  </label>

                  <input
                    id='imageUpload'
                    name='imageUpload'
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    onChange={handleImagePick}
                    className={style.imageInputBtn}
                  />
                </div>
              </div>

              {/*bottom row*/}
              <div className={`${style.Item}`}>
                <p className={`${style.label}`}>Sedes</p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key='select-site-warehouse'
                  defaultValue={optionsSitesValue()}
                  onChange={e => optionsSitesChange(e)}
                  options={[{ label: 'Seleccionar...', value: '' }, ...optionsSites()]}
                  isMulti
                  placeholder='Seleccionar...'
                  styles={customSelectMulti}
                />
              </div>
            </div>
            {/*gray zone end*/}

            <div className='d-flex mb-1'>
              <div className={`${style.Item}  ${style.mr} ${style.f4}`}>
                <p className={`${style.label}`}>Relación artículo</p>

                <div className={tableStyles.flex3}>
                  <Select
                    noOptionsMessage={() => 'No hay datos'}
                    defaultValue={stockTableData.chosenArticle}
                    onChange={e => setPutState({ ...putState, addArticle: e })}
                    options={[{ label: 'Seleccionar...', value: '' }, ...optionsArticles()]}
                    placeholder='Seleccionar...'
                    styles={customSelectNewDark}
                  />
                </div>
              </div>

              {/*added in ts-309 */}
              <div className={`${style.Item}  ${style.mr}  ${style.f1}`}>
                <p className={`${style.label}`}>Reorden</p>
                <input
                  className={`register-inputs ${tableStyles.flex1}`}
                  name='reorden'
                  type='number'
                  min={1}
                  placeholder='Escribir...'
                  value={putState.reorder || ''}
                  onChange={e => {
                    setPutState({ ...putState, reorder: e.target.value });
                  }}
                />
              </div>

              <div className={`${style.Item}  ${style.mr}  ${style.f1}`}>
                <p className={`${style.label}`}>Min</p>
                <input
                  className={`register-inputs ${tableStyles.flex1}`}
                  name='articleMin'
                  type='number'
                  placeholder='Escribir...'
                  min={1}
                  onChange={e => {
                    setPutState({ ...putState, minStock: e.target.value });
                  }}
                />
              </div>

              <div className={`${style.Item}  ${style.mr}  ${style.f1}`}>
                <p className={`${style.label}`}>Max</p>
                <input
                  className={`register-inputs ${tableStyles.flex1}`}
                  name='articleMax'
                  type='number'
                  min={1}
                  placeholder='Escribir...'
                  onChange={e => setPutState({ ...putState, maxStock: e.target.value })}
                />
              </div>

              <div className={`${style.Item} ${style.mr}  ${style.f1}`}>
                <p className={`${style.label}`}>Disponible</p>
                <input
                  className={`register-inputs ${tableStyles.flex1}`}
                  name='articleavailable'
                  type='number'
                  placeholder='Escribir...'
                  min={1}
                  onChange={e => setPutState({ ...putState, available: e.target.value })}
                />
              </div>

              <div className={`${style.Item}`}>
                <p className={`${style.label}`}>&nbsp;</p>
                <img
                  src={agregarEspecialidad}
                  alt='agregar artículo'
                  className={`${style.iconSave}`}
                  onClick={() => {
                    handleAddArticle();
                  }}
                />
              </div>
            </div>

            <GenericTableNew headers={renderHeadersStock} fontFamilyTable={'fontFamilyTable'}>
              {renderStock(putState.articles)}
            </GenericTableNew>

            <div className={paginationStyles.wrapperReverse}>
              <Pagination
                activePage={filters.pageInModal}
                itemsCountPerPage={filters.perpageModal}
                totalItemsCount={putState.articles ? putState.articles.length : 0}
                pageRangeDisplayed={5}
                onChange={e =>
                  setFilter({
                    ...filters,
                    pageInModal: e,
                  })
                }
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </ModalNew>
        ) : null}

        <GenericTableNew fontFamilyTable={'fontFamilyTable'} dark={true} headers={renderHeaders}>
          {listElem()}
        </GenericTableNew>
        <Pagination
          activePage={filters.page}
          itemsCountPerPage={filters.perpage}
          totalItemsCount={store.storageReducer.total_index}
          pageRangeDisplayed={filters.total}
          onChange={handlePageChange}
          itemClassPrev={paginationStyles.itemClassPrev}
          itemClassNext={paginationStyles.itemClassNext}
          itemClassFirst={paginationStyles.itemClassFirst}
          itemClassLast={paginationStyles.itemClassLast}
          itemClass={paginationStyles.itemClass}
        />
      </div>
    </div>
  );
}

export default TableStorage;
