import * as deepcopy from 'deepcopy';
import { Component } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  activationDeactivation,
  createSpeciality,
  createSubspeciality,
  getSpeciality,
  getSubSpeciality,
  updateSpeciality,
  updateSubspeciality,
} from '../../actions/SpecialityActions';
import Add from '../../assets/img/icons/agregar.svg';
import agregarEspecialidad from '../../assets/img/icons/agregarEspecialidad.svg';
import Export from '../../assets/img/icons/exportar.svg';
import Search from '../../assets/img/icons/lupa.svg';
import GenericTable from '../Layouts/GenericTable';
import ModalCreateRecord from '../Layouts/ModalCreateRecord';
import paginationStyles from '../Layouts/pagination.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopup';
import TableSubspecialty from './TableSubspecialty';
import './specialties_CSS.scss';

const MySwal = withReactContent(Swal);

class TableSpecialities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: 'Crear especialidad',
      filters: {
        page: 1,
        search: '',
        perpage: 10,
      },

      specialities: {
        description: '',
        id: '',
        enabled: true,
        subspecialty: [],
      },
      subspecialities: {
        description: '',
        id: '',
        enabled: true,
        id_speciality: '',
      },
      headerState: ['Código', 'Especialidad', 'est.'],
      editRecord: false,
      modal: false,
    };
  }

  componentDidMount() {
    this.props.getSpeciality(this.state.filters);

    this.getInitialState();
  }

  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.getSpeciality(this.state.filters);
  }

  handleChange(event) {
    let { filters } = this.state;
    filters.search = event.target.value;
    this.setState({ filters });
  }

  handleClick() {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.props.getSpeciality(this.state.filters);
  }

  toggleModal = () => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };

  getInitialState = () => {
    this.setState({
      titleModal: 'Crear especialidades',
      filters: {
        page: 1,
        search: '',
        perpage: 10,
      },

      specialities: {
        description: '',
        id: '',
        enabled: true,
        subspecialty: [],
      },
      subspecialities: {
        description: '',
        enabled: true,
        id_speciality: '',
      },
      headerState: ['Código', 'Especialidad', 'est.'],
      editRecord: false,
      modal: false,
    });
  };
  onChangeForm = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { specialities } = this.state;
    specialities[name] = value;
    this.setState({ specialities });
  };
  onChangeFormSubspec = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { subspecialities } = this.state;
    subspecialities[name] = value;
    this.setState({ subspecialities });
  };

  handleEditRecord = medidas => {
    let { modal, specialities, titleModal } = this.state;
    modal = !this.state.modal;
    specialities = deepcopy(medidas);
    titleModal = 'Editar especialidad';
    this.setState({ modal, specialities, editRecord: true, titleModal });
  };

  handleSubmit() {
    if (this.state.editRecord) {
      this.setState({
        modal: !this.state.modal,
      });
      this.props.updateSubspeciality(this.state.specialities);
      return this.props.updateSpeciality(this.state.specialities, this.state.filters);
    }

    this.props.createSpeciality(this.state.specialities, this.state.filters);
    this.props.updateSubspeciality(this.state.specialities);
    this.setState({
      modal: !this.state.modal,
    });
  }
  clearSubspec() {
    let { subspecialities } = this.state;
    subspecialities.description = '';
    this.setState({ subspecialities: subspecialities });
  }

  editarSubespecialidad = () => {
    let subs = [...this.state.specialities.subspecialty, { ...this.state.subspecialities }];
    if (this.state.subspecialities.description !== '') {
      let temp = { ...this.state.specialities };
      temp.subspecialty = subs;
      this.setState({ specialities: temp });
    } else {
      return MySwal.fire({
        icon: 'error',
        title: 'Debe llenar el campo de subespecialidad',
      });
    }
  };
  removeSubspecialty = id => {
    const subs = this.state.specialities.subspecialty;
    subs.splice(id, 1);
    this.setState({ subspecialty: subs });
  };

  renderSpecialities(specialities) {
    return (
      <tr key={specialities.id} className='hover-table-row text-center align-middle'>
        <td> {specialities.id} </td>
        <td>{specialities.description}</td>

        <td>
          <div className={tableStyles.groupElems}>
            <div
              className={specialities.enabled ? tableStyles.circleGreen : tableStyles.circleRed}
            ></div>
            <CustomPopup
              showEdit={true}
              editClickEvent={() => this.handleEditRecord(specialities)}
              showEnable={true}
              isEnabled={specialities.enabled}
              enableClickEvent={() =>
                this.props.activationDeactivation(
                  specialities.id,
                  specialities.enabled,
                  this.state.filters,
                  specialities.description,
                )
              }
            />
          </div>
        </td>
      </tr>
    );
  }
  render() {
    let listSpecialities;

    if (this.props.specialities !== undefined) {
      const specialities = this.props.specialities;
      listSpecialities = specialities.map(medidas => {
        return this.renderSpecialities(medidas);
      });
    }
    let renderHeaders = this.state.headerState.map((header, ind) => {
      return (
        <th key={ind}>
          <div className={header === 'est.' || header === '' ? tableStyles.groupElems : ''}>
            {header}
          </div>
        </th>
      );
    });
    return (
      <div className={tableStyles.container}>
        <h1 className={tableStyles.title}>Especialidades</h1>
        <div className={tableStyles.tableArea}>
          <div className={tableStyles.mainRow}>
            <div>
              <input
                className={tableStyles.searchUsersAlt}
                type='text'
                placeholder='Buscar'
                onChange={e => this.handleChange(e)}
              />

              <img
                src={Search}
                alt='Search icon'
                className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                onClick={e => this.handleClick(e)}
              />
            </div>

            <div className={tableStyles.groupElems}>
              <img src={Export} alt='exportar' className={tableStyles.iconSvg} />
              <img
                src={Add}
                alt='User icon'
                className={tableStyles.iconSvg}
                onClick={() => this.toggleModal(true)}
              />
            </div>
          </div>

          <ModalCreateRecord
            title={this.state.titleModal}
            show={this.state.modal}
            submit={() => this.handleSubmit()}
            onHide={() => this.toggleModal(false)}
          >
            <div className='d-flex flex-direction-row align-middle mx-3'>
              <div className='mx-3 col-md-5'>
                <p className='label-inputs'>Descripción</p>
                <input
                  className='register-inputs'
                  name='description'
                  type='text'
                  placeholder='Descripción especialidad'
                  onChange={this.onChangeForm}
                  value={this.state.specialities.description}
                />
              </div>
              <div className='mx-3 col-md-5'>
                <p className='label-inputs'>Subespecialidad</p>
                <input
                  className='register-inputs'
                  name='description'
                  type='text'
                  placeholder='Descripción subespecialidad'
                  onChange={this.onChangeFormSubspec}
                  value={this.state.subspecialities.description}
                />
              </div>
              <div>
                <img
                  src={agregarEspecialidad}
                  alt='agregar icon'
                  className={tableStyles.iconSvg}
                  onClick={() => {
                    this.editarSubespecialidad();
                    this.clearSubspec();
                  }}
                />
              </div>
            </div>
            <TableSubspecialty
              show={true}
              key={Math.random()}
              id_speciality={this.state.specialities.id}
              specialty={this.state.specialities}
              reset_specialty={() => this.props.getSpeciality(this.state.filters)}
              removeSubspecialty={this.removeSubspecialty}
            >
              {' '}
            </TableSubspecialty>
          </ModalCreateRecord>

          <GenericTable headers={renderHeaders}>{listSpecialities}</GenericTable>

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {this.props.totalspecialities ? this.state.filters.page : ''}
              {' de '}
              {Math.ceil(this.props.totalspecialities / this.state.filters.perpage)
                ? Math.ceil(this.props.totalspecialities / this.state.filters.perpage)
                : ''}{' '}
              ({this.props.totalspecialities} encontrados)
            </p>
            <Pagination
              activePage={this.state.filters.page}
              itemsCountPerPage={10}
              totalItemsCount={this.props.totalspecialities}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
  specialities: state.specialitiesReducer.specialities,
  totalspecialities: state.specialitiesReducer.totalspecialities,
});

const mapDispatchToProps = dispatch => ({
  getSpeciality: filters => {
    dispatch(getSpeciality(filters));
  },
  getSubSpeciality: filters => {
    dispatch(getSubSpeciality(filters));
  },

  activationDeactivation: (id, status, filters, description) => {
    dispatch(activationDeactivation(id, status, filters, description));
  },
  updateSpeciality: (obj, filters) => {
    dispatch(updateSpeciality(obj, filters));
  },
  createSpeciality: (obj, filters) => {
    dispatch(createSpeciality(obj, filters));
  },
  createSubspeciality: (obj, objSpeciality) => {
    dispatch(createSubspeciality(obj, objSpeciality));
  },
  updateSubspeciality: obj => {
    dispatch(updateSubspeciality(obj));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableSpecialities);
