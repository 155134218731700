import styled from 'styled-components';
import { Checkbox, Text } from '../../atoms';

const StyledBoxCheckText = styled.div`
  padding: ${({ padding }) => (padding ? padding : '0px')};
  margin: ${({ margin }) => (margin ? margin : '0')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'normal')};
  column-gap: ${({ columnGap }) => (columnGap ? columnGap : '10px')};
  display: flex;
  align-items: center;
`;

export const BoxCheckText = ({
  className,
  fontWeight,
  titleText,
  nameCheck,
  valueCheck,
  handleChangeCheck,
  padding,
  margin,
  columnGap,
}) => {
  return (
    <StyledBoxCheckText
      className={`${className} animate__animated animate__fadeIn`}
      padding={padding}
      margin={margin}
      columnGap={columnGap}
    >
      <Checkbox checked={valueCheck} handleChangeCheck={handleChangeCheck} name={nameCheck} />
      <Text title={titleText} fontWeight={fontWeight} />
    </StyledBoxCheckText>
  );
};
