/**
 * Crea un objeto Date local a partir de una cadena de fecha en formato YYYY-MM-DD o YYYY-MM-DD HH:MM:SS y devuelve exactamente la fecha pasada sin ser afectada por el time zone,
 * o devuelve directamente el objeto Date si date ya es un objeto Date.
 * Si la cadena de fecha no está definida o es inválida, devuelve la fecha y hora actuales.
 *
 * @param {string | Date} date - La cadena de fecha en formato YYYY-MM-DD o YYYY-MM-DD HH:MM:SS.
 * @returns {dt} Objeto Date local creado a partir de la cadena de fecha.
 */
export const convertLocalDate = date => {

  if (!date) {
    return new Date();
  } else if (date instanceof Date && !isNaN(date.getTime())) {
    return date;
  }

  const [year, month, day, hour = 0, minute = 0, second = 0] = date.match(/\d+/g).map(Number);

  const dt = new Date(year, month - 1, day, hour, minute, second);

  return dt;
};
