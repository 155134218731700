import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
//
import { useGetMethod } from "../../../../../Hooks";
import { loader } from "../../../../../helpers";
//
import { ordCustomSelect, customSelectMulti } from "../../../../../components/Layouts/react-select-custom";
import NumberFormat from "react-number-format";

export default function DataSheet({ companyParamsPayload, setCompanyParamsPayload, isCurrentTab }) {
    const store = useSelector((state) => state);

    const { trigger: getCompanies, results: companiesSelect } = useGetMethod();
    const { trigger: getContracts, results: contractsSelect } = useGetMethod();
    const { trigger: getContractServices, results: contractServices, load: contractServicesLoader } = useGetMethod();
    const { trigger: getContractDocs, results: contractDocs } = useGetMethod();

    useEffect(() => {
        if (contractServices.results) {
            setCompanyParamsPayload(prev => ({
                ...prev,
                contractServices: contractServices.results
            }))
        }
    }, [contractServices.results, setCompanyParamsPayload]);

    useEffect(() => {
        async function fetchAsyncData() {
            await getCompanies({
                url: "/receivable/client/",
                objFilters: {
                    eaccount: store.loginReducer.currentAccount.id,
                },
                token: store.loginReducer.Authorization
            });
        }
        fetchAsyncData().catch(error => console.error("Error: ", error));
    }, [
        getCompanies,
        store.loginReducer.currentAccount.id,
        store.loginReducer.Authorization
    ]);

    useEffect(() => {
        async function fetchAsyncData() {
            if (companyParamsPayload.companyId) {
                await getContracts({
                    url: "/receivable/contract/",
                    objFilters: {
                        client: companyParamsPayload.companyId,
                        status: "enabled"
                    },
                    token: store.loginReducer.Authorization
                });
            }
        }
        fetchAsyncData().catch(error => console.error("Error: ", error));
    }, [
        getContracts,
        companyParamsPayload.companyId,
        store.loginReducer.Authorization,
    ]);

    const onChangeContract = async (option) => {
        setCompanyParamsPayload(prev => ({ ...prev, contractId: option.value }));
        const result = await getContractServices({
            url: "/receivable/contract/getServices/",
            objFilters: {
                contractId: option.value
            },
            token: store.loginReducer.Authorization
        });
        const docsResult = await getContractDocs({
            url: "/receivable/contract/getDocuments/",
            objFilters: {
                contractId: option.value
            },
            token: store.loginReducer.Authorization
        });
        if (result.results) setCompanyParamsPayload(prev => ({
            ...prev,
            contractName: option.label,
            contractServices: result.results.map(item => ({
                ...item,
                documents: docsResult.results?.map(doc => ({
                    value: doc.fileId,
                    label: doc.docName
                })) || []
            }))
        }));
    };

    const onChangeDocuments = (id, options) => {
        const services = companyParamsPayload.contractServices.map(item => {
            if (item.contractServiceId === id) {
                return { ...item, documents: options }
            }
            return item;
        });
        setCompanyParamsPayload(prev => ({ ...prev, contractServices: services }));
    };

    const formattedCompaniesSelect = companiesSelect?.results?.map(com => ({
        value: com.id,
        label: com.copyName
    })) || [];
    const formattedContractsSelect = contractsSelect?.results?.map(com => ({
        value: com.id,
        label: com.contract_name
    })) || [];
    const formattedModalities = [
        { label: "Individual", value: "individual" },
        { label: "Grupal", value: "group" },
    ];
    const formattedFiling = [
        { label: "Física", value: "on_site" },
        { label: "Virtual", value: "virtual" },
    ];

    const render = () => {
        return (
            <div style={{ display: isCurrentTab ? "block" : "none" }}>
                {contractServicesLoader && loader}
                <h5 className="text-primary fw-bold mt-4">Información empresa cliente</h5>
                <div className="card p-4 mb-4 border-0 shadow" style={{ borderRadius: 10, backgroundColor: "#F5F7FA" }}>
                    <div className="row">
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Empresa</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <ReactSelect
                                    noOptionsMessage={() => "No hay datos"}
                                    options={[{ value: null, label: "Seleccionar..." }, ...formattedCompaniesSelect]}
                                    value={formattedCompaniesSelect?.find(el => el.value === companyParamsPayload.companyId)}
                                    onChange={(option) => {
                                        const company = companiesSelect?.results?.find(item => item.id === option.value);
                                        setCompanyParamsPayload(prev => ({
                                            ...prev,
                                            companyId: option.value,
                                            companyNit: company?.Nit,
                                            companyName: company?.copyName,
                                            contractId: null,
                                            contractServices: [],
                                            filingType: null,
                                            filingMaxDay: null,
                                            modality: null,
                                            folderParams: {
                                                mainFolder: "",
                                                childrenFiles: [],
                                            }
                                        }))
                                    }}
                                    styles={ordCustomSelect}
                                    placeholder="Seleccionar..."
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>NIT</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <Form.Control
                                    className={`ord-roundInput `}
                                    defaultValue={companyParamsPayload.companyNit}
                                    disabled
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Contrato</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <ReactSelect
                                    noOptionsMessage={() => "No hay datos"}
                                    options={[{ value: null, label: "Seleccionar..." }, ...formattedContractsSelect]}
                                    value={formattedContractsSelect?.find(item => item.value === companyParamsPayload.contractId)}
                                    onChange={(option) => onChangeContract(option)}
                                    styles={ordCustomSelect}
                                    placeholder="Seleccionar..."
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <h5 className="text-primary fw-bold mt-4">Documentos por tipo de servicios</h5>
                <div className="row">
                    {companyParamsPayload.contractServices.length ? companyParamsPayload.contractServices.map(item => (
                        <div key={item.contractServiceId} className="col-lg-6 mt-3">
                            <Form.Group className="me-3">
                                <span className="text-primary ms-1">
                                    <span>{item.serviceName}</span>
                                    <span className="text-danger">*</span>
                                </span>
                                <ReactSelect
                                    isMulti
                                    isClearable={false}
                                    noOptionsMessage={() => "No hay datos"}
                                    value={item?.documents}
                                    options={contractDocs?.results?.map(elem => ({ label: elem.docName, value: elem.fileId }))}
                                    onChange={(options) => onChangeDocuments(item.contractServiceId, options)}
                                    styles={customSelectMulti}
                                    placeholder="Seleccionar..."
                                />
                            </Form.Group>
                        </div>
                    )) : (
                        <p>Seleccione un contrato con servicios disponibles.</p>
                    )}
                </div>

                <h5 className="text-primary fw-bold mt-4">Información para radicación</h5>
                <div className="card p-4 border-0 shadow" style={{ borderRadius: 10, backgroundColor: "#F5F7FA" }}>
                    <div className="row align-items-end">
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Tipo de radicación</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <ReactSelect
                                    noOptionsMessage={() => "No hay datos"}
                                    options={formattedFiling}
                                    value={formattedFiling.find(el => el.value === companyParamsPayload.filingType)}
                                    onChange={(option) => setCompanyParamsPayload(prev => ({ ...prev, filingType: option.value }))}
                                    styles={ordCustomSelect}
                                    placeholder="Seleccionar..."
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Día máximo de radicación</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <div className="d-flex">
                                    <NumberFormat
                                        className="ord-roundInput w-100"
                                        value={companyParamsPayload.filingMaxDay}
                                        onValueChange={({ value }) => {
                                            setCompanyParamsPayload(prev => ({ ...prev, filingMaxDay: value }))
                                        }}
                                        allowNegative={false}
                                        decimalScale={0}
                                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                    />
                                    <input
                                        type="text"
                                        className="ord-roundInput"
                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, fontSize: 14, width: 110 }}
                                        defaultValue="de cada mes"
                                        disabled
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Modalidad de radicación</span>
                                    <span className="text-danger">*</span>
                                </small>
                                <ReactSelect
                                    noOptionsMessage={() => "No hay datos"}
                                    options={formattedModalities}
                                    value={formattedModalities.find(el => el.value === companyParamsPayload.modality)}
                                    onChange={(option) => setCompanyParamsPayload(prev => ({ ...prev, modality: option.value }))}
                                    styles={ordCustomSelect}
                                    placeholder="Seleccionar..."
                                />
                            </Form.Group>
                        </div>
                        <div className="col">
                            <Form.Group className="me-3">
                                <small className="text-primary ms-1">
                                    <span>Agrupado por</span>
                                </small>
                                <Form.Control
                                    className="ord-roundInput"
                                    defaultValue={companyParamsPayload.modality === "group" ? "Pacientes" : ""}
                                    disabled
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return render();
}