import { Drawer } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { OrdGenericTemplate } from '../../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import ordComponentStyles from '../../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import rowIcon from '../../../assets/img/icons/arrowDerechaCalendario.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, formatToRcSelect, loader, today } from '../../../helpers';

const TransferSurgery = () => {
  const store = useSelector(state => state);

  const [detailedClass, setDetailedClass] = useState({
    showModal: false,
    isEditing: false,
    headers: [
      { title: 'Fecha', className: 'col- text-start px-2' },
      { title: 'Tipo cirugía', className: 'col-1 text-start px-2' },
      { title: 'Anestesia', className: 'col-1 text-start px-2' },
      { title: 'Cirujano', className: 'col-2 text-start px-2' },
      { title: 'Quirófano inicial', className: 'col-2 text-start' },
      { title: 'Quirófano solicitado', className: 'col-2 text-start' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    perpage: 10,
    date: today(),
  });
  const [data, setData] = useState({
    nameClassificationDetail: '',
    stcId: null,
    createdBy: store.loginReducer.currentAccount.id,
    responsible: null,
  });
  const [trigger, setTrigger] = useState(0);

  const {
    load: detailedClassificationLoader,
    results: detailedClassificationResults,
    trigger: getDetailedClassification,
  } = useGetMethod();
  const { load: doctorLoader, results: doctorResults, trigger: getDoctorMethod } = useGetMethod();
  const {
    load: surgeryRoomLoader,
    results: surgeryRoomResults,
    trigger: getSurgeryRooms,
  } = useGetMethod();
  const { load: statusLoader, results: statusResults, trigger: statusMethod } = useGetMethod();

  const { load: TransferSurgeryLoader, trigger: TransferSurgeryFunction } = usePostMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getSurgeryRooms({
        url: '/medical/surgeriesRoom/',
        token: store.loginReducer.Authorization,
      });
      await getDoctorMethod({
        url: '/medical/surgeons/',
        token: store.loginReducer.Authorization,
      });

      await statusMethod({
        url: '/medical/status/movementOperatingRoom',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
          view: 'surgery_clinic_history',
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDoctorMethod,
    statusMethod,
    getSurgeryRooms,
    store.loginReducer.Authorization,
    store.loginReducer.currentAccount.id,
  ]);
  useEffect(() => {
    async function fetchAsyncData() {
      await getDetailedClassification({
        url: '/medical/clinicHistory/movementOperatingRooms/',
        objFilters: { ...filters, page: 1 },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDetailedClassification, store.loginReducer.Authorization, trigger]);

  const doctorFilterOptions = formatToRcSelect(doctorResults?.results, 'id', 'name', '', '', '');
  const surgeryRoomOptions = formatToRcSelect(
    surgeryRoomResults?.results,
    'id',
    'description',
    '',
    '',
    '',
  );
  const statusOptions = formatToRcSelect(
    statusResults?.results,
    'value',
    'description',
    '',
    '',
    '',
  );

  const handleOpenEditModal = async item => {
    setDetailedClass({
      ...detailedClass,
      showModal: true,
      isEditing: true,
    });
    setData({
      ...data,
      ...item,
    });
  };

  const handleCloseModal = () => {
    setDetailedClass({ ...detailedClass, showModal: false, isEditing: false });
    setData({
      nameClassificationDetail: '',
      srvId: [],
      createdBy: store.loginReducer.currentAccount.id,
      stcId: null,
    });
  };

  const handleSubmit = isApproved => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${isApproved ? 'aprobará' : 'rechazará'} la petición de traslado`,
      confirmButtonText: 'Si, continuar',
    }).then(async rs => {
      if (rs.isConfirmed) {
        await TransferSurgeryFunction({
          url: '/medical/clinicHistory/movementOperatingRooms/',
          method: 'PUT',
          token: store.loginReducer.Authorization,
          body: {
            requestId: data.requestId,
            appId: data.appId,
            status: isApproved ? 'approved' : 'reject',
          },
          doAfterSuccess: () => {
            setFilters({
              ...filters,
              page: 1,
            });
            setTrigger(trigger + 1);

            handleCloseModal();
            customSwaltAlertAsistencial({
              icon: 'success',
              titleColor: '#1ABCD2',
              title: 'Actualizado con éxito',
              text: `Se ha ${isApproved ? 'aprobado' : 'rechazado'} la petición de traslado exitosamente`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
          doAfterException: results => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              titleColor: '#1ABCD2',
              title: 'Intenta de nuevo',
              text: results.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderRow = row => {
    return (
      <tr key={row?.id} className={`hover-table-row`}>
        <td className='text-start px-2 col-1'>{row?.requestDate}</td>
        <td className='text-start px-2 col-2 '>
          <div className='d-flex'>
            {row?.services &&
              row?.services?.length > 0 &&
              row?.services?.map((service, ind) => {
                if (ind === 0) {
                  return (
                    <div className='p-2' key={ind}>
                      <div
                        style={{
                          backgroundColor: '#00b4cc20',
                          color: '#00b4cc',
                          borderRadius: 16,
                          padding: '4px 10px',
                        }}
                      >
                        {service}
                      </div>
                    </div>
                  );
                }
                return <div key={ind}></div>;
              })}

            {row?.services?.length > 1 && (
              <div className='p-2'>
                <div
                  style={{
                    backgroundColor: '#00b4cc20',
                    color: '#00b4cc',
                    borderRadius: 16,
                    padding: '4px 10px',
                  }}
                >
                  ...
                </div>
              </div>
            )}
          </div>
        </td>
        <td className='text-start px-2 col-1'>{row?.anesthesia}</td>
        <td className='text-start  col-2'>{row?.surgeon?.fullName}</td>
        <td className='text-start  col-1'>{row?.previousQx}</td>
        <td className='text-start  col-2 '>{row?.requestQx}</td>
        <td className='px-2 col-2'>
          <div className=' d-flex justify-content-center'>
            <div
              style={{
                backgroundColor: row?.status?.bgColor || '#E8E8EA',
                color: row?.status?.fontColor || '#6E6F7C',
                borderRadius: 16,
                padding: '4px 10px',
              }}
            >
              {row?.status?.name}
            </div>
          </div>
        </td>
        <td className='text-end col-1'>
          <div className='d-flex justify-content-center px-2'>
            <img
              alt='icon'
              src={rowIcon}
              className='cursorPointer'
              onClick={() => {
                handleOpenEditModal(row);
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={detailedClass.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: detailedClassificationResults?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getDetailedClassification({
              url: '/medical/clinicHistory/movementOperatingRooms/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
                page: e,
                perpage: 10,
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {detailedClassificationResults?.results?.map(row => renderRow(row))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    function calculateAge(dateOfBirth) {
      const ageInMilliseconds = Date.now() - new Date(dateOfBirth).getTime();
      return Math.floor(ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
    }

    return (
      <Drawer
        anchor={'right'}
        open={detailedClass.showModal}
        onClose={handleCloseModal}
        classes={{ width: '100%' }}
      >
        <div className={`${tableStyles.tlndrawer__header}`}>
          <button className={`${tableStyles.tlndrawer__close}`} onClick={handleCloseModal}></button>
          <div className={`${tableStyles.tlndrawer__title} w-100 text-start px-3`}>
            <span>Información cirugía</span>
          </div>
        </div>
        <div className='d-flex flex-column ' style={{ height: '100vh' }}>
          <div className={`p-5 ${tableStyles.tlndrawer__content}`} style={{ width: '500px' }}>
            <Row>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Paciente</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.patient?.fullName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Edad</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{calculateAge(data?.patient?.birthDate)} años</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Tipo de cirugía</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <div className='d-flex'>
                      {data?.services &&
                        data?.services?.length > 0 &&
                        data?.services?.map((service, ind) => {
                          if (ind < 3) {
                            return (
                              <div className='p-2' key={ind}>
                                <div
                                  style={{
                                    backgroundColor: '#00b4cc20',
                                    color: '#00b4cc',
                                    borderRadius: 16,
                                    padding: '4px 10px',
                                  }}
                                >
                                  {service}
                                </div>
                              </div>
                            );
                          }
                          return <div key={ind}></div>;
                        })}

                      {data?.services?.length > 2 && (
                        <div className='p-2'>
                          <div
                            style={{
                              backgroundColor: '#00b4cc20',
                              color: '#00b4cc',
                              borderRadius: 16,
                              padding: '4px 10px',
                            }}
                          >
                            ...
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Empresa</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.cclientName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Contrato</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.contractName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Cirujano</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.surgeon?.fullName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Solicitud</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>
                      {' '}
                      {data?.previousQx} a {data?.requestQx}{' '}
                    </span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Motivo</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.justification ?? '-'}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className='mb-3 text-start d-flex flex-column' controlId='2'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b className='px-1'>Solicitado por</b>
                  </Form.Label>
                  <Form.Label className={`m-0 ${tableStyles.darkGrayText}`}>
                    <span className='px-1'>{data?.userRequest?.fullName}</span>
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          </div>
          {data?.status?.value === 'pending' && (
            <div className='px-5 py-3'>
              <Row>
                <Col xs={12}>
                  <div className='text-end'>
                    <button
                      onClick={() => handleSubmit(false)}
                      className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
                    >
                      Rechazar
                    </button>
                    <button
                      className={`${tableStyles.ordBtnPrimary} btn`}
                      onClick={() => handleSubmit(true)}
                    >
                      Trasladar
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Drawer>
    );
  };

  const renderHeader = () => {
    return (
      <div className='row align-items-end'>
        <div className='col-4'>
          <input
            placeholder='Escribe aquí para buscar...'
            className={`${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} pl-2 w-100`}
            onChange={({ target }) => {
              setFilters(prev => ({ ...prev, search: target.value, page: 1 }));
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </div>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Quirófano inicial</span>
            </div>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            options={surgeryRoomOptions}
            onChange={option => {
              setTrigger(trigger + 1);
              setFilters(prev => ({ ...prev, currentConsultingRoom: option.value, page: 1 }));
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Cirujano</span>
            </div>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            options={doctorFilterOptions}
            onChange={option => {
              setTrigger(trigger + 1);
              setFilters(prev => ({ ...prev, surgeon: option.value, page: 1 }));
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Estado</span>
            </div>
          </Form.Label>
          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect}
            options={statusOptions}
            onChange={option => {
              setTrigger(trigger + 1);
              setFilters(prev => ({ ...prev, status: option.value, page: 1 }));
            }}
          />
        </Form.Group>
        <Form.Group className='col-2'>
          <Form.Label className={tableStyles.app_title_input}>
            <div className='ms-1'>
              <span className={tableStyles.app_title_new_item}>Fecha</span>
            </div>
          </Form.Label>
          <Form.Control
            type='date'
            value={filters.date}
            className='ord-roundInput'
            onChange={({ target }) =>
              setFilters(prev => ({ ...prev, date: target.value, page: 1 }))
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setTrigger(trigger + 1);
              }
            }}
          />
        </Form.Group>
      </div>
    );
  };

  const render = () => {
    return (
      <>
        <div className={`${tableStyles.tlnContainer}`}>
          <OrdGenericTemplate className='' titleSize={12} title={'Traslados de quirófano'}>
            <>
              <section className='pr-8 mt-2'>
                <>
                  {detailedClassificationLoader && loader}
                  {TransferSurgeryLoader && loader}
                  {statusLoader && loader}
                  {doctorLoader && loader}
                  {surgeryRoomLoader && loader}
                  {renderModal()}
                  <div>
                    {renderHeader()}
                    {renderTable()}
                  </div>
                </>
              </section>
            </>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
};

export default TransferSurgery;
