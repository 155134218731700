//-------------- components 🍕   --------------------------------------------
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { useHistory } from 'react-router-dom';
import { useGetMethod } from '../../Hooks/useFetch';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
//-------------- styles 🍕   --------------------------------------------
import tableStyles from '../../../src/components/Layouts/tableStyle.module.scss';
//-------------- icons 🍕   --------------------------------------------
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import Img from '../../assets/img/child-login.png';

function DoctorsDiaries() {
  //-------------- const 🍕   --------------------------
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(0);
  const { results: ListInfo, load: ListInfoLoader, trigger: getListInfo } = useGetMethod();
  const [filters, setFilters] = useState({
    search: '',
  });

  //-------------- useEffect 🍕   --------------------------

  useEffect(() => {
    getListInfo({
      url: '/medical/generals/doctors/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleSearch = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
  };

  //-------------- Principal return 🍕   --------------------------
  return (
    <div className={`${tableStyles.tlnContainer} mt-4`}>
      {ListInfoLoader && (
        <Loader className='loading' type='Oval' color='#003f80' height={100} width={100} />
      )}
      <OrdGenericTemplate showBottomBorder={false} title={'Médicos y agendas'}>
        <Row>
          <Col xs={6}>
            <Form.Group className='mb-3 text-start' controlId='fName'>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}></Form.Label>
              <form onSubmit={e => handleSearch(e)}>
                <div className={`d-flex ${tableStyles.tlnBlueInputSearch}`}>
                  <Form.Control
                    className={`${tableStyles.tlnRoundInputSearch}`}
                    type='text'
                    placeholder='Escribe aqui para buscar'
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                  />
                  <img
                    className='cursorPointer'
                    src={ordBlueSearch}
                    onClick={() => setTrigger(trigger + 1)}
                    alt={'search'}
                  ></img>
                </div>
              </form>
            </Form.Group>
          </Col>
        </Row>
        {ListInfo?.results?.length !== 0 ? (
          <>
            {ListInfo?.results?.map(elem => {
              return (
                <>
                  <div className={`${tableStyles.tlnMiniCard} d-flex py-2`}>
                    <div className='align-self-center px-2'>
                      <div>
                        <img
                          className='rounded-circle'
                          src={elem?.imageDoctor ? elem?.imageDoctor : Img}
                          alt=''
                          width='40'
                        />
                      </div>
                    </div>
                    <div className='align-self-center text-start px-2 col-3'>
                      <div>
                        <span className={`${tableStyles.tlnTextPrimary} fw-bold px-2`}>
                          {elem?.nameDoctor ? elem?.nameDoctor : '-'}
                        </span>
                      </div>
                    </div>

                    <div className='align-self-center text-start px-2 col-2'>
                      <div>
                        <span className={`${tableStyles.tlnTextGray} px-2`}>
                          {'R.M'}ㅤ{elem?.medicalRecord ? elem?.medicalRecord : '-'}
                        </span>
                      </div>
                    </div>

                    <div className='align-self-center px-2 col-2'>
                      <div>
                        <span className={`${tableStyles.tlnTextGray} px-2`}>
                          {elem?.typeDocument ? elem?.typeDocument : '-'}ㅤ
                          {elem?.document ? elem?.document : '-'}
                        </span>
                      </div>
                    </div>
                    <div className='align-self-center px-2 col-4'>
                      <div className='text-end'>
                        <button
                          onClick={() =>
                            history.push({
                              pathname: '/telemedicina/detalleHorario/',
                              state: {
                                idDoctor: elem?.idDoctor,
                                nameDoctor: elem?.nameDoctor,
                                imageDoctor: elem?.imageDoctor,
                              },
                            })
                          }
                        >
                          Horario
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          ''
        )}
      </OrdGenericTemplate>
    </div>
  );
}
export default DoctorsDiaries;
