import {
  GET_AUTH_STATUS,
  LOADING,
  GET_WAREHOUSE_ENTRIES,
  GET_WE_PROBLEMS,
  GET_PURCHASEORDER_INWAREHOUSE,
  GET_PROGRAMMING_INWAREHOUSE,
  GET_PROVIDRES_WITHPO,
  GET_ARTICLES_INWAREHOUSE,
  GET_WAREHOUSE_ENTRY_DETAIL,
} from '../actions/actionTypes';

const initialState = {
  warehouseEntries: [],
  row_total: 0,
  loading: false,
  problems: [],
  purchaseOrders: [],
  purchaseOrders_total: 0,
  programmings: [],
  programmings_total: 0,
  providers: [],
  articles: [],
  articles_total: 0,
  status: [],
  wEntry: {},
};

export const entryWarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WAREHOUSE_ENTRIES:
      return {
        ...state,
        warehouseEntries: action.payload,
        row_total: action.row_total,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_WE_PROBLEMS:
      return {
        ...state,
        problems: action.payload,
      };

    case GET_AUTH_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case GET_WAREHOUSE_ENTRY_DETAIL:
      return {
        ...state,
        wEntry: action.payload,
      };
    case GET_PURCHASEORDER_INWAREHOUSE:
      return {
        ...state,
        purchaseOrders: action.results,
        purchaseOrders_total: action.row_total,
        error: null,
        loading: action.loading,
      };

    case GET_ARTICLES_INWAREHOUSE:
      return {
        ...state,
        articles: action.results,
        articles_total: action.row_total,
      };

    case GET_PROGRAMMING_INWAREHOUSE:
      return {
        ...state,
        programmings: action.results,
        programmings_total: action.row_total,
      };

    case GET_PROVIDRES_WITHPO:
      return {
        ...state,
        providers: action.results,
      };
    default:
      return state;
  }
};
