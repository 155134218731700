import {
    SET_BLOCK_TEXTAREA
} from "../actions/actionTypes";


const initialState = {
    comment: ''
};


export const blockTextareaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BLOCK_TEXTAREA:
            return {
                ...state,
                comment: action.payload
            };

        default:
            return state;
    }

}
