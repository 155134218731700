import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import paperplane from '../../assets/img/icons/paperplane.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button } from 'react-bootstrap';
import Atras from '../../assets/img/icons/atras-icon.svg';
import { useHistory } from 'react-router-dom';
import ModalNew from '../../components/Layouts/ModalNew';
import NumberFormat from 'react-number-format';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import glossStyles from './Glosses.module.scss';
import { getHistory, getTypesNotes } from '../../actions/billingActions';
import { getOneGloss, postGlossReply, postNewNote } from '../../actions/GlossActions';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

let formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,
});

function CurrentGlossesDetails(props) {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  let history = useHistory();

  const myGloss = counter.glossReducer.chosen_gloss;

  const [objection, setObjection] = useState({
    noteValue: props.detail?.item?.total_amount ? props.detail?.item?.total_amount : 0,
  });
  const [controlNota, setControlNota] = useState({
    show: false,
  });

  useEffect(() => {
    dispatch(
      getTypesNotes({
        category: 4,
        status: 1,
        eaccount: counter.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlNota.show]);

  useEffect(() => {
    if (props?.detail?.item?.id) {
      dispatch(
        getOneGloss({
          id: props.detail.item.id,
          eaccount: counter.loginReducer.currentAccount.id,
        }),
      );
    }
    if (props?.detail?.item?.id_invoice) {
      dispatch(
        getHistory({
          id: props?.detail?.item?.id_invoice,
          eaccount: counter.loginReducer.currentAccount.id,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.detail?.item?.id]);

  let optionsNotes = [{ key: 'default', value: '', label: 'Seleccione Nota de cliente' }];
  if (Array.isArray(counter.billingReducer.note_types)) {
    counter.billingReducer.note_types.forEach(item => {
      optionsNotes.push({
        value: item.id,
        label: item.name,
        key: item.id + 'notes',
      });
    });
  }

  const myGlossInvoice = useMemo(() => {
    if (
      Array.isArray(counter.billingReducer.billing_history) &&
      counter.billingReducer.billing_history_total > 0
    ) {
      return counter.billingReducer.billing_history[0];
    } else {
      return counter.billingReducer.billing_history;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.billingReducer.billing_history_loading]);

  function handleClick() {
    if (props.goBack) {
      props.goBack();
    } else {
      history.push('/facturacion/Glosas/GlosasVigentes');
    }
  }

  const buttonOk = () => {
    let error = '';

    if (!!props?.detail?.item?.id === false) {
      error = 'No se selecciono una Glosa';
    }
    if (!!objection.action === false) {
      error = 'No se selecciono una accion';
    }
    if (!!objection.answer === false) {
      error = 'No se escribio una respuesta';
    }

    if (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: error,
      });
      return null;
    }

    dispatch(
      postGlossReply(
        {
          id: props.detail.item.id,
          action: objection.action,
          answer: objection.answer,
          eaccount: counter.loginReducer.currentAccount.id,
          user: counter.loginReducer.user_data.id,
        },
        () => {
          //handleClick()
          glossReplySweetAlert();
        },
      ),
    );
  };

  const glossReplySweetAlert = () => {
    if (objection.action === 'Conciliar' || objection.action === 'Aceptar') {
      MySwal.fire({
        icon: 'success',
        title: `Respuesta enviada`,
        text: `No. Factura ${props.detail?.item?.sequence}`,
        confirmButtonText: `Registrar Nota`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCloseButton: false,
      }).then(result => {
        if (result.isConfirmed) {
          registrarNota();
        } else {
          handleClick();
        }
      });
    } else {
      MySwal.fire({
        icon: 'success',
        title: `Respuesta enviada`,
        text: `No. Glosa ${props.detail?.item?.sequence}`,
      }).then(() => {
        handleClick();
      });
    }
  };

  const registrarNota = () => {
    setControlNota({
      ...controlNota,
      show: true,
    });
  };

  const GuardarNuevaNota = () => {
    dispatch(
      postNewNote(
        {
          client: myGloss.corporate_client,
          objType: myGloss.obj_type,
          invoice: myGloss.id_invoice,
          objection: props.detail.item.id,

          type: objection.memo_type,
          value: objection.noteValue,
          observations: objection.answer,

          eaccount: counter.loginReducer.currentAccount.id,
          user: counter.loginReducer.user_data.id,
        },
        () => {
          history.push('/facturacion/Glosas/HistorialDeGlosas');
        },
      ),
    );
  };

  const answerRender = () => {
    const answerArray = myGloss?.answers;
    let answerlist = [];

    if (Array.isArray(answerArray)) {
      answerArray.sort((a, b) =>
        a.created_at > b.created_at ? -1 : b.created_at > a.created_at ? 1 : 0,
      );
      answerArray.forEach((item, index) => {
        answerlist.push(
          <div key={index} className={glossStyles.sec3container}>
            <p className={glossStyles.sec3title}>
              {item.action === 'Respuesta del cliente'
                ? 'Respuesta recibida del cliente'
                : item.action}
            </p>
            <p className={glossStyles.sec3text}>{item.reply}</p>
            <div className={`d-flex text-start`}>
              <p className={glossStyles.sec3bottomtext}>{item.fullname}</p>
              <p className={glossStyles.sec3bottomtext}>{convertDateToLatinFormat(item.date)}</p>
            </div>
          </div>,
        );
      });
    }
    return answerlist;
  };
  return (
    <>
      {(counter.glossReducer.gloss_reply_loading || counter.glossReducer.gloss_note_loading) && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex' style={{ cursor: 'pointer' }}>
          <img alt='' className={`${tableStyles.title} mr-3`} src={Atras} onClick={handleClick} />
          <h1 className={tableStyles.title}>Gestión de Glosa</h1>
        </div>

        <div className={`${glossStyles.greysection} `}>
          <h2 className={`${glossStyles.subtitle} `}>Información de Factura</h2>

          <div className={`d-flex `}>
            <div className={`${glossStyles.item} ${glossStyles.f4} ${glossStyles.mr} `}>
              <label>Empresa</label>
              <input type='text' value={props.detail?.item?.clientName} disabled={true} />
            </div>

            <div className={`${glossStyles.item} ${glossStyles.f2}`}>
              <label>No. Factura</label>
              <input type='text' value={props.detail?.item?.invoice_nbr} disabled={true} />
            </div>
          </div>

          <div className={`d-flex `}>
            <div className={`${glossStyles.item} ${glossStyles.f4} ${glossStyles.mr} `}>
              <label>Tipo de glosa</label>
              <input
                type='text'
                value={props.detail?.item?.typeId + ' ' + props.detail?.item?.type}
                disabled={true}
              />
            </div>

            <div className={`${glossStyles.item} ${glossStyles.f2}`}>
              <label>Fecha de Glosa</label>
              <input
                type='text'
                value={convertDateToLatinFormat(props.detail?.item?.date)}
                disabled={true}
              />
            </div>
          </div>

          <div className={`d-flex `}>
            <div className={`d-flex ${glossStyles.f4} ${glossStyles.mr} `}>
              <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
                <label>Valor de la Glosa</label>
                <input
                  type='text'
                  value={formatter.format(props.detail?.item?.total_amount)}
                  disabled={true}
                />
              </div>

              <div className={`${glossStyles.item} ${glossStyles.f2}`}>
                <label>Responsable de Glosa</label>
                <input type='text' value={props.detail?.item?.objection_acc} disabled={true} />
              </div>
            </div>

            <div className={`${glossStyles.item} ${glossStyles.f2}`}>
              <label>Responsable de Registro</label>
              <input type='text' value={props.detail?.item?.registry_acc} disabled={true} />
            </div>
          </div>

          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Descripción</label>
            <textarea
              type='text'
              rows='3'
              value={props.detail?.item?.description}
              disabled={true}
            />
          </div>
        </div>

        <h2 className={`${glossStyles.subtitle} `}>Información de Respuesta</h2>

        <div className={`d-flex`}>
          <div className={`${glossStyles.item} ${glossStyles.f1} ${glossStyles.mr} `}>
            <label>Acción a realizar</label>

            <select
              className='select-register'
              name='id_consumption_center'
              value={objection.action}
              onChange={e => setObjection({ ...objection, action: e.target.value })}
            >
              <option value={''}> </option>
              <option value={'Rechazar'}>Rechazar</option>
              <option value={'Aceptar'}>Aceptar</option>
              <option value={'Conciliar'}>Conciliar</option>
              <option value={'Respuesta del Cliente'}>Respuesta del Cliente</option>
            </select>
          </div>

          {/*(objection.action==="Aceptar")?
                        <div className={`${glossStyles.item} ${glossStyles.f3}`}>
                            <label>Nota a registrar</label>
                            <select
                                className="select-register"
                                value={objection.noteRegister}
                                onChange={e=>setObjection({...objection, noteRegister: e.target.value})}
                            >
                                <option value={""}> </option>
                            </select>
                        </div>
                    :
                        <div className={`${glossStyles.item} ${glossStyles.f3}`}></div>
                    */}
          <div className={`${glossStyles.item} ${glossStyles.f3}`}></div>
        </div>

        {objection.action === 'Aceptar' && (
          <div className={`d-flex `}>
            <div className={`d-flex ${glossStyles.f4} ${glossStyles.mr} `}>
              <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
                <label>Valor Factura</label>
                <input
                  type='text'
                  value={formatter.format(myGlossInvoice?.total_amount)}
                  disabled={true}
                />
              </div>

              <div className={`${glossStyles.item} ${glossStyles.f2}`}>
                <label>Valor nota</label>
                <NumberFormat
                  allowNegative={false}
                  className={`register-inputs`}
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  value={objection.noteValue}
                  //onChange={e=>setObjection({...objection, noteValue: e.target.value})}
                  onValueChange={values => {
                    setObjection({
                      ...objection,
                      noteValue: values.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={`${glossStyles.item} ${glossStyles.f2}`}>
              <label>Saldo</label>
              <input
                type='text'
                value={formatter.format(myGlossInvoice?.total_amount - objection.noteValue)}
                disabled={true}
              />
            </div>
          </div>
        )}

        <div className={`${glossStyles.item}`}>
          <label>Respuesta</label>
          <textarea
            type='text'
            rows='3'
            value={objection.answer}
            onChange={e => setObjection({ ...objection, answer: e.target.value })}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 16 }}>
          <Button
            className={`${tableStyles.buttonExtra3}`}
            onClick={() => buttonOk()}
            disabled={!objection.action || !objection.answer}
          >
            <img alt='' src={paperplane} />
            <span>Enviar</span>
          </Button>
        </div>

        <div className={`d-flex flex-column`} style={{ paddingBottom: '20px' }}>
          <p className={glossStyles.sec3title}>Acciones Realizadas</p>
          {answerRender()}
        </div>
      </div>

      <ModalNew
        show={controlNota.show}
        onHide={() => setControlNota({ ...controlNota, show: false })}
        title={'Registrar Nota'}
        btnYesEvent={() => GuardarNuevaNota()}
        btnYesName={'Guardar'}
        size={'750'}
        btnYesDisabled={!objection.noteValue} // {!objection.memo_type || !objection.noteValue}
      >
        <div className='d-flex'>
          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Empresa</label>
            <input
              type='text'
              className='register-inputs'
              value={myGloss?.clientName + ' - ' + myGloss?.clientNit}
              disabled={true}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Tipo de Glosa</label>
            <input
              type='text'
              className='register-inputs'
              value={myGloss?.typeId + ' - ' + myGloss?.type}
              disabled={true}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
            <label>No. Factura</label>
            <input
              type='text'
              className='register-inputs'
              value={myGloss?.invoice_nbr}
              disabled={true}
            />
          </div>
          <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
            <label>Fecha Glosa</label>
            <input
              type='text'
              className='register-inputs'
              value={convertDateToLatinFormat(myGloss?.created_at)}
              disabled={true}
            />
          </div>
          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Valor de la Glosa</label>
            <input
              type='text'
              className='register-inputs'
              value={formatter.format(myGloss?.total_amount)}
              disabled={true}
            />
          </div>
        </div>

        <div className='d-flex'>
          <div className={`${glossStyles.item}  ${glossStyles.f2}`}>
            <label>Descripción</label>
            <textarea type='text' rows='3' value={myGloss?.description} disabled={true} />
          </div>
        </div>
        <div className='d-flex'>
          <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
            <label>Nota del Cliente</label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={optionsNotes}
              styles={customSelectNewDark}
              placeholder={''}
              onChange={e =>
                setObjection({
                  ...objection,
                  memo_type: e.value,
                })
              }
            />
          </div>
          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Valor Factura</label>
            <input
              type='text'
              className='register-inputs'
              value={formatter.format(myGlossInvoice?.total_amount)}
              disabled={true}
            />
          </div>
        </div>
        <div className='d-flex'>
          <div className={`${glossStyles.item} ${glossStyles.f2} ${glossStyles.mr} `}>
            <label>Valor Nota</label>
            <NumberFormat
              allowNegative={false}
              className='register-inputs'
              prefix='$'
              thousandsGroupStyle='thousand'
              thousandSeparator
              value={objection.noteValue}
              onValueChange={values => {
                setObjection({
                  ...objection,
                  noteValue: values.value,
                });
              }}
            />
          </div>
          <div className={`${glossStyles.item} ${glossStyles.f2}`}>
            <label>Saldo</label>
            <input
              type='text'
              className='register-inputs'
              value={formatter.format(myGlossInvoice?.total_amount - objection.noteValue)}
              disabled={true}
            />
          </div>
        </div>
      </ModalNew>
    </>
  );
}
export default CurrentGlossesDetails;
