// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { GET_REPAYMENT } from '../../actions/actionTypes';
import ModalNew from '../Layouts/ModalNew';

// --------------- icons 💥  ----------------//
import Back from '../../assets/img/icons/atras-icon.svg';
import LupaBlue from '../../assets/img/icons/lupa.svg';
import Lupa from '../../assets/img/icons/lupaTransparent.svg';

//-------------- scss styles 😄   --------------
import { DetailRepayment, getDetailExpense } from '../../actions/accountingActions';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { generateId } from '../../helpers';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import Styles from './pettyCashStyles.module.scss';

function RepaymentHistoryDetail(props) {
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [info, setInfo] = useState({
    search2: '',
    dateFrom2: '',
    dateUntil2: '',
    entity_account: storage.loginReducer.currentAccount.id,
  });

  const [showModal, setShowModal] = useState({
    show: false,
    id: '',
  });

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const bodyTemplate = () => {
    let body = [];
    for (let i = 0; i < 10; i++) {
      let bodyTemp = (
        <section2 className={`d-flex`}>
          <div className={`col-3`}>-</div>
          <div className={`col-5`}>-</div>
          <div className={`col-3`}>-</div>
          <div className={`col-1`}>-</div>
        </section2>
      );
      body.push(bodyTemp);
    }
    return body;
  };
  //--------------first functions 🍕   --------------

  useEffect(() => {
    if (props.selectedRepayment) {
      dispatch(
        DetailRepayment({
          eaccount: storage.loginReducer.currentAccount.id,
          id: props.selectedRepayment,
          search2: info.search2,
          dateFrom2: info.dateFrom2,
          dateUntil2: info.dateUntil2,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRepayment, trigger, dispatch]);

  useEffect(() => {
    if (showModal.id) {
      dispatch(
        getDetailExpense({
          eaccount: storage.loginReducer.currentAccount.id,
          id: showModal.id,
        }),
      );
    }
  }, [showModal.id, dispatch, storage.loginReducer.currentAccount.id]);

  //-------------- Table 🍕   --------------

  const header = [
    <div key={generateId()} className={`col-3`}>
      Fecha
    </div>,
    <div key={generateId()} className={`col-5 text-start`}>
      Detalle
    </div>,
    <div key={generateId()} className={`col-3 text-end`}>
      Valor
    </div>,
    <div key={generateId()} className={`col-1`}>
      &nbsp;
    </div>,
  ];
  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingReducer.detailRepayment.expenses)) {
      storage.accountingReducer.detailRepayment.expenses.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className={`col-3`}>
              {item.date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </div>
            <div className={`col-5 text-start`}>{item.description}</div>
            <div className={`col-3 text-end`}>
              {item.amount ? '$' + numberWithCommas(item.amount) : ''}
            </div>
            <div className={`col-1`}>
              <img
                className={`w-40 ${IndividualStyles.pointer}`}
                style={{ width: '16px', height: '16px' }}
                onClick={() => setShowModal({ show: true, id: item.id })}
                src={Lupa}
                alt='icon'
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  return (
    <div className='d-flex flex-column mx-2 p-2 container-fluid mx-auto'>
      <div className='d-flex'>
        <div className='align-self-top pt-1 mx-4'>
          <img
            src={Back}
            alt='volver'
            width={24}
            className='hoverPointer '
            style={{ marginTop: '52px' }}
            onClick={() => {
              props.setDetails({ show: false });
              dispatch({
                type: GET_REPAYMENT,
                payload: [],
                total: 0,
              });
            }}
          />
        </div>
        <div className='d-flex flex-column w-90'>
          <h1 className={tableStyles.title}>Historial de reembolso</h1>
          <Row>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={tableStyles.crudModalLabel}>Fecha de</label>
                <input
                  type='date'
                  name='fromDate'
                  value={info.dateFrom2}
                  onChange={e =>
                    setInfo({
                      ...info,
                      dateFrom2: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsBlue}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={3}>
              <div className='display-grid mt-1'>
                <label className={tableStyles.crudModalLabel}>Fecha hasta</label>
                <input
                  type='date'
                  name='untilDate'
                  value={info.dateUntil2}
                  onChange={e =>
                    setInfo({
                      ...info,
                      dateUntil2: e.target.value,
                    })
                  }
                  className={IndividualStyles.registerInputsBlue}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                />
              </div>
            </Col>
            <Col xs={5}>
              <div className='display-grid mt-1'>
                <label className={`${tableStyles.crudModalLabel}`}>ㅤ</label>
                <input
                  className={tableStyles.SearchNew}
                  style={{ height: '27px' }}
                  placeholder='Buscar...'
                  type='text'
                  value={info.search2}
                  onChange={e =>
                    setInfo({
                      ...info,
                      search2: e.target.value,
                    })
                  }
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      setTrigger(trigger + 1);
                    }
                  }}
                ></input>
              </div>
            </Col>
            <Col xs={1}>
              <div className='display-grid mt-1'>
                <label className={tableStyles.crudModalLabel}>ㅤ</label>
                <i>
                  <img
                    src={LupaBlue}
                    onClick={() => setTrigger(trigger + 1)}
                    alt=''
                    height='24px'
                    className='cursorPointer'
                  />
                </i>
              </div>
            </Col>
          </Row>

          <GenericTableScroll
            headers={header}
            dark={true}
            body={
              Array.isArray(storage.accountingReducer.detailRepayment.expenses)
                ? renderList()
                : bodyTemplate()
            }
            typeHead={'2'}
          ></GenericTableScroll>

          <Row>
            <Col xs={10}></Col>
            <Col xs={2}>
              <div className='display-grid mt-1'>
                <label className={tableStyles.crudModalLabel}>Total gastos</label>
                <NumberFormat
                  allowNegative={false}
                  style={{
                    padding: 0,
                    color: '#58595B',
                    background: '#F5F7FA',
                    textAlign: 'end',
                    paddingRight: '4px',
                  }}
                  className={IndividualStyles.registerInputs}
                  name='valueNote'
                  thousandsGroupStyle='thousand'
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'$'}
                  value={storage.accountingReducer?.detailRepayment?.totalAmount}
                ></NumberFormat>
              </div>
            </Col>
          </Row>

          <div>
            <ModalNew
              show={showModal.show}
              onHide={() => {
                setShowModal({ show: false });
              }}
              title={'Detalle de reembolso'}
              btnNoEvent={() => {
                setShowModal({ show: false });
              }}
              btnNoName={'Aceptar'}
              size={'600'}
            >
              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={tableStyles.crudModalLabel}>Caja menor</p>
                  <input
                    type='text'
                    name='cash'
                    value={storage.accountingReducer.detailExpense?.pettyCashName}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={tableStyles.crudModalLabel}>Detalle</p>
                  <input
                    type='text'
                    name='details'
                    value={storage.accountingReducer.detailExpense?.description}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Fecha reembolso</p>
                  <input
                    type='text'
                    name='dateReembolso'
                    style={{ color: '#58595B' }}
                    value={storage.accountingReducer.detailExpense?.repaymentDate?.replace(
                      /^(\d{4})-(\d{2})-(\d{2})$/g,
                      '$3/$2/$1',
                    )}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>

                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Valor reembolso</p>
                  <Tooltip
                    title={
                      storage.accountingReducer.detailExpense?.amount
                        ? '$' + numberWithCommas(storage.accountingReducer.detailExpense?.amount)
                        : ''
                    }
                    arrow
                    placement='top-end'
                  >
                    <NumberFormat
                      allowNegative={false}
                      style={{
                        padding: 0,
                        color: '#58595B',
                        background: '#F5F7FA',
                        textAlign: 'end',
                        paddingRight: '4px',
                      }}
                      className={IndividualStyles.registerInputsClearBlue}
                      name='valueNote'
                      thousandsGroupStyle='thousand'
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      prefix={'$'}
                      value={storage.accountingReducer.detailExpense?.amount}
                    ></NumberFormat>
                  </Tooltip>
                </Col>
              </Row>
              <Row className='d-flex' style={{ marginBottom: '0px', verticalAlign: 'middle' }}>
                <Col>
                  <p
                    className={Styles.crudModalLabelBlue}
                    style={{ marginLeft: '0px', marginBottom: '0px' }}
                  >
                    Información Contable
                  </p>
                </Col>
              </Row>

              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} mt-0 `}>Tipo de gasto</p>
                  <input
                    type='text'
                    name='details'
                    value={storage.accountingReducer.detailExpense?.expTypeName}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={tableStyles.crudModalLabel}>Cuenta</p>
                  <input
                    type='text'
                    name='details'
                    value={storage.accountingReducer.detailExpense?.niifAccountNbr}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={tableStyles.crudModalLabel}>Tercero</p>
                  <input
                    type='text'
                    name='details'
                    value={storage.accountingReducer.detailExpense?.thirdPartyName}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row className='d-flex' style={{ marginBottom: '7px' }}>
                <Col xs={12}>
                  <p className={tableStyles.crudModalLabel}>Centro de costo</p>
                  <input
                    type='text'
                    name='details'
                    value={storage.accountingReducer.detailExpense?.ccName}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row>
                <Col
                  className='d-flex'
                  style={{ marginBottom: '0px', verticalAlign: 'middle', marginTop: '16px' }}
                >
                  <p
                    className={Styles.crudModalLabelBlue}
                    style={{ marginLeft: '0px', marginBottom: '0px' }}
                  >
                    Registro
                  </p>
                </Col>
              </Row>

              <Row className='d-flex'>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Registrado por</p>
                  <Tooltip title={storage.accountingReducer.detailExpense?.creatorName} arrow>
                    <input
                      type='text'
                      name='registerBy'
                      value={storage.accountingReducer.detailExpense?.creatorName}
                      className={IndividualStyles.registerInputsClearBlue}
                      disabled={true}
                    />
                  </Tooltip>
                </Col>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Fecha</p>
                  <input
                    type='text'
                    name='date2'
                    style={{ color: '#58595B' }}
                    value={storage.accountingReducer.detailExpense?.createdDate?.replace(
                      /^(\d{4})-(\d{2})-(\d{2})$/g,
                      '$3/$2/$1',
                    )}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
                <Col xs={4}>
                  <p className={tableStyles.crudModalLabel}>Hora</p>
                  <input
                    type='text'
                    name='hour'
                    value={storage.accountingReducer.detailExpense?.createdTime}
                    className={IndividualStyles.registerInputsClearBlue}
                    disabled={true}
                  />
                </Col>
              </Row>
            </ModalNew>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RepaymentHistoryDetail;
