//costcenter' reducer

import {
  COSTCENTER_GET,
  COSTCENTER_GETONE,
  COSTCENTER_EDIT,
  COSTCENTER_POST,
  GET_ALL_COST_CENTER,
  COSTCENTER_LOAD,
} from "../actions/actionTypes";

const initialState = {
  cc: [{ id: 1 }],
  fu: [],
  cost_center: [],
  chosen_cc: {},
  total: 0,
  error: null,
  Authorization: "",
  loading: false,
};
export const costcenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case COSTCENTER_LOAD:
      return {
        ...state,
        loading: action.loading,
      };
    case COSTCENTER_GET:
      return {
        ...state,
        cc: action.results,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };

    case GET_ALL_COST_CENTER:
      return {
        ...state,
        cost_center: action.payload,
        error: null,
        Authorization: action.Authorization,
      };
    case COSTCENTER_GETONE:
      return {
        ...state,
        chosen_cc: action.payload,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
      };

    case COSTCENTER_EDIT:
      return {
        ...state,
        error: null,
        Authorization: action.Authorization,
      };

    case COSTCENTER_POST:
      return {
        ...state,
        error: null,
        Authorization: action.Authorization,
      };

    default:
      return state;
  }
};
