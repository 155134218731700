import Provider from '../../assets/img/icons/boxprovider.svg';
import Employ from '../../assets/img/icons/Empleados.svg';
import Client from '../../assets/img/icons/accountSelectIconClear.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import '../../TreasuryModule/Consultation/Consultation.scss';
import { useHistory } from 'react-router-dom';
import { Title } from '../../shared';

function ThirdPartyAccounting() {
  let history = useHistory();
  function handleClickDocumento() {
    history.push('/contabilidad/terceros/proveedores');
  }
  function handleClickNotaBancaria() {
    history.push({
      pathname: '/contabilidad/terceros/empleados',
      state: {
        employees: true,
      },
    });
  }
  function handleClickReconsignaciones() {
    history.push({
      pathname: '/contabilidad/terceros/clientesEmpresas',
      state: {
        accounting: true,
      },
    });
  }

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className={tableStyles.title}>
          <Title
            title='Terceros'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <h4 className='text-secondary'>Selecciona el tipo de consulta a realizar</h4>

        <Row className='p-5 d-flex' style={{ justifyContent: 'center' }}>
          <Col xs={3} className='pt-5'>
            <button className='botn' onClick={handleClickDocumento}>
              <div className='col-auto p-4 text-center'>
                <img src={Provider} alt='' srcSet='' />
                <p className='text-secondary'>Proveedores</p>
              </div>
            </button>
          </Col>
          <Col xs={3} className='pt-5'>
            <button className='botn' onClick={handleClickNotaBancaria}>
              <div className='col-auto p-4 text-center'>
                <img src={Employ} alt='' srcSet='' />
                <p className='text-secondary'>Empleados</p>
              </div>
            </button>
          </Col>
          <Col xs={3} className='pt-5'>
            <button className='botn' onClick={handleClickReconsignaciones}>
              <div className='col-auto p-4 text-center'>
                <img src={Client} alt='' srcSet='' />
                <p className='text-secondary'>Clientes - Empresas</p>
              </div>
            </button>
          </Col>
          {/* <Col xs={3} className="pt-5">
            <button className="botn" onClick={handleClickEgreso}>
              <div className="col-auto p-4 text-center">
                <img src={Patient} alt="" srcset="" />
                <p className="text-secondary">Clientes - Paciente</p>
              </div>
            </button>
          </Col> */}
        </Row>
      </div>
    </>
  );
}
export default ThirdPartyAccounting;
