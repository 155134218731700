export const findDuplicates = (arrayObject, llave) => {
    let repetidos = []

    if (!arrayObject.length) {
        return []
    }

    for (let i = 0; i < arrayObject.length; i++) {
        for (let j = 0; j < arrayObject.length; j++) {
            if (i !== j && arrayObject[i][llave] === arrayObject[j][llave]) {
                repetidos.push(arrayObject[i])
            }
        }

    }

    return repetidos

}