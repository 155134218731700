import {
    ON_SET_ACTIVE_EVENT_PATIENT, 
    ON_SET_CLEAR_ADMISSION_PATIENT, 
    ON_SET_EVENTS_PATIENT, 
    ON_SET_IMAGE_PATIENT
} from "../actions/actionTypes";


const initialState = {
    events: [],
    activeEvent: null,
    image: ''
};


export const patientAdmissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_SET_ACTIVE_EVENT_PATIENT:
            return {
                ...state,
                activeEvent: action.payload
            };
        case ON_SET_EVENTS_PATIENT:
            return {
                ...state,
                events: action.payload,

            };
        case ON_SET_IMAGE_PATIENT:
            return {
                ...state,
                image: action.payload,
            };
        case ON_SET_CLEAR_ADMISSION_PATIENT:
            return {
                events: [],
                activeEvent: null,
                image:''
            };

        default:
            return state;
    }

}
