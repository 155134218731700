
import {
    GLOSS_REGISTER,
    GLOSS_POST_REPLY,
    GLOSS_POST_NOTE,
    GET_SETTLED_BILLING,
    GET_GLOSS_TYPE,
    GET_GLOSS,
    GET_ONE_GLOSS,
}   from "../actions/actionTypes";
  
const initialState = {
    glosses:[],
    glosses_total: 0,
    glosses_loading: false,

    chosen_gloss: {},
    chosen_gloss_loading: false,

    facturas_radicadas:[],
    gloss_types:[],

    gloss_register_loading: false,
    gloss_reply_loading: false,
    gloss_note_loading: false,
};

export const glossReducer = (state = initialState, action) => {
    switch (action.type) {
        case GLOSS_REGISTER:
            return {
                ...state,
                gloss_register_loading: action.loading,
            };
        case GLOSS_POST_REPLY:
            return {
                ...state,
                gloss_reply_loading: action.loading,
            };
        case GLOSS_POST_NOTE:
            return {
                ...state,
                gloss_note_loading: action.loading,
            };

        case GET_SETTLED_BILLING:
            return {
                ...state,
                facturas_radicadas: action.payload,
            };
        case GET_GLOSS_TYPE:
            return {
                ...state,
                gloss_types: action.payload,
            };
        case GET_GLOSS:
            return {
                ...state,
                glosses: action.payload,
                glosses_total: action.total,
                glosses_loading: action.loading,
            }
        case GET_ONE_GLOSS:
            return {
                ...state,
                chosen_gloss: action.payload,
                chosen_gloss_loading: action.loading,
            }

        default:
            return state;
    }
};
  