import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OrdAccordion } from '../OrderingModule/OrderingComponents/OrdAccordion';
import { OrdGenericTemplate } from '../OrderingModule/OrderingComponents/OrdGenericTemplate';
import threeDots from '../assets/img/icons/threeDots.svg';
import { useGetMethod, usePostMethod } from '../Hooks/useFetch';
import { loader, message } from '../helpers/helpers';
import { useSelector } from 'react-redux';
import tableStyles from '../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial } from '../helpers/customSwaltAlertAsistencial';

export const SurgeryCombos = () => {
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const [trigger, setTrigger] = useState(1);
  const [close, setClose] = useState(true);
  const [filters, setFilters] = useState({
    idAccount: store.loginReducer.currentAccount.id,
    search: '',
  });
  const {
    results: medicationCombo,
    load: comboLoader,
    trigger: getMedicationCombo,
  } = useGetMethod();
  const { load: updateComboLoader, trigger: updateCombo } = usePostMethod();
  useEffect(() => {
    getMedicationCombo({
      url: '/medical/medicationCombo/',
      objFilters: filters,
      requestName: 'dosis',
      token: token,
    });
    if (close) {
      setClose(false);
    } else {
      setClose(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);
  const body = data => {
    let tempList = [];
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        tempList.push(
          <tr key={index + 'medicationCombo'} className={``}>
            <td className='col-1 px-1 text-secondary'>{item.name} </td>
            <td className='col-2 px-1 text-secondary'>
              {item.mcdQtyPresentation +
                ' ' +
                item.mcdPresentationDescription +
                ' ' +
                item.mdcQtyPerPresentation +
                ' ' +
                item.mdcOrderUnitLabel}{' '}
            </td>
            <td className='col-1 px-1 text-secondary'>{item?.dboName} </td>
            <td className='col-2 px-1 text-secondary'>
              {'cada ' + item.mcdQtyFrequency + ' ' + item.mcdFrequencyDescription}{' '}
            </td>
            <td className='col-2  text-secondary'>
              {'durante ' + item.mcdQtyPeriod + ' ' + item.mcdPeriodDescription}{' '}
            </td>
            <td className='col-3 text-secondary'
            style={{textAlign:'center'}}>{' ' + item?.mcdObservations} </td>
          </tr>,
        );
      });
      return tempList;
    }
  };
  const footer = data => {
    let x = (
      <div className='pt-4'>
        <b className='text-secondary'>Recomendaciones</b>
        <hr style={{ margin: '0px' }} />
        <label className='text-secondary'>{data}</label>
      </div>
    );
    return x;
  };
  const handleSubmit = data => {
    let statusEs = data?.mecStatus === 'enabled' ? 'habilitado' : 'deshabilitado';
    updateCombo({
      url: '/medical/medicationCombo/',
      token: token,
      method: 'PUT',
      body: data,
      doAfterSuccess: () => {
        setTrigger(trigger + 1);
      },
      succesAction: (res) =>
        message(
          'success',
          `<span class=${tableStyles.ordClearBlueText}>${res?.message} </span>`,
          `Se ha ${statusEs} ${data?.name} exitosamente`,
        ),
    });
  };
  return (
    <>
      {comboLoader && loader}
      {updateComboLoader && loader}
      <OrdGenericTemplate
        className='w-90'
        showBottomBorder={false}
        title={'Combos de cirugía'}
        buttonNewLabel={'Nuevo combo'}
        buttonNewAction={() => history.push('/asistencial/CombosDeCirugia/nuevo')}
        searchWithNew={{
          colSize: '12',
          WSize: '90',
          buttonNewLabel: 'Nuevo combo',
          onChange: e => {
            setFilters({ ...filters, search: e.target.value });
          },
          buttonNewAction: () => history.push('/asistencial/CombosDeCirugia/nuevo'),
          onSubmit: e => {
            e.preventDefault();
            setTrigger(trigger + 1);
          },
        }}
      >
        <div className='w-90 mx-1'>
          {medicationCombo?.results?.length > 0 &&
            medicationCombo.results.map(item => {
              return (
                <OrdAccordion
                  key={item?.id}
                  triggerSrc={threeDots}
                  title={item?.name}
                  subtitle={'Médico'}
                  subtitleVal={item?.doctor?.name ? item?.doctor?.name : '-'}
                  editClickEvent={() =>
                    history.push({
                      pathname: '/asistencial/CombosDeCirugia/nuevo',
                      state: {
                        isEditing: true,
                        combo: item,
                      },
                    })
                  }
                  enableClickEvent={() =>
                    customSwaltAlertAsistencial({
                      icon: 'warning',
                      title:'¿Está seguro?',
                      text: `Se
                        ${item?.status?.staValue === 'enabled'
                        ? 'deshabilitará'
                        : 'habilitará'}
                        el combo de cirugía: Combo para la migraña`,
                      confirmButtonColor: '#3085d6',

                      confirmButtonText:
                      item?.status?.staValue === 'enabled' ? 'Si, deshabilitar' : 'Si, habilitar',
                      cancelButtonColor: '#d33',
                      cancelButtonText: 'Cancelar',
                      showCancelButton: true,
                    }).then(result => {
                      if (result.isConfirmed) {
                        let newStatus =
                          item?.status?.staValue === 'enabled' ? 'disabled' : 'enabled';
                        handleSubmit({ id: item.id, mecStatus: newStatus, name: item.name });
                      }
                    })
                  }
                  isEnabled={item?.status?.staValue === 'enabled' ? true : false}
                  status={item?.status?.staDescription}
                  fontcolor={item?.status?.fontColor}
                  backgroundColor={item?.status?.bgColor}
                  body={body(item?.medicine)}
                  footer={footer(item?.suggestions)}
                ></OrdAccordion>
              );
            })}
        </div>
      </OrdGenericTemplate>
    </>
  );
};
