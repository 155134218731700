import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';
import { AddCircleOutline, Close } from '@material-ui/icons';
import Pagination from 'react-js-pagination';
//
import ThreeDots from '../../../assets/img/icons/threeDots.svg';
//
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { customSwaltAlert, loader } from '../../../helpers';
//
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

export default function Receivable() {
  const store = useSelector(state => state);
  const history = useHistory();

  const [filters, setFilters] = useState({
    companyId: null,
    contractId: null,
    modality: '',
    page: 1,
    perpage: 10,
  });
  const [duplicateModal, setDuplicateModal] = useState({
    isOpen: false,
    payload: null,
  });

  const { trigger: getCompanies, results: companiesSelect } = useGetMethod();
  const { trigger: getContracts, results: contractsSelect } = useGetMethod();
  const { trigger: getMatchContract, results: matchContract } = useGetMethod();

  const {
    trigger: getCompanyParams,
    results: companyParams,
    load: companyParamsLoader,
  } = useGetMethod();

  const { trigger: duplicateCompanyParams, load: duplicateLoader } = usePostMethod();
  const { trigger: updateStatus, load: updateStatusLoader } = usePostMethod();

  const onCloseDuplicateModal = () => {
    setDuplicateModal({ isOpen: false, payload: null });
  };

  const onDuplicateParams = () => {
    if (duplicateModal.payload) {
      duplicateCompanyParams({
        token: store.loginReducer.Authorization,
        body: duplicateModal.payload,
        method: 'POST',
        url: '/receivable/companyParams/duplicate/',
        succesAction: () => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha duplicado la parametrización de la empresa: ${duplicateModal.payload.companyName}`,
            confirmButtonText: 'Aceptar',
          }).then(async response => {
            if (response.isConfirmed) {
              await getCompanyParams({
                url: '/receivable/companyParams/',
                objFilters: {
                  ...filters,
                  eaccount: store.loginReducer.currentAccount.id,
                },
                token: store.loginReducer.Authorization,
              });
            }
            setDuplicateModal({ isOpen: false, payload: null });
          });
        },
        doAfterException: results => {
          customSwaltAlert({
            showCancelButton: false,
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: results.message,
            confirmButtonText: 'Aceptar',
          });
        },
      });
    }
  };

  const onUpdateStatus = item => {
    updateStatus({
      token: store.loginReducer.Authorization,
      body: {
        id: item.id,
        status: item.status === '1' ? 0 : 1,
        eaccount: store.loginReducer.currentAccount.id,
      },
      method: 'PUT',
      url: '/receivable/companyParams/',
      succesAction: () => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha ${
            item.status === '1' ? 'deshabilitado' : 'habilitado'
          } la parametrización de empresa: ${item.companyName}`,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            await getCompanyParams({
              url: '/receivable/companyParams/',
              objFilters: {
                ...filters,
                eaccount: store.loginReducer.currentAccount.id,
              },
              token: store.loginReducer.Authorization,
            });
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const renderModal = () => {
    const formattedMatchContracts =
      matchContract?.results?.map(con => ({
        value: con.id,
        label: con.companyName,
      })) || [];

    const c =
      matchContract?.results
        ?.find(item => item.id === duplicateModal.payload?.companyTo)
        ?.contractsList?.map(item => ({
          value: item.contractId,
          label: item.contractName,
        })) || [];

    const currentContracts = [{ label: 'Seleccionar...', value: '' }, ...c];

    return (
      <Modal
        show={duplicateModal.isOpen}
        centered
        className={`${modalStyles.container}`}
        dialogClassName={modalStyles.modal_width_md}
        onHide={onCloseDuplicateModal}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>Duplicar</h3>
          <Close className='pointer text-secondary' onClick={onCloseDuplicateModal} />
        </div>
        <hr className='m-0' />
        <div className='py-3 px-4'>
          <div className='p-3 d-flex flex-column'>
            <h5 className={`${tableStyles.text_primary_alt} m-0 fw-bold mb-2`}>Origen</h5>
            <div className='row'>
              <div className='col'>
                <Form.Group className='me-3'>
                  <small className='text-primary ms-1'>
                    <span>Empresa</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <Form.Control
                    className='register-inputs'
                    defaultValue={duplicateModal.payload?.companyName}
                    disabled
                  />
                </Form.Group>
              </div>
              <div className='col'>
                <Form.Group className='me-3'>
                  <small className='text-primary ms-1'>
                    <span>Contrato</span>
                    <span className='text-danger'>*</span>
                  </small>
                  <Form.Control
                    className='register-inputs'
                    defaultValue={duplicateModal.payload?.contractName}
                    disabled
                  />
                </Form.Group>
              </div>
            </div>
            <h5 className={`${tableStyles.text_primary_alt} m-0 fw-bold mb-2 mt-4`}>Destino</h5>
            <div className='row'>
              <div className='col'>
                <Form.Group className='me-3'>
                  <small className='text-primary ms-1'>Empresa</small>
                  <ReactSelect
                    noOptionsMessage={() => 'No hay datos'}
                    options={[{ label: 'Seleccionar...', value: '' }, ...formattedMatchContracts]}
                    onChange={option =>
                      setDuplicateModal(prev => ({
                        ...prev,
                        payload: {
                          ...prev.payload,
                          companyTo: option.value,
                          contractTo: '',
                          contractName: '',
                        },
                      }))
                    }
                    styles={customSelectNewDark}
                    placeholder='Seleccionar...'
                  />
                </Form.Group>
              </div>
              <div className='col'>
                <Form.Group className='me-3'>
                  <small className='text-primary ms-1'>Contrato</small>
                  <ReactSelect
                    noOptionsMessage={() => 'No hay datos'}
                    styles={customSelectNewDark}
                    options={currentContracts}
                    onChange={option =>
                      setDuplicateModal(prev => ({
                        ...prev,
                        payload: { ...prev.payload, contractTo: option?.value },
                      }))
                    }
                    value={currentContracts?.find(
                      item => item.value === duplicateModal.payload?.contractTo,
                    )}
                    placeholder='Seleccionar...'
                  />
                </Form.Group>
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-center mt-4'>
              <button
                onClick={onCloseDuplicateModal}
                className={`${tableStyles.btnSecondary} me-3`}
              >
                Cancelar
              </button>
              <button
                className={`${tableStyles.btnPrimaryAlt}`}
                disabled={!duplicateModal.payload?.companyTo || !duplicateModal.payload?.contractTo}
                onClick={onDuplicateParams}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderFilters = () => {
    const formattedCompaniesSelect =
      companiesSelect?.results?.map(com => ({
        value: com.id,
        label: com.copyName,
      })) || [];
    const formattedContractsSelect = [
      { value: '', label: 'Seleccionar...' },
      ...(contractsSelect?.results?.map(com => ({
        value: com.id,
        label: com.contract_name,
      })) || []),
    ];
    const formattedModalities = [
      { label: 'Individual', value: 'individual' },
      { label: 'Grupal', value: 'group' },
    ];

    return (
      <div className='d-flex align-items-end'>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Empresa</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={[{ value: '', label: 'Seleccionar...' }, ...formattedCompaniesSelect]}
            onChange={option =>
              setFilters({
                ...filters,
                companyId: option.value,
                contractId: '',
              })
            }
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Contrato</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            styles={customSelectNewDark}
            options={filters?.companyId ? formattedContractsSelect : []}
            onChange={option => setFilters(prev => ({ ...prev, contractId: option.value }))}
            value={formattedContractsSelect.find(el => el.value === filters.contractId)}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <Form.Group className='me-3' style={{ width: '20%' }}>
          <small className='text-primary ms-1'>Modalidad</small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            styles={customSelectNewDark}
            options={[{ value: null, label: 'Seleccionar...' }, ...formattedModalities]}
            onChange={option => setFilters(prev => ({ ...prev, modality: option.value }))}
            placeholder='Seleccionar...'
          />
        </Form.Group>
        <div className='flex-grow-1'></div>
        <div
          className={tableStyles.buttonTextPrimary}
          onClick={() => history.push('/administracion/TMDeCartera/Parametrizacion/Crear')}
        >
          <small className='mr-2'>Crear parametrización</small>
          <AddCircleOutline stroke='2' fontSize='small' />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-3'>
        Empresa cliente
      </th>,
      <th key={2} className='text-start'>
        Contrato
      </th>,
      <th key={3} className='text-center'>
        Documentos
      </th>,
      <th key={4} className='text-center'>
        Tipo de radicación
      </th>,
      <th key={5} className='text-center'>
        Modalidad 2
      </th>,
      <th key={6} className='text-center'>
        Estado
      </th>,
      <th key={7} className='text-center'>
        &nbsp;
      </th>,
    ];

    return (
      <GenericTableNew headers={tableHeaders} dark={true}>
        {companyParams?.results?.map(item => (
          <tr key={item.id}>
            <td className='text-start ps-3'>{item.companyName}</td>
            <td className='text-start'>{item.contractName}</td>
            <td>{item.documentsQuantity}</td>
            <td className='text-center'>{item.typeFiling === 'on_site' ? 'Física' : 'Virtual'}</td>
            <td>{item.modality === 'group' ? 'Grupal' : 'Individual'}</td>
            <td>
              <div
                className={`${item.status === '1' ? tableStyles.greenState : tableStyles.redState}`}
              >
                {item.status === '1' ? 'Habilitado' : 'Deshabilitado'}
              </div>
            </td>
            <td className='text-center'>
              <CustomPopupExtend
                showEnable={true}
                showDetails={true}
                position='right'
                triggerSrc={ThreeDots}
                showClone
                cloneEvent={() => {
                  getMatchContract({
                    url: '/receivable/contract/sum/',
                    token: store.loginReducer.Authorization,
                    objFilters: {
                      contractId: item.contractId,
                      eaccount: store.loginReducer.currentAccount.id,
                      active: 1,
                    },
                  });
                  setDuplicateModal({
                    isOpen: true,
                    payload: {
                      paramFrom: item.id,
                      companyName: item.companyName,
                      contractName: item.contractName,
                      contractOrigin: item.contractId,
                      created_by: store.loginReducer.user_data.id,
                    },
                  });
                }}
                showDetailsEvent={() =>
                  history.push(`/administracion/TMDeCartera/Parametrizacion/Detalle/${item.id}`)
                }
                cloneText='Duplicar'
                isEnabled={item.status === '1'}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${
                      item.status === '1' ? 'deshabilitará' : 'habilitará'
                    } la parametrización de empresa: ${item.companyName}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onUpdateStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>
        ))}
      </GenericTableNew>
    );
  };

  const render = () => {
    return (
      <>
        {companyParamsLoader && loader}
        {duplicateLoader && loader}
        {updateStatusLoader && loader}

        <div className={`${tableStyles.tlnContainer}`}>
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Parametrización de empresas</h1>
            {renderFilters()}
            {renderTable()}
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {filters.page}
                {' de '}
                {Math.ceil(companyParams?.row_count / filters.perpage)
                  ? Math.ceil(companyParams?.row_count / filters.perpage)
                  : '1'}{' '}
                ({companyParams?.row_count} encontrados)
              </p>
              <Pagination
                activePage={filters.page}
                itemsCountPerPage={filters.perpage}
                totalItemsCount={companyParams?.row_count}
                pageRangeDisplayed={5}
                onChange={page => setFilters({ ...filters, page: page })}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        </div>
        {renderModal()}
      </>
    );
  };

  useEffect(() => {
    async function fetchAsyncData() {
      await getCompanyParams({
        url: '/receivable/companyParams/',
        objFilters: {
          ...filters,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    filters,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getCompanyParams,
  ]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getCompanies({
        url: '/receivable/client/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getCompanies, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  useEffect(() => {
    async function fetchAsyncData() {
      if (filters.companyId) {
        await getContracts({
          url: '/receivable/contract/',
          objFilters: {
            client: filters.companyId,
          },
          token: store.loginReducer.Authorization,
        });
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    getContracts,
    duplicateModal.payload?.companyTo,
    store.loginReducer.Authorization,
    filters.companyId,
  ]);

  return render();
}
