import { Tooltip } from '@material-ui/core';
import * as deepcopy from 'deepcopy';
import { Component } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  getArticlesWithouIdAcc,
  requisition_filter,
  requisition_update,
} from '../../actions/authReqAction';
import { getACosumptiontCenter, getAllConCenter } from '../../actions/consumptionCenterActions';
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';
import deshacer from '../../assets/img/icons/deshacer.svg';
import CheckMark from '../../assets/img/icons/extraBtnCheck.svg';
import False from '../../assets/img/icons/extraBtnRedX.svg';
import Search from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopup from '../Popup/customPopUpExtends';
import IndividualStyles from './tableAuthorizeRequisitions.module.scss';

class TableAuthorizeRequisition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titleModal: 'Detalle de Requisición',
      loading: false,
      modal: false,
      showDetails: false,
      modalDenied: false,
      filters: {
        search: '',
        perpage: 10,
        page: 1,
        eaccount: this.props.currentAccount.id,
        reqAuth: 1,
        date: '',
        multistatus: 'reqAuth,pending,rejected',
        cc_id: '',
        changeorder: 1,
      },
      filtersArticles: {
        perpage: 30000,
      },
      filtersArticles2: {
        perpage: 5,
        page: 1,
      },
      requisition: {
        action: '',
        article_list: '',
        cc_ref: '',
        description: '',
        concept: '',
        created_at: '',
        id: '',
        id_consumption_center: '',
        id_user: '',
        name_user: '',
        num_req: '',
        observations: '',
        over_run_justification: '',
        requisition_date: '',
        status: '',
        updated_at: '',
        urgent: false,
        rejection_message: '',
      },
      ammountTotal: 0,
      inversionTotal: 0,
      headerState: [
        'No. Req',
        'Fecha Req',
        'Centro de Consumo',
        'Fecha Rev',
        'Estado',
        <span key={1}>&nbsp;</span>,
      ],
      headerStateModal: ['Código', 'Artículo', 'Cant. Requerida', 'Ult. Entrega', 'Valor Estimado'],
    };
  }
  getInitialState = () => {
    this.setState({
      titleModal: 'Detalle de Requisición',
      modal: false,
      showDetails: false,
      modalDenied: false,
      filters: {
        search: '',
        perpage: 10,
        page: 1,
        eaccount: this.props.currentAccount.id,
        reqAuth: 1,
        date: '',
        multistatus: 'reqAuth,pending,rejected',
        consumption_center: '',
        cc_id: '',
        changeorder: 1,
      },
      filtersArticles: {
        perpage: 30000,
      },
      filtersArticles2: {
        perpage: 5,
        page: 1,
      },
      requisition: {
        action: '',
        article_list: '',
        cc_ref: '',
        description: '',
        concept: '',
        created_at: '',
        id: '',
        id_consumption_center: '',
        id_user: '',
        name_user: '',
        num_req: '',
        observations: '',
        over_run_justification: '',
        requisition_date: '',
        status: '',
        updated_at: '',
        urgent: false,
        rejection_message: '',
      },
      ammountTotal: 0,
      inversionTotal: 0,
      headerState: [
        'No. Req',
        'Fecha Req',
        'Centro de Consumo',
        'Fecha Rev',
        'Estado',
        <span key={2}>&nbsp;</span>,
      ],
    });
  };
  componentDidMount() {
    this.setLoading(true);
    this.props.requisition_filter(this.state.filters, () => this.setLoading(false));
    this.props.getArticlesWithouIdAcc(this.state.filtersArticles);
    this.props.getAllConCenter({ eaccount: this.props.currentAccount.id }, () =>
      this.setLoading(false),
    );
  }

  setLoading = booleanValue => {
    this.setState({ ...this.state, loading: booleanValue });
  };

  myPermission = () =>
    this.props.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'AuthReq',
    );
  handlePageChange(pageNumber) {
    let { filters } = this.state;
    filters.page = pageNumber;
    this.setState({ filters });
    this.props.requisition_filter(this.state.filters);
  }
  handlePageChangeArticles(pageNumber) {
    let { filtersArticles2 } = this.state;
    filtersArticles2.page = pageNumber;
    this.setState({ filtersArticles2 });
  }
  handleClick() {
    let { filters } = this.state;
    filters.page = 1;
    this.setState({ filters });
    this.setLoading(true);
    this.props.requisition_filter(this.state.filters, () => this.setLoading(false));
  }
  toggleModal = () => {
    this.getInitialState();
    this.setState({
      modal: !this.state.modal,
    });
  };
  toggleModalDenied = () => {
    this.setState({
      modalDenied: !this.state.modalDenied,
    });
  };
  handleEditRecord = req => {
    let { modal, requisition, showDetails } = this.state;
    modal = !this.state.modal;
    showDetails = true;
    requisition = deepcopy(req);
    this.props.getACosumptiontCenter(requisition.id_consumption_center);
    this.setState({ modal, requisition, showDetails });
  };

  onChangeFiltersForm = e => {
    const name = e?.target?.name ?? e.name;
    const value = e?.target?.value ?? e.name;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };
  onChangeSelect = e => {
    const name = e.name;
    const value = e.value;
    let { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };
  onChangeForm = e => {
    const name = e.target.name;
    const value = e.target.value;
    let { requisition } = this.state;
    requisition[name] = value;
    this.setState({ requisition });
  };
  onchageStatus = newStatus => {
    let { requisition, modalDenied } = this.state;
    let req = deepcopy(requisition);
    req.status = newStatus;
    if (newStatus === 'rejected') {
      customSwaltAlert({
        icon: 'warning',
        showCancelButton: true,
        title: '<span style=color:#01A0F6>¿Está seguro?<span>',
        text: 'Se denegará la requisición ' + req.num_req,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, denegar',
        focusConfirm: false,
        customClass: {
          confirmButton: `${IndividualStyles.btnPrimary}`,
          cancelButton: `${IndividualStyles.btnSecondary}`,
        },
      }).then(result => {
        if (result.isConfirmed) {
          modalDenied = true;
          this.setState({ modalDenied });
        }
      });
    } else {
      customSwaltAlert({
        icon: 'warning',
        showCancelButton: true,
        title: '<span style=color:#01A0F6>¿Está seguro?<span>',
        text: 'Se autorizará la requisición ' + req.num_req,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, autorizar',
        focusConfirm: false,
        customClass: {
          confirmButton: `${IndividualStyles.btnPrimary}`,
          cancelButton: `${IndividualStyles.btnSecondary}`,
        },
      }).then(result => {
        if (result.isConfirmed) {
          req.action = 'autorizada';
          this.props.requisition_update(req, this.state.filters);
          this.setState({
            modal: !this.state.modal,
          });
        }
      });
    }
    this.setState({ requisition, modalDenied });
  };
  updateReq = () => {
    let { requisition } = this.state;
    let req = deepcopy(requisition);
    req.status = 'rejected';
    req.action = 'denegada';
    this.props.requisition_update(req, this.state.filters);
    this.setState({
      modal: !this.state.modal,
      modalDenied: !this.state.modalDenied,
    });
  };
  undoEvent = req => {
    let { requisition } = this.state;
    requisition = deepcopy(req);
    requisition.status = 'reqAuth';
    requisition.action = 'actualizada';
    this.setState({ requisition });
    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
      });
    }
    customSwaltAlert({
      icon: 'warning',
      showCancelButton: true,
      title: '<span style=color:#01A0F6>¿Está seguro?<span>',
      text: 'Se va a deshacer la requisición ',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, aceptar ',
      focusConfirm: false,
      customClass: {
        confirmButton: `${IndividualStyles.btnPrimary}`,
        cancelButton: `${IndividualStyles.btnSecondary}`,
      },
    }).then(result => {
      if (result.isConfirmed) {
        this.props.requisition_update(requisition, this.state.filters);
      }
    });
  };
  renderRequisitions(requisitions) {
    return (
      <tr key={requisitions.id} className='hover-table-row text-center align-middle'>
        <td
          className={`col-md-1 text-uppercase px-2 text-start${
            requisitions.urgent ? IndividualStyles.UrgentReq : ''
          }`}
        >
          {requisitions.urgent ? (
            <Tooltip title={this.ballthingyTooltipName(requisitions.urgent)} arrow>
              <div>{requisitions.num_req}</div>
            </Tooltip>
          ) : (
            <div>{requisitions.num_req}</div>
          )}
        </td>
        <td className={`col-md-2  ${requisitions.urgent ? IndividualStyles.UrgentReq : ''}`}>
          {requisitions.urgent ? (
            <Tooltip title={this.ballthingyTooltipName(requisitions.urgent)} arrow>
              <div>
                {requisitions.requisition_date
                  .split('T')[0]
                  .replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
              </div>
            </Tooltip>
          ) : (
            requisitions.requisition_date
              .split('T')[0]
              .replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
          )}
        </td>

        <td
          className={`col-md-5 text-start px-2  ${
            requisitions.urgent ? IndividualStyles.UrgentReq : ''
          }`}
        >
          {requisitions.urgent ? (
            <Tooltip title={this.ballthingyTooltipName(requisitions.urgent)} arrow>
              <div>{requisitions.cc_ref?.description}</div>
            </Tooltip>
          ) : (
            requisitions.cc_ref?.description
          )}
        </td>
        <td className={`col-md-2 ${requisitions.urgent ? IndividualStyles.UrgentReq : ''}`}>
          {requisitions.urgent ? (
            <Tooltip title={this.ballthingyTooltipName(requisitions.urgent)} arrow>
              <div>
                {requisitions.date_rev
                  ? requisitions.date_rev
                      .split('T')[0]
                      .replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                  : '-'}
              </div>
            </Tooltip>
          ) : requisitions.date_rev ? (
            requisitions.date_rev.split('T')[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
          ) : (
            '-'
          )}
        </td>
        <td className='col-md-1'>
          <div>
            <Tooltip title={this.ballthingyTooltipName(requisitions.status)} arrow>
              <div>
                <img
                  src={
                    requisitions.status === 'reqAuth'
                      ? AlertTriangle
                      : requisitions.status === 'rejected'
                        ? False
                        : CheckMark
                  }
                  alt='Alert'
                  className={` ${
                    requisitions.status === 'reqAuth'
                      ? IndividualStyles.alert
                      : IndividualStyles.icon
                  } `}
                />
              </div>
            </Tooltip>
          </div>
        </td>
        <td className='col-md-1'>
          <CustomPopup
            noHover
            triggerSrc={threeDots}
            showDetails={this.myPermission()?.read}
            showDetailsEvent={() => this.handleEditRecord(requisitions)}
            showUndo={requisitions.status !== 'reqAuth'}
            undoEvent={() => this.undoEvent(requisitions)}
          />
        </td>
      </tr>
    );
  }
  renderArticles(articles, requisition) {
    return (
      <tr key={articles.id} className='hover-table-row text-center align-middle'>
        <td>{articles.id}</td>
        <td className='text-start'>{articles.description}</td>
        <td>{requisition.filter(x => x.id_article === articles.id)[0].qty_article}</td>
        <td>
          {articles.updated_at.split('T')[0].replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
        </td>
        <td className='text-end px-2'>
          $ {articles.cost_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        </td>
      </tr>
    );
  }
  ballthingyTooltipName = backendName => {
    switch (backendName) {
      case 'pending':
        return 'Autorizada';
      case 'rejected':
        return 'Denegada';
      case 'reqAuth':
        return 'Sin revisar';
      case true:
        return 'Prioritaria';
      default:
    }
  };

  render() {
    let tempModules = [
      {
        value: '',
        label: 'Seleccionar...',
        name: 'cc_id',
      },
    ];
    if (this.props.consumption_center) {
      this.props.consumption_center.forEach(item => {
        let label = item.description;
        if (Array.isArray(item?.consumption_combination)) {
          if (item.consumption_combination?.length < 1) {
            label += '*';
          }
        } else {
          label += '*';
        }
        tempModules.push({
          value: item.id,
          label: label,
          name: 'cc_id',
        });
      });
    }
    let renderHeaders = this.state.headerState.map((header, ind) => {
      return (
        <th key={ind}>
          <div
            className={` ${header === 'Centro de Consumo' || header === 'No. Req' ? '' : 'text-center'}  px-2 ${header === 'est.' || header === '' ? tableStyles.groupElems : ''}`}
          >
            {header}
          </div>
        </th>
      );
    });
    let renderHeaderStateModal = this.state.headerStateModal.map((header, ind) => {
      return (
        <th key={ind}>
          <div
            className={`
            ${header === 'Valor Estimado' ? ' px-2 text-end' : header === 'Artículo' ? 'text-start' : 'text-center'}
            ${header === 'est.' || header === '' ? tableStyles.groupElems : ''}`}
          >
            {header}
          </div>
        </th>
      );
    });
    let listRequisitions;
    if (this.props.requisitions !== undefined) {
      const requistions = this.props.requisitions;
      listRequisitions = requistions.map(x => {
        return this.renderRequisitions(x);
      });
    }
    let listArticles;
    let tableArticles;
    if (this.state.requisition.id !== '') {
      let articles = this.props.articles;
      const requisition = this.state.requisition;
      listArticles = articles.filter(x =>
        requisition?.article_list?.find(x1 => x1.id_article === x.id),
      );
      let n = 5;
      const chunk = (arr, size) =>
        arr.reduce((acc, e, i) => {
          // eslint-disable-next-line no-sequences
          return i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc;
        }, []);
      tableArticles = chunk(listArticles, n)[this.state.filtersArticles2.page - 1]?.map(x => {
        return this.renderArticles(x, requisition.article_list);
      });
      let inversion = listArticles.reduce((acc, a) => {
        return (
          acc +
          a.cost_price * requisition?.article_list.filter(x => x.id_article === a.id)[0].qty_article
        );
      }, 0);
      if (inversion !== 0 && inversion !== this.state.inversionTotal) {
        let { inversionTotal } = this.state;
        inversionTotal = inversion;
        this.setState({ inversionTotal });
      }
    }

    return (
      <div className={tableStyles.container}>
        {this.state.loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        {this.myPermission()?.read ? (
          <div className={tableStyles.tableArea}>
            <h1 className={tableStyles.title}>Autorizar requisiciones</h1>
            <div>
              <div className={`flex-direction-row align-middle ${IndividualStyles.widtDiv}`}>
                <div className='d-flex justify-content-between'>
                  <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
                    <p className={`${tableStyles.crudModalLabel}`}>No. de requisición</p>
                    <input
                      name='search'
                      type='text'
                      className={`register-inputs`}
                      onChange={this.onChangeFiltersForm}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          this.handleClick(e);
                        }
                      }}
                      placeholder='Escribir...'
                    ></input>
                  </div>
                  <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
                    <p className={`${tableStyles.crudModalLabel}`}>Fecha</p>
                    <input
                      name='date'
                      type='date'
                      className={`register-inputs`}
                      onChange={this.onChangeFiltersForm}
                    ></input>
                  </div>
                  <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
                    <p className={`${tableStyles.crudModalLabel}`}>Centro de consumo</p>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      placeholder='Seleccionar...'
                      name='cc_id'
                      styles={customSelectNewDark}
                      onChange={e => this.onChangeSelect(e)}
                      options={tempModules}
                    />
                  </div>
                  <div className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}>
                    <p className={`${tableStyles.crudModalLabel}`}>Estado de requisición</p>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      placeholder='Seleccionar...'
                      name='multistatus'
                      styles={customSelectNewDark}
                      onChange={this.onChangeFiltersForm}
                      options={[
                        { label: 'Seleccionar...', value: '', name: 'multistatus' },
                        { label: 'Autorizada', value: 'pending', name: 'multistatus' },
                        { label: 'Denegada', value: 'rejected', name: 'multistatus' },
                        { label: 'Sin revisa', value: 'reqAuth', name: 'multistatus' },
                      ]}
                    />
                  </div>
                  <div
                    className={`${IndividualStyles.inputMargin} ${IndividualStyles.ItemInicio}`}
                    style={{ 'align-self': 'flex-end' }}
                  >
                    <div>
                      <img
                        src={Search}
                        alt='Search icon'
                        className={`${IndividualStyles.SearchIcon} hoverPointer`}
                        onClick={e => this.handleClick(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <GenericTableNew dark={true} headers={renderHeaders}>
              {listRequisitions}
            </GenericTableNew>
            <ModalNew
              title={this.state.titleModal}
              subtitleStyle={
                this.state.requisition.status === 'rejected'
                  ? 'modal-red'
                  : this.state.requisition.status === 'pending'
                    ? 'modal-green'
                    : ''
              }
              subtitle={
                this.state.requisition.status === 'rejected'
                  ? 'Denegada'
                  : this.state.requisition.status === 'pending'
                    ? 'Autorizada'
                    : ''
              }
              show={this.state.modal}
              size='1200'
              onHide={() => this.toggleModal(false)}
              btnNoName={` Cancelar`}
              hideCancelButton={this.state.detailModal ? () => this.handleSubmit() : null}
              extraButtons={
                this.state.requisition.status === 'reqAuth'
                  ? [
                      {
                        type: 1,
                        name: 'Denegar',
                        icon: False,
                        event: () => {
                          this.onchageStatus('rejected');
                        },
                      },
                      {
                        type: 1,
                        name: 'Autorizar',
                        icon: CheckMark,
                        event: () => {
                          this.onchageStatus('pending');
                        },
                      },
                    ]
                  : [
                      {
                        type: 1,
                        name: 'Deshacer',
                        icon: deshacer,
                        event: () => {
                          this.undoEvent(this.state.requisition);
                        },
                      },
                    ]
              }
            >
              <div className='d-flex flex-direction-row align-middle col-md-12 '>
                <div className={`${IndividualStyles.inputMargin} `}>
                  <p className={tableStyles.crudModalLabel}>No. de Requisición</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.budgetModalText}`}
                    name='ammountTotal'
                    type='text'
                    value={this.state.requisition.num_req}
                  />
                </div>
                <div className={`${IndividualStyles.inputMargin} col-md-3   `}>
                  <p className={tableStyles.crudModalLabel}>Fecha de requisición</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.budgetModalText}`}
                    name='ammountTotal'
                    type='date'
                    value={this.state.requisition.requisition_date.split('T')[0]}
                  />
                </div>
                <div className={`${IndividualStyles.inputMargin} col-md-3  `}>
                  <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.labelGray} `}
                    name='ammountTotal'
                    type='text'
                    value={this.state.requisition.cc_ref.description}
                  />
                </div>
                <div className={`col-md-3  `}>
                  <p className={tableStyles.crudModalLabel}>Requerido por</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.labelGray}`}
                    name='ammountTotal'
                    type='text'
                    value={this.state.requisition.name_user}
                  />
                </div>
              </div>
              <GenericTableNew headers={renderHeaderStateModal}>{tableArticles}</GenericTableNew>

              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}>
                  Pag. {listArticles?.length ? this.state.filtersArticles2.page : ''}
                  {' de '}
                  {Math.ceil(listArticles?.length / this.state.filtersArticles2.perpage)
                    ? Math.ceil(listArticles?.length / this.state.filtersArticles2.perpage)
                    : ''}{' '}
                  ({listArticles?.length} encontrados)
                </p>
                <Pagination
                  activePage={this.state.filtersArticles2.page}
                  itemsCountPerPage={5}
                  totalItemsCount={listArticles?.length}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChangeArticles.bind(this)}
                  itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
                  itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
                  itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
                  itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
              <div className={`d-flex flex-direction-row align-middle  `}>
                <div className={`${IndividualStyles.inputMargin} col-md-3 `}>
                  <p className={tableStyles.crudModalLabel}>Inversión estimada</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.budgetModalText}`}
                    name='inversion'
                    type='text'
                    value={
                      '$ ' +
                      this.state.inversionTotal?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    }
                  />
                </div>
                <div className={`${IndividualStyles.inputMargin} col-md-3  `}>
                  <p className={tableStyles.crudModalLabel}>Presupuesto asignado</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.budgetModalText}`}
                    name='annual_budget'
                    type='text'
                    value={`$ ${
                      this.props.chosen_cc?.annual_budget
                        ? this.props.chosen_cc?.annual_budget
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        : 'Cargando...'
                    } `}
                  />
                </div>
                <div className={` col-md-2 `}>
                  <p className={tableStyles.crudModalLabel}>% utilizado</p>
                  <input
                    readOnly
                    className={`register-inputs-readonly ${IndividualStyles.orangeText} `}
                    name='usedPercent'
                    type='text'
                    value={
                      parseInt(
                        (this.state?.inversionTotal / this.props.chosen_cc?.annual_budget) * 100,
                      ) + ' %'
                    }
                  />
                </div>
              </div>
              <div className={`col-md-12`}>
                <p className={tableStyles.crudModalLabel}>
                  Justificación de sobrepaso del presupuesto
                </p>

                <textarea
                  className={`${IndividualStyles.inputsTextArea} ${IndividualStyles.labelGray}`}
                  name='justification'
                  type='text'
                  value={this.state.requisition.over_run_justification}
                  readOnly
                ></textarea>
              </div>
              <ModalNew
                title={'Denegación'}
                show={this.state.modalDenied}
                size='400'
                onHide={() => this.toggleModalDenied(false)}
                btnNoName={` Cancelar`}
                btnYesName={'Guardar'}
                btnYesEvent={() => this.updateReq()}
                btnYesDisabled={this.state.requisition.rejection_message === ''}
              >
                <div className={`${IndividualStyles.ItemSmall}`}>
                  <p className={tableStyles.crudModalLabel}>Justificación</p>
                  <textarea
                    name='rejection_message'
                    rows='3'
                    cols='10'
                    className={`register-inputs ${IndividualStyles.inputsTextArea} ${IndividualStyles.justification}`}
                    onChange={this.onChangeForm}
                  ></textarea>
                </div>
              </ModalNew>
            </ModalNew>
            <div className={paginationStyles.wrapper}>
              <p className={paginationStyles.paginationText}>
                Pag. {this.props.requisitions?.length ? this.state.filters.page : ''}
                {' de '}
                {Math.ceil(this.props.requisitions?.length / this.state.filters.perpage)
                  ? Math.ceil(this.props.requisitions?.length / this.state.filters.perpage)
                  : ''}{' '}
                ({this.props.requisitions?.length} encontrados)
              </p>
              <Pagination
                activePage={this.state.filters.page}
                itemsCountPerPage={10}
                totalItemsCount={this.props.requisitions?.length}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange.bind(this)}
                itemClassPrev={paginationStyles.itemClassPrev}
                itemClassNext={paginationStyles.itemClassNext}
                itemClassFirst={paginationStyles.itemClassFirst}
                itemClassLast={paginationStyles.itemClassLast}
                itemClass={paginationStyles.itemClass}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentAccount: state.loginReducer.currentAccount,
  requisitions: state.requisitionReducer.requisitions,
  consumption_center: state.consumptionCenterReducer.consumption_center,
  chosen_cc: state.consumptionCenterReducer.chosen_cc,
  articles: state.articleReducer.articles,
});

const mapDispatchToProps = dispatch => ({
  requisition_filter: (objFilters, doAfter) => {
    dispatch(requisition_filter(objFilters, doAfter));
  },
  getACosumptiontCenter: (id, doAfter) => {
    dispatch(getACosumptiontCenter(id, doAfter));
  },
  getAllConCenter: (objFilters, doAfter) => {
    dispatch(getAllConCenter(objFilters, doAfter));
  },
  getArticlesWithouIdAcc: (objFilters, doAfter) => {
    dispatch(getArticlesWithouIdAcc(objFilters, doAfter));
  },
  requisition_update: (data, objFilters) => {
    dispatch(requisition_update(data, objFilters));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TableAuthorizeRequisition);
