import {
  BANK_NOTE_TYPES,
  GET_BILLING_RESOLUTION_LIST,
  POST_BANK_NOTE,
  TRANSFER_REGISTER,
} from "../actions/actionTypes";

const initialState = {
  bank_note_types: [],
  bank_note_types_loading: false,
  post_bank_note_loading: false,
  transfer_register_loading: false,
  billingResolutionList: [],
  billResList_loading: false,
};
export const bankNoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_BANK_NOTE:
      return {
        ...state,
        post_bank_note_loading: action.loading,
      };
    case BANK_NOTE_TYPES:
      return {
        ...state,
        bank_note_types: action.payload,
        bank_note_types_loading: action.loading,
      };
    case TRANSFER_REGISTER:
      return {
        ...state,
        transfer_register_loading: action.loading,
      };
    case GET_BILLING_RESOLUTION_LIST:
      return {
        ...state,
        billingResolutionList: action.payload,
        rowTotal: action.rowTotal,
        billResList_loading: action.loading,
      };

    default:
      return state;
  }
};
