const setting = {
    patterns: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN
        ? process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN.split(',').map(str => parseInt(str, 10)).filter(num => !isNaN(num))
        : [],
    separator: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR || '',
    execute: process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR_PATTERN && process.env.REACT_APP_DOCUMENT_IDS_SEPARATOR
        ? process.env.REACT_APP_APPLY_DOCUMENT_IDS_SEPARATOR === 'true'
        : false,
};
/**
 * Formatea un número según un patrón especificado, insertando un separador en posiciones definidas.
 *
 * Esta función toma un número y lo convierte en una cadena formateada según los patrones y el separador proporcionados.
 * Los patrones definen la longitud de cada segmento antes de insertar el separador. Además, se puede activar o desactivar
 * el formateo según sea necesario.
 *
 * @param {Object} params - Parámetros para formatear el número.
 * @param {string|number} params.value - El valor a formatear. Puede ser una cadena o un número.
 * @param {number[]} [params.patterns=[3, 7]] - Un array de números que define la longitud de cada segmento antes de insertar un separador.
 * @param {string} [params.separator='-'] - El símbolo que se utilizará como separador (por defecto es '-').
 * @param {boolean} [params.execute=true] - Bandera que indica si el formateo debe aplicarse o no (por defecto es `true`).
 * @returns {{value: number|string, format: string}} Un objeto que contiene el valor original y la cadena formateada.
 */
export const formatteDocument = (value, patterns = setting.patterns, separator = setting.separator, execute = setting.execute, formatted = true) => {
    if (!execute || !value) {
        return { value: value, format: value };
    }
    let formattedStr = '';
    const allowedCharactersRegex = new RegExp(`[^a-zA-Z0-9]`, 'g');
    const string = value.toString().replace(allowedCharactersRegex, '');
    const formattedNumber = string.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    if (formatted) {
        let index = 0;
        let patternIndex = 0;
        while (index < string.length) {
            const partLength = patterns[patternIndex] || patterns[patterns.length - 1];
            const part = string.slice(index, index + partLength);
            formattedStr += part;
            index += partLength;
            if (index < string.length) {
                formattedStr += separator;
            }
            if (patternIndex < patterns.length - 1) {
                patternIndex++;
            }
        }
    } else {
        formattedStr = formattedNumber;
    }
    return { value: string, format: formattedStr };
}