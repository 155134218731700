import tableStyles from '../../../../../components/Layouts/tableStyle.module.scss';
import iconClose from '../../../../../../src/assets/img/icons/modalClose.svg';

import { Drawer } from '@mui/material';

const NotesDetailDrawer = ({ isOpened, ccNotesDetail, onClose }) => {

  const formatDataComments = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <>
            <div key={index} style={{ paddingLeft: '5px', marginTop: '10px' }}>
              <div className={tableStyles.app_container_tras_notes}>
                <span className={tableStyles.app_name_drawer}>{"" || '-'}</span>
              </div>
              <p className={tableStyles.app_description_drawer}>{item?.ccNote || '-'}</p>
              <div className={tableStyles.app_container_date_drawer}>
                <span
                  className={tableStyles.app_date_drawer}
                >{`${item?.ccDate} ${item?.ccHour}`}</span>
              </div>
            </div>
            <div className={tableStyles.app_border_drawer} />
          </>,
        );
      });
    }
    return tempList;
  };

  return (
    <Drawer
      anchor={'right'}
      open={isOpened}
      style={{ zIndex: 900 }}
      onClose={() => onClose()}
    >
      <div className={tableStyles.app_drawer} style={{ width: '500px', maxWidth: '500px' }}>
        <div className={tableStyles.app_container_close}>
          <img
            className={`${tableStyles.closeDrawerAsistencial} pointer`}
            src={iconClose}
            alt='close'
            width={25}
            onClick={() => onClose()}
          />
        </div>

        <div
          className={`${tableStyles.app_container_drawer} pt-0 px-5 mt-0`}
          style={{ width: '500px', maxWidth: '500px' }}
        >
          <span className={`${tableStyles.app_title_drawer} mb-2`}>Notas</span>

          {ccNotesDetail?.results?.dailyNotes?.length === 0 ? (
            <div className={tableStyles.app_container_not_notes}>
              <span className={tableStyles.app_title_not_notes}>No hay notas aún</span>
            </div>
          ) : (
            <div className={tableStyles.app_container_content_drawer}>
              {formatDataComments(ccNotesDetail || [])}
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}

export default NotesDetailDrawer
