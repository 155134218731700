import { Form, Modal } from 'react-bootstrap';

import { Close } from '@material-ui/icons';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod } from '../../../Hooks';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { convertMoneyFormat, loader } from '../../../helpers';

export default function Details({ isOpen, onClose, currentDetail, client }) {
  const store = useSelector(state => state);

  const { trigger: getDetails, load: detailsLoader, results: detailsResult } = useGetMethod();

  useEffect(() => {
    async function fetchData() {
      if (isOpen) {
        await getDetails({
          url: '/receivable/balance/getDetail/',
          token: store.loginReducer.Authorization,
          objFilters: {
            process: currentDetail,
            eaccount: store.loginReducer.currentAccount.id,
            client: client,
          },
        });
      }
    }
    fetchData().catch(error => console.error(error));
  }, [
    isOpen,
    getDetails,
    store.loginReducer.Authorization,
    currentDetail,
    store.loginReducer.currentAccount.id,
    client,
  ]);

  const company = useMemo(() => {
    const c = detailsResult.results?.company?.[0];
    return c;
  }, [detailsResult.results?.company]);

  const data = useMemo(() => {
    const d = detailsResult.results?.data;
    return d ?? [];
  }, [detailsResult.results?.data]);

  const individual = useMemo(() => {
    const i = detailsResult.results?.individual?.[0];
    return i;
  }, [detailsResult.results?.individual]);

  const onCloseModal = () => {
    onClose();
  };

  const renderProvision = () => {
    return (
      <>
        {data?.length ? (
          <div className='row'>
            {data.map((item, ind) => (
              <div className='col-3' key={ind}>
                <small className='text-primary ms-1'>{item.name} días</small>
                <input
                  type='text'
                  className='register-inputs'
                  value={convertMoneyFormat(item.value)}
                  disabled
                />
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
        {individual ? (
          <div className='row align-items-end'>
            <div className='col'>
              <Form.Check
                type='checkbox'
                defaultChecked={true}
                label={<small>Mayor a 360 días</small>}
                disabled
              />
            </div>
            <div className='col-8'>
              <small className='text-primary ms-1'>{individual?.name} días</small>
              <input
                type='text'
                className='register-inputs'
                value={convertMoneyFormat(individual?.value)}
                disabled={true}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  const renderDeterioration = () => {
    return (
      <div className='row'>
        {data?.length
          ? data.map((item, ind) => (
              <div className='col-3' key={ind}>
                <small className='text-primary ms-1'>{item.name} días</small>
                <input
                  type='text'
                  className='register-inputs'
                  value={convertMoneyFormat(item.value)}
                  disabled
                />
              </div>
            ))
          : ''}
      </div>
    );
  };

  const renderArrears = () => {
    return (
      <div className='row'>
        {data?.length
          ? data.map((item, ind) => (
              <div className='col-3' key={ind}>
                <small className='text-primary ms-1'>{item.name} días</small>
                <input
                  type='text'
                  className='register-inputs'
                  value={convertMoneyFormat(item.value)}
                  disabled
                />
              </div>
            ))
          : ''}
      </div>
    );
  };

  const render = () => {
    return (
      <>
        {detailsLoader && loader}
        <Modal
          show={isOpen}
          size='lg'
          centered
          className={modalStyles.container}
          onHide={onCloseModal}
        >
          <div className='d-flex align-items-center justify-content-between py-3 px-4'>
            <div></div>
            <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
              Detalle de {currentDetail === 'arrears' && 'mora'}
              {currentDetail === 'deterioration' && 'deterioro'}
              {currentDetail === 'provision' && 'provisión'}
            </h3>
            <Close className='pointer text-secondary' onClick={onCloseModal} />
          </div>
          <hr className='m-0' />
          <div className='px-4 pt-3 pb-5'>
            {company && (
              <div className='row gy-2'>
                <div className='col-8'>
                  <small className='text-primary ms-1'>Empresa</small>
                  <input type='text' className='register-inputs' value={company.client} disabled />
                </div>
                <div className='col-4'>
                  <small className='text-primary ms-1'>NIT</small>
                  <input type='text' className='register-inputs' value={company.nit} disabled />
                </div>
                <div className='col'>
                  <small className='text-primary ms-1'>Saldo</small>
                  <input
                    type='text'
                    className='register-inputs'
                    value={convertMoneyFormat(company.pending)}
                    disabled
                  />
                </div>
                <div className='col'>
                  <small className='text-primary ms-1'>Corriente</small>
                  <input
                    type='text'
                    className='register-inputs'
                    value={convertMoneyFormat(company.not_due)}
                    disabled
                  />
                </div>
                {currentDetail === 'arrears' && (
                  <div className='col'>
                    <small className='text-primary ms-1'>Mora</small>
                    <input
                      type='text'
                      className='register-inputs'
                      value={convertMoneyFormat(company.arrears)}
                      disabled
                    />
                  </div>
                )}
                {currentDetail === 'provision' && (
                  <div className='col'>
                    <small className='text-primary ms-1'>Provisión</small>
                    <input
                      type='text'
                      className='register-inputs'
                      value={convertMoneyFormat(company.provision)}
                      disabled
                    />
                  </div>
                )}
                {currentDetail === 'deterioration' && (
                  <div className='col'>
                    <small className='text-primary ms-1'>Deterioro</small>
                    <input
                      type='text'
                      className='register-inputs'
                      value={convertMoneyFormat(company.deterioration)}
                      disabled
                    />
                  </div>
                )}
              </div>
            )}
            <div className='text-primary fw-bold mt-4 mb-1'>Edades de cartera</div>
            {currentDetail === 'provision' && renderProvision()}
            {currentDetail === 'deterioration' && renderDeterioration()}
            {currentDetail === 'arrears' && renderArrears()}
          </div>
        </Modal>
      </>
    );
  };

  return render();
}
