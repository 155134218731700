import { useEffect, useState } from 'react';
import bankMoveStyle from '../MovimientosBancarios/bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ModalDocuments from '../../components/TableIndividualAuction/IndividualDocuments';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import GenericTable from '../../components/Layouts/GenericTableNew';
import Detalle from '../../assets/img/icons/auctionDetailLupa2.svg';
import Cerrar from '../../assets/img/icons/littleClose.svg';
import Adjuntar from '../../assets/img/icons/insertar.svg';
import Search from '../../assets/img/icons/lupaClear.svg';
import ModalNew from '../../components/Layouts/ModalNew';
import Style from './InvoiceReceipt.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import {
  create_New_Invoice,
  edit_Invoice,
  getSelectPayConditions,
  getSelectTypeInvoice,
} from '../../actions/InvoiceReceiptActions';
import { getProviders } from '../../actions/providersActions';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { cleanWarehouseEntries, getWarehouseEntries } from '../../actions/warehouseActions';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import Excel from '../../assets/img/icons/excel.svg';
import Word from '../../assets/img/icons/word.svg';
import PDF from '../../assets/img/icons/pdf.svg';
import { getwhEntryDetails } from '../../actions/inventoryActions';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Title } from '../../shared';

function CreateInvoice(props) {
  const MySwal = withReactContent(Swal);
  const store = useSelector(state => state);
  const arrWhEntries = store.entryWarehouseReducer.warehouseEntries;
  const counter = useSelector(state => state);
  const [entryNumber, setEntryNumber] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [, setSelectedProvider] = useState('');
  const [selectedList, setselectedList] = useState([]);
  const [entryWare, setEntryWare] = useState([]);
  const [htmlEntry, setHtmlEntry] = useState(false);
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'listpinvoi',
    );
  useEffect(() => {
    if (!myPermission()?.read) {
      history.push('/tesoreria/inicio');
    }
  });

  const isBoxSelected = id_req => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id_req);
      return !!target;
    }
  };

  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let id_wh_entry = item.id;
        let created_by = counter.loginReducer.currentAccount.id;
        let newChange = {
          ...item,
          id_wh_entry,
          created_by,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const isBoxAllSelected = () => {
    let res = true;

    if (Array.isArray(arrWhEntries) && Array.isArray(selectedList)) {
      arrWhEntries.forEach(item => {
        if (selectedList.find(itemb => item.id !== itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = [];
      if (Array.isArray(arrWhEntries) && Array.isArray(tempSelected)) {
        arrWhEntries.forEach(x => {
          let id_wh_entry = x.id;
          let created_by = counter.loginReducer.currentAccount.id;
          let newChange = {
            ...x,
            id_wh_entry,
            created_by,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      setselectedList([]);
    }
  };

  const [getPinvoice, setPinvoice] = useState({
    id: props?.info?.id ? props?.info?.id : '',
    entity_account: counter?.loginReducer?.currentAccount.id,
    status: 2,
    invoice_nbr: props?.info?.invoice_nbr ? props?.info?.invoice_nbr : '',
    invoice_type: props?.info?.invoice_type ? props?.info?.invoice_type : 'null',
    provider: props?.info?.provider ? props?.info?.provider : '',
    provider_name: props?.info?.provider_name ? props?.info?.provider_name : '',
    exp_date: props?.info?.exp_date ? props?.info?.exp_date : '',
    due_date: props?.info?.due_date ? props?.info?.due_date : '',
    pay_conditions: props?.info?.pay_conditions ? props?.info?.pay_conditions : '',
    base_amount: props?.info?.total_amount ? props?.info?.total_amount : '',
    tax_amount: props?.info?.tax_amount ? props?.info?.tax_amount : '',
    late_pay_int: props?.info?.late_pay_int ? props?.info?.late_pay_int : '',
    rete_iva: props?.info?.rete_iva ? props?.info?.rete_iva : '',
    rete_ica: props?.info?.rete_ica ? props?.info?.rete_ica : '',
    discount: props?.info?.discount ? props?.info?.discount : '',
    total_amount: props?.info?.total_amount ? props?.info?.total_amount : '',
    purchase_order: props?.info?.purchase_order ? props?.info?.purchase_order : '',
    anullment_justif: props?.info?.anullment_justif ? props?.info?.anullment_justif : '',
    observations: props?.info?.observations ? props?.info?.observations : '',
  });

  const [reqState, setreqState] = useState({
    auction_title: '',
    date_expiry: '',
    hour_expiry: '',
    terms: '',
    priority: '',
    id_warehouse: '',
    id_user: counter.loginReducer.user_data.id,
    account: counter.loginReducer.currentAccount.id,
    account_session: counter.loginReducer.currentAccount.name,
    id_consumption_center: 0,
    articulos: [],
    pay_conditions: '',
    coin: '',
    document: [],
    file: false,
    observations: ' ',
    qty_total: 0,
  });
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
  });
  const [filters, setFilters] = useState({
    we_provider_id: '',
    linked_invoice: 0,
    perpage: 10,
    page: 1,
    search: '',
    eaccount: counter.loginReducer.currentAccount.id,
  });
  const resetStatusFile = event => {
    event?.stopPropagation();
    setreqState({
      auction_title: '',
      date_expiry: '',
      hour_expiry: '',
      terms: '',
      priority: '',
      id_warehouse: '',
      id_user: counter.loginReducer.user_data.id,
      account: counter.loginReducer.currentAccount.id,
      account_session: counter.loginReducer.currentAccount.name,
      id_consumption_center: 0,
      articulos: [],
      pay_conditions: '',
      coin: '',
      document: [],
      file: false,
      observations: ' ',
      qty_total: 0,
    });
  };

  const getEntryProvaider = () => {
    dispatch(
      getWarehouseEntries({
        perpage: 10,
        page: 1,
        we_provider_id: getPinvoice.provider,
        eaccount: counter.loginReducer.currentAccount.id,
        linked_invoice: 0,
      }),
    );
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
  };

  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const ButtonDocuments = () => {
    setmodalDocumentsControl({
      ...modalDocumentsControl,
      show: true,
    });
  };
  const [modalDocumentsControl, setmodalDocumentsControl] = useState({
    show: false,
  });
  useEffect(() => {
    dispatch(getSelectTypeInvoice());
    dispatch(getProviders({ slim: 1, status: 'enabled' }));
    dispatch(getSelectPayConditions({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const handleSearch = () => {
    const filters = {
      we_number: entryNumber,
      perpage: 10,
      page: 1,
      entry_date: selectedDate,
      we_provider_id: getPinvoice.provider,
      eaccount: counter.loginReducer.currentAccount.id,
      linked_invoice: 0,
    };
    dispatch(getWarehouseEntries(filters));
  };

  const resetSearch = () => {
    dispatch(cleanWarehouseEntries());
    setSelectedProvider('');
    setSelectedDate('');
    setEntryNumber('');

    setmodalArtControl({ ...modalArtControl, show: false });
  };
  let history = useHistory();
  function handleClick() {
    if (props?.setDetails) {
      props?.setDetails({ show: false });
    } else {
      history.push('/tesoreria/RecepcionDeFacturas');
    }
  }

  const [showModal1, setShowModal1] = useState(false);
  useEffect(() => {
    dispatch(getWarehouseEntries(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);

  const headerModalAlmacen = [
    <th key={1} style={{ paddingLeft: '10px' }} className='text-center'>
      <input
        type='checkbox'
        value='selectedAll'
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={2} className='text-center ml-3'>
      No. Entrada
    </th>,
    <th key={3} className='text-center'>
      Fecha
    </th>,
    <th key={4} className='text-center'>
      Proveedor
    </th>,
    <th key={5} className='text-center'>
      Factura
    </th>,
    <th key={6} className='text-center'>
      Documento
    </th>,
    <th key={7} className='text-center'></th>,
  ];

  const headerDetailEntry = [
    <th key={1} className='text-center'>
      Código
    </th>,
    <th key={2} className='text-center'>
      Descripción de Artículo
    </th>,
    <th key={3} className='text-center'>
      Lote
    </th>,
    <th key={4} className='text-center'>
      Cantidad ordenada
    </th>,
    <th key={5} className='text-center'>
      Cantidad Recibida
    </th>,
  ];

  const bodyDetailEntry = [];
  if (Array.isArray(counter.inventoryReducer?.wareHouseDetails?.articles)) {
    counter.inventoryReducer?.wareHouseDetails?.articles.forEach(item => {
      bodyDetailEntry.push(
        <tr>
          <td className='text-center'>{item.id_article}</td>
          <td className='text-center'>{item.description}</td>
          <td className='text-center'>{item.lots.length}</td>
          <td className='text-center'>{item.qty_ordened}</td>
          <td className='text-center'>{item.qty_received}</td>
        </tr>,
      );
    });
  }
  const modalDatallle = elem => {
    setShowModal1(true);
    dispatch(getwhEntryDetails(elem));
    setmodalArtControl({
      ...modalArtControl,
      show: false,
    });
  };

  const modalClose = () => {
    setShowModal1(false);
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
  };
  const bodyModalAlmacen = [];
  if (Array.isArray(arrWhEntries)) {
    arrWhEntries.forEach(item => {
      bodyModalAlmacen.push(
        <tr>
          <td style={{ paddingLeft: '10px' }} className='text-center'>
            <input
              type='checkbox'
              checked={isBoxSelected(item.id)}
              onChange={() => changeBoxSelected(item)}
            />
          </td>
          <td className='text-center'>{item.we_number}</td>
          <td className='text-center'>{convertDateToLatinFormat(item.entry_date)}</td>
          <td
            className='text-center'
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          >
            {item?.provider_name}
          </td>
          <td className='text-center'>{item.we_billNumber}</td>
          <td className='text-center'>
            <a href={item.we_billDocument} target='_blank' rel='noopener noreferrer'>
              Ver documento
            </a>
          </td>
          <td className='text-center'>
            <img
              alt=''
              className={`${Style.hoverDetali} text-start w-15`}
              style={{ width: '20px' }}
              src={Detalle}
              onClick={() => modalDatallle(item.id)}
            />
          </td>
        </tr>,
      );
    });
  }

  let optionTypeInvoice = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.InvoiceReceiptReducer.resultsSelectTypeInvoice)) {
    counter?.InvoiceReceiptReducer?.resultsSelectTypeInvoice.forEach(item => {
      optionTypeInvoice.push({
        value: item.id,
        label: item.description,
      });
    });
  }

  let optionProvider = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.providerReducer.providers)) {
    counter?.providerReducer?.providers.forEach(item => {
      optionProvider.push({
        value: item.id,
        label: item.nit + ' - ' + item.name,
        valueName: item.name,
      });
    });
  }

  let optionPayConditions = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.InvoiceReceiptReducer.resultsSelectPayConditions)) {
    counter?.InvoiceReceiptReducer?.resultsSelectPayConditions.forEach(item => {
      optionPayConditions.push({
        value: item.pay_name,
        label: item.pay_value,
      });
    });
  }
  const send_New_Invoice = () => {
    if (getPinvoice.due_date < getPinvoice.exp_date) {
      MySwal.fire({
        icon: 'warning',
        title: 'Error',
        text: `La fecha de vencimiento no puede ser menor a la fecha de emisión`,
      });
    } else {
      if (props.edit !== true) {
        dispatch(
          create_New_Invoice(
            {
              pinvoice: {
                entity_account: counter.loginReducer.currentAccount.id,
                status: 2,
                invoice_nbr: getPinvoice.invoice_nbr,
                invoice_type: getPinvoice.invoice_type,
                provider: getPinvoice.provider,
                provider_name: getPinvoice.provider_name,
                exp_date: getPinvoice.exp_date,
                due_date: getPinvoice.due_date,
                pay_conditions: getPinvoice.pay_conditions,
                base_amount: getPinvoice.base_amount,
                tax_amount: getPinvoice.tax_amount,
                late_pay_int: getPinvoice.late_pay_int,
                rete_iva: getPinvoice.rete_iva,
                rete_ica: getPinvoice.rete_ica,
                discount: getPinvoice.discount,
                total_amount: getPinvoice.total_amount,
                purchase_order: getPinvoice.purchase_order,
                anullment_justif: getPinvoice.anullment_justif,
                observations: getPinvoice.observations,
              },
              rel_pinv_whentry: selectedList,
              file: {
                filename: reqState.file.name ? reqState.file.name : ' ',
                file_base64: reqState.document[0]?.base64,
                route: 'pinvoice',
              },
            },
            () => resetForm(),
          ),
        );
      } else {
        dispatch(
          edit_Invoice(
            {
              id: props.info.id,
              entity_account: counter.loginReducer.currentAccount.id,
              status: 2,
              invoice_nbr: getPinvoice.invoice_nbr
                ? getPinvoice.invoice_nbr
                : props.info.invoice_nbr,
              invoice_type: getPinvoice.invoice_type
                ? getPinvoice.invoice_type
                : props.info.invoice_type,
              provider: getPinvoice.provider ? getPinvoice.provider : props.info.provider,
              provider_name: getPinvoice.provider_name
                ? getPinvoice.provider_name
                : props.info.provider_name,
              exp_date: getPinvoice.exp_date ? getPinvoice.exp_date : props.info.exp_date,
              due_date: getPinvoice.due_date ? getPinvoice.due_date : props.info.due_date,
              pay_conditions: getPinvoice.pay_conditions
                ? getPinvoice.pay_conditions
                : props.info.pay_conditions,
              base_amount: getPinvoice.base_amount
                ? getPinvoice.base_amount
                : props.info.base_amount,
              tax_amount: getPinvoice.tax_amount ? getPinvoice.tax_amount : props.info.tax_amount,
              late_pay_int: getPinvoice.late_pay_int
                ? getPinvoice.late_pay_int
                : props.info.late_pay_int,
              rete_iva: getPinvoice.rete_iva ? getPinvoice.rete_iva : props.info.rete_iva,
              rete_ica: getPinvoice.rete_ica ? getPinvoice.rete_ica : props.info.rete_ica,
              discount: getPinvoice.discount ? getPinvoice.discount : props.info.discount,
              total_amount: getPinvoice.total_amount
                ? getPinvoice.total_amount
                : props.info.total_amount,
              purchase_order: getPinvoice.purchase_order
                ? getPinvoice.purchase_order
                : props.info.purchase_order,
              anullment_justif: getPinvoice.anullment_justif
                ? getPinvoice.anullment_justif
                : props.info.anullment_justif,
              observations: getPinvoice.observations
                ? getPinvoice.observations
                : props.info.observations,
            },
            () => resetForm(),
          ),
        );
      }
    }
  };

  const docType = data => {
    let iconDoc;
    if (data.file) {
      switch (data.file.type) {
        case 'application/pdf':
          iconDoc = PDF;
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          'application/msword':
          iconDoc = Word;
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          'csv' ||
          'application/vnd.ms-excel':
          iconDoc = Excel;
          break;
        default:
          iconDoc = PDF;
          break;
      }
    }
    return iconDoc;
  };

  const filterArr = (id, event) => {
    event.stopPropagation();
    setselectedList(selectedList.filter(i => i.id !== id));
    selectedList.forEach(item => {
      setEntryWare({
        ...entryWare,
        id_wh_entry: item.id,
        created_by: 12,
      });
    });
  };

  const statusHtmlEntry = () => {
    setHtmlEntry(true);
    setmodalArtControl({ ...modalArtControl, show: false });
  };

  const changeProvaider = e => {
    setPinvoice({
      ...getPinvoice,
      provider: e.value,
      provider_name: e.valueName || e.label,
    });
    setFilters({
      ...filters,
      we_provider_id: e.value,
    });
    setselectedList([]);
  };

  const resetForm = () => {
    setTrigger(trigger + 1);
    setselectedList([]);
    resetStatusFile();
    handleClick();
    setPinvoice({
      entity_account: counter.loginReducer.currentAccount.id,
      status: 2,
      invoice_nbr: '',
      invoice_type: 'null',
      provider: 0,
      provider_name: '',
      exp_date: '',
      due_date: '',
      pay_conditions: '',
      base_amount: '',
      tax_amount: 0,
      late_pay_int: 0,
      rete_iva: 0,
      rete_ica: 0,
      discount: 0,
      total_amount: '',
      purchase_order: 0,
      anullment_justif: 0,
      observations: '',
    });
  };
  let micadena = '';
  let division = '';
  if (props?.info?.file) {
    micadena = props?.info?.file;
    division = micadena.split('/');
  }

  return (
    <>
      {!!counter.InvoiceReceiptReducer.loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div
            className={tableStyles.container1}
            style={{
              paddingLeft: '24px',
            }}
          >
            <div className={tableStyles.ItemSmall}>
              <div className='d-flex' style={{ marginTop: '40px', alignItems: 'end' }}>
                <Title
                  title={props.info ? props.info.provider_name : 'Nueva factura'}
                  onClickIcon={handleClick}
                />
              </div>
              {props?.info?.invoice_nbr ? (
                <span style={{ color: '#005dbf', fontWeight: '500', paddingLeft: '37px' }}>
                  {props?.info?.invoice_nbr ? 'Factura No. ' + props?.info?.invoice_nbr : ''}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className={`pt-3 pb-3`}
            style={{
              borderRadius: '10px',
              paddingLeft: '24px',
            }}
          >
            {/* ---------------------------- ROW 1 ---------------------------------- */}

            {/*--------------------------------- ROW 3 --------------------------------- */}
            <Row className='d-flex ' style={{ width: '100%' }}>
              <div className='mt-4'>
                <li className={tableStyles.titleTax}>Información General</li>
              </div>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Proveedor <span className='text-warning'>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'provider' + trigger}
                  styles={customSelectNewDark}
                  options={optionProvider}
                  placeholder='Seleccionar...'
                  value={
                    getPinvoice.provider_name
                      ? { value: getPinvoice.provider, label: getPinvoice.provider_name }
                      : ''
                  }
                  onChange={e => changeProvaider(e)}
                  isDisabled={props?.details ? true : false}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Número de factura <span className='text-warning'>*</span>{' '}
                </p>
                <input
                  placeholder='Escribir...'
                  key={'numberInvoice' + trigger}
                  className={IndividualStyles.registerInputsGris}
                  value={
                    getPinvoice.invoice_nbr ? getPinvoice.invoice_nbr : props?.info?.invoice_nbr
                  }
                  type='text'
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      invoice_nbr: e.target.value,
                    })
                  }
                  disabled={props?.details ? true : false}
                />
              </Col>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Fecha de emisión <span className='text-warning'>*</span>
                </p>
                <input
                  key={'dateExp' + trigger}
                  className={IndividualStyles.registerInputsBlue}
                  type='date'
                  value={getPinvoice.exp_date ? getPinvoice.exp_date : props?.info?.exp_date}
                  max={getPinvoice.due_date}
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      exp_date: e.target.value,
                    })
                  }
                  disabled={props?.details ? true : false}
                />
              </Col>
            </Row>

            {/*--------------------------------- ROW 4 --------------------------------- */}
            <Row className='d-flex ' style={{ width: '100%' }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Condición de pago <span className='text-warning'>*</span>
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'condiPayment' + trigger}
                  styles={customSelectNewDark}
                  options={optionPayConditions}
                  value={
                    getPinvoice.pay_conditions
                      ? optionPayConditions.find(item => item.value === getPinvoice.pay_conditions)
                      : optionPayConditions.find(item => item.value === props?.info?.pay_conditions)
                  }
                  placeholder='Seleccionar...'
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      pay_conditions: e.value,
                    })
                  }
                  isDisabled={props?.details ? true : false}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Monto del documento <span className='text-warning'>*</span>{' '}
                </p>
                <NumberFormat
                  placeholder='Escribir...'
                  key={'montDocument' + trigger}
                  allowNegative={false}
                  id='cost_price'
                  className={`${IndividualStyles.registerInputsGris}`}
                  value={
                    getPinvoice.base_amount ? getPinvoice.base_amount : props?.info?.total_amount
                  }
                  prefix='$'
                  thousandsGroupStyle='thousand'
                  thousandSeparator
                  onValueChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      base_amount: e.value,
                      total_amount: e.value,
                    })
                  }
                  disabled={props?.details ? true : false}
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Fecha de vencimiento <span className='text-warning'>*</span>{' '}
                </p>
                <input
                  key={'dateDue' + trigger}
                  className={IndividualStyles.registerInputsBlue}
                  name='requisition_date'
                  type='date'
                  value={getPinvoice.due_date ? getPinvoice.due_date : props?.info?.due_date}
                  min={getPinvoice.exp_date}
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      due_date: e.target.value,
                    })
                  }
                  disabled={props?.details ? true : false}
                />
              </Col>
            </Row>

            <Row className='d-flex' style={{ width: '100%' }}>
              <Col>
                <p className={tableStyles.crudModalLabel}>Observaciones</p>
                <textarea
                  key={'detail' + trigger}
                  style={{ height: '3rem' }}
                  placeholder='Escribir...'
                  rows='5'
                  cols='40'
                  value={
                    getPinvoice.observations ? getPinvoice.observations : props?.info?.observations
                  }
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      observations: e.target.value,
                    })
                  }
                  disabled={props?.details ? true : false}
                />
              </Col>
            </Row>
          </div>

          <Row>
            <div
              className={`pt-3 pb-3`}
              style={{
                borderRadius: '10px',
                paddingLeft: '24px',
                marginLeft: '20px',
                marginRight: '21px',
              }}
            >
              <li className={tableStyles.titleTax}>Cargar factura</li>
            </div>
          </Row>
          <div
            className={`pt-3 pb-3`}
            style={{
              backgroundColor: '#F2F7FC',
              borderRadius: '10px',
              paddingLeft: '24px',
              marginLeft: '20px',
              marginRight: '21px',
            }}
          >
            <Row className='d-flex' style={{ width: '100%' }}>
              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Cargar archivo </p>
                <div
                  className={`${IndividualStyles.registerInputsBlue} d-flex`}
                  style={{ backgroundColor: '#fff' }}
                  type='text'
                  onClick={() => (props?.details ? '' : ButtonDocuments())}
                >
                  <img src={docType(reqState)} alt='' srcSet='' />
                  <p
                    className={tableStyles.crudModalLabel}
                    style={{ marginTop: '0.2rem', width: '180px' }}
                  >
                    {reqState.file.name}
                    {division[5]}
                  </p>
                  {reqState.file.name ? (
                    <img
                      alt=''
                      src={Cerrar}
                      style={{ width: '15px' }}
                      onClick={e => resetStatusFile(e)}
                    />
                  ) : (
                    ''
                  )}
                  <img style={{ marginLeft: 'auto', width: '16px' }} src={Adjuntar} alt='' />
                </div>
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>
                  Tipo de factura <span className='text-warning'>*</span>{' '}
                </p>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  key={'typeInvoice' + trigger}
                  styles={customSelectNewDark}
                  placeholder='Seleccionar...'
                  style={{
                    border:
                      getPinvoice.provider === 0 ? '1.5px solid #CECECE' : '1.5px solid #005DBF',
                  }}
                  options={optionTypeInvoice}
                  value={
                    getPinvoice.invoice_type !== 'null'
                      ? optionTypeInvoice.find(item => item.value === getPinvoice.invoice_type)
                      : optionTypeInvoice.find(item => item.value === props?.info?.invoice_type)
                  }
                  isDisabled={getPinvoice.provider === 0 || !!props?.details ? true : false}
                  onChange={e =>
                    setPinvoice({
                      ...getPinvoice,
                      invoice_type: e.value,
                    })
                  }
                />
              </Col>
              <Col xs={4}>
                <p
                  className={tableStyles.crudModalLabel}
                  style={{
                    color:
                      getPinvoice.invoice_type === 'null' ||
                      getPinvoice.invoice_type === 2 ||
                      Object.keys(props)?.length > 0
                        ? '#CECECE'
                        : '#005DBF',
                  }}
                >
                  Relacionar entradas por almacén
                </p>
                <div
                  style={{
                    backgroundColor: '#fff',
                    border:
                      getPinvoice.invoice_type === 'null' ||
                      getPinvoice.invoice_type === 2 ||
                      Object.keys(props)?.length > 0
                        ? '1.5px solid #CECECE'
                        : '1.5px solid #005DBF',
                    height: 'auto',
                    maxHeight: '27px',
                    minHeight: '27px',
                  }}
                  className={IndividualStyles.registerInputsBlue}
                  onClick={() =>
                    getPinvoice.invoice_type === 'null' ||
                    getPinvoice.invoice_type === 2 ||
                    Object.keys(props)?.length > 0
                      ? ''
                      : getEntryProvaider()
                  }
                >
                  <div className='row d-flex'>
                    {htmlEntry === true
                      ? selectedList.map((e, key) => {
                          return (
                            <div
                              key={key}
                              className='col-3 d-flex'
                              style={{
                                padding: '0px 5px 0px 5px',
                                backgroundColor: '#f0f0f0',
                                fontSize: '12px',
                                fontWeight: 'normal',
                                margin: '5px 5px 0px 5px',
                                borderRadius: '10px',
                                width: 'max-content',
                              }}
                            >
                              {e.we_number}
                              <img
                                alt='close'
                                onClick={event => filterArr(e.id, event)}
                                src={Cerrar}
                                style={{ width: '10px', marginLeft: '5px' }}
                              ></img>
                            </div>
                          );
                        })
                      : props?.info?.warehouse_entry_id
                        ? props?.info?.warehouse_entry_id.map((e, key) => {
                            return (
                              <div
                                key={key}
                                className='col-3 d-flex'
                                style={{
                                  padding: '0px 5px 0px 5px',
                                  backgroundColor: '#f0f0f0',
                                  fontSize: '12px',
                                  fontWeight: 'normal',
                                  margin: '5px',
                                  borderRadius: '10px',
                                  width: 'max-content',
                                }}
                              >
                                {e.id}
                                <img
                                  alt=''
                                  onClick={event => filterArr(e.id, event)}
                                  src={Cerrar}
                                  style={{ width: '10px', marginLeft: '5px' }}
                                ></img>
                              </div>
                            );
                          })
                        : ''}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/*--------------------------------- ROW 5 --------------------------------- */}
          <Row className='d-flex'>
            <Col
              xs={12}
              className='d-flex'
              style={{ justifyContent: 'flex-end', paddingRight: '2.5rem' }}
            >
              &nbsp;
              {props.details ? (
                ''
              ) : (
                <Button
                  onClick={() => send_New_Invoice()}
                  className={`${tableStyles.btnPrimary} mt-4`}
                  disabled={
                    getPinvoice.due_date === '' ||
                    getPinvoice.invoice_type === 'null' ||
                    0 ||
                    getPinvoice.total_amount === '' ||
                    getPinvoice.base_amount === '' ||
                    getPinvoice.pay_conditions === '' ||
                    getPinvoice.exp_date === '' ||
                    getPinvoice.invoice_nbr === '' ||
                    getPinvoice.provider_name === '' ||
                    getPinvoice.provider === ''
                      ? true
                      : false
                  }
                >
                  Registrar
                </Button>
              )}
            </Col>
          </Row>
          <ModalDocuments
            title='Anexar Documento'
            key={'modalDocuments' + trigger}
            show={modalDocumentsControl.show}
            onHide={() =>
              setmodalDocumentsControl({
                ...modalDocumentsControl,
                show: false,
              })
            }
            reqState={reqState}
            setreqState={setreqState}
          />
        </div>
        <ModalNew
          size='940'
          title='Entradas de almacén'
          subtitle={getPinvoice.provider_name}
          show={modalArtControl.show}
          btnYesName='Traer'
          btnYesEvent={() => statusHtmlEntry()}
          onHide={() => resetSearch()}
        >
          <>
            <Row className='d-flex'>
              <Col xs={3}>
                <p className={tableStyles.crudModalLabel}>No. Entrada</p>
                <input
                  onChange={e => setEntryNumber(e.target.value)}
                  className={IndividualStyles.registerInputsGris}
                  name='requisition_date'
                  type='text'
                  placeholder='Escribir...'
                />
              </Col>

              <Col xs={4}>
                <p className={tableStyles.crudModalLabel}>Fecha</p>
                <input
                  onChange={e => setSelectedDate(e.target.value)}
                  className={IndividualStyles.registerInputsBlue}
                  name='id_consumption_center'
                  type='date'
                />
              </Col>

              <Col
                onClick={() => handleSearch()}
                xs={1}
                className={`pt-3 cursorPointer`}
                style={{ 'align-self': 'end' }}
              >
                <div>
                  <img src={Search} alt='Search icon' />
                </div>
              </Col>

              <GenericTable headers={headerModalAlmacen}>{bodyModalAlmacen}</GenericTable>
              <div className='d-flex p-0'>
                <p className={paginationStyles.paginationText}>
                  Pag. {store.entryWarehouseReducer.warehouseEntries ? filters.page : ''}
                  {' de '}
                  {Math.ceil(store.entryWarehouseReducer.row_total)
                    ? Math.ceil(store.entryWarehouseReducer.row_total / filters.perpage)
                    : ''}{' '}
                  ({store.entryWarehouseReducer?.row_total} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.entryWarehouseReducer.row_total} // Esta jugada
                  pageRangeDisplayed={5}
                  onChange={e => setFilters({ ...filters, page: e })}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </Row>
          </>
        </ModalNew>

        <ModalNew
          show={showModal1}
          onHide={() => modalClose()}
          title={'Entrada No. ' + counter?.inventoryReducer?.wareHouseDetails?.we_number}
          size={'940'}
        >
          <Row className='d-flex'>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>Orden de compra</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type='text'
                value={counter?.inventoryReducer?.wareHouseDetails?.purchaseOrder_number}
                disabled
              ></input>
            </Col>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>Almacen</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type='text'
                value={counter?.inventoryReducer?.wareHouseDetails?.provider_name}
                disabled
              ></input>
            </Col>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>No. de Factura o remisión</label>
              <input
                className={IndividualStyles.registerInputsGris}
                type='text'
                value={counter?.inventoryReducer?.wareHouseDetails?.we_billNumber}
                disabled
              ></input>
            </Col>
            <Col xs={3}>
              <label className={tableStyles.crudModalLabel}>Fecha</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                value={counter?.inventoryReducer?.wareHouseDetails?.entry_date}
                disabled
              ></input>
            </Col>
          </Row>
          <GenericTable headers={headerDetailEntry}>{bodyDetailEntry}</GenericTable>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Observaciones</label>
            <textarea
              className={IndividualStyles.registerInputsGris}
              style={{ height: '60px' }}
              value={counter.inventoryReducer?.wareHouseDetails?.observations}
              disabled
            ></textarea>
          </Col>
        </ModalNew>
      </div>
    </>
  );
}
export default CreateInvoice;
