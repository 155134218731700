import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';

import Alert from '../../assets/img/icons/Alert.svg';
import Check from '../../assets/img/icons/CheckMark.svg';
import Cancel from '../../assets/img/icons/FalseCross.svg';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import Adjuntar from '../../assets/img/icons/insertar.svg';
import Lupa from '../../assets/img/icons/lupa.svg';

import {
  authorizationPayment,
  getPaymentsAuthorization,
} from '../../actions/accountingMovementsActions';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';

import ModalNew from '../../components/Layouts/ModalNew';
import { Title } from '../../shared';
import GenericTableScroll from '../Layouts/GenericTableScroll';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

function AuthorizePaymentsTC() {
  let history = useHistory();
  const dispatch = useDispatch();
  const counter = useSelector(state => state);
  const [trigger, setTrigger] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState({});
  const [details, setDetails] = useState({});
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'authoTC',
    );

  useEffect(() => {
    if (!myPermission()?.read) {
      history.push('/contabilidad/inicio');
    }
  });

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };
  useEffect(() => {
    dispatch(
      getPaymentsAuthorization({
        eaccount: counter.loginReducer.currentAccount.id,
        module: 'auth',
        status: status.status ? status.status : '',
        dateFrom: status.dateFrom ? status.dateFrom : '',
        dateUntil: status.dateUntil ? status.dateUntil : '',
        search: status.search ? status.search : '',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const optionStatus = [
    { value: '', label: 'Seleccionar...' },
    { value: 'approved', label: 'Aprobado' },
    { value: 'rejected', label: 'Denegado' },
    { value: 'reqAuth', label: 'Pendiente' },
  ];
  const header = [
    <div key={1} className='col-1'>
      Fecha
    </div>,
    <div key={2} className='col-2'>
      No. Tarjeta
    </div>,
    <div key={3} className='col-2'>
      No. Factura
    </div>,
    <div key={4} className='col-4 text-start'>
      Concepto
    </div>,
    <div key={5} className='col-1 text-end'>
      Valor
    </div>,
    <div key={6} className='col-1'>
      Estado
    </div>,
    <div key={7} className='col-1'>
      &nbsp;
    </div>,
  ];

  let total2 = 0;
  const renderList = () => {
    let tempList = [];
    if (Array.isArray(counter.accountingMovementsReducer.paymentslAuthorization)) {
      counter.accountingMovementsReducer.paymentslAuthorization.forEach(item => {
        total2 = total2 + Number(item.totalAmount);
        tempList.push(
          <section2>
            <div className='col-1 text-center'>{convertDateToLatinFormat(item.date)}</div>
            <div className='col-2 text-center'>{item.creditCardNbr}</div>
            <div className='col-2 text-center'>{item.invoiceNbr}</div>
            <div className='col-4 text-start'>{item.description}</div>
            <div className='col-1 text-end'>$ {numberWithCommas(item.totalAmount)}</div>
            <div className='col-1 text-center'>
              <Tooltip title={item?.statusName} arrow>
                <img
                  src={
                    item.status === 'reqAuth' ? Alert : item.status === 'rejected' ? Cancel : Check
                  }
                  alt=''
                  srcSet=''
                />
              </Tooltip>
            </div>
            <div className='col-1 text-center'>
              <img
                src={Filtrar}
                alt='icon'
                onClick={() => {
                  setShowModal(true);
                  setDetails(item);
                }}
              />
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        {counter.accountingMovementsReducer.paymentA_loading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}
        <div className={tableStyles.title}>
          <Title
            title='Autorización de pagos con TC'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>
        <div>
          <Row className='div'>
            <Col xs={3} className='display-grid'>
              <label className={tableStyles.crudModalLabel}>Estado</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder={`Seleccionar...`}
                styles={customSelectNewDark}
                options={optionStatus}
                onChange={e => {
                  setStatus({
                    ...status,
                    status: e.value,
                  });
                  setTrigger(trigger + 1);
                }}
              />
            </Col>
            <Col xs={3} className='display-grid'>
              <label className={tableStyles.crudModalLabel}>Fecha desde</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                onChange={e =>
                  setStatus({
                    ...status,
                    dateFrom: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              />
            </Col>
            <Col xs={3} className='display-grid '>
              <label className={tableStyles.crudModalLabel}>Hasta</label>
              <input
                className={IndividualStyles.registerInputsBlue}
                type='date'
                onChange={e =>
                  setStatus({
                    ...status,
                    dateUntil: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              />
            </Col>
            <Col xs={2} className='display-grid'>
              <label className={tableStyles.crudModalLabel}>ㅤ</label>
              <input
                className={IndividualStyles.Search}
                type='text'
                placeholder='Buscar...'
                onChange={e =>
                  setStatus({
                    ...status,
                    search: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              />
            </Col>
            <Col className='display-grid  '>
              <label className={tableStyles.crudModalLabel}>ㅤ</label>
              <img
                src={Lupa}
                alt='icon'
                className='cursorPointer'
                onClick={() => setTrigger(trigger + 1)}
              />
            </Col>
          </Row>
        </div>
        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={'2'}
        ></GenericTableScroll>
        <div className='d-flex'>
          <div className='text-end col-6'></div>
          <div className='text-center col-3' style={{ color: '#58595b' }}>
            <b>Total</b>
          </div>
          <div className={`text-start col-3`} style={{ paddingLeft: '2rem', color: '#58595b' }}>
            <b>$ {numberWithCommas(total2)}</b>
          </div>
        </div>
      </div>
      <ModalNew
        show={showModal}
        hideCancelButton={true}
        onHide={() => setShowModal(false)}
        title={'Detalle de pago'}
        extraButtons={
          details.status === 'reqAuth' && !!myPermission()?.approve
            ? [
                {
                  type: 1,
                  name: 'Denegar',
                  icon: Cancel,
                  event: () => {
                    setShowModal(false);
                  },
                },
                {
                  type: 1,
                  name: 'Autorizar',
                  icon: Check,
                  event: () => {
                    dispatch(
                      authorizationPayment(
                        {
                          eaccount: counter.loginReducer.currentAccount.id,
                          id: details.id,
                          status: 'approved',
                          user: 30,
                        },
                        details.description,
                        () => {
                          setTrigger(trigger + 1);
                          setShowModal(false);
                        },
                      ),
                    );
                  },
                },
              ]
            : [
                {
                  type: 1,
                  name: 'Aceptar',
                  icon: false,
                  event: () => {
                    setShowModal(false);
                  },
                },
              ]
        }
        size={'500'}
      >
        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Concepto*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              value={details.description}
              type='text'
              disabled
            ></input>
          </Col>
        </Row>

        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Centro de consumo*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              value={details.ccName}
              type='text'
              disabled
            ></input>
          </Col>
        </Row>

        <Row className='d-flex'>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Fecha*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              style={{ color: '#7FADDE', fontWeight: 'bold' }}
              value={convertDateToLatinFormat(details.date)}
              type='text'
              disabled
            ></input>
          </Col>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Valor*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              style={{ color: '#7FADDE', fontWeight: 'bold' }}
              value={'$' + details.totalAmount}
              type='text'
              disabled
            ></input>
          </Col>
        </Row>
        <Row className='d-flex pt-3'>
          <Col xs={12}>
            <b className={`${tableStyles.crudModalLabel}`} style={{ fontSize: '16px' }}>
              Detalle de la factura
            </b>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={12}>
            <label className={tableStyles.crudModalLabel}>Proveedor*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              value={details.thirdPartyName}
              type='text'
              disabled
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>No. factura*</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              value={details.invoiceNbr}
              type='text'
              disabled
            ></input>
          </Col>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Impuestos</label>
            <input
              className={IndividualStyles.registerInputsClearBlue}
              value={'$' + details.taxAmount}
              type='text'
              disabled
            ></input>
          </Col>
        </Row>
        <Row className='d-flex'>
          <Col xs={6}>
            <label className={tableStyles.crudModalLabel}>Factura</label>
            <div
              className={`${IndividualStyles.registerInputsClearBlue} d-flex`}
              style={{ backgroundColor: '#fff' }}
              type='text'
            >
              <a
                href={details.fileUrl}
                className={tableStyles.crudModalLabel}
                style={{ marginTop: '0.2rem', width: '145px' }}
              >
                {details.fileUrl}
              </a>
              <img style={{ marginLeft: 'auto', width: '16px' }} src={Adjuntar} alt='' />
            </div>
          </Col>
        </Row>
      </ModalNew>
    </>
  );
}
export default AuthorizePaymentsTC;
