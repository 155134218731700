

import { GET_CONTRACT_TYPES_LIST } from "../actions/actionTypes";

const initialState = {
  contractTypesList: [],
};
export const tmPayRollReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACT_TYPES_LIST:
      return {
        ...state,
        contractTypesList: action.payload,
      };

    default:
      return state;
  }
};
