// icons
import { AddCircleOutline } from '@material-ui/icons';
import ThreeDots from '../../../assets/img/icons/threeDots.svg';

// scss
import treasuryStyles from './prioritize-bills-to-pay.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

// components
import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { PrioritizeBillsToPayConfig } from './config/PrioritizeBillsToPayConfig';
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import { customSwaltAlert, loader } from '../../../helpers';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../../Hooks';

const actions = {
  create: 'CREATE',
  update: 'UPDATE',
  detail: 'DETAIL',
};

export const PrioritizeBillsToPay = () => {
  const [actionConfig, setActionConfig] = useState(actions.create);
  const [dataConfig, setDataConfig] = useState({});

  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const [show, setShowConfig] = useState(false);
  const [optionsConfig, setOptionsConfig] = useState({ priority: [], paymentType: [] });

  const config = useMemo(() => {
    return {
      token,
      eaccount,
      show,
      action: actionConfig,
      data: dataConfig,
      options: optionsConfig,
    };
  }, [token, eaccount, show, actionConfig, dataConfig, optionsConfig]);

  const handleShowConfig = () => setShowConfig(true);

  const handleCloseConfig = useCallback(() => {
    setDataConfig({});
    setShowConfig(false);
    setActionConfig(actions.create);
  }, []);

  const initialStateFilters = {
    page: 1,
    perpage: 10,
    eaccount,
    status: undefined,
    payment_type_id: undefined,
  };

  const [filters, setFilters] = useState(initialStateFilters);

  const {
    results: listPrioritizeBills,
    load: loaderListPrioritizeBills,
    trigger: getListPrioritizeBills,
  } = useGetMethod();

  const { load: loaderListPriority, trigger: getListPriority } = useGetMethod();

  const {
    results: listPaymentTypes,
    load: loaderListPaymentTypes,
    trigger: getListPaymentTypes,
  } = useGetMethod();

  const { trigger: putData, load: loadSendDataForm } = usePostMethod();

  const listPaymentTypesSelect = useMemo(() => {
    const data = [{ label: 'Seleccionar...', value: '' }];

    if (listPaymentTypes?.data?.length) {
      data.push(
        ...listPaymentTypes.data.map(item => ({
          value: item.id,
          label: item.paymentType,
        })),
      );
    }

    return data;
  }, [listPaymentTypes.data]);

  const listStatusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Habilitado', value: 'enabled' },
    { label: 'Deshabilitado', value: 'disabled' },
  ];

  const onUpdateSuccess = useCallback(() => {
    getListPrioritizeBills({
      token,
      url: '/cash/priority',
      objFilters: { page: 1, perpage: 10, eaccount },
    });
  }, [eaccount, getListPrioritizeBills, token]);

  useEffect(() => {
    getListPrioritizeBills({
      token,
      url: '/cash/priority',
      objFilters: { ...filters },
    });
  }, [getListPrioritizeBills, token, filters]);

  useEffect(() => {
    getListPriority({
      token,
      url: '/cash/priority/select',
      objFilters: { eaccount },
    }).then(res => {
      if (res.success) {
        setOptionsConfig(state => ({
          ...state,
          priority: res.data.map(el => ({ label: el.label, value: el.id })),
        }));
      }
    });
  }, [eaccount, getListPriority, token]);

  useEffect(() => {
    getListPaymentTypes({
      token,
      url: '/cash/payment_types',
      objFilters: { eaccount, status: 'enabled' },
    }).then(res => {
      if (res.success) {
        setOptionsConfig(state => ({
          ...state,
          paymentType: res.data.map(el => ({ label: el.paymentType, value: el.id })),
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eaccount, getListPaymentTypes, token]);

  const onChangeStatus = data => {
    putData({
      url: `/cash/priority/change-status/${data.id}`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: { status: data.status.value === 'enabled' ? 'disabled' : 'enabled' },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha ${data.status.value === 'enabled' ? 'deshabilitado' : 'habilitado'} la prioridad de pago: ${data.paymentType.name}`,
          showCancelButton: false,
        }).finally(() => {
          getListPrioritizeBills({
            token,
            url: '/cash/priority',
            objFilters: { ...filters },
          });
        });

        // onHideModalCopago();
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  //////// HEADERS TABLE ////////////
  const headerTable = [
    <th key={0} className='px-2 text-start col-6'>
      Tipo de pago
    </th>,

    <th key={1} className='px-2 text-start col-4'>
      Jerarquía
    </th>,

    <th key={2} className='px-2 text-center col-1'>
      Estado
    </th>,

    <th key={4} className='px-2 text-end col-1'></th>,
  ];

  //////// BODY TABLE ////////////
  const bodyTable = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='px-2 text-start'>{item.paymentType.name}</td>
            <td className='px-2 text-start'>{item.priority_name}</td>
            <td className='px-2 text-center'>
              <div
                className={treasuryStyles.cardStatus}
                style={{ color: item.status.fontColor, background: item.status.bgColor }}
              >
                {item.status.name}
              </div>
            </td>
            <td className='px-2 text-end'>
              <CustomPopupExtend
                noHover
                position='right'
                triggerSrc={ThreeDots}
                showEnable
                showEdit
                showDetails
                isEnabled={item.status.value === 'enabled'}
                editClickEvent={() => {
                  setShowConfig(true);
                  setDataConfig(item);
                  setActionConfig(actions.update);
                }}
                showDetailsEvent={() => {
                  setShowConfig(true);
                  setDataConfig(item);
                  setActionConfig(actions.detail);
                }}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.status.value === 'enabled' ? 'deshabilitará' : 'habilitará'} la prioridad de pago: ${item.paymentType.name}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onChangeStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const renderFilters = () => {
    return (
      <div>
        <h1>Priorizar facturas por pagar</h1>
        <div className={treasuryStyles.filtersGrid}>
          <div>
            <div>Tipo de pago</div>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              options={listPaymentTypesSelect}
              onChange={el => {
                setFilters(state => ({
                  ...state,
                  payment_type_id: el.value,
                }));
              }}
            />
          </div>
          <div>
            <div>Estado</div>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listStatusSelect}
              styles={ordCustomSelect}
              onChange={el => {
                setFilters(state => ({
                  ...state,
                  status: el.value,
                }));
              }}
            />
          </div>

          <div></div>

          <button
            type='button'
            style={{ justifySelf: 'end', columnGap: 10 }}
            className={treasuryStyles.btnPrimaryFilters}
            onClick={handleShowConfig}
          >
            <span>Crear prioridad</span>
            <AddCircleOutline stroke={3} fontSize='small' />
          </button>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <GenericTableNew dark={true} headers={headerTable}>
        {bodyTable(listPrioritizeBills.data || [])}
      </GenericTableNew>
    );
  };

  const main = () => {
    return (
      <>
        {(loaderListPrioritizeBills ||
          loadSendDataForm ||
          loaderListPaymentTypes ||
          loaderListPriority) &&
          loader}
        <div className={treasuryStyles.container}>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {1}
              {' de '}
              {Math.ceil(1) ? Math.ceil(1) : '1'} ({1} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={listPrioritizeBills?.rowTotal}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

          <PrioritizeBillsToPayConfig
            config={config}
            handleClose={handleCloseConfig}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </>
    );
  };

  return main();
};
