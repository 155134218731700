import styles from '../components/Layouts/tableStyle.module.scss';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: '10px',
    boxShadow: '0px 0px 5px #ccc',
    padding: '10px 0px',
    background: 'white',
    marginTop: '10px',
    color: '#697387',
  },
}));

export const NewPopover = ({ listOptions, currentTarget }) => {
  const classes = useStyles();
  const open = Boolean(currentTarget);

  return (
    <div>
      <Popper open={open} anchorEl={currentTarget} transition>
        {() => (
          <div className={classes.paper}>
            {listOptions?.map((el, i) => (
              <div
                key={i}
                id={i}
                className={`${styles.stylePopoverAsistencial} pointer ${i < listOptions.length - 1 ? 'mb-2' : ''} ${el?.icon ? 'd-flex gap-2 align-items-center' : ''}`}
                style={{ padding: '2px 20px 2px 7px' }}
                onClick={() => Boolean(el?.onClick) && el?.onClick()}
              >
                {el?.icon && <img src={el?.icon} alt={el?.alt || 'icon'} width={16} height={15} />}
                <div style={{ fontSize: '14px' }}>{el.text}</div>
              </div>
            ))}
          </div>
        )}
      </Popper>
    </div>
  );
};
