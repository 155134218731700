import { Close, ErrorOutline } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';

import { customSwaltAlert, loader } from '../../../helpers';
import { useGetMethod, usePostMethod } from '../../../Hooks';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function Apply({
  isOpen,
  onClose,
  visualizer,
}) {
  const store = useSelector(state => state);

  const [provisionPayload, setProvisionPayload] = useState({
    process: 'provision',
    eaccount: store.loginReducer.currentAccount.id,
    created_by: store.loginReducer.user_data.id,
    type: 'general',
    percentage: null,
    puc: '',
    pucType: 'local',
  });
  const [deteriorationPayload, setDeteriorationPayload] = useState({
    process: 'deterioration',
    eaccount: store.loginReducer.currentAccount.id,
    created_by: store.loginReducer.user_data.id,
    puc: '',
    pucType: 'niif',
  });

  const {
    trigger: getProvision,
    results: provisionResults,
    load: provisionLoader,
  } = useGetMethod();
  const {
    trigger: getDeterioration,
    results: deteriorationResults,
    load: deteriorationLoader,
  } = useGetMethod();
  const { trigger: getNiif, results: niifResult } = useGetMethod();
  const { trigger: getLocal, results: localResult } = useGetMethod();

  const { trigger: applyProvision, load: applyProvisionLoader } = usePostMethod();

  useEffect(() => {
    if (visualizer === 'provision' && isOpen) {
      getProvision({
        url: '/receivable/portfolioProvision/',
        objFilters: {
          page: 1,
          perpage: 999,
          status: '1',
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
  }, [
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getProvision,
    visualizer,
    isOpen,
  ]);

  useEffect(() => {
    if (visualizer === 'deterioration' && isOpen) {
      getDeterioration({
        url: '/receivable/portfolioDeterioration/',
        objFilters: {
          page: 1,
          perpage: 999,
          status: '1',
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
  }, [
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getDeterioration,
    visualizer,
    isOpen,
  ]);

  useEffect(() => {
    if (isOpen && visualizer === 'deterioration') {
      getNiif({
        url: '/admin/niif',
        objFilters: {
          active: 1,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
  }, [
    getNiif,
    isOpen,
    visualizer,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
  ]);

  useEffect(() => {
    if (isOpen && visualizer === 'provision') {
      getLocal({
        url: '/admin/localaccount',
        objFilters: {
          active: 1,
          perpage: 10,
          page: 1,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
  }, [
    getLocal,
    isOpen,
    visualizer,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
  ]);

  const onCloseModal = () => {
    setProvisionPayload({
      ...provisionPayload,
      percentage: '',
      type: 'general',
    });
    setDeteriorationPayload({
      ...deteriorationPayload,
      puc: '',
    });
    onClose();
  };

  const onSubmitProvision = () => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
      confirmButtonText: 'Aceptar',
    }).then(response => {
      if (response.isConfirmed) {
        applyProvision({
          token: store.loginReducer.Authorization,
          body: {
            ...provisionPayload,
            percentage:
              provisionPayload.percentage === '' ? undefined : provisionPayload.percentage,
          },
          method: 'POST',
          url: '/receivable/balance/applyDeterioration/',
          succesAction: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Provisión exitosa',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                onCloseModal();
              }
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const onSubmitDeterioration = () => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: 'Verifique la información',
      confirmButtonText: 'Aceptar',
    }).then(response => {
      if (response.isConfirmed) {
        applyProvision({
          token: store.loginReducer.Authorization,
          body: deteriorationPayload,
          method: 'POST',
          url: '/receivable/balance/applyDeterioration/',
          succesAction: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'success',
              title: 'Deterioro exitoso',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            }).then(async response => {
              if (response.isConfirmed) {
                onCloseModal();
              }
            });
          },
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      }
    });
  };

  const onLoadUsers = async (inputValue = '', callback) => {
    const result = await getLocal({
      url: '/admin/localaccount',
      objFilters: {
        active: 1,
        perpage: 10,
        page: 1,
        search: inputValue,
        eaccount: store.loginReducer.currentAccount.id,
      },
      token: store.loginReducer.Authorization,
    });
    const formatted = result.results?.map(item => ({
      label: `${item.complete_account} - ${item.description}`,
      value: item.id,
    }));
    callback(formatted);
  };

  const niifSelect = useMemo(
    () =>
      niifResult.results?.map(item => ({
        label: `${item.complete_account} - ${item.description}`,
        value: item.id,
      })) || [],
    [niifResult.results],
  );

  const localSelect = useMemo(
    () =>
      localResult.results?.map(item => ({
        label: `${item.complete_account} - ${item.description}`,
        value: item.id,
      })) || [],
    [localResult.results],
  );

  const renderProvision = () => {
    return (
      <>
        <div className='d-flex align-items-center small mb-3'>
          <ErrorOutline className='text-primary me-2' fontSize='medium' />
          <span>Se provisionará según el porcentaje parametrizado para cada edad de cartera.</span>
        </div>
        <div className='small fw-bold text-primary my-2'>Provisión general</div>
        <div className='row gy-2'>
          {provisionResults.results?.map(item => (
            <div key={item.id} className='col-4'>
              <small className='text-primary ms-1'>{item.name}</small>
              <input
                type='text'
                className='register-inputs'
                defaultValue={item.percentage + '%'}
                disabled
              />
            </div>
          ))}
        </div>
        <hr className='mb-2 mt-4' />
        <div className='small fw-bold text-primary'>Provisión individual</div>
        <div className='row align-items-end mt-2'>
          <div className='col-4 d-flex'>
            <Form.Check
              type='checkbox'
              checked={provisionPayload.type === 'individual'}
              onChange={({ target }) =>
                setProvisionPayload({
                  ...provisionPayload,
                  type: target.checked ? 'individual' : 'general',
                  percentage: '',
                })
              }
              label={<small>Mayor a 360 días</small>}
            />
          </div>
          <div className='col-8'>
            <small className='text-primary ms-1'>Porcentaje</small>
            <NumberFormat
              className='register-inputs'
              suffix='%'
              allowNegative={false}
              decimalScale={1}
              decimalSeparator='.'
              value={provisionPayload.percentage}
              onValueChange={option =>
                setProvisionPayload({
                  ...provisionPayload,
                  percentage: option.floatValue,
                })
              }
              disabled={provisionPayload.type === 'general'}
            />
          </div>
        </div>
        <hr className='mb-2 mt-4' />
        <div className='mt-3'>
          <small className='text-primary ms-1'>
            <span>Cuenta contable</span>
          </small>
          <ReactSelectAsync
            noOptionsMessage={() => 'No hay datos'}
            loadOptions={onLoadUsers}
            defaultOptions={[{ label: 'Seleccionar...', value: '' }, ...localSelect]}
            onChange={option => setProvisionPayload({ ...provisionPayload, puc: option.value })}
            value={localSelect.find(item => item.value === deteriorationPayload.puc)}
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
          />
        </div>
        <div className='d-flex justify-content-center align-items-center mt-4'>
          <button className={`${tableStyles.btnPrimaryAlt}`} onClick={onSubmitProvision}>
            Aceptar
          </button>
        </div>
      </>
    );
  };

  const renderDeterioration = () => {
    return (
      <>
        <div className='d-flex align-items-center small mb-3'>
          <ErrorOutline className='text-primary me-2' fontSize='medium' />
          <span>Se deteriorará según el porcentaje parametrizado para cada edad de cartera.</span>
        </div>
        <div className='row gy-2'>
          {deteriorationResults.results?.map(item => (
            <div key={item.id} className='col-4'>
              <small className='text-primary ms-1'>{item.name}</small>
              <input
                type='text'
                className='register-inputs'
                defaultValue={item.percentage + '%'}
                disabled
              />
            </div>
          ))}
        </div>
        <div className='mt-3'>
          <small className='text-primary ms-1'>
            <span>Cuenta contable</span>
          </small>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            styles={customSelectNewDark}
            placeholder='Seleccionar...'
            options={[{ label: 'Seleccionar...', value: '' }, ...niifSelect]}
            onChange={option =>
              setDeteriorationPayload({ ...deteriorationPayload, puc: option.value })
            }
            value={niifSelect.find(item => item.value === deteriorationPayload.puc)}
          />
        </div>
        <div className='d-flex justify-content-center align-items-center mt-4'>
          <button className={`${tableStyles.btnPrimaryAlt}`} onClick={onSubmitDeterioration}>
            Aceptar
          </button>
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <>
        {(provisionLoader || applyProvisionLoader || deteriorationLoader) && loader}
        <Modal
          show={isOpen}
          centered
          className={modalStyles.container}
          dialogClassName={modalStyles.modal_width_md}
          onHide={onCloseModal}
        >
          <div className='d-flex align-items-center justify-content-between py-3 px-4'>
            <div></div>
            <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
              {visualizer === 'provision' && 'Aplicar provisión'}
              {visualizer === 'deterioration' && 'Aplicar deterioro'}
            </h3>
            <Close className='pointer text-secondary' onClick={onCloseModal} />
          </div>
          <hr className='m-0' />
          <div className='px-4 py-3'>
            {visualizer === 'provision' && renderProvision()}
            {visualizer === 'deterioration' && renderDeterioration()}
          </div>
        </Modal>
      </>
    );
  };

  return render();
}
