import {
  GET_BILLING_SITES,
  GET_ENTERPRISE_LIST,
  GET_INVOICING_MINUTES,
} from "../actions/actionTypes";

const initialState = {
  enterPriseList: [],
  billingSites: [],
  invoicingList: [],
  rowCount: 0,
  invoicingList_loading: false
};
export const ripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENTERPRISE_LIST:
      return {
        ...state,
        enterPriseList: action.payload,
      };
    case GET_BILLING_SITES:
      return {
        ...state,
        billingSites: action.payload,
      };
    case GET_INVOICING_MINUTES:
      return {
        ...state,
        invoicingList: action.payload,
        rowCount: action.rowCount,
        invoicingList_loading: action.loading
      };

    default:
      return state;
  }
};
