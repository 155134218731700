//functional' reducer

import {
    FUNCTIONAL_GET, 
    FUNCTIONAL_GETONE, 
    FUNCTIONAL_EDIT, 
    FUNCTIONAL_POST, 
    FUNCTIONAL_ACTIVES, 
    FUNCTIONAL_GET_NIIF,
    FUNCTIONAL_LOADING,
} from "../actions/actionTypes";
  
  const initialState = {
   niifs: [],
   sites:[],
   functionals:[],
   total:0,
   error: null,
   Authorization:"",
    loading: false,
  };
  export const functionalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FUNCTIONAL_LOADING:
            return {
                ...state,
                loading: action.loading,
            }
        case FUNCTIONAL_GET:
            return {
                ...state,
                units: action.payload,
                total: action.row_total,
                error: null,
                Authorization:action.Authorization
            };
            case FUNCTIONAL_ACTIVES:
            return {
                ...state,
                functionals: action.payload,
                error: null,
                Authorization:action.Authorization
            };

        case FUNCTIONAL_GETONE:
            return {
                ...state,
                units: action.payload,
                total: action.row_total,
                error: null,
                Authorization:action.Authorization
            };

        
        case FUNCTIONAL_GET_NIIF:
            return {
                ...state,
                niifs: action.niifs,
                sites: action.sites,
                error: null,
                Authorization:action.Authorization
            };

        case FUNCTIONAL_EDIT:
            return {
                ...state,
                error: null,
                Authorization:action.Authorization
            };

        case FUNCTIONAL_POST:
            return {
                ...state,
                error: null,
                Authorization:action.Authorization
            };

        default:
            return state;
    }
  };
  