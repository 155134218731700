// by jhan ortegon

import { useEffect, useState } from 'react';

// icons - images
import AlertTriangle from '../../assets/img/icons/AlertTriangle.svg';

// css
import '../Layouts/customTabs.scss';
import '../TableUsers/tableUserStyle.scss';
import reqStyles from './Requisitions.module.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';
import genericTableStyles from '../Layouts/genericTable.module.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// hooks
import { useSelector, useDispatch } from 'react-redux';

// helpers
import { customSwaltAlert } from '../../helpers/customSwaltAlert';

// services
import Pagination from 'react-js-pagination';
import { REQUISITION_GET } from '../../actions/actionTypes';
import { getIF } from '../../actions/inventoryFamilyActions';
import { getManufacturers } from '../../actions/articlesActions';
import { requisition_get_one, requisition_update_returnal } from '../../actions/requisitionActions';

// Components
import Loader from 'react-loader-spinner';
import ModalNew from '../Layouts/ModalNew';
import GenericTableNew from '../Layouts/GenericTableNew';
import ReqModalArticleCreate from './ReqModalArticleCreate';

const ReqModalPending = props => {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const myReq = counter.requisitionReducer.req_chosen;
  const myPermission = () =>
    counter.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'Reqp',
    );

  const [trigger, setTrigger] = useState(1);

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });

  const [articleCreate, setArticleCreate] = useState({
    showModal: false,
  });

  const [returnal, setReturnal] = useState({
    showModal: false,
  });

  const [selectedList, setselectedList] = useState([]);

  const isBoxSelected = id_article => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id_article === id_article);
      return !!target;
    }
  };

  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id_article === item.id_article);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id_article !== item.id_article);
      } else {
        let newChange = {
          id_requisitions: item.id_requisitions,
          id_article: item.id_article,
          qty_article: item.qty_article,
          nameformail: item.article?.description,
          status: 'rejected',
          comment: '',
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };

  const areAllRejected = () => {
    let res = false;
    if (Array.isArray(myReq?.article_list)) {
      //count all rejected
      let counting = 0;
      myReq?.article_list.forEach(x => {
        if (x.status === 'rejected') {
          counting++;
        }
      });

      //same as total?
      if (counting === myReq?.article_list.length) {
        res = true;
      }
    }
    return res;
  };

  const isBoxAllSelected = () => {
    let res = false;
    if (Array.isArray(myReq?.article_list) && Array.isArray(selectedList)) {
      //count all except rejects
      let counting = 0;
      myReq?.article_list.forEach(x => {
        if (x.status !== 'rejected') {
          counting++;
        }
      });

      //compare lenght
      if (counting === selectedList.length && counting > 0) {
        res = true;
      }
    }

    return res;
  };

  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(myReq?.article_list) && Array.isArray(tempSelected)) {
        myReq?.article_list.forEach(x => {
          if (x.status !== 'rejected') {
            //check if the article is in the selected array
            let newChange = {
              id_requisitions: x.id_requisitions,
              id_article: x.id_article,
              qty_article: x.qty_article,
              status: 'rejected',
              nameformail: x.article?.description,
              comment: '',
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  useEffect(() => {
    if (props.doIFdispatch) {
      dispatch(getIF({ cc_id: props.reqState?.id_consumption_center }));
      dispatch(getManufacturers());
    }
  }, [dispatch, props.doIFdispatch, props.reqState?.id_consumption_center]);

  useEffect(() => {
    dispatch(requisition_get_one(props.reqId));

    return () => {
      dispatch({
        type: REQUISITION_GET,
        payload: {},
      });
    };
  }, [dispatch, props.reqId, trigger]);

  const clickTableRow = reqArticle => {
    if (hasNewArticle(reqArticle)) {
      setArticleCreate({
        ...articleCreate,
        showModal: true,
        reqArticle: reqArticle,
      });
    } else {
      return;
    }
  };

  /* ============================= used in RequisitionsPending ================== */
  const optionFamilia = () => {
    let tempArray = [];
    if (Array.isArray(counter.inventoryFamilyReducer.inventoryFamily)) {
      counter.inventoryFamilyReducer.inventoryFamily.forEach(elem => {
        tempArray.push({
          value: elem.id,
          label: elem.description,
          measurements_list: elem.measurements_list,
          additional_info: elem.additional_info,
        });
      });
    }
    return tempArray;
  };

  const optionsManufacturers = () => {
    let tempArray = [];
    if (Array.isArray(counter.articleReducer.listManufacturers)) {
      counter.articleReducer.listManufacturers.forEach((elem, index) => {
        tempArray.push({
          value: index,
          label: elem,
        });
      });
    }
    return tempArray;
  };

  const renderHeadersPending = [
    <th key={`ReqModalPending1`} className='text-center'>
      <input
        key={'ReqModalPendingList_' + Math.random()}
        type='checkbox'
        name={'selectedAll'}
        value='selectedAll'
        defaultChecked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
    <th key={`ReqModalPending2`} className='text-center'>
      Código
    </th>,
    <th key={`ReqModalPending3`}>Artículo</th>,
    <th key={`ReqModalPending4`} className='text-center'>
      Cant.&nbsp;Requerida
    </th>,
    <th key={`ReqModalPending5`}>Ult.&nbsp;Entrega</th>,
    <th key={`ReqModalPending6`}>Cant.&nbsp;Entregada</th>,
    <th key={`ReqModalPending7`}></th>,
  ];

  const renderListPending = () => {
    let table = [];

    if (Array.isArray(myReq?.article_list)) {
      table = myReq?.article_list.map(x => {
        return (
          <tr
            key={'ReqModalPendingList' + x.id_article + '_' + Math.random()}
            className={`hover-table-row ${hasNewArticle(x) ? genericTableStyles.orange : ''}  ${x.status === 'rejected' ? reqStyles.rejected : ''} `}
          >
            <td className={`col-md-1`}>
              <input
                type='checkbox'
                name={'selected' + x.id_article}
                value='selected'
                defaultChecked={isBoxSelected(x.id_article)}
                onChange={() => changeBoxSelected(x)}
                disabled={x.status === 'rejected'}
              />
            </td>
            <td className={`col-md-1`} onClick={() => clickTableRow(x)}>
              <p className={`m-0 ${x.status === 'rejected' ? reqStyles.rejected : ''}`}>
                {x.id_article}
              </p>
            </td>
            <td className={`col-md-4`} onClick={() => clickTableRow(x)}>
              <p className={`m-0 ${x.status === 'rejected' ? reqStyles.rejected : ''} text-start`}>
                {x.article.description}
              </p>
            </td>
            <td className={`col-md-2`} onClick={() => clickTableRow(x)}>
              <p className={`m-0 ${x.status === 'rejected' ? reqStyles.rejected : ''}`}>
                {x.qty_article}
              </p>
            </td>

            <td className={`col-md-2 text-start`} onClick={() => clickTableRow(x)}>
              <p className={`m-0 ${x.status === 'rejected' ? reqStyles.rejected : ''}`}>
                ult entrega
              </p>
            </td>
            <td className={`col-md-2 text-start`} onClick={() => clickTableRow(x)}>
              <p className={`m-0 ${x.status === 'rejected' ? reqStyles.rejected : ''}`}>
                cant entregada
              </p>
            </td>
            <td onClick={() => clickTableRow(x)}>
              {hasNewArticle(x) ? (
                <img className='float-right mx-2' src={AlertTriangle} alt='Alerta' />
              ) : (
                <span>&ensp;&emsp;</span>
              )}
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  /* ============================= used in RequisitionsPending ================== */

  const paginateList = () => {
    const fullList = renderListPending();
    return fullList.slice(filters.perpage * (filters.page - 1), filters.perpage * filters.page);
  };

  const hasNewArticle = reqArt => {
    return reqArt.status === 'pending';
  };

  const presentableDate = new Date(props.reqState?.requisition_date);

  const btnDevolucion = () => {
    setReturnal({ ...returnal, showModal: true });
  };

  const enviarDevolucion = () => {
    let message = '';
    if (!!returnal.justification === false) {
      message = 'La justificación de devolución es obligatoria';
    }
    if (message) {
      customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: message,
        showCancelButton: false,
      });
      return null;
    }

    let preparedData = {
      id: props.reqState?.id,
      id_user: props.reqState?.id_user,
      numreq: props.reqState?.num_req,
      selectedList: selectedList,
      justification: returnal.justification,
    };

    setReturnal({ ...returnal, showModal: false });
    dispatch(
      requisition_update_returnal(preparedData, () => {
        setTrigger(trigger + 1);
        setselectedList([]);
      }),
    );
  };

  return (
    <ModalNew
      title={props.title ? props.title : 'Detalle de Requisición'}
      show={props.show}
      btnYesName={props.btnYesName ? props.btnYesName : 'Clonar'}
      btnNoName={props.btnNoName ? props.btnNoName : 'Cancelar'}
      size='xl'
      btnYesEvent={() => props.btnClonar()}
      btnYesDisabled={counter.requisitionReducer.loading}
      onHide={() => props.onHide()}
      btnNoEvent={() => btnDevolucion()}
      btnNoDisabled={
        counter.requisitionReducer.loading || areAllRejected() || selectedList.length < 1
      }
      hideCancelButton={myPermission()?.reject === 1 ? false : true}
    >
      <div className={reqStyles.container2}>
        <div className='d-flex'>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Fecha de Requisición</p>
            <input
              className='register-inputs'
              name='requisition_date'
              type='text'
              value={presentableDate.toLocaleDateString('es-CO')}
              disabled={true}
            />
          </div>
          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Centro de consumo</p>
            <input
              className='register-inputs'
              name='requisition_date'
              type='text'
              value={props.reqState?.cc_ref?.description}
              disabled={true}
            />
          </div>

          <div className={`${reqStyles.inputMargin} ${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Número de Requisición</p>
            <input
              className='register-inputs'
              name='num_req'
              type='text'
              value={props.reqState?.num_req}
              disabled={true}
            />
          </div>
          <div className={`${reqStyles.ItemSmall}`}>
            <p className={tableStyles.crudModalLabel}>Requerido por</p>
            <input
              className='register-inputs'
              name='id_user'
              type='text'
              value={props.reqState?.name_user}
              disabled={true}
            />
          </div>
        </div>

        <GenericTableNew headers={renderHeadersPending}>
          {!counter.requisitionReducer.loading && paginateList()}
        </GenericTableNew>
        {counter.requisitionReducer.loading && (
          <div className='mx-auto'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {myReq?.article_list?.length ? filters.page : ''}
            {' de '}
            {Math.ceil(myReq?.article_list?.length / filters.perpage)
              ? Math.ceil(myReq?.article_list?.length / filters.perpage)
              : ''}{' '}
            ({myReq?.article_list?.length} encontrados)
          </p>

          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={myReq?.article_list?.length}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={`${paginationStyles.itemClassPrev} ${paginationStyles.iconClear}`}
            itemClassNext={`${paginationStyles.itemClassNext} ${paginationStyles.iconClear}`}
            itemClassFirst={`${paginationStyles.itemClassFirst} ${paginationStyles.iconClear}`}
            itemClassLast={`${paginationStyles.itemClassLast} ${paginationStyles.iconClear}`}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Observaciones</p>
          <textarea
            id='txtArea'
            rows='10'
            cols='10'
            value={props.reqState?.observations}
            className={`register-inputs ${reqStyles.textArea}`}
            style={{ border: '1px solid #7FADDE' }}
            disabled={true}
          ></textarea>
        </div>
      </div>

      {articleCreate.showModal ? (
        <ReqModalArticleCreate
          show={articleCreate.showModal}
          optionFamilia={optionFamilia()}
          resetParent={() => setTrigger(trigger + 1)}
          optionsManufacturers={optionsManufacturers()}
          onHide={() => setArticleCreate({ ...articleCreate, showModal: false })}
          reqArticle={{
            ...articleCreate.reqArticle,
          }}
        />
      ) : (
        <span />
      )}

      <ModalNew
        title='Devolución'
        size='sm2'
        show={returnal.showModal}
        onHide={() => setReturnal({ ...returnal, showModal: false })}
        btnYesName='Enviar'
        btnYesEvent={() => enviarDevolucion()}
        btnYesDisabled={counter.requisitionReducer.loading}
        btnNoName='Cancelar'
      >
        <div className={`${reqStyles.ItemSmall}`}>
          <p className={tableStyles.crudModalLabel}>Justificación</p>
          <textarea
            id='justification'
            rows='3'
            cols='10'
            className={`register-inputs ${reqStyles.textArea} ${reqStyles.justification}`}
            value={returnal.justification}
            style={{ border: '1px solid #7FADDE' }}
            onChange={e => setReturnal({ ...returnal, justification: e.target.value })}
            disabled={counter.requisitionReducer.loading}
          ></textarea>
        </div>
      </ModalNew>
    </ModalNew>
  );
};

export default ReqModalPending;
