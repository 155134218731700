//Login's reducer

import {
    SHOW_ACCOUNT_INFO
  } from "../actions/actionTypes";
  
  const initialState = {
    user: "",
    password: "",
    error: null,
    displayError: false,
    displayInfo: false,
    loading: false,
    Authorization: "",
    Authenticated: false,
    message:"",
    showAccount:false,
  };
  export const headerReducer = (state = initialState, action) => {
    switch (action.type) {
      case SHOW_ACCOUNT_INFO:
        return {
          ...state,
          showAccount:action.payload
        };
      default:
        return state;
    }
  };
  