import { GET_ONE_PINVOICE, GET_PENDING_VENDOR_INVOICES } from "../actions/actionTypes";


const initialState = {
    results: [],
    resultsOnePinvoices: []
};
export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PENDING_VENDOR_INVOICES:
            return {
                ...state,
                results: action.payload
            };
            case GET_ONE_PINVOICE:
            return {
                ...state,
                resultsOnePinvoices: action.payload
            };
        default:
            return state;
    }
};