//Login's reducer

import {
  CITY_GET,
  PROVINCE_GET,
  COUNTRY_GET,
  PROVINCE_GET_ALTERNATIVE,
  CITY_GET_ALTERNATIVE,
  CITY_GET_ALL,
  PROVINCE_GET_ALL,
} from '../actions/actionTypes';

const initialState = {
  cities: [],
  provinces: [],
  citiesAlternative: [],
  provincesAlternative: [],
  allCities: [],
  allProvinces: [],
  countries: [],
};
export const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CITY_GET:
      return {
        ...state,
        cities: action.payload,
      };
    case PROVINCE_GET:
      return {
        ...state,
        provinces: action.payload,
      };
    case COUNTRY_GET:
      return {
        ...state,
        countries: action.payload,
      };
    case CITY_GET_ALTERNATIVE:
      return {
        ...state,
        citiesAlternative: action.payload,
      };
    case PROVINCE_GET_ALTERNATIVE:
      return {
        ...state,
        provincesAlternative: action.payload,
      };
    case CITY_GET_ALL:
      return {
        ...state,
        allCities: action.payload,
      };
    case PROVINCE_GET_ALL:
      return {
        ...state,
        allProvinces: action.payload,
      };
    default:
      return state;
  }
};
