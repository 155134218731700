import { Col, Row } from 'react-bootstrap';
import { OrdBiTable } from '../../OrderingModule/OrderingComponents/OrdBiTable';
// import alertCircle from '../../assets/img/icons/alert-circle-auxiliary-red.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import { formatteDocument, getAge, loader } from '../../helpers';

export const InfoPatientTab = ({ patient, loadPatient }) => {
  const formDataPersonal = [
    {
      title: 'Tipo de documento',
      subTitle: patient.results?.docType ? patient.results.docType : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'No. de documento',
      subTitle: patient.results?.document ? formatteDocument(patient.results?.document).format : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Nacimiento',
      subTitle: patient.results?.birthDate,
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Edad',
      subTitle: patient.results?.birthDate ? `${getAge(patient.results?.birthDate)} años` : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Género',
      subTitle:
        patient.results?.gender === 'male'
          ? 'Masculino'
          : patient.results?.gender === 'female'
            ? 'Femenino'
            : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Ocupación',
      subTitle: patient.results?.ocupation ? patient.results?.ocupation : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Estado civil',
      subTitle: patient.results?.maritalStatus ? patient.results?.maritalStatus : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Grupo sanguíneo',
      subTitle: patient.results?.bloodType ? patient.results?.bloodType : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Lentes de contacto',
      subTitle: patient.results?.use_contact_lens ? patient.results?.use_contact_lens : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Gafas',
      subTitle: patient.results?.use_glasses ? patient.results?.use_glasses : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];

  const formDataContact = [
    {
      title: 'Teléfono',
      subTitle: patient.results?.homephone ? patient.results.homephone : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Email',
      subTitle: patient.results?.email ? patient.results.email : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Celular',
      subTitle: patient.results?.cellphone ? patient.results.cellphone : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'WhatsApp',
      subTitle: patient.results?.whatsapp ? patient.results.whatsapp : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Departamento',
      subTitle: patient.results?.provinceName ? patient.results.provinceName : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Municipio',
      subTitle: patient.results?.cityName ? patient.results.cityName : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Zona',
      subTitle: patient.results?.address_zone ? patient.results.address_zone : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Barrio',
      subTitle: patient.results?.neighborhood ? patient.results.neighborhood : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
    {
      title: 'Dirección',
      subTitle: patient.results?.homeAddress ? patient.results.homeAddress : '-',
      titleSize: 5,
      subTitleSize: 5,
      titleClassName: `${styles.app_title_info}`,
      subTitleClassName: `${styles.app_subtitle_info}`,
    },
  ];
  return (
    <>
      {loadPatient && loader}
      <Row>
        <Col xl={5}>
          <div className={styles.app_container_biTable}>
            <span>Información personal</span>
            <OrdBiTable data={formDataPersonal} />
          </div>
          <div className={styles.app_container_biTable}>
            <span>Información de contacto</span>
            <OrdBiTable data={formDataContact} />
          </div>
        </Col>
        <Col key={patient?.results?.contracts.length} style={{ backgroundColor: '#F5FCFD' }} xl={7}>
          <div className={styles.app_container_biTable}>
            <div className={styles.app_container_alert}>
              <span>Aseguradora obligatoria</span>
              {/* <span>
                <img alt={'icon'} width={'22px'} src={alertCircle} />
              </span> */}
            </div>
            <div className={styles.container_info_required}>
              {patient?.results?.contracts.length > 0 && patient?.results?.contracts?.map((item, index) => {
                if (item.isMain === 1) {
                  return (
                    <div key={index}>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Empresa
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.corporateClient ? item?.corporateClient : '-'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Contrato
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.contractDescription ? item?.contractDescription : '-'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Población
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.population ? item?.population : '-'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Grupo de ingreso
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.crtName ? item?.crtName : '-'}
                        </Col>
                      </Row>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div className={styles.app_container_alert}>
              <span>Aseguradoras voluntarias</span>
            </div>
            <div className={styles.container_info_required}>
              {patient?.results?.contracts.length > 0 && patient?.results?.contracts.map((item, index) => {
                if (item.isMain === 0) {
                  return (
                    <div key={index}>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Empresa
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.corporateClient ? item?.corporateClient : '-'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          Contrato
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info}>
                          {item?.contractDescription ? item?.contractDescription : '-'}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3} className={styles.app_title_info}>
                          No. de póliza
                        </Col>
                        <Col xs={9} className={styles.app_subtitle_info_policy}>
                          {item?.policy ? item?.policy : '-'}
                        </Col>
                      </Row>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
