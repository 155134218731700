import bankMoveStyle from '../../TreasuryModule/MovimientosBancarios/bankMoveStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  getListCompanies,
  getObjectionType,
  getInfoInNotes,
  getTypesNotes,
  creatNote,
  getObjectedByClient,
} from '../../actions/billingActions';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import NumberFormat from 'react-number-format';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Title } from '../../shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function RecordNotes() {
  const historyHook = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(0);

  const [info, setInfo] = useState({
    type: '',
    client: '',
    eaccount: storage.loginReducer.currentAccount.id,
    objType: '',
    invoice: '',
    objection: '',
    value: '',
    user: storage.loginReducer.user_data.id,
    observations: '',
  });

  useEffect(() => {
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(getObjectionType({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(
      getTypesNotes({ category: 4, eaccount: storage.loginReducer.currentAccount.id, nature: 'C' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info.invoice !== '') {
      dispatch(getInfoInNotes(info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.invoice]);

  useEffect(() => {
    if (info.client !== '') {
      dispatch(getObjectedByClient(info));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info.client]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsObjections = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.objection_types)) {
    storage.billingReducer.objection_types.forEach(item => {
      optionsObjections.push({
        value: item.id,
        label: item.fullname,
        key: item.id + 'objections',
      });
    });
  }

  let optionsInvoices = storage.billingReducer.objected_by_client?.length
    ? [{ key: 'default', value: '', label: 'Seleccionar...' }]
    : [];
  if (Array.isArray(storage.billingReducer.objected_by_client)) {
    storage.billingReducer.objected_by_client.forEach(item => {
      optionsInvoices.push({
        value: item.id,
        label: item.sequence,
        key: item.id + 'objectedByClient',
      });
    });
  }

  let optionsNotes = storage.billingReducer.note_types?.legth
    ? [{ key: 'default', value: '', label: 'Seleccionar...' }]
    : [];
  if (Array.isArray(storage.billingReducer.note_types)) {
    storage.billingReducer.note_types.forEach(item => {
      optionsNotes.push({
        value: item.id,
        label: item.name,
        key: item.id + 'notes',
      });
    });
  }

  const buttonSave = () => {
    dispatch(
      creatNote(
        {
          eaccount: info.eaccount,
          client: info.client,
          objType: info.objType,
          invoice: info.invoice,
          type: info.type,
          objection: storage.billingReducer.info_notes.objectionInfo.id,
          value: info.value,
          user: info.user,
          observations: storage.billingReducer.info_notes.objectionInfo.description,
        },
        () => {
          setInfo({
            type: '',
            client: '',
            eaccount: storage.loginReducer.currentAccount.id,
            objType: '',
            invoice: '',
            objection: '',
            value: '',
            observations: '',
            user: storage.loginReducer.user_data.id,
          });
          setTrigger(trigger + 1);
          dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
          dispatch(getObjectionType({ eaccount: storage.loginReducer.currentAccount.id }));
          dispatch(
            getTypesNotes({ category: 4, eaccount: storage.loginReducer.currentAccount.id }),
          );
        },
      ),
    );
  };

  return (
    <>
      <div className={bankMoveStyle.centredContainer}>
        <div className={bankMoveStyle.container2}>
          <div className={tableStyles.container1}>
            <div className={tableStyles.ItemSmall} style={{ marginTop: '30px' }}>
              {storage.billingReducer.loading_create_invoice && (
                <div className='loading'>
                  <Loader type='Oval' color='#003f80' height={100} width={100} />
                </div>
              )}
              <Title
                title='Registro de notas'
                className={'mb-2'}
                onClickIcon={() => {
                  historyHook.push('/facturacion/inicio');
                }}
              />
            </div>
          </div>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <Row className='d-flex' style={{ width: '70%' }}>
            <Col xs={12}>
              <p className={tableStyles.crudModalLabel}>Empresa</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'client' + trigger}
                options={optionsCompanies}
                styles={customSelectNewDark}
                defaultValue={info.client}
                placeholder={'Seleccionar...'}
                onChange={e =>
                  setInfo({
                    ...info,
                    client: e.value,
                  })
                }
              />
            </Col>
          </Row>

          <Row className='d-flex' style={{ width: '70%' }}>
            <Col xs={12}>
              <p className={tableStyles.crudModalLabel}>Tipo de glosa</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'tipoglosa' + trigger}
                options={optionsObjections}
                placeholder={'Seleccionar...'}
                defaultValue={info.objType}
                styles={customSelectNewDark}
                onChange={e =>
                  setInfo({
                    ...info,
                    objType: e.value,
                  })
                }
              />
            </Col>
          </Row>

          <Row className='d-flex' style={{ width: '70%' }}>
            <Col xs={4}>
              <p className={tableStyles.crudModalLabel}>No. Factura</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={'noFact' + trigger}
                options={optionsInvoices}
                placeholder={'Seleccionar...'}
                defaultValue={info.invoice}
                styles={customSelectNewDark}
                onChange={e =>
                  setInfo({
                    ...info,
                    invoice: e.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              <p className={tableStyles.crudModalLabel}>Fecha de glosa</p>
              <input
                className={IndividualStyles.registerInputs}
                style={{ background: '#F5F7FA', color: '#58595B' }}
                name='requisition_date'
                type='text'
                placeholder={'Escribir...'}
                value={
                  storage.billingReducer.info_notes?.objectionInfo
                    ? storage.billingReducer.info_notes.objectionInfo.date.replace(
                        /^(\d{4})-(\d{2})-(\d{2})$/g,
                        '$3/$2/$1',
                      )
                    : ''
                }
                readOnly
              />
            </Col>
            <Col xs={4}>
              <p className={tableStyles.crudModalLabel}>Valor de la glosa</p>
              <input
                className={IndividualStyles.registerInputs}
                style={{ background: '#F5F7FA', color: '#58595B' }}
                name='requisition_date'
                type='text'
                placeholder={'Escribir...'}
                value={
                  storage.billingReducer.info_notes?.objectionInfo
                    ? '$' + numberWithCommas(storage.billingReducer.info_notes.objectionInfo.amount)
                    : ''
                }
                readOnly
              />
            </Col>
          </Row>

          {/* ---------------------------- ROW 4 ---------------------------------- */}
          <Row className='d-flex' style={{ width: '70%' }}>
            <Col xs={12}>
              <p className={tableStyles.crudModalLabel}>Descripción</p>
              <textarea
                style={{ height: '3rem', background: '#F5F7FA', color: '#58595B' }}
                rows='5'
                cols='40'
                placeholder={'Escribir...'}
                className={IndividualStyles.registerInputs}
                name='requisition_date'
                value={
                  storage.billingReducer.info_notes?.objectionInfo
                    ? storage.billingReducer.info_notes.objectionInfo.description
                    : ''
                }
                readOnly
              />
            </Col>
          </Row>
          {/*--------------------------------- ROW 5 --------------------------------- */}
          <Row className='d-flex' style={{ width: '70%' }}>
            <Col xs={6}>
              <p className={tableStyles.crudModalLabel}>Tipo de nota</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionsNotes}
                defaultValue={info.type}
                key={'noteClient' + trigger}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                onChange={e =>
                  setInfo({
                    ...info,
                    type: e.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              <p className={tableStyles.crudModalLabel}>Valor factura</p>
              <input
                className={IndividualStyles.registerInputs}
                name='requisition_date'
                type='text'
                placeholder={'Escribir...'}
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  storage.billingReducer.info_notes?.total_amount
                    ? '$' + numberWithCommas(storage.billingReducer.info_notes.total_amount)
                    : ''
                }
                readOnly
              />
            </Col>
          </Row>

          {/*--------------------------------- ROW 6 --------------------------------- */}

          <Row className='d-flex' style={{ width: '70%' }}>
            <Col className='d-flex'>
              <div className='col-12'>
                <p className={tableStyles.crudModalLabel}>Valor nota</p>
                <NumberFormat
                  placeholder={'Escribir...'}
                  allowNegative={false}
                  style={{ padding: 0, color: '#58595B' }}
                  className={IndividualStyles.registerInputs}
                  name='valueNote'
                  thousandsGroupStyle='thousand'
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'$'}
                  value={info.value}
                  onValueChange={values => {
                    const { value } = values;
                    const realValue = parseInt(value) !== 0 ? value : 1;
                    setInfo({
                      ...info,
                      value: realValue,
                    });
                  }}
                  min={1}
                ></NumberFormat>
              </div>
            </Col>

            <Col xs={6}>
              <p className={tableStyles.crudModalLabel}>Saldo</p>
              <input
                placeholder={'Escribir...'}
                className={IndividualStyles.registerInputs}
                name='requisition_date'
                type='text'
                style={{ background: '#F5F7FA', color: '#58595B' }}
                value={
                  !!(info.invoice !== '') && !!storage.billingReducer.info_notes?.total_amount
                    ? '$' +
                      numberWithCommas(
                        Number(storage.billingReducer.info_notes.total_amount) - Number(info.value),
                      )
                    : ''
                }
                readOnly
              />
            </Col>
          </Row>

          {/*--------------------------------- ROW 6 --------------------------------- */}
          <Row className='d-flex' style={{ width: '70%' }}>
            <div className={IndividualStyles.bottomRow}>
              <Button
                style={{ padding: '8px', outline: 'none', border: 0 }}
                className={`${tableStyles.buttonExtra3} d-block`}
                onClick={() => buttonSave()}
                disabled={
                  !!(info.value === '') ||
                  !!(info.client === '') ||
                  !!(info.type === '') ||
                  !!(info.objType === '') ||
                  !!(info.invoice === '')
                }
              >
                Guardar
              </Button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}
export default RecordNotes;
