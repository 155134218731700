import {
    ON_OPEN_DATE_MODAL, ON_ClOSE_DATE_MODAL
} from "../actions/actionTypes";

const initialState = {
    isDateModalOpen: false
};

export const uiModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_OPEN_DATE_MODAL:
            return {
                isDateModalOpen: true
            };
        case ON_ClOSE_DATE_MODAL:
            return {
                isDateModalOpen: false
            };
        default:
            return state;
    }

}
