// react, redux, router
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Styles
import styles from '../../../../components/Layouts/tableStyle.module.scss';
import stylesOrders from './stylesOrders.module.scss';
import { ordCustomSelect } from './../../../../components/Layouts/react-select-custom';

// Hooks
import { useGetMethod } from '../../../../Hooks/useFetch';
import { useHistory } from 'react-router-dom';

// helpers
import { today, loader, formatteDocument } from '../../../../helpers';

// Select, Material Ui, bootstrap
import Select from 'react-select';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import { Box } from '@mui/material';

// icons
import ordBlueSearch from '../../../../assets/img/icons/ordBlueSearch.svg';
import searchIcon from '../../../../assets/img/icons/searchCalendar.svg';
import addCheck from '../../../../../src/assets/img/icons/add-check-blue-dark.svg';

// components
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import { RenderRow } from '../../../../AdmissionModule/Orders/components';
import { ServiceOrderReplace } from '../../../../AdmissionModule/Orders';
import { Text } from '../../../UI/atoms';
import moment from 'moment';
import { formatNumberWithoutDecimals } from '../../../../helpers/numberFormatting';

export const CanceledProviders = () => {
  // states
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const history = useHistory();
  const isCoordAdmin = store?.loginReducer?.currentAccount?.profile?.prefijo === 'COORADMISIONIST';

  const { colors } = useTheme();
  const [search, setSearch] = useState('');
  const [data, setData] = useState({
    trigger: 0,
    showModalDetail: false,
    filters: {
      idUser,
      idAccount,
      isCoordinator: isCoordAdmin === true ? 1 : 0,
      isAdmisionist: isCoordAdmin === false ? 1 : 0,
      dateMax: '',
      dateMin: '',
      search: '',
      page: 1,
      perpage: 10,
    },
    amount: '',
    company: {
      id: '',
      name: '',
      nit: '',
    },
    contract: {
      id: '',
      name: '',
      sequence: '',
    },
    date: '',
    donation: '',
    hour: '',
    id: '',
    patient: {
      docType: '',
      document: '',
      id: '',
      name: '',
    },
    pendingAmountStatus: '',
    pending_amount: '',
    service: {
      description: '',
      id: '',
      name: '',
    },
    status: {
      background: '',
      fontColor: '',
      id: '',
      name: '',
    },
    modalAmount: {
      donation: 0,
      amountToDeliver: 0,
    },
  });

  const { filters, company, patient, trigger, modalAmount, showModalDetail } = data;

  //request
  const {
    load: loadListServiceOrder,
    results: listServiceOrder,
    trigger: getListServiceOrder,
  } = useGetMethod();

  const onChangeTrigger = () =>
    setData(state => ({ ...state, trigger: state.trigger + 1, search }));

  // -------------- SECTION FUNCTIONS TABLE  --------------------------------

  const headersCoordinador = [
    {
      title: 'Tipo',
      className: 'px-2 text-center',
    },
    {
      title: 'No. Orden',
      className: `px-2 text-center ${stylesOrders.w90px}`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center ${stylesOrders.w120px}`,
    },
    {
      title: 'Paciente',
      className: `px-2 text-start ${stylesOrders.w140px}`,
    },
    {
      title: 'Empresa',
      className: 'px-2 text-start',
    },
    {
      title: 'Contrato',
      className: 'px-2 text-start',
    },
    {
      title: 'Motivo',
      className: 'px-2 text-start',
    },
    {
      title: 'Admisionista',
      className: 'px-2 text-start',
    },
    {
      title: 'Total',
      className: `px-2 text-end ${stylesOrders.w110px}`,
    },
    {
      title: 'Estado',
      className: `px-2 text-center ${stylesOrders.w140px}`,
    },
    {
      // title: "",
      className: ' text-center',
    },
  ];

  const headers = [
    {
      title: 'Tipo',
      className: 'px-2 text-center',
    },
    {
      title: 'No. Orden',
      className: `px-2 text-center ${stylesOrders.w90px}`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center ${stylesOrders.w120px}`,
    },
    {
      title: 'Paciente',
      className: `px-2 text-start ${stylesOrders.w140px}`,
    },
    {
      title: 'Empresa',
      className: 'px-2 text-start',
    },
    {
      title: 'Contrato',
      className: 'px-2 text-start',
    },
    {
      title: 'Motivo',
      className: 'px-2 text-start',
    },
    {
      title: 'Admisionista',
      className: 'px-2 text-start',
    },
    {
      title: 'Total',
      className: `px-2 text-end ${stylesOrders.w110px}`,
    },
    {
      title: 'Estado',
      className: `px-2 text-center ${stylesOrders.w140px}`,
    },
    {
      // title: "",
      className: ' text-center',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-center px-2`}>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={props => (
                  <Tooltip {...props}>
                    <div
                      style={{
                        opacity: 1,
                        background: '#fff',
                        color: '#697387',
                      }}
                    >
                      {item?.typeName}
                    </div>
                  </Tooltip>
                )}
              >
                <img alt='pendingAmountStatus' src={item?.iconUrl} height='20px' width='20px' />
              </OverlayTrigger>
            </td>

            <td className={`text-center px-2 mx-2`}>{item?.prefixOrder || '-'}</td>

            <td className={`text-center px-2 mx-2`}>
              <span>{moment(item?.date)?.format('YYYY-MM-DD')}</span>
              <br />
              <span>{moment(item?.date)?.format('h:mm a')}</span>
            </td>

            <td className={`text-start px-2`}>
              <span>{item?.patient?.name}</span>
              <br />
              <span style={{ color: '#A2A3AB' }}>
                {`${item?.patient?.docType} ${formatteDocument(item?.patient?.doc).format}`}
              </span>
            </td>

            <td className={`text-start px-2`}>{item?.company?.name}</td>

            {!isCoordAdmin && <td className={`text-start px-2`}>{item?.contractName || '-'}</td>}

            <td className={`text-start px-2`}>{item?.cancelReason?.label || ` - `}</td>

            <td className={`text-start px-2`}>{item?.admisionist?.name || '-'}</td>

            <td className={`text-end px-2`}>
            {formatNumberWithoutDecimals(item?.totalPaidAmount)}
            </td>

            <td className={`text-center px-2`}>
              <span
                style={{
                  color: `${item?.status?.fontColor}`,
                  backgroundColor: `${item?.status?.background}`,
                  borderRadius: '10px',
                  padding: '2px 10px',
                }}
              >
                {item?.status?.name || '-'}
              </span>
            </td>

            <td className={`text-center pe-4`}>
              <img
                className='pointer'
                src={searchIcon}
                alt='search'
                height='15px'
                width='15px'
                onClick={() => {
                  setData(state => ({
                    ...state,
                    ...item,
                    showModalDetail: true,
                  }));
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //  ------------ SECTION MODAL CHANGE --------------------

  useEffect(() => {
    getListServiceOrder({
      url: '/medical/admissions/provider-orders/',
      token: token,
      objFilters: {
        ...filters,
        cancelledOrders: 1,
        generalResponse: 0,
        partialPayments: 0,
      },
    });
  }, [trigger, getListServiceOrder, token, filters]);

  useEffect(() => {
    if (modalAmount?.amountToDeliver <= 0) {
      setData(state => ({
        ...state,
        modalAmount: {
          ...state.modalAmount,
          donation: 0,
        },
      }));
    }
  }, [modalAmount?.amountToDeliver]);

  const printDataInsurance = () => {
    return (
      <>
        <RenderRow title={'Aseguradora'} subTitle={company?.name ? company?.name : '-'} />
        <RenderRow title={'Contrato'} subTitle={data?.contractName ? data?.contractName : '-'} />
        <RenderRow
          title={'Grupo de ingreso'}
          subTitle={data?.incomeGroupName ? data?.incomeGroupName : '-'}
        />
        <RenderRow title={'Origen'} subTitle={data?.population ? data?.population : '-'} />
      </>
    );
  };

  const printDataCite = () => {
    return (
      <>
        <RenderRow title={'Sede'} subTitle={data?.siteName ? data?.siteName : '-'} />
        <RenderRow title={'Proveedor'} subTitle={data?.providerName ? data?.providerName : '-'} />

        <RenderRow
          title={'Servicios'}
          subTitle={
            data?.orderServices?.length ? data?.orderServices?.map(el => el?.name)?.join() : '-'
          }
        />

        <RenderRow title={'Fecha'} subTitle={moment(data?.date)?.format('YYYY-MM-DD')} />

        <RenderRow title={'Hora'} subTitle={moment(data?.date)?.format('h:mm a')} />

        <RenderRow
          title={'Total'}
          subTitle={formatNumberWithoutDecimals(data?.totalPaidAmount)}
        />
      </>
    );
  };

  //  ------------- !SECTION MODAL CHANGE --------------------
  return (
    <>
      {loadListServiceOrder && loader}

      <ServiceOrderReplace
        customClassChildren={styles.replaceChildrenScroll}
        data={[]}
        title={`Detalle de anulación`}
        subTitle={`${patient?.docType} ${formatteDocument(patient?.doc).format} - ${patient?.name}`}
        show={showModalDetail}
        size={940}
        onHide={() => setData(state => ({ ...state, showModalDetail: false }))}
        btnNoEvent={() => setData(state => ({ ...state, showModalDetail: false }))}
        dataInsurance={{
          data: printDataInsurance,
          title: 'Cita original',
          subTitle: 'Datos de aseguradora',
        }}
        dataCite={{
          data: printDataCite,
          subTitle: 'Datos de la cita',
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                isDisabled
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                value={
                  data?.cancelReason?.label
                    ? {
                        label: data?.cancelReason?.label,
                        value: data?.cancelReason?.label,
                      }
                    : ''
                }
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                disabled
                placeholder='Escribir...'
                style={{ minHeight: '265px' }}
                className={`text-secondary ordInputAdmission w-100`}
                value={data?.cancelReason?.observation || '-'}
              />
            </Box>
          </Box>

          <Box display={'flex'} justifyContent='end' my={3}>
            <button
              className={`${styles.btnReplaceCancel}`}
              onClick={() => {
                setData(state => ({ ...state, showModalDetail: false }));
              }}
            >
              Cancelar
            </button>
          </Box>
        </Box>
      </ServiceOrderReplace>

      <div className='mx-2 my-2 asistencialDateColorIcon'>
        <Row className='d-flex px-1'>
          <Col xs={2} className={`px-0 align-self-end`}>
            <Form.Group className='px-2' controlId='ordType'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                <span>&nbsp;Fecha desde</span>
              </Form.Label>
              <input
                className={`ord-roundInput2`}
                style={filters.dateMin ? { color: '#1a538d' } : {}}
                type='date'
                id='date-start'
                value={filters.dateMin}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, dateMin: e.target.value },
                  }));
                }}
                max={today()}
              />
            </Form.Group>
          </Col>

          <Col xs={2} className={`px-0 align-self-end`}>
            <Form.Group className=' px-2' controlId='ordType'>
              <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                <span>&nbsp;Hasta</span>
              </Form.Label>
              <input
                className={`ord-roundInput2`}
                type='date'
                id='date-end'
                value={filters.dateMax}
                style={filters.dateMax ? { color: '#1a538d' } : {}}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    filters: { ...state.filters, dateMax: e.target.value },
                  }));
                }}
                min={filters.dateMin}
              />
            </Form.Group>
          </Col>

          <Col xs={4} className='align-self-end'>
            <Form.Group className=' text-start' controlId='fName'>
              <div className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                <input
                  className={`ord-roundInput ${styles.appInputDate} w-100`}
                  type='text'
                  placeholder='Escribe aquí para buscar'
                  autoComplete='off'
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      onChangeTrigger();
                      setData(state => ({
                        ...state,
                        filters: { ...state.filters, page: 1, search },
                      }));
                    }
                  }}
                />
                <img
                  src={ordBlueSearch}
                  className='pointer'
                  alt='buscar'
                  onClick={() => onChangeTrigger()}
                />
              </div>
            </Form.Group>
          </Col>

          <Col className={`align-self-end d-flex justify-content-end`}>
            <div
              className={styles.backGroundAquamarineHover}
              style={{
                borderRadius: '5px',
                padding: '0px 7px',
              }}
            >
              <Text
                classNameBox={`${styles.hoverOrdAquaMarine}`}
                title={'Anular orden'}
                iconPath={addCheck}
                iconHeight='18px'
                fontWeight={'bold'}
                handleClick={() => history.push('/admision/ordenesProveedores')}
                pointer
                padding={'3px 7px'}
                color={colors.ordDarkGray}
                classNameImg={styles.colorGray}
              />
            </div>
          </Col>
        </Row>
        {/* SECTION TABLE */}
        <Row>
          <div>
            <OrdTable
              shadow
              headers={isCoordAdmin ? headersCoordinador : headers}
              hasChildren={true}
              oneHeadDarkBlue={false}
              lowerCase={false}
              //   fTdClearBlue={true}
              paginate={{
                activePage: filters.page,
                totalPages: listServiceOrder?.rowTotal,
                perPage: filters.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  setData(state => ({
                    ...state,
                    trigger: trigger + 1,
                    filters: {
                      ...state.filters,
                      page: e,
                    },
                  }));
                },
                showTextDetails: true,
              }}
            >
              {formatData(listServiceOrder?.results || [])}
            </OrdTable>
          </div>
        </Row>
      </div>
    </>
  );
};
