import { Radio } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import Swal from 'sweetalert2';

import IconUser from '../../../../assets/img/icons/IconUser.svg';
import alertIcon from '../../../../assets/img/icons/alert-circle-auxiliary-red.svg';
import goBackIcon from '../../../../assets/img/icons/nomIconRow.svg';
import send from '../../../../assets/img/icons/send.svg';

import { useGetMethod, usePostMethod } from '../../../../Hooks/useFetch';
import { formatToRcSelect, isEmptyOrUndefined, loader } from '../../../../helpers/helpers';
import { responseSwal } from '../../../../helpers/responseSwal';

import { OrdGenericTemplate } from '../../../../OrderingModule/OrderingComponents/OrdGenericTemplate';

import { customSelectNewDark } from '../../../Layouts/react-select-custom';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import CardAccordionComponent from '../../../PayRollModule/partners/PerformanceAppraisal/CardAccordionComponent';

const AnswerSurvey = () => {
  const location = useLocation();
  const history = useHistory();
  const store = useSelector(state => state);
  const idUser = store.loginReducer.user_data.id;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'jobWea',
  );
  const idAccount = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const data = location?.state?.data;
  const dataBefore = location?.state?.dataBefore;
  const idSurvey = location?.state?.idSurvey;
  const {
    results: detailSurveyResults,
    load: detailSurveyLoader,
    trigger: getDetailSurvey,
  } = useGetMethod();
  const {
    results: dependenciesOptionsResults,
    load: dependenciesOptionsLoader,
    trigger: getDependenciesOptions,
  } = useGetMethod();
  const dependenciesOptions = formatToRcSelect(
    dependenciesOptionsResults?.results,
    'id',
    'title',
    '',
    '',
    '',
  );

  const [invalidSections, setInvalidSections] = useState([]);
  const [sections, setSections] = useState([]);
  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }
    getDependenciesOptions({
      url: '/payroll/dependencies',
      objFilters: {
        eaccount: idAccount,
      },
      token: token,
    });
    getDetailSurvey({
      url: '/payroll/performance_evaluation_detail',
      objFilters: {
        eaccount: idAccount,
        idUserAssessed: idUser,
        id: idSurvey,
      },
      token: token,
      doAfterSuccess: res => {
        setSections(res?.results?.sections);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const optionsType = [
    { value: '', label: 'Seleccione...' },
    { value: 'specific', label: 'Especifico' },
    { value: 'transversal', label: 'Transversal' },
  ];
  const optionsRadio = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

  const bodyTitle = elem => {
    return (
      <>
        <Row className='' key={elem.idSection}>
          <Col xs={8}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Título
              <span className={'text-danger'}>*</span>
            </p>
            <input
              key={elem.idSection}
              defaultValue={elem.title}
              readOnly
              className={`${tableStyles.nomInput}  text-secondary`}
              name='title'
            />
          </Col>
          <Col xs={elem?.type === 'specific' ? 2 : 4}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Tipo
              <span className={'text-danger'}>*</span>
            </p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={elem.idSection}
              styles={customSelectNewDark}
              placeholder={'Seleccione...'}
              value={optionsType.find(
                x =>
                  x.value ===
                  detailSurveyResults?.results?.sections?.find(
                    e => e?.idSection === elem?.idSection,
                  ).type,
              )}
              isSearchable={false}
              menuIsOpen={false}
              options={optionsType}
            />
          </Col>
          {elem?.type === 'specific' && (
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>
                Dependencia
                <span className={'text-danger'}>*</span>
              </p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={elem.idSection}
                styles={customSelectNewDark}
                placeholder={'-Seleccione-'}
                value={dependenciesOptions?.find(
                  x =>
                    x.value ===
                    detailSurveyResults?.results?.sections?.find(
                      e => e?.idSection === elem?.idSection,
                    )?.idDependece,
                )}
                isSearchable={false}
                menuIsOpen={false}
                options={dependenciesOptions}
              />
            </Col>
          )}
        </Row>
        <Row className=''>
          <Col xs={12}>
            <p className={`${tableStyles.crudModalLabel} `}>
              Descripción de la evaluación
              <span className={'text-danger'}>*</span>
            </p>
            <textarea
              key={elem.idSection}
              defaultValue={elem.description}
              placeholder={'Escribir...'}
              rows='10'
              cols='10'
              className={`${tableStyles.nomInput}  text-secondary`}
              style={{ height: '6rem' }}
              name='description'
              readOnly
            ></textarea>
          </Col>
        </Row>
      </>
    );
  };

  const selectedOption = (e, idSection, idQuestion) => {
    let tempSection = sections;
    let findSection = tempSection.find(elm => elm.idSection === idSection);
    let findIndexSection = tempSection.findIndex(elm => elm.idSection === idSection);
    let findQuestion = findSection?.questions?.find(x => x.idAsk === idQuestion);
    findQuestion.idAnswer = Number(e.target.id);
    tempSection[findIndexSection] = findSection;
    setSections(tempSection);
  };

  const bodyCard = (elem, findSectionInvalid) => {
    return (
      <>
        {elem?.questions?.map(question => {
          let hasBorder = elem.questions.length > 1 ? '1px solid #CBCBD0' : '0px';

          let findQuestionInvalid;
          if (findSectionInvalid) {
            findQuestionInvalid = findSectionInvalid?.questions?.find(x => x === question.idAsk);
          }

          return (
            <div key={question.idAsk} style={{ padding: '10px 0px', borderBottom: `${hasBorder}` }}>
              <Row className='mx-2' key={question.idAsk}>
                <Col xs={12}>
                  <p className={`${tableStyles.crudModalLabel} `}>
                    Pregunta
                    {findQuestionInvalid && (
                      <span className={'text-danger mx-2'}>Obligatorio *</span>
                    )}
                  </p>
                  <input
                    key={question.idAsk}
                    defaultValue={question.label}
                    readOnly
                    className={`${findQuestionInvalid ? tableStyles.invalidInput : tableStyles.nomInput}  text-secondary`}
                    name='position'
                  />
                </Col>
              </Row>
              <Row>
                <div className='my-4 d-flex align-items-center justify-content-center gap-4'>
                  <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>Nunca</span>
                  <div className='d-flex gap-2'>
                    {optionsRadio.map(radio => {
                      return (
                        <div key={radio.id} className='mx-2'>
                          <span className={` ${tableStyles.tlnTextGray} ${tableStyles.f14} `}>
                            {radio.id}
                          </span>
                          <Radio
                            key={question.idAsk}
                            checked={
                              question?.idAnswer
                                ? question?.idAnswer === Number(radio.id)
                                  ? true
                                  : false
                                : false
                            }
                            onClick={e => {
                              selectedOption(e, elem.idSection, question.idAsk);
                            }}
                            name='alphabetic'
                            id={Number(radio.id)}
                            style={{ color: '#005dbf' }}
                          />
                        </div>
                      );
                    })}
                  </div>

                  <span className={` ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                    Siempre
                  </span>
                </div>
              </Row>
            </div>
          );
        })}
      </>
    );
  };

  const surveyValidations = () => {
    let invalidSections = [];
    sections?.every(section => {
      let questionsInvalid = [];
      section?.questions?.forEach(question => {
        if (isEmptyOrUndefined(question.idAnswer)) {
          questionsInvalid.push(question.idAsk);
        }
      });

      if (questionsInvalid.length > 0) {
        invalidSections.push({
          section: section.idSection,
          questions: questionsInvalid,
        });
      }

      setInvalidSections(invalidSections);
      return true;
    });

    let body = {
      description: detailSurveyResults?.results?.description,
      eaccount: idAccount,
      endDate: detailSurveyResults?.results?.endDate,
      id: detailSurveyResults?.results?.id,
      createdBy: idUser,
      userAssessed: data?.userAssessed,
      sections: sections,
      startDate: detailSurveyResults?.results?.startDate,
      status: detailSurveyResults?.results?.status,
      statusName: detailSurveyResults?.results?.statusName,
      title: detailSurveyResults?.results?.title,
      idContract: data?.idContract,
    };

    if (invalidSections.length === 0) {
      sendSurvey(body);
    }
  };

  const { load: sendSurveyAnswersLoader, trigger: sendSurveyAnswers } = usePostMethod();

  const sendSurvey = body => {
    Swal.fire({
      title: `<span style=color:#003f80>¿Está seguro?</span>`,
      text: `Se enviará una evaluación `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        sendSurveyAnswers({
          url: '/payroll/performance_evaluation_answers',
          token: token,
          noAlert: true,
          method: 'POST',
          body: body,
          doAfterSuccess: results => {
            responseSwal({
              title: `<span style=color:#005dbf>${results.title}</span>`,
              icon: 'success',
            });
            handleBack();
          },
          doAfterException: err => {
            responseSwal({
              title: `<span style=color:#005dbf>${err.title}</span>`,
              icon: 'success',
            });
          },
        });
      }
    });
  };

  const handleBack = () => {
    history.push({
      pathname: '/miNomina/miNomina/registros/detalleDeEvaluacion',
      state: { data: dataBefore },
    });
  };

  return (
    <>
      {(dependenciesOptionsLoader || detailSurveyLoader || sendSurveyAnswersLoader) && loader}
      <OrdGenericTemplate className='mx-4 mt-4'>
        <Row>
          <div className='d-flex'>
            <button
              onClick={() => {
                handleBack();
              }}
            >
              <img alt='backIcon' src={goBackIcon} />
            </button>
            <div className={`${tableStyles.iconUserCircle} mx-4 align-self-center`}>
              <img alt='iconUser' src={data.picture ?? IconUser} />
            </div>

            <div className='d-flex flex-column mx-2 align-self-center'>
              <b className={`${tableStyles.ordDarkBlueText} ${tableStyles.f30}`}>
                {data.fullNameUserAssessed}
              </b>
              <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f15} `}>
                {data.jobTitle}
              </span>
              <span className={`${tableStyles.ordDarkBlueText} ${tableStyles.f12}`}>
                <b> Evaluador | </b>
                {data.fullNameUserAssessed}
              </span>
            </div>
          </div>
        </Row>

        <Row className='mt-4'>
          {detailSurveyResults?.results &&
            sections.map((elem, index) => {
              const labelAtTitle = (
                <span className={`${tableStyles.ordOrangeText} ${tableStyles.f12}`}>
                  {' '}
                  Todas las preguntas son obligatorias.
                  <img alt='icon' src={alertIcon} className='mx-2' />
                </span>
              );

              let findSectionInvalid = invalidSections.find(x => x.section === elem.idSection);
              let findSection = sections?.find(e => e?.idSection === elem?.idSection);

              return (
                <div key={elem.idSection} className='my-4'>
                  <CardAccordionComponent
                    title={`• Sección ${index + 1} de ${sections.length}`}
                    subtitle={` ${findSection?.questions?.length} pregunta(s)`}
                    bodyTitle={bodyTitle(elem)}
                    bodyCard={bodyCard(elem, findSectionInvalid)}
                    cardBodyClose={findSectionInvalid ? tableStyles.invalidCard : null}
                    cardBodyOpen={findSectionInvalid ? tableStyles.invalidCard : null}
                    labelAtTitle={findSectionInvalid ? labelAtTitle : null}
                  />
                </div>
              );
            })}
        </Row>
        <Row>
          <div className={`customTabs__Footer mt-4 ml-1 gap-2 px-2`}>
            <button
              onClick={() => {
                surveyValidations();
              }}
              className={tableStyles.btnPrimaryAlt}
              style={{ width: '200px' }}
            >
              <img src={send} alt='iconSend' height='16px' className='mr-2' />
              Enviar a Talento Humano
            </button>
          </div>
        </Row>
      </OrdGenericTemplate>
    </>
  );
};

export default AnswerSurvey;
