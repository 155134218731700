import { Component } from 'react';
import { connect } from 'react-redux';
import deepcopy from 'deepcopy';

import leftSpiral from '../../assets/img/icons/leftSpiral.svg';
import rightSpiral from '../../assets/img/icons/rightSpiral.svg';
import BiowellLogo from '../../assets/img/icons/bioWel.svg';
import send from '../../assets/img/icons/send.svg';
import carbonUserAvatar from '../../assets/img/icons/carbonUserAvatar.svg';
import carbonPassKey from '../../assets/img/icons/carbonPassKey.svg';
import carbonEmail from '../../assets/img/icons/carbonEmail.svg';

import { validateUser } from '../../actions/loginActions';
import { validateEmail } from '../../helpers/helpers';
import { responseSwal } from '../../helpers/responseSwal';
import { forgotPassword } from '../../actions/loginActions';

import { TextField, Button } from '../../shared';

import './Auth.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      email: '',
      forgotPassword: false,
    };
  }

  onChange = e => {
    let name = e.target.name;
    const value = e.target.value;
    if (name === 'username') this.setState({ username: value });
    if (name === 'password') this.setState({ password: value });
    if (name === 'email') this.setState({ email: value });
  };

  componentDidMount() {
    const { history } = this.props;
    if (this?.props.isAuth) {
      history?.push('/accountselect');
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuth !== this.props.isAuth) {
      const { history } = this.props;
      if (this.props.isAuth) {
        history?.push('/accountselect');
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    let { username } = this.state;
    let { password } = this.state;
    this.props.validateFieldsLogin(username, password);
  };

  checkForgotPassword() {
    let { email } = this.state;
    let changeemail = deepcopy(email);
    if (!validateEmail(changeemail)) {
      return responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Correo no válido',
      });
    } else {
      this.props.forgotPassword(this.state);
    }
  }

  render() {
    return (
      <div className='auth-section'>
        <img src={rightSpiral} className='shape shape-1' alt='' />
        <img src={leftSpiral} className='shape shape-2' alt='' />

        <div className='card auth-section__card'>
          {this.state?.forgotPassword ? (
            <form className='d-flex flex-column fs12px' onSubmit={this.handleSubmit}>
              <img
                src={BiowellLogo}
                className='img-fluid mb-3 align-self-center'
                width={300}
                alt='biowell-logo'
                draggable='false'
              />
              <h3 className='text-primary fw-bold mt-3 fs21px'>¿Olvidó su contraseña?</h3>
              <p className='fs12px'>
                Ingrese su correo electrónico y le enviaremos una contraseña provicional de ingreso
              </p>
              <TextField
                className='w-100 mb-1'
                placeholder='Correo electrónico'
                name='email'
                value={this.state.email}
                onChange={this.onChange}
                startIcon={carbonEmail}
              />

              <div className='d-flex justify-content-end mt-3'>
                <Button
                  className='me-3 w-25'
                  type='button'
                  onClick={() => this.setState({ forgotPassword: false })}
                >
                  Atrás
                </Button>

                <Button
                  className='w-25'
                  variant={'secondaryLight'}
                  startIcon={send}
                  onClick={() => this.checkForgotPassword()}
                >
                  Enviar
                </Button>
              </div>
            </form>
          ) : (
            <form className='d-flex flex-column fs12px' onSubmit={this.handleSubmit}>
              <img
                src={BiowellLogo}
                className='img-fluid mb-3 align-self-center'
                width={300}
                alt='biowell-logo'
                draggable='false'
              />
              <TextField
                type='email'
                className='w-100 mt-3 mb-1'
                placeholder='Usuario'
                name='username'
                value={this.state.username}
                onChange={this.onChange}
                startIcon={carbonUserAvatar}
              />

              <TextField
                type='password'
                className='w-100 mt-3 mb-1'
                placeholder='Contraseña'
                name='password'
                value={this.state.password}
                onChange={this.onChange}
                startIcon={carbonPassKey}
              />

              <div className='d-flex align-items-center mt-3 justify-content-between'>
                <div
                  className='text-primary pointer'
                  onClick={() => this.setState({ forgotPassword: true })}
                >
                  ¿Olvidó su contraseña?
                </div>
                <Button variant='secondaryLight' type='submit' className='border-0 fs13px w-25'>
                  ACCEDER
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.loginReducer.Authenticated,
});

const mapDispatchToProps = dispatch => ({
  validateFieldsLogin: (username, password) => {
    dispatch(validateUser(username, password));
  },
  forgotPassword: account => {
    dispatch(forgotPassword(account));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
