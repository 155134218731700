//-------------- components 🍕   --------------------------------------------
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import threeDots from '../../../assets/img/icons/threeDots.svg';
import Lupa from '../../../assets/img/icons/lupa.svg';
import percent from '../../../assets/img/icons/percent.svg';
import { customSelectNew4 } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { getPermission, isEmptyOrUndefined, loader } from '../../../helpers/helpers';
import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import { optionsYears } from '../../../helpers/optionsYears';
import NumberFormat from 'react-number-format';
import { customSwaltAlert } from './../../../helpers/customSwaltAlert';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../../helpers/numberFormatting';

export const Groups = () => {
  //-------------- states 🍕   --------------------------
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const listPermission = store.loginReducer.currentAccount?.profile?.permission;
  const myPermission = getPermission({ prefix: 'copayRate', listPermission });
  const [nameEdit, setNameEdit] = useState('');
  const [data, setData] = useState({
    modalActive: false,
    modalCreate: false,
    data: {
      name: '',
      value: '',
      vatId: '',
      copaymentValue: '',
      enabled: '',
      year: new Date().getFullYear(),
    },
    listIncomeGroups: [],
    listYears: optionsYears(),
    filters: {
      year: new Date().getFullYear(),
      name: '',
      enabled: '',
      ...{ eaccount, tm: true },
    },
    trigger: 0,
  });

  const localeSeparators = useRef(getLocaleSeparators())

  /* -------------------------------------------------------------------------- */
  /*                                  Petitions                                 */
  /* -------------------------------------------------------------------------- */

  const {
    results: listIncomeGroups,
    trigger: getIncomeGroups,
    load: loadIncomeGroups,
  } = useGetMethod();

  const { results: listVat, trigger: getVat, load: loadVat } = useGetMethod();

  const { load: loadUpdateIncomeGroup, trigger: updateIncomeGroup } = usePostMethod();

  /* /* ---------------------------      ACTIONS  -------------------------- */

  const actionRemoveIncomeGroup = group => {
    setData(state => ({ ...state, modalActive: false }));
    const incomeGroupStatus = group.enabled ? 'deshabilitará' : 'habilitará';

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${incomeGroupStatus} el grupo de ingreso: ${group.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      setData(state => ({ ...state, modalActive: false }));
      if (isConfirmed) {
        updateIncomeGroup({
          url: '/medical/copaymentRate/',
          token: token,
          method: 'PUT',
          body: {
            enabled: group.enabled ? false : true,
            id: group.id,
            name: group.name,
            year: group.YEAR,
          },

          succesAction: res => {
            setData(state => ({
              ...state,
              data: {
                name: '',
                value: '',
                vatId: '',
                year: new Date().getFullYear(),
              },
              trigger: state.trigger + 1,
            }));

            getIncomeGroups({
              url: `/medical/copaymentRate/`,
              objFilters: { eaccount, tm: true, year: new Date().getFullYear() },
              token: token,
            });

            customSwaltAlert({
              icon: 'success',
              title: `Actualizado exitosamente`,
              text: res.message,
              confirmButtonText: `Aceptar`,
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            customSwaltAlert({
              icon: 'warning',
              title: `Intenta de nuevo`,
              text: `${res.message}`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const actionUpdateIncomeGroup = () => {
    setData(state => ({ ...state, modalActive: false }));

    let formatCopaymentValue = data?.data?.copaymentValue;

    if (data?.data?.copaymentValue[data?.data?.copaymentValue?.length - 1] === '.') {
      formatCopaymentValue += '0';
    }

    customSwaltAlert({
      title: '¿Está seguro?',
      text: `Desea actualizar el grupo de ingreso: ${nameEdit}`,
      confirmButtonText: 'Si, continuar',
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      setData(state => ({ ...state, modalActive: false }));

      if (isConfirmed) {
        updateIncomeGroup({
          url: '/medical/copaymentRate/',
          token: token,
          method: 'PUT',
          body: {
            ...data.data,
            value: Number(data?.data?.value),
            copaymentValue: Number(formatCopaymentValue),
            enabled: true,
          },

          succesAction: res => {
            setData(state => ({ ...state, trigger: state.trigger + 1 }));

            getIncomeGroups({
              url: `/medical/copaymentRate/`,
              objFilters: { eaccount, tm: true, year: new Date().getFullYear() },
              token: token,
            });

            customSwaltAlert({
              icon: 'success',
              title: `Actualizado exitosamente`,
              text: res.message,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
          doAfterException: res => {
            customSwaltAlert({
              icon: 'error',
              title: 'Error al actualizar',
              text: `${res.message}`,
              footer: 'Si el problema persiste comuníquese con un asesor.',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const actionCreateIncomeGroup = () => {
    setData(state => ({ ...state, modalActive: false }));

    let formatCopaymentValue = data?.data?.copaymentValue;

    if (data?.data?.copaymentValue[data?.data?.copaymentValue?.length - 1] === '.') {
      formatCopaymentValue += '0';
    }

    updateIncomeGroup({
      url: '/medical/copaymentRate/',
      token: token,
      method: 'POST',
      body: {
        ...data.data,
        value: Number(data?.data?.value),
        copaymentValue: Number(formatCopaymentValue),
        enabled: true,
      },

      succesAction: () => {
        setData(state => ({ ...state, trigger: state.trigger + 1 }));

        getIncomeGroups({
          url: `/medical/copaymentRate/`,
          objFilters: { eaccount, tm: true, year: new Date().getFullYear() },
          token: token,
        });

        customSwaltAlert({
          icon: 'success',
          title: `Creado exitosamente`,
          text: `Se ha creado el grupo de ingreso: ${data?.data?.name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
      doAfterException: res => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const validateCreateOrUpdateIncomeGroup = () => {
    const { name, value, vatId, copaymentValue } = data.data;

    if (name?.length <= 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un nombre correcto',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (value?.length <= 0 || value < 0) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un valor de cuota moderadora correcto',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (isEmptyOrUndefined(copaymentValue) || copaymentValue > 100) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un valor de copago correcto',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    if (vatId?.length < 0 || vatId <= 0 || vatId === '') {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Digite un valor de iva correcto',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }

    data.modalCreate ? actionCreateIncomeGroup() : actionUpdateIncomeGroup();
  };

  const handleKeyDown = (e, searchClick) => {
    if (e.key === 'Enter' || searchClick) {
      setData(state => ({ ...state, trigger: state.trigger + 1 }));

      getIncomeGroups({
        url: `/medical/copaymentRate/`,
        objFilters: { ...data.filters, name: e.target.value },
        token: token,
      });
    }
  };

  const renderListIncomeGroup = () => {
    return data?.listIncomeGroups?.map((e, key) => (
      <Box
        key={key}
        display={'grid'}
        gap={3}
        gridTemplateColumns={'1fr 1fr 1fr 1fr 200px'}
        className={tableStyles.cardStyles2}
      >
        <Box display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Nombre</strong>
          <span>{e?.name || '-'}</span>
        </Box>

        <Box className='text-end px-2' display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Cuota moderadora</strong>
          <span>{e?.value ? `${formatNumberWithoutDecimals(e?.value)}` : '-'}</span>
        </Box>

        <Box className='text-center' display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>Copago</strong>
          <span>
            {!isEmptyOrUndefined(e?.copaymentValue) ? `${Number(e?.copaymentValue)}%` : '-'}
          </span>
        </Box>

        <Box className='text-center' display={'grid'} gridTemplateColumns={'1fr'}>
          <strong>IVA</strong>
          <span>{String(e?.ivaValue >= 0 ? e?.ivaValue : '')?.length ? e?.ivaShortName : '-'}</span>
        </Box>

        <Box display={'grid'} gap={2} gridTemplateColumns={'150px 60px'} alignItems='center'>
          <Box className={e.enabled ? tableStyles.greenState2 : tableStyles.redState2}>
            {e?.enabled ? 'Habilitado' : 'Inhabilitado'}
          </Box>

          {myPermission?.edit && (
            <CustomPopupExtend
              noHover
              position='right center'
              triggerSrc={threeDots}
              showEdit={myPermission?.edit}
              showEnable={myPermission?.edit}
              isEnabled={e?.enabled}
              editClickEvent={() => {
                setNameEdit(e?.name);
                setData(state => ({
                  ...state,
                  data: {
                    ...state.data,
                    name: e.name,
                    copaymentValue: e.copaymentValue,
                    value: e.value,
                    vat: e.iva,
                    vatId: e?.vatId,
                    id: e.id,
                  },
                  modalActive: true,
                  modalCreate: false,
                }));
              }}
              enableClickEvent={() => {
                setData(state => ({
                  ...state,
                  data: { ...e },
                  modalActive: true,
                  modalCreate: false,
                }));

                actionRemoveIncomeGroup(e);
              }}
            />
          )}
        </Box>
      </Box>
    ));
  };

  const renderNotData = () => (
    <div
      className='alert mt-5 text-center w-75 m-auto'
      style={{ backgroundColor: '#005dbf0d', color: '#005dbf' }}
      role='alert'
    >
      No hay datos para mostrar
    </div>
  );

  /* -------------------------------------------------------------------------- */
  /*                                   Effects                                  */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    //The endpoint always asks for the tm parameter to be true.
    getIncomeGroups({
      url: `/medical/copaymentRate/`,
      objFilters: { eaccount, tm: true, year: new Date().getFullYear() },
      token: token,
    });

    getVat({
      url: `/invoicing/vat/`,
      objFilters: { eaccount },
      token: token,
    });
  }, [eaccount, getIncomeGroups, getVat, token]);

  useEffect(() => {
    if (listIncomeGroups?.results) {
      setData(state => ({
        ...state,
        listIncomeGroups: listIncomeGroups?.results,
      }));
    }
  }, [listIncomeGroups]);

  // useEffect(() => {

  //   getIncomeGroups({
  //     url: `/medical/copaymentRate/`,
  //     token: token,
  //     objFilters: {
  //       year: new Date().getFullYear(),
  //       name: "",
  //       enabled: "",
  //     },
  //   });

  // }, [getIncomeGroups, token]);

  return (
    <>
      {(loadIncomeGroups || loadUpdateIncomeGroup || loadVat) && loader}

      <div className='w-100 mb-5'>
        {/* /* ---------------------------  MODAL -------------------------- */}

        <OrdModal
          hideBtnYes={data.modalCreate ? !myPermission?.create : !myPermission?.edit}
          title={data.modalCreate ? 'Crear grupo de ingreso' : 'Actualizar grupo de ingreso'}
          titleClassName={tableStyles.darkBlueText}
          show={data.modalActive}
          btnYesName='Aceptar'
          btnYesStyle={tableStyles.btnYesStyle}
          btnCancelStyle={tableStyles.btnCancelStyle}
          btnNoName='Cancelar'
          size='500'
          btnYesEvent={() => validateCreateOrUpdateIncomeGroup()}
          onHide={() => {
            setData(state => ({ ...state, modalActive: false }));
          }}
          btnNoEvent={() => {
            setData(state => ({ ...state, modalActive: false }));
          }}
        >
          <Box>
            <Box>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Nombre
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <input
                className={`register-inputs`}
                style={{ maxHeight: '32px', height: '32px' }}
                type='text'
                value={data.data.name}
                maxLength={'20'}
                placeholder={'Escribir...'}
                onChange={({ target }) => {
                  setData(state => ({
                    ...state,
                    data: { ...state.data, name: target.value },
                  }));
                }}
              />
            </Box>

            <Box mt={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Cuota moderadora
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <NumberFormat
                allowNegative={false}
                className={`register-inputs ${tableStyles.inputPlaceholder}`}
                placeholder={formatNumberWithoutDecimals(5000)}
                style={{ maxHeight: '32px', height: '32px' }}
                thousandSeparator={localeSeparators.current.groupSeparator}
                decimalSeparator={localeSeparators.current.decimalSeparator}
                format={valueStr => {
                  if (!valueStr) return '';

                  return formatNumberWithoutDecimals(Number(valueStr));
                }}
                decimalScale={0}
                isNumericString={true}
                value={data.data.value ? data.data.value : ''}
                min={0}
                onValueChange={({ value }) => {
                  setData(state => ({
                    ...state,
                    data: { ...state.data, value: value },
                  }));
                }}
              />
            </Box>

            <Box mt={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Copago
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <div className='d-flex position-relative align-items-center'>
                <input
                  className={`register-inputs`}
                  style={{ maxHeight: '32px', height: '32px' }}
                  type='text'
                  value={data.data.copaymentValue}
                  maxLength={'4'}
                  placeholder={'Escribir...'}
                  onChange={({ target }) => {
                    if (/^[0-9]+([.])?([0-9]+)?$/.test(target.value)) {
                      setData(state => ({
                        ...state,
                        data: {
                          ...state.data,
                          copaymentValue: target.value,
                        },
                      }));
                    } else {
                      if (!target.value.length) {
                        setData(state => ({
                          ...state,
                          data: {
                            ...state.data,
                            copaymentValue: '',
                          },
                        }));
                      }
                    }
                  }}
                />

                <div className={tableStyles.percentStyle}>
                  <img src={percent} alt='percentage' />
                </div>
              </div>
            </Box>

            <Box my={2}>
              <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                <span>
                  {' '}
                  &nbsp;Iva
                  <span className={`${tableStyles.ordOrangeText}`}>*</span>{' '}
                </span>
              </Form.Label>

              <Select
                value={
                  data.data.vatId && {
                    label: listVat?.results?.find(e => Number(e.id) === Number(data.data.vatId))
                      ?.short_name,
                    value: listVat?.results?.find(e => Number(e.id) === Number(data.data.vatId))
                      ?.id,
                  }
                }
                options={
                  listVat?.results?.length
                    ? [
                        { label: 'Seleccionar...', value: '' },
                        ...(listVat?.results?.map(vat => ({
                          label: vat.short_name,
                          value: vat.id,
                        })) ?? []),
                      ]
                    : [{ label: 'Seleccionar...', value: '' }]
                }
                className={`text-secondary`}
                placeholder={'Seleccionar...'}
                styles={customSelectNew4}
                onChange={e => {
                  setData(state => ({
                    ...state,
                    data: { ...state.data, vatId: Number(e.value) },
                  }));
                }}
              />
            </Box>
          </Box>
        </OrdModal>

        <div className='w-100 mx-1'>
          {myPermission?.read && (
            <div className='d-flex align-items-end gap-2'>
              <div style={{ width: '160px' }}>
                <p className={tableStyles.crudModalLabel}>Año</p>
                <Select
                  value={
                    data.filters.year
                      ? {
                          label: data.listYears?.find(
                            e => Number(e.value) === Number(data.filters.year),
                          )?.label,
                          value: data?.listYears?.find(
                            e => Number(e.value) === Number(data.filters.year),
                          )?.label,
                        }
                      : ''
                  }
                  options={data.listYears}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew4}
                  onChange={e => {
                    setData(state => ({
                      ...state,
                      trigger: state.trigger + 1,
                      filters: { ...data.filters, year: Number(e.value) },
                    }));

                    getIncomeGroups({
                      url: `/medical/copaymentRate/`,
                      objFilters: { ...data.filters, year: Number(e.value) },
                      token: token,
                    });
                  }}
                />
              </div>

              <div style={{ width: '160px' }}>
                <p className={tableStyles.crudModalLabel}>Estado</p>
                <Select
                  options={[
                    { label: 'Seleccionar...', value: '' },
                    { label: 'Habilitado', value: 1 },
                    { label: 'Inhabilitado', value: 0 },
                  ]}
                  className='text-secondary'
                  placeholder={'Seleccionar...'}
                  styles={customSelectNew4}
                  onChange={({ value }) => {
                    setData(state => ({
                      ...state,
                      filters: { ...data.filters, enabled: value },
                    }));

                    getIncomeGroups({
                      url: `/medical/copaymentRate/`,
                      objFilters: { ...data.filters, enabled: value },
                      token: token,
                    });
                  }}
                />
              </div>

              <div>
                <input
                  className={tableStyles.searchUsersAlt}
                  style={{ height: '32px' }}
                  type='text'
                  onChange={e => {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, name: e.target.value },
                    }));
                  }}
                  onKeyDown={e => handleKeyDown(e)}
                  placeholder='Buscar...'
                />

                <img
                  style={{ width: '1.7rem' }}
                  src={Lupa}
                  alt='User icon'
                  className={`${tableStyles.iconSvgMargin2} pointer`}
                  onClick={e => {
                    handleKeyDown(e, true);
                  }}
                />
              </div>

              {myPermission?.create && (
                <div className={`${tableStyles.colorNewOrder} ms-auto align-items-end p-0`}>
                  <div
                    className='d-flex justify-content-end groupAddButton align-items-center '
                    onClick={() => {
                      setData(state => ({
                        ...state,
                        data: {
                          name: '',
                          value: '',
                          vatId: '',
                          year: new Date().getFullYear(),
                        },
                        modalActive: true,
                        modalCreate: true,
                      }));
                    }}
                  >
                    <label htmlFor='newAccident' className='darkGray fw-bold'>
                      Crear grupo
                    </label>
                    <button className='addCheckButton mx-2' />
                  </div>
                </div>
              )}
            </div>
          )}

          {myPermission?.read && (
            <>{data?.listIncomeGroups?.length ? renderListIncomeGroup() : renderNotData()}</>
          )}
        </div>
      </div>
    </>
  );
};
