// --------------- components 🏴󠁧󠁢󠁥󠁮󠁧   ----------------//
import { useState } from 'react';
import GenericTableScroll from '../Layouts/GenericTableScroll';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

// --------------- icons 💥  ----------------//
import Lupa from '../../assets/img/icons/lupa.svg';

//-------------- scss styles 😄   --------------
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from './Consultations.module.scss';

//-------------- actions 😄   --------------
import { useHistory } from 'react-router-dom';
import { Title } from '../../shared';

function DeferralHistory() {
  const history = useHistory();
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    client: '',
    contract: '',
    status: '',
    sequence: '',
    date_from: '',
    date_until: '',
    resolution: '',
    range_from: '',
    range_until: '',
  });

  const header = [
    <div key={1} className={`col-1`}>
      No. de cuenta
    </div>,
    <div key={2} className={`col-1`}>
      No. de comprobante
    </div>,
    <div key={3} className={`col-2`}>
      Descripción
    </div>,
    <div key={4} className={`col-2`}>
      Centro de costo
    </div>,
    <div key={5} className={`col-2`}>
      Fecha
    </div>,
    <div key={6} className={`col-2`}>
      Debe
    </div>,
    <div key={7} className={`col-2`}>
      Haber
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    return tempList;
  };

  // --------------- Functions 💥  ----------------//
  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className={tableStyles.title}>
          <Title
            title='Historial de diferidos'
            onClickIcon={() => {
              history.push('/contabilidad/inicio');
            }}
          />
        </div>

        <Row className='d-flex'>
          <Col xs={2}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Fecha desde</label>
              <input className={IndividualStyles.registerInputsBlue} type='date'></input>
            </form>
          </Col>

          <Col xs={2}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Hasta</label>
              <input className={IndividualStyles.registerInputsBlue} type='date'></input>
            </form>
          </Col>

          <Col xs={2}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Valor desde</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
                // value={creatEgress.amount}
                // onValueChange={(values) => {
                //     const { value } = values;
                //     setcreatEgress({
                //         ...creatEgress,
                //         amount: value,
                //     })
                //     }}
              ></NumberFormat>
            </form>
          </Col>

          <Col xs={2}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={tableStyles.crudModalLabel}>Valor hasta</label>
              <NumberFormat
                allowNegative={false}
                style={{ padding: 0, color: '#58595B' }}
                className={IndividualStyles.registerInputs}
                name='valueNote'
                thousandsGroupStyle='thousand'
                thousandSeparator={'.'}
                decimalSeparator={','}
                prefix={'$'}
              ></NumberFormat>
            </form>
          </Col>

          <Col xs={3} style={{ alignSelf: 'end' }}>
            <form onSubmit={e => handleSearch(e)}>
              <label className={`${tableStyles.crudModalLabel}`}></label>
              <input className={Styles.inputSearch} placeholder='Buscar...' type='text'></input>
            </form>
          </Col>

          <div className=' col display-grid  mr-2' style={{ alignContent: 'end' }}>
            <label className=''>
              {' '}
              <img alt='' className={`${tableStyles.cursorPointer}`} src={Lupa} />
            </label>
          </div>
        </Row>

        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={'2'}
        ></GenericTableScroll>
      </div>
    </>
  );
}
export default DeferralHistory;
