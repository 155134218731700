import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { onValidatePermissionByTagModule } from '../helpers';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const useHasPermissionByTagModule = ({module, hasRead }) => {
    const counter = useSelector(state => state);
    const history = useHistory();
    useEffect(() => {
      if (
        !onValidatePermissionByTagModule({
          module: module,
          allCategories: counter.loginReducer.all_categories,
        }) && !hasRead
      ) {
        history.push('/categoryselect');
      } else if (!hasRead) {
        history.push('/nomina/inicio');
      }
    }, [counter.loginReducer.all_categories, hasRead, history, module]);
}
