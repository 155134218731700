import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import { Groups } from './TabsIncomeGroups/Groups';
import { EntityRelationship } from './TabsIncomeGroups/EntityRelationship';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export const IncomeGroups = () => {
  //  --------------------------- States 🪔 ------------------------
  const [newActive, setNewActive] = useState(false);

  const [tabActive, setTabActive] = useState('Groups');

  return (
    <>
      <OrdGenericTemplate
        className={`mt-4 mx-4 ${tableStyles.darkBlueText2}`}
        title={'Grupos de ingreso'}
        titleSize={12}
      >
        <div
          style={{ width: '90%', marginTop: '30px' }}
          onClick={() => {
            newActive && setNewActive(false);
          }}
        >
          <Row className={tableStyles.tabHeader}>
            <Col
              xs={2}
              onClick={() => setTabActive('Groups')}
              className={tabActive === 'Groups' ? tableStyles.tabActive : tableStyles.tabNoActive}
            >
              <span className='fw-bold'>Grupos</span>
            </Col>

            <Col
              xs={2}
              onClick={() => setTabActive('EntityRelationship')}
              className={
                tabActive === 'EntityRelationship' ? tableStyles.tabActive : tableStyles.tabNoActive
              }
            >
              <span className='fw-bold'>Relación entidad</span>
            </Col>
            <Col xs={8} style={{ borderBottom: '1px solid #005dbf' }}></Col>
          </Row>

          {tabActive === 'Groups' && <Groups />}

          {tabActive === 'EntityRelationship' && <EntityRelationship />}
        </div>
      </OrdGenericTemplate>
    </>
  );
};
