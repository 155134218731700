import { Accordion, Card, Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import tableStyles from './ordComponentStyles.module.scss';

function OrdTableAccordion(props) {
  return (
    <>
      <div
        className={`    
            ${props?.isAnAccTable ? 'fold-table' : 'table'}
          ${props?.lowerCase ? tableStyles.genericTableLower : tableStyles.genericTable}
          ${props?.oneHeadDarkBlue ? tableStyles.oneHeadDarkBlue : ''}
          ${props?.treeHeadDarkBlue ? tableStyles.treeHeadDarkBlue : ''}
          ${props?.className ? props.className : ''}
          ${props?.shadow ? tableStyles.shade : ''}
          `}
        style={props?.style}
      >
        <div
          className={`${tableStyles.thead} ${
            props.clearHeader ? '' : props.dark ? tableStyles.theadDark : tableStyles.theadLight
          }`}
        >
          {' '}
          <div
            style={{
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: props.headersGridColumn,
              padding: '10px',
            }}
          >
            {props?.headers?.length > 0 &&
              props?.headers.map((e, i) => {
                return (
                  <div className={e.className} key={`header${i}`}>
                    {e.title}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={`${props?.fTdClearBlue ? tableStyles.firstdClearBlue : ''}`}>
          {props?.body?.length > 0 &&
            props?.body.map((e, i) => {
              return (
                <>
                  <Accordion
                    style={{
                      borderBottom: `${props.noHoverAccordion ? '1px solid #CECECE' : '1px solid #00b4cc'}`,
                    }}
                    key={i + 1}
                    className={`${props.noHoverAccordion ? '' : 'hover-table-row'}`}
                  >
                    <Accordion.Toggle eventKey={i + 1} className={`w-100 accordionToggleButton  `}>
                      <Card.Body className='py-0'>
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'grid',
                            gridTemplateColumns: props.headersGridColumn,
                          }}
                        >
                          {e.map((content, i) => {
                            return (
                              <>
                                {i === 0 ? (
                                  <div>
                                    <span className='d-flex justify-content-start align-items-center text-secondary'>
                                      <code
                                        className={`m-3 text-secondary accordionToggleButton`}
                                      >{`>`}</code>
                                      <div style={{ textAlign: 'start' }}>
                                        {content?.text ? content.text : content}
                                      </div>
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    id={'td' + i}
                                    className={`${content?.className} text-secondary` || ''}
                                    key={`content${i}`}
                                    style={content?.style || {}}
                                  >
                                    {content?.text ? content.text : content}
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey={i + 1}>
                      <Card.Body className='py-0 mb-4'>
                        <Row>
                          <Col
                            xs={12}
                            style={{
                              paddingLeft: '3rem',
                            }}
                          >
                            {props.accordionBody(i)}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                </>
              );
            })}
          {props?.hasChildren && props.children}
        </div>
        {!!props.footer && <tfoot>{props.footer}</tfoot>}
      </div>
      {/* /* ------------------------------- PAGINATION ------------------------------- */}

      {props?.paginate && (
        <div className={tableStyles.wrapper}>
          {props?.paginate?.showTextDetails && (
            <>
              <p className={`${tableStyles.paginationText} text-secondary`}>
                Pag. {props?.paginate?.activePage || ''}
                {' de '}
                {Math.ceil(props?.paginate?.totalPages / props?.paginate?.perPage)
                  ? Math.ceil(props?.paginate?.totalPages / props?.paginate?.perPage)
                  : ''}
                {' - '}
                {props?.paginate?.totalPages} encontrados
              </p>
            </>
          )}
          <Pagination
            activePage={props?.paginate?.activePage}
            itemsCountPerPage={props?.paginate?.perPage}
            totalItemsCount={props?.paginate?.totalPages}
            pageRangeDisplayed={props?.paginate?.pageRangeDisplayed || 5}
            onChange={e => props?.paginate?.onChangePage(e)}
            itemClassPrev={tableStyles.itemClassPrev}
            itemClassNext={tableStyles.itemClassNext}
            itemClassFirst={tableStyles.itemClassFirst}
            itemClassLast={tableStyles.itemClassLast}
            itemClass={tableStyles.itemClass}
          />
        </div>
      )}
    </>
  );
}

export default OrdTableAccordion;
