/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { today } from '../../../../helpers/helpers';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod } from '../../../../Hooks/useFetch';
import 'reactjs-popup/dist/index.css';
import backArrow from '../../../../assets/img/icons/nomIconRow.svg';

import tableStyles from '../../../Layouts/tableStyle.module.scss';
import Questions from './Questions';
import { useHasPermissionByTagModule } from "../../../../Hooks";

const SurveyPerf = ({ edit, detailD, data }) => {
  const location = useLocation();
  const store = useSelector(state => state);
  const history = useHistory();
  const isEditing = edit ?? location?.state?.action === 'edit' ? true : false;
  const detail = detailD ?? location?.state?.action === 'detail' ? true : false;
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'evalPerf',
  );
  const surveyData = data ?? location?.state?.data;
  const idUser = store?.loginReducer?.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const { trigger: getSurveyDetailMethod } = useGetMethod();
  const token = store.loginReducer.Authorization;
  const [dataDetail, setDataDetail] = useState({
    title: '',
    startDate: '',
    endDate: '',
    description: '',
    eaccount: idAccount,
    createdBy: idUser,
  });
  const [sections, setSections] = useState([]);

  useHasPermissionByTagModule({module: 'nomina', hasRead: myPermission?.read})

  useEffect(() => {
    if (detail || edit) {
      getSurveyDetailMethod({
        url: '/payroll/performance_evaluation_detail',
        objFilters: {
          id: surveyData.id,
          eaccount: idAccount,
        },
        token: token,
        doAfterSuccess: res => {
          setDataDetail({
            ...dataDetail,
            title: res?.results?.title,
            startDate: res?.results?.startDate,
            endDate: res?.results?.endDate,
            description: res?.results?.description,
            id: res?.results?.id,
            status: res?.results?.status,
          });
          setSections(res?.results?.sections);
        },
      });
    }
  }, [detail, edit]);

  /* ----------------------------- HANDLE CHANGES ----------------------------- */
  const handleBack = () => {
    history.push('/nomina/EvaluacionDeDesempeño');
  };
  return (
    <>
      {/* {surveyDetailLoader && loader} */}
      <div className={`w-100 ${!isEditing && !detail && 'container'}`}>
        {!isEditing && !detail && (
          <Row>
            <Col xs={10}>
              <h1 className={tableStyles.title}>
                <img
                  onClick={() => handleBack()}
                  alt='icon'
                  src={backArrow}
                  className={`mr-2 mb-1 cursorPointer ${tableStyles.svgFilterColorBlue} `}
                  height='30px'
                ></img>
                <span className={`${tableStyles.titleAdministrative}`}>
                  {detail
                    ? 'Detalle de la evaluación'
                    : isEditing
                      ? 'Editar evaluación'
                      : 'Crear evaluación'}
                </span>
              </h1>
            </Col>
          </Row>
        )}
        {/* /* ---------------------------------- ROW 1 --------------------------------- */}
        <div className='d-flex justify-content-between'>
          <div
            className='mt-3 '
            style={{
              width: !isEditing && !detail ? '95%' : '100%',
              paddingLeft: !isEditing && !detail ? '2rem' : '',
            }}
          >
            <Row className='mt-2'>
              <Col xs={2}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha inicio
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  // value={detail ? dataDetail.startDate : inputs.date}
                  defaultValue={dataDetail.startDate}
                  type={detail ? 'text' : 'date'}
                  //   value={inputs.date}
                  readOnly={detail ? true : false}
                  min={today()}
                  onChange={e => setDataDetail({ ...dataDetail, startDate: e.target.value })}
                  className={`register-inputs`}
                  name='position'
                />
              </Col>
              <Col xs={2}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Fecha fin
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  // value={detail ? dataDetail.endDate : inputs.date}
                  type={detail ? 'text' : 'date'}
                  //   value={inputs.date}
                  defaultValue={dataDetail.endDate}
                  readOnly={detail ? true : false}
                  min={today()}
                  onChange={e => setDataDetail({ ...dataDetail, endDate: e.target.value })}
                  className={`register-inputs`}
                  name='position'
                />
              </Col>
              <Col xs={8}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Título evaluación
                  <span className={'text-danger'}>*</span>
                </p>
                <input
                  // value={detail ? dataDetail.title : inputs.title}
                  defaultValue={dataDetail.title}
                  readOnly={detail ? true : false}
                  className={`register-inputs`}
                  name='position'
                  onChange={e => setDataDetail({ ...dataDetail, title: e.target.value })}
                />
              </Col>
            </Row>
            <Row className=''>
              <Col xs={12}>
                <p className={`${tableStyles.crudModalLabel} `}>
                  Descripción de la evaluación
                  <span className={'text-danger'}>*</span>
                </p>
                <textarea
                  readOnly={detail ? true : false}
                  defaultValue={dataDetail.description}
                  // value={detail ? dataDetail.description : inputs.description}
                  // readOnly={detail && conclusion ? false : detail ? true : false}
                  // readOnly={((detail && !conclusion) || surveyData.conclusions)? true : false}
                  onChange={e => setDataDetail({ ...dataDetail, description: e.target.value })}
                  placeholder={'Escribir...'}
                  //  rows="10"
                  //  cols="10"
                  className={`${tableStyles.textareaNom}`}
                  //  style={{ height: "4rem", borderRadius: "5px", border: "1px solid #7FADDE", width: "100%", padding: "4px"  }}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Questions
                  dataSections={sections}
                  dataDetail={dataDetail}
                  handleBack={handleBack}
                  detail={detail}
                  edit={edit}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyPerf;
