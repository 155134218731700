import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Col, Row } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';

import Clientes from '../../assets/img/icons/Clientes.svg';
import topArrow from '../../assets/img/icons/topArrow.svg';

import {
  getActiveProviders,
  getConsum,
  getExpiredSupplies,
  getInventoryRotation,
  getProblemsInEntryWh,
  getTopProviders,
} from '../../actions/managementActions';
import { donutOptions, highChartHorizontalLine, singleLineOptions } from '../../helpers/constants';
import { month, year } from '../../helpers/helpers';

import 'reactjs-popup/dist/index.css';
import { customSelectNew } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import '../TableUsers/tableUserStyle.scss';
import './behave.css';
import { getEnvs } from "../../helpers";

const { APP_LANGUAGE, CURRENCY } = getEnvs();

export const Purchases = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'glbInd',
  );
  const consum = store?.managementReducer?.consum;
  let inventoryRotation = store?.managementReducer?.inventoryRotation;
  let topProviders = store?.managementReducer?.topProviders;
  let entryWhProblems = store?.managementReducer?.entryWhProblems;
  let expiredSupplies = store?.managementReducer?.expiredSuppliesList;

  const token = store.loginReducer.Authorization;
  const [filters, setFilters] = useState({
    month: month(),
    year: year(),
  });
  const [filtersProv, setFiltersProv] = useState({
    month: month(),
    year: year(),
    year_end: year(),
    month_end: month(),
    top: 5,
  });
  const [activeProviders, setActiveProviders] = useState(0);
  const [filtersInv, setFiltersInv] = useState({
    month: month(),
    year: year(),
  });

  let formatter = new Intl.NumberFormat(APP_LANGUAGE, {
    style: 'currency',
    currency: CURRENCY,
  });
  const getProvActives = async () => {
    const result = await getActiveProviders(
      {
        year: filtersProv.year,
        month: filtersProv.month,
        year_end: filtersProv.year_end,
        account: store.loginReducer.currentAccount.id,
        month_end: filtersProv.month_end,
      },
      token,
    );
    if (result?.success) {
      setActiveProviders(result.result[0]?.activos);
    }
  };
  useEffect(() => {
    getProvActives();
    dispatch(
      getTopProviders({
        year: filtersProv.year,
        month: filtersProv.month,
        year_end: filtersProv.year_end,
        account: store.loginReducer.currentAccount.id,
        month_end: filtersProv.month_end,
        top: filtersProv.top,
        sort: filtersProv.sort,
      }),
    );
    dispatch(
      getProblemsInEntryWh({
        year_start: filtersProv.year,
        month_start: filtersProv.month,
        year_end: filtersProv.year_end,
        month_end: filtersProv.month_end,
        account: store.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersProv]);

  useEffect(() => {
    dispatch(
      getInventoryRotation({
        account: store.loginReducer.currentAccount.id,
        year: filtersInv.year,
      }),
    );
    dispatch(
      getExpiredSupplies({
        account: store.loginReducer.currentAccount.id,
        year: filtersInv.year,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersInv]);

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/gerencia/inicio');
    }

    dispatch(
      getConsum({
        month: filters.month,
        year: filters.year,
        account: store.loginReducer.currentAccount.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page]);
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowButton(false);
    }, 15000);
  }, [showButton]);

  const seriesConsum = consum?.results?.series;
  const categoriesConsum = consum?.results?.labels;

  const getFivePreviousYear = () => {
    let array = [];
    let current_year = year();
    for (let step = 0; step <= 5; step++) {
      array.push(current_year - step);
    }
    return array;
  };

  return (
    <>
      {showButton && (
        <a
          width='25px'
          className={`${tableStyles.noTextDecoration} ${tableStyles.backToTop} p-3 center`}
          href='#home'
        >
          <img className={`center`} src={topArrow} alt=''></img>
        </a>
      )}

      <div
        className={`${tableStyles.container}`}
        style={{ overflowX: 'hidden' }}
        onScroll={() => setShowButton(true)}
      >
        <div className={tableStyles.tableArea} style={{ scrollBehavior: 'smooth' }}>
          <Row>
            <Col xs={8} className={``} id='home'>
              <h1 className={tableStyles.title}>Indicadores de compras</h1>
            </Col>
            <Col
              xs={4}
              className={`text-end d-flex justify-content-between`}
              style={{ paddingTop: '5rem' }}
            >
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Consum'>
                  <b>Consumo</b>
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Providers'>
                  <b>Proveedores</b>{' '}
                </a>
              </h6>
              <h6 className={`${tableStyles.darkBlueText} `}>
                <a className={`${tableStyles.noTextDecoration}`} href='#Inventory'>
                  <b>Inventario</b>{' '}
                </a>
              </h6>
            </Col>
          </Row>
          <hr></hr>
          <Row className='justify-content-between mb-2' id='Consum'>
            <Col xs={3} className=' text-start'>
              <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                <b>Consumo</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filters.month}
                onChange={e => setFilters({ ...filters, month: Number(e.target.value) })}
                placeholder={'Mes'}
                className={tableStyles.simpleDropDown}
              >
                <option value={1}>Enero</option>
                <option value={2}>Febrero</option>
                <option value={3}>Marzo</option>
                <option value={4}>Abril</option>
                <option value={5}>Mayo</option>
                <option value={6}>Junio</option>
                <option value={7}>Julio</option>
                <option value={8}>Agosto</option>
                <option value={9}>Septiembre</option>
                <option value={10}>Octubre</option>
                <option value={11}>Noviembre</option>
                <option value={12}>Diciembre</option>
              </select>

              <select
                value={filters.year}
                onChange={e => setFilters({ ...filters, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map((e, i) => {
                  return (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer}`}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Presupuesto del año</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {formatter.format(consum.total_annual_budget)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between cursorPointer`}>
                  <div title='Consumo acumulado hasta el mes'>
                    <p className={`text-secondary ${tableStyles.ellipsis}`}>
                      Consumo acumulado hasta el mes &nbsp;
                    </p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {formatter.format(consum.total_current_balance)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between`}>
                  <div>
                    <p className='text-secondary'>Consumo del mes</p>
                    <p className={tableStyles.cardBoxBottomText}>
                      {formatter.format(consum.total_total_spent)}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`d-flex text-start justify-content-between cursorPointer`}>
                  <div title='Porcentaje de costo acumulado'>
                    <p className={`text-secondary ${tableStyles.ellipsis}`}>
                      Porcentaje de costo acumulado&nbsp;
                    </p>
                    <p className={tableStyles.cardBoxBottomText}>{consum.total_percentage}%</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={5}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={`text-start `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Salidas por centro de consumo</b>
                    </p>

                    {categoriesConsum?.length > 0 && seriesConsum[1].data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels: categoriesConsum,
                        })}
                        series={seriesConsum?.length > 0 ? seriesConsum[1].data : []}
                        type='donut'
                        height={435}
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={7} className={tableStyles.scrollThis} style={{ height: '80vh' }}>
              <div className={`${tableStyles.cardBoxContainer} `}>
                <div className={` `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Consumo de presupuesto</b>
                    </p>
                    {seriesConsum?.length <= 0 ? (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartHorizontalLine({
                          series: [
                            {
                              name: 'Cargando...',
                              data: [
                                3, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 90, 80, 70, 60, 50, 40,
                                30, 20, 10, 3,
                              ],
                            },
                          ],
                          categories: [
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                            'Cargando...',
                          ],
                          colors: ['gray'],
                        })}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartHorizontalLine({
                          series: seriesConsum,
                          categories: categoriesConsum,
                        })}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* /* ---------------------------- PROVIDERS SECTION --------------------------- */}
          <hr></hr>
          <Row className='mb-4' id='Providers'>
            <Row className='justify-content-between mb-2'>
              <Col xs={3} className=' text-start'>
                <h3 className={`${tableStyles.darkBlueText} ml-2`}>
                  <b>Proveedores</b>
                </h3>
              </Col>
              <Col xs={6} className=' text-end mb-2 d-flex justify-content-end'>
                <p className={`${tableStyles.darkGrayText} pt-3`}>
                  <b>DE &nbsp;</b>
                </p>
                <select
                  value={filtersProv.month}
                  onChange={e =>
                    setFiltersProv({
                      ...filtersProv,
                      month: Number(e.target.value),
                    })
                  }
                  style={{ width: '23%' }}
                  placeholder={'Mes'}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>

                <select
                  value={filtersProv.year}
                  onChange={e =>
                    setFiltersProv({
                      ...filtersProv,
                      year: Number(e.target.value),
                    })
                  }
                  placeholder={'Año'}
                  className={`${tableStyles.simpleDropDown}`}
                >
                  {getFivePreviousYear().map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>

                <p className={`${tableStyles.darkGrayText} pl-5 pt-3 ml-5`}>
                  <b>HASTA &nbsp;</b>
                </p>

                <select
                  value={filtersProv.year_end}
                  onChange={e =>
                    setFiltersProv({
                      ...filtersProv,
                      year_end: Number(e.target.value),
                    })
                  }
                  placeholder={'Año'}
                  className={tableStyles.simpleDropDown}
                >
                  {getFivePreviousYear().map((e, i) => {
                    return (
                      <option key={i} value={e}>
                        {e}
                      </option>
                    );
                  })}
                </select>
                <select
                  value={filtersProv.month_end}
                  onChange={e =>
                    setFiltersProv({
                      ...filtersProv,
                      month_end: Number(e.target.value),
                    })
                  }
                  placeholder={'Mes'}
                  style={{ width: '20%' }}
                  className={tableStyles.simpleDropDown}
                >
                  <option value={1}>Enero</option>
                  <option value={2}>Febrero</option>
                  <option value={3}>Marzo</option>
                  <option value={4}>Abril</option>
                  <option value={5}>Mayo</option>
                  <option value={6}>Junio</option>
                  <option value={7}>Julio</option>
                  <option value={8}>Agosto</option>
                  <option value={9}>Septiembre</option>
                  <option value={10}>Octubre</option>
                  <option value={11}>Noviembre</option>
                  <option value={12}>Diciembre</option>
                </select>
              </Col>
            </Row>
            {/* /* ---------------------------- Provider Ranking ---------------------------- */}
            <Col xs={7}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2 pt-0`}>
                <Row className={`pb-2 rounded ${tableStyles.bgColorClearBlue}`}>
                  <Col xs={5}>
                    <label className={tableStyles.crudModalLabel}>Ordenar por</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      onChange={e => setFiltersProv({ ...filtersProv, sort: e.value })}
                      options={[
                        { value: 'desc', label: 'Mayor a menor calificación' },
                        { value: 'asc', label: 'Menor a mayor calificación' },
                      ]}
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                  <Col xs={4}>
                    <label className={tableStyles.crudModalLabel}>Número de proveedores</label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccione'}
                      onChange={e => setFiltersProv({ ...filtersProv, top: e.value })}
                      options={[
                        { value: 5, label: 'Top 5' },
                        { value: 10, label: 'Top 10' },
                        { value: 15, label: 'Top 15' },
                        { value: 25, label: 'Top 25' },
                        { value: 50, label: 'Top 50' },
                      ]}
                      styles={customSelectNew}
                    ></Select>
                  </Col>
                </Row>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Ranking de proveedores</b>
                    </p>
                    <div className={`${tableStyles.scrollThis}`} style={{ height: '56vh' }}>
                      {topProviders?.result?.length <= 0 ? (
                        <>
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={20}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={40}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={60}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={80}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={100}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={80}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={60}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={40}
                          />
                          <ProgressBar
                            title={`Cargando`}
                            style={{
                              backgroundColor: 'rgba(0, 93, 191, 0.15)',
                            }}
                            variant='secondary'
                            className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                            now={20}
                          />
                        </>
                      ) : (
                        topProviders?.result?.map(e => {
                          return (
                            <>
                              <div className={`d-flex justify-content-between`}>
                                <p className={`${tableStyles.darkGrayText}`}>{e.name}</p>
                                <p className={`${tableStyles.darkGrayText}`}>{e.puntos + '%'}</p>
                              </div>
                              <ProgressBar
                                title={`${e?.name} Participación: ${e?.puntos} Compras realizadas:
                              ${e?.comps} Problemas entrada: ${e?.problems}`}
                                style={{
                                  backgroundColor: 'rgba(0, 93, 191, 0.15)',
                                }}
                                className={`mb-2 rounded cursorPointer ${tableStyles.customTitle}`}
                                now={e.puntos}
                              />{' '}
                            </>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={5}>
              <div
                className={`${tableStyles.cardBoxContainer} p-2 ml-2 d-flex`}
                style={{ marginLeft: '0.5rem' }}
              >
                <Col xs={2}>
                  <img
                    style={{ marginLeft: '1rem', marginTop: '1rem' }}
                    src={Clientes}
                    alt=''
                  ></img>
                </Col>

                <Col xs={10}>
                  <div className={` text-start`}>
                    <div>
                      <p className={`${tableStyles.darkGrayText}`}>
                        <span className={'text-secondary'}>Cantidad de proveedores activos</span>
                        <br></br>
                        <b>{activeProviders}</b>
                      </p>
                    </div>
                  </div>
                </Col>
              </div>

              <div
                className={`${tableStyles.cardBoxContainer} mr-2 m-2`}
                style={{
                  height: '61.5vh',
                }}
              >
                <div className={` text-start`}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Problemas en entradas por almacén</b>
                    </p>

                    {entryWhProblems?.results?.labels?.length > 0 &&
                    entryWhProblems?.results?.data?.length > 0 ? (
                      <Chart
                        options={donutOptions({
                          labels: entryWhProblems?.results?.labels
                            ? entryWhProblems?.results?.labels
                            : [],
                        })}
                        series={
                          entryWhProblems?.results?.data ? entryWhProblems?.results?.data : []
                        }
                        type='donut'
                      />
                    ) : (
                      <Chart
                        options={donutOptions({
                          labels: ['Cargando...'],
                          colors: ['gray'],
                        })}
                        series={[50]}
                        type='donut'
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>{' '}
          <hr></hr>
          {/* /* ---------------------------- INVENTORY SECTION --------------------------- */}
          <Row className='justify-content-between mb-2' id='Consum'>
            <Col xs={3} className=' text-start'>
              <h3 className={tableStyles.darkBlueText} id='Inventory'>
                <b>Inventario</b>
              </h3>
            </Col>
            <Col xs={3} className=' text-end mb-2'>
              <select
                value={filtersInv.year}
                onChange={e => setFiltersInv({ ...filtersInv, year: Number(e.target.value) })}
                placeholder={'Año'}
                className={tableStyles.simpleDropDown}
              >
                {getFivePreviousYear().map((e, i) => {
                  return (
                    <option key={i} value={e}>
                      {e}
                    </option>
                  );
                })}
              </select>
            </Col>
          </Row>
          <Row className='mb-4'>
            {/* /* ---------------------------- Inventory rotation ---------------------------- */}
            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Rotación de inventario</b>
                    </p>
                    <Chart
                      options={singleLineOptions({
                        leftTitle: ' Días',
                        bottomTitle: 'Meses',
                        categories:
                          inventoryRotation?.labels?.length > 0 ? inventoryRotation?.labels : [],
                      })}
                      series={
                        inventoryRotation?.series?.length > 0 ? inventoryRotation?.series : []
                      }
                      type='line'
                      height={450}
                    />
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12}>
              <div className={`${tableStyles.cardBoxContainer} mr-2 m-2`}>
                <div className={`text-start justify-content-between `}>
                  <div>
                    <p className={`${tableStyles.darkGrayText}`}>
                      <b>Insumos vencidos</b>
                    </p>
                    <Chart
                      options={singleLineOptions({
                        colors: ['#01A0F6'],
                        leftTitle: 'Porcentaje (%)',
                        bottomTitle: 'Meses',
                        categories:
                          !!expiredSupplies?.labels?.length > 0 ? expiredSupplies.labels : [],
                      })}
                      series={!!expiredSupplies?.series?.length > 0 ? expiredSupplies.series : []}
                      type='line'
                      height={450}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
