import { capitalizeWords } from '../helpers';
import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';
import { message } from '../helpers/helpers';
import {
  GET_ANNOUNCEMENT,
  GET_LIST_CONTRACT,
  GET_PERIODS,
  GET_SELECTS_CONTRACT_CREATE,
  GET_SELECT_STATUS,
  GET_TEMP_WORK_CERT,
  GET_TEMP_WORK_CERT_NOM,
  GET_VACANCY_TYPE_LIST,
  LOADING,
  POST_OTROS_SI,
} from './actionTypes';

export const getListContract =
  (objFilters, doafter = null) =>
  (dispatch, getCreate) => {
    let filters = convertFilterToString(objFilters);
    const token = getCreate().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/?${filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (doafter) {
          doafter();
        }
        const { results, row_total } = res;
        dispatch({
          type: GET_LIST_CONTRACT,
          payload: results,
          total: row_total,
        });
      })
      .catch(err => {
        if (doafter) {
          doafter();
        }
        console.error(err.message);
      });
  };

export const getSelectStatus = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/status/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_SELECT_STATUS,
        payload: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getAccounts = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  return fetch(`${URL_GATEWAY}${API_VERSION}/admin/accounts/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      return res.results
    })
    .catch(err => {
      console.error(err.message);
    });
};

export const getSelectsConvCont = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contractType/lists/1?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { jobPositions, contractTypes, candidates } = res;
      dispatch({
        type: GET_SELECTS_CONTRACT_CREATE,
        job_positions: jobPositions,
        contractTypes: contractTypes,
        candidates: candidates,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const getSelectsDirCont = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contractType/lists/2?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { jobPositions, contractTypes, candidates } = res;
      dispatch({
        type: GET_SELECTS_CONTRACT_CREATE,
        job_positions: jobPositions,
        contractTypes: contractTypes,
        candidates: candidates,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const getVacancyType = () => (dispatch, getCreate) => {
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/lists/vacancyType`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_VACANCY_TYPE_LIST,
        vacancyType: res,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const getAnnouncement = objFilters => (dispatch, getCreate) => {
  let filters = convertFilterToString(objFilters);
  const token = getCreate().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/lists/announcement?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results } = res;
      dispatch({
        type: GET_ANNOUNCEMENT,
        announcement: results,
      });
    })
    .catch(err => {
      console.error(err.message);
    });
};
export const create_Contract = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        let linkSource = res?.contract && res?.contract;
        let downloadLink = document.createElement('a');
        let fileName = 'contrato.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        downloadLink.click();
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado el contrato al empleado: ${capitalizeWords(data?.candidateName)}`,
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
        });

        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: LOADING,
        payload: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: LOADING,
        payload: false,
      });

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Error al crear',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};
export const create_Candidate = (data, executeThisAfter) => (dispatch, getState) => {
  if (
    Object.keys(data.document[0]).includes('filename') &&
    Object.keys(data.document[0]).includes('base64') &&
    Object.keys(data.document[0]).includes('support_type') &&
    Object.keys(data.document[0]).includes('fileUrl')
  ) {
    if (
      data.document[0].fileUrl === '' ||
      data.document[0].filename === '' ||
      data.document[0].base64 === ''
    ) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'La imagen del candidato es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  } else {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La imagen del candidato es obligatorio',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }

  if (
    Object.keys(data.document[1]).includes('filename') &&
    Object.keys(data.document[1]).includes('base64') &&
    Object.keys(data.document[0]).includes('support_type') &&
    Object.keys(data.document[0]).includes('fileUrl')
  ) {
    if (
      data.document[1].filename === '' ||
      data.document[1].base64 === '' ||
      data.document[1].fileUrl === ''
    ) {
      return customSwaltAlert({
        icon: 'error',
        title: 'Error',
        text: 'La hoja de vida del candidato es obligatorio',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  } else {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'La hoja de vida del candidato es obligatorio',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/create-candidate/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado el candidato: ${data.full_name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        if (executeThisAfter) {
          executeThisAfter(res.results);
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: LOADING,
        payload: false,
      });
    })
    .catch(err => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      console.error(err);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Error al crear',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};
export const add_annex_contract = (contract_id, data, executeThisAfter) => (dispatch, getState) => {
  dispatch({
    type: POST_OTROS_SI,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contract_annex/${contract_id}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Anexado exitosamente',
          text: 'El documento ha sido anexado exitosamente',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: POST_OTROS_SI,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: POST_OTROS_SI,
        loading: false,
      });
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `Error de respuesta`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};
export const signContract = (data, id, executeThisAfter, candidate) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contract/signature/` + id, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha firmado el contrato al empleado: ${candidate}`,
          confirmButtonText: `<a class="text-decoration-none" target="_blank" style=color:#fff href=${res.contract}>Aceptar</a>`,
          showCancelButton: false,
        });
        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({
        type: LOADING,
        payload: false,
      });
    })
    .catch(err => {
      dispatch({
        type: LOADING,
        payload: false,
      });
      console.error(err);
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Error al firmar el contrato',
        footer: 'Si el error persiste comuníquese con un asesor.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

export const create_workCertificate = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    payload: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/export/payroll/work_certificate/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Generada exitosamente',
          text: `Se ha creado una carta laboral al empleado ${data?.nameCandidate ? data?.nameCandidate : ''}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        if (executeThisAfter) {
          executeThisAfter();
        }
      } else {
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).then(
          dispatch({
            type: LOADING,
            payload: false,
          }),
        );
      }
      dispatch({
        type: LOADING,
        payload: false,
      });
      dispatch({
        type: data.NOM ? GET_TEMP_WORK_CERT_NOM : GET_TEMP_WORK_CERT,
        payload: res.pdf,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: LOADING,
        payload: false,
      });
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Error al crear',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    });
};

//TODO: UPDATE CONTRACT STATE
export const updateContractState = async (data, token) => {
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/contracts/change-status/${data.action}/${data.id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message('error', 'Error', result.message, undefined, true);
    }
  } catch (error) {
    console.error(error);
    return message(
      'warning',
      'Intenta de nuevo',
      'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
      undefined,
      true,
    );
  }
};

export const getPeriods = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/settlement-cycles-reasons/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_PERIODS,
        payload: res,
      });
    })
    .catch(error => {
      return console.error(error.message);
    });
};

export const settlement = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/payroll-settlement/`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error inespereado', undefined, true);
  }
};
export const finishInterships = async (data, token) => {
  //Terminar acá también
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contract/end_contract/`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error inespereado', undefined, true);
  }
};

export const sendToSettlement = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/send_treasury_settlement/`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message(
      'warning',
      'Intenta de nuevo',
      'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
      undefined,
      true,
    );
  }
};

export const getDetailSettlement = async (objFilters, token) => {
  const filters = convertFilterToString(objFilters);
  try {
    const query = await fetch(
      `${URL_GATEWAY}${API_VERSION}/payroll/payroll-settlement/?${filters}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      },
    );
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message(
      'warning',
      'Intenta de nuevo',
      'Ha ocurrido un error inespereado',
      undefined,
      true,
    );
  }
};

export const updateContractById = async (data, id, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    //console.log(error);
    return message(
      'warning',
      'Intenta de nuevo',
      'Ha ocurrido un error y no se pudo actualizar el estado del contrato',
      undefined,
      true,
    );
  }
};
