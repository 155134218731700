import { useDispatch, useSelector } from "react-redux"
import { onSetActiveEventPatient } from "../actions/onSetActiveEventPatient"
import { onSetEventsPatient } from "../actions/onSetEventsPatient"
import { onSetImagePatient } from "../actions/onSetImagePatient"
import { onSetClearAdmissionPatient } from "../actions/onSetClearAdmissionPatient"

const usePatientAdmissionStore = () => {

    const { events, activeEvent, image } = useSelector(state => state.patientAdmissionReducer)

    const dispatch = useDispatch()

    const setEvents = (event) => {
        dispatch(onSetEventsPatient(event))
    }

    const setActiveEvent = (event) => {
        dispatch(onSetActiveEventPatient(event))
    }
    
    const clearStateAdmissionPatient = () => {
        dispatch(onSetClearAdmissionPatient())
    }

    const setImage = (event) => {
        dispatch(onSetImagePatient(event))
    }


    return {
        events,
        activeEvent,
        setEvents,
        setActiveEvent,
        setImage,
        image,
        clearStateAdmissionPatient
    }
}

export default usePatientAdmissionStore