import { Tab, Tabs } from "react-bootstrap";
import "./DailyConsignment.scss";
import Consignment from "./Tabs/Consignment";
import Checks from "./Tabs/Check";

export default function DailyConsignment() {

    const render = () => {
        return (
            <>
                <div className="container">
                    <h1 className="mb-4">Consignación diaria</h1>

                    <Tabs  defaultActiveKey="consignments">
                        <Tab eventKey="consignments" tabClassName="custom-tab" title="Consignación">
                            <Consignment />
                        </Tab>
                        <Tab eventKey="checks" tabClassName="custom-tab" title="Cheques">
                            <Checks />
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    };

    return render();
}