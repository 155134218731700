import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_CIETEN, SET_INDEX_CIETEN } from './actionTypes';

const MySwal = withReactContent(Swal);

export const getCieten = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  objFilters.page = objFilters.page === '0' ? 1 : objFilters.page;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/cieten?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { data } = res;
      dispatch({
        type: SET_INDEX_CIETEN,
        payload: res.count,
      });
      dispatch({
        type: GET_CIETEN,
        payload: data,
      });
    })
    .catch(err => {
      console.error(err, 'ERROR');
      MySwal.fire({
        icon: 'error',
        title: 'Error al traer los datos de CIE-10',
        text: 'Ocurrió un error al traer los datos de impresión diagnóstica, si el problema persiste comuniquese con un administrador',
      });
    });
};

export const changeStatus = (objSite, filters) => (dispatch, getState) => {
  const updated_at = Date.now();
  objSite['updated_at'] = updated_at;
  const token = getState().loginReducer.Authorization;
  let status = objSite.active ? 'deshabilitar' : 'habilitar';
  objSite.active = !objSite.active;
  objSite.active = objSite.active !== false ? 1 : 0;

  Swal.fire({
    title: `¿Está seguro de ${status} la impresión ${objSite.id}?`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#003f80',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si estoy seguro',
    cancelButtonText: 'Cancelar',
  }).then(result => {
    if (result.isConfirmed) {
      fetch(
        `${URL_GATEWAY}${API_VERSION}/admin/cieten?enabled=${objSite.active}&codigo=${objSite.id}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        },
      )
        .then(response => response.json())
        .then(res => {
          if (res.status || !res.message) {
            MySwal.fire({
              icon: 'success',
              title: '¡CIE-10 actualizado!',
              text: 'Se cambió el estado correctamente',
            });
            dispatch(getCieten(filters));
          } else {
            console.error(res.message);
          }
        })
        .catch(err => {
          console.error(err);
          MySwal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'No se pudo cambiar el estado',
            footer: 'Si el problema persiste comuníquese con un asesor.',
          });
        });
    }
  });
};
