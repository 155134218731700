import { getEnvs } from "./getEnvs";

const APP_LANGUAGE = getEnvs().APP_LANGUAGE ?? 'es-CO'
const CURRENCY = getEnvs().CURRENCY ?? 'COP'

export const formatNumberWithoutDecimals = (numberToFormat = 0) => {
    return new Intl.NumberFormat(
        APP_LANGUAGE, 
        { maximumFractionDigits: 0, style: 'currency', currency: CURRENCY}
    ).format(numberToFormat)
}

export const unFormatNumber = (formattedNumber = '') => {
    const { groupSeparator, decimalSeparator } = getLocaleSeparators()

    const normalizedNumber = formattedNumber
        .replace(new RegExp('\\' + groupSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator, 'g'), '.')

    return parseFloat(normalizedNumber)
}

export const getLocaleSeparators = (locale = APP_LANGUAGE) => {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const groupSeparator = parts.find(part => part.type === 'group').value;
    const decimalSeparator = parts.find(part => part.type === 'decimal').value;

    return { groupSeparator, decimalSeparator };
}