import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Button } from 'react-bootstrap';
import Atras from '../../assets/img/icons/atras-icon.svg';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import { useHistory } from 'react-router-dom';

function MoviTransferFunds() {
  let history = useHistory();
  function handleClick() {
    history.push('/tesoreria/ConfiMovimiento');
  }

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex'>
          <img
            alt=''
            className={`${tableStyles.title} mr-3`}
            style={{ cursor: 'pointer' }}
            src={Atras}
            onClick={handleClick}
          />
          <h1 className={tableStyles.title}>Traslado de fondos</h1>
        </div>

        <div className='div'>
          <div className='col-3 display-grid mr-3'>
            <label className={tableStyles.crudModalLabel}>Tipo de movimiento</label>
            <select className={IndividualStyles.selectRegister}></select>
          </div>
          <div className='col-3 display-grid mr-3'>
            <label className={tableStyles.crudModalLabel}>Último cambio</label>
            <input className={IndividualStyles.registerInputs} type='date' />
          </div>
          <div className='col-3 display-grid'>
            <label className={tableStyles.crudModalLabel}>Responsable</label>
            <input className={IndividualStyles.registerInputs} type='text' />
          </div>
        </div>
        <div className='bg-card'>
          <div className='div'>
            <div className='col-9 display-grid mr-3'>
              <label className={tableStyles.crudModalLabel}>Tipo de comprobante</label>
              <select className={IndividualStyles.selectRegister}></select>
            </div>
          </div>
        </div>

        <div className='d-block text-end p-5'>
          <Button className='mr-3'>Cancelar</Button>
          <Button>Guardar</Button>
        </div>
      </div>
    </>
  );
}
export default MoviTransferFunds;
