import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { usePostMethod } from '../../Hooks/useFetch';
import { getBalanceSum, getBalanceTotal, getClient } from '../../actions/receiptOfInvoicesActions';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import Excel from '../../assets/img/icons/excel.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTableNew from '../../components/Layouts/GenericTableNew';
import { convertMoneyFormat, customSwaltAlert, genericDownloadExcel, loader } from '../../helpers';
import CompanyBalance from './CompanyBalance';

import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';

function BalanceInquiry() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    show: false,
    client_id: '',
  });
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: storage.loginReducer.currentAccount.id,
    client: '',
    consolidated: false,
    reportType: true,
  });
  const { trigger: postDocExcel } = usePostMethod();

  useEffect(() => {
    const permission = storage.loginReducer.currentAccount?.profile?.permission?.find(
      x => x.functionality?.prefix === 'balanceInq',
    );
    if (!permission?.read) {
      history.push('/cartera/inicio');
    }
  }, [history, storage.loginReducer.currentAccount?.profile?.permission]);

  useEffect(() => {
    dispatch(getBalanceSum(filters));
    dispatch(
      getBalanceTotal({
        consolidated: true,
        eaccount: storage.loginReducer.currentAccount.id,
        client: filters.client,
      }),
    );
  }, [dispatch, storage.loginReducer.currentAccount.id, filters]);

  useEffect(() => {
    dispatch(getClient({ eaccount: storage.loginReducer.currentAccount.id }));
  }, [dispatch, storage.loginReducer.currentAccount.id]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  let optionsClient = [{ key: 'default', value: '', label: 'Seleccionar...', id: '' }];
  if (Array.isArray(storage.invoiceReducer.clients)) {
    storage.invoiceReducer.clients.forEach(item => {
      optionsClient.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  const header = [
    <th key={1} className='text-start px-2'>
      NIT
    </th>,
    <th key={2} className='text-start px-2'>
      Empresa
    </th>,
    <th key={3} className='text-end px-2'>
      Valor vencido
    </th>,
    <th key={4} className='text-end px-2'>
      Valor por vencer
    </th>,
    <th key={5} className='text-end px-2'>
      Valor de anticipo
    </th>,
    <th key={6} className='text-end px-2'>
      Saldo cartera
    </th>,
    <th key={7} className='text-end px-2'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.invoiceReducer.balance_sum)) {
      table = storage.invoiceReducer.balance_sum.map((x, index) => {
        return (
          <tr key={'renderList' + index}>
            <td className='text-start px-2'>{x.nit}</td>
            <td className='text-start px-2'>{x.client}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x.expired)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x.not_due)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x.anticipated)}</td>
            <td className='text-end px-2'>{convertMoneyFormat(x.pending)}</td>
            <td className='text-end ps-2 pe-3' width={30}>
              <img
                className='cursorPointer'
                src={Filtrar}
                onClick={() => setDetails({ show: true, client_id: x.id })}
                alt='Filtro'
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };
  const footer = [
    <tr key={1}>
      <th className='py-2 text-center'></th>
      <th className='py-2 text-muted text-start px-2'>TOTALES</th>
      <th className='py-2 text-muted text-end px-2'>
        {storage.invoiceReducer.balance_total?.expired
          ? '$' + numberWithCommas(storage.invoiceReducer.balance_total.expired)
          : ''}
      </th>
      <th className='py-2 text-muted text-end px-2'>
        {storage.invoiceReducer.balance_total?.not_due
          ? '$' + numberWithCommas(storage.invoiceReducer.balance_total?.not_due)
          : ''}
      </th>
      <th className='py-2 text-muted text-end px-2'>
        {storage.invoiceReducer.balance_total?.anticipated
          ? '$' + numberWithCommas(storage.invoiceReducer.balance_total?.anticipated)
          : ''}
      </th>
      <th className='py-2 text-muted text-end px-2'>
        {storage.invoiceReducer.balance_total?.sum_pending
          ? '$' + numberWithCommas(storage.invoiceReducer.balance_total?.sum_pending)
          : ''}
      </th>
      <th className='py-2 text-muted text-end'></th>
    </tr>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    const { company } = e.target;

    setFilters({
      ...filters,
      client: company.value,
      page: 1,
    });
  };

  const downloadExcelDoc = consultType => {
    postDocExcel({
      url: '/receivable/balance/excel/',
      method: 'POST',
      body: {
        eaccount: storage.loginReducer.currentAccount.id,
        consultType: consultType,
      },
      token: storage.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const principalRender = (
    <div className='container-fluid px-5'>
      {storage.invoiceReducer.balance_sum_loading && loader}

      <div className='d-flex align-items-center mt-5 mb-3'>
        <h1 className='m-0'>Consulta de saldos</h1>
      </div>

      <div className='d-flex gap-3 align-items-end'>
        <form onSubmit={e => handleSearch(e)} className='d-flex align-items-end w-50'>
          <div className='w-100 me-3'>
            <small className='text-primary ms-1'>Empresa</small>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              placeholder='Seleccionar...'
              name='company'
              options={optionsClient}
              className='text-secondary cursorPointer'
              styles={customSelectNewDark}
            />
          </div>

          <button type='submit' className='pointer'>
            <img src={Lupa} alt='Lupa' />
          </button>
        </form>

        <div className='flex-grow-1'></div>

        <CustomPopupExtend
          noHover
          position='bottom'
          triggerSrc={Excel}
          extraButtons={[
            {
              text: 'Informe general',
              class: CustomPopupStyles.popUpGeneric,
              event: () => {
                downloadExcelDoc('balance_inquiry_general');
              },
            },
            {
              text: 'Informe detallado',
              class: CustomPopupStyles.popUpGeneric,
              event: () => {
                downloadExcelDoc('detailed_balance_inquiry');
              },
            },
          ]}
        />
      </div>

      <GenericTableNew headers={header} footer={footer} dark={true}>
        {renderList()}
      </GenericTableNew>

      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.invoiceReducer.balance_sum ? filters.page : ''}
            {' de '}
            {Math.ceil(storage.invoiceReducer?.balance_sum_total / filters.perpage)
              ? Math.ceil(storage.invoiceReducer.balance_sum_total / filters.perpage)
              : '1'}{' '}
            ({storage.invoiceReducer.balance_sum_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.invoiceReducer.balance_sum_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );

  return details.show ? (
    <CompanyBalance client_id={details.client_id} show={details.show} setDetails={setDetails} />
  ) : (
    principalRender
  );
}
export default BalanceInquiry;
