import { useState, useEffect } from 'react';
import Lupa from '../../assets/img/icons/lupa.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Select from 'react-select';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import { useDispatch, useSelector } from 'react-redux';
import Filtrar from '../../assets/img/icons/Filtrar.svg';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import Pagination from 'react-js-pagination';
import {
  getListCompanies,
  getStatusForHistory,
  getGlossHistory,
} from '../../actions/billingActions';
import DetGloss from './GlossHistoryDetail';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { Title } from '../../shared';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function GlossHistory() {
  const history = useHistory();
  const storage = useSelector(state => state);
  const dispatch = useDispatch();
  const [trigger, setTrigger] = useState(1);
  const [details, setDetails] = useState({
    show: false,
  });

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    client: '',
    eaccount: storage.loginReducer.currentAccount.id,
    status: '',
    invoiceSeq: '',
    invoiceDate: '',
    filingDate: '',
  });

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  useEffect(() => {
    dispatch(getListCompanies({ eaccount: storage.loginReducer.currentAccount.id }));
    dispatch(
      getStatusForHistory({ eaccount: storage.loginReducer.currentAccount.id, type: 'objection' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getGlossHistory(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, filters.page]);

  let optionsCompanies = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.companies)) {
    storage.billingReducer.companies.forEach(item => {
      optionsCompanies.push({
        value: item.id,
        label: item.copyName,
        key: item.id + 'clients',
      });
    });
  }

  let optionsStatus = [{ key: 'default', value: '', label: 'Seleccionar...' }];
  if (Array.isArray(storage.billingReducer.status_in_history)) {
    storage.billingReducer.status_in_history.forEach(item => {
      optionsStatus.push({
        value: item.name,
        label: item.value,
        key: item.id + 'status',
      });
    });
  }

  const header3 = [
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      F. radicación
    </th>,
    <th key={3} className='text-center'>
      Fecha glosa
    </th>,
    <th key={4} className='text-center'>
      NIT
    </th>,
    <th key={5} className='text-center'>
      Empresa
    </th>,
    <th key={6} className='text-center'>
      Estado
    </th>,
    <th key={7} className='text-center'>
      Valor glosa
    </th>,
    <th key={8} className='text-center'></th>,
  ];
  const renderList = () => {
    let table = [];

    if (Array.isArray(storage.billingReducer.gloss_history)) {
      table = storage.billingReducer.gloss_history.map((x, index) => {
        return (
          <tr key={'renderList' + index} className='hover-table-row'>
            <td className='text-uppercase'>{x.invoice_nbr}</td>
            <td>
              {x?.invoiceFilingDate
                ? x.invoiceFilingDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
                : '-'}
            </td>
            <td>{x?.date ? x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1') : '-'}</td>
            <td>{x?.clientNit ? x.clientNit : '-'}</td>
            <td>{x?.clientName ? x.clientName : '-'}</td>
            <td className='text-center'>
              <div className='rounded-pill p-1' style={{ backgroundColor: `${x.background}` }}>
                <b style={{ color: `${x.fontcolor}` }}>{x.status}</b>
              </div>
            </td>
            <td>{x?.total_amount ? '$' + numberWithCommas(Number(x.total_amount)) : '-'}</td>
            <td className='text-center cursorPointer'>
              <img
                alt=''
                className='text-start'
                src={Filtrar}
                onClick={() => setDetails({ show: true, selectedGloss: x.id })}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  const principalRender = (
    <div
      className={` ml-5 ${tableStyles.container}`}
      style={{ marginRight: '3rem', marginTop: '30px' }}
    >
      {storage.billingReducer.loading_gloss_history && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}

      <Title
        title='Historial de glosas'
        onClickIcon={() => {
          history.push('/facturacion/inicio');
        }}
      />

      <div>
        <div className='div gap-3 align-items-end'>
          <div className={`align-items-end ${tableStyles.containerSelect}`}>
            <form onSubmit={e => handleSearch(e)}>
              &nbsp;<label className={tableStyles.stylesLabel}>Empresa</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionsCompanies}
                placeholder={'Seleccionar...'}
                onChange={e =>
                  setFilters({
                    ...filters,
                    client: e.value,
                  })
                }
                styles={customSelectNewDark}
              />
            </form>
          </div>

          <div className={`align-items-end ${tableStyles.containerSelect}`}>
            <form onSubmit={e => handleSearch(e)}>
              &nbsp;<label className={tableStyles.stylesLabel}>Estado</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={optionsStatus}
                placeholder={'Seleccionar...'}
                styles={customSelectNewDark}
                onChange={e =>
                  setFilters({
                    ...filters,
                    status: e.value,
                  })
                }
              />
            </form>
          </div>

          <div className={`${tableStyles.containerSelect}`}>
            <form onSubmit={e => handleSearch(e)}>
              &nbsp;<label className={tableStyles.stylesLabel}>No. Factura</label>
              <input
                className={IndividualStyles.registerInputs}
                type='text'
                placeholder={'Escribir...'}
                value={filters.invoiceSeq}
                onChange={e =>
                  setFilters({
                    ...filters,
                    invoiceSeq: e.target.value,
                  })
                }
              />
            </form>
          </div>

          <div className={tableStyles.containerDate}>
            &nbsp;<label className={tableStyles.stylesLabel}>Fecha factura</label>
            <input
              className={`${IndividualStyles.registerInputs} register-inputs align-self-end`}
              type='date'
              value={filters.invoiceDate}
              style={{ color: '#005DBF', fontWeight: 'bold' }}
              onChange={e =>
                setFilters({
                  ...filters,
                  invoiceDate: e.target.value,
                })
              }
            />
          </div>

          <div className={tableStyles.containerDate}>
            &nbsp;<label className={tableStyles.stylesLabel}>Fecha radicación</label>
            <input
              className={`${IndividualStyles.registerInputs} register-inputs align-self-end`}
              type='date'
              value={filters.filingDate}
              style={{ color: '#005DBF', fontWeight: 'bold' }}
              onChange={e =>
                setFilters({
                  ...filters,
                  filingDate: e.target.value,
                })
              }
            />
          </div>

          <div className={`display-grid`}>
            <i>
              <img
                style={{ width: '25px', cursor: 'pointer', marginTop: '29px' }}
                src={Lupa}
                alt=''
                srcSet=''
                onClick={() => {
                  setTrigger(trigger + 1);
                  setFilters({ ...filters, page: 1 });
                }}
              />
            </i>
          </div>
        </div>
      </div>
      <div>
        <GenericTable headers={header3} dark={true}>
          {renderList()}
        </GenericTable>
      </div>
      <div className={IndividualStyles.bottom}>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {storage.billingReducer.gloss_history ? filters.page : ''}
            {' de '}
            {Math.ceil(storage.billingReducer?.gloss_history_total / filters.perpage)
              ? Math.ceil(storage.billingReducer?.gloss_history_total / filters.perpage)
              : ''}{' '}
            ({storage.billingReducer?.gloss_history_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={storage.billingReducer?.gloss_history_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </div>
    </div>
  );

  return details.show ? (
    <DetGloss selectedGloss={details.selectedGloss} show={details.show} setDetails={setDetails} />
  ) : (
    principalRender
  );
}
export default GlossHistory;
