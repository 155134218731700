import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { message } from '../helpers/helpers';
import { responseSwal } from '../helpers/responseSwal';
import {
  GET_ARL_LIST,
  GET_CONTRACT_BY_ID,
  GET_DEDUCTIONS,
  GET_DISCIPLINARY_PROCESSES,
  GET_DISCOUNTS_ANDPAYOUT,
  GET_EDUCATION_LEVEL,
  GET_EMPLOYEE,
  GET_EMPLOYEEE_EDUCATION,
  GET_EMPLOYEE_EXPERIENCE,
  GET_EMPLOYEE_INFO,
  GET_ENDOWMENT_GROUPS,
  GET_EPS_LIST,
  GET_MEDICAL_INFO,
  GET_PENSION_FUND,
  GET_PERSONAL,
} from './actionTypes';

export const getCandidateList = objFilters => (dispatch, getState) => {
  const filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-list?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE,
        payload: res.results,
      });
    })
    .catch(() => {
      return responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error',
      });
    });
};
export const getCandidateInfo =
  (objFilters, doAfter = null) =>
  (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    const filters = convertFilterToString(objFilters);
    fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-details?${filters}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        Authorization: token,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(res => {
        dispatch({
          type: GET_EMPLOYEE_INFO,
          payload: res.results,
        });
        if (doAfter) {
          doAfter(res);
        }
      })
      .catch(() => {
        responseSwal({
          icon: 'error',
          title: 'Error',
          text: 'Ha ocurrido un error al cargar la información',
        });
        if (doAfter) {
          doAfter();
        }
      });
  };
export const getAnnotations = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-details?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_INFO,
        payload: {
          ...getState().partnersReducer?.employee_info,
          annotations: res.results,
        },
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const getEPS = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/eps`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EPS_LIST,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const getPensionFund = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/pension-fund-entities`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_PENSION_FUND,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const getArl = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/arl`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_ARL_LIST,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};

export const getEndowmentGroups = objFilters => (dispatch, getState) => {
  const filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/endowment-group?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_ENDOWMENT_GROUPS,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const updateCollaborator = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contracts/create-candidate/`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error al cargar la información');
  }
};

export const getEmployeeEducation = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-details?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EMPLOYEEE_EDUCATION,
        payload: res.results.education,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const getEmployeeExperience = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/candidate-details?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EMPLOYEE_EXPERIENCE,
        payload: res.results.workExperience,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
export const getEducationLevel = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/education-level`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_EDUCATION_LEVEL,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};

//////////////////////////////////// STIVEN CODE BEGIN ////////////////////////////////////////////////
export const get_personal = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_PERSONAL,
    loading: true,
  });
  const token = getState().loginReducer.Authorization;
  let filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/personal/?` + filters, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_PERSONAL,
        payload: results,
        total: row_total,
        loading: false,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_PERSONAL,
        payload: [],
        total: 0,
        loading: false,
      });
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};
//////////////////////////////////// STIVEN CODE END /////////////////////////////////////////////////

export const getDeductionList = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustments/Type/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_DEDUCTIONS,
        payload: res.result,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error',
      });
    });
};

export const getDiscountsAndPayouts = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustment_subscriptions/employee/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_DISCOUNTS_ANDPAYOUT,
        payload: res.result,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error',
      });
    });
};

export const addDiscountOrPayout = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/adjustment_subscriptions/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    return result;
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error');
  }
};

export const getMedicalInfo = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/resume/medical_exams/?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_MEDICAL_INFO,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};

export const addMedicalResults = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/resume/medical_exams/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message('error', 'Error', result.message);
    }
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error al cargar la información');
  }
};

export const addAnnotation = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/annotations`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message('error', 'Error', result.message);
    }
  } catch (error) {
    console.error(error);
    return message('error', 'Error', 'Ha ocurrido un error al cargar la información');
  }
};

export const getDisciplinaryProcess = objFilters => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const filters = convertFilterToString(objFilters);
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/disciplinary-processes?${filters}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_DISCIPLINARY_PROCESSES,
        payload: res.results,
      });
    })
    .catch(() => {
      responseSwal({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al cargar la información',
      });
    });
};

export const getUrlContract = id => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/contract/url/${id}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(response => response.json())
    .then(res => {
      dispatch({
        type: GET_CONTRACT_BY_ID,
        payload: res.result.signed_doc,
      });
    });
};

export const registerNewDisciplinaryProcess = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/export/disciplinary-processes/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message('error', 'Error', result.message);
    }
  } catch (error) {
    return message('error', 'Error', 'Ha ocurrido un error');
  }
};
export const registerComplaint = async (data, token) => {
  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/complaints-management/`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    let result = await query.json();
    if (result.success) {
      return result;
    } else {
      return message('error', 'Error', result.message);
    }
  } catch (error) {
    return message('error', 'Error', 'Ha ocurrido un error');
  }
};
