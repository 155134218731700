import Select, { components } from 'react-select';
import { customSelectNewDark } from '../Layouts/react-select-custom';

const SelectComponent = props => {
  const {
    key,
    styles = customSelectNewDark,
    placeholder = 'Seleccionar...',
    value = '',
    onChange,
    isSearchable = true,
    menuIsOpen = undefined,
    options = [],
    name = '',
    id = '',
    onKeyDown,
    isMulti = undefined,
    defaultValue = '',
    autoFocus = undefined,
    className = '',
    isClearable = undefined,
    isDisabled = undefined,
    isLoading = undefined,
    isOptionDisabled = undefined,
    minMenuHeight = undefined,
    maxMenuHeight = undefined,
    onBlur,
    onFocus,
    required = undefined,
    closeMenuOnSelect
  } = props;

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>No hay datos</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Select
      noOptionsMessage={() => 'No hay datos'}
      key={key ?? ''}
      components={{ NoOptionsMessage }}
      styles={styles}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      options={options}
      name={name}
      id={id}
      onKeyDown={onKeyDown}
      isMulti={isMulti}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      className={className}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isOptionDisabled={isOptionDisabled}
      minMenuHeight={minMenuHeight}
      maxMenuHeight={maxMenuHeight}
      onBlur={onBlur}
      onFocus={onFocus}
      required={required}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
};

export default SelectComponent;
