import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetMethod } from '../../Hooks/useFetch';
import advertenciaOrd from '../../assets/img/icons/alertIconOrd.svg';
import excelVeryClearBlue from '../../assets/img/icons/excelVeryClearBlue.svg';
import pdfClearBlueIcon from '../../assets/img/icons/pdfClearBlueIcon.svg';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { downloaFile, genericDownloaFile, loader, message, today, formatteDocument } from '../../helpers';
import { OrdGenericTemplate } from '../OrderingComponents/OrdGenericTemplate';
import OrdTable from '../OrderingComponents/OrdTable';

const ReferencesReports = () => {
  const {
    results: referencesReports,
    load: referencesLoader,
    trigger: getReferencesReports,
  } = useGetMethod();
  const { load: pdfLoader, trigger: getPdfOrder } = useGetMethod();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idCurrentAccount = store.loginReducer.currentAccount.id;
  const [showTable, setShowTable] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    idAccount: idCurrentAccount,
    report: '1',
    startDate: today(),
    endDate: today(),
  });
  const headers = [
    {
      title: 'Identificación',
      className: 'px-2 text-start',
    },
    {
      title: 'Paciente',
      className: 'px-2 text-start',
    },
    {
      title: 'Empresa',
      className: 'px-2 text-start',
    },
    {
      title: 'Contrato',
      className: 'px-2 text-start',
    },
    {
      title: 'Médico',
      className: 'px-2 text-start',
    },
    {
      title: 'Fecha',
      className: 'px-4 text-center',
    },
    {
      title: 'Motivo',
      className: 'px-2 text-start',
    },
  ];

  const searchButton = () => {
    let valueStartDate;
    let valueEndDate;
    let inputStartDate = document.getElementById('date-start');
    let inputEndDate = document.getElementById('date-end');
    if (inputStartDate.value) {
      valueStartDate = true;
    } else {
      valueStartDate = false;
    }
    if (inputEndDate.value) {
      valueEndDate = true;
    } else {
      valueEndDate = false;
    }
    if (valueStartDate & (valueEndDate === true)) {
      if (filters.endDate >= filters.startDate || filters.startDate <= filters.endDate) {
        setFilters({ ...filters, page: 1 });
        setTrigger(trigger + 1);
        setShowTable(true);
      } else {
        return message(
          'warning',
          'Fechas incorrectas',
          'La Fecha de Inicio no puede ser mayor a la Fecha Fin, es decir, la Fecha Fin no puede ser menor a la Fecha de Inicio',
        );
      }
    } else {
      setShowTable(false);
    }
  };

  const downloadPdf = () => {
    getPdfOrder({
      url: '/medical/pdfReferenceReport/',
      objFilters: {
        report: 1,
        startDate: filters.startDate,
        endDate: filters.endDate,
        idAccount: idCurrentAccount,
      },
      token: token,
      doAfterSuccess: res => {
        if (res.success) {
          downloaFile(res?.results[0].base64);
        }
      },
    });
  };

  const downloadExcel = () => {
    getPdfOrder({
      url: '/medical/pdfReferenceReport/',
      objFilters: {
        startDate: filters.startDate,
        endDate: filters.endDate,
        report: 1,
        idAccount: idCurrentAccount,
        excel: 1,
      },
      token: token,
      doAfterSuccess: res => {
        if (res.success) {
          genericDownloaFile(res?.results?.base64, res?.results?.filename);
        }
      },
    });
  };

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-2'>{formatteDocument(item?.patient?.document).format}</td>
            <td className='text-start px-2  '>{item?.patient?.name}</td>
            <td className='text-start px-2 '>{item?.corporateClient?.name}</td>
            <td className='text-start px-2 '>{item?.corporateClient?.contractName}</td>
            <td className='text-start px-2 '> {item?.doctor?.name}</td>
            <td className='text-center px-2 '> {item?.date}</td>
            <td className='text-start px-2 '>{item?.refs[0]?.cause}</td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  // --- GET references REPORTS-----
  useEffect(() => {
    getReferencesReports({
      url: '/medical/reference/',
      objFilters: filters,
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return (
    <>
      {referencesLoader && loader}
      {pdfLoader && loader}
      <div className='col-11'>
        <OrdGenericTemplate
          title={'Informes de referencia y contrarreferencia'}
          titleSize={12}
          className='mx-2 pb-2'
          titleStyle={{ paddingLeft: '1.8rem' }}
        >
          <div className='d-flex  mx-0 align-items-end pt-2'>
            <Row
              className={`d-flex justify-content-center w-100 aling-items-end`}
              style={{ marginLeft: '0.8rem' }}
            >
              <Col xs={3} className={`px-0 align-self-end`}>
                <Form.Group className='px-2' controlId='ordType'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} `}>
                    <b>&nbsp;Fecha de inicio</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.inputDate}`}
                    type='date'
                    id='date-start'
                    value={filters.startDate}
                    onChange={e => {
                      setFilters({ ...filters, startDate: e.target.value });
                    }}
                    max={today()}
                  />
                </Form.Group>
              </Col>
              <Col xs={3} className={`px-0 align-self-end`}>
                <Form.Group className=' px-2' controlId='ordType'>
                  <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText}`}>
                    <b>&nbsp;Fecha fin</b>
                  </Form.Label>
                  <Form.Control
                    className={`ord-roundInput ${tableStyles.ordDarkBlueText} ${tableStyles.inputDate}`}
                    type='date'
                    id='date-end'
                    value={filters.endDate}
                    onChange={e => {
                      setFilters({ ...filters, endDate: e.target.value });
                    }}
                    min={filters.startDate}
                  />
                </Form.Group>
              </Col>
              <Col className={`align-self-end`} xs={2}>
                <div className='d-flex  pt-2'>
                  <button
                    type='button'
                    className={`${tableStyles.btnGenerateRep}`}
                    onClick={() => {
                      searchButton();
                    }}
                  >
                    Generar
                  </button>
                </div>
              </Col>
              <Col className={`px-2 pb-1 d-flex justify-content-end aling-self-end`} xs={4}>
                {showTable && (
                  <div className=' d-flex align-self-end'>
                    <button
                      onClick={() => {
                        downloadPdf();
                      }}
                    >
                      <img
                        src={pdfClearBlueIcon}
                        alt='pdfClearBlueIcon'
                        className={`${tableStyles.iconSvgSemi}`}
                        style={{ width: '1.7rem' }}
                      />
                    </button>
                    <button
                      onClick={() => {
                        downloadExcel();
                      }}
                    >
                      <img
                        src={excelVeryClearBlue}
                        alt='excelVeryClearBlue p-2 mr-2'
                        className={`${tableStyles.iconSvg}`}
                        style={{ width: '1.5rem' }}
                      />
                    </button>
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {/* <TableReferencesReports /> */}
          {showTable && (
            <div className='pl-3 m-2 '>
              <OrdTable
                headers={headers}
                hasChildren={true}
                shadow={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: filters?.page,
                  totalPages: referencesReports?.rowTotal,
                  perPage: filters?.perpage,
                  pageRangeDisplayed: 3,
                  onChangePage: async e => {
                    const result = await getReferencesReports({
                      url: '/medical/reference/',
                      objFilters: { ...filters, page: e },
                      token: token,
                    });
                    setFilters({ ...filters, page: e });
                    formatData(result?.results);
                  },
                  showTextDetails: true,
                }}
              >
                {formatData(referencesReports.results)}
              </OrdTable>
            </div>
          )}
          {!showTable && (
            <section className={`d-flex justify-content-center`} style={{ padding: '10%' }}>
              <div>
                <Typography className={`text-secondary`}>
                  <h5 className={`  ${tableStyles.ordSpanText} text-center pb-4`}>
                    Para generar el informe indica el rango de fechas
                  </h5>
                </Typography>
                <img
                  className={'center'}
                  // width={'30x'}
                  src={advertenciaOrd}
                  alt='alert'
                ></img>
              </div>
            </section>
          )}
        </OrdGenericTemplate>
      </div>
    </>
  );
};

export default ReferencesReports;
