import { Tooltip } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getPdfTest } from '../../actions/IndividualAuctionActions';
import {
  changeStatus,
  get_articlesInPurchaseOrder,
  get_programming_InPOStatus,
  get_providerInfo_InPOStatus,
  get_purchaseOrderStatus,
} from '../../actions/purchaseOrderStatusActions';
import Watch2 from '../../assets/img/icons/Reloj.svg';
import RoundCheck from '../../assets/img/icons/RoundCheck.svg';
import iconPrint from '../../assets/img/icons/iconPrint.svg';
import Lupa from '../../assets/img/icons/lupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import { message } from '../../helpers/helpers';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { PdfViewer } from '../Layouts/PdfViewer';
import paginationStyles from '../Layouts/pagination.module.scss';
import { customSelectNewDark } from '../Layouts/react-select-custom';
import tableStyles from '../Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import SelectComponent from '../SelectComponent/SelectComponent';
import poStyles from './StatusPO.module.scss';

function payConditions(key) {
  switch (key) {
    case 'prepayment':
      return 'Pago anticipado';
    case 'delivery':
      return 'Pago contraentrega';
    case 'pay20':
      return 'Pago a 20 días';
    case 'pay30':
      return 'Pago a 30 días';
    case 'pay60':
      return 'Pago a 60 días';
    case 'pay90':
      return 'Pago a 90 días';
    default:
      return '-';
  }
}

function StatusPurchaseOrder() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'StatusPO',
  );
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: counter.loginReducer.currentAccount.id,
    dateCreated: '',
    status: '',
    code: '',
    name: '',
  });

  const [prog, setprog] = useState({
    page: 1,
    perpage: 10,
  });
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(1);
  useEffect(() => {
    dispatch(get_purchaseOrderStatus(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, trigger, filters.status]);
  const [showPdf, setShowPdf] = useState(false);
  const [base64, setBase64] = useState('');
  const token = counter.loginReducer.Authorization;

  const getPdf = async idRow => {
    const result = await getPdfTest(
      {
        id: idRow,
        doc: 10,
      },
      token,
    );
    if (result?.success) {
      return setBase64(result?.pdf[0]?.key?.split("'")[1]);
    } else {
      message('error', 'Ha ocurrido un error', 'No ha sido posible cargar el documento');
      return setShowPdf(false);
    }
  };

  const [modalDetails, setmodalDetails] = useState({
    show: false,
  });
  const [modalProgramming, setmodalProgramming] = useState({
    show: false,
  });

  const [modalJustify, setmodalJustify] = useState({
    show: false,
  });

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const detailShow = id => {
    getPdf(id);
    setmodalDetails({ show: true });
    dispatch(get_articlesInPurchaseOrder({ id_po: id }));
    dispatch(get_providerInfo_InPOStatus({ id_po: id }));
  };
  const ButtonProgramming = x => {
    setmodalProgramming({ show: true, info_article: x });
    setprog({
      ...prog,
      id_PO_article: x.id,
    });
    dispatch(
      get_programming_InPOStatus({
        ...prog,
        id_PO_article: x.id,
      }),
    );
  };
  const headerPO = [
    <th key={`PO1`} className='text-start px-2'>
      OC.
    </th>,
    <th key={`PO2`} className='text-center px-2'>
      Fecha
    </th>,
    <th key={`PO3`} className='text-center px-2'>
      Fecha Entrega Pactada
    </th>,
    <th key={`PO4`} className='text-start px-2'>
      Proveedor
    </th>,
    <th key={`PO5`} className='text-center px-2'>
      Estado
    </th>,
    <th key={`PO6`}></th>,
  ];
  const ballthingyTooltipName = percentage => {
    switch (percentage) {
      case 100:
        return 'Entregada';
      case 0:
        return 'Pendiente';
      default:
        return 'Parcialmente entregada';
    }
  };
  const annular = id => {
    customSwaltAlert({
      icon: 'warning',
      showCancelButton: true,
      title: '<span style=color:#01A0F6>¿Está seguro?<span>',
      text: 'Esta acción es irreversible',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, anular OC',
      focusConfirm: false,
      customClass: {
        confirmButton: `${poStyles.btnPrimary}`,
        cancelButton: `${poStyles.btnSecondary}`,
      },
    }).then(result => {
      if (result.isConfirmed) {
        setmodalJustify({ show: true, PO: id });
      }
    });
  };

  const printDoc = id => {
    getPdf(id);
    setShowPdf(true);
    //console.log("showPdf", showPdf);
  };

  const renderPO = () => {
    let table = [];
    if (Array.isArray(counter.statusPOReducer?.po)) {
      table = counter.statusPOReducer.po.map((x, realindex) => {
        return (
          <tr key={'renderPOStatus' + realindex} className='hover-table-row'>
            <td className='text-start px-2'>{x.purchaseOrder_number.split('.')[1]}</td>
            <td className='text-center px-2'>
              {x.created_at.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-center px-2'>
              {x.delivery_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}
            </td>
            <td className='text-start px-2'>{x.name_provider}</td>
            <td className='text-center px-2'>
              <div
                style={{ justifyContent: 'space-evenly' }}
                className={`${poStyles.status} d-flex`}
              >
                <div>
                  <p
                    className={` ${
                      (x.pendings / x.deliverys) * 100 === 0
                        ? poStyles.textRed
                        : (x.pendings / x.deliverys) * 100 === 100
                          ? poStyles.textGreen
                          : poStyles.textYellow
                    }
                        `}
                  >
                    {Math.round((x.pendings / x.deliverys) * 100) + '%'}
                    {(x.pendings / x.deliverys) * 100 <= 9 && <> &nbsp; </>}
                    {(x.pendings / x.deliverys) * 100 <= 99 && <> &nbsp;</>}
                  </p>
                </div>
                <div>
                  <Tooltip title={ballthingyTooltipName((x.pendings / x.deliverys) * 100)} arrow>
                    {/* <div
                      className={`${poStyles.popupReqActive} 
                        ${x.status === "rejected"
                          ? tableStyles.circleBlack
                          : (x.pendings / x.deliverys) * 100 === 0
                            ? tableStyles.circleRed
                            : (x.pendings / x.deliverys) * 100 === 100
                              ? tableStyles.circleGreen
                              : tableStyles.circleYellow
                        }
                      `}
                    ></div> */}
                    <div
                      className='rounded-pill p-1 mx-auto'
                      style={{
                        width: 200,
                        backgroundColor:
                          (x.pendings / x.deliverys) * 100 === 100
                            ? '#FAFDF6'
                            : (x.pendings / x.deliverys) * 100 === 0
                              ? 'rgb(254, 250, 238)'
                              : '#FEF7F5',
                      }}
                    >
                      <b
                        style={{
                          color:
                            (x.pendings / x.deliverys) * 100 === 100
                              ? '#83C036'
                              : (x.pendings / x.deliverys) * 100 === 0
                                ? 'rgb(233, 180, 25)'
                                : '#F39682',
                        }}
                      >
                        {ballthingyTooltipName((x.pendings / x.deliverys) * 100)}
                      </b>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </td>
            <td>
              <div className={poStyles.providerTable__Icon}>
                <CustomPopupExtend
                  noHover
                  triggerSrc={threeDots}
                  showDetails={true}
                  position='left'
                  showDetailsEvent={() => detailShow(x.id)}
                  showDelete={
                    myPermission.reject &&
                    x.status !== 'rejected' &&
                    (x.pendings / x.deliverys) * 100 !== 100
                  }
                  deleteEvent={() => annular(x.id)}
                  showPrint={true}
                  printEvent={() => printDoc(x.id)}
                  deleteText='Anular OC.'
                  triggerClass={poStyles.providerGearIcon}
                />
              </div>
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  const renderHeadersSummary = [
    <th className='text-center' key={`indexSum0`}>
      Entrega
    </th>,
    <th className='text-center' key={`indexSum1`}>
      Item
    </th>,
    <th className='text-center' key={`indexSum2`}>
      Descripción de Artículo
    </th>,
    <th className='text-center' key={`indexSum3`}>
      Cant.
    </th>,
    <th className='text-center' key={`indexSum4`}>
      Valor Unidad
    </th>,
    <th className='text-center' key={`indexSum5`}>
      Descuento <br />
      <div className='d-flex justify-content-around'>
        <span>%</span>
        <span>$</span>
      </div>
    </th>,
    <th className='text-center' key={`indexSum6`}>
      Total
    </th>,
  ];

  const [renderSummary, subtotal, iva, disc] = useMemo(() => {
    let table = [];
    let subtotal = 0;
    let iva = 0;
    let disc = 0;

    if (Array.isArray(counter.statusPOReducer?.articles_po)) {
      table = counter.statusPOReducer?.articles_po.map((x, index) => {
        subtotal += parseInt(x.unit_price) * parseInt(x.qty_article);
        iva += parseInt(x.iva_value);
        disc += parseInt(x.discount_value);
        return (
          <tr key={'summary' + index} className={`hover-table-row`}>
            <td className={`col-md-1 text-center`}>
              <img
                src={Watch2}
                alt='Reloj'
                className={poStyles.marginButton}
                onClick={() => ButtonProgramming(x)}
              />
            </td>
            <td className={`col-md-1`}>
              <p className='text-center'>{index + 1}</p>
            </td>
            <td className={`col-md-1`}>
              <p className=' text-start'>{x.description}</p>
            </td>
            <td className={`col-md-1`}>
              <p className='text-center'>{x.qty_article}</p>
            </td>
            <td className={`col-md-1`}>
              <p className='text-center'>
                {x?.unit_price ? '$' + numberWithCommas(x.unit_price) : '$'}
              </p>
            </td>
            <td className={`col-md-1`}>
              <div className='d-flex justify-content-around align-items-center'>
                <p className='text-center'>{x.discount}</p>
                <p className='text-center'>
                  {x?.discount_value ? '$' + numberWithCommas(x.discount_value) : '$'}
                </p>
              </div>
            </td>

            <td className={`col-md-1`}>
              <p className='text-center'>{x?.Total ? '$' + numberWithCommas(x.Total) : '$'}</p>
            </td>
          </tr>
        );
      });
    }

    return [table, subtotal, iva, disc];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.statusPOReducer?.articles_po]);

  const renderTableFooter = tfoot => {
    return tfoot;
  };

  let renderFooter = [
    <tr key={1}>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className={`${poStyles.textFooter}`}>Subtotal</td>
      <td className={`${poStyles.textFooter} text-center flex-wrap `}>
        {' '}
        {'$' + numberWithCommas(subtotal)}
      </td>
    </tr>,
    <tr key={2}>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className={`${poStyles.textFooter}`}>IVA</td>
      <td className={`${poStyles.textFooter} text-center`}> {'$' + numberWithCommas(iva)}</td>
    </tr>,
    <tr key={3}>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className={`${poStyles.textFooter}`}>DESCUENTO</td>
      <td className={`${poStyles.textFooter} text-center`}> {'$' + numberWithCommas(disc)}</td>
    </tr>,
    <tr key={4}>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className='invisible'></td>
      <td className={`${poStyles.textFooter}`}>TOTAL</td>
      <td className={`${poStyles.textFooter} text-center`}>
        {' '}
        {'$' + numberWithCommas(subtotal + iva - disc)}
      </td>
    </tr>,
  ].map(item => {
    return renderTableFooter(item);
  });
  const renderProgrammings = [
    <th key={`PRO1`} className='px-2'>
      #
    </th>,
    <th key={`PRO2`} className='px-2 text-center'>
      Fecha
    </th>,
    <th key={`PRO3`} className='px-2 text-center'>
      Cantidad
    </th>,
    <th key={`PRO4`}></th>,
  ];

  const [renderProgrammingsItems, amount] = useMemo(() => {
    let table = [];
    let amount = 0;
    if (Array.isArray(counter.statusPOReducer.programming)) {
      table = counter.statusPOReducer.programming.map((x, realindex) => {
        amount = amount + x.qty_article;
        return (
          <tr key={'ArticlesProgramming' + realindex} className='hover-table-row'>
            <td>
              {x.status === 'pending' ? (
                <img alt='d' src={RoundCheck}></img>
              ) : (
                <img alt='d' src={RoundCheck} className={poStyles.filter}></img>
              )}
            </td>
            <td>{x.delivery_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
            <td>{x.qty_article}</td>
          </tr>
        );
      });
    }
    return [table, amount];
  }, [counter.statusPOReducer.programming]);

  const closed = () => {
    setmodalProgramming({
      ...modalProgramming,
      show: false,
    });
    setprog({
      page: 1,
      perpage: 10,
      id_PO_article: '',
    });
  };
  const [justify, setjustify] = useState({
    text: '',
  });

  const btnJustification = id => {
    setjustify({ text: '' });
    setLoading(true);
    dispatch(changeStatus({ idPO: id, justify: justify.text }, () => setLoading(false)));
    setmodalJustify({ show: false });
  };

  const btncancelJustification = () => {
    setmodalJustify({ show: false });
    setjustify({ text: '' });
  };

  //render ___________________________________________________________________________________________
  return (
    <>
      <ModalNew
        title='Detalle de entrada por compra'
        show={showPdf}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowPdf(false)}
        btnNoEvent={() => setShowPdf(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
      </ModalNew>
      <div className={tableStyles.container} style={{ padding: '0 6rem' }}>
        {(counter.statusPOReducer?.po_loading || loading) && (
          <div className='loading'>
            <Loader type='Oval' color='#003F80' height={100} width={100} />
          </div>
        )}
        {/* /* -------------------------------- PDF Viwer ------------------------------- */}

        <div className={poStyles.container1}>
          <h1 className={tableStyles.title}>Estado ordenes de compra</h1>
        </div>
        <div className={poStyles.container2}>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <div className='d-flex'>
            <div className={`${poStyles.inputMarginR}  ${poStyles.Item0} col-2`}>
              <p className={poStyles.crudModalLabel}>Fecha OC</p>
              <input
                className={`register-inputs`}
                name='date_OC'
                style={{ color: '#58595B' }}
                type='date'
                value={filters.dateCreated}
                onChange={e =>
                  setFilters({
                    ...filters,
                    dateCreated: e.target.value,
                  })
                }
                disabled={false}
              />
            </div>

            <div className={`${poStyles.inputMarginR} col-2`}>
              <p className={poStyles.crudModalLabel}>Estado</p>
              <SelectComponent
                value={[
                  { label: 'Seleccionar...', value: '' },
                  { label: 'Anulada', value: 'rejected' },
                  { label: 'Pendiente', value: 'pending' },
                ].find(x => x.value === filters.status)}
                styles={customSelectNewDark}
                placeholder={'Seleccionar...'}
                onChange={e => {
                  setFilters({
                    ...filters,
                    status: e.value,
                  });
                }}
                options={[
                  { label: 'Seleccionar', value: '' },
                  { label: 'Anulada', value: 'rejected' },
                  { label: 'Pendiente', value: 'pending' },
                ]}
              ></SelectComponent>
            </div>

            <div className={`col-5`}>
              <p className={poStyles.crudModalLabel}>No. de Factura o remisión</p>
              <input
                className={`${tableStyles.SearchNew}`}
                type='text'
                style={{ color: '#58595B' }}
                placeholder='  Número de OC o proveedor...'
                value={filters.search}
                onChange={e =>
                  setFilters({
                    ...filters,
                    search: e.target.value,
                  })
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setTrigger(trigger + 1);
                  }
                }}
              />
            </div>
            <div className={`${poStyles.Item}`}>
              <p className={tableStyles.crudModalLabel}>&nbsp;</p>
              <img
                src={Lupa}
                alt='filtrar'
                height={24}
                className={`${tableStyles.iconSvg}`}
                onClick={() => setTrigger(trigger + 1)}
              />
            </div>
          </div>

          <GenericTableNew dark={true} headers={headerPO}>
            {renderPO()}
          </GenericTableNew>
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {counter.statusPOReducer.po_total ? filters.page : ''}
              {' de '}
              {Math.ceil(counter.statusPOReducer.po_total / filters.perpage)
                ? Math.ceil(counter.statusPOReducer.po_total / filters.perpage)
                : ''}{' '}
              ({counter.statusPOReducer.po_total} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={10}
              totalItemsCount={counter.statusPOReducer.po_total}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
        <ModalNew
          title={'Orden de compra '}
          show={modalDetails.show}
          btnNoName={'Cancelar'}
          btnNoEvent={() => setmodalDetails({ show: false })}
          size='940'
          onHide={() => setmodalDetails({ show: false })}
        >
          <div className={poStyles.container2}>
            {/* row 1 */}

            <div className={`d-flex`}>
              <div className={`${poStyles.inputMargin} ${poStyles.Item} ${poStyles.f15}`}>
                <p className={poStyles.crudModalLabel}>Proveedor</p>
                <input
                  className={`register-inputs`}
                  style={{ color: '#58595B' }}
                  type='text'
                  value={counter.statusPOReducer.info_provider?.name_provider}
                  readOnly
                />
              </div>

              <div className={`${poStyles.inputMargin} ${poStyles.Item} ${poStyles.f1}`}>
                <p className={poStyles.crudModalLabel}>NIT</p>
                <input
                  className={`register-inputs`}
                  type='text'
                  style={{ color: '#58595B' }}
                  value={counter.statusPOReducer.info_provider?.nit}
                  readOnly
                />
              </div>

              <div className={`${poStyles.Item} ${poStyles.f1}`}>
                <p className={poStyles.crudModalLabel}>Condiciones de pago</p>
                <input
                  className={`register-inputs`}
                  type='text'
                  style={{ color: '#58595B' }}
                  value={payConditions(counter.statusPOReducer.info_provider?.pay_conditions)}
                  readOnly
                />
              </div>
            </div>

            {/* row 2 */}
            <div className={`d-flex`}>
              <div className={`${poStyles.inputMargin} ${poStyles.Item} ${poStyles.f15}`}>
                <p className={poStyles.crudModalLabel}>Términos y condiciones</p>
                <input
                  className={`register-inputs`}
                  type='text'
                  style={{ color: '#58595B' }}
                  value={counter.statusPOReducer.info_provider?.observations}
                  readOnly
                />
              </div>

              <div className={`${poStyles.inputMargin} ${poStyles.Item} ${poStyles.f1}`}>
                <p className={poStyles.crudModalLabel}>Dirección de entrega</p>
                <input
                  className={`register-inputs`}
                  type='text'
                  style={{ color: '#58595B' }}
                  value={counter.statusPOReducer.info_provider?.address_warehouse}
                  readOnly
                />
              </div>

              <div className={`${poStyles.ItemCheckbox} ${poStyles.f1}`}>
                <div className={poStyles.myCheckbox}>
                  <input
                    key={'delivery' + Math.random()}
                    className={poStyles.myCheckboxInput}
                    type='checkbox'
                    name='delivery'
                    value='delivery'
                    checked={counter.info_provider?.partial_deliveries}
                  />
                  <label
                    className={`${poStyles.myCheckboxLabel} ${poStyles.delivery}`}
                    htmlFor='delivery'
                  >
                    Contiene entregas parciales
                  </label>
                </div>
              </div>
            </div>
          </div>{' '}
          <Row className='d-flex'>
            <Col xs={12} className='d-flex justify-content-end mt-2 cursorPointer'>
              <Tooltip title='Imprimir'>
                <img
                  // className={`${detailStyles.titleIcons}`}
                  src={iconPrint}
                  alt='imprimir'
                  onClick={() => setShowPdf(true)}
                />
              </Tooltip>
            </Col>
          </Row>
          <GenericTableNew
            headers={renderHeadersSummary}
            dark={false}
            lowerCase={true}
            footer={renderFooter}
          >
            {renderSummary}
          </GenericTableNew>
          <div className={poStyles.containerObservations}>
            <div className='d-flex w-100'>
              <div
                className={`${poStyles.observationsColor} ${poStyles.sizeObservations}  d-flex flex-column`}
              >
                <label
                  htmlFor='observations'
                  className={`${poStyles.labelFont} ${poStyles.marginLeftObservations}`}
                >
                  Observaciones
                </label>
                <textarea
                  name='observations'
                  style={{ color: '#58595B', border: '1px solid #7FADDE' }}
                  className={`${poStyles.observationsArea} ${poStyles.marginLeftObservations}`}
                  value={counter.statusPOReducer.info_provider?.observations}
                  disabled={true}
                ></textarea>
              </div>
            </div>
          </div>
        </ModalNew>
        <ModalNew
          title={'Entregas parciales'}
          show={modalProgramming.show}
          btnNoEvent={() => closed()}
          hideCancelButton={true}
          size='sz'
          onHide={() => closed()}
        >
          <div id='arriba' className={`${poStyles.backgroundModal} ${poStyles.paddingButton}`}>
            {/* ---------------------------- ROW 1 ---------------------------------- */}
            <div className='d-flex'>
              <div className={` ${poStyles.inputMarginLR} ${poStyles.f2}`}>
                <p className={poStyles.crudModalLabel}>Producto</p>
                <input
                  className={` register-inputs`}
                  name='product'
                  type='text'
                  value={modalProgramming.info_article?.description}
                  style={{ color: '#58595B' }}
                  readOnly
                />
              </div>

              <div className={`${poStyles.inputMarginR} ${poStyles.f1}`}>
                <p className={` ${poStyles.crudModalLabel}`}>Código</p>
                <input
                  className={` register-inputs`}
                  name='code'
                  type='text'
                  value={modalProgramming.info_article?.id_article}
                  style={{ color: '#58595B' }}
                  readOnly
                />
              </div>
            </div>
            {/* ---------------------------- ROW 2 ---------------------------------- */}
            <div className='d-flex'>
              <div className={`${poStyles.inputMarginLR} ${poStyles.f2}`}>
                <p className={poStyles.crudModalLabel}>Dirección de entrega</p>
                <input
                  className={` register-inputs`}
                  name='address'
                  type='text'
                  value={counter.statusPOReducer.info_provider?.address_warehouse}
                  style={{ color: '#58595B' }}
                  readOnly
                />
              </div>

              <div className={`${poStyles.inputMarginR} ${poStyles.f1}`}>
                <p className={` ${poStyles.crudModalLabel}`}>Cantidad</p>
                <input
                  className={` register-inputs`}
                  name='amount'
                  type='text'
                  value={amount}
                  style={{ color: '#58595B' }}
                  readOnly
                />
              </div>
            </div>
          </div>
          <p className={poStyles.sectionTitle}>Programación solicitada</p>
          <GenericTableNew dark={false} headers={renderProgrammings}>
            {renderProgrammingsItems}
          </GenericTableNew>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={10}
            totalItemsCount={counter.statusPOReducer.programming_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </ModalNew>

        <ModalNew
          title='Anulación'
          show={modalJustify.show}
          btnYesName={'Guardar'}
          btnNoName={'Cancelar'}
          size='400'
          btnYesEvent={() => btnJustification(modalJustify.PO)}
          btnYesDisabled={!justify.text}
          onHide={() => btncancelJustification()}
          btnNoEvent={() => btncancelJustification()}
          btnNoDisabled={false}
        >
          <textarea
            className={`${poStyles.inputsTextArea}`}
            name='justification'
            type='text'
            value={justify.text}
            onChange={e => setjustify({ text: e.target.value })}
          ></textarea>
        </ModalNew>
      </div>{' '}
    </>
  );
}
export default StatusPurchaseOrder;
