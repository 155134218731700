import { useEffect, useState } from 'react';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import Nav from 'react-bootstrap/Nav';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Checkbox } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import GestionUsers from './GestionUsers';
import cloneDeep from 'lodash/cloneDeep';

import {
  getProfiles,
  permission_module_get,
  update_permissions,
} from '../../actions/profileActions';

import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectStyleSmall } from '../Layouts/react-select-custom';
import '../TableUsers/tableUserStyle.scss';
import '../Layouts/customTabs.scss';

function GestionPermisos() {
  const counter = useSelector(state => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(1);
  const profileAdminId = 1;
  const [profile, setProfile] = useState(1);
  const [module, setModule] = useState({});
  const [moduleBackup, setModuleBackup] = useState({});
  const [moduleDefaultPending, setModuleDefaultPending] = useState(true);

  useEffect(() => {
    if (counter.loginReducer.currentAccount?.profile?.prefijo !== 'ADM') {
      history.push('/categoryselect');
    }
    dispatch(getProfiles());
    dispatch(permission_module_get());
  }, [counter.loginReducer.currentAccount?.profile?.prefijo, dispatch, history]);

  useEffect(() => {
    if (
      moduleDefaultPending &&
      Array.isArray(counter.profileReducer.modules) &&
      counter.profileReducer.modules.length > 0
    ) {
      setModule({
        value: counter.profileReducer.modules[0].id,
        label: counter.profileReducer.modules[0].name,
        functionality_list: counter.profileReducer.modules[0].functionality_list,
      });
      setModuleDefaultPending(false);
    }
  }, [counter.profileReducer.modules, moduleDefaultPending]);

  useEffect(() => {
    dispatch(getProfiles());
    dispatch(permission_module_get());
  }, [dispatch, trigger]);

  const findPermission = functionality => {
    if (functionality) {
      let temp = functionality.permission;
      if (Array.isArray(temp)) {
        let found = temp.find(x => x.id_profile === profile);
        if (found) {
          return found;
        } else {
          return {
            id_functionality: functionality.id,
            id_profile: profile,
            read: false,
            create: false,
            edit: false,
            delete: false,
            approve: false,
            reject: false,
          };
        }
      }
    }
    return null;
  };

  const perfilCheckHandle = (id_functionality, start_permission, box) => {
    let tempPermision = { ...start_permission };
    if (box === 'all') {
      if (tempPermision?.id_functionality) {
        let newval = perfilCheckStatus(tempPermision, 'all');
        tempPermision = {
          ...tempPermision,
          read: !newval,
          create: !newval,
          edit: !newval,
          delete: !newval,
          approve: !newval,
          reject: !newval,
        };
      } else {
        tempPermision = {
          id_profile: profile,
          id_functionality: id_functionality,
          read: true,
          create: true,
          edit: true,
          delete: true,
          approve: true,
          reject: true,
        };
      }
    } else {
      if (tempPermision?.id_functionality) {
        tempPermision[box] = !tempPermision[box];
      } else {
        tempPermision = {
          id_profile: profile,
          id_functionality: id_functionality,
          read: false,
          create: false,
          edit: false,
          delete: false,
          approve: false,
          reject: false,
        };
        tempPermision[box] = !tempPermision[box];
      }
    }

    let tempModule = { ...module };
    let func_id = tempModule.functionality_list.findIndex(x => x.id === id_functionality, 0);
    if (module.functionality_list[func_id]) {
      if (Array.isArray(tempModule.functionality_list[func_id].permission)) {
        let perm_id = tempModule.functionality_list[func_id].permission.findIndex(
          x => x === start_permission,
        );
        if (tempModule.functionality_list[func_id].permission[perm_id]) {
          tempModule.functionality_list[func_id].permission[perm_id] = { ...tempPermision };
          setModule({ ...tempModule });
        } else {
          tempModule.functionality_list[func_id].permission.push({ ...tempPermision });
          setModule({ ...tempModule });
        }
      }
    }
  };

  const perfilCheckStatus = (start_permission, box) => {
    if (box === 'all') {
      return (
        start_permission.read ||
        start_permission.create ||
        start_permission.edit ||
        start_permission.delete ||
        start_permission.approve ||
        start_permission.reject
      );
    } else {
      return start_permission[box];
    }
  };

  const renderPermisions = elem => {
    return (
      <div key={elem.id + 'functionality' + Math.random()} className='customTabs__Row'>
        <div className='customTabs__CellFirst'>
          <Checkbox
            checked={perfilCheckStatus(findPermission(elem), 'all')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'all')}
            id={'checkall' + elem.id}
            name={'checkall' + elem.id}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__Cell customTabs__Cell--border'>{elem.name}</div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkRead' + elem.id}
            name={'checkRead' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'read')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'read')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkCreate' + elem.id}
            name={'checkCreate' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'create')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'create')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkEdit' + elem.id}
            name={'checkEdit' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'edit')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'edit')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkDelete' + elem.id}
            name={'checkDelete' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'delete')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'delete')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkApprove' + elem.id}
            name={'checkApprove' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'approve')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'approve')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
        <div className='customTabs__TinyCell'>
          <Checkbox
            id={'checkReject' + elem.id}
            name={'checkReject' + elem.id}
            checked={perfilCheckStatus(findPermission(elem), 'reject')}
            onChange={() => perfilCheckHandle(elem.id, findPermission(elem), 'reject')}
            color='primary'
            style={{ padding: 0 }}
            disabled={profile === profileAdminId}
          />
        </div>
      </div>
    );
  };

  const listPermisions = theList => {
    let elemMap;
    if (Array.isArray(theList)) {
      elemMap = theList.map(elem => {
        if (findPermission(elem)) {
          return renderPermisions(elem);
        } else return null;
      });
    }

    const headerLine = (
      <div key='headerLine' className='customTabs__Row'>
        <div className='customTabs__CellHead'> </div>
        <div className='customTabs__Cell customTabs__Cell--border'></div>
        <div className='customTabs__TinyCell'>Ver</div>
        <div className='customTabs__TinyCell'>Crear</div>
        <div className='customTabs__TinyCell'>Editar</div>
        <div className='customTabs__TinyCell'>Borrar</div>
        <div className='customTabs__TinyCell'>Aprobar</div>
        <div className='customTabs__TinyCell'>Rechazar</div>
      </div>
    );

    return (
      <div className='customTabs_scroll'>
        {headerLine}
        {elemMap}
      </div>
    );
  };

  const listProfileTab = () => {
    let elemMap;
    if (Array.isArray(counter.profileReducer.profiles)) {
      elemMap = counter.profileReducer.profiles.map((elem, id) => {
        return (
          <Nav.Item key={elem.id} className={id === 0 ? 'customTabs__First' : ''}>
            <Nav.Link eventKey={elem.id} onClick={() => setProfile(elem.id)}>
              {elem.nombre}
            </Nav.Link>
          </Nav.Item>
        );
      });
    }
    return elemMap;
  };

  const listProfilePane = () => {
    let elemMap;
    if (Array.isArray(counter.profileReducer.profiles)) {
      elemMap = counter.profileReducer.profiles.map(elem => {
        return (
          <TabPane
            key={'panelTab' + elem.id + 'from' + profile}
            eventKey={elem.id}
            className='customTabs__Area'
          >
            <Select
              noOptionsMessage={() => 'No hay datos'}
              key={module.value ? 'selectmodule' + module.id : 'selectmodule'}
              defaultValue={module}
              onChange={e => {
                //setModule({...e});
                setModule(cloneDeep(e));
                setModuleBackup(e.value);
              }}
              options={optionModule()}
              placeholder='Seleccione artículos'
              styles={customSelectStyleSmall}
            />
            <h4 className='customTabs__thirdTitle'>Configuración de Permisos y Restricciones</h4>
            {listPermisions(module?.functionality_list)}
          </TabPane>
        );
      });
    }
    return elemMap;
  };

  const optionModule = () => {
    let tempModules = [];
    if (Array.isArray(counter.profileReducer.modules)) {
      tempModules = counter.profileReducer.modules.map(elem => {
        return {
          value: elem.id,
          label: elem.name,
          functionality_list: elem.functionality_list,
        };
      });
    }
    return tempModules;
  };

  const buttonCancel = () => {
    if (Array.isArray(optionModule())) {
      let elemFind = optionModule().find(module => module.value === moduleBackup);

      if (elemFind) {
        setModule(cloneDeep(elemFind));
      }
    }
  };

  const buttonOk = () => {
    let permission_changes = [];
    if (Array.isArray(module?.functionality_list)) {
      module.functionality_list.forEach(fl => {
        if (Array.isArray(fl?.permission)) {
          fl.permission.forEach(p => {
            permission_changes.push(p);
          });
        }
      });
    }

    dispatch(update_permissions(permission_changes, () => setTrigger(trigger + 1)));
  };

  return (
    <div className={tableStyles.container}>
      <h1 className={tableStyles.title}>Gestión de Permisos</h1>

      <GestionUsers></GestionUsers>

      <div className='customTabs__Border customTabs__Container'>
        <TabContainer id='left-tabs-example' defaultActiveKey={profile}>
          <Nav className='flex-row customTabs'>{listProfileTab()}</Nav>
          <TabContent className='customTabs__relative'>{listProfilePane()}</TabContent>
        </TabContainer>
      </div>

      <div className={`customTabs__Footer`}>
        <Button onClick={() => buttonOk()}>Guardar</Button>
        <Button onClick={() => buttonCancel()}>Limpiar</Button>
      </div>
    </div>
  );
}

export default GestionPermisos;
