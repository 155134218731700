import './App.css';
import Routes from './routes/Routes';

function App() {
  return (
   <Routes></Routes>
  );
}

export default App;
