import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { onCapitalLetter } from './../../../helpers/helpers';

import { PseudoSideBarAccordion } from '../../../OrderingModule/OrderingComponents/PseudoSideBarAccordion';

import '../../../OrderingModule/orderingStyles.css';
import { getEnvs, redirectOptions } from '../../../helpers';

const { URL_ADMINISTRATION_SPA, URL_PROTOCOL } = getEnvs();

export const ManagementHomeContent = ({ categoryDetail }) => {
  let history = useHistory();
  const storage = useSelector(state => state);
  const { all_categories } = useSelector(state => state?.loginReducer);

  const accountingModules = all_categories?.find(cat => cat?.name === 'Administración');
  const { modules } = accountingModules;

  const userData = storage.loginReducer.user_data;
  const currentAccount = storage.loginReducer.currentAccount;
  const fullName = `${userData.first_name || ''} ${userData.first_surname || ''} ${userData.second_surname || ''}`;
  const urlBase = `${URL_PROTOCOL}${URL_ADMINISTRATION_SPA}/validate?`;

  const idModule = `idModule=${categoryDetail.id}`;
  const token = `token=${storage.loginReducer.Authorization}`;
  const idAccount = `idAccount=${currentAccount?.id}`;
  const idUser = `idUser=${userData?.id}`;
  const idProfile = `idProfile=${currentAccount.profile.id}`;
  const userImage = `userImage=${userData.image}`;
  const fullNameUser = `fullNameUser=${fullName}`;

  const redirectUrl = `${urlBase}${idModule}&${idAccount}&${idUser}&${idProfile}&${userImage}&${fullNameUser}&${token}`;

  const redirect = (routePrefix = '', url = '') => {
    if (redirectOptions.includes(routePrefix)) {
      return redirectUrl + `&redirect=${url}`;
    }
    return null;
  };

  const contentDynamic = modules?.map(module => {
    const textJoin = onCapitalLetter({ text: module?.name });
    return {
      title: textJoin?.replace('Tm', 'Tabla maestra'),
      content: module?.functionalities?.map(subModule => {
        let subModuleName = onCapitalLetter({ text: subModule?.name });
        let returnValue = <></>;
        let permissionValidation = storage.loginReducer?.currentAccount?.profile?.permission?.find(
          x => x.functionality?.prefix === subModule?.prefix,
        )?.read;
        if (permissionValidation) {
          returnValue = {
            subtitle: subModuleName,
            action: () => {
              if (redirectOptions.includes(subModule?.prefix)) {
                window.location.href = redirect(subModule?.prefix, subModule?.url);
                return;
              }
              history.push(subModule?.url);
            },
          };
        }
        return returnValue;
      }),
    };
  });
  return (
    <div>
      {/* /* ----------------------------- PSEUDO-SIDEBAR--------------------------*/}
      {<PseudoSideBarAccordion ActiveBlue accordionItems={contentDynamic} />}
    </div>
  );
};
