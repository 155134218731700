import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import deepcopy from 'deepcopy';
import Loader from 'react-loader-spinner';

import atrasIcon from '../../assets/img/icons/atras-icon.svg';
import canecaGris from '../../assets/img/icons/canecaGris.svg';
import grayOutPut from '../../assets/img/icons/exitArrow.svg';
import Search from '../../assets/img/icons/lupa.svg';
import Agregar from '../../assets/img/icons/circleAdd.svg';

import { formatToRcSelect, isEmptyOrUndefined, message } from '../../helpers/helpers';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import {
  getWareHouseList,
  getArticlesInWarehouse,
  getOutputArticles,
  creatMovement,
  getInventoryAdjustmentType,
} from '../../actions/inventoryActions';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';
import { SkeletonTemplate } from '../Layouts/SkeletonTemplate';
import CustomNumUpDown from '../Layouts/CustomNumUpDown/CustomNumUpDown';

import { customSelectNewDark } from '../Layouts/react-select-custom';
import minModal from './minModal.module.scss';
import paginationStyles from '../Layouts/pagination.module.scss';

export const OutputAdjusment = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();

  const arrWarehouse = store.inventoryReducer.wareHouseList;
  const arrOutputArticles = store.inventoryReducer.outPutLotsArticles;
  const articlesWhLoading = store.inventoryReducer.articlesWhLoading;
  const isLoading = store.inventoryReducer.outputAdjsLoading;
  const arrArticles = store.inventoryReducer.articles;

  const [disableInput, setDisableInput] = useState(true);
  const [saveList, setSaveList] = useState([]);
  const [itemCheck, setItemCheck] = useState([]);
  const [selectedList, setselectedList] = useState([]);
  const [details, setDetails] = useState('');
  const [selectedList2, setSelectedList2] = useState([]);
  const [justification, setJustification] = useState('');
  const [search, setSearch] = useState({ search: '' });
  const [adjustmentType, setAdjustmentType] = useState(0);
  const [modalLotsControl, setmodalLotsControl] = useState({
    show: false,
  });
  const [trigger, setTrigger] = useState(1);
  const [filters, setFilters] = useState({
    id_warehouse: '',
    page: 1,
    perpage: 10,
    search: '',
  });
  const [lotsFilters, setLotsFilters] = useState({
    id_warehouse_articles: 0,
    page: 1,
    perpage: 10,
    trigger: false,
  });
  const [fillpTable, setFillpTable] = useState(false);
  const [modalArtControl, setmodalArtControl] = useState({
    show: false,
    showSecond: false,
    showThird: false,
    showFourth: false,
    showJustification: false,
  });

  useEffect(() => {
    dispatch(getWareHouseList(store.loginReducer.currentAccount.id));
    dispatch(
      getInventoryAdjustmentType({
        eaccount: store.loginReducer.currentAccount.id,
      }),
    );
  }, [dispatch, store.loginReducer.currentAccount.id]);

  useEffect(() => {
    if (filters.id_warehouse !== '') {
      dispatch(getArticlesInWarehouse(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters.page, trigger]);

  useEffect(() => {
    if (selectedList.length > 0) {
      dispatch(getOutputArticles(lotsFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotsFilters.trigger, lotsFilters.page]);

  let arrWh = formatToRcSelect(arrWarehouse, 'id_warehouse', 'description_warehouse');

  let inventoryAdjustmentOptions = [{ value: null, label: 'Seleccionar...' }];
  if (Array.isArray(store.inventoryReducer.inventoryAdjustmentType)) {
    store.inventoryReducer.inventoryAdjustmentType.forEach(item => {
      inventoryAdjustmentOptions.push({
        ...item,
        value: item.id,
        label: item.name,
      });
    });
  }

  const localStyles = {
    blueInputText: {
      color: '#005dbf',
    },
    footerStyles: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '310%',
    },
    noBorder: { border: 'none', padding: '8px' },
  };
  const isBoxSelected2 = item => {
    if (Array.isArray(selectedList2)) {
      let target = selectedList2.find(x => x.id === item.id);
      return !!target;
    }
  };

  const changeBoxSelected2 = item => {
    let tempSelected = selectedList2;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === item.id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== item.id);
      } else {
        let newChange = {
          ...item,
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setSelectedList2(tempSelected);
    }
  };

  const isBoxAllSelected2 = () => {
    let res = true;
    if (Array.isArray(arrOutputArticles) && Array.isArray(selectedList2)) {
      arrOutputArticles.forEach(item => {
        if (!selectedList2.find(itemb => item.id === itemb.id)) {
          res = false;
        }
      });
    }
    return res;
  };

  const changeBoxAllSelected2 = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = [];
      if (Array.isArray(arrOutputArticles) && Array.isArray(tempSelected)) {
        arrOutputArticles.forEach(x => {
          let newChange = {
            ...x,
          };
          tempSelected.push(newChange);
        });
        setSelectedList2(tempSelected);
      }
    } else {
      setSelectedList2([]);
    }
  };

  const editArticleAmmount = (id, ammount, og_val, item) => {
    if (ammount > og_val) {
      ammount = og_val;
    }
    let tempSelected = selectedList2;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.findIndex(x => x.id === item.id);
      if (target >= 0) {
        tempSelected[target].qty_received = Math.max(1, ammount);
      } else {
        let newChange = {
          ...item,
          qty_received: Math.max(1, ammount),
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setSelectedList2(tempSelected);
    }
  };

  const renderHeadersLots = [
    <th key={`Lots0`}>
      <input
        type='checkbox'
        name=''
        id=''
        checked={isBoxAllSelected2()}
        onChange={() => changeBoxAllSelected2(!isBoxAllSelected2())}
      />
    </th>,
    <th key={`Lots1`}>Vencimiento</th>,
    <th key={`Lots2`}>Lote</th>,
    <th key={`Lots2`}>Total</th>,
    <th key={`Lots3`}>Cantidad</th>,
  ];
  let curr = new Date();
  curr.setDate(curr.getDate());
  let date = curr.toISOString().substr(0, 10);

  const renderTableLots = () => {
    let table = [];
    let amount_received = 0;
    let num_lots = 0;
    if (!isEmptyOrUndefined(arrOutputArticles)) {
      if (arrOutputArticles.length > 0) {
        table = arrOutputArticles?.map((x, index) => {
          amount_received = amount_received + x.qty;
          num_lots = num_lots + 1;
          let foundlotvalue = selectedList2.find(item => item.id === x.id);
          return (
            <tr key={'lots' + index}>
              <td>
                <td className='col-md-1 text-center'>
                  <input
                    type='checkbox'
                    name=''
                    id=''
                    checked={isBoxSelected2(x)}
                    onChange={() => changeBoxSelected2(x)}
                  />
                </td>
              </td>
              <td>{moment(x.date_expiration).format('DD/MM/YYYY')}</td>
              <td style={{ textTransform: 'uppercase' }}>{x.name}</td>
              <td>{x.qty_received}</td>
              <td>
                <CustomNumUpDown
                  id={x.id}
                  value={foundlotvalue ? foundlotvalue.qty_received : x.qty_received}
                  onChange={(r_id, r_val) => editArticleAmmount(x.id, r_val, x.qty_received, x)}
                ></CustomNumUpDown>
              </td>
            </tr>
          );
        });
      }
    }
    return table;
  };

  const pTableListElem = () => {
    let elemMap;
    if (saveList !== undefined) {
      const elem2 = saveList;
      elemMap = elem2.map((elem, i) => {
        return pTableRenderElem(elem, i);
      });
    }
    return elemMap;
  };
  const listElem = () => {
    let elemMap;
    if (arrArticles !== undefined) {
      const elem2 = arrArticles;
      elemMap = elem2.map(elem => {
        return renderElement(elem);
      });
    }
    return elemMap;
  };
  const pTableRenderElem = (elem, i) => {
    return (
      <tr key={elem.code + 'articles'}>
        <td className='col-md-1 text-center'>
          <input
            key={'checkbox' + i + '-' + itemCheck.length}
            type='checkbox'
            checked={!!itemCheck.find(myitem => myitem === elem.id_article)}
            name=''
            id=''
            onChange={e => checkToDelete(e, elem, i)}
            // checked={isBoxSelected(elem)}
            // onChange={(e) => changeBoxSelected(elem)}
          />
        </td>
        <td className='col-md-1 text-center'>{i + 1}</td>
        <td className='col-md-2 text-center'>{elem.id_article}</td>
        <td className='col-md-2 text-start'>{elem.description_article}</td>
        <td className='cursorPointer'>
          <img src={Agregar} alt='btnAgregar' onClick={() => handleLots(elem)}></img>
        </td>
        <td className='col-md-2 text-center'>{elem.amount_received}</td>
      </tr>
    );
  };
  const checkToDelete = (e, elem) => {
    let checked = e.target.checked;

    let nueva = [...itemCheck];
    if (checked) {
      nueva.push(elem.id_article);
    } else {
      nueva = nueva.filter(x => x !== elem.id_article);
    }
    setItemCheck(nueva);
  };

  const deleteItems = () => {
    let templist = [...selectedList];
    templist = templist.filter(fullelement => itemCheck.indexOf(fullelement.id_article) === -1);
    setselectedList(templist);
    setSaveList(templist);
  };
  const renderElement = elem => {
    return (
      <tr key={elem.code}>
        <td className='col-md-1 text-center'>{elem.id_article}</td>
        <td className='col-md-6 text-start'>{elem.description_article}</td>
        <td className='col-md-1 text-center'>
          <input
            type='checkbox'
            name=''
            id=''
            checked={isBoxSelected(elem)}
            onChange={() => changeBoxSelected(elem)}
          />
        </td>
      </tr>
    );
  };

  const isBoxSelected = elem => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id_article === elem.id_article);
      return !!target;
    }
  };
  const changeBoxSelected = item => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id_article === item.id_article);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id_article !== item.id_article);
      } else {
        let newChange = {
          ...item,
          amount_received: item.amount,
          lot: item.name,
          date_expiry: item.date_expiration,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(arrArticles) && Array.isArray(selectedList)) {
      arrArticles.forEach(item => {
        if (!selectedList.find(itemb => item.id_article === itemb.id_article)) {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = selectedList;
      if (Array.isArray(arrArticles) && Array.isArray(tempSelected)) {
        arrArticles.forEach(x => {
          let found = selectedList.find(obj => obj.id_article === x.id_article);
          if (found?.id_article !== x.id_article) {
            let newChange = {
              ...x,
              amount_received: x.amount,
            };
            tempSelected.push(newChange);
          }
        });
        setselectedList(tempSelected);
      }
    } else {
      setselectedList([]);
    }
  };
  const pTableIsBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(selectedList) && Array.isArray(itemCheck)) {
      selectedList.forEach(item => {
        if (!itemCheck.find(itemb => item.id_article === itemb)) {
          res = false;
        }
      });
    }
    return res;
  };

  const pTableCheckThemAll = checkEmAll => {
    if (checkEmAll) {
      let tempSelected = [];
      if (Array.isArray(selectedList) && Array.isArray(tempSelected)) {
        selectedList.forEach(x => {
          let id = x.id_article;
          let newChange = id;
          tempSelected.push(newChange);
        });
        setItemCheck(tempSelected);
      }
    } else {
      setItemCheck([]);
    }
  };

  const btnCreatMovement = () => {
    setmodalArtControl({ ...modalArtControl, showJustification: false });
    dispatch(
      creatMovement({
        wm_account: store.loginReducer.currentAccount.id,
        wm_justification: justification,
        wm_observations: details,
        created_by: store.loginReducer.user_data.id,
        wm_movement_type: 'outputSetting',
        wm_origin: filters.id_warehouse,
        wm_status: 'legalized',
        articles: selectedList,
        adjustmentType: adjustmentType,
      }),
    );
  };
  const confirmation = () => {
    if (selectedList.length > 0) {
      customSwaltAlert({
        icon: 'warning',
        title: '¿Está seguro?',
        text: 'Revise la información',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          if (selectedList.length > 0) {
            selectedList.forEach(e => {
              if (e.lots?.length > 0) {
                e.lots?.forEach(() => {
                  setmodalArtControl({
                    ...modalArtControl,
                    showJustification: true,
                  });
                });
              } else {
                setmodalArtControl({
                  ...modalLotsControl,
                  showJustification: false,
                });
                return message(
                  'warning',
                  'Intenta de nuevo',
                  'Todos los artículos deben tener como minimo un lote',
                  undefined,
                  true,
                );
              }
            });
          }
        }
      });
    } else {
      return message(
        'warning',
        'Intenta de nuevo',
        'Debe seleccionar al menos un artículo',
        undefined,
        true,
      );
    }
  };
  const renderHeadersPTable = [
    <th className='col-md-1' style={{ borderTopLeftRadius: '10px' }} key={1}>
      <input
        type='checkbox'
        name=''
        id=''
        checked={pTableIsBoxAllSelected()}
        onChange={() => pTableCheckThemAll(!pTableIsBoxAllSelected())}
      />
    </th>,
    <th className='col-md-1' key={2}>
      Item
    </th>,
    <th className='col-md-1' key={3}>
      Código
    </th>,
    <th className='col-md-6' key={4}>
      Descripción de articulo
    </th>,
    <th className='col-md-1' key={5}>
      Lote
    </th>,
    <th style={{ borderTopRightRadius: '10px' }} key={6}>
      Cantidad
    </th>,
  ];
  const renderHeadersFirst = [
    <th style={{ borderTopLeftRadius: '10px' }} className='px-2' key={1}>
      Código
    </th>,
    <th className='text-start' key={2}>
      Nombre
    </th>,

    <th style={{ borderTopRightRadius: '10px' }} key={3}>
      <input
        type='checkbox'
        name=''
        id=''
        checked={isBoxAllSelected()}
        onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
      />
    </th>,
  ];

  /* -------------------------- HANDLE CHANGE SECTION ------------------------- */
  const handleBack = () => {
    history.push('/compras/registrarMovimiento');
  };
  const handleClickEntrybyStorage = () => {
    setmodalArtControl({
      ...modalArtControl,
      show: true,
    });
    dispatch(getArticlesInWarehouse(filters));
  };
  const handleBring = () => {
    let selListCopy = deepcopy(selectedList);
    setSaveList(selListCopy);
    setFillpTable(true);
    setDisableInput(false);
    setmodalArtControl({
      ...modalArtControl,
      showSecond: false,
      show: false,
    });
  };
  const handleLots = elem => {
    if (elem.lots?.length > 0) {
      setSelectedList2(elem.lots);
    }

    setLotsFilters({
      ...lotsFilters,
      trigger: !lotsFilters.trigger,
      id_warehouse_articles: elem.id_warehouse_articles,
    });
    setmodalLotsControl({
      show: true,
      product: elem.description_article,
      id: elem.id_article,
    });
    setmodalArtControl({
      ...modalArtControl,
      showLots: true,
    });
  };
  const handleItemsToAdd = id_article => {
    let temparray = deepcopy(selectedList);
    let target = temparray.findIndex(x => x.id_article === id_article);
    if (target >= 0) {
      temparray[target].lots = selectedList2;

      let newammount =
        selectedList2.length > 0 &&
        selectedList2.reduce((prev, current) => {
          return { qty_received: prev.qty_received + current.qty_received };
        });
      temparray[target].amount_received = newammount.qty_received;
      setselectedList(temparray);
      setSaveList(temparray);
    }

    setSelectedList2([]);

    setmodalArtControl({
      ...modalArtControl,
      showLots: false,
    });
  };
  const handleSearch = e => {
    e.preventDefault();
    setTrigger(trigger + 1);
    setFilters({
      ...filters,
      page: 1,
      search: search.search,
    });
  };
  return (
    <>
      {articlesWhLoading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}
      {isLoading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
      <ModalNew
        title='Justificación'
        show={modalArtControl.showJustification}
        btnYesName={justification !== '' && 'Guardar'}
        btnNoName={'Cancelar'}
        size='400'
        btnYesEvent={justification !== '' ? () => btnCreatMovement() : null}
        btnYesDisabled={false}
        onHide={() => setmodalArtControl({ ...modalArtControl, showJustification: false })}
        btnNoEvent={() => setmodalArtControl({ ...modalArtControl, showJustification: false })}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>¿Por qué es necesario hacer este ajuste?</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          onChange={e => setJustification(e.target.value)}
          value={justification}
          style={{ height: '4rem' }}
          className={`register-inputs text-secondary`}
        ></textarea>
      </ModalNew>

      {/* /* ------------------------------- First modal ------------------------------ */}
      <ModalNew
        size='700'
        btnYesEvent={selectedList.length > 0 ? () => handleBring() : null}
        title='Artículos'
        btnYesName={'Agregar'}
        show={modalArtControl.show}
        onHide={() =>
          setmodalArtControl({
            ...modalArtControl,
            show: false,
          })
        }
      >
        <Row className='d-flex'>
          <Col xs={5}>
            <form onSubmit={e => handleSearch(e)}>
              <p className={tableStyles.crudModalLabel}>&nbsp;</p>
              <input
                placeholder='Escribir...'
                className='register-inputs text-secondary'
                name='id_consumption_center'
                type='text'
                value={search.search}
                onChange={e => setSearch({ ...search, search: e.target.value })}
              />
            </form>
          </Col>

          <Col xs={1} className={`pt-3`} style={{ 'align-self': 'end' }}>
            <div>
              <img
                src={Search}
                alt='Search icon'
                className='cursorPointer'
                height={24}
                onClick={e => handleSearch(e)}
              />
            </div>
          </Col>
        </Row>

        <GenericTableNew dark={true} headers={renderHeadersFirst}>
          {listElem()}
        </GenericTableNew>

        <div className={`${paginationStyles.wrapper}`} style={localStyles.endPagination}>
          <p className={paginationStyles.paginationText}>
            Pag. {store.inventoryReducer.articles ? filters.page : ''}
            {' de '}
            {Math.ceil(store.inventoryReducer.articles_total / filters.perpage)
              ? Math.ceil(store.inventoryReducer.articles_total / filters.perpage)
              : ''}{' '}
            ({store.inventoryReducer.articles_total} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={store.inventoryReducer.articles_total}
            pageRangeDisplayed={5}
            onChange={e => setFilters({ ...filters, page: e })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </ModalNew>
      {/* /* ------------------------------ Second modal ------------------------------ */}

      {/* MODAL DE LOTES*/}
      <ModalNew
        title={'Lotes'}
        show={modalArtControl.showLots}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        btnYesEvent={() => handleItemsToAdd(modalLotsControl.id)}
        btnNoEvent={() =>
          setmodalArtControl({
            ...modalArtControl,
            showLots: false,
          })
        }
        size='sz'
        onHide={() => {
          setmodalArtControl({
            ...modalArtControl,
            showLots: false,
          });
          setSelectedList2([]);
        }}
      >
        <div id='arriba' className={`${minModal.backgroundModal} ${minModal.paddingButton}`}>
          {/* ---------------------------- ROW 1 ---------------------------------- */}
          <label className={`${tableStyles.subtitle} `}> Información del producto  </label>
          <div className='d-flex'>
            <div className={` ${tableStyles.ItemSmall}`}>
              <p className={'text-secondary'}>Producto</p>
              <input
                className='register-inputs text-secondary'
                // className={` ${tableStyles.registerInputs}`}
                name='auction_title'
                type='text'
                value={modalLotsControl.product}
                readOnly
              />
            </div>
          </div>
        </div>
        {/* ============================= ROW 2 =================================== */}
        <div id='arriba' className={`${tableStyles.paddingButton}`}>
          <GenericTableNew headers={renderHeadersLots}>{renderTableLots()}</GenericTableNew>
          <div className={`${paginationStyles.wrapper}`} style={localStyles.endPagination}>
            <p className={paginationStyles.paginationText}>
              Pag. {store.inventoryReducer.outPutLotsArticles ? lotsFilters.page : ''}
              {' de '}
              {Math.ceil(store.inventoryReducer.lotsTotal / lotsFilters.perpage)
                ? Math.ceil(store.inventoryReducer.lotsTotal / lotsFilters.perpage)
                : ''}{' '}
              ({store.inventoryReducer.lotsTotal} encontrados)
            </p>
            <Pagination
              activePage={lotsFilters.page}
              itemsCountPerPage={lotsFilters.perpage}
              totalItemsCount={store.inventoryReducer.lotsTotal}
              pageRangeDisplayed={2}
              onChange={e => setLotsFilters({ ...lotsFilters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </ModalNew>

      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <Row>
          <Col xs={10}>
            <h1 className={tableStyles.title}>
              <img
                onClick={() => handleBack()}
                src={atrasIcon}
                className={`mr-2 mb-2 cursorPointer`}
                alt=''
              />
              Ajuste de salida
              <img width='3.5%' src={grayOutPut} className={`ml-3`} alt='' />
            </h1>
          </Col>
        </Row>
        <Row className=' d-flex'>
          <Col xs={3} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>Almacén</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={arrWh}
              defaultValue={filters.id_warehouse}
              onChange={e => {
                setselectedList([]);
                setSaveList([]);
                setFilters({ ...filters, id_warehouse: e.value });
              }}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3} className='align-self-end'>
            <p className={tableStyles.crudModalLabel}>Tipo de Ajuste</p>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={inventoryAdjustmentOptions}
              className={'text-secondary'}
              value={inventoryAdjustmentOptions.find(x => x.value === filters.id_adjustment_type)}
              onChange={e => {
                setSaveList([]);
                setAdjustmentType(e.value);
                setselectedList([]);
              }}
              placeholder='Seleccionar...'
              styles={customSelectNewDark}
            />
          </Col>
          <Col xs={3}>
            <p className={tableStyles.crudModalLabel}>Traer artículos</p>
            <input
              placeholder={'Buscar...'}
              onClick={() => handleClickEntrybyStorage()}
              className={` ${tableStyles.articleSearch} register-inputs cursorPointer`}
              name='requisition_date'
              type='text'
              disabled={filters.id_warehouse === '' ? true : false}
            />
          </Col>
          <Col xs={3}>
            <p className={`${tableStyles.crudModalLabel}`}>Fecha</p>
            <input
              disabled={disableInput}
              className='register-inputs text-secondary'
              name='requisition_date'
              type='date'
              defaultValue={date}
            />
          </Col>
          {fillpTable && (
            <Col xs={12} className='d-flex' style={{ justifyContent: 'flex-end' }}>
              <img
                width='1.5%'
                src={canecaGris}
                className='cursorPointer'
                alt='btnAgregar'
                onClick={e => deleteItems(e)}
              ></img>
            </Col>
          )}
          <Col xs={12}>
            {fillpTable ? (
              <>
                <GenericTableNew dark={true} headers={renderHeadersPTable}>
                  {pTableListElem()}
                </GenericTableNew>
                <Row className={`w-100 m-0`}>
                  <Col xs={9} className={`${tableStyles.clearBlueBg} px-3`}>
                    <p className={`${tableStyles.crudModalLabel} pt-2`}>Observaciones</p>
                    <textarea
                      onChange={e => setDetails(e.target.value)}
                      style={{ height: '3rem' }}
                      rows='10'
                      cols='10'
                      className='register-inputs text-secondary'
                    ></textarea>
                  </Col>
                </Row>

                <div className={`customTabs__Footer mt-5`}>
                  <Button
                    onClick={() => confirmation()}
                    className={tableStyles.btnPrimary}
                    disabled={!(itemCheck.length > 0)}
                  >
                    Guardar
                  </Button>
                  <Button
                    // onClick={props.hideForm}
                    variant='outline-primary'
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <SkeletonTemplate></SkeletonTemplate>
                <div className={`customTabs__Footer mt-5`}>
                  <Button className={tableStyles.btnPrimary} disabled>
                    Guardar
                  </Button>
                  <Button
                    onClick={() => handleBack()}
                    variant='outline-primary'
                    className={tableStyles.btnSecondary}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
