import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Search from '../../assets/img/icons/lupa.svg';
import filtroLupa from '../../assets/img/filtroLupa.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import GenericTableNew from '../Layouts/GenericTableNew';
import { useSelector, useDispatch } from 'react-redux';
import 'reactjs-popup/dist/index.css';
import '../TableUsers/tableUserStyle.scss';
import tableStyles from '../Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../components/Layouts/react-select-custom';
import paginationStyles from '../Layouts/pagination.module.scss';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import CustomPopupExtend from '../Popup/customPopUpExtends';
import Loader from 'react-loader-spinner';
import {
  changeResolutionBillStatus,
  getBillingResolutionList,
  updateResolution,
} from '../../actions/bankNoteActions';
import { customSwaltAlert } from '../../helpers/customSwaltAlert';
import ModalNew from '../Layouts/ModalNew';

export const BillingResolution = () => {
  const store = useSelector(state => state);
  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'reFac',
  );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const billResolutionList = store.bankNoteReducer?.billingResolutionList;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    eaccount: idEnterprise,
    search: '',
    status: '',
  });

  const [modal, setModal] = useState({ isOpen: false });

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/administracion/inicio');
    }
  }, [history, myPermission?.read]);

  useEffect(() => {
    dispatch(
      getBillingResolutionList({
        page: 1,
        perpage: 10,
        eaccount: idEnterprise,
        search: '',
        status: '',
      }),
    );
  }, [dispatch, idEnterprise]);

  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col text-center px-2'>
          {elem.type === 'enabled' ? 'Habilita' : 'Autoriza'}
        </td>

        <td className='col text-center px-2'>{elem.prefix}</td>

        <td className='col text-start px-2'>{elem.nbr}</td>

        <td className='col text-center px-2'>
          {elem.typeBill?.name}
        </td>

        <td className='col text-center px-2'>{elem.origin}</td>

        <td className='col text-center px-2'>{elem.end}</td>

        <td className='col text-center px-2'>{elem.sequence}</td>

        <td className='col text-center px-2'>
          <div className={elem.status === 'Activo' ? tableStyles.greenState : tableStyles.redState}>
            {elem.status === 'Inactivo'
              ? elem.status
              : elem?.type === 'allow'
                ? 'Autoriza'
                : 'Habilita'}
          </div>
        </td>

        <td className='col text-center px-2'>
          {elem.status === 'Activo' ? (
            <div>
              {myPermission?.edit && (
                <CustomPopupExtend
                  noHover
                  position='right center'
                  isEnabled={elem.status === 'Activo'}
                  enableText={elem.status === 'Activo' ? 'Inactivar' : 'Activar'}
                  triggerSrc={threeDots}
                  showEdit={true}
                  editClickEvent={() =>
                    history.push({
                      pathname: '/administracion/Facturacion/ResolucionesFacturacion/nueva',
                      state: {
                        billInfo: elem,
                        isEnable: true,
                      },
                    })
                  }
                  editText='Habilitar'
                  showEnable={myPermission?.edit}
                  enableClickEvent={() =>
                    setModal(state => ({
                      ...state,
                      isOpen: true,
                      data: { ...elem },
                    }))
                  }
                />
              )}
            </div>
          ) : (
            <img
              className='cursorPointer'
              src={filtroLupa}
              alt='ver detalle'
              onClick={() => {
                history.push({
                  pathname: '/administracion/Facturacion/ResolucionesFacturacion/nueva',
                  state: {
                    billInfo: elem,
                    isDetail: true,
                  },
                });
              }}
            />
          )}
        </td>
      </tr>
    );
  };

  const listElem = () => {
    let elemMap;
    if (billResolutionList !== undefined) {
      const elem2 = billResolutionList;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };

  const renderHeaders = [
    <th key={1} className='text-center'>
      &nbsp; Tipo
    </th>,
    <th key={2} className='text-center'>
      &nbsp; Prefijo
    </th>,
    <th key={3} className='text-start'>
      # Resolución
    </th>,
    <th key={3} className='text-center'>
      Facturación
    </th>,
    <th key={4} className='text-center'>
      Primera factura
    </th>,
    <th key={5} className='text-center'>
      Última factura
    </th>,
    <th key={6} className='text-center'>
      Num. Actual
    </th>,
    <th key={7} className='text-center w100'>
      Estado
    </th>,
    <th key={8}>&nbsp;</th>,
  ];

  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    dispatch(getBillingResolutionList({ ...filters, page: 1 }));
    setIsLoading(false);
  };

  const onSubmitComment = () => {
    if (!modal?.data?.comments?.trim()) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'El comentario es obligatorio',
        showCancelButton: false,
      });
    }

    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se inactivará la resolución: ${modal?.data?.nbr}`,
      showCancelButton: true,
    })
      .then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          setIsLoading(true);

          const id = modal?.data?.id;
          const comments = modal?.data?.comments;

          const resultComment = await updateResolution(
            { eaccount: idEnterprise, id, comments },
            token,
          );

          const resultChangeStatus = await changeResolutionBillStatus(
            {
              id,
              eaccount: idEnterprise,
            },
            token,
          );

          if (resultComment.success && resultChangeStatus?.success) {
            customSwaltAlert({
              icon: 'success',
              title: 'Actualizado exitosamente',
              text: `Se ha inactivado la resolución: ${modal?.data?.nbr}`,
              showCancelButton: false,
            }).finally(() => {
              setModal({ isOpen: false });
              dispatch(getBillingResolutionList({ ...filters, page: 1 }));
              setIsLoading(false);
              setFilters(state => ({
                ...state,
                page: 1,
              }));
            });
          } else {
            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: `Se ha producido un error al momento de Inactivar la resolución: ${modal?.data?.nbr}`,
              showCancelButton: false,
            });
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      {store?.bankNoteReducer?.billResList_loading ? (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      ) : (
        <></>
      )}

      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <h1 className={tableStyles.title}>Resoluciones de facturación</h1>
          {myPermission?.read && (
            <div className='row'>
              <div className='col-2'>
                <p className={`${tableStyles.crudModalLabel}`}>Estado</p>
              </div>

              <div className='col-2'>
                <p className={`${tableStyles.crudModalLabel}`}>Tipo</p>
              </div>
            </div>
          )}

          <Row className='align-items-center'>
            {myPermission?.read && (
              <>
                <Col className='d-block' xs={2}>
                  <form onSubmit={e => handleSearch(e)}>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      onChange={e => {
                        setFilters({ ...filters, status: e.value });
                        setIsLoading(true);
                        dispatch(getBillingResolutionList({ ...filters, status: e.value }));
                        setIsLoading(false);
                      }}
                      options={[
                        { value: '', label: 'Seleccionar...' },
                        { value: 'Habilitado', label: 'Activo' },
                        { value: 'Deshabilitado', label: 'Inactivo' },
                      ]}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNewDark}
                    ></Select>
                  </form>
                </Col>

                <Col className='d-block' xs={2}>
                  <form onSubmit={e => handleSearch(e)}>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      onChange={e => {
                        setFilters({ ...filters, type: e.value });
                        setIsLoading(true);
                        dispatch(getBillingResolutionList({ ...filters, type: e.value }));
                        setIsLoading(false);
                      }}
                      options={[
                        { value: '', label: 'Seleccionar...' },
                        { value: 'enabled', label: 'Habilita' },
                        { value: 'allow', label: 'Autoriza' },
                      ]}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={customSelectNewDark}
                    ></Select>{' '}
                  </form>
                </Col>

                <Col className='d-block' xs={5}>
                  <div className=''>
                    <form onSubmit={e => handleSearch(e)}>
                      <input
                        className={tableStyles.searchUsersAlt}
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                        name='search'
                        type='text'
                        placeholder='Buscar...'
                        style={{
                          marginTop: '2px',
                          height: '25px',
                        }}
                      />
                      <img
                        src={Search}
                        className={`${tableStyles.iconSvg} ${tableStyles.iconSvgMargin}`}
                        width={'30px'}
                        style={{ marginBottom: '2px', width: '1.4rem' }}
                        alt='Search icon'
                        onClick={e => handleSearch(e)}
                      />
                    </form>
                  </div>
                </Col>
              </>
            )}

            {myPermission?.create && (
              <Col
                style={{ marginLeft: 'auto' }}
                xs={3}
                onClick={() =>
                  history.push({
                    pathname: '/administracion/Facturacion/ResolucionesFacturacion/nueva',
                  })
                }
              >
                <div
                  style={{ display: 'flex', marginLeft: 'auto' }}
                  className={`w210  groupBounding2 groupAddButton align-items-center`}
                >
                  <label htmlFor='newAccident' className='darkGray fw-bold px-2'>
                    Nueva resolución
                  </label>

                  <button className='addCheckButton mx-2' style={{ marginBottom: '1.5px' }} />
                </div>
              </Col>
            )}
          </Row>

          {myPermission?.read && (
            <>
              <GenericTableNew
                fontFamilyTable={'fontFamilyTable'}
                headers={renderHeaders}
                dark={true}
              >
                {listElem()}
              </GenericTableNew>

              <div className={paginationStyles.wrapper}>
                <p className={`${paginationStyles.paginationText} text-secondary`}>
                  Pag. {store.bankNoteReducer?.billingResolutionList ? filters.page : ''}
                  {' de '}
                  {Math.ceil(store.bankNoteReducer.rowTotal / filters.perpage)
                    ? Math.ceil(store.bankNoteReducer.rowTotal / filters.perpage)
                    : ''}{' '}
                  ({store.bankNoteReducer.rowTotal} encontrados)
                </p>
                <Pagination
                  activePage={filters.page}
                  itemsCountPerPage={filters.perpage}
                  totalItemsCount={store.bankNoteReducer.rowTotal}
                  pageRangeDisplayed={5}
                  onChange={e => {
                    setFilters({ ...filters, page: e });
                    dispatch(getBillingResolutionList({ ...filters, page: e }));
                  }}
                  itemClassPrev={paginationStyles.itemClassPrev}
                  itemClassNext={paginationStyles.itemClassNext}
                  itemClassFirst={paginationStyles.itemClassFirst}
                  itemClassLast={paginationStyles.itemClassLast}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            </>
          )}

          <ModalNew
            title='Comentario de inactivación'
            show={modal?.isOpen}
            btnYesName={'Aceptar'}
            btnNoName={'Cancelar'}
            onHide={() => setModal({ isOpen: false })}
            btnNoEvent={() => setModal({ isOpen: false })}
            btnYesEvent={onSubmitComment}
          >
            <textarea
              placeholder={'Escribir...'}
              value={modal?.data?.comments || ''}
              className={`register-inputs text-secondary py-1 px-2`}
              style={{
                minHeight: '6rem',
                maxHeight: '10rem',
                outline: 'none',
                borderColor: '#01a0f6',
              }}
              onChange={({ target }) =>
                setModal(state => ({
                  ...state,
                  data: {
                    ...state.data,
                    comments: target?.value,
                  },
                }))
              }
            />
          </ModalNew>
        </div>
      </div>
    </>
  );
};
