import { 
    BILL_PAY_GET_PROVIDERS,
    BILL_PAY_GET_NIIF,
    GET_PAYORDERS,
    GET_PO_PINVOICES,
    GET_PO_MOVCONTABLES,
    PAY_ORDER_PAY,
    PAGO_FACTURAS_CAUSADAS,
    PAGO_FACTURAS_CAUSADAS_RESET,
} from "../actions/actionTypes";
  
  const initialState = {
    providers:[],
    niif:[],
    payorders:[],
    payorders_loading: false,

    pinvoices:[],
    pinvoices_loading:false,

    movcontables:[],
    movcontables_loading:false,

    pay_order_pay_loading: false,
    pago_facturas_causadas_loading:false,
  };
  export const billPaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case BILL_PAY_GET_PROVIDERS:
            return {
                ...state,
                providers: action.providers,
            };
        
        case BILL_PAY_GET_NIIF:
            return {
                ...state,
                niif: action.niif,
            };
        case GET_PAYORDERS:
            return {
                ...state,
                payorders: action.payorders,
                payorders_loading: action.loading,
            };
        case GET_PO_PINVOICES:
            return {
                ...state,
                pinvoices: action.pinvoices,
                pinvoices_loading: action.loading,
            };
        case GET_PO_MOVCONTABLES:
            return {
                ...state,
                movcontables: action.movcontables,
                movcontables_loading: action.loading,
            };
        case PAY_ORDER_PAY:
            return {
                ...state,
                pay_order_pay_loading: action.loading,
            };
        case PAGO_FACTURAS_CAUSADAS:
            return {
                ...state,
                pago_facturas_causadas_loading: action.loading,
            };
        case PAGO_FACTURAS_CAUSADAS_RESET:
            return {
                ...state,
                pinvoices: [],
                pinvoices_loading: false,
                movcontables: [],
                movcontables_loading: false,
            };

        default:
            return state;
    }
  };
  