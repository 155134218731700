import {
  CUENTAS_GET,

  CUENTAS_EDIT,
  CUENTAS_POST,
  USERS_GET

} from "../actions/actionTypes";

const initialState = {
  cuentas: [{ id: 1 }],
  total: 0,
  displayError: false,
  error: null,
  Authorization: "",
  getAccLoading: false,
  usuarios: [],
  usuariosLoad: false,
};
export const cuentasReducer = (state = initialState, action) => {
  switch (action.type) {
    case CUENTAS_GET:
      return {
        ...state,
        cuentas: action.payload,
        total: action.row_total,
        error: null,
        Authorization: action.Authorization,
        getAccLoading: action.loading,
      };

    case CUENTAS_EDIT:
      return {
        ...state,
        displayError: false,
        error: null,
        Authorization: action.Authorization,
      };

    case CUENTAS_POST:
      return {
        ...state,
        displayError: false,
        error: null,
        Authorization: action.Authorization,
      };

    case USERS_GET:
      return {
        ...state,
        usuarios: action.payload,
        usuariosLoad: action.loading,
      };
  
    default:
      return state;
  }
};
