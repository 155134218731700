import Select from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import close from '../../../assets/img/icons/close-blue.svg';
import { Text } from '../../../components/UI/atoms';
import { useTheme } from 'styled-components';

export const CardCollectionBase = ({
  onChange,
  handleRemoveDeposit,
  options,
  hasDelete,
  dataOptions,
  className,
  col = 12,
  isDisabled = false,
}) => {
  const { colors } = useTheme();

  const optionCollections = [];

  options?.forEach(el => {
    if (!dataOptions?.includes(el?.value)) {
      optionCollections?.push(el);
    }
  });

  return (
    <Row className=' mt-2' style={{ maxWidth: '1100px' }}>
      <Col xs={col} className=''>
        <div
          className={`${tableStyles.addCardWhite} d-flex my-3 py-3 px-2 ${className ? className : ''}`}
        >
          {!!dataOptions.length && (
            <Col xs={2} className='align-self-center'>
              <div className='d-flex align-self-center justify-content-center'>
                <div
                  className={`${tableStyles.addCardWhite} d-flex justify-content-center align-self-center m-0 my-0`}
                  style={{ width: '100px', height: '72px', alignItems: 'center', display: 'flex' }}
                ></div>
              </div>
            </Col>
          )}

          <Col xs={5} className='px-2'>
            <Form.Group className='mb-4 text-start' controlId=''>
              <Text
                title={'Forma de pago'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <Select
                isDisabled={isDisabled}
                noOptionsMessage={() => 'No hay datos'}
                className='text-secondary'
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={onChange}
                options={optionCollections}
              />
            </Form.Group>

            <Form.Group className=' text-start' controlId=''>
              <Form.Control className={`ord-roundInput `} type='number' disabled />
            </Form.Group>
          </Col>

          <Col xs={5} className='px-2'>
            <Form.Group className='mb-4 text-start' controlId=''>
              <Text
                title={<>&nbsp;</>}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
              />
              <Form.Control className={`ord-roundInput `} type='number' disabled />
            </Form.Group>

            <Form.Group className=' text-start' controlId=''>
              <Form.Control className={`ord-roundInput `} type='number' disabled />
            </Form.Group>
          </Col>
        </div>
      </Col>

      {hasDelete && (
        <Col xs={1} style={{ display: 'flex', alignItems: 'center' }}>
          <span className={tableStyles.hoverClose} onClick={handleRemoveDeposit}>
            <img
              src={close}
              alt='cerrar'
              style={{
                padding: '5px',
                boxShadow: '0px 0px 2px #ccc',
                background: 'white',
                borderRadius: '100%',
                cursor: 'pointer',
              }}
            />
          </span>
        </Col>
      )}
    </Row>
  );
};
