
import {
    GET_COMPANIES,
    GET_CONTRACTS_IN_BILLING,
    GET_BILLING_ACOUNTABLE,
    GET_ONE_COMPANIES,
    GET_SERVICES_OF_CONTRACTS,
    
    GET_INVOICE_TYPE,
    GET_BILLING_HISTORY,
    GET_BILLING_HISTORY_STATUS,

    
    GET_RECEIPT_RECORDS,
    GET_RECEIPT_RECORDS_ONE,
    GET_SITES_IN_BILLING,
    GET_ONE_CONTRACT,
    INVOICES_TYPES,
    INVOICES_TO_SENT,
    OBJECTION_TYPES,
    INFO_NOTES,
    NOTE_TYPES,
    OBJECTED_BY_CLIENT,
    STATUS_HISTORY,
    GLOSS_HISTORY,
    GLOSS_HISTORY_DET,
    GET_TYPE_SERVICES_IN_ADM,
    GET_LIST_STATUS,
    GET_SERVICES_IN_ADM,
    GET_IVA,
    GET_ONE_SERVICES_IN_ADM,
    GET_TYPES_CONTRACTS,
    GET_REGION,
    GET_CIUU,
    GET_PAY_CONDITIONS,
    GET_PAY_METHODS,
    GET_DIVISA,
    GET_ONE_CONTRACT_IN_ADM,
    GET_CUPS,
    GET_CAUSES,
    GENERATE_INVOICE_LOADING,
    GENERATE_CAPTION_LOADING,
    SEND_INVOICES_LOADING,
    CREATE_NOTE_LOADING
    
}   from "../actions/actionTypes";
  
const initialState = {
    companies:[],
    contracts:[],

    
    acountable:[],
    one_company:[],
    services:[],

    billing_history:[],
    billing_history_loading: false,
    billing_history_total: 0,
    
    receipt_record: [],
    receipt_record_loading: false,
    receipt_record_total: 0,

    billing_history_status: [],
    invoice_types:[],

    contracts_total:0,

    sites:[],
    one_contract:[],
    invoices_types:[],
    invoices_to_send:[],
    invoices_to_send_total:0,
    objection_types:[],
    info_notes:[],
    note_types:[],
    objected_by_client:[],
    status_in_history:[],
    gloss_history:[],
    gloss_history_total:0,
    gloss_history_detail:[],
    get_types_services:[],
    status:[],
    services_ADM:[],
    services_ADM_total:0,
    iva:[],
    one_service:[],
    types_contracts:[],
    regions:[],
    ciuu:[],
    pay_conditions:[],
    pay_methods:[],
    divisa:[],
    one_contract_adm:[],
    cups:[],
    causes:[],
    loading_generate_invoice:false,
    loading_generate_caption:false,
    loading_send_invoices: false,
    loading_gloss_history: false,
    loading_gloss_history_det: false,
    loading_create_invoice: false,
    invoices_to_send_loading: false

};

export const billingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANIES:
            return {
                ...state,
                companies: action.payload,
            };
        case GET_CONTRACTS_IN_BILLING:
            return {
                ...state,
                contracts: action.payload,
                contracts_total: action.total
            };
        case GET_BILLING_ACOUNTABLE:
            return {
                ...state,
                acountable: action.payload,
            }
        case GET_ONE_COMPANIES:
            return {
                ...state,
                one_company: action.payload,
            };
        case  GET_SERVICES_OF_CONTRACTS:
            return {
                ...state,
                services: action.payload,
            };
        case GET_BILLING_HISTORY:
            return {
                ...state,
                billing_history: action.payload,
                billing_history_loading: action.loading,
                billing_history_total: action.total,
            };
        
        case GET_BILLING_HISTORY_STATUS:
            return {
                ...state,
                billing_history_status: action.payload,
            };
        case GET_INVOICE_TYPE:
            return {
                ...state,
                invoice_types: action.payload,
            };
        case GET_RECEIPT_RECORDS:
            return {
                ...state,
                receipt_record: action.payload,
                receipt_record_loading: action.loading,
                receipt_record_total: action.total,
            };
        case GET_RECEIPT_RECORDS_ONE:
            return {
                ...state,
                receipt_chosen: action.payload,
                receipt_chosen_loading: action.loading,
            };


        case GET_SITES_IN_BILLING:
            return {
                ...state,
                sites: action.payload,
            };
        case GET_ONE_CONTRACT:
            return {
                ...state,
                one_contract: action.payload,
            };
        case INVOICES_TYPES:
            return {
                ...state,
                invoices_types: action.payload,
            };
        case INVOICES_TO_SENT:
            return {
                ...state,
                invoices_to_send: action.payload,
                invoices_to_send_total: action.total,
                invoices_to_send_loading : action.loading

            };
        case OBJECTION_TYPES:
            return {
                ...state,
                objection_types: action.payload,
            };
        case INFO_NOTES:
            return {
                ...state,
                info_notes: action.payload,
            };
        case NOTE_TYPES:
            return {
                ...state,
                note_types: action.payload,
            };
        case OBJECTED_BY_CLIENT:
            return {
                ...state,
                objected_by_client: action.payload,
            };
        case STATUS_HISTORY:
            return {
                ...state,
                status_in_history: action.payload,
            };
        case GLOSS_HISTORY:
            return {
                ...state,
                gloss_history: action.payload,
                gloss_history_total: action.total,
                loading_gloss_history: action.loading
            };
        case GLOSS_HISTORY_DET:
            return {
                ...state,
                gloss_history_detail: action.payload,
                loading_gloss_history_det: action.loading
            };
        case GET_TYPE_SERVICES_IN_ADM:
            return {
                ...state,
                get_types_services: action.payload,
            };
        case GET_LIST_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case GET_SERVICES_IN_ADM:
            return {
                ...state,
                services_ADM: action.payload,
                services_ADM_total: action.total
            };
        case GET_IVA:
            return {
                ...state,
                iva: action.payload,
            };
        case GET_ONE_SERVICES_IN_ADM:
            return {
                ...state,
                one_service: action.payload,
            };
        case GET_TYPES_CONTRACTS:
            return {
                ...state,
                types_contracts: action.payload,
            };
        case GET_REGION:
            return {
                ...state,
                regions: action.payload,
            };
        case GET_CIUU:
            return {
                ...state,
                ciuu: action.payload,
            };
        case GET_PAY_CONDITIONS:
            return {
                ...state,
                pay_conditions: action.payload,
            };
        case GET_PAY_METHODS:
            return {
                ...state,
                pay_methods: action.payload,
            };
        case GET_DIVISA:
            return {
                ...state,
                divisa: action.payload,
            };
        case GET_ONE_CONTRACT_IN_ADM:
            return {
                ...state,
                one_contract_adm: action.payload,
            };
        case GET_CUPS:
            return {
                ...state,
                cups: action.payload,
            };
        case GET_CAUSES:
            return {
                ...state,
                causes: action.payload,
            };
        case GENERATE_INVOICE_LOADING:
            return {
                ...state,
                loading_generate_invoice: action.loading,
            };
        case GENERATE_CAPTION_LOADING:
            return {
                ...state,
                loading_generate_caption: action.loading,
            };
        case SEND_INVOICES_LOADING:
            return {
                ...state,
                loading_send_invoices: action.loading,
            };
        case CREATE_NOTE_LOADING:
            return {
                ...state,
                loading_create_invoice: action.loading,
            };
        default:
            return state;
    }
};
  
