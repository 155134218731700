//All actions for Login component
import {
  COLLECTION_GET,
  GET_LIST_NIIF,
  GET_ALL_COLLECTION,
  COLLECTION_LOAD,
  GET_LIST_LOAD,
  COLLECTION_GET_ONE,
  COLLECTION_LOAD_ONE,
} from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const collection_get = objFilters => (dispatch, getState) => {
  dispatch({ type: COLLECTION_LOAD, loading: true });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: COLLECTION_GET,
        results: results,
        row_total: row_total,
      });
      dispatch({ type: COLLECTION_LOAD, loading: false });
    })
    .catch(err => {
      console.error(err);
      dispatch({ type: COLLECTION_LOAD, loading: false });
    });
};

export const collection_get_one = objFilters => (dispatch, getState) => {
  dispatch({ type: COLLECTION_LOAD_ONE, loading: true });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: COLLECTION_GET_ONE,
        results: results,
        row_total: row_total,
      });
      dispatch({ type: COLLECTION_LOAD_ONE, loading: false });
    })
    .catch(err => {
      console.error(err);
      dispatch({ type: COLLECTION_LOAD_ONE, loading: false });
    });
};

export const getListNiifAcount = objFilters => (dispatch, getState) => {
  dispatch({ type: GET_LIST_LOAD, loading: true });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niif?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_LIST_NIIF,
        results: results,
        row_total: row_total,
      });
      dispatch({ type: GET_LIST_LOAD, loading: false });
    })
    .catch(err => {
      console.error(err);
      dispatch({ type: GET_LIST_LOAD, loading: false });
    });
};

export const getAllCollection = () => (dispatch, getState) => {
  dispatch({ type: COLLECTION_LOAD, loading: true });
  const token = getState().loginReducer.Authorization;

  fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { data } = res;
      let costc = [];
      for (let m in data) {
        if (data[m].active === true) {
          costc.push(data[m]);
        }
      }
      dispatch({
        type: GET_ALL_COLLECTION,
        payload: costc,
      });
      dispatch({ type: COLLECTION_LOAD, loading: false });
    })
    .catch(() => {
      dispatch({ type: COLLECTION_LOAD, loading: false });
    });
};

export const collection_edit_active =
  (codigo, data, description, executeThisAfter) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let status = data.active ? 'habilitará' : 'deshabilitará';
    let status2 = data.active ? 'habilitado' : 'deshabilitado';

    customSwaltAlert({
      title: `¿Está seguro?`,
      icon: 'warning',
      text: `Se ${status} el tipo de recaudo: ${description}`,
      showCancelButton: true,
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        dispatch({ type: COLLECTION_LOAD, loading: true });
        fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/`, {
          method: 'PUT',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token,
          },
        })
          .then(response => response.json())
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              return customSwaltAlert({
                icon: 'success',
                title: `Actualizado exitosamente`,
                text: `Se ha ${status2} el tipo de recaudo: ${description}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            } else {
              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: `${res.message}`,
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            }
            dispatch({ type: COLLECTION_LOAD, loading: false });
          })
          .then(() => {
            executeThisAfter();
          })
          .catch(err => {
            console.error(err);

            customSwaltAlert({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: 'No se pudo cambiar el estado',
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });

            dispatch({ type: COLLECTION_LOAD, loading: false });
          })
          .finally(() => {
            dispatch({ type: COLLECTION_LOAD, loading: false });
          });
      }
    });
  };

export const collection_insert = (data, executeThisAfter) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({ type: COLLECTION_LOAD, loading: true });
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Creado exitosamente',
          text: `Se ha creado el tipo de recaudo: ${data.depoTypeName}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });

        executeThisAfter();
      }

      if (!res.success) {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    })
    .catch(err => {
      console.error({ err });

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No se pudo crear el registro',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    })
    .finally(() => dispatch({ type: COLLECTION_LOAD, loading: false }));
};

export const collection_edit = (codigo, data, executeThisAfter) => (dispatch, getState) => {
  dispatch({ type: COLLECTION_LOAD, loading: true });
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/medical/admissions/depositType/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Recaudo ${data.depoTypeName?.toLowerCase()?.trim()} actualizado`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `${res.message}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
      dispatch({ type: COLLECTION_LOAD, loading: false });
    })
    .then(() => {
      executeThisAfter();
    })
    .catch(err => {
      console.error(err);

      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'No se pudo cambiar el estado',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

      dispatch({ type: COLLECTION_LOAD, loading: false });
    });
};
