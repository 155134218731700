import { useCallback, useEffect, useMemo, useState } from 'react';

// icons
import { AddCircleOutline } from '@material-ui/icons';
import lupa from '../../../assets/img/icons/lupa.svg';
import ThreeDots from '../../../assets/img/icons/threeDots.svg';

// scss
import treasuryStyles from './bank-note-types.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

// components
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { BankNoteTypesConfig } from './config/BankNoteTypesConfig';
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';

// hooks
import { useSelector } from 'react-redux';
import { useDebounce, useGetMethod, usePostMethod } from '../../../Hooks';

// helpers
import { loader } from '../../../helpers';

import { customSwaltAlert } from '../../../helpers';

const actions = {
  create: 'CREATE',
  update: 'UPDATE',
  detail: 'DETAIL',
};

export const BankNoteTypes = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const eaccount = store.loginReducer.currentAccount.id;

  const [showConfig, setShowConfig] = useState(false);

  const [actionConfig, setActionConfig] = useState(actions.create);

  const [search, setSearch] = useState('');

  const debounce = useDebounce(search, 500);

  const [dataConfig, setDataConfig] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialStateFilters = {
    page: 1,
    perpage: 10,
    eaccount,
    status: undefined,
    id: undefined,
  };

  const [filters, setFilters] = useState(initialStateFilters);

  const {
    results: listBankNoteTypes,
    load: loaderListBankNoteTypes,
    trigger: getListBankNoteTypes,
  } = useGetMethod();

  const { trigger: putData, load: loadSendDataForm } = usePostMethod();

  const listBankNoteTypesSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Débito', value: 'debit' },
    { label: 'Crédito', value: 'credit' },
  ];

  const config = useMemo(() => {
    return {
      token,
      eaccount,
      userId,
      show: showConfig,
      action: actionConfig,
      id: dataConfig.id,
    };
  }, [actionConfig, dataConfig.id, eaccount, showConfig, token, userId]);

  const listStatusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Habilitado', value: 'enabled' },
    { label: 'Deshabilitado', value: 'disabled' },
  ];

  const handleCloseConfig = useCallback(() => {
    setDataConfig({});
    setShowConfig(false);
    setActionConfig(actions.create);
  }, []);

  const handleShowConfig = () => {
    setShowConfig(true);
    setActionConfig(actions.create);
  };

  const onUpdateSuccess = useCallback(() => {
    getListBankNoteTypes({
      token,
      url: '/cash/bank-note-types',
      objFilters: { page: 1, perpage: 10, eaccount },
    });
  }, [eaccount, getListBankNoteTypes, token]);

  const onChangeStatus = data => {
    putData({
      url: `/cash/bank-note-types/change-status/${data.id}`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: { status: data.status.value === 'enabled' ? 'disabled' : 'enabled' },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha ${data.status.value === 'enabled' ? 'deshabilitado' : 'habilitado'} el tipo de nota: ${data.name}`,
          showCancelButton: false,
        }).finally(() => {
          getListBankNoteTypes({
            token,
            url: '/cash/bank-note-types',
            objFilters: { ...initialStateFilters },
          });
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  //////// HEADERS TABLE ////////////
  const headerTable = [
    <th key={0} className='px-2 text-center col-1'>
      Código
    </th>,

    <th key={1} className='px-2 text-start col-6'>
      Nombre
    </th>,

    <th key={2} className='px-2 text-center col-4'>
      Tipo
    </th>,

    <th key={3} className='px-2 text-center'>
      Estado
    </th>,

    <th key={4} className='px-2 text-end'></th>,
  ];

  //////// BODY TABLE ////////////
  const bodyTable = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='px-2 text-center'>{item.id}</td>
            <td className='px-2 text-start'>{item.name}</td>
            <td className='px-2 text-center'>{item.type.value}</td>
            <td className='px-2 text-center'>
              <div
                className={treasuryStyles.cardStatus}
                style={{ color: item.status.fontColor, background: item.status.bgColor }}
              >
                {item.status.name}
              </div>
            </td>
            <td className='px-2 text-end'>
              <CustomPopupExtend
                noHover
                position='right'
                triggerSrc={ThreeDots}
                showEnable
                isEnabled={item.status.value === 'enabled'}
                showEdit
                showDetails
                editClickEvent={() => {
                  setShowConfig(true);
                  setDataConfig(item);
                  setActionConfig(actions.update);
                }}
                showDetailsEvent={() => {
                  setShowConfig(true);
                  setDataConfig(item);
                  setActionConfig(actions.detail);
                }}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.status.value === 'enabled' ? 'deshabilitará' : 'habilitará'} el tipo de nota: ${item.name}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onChangeStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  useEffect(() => {
    getListBankNoteTypes({
      token,
      url: '/cash/bank-note-types',
      objFilters: { ...filters, search },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, debounce, filters]);

  const renderFilters = () => {
    return (
      <div>
        <h1>Tipos notas de bancos</h1>
        <div className={treasuryStyles.filtersGrid}>
          <div>
            <div>Tipo de nota</div>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              options={listBankNoteTypesSelect}
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              onChange={el => {
                setFilters(state => ({
                  ...state,
                  type: el.value,
                  page: 1,
                }));
              }}
            />
          </div>
          <div>
            <div>Estado</div>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listStatusSelect}
              styles={ordCustomSelect}
              onChange={el => {
                setFilters(state => ({
                  ...state,
                  status: el.value,
                }));
              }}
            />
          </div>
          <div className={treasuryStyles.boxInputSearch}>
            <input
              type='text'
              name='search'
              autoComplete='off'
              placeholder='Buscar por nombre...'
              className={treasuryStyles.inputSearch}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  getListBankNoteTypes({
                    token,
                    url: '/cash/bank-note-types',
                    objFilters: { ...filters, search, page: 1 },
                  });
                }
              }}
            />
            <img
              src={lupa}
              alt='search'
              onClick={() => {
                getListBankNoteTypes({
                  token,
                  url: '/cash/bank-note-types',
                  objFilters: { ...filters, search, page: 1 },
                });
              }}
            />
          </div>

          <button
            type='button'
            style={{ justifySelf: 'end', columnGap: 10 }}
            className={treasuryStyles.btnPrimaryFilters}
            onClick={handleShowConfig}
          >
            <span>Crear tipos de nota</span>
            <AddCircleOutline stroke={3} fontSize='small' />
          </button>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <GenericTableNew dark={true} headers={headerTable}>
        {bodyTable(listBankNoteTypes.data)}
      </GenericTableNew>
    );
  };

  const main = () => {
    return (
      <>
        {(loaderListBankNoteTypes || loadSendDataForm) && loader}
        <div className={treasuryStyles.container}>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(listBankNoteTypes?.rowTotal / filters.perpage)
                ? Math.ceil(listBankNoteTypes?.rowTotal / filters.perpage)
                : '1'}{' '}
              ({listBankNoteTypes?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={listBankNoteTypes?.rowTotal}
              pageRangeDisplayed={5}
              onChange={page => setFilters({ ...filters, page: page })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

          <BankNoteTypesConfig
            config={config}
            handleClose={handleCloseConfig}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </>
    );
  };

  return main();
};
