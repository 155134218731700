import { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Back from '../../../assets/img/icons/atras-icon.svg';
import iconDownload from '../../../assets/img/icons/iconDownload.svg';
import Lupa from '../../../assets/img/icons/lupa.svg';

import { API_VERSION, URL_GATEWAY, formatteDocument } from '../../../helpers';
import { convertFilterToString } from '../../../helpers/convertToFilter';

import GenericTableNew from '../../Layouts/GenericTableNew';

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import SalidaConStyles from '../../SalidaConsumo/SalidaConsumo.module.scss';
import payrollStyles from '../payrollStyles.module.scss';

function MedicalExamDetail(props) {
  const store = useSelector(state => state);
  const history = useHistory();
  const token = store.loginReducer.Authorization;

  const [isLoading, setIsLoading] = useState(false);

  const [trigger, setTrigger] = useState(0);

  const get_my_areaname = props.area_name
    ? props.area_name
    : store.PlanningMedicalExamsReducer.chosen_area
      ? store.PlanningMedicalExamsReducer.chosen_area
      : '';
  const get_my_examID = props.exam_id
    ? props.exam_id
    : store.PlanningMedicalExamsReducer.chosen_id
      ? store.PlanningMedicalExamsReducer.chosen_id
      : '';

  const [filters, setFilters] = useState({
    area_id: '',
    page: 1,
    perpage: 10,
    status: 'done',
    me_id: get_my_examID,
  });

  const [medTableTotal, setMedTableTotal] = useState(0);
  const [candidateList, setCandidateList] = useState([]);

  //zone that should be actions but honestly we are over-using redux
  const fetchMedicalExamCandidates = async my_filters => {
    setIsLoading(true);

    let str_filters = convertFilterToString(my_filters);
    await fetch(`${URL_GATEWAY}${API_VERSION}/payroll/medical_exam_candidates/?${str_filters}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        setCandidateList(res.results);
        setMedTableTotal(res.total);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  //

  useEffect(() => {
    fetchMedicalExamCandidates(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const meHeaders = [
    <th key={1}>
      <p className='m-0 text-start' style={{ paddingLeft: 10 }}>
        Colaborador
      </p>
    </th>,
    <th key={2}>
      <p className='m-0 text-center'>Identificación</p>
    </th>,
    <th key={3}>
      <p className='m-0 text-start'>Cargo</p>
    </th>,
    <th key={4}>&nbsp;</th>,
  ];

  const meBody = () => {
    if (!Array.isArray(candidateList)) {
      return [];
    }

    let filteredArray = candidateList;

    return filteredArray.map(item => {
      return (
        <tr key={item.id} className='hover-table-row'>
          <td className={`col-md-5`}>
            <p className={`m-0 text-start`} style={{ paddingLeft: 10 }}>
              {item.full_name}
            </p>
          </td>
          <td className={`col-md-3`}>
            <p className={`m-0 text-center`}>{formatteDocument(item.doc_number).format}</p>
          </td>
          <td className={`col-md-3`}>
            <p className={`m-0 text-start`}>{item.occupation}</p>
          </td>
          <td className={`col-md-1`}>
            <div className={`d-flex justify-content-center`}>
              <a
                href={item.result_location}
                target='_blank'
                className={payrollStyles.linkButton}
                rel='noreferrer'
              >
                <img
                  src={iconDownload}
                  alt='download'
                  style={{
                    width: 18,
                    cursor: 'pointer',
                    margin: 0,
                  }}
                />
              </a>
            </div>
          </td>
        </tr>
      );
    });
  };

  const screenGoBack = () => {
    if (props.setScreenControl) {
      props.setScreenControl(0);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={tableStyles.container} style={{ padding: 0 }}>
      <div className={`d-flex ${SalidaConStyles.titleBlock}`} style={{ marginBottom: 17 }}>
        <img
          src={Back}
          className={SalidaConStyles.backIcon}
          onClick={() => screenGoBack()}
          alt=''
        />
        <div>
          <h1 className={SalidaConStyles.mainTitle} style={{ margin: 0 }}>
            Resultados de Exámenes
          </h1>
          <h2
            className={payrollStyles.subtitle}
            style={{ fontWeight: 'normal', margin: 0, padding: 0 }}
          >
            {get_my_areaname}
          </h2>
        </div>
      </div>

      <div
        className={tableStyles.container}
        style={{ paddingBottom: 40, paddingLeft: 50, paddingRight: 50, flex: 1 }}
      >
        {isLoading && (
          <div className='loading'>
            <Loader type='Oval' color='#003f80' height={100} width={100} />
          </div>
        )}

        <div className={`d-flex`} style={{ marginTop: 16 }}>
          <div className='d-flex flex-column mr-2' style={{ flex: 1 }}>
            <input
              type='text'
              className={tableStyles.blueSearch}
              placeholder='Buscar...'
              value={filters.search}
              onChange={e => setFilters({ ...filters, search: e.target.value })}
            />
          </div>

          <div className='d-flex flex-column' style={{ flex: 1 }}>
            <img
              className={tableStyles.cursorPointer}
              style={{ width: '25px' }}
              onClick={() => setTrigger(trigger + 1)}
              src={Lupa}
              alt=''
            />
          </div>

          <div style={{ flex: 1 }}></div>
        </div>

        <GenericTableNew headers={meHeaders} dark={true}>
          {meBody()}
        </GenericTableNew>

        {medTableTotal > filters.perpage && (
          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {medTableTotal ? filters.page : ''}
              {' de '}
              {Math.ceil(medTableTotal / filters.perpage)
                ? Math.ceil(medTableTotal / filters.perpage)
                : ''}{' '}
              ({medTableTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={medTableTotal}
              pageRangeDisplayed={5}
              onChange={val => {
                setTrigger(trigger + 1);
                setFilters({ ...filters, page: val });
              }}
              itemClassPrev={`${paginationStyles.itemClassPrev} `}
              itemClassNext={`${paginationStyles.itemClassNext} `}
              itemClassFirst={`${paginationStyles.itemClassFirst} `}
              itemClassLast={`${paginationStyles.itemClassLast} `}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default MedicalExamDetail;
