import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { Close, ErrorOutline, PaidOutlined, SmsOutlined } from '@mui/icons-material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import { useGetMethod, usePostMethod } from '../../../Hooks';
import Excel from '../../../assets/img/icons/excel.svg';
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import {
  convertMoneyFormat,
  customSwaltAlert,
  genericDownloadExcel,
  loader,
} from '../../../helpers';

import modalStyles from '../../../components/Layouts/modalnew.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';
import { customSelectNewDark } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

export default function Consignment() {
  const store = useSelector(store => store);

  const [loading, setLoading] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [observationModal, setObservationModal] = useState({
    isOpen: false,
    data: null,
  });

  const INITIAL_STATE = {
    page: 1,
    perpage: 10,
    eaccount: store.loginReducer.currentAccount.id,
    dateFrom: '',
    dateUntil: '',
    client: '',
    niif: '',
    status: '',
  };

  const { trigger: getCompanies, results: companiesResult } = useGetMethod();
  const { trigger: getNiif, results: niifResult } = useGetMethod();
  const { trigger: getConsignments, results: consignmentsResult } = useGetMethod();
  const { trigger: postDocExcel } = usePostMethod();

  const { trigger: createConsignment, load: createLoader } = usePostMethod();

  const [filters, setFilters] = useState(INITIAL_STATE);

  useEffect(() => {
    async function fetchAsyncData() {
      try {
        setLoading(true);
        await getConsignments({
          url: '/receivable/corporateDeposit/',
          objFilters: {
            ...filters,
            dateFrom: filters.dateUntil ? filters.dateFrom : '',
            dateUntil: filters.dateFrom ? filters.dateUntil : '',
          },
          token: store.loginReducer.Authorization,
          doAfterException: results => {
            customSwaltAlert({
              showCancelButton: false,
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: results?.message,
              confirmButtonText: 'Aceptar',
            });
          },
        });
      } finally {
        setLoading(false);
      }
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [
    filters,
    store.loginReducer.currentAccount.id,
    store.loginReducer.Authorization,
    getConsignments,
  ]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getCompanies({
        url: '/receivable/client/',
        objFilters: {
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getCompanies, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  useEffect(() => {
    async function fetchAsyncData() {
      await getNiif({
        url: '/admin/niif',
        objFilters: {
          active: 1,
          eaccount: store.loginReducer.currentAccount.id,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [getNiif, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  const onChangeFilters = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      page: 1,
    }));
  };

  const onSubmitCreate = event => {
    event.preventDefault();
    const { client, date, type, identifier, niif, amount, observations, anticipatedPayment } =
      event.target;
    if (
      !client.value ||
      !date.value ||
      !type.value ||
      !identifier.value ||
      !niif.value ||
      !amount.value
    ) {
      customSwaltAlert({
        showCancelButton: false,
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Por favor, diligencie todos los campos marcados como obligatorios.',
        confirmButtonText: 'Aceptar',
      });
      return;
    }
    createConsignment({
      token: store.loginReducer.Authorization,
      body: {
        client: client.value,
        date: date.value,
        type: type.value,
        identifier: identifier.value,
        niif: niif.value,
        anticipatedPayment: anticipatedPayment.checked ? 1 : 0,
        amount: parseFloat(amount.value.replace(/[^\d,]/g, '').replace(',', '.')),
        eaccount: store.loginReducer.currentAccount.id,
        observations: observations.value,
      },
      method: 'POST',
      url: '/receivable/corporateDeposit/',
      succesAction: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Creado exitosamente',
          text: results.message,
          confirmButtonText: 'Aceptar',
        }).then(async response => {
          if (response.isConfirmed) {
            onCloseModal();
            setFilters(INITIAL_STATE);
          }
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results?.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const onCloseModal = () => {
    setIsCreateModalOpen(false);
  };

  const downloadExcelDoc = () => {
    postDocExcel({
      url: '/receivable/corporateDeposit/excel/',
      method: 'POST',
      body: {
        eaccount: store.loginReducer.currentAccount.id,
        check: false,
      },
      token: store.loginReducer.Authorization,
      succesAction: results => {
        genericDownloadExcel(results?.results?.base64, results?.results?.filename);
        customSwaltAlert({
          showCancelButton: false,
          icon: 'success',
          title: 'Excel descargado',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
      doAfterException: results => {
        customSwaltAlert({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const statusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Pendiente por legalizar', value: 'pendingLegalization' },
    { label: 'Legalizado parcialmente', value: 'partiallyLegalized' },
    { label: 'Legalizado', value: 'legalized' },
  ];

  const transferTypeSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Transferencia', value: 1 },
    { label: 'Consignación', value: 3 },
    { label: 'Tarjeta de crédito', value: 4 },
    { label: 'Efectivo', value: 5 },
  ];

  const niifSelect = useMemo(
    () =>
      niifResult.results?.map(item => ({
        label: `${item.complete_account} - ${item.description}`,
        value: item.id,
      })) || [],
    [niifResult.results],
  );

  const formattedCompaniesSelect = useMemo(
    () =>
      companiesResult?.results?.map(com => ({
        value: com.id,
        label: com.copyName,
      })) || [],
    [companiesResult?.results],
  );

  const renderFilters = () => {
    return (
      <div className='d-flex align-items-end mt-3'>
        <div className='row w-100 align-items-end' style={{ paddingBottom: 3 }}>
          <div className='col'>
            <small className='text-primary ms-1'>Fecha desde</small>
            <input
              type='date'
              className='register-inputs'
              onChange={({ target }) => onChangeFilters('dateFrom', target.value)}
            />
          </div>
          <div className='col'>
            <small className='text-primary ms-1'>Fecha hasta</small>
            <input
              type='date'
              className='register-inputs'
              onChange={({ target }) => onChangeFilters('dateUntil', target.value)}
            />
          </div>
          <div className='col'>
            <small className='text-primary ms-1'>Empresa</small>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              styles={customSelectNewDark}
              placeholder='Seleccionar...'
              options={[{ label: 'Seleccionar...', value: '' }, ...formattedCompaniesSelect]}
              onChange={option => onChangeFilters('client', option.value)}
            />
          </div>
          <div className='col'>
            <small className='text-primary ms-1 nowrap'>Cuenta contable</small>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              styles={customSelectNewDark}
              placeholder='Seleccionar...'
              options={[{ label: 'Seleccionar...', value: '' }, ...niifSelect]}
              onChange={option => onChangeFilters('niif', option.value)}
            />
          </div>
          <div className='col'>
            <small className='text-primary ms-1'>Estado</small>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              styles={customSelectNewDark}
              placeholder='Seleccionar...'
              options={statusSelect}
              onChange={option => onChangeFilters('status', option.value)}
            />
          </div>
        </div>
        <div className='mx-3'>
          <div className={tableStyles.buttonTextPrimary} onClick={() => setIsCreateModalOpen(true)}>
            <small className='mr-2'>Consignación</small>
            <PaidOutlined stroke='2' fontSize='small' />
          </div>
        </div>
        <div>
          <img className='mb-1' src={Excel} alt='' onClick={() => downloadExcelDoc()} />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const tableHeaders = [
      <th key={1} className='text-start ps-2'>
        &nbsp;
      </th>,
      <th key={2} className='text-start ps-2'>
        Identificador
      </th>,
      <th key={3} className='text-start ps-2'>
        Fecha
      </th>,
      <th key={4} className='text-start ps-2'>
        Consignante
      </th>,
      <th key={5} className='text-start ps-2'>
        Tipo
      </th>,
      <th key={6} className='text-start ps-2'>
        Cuenta contable
      </th>,
      <th key={7} className='text-end pe-2' style={{ whiteSpace: 'nowrap' }}>
        Valor recaudado
      </th>,
      <th key={8} className='text-center'>
        Estado
      </th>,
      <th key={9}>&nbsp;</th>,
    ];

    return (
      <>
        <div className='table-responsive'>
          <GenericTableNew headers={tableHeaders} dark={true}>
            {consignmentsResult?.results?.map((item, index) => (
              <tr key={`${item.identifier}_${index}`}>
                <td className='ps-2'>
                  {item.anticipated_payment === '1' && (
                    <Tooltip title='Pago anticipado'>
                      <ErrorOutline className='text-danger' fontSize='small' />
                    </Tooltip>
                  )}
                </td>
                <td className='text-start ps-2'>{item.identifier}</td>
                <td className='text-start ps-2'>
                  {moment(item.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                </td>
                <td className='text-start ps-2'>{item.clientName}</td>
                <td className='text-start ps-2'>{item.type}</td>
                <td className='text-start ps-2'>{item.niif}</td>
                <td className='text-end pe-2'>{convertMoneyFormat(item.amount)}</td>
                <td className='pe-2'>
                  <div
                    style={{
                      backgroundColor: item.background ?? undefined,
                      color: item.fontcolor ?? undefined,
                    }}
                    className={tableStyles.baseStatus}
                  >
                    {item.status}
                  </div>
                </td>
                <td className='pe-2'>
                  {item.observations ? (
                    <SmsOutlined
                      className='align-middle text-primary pointer'
                      onClick={() =>
                        setObservationModal({
                          isOpen: true,
                          data: item,
                        })
                      }
                      style={{ fontSize: 16 }}
                    />
                  ) : (
                    ''
                  )}
                </td>
              </tr>
            ))}
          </GenericTableNew>
        </div>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {filters.page}
            {' de '}
            {Math.ceil(consignmentsResult?.row_count / filters.perpage)
              ? Math.ceil(consignmentsResult?.row_count / filters.perpage)
              : '1'}{' '}
            ({consignmentsResult?.row_count} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={consignmentsResult?.row_count}
            pageRangeDisplayed={5}
            onChange={page => setFilters({ ...filters, page: page })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>
      </>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        show={isCreateModalOpen}
        centered
        className={`${modalStyles.container}`}
        onHide={onCloseModal}
      >
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            Consignación
          </h3>
          <Close className='pointer text-secondary' onClick={onCloseModal} />
        </div>
        <hr className='m-0' />
        <form onSubmit={onSubmitCreate} className='p-3'>
          <div className='row justify-content-center g-2'>
            <div className='col-12'>
              <small className='text-primary ms-1'>
                <span>Consignante</span>
                <span className='text-danger'>*</span>
              </small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                placeholder='Seleccionar...'
                name='client'
                options={[{ label: 'Seleccionar...', value: '' }, ...formattedCompaniesSelect]}
              />
            </div>
            <div className='col-6'>
              <small className='text-primary ms-1'>
                <span>Fecha</span>
                <span className='text-danger'>*</span>
              </small>
              <input
                type='date'
                className='register-inputs'
                name='date'
                max={moment().format('YYYY-MM-DD')}
              />
            </div>
            <div className='col-6'>
              <small className='text-primary ms-1'>
                <span>Tipo de consignación</span>
                <span className='text-danger'>*</span>
              </small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                placeholder='Seleccionar...'
                name='type'
                options={transferTypeSelect}
              />
            </div>
            <div className='col-12'>
              <small className='text-primary ms-1'>
                <span>Identificador</span>
                <span className='text-danger'>*</span>
              </small>
              <input
                type='text'
                className='register-inputs'
                maxLength={12}
                placeholder='Escribe...'
                name='identifier'
              />
            </div>
            <div className='col-12'>
              <small className='text-primary ms-1'>
                <span>Cuenta contable</span>
                <span className='text-danger'>*</span>
              </small>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                styles={customSelectNewDark}
                placeholder='Seleccionar...'
                options={[{ label: 'Seleccionar...', value: '' }, ...niifSelect]}
                name='niif'
              />
            </div>
            <div className='col-12'>
              <small className='text-primary ms-1'>
                <span>Valor</span>
                <span className='text-danger'>*</span>
              </small>
              <NumberFormat
                className='register-inputs'
                placeholder='Escribe...'
                name='amount'
                allowLeadingZeros={false}
                allowNegative={false}
                decimalSeparator=','
                thousandSeparator='.'
                prefix='$'
                isNumericString
              />
            </div>
            <div className='col-12'>
              <small className='text-primary ms-1'>Observaciones</small>
              <textarea
                className='register-inputs'
                style={{ minHeight: 50 }}
                name='observations'
                maxLength={250}
              />
            </div>
            <div className='col-12'>
              <FormControlLabel
                name='anticipatedPayment'
                className='text-muted'
                control={
                  <Switch
                    className='my-2'
                    activeBoxShadow
                    handleDiameter={22}
                    width={50}
                    color='primary'
                    onColor='#005DBF'
                  />
                }
                label='Pago anticipado'
              />
            </div>
          </div>

          <div className='d-flex justify-content-center align-items-center mt-4'>
            <button
              type='button'
              onClick={onCloseModal}
              className={`${tableStyles.btnSecondary} me-3`}
            >
              Cancelar
            </button>
            <button type='submit' className={`${tableStyles.btnPrimaryAlt}`}>
              Guardar
            </button>
          </div>
        </form>
      </Modal>
    );
  };

  const renderObservationModal = () => {
    return (
      <Modal show={observationModal.isOpen} className={`${modalStyles.container}`} centered>
        <div className='d-flex align-items-center justify-content-between py-3 px-4'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>Observación</h3>
          <Close
            className='pointer text-secondary'
            onClick={() => setObservationModal(prev => ({ ...prev, isOpen: false }))}
          />
        </div>
        <hr className='m-0' />

        {observationModal.data && (
          <div className='row p-3'>
            <div className='col-12'>
              <small className='text-primary ms-1'>Consignante</small>
              <input
                type='text'
                className='register-inputs'
                defaultValue={observationModal.data.clientName}
                disabled
              />
            </div>
            <div className='col-12'>
              <small className='text-primary ms-1'>Consignante</small>
              <textarea
                className='register-inputs'
                defaultValue={observationModal.data.observations}
                style={{ minHeight: 100 }}
                disabled
              />
            </div>
          </div>
        )}
      </Modal>
    );
  };

  const render = () => {
    return (
      <>
        {(loading || createLoader) && loader}
        {renderFilters()}
        {renderTable()}
        {renderModal()}
        {renderObservationModal()}
      </>
    );
  };

  return render();
}
