import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import '../Layouts/customTabs.scss';
import '../TableUsers/tableUserStyle.scss';
import pendingStyles from './AuctionPending.module.scss';
import detailStyles from './CurrentAuctionDetails.module.scss';
import IndividualStyles from './IndividualAuction_CCS.module.scss';
// import "../TableUsers/tableUserStyle.scss";
// import "../Layouts/customTabs.scss";
import GenericTableNew from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';

import { Tooltip } from '@material-ui/core';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import iconDownload from '../../assets/img/icons/iconDownload.svg';

function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max);
}

function DetailsModalCotizacion(props) {
  const counter = useSelector(state => state);

  const quotationlist = counter.individualAuctionReducer.quotations.filter(
    x => x.status !== 'requested',
  );

  const [quotationIndex, setquotationIndex] = useState(0);

  const validityformat = quotationlist[quotationIndex]?.quote_validity
    ? new Date(quotationlist[quotationIndex].quote_validity)
    : '';

  useEffect(() => {
    //setArticleChanges(props.reqState?.articulos);
    if (props.Qid) {
      let Qindex = quotationlist.findIndex(x => x.id === props.Qid);
      setquotationIndex(Qindex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const renderList = () => {
    let table = [];
    let totalval = 0;
    const articles = quotationlist[quotationIndex]?.quotation_article_ref;
    if (Array.isArray(articles)) {
      table = articles.map((x, index) => {
        if (!isNaN(x.qty_article * x.unit_price)) {
          totalval += x.qty_article * x.unit_price;
        }
        return (
          <tr key={'CotizacionList' + index} className='hover-table-row'>
            <td className={`col-md-1`}>{x.id_article}</td>
            <td className={`col-md-4`}>
              <p className={`m-0 ${detailStyles.rowName}`}>{x.article_ref?.description}</p>
            </td>
            <td className={`col-md-1`}>{x.brand}</td>
            <td className={`col-md-1`}>{props.formatter.format(x.unit_price)}</td>
            <td className={`col-md-1`}>{x.qty_article}</td>
            <td className={`col-md-2`}>{x.discount ? x.discount : '-'}</td>
            <td className={`col-md-2  ${detailStyles.rowValue}`}>
              <p className={`m-0 ${detailStyles.rowValue}`}>
                {props.formatter.format(x.qty_article * x.unit_price)}
              </p>
            </td>
          </tr>
        );
      });
    }

    table.push(
      <tr key={'CotizacionListTotal'} className='hover-table-row'>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-4`}>
          <p className={`m-0 ${detailStyles.rowName} ${detailStyles.rowTotal}`}>TOTALES</p>
        </td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-1`}>&nbsp;</td>
        <td className={`col-md-2`}>&nbsp;</td>
        <td className={`col-md-2`}>
          <p className={`m-0 ${detailStyles.rowValue} ${detailStyles.rowTotal}`}>
            {props.formatter.format(totalval)}
          </p>
        </td>
      </tr>,
    );

    return table;
  };

  const renderHeaders = [
    <th key={`HeaderCotizacion1`}>#</th>,
    <th key={`HeaderCotizacion2`}>Descripción</th>,
    <th key={`HeaderCotizacion3`}>Marca</th>,
    <th key={`HeaderCotizacion4`}>Precio&nbsp;Unt.</th>,
    <th key={`HeaderCotizacion5`}>Cant.</th>,
    <th key={`HeaderCotizacion6`}>Descuento</th>,
    <th key={`HeaderCotizacion7`}>Valor&nbsp;Total</th>,
  ];

  return (
    <ModalNew
      title='Detalles de Cotización'
      subtitle={quotationlist[quotationIndex]?.provider_ref?.name}
      show={props.show}
      size='940'
      btnNoName='Aceptar'
      onHide={props.onHide}
      leftArrowEvent={() =>
        setquotationIndex(clamp(quotationIndex - 1, 0, quotationlist.length - 1))
      }
      rightArrowEvent={() =>
        setquotationIndex(clamp(quotationIndex + 1, 0, quotationlist.length - 1))
      }
    >
      <div className='d-flex'>
        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>N° de Cotización</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={
              quotationlist[quotationIndex]?.quotation_number
                ? quotationlist[quotationIndex]?.quotation_number
                : '-'
            }
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Validez</p>
          <input
            className={` register-inputs ${detailStyles.inputText}`}
            type='text'
            value={
              quotationlist[quotationIndex]?.quote_validity
                ? validityformat.getDate() +
                  '/' +
                  validityformat.getMonth() +
                  '/' +
                  validityformat.getFullYear()
                : '-'
            }
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Terminos y Condiciones</p>
          <Tooltip title={props.parentTerms}>
            <input
              className={` register-inputs ${detailStyles.inputText}`}
              type='text'
              value={props.parentTerms ? props.parentTerms : '-'}
              disabled={true}
            />
          </Tooltip>
        </div>

        <div className={`${pendingStyles.inputMargin} ${pendingStyles.Item} ${pendingStyles.f2}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>Condiciones de Pago</p>
          <input
            className={`register-inputs ${detailStyles.inputText}`}
            type='text'
            value={
              quotationlist[quotationIndex]?.pay_conditions
                ? props.payConditions(quotationlist[quotationIndex]?.pay_conditions)
                : '-'
            }
            disabled={true}
          />
        </div>

        <div className={`${pendingStyles.Item}`}>
          <p className={`${IndividualStyles.crudModalLabel}`}>&nbsp;</p>
          <Tooltip title='Descargar PDF'>
            <img
              src={iconDownload}
              alt='Descargar'
              className={`${detailStyles.adjustIconDescargar}`}
              //onClick={()=>dispatch(requisition_export_pdf())}
            />
          </Tooltip>
        </div>
      </div>

      <GenericTableNew headers={renderHeaders}>{renderList()}</GenericTableNew>

      <div className={`${pendingStyles.Item} ${pendingStyles.f2}`}>
        <p className={`${IndividualStyles.crudModalLabel}`}>Observaciones</p>
        <textarea
          id='txtArea'
          rows='10'
          cols='10'
          className={IndividualStyles.textArea}
          value={quotationlist[quotationIndex]?.observations}
          disabled={true}
        ></textarea>
      </div>
    </ModalNew>
  );
}

export default DetailsModalCotizacion;
