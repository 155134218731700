import moment from 'moment';
import { ChevronRight } from '@material-ui/icons';

import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';

export default function ScheduleDetail({
  isOpen = false,
  onClose = () => null,
  events = [],
  onSelect = () => null,
}) {
  const referenceDay = moment(events[0]?.beginsAt).format('LL');

  const renderComponent = () => {
    return (
      <OrdModal
        title={`Agendas día ${referenceDay}`}
        show={isOpen}
        btnYesName='Aceptar'
        onHide={() => onClose()}
        btnYesEvent={() => onClose()}
        hideCancelButton
        size='lg'
      >
        <div
          className='d-flex flex-column ps-3 gap-3'
          style={{ maxHeight: 600, overflowY: 'auto' }}
        >
          {events.map(event => (
            <div
              key={event.id}
              className='row p-3 w-100'
              style={{ background: `${event.color}20`, borderRadius: 10 }}
            >
              <div className='col-lg-6'>
                <b>{event.name}</b>
                <div>
                  <b>Consultorio: </b>
                  {event.consultingRoomName}
                </div>
              </div>
              <div className='col-lg-4'>
                <div>
                  <b>Hora inicio: </b> {moment(event.beginsAt).format('hh:mm A')}
                </div>
                <div>
                  <b>Hora fin: </b> {moment(event.endsAt).format('hh:mm A')}
                </div>
              </div>
              <div className='col-lg-2 d-flex justify-content-end align-items-center'>
                <ChevronRight color='action' className='pointer' onClick={() => onSelect(event)} />
              </div>
            </div>
          ))}
        </div>
      </OrdModal>
    );
  };

  return renderComponent();
}
