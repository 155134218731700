//moment
import moment from 'moment';
import 'moment/locale/es';

//react
import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

//icons
import checkCirculo from '../../../../../src/assets/img/icons/checkCirculo.svg';
import app_aprovado from '../../../../../src/assets/img/icons/app_aprovado.svg';
import iconClose from '../../../../../src/assets/img/icons/modalClose.svg';

//styles
import styles from '../../../../components/Layouts/tableStyle.module.scss';

//helper
import { convertMoneyFormatNew, customSwaltAlertAsistencial, formatteDocument, getEnvs, loader } from '../../../../helpers';
import { formatNumberWithoutDecimals } from '../../../../helpers/numberFormatting';

//hooks
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../../../Hooks';

//components
import { Title, Text, Line, TableCustom, Button } from '../../../UI/atoms';
import { BoxCard, BoxFooter } from '../../../UI/molecules';
import { Box, Drawer } from '@mui/material';
import OrdTable from '../../../../OrderingModule/OrderingComponents/OrdTable';
import NumberFormat from 'react-number-format';
import { isNullOrUndefined } from 'util';

moment.locale('es');
const { CURRENCY } = getEnvs();

export const DailyClosing = () => {
  //states
  const myDate = moment();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const userId = store.loginReducer.user_data.id;
  const siteId = store.userReducer.site;
  const eaccount = store.loginReducer.currentAccount.id;
  const [voucherDepositTypeName, setVoucherDepositTypeName] = useState(undefined);

  const [filters] = useState({
    userId,
    siteId,
    eaccount,
    search: '',
    cashClosingId: undefined,
    depositTypeFilter: undefined,
    page: 1,
    perPage: 10,
  });

  const [filtersListCashClosingCurrency, setFiltersListCashClosingCurrency] = useState({
    userId,
    siteId,
    eaccount,
    getDetail: 1,
    page: 1,
    perpage: 10,
    depositTypeId: undefined,
    cashClosingId: undefined,
  });

  const [triggerFiltersListCashClosingCurrency, setTriggerFiltersListCashClosingCurrency] =
    useState(0);

  const [drawerDetail, setDrawerDetail] = useState(false);
  const [drawerVoucher, setDrawerVoucher] = useState(false);

  const [patientTotalValuesGrid, setPatientTotalValuesGrid] = useState({
    patientsCount: 0,
    totalAmount: 0,
    difference: 0,
  });

  const [patientTotalValues, setPatientTotalValues] = useState({
    appsCount: 0,
    totalDeposit: 0,
  });

  const [trigger, setTrigger] = useState(0);

  const history = useHistory();

  const { colors } = useTheme();

  //  --------------------------- Petitions -------------------------

  const {
    results: listCashClosing,
    trigger: getListCashClosing,
    load: loaderListCashClosing,
  } = useGetMethod();

  const {
    results: listCashClosingCurrency,
    trigger: getListCashClosingCurrency,
    load: loaderListCashClosingCurrency,
  } = useGetMethod();

  const {
    results: listCashClosingCurrencyCounted,
    trigger: getListCashClosingCurrencyCounted,
    load: loaderListCashClosingCurrencyCounted,
  } = useGetMethod();

  const {
    results: listDepositType,
    load: loaderListDepositType,
    trigger: getListDepositType,
  } = useGetMethod();

  const { load: loadListCurrencyDenomination, trigger: getListCurrencyDenomination } =
    useGetMethod();

  const { load: loadCreateDailyCashClosing, trigger: onCreateDailyCashClosing } = usePostMethod();

  const headersCashClosing = [
    {
      title: 'Moneda',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Cantidad',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total',
      className: 'px-3 text-end col-4',
    },
  ];

  const headersDetailCashClosing = [
    {
      title: 'Voucher',
      className: 'px-3 text-start',
    },
    {
      title: 'Entidad',
      className: 'px-2 text-center',
    },
    {
      title: 'Total',
      className: 'px-3 text-end',
    },
  ];

  const headersWalletMovements = [
    {
      title: 'Paciente',
      className: 'px-3 text-start col-4',
    },
    {
      title: 'Identificación',
      className: 'px-2 text-center col-4',
    },
    {
      title: 'Total devuelta',
      className: 'px-2 text-end col-4',
    },
  ];

  const closedStatus = useMemo(() => ["closed", "pending_review"], [])

  const formatWalletMovements = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-start px-2`}>{item?.patientName || '-'}</td>

            <td className={`text-center px-2`}>
              <span>{item?.patientDocType || '-'}</span>&nbsp;
              <span>
                {formatteDocument(item?.patientDoc).format || '-'}
              </span>
            </td>

            <td className={`text-end px-2`}>
            {item?.totalAmount ? formatNumberWithoutDecimals(item?.totalAmount) : '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatData = array => {
    const validateArray = array[0] === null ? [] : array;

    let tempList = [];
    if (Array.isArray(validateArray)) {
      const listCollections = [];

      listCashClosing?.results?.depositsData?.forEach(el => {
        if (el?.depositTypeLabel !== 'cash' && el?.depositType !== 'replacedDeposits') {
          listCollections?.push(el);
        }
      });
      let resFull = [];

      if (listCollections?.length && validateArray?.length) {
        resFull = [...listCollections, ...validateArray];
      } else if (listCollections?.length) {
        resFull = [...listCollections];
      } else if (validateArray?.length) {
        resFull = [...validateArray];
      }

      resFull?.forEach((item, index) => {
        const type = !isNullOrUndefined(item?.appsCount)
          ? item?.depositTypeName
          : item?.depositTypeName === 'transfer'
            ? 'Transferencia'
            : item?.depositTypeName === 'card'
              ? 'Tarjeta de crédito'
              : 'Efectivo';
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='text-start px-3'>
              {item?.depositTypeName ? (
                type
              ) : (
                <>
                  {item?.cdType === 'cash' ? 'Billete de ' : 'Moneda de '}
                  {convertMoneyFormatNew({
                    textNumber: item?.cdValue,
                    SymbolDecimal: ',',
                    SymbolShow: false,
                  })}
                </>
              )}
            </td>

            <td className='text-center px-2'>
              <span
                className={`${!isNullOrUndefined(item?.appsCount) ? `pointer text-decoration-underline ${styles.ordAquaMarineText}` : ''}`}
                onClick={() => {
                  if (!isNullOrUndefined(item?.appsCount)) {
                    setFiltersListCashClosingCurrency(state => ({
                      ...state,
                      depositTypeId: item?.depositType,
                    }));

                    getListCashClosingCurrency({
                      url: '/medical/admissions/dailyCashClosing/',
                      token: token,
                      objFilters: {
                        ...filtersListCashClosingCurrency,
                        depositTypeId: item?.depositType,
                        vouchersDetail: 1,
                      },
                    }).then(res => {
                      if (res?.success) {
                        setDrawerVoucher(true);
                        setVoucherDepositTypeName(item?.depositTypeLabel);
                      }
                    });
                  }

                  setDrawerDetail(false);
                }}
              >
                {item?.cdQuantity || item?.appsCount || '-'}
              </span>
            </td>

            <td className='text-end px-3'>
              {convertMoneyFormatNew({
                textNumber:
                  (item?.cdValue ? Number(item?.cdValue) * Number(item?.cdQuantity) : '') ||
                  item?.totalDeposit,
              }) || '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const formatDataVoucher = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className={`text-start px-3`} style={{ width: '150px' }}>
              <input
                value={item?.voucherNo || '-'}
                type='text'
                className='ord-roundInput2'
                style={{ color: '#6E6F7C' }}
              />
            </td>

            <td className='text-center px-2'>
              <span>{item?.bankName || '-'}</span>
            </td>

            <td className='text-end px-3'>
              {convertMoneyFormatNew({ textNumber: item?.totalAmount }) || '-'}
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const handleClosePartial = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Estas seguro?',
      text: `Una vez realice el cierre diario, no se podrá agregar nueva información`,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        onCreateDailyCashClosing({
          url: '/medical/admissions/dailyCashClosing/',
          token: token,
          method: 'POST',
          body: { userId, siteId },
          succesAction: () => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Guardado exitosamente',
              text:
                'Se ha creado el cierre diario No. ' +
                  listCashClosing?.results?.cashClosingDailyId || '',
              showCancelButton: false,
            }).then(() => {
              setTrigger(state => state + 1);
            });
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  useEffect(() => {
    getListCashClosing({
      url: `/medical/admissions/cashClosing/`,
      objFilters: { ...filters },
      token: token,
    }).then(res => {
      if (res?.results?.depositsData?.length) {
        let difference = 0;
        let patientsCount = 0;
        let totalAmount = 0;
        let appsCount = 0;
        let totalDeposit = 0;

        res?.results?.gridData?.forEach(el => {
          difference = Number(el?.difference) + Number(difference);
          patientsCount = Number(el?.patientsCount) + Number(patientsCount);
          totalAmount = Number(el?.totalAmount) + Number(totalAmount);
        });

        res?.results?.depositsData?.forEach(el => {
          appsCount = Number(el?.appsCount) + Number(appsCount);

          totalDeposit =
            el?.depositType === 'replacedDeposits'
              ? 0 + Number(totalDeposit)
              : Number(el?.totalDeposit) + Number(totalDeposit);
        });

        setPatientTotalValuesGrid(state => ({
          ...state,
          difference,
          patientsCount,
          totalAmount,
        }));

        setPatientTotalValues(state => ({
          ...state,
          appsCount,
          totalDeposit: totalDeposit + Number(res?.results?.totalBalance || 0),
        }));
      }

      if (!res?.success) {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res?.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      }
    });
  }, [trigger, filters, getListCashClosing, token]);

  useEffect(() => {
    if (triggerFiltersListCashClosingCurrency) {
      getListCashClosingCurrency({
        url: '/medical/admissions/dailyCashClosing/',
        token: token,
        objFilters: { ...filtersListCashClosingCurrency, vouchersDetail: 1 },
      }).then(res => {
        if (res?.success) setDrawerVoucher(true);
      });
    }
  }, [
    triggerFiltersListCashClosingCurrency,
    token,
    filtersListCashClosingCurrency,
    getListCashClosingCurrency,
  ]);

  useEffect(() => {
    getListDepositType({
      url: '/medical/admissions/depositType/',
      token: token,
      objFilters: { eaccount },
    });

    getListCurrencyDenomination({
      url: '/medical/admissions/currencyDenomination/',
      token: token,
      objFilters: {
        cdStatus: 'enabled',
        curName: CURRENCY,
      },
    });

    getListCashClosingCurrencyCounted({
      url: '/medical/admissions/dailyCashClosing/',
      token: token,
      objFilters: { ...filtersListCashClosingCurrency },
    });
  }, [
    getListCashClosingCurrencyCounted,
    eaccount,
    token,
    filtersListCashClosingCurrency,
    getListCurrencyDenomination,
    getListDepositType,
  ]);

  useEffect(() => {
    if (closedStatus.includes(listCashClosing?.results?.dailyCashClosingStatus)) {
      getListCashClosingCurrencyCounted({
        url: '/medical/admissions/dailyCashClosing/',
        token: token,
        objFilters: { ...filtersListCashClosingCurrency },
      });
    }
  }, [listCashClosing, filtersListCashClosingCurrency, closedStatus, getListCashClosingCurrencyCounted, token]);

  const ExtraFooter = () => {
    return (
      <Box
        style={{ color: colors.ordDarkGray, fontWeight: 'bold' }}
        display={'grid'}
        gridTemplateColumns={'2fr 2fr 1fr 2fr 2fr 1fr'}
        marginBottom={'0.7rem'}
        padding='0px 15px'
        gap={2}
      >
        <Box></Box>
        <Box></Box>
        <Box className={'text-center'}>{patientTotalValuesGrid.patientsCount}</Box>
        <Box className={'text-end'}>
          {formatNumberWithoutDecimals(patientTotalValuesGrid.totalAmount)}
        </Box>
        <Box className={'text-end'}>
          {`${patientTotalValuesGrid.difference !== 0 ? '-' : ''}${formatNumberWithoutDecimals(Math.abs(patientTotalValuesGrid.difference || 0))}`}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {(loaderListCashClosing ||
        loadCreateDailyCashClosing ||
        loaderListCashClosingCurrency ||
        loadListCurrencyDenomination ||
        loaderListCashClosingCurrencyCounted ||
        loaderListDepositType) &&
        loader}

      <Box display={'grid'} gridTemplateColumns={'350px 1fr'} width={'100%'}>
        <Box
          width={'100%'}
          padding='10%'
          borderRight={`4px solid ${colors.ordAquaMarine}`}
          overflow='scroll'
          display={'flex'}
          flexDirection={'column'}
          paddingBottom={'50px'}
        >
          <Title title={'Cierre diario'} margin='0 0 2rem 0' textAlign='center' />

          {listCashClosing?.results?.depositsData?.map((el, key) => (
            <BoxCard
              key={key}
              colorTitle={
                closedStatus.includes(listCashClosing?.results?.dailyCashClosingStatus)
                  ? colors.darkerGray
                  : colors.ordAquaMarine
              }
              colorName={colors.darkerGray}
              colorValue={colors.darkerGray}
              close={
                closedStatus.includes(listCashClosing?.results?.dailyCashClosingStatus) &&
                el?.depositType !== 'replacedDeposits'
              }
              title={
                el?.depositType === 'replacedDeposits'
                  ? 'ANULADO'
                  : listDepositType?.results
                      ?.find(depot => depot?.depoTypeId === Number(el?.depositType))
                      ?.depoTypeName?.toUpperCase()
              }
              textAlign={'center'}
              arrayObjItems={[
                {
                  name: 'Total recaudo',
                  value: `${convertMoneyFormatNew({ textNumber: el?.totalDeposit } || '-')}`,
                },
                { name: 'Recaudos', value: el?.appsCount || '-' },
              ]}
              fontWeightTitle={'bold'}
              fontWeightName='bold'
              margin='0 0 1rem 0'
              marginItem='0 0 0 auto'
              disabled={el?.depositType === 'replacedDeposits'}
            />
          ))}

          <Box mt={'auto'} style={{ marginBottom: '25px' }}>
            <Box className='mb-2' display={'grid'} gridTemplateColumns='1fr 1fr'>
              <Text title='Saldo a favor' color={colors.darkGray2} fontWeight={'bold'} />

              <Text
                textAlign={'end'}
                color={colors.darkGray}
                fontWeight={'bold'}
                title={formatNumberWithoutDecimals(listCashClosing?.results?.totalBalance)}
                margin='0 0 0 auto'
              />
            </Box>

            <Line backgroundColor={colors.darkGray} width='auto' margin='0.5rem 0' />

            <Box display={'grid'} gridTemplateColumns='1fr 1fr'>
              <Text title='Total' color={colors.darkGray2} fontWeight={'bold'} />

              <Text
                textAlign={'end'}
                color={colors.darkGray}
                fontWeight={'bold'}
                title={formatNumberWithoutDecimals(patientTotalValues?.totalDeposit)}
                margin='0 0 0 auto'
              />
            </Box>
            <Line backgroundColor={colors.darkGray} width='auto' margin='0.5rem 0' />
          </Box>
        </Box>

        <Box
          className={
            closedStatus.includes(listCashClosing?.results?.dailyCashClosingStatus)
              ? 'd-flex justify-content-center'
              : ''
          }
          style={{
            overflow: 'scroll',
            marginBottom: '100px',
            backgroundColor: '#f6feff',
            borderBottom: '1px solid #00b4cc',
          }}
        >
          {closedStatus.includes(listCashClosing?.results?.dailyCashClosingStatus) ? (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Box display={'flex'} gap={3} alignItems={'center'} justifyContent={'center'}>
                <img src={app_aprovado} alt='icon' height={45} width={45} />
                <Title
                  title={
                    listCashClosing?.results?.dailyCashClosingStatus === "pending_review"
                      ? 'Cierre realizado por el sistema, pendiente por revisión'
                      : 'Cierre concluido'
                  }
                  textAlign='center'
                  color={colors.darkGray}
                  fontSize='28px'
                />
              </Box>

              <button
                className={`${styles?.btnCancel} mt-4`}
                style={{ background: '#003f80', color: 'white' }}
                onClick={() => {
                  setDrawerVoucher(false);
                  setDrawerDetail(true);
                }}
              >
                Conteo de efectivo
              </button>
            </Box>
          ) : (
            ''
          )}

          {listCashClosing?.results?.dailyCashClosingStatus === 'open' ? (
            <>
              <Box
                display={'flex'}
                justifyContent='space-between'
                alignItems={'center'}
                paddingRight={'40px'}
                margin='2.4rem 0px 0 20px'
                width={'95.5%'}
              >
                <Title
                  title={`No. ${listCashClosing?.results?.cashClosingDailyId || ''}`}
                  color={colors.ordAquaMarine}
                />
                <Title title={myDate.format('LL')} color={colors.ordAquaMarine} fontSize={'16px'} />
              </Box>

              <TableCustom
                width='95%'
                totalBalance={listCashClosing?.results?.totalBalance}
                headers={[
                  { cashClosingStatusName: 'Estado', id: '1', width: '2fr' },
                  { cashClosingDate: 'Hora', id: '2', width: '2fr', className: 'text-center' },
                  { patientsCount: 'Pacientes', id: '3', width: '1fr', className: 'text-center' },
                  { totalAmount: 'Total recaudado', id: '4', width: '2fr', className: 'text-end' },
                  { difference: 'Diferencia', id: '5', width: '2fr', className: 'text-end' },
                  { icon: '', id: '6', width: '1fr', className: '' },
                ]}
                body={listCashClosing?.results?.gridData?.map(el => ({
                  cashClosingId: el?.cashClosingId || '-',
                  cashClosingStatusName:
                    el?.cashClosingId === 'currentCashClosing'
                      ? 'Recaudando'
                      : el?.cashClosingStatusName,
                  cashClosingDate: el?.cashClosingDate
                    ? moment(el?.cashClosingDate).format('h:mm a')
                    : '-',
                  patientsCount: el?.patientsCount || '-',
                  totalAmount: el?.totalAmount ? formatNumberWithoutDecimals(el?.totalAmount) : '-',
                  difference:
                    `${Math.abs(el?.difference) >= 0 ? '' : '-'}  ${formatNumberWithoutDecimals(Math.abs(el?.difference || 0))}` ||
                    '-',
                  active: el?.cashClosingId === 'currentCashClosing',
                }))}
                paginate={{
                  activePage: filters.page,
                  totalPages: listCashClosing?.results?.gridData?.length,
                  perPage: filters.perPage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: () => {
                    setTrigger(state => state + 1);
                  },
                }}
                extraFooter={<ExtraFooter />}
                iconPath={checkCirculo}
              />

              <Box
                display={'grid'}
                gridTemplateColumns={'20% 20% 20% 20% 20%'}
                width='85%'
                padding={'0 20px'}
                margin={'-15px 0'}
                fontWeight='bold'
                color={colors.ordDarkGray}
              ></Box>
            </>
          ) : (
            ''
          )}
        </Box>

        {listCashClosing?.results?.dailyCashClosingStatus === 'open' ? (
          <BoxFooter
            width={'calc(100% - 27rem)'}
            left='26.7rem'
            padding={'0 12% 0 0'}
            titleSave='Cerrar recaudos'
            saveDisabled={
              listCashClosing?.results?.gridData?.filter(
                el => el?.cashClosingId === 'currentCashClosing',
              )?.length
                ? true
                : false
            }
            widthSave={'16  0px'}
            onclickSave={handleClosePartial}
            onclickCancel={() => history.push({ pathname: '/admision/inicio' })}
            imgPathSave={checkCirculo}
            classSaveColor={`${styles.colorWhite}`}
            borderTop='transparent'
          />
        ) : (
          ''
        )}
      </Box>

      <Drawer
        anchor={'right'}
        open={drawerDetail}
        onClose={() => {
          setDrawerVoucher(false);
          setDrawerDetail(false);
        }}
      >
        <Box padding={3}>
          <Box mb={2}>
            <img
              className={`${styles.closeDrawerAsistencial} pointer`}
              src={iconClose}
              alt='close'
              onClick={() => {
                setDrawerVoucher(false);
                setDrawerDetail(false);
              }}
              height={'28px'}
              width={'28px'}
            />
          </Box>

          <Box paddingX={4}>
            <Box>
              <Box fontSize={'36px'} className={`${styles.ordDarkBlueText} fw-bold`} mb={0}>
                Conteo general
              </Box>

              <Text title='Recaudos en efectivo' classNameBox='mb-1' />
            </Box>

            <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
              <Box className='tableScroll'>
                <OrdTable
                  className='mt-0'
                  headers={headersCashClosing}
                  hasChildren={true}
                  oneHeadDarkBlue={false}
                  lowerCase={false}
                >
                  {formatData(listCashClosingCurrencyCounted?.results?.cashDetail || [])}
                </OrdTable>
              </Box>

              <Box className='tableScroll my-4'>
                <Text title='Transferencias a bolsillo' classNameBox='mb-1' />

                <OrdTable
                  className='mt-0'
                  headers={headersWalletMovements}
                  hasChildren={true}
                  oneHeadDarkBlue={false}
                  lowerCase={false}
                >
                  {formatWalletMovements(
                    listCashClosingCurrencyCounted?.results?.walletMovements,
                  ) || []}
                </OrdTable>
              </Box>

              <Box className='ms-1 animate__animated animate__fadeIn mt-auto'>
                <Box
                  borderRadius={3}
                  paddingX={3}
                  paddingY={2}
                  bgcolor={'#F5FCFD'}
                  className='d-flex gap-3 mt-4'
                >
                  <Box>
                    <Text title='Total contado' fontSize={'12px'} classNameBox={'ms-1'} />
                    <NumberFormat
                      disabled
                      allowNegative={false}
                      className={`register-inputs ${styles.inputPlaceholder}`}
                      placeholder='$...'
                      style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      isNumericString={true}
                      prefix={'$'}
                      value={listCashClosingCurrencyCounted?.results?.userTotalAmount || 0}
                    />
                  </Box>

                  <Box>
                    <Text title='Total en el sistema' fontSize={'12px'} classNameBox={'ms-1'} />
                    <NumberFormat
                      disabled
                      allowNegative={false}
                      className={`register-inputs ${styles.inputPlaceholder}`}
                      placeholder='$...'
                      style={{ maxHeight: '32px', height: '32px', borderColor: '#A3BAD1' }}
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      isNumericString={true}
                      prefix={'$'}
                      value={listCashClosingCurrencyCounted?.results?.systemTotalAmount || 0}
                    />
                  </Box>

                  <Box>
                    <Text title='Diferencia' fontSize={'12px'} classNameBox={'ms-1'} />
                    <input
                      disabled
                      type='text'
                      className={`register-inputs ${styles.inputPlaceholder}`}
                      style={{
                        maxHeight: '32px',
                        height: '32px',
                        borderColor: '#A3BAD1',
                        color:
                          Number(listCashClosingCurrencyCounted?.results?.userTotalAmount) -
                            Number(listCashClosingCurrencyCounted?.results?.systemTotalAmount) <
                          0
                            ? '#F39682'
                            : '#58595b',
                      }}
                      value={`${formatNumberWithoutDecimals(
                        Number(listCashClosingCurrencyCounted?.results?.userTotalAmount)
                          -
                          Number(listCashClosingCurrencyCounted?.results?.systemTotalAmount)
                          > 0
                          ? 0 :
                          Number(listCashClosingCurrencyCounted?.results?.userTotalAmount)
                          -
                          Number(listCashClosingCurrencyCounted?.results?.systemTotalAmount)
                      )}`
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor={'right'}
        open={drawerVoucher}
        onClose={() => {
          setDrawerVoucher(false);
          setDrawerDetail(true);
          setVoucherDepositTypeName(undefined);
          setFiltersListCashClosingCurrency(state => ({
            ...state,
            depositTypeId: undefined,
          }));
        }}
      >
        <Box padding={3}>
          <Box mb={2}>
            <img
              className={`${styles.closeDrawerAsistencial} pointer`}
              src={iconClose}
              alt='close'
              onClick={() => {
                setDrawerVoucher(false);
                setDrawerDetail(true);
                setVoucherDepositTypeName(undefined);
                setFiltersListCashClosingCurrency(state => ({
                  ...state,
                  depositTypeId: undefined,
                }));
              }}
              height={'28px'}
              width={'28px'}
            />
          </Box>

          <Box paddingX={4}>
            <Box>
              <Box fontSize={'36px'} className={`${styles.ordDarkBlueText} fw-bold`} mb={0}>
                Listado de{' '}
                {voucherDepositTypeName?.toLowerCase() === 'transfer'
                  ? 'transferencias'
                  : 'vouchers'}
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} height={'80vh'}>
              <OrdTable
                shadow
                headers={headersDetailCashClosing}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                style={{ minWidth: '500px' }}
                paginate={{
                  activePage: filtersListCashClosingCurrency.page,
                  totalPages: listCashClosingCurrency?.rowTotal,
                  perPage: filtersListCashClosingCurrency.perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async e => {
                    setTriggerFiltersListCashClosingCurrency(state => state + 1);
                    setFiltersListCashClosingCurrency(state => ({
                      ...state,
                      page: e,
                    }));
                  },
                }}
              >
                {formatDataVoucher(listCashClosingCurrency?.results || [])}
              </OrdTable>

              <Box display='flex' columnGap='10px' justifyContent={'start'}>
                <Button
                  backgroundHover={colors.ordAquaMarine}
                  backgroundColorHover={colors.ordAquaMarine}
                  backgroundColor={'white'}
                  color={colors.ordAquaMarine}
                  colorHover='white'
                  width='100px'
                  padding='8px'
                  fontSize='14px'
                  onClick={() => {
                    setDrawerVoucher(false);
                    setDrawerDetail(true);
                    setFiltersListCashClosingCurrency(state => ({
                      ...state,
                      depositTypeId: undefined,
                    }));
                  }}
                >
                  Regresar
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
