import { Box } from '@mui/material';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import Filtrar from '../../assets/img/icons/Filtrar.svg';
import Atras from '../../assets/img/icons/atras-icon.svg';
import excel from '../../assets/img/icons/excel.svg';

import GenericTable from '../Layouts/GenericTableNew';
import ModalNew from '../Layouts/ModalNew';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';

function DetailPayrollProvision(props) {
  let history = useHistory();

  const [showModal, setShowModal] = useState(false);

  function handleClick() {
    history.push('/contabilidad/autorizacionNomina');
  }

  const header = [
    <th key={1} className='col text-center'>
      Nombre
    </th>,
    <th key={2} className='text-center'>
      Total
    </th>,
    <th key={3} className='col text-center'></th>,
  ];
  const body = [
    <tr key={1}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={2}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={3}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={4}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={5}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={6}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={7}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={8}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={9}>
      <td className='text-center'>Prestamos </td>
      <td className='text-center'>$7.000.000</td>
      <td className='text-center'>
        <img
          alt=''
          className='text-start pointer'
          src={Filtrar}
          onClick={() => setShowModal(true)}
        />
      </td>
    </tr>,
    <tr key={10}>
      <td className='text-center fw-bold' style={{ color: '#58595b' }}>
        TOTAL{' '}
      </td>
      <td className='text-center fw-bold' style={{ color: '#58595b' }}>
        $25.000.000
      </td>
      <td className='text-center'></td>
    </tr>,
  ];
  const headerModal = [
    <th key={1} className='col text-center'>
      ID
    </th>,
    <th key={2} className='text-center'>
      Empleado
    </th>,
    <th key={3} className='col text-center'>
      Total
    </th>,
  ];
  const bodyModal = [
    <tr key={1}>
      <td className='text-center'>1.145.654.984 </td>
      <td className='text-center'>Melisa Villanueva</td>
      <td className='text-center'>$500.000</td>
    </tr>,
    <tr key={2}>
      <td className='text-center'>1.145.654.984 </td>
      <td className='text-center'>Melisa Villanueva</td>
      <td className='text-center'>$500.000</td>
    </tr>,
    <tr key={3}>
      <td className='text-center'>1.145.654.984 </td>
      <td className='text-center'>Melisa Villanueva</td>
      <td className='text-center'>$500.000</td>
    </tr>,
    <tr key={4}>
      <td className='text-center'>1.145.654.984 </td>
      <td className='text-center'>Melisa Villanueva</td>
      <td className='text-center'>$500.000</td>
    </tr>,
    <tr key={5}>
      <td className='text-center'></td>
      <td className='text-center fw-bold' style={{ color: '#58595b' }}>
        TOTAL
      </td>
      <td className='text-center fw-bold' style={{ color: '#58595b' }}>
        $5000.000
      </td>
    </tr>,
  ];

  return (
    <>
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ width: '50%', marginRight: '3rem' }}
      >
        <div>
          <div className='d-flex'>
            <img
              className={`${tableStyles.title} mr-3 pointer`}
              alt=''
              src={Atras}
              onClick={() => props.setDetails({ show: false })}
            />
            <label className={tableStyles.titlePayrollProvision}>
              <b>Diciembre 30 - 2021</b> <br />
              <span style={{ fontSize: '18px' }}>Provisión de nómina </span>
            </label>
          </div>

          <Row>
            <Col xs={8}>
              <label className={tableStyles.subTitlePayrollProvision}>Aportes patronales</label>
            </Col>
            <Col xs={4} className='d-flex justify-content-end align-items-center'>
              <img src={excel} alt='excel' />
            </Col>
          </Row>
        </div>
        <GenericTable headers={header} dark={true}>
          {body}
        </GenericTable>

        <btnMui
          style={{ textTransform: 'none', marginLeft: 'auto' }}
          className={tableStyles.btnCancelStyle}
          onClick={() => {
            handleClick();
          }}
        >
          Cancelar
        </btnMui>
      </div>
      <ModalNew
        show={showModal}
        onHide={() => setShowModal(false)}
        title={'Detalle de deducción'}
        subtitle={'Libranza ITAU'}
        btnYesEvent={false}
        size={'500'}
      >
        <Box display={'flex'} justifyContent='end'>
          <img src={excel} alt='excel' />
        </Box>

        <GenericTable headers={headerModal}>{bodyModal}</GenericTable>
      </ModalNew>
    </>
  );
}
export default DetailPayrollProvision;
