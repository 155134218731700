import {
  STORAGE_GET,
  STORAGE_EDIT,
  STORAGE_SUPPLIER,
  STORAGE_POST,
  ARTICLE_GET,
  DELETE_WAREHOUSE_ARTICLE,
} from '../actions/actionTypes';

const initialState = {
  list: [],
  list_new: [],
  total: 0,
  articles: [],
  error: null,
  Authorization: '',
  row_total: 0,
  total_index: 0,
  warehouse_edit: null,
  storage_loading: false,
  isLoading: false,
};
export const storageReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORAGE_SUPPLIER:
      return {
        ...state,
        list_new: action.payload,
      };

    case ARTICLE_GET:
      return {
        ...state,
        articles: action.payload,
      };

    case STORAGE_GET:
      return {
        ...state,
        list: action.results,
        total: action.row_total,
        error: null,
        total_index: action.total_index,
        storage_loading: action.loading,
        isLoading: action.loading,
      };

    case STORAGE_EDIT:
      return {
        ...state,
        warehouse_edit: action.payload,
        error: null,
      };

    case STORAGE_POST:
      return {
        ...state,
        error: null,
      };
    case DELETE_WAREHOUSE_ARTICLE:
      return {
        ...state,
        articleDelete: action.payload,
      };

    default:
      return state;
  }
};
