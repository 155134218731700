// components
import Select from 'react-select';
import { Title } from "../styled";
import { Alert } from '../../components/TableStatic/styled';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { ordCustomSelect } from "../../components/Layouts/react-select-custom";
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';

// styles
import 'react-datepicker/dist/react-datepicker.css';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

// helpers
import { loader } from '../../helpers/helpers';

export const AssignFeesComponent = ({ data, actions, loadings }) => {

    return (
        <>
            {(loadings.loadListCompanys || loadings.loadListServices) && loader}

            <div className='container-fluid'>
                <OrdGenericTemplate
                    showBackArrow={true}
                    title='Honorarios médicos'
                    style={data.listServices?.results?.length ? { width: '725px', marginLeft: 30 } : {}}
                    titleStyle={{ width: '100%' }}
                    backArrowAction={() => actions.goToBack()}
                >
                    <div style={{ width: 200, marginLeft: 30 }}>
                        <Title className={`mx-1 ${tableStyles?.ordDarkBlueText}`}>Tipo de Compañía</Title>
                        <Select
                            noOptionsMessage={() => 'No hay datos'}
                            options={data.options}
                            className='text-secondary'
                            placeholder={'Seleccione'}
                            styles={ordCustomSelect}
                            onChange={actions.handleChangeSelect}
                        />
                    </div>

                    {!data.listServices?.results?.length ? (
                        <Alert>Seleccione un Grupo de Atención</Alert>
                    ) : (
                        <OrdTable
                            shadow
                            headers={data.header}
                            hasChildren={true}
                            paginationStyles={{ marginLeft: 30 }}
                            style={{ marginTop: 20, width: '650px', marginLeft: 30 }}
                        >
                            {data.formatData(data.listServices.results)}
                        </OrdTable>
                    )}
                </OrdGenericTemplate>
            </div >
        </>
    );
};
