import {
  GET_CHECK_DEPOSIT_BANK,
  GET_DEPOSIT_BANK,
  GET_ONE_DEPOSIT_BANK,
  GET_SELECT_MONTH,
  CREATE_CONSIGNMENT,
} from "../actions/actionTypes";

const initialState = {
  combinedList: [],
  deposits_total: 0,
  combinedListCheck: [],
  deposits_total_check: 0,
  results: [],
  resultsOne: [],
  creatC_loading: false,
  deposits_total_peding: 0,
  deposits_total_peding_one: 0,
};
export const depositBankReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPOSIT_BANK:
      return {
        ...state,
        combinedList: action.payload,
        deposits_total: action.total,
      };
    case GET_CHECK_DEPOSIT_BANK:
      return {
        ...state,
        combinedListCheck: action.payload,
        deposits_total_check: action.total,
        deposits_total_peding: action.deposits_total_peding,
      };
    case GET_SELECT_MONTH:
      return {
        ...state,
        results: action.payload,
      };
    case GET_ONE_DEPOSIT_BANK:
      return {
        ...state,
        resultsOne: action.payload,
        totalOne: action.total,
        deposits_total_peding_one: action.deposits_total_peding,
      };
    case CREATE_CONSIGNMENT:
      return {
        ...state,
        creatC_loading: action.loading,
      };
    default:
      return state;
  }
};
