import {
  GO_TO_SALIDA_CONSUMO,
  LOTS_FROM_WAREHOUSE,
  LOTS_FROM_WAREHOUSE_LOADING,
} from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

function responseError(message) {
  return customSwaltAlert({
    icon: 'warning',
    title: 'Intenta de nuevo',
    text: message,
    showCancelButton: false,
  });
}

export const update_salida_consumo_reducer = (reqIds, mainWarehouse, centroConsumo) => dispatch => {
  dispatch({
    type: GO_TO_SALIDA_CONSUMO,
    reqIds: reqIds,
    mainWarehouse: mainWarehouse,
    centroConsumo: centroConsumo,
  });
};

export const lots_from_warehouse = objFilters => (dispatch, getState) => {
  dispatch({
    type: LOTS_FROM_WAREHOUSE_LOADING,
    loading: true,
  });
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory/lot/fromwarehouse/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: LOTS_FROM_WAREHOUSE,
        lots: results,
        lots_total: row_total,
        loading: false,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch({
        type: LOTS_FROM_WAREHOUSE_LOADING,
        loading: false,
      });
    });
};

export const go_go_salida_consumo =
  (data, executeThisAfter = null) =>
  (dispatch, getState) => {
    if (!data.cc_id) return responseError('Debe escoger un Centro de Consumo valido');
    if (!data.warehouse_id) return responseError('Debe escoger un Almacén Principal');
    if (data.warehousearticlesreserves.length <= 0)
      return responseError('Se requiere una lista de articulos');
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory/salidaconsumo/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(res => {
        if (res.success) {
          customSwaltAlert({
            icon: 'success',
            title: 'Salida a Consumo realizada',
            html: `Reservas consumadas. Transferencia de almacén realizada`,
            showCancelButton: false,
          }).then(() => {
            if (executeThisAfter) {
              executeThisAfter();
            }
          });
        } else {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${res.message}`,
            showCancelButton: false,
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
