import { Form } from 'react-bootstrap';
import styles from '../../../components/Layouts/tableStyle.module.scss';
import { companyTypeTags } from '../../../constants';


export const VoluntaryInsuranceInfo = ({ contract }) => {
    if (!contract) return null

    return (
        <div className={styles.app_container_insurance_voluntary}>
            <div>
                <span className={styles.app_insurer_voluntary}>Aseguradora voluntaria</span>
            </div>
            <div className={styles.app_container_form_register}>
                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Tipo</b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={contract.companyTypeName}
                            placeholder='-'
                        />
                    </Form.Group>
                </div>

                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Empresa</b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={contract.corporateClient}
                            placeholder='-'
                        />
                    </Form.Group>
                </div>

                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Contrato</b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={contract.contractName}
                            placeholder='-'
                        />
                    </Form.Group>
                </div>

                {contract.companyTypeTag !== companyTypeTags.PREPAID && (
                    <div className={styles.app_container_content_form}>
                        <Form.Group controlId='fName'>
                            <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                <b className='px-1'>Población</b>
                            </Form.Label>
                            <Form.Control
                                className={styles.app_inputs_detail_patient}
                                type='text'
                                disabled
                                value={contract.population}
                                placeholder='-'
                            />
                        </Form.Group>
                    </div>
                )}

                <div className={styles.app_container_content_form}>
                    <Form.Group controlId='fName'>
                        <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                            <b className='px-1'>Grupo de ingreso</b>
                        </Form.Label>
                        <Form.Control
                            className={styles.app_inputs_detail_patient}
                            type='text'
                            disabled
                            value={contract.crtName}
                            placeholder='-'
                        />
                    </Form.Group>
                </div>

                {contract.companyTypeTag === companyTypeTags.SPECIAL_AGREEMENT
                    ? null
                    : (
                        <div className={styles.app_container_content_form}>
                            <Form.Group controlId='fName'>
                                <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
                                    <b className='px-1'>No. de Póliza</b>
                                </Form.Label>
                                <Form.Control
                                    className={styles.app_inputs_detail_patient}
                                    type='text'
                                    disabled
                                    value={contract.policy}
                                    placeholder='-'
                                />
                            </Form.Group>
                        </div>
                    )}
            </div>
        </div>
    )
}