/* eslint-disable no-undef */
export const getEnvs = () => {
  //Medical
  const URL_PROTOCOL = process.env.REACT_APP_URL_PROTOCOL;
  const URL_BASE = process.env.REACT_APP_URL_BASE;
  const URL_GATEWAY = process.env.REACT_APP_URL_GATEWAY;
  const API_VERSION = process.env.REACT_APP_API_VERSION;
  const URL_MEDICAL = process.env.REACT_APP_URL_MEDICAL;

  const URL_ADMINISTRATION_SPA = process.env.REACT_APP_URL_ADMINISTRATION;

  //AWS
  const ACCESS_KEY_ID_AWS = process.env.REACT_APP_ACCESS_KEY_ID_AWS;
  const SECRET_ACCESS_KEY_AWS = process.env.REACT_APP_SECRET_ACCESS_KEY_AWS;
  const REGION_AWS = process.env.REACT_APP_REGION_AWS;
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

  // APP SETTINGS
  const APP_LANGUAGE = process.env.REACT_APP_LANGUAGE
  const CURRENCY = process.env.REACT_APP_CURRENCY

  return {
    URL_BASE,
    URL_GATEWAY,
    API_VERSION,
    URL_MEDICAL,
    URL_ADMINISTRATION_SPA,
    ACCESS_KEY_ID_AWS,
    SECRET_ACCESS_KEY_AWS,
    REGION_AWS,
    URL_PROTOCOL,
    BUCKET_NAME,
    APP_LANGUAGE,
    CURRENCY,
  };
};
