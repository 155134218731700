import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { AddCircleOutline, Close, Delete } from '@material-ui/icons';
import ReactSelect from 'react-select';
//
import { generateId } from '../../../../helpers';
//
import modalStyles from '../../../../components/Layouts/modalnew.module.scss';
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import { customSelectNewDark } from '../../../../components/Layouts/react-select-custom';

export default function ModifyRipsData({
  ripsData = [],
  equivalent = [],
  isOpen = false,
  onClose,
  onSubmit,
}) {
  const [creatableRipsData, setCreatableRipsData] = useState([]);

  useEffect(() => {
    if (ripsData && isOpen) {
      const formatted = ripsData.map(item => ({
        index: generateId(),
        ...item,
      }));
      setCreatableRipsData(formatted);
    }
  }, [ripsData, isOpen]);

  const onCloseModifyModal = () => {
    onClose();
    setCreatableRipsData([]);
  };

  const onAddRips = () => {
    const data = [
      {
        index: generateId(),
        name: '',
        equivalent: '',
      },
      ...creatableRipsData,
    ];
    setCreatableRipsData(data);
  };

  const onChangePayload = (index, value, key) => {
    const data = creatableRipsData.map(item => {
      if (item.index === index) return { ...item, [key]: value };
      return item;
    });
    setCreatableRipsData(data);
  };

  const removeRipsData = index => {
    const data = creatableRipsData.filter(item => item.index !== index);
    setCreatableRipsData(data);
  };

  const renderTable = () => {
    return (
      <>
        <div
          className='row g-0 py-2 px-2 text-white fw-bold small'
          style={{ background: '#005dbf', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
        >
          <div className='col'>Datos RIPS</div>
          <div className='col ps-2'>Homólogo</div>
          <div className='col-1 pe-2'>Acciones</div>
        </div>

        <div className={`${tableStyles.odd_rows} overflow-auto small`} style={{ maxHeight: 200 }}>
          {creatableRipsData?.map(item => (
            <div key={item.index} className='row g-0 align-items-center py-2'>
              <div className='col ps-2'>
                <input
                  type='text'
                  className='register-inputs'
                  value={item.name}
                  placeholder='Escribe...'
                  onChange={({ target }) => onChangePayload(item.index, target.value, 'name')}
                />
              </div>
              <div className='col ps-2'>
                <ReactSelect
                  noOptionsMessage={() => 'No hay datos'}
                  styles={customSelectNewDark}
                  placeholder='Seleccionar...'
                  options={equivalent}
                  value={equivalent.find(el => el.value === item.equivalent)}
                  onChange={option => onChangePayload(item.index, option.value, 'equivalent')}
                />
              </div>
              <div className='col-1 text-center text-muted'>
                {!item.id && (
                  <Delete
                    fontSize='small'
                    className='pointer hoverable'
                    onClick={() => removeRipsData(item.index)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const render = () => {
    return (
      <Modal show={isOpen} centered className={`${modalStyles.container}`} size='lg'>
        <div className='d-flex align-items-center justify-content-between pt-3 py-2 px-4 border-bottom'>
          <div></div>
          <h3 className={`${tableStyles.text_primary_alt} m-0 fw-bold text-center`}>
            Modificar Datos RIPS
          </h3>
          <Close className='pointer text-secondary' onClick={onCloseModifyModal} />
        </div>

        <div className='d-flex flex-column p-4 pt-0'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='text-muted small'>Crea o modifica datos RIPS.</div>
            <div
              className={`${tableStyles.buttonTextPrimary} align-self-end my-2`}
              onClick={onAddRips}
            >
              <small className='mr-2' style={{ whiteSpace: 'nowrap' }}>
                Agregar datos RIPS
              </small>
              <AddCircleOutline stroke='2' fontSize='small' />
            </div>
          </div>

          {renderTable()}
          <div className='align-self-end mt-4'>
            <button onClick={onCloseModifyModal} className={`${tableStyles.btnSecondary} me-3`}>
              Cancelar
            </button>
            <button
              className={`${tableStyles.btnPrimaryAlt}`}
              onClick={() => onSubmit(creatableRipsData)}
            >
              Guardar
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return render();
}
