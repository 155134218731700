import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import styles from '../components/Layouts/tableStyle.module.scss';
import siteIcon from '../assets/img/icons/siteIcon.svg';
import { useDispatch } from 'react-redux';
import { SITE_SELECTED } from '../actions/actionTypes';
import { useGetMethod } from './../Hooks/useFetch';
import { loader } from '../helpers/helpers';
import { useSelector } from 'react-redux';

const SelectSites = ({ siteSelected, setSiteSelected }) => {
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const userId = store.loginReducer.user_data.id;
  const eaccount = store.loginReducer.currentAccount.id;

  const token = store.loginReducer.Authorization;

  const { results: listSites, trigger: getSites, load: loadSites } = useGetMethod();

  React.useEffect(() => {
    getSites({
      url: `/medical/admissions/sites/`,
      objFilters: { eaccount, userId, status: 1 },
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notSites = () => {
    return (
      <Alert variant={'danger'} className='w-75 text-center fw-bold m-auto'>
        No hay asignaciones de sedes
      </Alert>
    );
  };

  return (
    <>
      {loadSites && loader}

      {!listSites.rowTotal && !loadSites ? (
        notSites()
      ) : listSites.rowTotal && !loadSites ? (
        <div className='d-flex flex-column align-items-center my-auto align-items-center w-100 px-5 mx-5'>
          <span className={`${styles.tlnTextGray} ${styles.f24}`}>
            Selecciona la sede en la que te encuentras
          </span>

          <Row className='my-4 d-flex w-100 px-5'>
            {listSites?.results?.sites?.map(site => {
              return (
                <>
                  <Col xs={3} className=' d-flex justify-content-center my-2'>
                    <button
                      className={`${styles.addCardSite} w-100 cursorPointer d-flex flex-column justify-content-center align-items-center`}
                      onClick={() => {
                        setSiteSelected({
                          ...siteSelected,
                          siteId: site.siteId,
                          siteName: site.siteName,
                          resolutions: site?.resoultions
                        });
                        dispatch({
                          type: SITE_SELECTED,
                          payload: {
                            siteId: site.siteId,
                            siteName: site.siteName,
                            resolutions: site?.resolutions
                          },
                        });
                      }}
                    >
                      <img alt='siteIcon' src={siteIcon} />
                      <span className={`${styles.tlnTextGray}`}>{site.siteName}</span>
                    </button>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default SelectSites;
