//All actions for user component
import {
  GET_INVENTORY_FAMILY,
  SET_INDEX_INVENTORY_FAMILY,
  GET_NIIF_AUX,
  GET_ADD_INFO,
  INFO_ID,
  GET_INVENTORY_ACTIVE,
  LOADING,
} from './actionTypes';
import { URL_GATEWAY, API_VERSION } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { customSwaltAlert } from '../helpers/customSwaltAlert';

export const getIF = objFilters => (dispatch, getState) => {
  dispatch({
    type: GET_INVENTORY_FAMILY,
    payload: [],
    loading: true,
  });
  const id_account = getState().loginReducer.currentAccount.id;
  objFilters.id_account = id_account;
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/params/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;

      dispatch({
        type: GET_INVENTORY_FAMILY,
        payload: results,
        loading: false,
      });
      dispatch({
        type: SET_INDEX_INVENTORY_FAMILY,
        payload: row_total,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_INVENTORY_FAMILY,
        payload: [],
        loading: false,
      });
    });
};

export const get_active_inventory = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  dispatch({
    type: LOADING,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/active/?id_account=${id_account}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { data } = res;
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: GET_INVENTORY_ACTIVE,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        loading: false,
      });
    });
};

export const getNiif = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  dispatch({
    type: LOADING,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/admin/niifforinventory/?entity_account=${id_account}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { niifs } = res;
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: GET_NIIF_AUX,
        payload: niifs,
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        loading: false,
      });
    });
};

export const getInfo = () => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  dispatch({
    type: LOADING,
    loading: true,
  });
  fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/infoAdicional/`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',

      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { data } = res;
      dispatch({
        type: LOADING,
        loading: false,
      });
      dispatch({
        type: GET_ADD_INFO,
        payload: data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOADING,
        loading: false,
      });
    });
};

export const activationDeactivation =
  (id, status, filters, description) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    let active = status ? 'deshabilitado' : 'habilitado';
    if (status === true) {
      status = 0;
    } else if (status === false) {
      status = 1;
    }

    let data = {
      id: id,
      active: status,
    };
    if (id != null && status != null) {
      customSwaltAlert({
        icon: 'warning',
        title: `¿Está seguro?`,
        text: `Se ${!status ? 'deshabilitará' : 'habilitará'} la familia de inventario: ${description}`,
        showCancelButton: true,
      }).then(result => {
        if (result.isConfirmed) {
          dispatch({
            type: LOADING,
            loading: true,
          });
          fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/activate/`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',

              Authorization: token,
            },
          })
            .then(response => response.json())
            .then(res => {
              dispatch({
                type: LOADING,
                loading: false,
              });
              if (res.status || !res.message) {
                customSwaltAlert({
                  icon: 'success',
                  title: 'Actualizado exitosamente',
                  text: `Se ha  ${active} la familia de inventario: ${description}`,
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false,
                });
                dispatch(getIF(filters));
              } else {
                dispatch({
                  type: LOADING,
                  loading: false,
                });
              }
            })
            .catch(() => {
              dispatch({
                type: LOADING,
                loading: false,
              });

              customSwaltAlert({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: 'No se pudo cambiar el estado',
                footer: 'Si el problema persiste comuníquese con un asesor.',
                confirmButtonText: 'Aceptar',
                showCancelButton: false,
              });
            });
        }
      });
    } else {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe ingresar correo y contraseña para acceder al sistema.',
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
    }
  };

export const updateIF = (obj, filters) => (dispatch, getState) => {
  const token = getState().loginReducer.Authorization;
  const { description, measurements_list } = obj;
  let ainfo = [];
  let mlist = [];
  for (let m in obj.additional_info) {
    if (typeof obj.additional_info[m] != 'number') {
      ainfo.push(obj.additional_info[m].id);
    } else {
      ainfo.push(obj.additional_info[m]);
    }
  }

  for (let m in obj.measurements_list) {
    if (typeof obj.measurements_list[m] != 'number') {
      mlist.push(obj.measurements_list[m].id);
    } else {
      mlist.push(obj.measurements_list[m]);
    }
  }

  const res = {
    niff_id: obj.niff_id,
    description: obj.description,
    measurements_list: mlist,
    additional_info: ainfo,
    provider_classification: obj.provider_classification,
    isOrdering: obj.isOrdering,
  };
  if (description !== '' && measurements_list[0] !== undefined) {
    dispatch({
      type: LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/${obj.id}`, {
      method: 'PUT',
      body: JSON.stringify(res),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha actualizado la familia de inventario: ${obj.description}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        dispatch(getIF(filters));
      })
      .catch(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al intentar conectarse, por favor intente de nuevo.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      });
  } else {
    dispatch({
      type: LOADING,
      loading: false,
    });

    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar los datos obligatorios para actualizar el registro.',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};

export const createIF = (obj, filters) => (dispatch, getState) => {
  if (!obj.description) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: `El nombre de la familia es un campo obligatorio`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }

  if (!obj.niff_id) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: `Debe escoger una cuenta de inventario valida`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }

  if (obj.measurements_list?.length <= 0) {
    return customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: `Debe escoger una unidad de medida valida`,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }

  const token = getState().loginReducer.Authorization;
  const id_account = getState().loginReducer.currentAccount.id;
  const { description, measurements_list, niff_id } = obj;
  let ainfo = [];
  for (let m in obj.additional_info) {
    if (typeof obj.additional_info[m] != 'number') {
      ainfo.push(obj.additional_info[m].id);
    } else {
      ainfo.push(obj.additional_info[m]);
    }
  }
  const res = {
    active: true,
    additional_info: ainfo,
    description: description,
    measurements_list: measurements_list,
    niff_id: niff_id,
    id_account: id_account,
    provider_classification: obj.provider_classification,
    isOrdering: obj.isOrdering,
  };
  if (description !== '' && measurements_list[0] !== undefined) {
    dispatch({
      type: LOADING,
      loading: true,
    });
    fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/`, {
      method: 'POST',
      body: JSON.stringify(res),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    })
      .then(response => response.json())
      .then(data => {
        const { status, message } = data;
        dispatch({
          type: LOADING,
          loading: false,
        });
        if (status !== 'fail') {
          customSwaltAlert({
            icon: 'success',
            title: 'Creado exitosamente',
            text: `Se ha creado la familia de inventario: ${description}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
          dispatch(getIF(filters));
        } else {
          dispatch({
            type: LOADING,
            loading: false,
          });

          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: `${message}`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: LOADING,
          loading: false,
        });

        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: 'Ocurrió un error al intentar conectarse, por favor intente de nuevo.',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      });
  } else {
    customSwaltAlert({
      icon: 'warning',
      title: 'Intenta de nuevo',
      text: 'Debe ingresar los datos obligatorios para crear la familia de inventario.',
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  }
};

export const createInfo = obj => async (dispatch, getState) => {
  try {
    const token = getState().loginReducer.Authorization;

    dispatch({
      type: LOADING,
      loading: true,
    });
    const response = await fetch(`${URL_GATEWAY}${API_VERSION}/inventory_Family/infoAdicional/`, {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: token,
      },
    });
    if (!response.ok) throw await response.json();

    const data = await response.json();
    const { result } = data;
    dispatch({
      type: LOADING,
      loading: false,
    });
    dispatch({
      type: INFO_ID,
      payload: result,
    });
  } catch (error) {
    customSwaltAlert({
      icon: 'error',
      title: 'Intenta de nuevo',
      text: error.message,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
    return error;
  } finally {
    dispatch({
      type: LOADING,
      loading: false,
    });
  }
};
