import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import GenericTableScroll from '../Layouts/GenericTableScroll';

import Alert from '../../assets/img/icons/alert-circle-orange.svg';
import Atras from '../../assets/img/icons/atras-icon.svg';
import CheckNew from '../../assets/img/icons/check2.svg';
import Check2 from '../../assets/img/icons/extraBtnCheck.svg';
import Reject from '../../assets/img/icons/extraBtnRedX.svg';

import {
  creatPayroll,
  getListPayrollAuthorization,
  getOnePayrollAuthorization,
} from '../../actions/accountingMovementsActions';

import ModalNew from '../Layouts/ModalNew';

import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import Styles from './movementsStyles.module.scss';

function DetaliPayrollAuthorization(props) {
  const MySwal = withReactContent(Swal);
  const dispatch = useDispatch();
  const storage = useSelector(state => state);
  const [showModalRejected, setShowModalRejected] = useState({
    show: false,
    justify: '',
  });

  useEffect(() => {
    if (props.selectedPayroll) {
      dispatch(
        getOnePayrollAuthorization({
          eaccount: storage.loginReducer.currentAccount.id,
          id: props.selectedPayroll,
        }),
      );
    }
  }, [dispatch, props.selectedPayroll, storage.loginReducer.currentAccount.id]);

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const header = [
    <div key={1} className={`col-3`}>
      Empleado
    </div>,
    <div key={2} className={`col-1`}>
      Cuenta
    </div>,
    <div key={3} className={`col-2`}>
      Salario
    </div>,
    <div key={4} className={`col-2`}>
      Devengado
    </div>,
    <div key={5} className={`col-2`}>
      Deducciones
    </div>,
    <div key={6} className={`col-2`}>
      Total
    </div>,
  ];

  const renderList = () => {
    let tempList = [];
    if (Array.isArray(storage.accountingMovementsReducer.onePayrollAuthorization?.detail)) {
      storage.accountingMovementsReducer.onePayrollAuthorization.detail.forEach(item => {
        tempList.push(
          <section2 className={`d-flex`}>
            <div className='col-3'>{item.employeeName}</div>
            <div className='col-1'>{item.niifAccountNbr}</div>
            <div className='col-2'>{item.salary ? '$' + numberWithCommas(item.salary) : '$0'}</div>
            <div className='col-2'>
              {item.received ? '$' + numberWithCommas(item.received) : '$0'}
            </div>
            <div className='col-2'>
              {item.discount ? '$' + numberWithCommas(item.discount) : '$0'}
            </div>
            <div className='col-2'>
              {item.totalAmount ? '$' + numberWithCommas(item.totalAmount) : '$0'}
            </div>
          </section2>,
        );
      });
    }
    return tempList;
  };

  //--------------  Actions  🍕   --------------
  const approvedPayroll = () => {
    MySwal.fire({
      title: `¿Está seguro?`,
      text:
        'Se autorizará el pago de la planilla de nómina ' +
        props.month +
        ' ' +
        props.day +
        ' - ' +
        props.year,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        dispatch(
          creatPayroll(
            {
              eaccount: storage.loginReducer.currentAccount.id,
              payroll: props.selectedPayroll,
              status: 'approved',
              user: storage.loginReducer.user_data.id,
            },
            () => {
              props.setDetails({ show: false });
              dispatch(
                getListPayrollAuthorization({
                  eaccount: storage.loginReducer.currentAccount.id,
                  module: 'payrollAuth',
                }),
              );
            },
          ),
        );
      }
    });
  };
  const rejectedPayroll = () => {
    MySwal.fire({
      title: `¿Está seguro?`,
      text:
        'Se denegará el pago de la planilla de nómina ' +
        props.month +
        ' ' +
        props.day +
        ' - ' +
        props.year,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#003f80',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Continuar',
      cancelButtonText: 'Cancelar',
    }).then(response => {
      if (response.isConfirmed) {
        setShowModalRejected({ show: true });
      }
    });
  };

  const sendrejectedPayroll = () => {
    dispatch(
      creatPayroll(
        {
          eaccount: storage.loginReducer.currentAccount.id,
          payroll: props.selectedPayroll,
          status: 'rejected',
          user: storage.loginReducer.user_data.id,
          rejected: showModalRejected.justify,
        },
        () => {
          props.setDetails({ show: false });
          dispatch(
            getListPayrollAuthorization({
              eaccount: storage.loginReducer.currentAccount.id,
              module: 'payrollAuth',
            }),
          );
        },
      ),
    );
  };

  return (
    <>
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div>
          <div className='d-flex'>
            <img
              alt='back'
              className={`${tableStyles.title} mr-3 hoverPointer`}
              style={{ marginBottom: '3px' }}
              src={Atras}
              onClick={() => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollAuth',
                  }),
                );
              }}
            />
            <h1 className={tableStyles.title} style={{ marginBottom: '3px' }}>
              {props.month + ' ' + props.day + ' - ' + props.year}
            </h1>
          </div>

          {props.status === 'En revisión' ? (
            <label
              className='ml-5'
              style={{
                color: '#FF8B00',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              Revisión pendiente
              <img style={{ width: '20px', marginLeft: '5px' }} src={Alert} alt='' />
            </label>
          ) : (
            <label
              className='ml-5'
              style={{
                color: '#00A551',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              Planilla autorizada
              <img style={{ width: '20px', marginLeft: '5px' }} src={CheckNew} alt='' />
            </label>
          )}
        </div>
        <GenericTableScroll
          headers={header}
          dark={true}
          body={renderList()}
          typeHead={'2'}
        ></GenericTableScroll>

        {props.status === 'En revisión' ? (
          <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => approvedPayroll()}
              disabled={false}
            >
              <img src={Check2} alt='my' /> Aprobar
            </Button>

            <Button
              className={IndividualStyles.btnExtra}
              onClick={() => rejectedPayroll()}
              disabled={false}
            >
              <img src={Reject} alt='mys' /> Denegar
            </Button>

            <Button
              className={IndividualStyles.btnPrimary}
              onClick={() => {
                props.setDetails({ show: false });
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollAuth',
                  }),
                );
              }}
              disabled={false}
            >
              Cancelar
            </Button>
          </div>
        ) : (
          <div className={IndividualStyles.bottomRow} style={{ paddingRight: '0px' }}>
            <Button
              className={IndividualStyles.btnPrimary}
              disabled={false}
              onClick={() => {
                props.setDetails({ show: false });
                console.log('entro');
                dispatch(
                  getListPayrollAuthorization({
                    eaccount: storage.loginReducer.currentAccount.id,
                    module: 'payrollAuth',
                  }),
                );
              }}
            >
              Cancelar
            </Button>
          </div>
        )}

        <div>
          {/* MODAL JUSTIFICACIÓN DE DENEGACIÓN*/}
          <ModalNew
            title='Denegar plantilla'
            show={showModalRejected.show}
            btnYesName={'Guardar'}
            btnNoName={'Cancelar'}
            size='550'
            btnYesEvent={() => {
              sendrejectedPayroll();
              setShowModalRejected({ ...showModalRejected, show: false });
            }}
            btnYesDisabled={!!(showModalRejected.justify === '')}
            onHide={() => setShowModalRejected({ show: false })}
            btnNoEvent={() => setShowModalRejected({ show: false })}
          >
            <p className={`${Styles.labelBlue}`}> Justificación </p>
            <textarea
              className={`${Styles.inputsTextArea}`}
              name='justification'
              type='text'
              value={showModalRejected.justify}
              onChange={e =>
                setShowModalRejected({
                  ...showModalRejected,
                  justify: e.target.value,
                })
              }
            ></textarea>
            <p className={`${Styles.labelGrey}`}>
              *El mensaje será enviado al autor de la plantilla
            </p>
          </ModalNew>
        </div>
      </div>
    </>
  );
}
export default DetaliPayrollAuthorization;
