import upArray from '../../../../assets/img/icons/upArray.svg';
import downArray from '../../../../assets/img/icons/downArray.svg';
import tableStyles from '../../../Layouts/tableStyle.module.scss';
import { useState } from 'react';

const CardComponent = props => {
  const { title, subtitle, body } = props;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`${tableStyles.cardComponentSecurity} py-2 mb-3`}>
      <div onClick={() => setIsOpen(!isOpen)} className='cursorPointer'>
        <div className='d-flex justify-content-between'>
          <b className={`${tableStyles.darkGrayText} `}>{title}</b>
          <img src={isOpen ? upArray : downArray} alt='rowIcon' className='px-2 ' />
        </div>
        {!isOpen && <span className={`${tableStyles.ordClearGrayText} `}>{subtitle}</span>}
      </div>
      {isOpen && <div className='mb-2'>{body}</div>}
    </div>
  );
};

export default CardComponent;
