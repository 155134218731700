import {
  REQUISITION_ARTICLES_FROM_LIST,
  REQUISITION_LOADING2,
  REQUISITION_LOADING3,
  REQUISITION_FILTER,
  REQUISITION_FILTER_LOADING,
  REQUISITION_GET,
  REQUISITION_ARTICLE,
  REQUISITION_LOADING,
} from '../actions/actionTypes';

const initialState = {
  req_chosen: {},
  requisitions: [],
  articles: [],
  req_total: 0,
  requisitions_loading: false,
  centroconsumos: [],
  cc_total: 0,
  consec: '',
  error: null,
  loading: false,
  loading2: false,
  loading3: false,

  consolidate_art: [],
  consolidate_total: 0,
};

export const requisitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUISITION_FILTER:
      return {
        ...state,
        requisitions: action.results,
        req_total: action.row_total,
        requisitions_loading: action.loading,
        error: null,
      };

    case REQUISITION_FILTER_LOADING:
      return {
        ...state,
        requisitions_loading: action.loading,
      };
    case REQUISITION_GET:
      return {
        ...state,
        req_chosen: action.payload,
        loading: action.loading,
        error: null,
      };
    case REQUISITION_ARTICLE:
      return {
        ...state,
        centroconsumos: action.results,
        cc_total: action.row_total,
        articles: action.articles,
        error: null,
      };
    case REQUISITION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case REQUISITION_ARTICLES_FROM_LIST:
      return {
        ...state,
        consolidate_art: action.payload,
        consolidate_total: action.row_total,
        loading2: action.loading2,
      };
    case REQUISITION_LOADING2:
      return {
        ...state,
        loading2: action.loading2,
      };
    case REQUISITION_LOADING3:
      return {
        ...state,
        loading3: action.loading3,
      };
    default:
      return state;
  }
};
