import {
    GET_SPECIALITY, GET_ALL_SPECIALITY, SET_INDEX_SPECIALITY
} from "../actions/actionTypes";

const initialState = {
    specialities: [],
    allspecialities: [],
    totalspecialities: 0,
    displayError: false,
    error: null,
    Authorization:""
   
   };
export const specialitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SPECIALITY:
            return{
                ...state,
                specialities: action.payload,
                totalspecialities: action.total,
                Authorization:action.Authorization
            };
        case SET_INDEX_SPECIALITY:
            return{
                ...state,
                totalspecialities: action.payload,
                displayError: false,
                error: null,
                authorization: action.Authorization
            };
        case GET_ALL_SPECIALITY:
            return{
                ...state,
                allspecialities: action.payload,
                displayError: false,
                error: null,
                token:action.token
            };
    default:
        return state;
    }
    
}
