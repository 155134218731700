import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GenericTable from '../../components/Layouts/GenericTableNew';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { Col, Row } from 'react-bootstrap';
import ModalNew from '../../components/Layouts/ModalNew';
import Select from 'react-select';
import { customSelectNew } from '../../components/Layouts/react-select-custom';
import { convertDateToLatinFormat } from '../../helpers/convertDateFormat';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import {
  GET_PO_PINVOICES,
  GET_PO_MOVCONTABLES,
  PAGO_FACTURAS_CAUSADAS_RESET,
} from '../../actions/actionTypes';

import {
  get_providers,
  get_niifs,
  get_pay_orders,
  pinvoices_get_from_payorder,
  mov_contables_from_payorder,
  pago_facturas_causadas,
} from '../../actions/billPaymentActions';
import Loader from 'react-loader-spinner';
import { customSwaltAlert, getEnvs } from '../../helpers';
import { Title } from '../../shared';

const { APP_LANGUAGE, CURRENCY } = getEnvs();

var formatter = new Intl.NumberFormat(APP_LANGUAGE, { style: 'currency', currency: CURRENCY });

function BillPayment() {
  const counter = useSelector(state => state);
  const dispatch = useDispatch();

  const history = useHistory();
  const myPermission = counter.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'PayPinC',
  );

  const [showModal, setShowModal] = useState(false);
  const [modalFilters, setModalFilters] = useState({
    po_number: '',
    date: '',
    trigger: 0,
    page: 1,
    perpage: 10,
  });
  const [preSelectedPO, setPreSelectedPO] = useState({});
  const [selectedPO, setSelectedPO] = useState({});
  const [pinvoiceTrigger, setpinvoiceTrigger] = useState(0);

  const [loading, setLoading] = useState(false);

  const today = new Date();

  const header = [
    <th key={1} className='text-center px-2'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center px-2'>
      Fecha
    </th>,
    <th key={3} className='text-center px-2'>
      Saldo
    </th>,
    <th key={4} className='text-center px-2'>
      Cuenta por pagar
    </th>,
    <th key={5} className='text-end px-2'>
      Valor pagado
    </th>,
  ];

  const header3 = [
    <th key={1} className='text-center px-2'>
      Cuenta{' '}
    </th>,
    <th key={2} className='text-start px-2'>
      <span>Detalle</span>
    </th>,
    <th key={3} className='text-end px-2'>
      DEBE
    </th>,
    <th key={4} className='text-end px-2'>
      HABER
    </th>,
  ];

  const header2 = [
    <th key={1} className='text-center'></th>,
    <th key={2} className='text-center'>
      No. Orden
    </th>,
    <th key={3} className='text-center'>
      Fecha
    </th>,
    <th key={4} className='text-center'>
      Valor
    </th>,
  ];

  const [filters, setFilters] = useState({
    niif_id: 0,
    provider: 0,
  });

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/tesoreria/');
    }

    dispatch(
      get_providers({
        lite: 1,
        id_account: counter.loginReducer.currentAccount.id,
        checked: 1,
        status: 'enabled',
      }),
    );
    dispatch(
      get_niifs({
        lite: 1,
        eaccount: counter.loginReducer.currentAccount.id,
        bank: 1,
        active: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPO.id) {
      dispatch(
        pinvoices_get_from_payorder({
          po_id: selectedPO.id,
          eaccount: counter.loginReducer.currentAccount.id,
        }),
      );
      dispatch(
        mov_contables_from_payorder({
          po_id: selectedPO.id,
          eaccount: counter.loginReducer.currentAccount.id,
          mov_type: 'C',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinvoiceTrigger]);

  const [body2, modalTableTotal] = useMemo(() => {
    let res = [];
    if (Array.isArray(counter.billPaymentReducer.payorders)) {
      counter.billPaymentReducer.payorders.forEach((item, index) => {
        let pass1 =
          !!modalFilters.po_number === false ||
          (!!modalFilters.po_number && String(item.consecutive).includes(modalFilters.po_number));
        let pass2 =
          !modalFilters.date ||
          (modalFilters.date &&
            convertDateToLatinFormat(modalFilters.date) ===
              convertDateToLatinFormat(item.created_at));
        if (pass1 && pass2) {
          res.push(
            <tr key={index}>
              <td className='text-center px-2'>
                <input
                  className={`form-check-input`}
                  type='radio'
                  name=''
                  id=''
                  checked={item.id === preSelectedPO.id}
                  onChange={() => setPreSelectedPO(item)}
                />
              </td>
              <td className='text-center px-2'>{item.consecutive}</td>
              <td className='text-center px-2'>{convertDateToLatinFormat(item.created_at)}</td>
              <td className='text-end px-2'>
                <span>{formatter.format(item.amount_to_pay)}</span>
              </td>
            </tr>,
          );
        }
      });
    }

    return [
      res.slice(
        modalFilters.perpage * (modalFilters.page - 1),
        modalFilters.perpage * modalFilters.page,
      ),
      res.length,
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalFilters.trigger,
    preSelectedPO.id,
    counter.billPaymentReducer.payorders_loading,
    modalFilters.page,
    modalFilters.perpage,
  ]);

  const [body, bodyTotal, pinvoiceIds] = useMemo(() => {
    let temp = [];
    let total = 0;
    let pinvoiceids = [];
    if (Array.isArray(counter.billPaymentReducer.pinvoices)) {
      counter.billPaymentReducer.pinvoices.forEach((item, index) => {
        let createdDate = new Date(item.due_date);
        const restasaldo = item.restasaldo || 0;
        pinvoiceids.push(item.id);
        total += item.rel_amount;
        temp.push(
          <tr key={index}>
            <td className='text-center px-2'>{item.invoice_nbr}</td>
            <td className='text-center px-2'>
              {createdDate.getDate() +
                '/' +
                createdDate.getMonth() +
                '/' +
                createdDate.getFullYear()}
            </td>
            <td className='text-center px-2'>{formatter.format(item.total_amount - restasaldo)}</td>
            <td className='text-center px-2'>
              {item.complete_account
                ? item.complete_account
                : counter?.billPaymentReducer?.niif?.find(niif => niif.id === item.niffAccount)
                    ?.complete_account}
            </td>
            <td className='text-center px-2'>{formatter.format(item.rel_amount)}</td>
          </tr>,
        );
      });
    }
    return [temp, total, pinvoiceids];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.billPaymentReducer.pinvoices, pinvoiceTrigger]);

  const footer = [
    <tr key={1}>
      <td
        className='px-2'
        style={{
          justifyContent: 'center',
          fontFamily: ' PT Sans Caption',
          fontWeight: 'bold',
          fontSize: 12,
          lineHeight: '16px',
          display: 'flex',
          alignItems: 'center',
          color: '#58595B',
        }}
      >
        TOTALES
      </td>
      <td colSpan='3'></td>
      <td
        className='text-end px-2'
        colSpan='1'
        style={{ color: '#005DBF', fontSize: '16px', fontWeight: 'bold', lineHeight: '12px' }}
      >
        {formatter.format(bodyTotal)}
      </td>
    </tr>,
  ];

  const [body3, totalDebe, facturasAPagar] = useMemo(() => {
    let temp = [];
    let facturas = [];
    let totalDebe = 0;
    let totalHaber = 0;
    if (Array.isArray(counter.billPaymentReducer.movcontables)) {
      counter.billPaymentReducer.movcontables.forEach((item, index) => {
        totalDebe += item.DEBE;
        totalHaber += item.HABER;
        facturas.push({
          amount: item.DEBE,
          rel_payord_pinv_id: item.rel_payord_pinv_id,
        });
        temp.push(
          <tr key={index}>
            <td className='text-center px-2'>{item.complete_account}</td>
            <td className='text-start px-2'>
              <span style={{ paddingLeft: 5 }}>{item.details}</span>
            </td>
            <td className='text-end px-2'>{formatter.format(item.DEBE)}</td>
            <td className='text-end px-2'>-</td>
          </tr>,
        );
      });
    }
    return [temp, totalDebe, totalHaber, facturas];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter.billPaymentReducer.movcontables, pinvoiceTrigger]);

  const body3withTotal = () => {
    let body3new = [...body3];
    if (selectedPO.status === 1) {
      body3new.push(
        <tr key={'zefinalrow'}>
          <td className='text-center px-2'>{filters.niif_complete_account}</td>
          <td className='text-start px-2'>
            <span>{filters.niif_description}</span>
          </td>
          <td className='text-end px-2'>-</td>
          <td className='text-end px-2'>{formatter.format(totalDebe)}</td>
        </tr>,
      );
    }
    return body3new;
  };

  const footer3 = [
    <tr key={1}>
      <td className='invisible' colSpan='1'></td>
      <th
        className='px-2'
        style={{ color: '#58595B', fontSize: '12px', fontWeight: 'bold', lineHeight: '16px' }}
      >
        TOTALES
      </th>
      <th
        style={{ color: '#58595B', fontSize: '16px', fontWeight: 'bold', lineHeight: '21px' }}
        className='text-end px-2'
      >
        {formatter.format(totalDebe)}
      </th>
      <th
        style={{ color: '#58595B', fontSize: '16px', fontWeight: 'bold', lineHeight: '21px' }}
        className='text-end px-2'
        colSpan='1'
      >
        {formatter.format(totalDebe)}
      </th>
    </tr>,
  ];

  const accountTypeKeyValue = account => {
    switch (account) {
      case 'Fondos_de_inversión':
        return 'Fondos de inversión';
      default:
        return account;
    }
  };

  let optionNiifAccounts = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.billPaymentReducer.niif)) {
    counter.billPaymentReducer.niif.forEach(item => {
      optionNiifAccounts.push({
        value: item.id,
        label:
          item.complete_account +
          ' - ' +
          item.description +
          ', ' +
          accountTypeKeyValue(item.account_type),
        complete_account: item.complete_account,
        description: item.description,
        key: item.id,
      });
    });
  }

  let optionProviders = [{ label: 'Seleccionar...', value: '' }];
  if (Array.isArray(counter.billPaymentReducer.providers)) {
    let newproviderorder = counter.billPaymentReducer.providers.sort((a, b) => a.id - b.id);
    newproviderorder.forEach(item => {
      optionProviders.push({
        value: item.id,
        label: item.id + ': ' + item.name,
        key: item.id,
        name: item.name,
        nit: item.nit,
      });
    });
  }

  const resetForm = () => {
    setModalFilters({
      po_number: '',
      date: '',
      trigger: modalFilters.trigger + 1,
    });
    setFilters({
      niif_id: 0,
      provider: 0,
      details: '',
    });
    setPreSelectedPO({});
    setSelectedPO({});
    setpinvoiceTrigger(pinvoiceTrigger + 1);
    dispatch({
      type: PAGO_FACTURAS_CAUSADAS_RESET,
    });
  };

  const go_go_finish = () => {
    let error = '';

    if (!!filters.niif_id === false) {
      error = 'Debe asignar una cuenta niif';
    }
    if (!!filters.details === false) {
      error = 'Debe escribir una descripción en detalle';
    }

    if (error) {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: error,
        showCancelButton: false,
      });
    } else {
      dispatch(
        pago_facturas_causadas(
          {
            eaccount: counter.loginReducer.currentAccount.id,
            user: counter.loginReducer.user_data.id,
            niif: filters.niif_id,
            accountNbr: filters.niif_complete_account,
            idDocRef: selectedPO.id,
            details: filters.details,
            mov_type: 'C',
            facturas: facturasAPagar,
            pinvoiceIds: pinvoiceIds,
            paydate: today,
          },
          () => {
            resetForm();
          },
        ),
      );
    }
  };
  const handleClick = () => {
    setShowModal(true);
    dispatch(
      get_pay_orders(
        {
          status: 1,
          provider: filters.provider,
        },
        () => setLoading(false),
      ),
    );
  };

  return (
    <>
      {loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      {counter.billPaymentReducer?.pago_facturas_causadas_loading && (
        <div className='loading'>
          <Loader type='Oval' color='#003f80' height={100} width={100} />
        </div>
      )}
      <div
        className={` ml-5 ${tableStyles.container}`}
        style={{ marginRight: '3rem', marginTop: '30px' }}
      >
        <Title
          title='Pago de facturas causadas'
          className={'mb-2'}
          onClickIcon={() => {
            history.push('/tesoreria/inicio');
          }}
        />
        <div>
          <ModalNew
            show={showModal}
            onHide={() => {
              setShowModal(false);
              setPreSelectedPO({});
              setModalFilters({ ...modalFilters, page: 1 });
            }}
            title={'Órdenes de pago pendientes'}
            //subtitle={"802.203.265 - Nueva EPS"}
            subtitle={filters.provider_nit + ' - ' + filters.provider_name}
            btnYesEvent={() => {
              setSelectedPO(preSelectedPO);
              setPreSelectedPO({});
              setpinvoiceTrigger(pinvoiceTrigger + 1);
              setShowModal(false);
            }}
            btnYesName={'Seleccionar'}
            size={'500'}
          >
            <form
              onSubmit={e => {
                e.preventDefault();
                setModalFilters({ ...modalFilters, trigger: modalFilters.trigger + 1 });
              }}
            >
              <Row className='d-flex'>
                <Col xs={5}>
                  <label className={tableStyles.crudModalLabel}>No. Orden de pago</label>
                  <input
                    placeholder='Escribir...'
                    key={`norden${modalFilters.trigger}`}
                    className={`register-inputs`}
                    style={{ color: '#697387' }}
                    type='number'
                    value={modalFilters.po_number}
                    onChange={e => setModalFilters({ ...modalFilters, po_number: e.target.value })}
                  />
                </Col>
                <Col xs={6}>
                  <label className={tableStyles.crudModalLabel}>Fecha</label>
                  <div className={tableStyles.deleteicon}>
                    {modalFilters.date ? (
                      <span onClick={() => setModalFilters({ ...modalFilters, date: '' })}>x</span>
                    ) : (
                      ''
                    )}
                    <input
                      key={`fecha${modalFilters.trigger}`}
                      className={`register-inputs`}
                      type='date'
                      value={modalFilters.date}
                      onChange={e => setModalFilters({ ...modalFilters, date: e.target.value })}
                    />
                  </div>
                </Col>
              </Row>
            </form>
            <GenericTable headers={header2}>{body2}</GenericTable>

            {modalTableTotal > modalFilters.perpage && (
              <div className={paginationStyles.wrapper}>
                <p className={paginationStyles.paginationText}></p>
                <Pagination
                  activePage={modalFilters.page}
                  itemsCountPerPage={modalFilters.perpage}
                  totalItemsCount={modalTableTotal}
                  pageRangeDisplayed={5}
                  onChange={val => {
                    setModalFilters({ ...modalFilters, page: val });
                  }}
                  itemClassPrev={`${paginationStyles.itemClassPrev} `}
                  itemClassNext={`${paginationStyles.itemClassNext} `}
                  itemClassFirst={`${paginationStyles.itemClassFirst} `}
                  itemClassLast={`${paginationStyles.itemClassLast} `}
                  itemClass={paginationStyles.itemClass}
                />
              </div>
            )}
          </ModalNew>
          <div className='d-flex'>
            <div className='d-flex flex-column  mr-3' style={{ flex: 2 }}>
              <label className={tableStyles.crudModalLabel}>Cuenta Contable*</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                key={`cuentacontable${modalFilters.trigger}`}
                value={optionNiifAccounts.filter(option => option.value === filters.niif_id)}
                onChange={e =>
                  setFilters({
                    ...filters,
                    niif_id: e.value,
                    niif_complete_account: e.complete_account,
                    niif_description: e.description,
                  })
                }
                options={optionNiifAccounts}
                placeholder='Seleccionar...'
                styles={customSelectNew}
              />
            </div>
            <div className='d-flex' style={{ flex: 3 }}>
              <div className='d-flex flex-column  mr-3' style={{ flex: 1 }}>
                <label className={tableStyles.crudModalLabel}>fecha de pago*</label>
                <input
                  key={`norden${modalFilters.trigger}`}
                  className={`register-inputs`}
                  style={{ color: '#005dbf', fontWeight: 'bold' }}
                  type='date'
                  name=''
                  id=''
                  //value={filters.paydate}
                  //min={today.toISOString().slice(0, 10)}
                  //onChange={e=>setFilters({...filters, paydate: e.target.value})}
                  value={today.toISOString().slice(0, 10)}
                  disabled={true}
                />
              </div>
              <div className='d-flex flex-column' style={{ flex: 1 }}>
                <label className={`${tableStyles.crudModalLabel} tooltip-text`}>
                  Orden de Pago*
                  {!(!!filters.niif_id && !!filters.provider) ? (
                    <span className='tooltip-content'>Seleccionar cuenta contable y proovedor</span>
                  ) : (
                    ''
                  )}
                </label>
                <input
                  placeholder='Escribir...'
                  key={`ordenpago${modalFilters.trigger}`}
                  value={selectedPO.consecutive ? selectedPO.consecutive : ''}
                  onClick={() => {
                    handleClick();
                    setLoading(true);
                  }}
                  style={{ color: '#697387' }}
                  className={
                    !!filters.niif_id && !!filters.provider
                      ? `${IndividualStyles.registerInputs} hoverPointer`
                      : `${IndividualStyles.registerInputs}`
                  }
                  type='text'
                  name=''
                  id=''
                  readOnly={true}
                  disabled={!(!!filters.niif_id && !!filters.provider)}
                />
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='d-flex flex-column mr-3' style={{ flex: 2 }}>
              <label className={tableStyles.crudModalLabel}>Proveedor*</label>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                placeholder='Seleccionar...'
                key={`proveedor${modalFilters.trigger}`}
                value={optionProviders.filter(option => option.value === filters.provider)}
                onChange={e => {
                  setFilters({
                    ...filters,
                    provider: e.value,
                    provider_name: e.name,
                    provider_nit: e.nit,
                  });
                  setPreSelectedPO({});
                  setSelectedPO({});
                  setpinvoiceTrigger(pinvoiceTrigger + 1);
                  dispatch({
                    type: PAGO_FACTURAS_CAUSADAS_RESET,
                  });
                }}
                options={optionProviders}
                styles={customSelectNew}
              />
            </div>
            <div className='d-flex flex-column' style={{ flex: 3 }}>
              <label className={tableStyles.crudModalLabel}>Detalle*</label>
              <input
                placeholder='Escribir...'
                key={`detail${modalFilters.trigger}`}
                className={`register-inputs`}
                style={{ color: '#697387' }}
                type='text'
                name=''
                id=''
                value={filters.details}
                onChange={e => setFilters({ ...filters, details: e.target.value })}
              />
            </div>
          </div>
        </div>
        <GenericTable headers={header} footer={footer} dark={true}>
          {body}
        </GenericTable>
        <div>
          <GenericTable headers={header3} footer={footer3}>
            {body3withTotal()}
          </GenericTable>
        </div>
        <div className='d-block text-end mt-5'>
          <button
            className={`${tableStyles.btnSecondary} mr-3`}
            onClick={() => {
              setModalFilters({
                po_number: '',
                date: '',
                trigger: modalFilters.trigger + 1,
              });
              setFilters({
                niif_id: 0,
                provider: 0,
              });
              setPreSelectedPO({});
              setSelectedPO({});
              setpinvoiceTrigger(pinvoiceTrigger + 1);
              dispatch({
                type: GET_PO_MOVCONTABLES,
                movcontables: [],
                loading: false,
              });
              dispatch({
                type: GET_PO_PINVOICES,
                movcontables: [],
                loading: false,
              });
            }}
            disabled={!(filters.niif_id || filters.provider || preSelectedPO.id || selectedPO.id)}
          >
            Cancelar
          </button>
          <button
            className={`${tableStyles.btnPrimaryAlt}`}
            onClick={() => go_go_finish()}
            disabled={selectedPO.status !== 1}
          >
            Guardar
          </button>
        </div>
      </div>
    </>
  );
}
export default BillPayment;
