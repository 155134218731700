import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
//
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import ThreeDots from '../../assets/img/icons/threeDots.svg';
import PopUp from '../../components/Popup/customPopUpExtends';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
//
import { customSwaltAlertAsistencial, formatToRcSelect, loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
//
import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import ordComponentStyles from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';

export default function MedicalConcept() {
  const store = useSelector(state => state);
  const PT_INITIAL_STATE = {
    userId: store.loginReducer.user_data.id,
    eaccount: store.loginReducer.currentAccount.id,
    name: '',
    riskId: '',
    srvId: '',
  };

  const [predefinedText, setPredefinedText] = useState(PT_INITIAL_STATE);
  const [services, setServices] = useState({
    showNewModal: false,
    showUploadModal: false,
    isEditing: false,
    headers: [
      { title: 'Concepto médico', className: 'col-5 text-start px-2' },
      { title: 'Servicio', className: 'col-4 text-start' },
      { title: 'Estado', className: 'col-2 text-center' },
      { title: <>&nbsp;</>, className: 'col-1' },
    ],
  });
  const [filters, setFilters] = useState({
    perpage: 10,
    page: 1,
    search: '',
    srvId: '',
  });
  const [trigger, setTrigger] = useState(0);
  const {
    load: predefinedTextsLoader,
    trigger: getPredefinedTexts,
    results: predefinedTexts,
  } = useGetMethod();
  const { load: updatePredefinedTextsLoader, trigger: updatePredefinedText } = usePostMethod();
  const { load: createPredefinedTextsLoader, trigger: createPredefinedText } = usePostMethod();

  const {
    trigger: getComponents,
    results: componentsData,
    loader: componentsLoader,
  } = useGetMethod();
  const { trigger: getSegments, results: segmentsData, loader: segmentsLoader } = useGetMethod();

  useEffect(() => {
    async function fetchAsyncData() {
      await getPredefinedTexts({
        url: '/medical/medicalConcept/',
        objFilters: {
          ...filters,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, store.loginReducer.currentAccount.id, store.loginReducer.Authorization]);

  useEffect(() => {
    async function fetchAsyncData() {
      getComponents({
        url: '/medical/generals/services/',
        objFilters: {
          tag: 'diagnostic_aids',
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
  }, [store.loginReducer.Authorization, getComponents]);

  useEffect(() => {
    async function fetchAsyncData() {
      getSegments({
        url: '/medical/generals/medicalConceptRisk/',
        objFilters: {
          id: predefinedText.id,
          prefix: predefinedText.prefix,
        },
        token: store.loginReducer.Authorization,
      });
    }
    fetchAsyncData().catch(error => console.error('Error: ', error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.loginReducer.Authorization, getSegments]);

  const handleOpenEditModal = item => {
    setPredefinedText({
      ...predefinedText,
      name: item.name,
      riskId: item.riskId,
      srvId: item.srvId,
      userId: store.loginReducer?.user_data?.id,
      id: item.id,
      prefix: item.prefix,
    });
    setServices({ ...services, showNewModal: true, isEditing: true });
  };
  const handleCloseModal = () => {
    setPredefinedText(PT_INITIAL_STATE);
    setServices({ ...services, showNewModal: false, isEditing: false });
  };

  const handleChangeState = item => {
    updatePredefinedText({
      url: '/medical/medicalConcept/',
      token: store.loginReducer.Authorization,
      method: 'PATCH',
      body: {
        updatedBy: store.loginReducer.user_data.id,
        id: item.id,
        enabled: item.enabled === 1 ? false : true,
        eaccount: store.loginReducer.currentAccount.id,
      },
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          titleColor: '#1ABCD2',
          text: `El concepto médico se ha ${item.enabled ? 'deshabilitado' : 'habilitado'} correctamente`,
          title: 'Actualizado exitosamente',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        setTrigger(trigger + 1);
      },
      doAfterException: err => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: err.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const handleCreatePredefinedText = () => {
    const payload = {
      name: predefinedText.name,
      riskId: predefinedText.riskId,
      srvId: predefinedText.srvId,
      userId: predefinedText.userId,
    };
    if (predefinedText?.id) {
      updatePredefinedText({
        url: '/medical/medicalConcept/',
        token: store.loginReducer.Authorization,
        method: 'PUT',
        body: { ...payload, id: predefinedText.id },
        succesAction: results => {
          customSwaltAlertAsistencial({
            icon: 'success',
            titleColor: '#1ABCD2',
            title: 'Editado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterSuccess: () => {
          setTrigger(trigger + 1);
          handleCloseModal();
        },
        doAfterException: err => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: err.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    } else {
      createPredefinedText({
        url: '/medical/medicalConcept/',
        token: store.loginReducer.Authorization,
        method: 'PUT',
        body: payload,
        succesAction: results => {
          customSwaltAlertAsistencial({
            icon: 'success',
            titleColor: '#1ABCD2',
            title: 'Creado exitosamente',
            text: results.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
        doAfterSuccess: () => {
          setTrigger(trigger + 1);
          handleCloseModal();
        },
        doAfterException: err => {
          customSwaltAlertAsistencial({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: err.message,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        },
      });
    }
  };

  const renderRow = item => {
    return (
      <tr key={item.id} className={`hover-table-row`}>
        <td className='text-start px-2'>
          <b style={{ color: item.risk.color }}>{item.name}</b>
        </td>
        <td className='text-start'>{item.service.name}</td>
        <td className='text-center'>
          {item.enabled === 1 ? (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_true}>Habilitado</span>
            </div>
          ) : (
            <div className={tableStyles.app_container_state}>
              <span className={tableStyles.app_state_false}>Deshabilitado</span>
            </div>
          )}
        </td>
        <td className='text-end'>
          <PopUp
            showEdit={true}
            showEnable={true}
            position='right'
            triggerSrc={ThreeDots}
            editClickEvent={() => handleOpenEditModal(item)}
            isEnabled={item.enabled === 1}
            enableClickEvent={() => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                titleColor: '#1ABCD2',
                title: '¿Está seguro?',
                text: `El concepto médico será ${item.enabled ? 'deshabilitado' : 'habilitado'}`,
                confirmButtonText: 'Si, continuar',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
              }).then(result => {
                if (result.isConfirmed) {
                  handleChangeState(item);
                }
              });
            }}
          />
        </td>
      </tr>
    );
  };

  const renderTable = () => {
    return (
      <OrdTable
        className={`${tableStyles.shade}`}
        headers={services.headers}
        hasChildren={true}
        style={{ borderRadius: '10px', overflow: 'hidden' }}
        paginate={{
          activePage: filters.page,
          totalPages: predefinedTexts?.rowTotal,
          perPage: filters.perpage,
          pageRangeDisplayed: 3,
          onChangePage: async e => {
            await getPredefinedTexts({
              url: '/medical/medicalConcept/',
              objFilters: {
                ...filters,
                page: e,
              },
              token: store.loginReducer.Authorization,
            });
            setFilters({ ...filters, page: e });
          },
          showTextDetails: true,
        }}
      >
        {predefinedTexts?.results?.map(dilatationService => renderRow(dilatationService))}
      </OrdTable>
    );
  };

  const renderModal = () => {
    const formattedComponents = formatToRcSelect(componentsData?.results, 'id', 'name');
    const formattedSegments = formatToRcSelect(segmentsData?.results, 'id', 'name', 'color');

    return (
      <OrdModal
        title={`${services.isEditing ? 'Editar' : 'Nuevo'} concepto médico`}
        titleClassName='fs-5'
        show={services.showNewModal}
        btnYesName='Guardar'
        btnNoName='Cancelar'
        size='md'
        onHide={() => setServices({ ...services, showNewModal: false, isEditing: false })}
        btnYesEvent={() => handleCreatePredefinedText()}
        btnNoEvent={() => setServices({ ...services, showNewModal: false, isEditing: false })}
      >
        <form>
          <Form.Group className='mb-3'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Concepto médico</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>
            <Form.Control
              className={`form-control text-muted ord-roundInput`}
              type='text'
              placeholder='Escribir...'
              value={predefinedText.name}
              style={{ fontSize: 12 }}
              onChange={({ target }) =>
                setPredefinedText({
                  ...predefinedText,
                  name: target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Servicio</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={ordCustomSelect}
              options={formattedComponents}
              onChange={option =>
                setPredefinedText({
                  ...predefinedText,
                  srvId: option.value,
                })
              }
              value={formattedComponents?.find(
                component => component.value === predefinedText.srvId,
              )}
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className={tableStyles.app_title_input}>
              <div className='ms-1'>
                <span className={tableStyles.app_title_new_item}>Color</span>
                <span style={{ color: '#FFFF00' }}>*</span>
              </div>
            </Form.Label>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              styles={{
                ...ordCustomSelect,
                option: (provided, state) => {
                  return {
                    ...provided,
                    fontSize: 13,
                    overflowX: 'hidden',
                    backgroundColor: state.isDisabled
                      ? '#58595B'
                      : state.isSelected
                        ? '#003F80'
                        : state.isFocused
                          ? '#ccdce4'
                          : '#fff',
                    color: state.data.extra,
                    cursor: state.isDisabled ? 'not-allowed' : 'default',
                    zIndex: 1000000,
                  };
                },
                singleValue: (provided, state) => {
                  const transition = 'opacity 300ms';
                  const color = state.data.extra;
                  return { ...provided, transition, color };
                },
              }}
              options={formattedSegments}
              onChange={option =>
                setPredefinedText({
                  ...predefinedText,
                  riskId: option.value,
                })
              }
              value={formattedSegments?.find(segment => segment.value === predefinedText.riskId)}
            />
          </Form.Group>
        </form>
      </OrdModal>
    );
  };

  const render = () => {
    const formattedComponents = formatToRcSelect(componentsData?.results, 'id', 'name');

    return (
      <>
        {predefinedTextsLoader && loader}
        {updatePredefinedTextsLoader && loader}
        {createPredefinedTextsLoader && loader}
        {componentsLoader && loader}
        {segmentsLoader && loader}
        {renderModal()}
        <div className={`${tableStyles.tlnContainer}`}>
          <OrdGenericTemplate className='' titleSize={12} title={'Conceptos médicos'}>
            <div>
              <Row className='d-flex align-items-center'>
                <Col xs={5}>
                  <Form.Label className={tableStyles.app_title_input}>&nbsp;</Form.Label>
                  <input
                    placeholder='Escribe aquí para buscar'
                    className={`${ordComponentStyles.backgroundImage} ${tableStyles.appInputDate} pl-2 w-100`}
                    onChange={({ target }) => {
                      setFilters({
                        ...filters,
                        search: target.value,
                        page: 1,
                      });
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setTrigger(trigger + 1);
                      }
                    }}
                  />
                </Col>
                <Col xs={2}>
                  <Form.Group className=''>
                    <Form.Label className={tableStyles.app_title_input}>
                      <div className='ms-1'>
                        <span className={tableStyles.app_title_new_item}>Servicio</span>
                      </div>
                    </Form.Label>
                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      className='text-secondary'
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      options={formattedComponents}
                      onChange={option => {
                        setFilters({
                          ...filters,
                          srvId: option.value,
                          page: 1,
                        });
                        setTrigger(trigger + 1);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={5} className='d-flex justify-content-end align-self-end'>
                  <div
                    className={
                      ' justify-content-end ordGroupAddButton align-items-center svgIconDark'
                    }
                    onClick={() => {
                      setPredefinedText(PT_INITIAL_STATE);
                      setServices({ ...services, showNewModal: true });
                    }}
                  >
                    <label className='ordGrayText fw-bold'>Nuevo</label>
                    <button className='addCheckButton ms-2' />
                  </div>
                </Col>
              </Row>
              {renderTable()}
            </div>
          </OrdGenericTemplate>
        </div>
      </>
    );
  };

  return render();
}
