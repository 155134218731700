import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import { Col, Form, Row } from 'react-bootstrap';

import alertIcon from '../../assets/img/icons/alertsIcon.svg';
import requestIcon from '../../assets/img/icons/requestIcon.svg';
import discountIcon from '../../assets/img/icons/discountIcon.svg';
import close from '../../assets/img/icons/appClosePatient.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';

import styles from '../../components/Layouts/tableStyle.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let json = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

  const dataQuotasNotification = () => {
    return (
      <div>
        {json.map(i => {
          return (
            <div className='d-flex my-2' key={i}>
              <div className={`d-flex  `}>
                <div className={`d-flex align-self-center `}>
                  <img alt='requestIcon' src={requestIcon} width='30px' />
                </div>
              </div>
              <div className={`d-flex ${styles.alertHeaderLineOrange} mr-2`}>⠀</div>

              <div className='d-flex flex-column'>
                <span className={`${styles.tlnTextPrimary} ${styles.f14} `}>
                  Solicitud de descuento
                </span>
                <span className={`${styles.tlnTextGray}  ${styles.f13}`}>Jorge Martínez</span>
                <span className={`${styles.veryClearGrayText} ${styles.f12}`}>Ayer</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const openDrawer = () => {
    handleClose();
    setShowDrawer(true);
  };
  const closeDrawer = () => {
    setShowDrawer(false);
  };

  return (
    <>
      <Drawer anchor={'right'} open={showDrawer} style={{ width: '40%' }}>
        <div style={{ width: '400px', padding: ' 1rem 2rem', height: '100%' }} className='h-100'>
          <div>
            <Row>
              <div className='d-flex justify-content-start'>
                <button onClick={() => closeDrawer()}>
                  <img alt='close' src={close} />
                </button>
              </div>
            </Row>
            <div className='px-4'>
              <div className=''>
                <b className={`${tableStyles.f32} ${tableStyles.tlnTextDark} `}>Notificaciones</b>{' '}
                <br />
              </div>
              <Row>
                <Col xs={12}>
                  <Form.Group className=' text-start' controlId='fName'>
                    <Form.Label
                      className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14} d-flex flex-column w-100`}
                    >
                      <b> &nbsp;</b>
                    </Form.Label>
                    <div className={`d-flex ${tableStyles.appGrayInputSearch} ${tableStyles.f12}`}>
                      <Form.Control
                        style={{ backGroundColor: 'red' }}
                        className={`ord-roundInput    ${tableStyles.appInputDate}`}
                        typeDiscount='text'
                        placeholder='Nombre '
                        autoComplete='off'
                      />
                      <img src={ordBlueSearch} className='' alt='buscar'></img>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </div>
          <div className='px-4 my-4' style={{ height: '75%', overflowY: 'auto' }}>
            <div className=' ' style={{ marginBottom: '40px' }}>
              {dataQuotasNotification()}
            </div>
          </div>

          <div
            style={{
              width: '400px',
              padding: ' 1rem 2rem',
              position: 'fixed',
              borderTop: '1px solid #',
              background: '#fff',
              bottom: '0px',
            }}
            className='d-flex justify-content-end align-items-end align-self-end border-top bg-white'
          >
            <div
              className='px-4 w-100 d-flex justify-content-end align-items-end align-self-end mb-2 '
              style={{ width: '100%' }}
            >
              <button
                onClick={() => closeDrawer()}
                className={`mr-2 btn ${tableStyles.ordBtnSecondary}`}
              >
                Borrar
              </button>
              <button className={`${tableStyles.ordBtnPrimary} btn`}>Aceptar</button>
            </div>
          </div>
        </div>
        {/* buttons */}
      </Drawer>
      <div className='px-2'>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <img alt='alertIcon' src={alertIcon} />
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className={`mr-2`}
        >
          <div className='p-2'>
            <span className={`${styles.veryClearGrayText} ${styles.f14} p-2`}>Notificaciones</span>
          </div>
          <Divider />
          <Paper sx={{ width: 300, maxWidth: '100%' }}>
            <MenuItem>
              <div className='d-flex'>
                <div className={`d-flex  `}>
                  <div className={`d-flex align-self-center `}>
                    <img alt='requestIcon' src={requestIcon} width='30px' />
                  </div>
                </div>
                <div className={`d-flex ${styles.alertHeaderLineOrange} mr-2`}>⠀</div>

                <div className='d-flex flex-column'>
                  <span className={`${styles.tlnTextPrimary} ${styles.f14} `}>
                    Solicitud de descuento
                  </span>
                  <span className={`${styles.tlnTextGray}  ${styles.f13}`}>Jorge Martínez</span>
                  <span className={`${styles.veryClearGrayText} ${styles.f12}`}>Ayer</span>
                </div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='d-flex'>
                <div className={`d-flex  `}>
                  <div className={`d-flex align-self-center `}>
                    <img alt='requestIcon' src={discountIcon} width='30px' />
                  </div>
                </div>
                <div className={`d-flex ${styles.alertHeaderLineOrange} mr-2`}>⠀</div>

                <div className='d-flex flex-column'>
                  <span className={`${styles.tlnTextPrimary} ${styles.f14} `}>
                    Solicitud de cupo extra
                  </span>
                  <span className={`${styles.tlnTextGray}  ${styles.f13}`}>Melissa Hernandez</span>
                  <span className={`${styles.veryClearGrayText} ${styles.f12}`}>Hace 5 días</span>
                </div>
              </div>
            </MenuItem>
            <MenuItem>
              <div className='d-flex'>
                <div className={`d-flex  `}>
                  <div className={`d-flex align-self-center `}>
                    <img alt='requestIcon' src={requestIcon} width='30px' />
                  </div>
                </div>
                <div className={`d-flex ${styles.alertHeaderLineOrange} mr-2`}>⠀</div>

                <div className='d-flex flex-column'>
                  <span className={`${styles.tlnTextPrimary} ${styles.f14} `}>
                    Solicitud de cupo extra
                  </span>
                  <span className={`${styles.tlnTextGray}  ${styles.f13}`}>Melissa Hernandez</span>
                  <span className={`${styles.veryClearGrayText} ${styles.f12}`}>Hace 6 días</span>
                </div>
              </div>
            </MenuItem>

            <Divider />
            <MenuItem
              onClick={() => {
                openDrawer();
              }}
            >
              <div className=' w-100 d-flex justify-content-center cursorPointer'>
                <span className={`  text-center ${styles.tlnTextGray} ${styles.f14}`}>
                  {' '}
                  Ver todas
                </span>
              </div>
            </MenuItem>
          </Paper>
        </Menu>
      </div>
    </>
  );
}
