import { GET_LIST_POPULATION, SET_ROW_POPULATION } from "../actions/actionTypes";

const initialState = {
  listPopulation: [],
  onePopulation: [],
  totalPopulation: 0,
};

export const populationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_POPULATION:
      return {
        ...state,
        listPopulation: action.payload,
      };
    case SET_ROW_POPULATION:
      return {
        ...state,
        totalPopulation: action.payload,
      };
    default:
      return state;
  }
};
