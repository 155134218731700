import { customSwaltAlert } from '../helpers';
import { API_VERSION, URL_GATEWAY } from '../helpers/constants';
import { convertFilterToString } from '../helpers/convertToFilter';
import { GET_COMPLAINTS, LOADING } from './actionTypes';

export const getComplaints = objFilters => (dispatch, getState) => {
  let filters = convertFilterToString(objFilters);
  const token = getState().loginReducer.Authorization;
  dispatch({ type: LOADING, payload: true });
  fetch(`${URL_GATEWAY}${API_VERSION}/payroll/complaints-management/?${filters}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
  })
    .then(response => response.json())
    .then(res => {
      const { results, row_total } = res;
      dispatch({
        type: GET_COMPLAINTS,
        payload: results,
        total: row_total,
      });
      dispatch({ type: LOADING, payload: false });
    })
    .catch(err => {
      dispatch({ type: LOADING, payload: false });
      console.error(err.message);
    });
};

export const changeComplaints = (data, doAfter, param1, param2, info) => (dispatch, getState) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  const token = getState().loginReducer.Authorization;
  fetch(
    `${URL_GATEWAY}${API_VERSION}/payroll/complaints-management/${param1}/complaint/${param2}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        token: token,
      },
    },
  )
    .then(response => response.json())
    .then(res => {
      if (res.success) {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha enviado ${info?.type === 'Reporte' ? 'el reporte' : 'la queja'}: ${info.concept}`,
          showCancelButton: false,
        }).then(response => {
          dispatch({
            type: LOADING,
            payload: false,
          });
          if (response.isConfirmed) {
            doAfter();
          } else {
            doAfter();
          }
        });
      } else {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          confirmButtonText: 'Aceptar',
          footer: 'Si el problema persiste comuníquese con un asesor.',
        });
        dispatch({
          type: LOADING,
          payload: false,
        });
      }
    })
    .catch(res => {
      customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de  nuevo',
        text: res.message,
        footer: 'Si el problema persiste comuníquese con un asesor.',
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
    });
};
