import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetMethod, usePostMethod } from '../../Hooks';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import { customSwaltAlert, loader } from '../../helpers';
import { appCustomSelectNew } from '../Layouts/react-select-custom';
import riskStyles from './Risk.module.scss';

import importanceAlert from '../../assets/img/icons/importanceAlert.svg';
import importanceSuccess from '../../assets/img/icons/importanceSuccess.svg';
import Close from '../../assets/img/icons/modalClose.svg';

export const Risk = ({
  idAccount,
  title,
  patient,
  listRisk,
  onRemove,
  onHide,
  onAdd,
  show,
  btnNoEvent,
  doAfterSuccess,
  type = 'administrative',
}) => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;

  const [listImportances, setListImportancesAdmin] = useState([]);
  const [listImportanceAssistance, setListImportanceAssistance] = useState([]);
  const { load: loadListImportancesAdmin, trigger: getListImportancesAdmin } = useGetMethod();

  const { load: loaderListImportanceAssistance, trigger: getListImportanceAssistance } =
    useGetMethod();

  const { load: loadPutRisk, trigger: onPutRisk } = usePostMethod();

  const handlePut = () => {
    const body = {
      ...patient,
      risk: {
        ...patient.risk,
        [type]: listRisk[type]?.map(risk => ({
          id: risk?.id,
          description: risk?.description,
          classification: risk?.classification,
        })),
      },
    };

    onPutRisk({
      body,
      noAlert: true,
      token,
      method: 'PUT',
      url: '/medical/patient/',
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: 'Importancias actualizadas ',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).finally(() => {
          doAfterSuccess();
        });
      },
    });
  };

  useEffect(() => {
    getListImportancesAdmin({
      token,
      url: '/medical/markups/',
      objFilters: { status: 'enabled' },
    }).then(res => {
      if (res.success) {
        const format = res?.results?.map(risk => ({
          ...risk,
          value: risk?.id,
          label: risk?.description,
        }));
        setListImportancesAdmin(format);
      }
    });
  }, [getListImportancesAdmin, token]);

  useEffect(() => {
    getListImportanceAssistance({
      url: '/medical/patient/assistance-risks',
      objFilters: { eaccount: idAccount },
      token: token,
    }).then(res => {
      if (res.success) {
        const format = res?.results?.map(assistance => ({
          ...assistance,
          label: assistance.clinicalDiseaseName,
          description: assistance.clinicalDiseaseName,
          value: assistance?.clinicalDiseaseId,
          id: assistance?.clinicalDiseaseId,
        }));

        setListImportanceAssistance(format);
      }
    });
  }, [getListImportanceAssistance, idAccount, token]);

  return (
    <>
      {(loadListImportancesAdmin || loaderListImportanceAssistance || loadPutRisk) && loader}

      <OrdModal
        title={title}
        show={show}
        btnYesName={'Aceptar'}
        btnNoName={'Cancelar'}
        size={'200'}
        onHide={onHide}
        btnYesEvent={handlePut}
        btnNoEvent={btnNoEvent}
      >
        <div className='mb-4'>
          <div>
            <Select
              noOptionsMessage={() => 'No hay datos'}
              options={type === 'administrative' ? listImportances : listImportanceAssistance}
              styles={appCustomSelectNew}
              className='text-secondary'
              placeholder={'Seleccionar...'}
              value={{ label: 'Seleccionar...', value: '' }}
              onChange={e => {
                const filter = listRisk[type]?.filter(risk => risk.id === e.value);
                if (!filter?.length) {
                  onAdd(listRisk[type]?.length ? [e, ...listRisk[type]] : [e]);
                }
              }}
            />
          </div>

          <div className={`${listRisk[type]?.length ? riskStyles.container : ''} text-secondary`}>
            {listRisk[type]?.map(el => (
              <div
                key={el?.id}
                className={`${riskStyles.container_item}`}
                onClick={() => {
                  const filter = listRisk[type]?.filter(risk => risk.id !== el.id);
                  onRemove?.(filter);
                }}
              >
                {type === 'administrative' && (
                  <img
                    alt='cerrar'
                    width={17}
                    height={17}
                    className=''
                    src={el?.classification === 'high' ? importanceAlert : importanceSuccess}
                  />
                )}

                <div>{el?.description || el.name}</div>
                <img
                  className={`ms-auto ${riskStyles.filterGray}`}
                  src={Close}
                  alt='cerrar'
                  width={17}
                  height={17}
                />
              </div>
            ))}
          </div>
        </div>
      </OrdModal>
    </>
  );
};
